import { permissionConstants } from '../constants/permission';

let initialState = {
  permissions:[],
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
  loading: true,
}


export function permissions(state = initialState, action) {
  switch (action.type) {
    // GET ALL
    case permissionConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case permissionConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        permissions: action.permissions,
        totalPerPage: action.permissions.total,
        perPage: action.permissions.pages,
        currentPage: action.permissions.page,

      };
    case permissionConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    
    // GET BY PAGE
    case permissionConstants.GETPAGE_REQUEST:
        return {
          loading: true
        };
    case permissionConstants.GETPAGE_SUCCESS:
        return {
            permissions: action.permissions
        };
    case permissionConstants.GETPAGE_FAILURE:
        return { 
            error: action.error
        };

    //GET BY ID
    case permissionConstants.GET_REQUEST:
      return {
        saving: true
      };
    case permissionConstants.GET_SUCCESS:
      return {
        permission: action.permission
      };
    case permissionConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    
    // POST PERMISSION
    case permissionConstants.POST_PERMISSION_REQUEST:
        return { permissionAdd: true };
    case permissionConstants.POST_PERMISSION_SUCCESS:
        return {};
    case permissionConstants.POST_PERMISSION_FAILURE:
        return {};
    
    //DELETE PERMISSION
    case permissionConstants.DELETE_PERMISSION_REQUEST:
      // add 'deleting:true' property to user being deleted
      console.log(state)
      return {
        ...state,
        items: state.permissions.items.map(permission =>
          permission._id === action.id
            ? { ...permission, deleting: true }
            : permission
        )
      };

    case permissionConstants.DELETE_PERMISSION_SUCCESS:
      // remove deleted user from state
      return {
        items: state.permissions.items.filter(permission => permission._id !== action.id)
      };

    case permissionConstants.DELETE_PERMISSION_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.permissions.items.map(permission => {
          if (permission._id === action.id) {
            // make copy of permission without 'deleting:true' property
            const { deleting, ...permissionCopy } = permission;
            // return copy of permission with 'deleteError:[error]' property
            return { ...permissionCopy, deleteError: action.error };
          }
          return permission;
        })
      };

    default:
      return state
  }
}