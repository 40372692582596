import { get, post, del, put } from '../helpers/api';
import { userService } from '../services/user';

export const badgesService = {
    getAll,
    create,
    getContent,
    imageUpload,
    badgeDelete,
    updateBadge
};


function getAll(page, itemsPerPage, search, filter = "") {
    let fields = `${filter ==='badgeName' ? "badgeName:" + search : filter === 'badgeContent' ?"badgeContent:" + search : ""}`
    return get(`/api/v1/badge?page=${page}&items-per-page=${itemsPerPage}&search=${search}&filter=${fields}`).then(handleResponse);
}

function create(createBadge){
    return post(`/api/v1/badge`, JSON.stringify(createBadge))
    .then(handleResponse)
}

function getContent(id) {
    return get(`/api/v1/badge/${id}`).then(handleResponse);
}


function imageUpload(id , image){
    //var sample = JSON.parse(id)
    return post(`/api/v1/upload/${id}/badge`, image).then(handleResponse);
}

function badgeDelete(id) {
    return del(`/api/v1/badge/${id}`).then(handleResponse)
}

function updateBadge(id, posts){

    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
    const fd = new FormData();
    const imageFile = posts.badgeImage;
    fd.append('file',imageFile)
    
    //checking if post images has a content file
    if(isFile(posts.badgeImage) === true){
        posts.badgeImage = null;
    }

    return put(`/api/v1/badge/${id}`, JSON.stringify(posts)).then(handleResponse)
    .then(response => {
        imageUpload(id,fd);
    });
}


function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              userService.logout();
              //location.reload(true);
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);

      }
      return data;
  });
}


