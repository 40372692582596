import { overtimeConstants } from '../constants/overtime';
import { overtimeService } from '../services/overtime';
import  requestsActions from './requests';
import { alertActions } from './alert';
import { history } from '../helpers/history';
import Swal from 'sweetalert2'

export const overtimeActions = {
    getAll,
    getAllById,
    getById,
    addOvertimeRequest,
    approve,
    hold,
    reject,
    update
};

function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());
        overtimeService.getAll(page, itemsPerPage)
            .then(
                overtimes => {
                    dispatch(success(overtimes));
                    if(window.location.pathname === '/admin/overtime-requests'){
                        history.push('/admin/overtime-requests?page=' + Number(page) || 1);
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: overtimeConstants.GETALL_REQUEST } }
    function success(overtimes) { return { type: overtimeConstants.GETALL_SUCCESS, overtimes } }
    function failure(error) { return { type: overtimeConstants.GETALL_FAILURE, error } }
}

function getAllById(selectPage) {
    return dispatch => {
        dispatch(request());
        
        overtimeService.getAllById(selectPage)
            .then(
                overtimes => dispatch(success(overtimes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: overtimeConstants.GETUSER_REQUEST } }
    function success(overtimes) { return { type: overtimeConstants.GETUSER_SUCCESS, overtimes } }
    function failure(error) { return { type: overtimeConstants.GETUSER_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request(id));

        overtimeService.getById(id)
            .then(
                overtime => {
                    dispatch(success(overtime));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: overtimeConstants.GET_REQUEST} }
    function success(overtime) { return { type: overtimeConstants.GET_SUCCESS, overtime } }
    function failure(error) { return { type: overtimeConstants.GET_FAILURE, error } }
}

function approve(overtime) {
    return dispatch => {
        dispatch(request(overtime));

        overtimeService.approve(overtime)
            .then(
                overtime => { 
                    dispatch(success(overtime));
                    // history.push('/admin/overtime-requests/page=:id');
                    history.goBack(); 
                    dispatch(alertActions.success('overtime request approved'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('overtime approve failed'));
                }
            );
    };

    function request(overtime) { return { type: overtimeConstants.APPROVE_REQUEST, overtime } }
    function success(overtime) { return { type: overtimeConstants.APPROVE_SUCCESS, overtime } }
    function failure(error) { return { type: overtimeConstants.APPROVE_FAILURE, error } }
}

function hold(overtime) {
    return dispatch => {
        dispatch(request(overtime));

        overtimeService.hold(overtime)
            .then(
                overtime => { 
                    dispatch(success(overtime));
                    // history.push('/admin/overtime-requests/page=:id');
                    history.goBack();
                    dispatch(alertActions.success('overtime request on hold'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('overtime on hold failed'));
                }
            );
    };

    function request(overtime) { return { type: overtimeConstants.HOLD_REQUEST, overtime } }
    function success(overtime) { return { type: overtimeConstants.HOLD_SUCCESS, overtime } }
    function failure(error) { return { type: overtimeConstants.HOLD_FAILURE, error } }
}

function reject(overtime) {
    return dispatch => {
        dispatch(request(overtime));

        overtimeService.reject(overtime)
            .then(
                overtime => { 
                    dispatch(success(overtime));
                    // history.push('/admin/overtime-requests/page=:id');
                    history.goBack(); 
                    dispatch(alertActions.success('overtime request rejected'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('overtime reject failed'));
                }
            );
    };

    function request(overtime) { return { type: overtimeConstants.DECLINE_REQUEST, overtime } }
    function success(overtime) { return { type: overtimeConstants.DECLINE_SUCCESS, overtime } }
    function failure(error) { return { type: overtimeConstants.DECLINE_FAILURE, error } }
}

function update(overtime) {
    return dispatch => {
        dispatch(request(overtime));

        overtimeService.update(overtime)
            .then(
                overtime => { 
                    dispatch(success(overtime));
                    //history.push('/admin/overtime-requests/page=:id');
                    history.goBack(); 
                    dispatch(alertActions.success('overtime updated'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('overtime update failed'));
                }
            );
    };

    function request(overtime) { return { type: overtimeConstants.UPDATE_REQUEST, overtime } }
    function success(overtime) { return { type: overtimeConstants.UPDATE_SUCCESS, overtime } }
    function failure(error) { return { type: overtimeConstants.UPDATE_FAILURE, error } }
}

function addOvertimeRequest(overtime) {
    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });
    return dispatch => {
        dispatch(request(overtime));

        overtimeService.addOvertimeRequest(overtime)
            .then(
                overtime => { 
                    dispatch(success());
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:green">Overtime Request Added!</span>'
                    }).then(() => {
                        const userDetails = JSON.parse(localStorage.getItem("userCredentials"));
                        dispatch(requestsActions.getRequests({
                            itemPerPage: 10,
                            selectPage: 1,
                            userId: userDetails.user._id
                        })) 
                        
                    }).then(() => {   history.push('/employee-self-service') })
                    dispatch(alertActions.success('New overtime has been created'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    Toast.fire({
                        type: 'error',
                        title: '<span style="color:green">Overtime Request Failed!</span>'
                    }).then(() => {
                        // history.push('/overtime')
                    })
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };

    function request(overtime) { return { type: overtimeConstants.CREATE_REQUEST, overtime } }
    function success(overtime) { return { type: overtimeConstants.CREATE_SUCCESS, overtime } }
    function failure(error) { return { type: overtimeConstants.CREATE_FAILURE, error } }
}

// // prefixed function name with underscore because delete is a reserved word in javascript
// function _delete(id) {
//     return dispatch => {
//         dispatch(request(id));

//         overtimeService.delete(id)
//             .then(
//                 overtime => dispatch(success(id)),
//                 error => dispatch(failure(id, error.toString()))
//             );
//     };

//     function request(id) { return { type: overtimeConstants.DELETE_REQUEST, id } }
//     function success(id) { return { type: overtimeConstants.DELETE_SUCCESS, id } }
//     function failure(id, error) { return { type: overtimeConstants.DELETE_FAILURE, id, error } }
// }