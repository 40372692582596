import React from 'react';
import { Link } from 'react-router-dom';
import MainNav from '../Components/MainNav';

import flag from '../_templates/flag.png'

import avatar from '../_templates/26731183_1548961821883748_5876949817012874527_n.jpg'

import background_card from '../_templates/bg-card.png'

import coins from '../_templates/coins.png'

import M from 'materialize-css/dist/js/materialize.min.js'

import MaterialIcon from 'material-icons-react';

import './Challengespage.sass'

class Challengespage extends React.Component {
    componentDidMount() {
        var elem = document.querySelector(".parallax");
        M.Parallax.init(elem);
    }

    render() {
        return (
          <div className="challenges-page">
            <MainNav/>
          	<div className="container-fluid">
                <div className="row">
                    <div className="center">
                    	<img src={flag} className="flag" alt="flag"/>
                        <h3 className="challenge-title">Challenges</h3>
                    	<h4 className="challenge-detail">I challenge you to create challenge now!</h4>
                        <div className="col s12 m4 offset-m4">
                        	<button className="waves-effect waves-light btn btn-create-challenge">Create a Challenge!</button>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col s12 m6 l4"> 
                            <div className="card hoverable">
                                <div className="card-image">
                                    <img src={background_card} className="background-card" alt="background-card"/>
                                    <div className="card-title grey-text text-darken-4 more_horiz"><MaterialIcon icon="more_horiz" color="white" id="logo"/></div>
                                </div>
                                <div className="card-content">
                                    <div className="row">
                                        <div className="col s12 m6 l3">
                                            <img src={avatar} alt="" className="avatar circle"/>
                                           
                                        </div>
                                        <div className="col s12 m6 l7">
                                            <h5 className="left-align">Heart Santiago</h5>
                                            <p className="left-align">Web Designer</p>
                                        </div>
                                    </div>
                                     <p className="content-title">Mission Impossible</p>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque. In vehicula eget ligula vitae elementum. Quisque commodo diam et augue sodales vehicula. Integer. 
                                    </p>
                                    <Link to="#" className="waves-effect waves-light more-info">More Info</Link>
                                </div>
                                <div className="card-action">
                                    <div className="center">
                                        <div className="col s12 m12 l12">
                                            <button className="waves-effect waves-light btn btn-accept-challenge">Accept Challenge</button>
                                        </div>
                                    </div>

                                    <div className="icon-down">
                                        <span className="thumb_up"><MaterialIcon icon="thumb_up" color="gray"/></span>
                                        <span className="bill"> 299 </span>
                                        <span className="coin"> <img src={coins} className="coins" alt="coin"/> </span>
                                        <span className="bill"> 299 </span>
                                        <span className="brightness_1"><MaterialIcon icon="brightness_1" color="red"/></span>
                                    <span className="hourglass_empty"><MaterialIcon icon="hourglass_empty" color="gray"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                         <div className="col s12 m6 l4"> 
                            <div className="card hoverable">
                                <div className="card-image">
                                    <img src={background_card} className="background-card" alt="background-card"/>
                                        <span className="card-title activator grey-text text-darken-4"><MaterialIcon icon="more_horiz" color="white" id="logo"/></span>
                                </div>
                                <div className="card-content">
                                    <div className="row">
                                        <div className="col s12 m6 l3">
                                            <img src={avatar} alt="" className="avatar circle"/>
                                            
                                        </div>
                                        <div className="col s12 m6 l7">
                                            <h5 className="left-align">Heart Santiago</h5>
                                             <p className="left-align">Web Designer</p>
                                        </div>
                                    </div>
                                    <p className="content-title">Mission Impossible</p>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque. In vehicula eget ligula vitae elementum. Quisque commodo diam et augue sodales vehicula. Integer.
                                    </p>
                                    <Link to="#" className="waves-effect waves-light more-info">More Info</Link>
                                </div>
                            <div className="card-action">
                                <div className="center">
                                    <div className="col s12 m12 l12">
                                        <button className="waves-effect waves-light btn btn-accept-challenge">Accept Challenge</button>
                                     </div>
                                </div>
                                <div className="icon-down">
                                    <span className="thumb_up"><MaterialIcon icon="thumb_up" color="gray"/></span>
                                    <span className="bill"> 299 </span>
                                    <span className="coin"> <img src={coins} className="coins" alt="coin"/> </span>
                                    <span className="bill"> 299 </span>
                                    <span className="brightness_1"><MaterialIcon icon="brightness_1" color="red"/></span>
                                    <span className="hourglass_empty"><MaterialIcon icon="hourglass_empty" color="gray"/></span>
                                </div>
                            </div>
                          </div>
                        </div>

                        <div className="col s12 m6 l4">
                            <div className="card hoverable">
                                <div className="card-image">
                                    <img src={background_card} className="background-card" alt="background-card"/>
                                        <span className="card-title activator grey-text text-darken-4"><MaterialIcon icon="more_horiz" color="white" id="logo"/></span>
                                </div>
                                <div className="card-content">
                                    <div className="row">
                                        <div className="col s12 m6 l3">
                                            <img src={avatar} alt="" className="avatar circle"/>
                                            
                                        </div>
                                        <div className="col s12 m6 l7">
                                            <h5 className="left-align">Heart Santiago</h5>
                                             <p className="left-align">Web Designer</p>
                                        </div>
                                    </div>
                                    <p className="content-title">Mission Impossible</p>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque. In vehicula eget ligula vitae elementum. Quisque commodo diam et augue sodales vehicula. Integer. 
                                    </p>
                                    <Link to="#" className="waves-effect waves-light more-info">More Info</Link>
                                </div>
                            <div className="card-action">
                                <div className="center">
                                    <div className="col s12 m12 l12">
                                        <button className="waves-effect waves-light btn btn-accept-challenge">Accept Challenge</button>
                                     </div>
                                </div>
                                <div className="icon-down">
                                    <span className="thumb_up"><MaterialIcon icon="thumb_up" color="gray"/></span>
                                    <span className="bill"> 299 </span>
                                    <span className="coin"> <img src={coins} className="coins" alt="coin"/> </span>
                                    <span className="bill"> 299 </span>
                                    <span className="brightness_1"><MaterialIcon icon="brightness_1" color="red"/></span>
                                    <span className="hourglass_empty"><MaterialIcon icon="hourglass_empty" color="gray"/></span>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="col s12 m6 l4"> 
                            <div className="card hoverable">
                                <div className="card-image">
                                    <img src={background_card} className="background-card" alt="background-card"/>
                                        <span className="card-title activator grey-text text-darken-4"><MaterialIcon icon="more_horiz" color="white" id="logo"/></span>
                                </div>
                                <div className="card-content">
                                    <div className="row">
                                        <div className="col s12 m6 l3">
                                            <img src={avatar} alt="" className="avatar circle"/>
                                            
                                        </div>
                                        <div className="col s12 m6 l7">
                                            <h5 className="left-align">Heart Santiago</h5>
                                             <p className="left-align">Web Designer</p>
                                        </div>
                                    </div>
                                    <p className="content-title">Mission Impossible</p>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque. In vehicula eget ligula vitae elementum. Quisque commodo diam et augue sodales vehicula. Integer.
                                    </p>
                                    <Link to="#" className="waves-effect waves-light more-info">More Info</Link>
                                </div>
                                <div className="card-action">
                                    <div className="center">
                                        <div className="col s12 m12 l12">
                                            <button className="waves-effect waves-light btn btn-accept-challenge">Accept Challenge</button>
                                        </div>
                                    </div>
                                    <div className="icon-down">
                                        <span className="thumb_up"><MaterialIcon icon="thumb_up" color="gray"/></span>
                                        <span className="bill"> 299 </span>
                                        <span className="coin"> <img src={coins} className="coins" alt="coin"/> </span>
                                        <span className="bill"> 299 </span>
                                        <span className="brightness_1"><MaterialIcon icon="brightness_1" color="red"/></span>
                                    <span className="hourglass_empty"><MaterialIcon icon="hourglass_empty" color="gray"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                         <div className="col s12 m6 l4"> 
                            <div className="card hoverable">
                                <div className="card-image">
                                    <img src={background_card} className="background-card" alt="background-card"/>
                                        <span className="card-title activator grey-text text-darken-4"><MaterialIcon icon="more_horiz" color="white" id="logo"/></span>
                                </div>
                                <div className="card-content">
                                    <div className="row">
                                        <div className="col s12 m6 l3">
                                            <img src={avatar} alt="" className="avatar circle"/>
                                           
                                        </div>
                                        <div className="col s12 m6 l7">
                                            <h5 className="left-align">Heart Santiago</h5>
                                            <p className="left-align">Web Designer</p>
                                        </div>
                                    </div>
                                     <p className="content-title">Mission Impossible</p>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque. In vehicula eget ligula vitae elementum. Quisque commodo diam et augue sodales vehicula. Integer. 
                                    </p>
                                    <Link to="#" className="waves-effect waves-light more-info">More Info</Link>
                                </div>
                            <div className="card-action">
                                <div className="center">
                                    <div className="col s12 m12 l12">
                                        <button className="waves-effect waves-light btn btn-accept-challenge">Accept Challenge</button>
                                     </div>
                                </div>
                                <div className="icon-down">
                                    <span className="thumb_up"><MaterialIcon icon="thumb_up" color="gray"/></span>
                                    <span className="bill"> 299 </span>
                                    <span className="coin"> <img src={coins} className="coins" alt="coin"/> </span>
                                    <span className="bill"> 299 </span>
                                    <span className="brightness_1"><MaterialIcon icon="brightness_1" color="red"/></span>
                                    <span className="hourglass_empty"><MaterialIcon icon="hourglass_empty" color="gray"/></span>
                                </div>
                            </div>
                          </div>
                        </div>

                        <div className="col s12 m6 l4">
                            <div className="card hoverable">
                                <div className="card-image">
                                    <img src={background_card} className="background-card" alt="background-card"/>
                                        <span className="card-title activator grey-text text-darken-4"><MaterialIcon icon="more_horiz" color="white" id="logo"/></span>
                                </div>
                                <div className="card-content">
                                    <div className="row">
                                        <div className="col s12 m6 l3">
                                            <img src={avatar} alt="" className="avatar circle"/>
                                            
                                        </div>
                                        <div className="col s12 m6 l7">
                                            <h5 className="left-align">Heart Santiago</h5>
                                             <p className="left-align">Web Designer</p>
                                        </div>
                                    </div>
                                    <p className="content-title">Mission Impossible</p>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque. In vehicula eget ligula vitae elementum. Quisque commodo diam et augue sodales vehicula. Integer. 
                                    </p>
                                    <Link to="#" className="waves-effect waves-light more-info">More Info</Link>
                                </div>
                            <div className="card-action">
                                <div className="center">
                                    <div className="col s12 m12 l12">
                                        <button className="waves-effect waves-light btn btn-accept-challenge">Accept Challenge</button>
                                     </div>
                                </div>
                                <div className="icon-down">
                                    <span className="thumb_up"><MaterialIcon icon="thumb_up" color="gray" size="small"/></span>
                                    <span className="bill"> 299 </span>
                                    <span className="coin"> <img src={coins} className="coins" alt="coin"/> </span>
                                    <span className="bill"> 299 </span>
                                    <span className="brightness_1"><MaterialIcon icon="brightness_1" color="red"/></span>
                                    <span className="hourglass_empty"><MaterialIcon icon="hourglass_empty" color="gray"/></span>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>  
                    </div>
                    
          </div>
        );
    }
}

export { Challengespage };
