import { pageConstants } from '../constants/page';

export function pages(state = {}, action){
    switch(action.type){
        case pageConstants.GETUSER_REQUEST:
            return{
                loading: true
            };
        case pageConstants.GETUSER_SUCCESS:
            return{
                users: action.users
            };
        case pageConstants.GETUSER_FAILURE:
            return{
                error: action.error
            };

        case pageConstants.GETLEAVE_REQUEST:
            return{
                loading: true
            };
        case pageConstants.GETLEAVE_SUCCESS:
            return{
                leaves: action.leaves
            };
        case pageConstants.GETLEAVE_FAILURE:
            return{
                error: action.error
            };

        case pageConstants.GETOVERTIME_REQUEST:
            return{
                loading: true
            };
        case pageConstants.GETOVERTIME_SUCCESS:
            return{
                overtimes: action.overtimes
            };
        case pageConstants.GETOVERTIME_FAILURE:
            return{
                error: action.error
            };

        //attributes
        case pageConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case pageConstants.GETALL_SUCCESS:
            return {
                attributes: action.attributes
            };
        case pageConstants.GETALL_FAILURE:
            return {
                error: action.error
            };

        //roles
        case pageConstants.GETROLE_REQUEST:
            return {
                loading: true
            };
        case pageConstants.GETROLE_SUCCESS:
            return {
                roles: action.roles
            };
        case pageConstants.GETROLE_FAILURE:
            return {
                error: action.error
            };

        //pragger
        case pageConstants.GETPRAGGER_REQUEST:
            return {
                loading: true
            };
        case pageConstants.GETPRAGGER_SUCCESS:
            return {
                praggers: action.praggers
            };
        case pageConstants.GETPRAGGER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
