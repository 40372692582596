import React from "react";
import styled from "styled-components";
import moment from 'moment';


export const PageWrapper = styled.div`
  // position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;

  margin-top: 30px;
  padding: 35px 20px 35px 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;

  height: 100%;
`;

export const PageHeaderWrapper = styled.div``;

export const ContentWrapper = styled.div`
  border-radius: 4px;
  margin-top: 20px;
  padding: 20px;
  background-color: #2a3143;
  width: 100%;
`;

export const HeaderContentWrapper = styled.div``;

export const DropdownWrapper2Set = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

// for create/edit pages
export const LabelCustom = styled.p`
  color: #9ba2ce;
  margin: 0 0 20px 0;

  &.main-label {
    font-size: 16px;
    font-weight: 700;
  }

  &.sub-label {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 18px;

  > div {
    height: 40px;
    width: 100%;
    padding: 0 15px;
    background-color: #2e3648;
    overflow: hidden;

    > input[type="text"] {
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #9ba4c7;
      border: none !important;
      border-radius: 3px;

      &::placeholder {
        color: #9ba4c7;
      }
    }

    &.is-error {
      border: 1px solid #f14646;
      border-radius: 3px;
    }
  }

  > small {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
    margin: 0 15px;
  }
`;

export const CreateBtn = styled.button`
  width: 100%;
  height: 40px;
  background-color: #556ee6;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    background-color: #556ee6;
  }
`;

export const SaveAndCancelBtnWrapper = styled.div`
  width: 100%;

  > div {
    max-width: 270px;
    margin-left: auto;

    > button {
      width: 120px;
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      border: 1px solid #556ee6;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 15px;

      &:nth-child(1) {
        background-color: transparent;
        color: #556ee6;

        &:focus {
          background-color: transparent;
        }
      }

      &:nth-child(2) {
        background-color: #556ee6;
        color: #ffffff;

        &:focus {
          background-color: #556ee6;
        }
      }
    }
  }
`;

export const SaveAndCancelButton = (props) => {
  return (
    <SaveAndCancelBtnWrapper>
      <div>
        <button onClick={props.cancelFn}>Cancel</button>
        <button onClick={props.saveFn}>{props.submitLabel ?? "Save"} </button>
      </div>
    </SaveAndCancelBtnWrapper>
  );
};

export const InputTextCustom = (props) => {
  return (
    <InputWrapper>
      <div className={!props.value.trim() && props.isError ? "is-error" : ""}>
        <input
          type="text"
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          onChange={props.inputOnChange}
          autoComplete="off" 
        />
      </div>
      {!props.value.trim() && props.isError && (
        <small>Please fill-up this field.</small>
      )}
    </InputWrapper>
  );
};

const SwitchWrapper = styled.div`
  position: relative;
  width: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const SwitchCheckedBoxCustom = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;

  :checked + .onoffswitch-label {
    background-color: #556ee6;
    border-color: #556ee6;
  }
  :checked + .onoffswitch-label:before {
    border-color: #556ee6;
  }
  :checked + .onoffswitch-label:before {
    right: 0px;
  }
`;

const SwitchLabelCustom = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 20px;
  padding: 0;
  line-height: 36px;
  border: 2px solid #6A7287;
  border-radius: 12px;
  background-color: #6A7287;
  transition: background-color 0.3s ease-in;

  :before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin: 0px;
    background: #FFF;
    position: absolute;
    top: 1px;
    bottom: 0;
    right: 20px;
    border: 2px solid #6A7287;
    border-radius: 12px;
    transition: all 0.3s ease-in 0s;
  }
`;

export const SwitchCustom = (props) => {
  return (
    <SwitchWrapper className="onoffswitch" >
      <SwitchCheckedBoxCustom
        type="checkbox"
        name="onoffswitch"
        className="onoffswitch-checkbox"
        id="myonoffswitch"
        tabindex="0"
      />
      <SwitchLabelCustom
        className="onoffswitch-label"
        htmlFor="myonoffswitch"
        onClick = { props.onClick }
      ></SwitchLabelCustom>
    </SwitchWrapper>
  );
};

export const SwitchComponentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
`;

export const SwitchLabelWrapper = styled.label`
  font-size: 16px;
  font-weight: 700;
  color: #9ba2ce;
`;

export const SwitchAndLabelCustom = (props) => {
  return (
    <SwitchComponentWrapper >
      <SwitchLabelWrapper > {props.switchLabel} </SwitchLabelWrapper>
      <SwitchCustom onClick = {props.onClick} />
    </SwitchComponentWrapper>
  );
};

const QuestionPreviewWrapper = styled.div`
  width: 100%;
  padding: 20px;
  margin: 40px 0px;
  background: #2E3648;
  border: 1px #323A4F solid;
  border-radius: 3px;
`;

const QuestionTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #9ba3ca;
  margin-bottom: 24px;
`;
const QuestionDescription = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
`;

const RadioButtonRatingWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  margin: 20px 0px;

  > p {
    margin-right: 10px;
  }

  > p > label > select {
  
  }

  > p > label > [type="radio"] + span:before,
  [type="radio"] + span:after {
    height: 20px;
    width: 20px;
  }
  > p > label > [type="radio"]:not(:checked) + span:before,
  [type="radio"]:not(:checked) + span:after {
    background: #ffffff;
    border: 2px solid #cccccc;
  }

  > p > label > [type="radio"].with-gap:checked + span:before {
    border: 2px solid #4cd04b;
  }
  > p > label > [type="radio"]:checked + span:after,
  [type="radio"].with-gap:checked + span:after {
    background-color: #4cd04b;
  }

  > p > label > [type="radio"]:disabled + span {
    color: #ffffff;
  }

  > p > label > [type="radio"]:not(:checked) + span,
  [type="radio"]:checked + span {
    color: #ffffff;
  }
`;

export const CustomRadioButton = (props) => {
  let ratingData = props.getRating ?? [{label: 0, value: 0}];
  let isDisable = props.isDisable ?? false;
  return (
    <RadioButtonRatingWrapper>
      {ratingData.length !== 0 &&
        ratingData.map((rating, count) => (
          <p key={count}>
            <label>
              <input className="with-gap" name="group1" type="radio" disabled={isDisable} />
              <span>{rating.label}</span>
            </label>
          </p>
        ))}
    </RadioButtonRatingWrapper>
  );
}

export const QuestionPreview = (props) => {
  return (
    <QuestionPreviewWrapper>
      <QuestionTitle>{props.questionTitle}</QuestionTitle>
      <QuestionDescription>{props.questionDescription}</QuestionDescription>
    {props.isRating &&
      <CustomRadioButton getRating = {props.getRating} isDisable = {props.isDisable}/>
    }
    
    </QuestionPreviewWrapper>
  );
};

const LeaveHeaderStyle = styled.div`
    .info {
        color:#9BA1D2;
        font-weight:700;

        span {
            color:#556EE6
        }
    }
`;

export const LeaveHeader = (props) => {
  return (
      <LeaveHeaderStyle className="row">
        <div class="col s4 m6 l4" style={{padding:"0"}}>
            <p className="info">Application Status :
                <span className={props.status === "on hold" ? "onHold" : props.status} style={{ fontWeight: "bold" }}>{' '}{props.status ? props.status === "new" ? "in-review" : props.status : "no status/old"}</span>
            </p>
        </div>
        <div class="col s4 m6 l3" style={{padding:"0"}}>
            <p className="info">Number of Days : <span style={{ fontWeight: "normal",color:"#556EE6" }}>
                {props.startedAt && props.endedAt ?
                    moment(props.endedAt).diff(moment(props.startedAt), 'days') + 1 ? moment(props.endedAt).diff(moment(props.startedAt), 'days') + 1 : '0'
                    :
                    moment(props.endedAt).diff(moment(props.startedAt), 'days') + 1 ? moment(props.endedAt).diff(moment(props.startedAt), 'days') + 1 : '1'
                }
            </span></p>
        </div>
        <div class="col s4 m6 l5" style={{padding:"0"}}>
            <p className="info right">Date Filed : <span style={{color:"#556EE6" }}>{moment(props.createdAt).utc().format('dddd, MMMM DD YYYY')}</span></p>
        </div>
      </LeaveHeaderStyle>
  );
};