import { leaveCreditConstants } from '../constants/leaveCredit';

export function addleaveCredit(state = {}, action) {
  switch (action.type) {
    case leaveCreditConstants.POST_REQUEST:
      return { addleaveCredit: true };
    case leaveCreditConstants.POST_SUCCESS:
      return { addleaveCredit: false };
    case leaveCreditConstants.POST_FAILURE:
      return {};
    default:
      return state
  }
}