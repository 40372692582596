import React from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect, useDispatch } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import styled from "styled-components";
import Select from 'react-select';
import Swal from 'sweetalert2';
import {CustomInputText, CustomTextAreaEngage} from "../../Components/FloatingTextBox/TextArea";
import { creditService } from '../../../_core/services/credit';
import { challengeService } from '../../../_core/services/challenge';
import { userService } from '../../../_core/services/user';
import './Credit.scss';

import { userActions } from '../../../_core/actions/user';
import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent} from "../../Components/PageWrapper/DynamicWrapper";

import avatar from '../../../_templates/man.png';
// import './TodayPageDark.sass'

const CreditsPage = styled.div`
  table {
    >thead>tr>th {
       &:last-child {
        text-align: inherit;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: inherit;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const Credits = (props) => {
  const dispatch = useDispatch();
  const [InputError, setInputError] = React.useState(false);
  

  const user = JSON.parse(localStorage.getItem('userCredentials'));

  const [count, setCount] = React.useState(0);


  const [users, setUsers] =  React.useState({ 
    id: '',
    type:"available",
    value:'',
    origin:"credits",
    user: '',
    userFullName: '',
    image: '',
    loading: false,
    error: false,
    password: '12345',
    reason: ''
});

const currentUserId = user.user._id;

React.useEffect(() => {
  props.dispatch(userActions.getAll(1, 100000));
  getUsers();
  //eslint-disable-next-line
}, [dispatch]);
  

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    if(name === "reason"){
      setCount(value.length)
  }

    // if(name === "reason"){
    //   setUsers(reason.length)
    // }
    setUsers({
        ...users,
        [name]: value,
      });

  }

  const handleChange = (event) => {
    const value = event.value;

    const users = props.users;
    const userDetails = [];

    users.users.items.filter(user => 
      user.praggerUsername === value
    ).map(user2 => {userDetails.push({
        value : user2.praggerUsername,
        image: user2.details[0].avatar,
        fullName: user2.details[0].firstName + " " + user2.details[0].lastName,
        key: user2._id
      })
    
      return user2
    });
    
    const userImage = userDetails.filter(un => (
      un.value === value
    ))[0].image;
    
    const fullName = userDetails.filter(un => (
      un.value === value
    ))[0].fullName;
    
    const keyId = userDetails.filter(un => (
      un.value === value
    ))[0].key;

    setUsers({ 
        id: keyId,
        type:"available",
        value:'',
        origin:"credit",    
        user: value,
        image: userImage,
        userFullName: fullName,
        password: '12345',
        reason: ''
    });
    setInputError(false);
  }
  
  const searchUser = (e) =>{
    e.preventDefault();

    // const users = props.users;
    // let username = users.user;
    setUsers({...users,
        loading: true
    })

    if(users.user === ''){
      setUsers({
          loading: false
      },()=>{
        setInputError(true);
      })
    }else {
      if(
        users.value === '' ||
        users.reason === ''
      ){
        setInputError(true);
      }else {
        const ToastSuccess = Swal.mixin({
          background: "#2a3042",
          toast: true,
          width: 300,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500
        });
        Swal.fire({
          title: 'Please confirm your password to continue.',
          html:
          '<small class="passwordSubTitle">Password</small>',
          input: 'password',
          inputLabel: 'Password',
          showCloseButton: true,
          showConfirmButton: true,
          showButton: true,
          padding: '2em',
          background: '#2E3549',
          confirmButtonText:"Confirm",
          customClass: {
            title: 'creditTitle',
            confirmButton: 'myConfirmButton',
            container: 'container-class',
            popup: 'popup-class-engagament',
            actions: 'actions-class-engagament',
            input: 'passwordConfirm'
          },
        }).then((result) => {

            const pass = {
                "password":result.value
            }

            userService.verifyPassword(currentUserId,pass).then((res) => {
                if (res.success) {
                    Swal.fire({
                        title: "<p class='swal-title-engagement'>Confirmation</p>",
                        html:
                          " <div style='margin:30px'><p class='alert-first'>Are you sure you want to add credit?</p></div>",
                    
                          showCancelButton: true,
                          showCloseButton: true,
                          confirmButtonColor: "#556ee6",
                          cancelButtonColor: "#2A3143",
                          customClass: {
                            header: 'header-class',
                            container: 'container-class',
                            popup: 'popup-class-engagament',
                            actions: 'actions-class-engagament',
                            cancelButton: 'cancel-button-class',
                            confirmButton: 'confirm-btn-engagement',
                            content: 'E-alert-conent',
                          },
                          reverseButtons:true,
                          cancelButtonText: "CANCEL",
                          confirmButtonText:"ADD CREDIT",
                          background: "#2E3549",
                      }).then((result) => {
                        if (result.value) {

                          let draftPostContent = `${users.user}  has been rewarded ${users.value}  credits by System Administrator for ${users.reason} `;
                          let draftPostTitle = `${users.user} was commended`;
                          let draftUserTag =  currentUserId;
                
                          let commendPost = {
                            postContent: draftPostContent,
                            postTitle: draftPostTitle,
                            postUser: draftUserTag,
                            postMention: [users.id],
                            anonymous: 1
                          }

                          let credit ={
                            type: users.type,
                            value:users.value,
                            origin:users.origin
                          }

                            challengeService.addCredit(users.id , credit).then((res)=>{

                                if(res.success){
                                    setTimeout(() => {
                                        ToastSuccess.fire({
                                            type: 'success',
                                            title: '<span style="color:#FFFFFF"> Credit added </span>',
                                            position: 'center',
                                        });
                                            creditService.postCredit(users.id, currentUserId, commendPost).then(() => {
                                                setTimeout(function(){window.location.reload(1);}, 2000);
                                            })
                                    }, 200)
                                }

                            }).catch((error) => {
                                throw Swal.fire({
                                    title: '<p class="swal-subtitle-dark">'+error+'</p>',
                                    type: 'warning',
                                    showConfirmButton: false,
                                    showCancelButton: true,
                                    cancelButtonColor: '#d33',
                                    background: '#2E3549',
                                    cancelButtonText: "OK",
                                  })
                              });



                        }
                      })
                }
                
              }).catch((error) => {
                throw Swal.fire({
                    title: '<p class="swal-subtitle-dark">Incorrect Password</p>',
                    type: 'warning',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonColor: '#d33',
                    background: '#2E3549',
                    cancelButtonText: "OK",
                  })
              });
              setUsers({...users,
                loading: false
               })
        })
        
      }
    }
  }

  const getUsers = () =>{
    const users = props.users;
    const usersList = [];
    const user =  users.users.items ;

    const def = (user) => (
      user?.map((myItems,index) => {
        // myItems.praggerUsername
          usersList.push({
            value: myItems.praggerUsername,
            label: myItems.details[0].firstName + " " + myItems.details[0].lastName,
            image: myItems.details[0].avatar,
            key: index,
        })
        return myItems
      })
    );
        
    def(user);
    // users.users && users.users.items.map((user, index) => {
    //     usersList.push({
    //         value: user.praggerUsername,
    //         label: user.details[0].firstName + " " + user.details[0].lastName,
    //         key: index,
    //     });
    //     return user
    // })

    return usersList
}

const handleNumber = (e) => {
  const value = e.target.value.replace(/\D/g, "");
  const credit = parseInt(value);
  if(value.length === 0) {
    setUsers({ ...users, [e.target.name]: '', });
  } else {
    setUsers({ ...users, [e.target.name]: credit, });
  }
};

  const customStyles = {
    control: (base, state) => ({
        ...base,
        fontFamily: '"Helvetica Neue", sans-serif',
        border: state.isFocused ? '1px solid #556EE6 !important' : '1px solid #2E3648',
        boxShadow: state.isFocused ? '1px solid #556EE6' : '1px solid #2E3648',
        outline: 'none',
        marginBottom:"15px",
        height: '50px',
        padding: '0px 3px',
        borderRadius: '5px',
        alignContent: "center",
        backgroundColor: '#2E3648',
        color: 'white'
      }),
      input: (state) => ({
        fontFamily: '"Helvetica Neue", sans-serif',
        '& input': {
          font: 'inherit',
          textDecoration: state.isFocused ? 'none !important' : 0,
          color: 'white',
          marginLeft: '50px',
        }
      }),
    menu:(provided)  => ({
        ...provided,
        height: "auto",
        maxHeight: "180px",
        zIndex: "99",
        position: "absolute",
        display: "block",
        backgroundColor: '#2E3648',
        color: '#9BA2CE'
    }),
    menuList:(provided)  => ({
        ...provided,
        height: "auto",
        maxHeight: "180px",
        padding: "2px 2px",
        backgroundColor: '#2E3648',
        color: '#9BA2CE'
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#323A4F' : state.isFocused ? '#323A4F' : '',
        color: state.isSelected ? 'white' : state.isFocused ? 'white' : '',
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: state.isFocused ? "#323A4F" : "",
            color: state.isSelected ? 'white' : '',
        }
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#9BA2CE';
      return { ...provided, opacity, transition, color};
    }
  }

  return (
    <CreditsPage>
      <div>
        <b className="userTitle">User</b>
        <div className="row" id="zero">
            <div className="col s12 m12 l12" id="zero">
                <div className="search-border">
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select user"
                    isSearchable='true'
                    options={getUsers()}
                    styles={customStyles}
                    onChange={handleChange}
                />
                </div>
            </div>
            
            {
            users.user &&
              <div className="col s12 m12 l12 right" id="zero">
                <div  className="col s12 m6 l6 pl-0 userNameWrapper" style={{display: 'flex'}}>
                  <img src={users.image ? users.image : avatar} alt="" className="imgCircle"  style={{height: '25px', width: '25px', marginRight: '15px', borderRadius: '50%', objectFit: 'cover'}}/>
                  <p className="userName">{users.userFullName}</p>
                </div>
                <div className="col s12 m6 l6 pl-0" >
                  <CustomInputText
                      label={"Amount"}
                      name={"value"}
                      value={String(users.value)}
                      inputOnChange={(e) => handleNumber(e)}
                      isError={!users.value && InputError}
                  />
                </div>
                <div className="col s12 m12 l12 pr-0" >
                <CustomTextAreaEngage
                    label={"Reason"}
                    name={"reason"}
                    value={String(users.reason)}
                    inputOnChange={handleChangeInput}
                    maxlength="150"
                    isError={!users.reason && InputError}
                />
                <p className="charCount2 reasonCount">{count}</p>
                  <button className="btn  right generate-btn submitButton" onClick={searchUser}>Submit</button>
                </div>
              </div>
            }
        </div>
      </div>
    </CreditsPage>
  );
};


const mapStateToProps = ({ users }) => ({
  users
});

const TodayPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Credits", link: "" },
        ]);
        break;
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={ `Engagement | Credits | ${process.env.REACT_APP_DOCUMENT_TITLE}`}
      >
        <PageWrapper className={cc(["LeaveTypesPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Engagement"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <Credits {...props} />
              </ContentWrapper>
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});


TodayPage.WrappedComponent.displayName = "TodayPage";
const CreditsPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <TodayPage {...props} mode="list" />}
    />
  </Switch>
);
export default React.memo(CreditsPageRoute);
