import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import styled, { css } from "styled-components";

const DropdownWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const LabelDropdown = styled.label`
  font-size: 10px !important;
  font-weight: 700 !important;
  color: #9ba4c7;
  position: relative;
  top: 10px;

  z-index: 1;
  ${props => props.section
    ? css`
        left: 51px;
      `
    : css`
        left: 21px;
      `}
`;


const customStyles1 = {
  control: (provided, state) => ({
    ...provided,
    background: "#2E3648",
    borderColor: "#9ba2ce",
    minHeight: 46,
    height: 46,
    width: "auto",
    marginRight: "28px",
    border: "none",
    boxShadow: state.isFocused ? null : null,
  }),
  
  singleValue: (provided) => ({
    ...provided,
    color: "#FFFFFF",
    
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "21px",
  }),

  option: (base, state) => ({
    ...base,
    color: "#9ba2ce",
    backgroundColor: state.isSelected ? "#32394A" : "#2A3143",
    ":hover": {
      backgroundColor: state.isSelected ? "#2A3143" : "#32394A",
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    height: 46,
    marginLeft: "21px",
    color: "#fff",
    lineHeight: state.isFocused ? 0 : 0,
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 46,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    transition: "0.5s",
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),

  placeholder: (provided, state) => ({
    fontSize: "14px",
    fontWeight: 400,
    color: "#FFFFFF",
    marginLeft: "21px"
  }),
};

const customStyles2= {
  control: (provided, state) => ({
    ...provided,
    background: "#2E3648",
    borderColor: "red",
    minHeight: 46,
    height: 46,
    width: "auto",
    marginLeft: "28px",
    border: "none",
    color: "#fff !important",
    boxShadow: state.isFocused ? null : null,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#FFFFFF",
    marginLeft: "21px",
    fontSize: "14px",
    fontWeight: 400,
  }),

  option: (base, state) => ({
    ...base,
    fontSize: "14px",
    fontWeight: 400,
    color: "#9ba2ce",
    backgroundColor: state.isSelected ? "#32394A" : "#2A3143",
    ":hover": {
      backgroundColor: state.isSelected ? "#2A3143" : "#32394A",
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    height: 46,
    color: "#fff",
    marginLeft: "21px",
    lineHeight: state.isFocused ? 0 : 0,
  }),
  indicatorSeparator: (state) => ({
    // display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    color: "#fff",
    // height: 46,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    transition: "0.5s",
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),

  placeholder: (provided, state) => ({
    color: "#fff",
    marginLeft: "21px",
    fontSize: "14px",
    fontWeight: 400,
  }),
};


export const StaticDropdownQuestion = (props) => {
  const [Value1, setValue1] = useState();
  const [Value2, setValue2] = useState();


  const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
  ) => {
    return (
      <components.DropdownIndicator {...props}></components.DropdownIndicator>
    );
  };

  useEffect(() => {
    setValue1(props.value1);
  }, [props.value1]);
  useEffect(() => {
    setValue2(props.value2);
  }, [props.value2]);

  return (
    <>
      <DropdownWrapper>
        <LabelDropdown> Question Type </LabelDropdown>
        <Select
          components={{ DropdownIndicator }}
          captureMenuScroll={false}
          menuPlacement="bottom"
          onChange={props.handleChangeQuestionType}
          options={props.options1}
          styles={customStyles1}
          value={Value1}
          menuPortalTarget={document.getElementById("root")}
          hideSelectedOptions={false}
          classNamePrefix="select"
          maxMenuHeight={200}
        />
      </DropdownWrapper>

      <DropdownWrapper>
      <LabelDropdown section> Section </LabelDropdown>
        <Select
          components={{ DropdownIndicator }}
          // captureMenuScroll={false}
          menuPlacement="bottom"
          onChange={props.handleChangeSection}
          options={props.options2}
          styles={customStyles2}
          value={Value2}
          menuPortalTarget={document.getElementById("root")}
          // hideSelectedOptions={false}  
          classNamePrefix="select"
          maxMenuHeight={200}
        />
      </DropdownWrapper>
    </>
  );
}



export const StaticDropdownTenure = (props) => {
  const [Value1, setValue1] = useState();
  const [Value2, setValue2] = useState();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#2E3648",
      borderColor: "#9ba2ce",
      minHeight: 46,
      height: 46,
      width: "auto",
      margin: "0px 5px",
      border: "none",
      boxShadow: state.isFocused ? null : null,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#9ba2ce",
    }),

    option: (base, state) => ({
      ...base,
      color: "#9ba2ce",
      backgroundColor: state.isSelected ? "#32394A" : "#2A3143",
      ":hover": {
        backgroundColor: state.isSelected ? "#2A3143" : "#32394A",
      },
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: 46,
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      // width: "50%",
      height: 46,
      color: "#9ba2ce",
      lineHeight: state.isFocused ? 0 : 0,
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 46,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && "rotate(180deg)",
      transition: "0.5s",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),

    placeholder: (provided, state) => ({
      color: "#9ba2ce",
    }),
  };

  const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
  ) => {
    return (
      <components.DropdownIndicator {...props}></components.DropdownIndicator>
    );
  };

  useEffect(() => {
    setValue1(props.value1);
  }, [props.value1]);
  useEffect(() => {
    setValue2(props.value2);
  }, [props.value2]);

  return (
    <>
      <DropdownWrapper>
        <Select
          components={{ DropdownIndicator }}
          captureMenuScroll={false}
          menuPlacement="bottom"
          onChange={props.handleChangeDropdownDates}
          options={props.options2}
          styles={customStyles}
          value={Value2}
          menuPortalTarget={document.getElementById("root")}
          hideSelectedOptions={false}
          classNamePrefix="select"
          maxMenuHeight={200}
        />
      </DropdownWrapper>
      <DropdownWrapper>
        <Select
          components={{ DropdownIndicator }}
          captureMenuScroll={false}
          menuPlacement="bottom"
          onChange={props.handleChangeDropdownDatesNumber}
          options={props.options1}
          styles={customStyles}
          value={Value1}
          menuPortalTarget={document.getElementById("root")}
          hideSelectedOptions={false}
          classNamePrefix="select"
          maxMenuHeight={200}
        />
      </DropdownWrapper>
    </>
  );
};
