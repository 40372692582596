import React from 'react';
import { Bar } from 'react-chartjs-2';

import "./StatisticChart.scss"

const StatisticChart = (props) => { 
    let labelColor1 = "#9BA2CE"
    let labelColor2 = "#F1B44C"
    let labelColor3 = "#34C38F"
    const options = {
        scales: {
             xAxes: [{
                 stacked: true,
                 barPercentage: 0.2,
                gridLines: { display: false },
                ticks: {
                    fontSize: 12, 
                    fontColor: "#9BA4C7",
                }
              
             }],
             yAxes: [{
                 stacked: true,
                 angleLines: {
                    color: '#6A7287' // lines radiating from the center
                },
                gridLines: {
                    color: '#6A7287',
                    borderWidth: "3px",
                },
                ticks: {
                    fontSize: 12, 
                    fontColor: "#9BA4C7",
                }
             }]
         },
         legend: {
            position: 'bottom',
            labels: {
              usePointStyle: false,
              fontColor: "#9BA4C7",
            }
        },
        maintainAspectRatio: false,
		responsive: true,
          
     }
 
     let data ={ 
       datasets:[{
         label: 'Points 1',
           data :[5, 5, 5, 5, 6 ,7, 8 ,4 ,5, 6, 8, 7],
           backgroundColor: labelColor1,
           borderWidth: 1,
           color: "#9BA4C7"
         },
         {
           label: 'Points 2',
           data:  [3, 4, 3, 4, 3 ,4, 3,4,3, 4 , 4, 3],
           backgroundColor: labelColor2,
           borderWidth: 1,
         },
         {
            label: 'Points 3 ',
            data:  [2, 1, 2, 1, 2,1, 2 ,1 ,2, 1 , 2, 3],
            backgroundColor: labelColor3,
            borderWidth: 1,
          
          },
        ],
       labels:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct', 'Nov', 'Dec'],

     }
    return (

        <div className="StatisticBarChart">
            <div className="header_chart">
                <div className="header_chart_title">
                    <p> Points credit statistics</p>
                </div>

                <div className="header_chart_dates">
                    <button className="btn_chart_dates"> Weekly </button>
                    <button className="btn_chart_dates"> Monthly </button>
                    <button className="btn_chart_dates Active"> Yearly </button>
                </div>

            </div>
          
            <Bar
                data={data}
                width={400}
                height={150}
                options={options}
            />
        </div>
    )
}

export default StatisticChart;