export const undertimeConstants = {
  GETALL_REQUEST: 'UNDERTIME_GETALL_REQUEST',
  GETALL_SUCCESS: 'UNDERTIME_GETALL_SUCCESS',
  GETALL_FAILURE: 'UNDERTIME_GETALL_FAILURE',

  GET_REQUEST: 'UNDERTIME_GET_REQUEST',
  GET_SUCCESS: 'UNDERTIME_GET_SUCCESS',
  GET_FAILURE: 'UNDERTIME_GET_FAILURE',

  GETUSER_REQUEST: 'UNDERTIME_GETUSER_REQUEST',
  GETUSER_SUCCESS: 'UNDERTIME_GETUSER_SUCCESS',
  GETUSER_FAILURE: 'UNDERTIME_GETUSER_FAILURE',

  CREATE_REQUEST: 'UNDERTIME_CREATE_REQUEST',
  CREATE_SUCCESS: 'UNDERTIME_CREATE_SUCCESS',
  CREATE_FAILURE: 'UNDERTIME_CREATE_FAILURE',

  UPDATE_REQUEST: 'UNDERTIMES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UNDERTIMES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UNDERTIMES_UPDATE_FAILURE',

  APPROVE_REQUEST: 'UNDERTIMES_APPROVE_REQUEST',
  APPROVE_SUCCESS: 'UNDERTIMES_APPROVE_SUCCESS',
  APPROVE_FAILURE: 'UNDERTIMES_APPROVE_FAILURE',
  
  HOLD_REQUEST: 'UNDERTIMES_HOLD_REQUEST',
  HOLD_SUCCESS: 'UNDERTIMES_HOLD_SUCCESS',
  HOLD_FAILURE: 'UNDERTIMES_HOLD_FAILURE',
  
  DECLINE_REQUEST: 'UNDERTIMES_REJECT_REQUEST',
  DECLINE_SUCCESS: 'UNDERTIMES_REJECT_SUCCESS',
  DECLINE_FAILURE: 'UNDERTIMES_REJECT_FAILURE',

};

