export const overtimeConstants = {
    GETALL_REQUEST: 'OVERTIME_GETALL_REQUEST',
    GETALL_SUCCESS: 'OVERTIME_GETALL_SUCCESS',
    GETALL_FAILURE: 'OVERTIME_GETALL_FAILURE',

    GET_REQUEST: 'OVERTIME_GET_REQUEST',
    GET_SUCCESS: 'OVERTIME_GET_SUCCESS',
    GET_FAILURE: 'OVERTIME_GET_FAILURE',

    GETUSER_REQUEST: 'OVERTIME_GETUSER_REQUEST',
    GETUSER_SUCCESS: 'OVERTIME_GETUSER_SUCCESS',
    GETUSER_FAILURE: 'OVERTIME_GETUSER_FAILURE',

    CREATE_REQUEST: 'OVETIME_CREATE_REQUEST',
    CREATE_SUCCESS: 'OVETIME_CREATE_SUCCESS',
    CREATE_FAILURE: 'OVETIME_CREATE_FAILURE',

    UPDATE_REQUEST: 'OVERTIMES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'OVERTIMES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'OVERTIMES_UPDATE_FAILURE',

    APPROVE_REQUEST: 'OVERTIMES_APPROVE_REQUEST',
    APPROVE_SUCCESS: 'OVERTIMES_APPROVE_SUCCESS',
    APPROVE_FAILURE: 'OVERTIMES_APPROVE_FAILURE',
    
    HOLD_REQUEST: 'OVERTIMES_HOLD_REQUEST',
    HOLD_SUCCESS: 'OVERTIMES_HOLD_SUCCESS',
    HOLD_FAILURE: 'OVERTIMES_HOLD_FAILURE',
    
    DECLINE_REQUEST: 'OVERTIMES_REJECT_REQUEST',
    DECLINE_SUCCESS: 'OVERTIMES_REJECT_SUCCESS',
    DECLINE_FAILURE: 'OVERTIMES_REJECT_FAILURE',

};

