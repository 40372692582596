import { energyConstants } from '../constants/energy';

export function energy(state = {}, action) {
    switch (action.type) {
        case energyConstants.CREATE_REQUEST:
            return { energy: true };
        case energyConstants.CREATE_SUCCESS:
            return { energy: false };
        case energyConstants.CREATE_FAILURE:
            return {};
        case energyConstants.UPDATE_REQUEST:
            return {loading: true};
        case energyConstants.UPDATE_SUCCESS:
            return {success: true};
        case energyConstants.UPDATE_FAILURE:
            return {error: action.error};
        case energyConstants.GETALL_REQUEST:
            return {loading: true};
        case energyConstants.GETALL_SUCCESS:
            return {energy: action.energy.success};
        case energyConstants.GETALL_FAILURE:
            return {error: action.error};       
        default:
            return state
    }
  }