import { feedbackConstants } from '../constants/feedback';

let initialState = {
  items: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function feedback(state = initialState, action) {
  switch (action.type) {
    //get feedback
    case feedbackConstants.GETALL_FEEDBACK_REQUEST:
      return {
        loading: true
      };
    case feedbackConstants.GETALL_FEEDBACK__SUCCESS:
      return {
        loading: false,
        items: action.feedback
      };
    case feedbackConstants.GETALL_FEEDBACK__FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}