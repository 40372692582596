import { challengeConstants } from '../constants/challenge';

export function challenges(state = {}, action) {
  switch (action.type) {
    case challengeConstants.CREATE_REQUEST:
      return { creating: true };
    case challengeConstants.CREATE_SUCCESS:
      return {};
    case challengeConstants.CREATE_FAILURE:
      return {};

    case challengeConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case challengeConstants.GETALL_SUCCESS:
      return {
        loading: false,
        items: action.challenges.items,
        totalPerPage:  action.challenges.total,
        perPage: action.challenges.pages,
        currentPage: action.challenges.page,
      };
    case challengeConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

    //get challenge by id
    case challengeConstants.GET_REQUEST:
      return {
        saving: true
      };
    case challengeConstants.GET_SUCCESS:
      return {
        challenge: action.challenge
      };
    case challengeConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    
    //update challenge
    case challengeConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case challengeConstants.UPDATE_SUCCESS:
      return {
        success: true
      };
    case challengeConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };


    case challengeConstants.DELETE_REQUEST:
      // add 'deleting:true' property to attribute being deleted
      return {
        ...state,
        items: state?.items?.map(challenge =>
            challenge.id === action.id
            ? { ...challenge, deleting: true }
            : challenge
        )
      };
    case challengeConstants.DELETE_SUCCESS:
      // remove deleted attribute from state
      return {
        items: state?.items?.filter(challenge => challenge.id !== action.id)
      };
    case challengeConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to attribute 
      return {
        ...state,
        items: state?.items?.map(challenge => {
          if (challenge.id === action.id) {
            // make copy of attribute without 'deleting:true' property
            const { deleting, ...challengeCopy } = challenge;
            // return copy of attribute with 'deleteError:[error]' property
            return { ...challengeCopy, deleteError: action.error };
          }

          return challenges;
        })
      };
    default:
      return state
  }
}