import { userOvertimeConstants } from '../constants/userOvertime';


  let initialState = {
    isValid: null,
  }

export function userOvertime( state = {initialState}, action ){
    switch(action.type){
        case userOvertimeConstants.POST_OVERTIME_REQUEST:
          return {
            ...state,
            isValid: null,
            userOvertime: false,
          };
        case userOvertimeConstants.POST_OVERTIME_REQUEST_SUCCESS:
          return {
            ...state,
            isValid: true,
            userOvertime: true,
          }
        case userOvertimeConstants.POST_OVERTIME_REQUEST_FAILED:
            return {
              ...state,
              isValid: true,
              userOvertime: false
            }
        default:
        return state
    }
}