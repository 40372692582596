import { missionConstants } from '../constants/mission';

export function missions(state = {}, action) {
  switch (action.type) {
    //get mission
    case missionConstants.GETALL_MISSION_REQUEST:
      return {
        loading: true
      };
    case missionConstants.GETALL_MISSION_SUCCESS:
      return {
        items: action.missions
      };
    case missionConstants.GETALL_MISSION_FAILURE:
      return { 
        error: action.error
      };

    //post user mission
    case missionConstants.START_REQUEST:
      return { saving: true };
    case missionConstants.START_SUCCESS:
      return {};
    case missionConstants.START_FAILURE:
      return {error: action.error};
    default:
      return state
  }
}