import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from "react-redux";

import { expActions } from '../_core/actions/exp';
import { UserDashboardContext } from "../UserDashboard/UserDashboard";
import Everyone from './Everyone';
import Teams from './Teams';
import TeamDetails from './TeamDetails';
//import LeaderBoardsBtn from '../LeaderBoards/LeaderBoardsBtn';
import Footer from '../Footer/Mainfooter.js';
import { Route } from 'react-router-dom';
import ProfileWidget from '../ProfileWidget/ProfileWidget';
import TimekeepingWidget from '../TimekeepingWidget/TimekeepingWidget';
import AttributesWidget from '../AttributesWidget/AttributesWidget';
import TeamWidget from '../TeamWidget/TeamWidget';
import WorkstatusWidget from '../WorkStatusWidget/WorkstatusWidget';

import './People.scss';

const People = (props) => {
	const dispatch = useDispatch();
	const secret = JSON.parse(localStorage.getItem("secretCredentials"));

	React.useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		const unknown = secret ? secret.details[0].user_id : user.user_id

		dispatch(expActions.getExp(unknown));

	}, [dispatch]);// eslint-disable-line 

	const device = React.useContext(UserDashboardContext).device;
	const auth = props.authentication;

	return (
		<div className={device === 'desktop' ? "row people desktop-footer" : "row people"}>
			<div
				className={cc([
					'sub-panel',
					'col',
					's12',
					'l4',
					device
				])}
			>

				{auth &&
					<div>
						<ProfileWidget />
						<WorkstatusWidget
							userId={secret ? secret._id : props.authentication.user?.user?._id}
						/>
						<TimekeepingWidget
							public={false}
							username={secret ? secret.praggerUsername : props.authentication.user?.user?.praggerUsername}
							userteam={secret ? secret.team : props.authentication?.user?.user?.team}
							user={secret ? secret._id : props.authentication?.user}
						/>
						<AttributesWidget
							userId={secret ? secret._id : props.authentication.user?.user?._id}
						/>
						<TeamWidget
							team={secret ? secret.team : props.authentication?.user?.user?.team}
							userId={secret ? secret._id : props.authentication.user?.user?._id}
						/>
					</div>
				}
				{device === 'desktop' &&
					<div><Footer /></div>
				}
			</div>
			<div
				className={cc([
					'main-panel',
					'col',
					's12',
					'l8',
					device
				])}
			>
				<Route exact path="/people" component={Everyone}/>
				<Route exact path="/people/teams" component={Teams} />
				<Route path={`/people/team/:teamId`} component={TeamDetails}/>

			</div>
			{device === 'tablet' &&
				<div><Footer /></div>
			}
		</div>
	)
};

const mapStateToProps = (state) => ({
	isLoading: (
		state.exps.loading
	) === true,
	exp: state.exps.exp?.[0]?.exp || 0,
	authentication: state.authentication,
});

export default React.memo(connect(mapStateToProps)(People));
