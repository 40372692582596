import React from 'react';
import { isEmpty } from 'lodash';
import RadarChart from 'react-svg-radar-chart';
import { useDispatch, connect } from "react-redux";

import { attributeActions } from '../_core/actions/attribute';
import { userAttributeActions } from '../_core/actions/userAttribute';

import './AttributesWidget.scss';

const AttributesWidget = (props) => {
	const dispatch = useDispatch();
	// Transform array into object with attribute id as its key
	const captions = React.useMemo(() => (
		props.attributes.filter(
			attribute => props.userAttributes
			.map(userAttribute => userAttribute.attribute_id)
			.includes(attribute._id)
		)
		.reduce((accumulator, attribute) => ({
			...accumulator,
			[attribute._id]: attribute.attrName
		}), {})
	), [props]);

	// Transform array into object with attribute id as its key, then get value by dividing
	const data = React.useMemo(() => ([{
		data: (
			props.userAttributes.reduce((accumulator, userAttribute) => ({
				...accumulator,
				[userAttribute.attribute_id]: parseInt(userAttribute.value, 10) / parseInt(userAttribute.max, 10)
			}), {})
		),
		meta: { color: '#427701' }
	}]), [props]);

	React.useEffect(() => {
		if(!props.userId) {
			return;
		}

		dispatch(userAttributeActions.getUserAttributes(props.userId));
		dispatch(attributeActions.getAll(1, 100000));
	}, [dispatch, props.userId]);

	return (
		<div className="attributes-widget">
			<div>
				<h6>Attributes</h6>
			</div>
			<div className="card z-depth-0">
				{
					(isEmpty(captions) || isEmpty(data)) ?
					<p>User has no attributes</p> :
					<RadarChart
						captions={captions}
						data={data}
						options={{
							captionProps: (props) => ({
								...props,
								fontSize: 12,
								fontWeight: 600,
								textAnchor: "middle"
							}),
							zoomDistance: 1.3
						}}
						size={200}
					/>
				}
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	loading: (
		state.attributes.loading ||
		state.userAttributes.loading
	) === true,
	attributes: state.attributes.attributes?.items || [],
	userAttributes: state.userAttributes.userAttributes || [],
});

export default connect(mapStateToProps)(React.memo(AttributesWidget));