import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import BodyBackgroundColor from 'react-body-backgroundcolor'


import rocket_clouds from '../_templates/homepage-bg/rocket_clouds.png'

import logo from '../_templates/homepage-bg/appicon-web.png'

import { SocialIcon } from 'react-social-icons';

import './Homepage.sass'

class NewHomepage extends React.Component {

    render() {
        return (
          <div className="homepage">
      			<BodyBackgroundColor backgroundColor='#fff'>
            </BodyBackgroundColor>
            <div className="container-fluid" id="backgroun-one">
            <div className="container-fluid" id="backgroun-two">
            <div className="container-fluid" id="backgroun-three">
            <div className="container-fluid">
              <img src={logo} className="logo" alt="logos"/>
              <h5 className="align-left">Welcome To</h5>
              <h5 className="align-left">Pragmanila</h5>
              <ul className="align-left">
                <li>Commend Your Teammates.</li>
                <li>Get Rewarded by task</li>
                <li>Set forth on a Quest.</li>
              </ul>
              <Link to="/profile" className="cta-btn">Let's Get Started</Link>
              <br/><br/>
              <img src={rocket_clouds} className="rockets" alt="rockets"/> 

            <div className="container social-icons center">
                <ul className="social-link">
                     <li><SocialIcon network="facebook" style={{ height: 35, width: 35, color: 'yellow'}} url="#!" className="waves-effect waves-light btn-facebook"/></li>
                     <li className="twitter"><SocialIcon network="twitter" style={{ height: 35, width: 35 }} url="#!" className="waves-effect waves-light btn-twitter"/></li>
                     <li><SocialIcon network="google" style={{ height: 35, width: 35 }} url="#!" className="waves-effect waves-light btn-google"/></li>
                </ul>
               <span className="copyright">&copy; 2018 PragmaHR. All rights reserved.</span>
              </div>
            </div>
            </div>
            </div>
            </div>
        </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return {
        loggingIn,
        alert
    };
}

const connectedNewHomepage = connect(mapStateToProps)(NewHomepage);
export { connectedNewHomepage as NewHomepage }; 