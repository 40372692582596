import React, { useEffect } from "react";

const acceptedImgFile = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';

export const DragDropImage = (props) => {

    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      };

  // Just some styles
    const styles = {
        preview: {
            display: "flex",
            flexDirection: "column",
        },
        image: { maxWidth: "100%", maxHeight: "fit-content" },
        delete: {
        cursor: "pointer",
        padding: 15,
        background: "red",
        color: "white",
        border: "none",
        },
    };

    const thumbs = props.selectedImage.map(file => (
        <div key={file.name}>
          <div style={styles.preview}>
            <img
              accept={acceptedImgFile}
              src={file.preview}
              style={styles.image}
              alt="Thumb"
            />
            <button onClick={props.removeImage} style={styles.delete}>
                Remove This Image
            </button>
          </div>
        </div>
      ));

      useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        props.selectedImage.forEach(file => URL.revokeObjectURL(file.preview));
      }, [props.selectedImage]);


  return (
    <>
      <div className={props.className}>
        <section className="container">
            <aside style={thumbsContainer}>
                {
                    props.selectedImage.length !== 0  ?
                        thumbs 
                    :
                    <div className={props.remove !== true && props.isAdd !==true && props.existingImage !== null && props.existingImage !== ''  ? '':'hide'}>
                        <div style={{display:'flex',flexDirection: 'column'}}>
                            <img
                                accept={acceptedImgFile}
                                src={props.existingImage}
                                style={styles.image}
                                alt="Thumb"
                            />
                        <button onClick={props.removeImage} style={styles.delete}>
                            Remove This Image
                        </button>
                        </div>
                    </div>
                }

            </aside>
        </section>
      </div>
    </>
  );
};
