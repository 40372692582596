import React from 'react';
import { connect } from 'react-redux';
import no_permission from './img/no_permission.png';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import NoPermissionButton from './NoPermissionButton';
// import './error.sass'
import './noPermission.sass'
class NoPermission extends React.Component {

    render() {
        return (
        	<BodyBackgroundColor backgroundColor='#8D006B'>
        	<div className="bgc-notfound">
				<div className="container center main">
					<img className="img-responsive" src={no_permission} alt="404"/>
				    <h2 className=" notfound-text-title">You do not have permission to access this page.</h2>
				    <p className=" notfound-text-sub">Contact your administrator if you need help.</p>
				    <NoPermissionButton/>
				</div>
			</div>
			</BodyBackgroundColor>
        );
    }
}

function mapStateToProps(state) {

}

const noPermission = connect(mapStateToProps)(NoPermission);
export { noPermission as NoPermission };