import { energyConstants } from '../constants/energy';
import { energyService } from '../services/energy';
import { alertActions } from './alert';
// import { history } from '../helpers/history';

export const energyActions = {
    create,
    update,
    getAll
};

function create(energy) {
    return dispatch => {
        dispatch(request(energy));

        energyService.create(energy)
            .then(
                energy => { 
                    dispatch(success());
                    dispatch(alertActions.success('New Energy has been created'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };

    function request(energy) { return { type: energyConstants.CREATE_REQUEST, energy } }
    function success(energy) { return { type: energyConstants.CREATE_REQUEST, energy } }
    function failure(error) { return { type: energyConstants.CREATE_REQUEST, error } }
}


function update(energy) {
    return dispatch => {
        dispatch(request(energy));
        energyService.update(energy)
            .then(
                energy => {
                    dispatch(success(energy));
                    dispatch(alertActions.success('Energy successfully updated.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };   

    function request() { return { type: energyConstants.UPDATE_REQUEST }}
    function success(energy) { return { type: energyConstants.UPDATE_SUCCESS, energy } }
    function failure(error) { return { type: energyConstants.UPDATE_FAILURE, error} }
}

function getAll(id) {
    return dispatch => {
        dispatch(request(id));

        energyService.getAll(id)
            .then(
                energy => {
                    dispatch(success(energy));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: energyConstants.GETALL_REQUEST } }
    function success(energy) { return { type: energyConstants.GETALL_SUCCESS, energy } }
    function failure(error) { return { type: energyConstants.GETALL_FAILURE, error } }
}