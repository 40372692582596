import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import cc from "classcat";
import styled, { css } from "styled-components";
import { useParams } from "react-router-dom";
import { userActions } from "../../../_core/actions/user";
import { userService } from "../../../_core/services/user";
import { overtimeActions } from "../../../_core/actions/overtime";
import { essActions } from "../../../_core/actions/ess";
import { leaveActions } from "../../../_core/actions/leave";
import { leaveCreditActions } from "../../../_core/actions/leaveCredit";
import { alertActions } from "../../../_core/actions/alert";
//import { notifActions } from "../../../_core/actions/notification";
import "./LeavePageDark.scss";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { When } from "react-if";
import PreviewLeaveUserWidget from "../../AdminWidget/PreviewUserDetailsWidget/PreviewLeaveUserWidget";
import {
  ContentWrapper,
  LabelCustom,
} from "../../Components/PageWrapper/ContentWrapper";
import { requestStatus } from "../../../_core/helpers/requestStatus";
import avatar from "../../../_templates/man.png";
import { ConfirmationModal } from "../../Components/AdminModals/RequestPopup";
import download from '../../../_templates/dark/download.png';

import Swal from 'sweetalert2';
import FileViewer from 'react-file-viewer';
// import { CustomErrorComponent } from 'custom-error';
import close from '../../../_templates/dark/off_outline_close.png';
import fileIcon from '../../../_templates/dark/file_blank_outline.png';
import { commentActions } from "../../../_core/actions/comment";
import { commentService } from "../../../_core/services/comment";


const customStyles = {
    content: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }
  };
  

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

const mapStateToProps = ({ overtimes, leaves, leaveCredits, comments }) => ({
  overtimes,
  leaves,
  leaveCredits,
  comments
});

const ReviewLeavePage = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    const [update, setUpdate] = React.useState(false);
    const [count, setRender] = React.useState(true);
    const [UserDetailsView, setUserDetailsView] = React.useState([]);
    const [isDisplayUserDetails, setIsDisplayUserDetails] = React.useState(false); // eslint-disable-line
    const countRef = React.useRef(count);
    const [isAdd, setIsAdd] = React.useState(true); // eslint-disable-line
    const [leaveRequest, setLeaveRequest] = React.useState({});
    const [startDate2, setStartDate2] = React.useState("");
    const [endDate2, setEndDate2] = React.useState("");
    const [userManager, setManager] = React.useState([]);

    const [showFilePreview, setFilePreview] = React.useState(false);
    const [FileUrl, setFileUrl] = React.useState('');
    const [FileType, setFileType] = React.useState('');
    const [state, setState] = React.useState({
            type: FileType,
            path: FileUrl
        });

    const [showRemarkModal, setRemarkModal] = React.useState(false);
    let approverDetails = {
        remarks: "",
        notedBy: "",
        notedAt: "",
        status: "",
    };
    const [approversNote, setApproversNote] = React.useState(approverDetails);
    const [comment, setCommment] = React.useState([]);
    const [reply, setReply] = React.useState([]);

    countRef.current = count;

    const { requestId, page } = useParams();

    let admin = JSON.parse(localStorage.getItem("userCredentials"));

    useEffect(() => {
        let oid = requestId;
        dispatch(overtimeActions.getById(oid));
        dispatch(leaveActions.getById(oid));
        dispatch(userActions.getAll(1, 100000));
        dispatch(leaveCreditActions.getAll());
        dispatch(commentActions.getByOrigin(oid, 1, 999))
        setIsDisplayUserDetails(true);
        setIsAdd(false); // Set update
        alertTimeOut();
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (requestId) {
        let userList = props.leaves.success ?? [];
            if (userList) {
                userList.forEach((item) => {
                    setLeaveRequest(item);
                    setUserDetailsView(item.users);
                    setStartDate2(new Date(item.startedAt));
                    setEndDate2(new Date(item.endedAt));
                });
            }
        }
        //eslint-disable-next-line
    }, [props.leaves, requestId, props.comments]);

    useEffect(() => {
        if (state.path !== FileUrl) {
            setState({
                type: FileType,
                path: FileUrl
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        commentService.getByOrigin(requestId, 1, 999).then(res=>{
            if(res.items?.length !== 0){
                setCommment(res.items)
                commentService.getByOrigin(res.items[0]?._id, 1, 999).then(res=>{
                    if(res.items){
    
                        if(res.items.length !== 0){
                            setReply(res.items)
                        }
    
                    }
                }).catch(error=>{console.log(error)});
    
            }
        }).catch(error=>{console.log(error)});
      }, [props, requestId]);



    const alertTimeOut = () => {
        setTimeout(() => {
        setRender(countRef.current);
        props.dispatch(alertActions.clear());
        }, 3000);
    };

    const goBack = () => {
        props.history.goBack();
    };

    const goBackPath = `/admin/leave-requests?page=${page}`;

    const handleChangeStart2 = (date) => {
        setStartDate2(date);
        setUpdate(true);
        setLeaveRequest({ ...leaveRequest, startedAt: moment(date).toISOString() });
    };

    const handleChangeEnd2 = (date) => {
        setEndDate2(date);
        setUpdate(true);
        setLeaveRequest({ ...leaveRequest, endedAt: moment(date).toISOString() });
    };

    const isDatePast = (dateTEst)  => {
      const today = new Date();
    
      today.setHours(0, 0, 0, 0);
    
      return dateTEst < today;
    }

    const delStartDate2 = (date, id) => {
      let newStartDate = moment(stateDateRange2[0]).format();
      setStartDate2(newStartDate);
      setLeaveRequest({ ...leaveRequest, startedAt: newStartDate });
      setUpdate(true);
    };

    const WEEKEND = [moment().day("Saturday").weekday(), moment().day("Sunday").weekday()]

    const addBusinessDays1 = (date, daysToAdd) => {
      var daysAdded = 0,
        momentDate = moment(new Date(date));
      while (daysAdded < daysToAdd) {
        momentDate = momentDate.subtract(1, 'days');
        if (!WEEKEND.includes(momentDate.weekday())) {
          daysAdded++
        }
      }
    
      return momentDate;
    }

    const delEndDate2 = (date, id) => {
      let newEndDate = addBusinessDays1(date, 1).toISOString()

      setEndDate2(newEndDate);
      setLeaveRequest({ ...leaveRequest, endedAt: newEndDate });
      setUpdate(true);
    };

    const descriptionChange2 = (e) => {
        setLeaveRequest({ ...leaveRequest, description: e.target.value });
        setUpdate(true);
    };

    const openFilePreview = () => {
        setFilePreview(true);
    };

    const openRemarkModal = (status) => {
        setApproversNote({
        ...approverDetails,
        status: status,
        });
        setRemarkModal(true);
    };

    const onRemarksChange = (e) => {
        let admin = JSON.parse(localStorage.getItem("userCredentials"));
        setApproversNote({
        ...approversNote,
        [e.target.name]: e.target.value,
        notedAt: moment().format(),
        notedBy: admin.user._id,
        });
    };

    const handleSubmit = (id) => {
        const requestUser = [
        leaveRequest.users[0].firstName,
        leaveRequest.users[0].lastName,
        ].join(" ");
        const referenceCode = leaveRequest.refCode;
        const requestDates = [leaveRequest.startedAt, leaveRequest.endedAt];
        const requestRemarks = approversNote.remarks;

        if (
        !leaveRequest.startedAt ||
        !leaveRequest.user_id ||
        !leaveRequest.endedAt
        ) {
        return;
        }

        ConfirmationModal( requestUser, referenceCode, requestDates, requestRemarks, "Remarks" ).then((result) => {
        if (result.value) {
            let updateRequest = {
            _id: id,
            subject: leaveRequest.subject || "Leave Request",
            type: leaveRequest.type,
            subtype: leaveRequest.subtype,
            description: leaveRequest.description,
            };

            switch (approversNote.status) {
            case requestStatus.onHold:
                updateRequest = {
                ...updateRequest,
                status: approversNote.status,
                notedAt: approversNote.notedAt,
                notedBy: approversNote.notedBy,
                notedRemark: approversNote.remarks,
                };
                break;
            case requestStatus.approved:
                updateRequest = {
                ...updateRequest,
                status: approversNote.status,
                approvedAt: approversNote.notedAt,
                approvedBy: approversNote.notedBy,
                approvalRemark: approversNote.remarks,
                };
                break;
            case requestStatus.rejected:
                updateRequest = {
                ...updateRequest,
                status: approversNote.status,
                rejectedAt: approversNote.notedAt,
                rejectedBy: approversNote.notedBy,
                rejectedRemark: approversNote.remarks,
                };
                break;
            default:
                updateRequest = {
                ...updateRequest,
                startedAt: startDate2 ? startDate2 : leaveRequest.startedAt,
                endedAt: endDate2 ? endDate2 : leaveRequest.endedAt,
                };
                break;
            }

            // const updateNotif = {
            // type: "admin",
            // title: `Leave Request ${
            //     approversNote.status === requestStatus.new
            //     ? "Updated"
            //     : approversNote.status.charAt(0).toUpperCase() +
            //         approversNote.status.slice(1, approversNote.status.length)
            // }`,
            // content: `Your leave request was ${
            //     approversNote.status === requestStatus.new
            //     ? "updated"
            //     : approversNote.status
            // }.`,
            // user_id: leaveRequest.user_id,
            // recurring: "true",
            // };

            if (updateRequest.status === requestStatus.approved) {
            dispatch(essActions.approve(updateRequest, goBackPath));
            } else if (updateRequest.status === requestStatus.onHold) {
            dispatch(essActions.hold(updateRequest, goBackPath));
            } else if (updateRequest.status === requestStatus.rejected) {
            dispatch(essActions.reject(updateRequest, goBackPath));
            } else {
            dispatch(essActions.update(updateRequest, goBackPath));
            }

            setApproversNote(approverDetails);
            setRemarkModal(false);
            setRender(false);
            //dispatch(notifActions.createNotif(updateNotif));
        }
        });
    };




    const getDateRange2 = () => {
        let dateList = [];

        let startDate = moment(leaveRequest.startedAt);
        let endedAt = moment(leaveRequest.endedAt);
        // eslint-disable-next-line
        let interval = 0;
        let datediff = startDate.diff(endedAt, "days");

        while (datediff + 2 <= 0) {
        startDate.add(1, "days").format("dddd, MMMM DD YYYY");
        dateList.push(startDate.format("dddd, MMMM DD YYYY"));
        interval++;
        datediff++;
        }
        return dateList;
    };

    const dateRange2 = () => {
        let dateRange = [];

        let startDate = moment(leaveRequest.startedAt);
        let endedAt = moment(leaveRequest.endedAt);
        // eslint-disable-next-line
        let interval = 0;
        let datediff = startDate.diff(endedAt, "days");

        while (datediff + 2 <= 0) {
        let startTest = startDate.add(1, "days").format("dddd, MMMM DD YYYY");

        if(!startTest.includes('Sunday') && !startTest.includes('Saturday')) {
            dateRange.push(startDate.format("dddd, MMMM DD YYYY"));
        }
        interval++;
        datediff++;
        }
        return dateRange;
    };

    let dateRanges2 = getDateRange2();
    let stateDateRange2 = dateRange2();

    const myDays = dateRanges2.map(daysss  => (
        daysss.split(',')[0]
    ));

    // const hasWeekEnd = myDays.includes("Sunday");
    const hasWeekEnd = myDays.includes("Saturday");
    const hasWeekEnd2 = myDays.includes("Sunday");

    const cantRemove = () => {
        Swal.fire({
        title: '<p class="swal-subtitle-dark">This cant be remove</p>',
        type: 'warning',
        showCancelButton: true,
        showConfirmButton: false,
        confirmButtonColor: '#24DC98',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Ok',
        background: '#2E3549',
        })
    }


    const ApproverDetails = ({status, date, manager }) => {
        if (userManager.length === 0) {
        const getManager = async () => {
            try {
                if(manager){
                    const user = await userService.getById(manager);
                    setManager(user.user[0].details);
                }

            } catch (error) {
                console.error(error);
            }
        };
        getManager();
        }
        if ( userManager.length !== 0) {
        return (
            <div>
            <div className="request-approver-content">
                <div>
                <p>Approver : </p>
                <div>
                    <img
                    src={userManager[0]?.avatar}
                    alt="holder-img"
                    onError={(e) =>  (e.target.src = avatar)}
                    />
                    <p>
                    {[userManager[0]?.firstName, userManager[0]?.lastName].join(
                            " "
                        )}
                    </p>
                </div>
                </div>
                <div>
                    <p>Role : Manager</p>
                </div>
            </div>
            
            {leaveRequest.noter_details?.length !== 0 ? (
                <div>
                <div className="request-approver-content">
                    <div>
                    <p>Held by :</p>
                    {
                        leaveRequest.noter_details?.map(detail => (
                        <div key={detail._id}>
                            <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                            <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                        </div>
                        ))
                    }
                    </div>
                    <div>
                        <p>{`Date put on hold : ${moment(leaveRequest.notedAt).format("MMMM DD YYYY")}`}</p>
                    </div>
                </div>
                <div className="floating-label">
                    <textarea
                    className="floating-input floating-textarea hr-textarea"
                    value={leaveRequest.notedRemark}
                    name="description"
                    cols="30"
                    placeholder=" "
                    style={{ resize: "none" }}
                    row="2"
                    readOnly
                    disabled
                    />
                    <label className="floating-text">Remarks</label>
                </div>
                </div>
            ) :  ''}

            {leaveRequest.approver_details?.length !== 0 ? (
                <div>
                <div className="request-approver-content">
                    <div>
                        <p>Approved by :</p>
                    {
                        leaveRequest.approver_details?.map(detail => (
                        <div>
                            <img src={detail.avatar} alt="approver-img"  onError={(e) => (e.target.src = avatar) }/>
                            <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                        </div>
                        ))
                    }
                    </div>
                    <div>
                        <p>{`Date of Approval : ${moment(leaveRequest.approvedAt).format("MMMM DD YYYY")}`}</p>
                    </div>
                </div>
                <div className="floating-label">
                    <textarea
                    className="floating-input floating-textarea hr-textarea"
                    value={leaveRequest.approvalRemark}
                    name="description"
                    cols="30"
                    placeholder=" "
                    style={{ resize: "none" }}
                    row="2"
                    readOnly
                    disabled
                    />
                    <label className="floating-text">Remarks</label>
                </div>
                </div>
            ) : ''}

            {leaveRequest.rejecter_details?.length !== 0 ? (
                <div>
                <div className="request-approver-content">
                    <div>
                        <p>Rejected by : </p>
                    {
                        leaveRequest.rejecter_details?.map(detail => (
                        <div key={detail._id}>
                            <img src={detail.avatar} alt="rejecter-img"  onError={(e) => (e.target.src = avatar) }/>
                            <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                        </div>
                        ))
                    }
                    </div>
                    <div>
                        <p>{`Date of Rejected ${moment(leaveRequest.rejectedAt).format("MMMM DD YYYY")}`}</p>
                    </div>
                </div>
                <div className="floating-label">
                    <textarea
                    className="floating-input floating-textarea hr-textarea"
                    value={leaveRequest.rejectedRemark}
                    name="description"
                    cols="30"
                    placeholder=" "
                    style={{ resize: "none" }}
                    row="2"
                    readOnly
                    disabled
                    />
                    <label className="floating-text">Remarks</label>
                </div>
                </div>
            ) : ''}

            
            </div>
        );
        } else {
        return <React.Fragment />;
        }
        
    };

  return (
    <CustomContentWrapper
      displayUserWrapper={isDisplayUserDetails}
      style={{ gridTemplateColumns: "70% 27%" }}
    >
      <ContentWrapper className="update_leave">
        <LabelCustom className="main-label">
          Review Leave Application
        </LabelCustom>
        <div className="row">
        {/* {leaveRequest?.notedRemark && leaveRequest.status !== "new" ? (
                    <div className="col s12 m12 l12 remark-dark">
                        <p className="info" style={{ color: "#B1822D", marginTop: "0",}}>
                            On hold remark :{" "}
                            <span style={{color: "#9BA3CA", fontWeight: "normal",textTransform: "lowercase",}}>
                                {leaveRequest?.notedRemark}
                              </span>
                            </p>
                          </div>
                        ) : ( "" )}
                {leaveRequest.rejectedRemark && update !== true? (
                    <div className="col s12 m12 l12 remark-dark">
                        <p className="info"style={{ color: "#AE555C", marginTop: "0",}}>
                            Rejection remark :{" "}
                            <span style={{color: "#9BA3CA",fontWeight: "normal",textTransform: "lowercase"}}>
                                {leaveRequest.rejectedRemark}
                              </span>
                            </p>
                          </div>
                        ) : ("")}
                {leaveRequest.approvalRemark && update !== true? (
                    <div className="col s12 m12 l12 remark-dark">
                        <p  className="info" style={{ color: "#2B7A69",marginTop: "0" }}>
                            Approval remark :{" "}
                        <span style={{ color: "#9BA3CA",fontWeight: "normal",textTransform: "lowercase"}}>
                            {leaveRequest.approvalRemark}
                        </span>
                        </p>
                    </div>
                    ) : ( "" )} */}
          <div className="col s4 p-0" style={{ width: "27%" }}>
          
            <p className="coe-info-dark">
              Application Status :
              <span
                className={
                  leaveRequest.status === "new" ? "new" : leaveRequest.status
                }
              >
                {" "}
                {leaveRequest.status === "new"
                  ? "In review"
                  : leaveRequest.status}
              </span>
            </p>
          </div>
          <div className="col s4" style={{ width: "23%" }}>
            <p className="info">
              Number of Days :{" "}
              <span style={{ fontWeight: "normal", color: "#556EE6" }}>
                {
                    hasWeekEnd ? hasWeekEnd2 
                      ? moment(endDate2).diff(moment(startDate2), "days") -1
                      : moment(endDate2).diff(moment(startDate2), "days")
                    : moment(endDate2).diff(moment(startDate2), "days") + 1
                }

                {/* {hasWeekEnd 
                  ? moment(endDate2).diff(moment(startDate2), "days") -1 
                  : moment(endDate2).diff(moment(startDate2), "days") === 0
                  ? +1
                  :moment(endDate2).diff(moment(startDate2), "days") === 1 ? + 2 : dateRanges2.length + 2} */}
                {/* {leaveRequest.startedAt && leaveRequest.endedAt
                  ? moment(leaveRequest.endedAt).diff(
                      moment(leaveRequest.startedAt),
                      "days"
                    ) + 1
                    ? moment(leaveRequest.endedAt).diff(
                        moment(leaveRequest.startedAt),
                        "days"
                      ) + 1
                    : "0"
                  : moment(endDate2).diff(moment(startDate2), "days") + 1
                  ? moment(endDate2).diff(moment(startDate2), "days") + 1
                  : "1"} */}
              </span>
            </p>
          </div>
          <div className="col s4">
            <p className="info">
              Date Filed :{" "}
              <span className="dateInfo" style={{ color: "#556EE6" }}>
                {moment(leaveRequest.createdAt).format("MM - D - YYYY")}
              </span>
            </p>
          </div>
        </div>
        <div className="row column3 mb-0">
          <div className="col l6 m6 s12 p-0">
            <div className="floating-label attrName ">
              {leaveRequest.status === "approved" ||
              leaveRequest.status === "rejected" ||
              leaveRequest.status === "completed" ||
              leaveRequest.status === "cancelled" ? (
                <span id="main_text" className="readonly">
                  <DatePicker
                    selected={
                      new Date(
                        isDatePast(endDate2) 
                        ? moment(leaveRequest.startedAt)
                          .add(1, 'days')
                          .utc()
                          .format("YYYY-MM-DD") 
                        : moment(leaveRequest.startedAt)
                          .utc()
                          .format("YYYY-MM-DD")
                      )
                    }
                    showMonthDropdown
                    showYearDropdown
                    readOnly
                    value={moment(leaveRequest.startedAt).format(
                      "MM - D - YYYY"
                    )}
                    className="floating-input calendar-dark"
                    id="abc"
                    placeholderText=" "
                  />
                  <label className="title floating-text">From</label>
                </span>
              ) : (
                <span id="main_text" className="active">
                  <DatePicker
                    selected={
                      startDate2 && update === false
                        ? startDate2
                        : leaveRequest.startedAt
                        ? new Date(
                          isDatePast(endDate2) 
                          ? moment(leaveRequest.startedAt)
                            .add(1, 'days')
                            .utc()
                            .format("YYYY-MM-DD") 
                          : leaveRequest.startedAt
                          )
                        : endDate2
                    }
                    endDate={endDate2}
                    maxDate={endDate2}
                    onChange={(date) => handleChangeStart2(date)}
                    className="floating-input calendar-dark"
                    id="abc"
                    placeholderText=" "
                    dateFormat="MM-dd-yyyy"
                    showMonthDropdown
                    showYearDropdown
                  />
                  <label className="title floating-text">From</label>
                </span>
              )}
            </div>
          </div>
          <div className="col l6 m6 s12">
            <div className="floating-label attrName " id="main_text">
              {leaveRequest.status === "approved" ||
              leaveRequest.status === "rejected" ||
              leaveRequest.status === "completed" ||
              leaveRequest.status === "cancelled" ? (
                <span id="main_text">
                  <DatePicker
                    selected={
                      new Date(
                        isDatePast(endDate2) 
                        ? moment(leaveRequest.endedAt)
                        : moment(leaveRequest.endedAt)
                      )
                    }
                    showMonthDropdown
                    showYearDropdown
                    readOnly
                    value={moment(leaveRequest.endedAt).format("MM - D - YYYY")}
                    className="floating-input calendar-dark"
                    id="abc"
                    placeholderText=" "
                  />
                  <label className="title floating-text">To</label>
                </span>
              ) : (
                <span id="main_text">
                  <DatePicker
                    selected={
                      endDate2 && update === false
                        ? endDate2
                        : leaveRequest.endedAt
                        ? new Date(
                          isDatePast(endDate2) 
                          ? moment(leaveRequest.endedAt)
                          : moment(leaveRequest.endedAt)
                          )
                        : startDate2
                    }
                    startDate={startDate2}
                    endDate={new Date(endDate2)}
                    onChange={(date) => handleChangeEnd2(date)}
                    minDate={startDate2 ? startDate2 : ""}
                    className="floating-input calendar-dark"
                    id="abc"
                    placeholderText=" "
                    dateFormat="MM-dd-yyyy"
                    showMonthDropdown
                    showYearDropdown
                  />
                  <label className="title floating-text">To</label>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m12 l12 p-0">
            <p className="info" style={{ marginTop: "0" }}>
              Inclusive Days
            </p>
            <div className="col s12 dates-card">
              <div className="row" id="zero">
                {leaveRequest.startedAt
                  ? leaveRequest.startedAt
                      .replace("[", "")
                      .replace("]", "")
                      .split("'")
                      .join("")
                      .split('"')
                      .join("")
                      .split(",")
                      .map((date, index) => {
                        return date ? (
                          <div
                            key={index}
                            className="col s12 m12 l12 valign-wrapper"
                            style={{ padding: "10px 5px" }}
                          >
                            <div
                              className="col s6 m6 l6"
                              style={{ color: "#9BA4C7" }}
                            >
                              <p>
                                {leaveRequest.startedAt
                                  ? moment(leaveRequest.startedAt).format(
                                      "dddd, MMMM DD YYYY"
                                    )
                                  : startDate2}
                              </p>
                            </div>
                            <div className="col s6 m6 l6">
                              {leaveRequest.status === "approved" ||
                              leaveRequest.status === "rejected" ||
                              leaveRequest.status === "completed" ||
                              leaveRequest.status === "cancelled" ? (
                                ""
                              ) : (
                                <button
                                  className="btn sm btn-remove right"
                                  onClick={(date) =>
                                    delStartDate2(date, leaveRequest._id)
                                  }
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        );
                      })
                  : ""}
                {leaveRequest.startedAt === "" &&
                leaveRequest.endedAt === "" ? (
                  <div
                    className="col s12 m12 l12 valign-wrapper"
                    style={{ padding: "5px 5px" }}
                  >
                    <div
                      className="col s12 m12 l12"
                      style={{ color: "#9BA4C7" }}
                    >
                      <p style={{ padding: "5px 0px" }}>
                        {moment(leaveRequest.startedAt).format(
                          "dddd, MMMM DD YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  false
                )}
                {leaveRequest.startedAt && leaveRequest.endedAt
                  ? stateDateRange2.map((dates, index) => (
                      <div
                        key={index}
                        className="col s12 m12 l12 valign-wrapper"
                        style={{ padding: "5px 5px" }}
                      >
                        <div
                          className="col s12 m12 l12"
                          style={{ color: "#9BA4C7" }}
                        >
                          <div className="col s12 m12 l6" style={{ paddingLeft: "0" }}>
                            <p style={{ padding: "5px 0px" }}>{dates}</p>
                          </div>

                          <div className="col s12 m12 l6" style={{ paddingRight: "0" }}>
                            {
                              dateRanges2.length ? (
                              <button
                                className="btn sm btn-remove right"
                                onClick={ cantRemove
                                }
                              >
                                Remove
                              </button>) : false
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  : dateRanges2.map((dates, index) => (
                      <div
                        key={index}
                        className="col s12 m12 l12 valign-wrapper"
                        style={{ padding: "5px 5px" }}
                      >
                        <div
                          className="col s12 m12 l12"
                          style={{ color: "#9BA4C7" }}
                        >
                          <p style={{ padding: "5px 0px" }}>{dates}</p>
                        </div>
                      </div>
                    ))}
                {leaveRequest.startedAt === "" &&
                leaveRequest.endedAt === "" ? (
                  <div
                    className="col s12 m12 l12 valign-wrapper"
                    style={{ padding: "5px 5px" }}
                  >
                    <div className="col s12 m12 l12">
                      <p style={{ padding: "5px 0px" }}>
                        {moment(leaveRequest.endedAt).format(
                          "dddd, MMMM DD YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  false
                )}
                {leaveRequest.endedAt
                  ? leaveRequest.endedAt
                      .replace("[", "")
                      .replace("]", "")
                      .split("'")
                      .join("")
                      .split('"')
                      .join("")
                      .split(",")
                      .map((date, index) => {
                        return date ? (
                          <div
                            key={index}
                            className="col s12 m12 l12 valign-wrapper"
                            style={{ padding: "10px 5px" }}
                          >
                            <div
                              className="col s6 m6 l6"
                              style={{ color: "#9BA4C7" }}
                            >
                              <p>
                                {leaveRequest.endedAt
                                  ? moment(leaveRequest.endedAt).format(
                                      "dddd, MMMM DD YYYY"
                                    )
                                  : endDate2}
                              </p>
                            </div>
                            <div className="col s6 m6 l6">
                              {leaveRequest.status === "approved" ||
                              leaveRequest.status === "rejected" ||
                              leaveRequest.status === "completed" ||
                              leaveRequest.status === "cancelled" ? (
                                ""
                              ) : (
                                <button
                                  className="btn sm btn-remove right"
                                  onClick={() =>
                                    delEndDate2(date, leaveRequest._id)
                                  }
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        );
                      })
                  : ""}
              </div>
            </div>
          </div>
        </div>
        {leaveRequest.status === "approved" ||
            leaveRequest.status === "rejected" ||
            leaveRequest.status === "completed" ||
            leaveRequest.status === "cancelled" ? (
              <div className="floating-label">
                <textarea
                  className="floating-input floating-textarea ot-reason"
                  value={leaveRequest.description}
                  name="description"
                  cols="30"
                  placeholder=" "
                  style={{ resize: "none" }}
                  row="10"
                  readOnly
                ></textarea>
                <label className="floating-text">Reason</label>
              </div>
            ) : (
              <div className="floating-label">
                {/* <textarea
                  className="floating-input floating-textarea ot-reason"
                  name="description"
                  value={leaveRequest.description}
                  cols="30"
                  placeholder=" "
                  style={{ resize: "none" }}
                  row="10"
                  onChange={descriptionChange2}
                >
                  {leaveRequest.description ? leaveRequest.description : ""}
                </textarea> */}
                <textarea 
                  className="floating-input floating-textarea ot-reason"
                  name="description"
                  value={leaveRequest.description ? leaveRequest.description : ""}
                  cols="30"
                  placeholder=" "
                  style={{ resize: "none" }}
                  row="10"
                  onChange={descriptionChange2}
                    >
                </textarea>
                <label className="floating-text">Message</label>
              </div>
            )}

       
          <div className="div-attach" style={{marginBottom:'20px'}}>
                <p className="info" style={{ marginTop: "0" }}>
                    Attachments
                </p>

                {leaveRequest.files?.length !== 0 || leaveRequest.files === 'undefined' || leaveRequest.files === '' || leaveRequest.files === null ? (
                    leaveRequest.files?.map((c) => (
                        // <p>{c.split('-').pop()}</p>
                        <div key={c} className="attach-div">
                            <div className="attach-preview">
                                <img
                                    src={c}
                                    className="attach-file"
                                    alt="Thumb"
                                    onError={(e) => (e.target.src = fileIcon) }
                                />
                                    <p onClick={() => { openFilePreview(); setFileUrl(c); setFileType(c.split('.').pop()) }} style={{cursor:'pointer'}}>{c.split('/').pop()}</p>
                            </div>
                            <a href={c} download>
                                <button className="btn-download" style={{background:'none'}} >
                                    <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'contrast(0.5)' }}/>
                                </button>
                            </a>
                        </div>
                    ))
                    
                ) : (
                <>
                    <div className="attach-div">
                        <div className="attach-preview">
                            <p>No File Attached</p>
                        </div>
                    </div>
                </>
                )}

            </div>
        

        <div className="row textarea-column">
          <div
            className="col s12 m12 l12 p-0"
            style={{ paddingLeft: "2%", paddingRight: "2%" }}
          >
            <ApproverDetails
              manager={leaveRequest.approver}
              status={leaveRequest.status}
              date={
                leaveRequest.status === requestStatus.approved ||
                leaveRequest.status === requestStatus.completed
                  ? leaveRequest.approvedAt
                  : leaveRequest.status === requestStatus.onHold
                  ? leaveRequest.notedAt
                  : leaveRequest.status === requestStatus.rejected
                  ? leaveRequest.rejectedAt
                  : ""
              }
            />
          </div>
        </div>
        {/* ------------------------------------- BUTTONS -------------------------------------------- */}
        <div className="row leave-buttons pl-0">
          <div
            className="col s1 m8 l8"
            style={{ width: "50%", padding: "0 0" }}
          >
            {leaveRequest.status === "new" ||
            leaveRequest.status === "on hold" ? (
              <span>
                {leaveRequest.user_id !== admin.user._id ? (
                  <span>
                    <button
                      className="btn leave-btn-approve-dark"
                      onClick={() => openRemarkModal(requestStatus.approved)}
                    >
                      Approve
                    </button>
                    <button
                      className="btn leave-btn-hold-dark"
                      onClick={() => openRemarkModal(requestStatus.onHold)}
                    >
                      Hold
                    </button>
                    <button
                      className="btn leave-btn-reject-dark"
                      onClick={() => openRemarkModal(requestStatus.rejected)}
                    >
                      Reject
                    </button>
                  </span>
                ) : (
                  ""
                )}
              </span>
            ) : (
              ""
            )}
          </div>
          <div
            className="col s1 m4 l4 right"
            style={{ width: "50%", padding: "0 0" }}
          >
            {leaveRequest.status === "new" ||
            leaveRequest.status === "on hold" ? (
              <button
                className={
                  update === false
                    ? "btn btn-leave disabled right"
                    : "btn btn-leave right leave-update-dark"
                }
                onClick={() => {
                  handleSubmit(leaveRequest._id);
                }}
              >
                UPDATE
              </button>
            ) : (
              ""
            )}
            <span className="right">
              <button onClick={goBack} className="btn cancelDark">
                BACK
              </button>
            </span>
          </div>
        </div>
        {/* --------------------------------------------------> MODAL <-------------------------------------------------- */}
        <div>
          <Modal
            isOpen={showRemarkModal}
            ariaHideApp={true}
            toggle={setRemarkModal}
            onRequestClose={() => setRemarkModal(false)}
            shouldCloseOnOverlayClick={true}
            className="AnnouncementModalBG"
          >
            <div
              className="row center rejecting-container"
              style={{
                width: "auto",
                height: "auto",
                margin: "auto",
                padding: "50px",
              }}
            >
              <p className="coe-info left">
                {`Please state your reason for ${
                  approversNote.status === requestStatus.approved
                    ? "approving"
                    : approversNote.status === requestStatus.onHold
                    ? "on hold"
                    : approversNote.status === requestStatus.rejected
                    ? "rejecting"
                    : ""
                } :`}
              </p>
              <br />

              <div className="col s12 m12 l12">
                <div className="floating-label">
                  <textarea
                    className="floating-input floating-textarea hr-textarea rejecting"
                    value={approversNote.remarks}
                    name="remarks"
                    // cols="30"
                    placeholder=" "
                    style={{ resize: "none" }}
                    onChange={onRemarksChange}
                    row="10"
                  ></textarea>
                  <label className="floating-text">Remarks</label>
                </div>
              </div>
              <div className="col s10 m10 l10 coe-buttons">
                <span className="right-dark">
                  <button
                    className="btn coe-btn-cancel-dark"
                    onClick={() => setRemarkModal(false)}
                  >
                    Cancel{" "}
                  </button>
                  <button
                    className={cc([
                      "btn",
                      approversNote.status === requestStatus.approved
                        ? "coe-btn-approve-dark"
                        : approversNote.status === requestStatus.onHold
                        ? "coe-btn-hold-dark"
                        : approversNote.status === requestStatus.rejected
                        ? "btn coe-btn-reject-dark"
                        : "",
                    ])}
                    onClick={() => {
                      approversNote.remarks && handleSubmit(leaveRequest._id);
                    }}
                  >
                    {approversNote.status === requestStatus.approved
                      ? "Approve"
                      : approversNote.status === requestStatus.onHold
                      ? "Hold"
                      : approversNote.status === requestStatus.rejected
                      ? "Reject"
                      : ""}
                  </button>
                </span>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={showFilePreview}
            ariaHideApp={true}
            toggle={setFilePreview}
            onRequestClose={() => setFilePreview(false)}
            shouldCloseOnOverlayClick={true}
            className="fileModalPreview"
            style={customStyles}
          >
            <div
              className="row center rejecting-container"
              style={{
                width: "auto",
                height: "auto",
                margin: "auto",
                padding: "50px",
              }}
            >
            <div className="gAction">
                <a href={FileUrl} download>
                    <button className="btn-download" style={{background:'none'}}>
                        <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'brightness(5.5)' }}/>
                    </button>
                </a>
                <img height={20} onClick={() => setFilePreview(false)} src={close} alt="" style={{ margin:'15px 7px', filter: 'brightness(5.5)', cursor:'pointer' }}/>
            </div>
            <div style={{ height: '100%' }}>
            <FileViewer 
                fileType={FileType} 
                filePath={FileUrl} 
                key= {FileUrl}
            />
            </div>

            </div>
          </Modal>
        </div>
      </ContentWrapper>
      <When condition={isDisplayUserDetails === true}>
        <PreviewLeaveUserWidget
          {...props}
          subjectId={UserDetailsView}
          credits={props.leaveCredits.leaveCredits}
          availableCredits="true"
          comments ={comment}
          replies = {reply}
          request = {leaveRequest}
          isReply={Object.keys(comment).length !== 0}
        />
      </When>
    </CustomContentWrapper>
  );
});

export default ReviewLeavePage;
