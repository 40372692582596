export const officeConstants = {
    GETALL_REQUEST: 'OFFICE_GETALL_REQUEST',
    GETALL_SUCCESS: 'OFFICE_GETALL_SUCCESS',
    GETALL_FAILURE: 'OFFICE_GETALL_FAILURE',

    GET_REQUEST: 'OFFICE_GET_REQUEST',
    GET_SUCCESS: 'OFFICE_GET_SUCCESS',
    GET_FAILURE: 'OFFICE_GET_FAILURE'
};

