import './SideMenu.sass'; //adjust component width if admin sidenav is open

export function checkSideNav(){
	//adjust component width if admin sidenav is open		
	var navIsOpen = JSON.parse(localStorage.getItem('navIsOpen'))
	if(navIsOpen === true){
		return ' SideBarSlide open-admin-sideNav'
	}else{
		return ' SideBarSlide collapse-admin-sideNav'
	}
}