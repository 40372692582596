export const settingsConstants = {
  CREATE_REQUEST: 'SETTINGS_CREATE_REQUEST',
  CREATE_SUCCESS: 'SETTINGS_CREATE_SUCCESS',
  CREATE_FAILURE: 'SETTINGS_CREATE_FAILURE',

  GETALL_REQUEST: 'SETTINGS_GETALL_REQUEST',
  GETALL_SUCCESS: 'SETTINGS_GETALL_SUCCESS',
  GETALL_FAILURE: 'SETTINGS_GETALL_FAILURE',

  DELETE_REQUEST: 'SETTINGS_DELETE_REQUEST',
  DELETE_SUCCESS: 'SETTINGS_DELETE_SUCCESS',
  DELETE_FAILURE: 'SETTINGS_DELETE_FAILURE',

  GETONE_REQUEST: 'GET_SETTINGS_REQUEST',
  GETONE_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GETONE_FAILURE: 'GET_SETTINGS_FAILURE', 
  
  UPDATE_REQUEST: 'SETTINGS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SETTINGS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SETTINGS_UPDATE_FAILURE',
};