import React from 'react';
import './HeaderPhoto.sass';
// import { history } from '../_core/helpers/history';

class PeopleTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            render: true,
            imageHash: Date.now(),
        }
        this.handleClose = this.handleClose.bind(this);
        this.alertTimeOut = this.alertTimeOut.bind(this);
    }
    componentDidMount() {
        
    }

    alertTimeOut = () =>
        setTimeout(function () {
            this.setState({ render: false })

        }.bind(this), 4000
        )


    // componentWillUnmount() {
    //     clearTimeout(this.alertTimeOut)
    // }

    handleClose(event) {
        event.preventDefault()
        this.setState({ render: false })
    }

    render() {
        // let user = JSON.parse(localStorage.getItem('user'));

        return(
        <div className="userSticky">
            <div className="container-userpage userTabCon">
                <div className="col s12 m4">
                    <div className="row">
                        <div className="offset-m1 col s12 m2 user-pic-center">
                            
                        </div>
                        <div className="col s12 m9 l6">
                            <div className="MenuListCon">
                            <ul class="tabs" id="tabs-swipe-demo">
                                <li class="tab col s3"><a href="#test1" id="post">FEEDS</a></li>
                                <li class="tab col s3"><a class="active" href="#test2">PROFILE</a></li>
                                
                            </ul>   
                            </div>

                            <div className="mobIconList">
                                    <ul class="tabs" id="tabs-swipe-demo">
                                        <li class="tab col s3"><a href="#test1">FEEDS</a></li>
                                        <li class="tab col s3"><a class="active" href="#test2">PROFILE</a></li>
                                        
                                    </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div> 
        </div>
        )
    }
}

export default PeopleTab;