import { get, post, put, del } from '../helpers/api';
import { userService } from '../services/user';
import buildUrl from "build-url";

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}

const create = (page) => {
    return post('/api/v1/page', page).then(handleResponse);
}

const edit = (page, pageId) => {
    return put(`/api/v1/page/${pageId}`, page).then(handleResponse);
}

const getById = (id) => {
    return get(`/api/v1/page/${id}`).then(handleResponse);
}

const getBySlug = (slug) => get(`/api/v1/page/slug/${slug}`).then(handleResponse);

const getAll = (page = 1, itemPerPage) => (
    get(
        buildUrl('/api/v1/page', {
            queryParams: {
                page,
                'items-per-page': itemPerPage
            }
        })
    ).then(handleResponse)
);

const remove = (pageId) => del(`/api/v1/page/${pageId}`).then(handleResponse);

const uploadFeaturedImage = (formData, pageId) => post(`/api/v1/upload/${pageId}/page/featuredImage`, formData).then(handleResponse);

export default {
    create,
    edit,
    getAll,
    getById,
    getBySlug,
    remove,
    uploadFeaturedImage
}