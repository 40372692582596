import { grantConstants } from '../constants/grant';
import { grantServices } from '../services/grant';
import { alertActions } from './alert';
import Swal from 'sweetalert2';

export const grantActions = {
    getAll,
    getByOrigin,
    grantPost
};

function getAll(page) {
    return dispatch => {
        dispatch(request(page));


        grantServices.getAllGrant(page)
            .then(
                grant => {
                    dispatch(success(grant))
                    // if(window.location.pathname === '/admin/attributes'){
                    //     history.push('/admin/attributes?page=' + Number(page) || 1);
                    // }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: grantConstants.GETALL_REQUEST } }
    function success(grant) { return { type: grantConstants.GETALL_SUCCESS, grant } }
    function failure(error) { return { type: grantConstants.GETALL_FAILURE, error } }
}

function getByOrigin(id, page, itemsPerPage) {
    return dispatch => {
        dispatch(request(id));
        grantServices.getByOrigin(id, page, itemsPerPage)
            .then(
                orig_grant => dispatch(success(orig_grant)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: grantConstants.GET_ORIGIN_REQUEST, id } }
    function success(orig_grant) { return { type: grantConstants.GET_ORIGIN_SUCCESS, orig_grant } }
    function failure(error) { return { type: grantConstants.GET_ORIGIN_FAILURE, error } }
}

function grantPost(grant) {
    return dispatch => {
        dispatch(request(grant));

        const ToastSuccess = Swal.mixin({
            background: "white",
            toast: true,
            width: 300,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1000
        });


        grantServices.grantPost(grant)
            .then(
                grant => { 
                    dispatch(success());
                    ToastSuccess.fire({
                        type: 'success',
                        title: '<span style="color:green">Announcement Successfully Acknowledged!</span>'
                    }).then(() => {
                        dispatch(alertActions.success('Announcement Successfully Acknowledged'));
                    })
                    dispatch(alertActions.success(''));

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };

    function request(grant) { return { type: grantConstants.POST_REQUEST, grant } }
    function success(grant) { return { type: grantConstants.POST_REQUEST, grant } }
    function failure(error) { return { type: grantConstants.POST_REQUEST, error } }
}