import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from 'react-modal';
import {useDropzone} from 'react-dropzone';
import { DragDropImage,} from "../../Components/ImageUploader/upload";
import Select, { components } from "react-select";
import moment from "moment";
import {
    SaveAndCancelButton,
  } from "../../Components/PageWrapper/ContentWrapper";
import "./Quests.scss";
import { useParams } from "react-router-dom";
import {
    CustomInputText,
    CustomTextAreaEngage
  } from "../../Components/FloatingTextBox/TextArea";

import { challengeActions } from '../../../_core/actions/challenge';
import { questActions } from '../../../_core/actions/quest';

const AddChallengeModal = (props) => {

    const [InputError, setInputError] = useState(false);
    const [files, setFiles] = React.useState([]);
    const [newQuestionToAdd, setNewQuestionToAdd] = React.useState([]);
    const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
    const [count, setCount] = React.useState(0);
    const dispatch = useDispatch();
    const { pageId } = useParams();

    const [challenge, setChallenge] = useState({
        questId: pageId !== undefined ?  pageId : "",
        recurring: true,
        timeLimit: "",
        active:1,
        requiredBadges:"",
        title: "",
        description: "",
        bestTime: "00:00:00",
        location: "",
        lat:"",
        lon:"",
        eneryValue:"",
        credits:"",
        exp:"",
        expiresAt: "",
        startsAt: moment(new Date()).utc().format(),
        images:"",
        order: 0
    });

    React.useEffect(()=>{

        if(props.editStatus !== false && Object.keys(props.challengeEdit || {} ).length !== 0){
     
            setChallenge({
                questId: props.challengeEdit.questId,
                recurring: props.challengeEdit.recurring,
                timeLimit: props.challengeEdit.timeLimit.split(':')[0],
                active: props.challengeEdit.active,
                requiredBadges: props.challengeEdit.requiredBadges,
                title: props.challengeEdit.title,
                description: props.challengeEdit.description,
                bestTime: props.challengeEdit.bestTime,
                location: props.challengeEdit.location,
                lat: props.challengeEdit.lat,
                lon: props.challengeEdit.lon,
                eneryValue: props.challengeEdit.eneryValue,
                credits: props.challengeEdit.credits,
                exp: props.challengeEdit.exp,
                expiresAt: props.challengeEdit.expiresAt,
                startsAt: props.challengeEdit.startsAt,
                images: props.challengeEdit.images,
                order: props.challengeEdit.order,
            })
            setIsAdd(false)
        }else{
            setChallenge({
                ...challenge,
                order: props.edit?.length + 1,
            })
            setIsAdd(true)
        }
        
        //eslint-disable-next-line
    },[props]);

    console.log(props.questStatus)

    React.useEffect(()=>{

        if(props.data) {
            props.data(newQuestionToAdd);
        }
    },[props,newQuestionToAdd]);
    

    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        if(name === "description"){
            setCount(value.length)
        }
        setChallenge({
            ...challenge,
            [name]: value,
        });
    }

    const handleNumber = (e) => {
        e.preventDefault(); // crashes
        const value = e.target.value.replace(/[^\d.]/ig, "");
        const total = parseInt(value);
        if(value.length === 0) {
            setChallenge({ ...challenge, [e.target.name]: '', });
        } else {
            setChallenge({ ...challenge, [e.target.name]: total, });
        }
    };

    const {getRootProps, getInputProps, open} = useDropzone({
        accept: 'image/x-png, image/png, image/jpg, image/jpeg, image/gif',
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
            })));
            setChallenge({
                ...challenge,
                images:acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                    }))[0],
              });
    
        }
        });

    const removeSelectedImage = () => {
        setFiles([]);
    } 

     let isValidTitle = challenge.title.trim() === ''
    // const handleTime = (e) => {
    //     e.preventDefault(); // crashes
    //     const value = e.target.value;
    //     setChallenge({
    //         ...challenge,
    //         timeLimit: value,
    //     })
    // };

    const handleSelect = (data) => {
        const names = data.target.name
        const value = data.target.value
        setChallenge({ ...challenge, [names]: value });
    };

    const cancelQuest = () => {

        props.handleCloseModal(false)
        setInputError(false);
        setChallenge({
            questId: pageId !== undefined ?  pageId : "",
            recurring: true,
            timeLimit: "",
            active:1,
            requiredBadges:"",
            title: "",
            description: "",
            bestTime: "00:00:00",
            location: "",
            lat:"",
            lon:"",
            eneryValue:"",
            credits:"",
            exp:"",
            expiresAt: "",
            startsAt: moment(new Date()).utc().format(),
            images:"",
            order:"'"
        });

    }

    const submitQuestChallenge = () => {
        if(
            !challenge.title ||
            !challenge.description ||
            !challenge.timeLimit ||
            !challenge.eneryValue ||
            !challenge.credits ||
            !challenge.exp
        ) {
            setInputError(true);
        }else{
            let newChallenge = {
                questId:challenge.questId,
                recurring: challenge.recurring,
                timeLimit: challenge.timeLimit + ":00:00",
                active: challenge.active,
                requiredBadges: challenge.requiredBadges,
                title: challenge.title,
                description: challenge.description,
                bestTime: challenge.bestTime,
                location: challenge.location,
                lat:challenge.lat,
                lon:challenge.lon,
                eneryValue:challenge.eneryValue,
                credits:challenge.credits,
                exp:challenge.exp,
                expiresAt: challenge.expiresAt,
                startsAt:challenge.startsAt,
                images:challenge.images,
                order: challenge.order
            };
            
            if(isAdd && props.editStatus !== true && props.questStatus !== true){

                dispatch(challengeActions.createChallenge(newChallenge))

                //setNewQuestionToAdd([...newQuestionToAdd, newChallenge])
                props.handleCloseModal(false)
                //  reset
                setChallenge({
                    questId: pageId !== undefined ?  pageId : "",
                    recurring: true,
                    timeLimit: "",
                    active:1,
                    requiredBadges:"",
                    title: "",
                    description: "",
                    bestTime: "",
                    location: "",
                    lat:"",
                    lon:"",
                    eneryValue:"",
                    credits:"",
                    exp:"",
                    expiresAt: "",
                    startsAt:"",
                    images:"",
                    order:"'"
                });
                setFiles([]);
                setInputError(false);
            }else if(!isAdd && props.editStatus && !props.questStatus){
                let UpdateChallenge = {
                    questId:challenge.questId,
                    recurring: challenge.recurring,
                    timeLimit: challenge.timeLimit + ":00:00",
                    active: challenge.active,
                    requiredBadges: challenge.requiredBadges,
                    title: challenge.title,
                    description: challenge.description,
                    bestTime: challenge.bestTime,
                    location: challenge.location,
                    lat:challenge.lat,
                    lon:challenge.lon,
                    eneryValue:challenge.eneryValue,
                    credits:challenge.credits,
                    exp:challenge.exp,
                    expiresAt: challenge.expiresAt,
                    startsAt:challenge.startsAt,
                    images:challenge.images,
                    order: challenge.order
                };
                dispatch(challengeActions.update(UpdateChallenge,props.challengeId))
                props.handleCloseModal(false)
                setTimeout(() => {
                    window.location.reload() 
                }, 1500)
            }else if(!isAdd && props.questStatus && props.editStatus){
                setNewQuestionToAdd(newChallenge)
                props.handleCloseModal(false)
                //  reset
                setChallenge({
                    questId: pageId !== undefined ?  pageId : "",
                    recurring: true,
                    timeLimit: "",
                    active:1,
                    requiredBadges:"",
                    title: "",
                    description: "",
                    bestTime: "00:00:00",
                    location: "",
                    lat:"",
                    lon:"",
                    eneryValue:"",
                    credits:"",
                    exp:"",
                    expiresAt: "",
                    startsAt: moment(new Date()).utc().format(),
                    images:"",
                    order:"'"
                });
                setFiles([]);
                setInputError(false);                   
            }else{
                setNewQuestionToAdd([...newQuestionToAdd, newChallenge])
                props.handleCloseModal(false)
                //  reset
                setChallenge({
                    questId: pageId !== undefined ?  pageId : "",
                    recurring: true,
                    timeLimit: "",
                    active:1,
                    requiredBadges:"",
                    title: "",
                    description: "",
                    bestTime: "00:00:00",
                    location: "",
                    lat:"",
                    lon:"",
                    eneryValue:"",
                    credits:"",
                    exp:"",
                    expiresAt: "",
                    startsAt: moment(new Date()).utc().format(),
                    images:"",
                    order:"'"
                });
                setFiles([]);
                setInputError(false);                
            }

            if (props.list) {
                let newChallenge = {
                    questId:props.listId._id,
                    recurring: challenge.recurring,
                    timeLimit: challenge.timeLimit + ":00:00",
                    active: challenge.active,
                    requiredBadges: challenge.requiredBadges,
                    title: challenge.title,
                    description: challenge.description,
                    bestTime: challenge.bestTime,
                    location: challenge.location,
                    lat:challenge.lat,
                    lon:challenge.lon,
                    eneryValue:challenge.eneryValue,
                    credits:challenge.credits,
                    exp:challenge.exp,
                    expiresAt: challenge.expiresAt,
                    startsAt:challenge.startsAt,
                    images:challenge.images,
                    order: props.listId.questChallenges.length + 1
                };

                dispatch(challengeActions.createChallenge(newChallenge, props.listId._id)); 
                props.handleCloseModal(true)
                //dispatch(questActions.getAllFilter(props.activePage,props.entriesItem,"",""))
                setTimeout(() => {
                    dispatch(questActions.getAll())
                }, 1500)
                
            }
            

        }
    }

    const { ValueContainer, Placeholder } = components;

    const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused} className="menu">
            {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
            child && child.type !== Placeholder ? child : null
        )}
        </ValueContainer>
    );
    };

    const customStyles2 = {
        control: (base, state) => (InputError ? {
            ...base,
            background: "#2e3648",
            height:"40px",
            // match with the menu
            borderRadius: '5px',
            marginBottom:"15px",
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#323a4f" : "#f14646",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
              // Overwrittes the different states of border
              borderColor: state.isFocused ? "#556ee6" : "#323A4F",
            },
          }:{
            ...base,
            background: "#2e3648",
            height:"40px",
            borderRadius: '5px',
            marginBottom:"20px",
            borderColor: state.isFocused ? "#323a4f" : "#323a4f",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
              borderColor: state.isFocused ? "#556ee6" : "#323A4F",
            },
          }),
        container: (provided, state) => ({
            ...provided,
            marginTop: 0,
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            marginTop: '-15px',
          }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: "#9BA3CA",
            "&:hover": {
                color: state.isFocused ? "#556ee6" : "#556ee6",
              }, 
        }),
        option: (base, state) => ({
            ...base,
            color: "#9ba2ce",
            backgroundColor: state.isSelected ? "#32394A" : "#2e3648",
            ":hover": {
              backgroundColor: state.isSelected ? "#2e3648" : "#32394A",
            },
            zIndex: "999",
          }),
    
        menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        }),
        menu: (provided) => ({
          ...provided,
          height: "auto",
          maxHeight: "208px",
          position: "absolute",
          display: "block",
          background: "#2E3648",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible"
        }),
        input: (provided, state) => ({
            ...provided,
            height: 35,
            color: '#9BA3CA',
            lineHeight: state.isFocused ? 35 : 0,
            transition:  "top 0.1s, font-size 0.1s"
        }),
        placeholder: (provided, state) => (InputError ? {
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
            transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
            background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
            color: state.hasValue ? "#9BA3CA" :"#f14646",
        }:{
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
            transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
            background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
            color: "#9BA3CA",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: ' #9BA3CA',
            fontSize:"14px",
            fontWeight:'normal',
            top:'35%'
          }),
      };

    const expOptions = [
        { value: "10", label: '10'},
        { value: "25", label: '25'},
        { value: "50", label: '50'},
        { value: "100", label: '100'},
        { value: "250", label: '250'},
        { value: "500", label: '500'},
        { value: "750", label: '750'},
        { value: "1000", label: '1000'},
    ];

    const creditsOptions = [
        { value: "10", label: '10'},
        { value: "20", label: '20'},
        { value: "50", label: '50'},
        { value: "100", label: '100'},
        { value: "250", label: '250'},
        { value: "300", label: '300'},
        { value: "500", label: '500'},
    ];

    const energyOptions = [
        { value: 1, label: '1'},
        { value: 2, label: '2'},
        { value: 3, label: '3'},
        { value: 4, label: '4'},
        { value: 5, label: '5'},
        { value: 6, label: '6'},
        { value: 7, label: '7'},
        { value: 8, label: '8'},
        { value: 9, label: '9'},
        { value: 10, label: '10'}
    ];

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        toggle={props.toggle}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
        centered
        className="AddChallengeModal"
      >
        <div className="row center approval-container"
            style={{
                width: "auto",
                height: "auto",
                margin: "auto",
                padding: "20px",
                borderBottom: "1px solid #4A4E5E"
            }}>
            
            <p className="modal_title left"> Add Quest Challenge</p>
        </div>

        <div style={{padding:'20px'}}>
            <CustomInputText
                label={"Challenge name"}
                name={"title"}
                value={challenge.title}
                inputOnChange={handleChangeInput}
                isError={isValidTitle && InputError}
            />     
            <CustomTextAreaEngage
                label={"Description"}
                name={"description"}
                value={challenge.description}
                inputOnChange={handleChangeInput}
                maxlength="250"
                isError={!challenge.description && InputError}
            />
            <p className="charCountChallenge">{count}</p>

            <label className="questCover">Challenge Cover Photo</label>
            <div {...getRootProps({className: 'dropzone'})}>
                <div className={files[0] ? 'active-dz': 'unactive-dz'} >
                    <input {...getInputProps()} />
                    <div>
                        <p className="dropTitle">Drag and Drop</p>
                        <p className="dropTitle">Cover Photo here</p>
                        <p className="dropTitle">or</p>
                    </div>
                    <button onClick={open} className="custom-file-upload-engage">
                        Browse
                    </button>
                </div>
                <DragDropImage removeImage={removeSelectedImage} selectedImage={files}/>
            </div>
            <label data-domain="hrs" className="textModal">
                <CustomInputText
                    label={"Time limit"}
                    name={"timeLimit"}
                    value={String(challenge.timeLimit)}
                    inputOnChange={(e) => handleNumber(e)}
                    isError={!challenge.timeLimit && InputError}
                />
            </label>
            <Select
                defaultValue={energyOptions.find(option => option.value === challenge.eneryValue || '' )}
                onChange={(statusValue)=> {handleSelect({target: { name:'eneryValue', value: statusValue === null ? '' : statusValue.value }})}}
                components={{
                    ValueContainer: CustomValueContainer
                }}
                captureMenuScroll={false}
                placeholder="Energy"
                styles={customStyles2}
                options={energyOptions}
                openMenuOnClick={false}
                isSearchable={false}
                menuPlacement="bottom"
                hideSelectedOptions={false}
                isClearable={true}
                maxMenuHeight={200}
                className="basic-single"
                classNamePrefix="select"
                name="eneryValue"
            />
            <Select
                defaultValue={creditsOptions.find(option => option.value === challenge.credits || '' )}
                onChange={(statusValue)=> {handleSelect({target: { name:'credits', value: statusValue === null ? '' : statusValue.value }})}}
                components={{
                    ValueContainer: CustomValueContainer
                }}
                captureMenuScroll={false}
                placeholder="Credits"
                styles={customStyles2}
                options={creditsOptions}
                openMenuOnClick={false}
                isSearchable={false}
                menuPlacement="bottom"
                hideSelectedOptions={false}
                isClearable={true}
                maxMenuHeight={200}
                className="basic-single"
                classNamePrefix="select"
                name="credits"
            />
            <Select
                defaultValue={expOptions.find(option => option.value === challenge.exp || '' )}
                onChange={(statusValue)=> {handleSelect({target: { name:'exp', value: statusValue === null ? '' : statusValue.value }})}}
                components={{
                    ValueContainer: CustomValueContainer
                }}
                captureMenuScroll={false}
                placeholder="Experience"
                styles={customStyles2}
                options={expOptions}
                openMenuOnClick={false}
                isSearchable={false}
                menuPlacement="bottom"
                hideSelectedOptions={false}
                isClearable={true}
                maxMenuHeight={200}
                className="basic-single"
                classNamePrefix="select"
                name="exp"
            />
        <SaveAndCancelButton
          cancelFn={() => cancelQuest()}
          saveFn={() => submitQuestChallenge()}
        />
        </div>
      </Modal>
    </>
  );
};

export default AddChallengeModal;