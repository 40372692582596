import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import styled, { css } from "styled-components";
import { useParams } from 'react-router-dom';
import { userActions } from '../../../_core/actions/user';
import { leaveCreditActions } from '../../../_core/actions/leaveCredit';
import { technicalActions } from '../../../_core/actions/technical';
import { userService } from "../../../_core/services/user";
import { alertActions } from '../../../_core/actions/alert';
import { essActions } from '../../../_core/actions/ess';
import { notifActions } from '../../../_core/actions/notification';
import Swal from 'sweetalert2'
import Modal from 'react-modal';
import "./TechSupport.scss";
import avatar from "../../../_templates/man.png";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { When } from 'react-if'; 
import PreviewLeaveUserWidget from "../../AdminWidget/PreviewUserDetailsWidget/PreviewLeaveUserWidget"
import { requestStatus } from "../../../_core/helpers/requestStatus";
import {ContentWrapper,LabelCustom} from "../../Components/PageWrapper/ContentWrapper";
import fileIcon from '../../../_templates/dark/file_blank_outline.png';
import download from '../../../_templates/dark/download.png';
import close from '../../../_templates/dark/off_outline_close.png';
import FileViewer from 'react-file-viewer';

import {
    CustomTextArea,
    CustomDatePicker
  } from "../../Components/FloatingTextBox/TextArea";
import { commentActions } from '../../../_core/actions/comment';
import { commentService } from '../../../_core/services/comment';

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

const mapStateToProps = ({ overtimes,leaves,leaveCredits,technical,comments}) => ({
    overtimes,
    leaves,
    leaveCredits,
    technical,
    comments
});

const ReviewTech = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    const [update, setUpdate] = React.useState(false);
    const [showAppModal, setAppModal] = React.useState(false);// eslint-disable-line
    const [showModal, setModal] = React.useState(false);
    const [showRejModal, setRejModal] = React.useState(false);
    const [count, setRender] = React.useState(true);
    const [UserDetailsView, setUserDetailsView] = React.useState([]);
    const [isDisplayUserDetails, setIsDisplayUserDetails] = React.useState(false); // eslint-disable-line
    const countRef = React.useRef(count);
    const [isAdd, setIsAdd] = React.useState(true); // eslint-disable-line
    const [UserApproverView, setApproverView] = React.useState([]);
    const [UserRejecterView, setRejectedView] = React.useState([]);
    const [UserNoterView, setOnHoldView] = React.useState([]);
    const [request, setRequest] = React.useState([]);
    const [comment, setCommment] = React.useState([]);
    const [reply, setReply] = React.useState([]);

    const openFilePreview = () => {
      setFilePreview(true);
    };
    
    const [showFilePreview, setFilePreview] = React.useState(false);
    const [FileUrl, setFileUrl] = React.useState('');
    const [FileType, setFileType] = React.useState('');
    
    const customStyles = {
      content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
      }
    };

    countRef.current = count;

    const { pageId } = useParams();
    let ActivePage = props.match.params.page;

    let admin = JSON.parse(localStorage.getItem('userCredentials'));

    useEffect(() => {
        let oid = pageId
        dispatch(technicalActions.getById(oid));
        dispatch(userActions.getAll(1, 100000));
        dispatch(leaveCreditActions.getAll());
        setIsDisplayUserDetails(true)
        dispatch(commentActions.getByOrigin(oid, 1, 999));
        setIsAdd(false); // Set update
        alertTimeOut();
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        const { technical } = props || {};
        const concernList =
          (technical && technical.success) || [];
        setRequest(concernList);
      }, [props]);

    useEffect(() => {
        commentService.getByOrigin(pageId, 1, 999).then(res=>{
            if(res.items?.length !== 0){
                setCommment(res.items)
                commentService.getByOrigin(res.items[0]?._id, 1, 999).then(res=>{
                    if(res.items){
    
                        if(res.items.length !== 0){
                            setReply(res.items)
                        }
    
                    }
                }).catch(error=>{console.log(error)});
    
            }
        }).catch(error=>{console.log(error)});
      }, [props, pageId]);


    const [support, setTechSupport] = React.useState({
        _id: '',
        description: '',
        startedAt:'',
        endedAt:'',
        user_id:'',
        // refCode: '',
        subtype:'',
        subject:'',
        status: '',
        approver:'',
        approvedBy: '',
        approvalRemark: '',
        approvedAt: '',
        rejectedBy: '',
        rejectedAt: '',
        notedBy: '',
        notedRemark: '',
        notedAt: '',
        createdAt: '',
        updatedAt: '',
        __v: '',

        approvedRemark: '',
        type: '',
        rejectedRemark: '',
        noter_details: '',
        approver_details: '',
        rejecter_details: ''
    });
    const [startDate2, setStartDate2] = React.useState('');
    const [endDate2, setEndDate2] = React.useState('');
   

    useEffect(() => {

        if (pageId) {
          
            let technicalList =  props.technical.success ?? [];
            if(technicalList) {
                technicalList.forEach((item) => {

                    setTechSupport({
                        _id: item._id,
                        description: item.description,
                        startedAt: item.startedAt,
                        endedAt: item.endedAt,
                        user_id:item.user_id,
                        subtype:item.subtype,
                        status: item.status,
                        approver: item.approver,
                        approvedBy: item.approvedBy,
                        approvalRemark: item.approvalRemark,
                        approvedAt: item.approvedAt,
                        rejectedBy: item.rejectedBy,
                        rejectedAt: item.rejectedAt,
                        notedBy: item.notedBy,
                        notedRemark: item.notedRemark,
                        notedAt: item.notedAt,
                        createdAt: item.createdAt,
                        updatedAt: item.updatedAt,
                        approvedRemark: item.approvedRemark,
                        type: item.type,
                        rejectedRemark: item.rejectedRemark,
                        noter_details: item.noter_details,
                        approver_details: item.approver_details,
                        rejecter_details: item.rejecter_details
                    });
                    setUserDetailsView(item.users);
                    setStartDate2(new Date(item.startedAt))
                    setEndDate2(new Date(item.endedAt))
                    setApproverView(item.approver_details)
                    setOnHoldView(item.noter_details)
                    setRejectedView(item.rejecter_details)
                    
                });
                
            }
        
        //   }
        }
        //eslint-disable-next-line
      }, [props.technical, pageId]);

      const alertTimeOut = () => {
        setTimeout(() => {
            setRender(countRef.current);
            props.dispatch(alertActions.clear());
        }, 3000);
    };

   const goBackPath = `/admin/technical-supports?page=${ActivePage}`;

    const handleChangeStart2 = (date) => {
        setStartDate2(date);
        setUpdate(true)
        setIsAdd(true)
        setTechSupport({ ...support, startedAt: moment(date).toISOString() });
    };

    const handleChangeEnd2 = (date) => {
        setEndDate2(date);
        setUpdate(true)
        setIsAdd(true)
        setTechSupport({ ...support, endedAt: moment(date).toISOString() });
    };

    const descriptionChange2 = (e) => {
        setIsAdd(true)
        setTechSupport({ ...support, description: e.target.value });
       
    }

    const onApproveOpenModal = () => {
         setAppModal(true);
    }
    const onHoldOpenModal = () => {
         setModal(true);
    }
    const onRejectOpenModal = () => {
        setRejModal(true);
    }

    const goBack = () => {
        props.history.goBack();
    }

   const handleCloseModal =()=> {
        setAppModal(false)
        setRejModal(false);
        setModal(false);

    }

    const onApproveChange = e => {
        let admin = JSON.parse(localStorage.getItem('userCredentials'));
        setUpdate(true)
        setTechSupport({ ...support, approvalRemark: e.target.value, approvedAt: moment().format(),approvedBy: admin.user._id });
    }

    const onHoldChange = (e) => {
        let admin = JSON.parse(localStorage.getItem("userCredentials"));
        setUpdate(false)
        setTechSupport({ ...support, notedRemark: e.target.value, notedAt: moment().format(), notedBy: admin.user._id});

      };

    const onRejectChange = e => {
        let admin = JSON.parse(localStorage.getItem('userCredentials'));
        setUpdate(true)
        setTechSupport({ ...support, rejectedRemark:e.target.value, rejectedAt: moment().format(), rejectedBy: admin.user._id})
    }

    const [userManager, setManager] = React.useState([]);
    const ApproverDetails = ({ details, status, date, manager }) => {
    
        if(userManager.length === 0){
          const getManager = async () => {
            try {
                if(manager){
                    const user = (await userService.getById(manager));
                    setManager(user.user[0].details);
                }
              
            } catch (error) {
              console.error(error);
            }
          }
          getManager();
        }
          if(userManager.length !== 0){
          return (
            <div>
              <div className="request-approver-content">
                <div>
                  <p>Approver : </p>
                  <div>
                    <img
                      src={userManager[0]?.avatar}
                      alt="approver-img"
                      onError={(e) => (e.target.src = avatar)}
                    />
                    <p>{[userManager[0]?.firstName, userManager[0]?.lastName].join(" ")}</p>
                  </div>
                </div>
                <div>
                  <p>Role : Manager</p>
                  {/* <p>{`Date of ${
                    status === requestStatus.rejected
                      ? "Rejection"
                      : status === requestStatus.onHold
                      ? "Hold"
                      : "Approval"
                  } : ${moment(date).format("MMMM DD YYYY")}`}</p> */}
                </div>
              </div>

              {request[0]?.noter_details?.length !== 0 ? (
                <div>
                  <div className="request-approver-content">
                    <div>
                      <p>Assigned to : </p>
                        {
                          request[0]?.noter_details?.map(detail => (
                            <div key={detail._id}>
                                <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                                <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                            </div>
                          ))
                        }
                    </div>
                    <div>
                        <p>{`Date Assigned :  ${moment(request?.notedAt).format("MMMM DD YYYY")}`}</p>
                    </div>
                  </div>
                  <CustomTextArea
                    value={request[0]?.notedRemark}
                    readOnly={true}
                    label='Remarks'
                  />
                  </div>
                ) : ''}

              {request[0]?.approver_details?.length !== 0 ? (
                <div>
                  <div className="request-approver-content">
                    <div>
                      <p>Completed by : </p>
                      {
                        request[0]?.approver_details?.map(detail => (
                          <div key={detail._id}>
                              <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                              <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                          </div>
                        ))
                      }
                    </div>
                    <div>
                        <p>{`Date Completed : ${moment(request[0]?.approvedAt).format("MMMM DD YYYY")}`}</p>
                    </div>
                  </div>
                  <CustomTextArea
                    value={request[0]?.approvalRemark}
                    readOnly={true}
                    label='Remarks'
                  />
                </div>
              ) : ''}

              {request[0]?.rejecter_details?.length !== 0 ? (
                <div>
                  <div className="request-approver-content">
                    <div>
                      <p>Rejected by : </p>
                      {
                        request[0]?.rejecter_details?.map(detail => (
                          <div>
                            <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                            <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                          </div>
                        ))
                      }
                    </div>
                    <div>
                      <p>{`Date rejected : ${moment(request[0]?.rejectedAt).format("MMMM DD YYYY")}`}</p>
                    </div>
                  </div>
                  <CustomTextArea
                    value={request[0]?.rejectedRemark}
                    readOnly={true}
                    label='Remarks'
                  />
                </div>
              ) : ''}
            </div>
          );
          } else {
            return <React.Fragment/>
          }
      };

      const confirmationModal = (details, description, startedAt, endedAt, status) => {
        const user = details?.users[0];
    
        return Swal.fire({
          title: "<p class='swal-title-modal'> Are you sure? </p>",
          html:
            " <div class='first-column'><div class='inline'><p class='highlights'>"+[user.firstName, user.lastName].join(" ") +"</p></div>"+
            "<div class='inline'> <p class='inline-text title-swal'>Reference Code:</p><p class='inline-text highlights'>"+details.refCode +"</p></div>"+
            "<div class='inline floatRight'> <p class='inline-text title-swal'>Date:</p><p class='inline-text highlights'>"+moment(startedAt).format("MMMM DD, YYYY") + " - " +  moment(endedAt).format("MMMM DD, YYYY")+"</p></div></div>"+
            "<div class='floating-div'><textarea class='floating-input floating-textarea' readonly>"+description+"</textarea><label class='floating-text'>Description</label></div>",
    
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#2A3143",
          cancelButtonColor: "#2A3143",
          customClass: {
            header: 'header-class',
            container: 'container-class',
            popup: 'popup-class',
            actions: 'actions-class',
            cancelButton: 'cancel-button-class',
            confirmButton: 'confirm-button-class',
          },
          reverseButtons:true,
          cancelButtonText: "CLOSE",
          confirmButtonText:"UPDATE",
          background: "#2E3549",
        });

        
      };
    

    const markApproved = (id) => {


        let tech = props.technical.success

          confirmationModal(tech[0], support.description, support.startedAt, support.endedAt || tech[0].description, tech[0].startedAt, tech[0].endedAt, requestStatus.onHold).then((result) => {
            if (result.value) {
              const updateTech = {

                _id: id,
                subject: tech[0].subject || "Tech Support",
                type: tech[0].type,
                subtype: tech[0].subtype,
                status: support.status,
                approvalRemark: support.approvalRemark,
                approvedAt: support.approvedAt,
                approvedBy: support.approvedBy
              };
    
              const updateNotif = {
                type: 'admin',
                title: "Tech Request Approved",
                content: "Your Tech request was approved.",
                user_id: support.user_id,
                recurring: "true"
              };
              setModal(false);
              setRender(true);
    
              dispatch(essActions.approveTechSuppport(updateTech, goBackPath));
              dispatch(notifActions.createNotif(updateNotif));
            }
          });

    };

    const markHold = (id) => {

        let tech = props.technical.success

          confirmationModal(tech[0], support.description, support.startedAt, support.endedAt || tech[0].description, tech[0].startedAt, tech[0].endedAt, requestStatus.onHold).then((result) => {
            if (result.value) {
              const updateTech = {
                _id: id,
                subject: tech[0].subject || "Tech Support",
                type: tech[0].type,
                subtype: tech[0].subtype,
                status: support.status,
                notedRemark: support.notedRemark,
                notedAt: support.notedAt,
                notedBy: support.notedBy
              };
    
              const updateNotif = {
                type: 'admin',
                title: 'Tech Request On-Hold',
                content: 'Your Tech request was on-hold',
                user_id: support.user_id,
                recurring: 'true'
              };
              setModal(false);
              setRender(true);
    
              dispatch(essActions.holdTechSUpport(updateTech, goBackPath));
              dispatch(notifActions.createNotif(updateNotif));
            }
          });
      };

      const markRejected = (id) => {

        let tech = props.technical.success

          confirmationModal(tech[0], support.description, support.startedAt, support.endedAt || tech[0].description, tech[0].startedAt, tech[0].endedAt, requestStatus.onHold).then((result) => {
            if (result.value) {
              const updateTech = {

                _id: id,
                subject: tech[0].subject || "Tech Support",
                type: tech[0].type,
                subtype: tech[0].subtype,
                status: support.status,
                rejectedRemark: support.rejectedRemark,
                rejectedBy: support.rejectedBy,
                rejectedAt: support.rejectedAt
              };
    
              const updateNotif = {
                    type: 'admin',
                    title: 'Tech Request Rejected',
                    content: 'Your Tech request was rejected.',
                    user_id: support.user_id,
                    recurring: 'true'
              };
              setModal(false);
              setRender(true);
    
              dispatch(essActions.reject(updateTech, goBackPath));
              dispatch(notifActions.createNotif(updateNotif));
            }
          });

      };

    const markUpdate = (id) => {

        const { technical } = props || {};
        const technicalList = (technical.success) || [];

        let name;

        // eslint-disable-next-line
        technicalList.map((techUpdate) => {
            name = techUpdate.users[0].firstName + " " + techUpdate.users[0].lastName
        });
        
        Swal.fire({
            title: "<p class='swal-title-modal'> Are you sure? </p>",
            html:
              " <div class='first-column'><div class='inline'><p class='highlights'>"+name+"</p></div>"+
              "<div class='inline'> <p class='inline-text title-swal'>Reference Code:</p><p class='inline-text highlights'>"+request[0]?.refCode +"</p></div>"+
              "<div class='inline floatRight'> <p class='inline-text title-swal'>Date:</p><p class='inline-text highlights'>"+moment(support.startedAt).format("MMMM DD, YYYY") + " - " +  moment(support.endedAt).format("MMMM DD, YYYY")+"</p></div></div>"+
              "<div class='floating-div'><textarea class='floating-input floating-textarea' readonly>"+support.description+"</textarea><label class='floating-text'>Description</label></div>",
      
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#2A3143",
            cancelButtonColor: "#2A3143",
            customClass: {
              header: 'header-class',
              container: 'container-class',
              popup: 'popup-class',
              actions: 'actions-class',
              cancelButton: 'cancel-button-class',
              confirmButton: 'confirm-button-class',
            },
            reverseButtons:true,
            cancelButtonText: "CLOSE",
            confirmButtonText:"UPDATE",
            background: "#2E3549",
        }).then((result) => {
            if (result.value) {
                technicalList.map((techHold) => {
                    if (techHold._id === id) {

                        const updateConcern = {
                            _id: id,
                            status: "new",
                            subject: techHold.subject || "HR Concern",
                            type: techHold.type,
                            subtype: techHold.subtype,
                            description: support.description ? support.description : techHold.description, 
                            startedAt: support.startedAt ? support.startedAt : techHold.startedAt,
                            endedAt: support.endedAt ? support.endedAt : techHold.endedAt,
                          };
                          const updateNotif = {
                            type: "admin",
                            title: "HR Request Updated",
                            content: "Your HR request was updated.",
                            user_id: technicalList[0].user_id,
                            recurring: "true",
                          };
                          setUpdate(false);
                          setRender(true);
                        // //   // dispatch(hrConcernActions.update(updateConcern));
                          dispatch(essActions.update(updateConcern, goBackPath));
                          dispatch(notifActions.createNotif(updateNotif));
                    }
                    return techHold;
                })
            }
        })
    };

    console.log(support)
    return (
        <CustomContentWrapper displayUserWrapper={isDisplayUserDetails} style={{ gridTemplateColumns:"70% 27%"}}>

            <ContentWrapper className="div-main">
                <LabelCustom className="main-label">
                    Review Tech Support Application
                </LabelCustom>

                {/* {request[0]?.notedRemark && support.status !== "new" ? (
                    <div className="col s12 m12 l12 remark-dark">
                        <p className="info" style={{ color: "#B1822D", marginTop: "0",}}>
                            On hold remark :{" "}
                            <span style={{color: "#9BA3CA", fontWeight: "normal",textTransform: "lowercase",}}>
                                {request[0]?.notedRemark}
                              </span>
                            </p>
                          </div>
                        ) : ( "" )}
                {support.rejectedRemark && update !== true? (
                    <div className="col s12 m12 l12 remark-dark">
                        <p className="info"style={{ color: "#AE555C", marginTop: "0",}}>
                            Rejection remark :{" "}
                            <span style={{color: "#9BA3CA",fontWeight: "normal",textTransform: "lowercase"}}>
                                {support.rejectedRemark}
                              </span>
                            </p>
                          </div>
                        ) : ("")}
                {support.approvalRemark && update !== true? (
                    <div className="col s12 m12 l12 remark-dark">
                        <p  className="info" style={{ color: "#2B7A69",marginTop: "0" }}>
                            Approval remark :{" "}
                        <span style={{ color: "#9BA3CA",fontWeight: "normal",textTransform: "lowercase"}}>
                            {support.approvalRemark}
                        </span>
                        </p>
                    </div>
                    ) : ( "" )} */}

                {/* TechSupport Status */}
                {/* <div className='app_status'>
                    <p className="status">
                        Application Status :
                        <span className={support.status === "new" ? "new" : support.status}>{' '}
                            {support.status === "new" 
                              ? "In review" 
                              : support.status && support.status === "approved" 
                              ? "Completed" 
                              : support.status && support.status === "on hold" ? 'In progress' : support.status}
                        </span>
                    </p>
                    <div className="col l6 m6 s12">
                        <p className="status">
                            Date Filed :{" "}
                                <span className="rr-text-content">
                                    {moment(support.createdAt).format(
                                         "dddd, MMMM DD YYYY"
                                    )}
                                </span>
                        </p>
                        </div>
                </div> */}

                <div className='app_status'>
                    <div className="row">
                        <div className="col l6 m6 s12">
                        <p className="status">
                            Application Status :
                            <span className={support.status === "new" ? "new" : support.status}>{' '}
                                {support.status === "new" 
                                ? "In review" 
                                : support.status && support.status === "approved" 
                                ? "Completed" 
                                : support.status && support.status === "on hold" ? 'In progress' : support.status}
                            </span>
                        </p>
                        </div>
                        <div className="col l6 m6 s12">
                        <p className="status">
                        Date Filed :{" "}
                            <span className="rr-text-content">
                            {moment(support.createdAt).format(
                                "dddd, MMMM DD YYYY"
                            )}
                            </span>
                        </p>
                        </div>
                    </div>
                </div>

                {/* Date Request */}
                <div className="row" style={{marginBottom:'0px'}}>
                    <div className="col l6 m6 s12" style={{ paddingLeft: "0px" }}>

                            {support.status === "approved" || support.status === "rejected" || support.status === "completed" || support.status === "cancelled" ?
                                <CustomDatePicker
                                    selected={new Date(moment(support.startedAt).utc().format("YYYY-MM-DD"))}
                                    minDate={startDate2}
                                    showMonthDropdown
                                    showYearDropdown
                                    label="From"
                                    readOnly={true}
                                    value={moment(support.startedAt).format('MM - D - YYYY')}
                                />
                                :

                                <CustomDatePicker                              
                                    selected={startDate2 && update === false ? startDate2  :  support.startedAt ? new Date(moment(support.startedAt).utc().format("MMMM DD, YYYY")) : endDate2 }
                                    selectsStart
                                    startDate={startDate2}
                                    endDate={endDate2}
                                    onChange={(date) => handleChangeStart2(date)}
                                    className="floating-input calendar-dark"
                                    id="abc"
                                    placeholderText=" "
                                    dateFormat="MM-dd-yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    label="From"
                                />
                             }
                        </div>
                        <div className="col l6 m6 s12">
                                {support.status === "approved" || support.status === "rejected" || support.status === "completed" || support.status === "cancelled"?
                                    <CustomDatePicker
                                        selected={new Date(moment(support.endedAt).utc().format("YYYY-MM-DD"))}
                                        minDate={new Date(startDate2)}
                                        label="To"
                                        readOnly={true}
                                        value={moment(request[0]?.endedAt).format('MM - D - YYYY')}
                                    />
                                    :

                                    <CustomDatePicker
                                        selected={endDate2}
                                        selectsEnd
                                        startDate={startDate2}
                                        endDate={endDate2}
                                        minDate={startDate2}
                                        onChange={(date) => handleChangeEnd2(date)}
                                        label="To"
                                    />
                                }
                        </div>

                    </div>
                    
                    {/* Description */}
                    <div className="row textarea-column" style={{marginBottom:'0px'}}>
                        <div className="col s12 m12 l12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>

                            {support.status === "approved" || support.status === "rejected" || support.status === "completed" || support.status === "cancelled" ?
                                
                                <CustomTextArea
                                    value={support.description}
                                    readOnly={true}
                                    label='Description'
                                />
                                :
                                <CustomTextArea
                                    value={support.description}
                                    onChange={descriptionChange2}
                                    label='Description'
                                />
                            }
                                                
                        </div>
                    </div>

                    <p></p>

                    <div className="div-attach" style={{marginBottom:'20px'}}>
                      <p className="info" style={{ marginTop: "0" }}>
                          Attachments
                      </p>

                      {request[0]?.files?.length !== 0 || request[0]?.files === 'undefined' || request[0]?.files === '' || request[0]?.files === null ? (
                          request[0]?.files?.map((c) => (
                              // <p>{c.split('-').pop()}</p>
                              <div key={c} className="attach-div">
                                  <div className="attach-preview">
                                      <img
                                          src={c}
                                          className="attach-file"
                                          alt="Thumb"
                                          onError={(e) => (e.target.src = fileIcon) }
                                      />
                                          <p onClick={() => { openFilePreview(); setFileUrl(c); setFileType(c.split('.').pop()) }} style={{cursor:'pointer'}}>{c.split('/').pop()}</p>
                                  </div>
                                  <a href={c} download>
                                      <button className="btn-download" style={{background:'none'}} >
                                          <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'contrast(0.5)' }}/>
                                      </button>
                                  </a>
                              </div>
                          ))
                      ) : (
                      <>
                          <div className="attach-div">
                              <div className="attach-preview">
                                  <p>No File Attached</p>
                              </div>
                          </div>
                      </>
                      )}
                    </div>

                    <div>
                      <Modal
                        isOpen={showFilePreview}
                        ariaHideApp={true}
                        toggle={setFilePreview}
                        onRequestClose={() => setFilePreview(false)}
                        shouldCloseOnOverlayClick={true}
                        className="fileModalPreview"
                        style={customStyles}
                      >
                        <div
                          className="row center rejecting-container"
                          style={{
                            width: "auto",
                            height: "auto", 
                            margin: "auto",
                            padding: "50px",
                          }}
                        >
                        <div className="gAction">
                            <a href={FileUrl} download>
                                <button className="btn-download" style={{background:'none'}}>
                                    <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'brightness(5.5)' }}/>
                                </button>
                            </a>
                            <img height={20} onClick={() => setFilePreview(false)} src={close} alt="" style={{ margin:'15px 7px', filter: 'brightness(5.5)', cursor:'pointer' }}/>
                        </div>
                        <div style={{ height: '100%' }}>
                        <FileViewer 
                            fileType={FileType} 
                            filePath={FileUrl} 
                            key= {FileUrl}
                        />
                        </div>

                        </div>
                      </Modal>
                    </div>
                    
                          <ApproverDetails
                            details={
                              support.status ===
                                requestStatus.approved ||
                              support.status ===
                                requestStatus.completed
                                ? UserApproverView
                                : support.status ===
                                  requestStatus.onHold
                                ? UserNoterView
                                : support.status ===
                                  requestStatus.rejected
                                ? UserRejecterView
                                : []
                            }
                            manager={UserDetailsView[0]?.manager}
                            status={support.status}
                            date={
                              support.status ===
                                requestStatus.approved ||
                              support.status ===
                                requestStatus.completed
                                ? support.approvedAt
                                : support.status ===
                                  requestStatus.onHold
                                ? support.notedAt
                                : support.status ===
                                  requestStatus.rejected
                                ? support.rejectedAt
                                : ""
                            }
                          />
                          {/* {support.status !== requestStatus.new && (
                                <CustomTextArea
                                value={
                                    support.status ===  requestStatus.approved 
                                    || support.status === requestStatus.completed ? request[0]?.approvalRemark : 
                                    support.status === requestStatus.onHold ? request[0]?.notedRemark
                                    : support.status ===  requestStatus.rejected ? request[0]?.rejectedRemark
                                    : ""
                                  }
                                readOnly='true'
                                label='Remarks'
                            />
                          )}   */}

                {/* ------------------------------------- BUTTONS -------------------------------------------- */}
                <div className="row review-buttons">
                    <div className="col s1 m8 l8 buttonHROne" style={{ width: "60%", padding: "0 0" }}>
                        {support.status === "new" || support.status === "on hold" ?
                            <div>
                                {support.user_id !== admin.user._id ?
                                <span className='btn-review-span'>
                                    <button className="btn btn-approve-dark" onClick={onApproveOpenModal} >Completed</button>
                                    <button className="btn btn-hold-dark" onClick={onHoldOpenModal} >In&nbsp;Progress</button>
                                    <button className="btn btn-reject-dark" onClick={onRejectOpenModal} >Reject</button>
                                </span>
                        : ''}
                        </div>
                        : ''}
                    </div>
                    <div className="col s1 m4 l4 right buttonHRTwo" style={{ width: "40%", padding: "0 0" }}>
                    
                        <span>
                            {support.status === "new" || support.status === "on hold" ?
                            <button className={isAdd === false ? "btn btn-update disabled right" : "btn btn-update right update-btn"} onClick={() => {markUpdate(support._id)}}>UPDATE&nbsp;CHANGES</button>
                            : ''}
                            
                        </span>
                        <button onClick={goBack} className="btn right cancelDark-tech">CANCEL</button>

                    </div>
                </div>

                    {/* ------------------------------------- END BUTTONS -------------------------------------------- */}

                    <Modal
                        isOpen={showAppModal}
                        ariaHideApp={true}
                        toggle={setAppModal}
                        onRequestClose={() => setAppModal(false)}
                        shouldCloseOnOverlayClick={true}
                        className="AnnouncementModalBG"
                      >
                        <div
                          className="row center approval-container"
                          style={{
                            width: "auto",
                            height: "auto",
                            margin: "auto",
                            padding: "50px",
                          }}
                        >
                          <p className="coe-info left">
                          Please Add Remarks :
                          </p>
                          <div className="col s12 m12 l12">
                                <CustomTextArea
                                    value={support.approvalRemark}
                                    onChange={onApproveChange}
                                    label='Remarks'
                                />
                          </div>

                          <div className="col s10 m10 l10 coe-buttons">
                            <span className="right-dark">
                              <button
                                className="btn coe-btn-cancel-dark"
                                onClick={handleCloseModal}
                              >
                                Cancel{" "}
                              </button>
                              <button
                                className="btn coe-btn-approve-dark"
                                onClick={() => {
                                  markApproved(support._id);
                                }}
                              >
                                Completed
                              </button>
                            </span>
                          </div>
                        </div>
                      </Modal>
                      <Modal
                        isOpen={showModal}
                        ariaHideApp={true}
                        toggle={setModal}
                        onRequestClose={() => setModal(false)}
                        shouldCloseOnOverlayClick={true}
                        className="AnnouncementModalBG"
                      >
                        <div
                          className="row center onhold-container"
                          style={{
                            width: "auto",
                            height: "auto",
                            margin: "auto",
                            padding: "50px",
                          }}
                        >
                          <p className="coe-info left">
                            Please Add Remarks :
                          </p>
                          <br />
                          <div className="col s12 m12 l12">
                          {
                                    support.status === 'new' || support.status === 'on hold'?
                                    <CustomTextArea
                                        onChange={onHoldChange}
                                        label='Remarks'
                                    />
                                    :
                                    <CustomTextArea
                                        value={support.notedRemark}
                                        onChange={onHoldChange}
                                        label='Remarks'
                                    />
                                }

                          </div>

                          <div className="col s10 m10 l10 coe-buttons">
                            <span className="right-dark">
                              <button
                                className="btn coe-btn-cancel-dark"
                                onClick={handleCloseModal}
                              >
                                Cancel{" "}
                              </button>
                              <button
                                className="btn coe-btn-hold-dark size-btn"
                                onClick={() => {
                                  markHold(support._id);
                                }}
                              >
                                IN PROGRESS
                              </button>
                            </span>
                          </div>
                        </div>
                      </Modal>
                      <Modal
                        isOpen={showRejModal}
                        ariaHideApp={true}
                        toggle={setRejModal}
                        onRequestClose={() => setRejModal(false)}
                        shouldCloseOnOverlayClick={true}
                        className="AnnouncementModalBG"
                      >
                        <div
                          className="row center rejecting-container"
                          style={{
                            width: "auto",
                            height: "auto",
                            margin: "auto",
                            padding: "50px",
                          }}
                        >
                          <p className="coe-info left">
                            Please Add Remarks :
                          </p>
                          <br />

                          <div className="col s12 m12 l12">
                                <CustomTextArea
                                    value={isAdd === false ? support.rejectedRemark:''}
                                    onChange={onRejectChange}
                                    label='Remarks'
                                />
                          </div>
                          <div className="col s10 m10 l10 coe-buttons">
                            <span className="right-dark">
                              <button
                                className="btn coe-btn-cancel-dark"
                                onClick={handleCloseModal}
                              >
                                Cancel{" "}
                              </button>
                              <button
                                className="btn coe-btn-reject-dark"
                                onClick={() => {
                                  markRejected(support._id);
                                }}
                              >
                                Reject
                              </button>
                            </span>
                          </div>
                        </div>
                      </Modal>

            </ContentWrapper>

            <When condition={isDisplayUserDetails === true}>
                <PreviewLeaveUserWidget 
                    {...props} 
                    subjectId={UserDetailsView} 
                    availableCredits='false'
                    comments ={comment}
                    replies = {reply}
                    request = {support}
                    isReply={Object.keys(comment).length !== 0}
                />
            </When>
                
        </CustomContentWrapper>
    )
});


export default ReviewTech;
