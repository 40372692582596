import { post, get, put } from '../helpers/api';
import { userService } from '../services/user';

export const leaveCreditService = {
    getAll,
    addleaveCredit,
    update,
    getUser,
    editCreditStatus
};

function getAll() {
    return get('/api/v1/leave-credit').then(handleResponse);
}

function addleaveCredit(leaveCredits){
    return post('/api/v1/leave-credit', JSON.stringify(leaveCredits))
        .then(handleResponse)
}

function update(leaveCredit) {
    let id = leaveCredit._id;
    return put(`/api/v1/leave-credit/${id}`, JSON.stringify(leaveCredit))
        .then(handleResponse);
}

function getUser(id) {
    return get(`/api/v1/user-leave-credit/${id}`).then(handleResponse);
}

function editCreditStatus(leaveCreditId){
    let creditID = leaveCreditId._id
    return put(`/api/v1/leave-credit/${creditID}`, JSON.stringify(leaveCreditId))
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}