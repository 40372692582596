import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../../_core/actions/user';
import { alertActions } from '../../_core/actions/alert';


import logo from  '../../LoginPage/appicon-web.png';
import loading from '../../LoginPage/Ellipsis-1.6s-60px.svg'

import './overlay.sass'
import '../../RegisterPage/Register.sass'
class Overlay extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            user: {
                open: false,
                email          : '',
                team           : '',
                password       : '',
                confirmPassword : '',
                startDate      :  new Date(Date.now()).toISOString()
            },
            appClass: 'myClass',
            submitted: false,
            render: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.alertTimeOut = this.alertTimeOut.bind(this);
    }

     componentDidMount() {
		window.addEventListener('scroll', (event) => {
         if(window.pageYOffset > 300){
             this.setState({ appClass: 'myModal' });
         } else {
         	this.setState({ appClass: 'show' });
         }
      });
    }

	alertTimeOut = () =>
        setTimeout(function() {
            this.setState({render: false})
            this.props.dispatch(alertActions.clear());
        }.bind(this), 4000
    )

    componentWillUnmount(){
        clearTimeout(this.alertTimeOut)
    }
    handleClose(event){
        event.preventDefault()
        this.setState({render: false});
        this.props.dispatch(alertActions.clear());
        clearTimeout(this.alertTimeOut)
    }
    alertTimeOut = () =>
        setTimeout(function() {
            this.setState({render: false})
            this.props.dispatch(alertActions.clear());
        }.bind(this), 5000
    )
    handleChange(event) {
        const { name, value } = event.target;
        const { user }        = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true,
                        render: true });
        const { user }     = this.state;
        const { dispatch } = this.props;

        if (user.email && user.team && user.password && user.confirmPassword && user.startDate && user.password === user.confirmPassword) {
            dispatch(userActions.register(user));
            setTimeout(function() {
                this.setState({render: false})
                this.props.dispatch(alertActions.clear());
            }.bind(this), 5000)
        }
    }

	render() {
        const { registering, alert }    = this.props;
        const { user, submitted, render } = this.state;

		return (
	      		<div className={`overlay ${this.state.appClass}`}>
		        	<div className="register">
		        		<div className="modal-login">
		        			<div className="row">
                                <div className="col l6 m6 s12">
                                    <h3 className="register-title center-align">REGISTER ACCOUNT</h3>
                                    <Link to="/"><img src={logo} className="logo" alt="logo"/></Link>
                                </div>
                                <div className="col l6 m6 s12">
                                    <form name="form" className="overlay-form" onSubmit={this.handleSubmit} >
                                        {alert.message && render &&
                                            <div id="alert" className={`alert ${alert.type}`} toggle={this.onDismiss}>{alert.message}<span className="closebtn right" onClick={this.handleClose}>&times;</span></div>
                                        }
                                         <div className={'input-field' + (submitted && !user.email ? ' has-error' : '')}>
                                            <label htmlFor="email">Email</label>
                                            <input type="email" className="validate form-control" name="email" value={user.email} onChange={this.handleChange} />
                                            {submitted && !user.email &&
                                            <div className="helper-text red-text">Email is required</div>
                                            }
                                        </div>
                                        <div className={'input-field' + (submitted && !user.team ? ' has-error' : '')}>
                                            <label htmlFor="team">Team</label>
                                            <input type="text" name="team" className="validate form-control" value={user.team} onChange={this.handleChange} />
                                            {submitted && !user.team &&
                                            <div className="helper-text red-text">Team is required</div>
                                            }
                                        </div>
                                        <div className={'input-field' + (submitted && !user.password ? ' has-error' : '')}>
                                            <label htmlFor="password">Password</label>
                                            <input type="password" name="password" className="validate form-control" value={user.password} onChange={this.handleChange} />
                                            {submitted && !user.password &&
                                            <div className="helper-text red-text">Password is required</div>
                                            }
                                        </div>
                                        <div className={'input-field' + (submitted && !user.confirmPassword ? ' has-error' : '')}>
                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                            <input type="password" name="confirmPassword" className="validate form-control" value={user.confirmPassword} onChange={this.handleChange} />
                                             {submitted && user.password !== user.confirmPassword && 
                                                <div className="help-block red-text">Password did not match</div>
                                             }
                                            
                                        </div>

                                        <div className="input-field center-align">
                                            <button className="button btn-register">Continue</button>
                                            {registering &&
                                                <img alt="" src={loading} id="loading"/>
                                            }
                                        </div>
                                    </form>
                                </div>
		          			</div>
		          		</div>
		          	</div>
		        </div>
		);
	}
}

function mapStateToProps(state) {
    const { registering } = state.registration;
    const { alert } = state
    return {
        registering,
        alert
    };
}

const connectedOverlay = connect(mapStateToProps)(Overlay);
export { connectedOverlay as Overlay }; 