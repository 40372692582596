import React from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { maritalStatus, level, department, position, title, gender } from '../../_core/constants/selections'

import { hex } from "../../_core/helpers/profile";
import { hrm } from '../../_core/helpers/config'; //check regularization date
import { decryptUserEmail } from '../../_core/helpers/crypto_config';
import { ValidateEmail } from '../../_core/helpers/validateEmail';
import { UsernameCreator } from '../../_core/helpers/usernameCreator';
import { history } from '../../_core/helpers/history';

import { userActions } from '../../_core/actions/user';
import { userService } from '../../_core/services/user';
import { roleService } from '../../_core/services/role';
import { officeService } from '../../_core/services/office';

import avatarIcon from '../../_templates/dark_avatar_photo.png';
import coverIcon from '../../_templates/dark_cover_photo.png';

import { WarningPopup } from '../Components/AdminModals/WarningPopup';
import ImageUploadModal from './ImageUploadModal';

import './CreateEditUser.scss';

const IsoFormat = (date) => moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z";

const PhotoBorder = (props) => {
    return (
        <div className="dashed-border">
            <div><div><div><div>{props.children}</div></div></div></div>
        </div>
    )
}

const WarningMessage = (message) => {
    WarningPopup("warning", message, false, true).then((res) => {
        // 
    });
}

const AdminCustomDatePicker = ({ placeholder = "No data", value = "", className = "", dateOnChange, label, disabled = false, isError = false, errorMessage = "required field" }) => {
    const [date, setDate] = React.useState("");

    React.useEffect(() => {
        setDate(value);
    }, [value]);

    const handleDatePicker = (date) => {
        const toISODate = moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z";
        setDate(toISODate);
        dateOnChange(toISODate);
    }

    return (
        <div className={"admin-datepicker-wrapper " + className}>
            <DatePicker
                name="birthDate"
                selected={date ? new Date(date) : new Date()}
                onChange={(d) => handleDatePicker(d)}
                showMonthDropdown
                showYearDropdown
                value={date === '' ? placeholder : moment(date).format('MM - DD - YYYY')}
                className={isError ? "datepicker is--error" : "datepicker"}
                disabled={disabled}
            />
            <label className={date ? "is--visible" : ""}>{label}</label>
            {isError &&
                <p className="admin-input-error-msg">{errorMessage}</p>
            }
        </div>
    )
}

const AdminCustomTextInput = ({ isTextArea = false, isNumberInput = false, numberInputLimit = 0, placeholder = "", handleChange, value = "", name = "", id = "", label = "", className = "", disabled = false, isError = false, errorMessage = "required field" }) => {

    const textOnChange = (e) => {
        e.preventDefault();
        let getValue = e.target.value;
        let valRemoveNonDigit = getValue.replace(/[^0-9\+]/g, '');// eslint-disable-line
        e.target.value = valRemoveNonDigit.slice(0, numberInputLimit === 0 ? valRemoveNonDigit.length : numberInputLimit);
        handleChange(e);
    }

    return (
        <React.Fragment>
            {isNumberInput ?
                <div className={"admin-input-wrapper " + className + `${isError ? " is--error" : ""}`}>
                    <input className="admin-input" type="text" onChange={textOnChange} value={value} name={name} disabled={disabled} id={id} required/>
                    <label>{label}</label>
                    {isError &&
                        <p className="admin-input-error-msg">{errorMessage}</p>
                    }
                </div>
                :
                isTextArea ?
                    <div className={"admin-input-wrapper " + className + `${isError ? " is--error" : ""}`}>
                        <textarea className="admin-textarea" type="text" onChange={handleChange} value={value} name={name} id={id} required />
                        <label>{label}</label>
                        {isError &&
                            <p className="admin-input-error-msg">{errorMessage}</p>
                        }
                    </div>
                    :
                    <div className={"admin-input-wrapper " + className + `${isError ? " is--error" : ""}`}>
                        <input className="admin-input" type="text" onChange={handleChange} value={value} name={name} disabled={disabled} id={id} required />
                        <label>{label}</label>
                        {isError &&
                            <p className="admin-input-error-msg">{errorMessage}</p>
                        }
                    </div>
            }
        </React.Fragment>
    );
}

const AdminCustomDropdown = ({ value, placeholder = "", options = [{ value: '', label: '' }], onChange, className, label, isError = false, errorMessage = "required field" }) => {
    const [selectedOption, setSelectedOption] = React.useState(null);

    const handleChange = (option) => {
        setSelectedOption(option);
        onChange(option);
    }

    React.useEffect(() => {
        setSelectedOption(value);
    }, [value]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: "40px",
            width: "100%",
            backgroundColor: "#2E3648",
            border: isError ? "1px solid #F14646" : state.isSelected ? "1px solid #556EE6" : "1px solid transparent",
            ":hover": {
                border: isError ? "1px solid #F14646" : "1px solid transparent",
            },
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            margin: "0px 6px",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#9BA4C7",
            fontSize: "14px",
        }),
        input: (provided, state) => ({
            ...provided,
            height: "40px",
            color: "#9BA4C7",
            fontSize: "14px",
            // padding: "12px 0px",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && "rotate(180deg)",
            transition: "0.5s",
        }),
        indicatorSeparator: (state) => ({
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "40px",
        }),
        option: (base, state) => ({
            ...base,
            color: "#9BA4C7",
            backgroundColor: state.isSelected ? "#696969" : "#2A3143",
            ":hover": {
                backgroundColor: state.isSelected ? "#696969" : "#32394A",
            },
        }),
        placeholder: (provided, state) => ({
            color: "#9BA4C7",
            fontSize: "14px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#2E3648",
            maxHeight: "200px",
        }),
        menuList: (base) => ({
            ...base,
            padding: "0",
        }),
    }

    return (
        <div className={"admin-dropdown-wrapper " + className}>
            <Select
                value={selectedOption}
                onChange={handleChange}
                options={options}
                styles={customStyles}
                placeholder={placeholder}
            />
            <label className={selectedOption?.value ? "is--visible" : ""}>{label}</label>
            {isError &&
                <p className="admin-input-error-msg">{errorMessage}</p>
            }
        </div>
    )
}

const UserProfileView = (props) => {
    const [user, setUser] = React.useState({});
    const [isProfileVisible, setIsProfileVisible] = React.useState(false);
    const [uploadedAvatar, setUploadedAvatar] = React.useState(null);
    const [uploadedCover, setUploadedCover] = React.useState(null);

    React.useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    React.useEffect(() => {
        setUploadedAvatar(props.uploadedAvatar);
        setUploadedCover(props.uploadedCover);
    }, [props.uploadedAvatar, props.uploadedCover]);

    const detailContent = (title, content) => {
        return (
            <div>
                <p>{title}</p>
                <p>{content ? content : "-"}</p>
            </div>
        )
    }

    return (
        <div className="adminUser-create-edit-profile-view-wrapper">
            <div className="adminUser-create-edit-profile-view-image-card">{/*image*/}
                <div>{/*cover*/}
                    {uploadedCover || user.cover ?
                        <img src={uploadedCover ? uploadedCover : user.cover} alt="cover" />
                        :
                        <div style={{ backgroundColor: hex(user.firstName || user.lastName ? user.firstName + user.lastName : "FFFFFF") }}></div>
                    }
                </div>
                <div>{/*avatar + name wrapper -flex*/}
                    <div>
                        {uploadedAvatar || user.avatar ?
                            <img src={uploadedAvatar ? uploadedAvatar : user.avatar} alt="avatar" />
                            :
                            <div className="profile-view-avatar-initials" style={{ backgroundColor: hex(user.firstName || user.lastName ? user.firstName + user.lastName : "FFFFFF") }}>
                                {[user.firstName?.length > 0 ? user.firstName.charAt(0) : "", user.lastName?.length > 0 ? user.lastName.charAt(0) : ""].join("")}
                            </div>
                        }
                    </div>
                    <div>
                        <p>{user.firstName || user.middleName || user.lastName ? [user.firstName, user.middleName, user.lastName].join(" ") : "-"}</p>
                        <p>{user.position || "-"}</p>
                    </div>
                </div>
            </div>
            <div className={isProfileVisible ? "adminUser-create-edit-profile-view-details is--visible" : "adminUser-create-edit-profile-view-details"}>{/*details - hidden or visible*/}
                <p>Profile Information</p>
                {detailContent("Bio", user.bio)}
                {detailContent("Address Line 1", user.address1)}
                {detailContent("Address Line 2", user.address2)}
                {detailContent("City", user.city)}
                {detailContent("State", user.state)}
                {detailContent("Country", user.country)}
                {detailContent("Contact Number", user.contactNumber)}
                {detailContent("Nationality", user.nationality)}
                {detailContent("Mother's Maiden Name", user.mothersMaidenName)}
                {detailContent("Rfid", user.rfid)}
                {detailContent("Esn", user.esn)}
                {detailContent("Marital Status", user.maritalStatus)}
                {detailContent("Gender", user.gender)}
            </div>
            <div className="adminUser-create-edit-profile-view-btn" onClick={() => setIsProfileVisible(!isProfileVisible)}>{/*show button*/}
                <p>{isProfileVisible ? "Show Less" : "Show Profile"}</p>
            </div>
        </div>
    );

}

const CreateEditUser = (props) => {
    const dispatch = useDispatch();

    const isEditMode = props.mode === "edit";
    const isAddMode = props.mode === "create";

    const [user, setUser] = React.useState({});
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const maxStep = 4;
    //STEP 1
    const [openModal, setOpenModal] = React.useState(false);
    const [isProfileImage, setIsProfileImage] = React.useState(true);
    const [avatarSrc, setAvatarSrc] = React.useState(null);
    const [coverSrc, setCoverSrc] = React.useState(null);
    const [avatarUpload, setAvatarUpload] = React.useState(null);
    const [coverUpload, setCoverUpload] = React.useState(null);
    const [hasPraggerUsername, setHasPraggerUsername] = React.useState(false);
    const [isPraggerCodeValid, setIsPraggerCodeValid] = React.useState(true);
    const [isEmailValid, setIsEmailValid] = React.useState(true);
    const [isAltEmailValid, setIsAltEmailValid] = React.useState(true);
    const praggerCodeLimit = 6;
    const rfidLimit = 10;

    const maritalStatusList = maritalStatus.default;
    const jobLevelsList = level.software;
    const positionsList = position.software;
    const departmentsList = department.software;
    const titlesList = title.default;
    const gendersList = gender;

    const [usernamesList, setUsernames] = React.useState([]);
    const [rolesList, setRoles] = React.useState([]);
    const [userManagersList, setManagers] = React.useState([]);
    const [officesList, setOffices] = React.useState([]);

    const adminCred = JSON.parse(localStorage.getItem('user'))

    React.useEffect(() => {
        officeService.getAll().then(res => {
            const getOffices = res?.success;
            const officeList = getOffices?.filter(office => office.name)?.map(office => {
                return {
                    label: office.name,
                    value: office._id
                }
            });
            setOffices(officeList);
        }).catch(err => console.error(err));
    }, [setOffices]);

    React.useEffect(() => {
        userService.getAll(1, 9999999).then(users => {
            const getUsernames = users?.items?.filter(u => user?._id !== u._id && u.praggerUsername.length > 0).map(u => u.praggerUsername);
            setUsernames(getUsernames);
        }).catch(err => console.error(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUsernames, user._id]);

    React.useEffect(() => {
        roleService.getAll(1, 999999).then(res => {
            const role = res.items;
            const roleList = role?.map((r) => {
                return {
                    label: r.roleName,
                    value: r._id
                }
            });
            setRoles(roleList)
        }).catch(err => console.error(err));
    }, [setRoles]);

    React.useEffect(() => {
        let ManagerRoles = rolesList?.filter(role => role.label.toLowerCase().includes("manager"))?.map(role => role.value);

        userService.getAllActive().then(users => {
            const getManagers = users?.items?.filter(u => ManagerRoles?.includes(u.role))?.map(u => {
                return {
                    label: [u.details[0]?.firstName, u.details[0]?.lastName].join(" "),
                    value: u._id
                }
            });
            setManagers(getManagers);

            //Added useradmin login to the list of manager. 
            const adminDetails = {
                label: [adminCred?.firstName, adminCred?.lastName].join(" "),
                value: adminCred.user_id
            }
            let isFounded = getManagers.some( ai => adminDetails.value.includes(ai.value) ); // check if already in the list.
            if(isFounded === false){
                setManagers([...getManagers, adminDetails]);
            }// if not in the list push items in the list.

        }).catch(err => console.error(err));
        //eslint-disable-next-line
    }, [setManagers, rolesList]);
    //

    const formatDate = (date) => {
        return date ? moment(date).format() === "Invalid date" ? "" : IsoFormat(date) : "";
    }

    React.useEffect(() => {
        if (isEditMode && !props.userDetails?._id) {
            history.push('/admin/users/create');

        } else {
            const userEdit = props.userDetails;
            const adminCred = JSON.parse(localStorage.getItem('userCredentials'))
            const details = isEditMode && userEdit?.details[0];

            let reg_date = hrm.regularizationDate;

            setUser({
                role: isEditMode ? userEdit.role : "",
                organization: isEditMode ? userEdit.organization : adminCred?.user.organization,
                email: isEditMode ? decryptUserEmail(userEdit.email) : "",
                praggerUsername: isEditMode ? userEdit.praggerUsername : "",
                praggerCode: isEditMode ? userEdit.praggerCode : "",
                //details
                bio: isEditMode ? details.bio : "",
                avatar: isEditMode ? details.avatar : "",
                cover: isEditMode ? details.cover : "",

                title: isEditMode ? details.title : "",
                firstName: isEditMode ? details.firstName : "",
                lastName: isEditMode ? details.lastName : "",
                middleName: isEditMode ? details.middleName : "",
                suffix: isEditMode ? details.suffix : "",
                address1: isEditMode ? details.address1 : "",
                address2: isEditMode ? details.address2 : "",
                city: isEditMode ? details.city : "",
                state: isEditMode ? details.state : "",
                country: isEditMode ? details.country : "",
                nationality: isEditMode ? details.nationality : "",
                mothersMaidenName: isEditMode ? details.mothersMaidenName : "",
                maritalStatus: isEditMode ? details.maritalStatus : "",
                gender: isEditMode ? details.gender : "",
                birthDate: isEditMode ? formatDate(details.birthDate) : "",
                birthPlace: isEditMode ? details.birthPlace : "",

                rfid: isEditMode ? details.rfid : "",
                esn: isEditMode ? details.esn : "",
                
                contactNumber: isEditMode ? details.contactNumber : "",

                manager: isEditMode ? details.manager : "",
                startDate: isEditMode ? formatDate(details.startDate) : formatDate(new Date()),
                endDate: isEditMode ? formatDate(details.endDate) : "",
                regularizationDate: isEditMode ? formatDate(details.regularizationDate) : IsoFormat(moment(new Date()).add(reg_date, 'M')),
                position: isEditMode ? details.position : "",
                level: isEditMode ? details.level : "",
                department: isEditMode ? details.department : "",
                officeId: isEditMode ? details.officeId : "",

                _id: isEditMode ? userEdit._id : "", //remove if add user
                altemail: isEditMode ? details.altemail : "", //remove if add user
                password: "", //remove if edit user
            });

            setHasPraggerUsername(userEdit.praggerUsername?.length > 0);
            setIsEmailValid(true);
            setIsAltEmailValid(true);
            setIsPraggerCodeValid(true);
        }

    }, [props.userDetails, isEditMode]);

    const toggleModal = (img) => {
        setIsProfileImage(img === 'profile' ? true : false);
        setOpenModal(!openModal);
    }

    const draftImageUpload = (src, file) => {
        if (isProfileImage) {
            setAvatarSrc(src);
            setAvatarUpload(file);

        } else {
            setCoverSrc(src);
            setCoverUpload(file);
        }
    }

    const onChangeHandler = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        setUser({
            ...user,
            [name]: value
        });

        if (name === "praggerCode") {
            setIsPraggerCodeValid(true);
        }
        if (name === "email") {
            setIsEmailValid(true);
        }
        if (name === "altemail") {
            setIsAltEmailValid(true);
        }
    }

    const dropdownOnChangeHandler = (name, opt) => {
        setUser({
            ...user,
            [name]: opt.value
        });
    };

    const dateOnChangeHandler = (name, date) => {
        if (name === 'startDate') {
            let reg_date = hrm.regularizationDate;
            let regDate = IsoFormat(moment(date).add(reg_date, 'M'));
            setUser({
                ...user,
                [name]: date,
                regularizationDate: regDate,
            });
        } else {
            setUser({
                ...user,
                [name]: date,
            });
        }
    };

    const submitInputs = () => {
        switch (step) {
            case 1:
                return proceedToNextStep();
            case 2:
                if (isAddMode) {
                    if (!user.firstName || !user.lastName || !user.address1) {
                        return incompleteFormWarning();
                    }
                }

                if (isEditMode) {
                    if (!user.firstName || !user.lastName || !user.address1 ||
                        !user.city || !user.state || !user.country || !user.nationality || !user.maritalStatus || !user.gender ||
                        !user.birthDate || !user.birthPlace) {
                        return incompleteFormWarning();
                    }
                }

                if (!hasPraggerUsername) {
                    handlePraggerUsername();
                }
                return proceedToNextStep();
            case 3:
                if (!user.role || !user.officeId) {
                    return incompleteFormWarning();
                }
                return proceedToNextStep();
            case 4:
                if (!user.email || !user.praggerCode || !user.manager) {
                    return incompleteFormWarning();
                } else {
                    let checkValidPraggerCode = user.praggerCode.length === praggerCodeLimit;
                    let checkValidEmail = ValidateEmail(user.email);
                    let checkValidAltEmail = isEditMode && user.altemail?.length > 0 ? ValidateEmail(user.altemail) : true;
                    let checkEmptyPassword = isAddMode && !user.password;

                    if (!checkValidPraggerCode) {
                        setIsPraggerCodeValid(false);
                    }
                    if (!checkValidEmail) {
                        setIsEmailValid(false);
                    }
                    if (!checkValidAltEmail) {
                        setIsAltEmailValid(false);
                    }

                    if (!checkValidPraggerCode || !checkValidEmail || !checkValidAltEmail || checkEmptyPassword) {
                        return incompleteFormWarning();
                    }

                    return proceedToNextStep();
                }
            default:
                return;
        }
    }

    const handlePraggerUsername = () => {
        let assignPraggerUsername = UsernameCreator(user.firstName, user.lastName, user.middleName, usernamesList);
        setUser({
            ...user,
            praggerUsername: assignPraggerUsername,
        })
    }

    const incompleteFormWarning = () => {
        WarningMessage(["Unable to proceed", "<p style='font-size: 1rem'>Kindly fill up the required fields</p>"]);
        setIsSubmit(true);
    }

    const proceedToNextStep = () => {
        setIsSubmit(false);

        if (step === maxStep) {
            return saveUser();
        }

        return step < maxStep && setStep(step + 1);
    }

    const saveUser = () => {
        //submit items
        const addUserWarningMessage = ["Are you sure you want to save this user?", `<p style='font-size: 1rem'>${"Name: " + user.firstName + " " + user.lastName}</p>`];
        const editUserWarningMessage = ["Are you sure you want to update details from this user?", `<p style='font-size: 1rem'>${"Name: " + user.firstName + " " + user.lastName}</p>`];

        if (isAddMode) {
            WarningPopup("warning", addUserWarningMessage, true, true).then((res) => {
                if (res?.value) {
                    delete user._id;
                    dispatch(userActions.create(user));
                }
            });
            return;
        }

        if (isEditMode) {
            const avatar = avatarUpload ? avatarUpload : "";
            const cover = coverUpload ? coverUpload : "";

            WarningPopup("warning", editUserWarningMessage, true, true).then((res) => {
                if (res?.value) {
                    if (avatar) {
                        delete user.avatar;
                        dispatch(userActions.uploadAvatar(avatar, user._id));
                    }
                    if (cover) {
                        delete user.cover;
                        dispatch(userActions.uploadCover(cover, user._id));
                    }
                    delete user.password;
                    dispatch(userActions.edit(user));
                }
            });
            return;
        }
    }

    const StepTitle = [
        "Personal Information",
        "Personal Information",
        "Employment Details",
        "User Credentials"
    ]

    return (
        <div className="admin-content-wrapper">
            <div className="adminUser-create-edit-wrapper">
                <p className="admin-content-title">{StepTitle[step - 1]}</p>
                <div className={step !== 1 ? "adminUser-create-edit-content is--profile-view" : "adminUser-create-edit-content"}>
                    <div>
                        {step === 1 &&
                            <div className="adminUser-bio">
                                {isEditMode &&
                                    <div className="adminUser-photos-wrapper">
                                        <div>
                                            <p>Profile Picture</p>
                                            <div className="adminUser-profile-photo-wrapper">
                                                <PhotoBorder>
                                                    <div className="image-wrapper">
                                                        {avatarSrc ?
                                                            <img src={avatarSrc} alt="avatar" />
                                                            :
                                                            <img src={user.avatar} alt="avatar" onError={(e) => e.target.src = avatarIcon} />
                                                        }
                                                    </div>
                                                </PhotoBorder>
                                            </div>
                                            <button className="admin-user-add-profile-btn" onClick={() => toggleModal('profile')}>UPLOAD OFFICIAL PHOTO</button>
                                        </div>
                                        <div>
                                            <p>Cover Photo</p>
                                            <div className="adminUser-cover-photo-wrapper">
                                                <PhotoBorder>
                                                    <div className="image-wrapper">
                                                        {coverSrc ?
                                                            <img src={coverSrc} alt="cover" />
                                                            :
                                                            <img src={user.cover} alt="cover" onError={(e) => e.target.src = coverIcon} />
                                                        }
                                                    </div>
                                                </PhotoBorder>
                                            </div>
                                            <button className="admin-user-add-cover-btn" onClick={() => toggleModal('cover')}>UPLOAD COVER PHOTO</button>
                                        </div>
                                    </div>
                                }
                                <AdminCustomTextInput
                                    isTextArea={true}
                                    placeholder={"Bio"}
                                    handleChange={onChangeHandler}
                                    value={user.bio}
                                    name={"bio"}
                                    id={"bio"}
                                    label={"Bio"}
                                />
                                <ImageUploadModal
                                    isOpen={openModal}
                                    closeModal={() => setOpenModal(false)}
                                    modalTitle={isProfileImage ? "Change profile photo" : "Change Cover Photo"}
                                    savedImage={(imgSrc, imgFile) => draftImageUpload(imgSrc, imgFile)}
                                    isProfilePhoto={isProfileImage}
                                />
                            </div>
                        }

                        {step === 2 &&
                            <div>
                                <AdminCustomDropdown
                                    placeholder="Title"
                                    value={titlesList.filter(v => v.value === user.title)[0]}
                                    options={titlesList}
                                    onChange={opt => dropdownOnChangeHandler("title", opt)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="Title"
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"First Name"}
                                    handleChange={onChangeHandler}
                                    value={user.firstName}
                                    name={"firstName"}
                                    id={"firstName"}
                                    label={"First Name"}
                                    className={"adminUser-create-edit-input-mb"}
                                    isError={isSubmit && !user.firstName}
                                    errorMessage={"First Name is required"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Middle Name"}
                                    handleChange={onChangeHandler}
                                    value={user.middleName}
                                    name={"middleName"}
                                    id={"middleName"}
                                    label={"Middle Name"}
                                    className={"adminUser-create-edit-input-mb"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Last Name"}
                                    handleChange={onChangeHandler}
                                    value={user.lastName}
                                    name={"lastName"}
                                    id={"lastName"}
                                    label={"Last Name"}
                                    className={"adminUser-create-edit-input-mb"}
                                    isError={isSubmit && !user.lastName}
                                    errorMessage={"Last Name is required"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Suffix"}
                                    handleChange={onChangeHandler}
                                    value={user.suffix}
                                    name={"suffix"}
                                    id={"suffix"}
                                    label={"Suffix"}
                                    className={"adminUser-create-edit-input-mb"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Address Line 1"}
                                    handleChange={onChangeHandler}
                                    value={user.address1}
                                    name={"address1"}
                                    id={"address1"}
                                    label={"Address Line 1"}
                                    className={"adminUser-create-edit-input-mb"}
                                    isError={isSubmit && !user.address1}
                                    errorMessage={"Address Line 1 is required"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Address Line 2"}
                                    handleChange={onChangeHandler}
                                    value={user.address2}
                                    name={"address2"}
                                    id={"address2"}
                                    label={"Address Line 2"}
                                    className={"adminUser-create-edit-input-mb"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"City"}
                                    handleChange={onChangeHandler}
                                    value={user.city}
                                    name={"city"}
                                    id={"city"}
                                    label={"City"}
                                    className={"adminUser-create-edit-input-mb"}
                                    isError={isEditMode && isSubmit && !user.city}
                                    errorMessage={"City is required"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"State"}
                                    handleChange={onChangeHandler}
                                    value={user.state}
                                    name={"state"}
                                    id={"state"}
                                    label={"State"}
                                    className={"adminUser-create-edit-input-mb"}
                                    isError={isEditMode && isSubmit && !user.state}
                                    errorMessage={"State is required"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Country"}
                                    handleChange={onChangeHandler}
                                    value={user.country}
                                    name={"country"}
                                    id={"country"}
                                    label={"Country"}
                                    className={"adminUser-create-edit-input-mb"}
                                    isError={isEditMode && isSubmit && !user.country}
                                    errorMessage={"Country is required"}
                                />
                                <AdminCustomTextInput
                                    isNumberInput={true}
                                    isTextArea={false}
                                    placeholder={"Contact Number"}
                                    handleChange={onChangeHandler}
                                    value={user.contactNumber}
                                    name={"contactNumber"}
                                    id={"contactNumber"}
                                    label={"Contact Number"}
                                    className={"adminUser-create-edit-input-mb"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Nationality"}
                                    handleChange={onChangeHandler}
                                    value={user.nationality}
                                    name={"nationality"}
                                    id={"nationality"}
                                    label={"Nationality"}
                                    className={"adminUser-create-edit-input-mb"}
                                    isError={isEditMode && isSubmit && !user.nationality}
                                    errorMessage={"Nationality is required"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Mother's Maiden Name"}
                                    handleChange={onChangeHandler}
                                    value={user.mothersMaidenName}
                                    name={"mothersMaidenName"}
                                    id={"mothersMaidenName"}
                                    label={"Mother's Maiden Name"}
                                    className={"adminUser-create-edit-input-mb"}
                                />
                                <AdminCustomDropdown
                                    placeholder="Marital Status"
                                    value={maritalStatusList.filter(v => v.value === user.maritalStatus)[0]}
                                    options={maritalStatusList}
                                    onChange={opt => dropdownOnChangeHandler("maritalStatus", opt)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="Marital Status"
                                    isError={isEditMode && isSubmit && !user.maritalStatus}
                                    errorMessage={"Marital status is required"}
                                />
                                <AdminCustomDropdown
                                    placeholder="Gender"
                                    value={gendersList.filter(v => v.value === user.gender)[0]}
                                    options={gendersList}
                                    onChange={opt => dropdownOnChangeHandler("gender", opt)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="Gender"
                                    isError={isEditMode && isSubmit && !user.gender}
                                    errorMessage={"Gender is required"}
                                />
                                <AdminCustomDatePicker
                                    placeholder="Birthdate"
                                    value={user.birthDate}
                                    dateOnChange={(d) => dateOnChangeHandler("birthDate", d)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="Birthdate"
                                    isError={isEditMode && isSubmit && !user.birthDate}
                                    errorMessage={"Birthdate is required"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Birthplace"}
                                    handleChange={onChangeHandler}
                                    value={user.birthPlace}
                                    name={"birthPlace"}
                                    id={"birthPlace"}
                                    label={"Birthplace"}
                                    className={"adminUser-create-edit-input-mb"}
                                    isError={isEditMode && isSubmit && !user.birthPlace}
                                    errorMessage={"Birthplace is required"}
                                />
                            </div>
                        }
                        {step === 3 &&
                            <div>
                                <AdminCustomDropdown
                                    placeholder="User Manager"
                                    value={userManagersList?.filter(v => v.value === user.manager)[0]}
                                    options={userManagersList}
                                    onChange={opt => dropdownOnChangeHandler("manager", opt)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="User Manager"
                                    isError={isSubmit && !user.manager}
                                    errorMessage={"Manager is required"}
                                />
                                <AdminCustomDropdown
                                    placeholder="Role"
                                    value={rolesList?.filter(v => v.value === user.role)[0]}
                                    options={rolesList}
                                    onChange={opt => dropdownOnChangeHandler("role", opt)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="Role"
                                    isError={isSubmit && !user.role}
                                    errorMessage={"Role is required"}
                                />
                                <div className="flex-2-col-m10">
                                    <AdminCustomDatePicker
                                        placeholder="Start Date"
                                        value={user.startDate}
                                        dateOnChange={(d) => dateOnChangeHandler("startDate", d)}
                                        className={"adminUser-create-edit-input-mb"}
                                        label="Start Date"
                                    />
                                    <AdminCustomDatePicker
                                        placeholder="End Date"
                                        value={user.endDate}
                                        dateOnChange={(d) => dateOnChangeHandler("endDate", d)}
                                        className={"adminUser-create-edit-input-mb"}
                                        label="End Date"
                                    />
                                </div>
                                <AdminCustomDatePicker
                                    placeholder="Regularization Date"
                                    value={user.regularizationDate}
                                    dateOnChange={(d) => dateOnChangeHandler("regularizationDate", d)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="Regularization Date"
                                // disabled={true}
                                />
                                <AdminCustomDropdown
                                    placeholder="Position"
                                    value={positionsList.filter(v => v.value === user.position)[0]}
                                    options={positionsList}
                                    onChange={opt => dropdownOnChangeHandler("position", opt)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="Position"
                                />

                                <AdminCustomDropdown
                                    placeholder="Job Level"
                                    value={jobLevelsList.filter(v => v.value === user.level)[0]}
                                    options={jobLevelsList}
                                    onChange={opt => dropdownOnChangeHandler("level", opt)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="Job Level"
                                />
                                <AdminCustomDropdown
                                    placeholder="Department"
                                    value={departmentsList.filter(v => v.value === user.department)[0]}
                                    options={departmentsList}
                                    onChange={opt => dropdownOnChangeHandler("department", opt)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="Department"
                                />
                                <AdminCustomDropdown
                                    placeholder="Office Location"
                                    value={officesList.filter(v => v.value === user.officeId)[0]}
                                    options={officesList}
                                    onChange={opt => dropdownOnChangeHandler("officeId", opt)}
                                    className={"adminUser-create-edit-input-mb"}
                                    label="Office Location"
                                    isError={isSubmit && !user.officeId}
                                    errorMessage={"Office Location is required"}
                                />
                                <AdminCustomTextInput
                                    isNumberInput={true}
                                    numberInputLimit={rfidLimit}
                                    isTextArea={false}
                                    placeholder={"RFID"}
                                    handleChange={onChangeHandler}
                                    value={user.rfid}
                                    name={"rfid"}
                                    id={"rfid"}
                                    label={"RFID"}
                                    className={"adminUser-create-edit-input-mb"}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"ESN"}
                                    handleChange={onChangeHandler}
                                    value={user.esn}
                                    name={"esn"}
                                    id={"esn"}
                                    label={"ESN"}
                                    className={"adminUser-create-edit-input-mb"}
                                />
                            </div>
                        }
                        {step === 4 &&
                            <div>
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Organization"}
                                    handleChange={onChangeHandler}
                                    value={user.organization}
                                    name={"organization"}
                                    id={"organization"}
                                    label={"Organization"}
                                    className={"adminUser-create-edit-input-mb"}
                                    disabled={true}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Username"}
                                    handleChange={onChangeHandler}
                                    value={user.praggerUsername}
                                    name={"praggerUsername"}
                                    id={"praggerUsername"}
                                    label={"Username"}
                                    className={"adminUser-create-edit-input-mb"}
                                    disabled={true}
                                />
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    placeholder={"Email"}
                                    handleChange={onChangeHandler}
                                    value={user.email}
                                    name={"email"}
                                    id={"email"}
                                    label={"Email"}
                                    className={"adminUser-create-edit-input-mb"}
                                    isError={isSubmit && (!user.email || !isEmailValid)}
                                    errorMessage={!isEmailValid ? "Must be a valid email" : "Email is required"}
                                />
                                {isAddMode &&
                                    <AdminCustomTextInput
                                        isTextArea={false}
                                        placeholder={"Email Password"}
                                        handleChange={onChangeHandler}
                                        value={user.password}
                                        name={"password"}
                                        id={"password"}
                                        label={"Email Password"}
                                        className={"adminUser-create-edit-input-mb"}
                                        isError={isSubmit && !user.password}
                                        errorMessage={"Email password is required"}
                                    />
                                }
                                {isEditMode &&
                                    <AdminCustomTextInput
                                        isTextArea={false}
                                        placeholder={"Alternate Email"}
                                        handleChange={onChangeHandler}
                                        value={user.altemail}
                                        name={"altemail"}
                                        id={"altemail"}
                                        label={"Alternate Email"}
                                        className={"adminUser-create-edit-input-mb"}
                                        isError={isSubmit && !isAltEmailValid}
                                        errorMessage={"Must be a valid email"}
                                    />
                                }
                                <AdminCustomTextInput
                                    isTextArea={false}
                                    isNumberInput={true} //for praggerCode input
                                    numberInputLimit={praggerCodeLimit} //for praggerCode input limit
                                    placeholder={"Pragger Code"}
                                    handleChange={onChangeHandler}
                                    value={user.praggerCode}
                                    name={"praggerCode"}
                                    id={"praggerCode"}
                                    label={"Pragger Code"}
                                    className={"adminUser-create-edit-input-mb"}
                                    isError={isSubmit && (!user.praggerCode || !isPraggerCodeValid)}
                                    errorMessage={!isPraggerCodeValid ? "Must be a 6 digit input" : "Pragger Code is required"}
                                />
                            </div>
                        }
                    </div>
                    {step !== 1 &&
                        <UserProfileView
                            user={user}
                            uploadedAvatar={avatarSrc}
                            uploadedCover={coverSrc}
                        />
                    }
                </div>
                <div className="adminUser-create-edit-footer">
                    <p>{step + " out of " + maxStep}</p>
                    <div className={step === 1 ? "is--previous-btn-hidden" : ""}>
                        {step !== 1 && <button className="previous-btn" onClick={() => step > 1 && setStep(step - 1)}>Previous</button>}
                        <button className="next-btn" onClick={() => submitInputs()}>{step !== maxStep ? "Next" : "Save"}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateEditUser;