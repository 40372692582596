import { achievementConstants } from '../constants/achievement';

let initialState = {
    items: [],
    loading: true,
    error: null,
    totalPerPage: 0,
    perPage: 0,
    currentPage: 0,
}

export function achievements(state = initialState, action) {
  switch (action.type) {
    case achievementConstants.CREATE_REQUEST:
      return { creating: true };
    case achievementConstants.CREATE_SUCCESS:
      return {};
    case achievementConstants.CREATE_FAILURE:
      return {};

    case achievementConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case achievementConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.achievements.items,
        totalPerPage:  action.achievements.total,
        perPage: action.achievements.pages,
        currentPage: action.achievements.page,
      };
    case achievementConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

    case achievementConstants.DELETE_REQUEST:
      // add 'deleting:true' property to attribute being deleted
      return {
        ...state,
        items: state.items.map(achievement =>
            achievement.id === action.id
            ? { ...achievement, deleting: true }
            : achievement
        )
      };
    case achievementConstants.DELETE_SUCCESS:
      // remove deleted attribute from state
      return {
        items: state.items.filter(achievement => achievement.id !== action.id)
      };
    case achievementConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to attribute 
      return {
        ...state,
        items: state.items.map(achievement => {
          if (achievement.id === action.id) {
            // make copy of attribute without 'deleting:true' property
            const { deleting, ...achievementCopy } = achievement;
            // return copy of attribute with 'deleteError:[error]' property
            return { ...achievementCopy, deleteError: action.error };
          }

          return achievement;
        })
      };
    case achievementConstants.GETBYID_REQUEST:
        return {
            ...state,
          saving: true
        };
    case achievementConstants.GETBYID_SUCCESS:
        return {
          ...state,
          items: action.achievements
        };
    case achievementConstants.GETBYID_FAILURE:
        return {
          error: action.error
        };
    case achievementConstants.UPDATE_REQUEST:
      return {loading: true}    
    default:
      return state
  }
}