import { del, post } from '../helpers/api';
import { userService } from '../services/user';

export const resetEnergyServices = {
  deletEnergy,
  createEnergy
};

// function createEnergy(id){
//   return del(`/api/v1/user/${id}/energy`).then(handleResponse);
// }

function createEnergy(energy){
  let id = energy.userId;
  delete energy._id;
  return post(`/api/v1/user/${id}/energy`, JSON.stringify(energy))
  .then(handleResponse)
}

function deletEnergy(id){
  return del(`/api/v1/user/${id}/energy`).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              userService.logout();
              //location.reload(true);
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);

      }
      return data;
  });
}
