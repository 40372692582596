import React from "react";
import { history } from '../../_core/helpers/history';
import { connect } from 'react-redux';

// import { userTechnicalSupportRequest } from '../../_core/actions/userTechnicalSupport';
import { essActions } from '../../_core/actions/ess';

import requestsActions from '../../_core/actions/requests'

import Modal from 'react-modal';
import moment from 'moment';
// import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import 'react-datepicker/dist/react-datepicker.css';
import './RequestModalTechSupp.sass';

import holiday from '../../holiday.js';
import PolicyList from "../PolicyList";

import Dropzone from "./Dropzone";


class RequestModalTechnicalSupport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            userDetails: null,
            technicalSupport: {
                subject: "",
                description: "",
                type: "support",
                subtype: "technical support",
                startedAt: "",
                endedAt: "",
            },

            startOfDate: null,
            endOfDate: null,
            date: '',

            HolidayList: [],

            itemPerPage: 10,

            render: true,
            submitted: false,
        }

        this.getEndDate = this.getEndDate.bind(this)
        this.getStartDate = this.getStartDate.bind(this)
        this.isWeekDay = this.isWeekDay.bind(this)
        this.disableHoliday = this.disableHoliday.bind(this)
    }

    close(e) {

        e.preventDefault();
        const { itemPerPage } = this.state;
        const userDetails = JSON.parse(localStorage.getItem("userCredentials"));
        this.props.dispatch(requestsActions.getRequests({
            itemPerPage,
            selectPage: 1,
            userId: userDetails.user._id
        }));
        history.goBack();
    }

    getTitle(e) {
        const technicalSupport = this.state.technicalSupport;
        this.setState({
            technicalSupport: {
                ...technicalSupport,
                subject: e.target.value,
            }
        }, () => {
        });
    }

    getDescription(e) {

        const technicalSupport = this.state.technicalSupport;
        this.setState({
            technicalSupport: {
                ...technicalSupport,
                description: e.target.value,
            }
        }, () => {
        });
    }


    disableHoliday() {
        let holidayLists = []

        holiday.items.map((holiday) => {

            let dateDay = new Date(holiday.date);
            var day = dateDay.getDay();
            var isWeekend = (day === 6) || (day === 0);
            if (isWeekend === false) {
                holidayLists.push(new Date(holiday.date));
            }

            return null // This line Do nothing // just remove warning
        })
        return holidayLists;
    }


    getStartDate(date) {

        const technicalSupport = this.state.technicalSupport;
        this.setState({
            technicalSupport: {
                ...technicalSupport,
                startedAt: moment(date).format("YYYY-MM-DDT00:00:00.000") + "Z",
            },

            startOfDate: date,
        }, () => {
            this.isValidDate();
        });
    }

    getEndDate(date) {
        const technicalSupport = this.state.technicalSupport;
        this.setState({
            technicalSupport: {
                ...technicalSupport,
                endedAt: moment(date).format("YYYY-MM-DDT00:00:00.000") + "Z",
            },
            endOfDate: date,
        }, () => {
            this.isValidDate();
        });
    }

    isValidDate() {
        const { technicalSupport, HolidayList } = this.state;
        const storeHolidayDate = [];

        let isWeekend = false;
        let day = '';
        let dateDay = '';

        HolidayList.splice(0, HolidayList.length);
        storeHolidayDate.splice(0, storeHolidayDate.length)

        holiday.items.map((holiday) => {

            // Check if selected start date or end date has holiday
            if ((moment(holiday.date).format() === moment(technicalSupport.startedAt).format() && technicalSupport.startedAt !== '')
                || (moment(holiday.date).format() === moment(technicalSupport.endedAt).format() && technicalSupport.endedAt !== '')) {

                // Filter to prevent duplicate holiday input in an array
                if (storeHolidayDate.indexOf(holiday) === -1) {
                    dateDay = new Date(holiday.date);
                    day = dateDay.getDay();
                    isWeekend = (day === 6) || (day === 0);
                    if (isWeekend === false) {
                        storeHolidayDate.push(holiday)
                    }
                }
                this.setState({ HolidayList: storeHolidayDate })

            }

            // Check if between seleceted start date and end date has Holiday
            if (technicalSupport.startedAt !== '' && technicalSupport.endedAt !== '') {
                if (moment(moment(holiday.date).format())
                    .isBetween(moment(technicalSupport.startedAt).format(),
                        moment(technicalSupport.endedAt).format(), null, '[]')) {

                    // Filter to prevent duplicate holiday input in an array
                    if (storeHolidayDate.indexOf(holiday) === -1) {
                        dateDay = new Date(holiday.date);
                        day = dateDay.getDay();
                        isWeekend = (day === 6) || (day === 0);
                        if (isWeekend === false) {
                            storeHolidayDate.push(holiday)
                        }
                    }

                    this.setState({ HolidayList: storeHolidayDate }, () => {

                        if (storeHolidayDate.length !== 0) {
                            Swal.fire({
                                title: 'Selected Date Range Invalid',
                                html:
                                    "<div>Selected Date Range has a holiday </div> <div style='color:rgb(38, 166, 154)'>Note: Select Date range does not have a holiday</div>",
                                type: 'error',
                                confirmButtonColor: '#26a69a',
                                confirmButtonText: 'OK'
                            })
                        }

                    })
                }
            }

            return null // To remove warning 
        })
    }


    handleSubmit(e) {
        e.preventDefault();
        const { technicalSupport, HolidayList } = this.state;

        this.setState({ submitted: true })

        let StartDate = document.querySelector("#startedAt").value;
        let EndDate = document.querySelector("#endedAt").value;

        let dateNow = Date(Date.now()).toLocaleString();
        let dateFormat = moment(dateNow).format("ddd, MMMM DD, YYYY");


        if (technicalSupport.subject && technicalSupport.description && technicalSupport.startedAt && technicalSupport.endedAt) {
            if (HolidayList.length !== 0) {

                Swal.fire({
                    title: 'Selected Date Range Invalid',
                    html:
                        "<div>Selected Date Range has a holiday </div> <div style='color:rgb(38, 166, 154)'>Note: Select Date range does not have a holiday</div>",
                    type: 'error',
                    // showCancelButton: true,
                    confirmButtonColor: '#26a69a',
                    // cancelButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })

            } else {
                Swal.fire({
                    title: 'Submit request?',
                    html:
                        "<br> <b> Check the following  date if correct:</b> <br> " +
                        "<br> <b> Start of Date:</b> " +
                        StartDate +
                        "<br> <b>End of Date:</b>  " +
                        EndDate,
                    footer: 'Filing Date:  ' + dateFormat,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#26a69a',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Submit'
                }).then((result) => {
                    if (result.value) {
                        // this.props.dispatch(userTechnicalSupportRequest.requestService(technicalSupport))
                        this.props.dispatch(essActions.create(technicalSupport))

                    }
                })
            }
        }

    }

    handleDateChangeRaw(e) {
        e.preventDefault();
    }

    validateWhiteSpaces(e) {
        if (/^\s/.test(e.target.value)) { // Not accepting Whitespaces in first input. 
            e.target.value = '';
        }
    }

    isWeekDay(date) {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    }

    render() {
        const { technicalSupport, submitted } = this.state;
        let dateNow = Date(Date.now()).toLocaleString();
        let dateFormat = moment(dateNow).format("dddd, MMMM DD, YYYY");

        return (
            <Modal
                isOpen={true}
                onRequestClose={this.close.bind(this)}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                className="RequestModalUser"
                centered
            >
                <div className="technicalSupport">
                    <div className="header-container">
                        <div className="title-container">
                            <span> Create Technical Support Request </span>
                        </div>
                        <div className="dateFiled-container">
                            <span> Filing Date: {dateFormat}</span>
                        </div>
                    </div>

                    <div className="content-container">
                        <form name="form" onSubmit={this.handleSubmit.bind(this)} autoComplete="off">

                            {/* Request Title */}
                            <div className="row">
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Request Title
                                    </label>

                                    <div className="input-custom">
                                        <input type="text" className="form-control requestTitle"
                                            id="requestTitle" onChange={this.getTitle.bind(this)} onInput={this.validateWhiteSpaces.bind(this)} />
                                    </div>
                                    {submitted && !this.state.technicalSupport.subject &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">Request title is required</div>
                                    }
                                </div>
                            </div>

                            {/* Request Description */}
                            <div className="row">
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Description
                                    </label>

                                    <div className="input-custom">
                                        <textarea style={{ border: "none", padding: "10px", resize: "vertical" }} placeholder="Description" className="form-control requestDescription"
                                            id="requestDescription" onChange={this.getDescription.bind(this)} onInput={this.validateWhiteSpaces.bind(this)} />
                                    </div>
                                    {submitted && !this.state.technicalSupport.description &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">Description is required</div>
                                    }
                                </div>
                            </div>

                            {/* Date field */}
                            <div className="row">
                                {/* Select Start Date */}
                                <div className="col s12 m6 l6">
                                    <label className="label-title" htmlFor="">
                                        Select start date
                                    </label>
                                    <div className="input-custom">
                                        <DatePicker
                                            className="sqUserCalendar dateValue"
                                            name="date"
                                            id="startedAt"
                                            placeholderText="Choose date"
                                            dateFormat="ddd, MMM DD, YYYY"
                                            selected={this.state.startOfDate}



                                            filterDate={(date) => this.isWeekDay(date)} // Disable Saturday & Sunday but still highlighted when time range set
                                            value={this.state.startOfDate ? moment(this.state.startOfDate).format('ddd, MMM DD, YYYY') : ''}
                                            onChange={(date) => this.getStartDate(date)}
                                            onChangeRaw={this.handleDateChangeRaw.bind(this)} // Prevent raw input
                                            excludeDates={this.disableHoliday()}


                                            selectsStart
                                            startDate={this.state.startOfDate}
                                            endDate={this.state.endOfDate}
                                            maxDate={this.state.endOfDate}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>
                                    {submitted && !this.state.startOfDate &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">Date is required</div>
                                    }
                                </div>


                                {/* Select End Date */}
                                <div className="col s12 m6 l6">
                                    <label className="label-title" htmlFor="">
                                        Select end date
                                    </label>
                                    <div className="input-custom">
                                        <DatePicker
                                            className="sqUserCalendar dateValue"
                                            name="date"
                                            id="endedAt"
                                            placeholderText="Choose date"
                                            dateFormat="ddd, MMM DD, YYYY"
                                            selected={this.state.endOfDate}

                                            filterDate={(date) => this.isWeekDay(date)} // Disable Saturday & Sunday but still highlighted when time range set
                                            value={this.state.endOfDate ? moment(this.state.endOfDate).format('ddd, MMM DD, YYYY') : ''}
                                            opentoDate={(moment()).isAfter(this.state.startOfDate) ? this.state.endOfDate == null ? moment() : null : null}
                                            excludeDates={this.disableHoliday()}

                                            onChange={(date) => this.getEndDate(date)}
                                            onChangeRaw={this.handleDateChangeRaw.bind(this)}  // Prevent raw input 

                                            selectsEnd
                                            startDate={this.state.startOfDate}
                                            endDate={this.state.endOfDate}
                                            minDate={this.state.startOfDate}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>
                                    {submitted && !this.state.endOfDate &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">Date is required</div>
                                    }
                                </div>
                            </div>

                            {/* Attachment Files*/}
                            <div className="row">
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Attach File 
                                    </label>
                                    <Dropzone item={items => {
                                        this.setState({technicalSupport: {
                                            ...technicalSupport,
                                            files: items,
                                        }})
                                    }}/>
                                </div>
                            </div>

                            {/* Temporay Guideline in the bottom*/}
                            <div className="row">
                                <div className="col s12">
                                    <PolicyList type={"support"} index={0} />
                                </div>
                            </div>

                            {/* Button submit */}
                            <div className="footer-container">
                                <div>
                                    <button type="submit" className="waves-effect waves-light btn sroUserSubmitBtn" id="submit">Submit</button>
                                    <button className="waves-effect waves-light btn sroUserCancelBtn" id="cancel" onClick={this.close.bind(this)}>Cancel</button>
                                </div>
                            </div>
                        </form>

                        {/* Temporay guideline in rightside */}
                        <div className="row">
                            <div className="col s12">
                                <PolicyList type={"support"} index={1} />
                            </div>
                        </div>

                    </div>  {/* content-container end */}
                </div>
            </Modal>
        )

    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { userTechnicalSupport } = state;
    return {
        userTechnicalSupport,
        alert,
    };
}

export default React.memo(connect(mapStateToProps)(RequestModalTechnicalSupport));
