import questionnaireConstants from "../constants/questionnaire";

const initialState = {
    data: [],
    loading: true,
    isError: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case questionnaireConstants.GET_REQUEST:
            return {
                ...state,
                loading: true,
                isError: false,
                itemsPerPage: action.items,
                // reset props after saving or cancel in create/edit questionnaire page
                questions: [],
                message: "",
                dataById: [],
            };
        case questionnaireConstants.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                isError: false,
                data: action.questionnaires,
                list: action.questionnaires.items,

                totalPerPage: action.questionnaires.total,
                perPage: action.questionnaires.pages,
                currentPage: action.questionnaires.page,
            };
        case questionnaireConstants.GET_FAILURE:
            return {
                ...state,
                loading: false,
                isError: action.error,
            };

        case questionnaireConstants.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
                isError: false,
                message: "",
            };
        case questionnaireConstants.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                isError: false,
                message: action.questionnaire.success,
                returnId: action.questionnaire._id,
            };
        case questionnaireConstants.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                isError: action.error,
            };
        
        case questionnaireConstants.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                isError: false,
                message: "",
            };
        case questionnaireConstants.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                isError: false,
                message: action.questionnaire.success.message,
            };
        case questionnaireConstants.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                isError: action.error,
            };

        case questionnaireConstants.GETBYID_REQUEST:
            return {
                ...state,
                loading: true,
                isError: false,
            };
        case questionnaireConstants.GETBYID_SUCCESS:
            return {
                ...state,
                loading: false,
                isError: false,
                dataById: action.questionnaire?.item,
            };
        case questionnaireConstants.GETBYID_FAILURE:
            return {
                ...state,
                loading: false,
                isError: action.error,
            };

        case questionnaireConstants.GET_QUESTION_REQUEST:
            return {
                ...state,
                loading: true,
                isError: false,
                questions: [],
            };
        case questionnaireConstants.GET_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                isError: false,
                questions: action.questions.items
            };
        case questionnaireConstants.GET_QUESTION_FAILURE:
            return {
                ...state,
                loading: false,
                isError: action.error,
            };

        default:
            return state;
    }
}