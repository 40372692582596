import { technicalConstants } from '../constants/technical';
import { technicalService } from '../services/technical';
import{ userService } from '../services/user';
import { alertActions } from '../actions/alert';
import { history } from '../helpers/history';

export const technicalActions = {
    getAll,
    getById,
    reject,
    approve,
    update,
    hold
};

function approve(technical) {
    return dispatch => {
        dispatch(request(technical));

        technicalService.approve(technical)
        .then(
            technical => {
                dispatch(success(technical));
                history.push(`/admin/technical-supports/page=:id`)
                dispatch(alertActions.success('Tech request Approved!'))
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error('Tech request failed'))
            }
        );
    };
    function request(technical) { return { type: technicalConstants.APPROVE_REQUEST, technical } }
    function success(technical) { return { type: technicalConstants.APPROVE_SUCCESS, technical } }
    function failure(error) { return { type: technicalConstants.APPROVE_FAILURE, error } }
}

function reject(technical) {
    return dispatch => {
        dispatch(request(technical));

        technicalService.reject(technical)
        .then(
            technical => {
                dispatch(success(technical));
                history.push(`/admin/technical-supports/page=:id`)
                dispatch(alertActions.success('Tech request rejected'))
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error('Tech request reject failed'))
            }
        );
    };
    function request(technical) { return { type: technicalConstants.DECLINE_REQUEST, technical } }
    function success(technical) { return { type: technicalConstants.DECLINE_SUCCESS, technical } }
    function failure(error) { return { type: technicalConstants.DECLINE_FAILURE, error } }
}

function update(technical) {
    return dispatch => {
        dispatch(request(technical));

        technicalService.update(technical)
        .then(
            technical => {
                dispatch(success(technical));
                history.push(`/admin/technical-supports/page=:id`)
                dispatch(alertActions.success('Tech request updated'))
            },
            error => {
                dispatch(failure(error.toString()))
                dispatch(alertActions.error('Tech request update failed'))
            }
        );
    };
    function request(technical) { return { type: technicalConstants.UPDATE_REQUEST, technical } }
    function success(technical) { return { type: technicalConstants.UPDATE_SUCCESS, technical } }
    function failure(error) { return { type: technicalConstants.UPDATE_FAILURE, error } }
}

function hold(technical) {
    return dispatch => {
        dispatch(request(technical));

        technicalService.hold(technical)
        .then(
            technical => {
                dispatch(success(technical));
                history.push(`/admin/technical-supports/page=:id`)
                dispatch(alertActions.success('Tech request On-Hold'))
            },
            error => {
                dispatch(failure(error.toString()))
                dispatch(alertActions.error('Tech request On-Hold failed'))
            }
        );
    };
    function request(technical) { return { type: technicalConstants.HOLD_REQUEST, technical } }
    function success(technical) { return { type: technicalConstants.HOLD_SUCCESS, technical } }
    function failure(error) { return { type: technicalConstants.HOLD_FAILURE, error } }
}

// function getById(id) {
//     return dispatch => {
//         dispatch(request(id));

//         technicalService.getById(id)
//         .then(
//             technical => {
//                 dispatch(success(technical));
//             },
//             error => dispatch(failure(error.toString()))
//         );
//     ;}
//  function request() { return { type: technicalConstants.GETUSER_REQUEST } }
//  function success(technical) { return { type: technicalConstants.GETUSER_SUCCESS, technical } }
//  function failure(error) { return { type: technicalConstants.GETUSER_FAILURE, error } }

// }


function getById(id) {
    return dispatch => {
        dispatch(request(id));

        technicalService.getById(id)
            .then(
                technical => {
                    dispatch(success(technical));
                    // history.push('/admin/leave-request/update');
                    var uid = technical.success[0].user_id;
                    userService.getById(uid)
                },
                error => dispatch(failure(error.toString()))
            );

    };

 function request() { return { type: technicalConstants.GETUSER_REQUEST } }
 function success(technical) { return { type: technicalConstants.GETUSER_SUCCESS, technical } }
 function failure(error) { return { type: technicalConstants.GETUSER_FAILURE, error } }
}


function getAll(page, itemPerPage) {
    return dispatch => {
        dispatch(request());

        technicalService.getAll(page, itemPerPage)
            .then(
                technical =>{ 
                    dispatch(success(technical));
                    // if(window.location.pathname === '/admin/leave-requests'){
                    //     history.push('/admin/leave-requests?page=' + Number(page) || 1);
                    // }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: technicalConstants.GETALL_REQUEST } }
    function success(technical) { return { type: technicalConstants.GETALL_SUCCESS, technical } }
    function failure(error) { return { type: technicalConstants.GETALL_FAILURE, error } }
}