import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from "react-redux";
import { history } from "../_core/helpers/history";
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { Route } from 'react-router-dom';

import evaluationActions from "../_core/actions/evaluation";
import { userActions } from "../_core/actions/user";
import Swal from 'sweetalert2';
import { UserDashboardContext } from "../UserDashboard/UserDashboard";
import Footer from '../Footer/Mainfooter.js';
import ProfileWidget from '../ProfileWidget/ProfileWidget';
import BioWidget from '../BioWidget/BioWidget';
import TimekeepingWidget from '../TimekeepingWidget/TimekeepingWidget';
import AttributesWidget from '../AttributesWidget/AttributesWidget';
import TeamWidget from '../TeamWidget/TeamWidget';
import WorkstatusWidget from '../WorkStatusWidget/WorkstatusWidget';
import Table from '../Table/Table';
import filter from '../_templates/filter.png';

import flag from '../_templates/flag_black_large.png';
import clock from '../_templates/clock_icon.png';
import avatar from '../_templates/man.png';

import './Performance.scss';
import PerformanceFormModal from './PerformanceFormModal';

const AdminEvaluation = () => {
	return (
		<div className="goto-evaluation-admin">
			<div>
				<img src={flag} alt="admin evaluation" className="to-eval-admin-img" />
				<p className="to-eval-admin-title">Visit Evaluation Admin</p>
				<p className="to-eval-admin-sub">View Evaluation Page in Admin by clicking the button below.</p>
				<button className="to-eval-admin-btn" onClick={() => history.push("/admin/evaluations?page=1")}>Go to Administrator Page</button>
			</div>
		</div>
	);
}

const UserEvaluations = (props) => {
	const dispatch = useDispatch();
	const users = props.allActiveUsers;
	const [evaluations, setEvaluations] = React.useState([]);
	const [itemsPerPage, setItemsPerPage] = React.useState(10);
	const [page, setPage] = React.useState(1);

	const isAdmin = JSON.parse(localStorage.getItem('userRole'))?.[0]?.roleAll === 1;

	const headers = [
		{ name: "", withOrder: false },
		{ name: "Period", withOrder: true },
		{ name: "Respondents", withOrder: false },
	];

	const getStatusStyles = (stat) => {
		switch (stat) {
			case 'accepted':
				return 'accepted';
			case 'on going':
				return 'inProgress';
			case 'finished':
				return 'finished';
			case 'rejected':
				return 'rejected';
			case 'completed':
				return 'completed';
			case 'expired':
				return 'expired';
			case 'pending':
				return 'pending';
			default:
				return '';
		}
	};

	const getStatusText = (stat) => {
		switch (stat) {
			case 'accepted':
				return 'in progress';
			case 'on going':
				return 'in progress';
			case 'finished':
				return 'finish';
			case 'rejected':
				return 'rejected';
			case 'completed':
				return 'complete';
			case 'expired':
				return 'expired';
			case 'pending':
				return 'pending';
			default:
				return '';
		}
	};

	const sort = React.useCallback((index, order) => {
		setEvaluations(evaluations => {
			const orders = ['asc', 'desc'];

			return (
				orderBy(evaluations, (evaluation) => (
					evaluation.cells[index].value
				), [orders[order]])
			);
		})
	}, [setEvaluations]);

	const formatEvaluationsData = (evaluations = [], usersList = []) => {
		const getRespondent = (data, i) => {
			let respondent = usersList?.filter((user) => user._id === data?.user_id)[0]?.details[0] || {};

			return (
				<img src={respondent?.avatar || avatar} alt="respondent" onError={(e) => e.target.src = avatar} key={i} />
			)
		}

		return (
			evaluations.map(evaluation => {
				const formattedData = {
					title: {
						_id: 'title',
						element: <p>{evaluation.title}</p>,
						value: evaluation.title
					},
					period: {
						_id: 'period',
						element: (
							<div style={{
								display: 'flex',
								alignContent: 'center'
							}}>
								<img height={20} src={clock} alt="" style={{ marginRight: 5 }} />
								<p>
									{
										[
											moment(evaluation.data[0].startedAt).utc().format('MM/DD/YYYY'),
											(moment(evaluation.data[0].expiresAt).utc().isValid() ? moment(evaluation.data[0].expiresAt).utc().format('MM/DD/YYYY') : "No date")
										].join(' - ')
									}
								</p>
							</div>
						),
						value: moment(evaluation.startedAt).utc().format('MM/DD/YYYY').toString()
					},
					respondents: {
						_id: 'respondents',
						element: (
							<div className="td-performance-respondents" >
								{evaluation?.data?.map((item, index) => getRespondent(item, index))}
								{Number(evaluation.respondents) > 3 &&
									<span>+
								{Number(evaluation.respondents) - 3}
										{Number(evaluation.respondents) - 3 > 1 ? " peers" : " peer"}
									</span>
								}
							</div>
						),
						value: evaluation.respondents
					},
					status: {
						_id: 'status',
						element: (
							<div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }} className={"td-status " + getStatusStyles(evaluation.status)}>
								{
									<div style={{ textTransform: 'capitalize' }}>{getStatusText(evaluation.status)}</div>
								}
							</div>
						),
						value: evaluation.status
					}
				};

				return {
					_id: evaluation.refCode,
					cells: Object.values(formattedData)
				};
			}) || []
		);
	}

	React.useEffect(() => {
		const userId = props.authentication.user?.user?._id || "";
		if (!isAdmin) {
			dispatch(evaluationActions.getAllByUser(page, itemsPerPage, userId));
		}
	}, [dispatch, page, itemsPerPage, props.authentication.user, isAdmin]); //eslint-disable-line

	
	const viewEvaluation = React.useCallback(id => {
      let getEvaluation = evaluations.filter(data => data._id === id) ;
	  let filterEvaluationRejected = getEvaluation[0]['cells'].filter(cell => cell.value === 'rejected');

		if(filterEvaluationRejected.length !== 0) {
			// Dialogue for Rejected Evaluation
			Swal.fire({
				title: 'This evaluation has already rejected',
				type: 'error',
				confirmButtonColor: '#26a69a',
				confirmButtonText: 'OK'
			})  
		}

	}, [props.history, props.match]); //eslint-disable-line

	React.useEffect(() => {
		if (isEmpty(props.userEvaluations?.items) === false && !isAdmin) {
			setEvaluations(formatEvaluationsData(props.userEvaluations.items, users));
		}
	}, [props.userEvaluations, props.allActiveUsers]); //eslint-disable-line

	return (
		<div>
			<div className="performance-header">
				<h6>Evaluations ({props.userEvaluations?.total || 0})</h6>
				<div className="performance-widget-controls">
					<button className="filter-btn">
						<img src={filter} alt="filter" />
					</button>
				</div>
			</div>
			<div className="card z-depth-0">
				<Table
					headers={headers}
					itemsPerPage={itemsPerPage}
					loading={props.userEvaluationsLoading}
					goToPage={page => { setPage(page) }}
					noDataComponent={
						<>
							<p className="no-data-helper">You don't any evaluations yet</p>
						</>
					}
					onHeaderClick={sort}
					onRowClick={viewEvaluation}
					page={props.userEvaluations?.page}
					pages={props.userEvaluations?.pages || 1}
					rows={evaluations}
					setItemsPerPage={setItemsPerPage}
					columns={4}
				/>
			</div>
		</div>
	)
}

const PeersEvaluation = (props) => {
	const dispatch = useDispatch();
	const users = props.allActiveUsers;
	const [evaluations, setEvaluations] = React.useState([]);
	const [itemsPerPage, setItemsPerPage] = React.useState(10);
	const [page, setPage] = React.useState(1);

	const isAdmin = JSON.parse(localStorage.getItem('userRole'))?.[0]?.roleAll === 1;

	const headers = [
		{ name: "", withOrder: false },
		{ name: "Period", withOrder: true },
		{ name: "Respondents", withOrder: false },
	];

	const getStatusStyles = (stat) => {
		switch (stat) {
			case 'accepted':
				return 'accepted';
			case 'on going':
				return 'inProgress';
			case 'finished':
				return 'finished';
			case 'rejected':
				return 'rejected';
			case 'completed':
				return 'completed';
			case 'expired':
				return 'expired';
			case 'pending':
				return 'pending';
			default:
				return '';
		}
	};

	const getStatusText = (stat) => {
		switch (stat) {
			case 'accepted':
				return 'in progress';
			case 'on going':
				return 'in progress';
			case 'finished':
				return 'finish';
			case 'rejected':
				return 'rejected';
			case 'completed':
				return 'complete';
			case 'expired':
				return 'expired';
			case 'pending':
				return 'pending';
			default:
				return '';
		}
	};

	const sort = React.useCallback((index, order) => {
		setEvaluations(evaluations => {
			const orders = ['asc', 'desc'];

			return (
				orderBy(evaluations, (evaluation) => (
					evaluation.cells[index].value
				), [orders[order]])
			);
		})
	}, [setEvaluations]);

	const viewEvaluation = React.useCallback(id => {
		let getEvaluation = evaluations.filter(data => data._id === id) ;
		let filterEvaluationRejected = getEvaluation[0]['cells'].filter(cell => cell.value === 'rejected');
  
		  if(filterEvaluationRejected.length !== 0) {
			  // Dialogue for Rejected Evaluation
			  Swal.fire({
				  title: 'This evaluation has already rejected',
				  type: 'error',
				  confirmButtonColor: '#26a69a',
				  confirmButtonText: 'OK'
			  })  
		  } else {
				props.history.push(`${props.match.path}/${id}`);
		  }
	
	}, [props.history, props.match]); //eslint-disable-line

	const formatEvaluationsData = (evaluations = [], usersList = []) => {

		const getRespondentAvatar = (data, i) => {
			let respondent = usersList?.filter((user) => user._id === data?.user_id)[0]?.details[0] || {};

			return (
				<img src={respondent?.avatar || avatar} alt="respondent" onError={(e) => e.target.src = avatar} key={i} />
			)
		}

		const getSubjectTitle = (data) => {
			let userDetails = usersList?.filter((user) => user._id === data?.subject)[0]?.details[0] || {};

			return (
				<p>{`${userDetails?.firstName || ""} ${userDetails.lastName || ""} - ${data?.title || ""}`}</p>
			)
		}

		const getEvaluationData = (evaluation) => {
			const id = props.authentication.user?.user?._id;
			const filterData = evaluation?.data?.length > 0 ? evaluation?.data.filter((item) => item.user_id === id) : [];
			const getData = filterData?.length > 0 ? filterData[0] : {};

			return getData
		}

		return (
			evaluations.map(evaluation => {
				const formattedData = {
					title: {
						_id: 'title',
						element: getSubjectTitle(evaluation?.data[0]),
						value: evaluation.title
					},
					period: {
						_id: 'period',
						element: (
							<div style={{
								display: 'flex',
								alignContent: 'center'
							}}>
								<img height={20} src={clock} alt="" style={{ marginRight: 5 }} />
								<p>
									{
										[
											moment(getEvaluationData(evaluation)?.startedAt).utc().format('MM/DD/YYYY'),
											(moment(getEvaluationData(evaluation)?.expiresAt).utc().isValid() ? moment(getEvaluationData().expiresAt).utc().format('MM/DD/YYYY') : "No date")
										].join(' - ')
									}
								</p>
							</div>
						),
						value: moment(getEvaluationData(evaluation)?.startedAt).utc().format('MM/DD/YYYY').toString()
					},
					respondents: {
						_id: 'respondents',
						element: (
							<div className="td-performance-respondents" >
								{evaluation?.data?.map((item, index) => getRespondentAvatar(item, index))}
								{Number(evaluation.respondents) > 3 &&
									<span>+
								{Number(evaluation.respondents) - 3}
										{Number(evaluation.respondents) - 3 > 1 ? " peers" : " peer"}
									</span>
								}
							</div>
						),
						value: evaluation.respondents
					},
					status: {
						_id: 'status',
						element: (
							<div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }} className={"td-status " + getStatusStyles(getEvaluationData(evaluation)?.status)}>
								{
									<div style={{ textTransform: 'capitalize' }}>{getStatusText(getEvaluationData(evaluation)?.status)}</div>
								}
							</div>
						),
						value: evaluation.status
					}
				};

				return {
					_id: evaluation.refCode,
					cells: Object.values(formattedData)
				};
			}) || []
		);
	}

	React.useEffect(() => {
		const userId = props.authentication.user?.user?._id || "";
		if (!isAdmin) {
			dispatch(evaluationActions.getAllByPeers(page, itemsPerPage, userId));
		}
	}, [dispatch, page, itemsPerPage, props.authentication.user]); //eslint-disable-line

	React.useEffect(() => {
		// if (isEmpty(props.peerEvaluations?.items) === false && !isAdmin) {
			setEvaluations(formatEvaluationsData(props.peerEvaluations?.items, users));
		// }
	}, [props.peerEvaluations, props.allActiveUsers]); //eslint-disable-line

	return (
		<div>
			<div className="performance-header">
				<h6>Peer Reviews ({props.peerEvaluations?.total || 0})</h6>
				<div className="performance-widget-controls">
					<button className="filter-btn">
						<img src={filter} alt="filter" />
					</button>
				</div>
			</div>
			<div className="card z-depth-0">
				<Table
					headers={headers}
					itemsPerPage={itemsPerPage}
					loading={props.peerEvaluationsLoading}
					goToPage={page => { setPage(page) }}
					noDataComponent={
						<>
							<p className="no-data-helper">You don’t have any peer reviews yet.</p>
						</>
					}
					onHeaderClick={sort}
					onRowClick={viewEvaluation}
					page={props.peerEvaluations?.page}
					pages={props.peerEvaluations?.pages || 1}
					rows={evaluations}
					setItemsPerPage={setItemsPerPage}
					columns={4}
				/>
			</div>
			<Route
				exact
				path={`${props.match.path}/:performanceId`}
				render={() => <PerformanceFormModal />}
			/>
		</div>
	)
}

const Performance = (props) => {
	const dispatch = useDispatch();
	const device = React.useContext(UserDashboardContext).device;
	const isAdmin = JSON.parse(localStorage.getItem('userRole'))?.[0]?.roleAll === 1;

	React.useEffect(() => {
		if (!isAdmin) {
			dispatch(userActions.getAllActive());
		}
	}, [dispatch]); //eslint-disable-line

	console.log(props.authentication)

	return (
		<div className={device === 'desktop' ? "row performance desktop-footer" : "row performance"}>
			<div
				className={cc([
					'sub-panel',
					'col',
					's12',
					'l4',
					device
				])}
			>
				<div>
					<ProfileWidget />
					<BioWidget
						userCred={props.authentication?.user?.user}
					/>
					<WorkstatusWidget 
                        userId={props.authentication.user?.user?._id}
                    />
					<TimekeepingWidget
						public={false}
						username={props.authentication.user?.user?.praggerUsername}
						userteam={props.authentication?.user?.user?.team}
						user={props.authentication?.user}
					/>
					<AttributesWidget
						userId={props.authentication.user?.user?._id}
					/>
					<TeamWidget
						team={props.authentication?.user?.user?.team}
						userId={props.authentication.user?.user?._id}
					/>
				</div>
				{device === 'desktop' &&
					<div><Footer /></div>
				}
			</div>
			<div
				className={cc([
					'main-panel',
					'col',
					's12',
					'l8',
					device
				])}
			>
				<div className="performance-wrapper">

					{isAdmin ?
						<AdminEvaluation />
						:
						// evaluation list tables
						<>
							<UserEvaluations {...props} />
							<PeersEvaluation {...props} />
						</>
					}
				</div>
			</div>
			{device === 'tablet' &&
				<div><Footer /></div>
			}
		</div>
	)
};

const mapStateToProps = (props) => ({
	authentication: props.authentication,
	active: props.session,
	userEvaluations: props.evaluations?.userEvaluations || {},
	peerEvaluations: props.evaluations?.peerEvaluations || {},
	userEvaluationsLoading: props.evaluations?.userEvaluationsLoading,
	peerEvaluationsLoading: props.evaluations?.peerEvaluationsLoading,
	allActiveUsers: props.users?.users?.items || [],
});

export default React.memo(connect(mapStateToProps)(Performance));
