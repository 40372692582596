export const timekeepingConstants = {
    GET_ALL_REQUEST: 'GET_ALL_TIMEKEEPING_REQUEST',
    GET_ALL_SUCCESS: 'GET_ALL_TIMEKEEPING_SUCCESS',
    GET_ALL_FAILURE: 'GET_ALL_TIMEKEEPING_FAILURE',

    GET_REQUEST: 'GET_TIMEKEEPING_REQUEST',
    GET_SUCCESS: 'GET_TIMEKEEPING_SUCCESS',
    GET_FAILURE: 'GET_TIMEKEEPING_FAILURE',

    SAVE_PRAG: 'SAVE_PRAG',

    GET_PRAGGER_REQUEST: 'PRAGGER_GETALL_REQUEST',
    GET_PRAGGER_SUCCESS: 'PRAGGER_GETALL_SUCCESS',
    GET_PRAGGER_FAILURE: 'PRAGGER_GETALL_FAILURE',
};
