import React from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import moment from "moment";
import styled from "styled-components";
import LinesEllipsis from 'react-lines-ellipsis';
import ReactImageFallback from "react-image-fallback";

import Menu, { Item as MenuItem } from 'rc-menu';
import Dropdown from 'rc-dropdown';

import Modal from 'react-modal';
import closeIcon from '../../../_templates/x_round_grey.png';
import { Link } from 'react-router-dom';

import { grantServices } from '../../../_core/services/grant';
import { history } from "../../../_core/helpers/history";

import emptyIcon from '../../../_templates/empty.png';

import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent, DynamicContentHeaderWrapper, DynamicTableAndPaginationWrapper } from "../../Components/PageWrapper/DynamicWrapper";

//import CreateAnnouncementPage from './CreateAnnouncementPage';

import Swal from 'sweetalert2';

import { useDispatch } from "react-redux";

import { announcementActions } from '../../../_core/actions/announcement';
import CreateEditAnnoucement from "./CreateEditAnnouncement";
import avatar from '../../../_templates/man.png';

const AdminAnnouncementPage = styled.div`
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4){
        text-align: inherit;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: center;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const AnnouncementPageList = (props) => {
  const [announcement, setAnnouncement] = React.useState([]);
  const [isModalOpen, toggleModal] = React.useState(false);
  const [acknowledge, setAcknowledge] = React.useState(null);
  const [grantHandleError, setGrantHandleError] = React.useState(null);
  const [entriesValuest, setentriesValues] = React.useState(); 

  // console.log(hasAnnouncementAdd(permissions));
  // console.log(permissions);

  const dispatch = useDispatch();

  const [Action, setAction] =  React.useState('');

  let tableHeader = ["Date", "Type", "Content", "Affirms", "Mention", "Actions"];

  let secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

  React.useEffect(()=>{
    setAnnouncement(props.announcements.items);
  },[props.announcements, props.permissions])

  const announcementContentConvert = (content) => {
    let announcementContent = content;
    let convertRaw = '';
    convertRaw = announcementContent.replace(/[^a-zA-Z0-9!@#%^&();:?.,]/g, " "); 
    return convertRaw;
}

const placeholderAcknowledged = (acknowledge) => {
    let placeholderAcknowledged = acknowledge === undefined ? 0 : acknowledge;

    return placeholderAcknowledged;
}

const entriesValue = (entries) => {
    setentriesValues(entries);
}

const viewUser = (value) => {

    // Reset State
    setAcknowledge(null); 
    setGrantHandleError(null);

    grantServices.getByOrigin(value, 1, 999).then(response => {
        const allNotes = response.items.map((item,i) => item);
        setAcknowledge(allNotes)

    }).catch(error=> {
        setGrantHandleError(error); 
        console.error(`Error: $(error)`)
    })

};
const changeStatus = (statusId, statusValue) => {
  let newStatusValue = statusValue ? 0 : 1 ;
  const getAnnouncementID = props.announcements?.items;
  const resultId = getAnnouncementID.filter(checkId);

  function checkId(statId) {
    return statId._id === statusId ;
  }

  const updateAnnouncement = {
    "postType" : resultId[0].postType,
    "postTitle"	: resultId[0].postTitle,
    "postSlug"	: resultId[0].postSlug,
    "userId"	: resultId[0].userId,
    "postContent" : resultId[0].postContent,
    "postImage": resultId[0].postImage,
    "status": newStatusValue
  };

  Swal.fire({
    title: '<p class="swal-subtitle-dark">Are you sure?</p>',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#24DC98',
    cancelButtonColor: '#d33',
    confirmButtonText: `Yes, ${statusValue ? 'Unpublish' : 'Publish'} it!`,
    background: '#2E3549',
  }).then((result) => {
      if (result.value) {
        dispatch(announcementActions.updateStatusValue(statusId, updateAnnouncement));
      }
  })
};

const onVisibleChange = (visible) => {
  setAction(visible)
}



const deleteAnnouncement = () => {

  const id = Action._id;

  const Toast = Swal.mixin({
    toast: true,
    width: 300,
    height: 500,
    background: '#D4EDDA',
    position: 'center',
    showConfirmButton: false,
    timer: 5000
  });
  Swal.fire({
      title: '<p class="swal-subtitle-dark">Are you sure?</p>',
      html: '<p class="swal-subtitle-dark">You wont be able to revert this!</p>',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#24DC98',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      background: '#2E3549',
  }).then((result) => {
      if (result.value) {
    dispatch(announcementActions.deleteAnnouncement(id)); 
    setTimeout(() => {
      Toast.fire({
        type: 'success',
        title: '<span style="color:#0A551E"> Announcement  has been deleted.</span>',
      });
        dispatch(announcementActions.getAll(props.announcements.currentPage,entriesValuest))
    }, 100)
              
      }
  })


}

const editAnnouncement = (item) => {
    history.push(`/admin/announcement/${item}/edit`)
}




  const AnnouncementData = () => {
    let data = announcement ?? [];

    const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
    const editPermission = JSON.parse(localStorage.getItem('editPermission'));
    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

    
    return (
      <>
        <Modal 
            isOpen={isModalOpen} 
            ariaHideApp={true}
            toggle={toggleModal}
            onRequestClose={() => toggleModal(false)}
            shouldCloseOnOverlayClick={true}
            className="AnnouncementModalBG"
        >
            <div className="acknowledge-modal-container">
                <div className="acknowledge-container">
                    <div className="acknowledge-header">
                        <img alt="" src={closeIcon} onClick={() => toggleModal(false)} style={{float: 'right', cursor: 'pointer'}}/>
                        <div id="postComments" style={{textAlign:'center', clear:'both'}}>
                            <p className="acknowledge-title">Acknowledgements</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{ height: '2px', backgroundColor: '#4A4E5E', border: 'none', marginBottom: '1em'}}/>
            <div className="acknowledge-content">
                { acknowledge === null || grantHandleError !== null ? <h6 style={{ color: "#9BA3CA"}}> Loading data... </h6> :
                        
                    <div>    
                        { acknowledge.map(a =>
                        <div id="container" style={{ whiteSpace:'nowrap', display:'flex', alignItems:'center',marginBottom:'10px'}}>

                            <div id="image" style={{display:'inline', marginRight:'10px'}}>
                                {
                                    a.createdDetails.map(c => 
                                        <Link to={`/profile/${c._id}`}>
                                            <ReactImageFallback
                                                src={`${c.avatar}`}
                                                fallbackImage={avatar}
                                                initialImage={avatar}
                                                alt="userAvatar"
                                                className="userAvatar"
                                            />
                                        </Link> 
                                        )
                                }

                            </div>
                            <div id="texts" style={{display:'inline', whiteSpace:'nowrap'}}> 
                                {
                                    a.createdDetails.map(c => 
                                        <Link to={`/profile/${c._id}`}>
                                            <div className="acknowledge-list" style={{ borderBottom: 'none'}} key={a._id}>
                                                {c.firstName  +' '+ c.lastName}
                                            </div>
                                        </Link> 
                                    )
                                }

                            </div>
                        
                        </div>
                        ).reverse()}

                    </div>
                }
                        
            </div>
        </Modal>

        {
            props.announcements.loading !== false ?
                <>
                    <tr className="table-td-data">
                      <td> 
                        <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                            <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </div>
                      </td>
                    </tr>
                  </>

            :

            data.length !== 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{ moment(item.createdAt).format("MMMM DD, YYYY") }</td>
                    <td style={{textTransform: 'capitalize' }}>{ item.postType }</td>
                    <td style={{width: '300px',textAlign:"inherit"}}>
                          <LinesEllipsis
                              text={announcementContentConvert(item.postContent)}
                              maxLine='1'
                              ellipsis='...'
                              basedOn='words' />
                      </td>
                    <td style={{width: '200px',textAlign:"inherit"}}>
                      { placeholderAcknowledged(item.acknowledged) === 0 && placeholderAcknowledged(item.acknowledged) }
                      {   placeholderAcknowledged(item.acknowledged) !== 0 && <a href = { () => "#" } className=" view-users-btn darkmode-btn-default" 
                          style={{ marginLeft: "3px"}}
                          onClick= {(e) => { toggleModal(!isModalOpen); viewUser(item._id);}}
                          > 
                          View {placeholderAcknowledged(item.acknowledged)} 
                          {placeholderAcknowledged(item.acknowledged) > 1 ? " users" : " user"}  </a> }
                    </td>
                    <td  style={{textAlign:"inherit"}}>{ item.mentions === null ? "No Mention" : "Announcement have a user mention" }</td>
                    <td style={{cursor: "pointer", textAlign:"center"}}>
                      <Dropdown
                        trigger={['click']}
                        overlay={
                          (
                            <Menu  className="action-dropdown">
                              <MenuItem key="2" onClick= {(e) => { changeStatus(item._id, item.status);}}  className="action-menu" ><i className="fa fa-file" aria-hidden="true" style={{marginRight:'10%'}}></i>
                                {
                                  item.status ? "Unpublish" : "Publish"
                                }
                              </MenuItem>
                              {
                                (editPermission?.includes("Announcements") === true) || (roleAllTrue === 1)  ? 
                                <MenuItem key="1"   onClick={() => {editAnnouncement(item._id)}}  className="action-menu"> <i className="fa fa-pencil" aria-hidden="true" style={{marginRight:'10%'}} ></i>Edit</MenuItem> : false
                              }
      
                              {
                                (deletePermission?.includes("Announcements") === true) || (roleAllTrue === 1)  ? <MenuItem key="4"  onClick={deleteAnnouncement} className="action-menu" ><i className="fa fa-trash" aria-hidden="true" style={{marginRight:'10%'}}></i> Delete </MenuItem> : false
                              }
                              
                            </Menu>
                          )
                        }
                        animation="slide-up"
                        onVisibleChange={()=>onVisibleChange(item)}
                      >
                        <div className={secretDetails ? 'dot-container disabled-link' : 'dot-container'}>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                        </div>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              ) : (
                  <>
                    <tr>
                        <td colSpan="12" style={{textAlign:'center'}}>
                            <img src={emptyIcon} className="empty" alt="empty"/>
                            <p style={{fontSize:'16px'}}>No Data Available</p>
                            <p style={{fontSize:'14px'}}>There are currently no data available</p>
                        </td>
                    </tr>
                  </>
                )
        }
        
        
      </>
    );
  };

  return (
    <AdminAnnouncementPage>
      <DynamicContentHeaderWrapper
        // addPermission = {addPermission}
        buttonLabel="ADD ANNOUNCEMENT"
        btnPath="/admin/announcement/create"
        entriesValue={entriesValue}
        paginationPath={"/admin/announcement"}
        location={props.location}
        originPage="Announcement"
        addPage="Announcements"
      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={AnnouncementData()}
        originPage="Announcement"
        data={props.announcements}
        location={props.location}
        paginationPath={"/admin/announcement"}
        entriesItem={entriesValuest}
      />
    </AdminAnnouncementPage>
  );
};

const mapStateToProps = ({ announcements, announcement, permissions }) => ({
  announcements,
  announcement,
  permissions
});
const AnnouncementPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "List of Announcement", link: "/admin/announcement?page=1" },
        ]);
        break;
      case "create":
        setBreadCrumbs([
          { label: "List of Announcement", link: "/admin/announcement?page=1" },
          { label: "Create Announcement", link: "#" },
        ]);
        break;
      case "edit":
        setBreadCrumbs([
          { label: "List of Announcement", link: "/admin/announcement?page=1" },
          { label: "Edit Announcement", link: "#" },
        ]);
        break;
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);




  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Announcement | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Announcement | List of Announcement | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : ''
        }
      >
        <PageWrapper className={cc(["AnnouncementPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Announcements"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <AnnouncementPageList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={['create','edit'].includes(props.mode)}>
              <CreateEditAnnoucement {...props } />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});


AnnouncementPage.WrappedComponent.displayName = "AnnouncementPage";
const AnnouncementPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <AnnouncementPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <AnnouncementPage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <AnnouncementPage {...props} mode="edit" />}
    />
  </Switch>
);
export default React.memo(AnnouncementPageRoute);
