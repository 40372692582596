import React, { Component } from "react";
import MainNav from "../Components/MainNav";
import "./PeoplesPage.sass";
import { userActions } from "../_core/actions/user";
import { peopleActions } from "../_core/actions/people";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import avatar from "../_templates/man.png";
// import loading from "../Components/Ellipsis-1s-60px.svg";
import ReactTooltip from 'react-tooltip';
import { badges } from "../_core/constants/selections";
import { history } from "../_core/helpers/history";
import { level } from "../_core/constants/selections";
import { exp_level, exp_targetPoints } from '../_core/helpers/exp';
import { feedsActions } from '../_core/actions/feeds';

class PeoplesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      level: level.software,
      selectPage: 1,
      users: [],
      recentUsers: [],
      recentPage: '',
      currentUsers: [],
      currentPage: '',
      page: 0,
      pages: 0,
      total: 0,
      loading: false
    };
    this.trackSrolling = this.trackSrolling.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(userActions.getAllActiveUsers(this.state.page));
    document.addEventListener('scroll', this.trackSrolling);
  }

  static getDerivedStateFromProps(props, state) {
    if(props.users.users){
      var addItem, page, pages, total
        if(state.recentUsers.length !== 0 && props.users.users.page === state.recentPage){
          addItem = state.recentUsers
          page = props.users.users.page
          pages = props.users.users.pages
          total = props.users.users.total
        }else if(props.users.users.items.length === props.users.users.total && props.users.users.page === 1){
          addItem = props.users.users.items
          page = state.page ? state.page : props.users.users.page
          pages = state.pages ? state.pages : props.users.users.pages
          total = state.total ? state.total : props.users.users.total
        }else if(props.users.users.page === state.page || state.users.length >= props.users.users.total){
          addItem = state.users
          page = state.page
          pages = state.pages
          total = state.total
        }else if(props.users.users.items !== state.users && state.users.length < props.users.users.total) {
          addItem = state.users.concat(props.users.users.items)
          page = props.users.users.page
          pages = props.users.users.pages
          total = props.users.users.total
        }

        return {
          users: addItem,
          page: page,
          pages: pages,
          total: total,
        };
    }
  

  return null;
  }

  trackSrolling(){
    if((window.innerHeight + window.scrollY) >= document.body.scrollHeight){
      this.setState({
        recentUsers: [],
        recentPage: '',
      },()=>{
        if(this.state.page <= this.state.pages){
          const nextPage = Number(this.state.page) + 1;
          if(this.state.page === nextPage){
            this.props.dispatch(userActions.getAllActiveUsers(nextPage + 1));
          }else{
            if(nextPage !== this.pageValue()){
              this.pageValue(nextPage)
              this.props.dispatch(userActions.getAllActiveUsers(nextPage));
              document.removeEventListener('scroll', this.trackSrolling);
            }
          }
        }
      })
    }
   
  }

  pageValue(page){
    var pages = (page ? page : 0) + 1;
    setTimeout(() => {
      document.addEventListener('scroll', this.trackSrolling);
    }, 1000);
    return pages
  }
  
  handleChange = event => {
    event.preventDefault();
    const {users} = this.state;
    this.setState({ 
      filter: event.target.value,
      recentUsers: [],
      recentPage: '',
    },()=>{
      if(this.state.filter.length <= 0){
        if(this.state.currentUsers.length !== 0){
          this.setState({
            recentUsers: this.state.currentUsers,
            recentPage: this.state.currentPage,
            currentUsers:[],
            currentPage: ''
          },()=>{
            var page = this.state.recentPage;
            this.props.dispatch(userActions.getAllActiveUsers(page));
          })
        }
        return
      }else if(this.state.filter.length > 2){
        if(this.state.currentUsers.length === 0){
          this.setState({
            currentUsers: users,
            currentPage: this.state.page
          },()=>{
            this.props.dispatch(userActions.getAllActive());
          });
        }
        return
      }
    });
  }

  handleViewProfile = id => {
    var page = 1
    this.props.dispatch(feedsActions.getFeedsByUser(id, page))
    this.props.dispatch(peopleActions.getById(id));

  };

  viewOffices() {
    history.push("/offices");
  }
  
  viewLeaderboards() {
    history.push("/leaderboards");
  }

  componentWillUnmount(){
    document.removeEventListener('scroll', this.trackSrolling);
    
  }

  getExp(exp) {
    const userExp = exp;
    var userExpLvl = [];

    var currentExp = userExp ? userExp : 0;
    var levels = exp_level(currentExp)[0];
    var curLevel = levels.current;
    var nxtLevel = levels.next;
    var targets = exp_targetPoints(curLevel, nxtLevel)[0];
    var curTarget = targets.current;
    var nxtTarget = targets.next;
    var getPercent = ((currentExp - curTarget) / (nxtTarget - curTarget))*100;
    var percent = userExp ? getPercent.toString().slice(0,2) + "%" : "0%";

    if (userExp !== undefined || userExp !== "" || !userExp) {
      try {
        userExpLvl.push({
          currentLevel: curLevel,
          currentExp: currentExp,
          targetExp: nxtTarget,
          percent: percent,
        });
      } catch (err) {}
    }
    return userExpLvl;
  }

  render() {
    const { users, total, filter, level } = this.state;
    const userLoad = this.props.users;
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = users.filter(item => {
      if(filter.length >= 3){
        return Object.keys(item.details[0]).some(key =>
          typeof item.details[0][key] === "string" && item.details[0][key].toLowerCase().includes(lowercasedFilter) && item.details[0][key] !== item.details[0].avatar && item.details[0][key] !== item.details[0].cover && item.details[0][key] !== item.details[0].photo && item.details[0][key] !== item.details[0].email && item.details[0][key] !== item.details[0]._id && item.details[0][key] !== item.details[0].user_id && item.details[0][key] !== item.details[0].officeId
        );
      }else{
        return users
      }
    });

    const totalUsers = users ? (
      total  ? total : 0
    ) : (
      <img
        alt=""
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
      />
    );
    
    const peopleList = 
    userLoad.loading && filteredData.length <= 0 ? (
      <div className="">
          <center><img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </center>
      </div>)
      : filteredData && filteredData.length <= 0 ? 
      (<div className="">
          <center><h5><em>no records found</em></h5></center>
      </div>)
      :
      users ? (
      filteredData.map((list, index) => {
        return (
          <div className="items" key={index}>
            <div className="col s6 m6 l3">
              <div className="card" id="peoplesCard">
                <div className="image-name-pos">
                  <ReactImageFallback
                    src={list.details[0].avatar}
                    fallbackImage={avatar}
                    initialImage={avatar}
                    alt="user avatar"
                    className="responsive-img people-image"
                  />
                  <div className="valign-wrapper name-pos">
                    {list.details[0].firstName} <br/>
                    {list.details[0].position}
                  </div>
                </div>
                <div className="level">
                  <span>
                    Level {this.getExp(list.exp)[0].currentLevel}{" "}
                    {level
                      .filter(level => level.value === list.details[0].level)
                      .map(level => level.label)}
                  </span>
                  <div id="progress" data-tip={this.getExp(list.exp)[0].percent} place="bottom" data-class="tooltips">
                    <span id="percent"></span>
                    <div
                      id="bar"
                      style={{ width: this.getExp(list.exp)[0].percent }}
                    ></div>
                  </div>
                  <ReactTooltip />
                  <div className="exp-credits">
                    <span className="exp">
                      {this.getExp(list.exp)[0].currentExp}/{this.getExp(list.exp)[0].targetExp} XP
                    </span>
                    <span className="credits">{list.credit} Credits</span>
                  </div>
                </div>
                {badges.default &&
                <div className="trophies">
                  {badges.default.filter((badges)=> badges.userId === list.details[0].user_id).slice(0, 3).map((badge, index) =>
                    <img
                      className="responsive-img tropImg"
                      src={badge.details[0].badgeImage}
                      alt="1"
                      key={index}
                    />
                  )}{badges.default.filter((badges)=> badges.userId === list.details[0].user_id).length <= 0 &&
                    <center className="no-badge"><em>No Badges Found</em></center>
                  }
                </div>
                }
                <div className="profile-view">
                  <Link to="#" onClick={() => this.handleViewProfile(list._id)}>
                    <button className="btn-small profile-btn">
                      View Profile
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div>
        {users.loading &&
        <em> 
          <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </em>}
      </div>
    );
    
    return (
      <div className="peoplesPage">
        <MainNav />
        <div className="container p-container">
          <Link to="/profile" className="link-goback">
              <i className="material-icons backicon">keyboard_backspace</i> 
              <span className="backtext">Go back</span>
          </Link>
          <div className="row secondRow">
            <div className="col s4 m4 people-count">
              <span className="texttotal">
                Everyone ({totalUsers}) See your entire work force at glance
              </span>
            </div>
            <div className="col s4 m4 people-search">
              <input
                className="search-user"
                type="text"
                placeholder="Filter User.."
                value={filter}
                onChange={this.handleChange}
                id="filterUser"
                autoComplete="off"
              />
              <i className="fa fa-search search-icon" aria-hidden="true"></i>
            </div>
            <div className="col s4 m4 right-align office-leaderboards">
              <button
                className="btn-small links-btn"
                onClick={this.viewOffices}
              >
                View Offices
              </button>
              <button className="btn-small links-btn" onClick={this.viewLeaderboards}>Leaderboards</button>
            </div>
          </div>
          <div className="row parent">{peopleList}</div>
          
        </div>
        
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(PeoplesPage);
