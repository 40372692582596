import React from 'react';
import cc from 'classcat';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { If, Else, Then, When } from 'react-if';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Route, Switch } from "react-router-dom";
import { useMeasure, useToggle } from 'react-use';

import AttributesWidget from '../AttributesWidget/AttributesWidget';
import Avatar from '../Components/Avatar/Avatar';
import Feeds from '../Feeds/Feeds';
import { PostWidget } from '../PostWidget/PostWidget';
import TeamWidget from '../TeamWidget/TeamWidget';
import WorkstatusWidget from '../WorkStatusWidget/WorkstatusWidget';
import TimekeepingWidget from '../TimekeepingWidget/TimekeepingWidget';
import CommendsTipsWidget from '../CommendsTipsWidget/CommendsTipsWidget';

import { userService } from '../_core/services/user';
import { getPositionDepartment } from '../ProfileWidget/ProfileWidget';

import { UserDashboardContext } from '../UserDashboard/UserDashboard';
import Footer from '../Footer/Mainfooter.js';
import { decryptUserEmail } from '../_core/helpers/crypto_config';

import { department, maritalStatus, position } from '../_core/constants/selections';
import { hex } from '../_core/helpers/profile';

import imgNotFound from '../_templates/img-not-found.png';


import bioClassnames from './Bio.module.scss';
import './Profile.scss';

import { sessionService } from '../_core/services/session.js';

const mapStateToProps = (state) => ({
	authentication: state.authentication,
});

const Bio = ({ user }) => {
	const [open, toggle] = useToggle(false);


	const decryptValues = (val) => {
		let isValid = !!val !== null || val !== '' || val !== undefined;
		let decryptVal = '';
		if (isValid) {
			decryptVal = decryptUserEmail(val) === '' ? val : decryptUserEmail(val);
		}
		return decryptVal;
	}

	return (
		<div
			className={cc([
				'card',
				'z-depth-0',
				bioClassnames.bio
			])}
		>
			<If condition={open}>
				<Then>
					<div className={bioClassnames.field}>
						<h6 data-main>Bio</h6>
						<p>{user.details[0].bio}</p>
					</div>
					<div className={bioClassnames.field}>
						<h6 data-main>Profile</h6>
					</div>
					<div className={bioClassnames.field}>
						<h6>Email</h6>
						<p>{decryptValues(user.email)}</p>
					</div>
					<div className={bioClassnames.field}>
						<h6>Birth date</h6>
						<p>{moment(user.details[0].birthDate).utc().format('MMMM D, YYYY')}</p>
					</div>
					<div className={bioClassnames.field}>
						<h6>Marital status</h6>
						<p>
							{
								maritalStatus.default.filter(status => (
									status.value === user.details[0].maritalStatus
								))[0]?.label ||
								'No data available'
							}
						</p>
					</div>
					<div className={bioClassnames.field}>
						<h6>Mother's maiden name</h6>
						<p>
							{
								user.details[0].mothersMaidenName || 'No data available'
							}
						</p>
					</div>
					<div className={bioClassnames.field}>
						<h6>Address</h6>
						<p>
							{
								[
									user.details[0].city,
									user.details[0].state,
									user.details[0].country,
								].join(', ')
							}
						</p>
					</div>
					<div className={bioClassnames.field}>
						<h6>Nationality</h6>
						<p>{user.details[0].nationality ?? 'No data available'}</p>
					</div>
					<div className={bioClassnames.field}>
						<h6 data-main>Employment details</h6>
					</div>
					<div className={bioClassnames.field}>
						<h6>Department</h6>
						<p>
							{
								department.default.filter(department => (
									department.value === user.details[0].department
								))[0]?.label ??
								'No data available'
							}
						</p>
					</div>
					<div className={bioClassnames.field}>
						<h6>Position</h6>
						<p>
							{
								position.software.filter(position => (
									position.value === user.details[0].position
								))[0]?.label ??
								'No data available'
							}
						</p>
					</div>
					<div className={bioClassnames.field}>
						<h6>Start date</h6>
						<p>{moment(user.details[0].startDate).utc().format('MMMM D, YYYY')}</p>
					</div>
					<div className={bioClassnames.field}>
						<h6>Regularization date</h6>
						<p>{moment(user.details[0].regularizationDate).utc().format('MMMM D, YYYY')}</p>
					</div>
					<button onClick={toggle}>Show less</button>
				</Then>
				<Else>
					<button onClick={toggle}>Show profile</button>
				</Else>
			</If>
		</div>
	);
}

const Profile = connect(mapStateToProps)((props) => {

	const [user, setUser] = React.useState({
		details: [{}]
	});
	const [session, setSession] = React.useState([]);
	const device = React.useContext(UserDashboardContext).device;
	const secretDetails = JSON.parse(localStorage.getItem("secretDetails"));

	const isPublic = React.useMemo(() => {
		if (!user._id) {
			return false;
		}

		return user._id !== props.authentication.user.user._id;
	}, [props.authentication, user]);
	const [profileRowRef, { height: profileRowHeight }] = useMeasure();
	const [rowRef,
		// { height: rowHeight }
	] = useMeasure();


	React.useEffect(() => {
		const activeUsers = async () => {
			try {
				const user = (await sessionService.getAll()).success;
				setSession(user);
			} catch (error) {
				// TODO: This user doesn't exist
			}
		}

		activeUsers();
	}, [setSession]);

	React.useEffect(() => {
		if (!props.match.params.user) {
			props.history.push(`/profile/${props.authentication?.user?.user?._id}`)
		}
	}, [props.authentication, props.history, props.match]);

	React.useEffect(() => {
		if (!props.match.params.user) {
			return;
		}

		const getUser = async () => {
			try {
				const user = (await userService.getById(props.match.params.user)).user[0];
				setUser(user);
			} catch (error) {
				// TODO: This user doesn't exist
			}
		}

		getUser();
	}, [props.match.params.user, setUser]);

	const activeUsers = session.map(obj => obj.user_id);

	return (
		<DocumentTitle
			title={
				[
					(
						isEmpty(user.details[0]) ?
							'Profile' :
							`${user.details?.[0]?.firstName} ${user.details?.[0]?.lastName}`
					),
					process.env.REACT_APP_DOCUMENT_TITLE
				].join(' | ')
			}
		>
			<div className={cc(['profile', device])}>
				<div
					className={cc([
						'profile-area',
						{
							'pointer-events-none': device === 'desktop' && isPublic
						}
					])}
				>
					<div className={cc(['cover-photo', device])} style={{ display: (user.details?.[0]?.cover) ? 'flex' : 'block' }}>
						<If condition={!!user.details?.[0]?.cover}>
							<Then>
								{/* <img src={user.details?.[0]?.cover} alt="cover"/> */}
								<img alt="" src={user.details[0].cover || imgNotFound} onError={(e) => { e.target.src = imgNotFound }} />
							</Then>
							<Else>
								<div style={{ background: hex(user.details[0].firstName + user.details[0].lastName + '') }}></div>
							</Else>
						</If>
					</div>
					<div className="row" ref={profileRowRef} style={{ display: (device === 'desktop' && isPublic) ? '' : 'flex' }}>
						<div className="col s12 l4 avatar-name-container">
							<div className={activeUsers.includes(user._id) ? "online-indicator" : "offline-indicator" }      />
							<div className="avatar-container">
								<If condition={!!user.details?.[0]?.avatar}>
									<Then>
										<Avatar name="hey" border="solid 4px white" src={user.details?.[0]?.avatar || ''} size={100} />
									</Then>
									<Else>
										{/* <div className="people-initials-avatar" style={{
                           backgroundColor: hex(`${user.details?.[0]?.firstName} ${user.details?.[0]?.lastName}`)}}>{String(user.details?.[0]?.firstName).charAt(0).toUpperCase() + String(user.details?.[0]?.lastName).charAt(0).toUpperCase()}</div> */}
										<div className="people-initials-avatar" style={{ background: hex(user.details[0].firstName + user.details[0].lastName + '') }}>{String(user.details?.[0]?.firstName).charAt(0).toUpperCase() + String(user.details?.[0]?.lastName).charAt(0).toUpperCase()}</div>
									</Else>
								</If>
							</div>
							<div className="name">
								<h6>{`${user.details?.[0]?.firstName} ${user.details?.[0]?.lastName}`}</h6>
								<p>{getPositionDepartment(user.details?.[0]) ?? `&nbsp;`}</p>
							</div>
						</div>
						<When condition={device === 'desktop' && isPublic === false}>
							<div className="col s12 l8 post-widget-container">
								<PostWidget />
							</div>
						</When>
					</div>
				</div>
				<div className="row">
					<div className="sub-panel col s12 l4" ref={rowRef}>
						<div>
							<Bio user={user} />
							<WorkstatusWidget 
								userId={user._id}
							/>
							{
								secretDetails !== null ?
								''
								:
								<>
								{isPublic && user._id &&
									<CommendsTipsWidget commendedUser={user} />
								}
								</>
								
							}

							<TimekeepingWidget
								public={isPublic}
								username={user.praggerUsername}
								userteam={user.team}
								user={user}
							/>
							{/* {
								secretDetails !== null ?
								<If condition={secretDetails.user_id}>
									<Then>
										<AttributesWidget userId={secretDetails.user_id} />
									</Then>
								</If>
								:
								<If condition={isPublic === false && user._id}>
									<Then>
										<AttributesWidget userId={user._id} />
									</Then>
								</If>
							} */}

							{
								secretDetails !== null && secretDetails.user_id !== undefined && (
									<If condition={secretDetails.user_id}>
										<Then>
											<AttributesWidget userId={secretDetails.user_id} />
										</Then>
									</If>
								)
							}
							{
								secretDetails === null && isPublic === false && user._id !== undefined && (
									<If condition={user._id}>
										<Then>
											<AttributesWidget userId={user._id} />
										</Then>
									</If>
								)
							}

							<TeamWidget team={user.team} userId={user._id}/>
						</div>
						{device === 'desktop' &&
							<div><Footer /></div>
						}
					</div>
					<div
						className={cc([
							'main-panel',
							'col',
							'l8',
							's12',
							device
						])}
						style={{
							marginTop: (
								device === 'desktop' &&
								isPublic
							) ? -profileRowHeight : 0
						}}
					>
						<div style={{ marginTop: (device === 'desktop' && isPublic) ? 20 : 0 }}>
							<When condition={device === 'tablet' || isPublic}>
								<PostWidget />
							</When>
							<Feeds
								// minHeight={rowHeight + profileRowHeight}
								singleUser
								userId={user._id}
							/>
						</div>
					</div>
					{device === 'tablet' &&
						<div><Footer /></div>
					}
				</div>
			</div>
		</DocumentTitle>
	);
});
Profile.WrappedComponent.displayName = 'Profile';

const ProfileRoute = (props) => (
	<Switch>
		<Route exact path={props.match.path} component={Profile} />
		<Route exact path={`${props.match.path}/edit`} component={Profile} />
		<Route path={`${props.match.path}/:user/edit`} component={Profile} />
		<Route path={`${props.match.path}/:user`} component={Profile} />
	</Switch>
);

export default React.memo(ProfileRoute);
