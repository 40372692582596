import React from 'react';
import { Link } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import avatar from '../_templates/man.png';
import M from 'materialize-css/dist/js/materialize.min.js'
import MaterialIcon from 'material-icons-react';
import './MainNav.sass';
import { connect } from 'react-redux';
import { roleActions } from '../_core/actions/role';
import { userActions } from '../_core/actions/user';

class MainNav extends React.Component {

  constructor(props) {
    super(props);
            
    this.state = {
        render: true,
        newNotif: 0,
        notif: [],
        page: 0,
        pages: 0,
        total: 0,
    }
  }

  componentDidMount() {
    var elem = document.querySelector(".dropdown-trigger");
    M.Dropdown.init(elem);

    var elem1 = document.querySelector(".dropdown-trigger1");
    M.Dropdown.init(elem1);

    var elem2 = document.querySelector(".notif-dropdown-trigger");
    M.Dropdown.init(elem2);

    let user = JSON.parse(localStorage.getItem('userCredentials'));
    this.props.dispatch(roleActions.getOneRole(user.user.role));
    
  }

  handlelogout() {
    userActions.logout();
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const role = JSON.parse(localStorage.getItem('userRole'));

    return (
      <nav className="pragNav">

        {/* <ul id="profileDropdown" className="dropdown-content">
          <li><Link to="/overtime" className="waves-effect" > Request Overtime</Link></li>
          <li><Link to="/leaves" className="waves-effect" > Request Filings</Link></li>
          <li><Link to="/send/hr-concern" className="waves-effect" > Submit HR Concern</Link></li>
          <li><Link to="/send/support-ticket" className="waves-effect" >Submit Support Ticket</Link></li>
          <li><Link to="/send/coe" className="waves-effect" >Request for COE</Link></li>
          <li><Link to="/profile/edit" className="waves-effect" >Edit profile</Link></li>
          <li><Link to="/changepassword" className="waves-effect" >Change password</Link></li>
          <li><Link to="#!" className="waves-effect" >Settings</Link></li>
          <li className="divider"></li>
          <li><Link to="/" onClick={this.handlelogout} className="waves-effect" ><i className="material-icons">exit_to_app</i> Logout</Link></li>
        </ul> */}

        <ul id="profileDropdown" className="dropdown-content drpList">
          <li className="drpListLi"><Link to="/dashboard"className="drpListTxt">Dashboard</Link></li>
          {/* <li className="drpListLi"><Link to="#!"className="drpListTxt">Account</Link></li>
          <li className="drpListLi"><Link to="#!"className="drpListTxt">Settings</Link></li> */}
          <li className="drpListLi"><Link to="/edit-profile" className="waves-effect drpListTxt">Edit profile</Link></li>
          { role !== null && role[0] && role[0].roleAll === 1 ?
            (<li className="drpListLi"><Link to="/admin/dashboard" className="drpListTxt">Administrator</Link></li>)
            : ''
          }
          <li className="drpListLi"><Link to="/" onClick={this.handlelogout} className="drpListTxt">Logout</Link></li>
                
            
        </ul>

        <ul id="profileDropdown1" className="dropdown-content drpList1">
          <li>
            <span className="ham-center-img">
                <span className="profile-nav-img">
                  <ReactImageFallback
                    src={`${user.avatar}`}
                    fallbackImage={avatar}
                    initialImage={avatar}
                    alt="user avatar"
                    className="prof-img circle responsive-img"
                  />
                </span>
                <p className="nav-prof-name">{user.firstName} {user.lastName}</p>
              </span>
          </li>
          <li><Link to="/dashboard">Dashboard</Link></li>
          <li><Link to="/people">People</Link></li>
          {/* <li><Link to="#!">Feed</Link></li> */}
          <li><Link to="/resetpassword" className="waves-effect drpListTxt">Change Password</Link></li>
          {role !== null && role[0] && role[0].roleAll === 1 ?
            (<li><Link to="/admin">Administrator</Link></li>
            ):''
          }
          <li className="drpListLi"><Link to="/" onClick={this.handlelogout} className="drpListTxt">Logout</Link></li>
          
          
        </ul>

        <div className="nav-wrapper ActionNav">
          {/* <Link to="/" className="brand-logo center">P</Link> */}
          <ul className="left">
            <li className="hide-on-med-and-down mainNv"><Link to="/people">PEOPLE</Link></li>
            <li className="hide-on-med-and-down mainNv"><Link to="#!">TROPHIES AND BADGES</Link></li>
            {/* <li className="hide-on-med-and-down"><Link to="#!">Challenges</Link></li>
            <li className="hide-on-med-and-down"><Link to="#!">Quests</Link></li>
            <li className="hide-on-med-and-down"><Link to="#!">Rewards</Link></li> */}
            <li className="navMobile mainNv"><a href="#!" className="dropdown-trigger1" data-target="profileDropdown1"><i className="material-icons right">menu</i></a></li>
          </ul>

          <ul className="right drpTitle hide-on-med-and-down">
            {/* <li className="notif"><span className="bell"></span><span className="new-notif">1</span></li> */}
            <li className="notif"><span className="bell"></span></li>
            <li className="txtLines">|</li>
            {/* <li className="mainNv"><Link to="#!">Feed</Link></li> */}
            {/* <li className="txtLines">|</li> */}
            <li className="mainNv profile-dd">
              <a href="#!" className="dropdown-trigger" data-target="profileDropdown">
              <span className="valign-wrapper">
                <span className="profile-nav-img">
                  <ReactImageFallback
                    src={`${user.avatar}`}
                    fallbackImage={avatar}
                    initialImage={avatar}
                    alt="user avatar"
                    className="prof-img circle responsive-img"
                  />
                </span>
                <span className="nav-prof-name">{user.firstName.split(' ')[0]} {user.lastName}</span>
                <span><MaterialIcon icon="arrow_drop_down" size="small" color="white">arrow_drop_down</MaterialIcon></span>
              </span>
              </a></li>
          </ul>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
	const { role } = state;
    return { role };
}

export default MainNav = connect(mapStateToProps)(MainNav);
// export default MainNav;
