import React from "react";
import cc from "classcat";
import { connect } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import { If, Else, Then } from "react-if";
import { Link } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import queryString from 'query-string';
import "./HRPageDark.scss";
import LinesEllipsis from "react-lines-ellipsis";
import moment from "moment";
import DocumentTitle from "react-document-title";
import { Switch as SwitchCase, Case } from "react-if";
import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import {
  DynamicHeaderContent,
  DynamicTableAndPaginationWrapper,
  DynamicEntriesOnly,
} from "../../Components/PageWrapper/DynamicWrapper";
import UpdateConcernPage from "./UpdateHRPageDark";
import "../../Components/StatusStyle/status.scss";
import "./UpdateHRPageDark.scss";

import emptyIcon from '../../../_templates/empty.png';

const mapStateToProps = ({ hrConcerns }) => ({
  hrConcerns,
});

const HRConcernList = (props) => {
  const [entriesValue, setentriesValue] = React.useState();

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  const updateEntries = (entries) => {
    setentriesValue(entries);
  };

  let tableHeader = (editPermission.includes("Support - HR Concerns") === true) || (roleAllTrue === 1) ? [
    "Reference Code",
    "Reason",
    "Submitted",
    "Date Approved",
    "Status",
    "Actions",
  ] : [
    "Reference Code",
    "Reason",
    "Submitted",
    "Date Approved",
    "Status",
    "",
  ];

  const ActivePage = Number(queryString.parse(props.location.search).page) || 1

  const HRData = () => {
    return (
      <If condition={props.hrConcerns.loading === false}>
        <Then>

        <If  condition={props.hrConcerns.hrConcerns.total === 0}>
            <Then>
                <tr>
                    <td colSpan="12" style={{textAlign:'center'}}>
                        <img src={emptyIcon} className="empty" alt="empty"/>
                        <p style={{fontSize:'16px'}}>No Data Available</p>
                        <p style={{fontSize:'14px'}}>There are currently no data available</p>
                    </td>
                </tr>
            </Then>

            <Else>
                {props.hrConcerns.loading === false &&
                    props.hrConcerns.hrConcerns.items.map((hrConcernItem) => (
                    <tr key={hrConcernItem._id}>
                        <td style={{ width: "12%" }}>
                        {hrConcernItem.refCode ? hrConcernItem.refCode : "No Code"}
                        </td>
                        <td style={{ width: "25%" }}>
                        <LinesEllipsis
                            text={
                            hrConcernItem.description !== null
                                ? hrConcernItem.description
                                : ""
                            }
                            maxLine="1"
                            ellipsis="..."
                            trimRight
                            basedOn="words"
                        />
                        </td>
                        <td style={{ width: "10%" }}>
                        {moment(hrConcernItem.createdAt).fromNow()}
                        </td>
                        <td style={{ width: "10%" }}>
                        {hrConcernItem.updatedAt
                            ? moment(hrConcernItem.updatedAt).fromNow()
                            : "Pending"}
                        </td>
                        <td style={{ width: "10%" }} className={hrConcernItem.status}>
                        {hrConcernItem.status === "new"
                            ? "In review"
                            : hrConcernItem.status &&
                            hrConcernItem.status === "approved"
                            ? "Approved"
                            : hrConcernItem.status &&
                            hrConcernItem.status === "rejected"
                            ? "Rejected"
                            : hrConcernItem.status &&
                            hrConcernItem.status === "cancelled"
                            ? "Cancelled"
                            : hrConcernItem.status && hrConcernItem.status === "on hold"
                            ? "On hold"
                            : hrConcernItem.status &&
                            hrConcernItem.status === "completed"
                            ? "Completed"
                            : hrConcernItem.status}
                        </td>
                        {
                        (editPermission.includes("Support - HR Concerns") === true) || (roleAllTrue === 1) ? 
                            <td
                                style={{ width: "10%", textAlign: "end" }}
                                className={secretDetails !== null ? "disabled-link" : '' }
                            >
                                <Link
                                to={`${props.match.path}/review/${ActivePage}/${hrConcernItem._id}`}
                                >
                                <button id="review">
                                    REVIEW
                                </button>
                                </Link>
                            </td> : false
                        }
                    </tr>
                ))}
            </Else>
        </If>
         
        </Then>
        <Else>
          <tr className="table-td-data">
            <td> 
              <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                  <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
            </td>
          </tr>
        </Else>
      </If>
    );
  };
  return (
    <div>
      <DynamicEntriesOnly 
        entriesValue={updateEntries} 
        originPage="HRSupport"
      />
      
      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={HRData()}
        originPage="HRSupport"
        data={props.hrConcerns}
        location={props.location}
        paginationPath={`${props.match.path}?page=${props.match.params.page}`}
        entriesItem={entriesValue}
      />
    </div>
  );
};

const HRConcernPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";
  const [breadCrumbs, setBreadCrumbs] = React.useState([]);

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "HR Concerns", link: `/admin/hr-concerns?page=1` },
        ]);
        break;
      case "review":
        setBreadCrumbs([{
            label: "HR Concerns",
            link: `/admin/hr-concerns?page=1`,
          },
          { label: "Review", link: "#" },
        ]);
        break;
      default:
        // Error page
        break;
    }
  };

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);

  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "review"
            ? `Review | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Support | HR Concern | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : ""
        }
      >
        <PageWrapper className={cc(["HRConcernsPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Support"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={["list"].includes(props.mode)}>
              <ContentWrapper>
                <HRConcernList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={["review"].includes(props.mode)}>
              <UpdateConcernPage {...props} />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});
HRConcernPage.WrappedComponent.displayName = "HRConcernPage";
const HRConcernPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <HRConcernPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/review/:page/:requestId`}
      render={(props) => <HRConcernPage {...props} mode="review" />}
    />
  </Switch>
);
export default React.memo(HRConcernPageRoute);
