import { userAttributeConstants } from '../constants/userAttribute';
import { userAttributeService } from '../services/userAttribute';
import { alertActions } from './alert';
// import { history } from '../helpers/history';

export const userAttributeActions = {
    getUserAttributes,
    addUserAttribute,
    delete: _delete
};

function getUserAttributes(id) {
    return dispatch => {
        dispatch(request(id));

        userAttributeService.getUserAttributes(id)
            .then(
                userAttributes => {
                    if(id) {
                        dispatch(success(userAttributes.user[0].attributes[0]))
                    } else {
                        dispatch(success(userAttributes.success));
                    }
                    localStorage.setItem('userAttribute', JSON.stringify(id))
                    // history.push('/admin/user/add/attributes');
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userAttributeConstants.GET_USERATTRIBUTE_REQUEST } }
    function success(userAttributes) { return { type: userAttributeConstants.GET_USERATTRIBUTE_SUCCESS, userAttributes } }
    function failure(error) { return { type: userAttributeConstants.GET_USERATTRIBUTE_FAILURE, error } }
}

function addUserAttribute(userAttribute) {
    return dispatch => {
        dispatch(request(userAttribute));

        userAttributeService.addUserAttribute(userAttribute)
            .then(
                userAttribute => {
                    dispatch(success());
                    dispatch(alertActions.success('New attribute has been created'));
                    setTimeout(function(){window.location.reload(1);}, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('something went wrong'));
                }
            );
    };

    function request(userAttribute) { return { type: userAttributeConstants.ADD_USERATTRIBUTE_REQUEST, userAttribute } }
    function success(userAttribute) { return { type: userAttributeConstants.ADD_USERATTRIBUTE_SUCCESS, userAttribute } }
    function failure(error) { return { type: userAttributeConstants.ADD_USERATTRIBUTE_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(userAttribute) {
    return dispatch => {
        dispatch(request(userAttribute));

        userAttributeService.delete(userAttribute)
            .then(
                userAttribute => {
                    dispatch(success(userAttribute));
                    dispatch(alertActions.success('User Attribute Successfully deleted'));
                    setTimeout(function(){window.location.reload(1);}, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Delete User Attribute failed'));
                }
            );
    };

    function request(userAttribute) { return { type: userAttributeConstants.DELETE_USERATTRIBUTE_REQUEST, userAttribute} }
    function success(userAttribute) { return { type: userAttributeConstants.DELETE_USERATTRIBUTE_SUCCESS, userAttribute } }
    function failure(userAttribute, error) { return { type: userAttributeConstants.DELETE_USERATTRIBUTE_FAILURE, userAttribute, error } }
}