import { badgesConstants } from '../constants/badges';

let initialState = {
  items: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function badges( state = initialState, action) {
  switch (action.type) {
    //get quest
    case badgesConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case badgesConstants.GETALL_SUCCESS:
      return {
        loading: false,
        items: action.badges
      };
    case badgesConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

    case badgesConstants.GET_REQUEST:
      return {
        saving: true
      };
    case badgesConstants.GET_SUCCESS:
      return {
        items: action.badges
      };
    case badgesConstants.GET_FAILURE:
      return {
        error: action.error
      };

    case badgesConstants.CREATE_REQUEST:
      return { creating: true };
    case badgesConstants.CREATE_SUCCESS:
      return {};
    case badgesConstants.CREATE_FAILURE:
      return {};
    
    case badgesConstants.BADGE_DEL_SUCCESS:
      return {
        attributes: action.badges
      };
    case badgesConstants.UPDATE_BADGES_REQUEST:
      return {loading: true}

      default:
        return state
  }
}