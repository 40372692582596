import { questConstants } from '../constants/quest';

export function quests(state = {}, action) {
  switch (action.type) {
    //get quest
    case questConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case questConstants.GETALL_SUCCESS:
      return {
        loading: false,
        items: action.quests
      };
    case questConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    //delete user
    // case questConstants.DELETE_REQUEST:
    //   // add 'deleting:true' property to user being deleted
    //   return {
    //     ...state,
    //     items: state.items.map(quest =>
    //       quest.id === action.id
    //         ? { ...quest, deleting: true }
    //         : quest
    //     )
    //   };
    // case questConstants.DELETE_SUCCESS:
    //   // remove deleted user from state
    //   return {
    //     items: state.items.filter(quest => quest.id !== action.id)
    //   };
    // case questConstants.DELETE_FAILURE:
    //   // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
    //   return {
    //     ...state,
    //     items: state.items.map(quest => {
    //       if (quest.id === action.id) {
    //         // make copy of user without 'deleting:true' property
    //         const { deleting, ...questCopy } = quest;
    //         // return copy of user with 'deleteError:[error]' property
    //         return { ...questCopy, deleteError: action.error };
    //       }

    //       return quest;
    //     })
    //   };
    //get quest by id
    case questConstants.GET_REQUEST:
      return {
        loading: true
      };
    case questConstants.GET_SUCCESS:
      return {
        quest: action.quest
      };
    case questConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    //getallUserQuests
    case questConstants.USERQUESTS_REQUEST:
      return {
        loading: true
      };
    case questConstants.USERQUESTS_SUCCESS:
      return {
        items: action.quests
      }
    case questConstants.USERQUESTS_FAILURE:
      return {
        error: action.error
      };
    //PostQuest
    case questConstants.POSTQUEST_REQUEST:
        return {saving: true};
    case questConstants.POSTQUEST_SUCCESS:
        return {};
    case questConstants.POSTQUEST_FAILURE:
        return {error: action.error};
    case questConstants.DELETE_SUCCESS:
      return {
        attributes: action.quests
      };
    default:
      return state
  }
}