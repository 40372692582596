import {  post, get, put } from '../helpers/api';
import { userService } from '../services/user';

export const coeService = {
    sendRequestCoe,
    getAll,
    update,
    approve,
    hold,
    reject,
    getById
};

function createActivity(activity,id) {
    return post(`/api/v1/user/${id}/activity`, JSON.stringify(activity)).then(handleResponse);
}

function sendRequestCoe(coes){
    return post('/api/v1/request', JSON.stringify(coes))
        .then(handleResponse)
        .then(response => {
            getById(response._id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });  
}
function getAll(selectPage, itemPerPage) {
    return get(`/api/v1/request?page=${selectPage || 1}&items-per-page=${itemPerPage || 20}&filter=subtype:certificate%20of%20employment`).then(handleResponse)
}


function update(coes) {
    let id = coes._id
    delete coes._id
    return put(`/api/v1/request/${id}`, JSON.stringify(coes))
        .then(handleResponse)
}

function approve(coes) {
    let id = coes._id
    delete coes._id
    return put(`/api/v1/request/${id}/approve`, JSON.stringify(coes))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });  
}

function hold(coes) {
    let id = coes._id
    delete coes._id
    return put(`/api/v1/request/${id}/hold`, JSON.stringify(coes))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });  
}

function reject(coes) {
    let id = coes._id;
    delete coes._id;
    return put(`/api/v1/request/${id}/reject`, JSON.stringify(coes))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });  
}

function getById(id) {
    return get(`/api/v1/request/${id}`).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}