import { timesheetConstants } from '../constants/timesheet';
import { timesheetServices } from '../services/timesheet';

export const timesheetActions = {
    getByUsername,
};

function getByUsername(username, startdate, enddate) {
    return dispatch => {
        dispatch(request());

        timesheetServices.getByUsername(username, startdate, enddate)
            .then(
                timesheet => dispatch(success(timesheet)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: timesheetConstants.USER_TIMESHEET_REQUEST } }
    function success(timesheet) { return { type: timesheetConstants.USER_TIMESHEET_SUCCESS, timesheet } }
    function failure(error) { return { type: timesheetConstants.USER_TIMESHEET_FAILURE, error } }
}