import React from "react";
import { history } from '../../_core/helpers/history';
import { connect } from 'react-redux';

import { essActions } from '../../_core/actions/ess';
// import { userOvertimeRequest } from '../../_core/actions/userOvertime';
import requestsActions from '../../_core/actions/requests'

import Modal from 'react-modal';
import moment from 'moment';

import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import './RequestOvertimeModal.sass';
import holiday from '../../holiday.js';
import PolicyList from "../PolicyList";

import Dropzone from "./Dropzone";

import TimePicker from 'rc-time-picker';
import clock from '../../_templates/clock_icon.png';

import styled from "styled-components";

const format = 'h:mm a';

const StyledTimePicker = styled(TimePicker)`
  .rc-time-picker {
    width: 99%;
  }

  & .rc-time-picker-panel-select-option-selected {
    background-color: #edeffe;
    font-weight: normal;
  }

  & .rc-time-picker-clear,
  & .rc-time-picker-clear-icon:after {
    font-size: 15px;
  }

  .rc-time-picker-clear {
    top: 17px;
    right: 15px;
  }

  & .rc-time-picker-panel-select,
  & .rc-time-picker-input,
  & .rc-time-picker-panel-input {
    font-size: 16px;
    cursor: pointer;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`;

const iconStyle = {
	position: 'absolute',
	width: '24px',
	right: 0,
	top: 0,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};
  

class RequestModalOvertime extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      userDetails: null,
      isUndertime: props.isUndertime, 
      overtime: {
        subject: "",
        description: "",
        type: "request",
        subtype: props.isUndertime ? "undertime" : "overtime",
        startedAt: "",
        endedAt: "",
        files:[]
      },

      startOfTime: '',
      endOfTime: '',
      date: '',

      HolidayList: [],

      itemPerPage: 10,

      render: true,
      submitted: false,

	  open: false,
	  useIcon: false,
    }

    this.getDate = this.getDate.bind(this)
    this.disableHoliday = this.disableHoliday.bind(this)
  }

  getIcon = (path, style = {}) => {
    return (
      <i
        style={{
          fontSize: '12px',
          fontStyle: 'normal',
          color: '#aaa',
          display: 'inline-block',
          lineHeight: '1',
          width: '20px',
          transition: 'color 0.3s ease',
          ...style,
        }}
      >
        <img src={path} className="clockimg" alt="clock"/>
      </i>
    );
  };


  close(e) {
    e.preventDefault();
    const { itemPerPage } = this.state;
    const userDetails = JSON.parse(localStorage.getItem("userCredentials"));
    this.props.dispatch(requestsActions.getRequests({
      itemPerPage,
      selectPage: 1,
      userId: userDetails.user._id
    }));
    history.goBack();
  }

  disableHoliday() {
    let holidayLists = []

    holiday.items.map((holiday, i) => {

      let dateDay = new Date(holiday.date);
      var day = dateDay.getDay();
      var isWeekend = (day === 6) || (day === 0);
      if (isWeekend === false) {
        holidayLists.push(new Date(holiday.date));
      }
      return null // To remove warning 
    })

    return holidayLists;
  }

  getStartTime(e) {
    //console.log(e.target.value)
    this.setState({
      startOfTime: e && e.format("HH:mm")
    }, () => {
      this.getTimes()
    });
  }

  getEndTime(e) {
    // console.log(e.target.value)
    this.setState({
      endOfTime: e && e.format("HH:mm")
    }, () => {
      this.getTimes()
    });
  }

  getDate(date) {
    const setDate = date;
    this.setState({
      date: moment(setDate).format()
    }, () => {
      this.getTimes()
      this.isValidDate();
    })
  }

  getTimes() {
    const overtime = this.state.overtime;
    const date = this.state.date;
    const startTime = this.state.startOfTime;
    const endTime = this.state.endOfTime;

    if (date && startTime && endTime) {
      var start = moment(date).format('YYYY-MM-DD') + "T" + startTime + ":00." + moment().format('SSS[Z]');
      var end = moment(date).format('YYYY-MM-DD') + "T" + endTime + ":00." + moment().format('SSS[Z]');

      this.setState({
        overtime: {
          ...overtime,
          startedAt: start,
          endedAt: end,
        }
      }, () => {
      })
    }
  }

  isValidDate() {
    const { overtime, HolidayList } = this.state;
    const storeHolidayDate = [];

    HolidayList.splice(0, HolidayList.length);
    storeHolidayDate.splice(0, storeHolidayDate.length)

    holiday.items.map((holiday) => {

      // Check if selected start date or end date has holiday
      if (moment(holiday.date).format() === moment(overtime.startedAt).format()) {

        // Filter to prevent duplicate holiday input in an array
        if (storeHolidayDate.indexOf(holiday) === -1) {
          let dateDay = new Date(holiday.date);
          var day = dateDay.getDay();
          var isWeekend = (day === 6) || (day === 0);
          if (isWeekend === false) {
            storeHolidayDate.push(holiday)
          }
        }

        this.setState({ HolidayList: storeHolidayDate }, () => {

          if (storeHolidayDate.length !== 0) {
            Swal.fire({
              title: 'Selected Date Range Invalid',
              html:
                "<div>Selected Date Range has a holiday </div> <div style='color:rgb(38, 166, 154)'>Note: Select Date range does not have a holiday</div>",
              type: 'error',
              // showCancelButton: true,
              confirmButtonColor: '#26a69a',
              // cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }

        })
      }
      return null // To remove warning 
    })
  }

  getDescription = (e) => {
    const overtime = this.state.overtime;
    this.setState({
      overtime: {
        ...overtime,
        description: e.target.value,
      }
    }, () => {
    });
  }
  getTitle(e) {
    const overtime = this.state.overtime;
    this.setState({
      overtime: {
        ...overtime,
        subject: e.target.value,
      }
    }, () => {

    });
  }

  getTimeRange() {
    const date = this.state.date;
    var startTime = moment(this.state.startOfTime, "HH:mm A");
    var endTime = moment(this.state.endOfTime, "HH:mm A");
    var totalTime = '';

    if (date && startTime && endTime) {
      var duration = moment.duration(moment(endTime).diff(startTime)); // Get difference StartTime and EndTime
      var hours = parseInt(duration.asHours()); // Hours Convert
      var minutes = parseInt(duration.asMinutes()) % 60;  // Minutes Convert

      var hr = '';
      var min = '';

      if (hours < 0 || hours === 1) {
        hr = hours + " hour"
      } else if (hours === 0) {
        hr = ''
      } else if (hours > 1) {
        hr = hours + " hours"
      }

      if (minutes < 0 || minutes === 1) {
        min = minutes + " minute"
      } else if (minutes === 0) {
        min = ''
      } else if (minutes > 1) {
        min = minutes + " minutes"
      }

      totalTime = hr + " " + min;

      return totalTime
    } else {
      return
    }
  }


  handleSubmit(event) {
    event.preventDefault();
    const { overtime, HolidayList } = this.state;

    var StartTime = document.querySelector(".startedAtValue .rc-time-picker-input").value;
    var EndTime = document.querySelector(".endedAtValue .rc-time-picker-input").value;

    var totalHours = document.querySelector(".totalvalue").value;
    var date = document.querySelector(".dateValue").value;


    var dateNow = Date(Date.now()).toLocaleString();
    var dateFormat = moment(dateNow).format("ddd, MMMM DD, YYYY");

    this.setState({ submitted: true })

    // custom toast mixin for sweetAlert
    /*eslint-disable */
    if (overtime.startedAt && overtime.endedAt && overtime.description && overtime.subject) {


      if (totalHours.includes("-")) {
        Swal.fire({
          title: 'Negative time range computed!',
          text: 'Check your inputted start and end time',
          type: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          // confirmButtonColor: '#26a69a',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Ok'
        })
      } else if (this.state.startOfTime === this.state.endOfTime) {
        Swal.fire({
          title: 'No time range computed',
          text: 'Check your inputted start and end time',
          type: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          // confirmButtonColor: '#26a69a',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Ok'
        })
      } else if (HolidayList.length !== 0) {
        Swal.fire({
          title: 'Selected Date Invalid',
          html:
            "<div>Selected Date has a holiday </div> <div>Select date does not have a holiday</div>",
          type: 'error',
          // showCancelButton: true,
          confirmButtonColor: '#26a69a',
          // cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      } else {
        Swal.fire({
          title: 'Submit request?',
          html:
            "<br> Date: " +
            date +
            "<br> <b>Start time:</b> " +
            StartTime +
            "<br> <b>End time:</b>  " +
            EndTime +
            "<br> Total time: " +
            totalHours,
          footer: 'Filing Date:  ' + dateFormat,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#26a69a',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Submit'
        }).then((result) => {
          if (result.value) {
            // this.props.dispatch(userOvertimeRequest.requestService(overtime, isUndertime));
            this.props.dispatch(essActions.create(overtime))
          }
        })
      }
    }
  }

  validateWhiteSpaces(e) {
    if (/^\s/.test(e.target.value)) { // Not accepting Whitespaces in first input. 
      e.target.value = '';
    }
  }
  handleDateChangeRaw(e) {
    e.preventDefault();
  }

  render() {
    const { request, overtime, submitted, isUndertime } = this.state
    var dateNow = Date(Date.now()).toLocaleString();
    var dateFormat = moment(dateNow).format("dddd, MMMM DD, YYYY");

   const inputIcon = this.getIcon(clock, iconStyle);

    return (
      <Modal
        isOpen={true}
        onRequestClose={this.close.bind(this)}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        className="RequestModalUser"
        centered
      >
        <div className="RequestOvertimeModal">

          <div className="header-container">
            <div className="title-container">
              <span> {`Create ${isUndertime ? "Undertime" :"Overtime"} Request`} </span>
            </div>
            <div className="dateFiled-container">
              <span> Filing Date: {dateFormat}</span>
            </div>

          </div>

          <div className="content-container">
            <form name="form" onSubmit={this.handleSubmit.bind(this)} autoComplete="off">

              <div className="row">
                {/* Row for Request title */}
                <div className="col s12">
                  <label className="label-title" htmlFor="">
                    Request Title
                  </label>

                  <div className="date-time-border">
                    <input type="text" className="form-control requestTitle"
                      id="requestTitle" onInput={this.validateWhiteSpaces.bind(this)}
                      onChange={this.getTitle.bind(this)} />
                  </div>
                  {submitted && !this.state.overtime.subject &&
                    <div className="help-block red-text" data-error="wrong" data-success="right">Request title is required</div>
                  }
                </div>
              </div>


              {/* Row for Description */}
              <div className="row ">
                <div className="col s12">
                  <div className="center-textarea">
                    <label className="label-title">Description:</label>
                      <div className="input-custom">
                        <textarea style={{ border: "none", padding: "10px", resize: "vertical" }} placeholder="Description"   className="form-control requestDescription"
                          id="requestDescription" onInput={this.validateWhiteSpaces.bind(this)}
                          onChange={this.getDescription.bind(this)} />
                      </div>
                  </div>

                  {submitted && !this.state.overtime.description &&
                    <div className="help-block red-text" data-error="wrong" data-success="right">Description is required</div>
                  }
                </div>
              </div>

              {/* row for hour and minutes*/}
              <div className="row">
                <div className="col s12 m6 l6">
                  <label className="label-title" htmlFor="">
                    Start Time:
                  </label>
                  <div className="date-time-border">
                    {/* <input className="sqUserClock startedAtValue"
                      value={this.state.startOfTime} type="time"
                      name="startedAt" id="startedAt" placeholder="00 : 00 : 00"
                      onChange={this.getStartTime.bind(this)} /> */}
				    <StyledTimePicker
						showSecond={false}
						defaultValue={this.state.startOfTime}
						className="startedAtValue"
						onChange={this.getStartTime.bind(this)}
						format={format}
						use12Hours
						inputReadOnly
						placeholder="00 : 00 : 00"
						name="startedAt" 
						id="startedAt"
						inputIcon={inputIcon}
					/>,

                  </div>

                  {submitted && !this.state.startOfTime &&
                    <div className="help-block red-text" data-error="wrong" data-success="right">Start time is required</div>
                  }
                </div>

                <div className="col s12 m6 l6">
                  <label className="label-title" htmlFor="">
                    End Time:
                  </label>
                  <div className="date-time-border">
                    {/* <input className="sqUserClock endedAtValue" value={this.state.endOfTime}
                      type="time" name="endedAt" id="endedAt" placeholder="00 : 00 : 00"
                      onChange={this.getEndTime.bind(this)} /> */}
                    <StyledTimePicker
						showSecond={false}
						defaultValue={this.state.endOfTime}
						className="endedAtValue"
						onChange={this.getEndTime.bind(this)}
						format={format}
						use12Hours
						inputReadOnly
						placeholder="00 : 00 : 00"
						name="endedAt" 
						id="endedAt"
						inputIcon={inputIcon}
					/>,
                  </div>
                  {submitted && !this.state.endOfTime &&
                    <div className="help-block red-text" data-error="wrong" data-success="right">End time is required</div>
                  }
                </div>
              </div>

              {/* row Date and Total time*/}
              <div className="row">
                <div className="col s12 m6 l6">
                  <label className="label-title" htmlFor="">
                    Date:
                  </label>
                  <div className="date-time-border">
                    <DatePicker
                      className="sqUserCalendar dateValue"
                      name="date"
                      id="startedAt"
                      placeholderText="Choose date"
                      dateFormat="ddd, MMM DD, YYYY"
                      selected={this.state.date ? new Date(this.state.date) : new Date()}

                      value={this.state.date ? moment(this.state.date).format('ddd, MMM DD, YYYY') : ''}
                      onChange={(date) => this.getDate(date)}
                      onChangeRaw={this.handleDateChangeRaw} // Prevent raw input
                      excludeDates={this.disableHoliday()}

                      showMonthDropdown
                      showYearDropdown
                    />
                  </div>
                  {submitted && !this.state.date &&
                    <div className="help-block red-text" data-error="wrong" data-success="right">Date is required</div>
                  }
                </div>

                <div className="col s12 m6 l6">
                  <label className="label-title" htmlFor="">
                    Total Time:
                  </label>
                  <div className="date-time-border">
                    <input type="text" className="form-control totalvalue"
                      name="hours" id="totalVal" value={this.getTimeRange()} disabled />
                  </div>
                </div>
              </div>

              <div className="row">
                  <div className="col s12">
                      <label className="label-title" htmlFor="">
                          Attach File 
                      </label>
                      <Dropzone item={items => {
                          this.setState({overtime: {
                              ...overtime,
                              files: items,
                          }})
                      }}/>
                  </div>
              </div>

              {/* Row for Place holder */}
              <div className="row">
                <div className="col s12">
                  <PolicyList type={isUndertime ? "undertime" : "overtime"} index={0} />
                </div>
              </div>




              {/* Submit */}
              <div className="footer-container">
                <div>
                  <button type="submit" className="waves-effect waves-light btn sroUserSubmitBtn" id="submit">Submit</button>
                  <button className="waves-effect waves-light btn sroUserCancelBtn" id="cancel" onClick={this.close.bind(this)}>Cancel</button>
                </div>
              </div>

            </form>

            {/* Row for Place holder */}
            <div className="row">
              <div className="col s12">
                <label className="label-title" htmlFor="">
                  Details:
                </label>
                <div className="policy">
                  <p>Here is where content for simple reminders of policy rules</p>
                  <br />
                  <p>1. Request Title</p>
                  <p>2. Description</p>
                  <p>3. Starting Time</p>
                  <p>4. End Time:</p>
                  <p>5. Date: </p>
                  <p>6. Total Time:</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Modal>
    )
  }
}


function mapStateToProps(state) {
  const { userOvertime } = state;
  const { alert } = state;
  return {
    userOvertime,
    alert,
  };
}

export default React.memo(connect(mapStateToProps)(RequestModalOvertime));

