import React from 'react';
import M from 'materialize-css/dist/js/materialize.min.js'

import './RequestList.sass'

class RequestList extends React.Component {
    
    componentDidMount(){
        let el = document.querySelector('.tabs');
        M.Tabs.init(el, {});
    }
  
    render() {
    return (
        <div className="RequestListsCon">
             <div className="container-fluid">
                    <div className="col m2 l2 s12" id="allTabs">
                        <ul className="tabs">
                            <li className="tab active"><a className="active" href="#test1">Request Time Off</a></li>
                            <li className="tab"><a href="#test2">Request Overtime</a></li>
                            <li className="tab"><a href="#test3">Submit HR Concern</a></li>
                            <li className="tab"><a href="#test4">Submit Support Tickets</a></li>
                            <li className="tab"><a href="#test5">Request For COE</a></li>
                            
                        </ul>
                    </div>
                   
                </div>
        </div>
    );
  }
}

export default RequestList;