export const permissionConstants = {
    
    GETALL_REQUEST: 'PERMISSIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PERMISSIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PERMISSIONS_GETALL_FAILURE',

    GETPAGE_REQUEST: 'PERMISSIONS_GETPAGE_REQUEST',
    GETPAGE_SUCCESS: 'PERMISSIONS_GETPAGE_SUCCESS',
    GETPAGE_FAILURE: 'PERMISSIONS_GETPAGE_FAILURE',

    GET_REQUEST: 'PERMISSIONS_GET_REQUEST',
    GET_SUCCESS: 'PERMISSIONS_GET_SUCCESS',
    GET_FAILURE: 'PERMISSIONS_GET_FAILURE',

    POST_PERMISSION_REQUEST: 'PERMISSIONS_POST_REQUEST',
    POST_PERMISSION_SUCCESS: 'PERMISSIONS_POST_SUCCESS',
    POST_PERMISSION_FAILURE: 'PERMISSIONS_POST_FAILURE',

    UPDATE_PERMISSION_REQUEST: 'PERMISSION_UPDATE_REQUEST',
    UPDATE_PERMISSION_SUCCESS: 'PERMISSION_UPDATE_SUCCESS',
    UPDATE_PERMISSION_FAILURE: 'PERMISSION_UPDATE_FAILURE',
    
    DELETE_PERMISSION_REQUEST: 'PERMISSIONS_DELETE_REQUEST',
    DELETE_PERMISSION_SUCCESS: 'PERMISSIONS_DELETE_SUCCESS',
    DELETE_PERMISSION_FAILURE: 'PERMISSIONS_DELETE_FAILURE',
};