import { get, post, put, del } from "../helpers/api";
import { userService } from "../services/user";

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

const basePath = "/api/v1" ;

const getAll = (page = 1, items = 10) => (
    get(`${basePath}/questionnaire?page=${page}&items-per-page=${items}`).then(handleResponse)
);

const create = (questionnaire) => (
    post(`${basePath}/questionnaire`, questionnaire).then(handleResponse)
);

const update = (questionnaire, id) => (
    put(`${basePath}/questionnaire/${id}`, questionnaire).then(handleResponse)
);

const remove = (id) => (
    del(`${basePath}/questionnaire/${id}`).then(handleResponse)
);

const getById = (id) => (
    get(`${basePath}/questionnaire/${id}`).then(handleResponse)
);

const getAllQuestions = (id, page = 1, items = 10) => (
    get(`${basePath}/questionnaire/${id}/question?page=${page}&items-per-page=${items}`).then(handleResponse)
);

export default {
    getAll,
    create,
    update,
    remove,
    getById,
    getAllQuestions,
}
