import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../_core/actions/user";
import { alertActions } from "../_core/actions/alert";
import Swal from "sweetalert2";
import "./step1.sass";
import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import "./upload.sass";
const acceptedImgFile = "image/x-png, image/png, image/jpg, image/jpeg";

class Step1 extends Component {
  constructor(props) {
    super(props);

    //for upload image input ref
    this.fileInputRef = React.createRef();
    this.fileCoverRef = React.createRef();

    this.edit = typeof props.getStore().manager !== "undefined" ? true : false;
    this.state = {
      user: {
        _id: props.getStore()._id,
        //avatar: props.getStore().avatar,
        bio: props.getStore().bio,
        //cover: props.getStore().cover,
        firstName: props.getStore().firstName,
        middleName: props.getStore().middleName,
        lastName: props.getStore().lastName,
        title: props.getStore().title,
        suffix: props.getStore().suffix,
        address1: props.getStore().address1,
        address2: props.getStore().address2,
        city: props.getStore().city,
        state: props.getStore().state,
        country: props.getStore().country,
        nationality: props.getStore().nationality,
        mothersMaidenName: props.getStore().mothersMaidenName,
        maritalStatus: props.getStore().maritalStatus,
        gender: props.getStore().gender,
        birthDate: props.getStore().birthDate,
        birthPlace: props.getStore().birthPlace,
        email: props.getStore().email,
        team: props.getStore().team,
        organization: props.getStore().organization,
        manager: this.edit ? props.getStore().manager : null,
        position: props.getStore().position,
        department: props.getStore().department,
        startDate: props.getStore().startDate,
        endDate: props.getStore().endDate,
        regularizationDate: props.getStore().regularizationDate,
        level: props.getStore().level,
        officeId: props.getStore().officeId
      },
      // avatar: props.getStore().avatar,
      // cover: props.getStore().cover,
      avatarSrc: null,
      coverSrc: null,
      src: null,
      avatarUpload: null,
      coverUpload: null,
      crop: {
        unit: "%",
        width: 50,
        height: 50,
        aspect: 2 / 2,
        x: 25,
        y: 25
      },
      cropCover: {
        unit: "%",
        width: 50,
        height: 50,
        aspect: 16 / 9,
        x: 25,
        y: 25
      },
      render: false
    };
    this.handleChange = this.handleChange.bind(this);
    // this.handleUploadAvatar = this.handleUploadAvatar.bind(this);
    // this.handleUploadCover = this.handleUploadCover.bind(this);
    this.alertTimeOut = this.alertTimeOut.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  componentDidMount() {
    //upload image
    document.getElementById("uploadAvatar").style.display = "none";
    document.getElementById("uploadCover").style.display = "none";
  }
  componentWillUnmount() {
    this.alertTimeOut();
  }
  alertTimeOut = () =>
    setTimeout(
      function() {
        this.props.dispatch(alertActions.clear());
      }.bind(this),
      4000
    );
  jumpToStep = toStep => {
    // We can explicitly move to a step (we -1 as its a zero based index)
    const { user } = this.state;
    this.props.updateStore(user);
    this.props.jumpToStep(toStep - 1); // The StepZilla library injects this jumpToStep utility into each component
  };

  //------------------------------------------ UPLOAD EXIT --------------------------------------------------->
  exitUpload = () => {
    this.setState({ src: null, croppedImageUrl: null });
    this.fileInputRef.current.value = null;
    document.getElementById("uploadAvatar").style.display = "none";
    document.getElementById("userProfileUploadBio").style.display = "block";
  };

  exitUploadCover = () => {
    this.setState({ srcCover: null, croppedCoverUrl: null });
    this.fileCoverRef.current.value = null;
    document.getElementById("uploadCover").style.display = "none";
    document.getElementById("userProfileUploadBio").style.display = "block";
  };

  //------------------------------------------ UPLOAD AVATAR --------------------------------------------------->
  getAvatar = () => {
    document.getElementById("uploadAvatar").style.display = "block";
    document.getElementById("userProfileUploadBio").style.display = "none";
  };

  uploadAvatar = () => {
    const { crop, src } = this.state;
    return (
      <div>
        <div className="user-upload-container"></div>
        <div className="user-upload-max-width">
          <div className="user-upload-img-container">
            <div className="user-update-avatar-title">
              <span className="user-title">Update Avatar</span>
            </div>
            <div className="user-image-display">
              <div className="user-image-crop-display">
                <ReactCrop
                  src={src}
                  crop={crop}
                  minWidth={50}
                  minHeight={50}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              </div>
              <div className="user-input-crop-border">
                <label className="user-input-image">
                  <p className="user-upload-pic">
                    Upload Avatar<br></br>
                    <input
                      className="user-upload-pic"
                      ref={this.fileInputRef}
                      type="file"
                      accept={acceptedImgFile}
                      multiple={false}
                      onChange={this.onSelectFile}
                      onDrag={this.onSelectFile}
                    />
                  </p>
                </label>
              </div>
            </div>

            <div className="user-upload-avatar-save">
              {src && (
                <button
                  className="btn user-save-avatar"
                  onClick={this.saveAvatar}
                >
                  Save Image
                </button>
              )}
              <span>
                <button
                  className="btn user-cancel-avatar"
                  onClick={this.exitUpload}
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const bytes = e.target.files[0].size;
      const mb = 2;
      var imageSizeMB = bytes / Math.pow(1024, 2);

      if (imageSizeMB > mb) {
        Swal.fire({
          title: "File too large",
          html:
            "<bold><p>Can't upload image larger than " + mb + " mb</p></bold>",
          type: "warning",
          showCancelButton: false,
          confirmButtonColor: "#d33",
          confirmButtonText: "Ok",
          customClass: {
            popup: "format-pre"
          }
        }).then(result => {
          this.setState({
            srcAvatar: null,
            croppedImageUrl: null
          });
          this.fileInputRef.current.value = null;
        });
      } else {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };
  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };
  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };
  onCropChange = crop => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    var filname = new Date().getTime() + ".jpeg";
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        filname
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    ctx.canvas.width = Math.ceil(crop.width * scaleX);
    ctx.canvas.height = Math.ceil(crop.height * scaleY);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      Math.ceil(crop.width * scaleX),
      Math.ceil(crop.height * scaleY)
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            Swal.fire({
              title: "Error",
              html: "<bold><p>No image detected</bold>",
              type: "warning",
              showCancelButton: false,
              confirmButtonColor: "#d33",
              confirmButtonText: "Ok",
              customClass: {
                popup: "format-pre"
              }
            });
            return;
          }

          blob.name = fileName;
          var file = new File([blob], blob.name, {
            type: blob.type,
            lastModified: new Date()
          });
          this.setState({ avatarSrc: file });

          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  }

  saveAvatar = event => {
    event.preventDefault();
    let avatar = this.state.avatarSrc;
    const data = new FormData();
    if (avatar) {
      const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        background:
          "linear-gradient(rgba(255,192,203 ,1 ), rgba(191, 85, 236, 1) )",
        fontColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 1500
      });

      Swal.fire({
        title:
          '<small style="font-size: 16px">Are you sure you want to update your avatar?</small>',
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#24DC98",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          popup: "format-pre"
        }
      }).then(result => {
        if (result.value) {
          Toast.fire({
            type: "success",
            title: '<span style="color:#FFFFFF">Saving your avatar...!</span>'
          }).then(res => {
            data.append("file", avatar, avatar.name);
            this.setState({ render: true, src: null, avatarUpload: data });
            localStorage.setItem("userid", JSON.stringify(this.state.user._id));
            this.props.dispatch(userActions.uploadAvatar(data, this.state.user._id));
            this.fileInputRef.current.value = null;
            document.getElementById("uploadAvatar").style.display = "none";
            document.getElementById("userProfileUploadBio").style.display =
              "block";
          });
        }
      });
    } else {
      Swal.fire({
        title: "No image saved",
        html: "<bold><p>Please crop the image</bold>",
        type: "warning",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Ok",
        customClass: {
          popup: "format-pre"
        }
      });
      return;
    }
  };
  //------------------------------------------ END UPLOAD AVATAR --------------------------------------------------->
  //------------------------------------------ COVER AVATAR --------------------------------------------------->
  getCover = () => {
    document.getElementById("uploadCover").style.display = "block";
    document.getElementById("userProfileUploadBio").style.display = "none";
  };

  uploadCover = () => {
    const { cropCover, srcCover } = this.state;
    return (
      <div>
        <div className="user-upload-container"></div>
        <div className="user-upload-max-width">
          <div className="user-upload-img-container">
            <div className="user-update-avatar-title">
              <span className="user-title">Update Cover</span>
            </div>
            <div className="user-image-display">
              <div className="user-image-crop-display">
                <ReactCrop
                  src={srcCover}
                  crop={cropCover}
                  minWidth={50}
                  minHeight={50}
                  onImageLoaded={this.onCoverLoaded}
                  onComplete={this.onCropCoverComplete}
                  onChange={this.onCropCoverChange}
                />
              </div>
              <div className="user-input-crop-border">
                <label className="user-input-image">
                  <p className="user-upload-pic">
                    Upload Cover<br></br>
                    <input
                      className="user-upload-pic"
                      ref={this.fileCoverRef}
                      type="file"
                      accept={acceptedImgFile}
                      multiple={false}
                      onChange={this.onSelectCover}
                      onDrag={this.onSelectCover}
                    />
                  </p>
                </label>
              </div>
            </div>
            <div className="user-upload-avatar-save">
              {srcCover && (
                <button
                  className="btn user-save-avatar"
                  onClick={this.saveCover}
                >
                  Save Image
                </button>
              )}
              <span>
                <button
                  className="btn user-cancel-avatar"
                  onClick={this.exitUploadCover}
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onSelectCover = e => {
    if (e.target.files && e.target.files.length > 0) {
      const bytes = e.target.files[0].size;
      const mb = 2;
      var imageSizeMB = bytes / Math.pow(1024, 2);

      if (imageSizeMB > mb) {
        Swal.fire({
          title: "File too large",
          html:
            "<bold><p>Can't upload image larger than " + mb + " mb</p></bold>",
          type: "warning",
          showCancelButton: false,
          confirmButtonColor: "#d33",
          confirmButtonText: "Ok",
          customClass: {
            popup: "format-pre"
          }
        }).then(result => {
          this.setState({
            srcCover: null,
            croppedCoverUrl: null
          });
          this.fileCoverRef.current.value = null;
        });
      } else {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          this.setState({ srcCover: reader.result })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };
  // If you setState the crop in here you should return false.
  onCoverLoaded = cover => {
    this.coverRef = cover;
  };
  onCropCoverComplete = crop => {
    this.makeCoverCrop(crop);
  };
  onCropCoverChange = cropCover => {
    this.setState({ cropCover });
  };

  async makeCoverCrop(crop) {
    var filname = new Date().getTime() + ".jpeg";
    if (this.coverRef && crop.width && crop.height) {
      const croppedCoverUrl = await this.getCroppedCover(
        this.coverRef,
        crop,
        filname
      );
      this.setState({ croppedCoverUrl });
    }
  }

  getCroppedCover(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    ctx.canvas.width = Math.ceil(crop.width * scaleX);
    ctx.canvas.height = Math.ceil(crop.height * scaleY);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
	  Math.ceil(crop.width * scaleX),
      Math.ceil(crop.height * scaleY)
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            Swal.fire({
              title: "Error",
              html: "<bold><p>No image detected</bold>",
              type: "warning",
              showCancelButton: false,
              confirmButtonColor: "#d33",
              confirmButtonText: "Ok",
              customClass: {
                popup: "format-pre"
              }
            });
            return;
          }

          blob.name = fileName;
          var file = new File([blob], blob.name, {
            type: blob.type,
            lastModified: new Date()
          });
          this.setState({ coverSrc: file });

          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        "image/jpeg", 1);
    });
  }

  saveCover = event => {
    event.preventDefault();
    let cover = this.state.coverSrc;
    const data = new FormData();
    if (cover) {
      const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        background:
          "linear-gradient(rgba(255,192,203 ,1 ), rgba(191, 85, 236, 1) )",
        fontColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 1500
      });

      Swal.fire({
        title:
          '<small style="font-size: 16px">Are you sure you want to update your cover photo?</small>',
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#24DC98",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          popup: "format-pre"
        }
      }).then(result => {
        if (result.value) {
          Toast.fire({
            type: "success",
            title:
              '<span style="color:#FFFFFF">Saving your cover photo...!</span>'
          }).then(res => {
            data.append("file", cover, cover.name);
            this.setState({ render: true, src: null, coverUpload: data });
            localStorage.setItem("userid", JSON.stringify(this.state.user._id));
            this.props.dispatch(userActions.uploadCover(data, this.state.user._id));
            this.fileCoverRef.current.value = null;
            document.getElementById("uploadCover").style.display = "none";
            document.getElementById("userProfileUploadBio").style.display =
              "block";
          });
        }
      });
    } else {
      Swal.fire({
        title: "No image detected",
        html: "<bold><p>Please crop the image</bold>",
        type: "warning",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Ok",
        customClass: {
          popup: "format-pre"
        }
      });
      return;
    }
  };
  //------------------------------------------ END COVER AVATAR --------------------------------------------------->

  render() {
    const { user } = this.state;
    const { croppedCoverUrl, croppedImageUrl } = this.state;
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const avatar = currentUser.avatar;
    const cover = currentUser.cover;
    return (
      <div className="step step1 step1Con">
        <div className="col s12 m12 l12">
          <div className="">
            <div className="card-header">
              <p className="left step1Txt"> User Profile </p>
            </div>
            {/* UPLOADING IMAGE */}
            <div className="clearfix step1Cons" id="uploadAvatar">
              {this.uploadAvatar()}
            </div>
            <div className="clearfix step1Cons" id="uploadCover">
              {this.uploadCover()}
            </div>
            {/* END UPLOADING IMAGE */}
            <div className="clearfix step1Cons" id="userProfileUploadBio">
              <div className="row">
                <div className="col s12 m6 l4">
                  <div className="step1Con1">
                    <p className="left step1Txt1">Upload Profile Picture</p>
                    <div className="page1Blue-bg clearfix">
                      <div className="page1Photo-border">
                        <div>
                          {croppedImageUrl ? (
                            <img
                              alt=""
                              style={{ width: "100%" }}
                              src={croppedImageUrl}
                            />
                          ) : (
                            <img
                              alt=""
                              style={{ width: "100%" }}
                              src={avatar}
                            />
                          )}
                        </div>
                        <label
                          className="upload-avatar"
                          onClick={this.getAvatar}
                        >
                          <p className="upload-new">Update avatar</p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col s12 m6 l8">
                  <div className="step1Con2">
                    <p className="left step1Txt1">Cover Photo</p>
                    <div className="page1Blue-bg clearfix">
                      <div className="page1Cover-border">
                        <div>
                          {croppedCoverUrl ? (
                            <img
                              alt=""
                              style={{ width: "100%" }}
                              src={croppedCoverUrl}
                            />
                          ) : (
                            <img alt="" src={cover} className="img-cover-photo"/>
                          )}
                        </div>
                        <label
                          className="upload-avatar"
                          onClick={this.getCover}
                        >
                          <p className="upload-new">Update cover</p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col s12">
                  <p className="step1Txt2 left">Bio</p>

                  <div className="col s12">
                    <textarea
                      name="bio"
                      className="step2Textarea"
                      data-length="120"
                      placeholder="Add some details"
                      onChange={this.handleChange}
                      value={user.bio}
                    ></textarea>
                  </div>

                  <div className="button-container-three clearfix">
                    <button
                      className="btn page1Btn-next"
                      onClick={() => this.jumpToStep(2)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="right">
            <p className="page1PageNum">Step 1 of 4</p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registration, alert } = state;
  const { updating } = registration;

  return {
    updating,
    alert
  };
}

export default connect(mapStateToProps)(Step1);
