import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { overtimeActions } from '../_core/actions/overtime';
import MainNav from '../Components/MainNav';
import HeaderPhoto from '../Components/HeaderPhoto';
import DetailPage from '../Components/DetailPage';
import RequestList from '../Components/RequestList';
import StatusPage from '../Components/StatusPage';
import UserTab from '../Components/UserTab';
//import ReactTooltip from 'react-tooltip'

import Swal from 'sweetalert2'
// import BodyBackgroundColor from 'react-body-backgroundcolor'
import moment from 'moment';
import DatePicker from "react-datepicker";
// import M from 'materialize-css/dist/js/materialize.min.js'
//import MaterialIcon from 'material-icons-react';
// import { TimePicker } from 'react-materialize';

import '../_core/styles/alert.sass'
import './SendRequestOvertimePage.sass';
// import Img from './profile.png';

class SendRequestOvertime extends React.Component {

    constructor(props) {
        super(props);
        //alert Timeout
        this.state = {
            overtime: {
                subject: "Overtime Request",
                description: "",
                type: "overtime",
                startedAt: "",
                endedAt: "",
            },
            startOfTime: '',
            endOfTime: '',
            date: '',

            render: true,
            submitted: false,
        }
        this.getStartTime = this.getStartTime.bind(this);
        this.getEndTime = this.getEndTime.bind(this);
        this.getDate = this.getDate.bind(this);
        this.getTimeRange = this.getTimeRange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        
    }

    getStartTime(e){
        this.setState({
            startOfTime: e.target.value 
        }, () => {
            this.getTimes()
        });
    }

    getEndTime(e){
        this.setState({
            endOfTime:e.target.value  
        }, () => {
            this.getTimes()
        });
    }

    getDate(date){
        const setDate = date;
        this.setState({
            date: moment(setDate).format()
        },()=>{
            this.getTimes()
        })
    }

    getTimes(){
        const overtime = this.state.overtime;
        const date = this.state.date;
        const startTime = this.state.startOfTime;
        const endTime = this.state.endOfTime;

        if(date && startTime && endTime){
            var start = moment(date).format('YYYY-MM-DD') + "T" + startTime + ":00." + moment().format('SSS[Z]');
            var end = moment(date).format('YYYY-MM-DD') + "T" + endTime + ":00." + moment().format('SSS[Z]');

            this.setState({
                overtime:{
                    ...overtime,
                    startedAt: start,
                    endedAt: end,
                }
            },()=>{
                // console.log(this.state.overtime)
            })
        }else{

        }
    }

    getReason=(e)=>{
        const overtime = this.state.overtime;
        this.setState({
            overtime:{
                ...overtime,
                description: e.target.value, 
            }
        }, () => {
            console.log(this.state.overtime.description)
        });
    }

    getTimeRange(){
        const date = this.state.date;
        var startTime = moment(this.state.startOfTime, "HH:mm A");
        var endTime = moment(this.state.endOfTime, "HH:mm A");
        var totalTime = '';

        if(date && startTime && endTime){
            var duration = moment.duration(moment(endTime).diff(startTime)); // Get difference StartTime and EndTime
            var hours = parseInt(duration.asHours()); // Hours Convert
            var minutes = parseInt(duration.asMinutes()) % 60;  // Minutes Convert
            
            var hr = '';
            var min = '';

            if(hours < 0 || hours === 1){
                hr = hours + " hour"
            }else if(hours === 0){
                hr = ''
            }else if(hours > 1){
                hr = hours + " hours"
            }

            if(minutes < 0 || minutes === 1){
                min = minutes + " minute"
            }else if(minutes === 0){
                min = ''
            }else if(minutes > 1){
                min = minutes + " minutes"
            }
            
            totalTime = hr + " " + min;
            
            return totalTime
        }else{
            return
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const { overtime } = this.state;
        // var totalValueStatus = sessionStorage.getItem("totalValueStatus");

        var StartTime = document.querySelector(".startedAtValue").value;
        var EndTime = document.querySelector(".endedAtValue").value;

        var totalHours = document.querySelector(".totalvalue").value;
        var date = document.querySelector(".dateValue").value;
        // var desc = document.querySelector(".descriptionValue").value;

        var dateNow = Date(Date.now()).toLocaleString();
        var dateFormat = moment(dateNow).format("ddd, MMMM DD, YYYY");

        this.setState({ submitted: true })
        // custom toast mixin for sweetAlert
        
        /*eslint-disable */
        if (overtime.startedAt && overtime.endedAt && overtime.description) {
            if(totalHours.includes("-")){
                Swal.fire({
                    title: 'Negative time range computed!',
                    text: 'Check your inputted start and end time',
                    type: 'error',
                    showCancelButton: true,
                    showConfirmButton: false,
                    // confirmButtonColor: '#26a69a',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Ok'
                })
            }else if(this.state.startOfTime === this.state.endOfTime){
                Swal.fire({
                    title: 'No time range computed',
                    text: 'Check your inputted start and end time',
                    type: 'error',
                    showCancelButton: true,
                    showConfirmButton: false,
                    // confirmButtonColor: '#26a69a',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Ok'
                })
            }else{
                Swal.fire({
                    title: 'Submit request?',
                    html:
                        "<br> Date: " +
                        date +
                        "<br> <b>Start time:</b> " +
                        StartTime +
                        "<br> <b>End time:</b>  " +
                        EndTime +
                        "<br> Total time: " +
                        totalHours,
                    footer: 'Filing Date:  ' + dateFormat,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#26a69a',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Submit'
                }).then((result) => {
                    if (result.value) {
                        this.props.dispatch(overtimeActions.addOvertimeRequest(overtime));
                    }
                })
            }
        }
    }

    // handleChange(event) {
    //     event.preventDefault();
    // }


    render() {
        // let user = JSON.parse(localStorage.getItem('user'));
        var tempDate = new Date();
        var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        // const { addOvertimeRequest } = this.props
        const { submitted } = this.state;

        return (
            <div className="profile">
                <MainNav />
               
                <HeaderPhoto />

                <UserTab />

                <div className="container-userdetail">

                    <div className="col s12 m4">
                        <div className="row">
                            <div className="col s12 m3">
                                <DetailPage />
                            </div>
                            <div className="col s12 m9 l6">
                                <div className="BodyCon">
                                    <div className="userDetailCon">
                                        <div className="row">

                                            <RequestList />
                                            <div className="col s9 m9 l9 userDetailConLineLeft">
                                                
                                                <div className="send-request-overtime">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col s12 m6 l6">
                                                                <p className="sroText"><b>Request for Overtime</b></p>
                                                            </div>
                                                            <div className="col s12 m6 l6 right-align">
                                                                <p className="sroTextDate"><b>Filing Date: {moment(date).format('dddd, DD MMMM YYYY')}</b></p>
                                                            </div>
                                                        </div>
                                                        <div className="center">
                                                            {/* <div className="error">Time not accepted..</div> */}
                                                            {/* {addOvertimeRequest &&
                                                                <div className="success"><em>Creating leave Request ....</em></div>
                                                            } */}
                                                        </div>
                                                        <div className="">
                                                            <form name="form" onSubmit={this.handleSubmit} autoComplete="off">
                                                                <div className="row">
                                                                    <div className="col s12 m6 l6 input-custom">
                                                                        <label>Start Time:</label>
                                                                        <div className="date-time-border">
                                                                        <input className="sqUserClock startedAtValue" value={this.state.startOfTime} type="time" name="startedAt" id="startedAt" placeholder="00 : 00 : 00" onChange={this.getStartTime}/>
                                                                        {/* <input type="text" className="timepicker startedAtValue sqUserClock" name="startedAt" id="startedAt" placeholder="00 : 00 : 00" onBlur={this.onBlurInput} required="required" /> */}
                                                                        </div>
                                                                        {submitted && !this.state.startOfTime &&
                                                                            <div className="help-block red-text" data-error="wrong" data-success="right">Start time is required</div>
                                                                        }
                                                                    </div>
                                                                    <div className="col s12 m6 l6 input-custom" >
                                                                        <label>End Time:</label>
                                                                        <div className="date-time-border">
                                                                            <input className="sqUserClock endedAtValue" value={this.state.endOfTime} type="time" name="endedAt" id="endedAt" placeholder="00 : 00 : 00" onChange={this.getEndTime} />
                                                                            {/* <input type="text" className="timepicker endedAtValue sqUserClock" name="endedAt" id="startedAt" placeholder="00 : 00 : 00" required="required" onBlur={this.onBlurInput} /> */}
                                                                        </div>
                                                                        {submitted && !this.state.endOfTime &&
                                                                            <div className="help-block red-text" data-error="wrong" data-success="right">End time is required</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col s12 m6 l6 input-custom">
                                                                        <label>Date:</label>
                                                                        <div className="date-time-border">
                                                                        <DatePicker
                                                                            selected={this.state.date ? new Date(this.state.date) : new Date()}
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            value={this.state.date ? moment(this.state.date).format('dddd, MMMM DD, YYYY') : ''}
                                                                            placeholderText="Choose date"
                                                                            onChange={(date)=>this.getDate(date)}
                                                                            className="sqUserCalendar dateValue"
                                                                            name="date"
                                                                            id="startedAt"
                                                                        />
                                                                        </div>
                                                                        {submitted && !this.state.date &&
                                                                            <div className="help-block red-text" data-error="wrong" data-success="right">Date is required</div>
                                                                        }
                                                                        {/* <input type="text" className="datepicker dateValue sqUserCalendar" name="date" id="startedAt" required="required" placeholder="Choose date" onBlur={this.onBlurInput} /> */}
                                                                    </div>
                                                                    
                                                                    <div className="col s12 m6 l6 input-custom">
                                                                        <label>Total Time:</label>
                                                                        <input type="text" className="form-control totalvalue" name="hours" id="totalVal" value={this.getTimeRange()} disabled />
                                                                    </div>
                                                                </div>

                                                                <div className="row sqUserTextArea">
                                                                    <label className="reason-label">Reason:</label>
                                                                    <textarea className="form-control descriptionValue" name="reason" id="reason" placeholder="State your Reason" onChange={this.getReason}/>
                                                                    {submitted && !this.state.overtime.description &&
                                                                    <div className="help-block red-text" data-error="wrong" data-success="right">Reason is required</div>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <button type="submit" className="waves-effect waves-light btn sroUserSubmitBtn" id="submit">Submit</button>
                                                                    <Link to="/user/overtime" className="waves-effect waves-light btn" id="cancel">Cancel</Link>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col s12 m3">
                                <StatusPage />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { overtimes } = state;
    const { alert } = state;
    const { addOvertimeRequest } = state.addOvertimeRequest;
    return {
        addOvertimeRequest,
        overtimes,
        alert,
    };
}


const connectedSendRequestOvertime = connect(mapStateToProps)(SendRequestOvertime);
export { connectedSendRequestOvertime as SendRequestOvertime };