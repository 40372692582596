import { userConstants } from '../constants/user';
import { userService } from '../services/user';
import { alertActions } from './alert';
import Swal from 'sweetalert2'
import moment from 'moment';
import { history } from '../helpers/history';
import { encrypted } from '../helpers/crypto_config';
import { pragmaCore } from '../helpers/config'
var qs = require('qs');
export const userActions = {
    login,
    logout,
    register,
    getAll,
    delete: _delete,
    roleAssign,
    getById,
    getByIdPage,
    edit: update,
    create,
    updateUser,
    deactivate: _deactivate,
    activate: _activate,
    getUser,
    changePass,
    resetPass,
    uploadAvatar,
    uploadCover,
    refreshToken,
    getAllActiveUsers,
    getAllActive,
    editRole,
    tokenPass,
    stopImpersonate,
    getAllSearchFilter
    // getUserDetails,
};

function loginCore(username,password){

    let details = {
        username: username,
        password: password,
        grant_type: 'password',
        scope: 'read write',
        source: 'PragmaHR'
    };

    return fetch('http://identity-dev.pragmacore.prgmnl.com/oauth/token', {
        method: "POST",
        body: qs.stringify(details),
        headers: {"Authorization": "Basic Yjk3YWM0YzktMDI1Yi00NjJmLWJlYTAtZjZlNzMyOTg4ZTk2Ok9HVTBZVEJoWVRJdFl6ZGxaUzAwWkdNd0xUZ3laR1V0TldWaFpERTVObVEzTnpVNA==",
    "Content-Type":'application/x-www-form-urlencoded'}
      })
        .then(response => response.json()) 
        .then((responseData) => {
           //sessionStorage.setItem('coreCredentials', JSON.stringify(responseData));
            localStorage.setItem('coreCredentials', JSON.stringify(responseData));
        })
}

function login(email, password, refreshToken, prevUrl) {
    return dispatch => {
        dispatch(request({ email }));
            userService.login(email, password, refreshToken)
            .then(
                user => {
                    if ((user.user.active === 1) && (typeof user.user.deletedAt === "undefined") && user.user.organization) {
                        dispatch(success(user));
                        const userId =  JSON.stringify(user.user._id)
                        userService.getUserDetails(userId).then(()=>{
                            if(prevUrl !== ''){
                                history.push(prevUrl)
                            }else{
                               
                                if(user.user.pragmacore === 1){
                                    const emailEncrypt = encrypted(email+"."+password)
                                    const coreUrl = pragmaCore.core;
                                    const url = coreUrl+emailEncrypt

                                    loginCore(email,password)
                                    window.setTimeout(function() {
                                        window.location.href = url;
                                    }, 1000);

                                } else {
                                    history.push('/dashboard');
                                }

                            }
                        })
                    } else {
                        dispatch(failure(user.toString()));
                        dispatch(alertActions.error("An Error Occured"));
                        // logout();
                    }
                },
                error => {
                    if (email.split("").filter(x => x === "@").length !== 1) {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error("Email should contain a @"));
                    }
                    else if (email.indexOf(".") === -1) {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error("Email should contain at least one dot"));
                    }
                    else if (email.indexOf(".") >= 1) {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error("Email should contain at least one dot"));
                    }
                    else if (email.split("").filter(x => x === "@").length !== 1 && email.indexOf(".") === -1){
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error("Email should contain a @ and at least one dot"));
                    }
                    else{ 
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error("Email and/or Password is invalid"));
                    }
                }
            );
            };
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}



function refreshToken(refreshToken) {
    return dispatch => {
        dispatch(request(refreshToken));

        userService.refreshToken(refreshToken)
            .then(
                user => {
                    if ((user.user.active === 1) && (typeof user.user.deletedAt === "undefined")) {
                        dispatch(success(user));
                        const userId =  JSON.stringify(user.user._id)
                        userService.getUserDetails(userId).then(()=>{
                            localStorage.removeItem('refresh_token');
                        })
                    } else {
                        dispatch(failure(user.toString()));
                        dispatch(alertActions.error("User inactive"));
                        localStorage.removeItem('refresh_token');
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    localStorage.removeItem('refresh_token');
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    //sessionStorage.removeItem('coreCredentials');
    return { type: userConstants.LOGOUT };
    
}

function stopImpersonate() {
    userService.stopImpersonate();
    return { type: userConstants.IMPERSONATE };
}


function register(user) {
    return dispatch => {
        dispatch(request(user));
        userService.register(user)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/login');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Email has already been taken'));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function create(user) {
    return dispatch => {
        dispatch(request(user));
        const Toast = Swal.mixin({
            toast: true,
            width: 300,
            height: 500,
            background: 'rgba(191, 85, 236, 1)',
            fontColor: "white",
            position: 'center',
            showConfirmButton: false,
            timer: 5000
        });
        userService.register(user)
            .then(
                user => {
                    dispatch(success(user));
                    history.goBack();
                    dispatch(alertActions.success('User successfully created'));
                    Toast.fire({
						type: 'success',
						title: '<span style="color:#FFFFFF">User Successfully Added!</span>'
					});
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong...'));
                    Toast.fire({
						type: 'error',
						title: '<span style="color:#d33">Something went wrong...</span>'
					});
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function update(user) {
    return dispatch => {
        dispatch(request(user));
        const Toast = Swal.mixin({
			toast: true,
			width: 300,
			height: 500,
			background: 'rgba(191, 85, 236, 0.9)',
			fontColor: "white",
			position: 'center',
			showConfirmButton: false,
			timer: 5000
		});
        userService.update(user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success('User Successfully Updated'));
                    history.goBack();
                    Toast.fire({
						type: 'success',
						title: '<span style="color:#FFFFFF">User Successfully Updated!</span>'
                    })
                    // let userDetails = localStorage.getItem('user');
                    // let userParsed = JSON.parse(userDetails);
                    // let userId = userParsed.user_id //JSON.stringify(userParsed.user_id);
                    //    userService.getUserDetails(userId).then(()=>{
                    //         history.goBack();
                    //     })
                  
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Edit user failed'));
                    Toast.fire({
						type: 'error',
						title: '<span style="color:#d33">Edit user failed!</span>'
					});
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function editRole(user) {
    return dispatch => {
        dispatch(request(user));
        const Toast = Swal.mixin({
			toast: true,
			width: 300,
			height: 500,
			background: 'rgba(191, 85, 236, 0.9)',
			fontColor: "white",
			position: 'center',
			showConfirmButton: false,
			timer: 5000
		});
        userService.editRole(user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success('User Role Successfully Updated'));
                    // Toast.fire({
					// 	type: 'success',
					// 	title: '<span style="color:#FFFFFF">User Role Successfully Updated!</span>'
                    // });
                    setTimeout(() => {
                        Toast.fire({
                            type: 'success',
                            title: '<span style="color:#FFFFFF">User Role Successfully Updated!</span>'
                        });
                        dispatch(userActions.getAll())
                    }, 100)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Update user role failed'));
                    Toast.fire({
						type: 'error',
						title: '<span style="color:#d33">Update user role failed!</span>'
					});
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function updateUser(user) {
    return dispatch => {
        dispatch(request(user));

        const Toast = Swal.mixin({
            toast: true,
			width: 300,
			height: 500,
			background: 'rgba(191, 85, 236, 0.9)',
			fontColor: "white",
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        userService.updateUser(user)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/profile');
                    dispatch(alertActions.success('User successfully updated'));
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:green">User Profile has been Updated!</span>'
                    });
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Edit user failed'));
                    Toast.fire({
                        type: 'error',
                        title: '<span style="color:red">Something went wrong</span>'
                    });
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATEPROFILE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATEPROFILE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATEPROFILE_FAILURE, error } }
}

function getAll(page, itemPerPage) {
    return dispatch => {
        dispatch(request());

        userService.getAll(page, itemPerPage)
            .then(
                users => {
                    dispatch(success(users))
                    if(window.location.pathname === '/admin/users'){
                        history.push(`/admin/users?page=${Number(page) || 1}`)
                    }else if(window.location.pathname === '/admin/leave-credits-overview'){
                        history.push('/admin/leave-credits-overview?page=' + Number(page) || 1);
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function getAllActiveUsers(selectPage, itemsPerPage, filter){ 
    return dispatch => {
        dispatch(request(selectPage));
        userService.getAllActiveUsers(selectPage, itemsPerPage, filter)
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALLACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GETALLACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALLACTIVE_FAILURE, error } }
}

function getAllActive(){ 
    return dispatch => {
        dispatch(request());
        userService.getAllActive()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALLACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GETALLACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALLACTIVE_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request(id));

        userService.getById(id)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/admin/user/edit');
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GET_REQUEST } }
    function success(user) { return { type: userConstants.GET_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_FAILURE, error } }
}

function getByIdPage(id, page) {
    return dispatch => {
        dispatch(request(id, page));

        userService.getById(id)
            .then(
                user => {
                    dispatch(success(user));
                    // history.push('/admin/user/edit/'+ page);
                    history.push('/admin/user/edit');
                },
                error => dispatch(failure(error.toString()))
                
            );
    };

    function request() { return { type: userConstants.GET_REQUEST } }
    function success(user) { return { type: userConstants.GET_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_FAILURE, error } }
}

function getUser(id) {
    return dispatch => {
        dispatch(request(id));

        userService.getById(id)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GET_REQUEST } }
    function success(user) { return { type: userConstants.GET_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_FAILURE, error } }
}


function getAllSearchFilter(page, itemPerPage, search) {
    return dispatch => {
        dispatch(request());
        
        userService.getAllSearchFilter(page, itemPerPage, search)
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function roleAssign(user, role) {
    return dispatch => {
        dispatch(request(user, role));

        userService.roleAssign(user, role)
            .then(
                users => {
                    dispatch(success());
                    history.push('/users');
                    dispatch(alertActions.success('Role has been assigned'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };

    function request(user, role) { return { type: userConstants.ASSIGNROLE_REQUEST } }
    function success(user, role) { return { type: userConstants.ASSIGNROLE_SUCCESS } }
    function failure(error) { return { type: userConstants.ASSIGNROLE_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        const Toast = Swal.mixin({
            toast: true,
			width: 300,
			height: 500,
			background: '#D4EDDA',
			fontColor: "#0A551E",
			position: 'center',
			showConfirmButton: false,
			timer: 1500
        });
        const Error = Swal.mixin({
            toast: true,
            width: 300,
            background: '#ff9999',
            position: 'center',
            showConfirmButton: false,
            timer: 1500
        });

        userService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                    dispatch(alertActions.success('User successfully deleted'));
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#0A551E">User Successfully Deleted!</span>'
                    });
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Delete user failed'));
                    Error.fire({
                        type: 'error',
                        title: '<span style="color:#990000">Delete user failed!</span>'
                    });
                }
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

function _deactivate(user) {
    return dispatch => {
        dispatch(request(user));
        const Toast = Swal.mixin({
            toast: true,
			width: 300,
			height: 500,
			background: '#D4EDDA',
			fontColor: "#0A551E",
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        const Error = Swal.mixin({
            toast: true,
            width: 300,
            background: '#ff9999',
            position: 'center',
            showConfirmButton: false,
            timer: 5000
        });
        userService.deactivate(user)
            .then(
                user => {
                    dispatch(success(user.success));
                    dispatch(alertActions.success('User successfully deactivated'));
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#0A551E">User successfully deactivated!</span>'
                    });
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong...'));
                    Error.fire({
                        type: 'error',
                        title: '<span style="color:#990000">User deactivation failed!</span>'
                    });
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function _activate(user) {
    return dispatch => {
        dispatch(request(user));
        const Toast = Swal.mixin({
            toast: true,
			width: 300,
			height: 500,
			background: '#D4EDDA',
			fontColor: "#0A551E",
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        const Error = Swal.mixin({
            toast: true,
            width: 300,
            background: '#ff9999',
            position: 'center',
            showConfirmButton: false,
            timer: 5000
        });
        userService.activate(user)
            .then(
                user => {
                    dispatch(success(user.success));
                    dispatch(alertActions.success('User successfully activated'));
                    Toast.fire({ 
                        type: 'success',
                        title: '<span style="color:#0A551E">User successfully activated!</span>'
                    });
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong...'));
                    Error.fire({
                        type: 'error',
                        title: '<span style="color:#990000">User activation failed!</span>'
                    });
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}


function changePass(user) {
    return dispatch => {
        dispatch(request(user));

        userService.checkLogin(user)
            .then(
                user => {

                    // Alert need to declare here because 
                    // it check the password before the alert show

                    // Custom Toast 
                    const Toast = Swal.mixin({
                        toast: true,
                        width: 300,
                        height: 500,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    var dateNow = Date(Date.now()).toLocaleString();
                    var dateFormat = moment(dateNow).format("ddd, MMMM DD, YYYY");


                    Swal.fire({
                        title: 'Are you sure?',
                        html: "Do you want to change your password?",
                        footer: 'Filing Date:  ' + dateFormat,
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#5CB85C',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes!',
                        cancelButtonText: 'No!'
                    }).then((result) => {
                        if (result.value) {
                            // Toast when password succeed
                            Toast.fire({
                                type: 'success',
                                title: '<span style="color:green">Password has been Changed!</span>'
                            }).then((res) => {
                                // const user = localStorage.getItem('userCredentials')
                                dispatch(success(user));
                                history.push('/profile');
                            })
                        }else{
                            // console.log('cancel');
                        }
                    })


                    //Success Empty string remove duplicate toast and reset the Alert, if other alert is in active.
                    dispatch(alertActions.success(''));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error("Password didn't matched"));
                }

            );
    };

    function request(user) { return { type: userConstants.CHANGEPASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.CHANGEPASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CHANGEPASSWORD_FAILURE, error } }
}

function resetPass(user) {
    return dispatch => {
        dispatch(request(user));

        userService.changePass(user)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/login');
                    dispatch(alertActions.success('Temporary password has been sent to your email'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error("Email not found"));
                }

            );
    };

    function request(user) { return { type: userConstants.RESETPASS_REQUEST, user } }
    function success(user) { return { type: userConstants.RESETPASS_SUCCESS, user } }
    function failure(error) { return { type: userConstants.RESETPASS_FAILURE, error } }
}

function tokenPass(user) {
    return dispatch => {
        dispatch(request(user));

        userService.getToken(user)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error("User not found"));
                }

            );
    };

    function request(user) { return { type: userConstants.RESETPASS_REQUEST, user } }
    function success(user) { return { type: userConstants.RESETPASS_SUCCESS, user } }
    function failure(error) { return { type: userConstants.RESETPASS_FAILURE, error } }
}


function uploadAvatar(avatar, id) {
    return dispatch => {
        dispatch(request(avatar));
        userService.uploadAvatar(avatar, id)
            .then(
                user => {
                    dispatch(success());
                    dispatch(alertActions.success('Avatar successfully uploaded'));
                },
                error => {
                    // console.log('error')
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };

    function request(avatar) { return { type: userConstants.UPLOADAVATAR_REQUEST, avatar } }
    function success(avatar) { return { type: userConstants.UPLOADAVATAR_SUCCESS, avatar } }
    function failure(error) { return { type: userConstants.UPLOADAVATAR_FAILURE, error } }
}

function uploadCover(cover, id) {
    return dispatch => {
        dispatch(request(cover));
        userService.uploadCover(cover, id)
            .then(
                user => {
                    dispatch(success());
                    dispatch(alertActions.success('Cover successfully uploaded'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };

    function request(cover) { return { type: userConstants.UPLOADCOVER_REQUEST, cover } }
    function success(cover) { return { type: userConstants.UPLOADCOVER_SUCCESS, cover } }
    function failure(error) { return { type: userConstants.UPLOADCOVER_FAILURE, error } }
}
