import questionnaireServices from "../services/questionnaire";
import questionnaireConstants from "../constants/questionnaire";
import { history } from "../helpers/history";
import { MessageAfterSubmit } from "../components/Alert";

import question from "../services/question"

function getAll(page, items) {
    return dispatch => {
        dispatch(request(page, items));
        questionnaireServices.getAll(page, items)
            .then(
                questionnaires => {
                    dispatch(success(questionnaires));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(page, items) { return { type: questionnaireConstants.GET_REQUEST, page, items } }
    function success(questionnaires) { return { type: questionnaireConstants.GET_SUCCESS, questionnaires } }
    function failure(error) { return { type: questionnaireConstants.GET_FAILURE, error } }
}

function create(item, questions = null) {
    return dispatch => {
        dispatch(request());
        questionnaireServices.create(item)
            .then(
                async questionnaire => {
                    dispatch(success(questionnaire));
                    MessageAfterSubmit("success", questionnaire.success);

                    if (questions?.length > 0) {
                        let order = 1;
                        questions.map((questionItem, count) => {
                            questionItem.order = order; //add question order
                            order++
                            question
                                .create(questionItem, questionnaire._id)
                                .then((res) => {
                                    if (
                                        res.success !== undefined &&
                                        count === questions.length - 1
                                    ) {
                                        // Get the last question processed then move to questionnaire list
                                        history.push(`/admin/questionnaires?page=1`);
                                    }
                                });
                            return null;
                        });
                        
                    } else {
                        history.push(`/admin/questionnaires?page=1`);// move to questionnaire list
                    }
                },
                error => {
                    MessageAfterSubmit("error", error.toString());
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: questionnaireConstants.CREATE_REQUEST } }
    function success(questionnaire) { return { type: questionnaireConstants.CREATE_SUCCESS, questionnaire } }
    function failure(error) { return { type: questionnaireConstants.CREATE_FAILURE, error } }
}

function update(item, id, questions = null) {
    return dispatch => {
        dispatch(request());
        questionnaireServices.update(item, id)
            .then(
                async questionnaire => {
                    dispatch(success(questionnaire));
                    MessageAfterSubmit("success", questionnaire.success.message);
                    if (questions?.length > 0) {
                        let order = 1;
                        questions.map((questionItem, count) => {
                            if (!questionItem.questionnaire_id) { // add question
                                questionItem.order = order; //update question order
                                order++ ;
                                question
                                    .create(questionItem, questionnaire.success.details['_id'])
                                    .then((res) => {
                                        if (
                                            res.success !== undefined &&
                                            count === questions.length - 1
                                        ) {
                                            // Get the last question processed then move to questionnaire list
                                            history.push(`/admin/questionnaires?page=1`);
                                        }
                                    });
                            } else { //update question
                                questionItem.order = order; //update question order
                                order++ ;
                                question
                                    .update(questionItem, questionnaire.success.details['_id'])
                                    .then((res) => {
                                        if (
                                            res.success !== undefined &&
                                            count === questions.length - 1
                                        ) {
                                            // Get the last question processed then move to questionActions
                                            history.push(`/admin/questionnaires?page=1`);
                                        }
                                    });
                            }
                            return null;
                        });
                        
                    } else {
                        history.push(`/admin/questionnaires?page=1`); // move to questionnaire list
                    }
                },
                error => {
                    MessageAfterSubmit("error", error.toString());
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: questionnaireConstants.UPDATE_REQUEST } }
    function success(questionnaire) { return { type: questionnaireConstants.UPDATE_SUCCESS, questionnaire } }
    function failure(error) { return { type: questionnaireConstants.UPDATE_FAILURE, error } }
}

function removeInList(id, page, itemsPerPage) {
    return dispatch => {
        dispatch(request());
        questionnaireServices.remove(id)
            .then(
                questionnaire => {
                    dispatch(success(questionnaire));
                    dispatch(getAll(page, itemsPerPage));
                    MessageAfterSubmit("success", questionnaire.success);
                },
                error => {
                    MessageAfterSubmit("error", error.toString());
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: questionnaireConstants.DELETE_REQUEST } }
    function success(questionnaire) { return { type: questionnaireConstants.DELETE_SUCCESS, questionnaire } }
    function failure(error) { return { type: questionnaireConstants.DELETE_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request());
        questionnaireServices.getById(id)
            .then(
                questionnaire => {
                    dispatch(success(questionnaire));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: questionnaireConstants.GETBYID_REQUEST } }
    function success(questionnaire) { return { type: questionnaireConstants.GETBYID_SUCCESS, questionnaire } }
    function failure(error) { return { type: questionnaireConstants.GETBYID_FAILURE, error } }
}

function getAllQuestions(id, page = 1, items = 100000) {
    return dispatch => {
        dispatch(request(page, items));
        questionnaireServices.getAllQuestions(id, page, items)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: questionnaireConstants.GET_QUESTION_REQUEST } }
    function success(questions) { return { type: questionnaireConstants.GET_QUESTION_SUCCESS, questions } }
    function failure(error) { return { type: questionnaireConstants.GET_QUESTION_FAILURE, error } }
}

export default {
    getAll,
    create,
    update,
    removeInList,
    getById,
    getAllQuestions,
}