import React from 'react';
import { Link } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";

import { department, position } from '../_core/constants/selections';

import avatar from '../_templates/man.png'
import bgCard from '../_templates/bg-card.png'

import './ProfileWidget.sass';
import { hex } from '../_core/helpers/profile';


export const getPositionDepartment = (user) => (
    [
        position.software.filter(position => user?.position === position.value)?.[0]?.label,
        department.software.filter(department => user?.department === department.value)?.[0]?.label
    ]
    .filter(text => !!text)
    .join(" | ")
);

export const getProfile = () => ({
    user: localStorage.getItem("secretDetails") !== null ? JSON.parse(localStorage.getItem("secretDetails")) : JSON.parse(localStorage.getItem('user')),
    get positionDepartment() {
        return getPositionDepartment(this.user);
    }
});

const ProfileWidget = () => {
    const profile = React.useMemo(getProfile, []);

    return (
        <div className="card z-depth-0 profile-widget">
            <div className="profile-cover-container">
                { profile.user.cover 
                ? <ReactImageFallback
                    fallbackImage={bgCard}
                    height="100"
                    src={profile.user.cover}
                />
                : <div style={{backgroundColor: hex(profile.user.firstName + profile.user.lastName), height:'100px'}}></div>
               }
            </div>
            <div className="profile-picture-container">
                <div />
                { profile.user.avatar
                ? <ReactImageFallback
                    fallbackImage={avatar}
                    src={profile.user.avatar}
                    width="100"
                    height="80"
                    className="wid-avatar circle responsive-img " 
                />
                : <div className="people-initials-avatar" style={{ backgroundColor: hex(profile.user.firstName + profile.user.lastName) }}>{profile.user.firstName.charAt(0).toUpperCase() + profile.user.lastName.charAt(0).toUpperCase()}</div>
               }
                <div className="name">
                    <Link to={`/profile/${profile.user.user_id}`}>
                        <h6>{`${profile.user.firstName} ${profile.user.lastName}`}</h6>
                        {
                            profile.positionDepartment &&
                            <p>{profile.positionDepartment}</p>
                        }
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default ProfileWidget;
