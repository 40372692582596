export const tenuresConstant = {

    GET_ALL_TENURES: 'GET_ALL_TENURES_REQUEST',
    GET_ALL_TENURES_SUCCESS: 'GET_ALL_TENURES_SUCCESS',
    GET_ALL_TENURES_FAILED: 'GET_ALL_TENURES_FAILED',

    POST_TENURE_REQUEST : "POST_TENURE_REQUEST",
    POST_TENURE_REQUEST_SUCCESS : "POST_TENURE_REQUEST_SUCCESS",
    POST_TENURE_REQUEST_FAILED : "POST_TENURE_REQUEST_FAILED",

    EDIT_TENURE_REQUEST : "EDIT_TENURE_REQUEST",
    EDIT_TENURE_REQUEST_SUCCESS : "EDIT_TENURE_REQUEST_SUCCESS",
    EDIT_TENURE_REQUEST_FAILED : "EDIT_TENURE_REQUEST_FAILED",

    DELETE_TENURE_REQUEST : "DELETE_TENURE_REQUEST",
    DELETE_TENURE_REQUEST_SUCCESS : "DELETE_TENURE_REQUEST_SUCCESS",
    DELETE_TENURE_REQUEST_FAILED : "DELETE_TENURE_REQUEST_FAILED",
};
