import React from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect, useDispatch } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import moment from "moment";
import styled from "styled-components";
import { history } from "../../../_core/helpers/history";

import { userService } from "../../../_core/services/user";
import evaluationActions from "../../../_core/actions/evaluation";

import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent, DynamicContentHeaderWrapper, DynamicTableAndPaginationWrapper } from "../../Components/PageWrapper/DynamicWrapper";
import { DynamicTableAction } from "../../Components/tableActions/tableActions";
import { WarningPopup } from '../../Components/AdminModals/WarningPopup';

import avatar from "../../../_templates/man.png";
import emptyIcon from '../../../_templates/empty.png';

import CreateEditEvaluationPage from './CreateEditEvaluationPage';



const AdminEvaluationPage = styled.div`
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4) {
        text-align: center;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: center;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const EvaluationStatus = styled.p`
  font-size: 14px;
  font-weight: 400;

  &.onGoing {
    color: #50A5F1;
    &:before {
      content: "On Going";
    }
  }
  
  &.accepted {
    color: #FFFFFF;
    &:before {
      content: "Accepted";
    }
  }

  &.pending {
    color: #9BA3CA;
    &:before {
      content: "Pending";
    }
  }

  &.finished {
    color: #34C38F;
    &:before {
      content: "Finished";
    }
  }

  &.rejected {
    color: #F46B6B;
    &:before {
      content: "Declined";
    }
  }

  &.expired {
    color: #F46B6B;
    &:before {
      content: "Expired";
    }
  }

  &.completed {
    color: #F1B44C;
    &:before {
      content: "Completed";
    }
  }
`;

const GetTitle = ({ data }) => {

  const [title, setTitle] = React.useState("");

  React.useEffect(() => {
    userService.getUserById(data?.subject).then((res) => {
      if (res?.user?.length > 0) {
        let userDetails = res.user[0].details[0];
        setTitle(`${userDetails?.firstName} ${userDetails.lastName} - ${data?.title}`)
      }
    }).catch((error) => {
      throw error;
    });
  }, [data]);

  return (<>{title}</>)
}

const GetSubjectAvatar = ({ data }) => {
  const [user, setUser] = React.useState("");

  React.useEffect(() => {
    userService.getUserById(data?.subject).then((res) => {
      if (res?.user?.length > 0) {
        let userDetails = res.user[0].details[0];
        setUser(userDetails);
      }
    }).catch((error) => {
      throw error;
    });
  }, [data]);

  return (
    <>
      <img
        src={user?.avatar || avatar}
        onError={(e) => e.target.src = avatar}
        alt="subject"
        data-tip={`${user?.firstName} ${user?.lastName}`}
        data-class="subjectTooltip"
      />
    </>
  )
}

const EvaluationPageList = (props) => {
  const dispatch = useDispatch(); //eslint-disable-line
  const [entriesValues, setentriesValues] = React.useState();
  const [evaluations, setEvaluations] = React.useState([]);

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  let tableHeader = (editPermission.includes("Appraisal - Evaluations") === true) || (deletePermission.includes("Appraisal - Evaluations") === true) || (roleAllTrue === 1) ? ["Ref Code", "Title", "Subject", "Respondents", "Started Date", "Status", "Actions"] : ["Ref Code", "Title", "Subject", "Respondents", "Started Date", "Status", ""];
  
  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

    //show entries
    const entriesValue = (entries) => {
        setentriesValues(entries);
    }

  const deleteEvaluation = (id, index) => {
    const message = ["All respondent copies of the evaluation will also be deleted.", "Are you sure you want to delete?"];

    WarningPopup("warning", message, true, true).then((res) => {
      if (res?.value) {
        const itemsInPage = props?.evaluations?.list?.length;
        const remainingItemsInPage = itemsInPage - 1;
        const currentPage = props?.evaluations?.currentPage;
        const itemsPerPage = props?.evaluations?.itemsPerPage; // get from evaluation action to reducer

        if (remainingItemsInPage > 0) {
          //call api with current page
          dispatch(evaluationActions.removeInList(id, currentPage, itemsPerPage))
        } else {
          //call previous page
          const prevPage = (currentPage - 1) <= 0 ? 1 : currentPage;
          dispatch(evaluationActions.removeInList(id, prevPage, itemsPerPage));
        }

        // const filterDeletedItem = evaluations.filter((item, i) => i !== index );
        // setEvaluations(filterDeletedItem);
      }
    });
  }

  const updateEvaluationStatus = (status, id, index) => {
    const message = "Are you sure you want to complete this evaluation?";

    WarningPopup("warning", message, true, true).then((res) => {
      if (res?.value) {
        const itemsInPage = props?.evaluations?.list?.length;
        const remainingItemsInPage = itemsInPage - 1;
        const currentPage = props?.evaluations?.currentPage;
        const itemsPerPage = props?.evaluations?.itemsPerPage; // get from evaluation action to reducer

        if (remainingItemsInPage > 0) {
          //call api with current page
          dispatch(evaluationActions.updateStatus(status, id, currentPage, itemsPerPage))
        } else {
          //call previous page
          const prevPage = (currentPage - 1) <= 0 ? 1 : currentPage;
          dispatch(evaluationActions.updateStatus(status, id, prevPage, itemsPerPage));
        }

        const updateEvaluations = evaluations?.map((item, i) => {
          if(i === index) {
            item.status = "completed";
          }
          return item;
        });
        setEvaluations(updateEvaluations);
      }
    });
  }

  const updateEvaluation = (item) => {
    let evaluationId = item.data[0]._id;
    history.push(`/admin/evaluations/${evaluationId}/edit`)

  }

  React.useEffect(()=>{
    // if(props.evaluations?.list?.length > 0 && props.evaluations?.list !== evaluations) {
      setEvaluations(props.evaluations.list);
    // }
  },[props.evaluations])

  const EvaluationsData = () => {
    let data = evaluations ?? [];

    console.log(props.evaluations)
    
    return (
      <>
      {
        props.evaluations.loading !== false ?
            <>
                <tr className="table-td-data">
                <td> 
                    <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                        <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                </td>
                </tr>
            </>
        :

        data.length !== 0 ? (
            data.map((item, index) => (
              <tr key={index}>
                <td>{item.refCode}</td>
                <td style={{ width: "400px" }}><GetTitle data={item} /></td>
                <td><GetSubjectAvatar data={item} /></td>
                <td>{item.respondents}</td>
                <td>{moment(item.startedAt).utc().format("MMM D, YYYY")}</td>
                <td><EvaluationStatus className={item.status === "on going" ? "onGoing" : item.status} /></td>
                {(editPermission.includes("Appraisal - Evaluations") === true) || (deletePermission.includes("Appraisal - Evaluations") === true) || (roleAllTrue === 1) ?
                  <td className={secretDetails !== null ? "disabled-link" : '' }>
                    <div>
                      <DynamicTableAction
                        originPage="Evaluations"
                        iconSet="2"
                        editLabel="Edit Evaluation"
                        deleteLabel="Delete Evaluation"
                        completeLabel={item.status === "finished" ? "Complete Evaluation" : ""}
                        onClickEdit={() => updateEvaluation(item)}
                        onClickDelete={() => deleteEvaluation(item?.data[0]?._id, index)}
                        onClickCompleted={() => updateEvaluationStatus("complete", item?.data[0]?._id, index)}
                        deletePermission="Appraisal - Evaluations"
                        editPermission="Appraisal - Evaluations"
                      />
                    </div>
                  </td> : false
                }
              </tr>
            ))
          ) : (
                <>
                    <tr>
                        <td colSpan="12" style={{textAlign:'center'}}>
                            <img src={emptyIcon} className="empty" alt="empty"/>
                            <p style={{fontSize:'16px'}}>No Data Available</p>
                            <p style={{fontSize:'14px'}}>There are currently no data available</p>
                        </td>
                    </tr>
                </>
            )
      }


        
      </>
    );
  };

  return (
    <AdminEvaluationPage>
      <DynamicContentHeaderWrapper
        buttonLabel="ADD EVALUATION"
        btnPath="/admin/evaluations/create"
        entriesValue={entriesValue}
        originPage="Evaluations"
        addPage="Appraisal - Evaluations"
      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={EvaluationsData()}
        originPage="Evaluations"
        data={props.evaluations}
        location={props.location}
        paginationPath={"/admin/evaluations"}
        entriesItem={entriesValues}
      />
    </AdminEvaluationPage>
  );
};

const mapStateToProps = ({ evaluations }) => ({
  evaluations,
});

const EvaluationPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Evaluations", link: "/admin/evaluations?page=1" },
        ]);
        break;
      case "create":
        setBreadCrumbs([
          { label: "Evaluations", link: "/admin/evaluations?page=1" },
          { label: "Create Evaluation", link: "#" },
        ]);
        break;
        case "edit":
          setBreadCrumbs([
            { label: "Evaluations", link: "/admin/evaluations?page=1" },
            { label: "Edit Evaluation", link: "#" },
          ]);
          break;
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Evaluation | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Appraisal | Evaluation | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : `Edit Evaluation | ${process.env.REACT_APP_DOCUMENT_TITLE}`
        }
      >
        <PageWrapper className={cc(["EvaluationPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Appraisal"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <EvaluationPageList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={['create', 'edit'].includes(props.mode)}>
              <CreateEditEvaluationPage {...props } />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});

EvaluationPage.WrappedComponent.displayName = "EvaluationPage";
const EvaluationPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <EvaluationPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <EvaluationPage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <EvaluationPage {...props} mode="edit" />}
    />
  </Switch>
);
export default React.memo(EvaluationPageRoute);
