import { smsConstants } from '../constants/sms';
import { smsService } from '../services/sms';
import { alertActions } from './alert';
import Swal from 'sweetalert2';

export const smsActions = {
    createOTP,
    getOne,
    deleteAll,
    createMessage
};

function createOTP() {
    return dispatch => {
        dispatch(request());

        const ToastSuccess = Swal.mixin({
            background: "#2a3042",
            toast: true,
            width: 300,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });


        smsService.sendOTP()
        .then(
            sms => {
                dispatch(success(sms));
                
                ToastSuccess.fire({
                    type: 'success',
                    title: '<span style="color:#FFFFFF">OTP sent successfully!</span>'
                }).then(() => {
                    dispatch(alertActions.success('SMS successfully sent'));
                })
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error('Something went wrong'));
            }
        );
    };

    function request() { return { type: smsConstants.POST_REQUEST} }
    function success() { return { type: smsConstants.POST_SUCCESS } }
    function failure(error) { return { type: smsConstants.POST_FAILURE, error} }
}


function getOne(id){
    return dispatch => {
        dispatch(request(id));
        smsService.getOne(id)
            .then(
                sms => {
                    dispatch(success(sms.items));
                    localStorage.setItem('userRole', JSON.stringify(sms.items))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: smsConstants.GET_REQUEST } }
    function success(sms) { return { type: smsConstants.GET_SUCCESS, sms } }
    function failure(error) { return { type: smsConstants.GET_FAILURE, error } }
}

function deleteAll(id){
    return dispatch => {
        dispatch(request(id))
        
        smsService.deleteAll(id)
            .then(
                sms => {
                    dispatch(success(sms))
                },
                error => dispatch(failure(error.toString()))
            )

    }
    function request(id) { return { type: smsConstants.DELETE_REQUEST, id } }
    function success(sms) { return { type: smsConstants.DELETE_SUCCESS, sms } }
    function failure(error) { return { type: smsConstants.DELETE_FAILURE, error } }
}

function createMessage(sms) {
    return dispatch => {
        dispatch(request(sms));

        smsService.sendMessage(sms)
        .then(
            sms => {
                dispatch(success(sms));
                dispatch(alertActions.success('Announcement successfully sent to their contact number.'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error('Failed to send messages.'));
            }
        );
    };

    function request() { return { type: smsConstants.POST_REQUEST} }
    function success() { return { type: smsConstants.POST_SUCCESS } }
    function failure(error) { return { type: smsConstants.POST_FAILURE, error} }
}