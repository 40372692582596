import React from 'react';
import { connect } from 'react-redux';
import { supportTicketActions } from '../_core/actions/supportTicket';
import { Link } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';
import Swal from 'sweetalert2'
import moment from 'moment';
import './SendSupportTicketPage.sass'
import '../_core/styles/alert.sass'
import Select from 'react-select';


const options = [
    { value: 'hr-docs', label: 'HR Document' },
    { value: 'environmental', label: 'Environmental' },
    { value: 'work-resources', label: 'Work Resources' },
    { value: 'employee-relations', label: 'Employee Relations' },
    { value: 'compensation-and-benefits', label: 'Compensation and Benefits' },
    { value: 'labor-law-compliance', label: 'Labor Law Compliance' },
    { value: 'training-and-development', label: 'Training and Development' }
  ]

class SendSupportTicket extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            supportTicket: {
                title: '',
                description: '',
                category: '',
                assignee: '',
                type: 'coe'
            },
            submitted: false,
            render: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderAlert = this.renderAlert.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { supportTicket } = this.state
        this.setState({
            supportTicket: {
                ...supportTicket,
                [name]: value
            }
        });
    }

    handleSelect = (selected) => {
        const { supportTicket } = this.state
        
        this.setState({
            supportTicket: {
            ...supportTicket,
            category: selected.value
            }
        })
        // , () => console.log(this.state.hrConcern)
        // console.log(hrConcern)
      }

    componentDidMount() {
        setTimeout(function () {
            var select = document.querySelectorAll('select');
            M.FormSelect.init(select);
        }, 1000)

    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
        const { supportTicket } = this.state;

        // var category = document.querySelector(".categoryVal");
        // var value_Category = category.options[category.selectedIndex].innerHTML;

        var dateNow = Date(Date.now()).toLocaleString();
        var dateFormat = moment(dateNow).format("ddd, MMMM DD, YYYY");

        // custom toast mixin for sweetAlert
        const Toast = Swal.mixin({
            toast: true,
            width: 300,
            height: 500,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });

        if (supportTicket.title && supportTicket.description && supportTicket.category && supportTicket.type) {
            Swal.fire({
                title: 'Are you sure?',
                html:
                    "<br> <b>Concern Title:</b> " +
                    supportTicket.title +
                    "<br> <b>Concern Catergory:</b>  " +
                    supportTicket.category,
                footer: 'Filing Date:  ' + dateFormat,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5CB85C',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Done!'
            }).then((result) => {
                if (result.value) {

                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:green">Support Ticket has been Sent!</span>'
                    }).then((res) => {

                        this.props.dispatch(supportTicketActions.sendSupportTicket(supportTicket));
                        this.setState({ render: true });

                    })

                }
            })
        }
    }

    handleClose(event) {
        event.preventDefault()
        this.setState({ render: false })
    }




    renderAlert() {
        // const { alert } = this.props;
        // if (this.state.render) {
        //     return (<div onLoad={this.autoClose} id='alert' className={`alert ${alert.type}`} toggle={this.onDismiss}>{alert.message}<span className='closebtn right' onClick={this.handleClose}>&times;</span></div>)

        // } else {
        //     return null
        // }

    }
    render() {
        const { supportTicket, submitted } = this.state
        // eslint-disable-next-line
        const { alert } = this.props;

        const customStyle = {
            control: (base, state) => ({
              ...base,
              height: 45,
              width: "92%",
              borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
              boxShadow: state.isFocused ? null : null,
            }),
            menu: (base) => ({
                ...base,
                width: "92%",                
            }),
            menuList: (base) => ({
                ...base,
                width: "100%",
            }),
            input: (base) => ({
              ...base,
              // background: "#023950",
              height: 32,
              // width: '100%',
              paddingTop: 0,
              paddingBottom: 0,
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? 'rgb(202,108,235)' : state.isFocused ? 'rgba(202,108,235,0.5)' : '',
                color: state.isSelected ? 'white' : state.isFocused ? 'white' : '',
                '& hover': {
                  backgroundColor: 'rgb(202,108,235)',
                },
                
              }),
        }

        return (

            <div className="col s12 m12 l12 userDetailConLineLeft">
                                                
                <div className="container-fluid support">
                                                
                    <form name="form" onSubmit={this.handleSubmit}>

                            <div className="row">
                                <div className="col s12">
                                <p className="titles">Add title here</p>
                                    <input type="text" className="form-control validate title" name="title" value={supportTicket.title} onChange={this.handleChange} placeholder="Type your title here" />
                                        {submitted && !supportTicket.title &&
                                                <div className="help-block red-text" data-error="wrong" data-success="right">Title is required</div>
                                        }
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12">
                                <p className="titles marginbottom">Category</p>
                                    <Select
                                        options={options} 
                                        styles={customStyle}
                                        onChange={this.handleSelect}
                                        placeholder="Select Category" 
                                    />
                                    {/* <select className="form-control validate categoryVal" name="category" value={supportTicket.category} onChange={this.handleChange} id="name" placeholder="aa">
                                        <option defaultValue > Select Category </option>
                                        <option value="hr-docs">HR Document</option>
                                        <option value="environmental">Environmental</option>
                                        <option value="work-resources">Work Resources</option>
                                        <option value="employee-relations">Employee Relations</option>
                                        <option value="compensation-and-benefits">Compensation and Benefits</option>
                                        <option value="labor-law-compliance">Labor Law Compliance</option>
                                        <option value="training-and-development">Training and Development</option>
                                        </select> */}
                                    {submitted && !supportTicket.category &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">Category is required</div>
                                    }
                                    </div>
                            </div>

                            <div className="row">
                                <div className="col s12">
                                    <div className={'textarea-field' + (submitted && !supportTicket.description ? ' has-error' : '')} id="description-container">
                                        <p className="titles">Details</p>
                                            <textarea className="form-control validate" id="description" value={supportTicket.description} onChange={this.handleChange} name="description" placeholder="Enter your details here" />
                                                {submitted && !supportTicket.description &&
                                                    <div className="help-block red-text" data-error="wrong" data-success="right">Description is required</div>
                                                }
                                            </div>
                                    </div>
                                </div>
                                                    
                                <div className="row">
                                    <div className="col s12">
                                        <button className="btn submitbtn">Submit</button>
                                        <Link to="/profile" ><button className="btn cancelbtn">Cancel</button></Link>
                                    </div>
                                 </div>


                        </form>
                                                
                </div>
                                                

                                            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { coe } = state;
    return {
        coe,
        alert
    };
}
const connectedSendSupportTicket = connect(mapStateToProps)(SendSupportTicket);

export { connectedSendSupportTicket as SendSupportTicket };