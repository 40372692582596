import { post, get, del, put } from '../helpers/api';
import { userService } from '../services/user';

export const roleService = {
    getAll,
    roleDelete,
    roleAdd,
    getOneRole,
    updateRole,
    delete: _delete,
    rolePermission,
    roleDeletepermissions
};


function roleAdd(roles) {
    return post('/api/v1/role', JSON.stringify(roles))
        .then(handleResponse)
}

function rolePermission(role, permissions) {
    return put(`/api/v1/role/${role}/permission`, JSON.stringify(permissions))
        .then(handleResponse)
}

function roleDelete(id){
    return del(`/api/v1/role/${id}`).then(handleResponse)
}

function getAll(page, itemPerPage) {
    return get(`/api/v1/role?page=${page || 1}&items-per-page=${itemPerPage || 20}`).then(handleResponse);
}

function getOneRole(id) {
    return get(`/api/v1/role/${id}`).then(handleResponse);
}

function updateRole(role) {
    return put(`/api/v1/role/${role._id}`, JSON.stringify(role))
        .then(handleResponse)
}

function roleDeletepermissions(id,permission){
    return put(`/api/v1/role/${id}/permission/${permission}`).then(handleResponse)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return del(`/api/v1/role/${id}`).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}
