import React from 'react';
import { connect } from 'react-redux';
import MainNav from '../Components/MainNav';
import HeaderPhoto from '../Components/HeaderPhoto';
import DetailPage from '../Components/DetailPage';
import StatusPage from '../Components/StatusPage';
import UserTab from '../Components/UserTab';
import M from 'materialize-css/dist/js/materialize.min.js'
import 'react-svg-radar-chart/build/css/index.css'
import './UserProfileDetail.sass';
import moment from 'moment';
import {hrm} from '../_core/helpers/config';

class UserProfileDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            render: true, 
            imageHash: Date.now(),
        }
        this.handleClose = this.handleClose.bind(this);
        this.alertTimeOut = this.alertTimeOut.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        var elem = document.querySelector(".parallax");
        M.Parallax.init(elem);

        this.alertTimeOut();

        var elems = document.querySelectorAll('.carousel');
        M.Carousel.init(elems);


    }
    alertTimeOut = () =>
        setTimeout(function () {
            this.setState({ render: false })

        }.bind(this), 4000
        )


    /*componentWillUnmount() {
        clearTimeout(this.alertTimeOut)
    }*/

    handleClose(event) {
        event.preventDefault()
        this.setState({ render: false })
    }

    render() {
        // const { alert } = this.props;
        // const { imageHash } = this.state;
        let user = JSON.parse(localStorage.getItem('user'));
        let userCred = JSON.parse(localStorage.getItem('userCredentials'));
        const reg_date = hrm.regularizationDate;
        
        return (
            <div className="profile">
                <MainNav />
               
                <HeaderPhoto />

                <UserTab />

                <div className="container-userdetail">

                    <div className="col s12 m4">
                        <div className="row">
                            <div className="col s12 m3">
                                <DetailPage />
                            </div>
                            <div className="col s12 m9 l6">
                                <div className="BodyCon">
                                    <div className="userDetailCon">
                                        <div className="row">
                                            
                                            <div className="col s12 m12 l12">
                                                <div className="row userDetailCon2">
                                                    <div className="col s12 m6">
                                                        <div className="userDetailList">
                                                            <p><b>Email</b></p> 
                                                            {userCred.user.email ?
                                                            <p>{userCred.user.email}</p>
                                                            : <em>No Data Available</em>} 
                                                        </div>
                                                        
                                                        <div className="userDetailList">
                                                            <p><b>Gender</b></p>
                                                            {user.gender ?
                                                            <p>{user.gender}</p>
                                                            : <em>No Data Available</em>}
                                                        </div>
                                                        
                                                        <div className="userDetailList">
                                                            <p><b>Birthdate</b></p>
                                                            <p>{moment(user.birthDate).format() === 'Invalid date' ? <em>No Data Available</em> : moment(user.birthDate).format("MMMM D, YYYY")}</p>
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>Marital Status</b></p>
                                                            {user.maritalStatus ?
                                                            <p>{user.maritalStatus}</p>
                                                            : <em>No Data Available</em>} 
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>BirthPlace</b></p>
                                                            {user.birthPlace ?
                                                            <p>{user.birthPlace}</p>
                                                            : <em>No Data Available</em>}
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>Mothers maiden name</b></p>
                                                            {user.mothersMaidenName ?
                                                            <p>{user.mothersMaidenName}</p>
                                                            : <em>No Data Available</em>}
                                                        </div>
                                                    </div>
                                                    <div className="col s12 m6">
                                                        <div className="userDetailList">
                                                            <p><b>Address</b></p>
                                                            {user.address1 || user.address2 ?
                                                            <p>{user.address1}, {user.address2}</p>
                                                            : <em>No Data Available</em>}
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>City</b></p>
                                                            {user.city ?
                                                            <p>{user.city}</p>
                                                            : <em>No Data Available</em>}
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>State</b></p>
                                                            {user.state ?
                                                            <p>{user.state}</p>
                                                            : <em>No Data Available</em>}
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>Country</b></p>
                                                            {user.country ?
                                                            <p>{user.country}</p>
                                                            : <em>No Data Available</em>}
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>Nationality</b></p>
                                                            {user.nationality ?
                                                            <p>{user.nationality}</p>
                                                            : <em>No Data Available</em>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row userDetailCon2">
                                                    <div className="col s12 m6">
                                                        <div className="userDetailList userDetailList2">
                                                            <p><b>Employment Details</b></p>
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>Department</b></p>
                                                            {user.department ?
                                                            <p>{user.department}</p>
                                                            : <em>No Data Available</em>}
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>Position</b></p>
                                                            {user.position ?
                                                            <p>{user.position}</p>
                                                            : <em>No Data Available</em>}
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>Start Date</b></p>
                                                            <p>{moment(user.startDate).format() === 'Invalid date' ? <em>No Data Available</em> : moment(user.startDate).format("MMMM D, YYYY")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col s12 m6">
                                                        <div className="userDetailList userDetailList1">
                                                            <p><b>Regularization Date</b></p>
                                                            <p>{ moment(user.regularizationDate).format() === 'Invalid date' ? moment(user.startDate).format() === 'Invalid date' ? <em>No Data Available</em> : moment(user.startDate).add(reg_date, 'M').format("MMMM D, YYYY")
                                                                : moment(user.regularizationDate).format("MMMM D, YYYY")
                                                            }</p>
                                                        </div>
                                                        <div className="userDetailList">
                                                            <p><b>End Date</b></p>
                                                            <p>{moment(user.endDate).format() === 'Invalid date' ? <em>No Data Available</em> : moment(user.endDate).format("MMMM D, YYYY")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col s12 m3">
                                <StatusPage />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );

    }
}
function mapStateToProps(state) {
    const { authentication, alert } = state;
    const { user } = authentication;
    return {
        user,
        alert
    };
}
const connectedUserProfile = connect(mapStateToProps)(UserProfileDetail);
export { connectedUserProfile as UserProfileDetail };
