import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import Select, { components } from "react-select";
import { useParams } from 'react-router-dom';
import { leaveTypeService } from '../../../_core/services/leaveType';
import { leaveCreditActions } from '../../../_core/actions/leaveCredit';
import { ContentWrapper, LabelCustom} from "../../Components/PageWrapper/ContentWrapper";

import { userActions } from "../../../_core/actions/user";

import Swal from 'sweetalert2';
import search_icon from "../../../_templates/dark/search_default.png";
import "react-datepicker/dist/react-datepicker.css";
import "./LeaveCreditsDark.scss";

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;
export const SearchIconFindUser = styled.img`
  margin-bottom: -30px;
  margin-left: 15px;
  display: flex;
  position: relative;
  z-index: 1;
  height: 15px;
  width: 15px;
  object-fit: contain;
`;

export const FindUserWrapper = styled.div`
  > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    margin-left: 15px;
    color: #f14646 !important ;
    position: initial !important;
  }
`;

export const GridContainer = styled.div`
  ${(props) =>
    props.isShowUserDetails === false
      ? css`
          margin-top: 15px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;
        
        `
      : css`
          display: block;
        `}

  > div > label.label__questionnaires, > div > label.label__tenures {
    display: flex;
    float: left;
    top: 10px;
    position: relative;
    z-index: 1;
    margin-left: 25px;
    color: #9ba2ce;
  }

  > div > label.Error__message {
    margin-left: 10px;
    
  }

  > div > label.label__questionnaires {
    background: #2a3143;
    width: 102px;
    margin-top: 5px;
   
  }
  > div > label.label__tenures {
    background: #2a3143;
    width: 40px;
    margin-top: 5px;
  }

  > div > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646 !important ;
    position: initial !important;
  }

  > div > div.error_date {
    border: 1px solid #f14646;
    border-radius: 3px;
  }
`;


const mapStateToProps = ({ evaluations, questionnaires, tenures, users }) => ({
  evaluations,
  questionnaires,
  tenures,
  users,
});

const CreateCreditsPage = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();
  const [InputError, setInputError] = useState(false);
  const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
  const [isDisplayUserDetails, setIsDisplayUserDetails] = useState(false); // eslint-disable-line
  const [leaveTypesDropdown, setLeaveTypesDropdown] = useState([]);
  const { id } = useParams();
  const [SelectedQuestionnaires, setSelectedQuestionnaires] = useState(null)

  const [SelectedEvaluator, setSelectedEvaluator] = useState(null)
  const [UserData, setUserData] = useState([]);

  const [types, setTypes] = React.useState([]);
  const [userCredits, setUserCredits] = useState({
    user_id: '',
    leave_type: '',
    value: '',
    status: true,
  });

  React.useEffect(() => {
    try {
      leaveTypeService.getAll(1, 999999).then(res => {
        if (res.items) {
          setTypes(res.items)
        }
      }).catch((err) => { console.log('get leave error occured') })
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    // React.useEffect(() => {
    //   const userDataNew = UserData.find(option =>  option.value === id ? (
    //     setUserCredits({ ...userCredits, user_id: option.value })
    //   ): '')
    //   console.log(userDataNew)
    // }, []); 

  React.useEffect(() => {
    leaveTypeService.getAll().then((res) => { 
      const leaveTypes = res.items;
      const uniqueType = Array.from(leaveTypes.reduce((map, obj) => map.set(obj.type, obj),new Map()).values());
      const leaveTypes2 = uniqueType.map(leaveType => leaveType.type);
      let optionGroupData = [];

      leaveTypes2 && 
      leaveTypes2.map((items) => {
        if (items.length !== 0) {
          optionGroupData.push({
            label: items,
            value: items,
            options: []
          });
        }
        return null
      });

      leaveTypes.length !== 0 &&
      leaveTypes.forEach((item) => {
        optionGroupData.map((val, index) => {
          let leaveTypeIndex = index;
          if (item.type === val.value) {
            optionGroupData[leaveTypeIndex].options.push({ label: item.name, value: item._id });
          }
          return null
        })
      });

      setLeaveTypesDropdown(optionGroupData);
    });
  })

  React.useEffect(() => {
    let page = 1;
    let totalItems = 9999;
    dispatch(userActions.getAllActiveUsers(page, totalItems));
  }, [dispatch]);

  // useEffect(() => {
  //   filterLeaveTypesDropdownValue(types);
  //   //eslint-disable-next-line
  // }, [types]);

  useEffect(() => {
    filterUserDropdownValue(props.users?.users?.items);
    
    // props.users?.items?.map((item) => {
      // props.users?.items?.forEach(item => console.log("yow:"+item))

    //eslint-disable-next-line
  }, [props.users.users]);

  // const filterLeaveTypesDropdownValue = (data) => {
  //   let optionGroupData = [];
  //   const optionGroup = [
  //     {
  //       label: "Leave",
  //       type: "vacation",
  //       options: [],
  //     },
  //     {
  //       label: "Medical",
  //       type: "medical",
  //       options: [],
  //     },
  //     {
  //       label: "Emergency",
  //       type: "emergency",
  //       options: [],
  //     },
  //   ];


  //   types.length !== 0 &&
  //     types.forEach((item) => {
  //       if (item.type === "vacation") {
  //         optionGroup[0].options.push({ label: item.name, value: item._id });
  //       }
  //       if (item.type === "medical") {
  //         optionGroup[1].options.push({ label: item.name, value: item._id });
  //       }
  //       if (item.type === "emergency") {
  //         optionGroup[2].options.push({ label: item.name, value: item._id });
  //       }
  //     });
     

  //   optionGroup.map((data) => {
  //     if (data.options.length !== 0) {
  //       optionGroupData.push({
  //         label: data.label,
  //         options: data.options,
  //       });
  //     }
  //     return null;
  //   });

  //   setLeaveTypesDropdown(optionGroup);
  // };


  const filterUserDropdownValue = (data) => {
    let userList = [];
    // let userCredits = {};
    let userData = data ?? [];

    userData.length !== 0 &&
      userData.map((item) => {
        userList.push({
          label: item.details[0].firstName + " " + item.details[0].lastName,
          value: item._id,
        });
        if (item._id === id) {
          setSelectedEvaluator({
            label: item.details[0].firstName + " " + item.details[0].lastName,
          value: item._id,
          });
          setUserCredits({ ...userCredits, user_id: item._id })
          // userCredits = {
          //   user_id: item._id,
          //   leave_type: '',
          //   value: '',
          //   // value: item.credit.toString() ?? '',
          //   status: item.active == 1 ? true : false,
          // };
        }
        return null;
      });

    setUserData(userList);
    // setUserCredits(userCredits);
  };




  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
  return (
      <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused} className="menu">
          {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
      )}
      </ValueContainer>
  );
  };

  const validErrorEvaluator = {
    control: (provided, state) => ({
      ...provided,
      borderColor: InputError && !userCredits.user_id ? "#f14646" : "#2e3648",
      minHeight: 40,
      height: 40,
      background: "#2e3648",
      width: "100%",
      boxShadow: state.isFocused ? null : null,
    }),
  };


  const customStyles = {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: "#fff",
      // height: 46,
    }),
    option: (base, state) => ({
      ...base,
      color: "#9ba2ce",
      backgroundColor: state.isSelected ? "#32394A" : "#2e3648",
      ":hover": {
        backgroundColor: state.isSelected ? "#2e3648" : "#32394A",
      },
      zIndex: "999",
    }),

    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: "0",
      marginTop: "-5px",
      background: "#2e3648",
      zIndex: "999",
    }),
    menu: (provided) => ({
      ...provided,
      height: "auto",
      maxHeight: "208px",
      position: "absolute",
      display: "block",
      background: "#2e3648",
      zIndex: "999",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "auto",
      padding: "0 5px",
    }),
    input: (provided, state) => ({
      ...provided,

      height: 40,
      color: "#9ba2ce",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: state.isFocused ? 0 : 0,
      paddingLeft: "40px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#9ba2ce",
      paddingLeft: "40px",
      top: "50%",
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingLeft: "40px",
      color: "#9ba2ce",
      top: "50%",
    }),
  };

  const customStyles3 = {
    control: (base, state) => ({
        ...base,
        background: "#2e3648",
        height:'30px',
        borderRadius: '5px',
        borderColor: state.isFocused ? "#323a4f" : "#323a4f",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {

          borderColor: state.isFocused ? "#556ee6" : "#556ee6",
        },
      }),

    container: (provided, state) => ({
      ...provided,
      marginTop: '20px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible"
    }),
    placeholder: (provided, state) => ({
        ...provided,
        position: "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
        transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
        background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
        color: "#9BA3CA",
    }),
    
    option: (base, state) => ({
        ...base,
        color: '#9BA3CA',
        backgroundColor: state.isSelected ? '#32394A' : '#2A3143',
        ':hover': {
          backgroundColor: state.isSelected ? '#2A3143' : '#32394A'
        }
      }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    input: (provided, state) => ({
        ...provided,
        height: 35,
        color: '#9BA3CA',
        lineHeight: state.isFocused ? 35 : 0,
        transition:  "top 0.1s, font-size 0.1s"
    }),
    singleValue: (provided) => ({
        ...provided,
        color: ' #9BA3CA',
        fontSize:"14px",
        fontWeight:'normal',
        top:'35%',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        marginTop: '-15px',
      }),
  };

  const handleQuestionnaire = (data) => {
    setSelectedQuestionnaires(data);
    setUserCredits({ ...userCredits, leave_type: data === null ? '' : data.value });
   
  };

  const handleUsers = (data) => {
    setSelectedEvaluator(data);
    setUserCredits({ ...userCredits, user_id: data.value });
  };

const handleValue = (e) => {
    e.preventDefault()
    setUserCredits({ ...userCredits, value: e.target.value });
}

  const submitEvaluation = () => {
    
    if (
      !userCredits.user_id ||
      !userCredits.leave_type ||
      !userCredits.value 
    ) {
      setInputError(true);
    } else {
        var user_name = '';
        var leave ='';
        var value = userCredits.value
    
        props.users.users && props.users.users.items.map((user) => {
            if(user._id === SelectedEvaluator.value){
                user_name = user.details[0].firstName + " " + user.details[0].lastName;
            }
    
    
            return user
        })
    
        types && types.map((type) => {
            if(type._id === SelectedQuestionnaires.value){
                leave = type.name
            }
            return type
        })

        Swal.fire({
            title: '<p class="swal-subtitle-dark">Are you sure?</p>',
            html:
                "<br> <b class='swal-subtitle-dark'>Employee Name:"
                + user_name + "</b><br><b class='swal-subtitle-dark'>Leave Type: "
                + leave + "<br><b class='swal-subtitle-dark'>Value:  "
                + value + "</b>",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#24DC98',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Done!',
                background: '#2E3549',

        }).then((result) => {
            if (result.value) {
                dispatch(leaveCreditActions.addleaveCredit(userCredits))
            }
        })
    }
  };


  const validErrorQuestionnaire = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 40,
      height: 40,
      background: "#2E3648",
      borderColor:
        InputError && !userCredits.leave_type ? "#f14646" : "#2e3648",
      width: "100%",
      // border: "none",
      boxShadow: state.isFocused ? null : null,
    }),
  };

  return (
    <CustomContentWrapper displayUserWrapper={isDisplayUserDetails}>
      <ContentWrapper>
        <LabelCustom className="main-label">
            Employee
        </LabelCustom>

        <FindUserWrapper>
            <SearchIconFindUser src={search_icon} alt=""></SearchIconFindUser>
            <Select
                value={id ? (UserData.find(option =>  option.value === id ? option.label  : '')) : SelectedEvaluator}
                onChange={(data) => handleUsers(data)}
                captureMenuScroll={false}
                placeholder="Find User"
                styles={{ ...customStyles, ...validErrorEvaluator }}
                options={UserData}
                openMenuOnClick={false}
                isSearchable={true}
                menuPlacement="bottom"
                menuPortalTarget={document.getElementById("root")}
                hideSelectedOptions={false}
                classNamePrefix="select"
                maxMenuHeight={200}
                components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                }}
            ></Select>
            {InputError && !userCredits.user_id && (
                <label className="Error__message">Please fill-up this field</label>
            )}
        </FindUserWrapper>

        <GridContainer isShowUserDetails={isDisplayUserDetails}>
          <div>
                <Select 
                    className="basic-single"
                    classNamePrefix="select"
                    options={leaveTypesDropdown} 
                    defaultValue={leaveTypesDropdown.find(option => option.value === userCredits.leave_type || '' )}
                    components={{
                            ValueContainer: CustomValueContainer
                    }}
                    placeholder="Leave Classification"
                    name="credits"
                    onChange={(data) => handleQuestionnaire(data)}
                    maxMenuHeight={220}
                    menuPlacement="bottom"
                    menuPortalTarget={document.getElementById("root")}
                    styles={{...customStyles3 , ...validErrorQuestionnaire}}
                    isSearchable={false}
                    openMenuOnClick={false}
                    isClearable={true}
                />
            {InputError && !userCredits.leave_type && (
              <label className="Error__message">
                Please fill-up this field
              </label>
            )}
          </div>
            <div className="floating-label value"> 
            
                <input  className={InputError && !userCredits.value ? "floating-input is-error" : "floating-input"}  pattern="\d+(\.\d+)?" type="text" placeholder=" "  step="any" name="value" id="value" onChange={handleValue} />
                <label>Value (Days)</label>
                {!userCredits.value?.trim() && InputError && (
                    <label className="Error__message">Please fill-up this field. </label>
                )}
            </div>


        </GridContainer>
        <div style={{maxWidth:'180px',marginLeft:'auto'}}>
            <button onClick={submitEvaluation} className="addLeave">ADD LEAVE CREDIT</button>
        </div>
      </ContentWrapper>

    </CustomContentWrapper>
  );
});

export default CreateCreditsPage;
