import pagesConstants from '../constants/pageManagement';

const initialState = {
    items: [],
    loading: true,
    error: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case pagesConstants.GET_PAGES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case pagesConstants.GET_PAGES_SUCCESS:
            return {
                ...action.data,
                loading: false,
                error: null,
            }
        case pagesConstants.GET_PAGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}