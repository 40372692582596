import React from 'react';
import { connect } from 'react-redux';

import { missionActions } from '../_core/actions/mission';
import { alertActions } from '../_core/actions/alert';
import ErrorPage from '../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../Components/ERRORPAGE/ButtonHome';
import error from '../_templates/error.png';

import '../_core/styles/alert.sass'
class MissionsList extends React.Component {

	constructor(props) {
        super(props);

        //get logged in user
        let _user_id = JSON.parse(localStorage.getItem('userCredentials'));
		let _quest_id = JSON.parse(localStorage.getItem('quest'));
        this.state = {
            mission: {
                userId: _user_id.user._id,
                questId: _quest_id,
                status: 'In Progress',
				remarks: 'Remarks',
                missionId: ''        
            },
            submitted: false,
            render: true,
        };


        this.handleStart = this.handleStart.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.alertTimeOut = this.alertTimeOut.bind(this);
	}

    handleStart(missionid){
        return (e) => {
            e.preventDefault();
            let { mission }        = this.state;
            this.setState({
                mission: {
                    ...mission,
                    missionId: missionid,
                },
                render: true
            },() => {
                        let updatedMission = this.state;
                        this.props.dispatch(missionActions.startMission(updatedMission.mission));
                    }
            );
         }
     
    }
    componentWillUnmount(){
        clearTimeout(this.alertTimeOut)
    }

    alertTimeOut = () =>
        setTimeout(function() {
            this.setState({render: false})
            this.props.dispatch(alertActions.clear());
        }.bind(this), 4000
    )

    handleClose(event){
        event.preventDefault()
        this.setState({render: false})
        clearTimeout(this.alertTimeOut)
    }
    componentDidMount() {
		// const { questId } = this.props;
		let questId = JSON.parse(localStorage.getItem('quest'));
		this.props.dispatch(missionActions.getMissions(questId));

	}

    handleViewMission(id) {
        //return (e) => this.props.dispatch(missionActions.getById(id));
    }
    render() {
    	const { missions,alert } = this.props;
        const { render } = this.state;
    
        return (
            <div>
                {alert.message && render &&
                <div className={`alert ${alert.type}`} toggle={this.onDismiss}>{alert.message}<span className="closebtn right" onClick={this.handleClose}>&times;</span></div>
            }
            { missions.error ? 
                <div className="container error-div">
                    <ErrorPage/>
                        <div className="text-danger">
                            <img className="error" src={error} alt="error"/> 
                                {missions.error}
                        </div>
                    <ButtonHome/>
                </div> :

       		<div className="container" id="content">
       			{missions.loading && <em>Loading missions...</em>}
                {missions.items &&
                    <div className="row">
                        {missions.items.length ? 
                            <div className="grid-pinterest" >
                            <h4 className="quest-title-index center"> Missions </h4>
                                {missions.items.map((mission, index) =>
                                    <div className="card item" key={index}>
                                        <div className="card-image">
                                            <img className="img-quest" src={mission.missionImage} onError={(e)=>{e.target.onerror = null; e.target.src="https://via.placeholder.com/350x150"}} alt="Quest Banner" />
                                                {/*{quest.questImage}*/}
                                                <span className="quest-title">{mission.missionTitle}</span>
                                        </div>

                                        <div className="card-content">
                                            <p>{mission.missionContent}</p>
                                        </div>
                                        <div className="card-action">
                                            <button onClick={this.handleStart(mission._id)} className="waves-effect waves-light btn green">START</button>  
                                        </div>
                                    </div>
                                )}
                            </div> 
                            :
                            <h5>NO MISSIONS AVAILABLE FOR THIS QUEST</h5>}   
                    </div>
                } 
        	</div>
            }
            </div>
        );
    }
}

function mapStateToProps(state) {
  	const { missions , alert } = state;
    return {
		missions,
        alert 
    };
}

const connectedMissionsList = connect(mapStateToProps)(MissionsList);
export { connectedMissionsList as MissionsList};