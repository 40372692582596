export const hrConcernConstants = {
    
    SUBMIT_HRCONCERN_REQUEST: 'HRCONCERN_SUBMITHRCONCERN_REQUEST',
    SUBMIT_HRCONCERN_SUCCESS: 'HRCONCERN_SUBMITHRCONCERN_SUCCESS',
    SUBMIT_HRCONCERN_FAILURE: 'HRCONCERN_SUBMITHRCONCERN_FAILURE',

    GETALL_HRCONCERN_REQUEST: 'GETALL_HRCONCERN_REQUEST',
    GETALL_HRCONCERN_SUCCESS: 'GETALL_HRCONCERN_SUCCESS',
    GETALL_HRCONCERN_FAILURE: 'GETALL_HRCONCERN_FAILURE',

    UPDATE_HR_CONCERN_REQUEST: 'UPDATE_HR_CONCERN_REQUEST',
    UPDATE_HR_CONCERN_SUCCESS: 'UPDATE_HR_CONCERN_SUCCESS',
    UPDATE_HR_CONCERN_FAILURE: 'UPDATE_HR_CONCERN_FAILURE',

    APPROVE_HR_CONCERN_REQUEST: 'APPROVE_HR_CONCERN_REQUEST',
    APPROVE_HR_CONCERN_SUCCESS: 'APPROVE_HR_CONCERN_SUCCESS',
    APPROVE_HR_CONCERN_FAILURE: 'APPROVE_HR_CONCERN_FAILURE',

    HOLD_HR_CONCERN_REQUEST: 'HOLD_HR_CONCERN_REQUEST',
    HOLD_HR_CONCERN_SUCCESS: 'HOLD_HR_CONCERN_SUCCESS',
    HOLD_HR_CONCERN_FAILURE: 'HOLD_HR_CONCERN_FAILURE',

    DECLINE_REQUEST: 'HR_CONCERN_REJECTED_REQUEST',
    DECLINE_SUCCESS: 'HR_CONCERN_REJECTED_SUCCESS',
    DECLINE_FAILURE: 'HR_CONCERN_REJECTED_FAILURE',

    GETUSER_REQUEST: 'HR_CONCERN_GETUSER_REQUEST',
    GETUSER_SUCCESS: 'HR_CONCERN_GETUSER_SUCCESS',
    GETUSER_FAILURE: 'HR_CONCERN_GET_USER_FAILURE',

    UPDATEUSER_REQUEST: 'HR_CONCERN_UPDATE_REQUEST',
    UPDATEUSER_SUCCESS: 'HR_CONCERN_UPDATE_SUCCESS',
    UPDATEUSER_FAILURE: 'HR_CONCERN_UPDATE_FAILURE',

};


 