import { leaveTypeConstants } from '../constants/leaveType';

let initialState = {
  items: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function leaveTypes(state = initialState, action){
    switch(action.type){
        
        //POST LEAVE TYPE
        case leaveTypeConstants.POST_LEAVETYPE_REQUEST:
          return {saving: true};
        case leaveTypeConstants.POST_LEAVETYPE_SUCCESS:
          return {};
        case leaveTypeConstants.POST_LEAVETYPE_FAILURE:
          return {error: action.error};
        //GETALL LEAVE TYPE
        case leaveTypeConstants.GETALL_REQUEST:
            return {         
                ...state,
                loading: true
            };
        case leaveTypeConstants.GETALL_SUCCESS:
          // return {items: action.leaveTypes};
            return {
                ...state,
                loading: false,
                error: null,
                items: action.leaveTypes.items,
                totalPerPage:  action.leaveTypes.total,
                perPage: action.leaveTypes.pages,
                currentPage: action.leaveTypes.page,
            };
        case leaveTypeConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case leaveTypeConstants.DEL_SUCCESS:
          return {}
        
        case leaveTypeConstants.GETBYID_REQUEST:
          // return {loading: true}
          return {         
            ...state,
            loading: true
        };
        case leaveTypeConstants.GETBYID_SUCCESS:
          return {
            ...state,
            loading: false,
            items: action.leavetype
          }
          // return {
          //   ...state,
          //   ...action.leavetype,
          //   loading: false,
          // };
        case leaveTypeConstants.GETBYID_FAILURE:
          return { 
            ...state,
            loading: false,
            error: action.error
          };

        case leaveTypeConstants.UPDATE_LEAVETYPE_REQUEST:
          return {loading: true}

        default:
          return state

    }
}