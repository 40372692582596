import React, { Component } from 'react';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import icon from '../steps/4.png'

registerPlugin(FilePondPluginImagePreview);


export default class Step1 extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // componentWillUnmount() {}

  // not required as this component has no forms or user entry
  // isValidated() {}

  jumpToStep(toStep) {
    // We can explicitly move to a step (we -1 as its a zero based index)
    this.props.jumpToStep(toStep-1); // The StepZilla library injects this jumpToStep utility into each component
  }

  render() {
    return (
      <div className="step step1">
          <div className="col s12 m12 l12">
              <div className="card-panel white lighten-5 z-depth-1">
                <div className="card-header">
                  <div className="step-icon">
                    <img src={icon} alt="icon" className="step-icon"/>
                  </div>
                  <div className="steps-name">
                    <h6 className="step-name"> User Profile </h6>
                  </div>
                  <div className="right">
                    <h6> Step 1 of 4 </h6>
                  </div>
                </div>
                
                <div className="container">
                  <div className="row">
                    <div className="col s12 m6 l5 xl4">
                      <h6 className="black-text">Change Avatar</h6>
                      <div className="card card-avatar">
                        <div className="file-field input-field input1">
                          <FilePond allowMultiple={false} maxFiles={1} server='null'/>
                        </div>
                          <h6 className="filepond-titles">Upload Image</h6>
                        <div className="button-container">
                          <button className="btn btn-save-image-one">Save</button>
                          <button className="btn btn-cancel-one">Cancel</button>
                        </div>
                      </div>
                    </div>

                    <div className="col s12 m6 l7 xl8">
                      <h6 className="black-text">Cover Photo</h6>
                      <div className="card card-photo">
                        <div className="file-field input-field input1">
                          <FilePond allowMultiple={false} maxFiles={1} server='null'/>
                        </div>
                        <h6 className="filepond-titles">Upload Image</h6>
                        <div className="button-container-two">
                          <button className="btn btn-save-image-two">Save</button>
                          <button className="btn btn-cancel-two">Cancel</button>
                        </div>
                      </div>
                    </div>

                    <div className="col s12">
                      <h6 className="black-text">Description</h6>
                      <div className="card card-descp">
                        <div className="input-field input2 col s12">
                          <textarea id="textarea2" className="materialize-textarea" data-length="120" placeholder="Sample Text"></textarea>
                        </div>
                      </div>
                      <div className="button-container-three">
                        <button className="btn btn-next" onClick={() => this.jumpToStep(2)}>Next</button>
                      </div>
                    </div>

                  </div>
                </div>








              </div>
          </div>  
      </div>
    )
  }
}
