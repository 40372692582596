import { commentConstants } from '../constants/comment';

export function comments(state = {}, action) {
  switch (action.type) {
    case commentConstants.POST_REQUEST:
        return {saving: true};
    case commentConstants.POST_SUCCESS:
        return {success: true};
    case commentConstants.POST_FAILURE:
        return {error: action.error};

    case commentConstants.GETALL_REQUEST:
        return {loading: true};
    case commentConstants.GETALL_SUCCESS:
        return {comments: action.comments};
    case commentConstants.GETALL_FAILURE:
        return {error: action.error};

    case commentConstants.GET_REQUEST:
        return {loading: true};
    case commentConstants.GET_SUCCESS:
        return {comment: action.comment};
    case commentConstants.GET_FAILURE:
        return {error: action.error};
    
    case commentConstants.GET_ORIGIN_REQUEST:
        return {loading: true};
    case commentConstants.GET_ORIGIN_SUCCESS:
        return {orig_comment: action.orig_comment};
    case commentConstants.GET_ORIGIN_FAILURE:
        return {error: action.error};

    default:
        return state;
  }
}