import React from 'react';
import { connect } from 'react-redux';
import { If, Then, Else } from 'react-if';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import useOnClickOutside from 'use-onclickoutside'

import Avatar from '../Components/Avatar/Avatar';

import { userActions } from '../_core/actions/user';

import './ProfileDropdown.scss';

import chevronDown from '../_templates/chevron-down.png';

const ProfileDropdown = ({ isAdmin }) => {
	const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
	const [user, setUser] = React.useState({});
	const ref = React.useRef();

	const secret = JSON.parse(localStorage.getItem("secretDetails"));
	const secretRole = JSON.parse(localStorage.getItem('secretRole'))?.[0]?.roleAll === 1 ? true : false

	const isUnknown = localStorage.getItem('secretRole') !== null ? secretRole : isAdmin

	const isLoading = !(
		(isEmpty(user) === false) &&
		typeof isUnknown === 'boolean'
	);

	const logout = React.useCallback(() => {
		userActions.logout();
	}, []);

	const impersonate = React.useCallback(() => {
		userActions.stopImpersonate();
	}, []);

	const toggleDropdown = React.useCallback(() => {
		if (isLoading) {
			return;
		}

		setIsDropdownOpen(state => !state);
	}, [isLoading, setIsDropdownOpen]);

	React.useEffect(() => {
		const getUser = () => {
			const user = JSON.parse(localStorage.getItem('user'));
			const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));
			const unknown = secretDetails !== null ? secretDetails : user

			if (isEmpty(unknown)) {
				getUser();
			}

			setUser(unknown);
		};

		getUser();
	}, []);

	useOnClickOutside(ref, () => { setIsDropdownOpen(false) });

	return (
		<div className="profile-dropdown" onClick={toggleDropdown} ref={ref}>
			<div className="profile-details">
			{secret ? <i className="fa fa-user-secret" aria-hidden="true" style={{fontSize:'30px', color:'#F46B6B'}}></i> : ''}
				<Avatar src={user.avatar} />
				<If condition={isLoading}>
					<Then>
						<Skeleton width={160} height={15} />
						<Skeleton width={15} height={15} />
					</Then>
					<Else>
						<p className="name">
							{
								[
									user.firstName,
									user.lastName
								].join(' ')
							}
						</p>
						<img height={18} src={chevronDown} alt="chevron-down" />
					</Else>
				</If>
			</div>
			<div className="profile-dropdown-content-container">
				<If condition={isDropdownOpen}>
					<Then>
						<div className="profile-dropdown-content">
							<ul>
								<li>
									<Link to="/dashboard">Dashboard</Link>
								</li>
								<If condition={isUnknown}>
									<Then>
										<li>
											<Link to="/admin/dashboard">Administrator</Link>
										</li>
									</Then>
								</If>
								<li className={secret ? 'disabled-link' : ''}>
									<Link to={secret ? '#' : "/edit-profile"}>Edit profile</Link>
								</li>
								<li className={secret ? 'disabled-link' : ''}>
									<Link to="/changepassword">Change password</Link>
								</li>
								{
									secret ?
									<li>
										<Link onClick={impersonate} to="/">Stop Impersonate</Link>
									</li>
									:
									''
								}
								<li>
									<Link onClick={logout} to="/">Log out</Link>
								</li>
							</ul>
						</div>
					</Then>
				</If>
			</div>
		</div>
	)
};

const mapStateToProps = (state) => ({
	// isAdmin: state.roles?.role?.[0]?.roleAll === 1 ? true : false
	isAdmin: state.roles?.role?.[0]?.roleAll === 1 ? true :  JSON.parse(localStorage.getItem('userRole'))?.[0]?.roleAll === 0 && JSON.parse(localStorage.getItem('userRole'))?.[0]?.permissions.length !== 0 ? true :  false
})

export default React.memo(connect(mapStateToProps)(ProfileDropdown));