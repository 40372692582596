import { get, del, post, put} from '../helpers/api';
import { userService } from '../services/user';

export const tenuresServices = {
    addTenure,
    editTenure,
    getAll,
    getOne,
    _delete,  
};

const basePath = "/api/v1" ;

function addTenure(tenure){
    return post(`${basePath}/tenure`, tenure).then(handleResponse);
}

function editTenure(id, tenure){
  return put(`${basePath}/tenure/${id}`, tenure).then(handleResponse);
}

function  getAll (selectPage, itemPerPage){
     return get(`${basePath}/tenure?page=${selectPage ||  1}&per-page=${itemPerPage || 10}`).then(handleResponse);
}

function _delete(id){
    return del(`${basePath}/tenure/${id}`).then(handleResponse);
}

function getOne(id){
  return get(`${basePath}/tenure/${id}`).then(handleResponse);
}


function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}