import React from 'react';
import { connect } from 'react-redux'	;
import { leaveActions } from '../_core/actions/leave';
// import LinesEllipsis from 'react-lines-ellipsis';
import LeavePageModal from './LeavePageModal';
import { Link } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js'
import ErrorPage from '../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../Components/ERRORPAGE/ButtonHome';
import error from '../_templates/error.png';
import '../_core/styles/alert.sass'
import './../Pagination.sass';
import './LeavePage.sass';
import moment from 'moment';

class LeavesPage extends React.Component {

	constructor(props) {
		super(props);
		//alert Timeout

        this.state = {
            render: true,
            //pagination
            page: 1,
            pages: '',
			goto: '',
			getByIdModalOpen: false,
            id: ''
        }
	}

	componentDidMount(id) {
		var elem = document.querySelector(".sidenav");
		M.Sidenav.init(elem, {
			edge: "left",
			inDuration: 250
		});

		//init page
        var selectPage = this.state.page;
        this.props.dispatch(leaveActions.getAllById(selectPage));
	}

	handleClose(event) {
		event.preventDefault()
		var alertId = document.getElementById("alert")
		alertId.style.display = 'none';
	}

	openGetByIdModal = (id) => {
        this.setState({
            getByIdModalOpen: true,
            id
        })
    }

    closeGetByIdModal = () => {
        this.setState({
            getByIdModalOpen: false,
            id: ''
        })
    }

	//--------------------------------------------> PAGINATION <-----------------------------------------------------------
	checkPage(e, totalpages, currentpage) {
		e.preventDefault();
		var totalPages = totalpages;
		var curPage = currentpage;
		var page = e.target.textContent;

		if (Number(page) === curPage){
			return
		}else if (page >= totalPages) {
			this.setState({
				page: totalPages,
			}, () => {
				var selectPage = this.state.page;
				this.changePage(selectPage);
			})
		} else {
			this.setState({
				page: Number(page),
			}, () => {
				var selectPage = this.state.page;
				this.changePage(selectPage);
			})
		}
	}

	pageNum(totalpages, currentpage) {
		var totalPages = totalpages;
		var curPage = currentpage;
		var pages = [];
		var a = 1;

		for (var i = 1; i <= totalPages; i++) {
			var page = i
			if (page === this.state.page || page === curPage) {
				pages.push(<li key={i} className={this.state.page === page ? "selected" : "btn-page"} id="zero" onClick={(e) => this.checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{page}</span></li>);
			} else {
				if (a <= 4) {
					if (page === this.state.page - 1 || page === this.state.page + 1) {
						pages.push(<li key={i} className={this.state.page === page ? "selected" : "btn-page"} id="zero" onClick={(e) => this.checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{page}</span></li>);
						a++
					} else if (page === this.state.page - 2 || page === this.state.page + 2) {
						pages.push(<li key={i} className={this.state.page === page ? "selected" : "btn-page"} id="zero" onClick={(e) => this.checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{page}</span></li>);
						a++
					} else if (this.state.page === 1 || this.state.page === 2) {
						pages.push(<li key={i} className={this.state.page === page ? "selected" : "btn-page"} id="zero" onClick={(e) => this.checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{page}</span></li>);
						a++
					} else if (page === totalPages - 3 || page === totalPages - 4) {
						pages.push(<li key={i} className={this.state.page === page ? "selected" : "btn-page"} id="zero" onClick={(e) => this.checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{page}</span></li>);
						a++
					}
				}
			}
		}

		return (
			<div className="pages">
				<ul className="pagination">
					{curPage === 1 ? ""
						: <span>
							<li className={curPage === 1 ? "btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="Go to first page" id="zero" onClick={(e) => this.firstPage(e)} style={{ margin: "0px 5px", padding: "0px 10px" }}><i className="tiny material-icons" id="zero" style={{ lineHeight: "0.5", display: "-webkit-inline-box", fontSize: "20px" }}>first_page</i><span style={{ padding: "0 10px" }}>First</span></li>
							<li className={curPage === 1 ? "btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="prev" id="zero" style={{ margin: "0px 5px", padding: "0px 5px" }} onClick={(e) => this.prevPage(e)}><i className="tiny material-icons" id="zero" style={{ lineHeight: "0.5", display: "-webkit-inline-box", fontSize: "20px" }}>chevron_left</i></li>
						</span>
					}
					{pages}
					{curPage === totalPages ? ""
						: <span>
							<li className={curPage === totalPages ? " btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="next" id="zero" style={{ margin: "0px 5px", padding: "0px 5px" }} onClick={(e) => this.nextPage(e, totalPages)}><i className="tiny material-icons" id="zero" style={{ lineHeight: "0.5", display: "-webkit-inline-box", fontSize: "20px" }}>chevron_right</i></li>
							<li className={curPage === totalPages ? " btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="Go to last page" id="zero" style={{ margin: "0px 5px", padding: "0px 10px" }} onClick={(e) => this.lastPage(e, totalPages)}><span style={{ padding: "0 10px" }}>Last</span><i className="tiny material-icons" id="zero" style={{ lineHeight: "0.5", display: "-webkit-inline-box", fontSize: "20px" }}>last_page</i></li>
						</span>
					}
					<span>
						<li className="goText">Go to Page</li>
						<input className="pageNum" type="number" id="zero" min="1" max={totalPages} placeholder={curPage ? curPage : ''} style={{ border: "1px solid #ccc", width: "40px", height: "30px", overflow: "auto", textAlign: "center" }} onChange={(e) => this.getPage(e)} />
						<li className="pageGo text-page" id="zero" onClick={(e) => this.goToPage(e, totalPages, curPage)} style={{ padding: "0px 10px" }}>Go</li>
					</span>
				</ul>
			</div>
		);
	}

	nextPage(e, pages) {
		e.preventDefault();
		var totalPages = pages;
		var setPage = Number(this.state.page) + 1;

		if (setPage >= totalPages) {
			this.setState({
				page: totalPages
			}, () => {
				var selectPage = this.state.page;
				this.changePage(selectPage);
			})
		} else if (this.state.page) {
			this.setState({
				page: setPage
			}, () => {
				var selectPage = this.state.page;
				this.changePage(selectPage);
			})
		}
	}

	prevPage(e) {
		e.preventDefault();
		var setPage = Number(this.state.page) - 1
		if (setPage <= 1) {
			this.setState({
				page: 1
			}, () => {
				var selectPage = this.state.page;
				this.changePage(selectPage);
			})
		} else if (this.state.page) {
			this.setState({
				page: setPage
			}, () => {
				var selectPage = this.state.page;
				this.changePage(selectPage);
			})
		}
	}

	firstPage(e) {
		e.preventDefault();
		this.setState({
			page: 1
		}, () => {
			var selectPage = this.state.page;
			this.changePage(selectPage);
		})
	}

	lastPage(e, pages) {
		e.preventDefault();
		var totalPages = pages
		this.setState({
			page: totalPages
		}, () => {
			var selectPage = this.state.page;
			this.changePage(selectPage);
		})
	}

	getPage(e) {
		var page = e.target.value;
		this.setState({ goto: page });
	}

	goToPage(e, pages, pageNo) {
		e.preventDefault();
		var page = this.state.goto;
		var curPage = pageNo;
		var totalPages = pages;

		if (page === "" || page === null || Number(page) === curPage){
			this.setState({
				page: this.state.page,
				goto: ''
			})
		} else if (page >= totalPages) {
			this.setState({
				page: totalPages,
				goto: ''
			}, () => {
				var selectPage = this.state.page
				this.changePage(selectPage);
			})
		} else if (page <= 1) {
			this.setState({
				page: 1,
				goto: ''
			}, () => {
				var selectPage = this.state.page
				this.changePage(selectPage);
			})
		} else {
			this.setState({
				page: Number(page),
				goto: ''
			}, () => {
				var selectPage = this.state.page
				this.changePage(selectPage);
			})
		}
	}

	changePage(page){
		var selectPage = page;
		this.props.dispatch(leaveActions.getAllById(selectPage));
	}
	//--------------------------------------------> END PAGINATION <-----------------------------------------------------------

	render() {
		const { leaves } = this.props;
		// const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
		return (
			
                                            <div className="col s12 m12 l12 userDetailConLineLeft">
                                                
												<div className="user-leave-management">
													{leaves.error ?
														<div className="container error-div">
															<ErrorPage />
															<div className="text-danger">
																<img className="error" src={error} alt="error" />
																{leaves.error}
															</div>
															<ButtonHome />
														</div> :
														<div>
															<div className="row">
																<div className="col s12 m6 l6">
																	<p className="text-left leavePageTxt1"><b>Your Filings</b></p>
																</div>
																<div className="col s12 m6 l6">
																	<Link to="/user/leave/request" className="leavePageBtn btn right">File for Leave</Link>
																</div>
															</div>
															{leaves.loading ?
																<div className="center">
																	<img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
																</div>
															: leaves.leaves && leaves.leaves.total <= 0 ?
																<center><h5><em>no records found</em></h5></center>
															:
															<div>
															{leaves.leaves &&
															<div>
																
																<div>
																	<table className="responsive-table highlight">
																		<thead className="thead">
																			<tr>
																				<th>Status</th>
																				<th>Type</th>
																				<th>From</th>
																				<th>To</th>
																				<th>Days</th>
																			</tr>
																		</thead>
																		<tbody>
																		{leaves.leaves.items.map((leave, index) =>
																			<tr key={index}>
																				{
																					this.state.id === leave._id ?
																					<LeavePageModal
																						isOpen={this.state.getByIdModalOpen}
																						close={this.closeGetByIdModal}
																						leave={leave}
																					/> : null
																				}
																				<td 
																					onClick={() => this.openGetByIdModal(leave._id)}
																					width="5%" 
																					style={{ textTransform: "capitalize" }} className={leave.status === "on hold" ? "onHold" : leave.status}
																					>
																						{leave.status ? leave.status === "new" ? "in-review" : leave.status : "none"}
																				</td>
																				<td 
																					onClick={() => this.openGetByIdModal(leave._id)}
																					width="10%" 
																					className="" 
																					style={{ textTransform: "capitalize" }}
																					>
																						{leave.leaveType[0] ? leave.leaveType[0].type : 'none'}
																				</td>
																				<td 
																					onClick={() => this.openGetByIdModal(leave._id)}
																					width="10%"
																					>
																						{leave.dates ? moment(leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(',')[0]).format('MMMM DD, YYYY') : "no date"}
																				</td>
																				<td 
																					onClick={() => this.openGetByIdModal(leave._id)}
																					width="10%"
																					>
																						{leave.dates ? moment(leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(/[, ]+/).pop()).format('MMMM DD, YYYY') : 'no date'}
																				</td>
																				<td 
																					onClick={() => this.openGetByIdModal(leave._id)}
																					style={{ width: "70px" }} 
																					className=""
																					>
																						{leave.dates.slice(1, -1).split(',').length}
																				</td>
																			</tr>
																		)}
																		</tbody>
																	</table>
																</div>
																
																<div className="pages leavePagePagination">
																	{/* -----------------------------------------------------> PAGINATION <----------------------------------------------- */}
																	{this.pageNum(leaves.leaves.pages, leaves.leaves.page)}
																	{/* -----------------------------------------------------> END PAGINATION <----------------------------------------------- */}
																</div>
															</div>
															}
															</div>
															}
														</div>
													}
												</div>

                                            </div>
            
		);
	}
}

function mapStateToProps(state) {
	const { leaves } = state;
	const { alert } = state;
	return {
		leaves,
		alert
	};
}


const connectedLeavesPage = connect(mapStateToProps)(LeavesPage);
export { connectedLeavesPage as LeavePage };