import React from 'react';
import cc from 'classcat';
import Modal from 'react-modal';
// import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import teamService from '../_core/services/teams';
import { commendService } from '../_core/services/commend';
import { commendActions } from '../_core/actions/commend';
import { creditActions } from '../_core/actions/credit';
import {creditService} from '../_core/services/credit';
import { postActions } from '../_core/actions/post';
import closebtn from '../_templates/x_square.png';
import crediticon from '../_templates/credit-icon.png';
import avatar from '../_templates/man.png';
import './CommendsTips.scss';
import ReactTooltip from 'react-tooltip';
import sweetAlert from 'sweetalert2';
import { energyActions } from '../_core/actions/energy';

const toast = sweetAlert.mixin({
	toast: true,
	width: 300,
	position: 'center',
	showConfirmButton: false,
	timer: 2000
});
const CommendsTipsModal = (props) => {

	const [commendedUser, setCommendedUser] = React.useState({});
	const [userTeam, setUserTeam] = React.useState("");
	const [showModal, setShowModal] = React.useState(false);
	const [commend, setCommend] = React.useState("");
	const [tip, setTip] = React.useState(0);
	const [isCustomMessage, setIsCustomMessage] = React.useState(false);
	const [anonymousUser, setAnonymousUser] = React.useState(false);
	const [isSubmitted, setIsSubmitted] = React.useState(false);

	const dispatch = useDispatch();

	//setup commends and tips options
	const commendOptions = [
		'for a job well done',
		'for the assist',
		'for inspiring'
	];

	const tips = [50, 100, 200];
	const totalCharAccepted = 400;

	const { currentUser, currentUsertotalCredits, totalEnergy } = props;


	React.useEffect(() => {
		//set profile user details
		let userDetails = props.commendedUser?.details?.[0] || {};

		let user = {
			name: [userDetails?.firstName, userDetails?.lastName].join(" "),
			_id: userDetails?.user_id,
			avatar: userDetails?.avatar
		}

		const getTeam = async () => await teamService.getUserTeam(userDetails?.user_id).then(team => setUserTeam(team?.items[0]?.name));
		getTeam();

		setShowModal(props.isOpen);
		setCommendedUser(user);
		
	}, [props.commendedUser, props.isOpen]);


	React.useEffect(()=> {
		if(props.commends?.postCommendSuccess && isSubmitted) {
			closeModal();
			dispatch(commendActions.getCommendations(commendedUser._id));
			ReactTooltip.rebuild()
		}
	},[props.commends, commendedUser._id, isSubmitted, dispatch]); //eslint-disable-line

	const addCommendText = (e) => {
		setCommend(e?.target?.value);
		setIsCustomMessage(true);
	}

	const addCommend = (text) => {
		setCommend(text);
		setIsCustomMessage(false);
	}

	const addTip = (tipReceived) => {
		setTip(tipReceived);
	}

	const submitCommend = () => {

		if (!commend) {
			return;
		}
		
		
		let draftTip = isCustomMessage && tip ? `Was also tipped ${tip} credits !`  : tip ? `and has received a ${tip} credit tip!` : "";
		let draftCommend = isCustomMessage ? `saying "${commend}".` : commend;
		let draftMention = anonymousUser ? `${commendedUser.name} was commended` : `${currentUser.name} was commended by ${commendedUser.name}`;

		let draftPostContent = `${draftMention} ${draftCommend} ${draftTip}`;
		let draftPostTitle = `${commendedUser.name} was commended`;
		let draftUserTag = anonymousUser ? commendedUser._id : currentUser._id;

		let draftAnonymous = anonymousUser ? 1 : 0;

		let commendPost = {
			tip: tip,
			postContent: draftPostContent,
			postTitle: draftPostTitle,
			postUser: draftUserTag,
			postMention: [commendedUser._id],
			anonymous: draftAnonymous
		}

		const energyInfo = {
			add: true,
			userId: currentUser._id,
			energyType: "challenge",
			value:-3
		}

		if(currentUsertotalCredits <= 0){
			toast.fire({
				type: 'error',
				title: 'Insufficient Credits.'
			});
			closeModal();
		}else if (totalEnergy <= 3 && totalEnergy !== 3){
			toast.fire({
				type: 'error',
				title: 'Insufficient Energy.'
			});
			closeModal();
		}else{
			setIsSubmitted(true);
			dispatch(commendActions.postCommend(commendedUser._id, currentUser._id, commendPost));
			dispatch(postActions.resetPosts());
			dispatch(energyActions.update(energyInfo));
			setTimeout(() => {
				dispatch(postActions.getAllByPage({ page: 1, userId: commendedUser._id }));
			}, 100)
		}


		

		
	}

	const closeModal = () => {
		props.setModal(false);
		setShowModal(false);
		setCommend("");
		setTip(0);
		setIsCustomMessage(false);
		setAnonymousUser(false);
		setIsSubmitted(false);
	}

	const ProfileImage = (img) => <img className="cmds-profile-img" alt="profile" src={img || avatar} onError={e => e.target.src = avatar} />
	const AnonymousUserCheckbox = <div className={anonymousUser ? "cmd-chkbx-custom chkbx-true" : "cmd-chkbx-custom"} onClick={() => setAnonymousUser(!anonymousUser)}><div><div></div></div></div>

	return (
		<Modal
			isOpen={showModal}
			ariaHideApp={true}
			toggle={closeModal}
			onRequestClose={closeModal}
			shouldCloseOnOverlayClick={true}
			className='cmds-modal'
		>
			<div className="cmds-mdl-wrapper">
				<div className="cmds-mdl-header">{/*header*/}
					<h6>Commend</h6>
					<div><img alt="close_cmd" src={closebtn} onClick={closeModal} /></div>
				</div>

				<div className="cmds-mdl-content">{/*content*/}
					<div>{/*commendedUser*/}
						<div className="cmds-mdl-receiver">{/*Profile*/}
							<div>
								{ProfileImage(commendedUser?.avatar)}
								<div></div>
							</div>
							<div>
								<p>{commendedUser?.name}</p>
								<p>{userTeam}</p>
							</div>
						</div>
						<div className="cmds-mdl-options">{/*commend btns*/}
							{commendOptions.map((text, index) =>
								<div key={index} onClick={() => addCommend(text)} className={commend === text ? "cmd-selected" : ""}>
									<p>{text[0].toUpperCase() + text.substr(1, text.length)}</p>
								</div>
							)}
						</div>
						<div className="cmds-mdl-custom">{/*custom message*/}
							<label>Custom Message</label>
							<textarea placeholder="Type anything" maxLength={totalCharAccepted} onChange={addCommendText} value={isCustomMessage ? commend : ""} />
							<div className="cmds-custom-text-count">{isCustomMessage ? totalCharAccepted - commend?.length : totalCharAccepted}</div>
						</div>
						<div className="cmds-mdl-tip">{/*tip*/}
							<p>Give Tip</p>
							<div>
								{tips.map((tipAmount, index) =>

									currentUsertotalCredits >= tipAmount  ? 

										<div key={index} className={tipAmount === tip ? "cmd-tip-selected" : ""} onClick={() => addTip(tipAmount)}>
											<span className="cmds-credit-icon"></span>
											<span>{tipAmount}</span>
										</div>
									:
									(currentUsertotalCredits >= 0  && tipAmount === 50)  ? 
									<div key={index} className={tipAmount === tip ? "cmd-tip-selected" : ""} onClick={() => addTip(tipAmount)}>
										<span className="cmds-credit-icon"></span>
										<span>{tipAmount}</span>
									</div>
									: 
									<div key={index} className="disbaledTip" data-tip="" data-for="disable" currentitem="false">
										<span className="cmds-credit-icon"></span>
										<span>{tipAmount}</span>
										<ReactTooltip
											id='disable'
											type="light"

										>
											{"You do not have sufficient credits to send a tip"}
										</ReactTooltip>
									</div>
								)}
							</div>
						</div>
						<div className="cmds-mdl-anonymous">{/*anonymous checkbox*/}
							{AnonymousUserCheckbox}
							<span>Anonymous to others</span>
						</div>
					</div>
					<div>{/*current user*/}
						<div>
							{ProfileImage(currentUser?.avatar)}
							<div>
								<p>{currentUser?.name}</p>
								<p>{[currentUser?.position, currentUser?.department].join(" | ")}</p>
							</div>
						</div>
						<div>
							<p>Current points :</p>
							<div>
								<img alt="credits" src={crediticon} />
								<span>{currentUsertotalCredits?.toLocaleString()}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="cmds-mdl-footer">{/*buttons*/}
					<button onClick={closeModal}>Back</button>
					<button className={!commend ? "cmd-mdl-disabled-btn" : ""} onClick={() => submitCommend()}>Commend</button>
				</div>
			</div>
		</Modal>
	)
}

const CommendsTips = (props) => {

	const [totalCommendations, setTotalCommendations] = React.useState(0);
	const [myTotalCredits, setMyTotalCredits] = React.useState({});
	const [alreadyCommended, setAlreadyCommended] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(false);
	const [openModal, setOpenModal] = React.useState(false);
	const dispatch = useDispatch();

	//get current user details
	let currentUserDetails = localStorage.getItem('user');
	let currentUserParsed = JSON.parse(currentUserDetails);

	let currentUser = {
		name: [currentUserParsed?.firstName, currentUserParsed?.lastName].join(" "),
		_id: currentUserParsed?.user_id,
		avatar: currentUserParsed?.avatar,
		position: currentUserParsed?.position,
		department: currentUserParsed?.department,
	}

	React.useEffect(() => {
		let commendedUserId = props.commendedUser?._id;


		dispatch(commendActions.getCommendations(commendedUserId));
		dispatch(commendActions.getUserCommends(commendedUserId));
		dispatch(creditActions.getTotalCredits(currentUser._id));
		dispatch(energyActions.getAll(currentUser._id));
	}, [props.commendedUser, currentUser._id, dispatch]);

	const setUserCredit = async () => {
		try {
		  const res = await creditService.getTotalCredits(currentUser._id);
		  res.success.forEach((item, i) => {
			setMyTotalCredits(state => ({...state, item}));
		 });
		} catch (err) {
		  console.error(err);
		}
	  };

	const setCommendations = async () => {
		let commendedUserId = props.commendedUser?._id;
		try {
		  const res = await commendService.getCommendations(commendedUserId);
		  const total = res.success
		  setTotalCommendations(state => ({...state, total}));
		} catch (err) {
		  console.error(err);
		}
	};
	  
	  // Initial load
	  React.useEffect(() => {
		setUserCredit();
		setCommendations();
		// eslint-disable-next-line
	  }, []);

	  const sendCheckingFrequency = async () => {
		let commendedUserId = props.commendedUser?._id;
		let commedFrequency = process.env.REACT_APP_COMMEND_SAME_PERSON_PER_DAY
		try {
		  const res = await commendService.commendedFrequency(currentUser._id,commendedUserId,commedFrequency);

			if(res.success){
				setAlreadyCommended(false);
				setUserCredit()
			}else{
				setAlreadyCommended(true)
				setCommendations()
				setTimeout(function(){
					setErrorMessage(true)
				}, 
				1500);

			}
		} catch (err) {
		  console.log(err);
		}
		
	  };


	React.useEffect(() => {

		// const commendsList = props.commends?.userCommends || [];
		// const getTodaysCommends = commendsList.length > 0 ?
		// 	commendsList.filter(cmd => moment(cmd.createdAt).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'))
		// 	: [];
		
		// let getSelectedUserCommends = getTodaysCommends?.filter(commend => commend?.commendee_details[0]?.user_id === commendedUserId);
		// let getCurrentUserCommend = getSelectedUserCommends?.filter(commend => commend?.commender_details[0]?.user_id === currentUser._id);
		
		// if (getCurrentUserCommend?.length > 0) {
		// 	setAlreadyCommended(true);
		// }
		//setTotalCommendations(props.commends?.totalCommendations || 0);
		//setMyTotalCredits(props.credits?.totalCredit || 0);
		sendCheckingFrequency();
		// eslint-disable-next-line
	}, [props,props.commends, props.credits, currentUser._id, props.commendedUser]);


	return (
		<div className="cmds widget">
			<div className={cc('header')}>
				<h6>Commend</h6>
			</div>
			{!props.commends?.loadingUserCommends &&
			<div
				className={cc([
					'card',
					'z-depth-0',
					'body',
					'cmds-wrapper'
				])}
			>
				<div>
					<h6>Total Commends</h6>
					<h6>{totalCommendations.total?.toLocaleString()}</h6>
				</div>
					{errorMessage ?
						<div className='erroMessage'>
							<p>You have already commended {props.commendedUser.details[0].firstName} today, You can commend again tomorrow.</p>
						</div> :
						<></>
					}
					<button className={alreadyCommended ? "has-commended" : ""} onClick={() => alreadyCommended ? null : setOpenModal(true)}>
						COMMEND
					</button>
			</div>
			}
			<CommendsTipsModal
				{...props}
				currentUser={currentUser}
				currentUsertotalCredits={myTotalCredits.item?.credits}
				isOpen={openModal}
				setModal={retVal => setOpenModal(retVal)}
				totalEnergy={props.energy.energy?.energy[0]?.value}
			/>
		</div>
	);
}

const mapStateToProps = ({ commends, credits, energy }) => ({
	commends,
	credits,
	energy
});

export default connect(mapStateToProps)(React.memo(CommendsTips));