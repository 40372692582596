import React from 'react';
import { connect, useDispatch } from 'react-redux';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { rewardActions } from "../_core/actions/reward";
import Table from '../Table/Table';
import './Redemption.scss';
import HeaderTabsSearch from "../Components/HeaderTabsSearch";

const StatusPill = () => {
    return (
        <div className={"usr-tbl-pill-success"}><p>redeemed</p></div>
    )
}

const FilterSection = () => { //TODO: create separate component
    return (
        <div className={"usr-pg-filter"}>{/*filter button */}
            <div></div>
            <div className={"usr-pg-filter-btn"}></div>
        </div>
    )
}

const RedemptionPage = (props) => {

    const [allRewards, setAllRewards] = React.useState([]);
    const [currentUserRewards, setCurrentUserRewards] = React.useState([]);
    const [userRewards, setUserRewards] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [numOfItems, setNumOfItems] = React.useState(10);
    const dispatch = useDispatch();

    const headers = [
        { name: "Title", withOrder: false },
        { name: "Credits", withOrder: true },
        { name: "Date", withOrder: true },
        { name: "Status", withOrder: false },
    ];

    const getUser = JSON.parse(localStorage.getItem('userCredentials'));
    const secret = JSON.parse(localStorage.getItem("secretDetails"));
    const getCurrentUserId = secret ? secret.user_id : getUser.user._id;

    const sort = React.useCallback((index, order) => {
        setUserRewards(rewards => {
            const orders = ['asc', 'desc'];

            return (
                orderBy(rewards, (reward) => (
                    reward.cells[index].value
                ), [orders[order]])
            );
        })
    }, [setUserRewards]);

    const dataRowContent = (rewardsList, userRewardsList) => {
        let rowItems = userRewardsList.map(userItem => {
            let record = { ...userItem, title: "", value: "" };
            record.title = rewardsList.filter(item => item._id === userItem.reward_id)[0]?.title || "";
            record.value = rewardsList.filter(item => item._id === userItem.reward_id)[0]?.value || 0;
            return record
        });

        return (
            rowItems?.map(item => {
                const formattedData = {
                    title: {
                        _id: "title",
                        element: <p>{item.title}</p>,
                        value: item.title
                    },
                    credits: {
                        _id: "credits",
                        element: <p>{item.value.toLocaleString()}</p>,
                        value: item.value
                    },
                    date: {
                        _id: "date",
                        element: <p>{moment(item.redeem_date).utc().format('MM/DD/YYYY')}</p>,
                        value: moment(item.redeem_date).utc().format('MM/DD/YYYY').toString()
                    },
                    status: {
                        _id: "status",
                        element: <StatusPill />,
                        value: "redeemed"
                    },
                }

                return {
                    _id: item._id,
                    cells: Object.values(formattedData)
                };
            }) || []
        )
    }

    React.useEffect(() => {

        if (allRewards.length === 0 && props.rewardItems?.length > 0) {
            setAllRewards(props.rewardItems);
        }

        if (allRewards.length > 0 && currentUserRewards !== props.userRewards?.items && props.userRewards?.items?.length > 0) {
            let draftUserRewardsItems = props.userRewards?.items;
            let draftRewardsList = allRewards;

            setCurrentUserRewards(props.userRewards?.items);
            setUserRewards(dataRowContent(draftRewardsList, draftUserRewardsItems));
        }

    }, [allRewards, userRewards, currentUserRewards, props]);

    React.useEffect(() => {
        dispatch(rewardActions.getAllByUser(getCurrentUserId, page, numOfItems));
        dispatch(rewardActions.getAll(999999,1,'',''));
        //eslint-disable-next-line
    }, [page, numOfItems, dispatch]); 


    return (
        <div className={"redemption-container"}>{/*main*/}
            <HeaderTabsSearch 
                currentPath={window.location.pathname}
                tabs={[
                    {name: "Redemption", path: "/rewards/redemption"}
                ]}
                // searchValue={}
                // searchOnChange={}
                searchDisabled={true}
            />
            <div className={"redemption-content"}>{/*body*/}
                <FilterSection />
                <Table
                    headers={headers}
                    itemsPerPage={numOfItems}
                    loading={props.userRewards?.loading}
                    goToPage={page => { setPage(page) }}
                    noDataComponent={
                        <>
                            <p className="no-data-helper">You don't have any redemptions yet</p>
                        </>
                    }
                    onHeaderClick={sort}
                    //onRowClick={}
                    page={props.userRewards?.page}
                    pages={props.userRewards?.pages || 1}
                    rows={userRewards}
                    setItemsPerPage={setNumOfItems}
                    columns={4}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userRewards: {
        items: state.reward?.userRewards?.items || [],
        loading: state.reward?.userRewards?.loading,
        page: state.reward?.userRewards?.page || 1,
        pages: state.reward?.userRewards?.pages || 1,
    },
    rewardItems: state.reward?.items || []

});

export default React.memo(connect(mapStateToProps)(RedemptionPage));