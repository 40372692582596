import React from "react";
import './HeaderTabsSearch.scss';
import { history } from "../_core/helpers/history";

const HeaderTabsSearch = ({tabs, searchValue, searchOnChange, searchDisabled = false}) => {
    const pathname = window.location.pathname;
    
    const changePage = React.useCallback((page)=>{
        history.push(page);
    },[])

    return (
        <div className={"usr-pg-header"}>{/*header-line */}
            <div>
                <div className={"usr-pg-tab"}> {/*tabs*/}
                    {tabs?.map((tab, index)=>
                    <div key={index} className={"usr-pg-opt"} onClick={()=> changePage(tab.path)}>{/*option*/}
                        <p className={ tab.path === pathname ? "usr-pg-tab-selected" : ""}>{tab.name}</p>
                        { pathname === tab.path &&
                            <div className={"usr-pg-opt-highlight"}></div>
                        }
                    </div>
                    )}
                </div>
                <div className={"usr-pg-search-bar"}>{/*search bar*/}
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchValue}
                        onChange={searchOnChange}
                        autoComplete="off"
                        disabled={searchDisabled}
                    />
                </div>
            </div>
            <hr className="usr-pg-header-divider" />
        </div>
    )
}

export default HeaderTabsSearch