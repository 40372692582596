import { leaveCreditConstants } from '../constants/leaveCredit';
import { leaveCreditService } from '../services/leaveCredit';
import { alertActions } from './alert';
import { history } from '../helpers/history';
import Swal from 'sweetalert2';

export const leaveCreditActions = {
    getAll,
    addleaveCredit,
    update,
    getUser,
    editCreditStat
};

function getAll() {
    return dispatch => {
        dispatch(request());

        leaveCreditService.getAll()
            .then(
                leaveCredits => dispatch(success(leaveCredits)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: leaveCreditConstants.GETALL_REQUEST } }
    function success(leaveCredits) { return { type: leaveCreditConstants.GETALL_SUCCESS, leaveCredits } }
    function failure(error) { return { type: leaveCreditConstants.GETALL_FAILURE, error } }
}



function addleaveCredit(leaveCredit){
    return dispatch => {
        dispatch(request(leaveCredit));
        
        leaveCreditService.addleaveCredit(leaveCredit)
            .then(
                leaveCredits => {
                    const Toast = Swal.mixin({
                        toast: true,
                        width: 300,
                        height: 500,
                        background: '#D4EDDA',
                        position: 'center',
                        showConfirmButton: false,
                        timer: 5000
                    });
                    
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#0A551E">New Leave Credit has been Added</span>'
                    })
                    dispatch(success(leaveCredits));
                    dispatch(alertActions.success(''));
                    history.push(`/admin/leave-credits-overview?page=1`);
                    // dispatch(alertActions.success('New leave credit has been created'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };   

    function request(leaveCredit) { return { type: leaveCreditConstants.POST_REQUEST, leaveCredit } }
    function success(leaveCredit) { return { type: leaveCreditConstants.POST_SUCCESS, leaveCredit } }
    function failure(error) { return { type: leaveCreditConstants.POST_FAILURE, error} }
}

function update(leaveCredit) {
    return dispatch => {
        dispatch(request(leaveCredit));

        leaveCreditService.update(leaveCredit)
            .then(
                leaveCredit => { 
                    dispatch(success(leaveCredit));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(leaveCredit) { return { type: leaveCreditConstants.UPDATE_REQUEST, leaveCredit } }
    function success(leaveCredit) { return { type: leaveCreditConstants.UPDATE_SUCCESS, leaveCredit } }
    function failure(error) { return { type: leaveCreditConstants.UPDATE_FAILURE, error } }
}

function getUser(id) {
    return dispatch => {
        dispatch(request(id));

        leaveCreditService.getUser(id)
            .then(
                leaveCredits => dispatch(success(leaveCredits.user[0]?.leave_types)),
                // leaveCredits => dispatch(success(leaveCredits))
                
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: leaveCreditConstants.GETUSER_CREDIT_REQUEST, id } }
    function success(leaveCredits) { return { type: leaveCreditConstants.GETUSER_CREDIT_SUCCESS, leaveCredits } }
    function failure(error) { return { type: leaveCreditConstants.GETUSER_CREDIT_FAILURE, error } }
}

function editCreditStat(leaveCredit) {
    return dispatch => {
        dispatch(request(leaveCredit));
        leaveCreditService.editCreditStatus(leaveCredit)
            .then(
                leaveCredit => { 
                    // console.log(leaveCredit)
                    dispatch(success(leaveCredit));
                    //  setTimeout(function(){window.location.reload(1);}, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(leaveCredit) { return { type: leaveCreditConstants.UPDATE_REQUEST, leaveCredit } }
    function success(leaveCredit) { return { type: leaveCreditConstants.UPDATE_SUCCESS, leaveCredit } }
    function failure(error) { return { type: leaveCreditConstants.UPDATE_FAILURE, error } }
}