import moment from "moment";
import Swal from "sweetalert2";
import "./RequestPopup.scss";

export const ConfirmationModal = (user = "", refCode = "", dates = [], description = "", descriptionLabel = "Description") => {
    const content = `<div class='first-column'><div class='inline'><p class='highlights'>${user}</p></div>
    <div class='inline'> <p class='inline-text title-swal'>Reference Code:</p><p class='inline-text highlights'>${refCode}</p></div>
    <div class='inline floatRight'> <p class='inline-text title-swal'>Date:</p><p class='inline-text highlights'>${dates.length > 1 ? moment(dates[0]).utc().format("MMMM DD, YYYY") + " - "+ moment(dates[dates.length - 1]).utc().format("MMMM DD, YYYY") : dates.length > 0 ? moment(dates[0]).format("MMMM DD, YYYY") : ""}</p></div></div>
    ${description ? "<div class='floating-div'><textarea class='floating-input floating-textarea' style='resize:none' readonly>"+description+"</textarea><label class='floating-text'>"+ descriptionLabel +"</label></div>" : ""}`;
  
    return Swal.fire({
      title: "<p class='swal-title-modal'> Are you sure? </p>",
      html: content,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#2A3143",
      cancelButtonColor: "#2A3143",
      customClass: {
        header: 'header-class',
        container: 'container-class',
        popup: 'popup-class',
        actions: 'actions-class',
        cancelButton: 'cancel-button-class',
        confirmButton: 'confirm-button-class',
      },
      reverseButtons:true,
      cancelButtonText: "CLOSE",
      confirmButtonText:"UPDATE",
      background: "#2E3549",
    });
  };