import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { useParams, useLocation } from "react-router-dom";
import Select, { components } from "react-select";
import DocumentTitle from "react-document-title";
import cc from "classcat";
import BodyBackgroundColor from "react-body-backgroundcolor";
import {
  PageWrapper,
  ContentWrapper,
  LabelCustom,
  SaveAndCancelButton,
} from "../../Components/PageWrapper/ContentWrapper";
// import { checkSideNav } from "../../Components/checkSideMenu";
// import { DynamicHeaderContent} from "../../Components/PageWrapper/DynamicWrapper";
import './settingsDark.scss';
import ImageUploadModal from './ImageUploadModal';
import { CustomInputText, CustomTextAreaEngage,} from "../../Components/FloatingTextBox/TextArea";
import avatarIcon from '../../../_templates/settings-profile.png';
import coverIcon from '../../../_templates/settings-cover.png';
import { history } from "../../../_core/helpers/history";
import { settingsActions } from '../../../_core/actions/settings';
import { settingsService } from "../../../_core/services/settings";
import { roleService } from "../../../_core/services/role";
import { userService } from "../../../_core/services/user";

import { UsernameCreator } from '../../../_core/helpers/usernameCreator';

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

const PhotoBorder = (props) => {
  return (
      <div className="dashed-border">
          <div><div><div><div>{props.children}</div></div></div></div>
      </div>
  )
}

const mapStateToProps = ({ users, badges, reward, settings}) => ({
    users,
    badges,
    reward,
    settings
});


const SettingsAddUser = connect(mapStateToProps)((props) => {        

  const [isProfileImage, setIsProfileImage] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [avatarSrc, setAvatarSrc] = React.useState(null);
  const [coverSrc, setCoverSrc] = React.useState(null);
  //onst [avatarUpload, setAvatarUpload] = React.useState(null);
  const [coverUpload, setCoverUpload] = React.useState(null);
  const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
  // const [count, setCount] = React.useState(0);
  const [InputError, setInputError] = React.useState(false);// eslint-disable-line
  const dispatch = useDispatch();

  const { pageId } = useParams();

  const location = useLocation();
  const { avatar } = location.state;

  // Now you can use newSettingsData in your component
  console.log(location);
  console.log(avatar)

  const data = localStorage.getItem('newSettingsData');
  //const dataAvatar = localStorage.getItem('avatarUpload');
//   const dataCover = localStorage.getItem('coverImage');
const format = JSON.parse(data)
  //console.log(JSON.parse(dataAvatar))
//   const storedAvatarUpload = localStorage.getItem('avatarUpload');
//   let avatarUploads = null;
  
//   if (storedAvatarUpload) {
//       try {
//           const data = JSON.parse(storedAvatarUpload);
//           if (Array.isArray(data)) {
//               avatarUploads = new FormData();
//               data.forEach(([key, value]) => {
//                   avatarUploads.append(key, value);
//               });
//           } else {
//               throw new Error('Invalid data format');
//           }
//       } catch (error) {
//           console.error('Error parsing stored data:', error);
//       }
//   } else {
//       console.error('No stored data found');
//   }

const avatarUpload = new FormData();
Object.entries(location.state.avatar).forEach(([key, value]) => {
    avatarUpload.append(key, value);
});

console.log(avatarUpload)
  const [role, setRoles] = React.useState({
        roleName: 'Admin',
        roleSlug: 'admin',
        roleAll: 1,
        organization: format.org_slug
  })

    const [userAdmin, setUsers] = React.useState({
        email:"",
        password:"",
        organization: format.org_slug,
        praggerUsername:"",
        role: "",
        pragmacore: 0,
        firstName:"",
        lastName:"",
        address1:""
    })
 


// React.useEffect(() => {
//     const getUsers = async () => await userService.getAll(1, 1000000000);

// 		getUsers().then(response => {
//             console.log(response.items)
// 			// const usersList = response?.total > 0 ? response?.items : [];
// 			// const getUsernamesList = usersList?.filter(u => //filter usernamesList
// 			// 	u._id !== this.state.user._id  //filter out selected user id
// 			// 	&& this.state.user._id // filter out no ids
// 			// 	&& u.praggerUsername?.length > 0 //filter out no praggerUsername
// 			// 	)?.map(user => user.praggerUsername);

// 			// this.setState({
// 			// 	usernamesList: getUsernamesList
// 			// })
// 		}).catch(error => console.log(error));
//     //eslint-disable-next-line
//   }, [props, pageId]);



  const handleChangeInputName = (e) => {
    const { name, value } = e.target;

    setRoles({
      ...role,
      [name]: value,
      // org_slug: value.replace(/\s+/g,'-').toLowerCase()
      roleSlug: value.replace(/[&\/\\#,+()$~%.'":*?<>{}^!@_`=|;\[\]]/g,'').toLowerCase().replace(/\s/g,'-')//eslint-disable-line
      
    });

  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setUsers({
        ...userAdmin,
        [name]: value,
      });
      

  }

  const toggleModal = (img) => {
    setIsProfileImage(img === 'profile' ? true : false);
    setOpenModal(!openModal);
  }

  const draftImageUpload = (src, file) => {

    console.log(src)
    // if (isProfileImage) {
    //     setAvatarSrc(src);
    //     setAvatarUpload(file);

    // } else {
    //     setCoverSrc(src);
    //     setCoverUpload(file);
    // }
  }

  const cancelAnnouncement = () => {
    history.goBack()
    setInputError(false);
  }

  const submit = () => {

    if( !userAdmin.firstName ||
        !userAdmin.lastName ||
        !userAdmin.email ||
        !userAdmin.password ||
        !userAdmin.praggerUsername){
      setInputError(true);
    }else {

        roleService.roleAdd(role).then(res=>{
            if(res.success){
    
                let userAdminReg = {
                    email:userAdmin.email,
                    password: userAdmin.password,
                    organization: format.org_slug,
                    praggerUsername: userAdmin.praggerUsername,
                    role: res._id,
                    pragmacore: 0,
                    firstName: userAdmin.firstName,
                    lastName: userAdmin.lastName,
                    address1: userAdmin.address1
                  }
    
                
    
                userService.register(userAdminReg).then(response=>{
                    console.log(response)

                    let organizationD = {
                        org_name: format.org_name,
                        org_slug: format.org_slug,
                        org_description: format.org_description,
                        org_address: format.org_address,
                        default: format.default,
                        user_id: response._id
                    }

                    let avatarOrg = format.org_avatar
                    let coverOrg = format.org_cover

                    dispatch(settingsActions.create(organizationD,avatarUpload));
                    // setTimeout(() => {
                    //     history.goBack()    
                    // }, 1500)
                }).catch(error=>{
                    console.log(error)
                })
            }
    
            console.log(res._id)
        }).catch(error=>{
            console.log(error)
        });

        // let newsettingsData = {
        //     org_name: settingsData.org_name,
        //     org_slug: settingsData.org_slug,
        //     org_description: settingsData.org_description,
        //     org_address: settingsData.org_address,
        //     default: 0
        //   }

        // if(isAdd !== true){
        //     dispatch(settingsActions.update(pageId,newsettingsData,avatarUpload,coverUpload));
        //     setTimeout(() => {
        //       history.goBack()    
        //   }, 1500)
        // }else{

        //     dispatch(settingsActions.create(newsettingsData,avatarUpload,coverUpload));
        //       setTimeout(() => {
        //         history.goBack()    
        //     }, 1500)
        // }


    }
  }

  console.log(role)
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
  return (
      <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused} className="menu">
          {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
      )}
      </ValueContainer>
  );
  };
  const customStyles2 = {
    control: (base, state) => (InputError ? {
        ...base,
        background: "#2e3648",
        height:"40px",
        // match with the menu
        borderRadius: '5px',
        marginBottom:"15px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#323a4f" : "#f14646",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          // Overwrittes the different states of border
          borderColor: state.isFocused ? "#556ee6" : "#323A4F",
        },
      }:{
        ...base,
        background: "#2e3648",
        height:"40px",
        borderRadius: '5px',
        marginBottom:"20px",
        borderColor: state.isFocused ? "#323a4f" : "#323a4f",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? "#556ee6" : "#323A4F",
        },
      }),
    container: (provided, state) => ({
        ...provided,
        marginTop: 0,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        marginTop: '-15px',
      }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: "#9BA3CA",
        "&:hover": {
            color: state.isFocused ? "#556ee6" : "#556ee6",
          }, 
    }),
    option: (base, state) => ({
        ...base,
        color: "#9ba2ce",
        backgroundColor: state.isSelected ? "#32394A" : "#2e3648",
        ":hover": {
          backgroundColor: state.isSelected ? "#2e3648" : "#32394A",
        },
        zIndex: "999",
      }),

    menuList: (base) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    menu: (provided) => ({
      ...provided,
      height: "auto",
      maxHeight: "208px",
      position: "absolute",
      display: "block",
      background: "#2E3648",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible"
    }),
    input: (provided, state) => ({
        ...provided,
        height: 35,
        color: '#9BA3CA',
        lineHeight: state.isFocused ? 35 : 0,
        transition:  "top 0.1s, font-size 0.1s"
    }),
    placeholder: (provided, state) => (InputError ? {
        ...provided,
        position: "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
        transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
        background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
        color: state.hasValue ? "#9BA3CA" :"#f14646",
    }:{
        ...provided,
        position: "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
        transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
        background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
        color: "#9BA3CA",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: ' #9BA3CA',
        fontSize:"14px",
        fontWeight:'normal',
        top:'35%'
      }),
  };

  const statusOptions = [
    { value: 1, label: 'Yes'},
    { value: 0, label: 'No'}
];


  return (
    
          <ContentWrapper>
            <LabelCustom className="main-label">
              Create User Admin Account
            </LabelCustom>

            <CustomInputText
              label={"FirstName"}
              name="firstName"
              value={userAdmin.firstName}
              inputOnChange={handleChangeInput}
              isError={!userAdmin.firstName && InputError}
            />
            <CustomInputText
              label={"LastName"}
              name="lastName"
              value={userAdmin.lastName}
              inputOnChange={handleChangeInput}
              isError={!userAdmin.lastName && InputError}
            />
            <CustomInputText
              label={"Email"}
              name="email"
              value={userAdmin.email}
              inputOnChange={handleChangeInput}
              isError={!userAdmin.lastName && InputError}
            />
            <CustomInputText
              label={"Password"}
              name="password"
              value={userAdmin.password}
              inputOnChange={handleChangeInput}
              isError={!userAdmin.password && InputError}
            />
            <CustomInputText
              label={"Pragger Username"}
              name="praggerUsername"
              value={userAdmin.praggerUsername}
              inputOnChange={handleChangeInput}
              isError={!userAdmin.praggerUsername && InputError}
            />
            {/* <div className="test">
              <div className="titleSwitch-edit">
                <p className="switch-one">{settingsData.org_slug ? settingsData.org_slug : "Organization Slug"} </p>
              </div>
            </div> */}
            {/* <CustomInputText
              label={"Role Slug"}
              name="roleSlug"
              value={role.roleSlug}
              readOnly={true}
              isError={!role.roleSlug && InputError}
            /> */}

            {/* <Select
                //value={LevelOptions.find(option => option.value === quest.level || '' )}
                //onChange={(statusValue)=> {handleSelect({target: { name:'level', value: statusValue === null ? '' : statusValue.value }})}}
                components={{
                    ValueContainer: CustomValueContainer
                }}
                captureMenuScroll={false}
                placeholder="Role All"
                styles={customStyles2}
                options={statusOptions}
                openMenuOnClick={false}
                isSearchable={false}
                menuPlacement="bottom"
                menuPortalTarget={document.querySelector('body')} 
                hideSelectedOptions={false}
                isClearable={true}
                maxMenuHeight={200}
                className="basic-single"
                classNamePrefix="select"
                name="level"
            /> */}

            <SaveAndCancelButton
                cancelFn={() => cancelAnnouncement()}
                saveFn={() => submit()}
                submitLabel="SAVE"
            />
          </ContentWrapper>
    
  )
})

export default SettingsAddUser;