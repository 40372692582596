import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import M from 'materialize-css/dist/js/materialize.min.js'
import '../RolesPage/role.sass';
import {checkSideNav} from '../Components/checkSideMenu'; //adjust component width if admin sidenav is open

class AssignRolePage extends React.Component {
    componentDidMount() {

        var elem = document.querySelector(".sidenav");
        M.Sidenav.init(elem, {
            edge: "left",
            inDuration: 250
        });

        var select = document.querySelectorAll('select');
        M.FormSelect.init(select);
    }

    render() {
        return (
            <div className={"assign-role col s12 m12 l12" + checkSideNav()}>
                {/* <SideMenu /> */}
                <div className="container">
                    <div className="col s12 m8 offset-m2 l6 offset-l3">
                        <div className="card">
                            <div className="card-content black-text">
                                <span className="card-title center-align">ASSIGN ROLE</span>
                                <div className="row">
                                    <form className="col s12 m12 l12">
                                        <div className="input-field col s12 m12 l12">
                                            <input id="user_id" type="text" className="validate" />
                                            <label htmlFor="user_id">User ID</label>
                                        </div>
                                        <div className="input-field col s12 m12 l12">
                                            <input id="user_name" type="text" className="validate" />
                                            <label htmlFor="user_name">User Name</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <select multiple>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>
                                            </select>
                                            <label>Role</label>
                                        </div>
                                    </form>
                                    <div className="input-field center-align">
                                        <button className="waves-effect waves-light btn btn-save">SAVE</button>
                                        <Link to="/role" className="btn btn-link btn-cancel">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

function mapStateToProps(state) {
    return {};
}


const connectedAssignRolePage = connect(mapStateToProps)(AssignRolePage);
export { connectedAssignRolePage as AssignRolePage };
