import { userConstants } from '../constants/user';
import { peopleService } from '../services/people';
//import { alertActions } from './alert';
import { history } from '../helpers/history';

export const peopleActions = {
    getById,
};

function getById(id) {
    return dispatch => {
        dispatch(request(id));

        peopleService.getById(id)
            .then(
                user => {
                    dispatch(success(user));
                    // localStorage.setItem('selectedUser', JSON.stringify(user))
                    // history.push('/peoples/view/'+ user.user[0].details[0].firstName + user.user[0].details[0].lastName);
                    history.push('/peoples/view')
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GET_REQUEST } }
    function success(user) { return { type: userConstants.GET_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_FAILURE, error } }
}