import React from "react";

import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { useParams } from "react-router-dom";
import { If, Else, Then,  } from 'react-if';

import moment from 'moment';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import FileViewer from 'react-file-viewer';

import emptyIcon from '../../../_templates/empty.png';
import avatar from '../../../_templates/man.png'
import calendar from '../../../_templates/dropdown_chevrondown.png'
import download from '../../../_templates/dark/download.png';
import close from '../../../_templates/dark/off_outline_close.png';

import { history } from "../../../_core/helpers/history";
import { userActions } from "../../../_core/actions/user";
import { questService } from "../../../_core/services/quest";
import { userChallengeActions } from "../../../_core/actions/userChallenge";

import { DynamicEngagementAction } from "../../Components/tableActions/engagementActions";
import { DynamicTableAndPaginationWrapper, EngageSearchEntriesWrapper } from "../../Components/PageWrapper/DynamicWrapper";

import "./Quests.scss";
import "react-datepicker/dist/react-datepicker.css";


export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;
export const SearchIconFindUser = styled.img`
  margin-bottom: -30px;
  margin-left: 15px;
  display: flex;
  position: relative;
  z-index: 1;
  height: 15px;
  width: 15px;
  object-fit: contain;
`;

export const FindUserWrapper = styled.div`
  > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
    margin-left: 15px;
  }
`;

export const GridContainer = styled.div`
  ${(props) =>
    props.isShowUserDetails === false
      ? css`
          margin-top: 20px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;

          // > div > label {
          //   margin-top: 10px;
          // }

        
        `
      : css`
          display: block;
        `}

  > div > label.label__questionnaires, > div > label.label__tenures {
    display: flex;
    float: left;
    top: 10px;
    position: relative;
    z-index: 1;
    margin-left: 25px;
    color: #9ba2ce;
  }

  > div > label.Error__message {
    margin-left: 10px;
  }

  > div > label.label__questionnaires {
    background: #2a3143;
    width: 72px;
    margin-top: 5px;
   
  }
  > div > label.label__tenures {
    background: #2a3143;
    width: 40px;
    margin-top: 5px;
  }

  > div > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
  }

  > div > div.error_date {
    border: 1px solid #f14646;
    border-radius: 3px;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
  margin-top: 20px;

    > img.admin_calendar_icon1 {
        display: flex;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
    }
    > img.admin_calendar_icon2 {
        display: flex;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
    }
  
    .floating-label {
        display: inline-block;
    }

    .floating-label:nth-child(2) {
        float: right; 
    }

   .calendar-dark {
        background-image:url(${calendar}); !important;
    }

`;



const AdminQuestsPage = styled.div`

    border-radius: 4px;
    margin-top: 20px;
    padding: 20px;
    background-color: #2a3143;
    width: 100%;
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        text-align: center;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        text-align: center;
        
        // img {
        //   width: 25px;
        //   height: 25px;
        //   border-radius: 50%;
        //   object-fit: cover;
        // }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const mapStateToProps = ({users,userChallenge,challenges,quests}) => ({
  users,
  userChallenge,
  challenges,
  quests
});

const ViewParticipants = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();

  const { pageId,userId } = useParams();

  const [keys, setKeys] = React.useState("");
  const [search, setNewSearch] = React.useState("");
  const [FileUrl, setFileUrl] = React.useState('');
  const [FileType, setFileType] = React.useState('');
  const [checkeds, setChecked] = React.useState(false);
  const [entriesValues, setentriesValues] = React.useState();
  const [userChallenge, setUserChallenge] = React.useState([]);
  const [questChallenges, setQuestChallenges] = React.useState([]);
  const [showFilePreview, setFilePreview] = React.useState(false);


  let filterkeys = ["questTitle", "questContent"];
  let tableHeader = ["Challenges", "Description","Supporting Image", "Challenge Status", "Actions"]


    //show entries
      const entriesValue = (entries) => {
        setentriesValues(entries);
    }

    //search
    const handleSearchChange = (item) => {
        setNewSearch(item);
    };    

    //filters
    const filterKeys = (item) => {
        setKeys(item);
    };    


    React.useEffect(() => {
        if (pageId) {
          questService.getAllQuestChallenge(pageId, 1, 100000)
            .then((res) => { 
              setQuestChallenges(res?.items);
            })
            .catch(err => console.error(err));
        }
        //eslint-disable-next-line
      }, [pageId]);


  React.useEffect(() => {
    dispatch(userActions.getAllActiveUsers(1,100000,'',''));
    dispatch(userChallengeActions.getUserQuestChallenges(userId,pageId,1,100000))

    //eslint-disable-next-line
  }, [dispatch,pageId]);

  React.useEffect(() => {
    setUserChallenge(props.userChallenge?.userChallenge?.items)

    //eslint-disable-next-line
  }, [setUserChallenge,props.userChallenge,props.quests]);



    const questChallengeParticipants = (users) => {
        let setParticipants = props.users.users?.items && props.users.users?.items.filter(user => users.includes(user.details[0].user_id));
        return (
            <div className="rq-participants" style={{display:'inline-flex', marginTop:'20px', float:'left'}}>
                <div className="participants-imgs">
                    {setParticipants && setParticipants.map((challenger, index) =>
                    <div style={{display:'flex', alignContent:'center'}} key={index}>
                        <img className="img-participant" alt="" onError={(e) => { e.target.src = avatar }} src={challenger.details[0]?.avatar || avatar} style={{marginRight:'15px'}}/>
                        <label className="participantName">{challenger.details[0]?.firstName + " " + challenger.details[0]?.lastName}</label>
                    </div>
                        

                    )}
                </div>
            </div>
        );
    };    


    const completeChallenge = (userId, challengeId) => {

        const Toast = Swal.mixin({
            toast: true,
            width: 300,
            height: 500,
            background: '#D4EDDA',
            position: 'center',
            showConfirmButton: false,
            timer: 5000
          });
          Swal.fire({
            title: "<p class='swal-title-engagement'>Confirmation</p>",
            html:
              " <div style='margin:30px'><p class='alert-first'>Do you want to complete this Challenge?</p></div>",
        
              showCancelButton: true,
              showCloseButton: true,
              confirmButtonColor: "#556ee6",
              cancelButtonColor: "#2A3143",
              customClass: {
                header: 'header-class',
                container: 'container-class',
                popup: 'popup-class-engagament',
                actions: 'actions-class-engagament',
                cancelButton: 'cancel-button-class',
                confirmButton: 'confirm-btn-engagement',
                content: 'E-alert-conent',
              },
              reverseButtons:true,
              cancelButtonText: "LEAVE",
              confirmButtonText:"COMPLETE",
              background: "#2E3549",
          }).then((result) => {
              if (result.value) {

            dispatch(userChallengeActions.completeQuestChallenge(userId,challengeId)); 
            setTimeout(() => {
              Toast.fire({
                type: 'success',
                title: '<span style="color:#0A551E"> Quest Challenge has been completed.</span>',
              });
                dispatch(userChallengeActions.getUserQuestChallenges(userId,pageId,1,100000))
            }, 1500)
                      
              }
          })        
    }

    const failedChallenge = (userId, challengeId) =>{

        const Toast = Swal.mixin({
            toast: true,
            width: 300,
            height: 500,
            background: '#D4EDDA',
            position: 'center',
            showConfirmButton: false,
            timer: 5000
          });
          Swal.fire({
            title: "<p class='swal-title-engagement'>Confirmation</p>",
            html:
              " <div style='margin:30px'><p class='alert-first'>Do you want to fail this Challenge?</p></div>",
        
              showCancelButton: true,
              showCloseButton: true,
              confirmButtonColor: "#556ee6",
              cancelButtonColor: "#2A3143",
              customClass: {
                header: 'header-class',
                container: 'container-class',
                popup: 'popup-class-engagament',
                actions: 'actions-class-engagament',
                cancelButton: 'cancel-button-class',
                confirmButton: 'confirm-btn-engagement',
                content: 'E-alert-conent',
              },
              reverseButtons:true,
              cancelButtonText: "LEAVE",
              confirmButtonText:"FAILED",
              background: "#2E3549",
          }).then((result) => {
              if (result.value) {
                // console.log(result.value)
            dispatch(userChallengeActions.failQuestChallenge(userId,challengeId)); 
            setTimeout(() => {
              Toast.fire({
                type: 'success',
                title: '<span style="color:#0A551E"> Quest Challenge has been failed.</span>',
              });
                dispatch(userChallengeActions.getUserQuestChallenges(userId,pageId,1,100000))
            }, 1500)
                      
              }
          })        
    }
    

    const deleteChallenge = (userId, challenge, status) => {

        console.log(status)

        const Toast = Swal.mixin({
            toast: true,
            width: 300,
            height: 500,
            background: '#D4EDDA',
            position: 'center',
            showConfirmButton: false,
            timer: 2000
          });
          Swal.fire({
            title: "<p class='swal-title-engagement'>Confirmation</p>",
            html:
              " <div style='margin:30px'><p class='alert-first'>Do you want to delete this User Challenger?</p></div>",
        
              showCancelButton: true,
              showCloseButton: true,
              confirmButtonColor: "#556ee6",
              cancelButtonColor: "#2A3143",
              customClass: {
                header: 'header-class',
                container: 'container-class',
                popup: 'popup-class-engagament',
                actions: 'actions-class-engagament',
                cancelButton: 'cancel-button-class',
                confirmButton: 'confirm-btn-engagement',
                content: 'E-alert-conent',
              },
              reverseButtons:true,
              cancelButtonText: "LEAVE",
              confirmButtonText:"DELETE",
              background: "#2E3549",
          }).then((result) => {
              if (result.value) {

                dispatch(userChallengeActions.deleteQuestChallenge(userId,challenge)); 
                setTimeout(() => {
                Toast.fire({
                    type: 'success',
                    title: '<span style="color:#0A551E"> Quest Challenge has been deleted.</span>',
                });
                    dispatch(userChallengeActions.getUserQuestChallenges(userId,pageId,1,100000))
                }, 1500)

                if(status === 'completed'){
                    dispatch(userChallengeActions.revertQuest(userId,pageId))
                }
              }
          })        
    }

    const handleChangeCheckBox = (event) => { 
        
        setChecked(event.target.checked)
        
    }

    const completedQuest = (status) => {


        if(checkeds !== false){
            const Toast = Swal.mixin({
                toast: true,
                width: 300,
                height: 500,
                background: '#D4EDDA',
                position: 'center',
                showConfirmButton: false,
                timer: 2000
              });
              Swal.fire({
                title: "<p class='swal-title-engagement'>Confirmation</p>",
                html:
                  " <div style='margin:30px'><p class='alert-first'>Do you want to complete this Quest?</p></div>",
            
                  showCancelButton: true,
                  showCloseButton: true,
                  confirmButtonColor: "#556ee6",
                  cancelButtonColor: "#2A3143",
                  customClass: {
                    header: 'header-class',
                    container: 'container-class',
                    popup: 'popup-class-engagament',
                    actions: 'actions-class-engagament',
                    cancelButton: 'cancel-button-class',
                    confirmButton: 'confirm-btn-engagement',
                    content: 'E-alert-conent',
                  },
                  reverseButtons:true,
                  cancelButtonText: "LEAVE",
                  confirmButtonText:"COMPLETE",
                  background: "#2E3549",
              }).then((result) => {


                  if (result.value) {
                    //setChecked(true)
                        
                    dispatch(userChallengeActions.completeQuest(userId,pageId)); 
                    setTimeout(() => {
                        Toast.fire({
                            type: 'success',
                            title: '<span style="color:#0A551E"> Quest has been completed.</span>',
                        });
                        history.goBack();
                    }, 1500)
                  }else if (result.dismiss === 'cancel' || result.dismiss === 'close'){
                    setChecked(false)
                  }

              })
        }

              return (
                <>
                    <div className="titleSwitch-edit">
                        <p className="switch-one">Complete</p>
                        <p className="switch-two">Mark quest as complete</p>
                    </div>
                    <input type="checkbox" 
                        name="rememberCheckbox"
                        checked={status?.[0]?.overAllStatus !== 'completed'? checkeds : true}
                        onChange={handleChangeCheckBox}
                        disabled={completedCount(status) ? false : true}
                    />
                </>
            );
 


        
    }


    // const finishedChallenge = (userId, challengeId) =>{

    //     const Toast = Swal.mixin({
    //         toast: true,
    //         width: 300,
    //         height: 500,
    //         background: '#D4EDDA',
    //         position: 'center',
    //         showConfirmButton: false,
    //         timer: 5000
    //       });
    //       Swal.fire({
    //         title: "<p class='swal-title-engagement'>Confirmation</p>",
    //         html:
    //           " <div style='margin:30px'><p class='alert-first'>Do you want to finish this Challenge?</p></div>",
        
    //           showCancelButton: true,
    //           showCloseButton: true,
    //           confirmButtonColor: "#556ee6",
    //           cancelButtonColor: "#2A3143",
    //           customClass: {
    //             header: 'header-class',
    //             container: 'container-class',
    //             popup: 'popup-class-engagament',
    //             actions: 'actions-class-engagament',
    //             cancelButton: 'cancel-button-class',
    //             confirmButton: 'confirm-btn-engagement',
    //             content: 'E-alert-conent',
    //           },
    //           reverseButtons:true,
    //           cancelButtonText: "LEAVE",
    //           confirmButtonText:"FINISHED",
    //           background: "#2E3549",
    //       }).then((result) => {
    //           if (result.value) {
    //             // console.log(result.value)
    //         dispatch(userChallengeActions.finishQuestChallenge(userId,challengeId)); 
    //         setTimeout(() => {
    //           Toast.fire({
    //             type: 'success',
    //             title: '<span style="color:#0A551E"> Quest Challenge has been Finish.</span>',
    //           });
    //             dispatch(userChallengeActions.getUserQuestChallenges(userId,pageId,1,100000))
    //         }, 1500)
                      
    //           }
    //       })        
    // }

    const openFilePreview = () => {
		setFilePreview(true);
	};

  
    const customStyles = {
        content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }
      };


    const now = moment().utc();

    const setEndDate = (status,date,id) => { //get accepted date + time limit
        
        const isAccepted = status.toLowerCase() === 'accepted' ? true : false;
        const acceptedDate = moment(date).isValid() ? moment(date).utc() : false;
        const timelimit = questChallenges?.map((userCredit) => {
            return userCredit.timeLimit
        })[0]
        const getTimeLimitString = timelimit !== '00:00:00' || !timelimit ? timelimit : false;

        var getTimer;

          if(getTimeLimitString){
              if(isAccepted && acceptedDate){
                  const hms_split = getTimeLimitString.split(':');
                  const getTimePerSec = (+hms_split[0]) * 60 * 60 + (+hms_split[1]) * 60 + (+hms_split[2]);
                  const computedEndTime = moment(acceptedDate).add(getTimePerSec, 'seconds');
                  getTimer = computedEndTime;
              }else{
                  getTimer = getTimeLimitString;
              }
          }else{
              getTimer = false;
          }

        return getTimer

      }


    const completedCount = (data) =>{

        let result;

        data && data.map((items) => {
            let status = [];

            if (items.length !== 0) {

                items.challenges && items.challenges.map((data) => {
                    
                    status.push(data.status);

                    return data
                })
                
            }
            
            //Checking if quest challenges is all completed
            const counts = {};
            status.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
            if(counts.completed === questChallenges.length){
                result = true
            }else{
                result = false
            }
            
            return items
        });

        return result

    }


    const QuestsData = () => {
        let datas = userChallenge ?? [];
        let data = questChallenges ?? [];

        return (
          <>
            <If condition={props.userChallenge.loading !== true }> 
                <Then>
                    { data.length !== 0 ?  (
                        data?.map((item, index) => {
    
                            const matchingItems = datas.filter((items) => items?.challenges?.some((challenge) => challenge.challengeId === item._id));
                            const hasMatchingItems = matchingItems.length > 0;

                            return (
                                <tr key={index}>
                                  <td style={{ width: '20%' }}>
                                    {item.title}
                                  </td>
                                  <td style={{ width: '20%' }}>
                                    {item.description}
                                  </td>
                                  <td style={{width:'20%'}}>
                                  {hasMatchingItems ? (
                                        // Render image for matching items
                                        matchingItems.map((matchingItem, dataIndex) => (
                                            <React.Fragment key={dataIndex}>
                                            {matchingItem.challenges.filter((challenge) => challenge.challengeId === item._id).map((challenge, challengeIndex) => (
                                                <React.Fragment key={challengeIndex}>
                                                {challenge.images !== undefined && challenge.images !== null ? (
                                                    <img
                                                    src={challenge.images}
                                                    className="challenge-img"
                                                    alt="challengeimg"
                                                    onClick={() => {
                                                        openFilePreview();
                                                        setFileUrl(challenge.images);
                                                        setFileType(challenge.images.split('.').pop());
                                                    }}
                                                    />
                                                ) : (
                                                    "No supporting image"
                                                )}
                                                </React.Fragment>
                                            ))}
                                            </React.Fragment>
  
                                            ))
                                        ) : (
                                        // Render pending status if no matching items
                                        <React.Fragment>
                                            <span>Pending</span>
                                        </React.Fragment>
                                        )}
                                    </td>
                                    <td style={{width:'25%'}}> 
                                        {hasMatchingItems ? (
                                                // Render status for matching items
                                                matchingItems.map((matchingItem, dataIndex) => (
                                                    <React.Fragment key={dataIndex}>
                                                    {matchingItem.challenges.filter((challenge) => challenge.challengeId === item._id).map((challenge, challengeIndex) => (
                                                        <React.Fragment key={challengeIndex}>
                                                            { moment(now).isSameOrAfter(moment(setEndDate(challenge.status,challenge.acceptedAt), 'YYYY-MM-DDTHH:mm:ssZ', true)) ?
                                                                    <span className="rejected">Failed - (challenge time limit is up)</span>
                                                                :
                                                                challenge.status === "accepted"? 
                                                                    <span  className="on hold">Accepted</span> 
                                                                : challenge.status === "finished" ? 
                                                                    <span  className="completed">Finished</span>  
                                                                : challenge.status === "completed" ?
                                                                    <span className="completed">Completed</span>  
                                                                : challenge.status === "failed" ?
                                                                    <span className="rejected">Failed</span> 
                                                                : 
                                                                " "
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                    </React.Fragment>
        
                                                    ))
                                                ) : (
                                                // Render pending status if no matching items
                                                <React.Fragment>
                                                    <span>Pending</span>
                                                </React.Fragment>
                                            )}
                                    </td>
                                    <td style={{width:'5%'}}>
                                        {hasMatchingItems ? (
                                                // Render action for matching items
                                                matchingItems.map((matchingItem, dataIndex) => (
                                                    <React.Fragment key={dataIndex}>
                                                    {matchingItem.challenges.filter((challenge) => challenge.challengeId === item._id).map((challenge, challengeIndex) => (
                                                        <React.Fragment key={challengeIndex}>
                                                            <div>
                                                                <DynamicEngagementAction
                                                                    originPage="QuestChallenge"
                                                                    iconSet="2"
                                                                    deleteParticipants="Delete"
                                                                    onDeleteParticipants={ (e) => {deleteChallenge(challenge.userId, item._id, challenge.status)}}
                                                                    onCompleteChallenge={ (e) => {completeChallenge(challenge.userId, item._id)}}
                                                                    onFailedChallenge={ (e) => {failedChallenge(challenge.userId, item._id)}}
                                                                    // onFinishedChallenge={ (e) => {finishedChallenge(item.userId, item.challenge._id)}}
                                                                    editStatus = {false}
                                                                    challengeStatus={ moment(now).isSameOrAfter(moment(setEndDate(challenge.status,challenge.acceptedAt,item._id),'YYYY-MM-DDTHH:mm:ssZ', true)) ?
                                                                        "failed" 
                                                                        : challenge.status === 'new'?
                                                                        "pending" 
                                                                        :challenge.status
                                                                    }
                                                                />
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                    </React.Fragment>
        
                                                    ))
                                                ) : (
                                                // Render empty if no matching items
                                                <></>
                                            )}
                                        
                                    </td>
                                </tr>
                            )

                        }) 
                    ) :
                    <tr>
                        <td colSpan="12" style={{textAlign:'center'}}>
                            <img src={emptyIcon} className="empty" alt="empty"/>
                            <p style={{fontSize:'16px'}}>No Data Available</p>
                            <p style={{fontSize:'14px'}}>There are currently no data available</p>
                        </td>
                    </tr>
                    }
                </Then>
                <Else>
                  <tr className="table-td-data">
                    <td> 
                      <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                          <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                      </div>
                    </td>
                  </tr>
                </Else>
            </If>

                <Modal
                    isOpen={showFilePreview}
                    ariaHideApp={true}
                    toggle={setFilePreview}
                    onRequestClose={() => setFilePreview(false)}
                    shouldCloseOnOverlayClick={true}
                    className="fileModalPreview"
                    style={customStyles}
                >
                    <div
                        className="row center rejecting-container"
                        style={{
                            width: "auto",
                            height: "auto",
                            margin: "auto",
                            padding: "50px",
                        }}
                    >
                    <div className="gAction">
                        <a href={FileUrl} download>
                            <button className="btn-download" style={{background:'none'}}>
                                <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'brightness(5.5)' }}/>
                             </button>
                        </a>
                        <img height={20} onClick={() => setFilePreview(false)} src={close} alt="" style={{ margin:'15px 7px', filter: 'brightness(5.5)', cursor:'pointer', objectFit:'contain' }}/>
                    </div>
                    <div style={{ height: '100%' }}>
                        <FileViewer 
                            fileType={FileType} 
                            filePath={FileUrl} 
                            key= {FileUrl}
                        />
                    </div>

                </div>
            </Modal>

          </>
        );
      };


  return (
    <>
    <div>
        {props.challenges.length !== 0 ?  (
            props.challenges?.challenge?.map((item, index) => ( 
                <div style={{color:'#9BA3CA', marginTop:'25px'}} key={index}>
                    <span style={{fontSize:'18px', fontWeight:'700'}}>{item.title}</span>
                    <div style={{marginTop:'20px'}}>
                        <span style={{fontSize:'14px'}}>{item.description}</span>
                    </div>
                </div>

            ))
        )
        :
        <></>
        }
    </div>
    <AdminQuestsPage>
      <EngageSearchEntriesWrapper
        buttonLabel="ADD QUEST"
        btnPath="/admin/quests/create"
        entriesValue={entriesValue}
        data={userChallenge}
        location={props.location}
        searchs={handleSearchChange}
        filters={filterKeys}
        keys={filterkeys}
        paginationPath={"/admin/quests"}
        originPage="Quest"
        addPage="Engagement - Quests"
        challengesStatus={completedCount(userChallenge)}
        userName={questChallengeParticipants(userId)}
        completedStatus={completedQuest(userChallenge)}
        // checkStatus={checkStatus}

      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={QuestsData()}
        originPage="Quest"
        data={userChallenge}
        location={props.location}
        paginationPath={"/admin/quests"}
        entriesItem={entriesValues}
        search={search}
        loading = {props.quests.loading}
        filter={keys}
      />
    </AdminQuestsPage>
    </>

  );
});

export default ViewParticipants;
