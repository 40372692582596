import React from 'react';

import { connect } from 'react-redux';
import { userActions } from '../_core/actions/user';
import { officeActions } from '../_core/actions/office';
import { roleActions } from '../_core/actions/role';
import { maritalStatus, level, department, position, officeLocation } from '../_core/constants/selections'

import moment from 'moment';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";	
import TextField from '@material-ui/core/TextField';
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import './overrideInputField.sass';
import './UserEditProfile.sass';
import './uploadModal.sass';

import { hrm } from '../_core/helpers/config'; //check regularization date

//import { decryptUserEmail } from '../_core/helpers/crypto_config';


const acceptedImgFile = 'image/x-png, image/png, image/jpg, image/jpeg';
class UserEditProfilePage extends React.Component {
	constructor(props) {
		super(props);

		//for upload image input ref
		this.fileInputRef = React.createRef();
		this.fileCoverRef = React.createRef();

		//get logged in user
		
		let userCred = JSON.parse(localStorage.getItem('userCredentials'));
        let userDetail= JSON.parse(localStorage.getItem('user'));
		let userCredDetail = userCred.user;
		let decryptEmail = userCredDetail.email;
		let isEmailEncrypted = decryptEmail !== '' ? decryptEmail : userCredDetail.email;
	
        const userEdit = true;

		this.title = userEdit ? 'Edit User' : ''

		this.state = {

			activeStep: 0,

			user: {
				_id: userEdit ? userDetail.user_id : null,
				email: userEdit ? isEmailEncrypted : '',
				organization: userCredDetail.organization, //logged in user's team
				officeId: userEdit ? userDetail.officeId : '',
				manager: userEdit ? userDetail.manager : null,
            altemail: userEdit ? userDetail.altemail : '',
				bio: userEdit ? userDetail.bio : '',
				firstName: userEdit ? userDetail.firstName : '',
				middleName: userEdit ? userDetail.middleName : '',
				lastName: userEdit ? userDetail.lastName : '',
				title: userEdit ? userDetail.title : '',
				suffix: userEdit ? userDetail.suffix : '',
				address1: userEdit ? userDetail.address1 : '',
				address2: userEdit ? userDetail.address2 : '',
				city: userEdit ? userDetail.city : '',
				state: userEdit ? userDetail.state : '',
				country: userEdit ? userDetail.country : '',
				nationality: userEdit ? userDetail.nationality : '',
				mothersMaidenName: userEdit ? userDetail.mothersMaidenName : '',
				maritalStatus: userEdit ? userDetail.maritalStatus : '',
				gender: userEdit ? userDetail.gender : '',
				birthDate: userEdit ? moment(userDetail.birthDate).utc().format() === 'Invalid date' ? '' : moment(userDetail.birthDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
				birthPlace: userEdit ? userDetail.birthPlace : '',
				startDate: userEdit ? moment(userDetail.startDate).utc().format() === 'Invalid date' ? '' : moment(userDetail.startDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
				endDate: userEdit ? moment(userDetail.endDate).utc().format() === 'Invalid date' ? '' : moment(userDetail.endDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
				regularizationDate: userEdit ? moment(userDetail.regularizationDate).utc().format() === 'Invalid date' ? '' : moment(userDetail.regularizationDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
				position: userEdit ? userDetail.position : '',
				department: userEdit ? userDetail.department : '',
				level: userEdit ? userDetail.level : '',
				praggerUsername: userEdit ? userCredDetail.praggerUsername : '',
				contactNumber: userEdit ? userDetail.contactNumber : '',
                role: userEdit ? userCredDetail.role : '',
				praggerCode: userEdit ? userCredDetail.praggerCode : '',
                sssNumber:"222222",
                philhealthNumber:"222222",
                pagibigNumber:"222222",
			},
			submitted: false,
			invalidEmail: false,
			render: false,
			selections: {
				maritalStatus: maritalStatus,
				department: department,
				position: position,
				level: level,
				officeLocation: officeLocation
			},
			avatar: userEdit ? userDetail.avatar : '',
			photo: userEdit ? userDetail.photo : '',
			cover: userEdit ? userDetail.cover : '',
			avatarSrc: null,
			coverSrc: null,
			src: null,
			avatarUpload: null,
			coverUpload: null,
			crop: {
				unit: '%',
				aspect: 1 / 1,
				width: 50,
				height: 50,
				x: 25,
  				y: 25
			},
			cropCover: {
				unit: '%',
				aspect: 16 / 9,
				width: 100,
				height: 30,
				x: 25,
  				y: 25
			},
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleNumber = this.handleNumber.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleStartDatePicker = this.handleStartDatePicker.bind(this);
		this.handleEndDatePicker = this.handleEndDatePicker.bind(this);
		this.handleRegDatePicker = this.handleRegDatePicker.bind(this);
		this.handleBirthDatePicker = this.handleBirthDatePicker.bind(this);
	}

	componentDidMount() {
        let userDetail = JSON.parse(localStorage.getItem('user'));

        localStorage.setItem('userid', JSON.stringify(userDetail.user_id))

		this.props.dispatch(officeActions.getAll())
		this.props.dispatch(roleActions.getAll(1, 1000000))
		document.getElementById('uploadAvatar').style.display = "none";
		document.getElementById('uploadCover').style.display = "none";
	}

	getSteps() {
		return [
		'Personal Information (Upload Avatar/Cover)', 
		'Personal Information (User details)', 
		'User Crendentials'
		];
	}
	  
	 handleNext(isSubmit) {	
		if(isSubmit === "completed"){
			this.handleSubmit()
		}
		if(this.state.activeStep === 1){
			this.page_userDetails();
		} else if (this.state.activeStep === 2) {
			this.page_UserCredentials();
		} else {
			this.setState({ activeStep: this.state.activeStep + 1});
		}
	};

	 handleBack()  {
		this.setState({ activeStep: this.state.activeStep  - 1})
	};

	 handleReset()  {
		this.setState({ activeStep: 0})
	};


	goBack = () => { this.props.history.goBack();}

	//------------------------------------------ UPLOAD EXIT --------------------------------------------------->
	exitUpload=()=>{
		this.setState({ src: null, croppedImageUrl: null});
		this.fileInputRef.current.value = null;
		document.getElementById('uploadAvatar').style.display = "none";
	}

	exitUploadCover=()=>{
		this.setState({ srcCover: null, croppedCoverUrl: null});
		this.fileCoverRef.current.value = null;
		document.getElementById('uploadCover').style.display = "none";
	}

	//------------------------------------------ UPLOAD AVATAR --------------------------------------------------->
	getAvatar=()=>{
		document.getElementById('uploadAvatar').style.display = "block";
	}

	uploadAvatar=()=>{
		const { crop, src } = this.state;
		return(
			<div>
			<div className="upload-container" onClick={this.exitUpload}></div>
				<div className="upload-max-width">
					<div className="upload-img-container">
						<div className="update-avatar-title">
							<span className="title">Update Avatar</span><span className="exit-avatar-modal" onClick={this.exitUpload}>x</span>
						</div>				
						<div className="image-display">
							<div className="image-crop-display">
								<ReactCrop
									src={src}
									crop={crop}
									minWidth={50}
									minHeight={50}
									onImageLoaded={this.onImageLoaded}
									onComplete={this.onCropComplete}
									onChange={this.onCropChange}
								/>
							</div>
							<div className="input-crop-border">
								<label className="input-image">
									<p className="upload-pic">Upload Avatar<br></br>
										<input className="upload-pic" ref={this.fileInputRef} type="file" accept={acceptedImgFile} multiple={false} onChange={this.onSelectFile} onDrag={this.onSelectFile} />
									</p>
								</label>
							</div>
						</div>
						{src &&
						<div className="upload-avatar-save">
							<button className="btn save-avatar" onClick={this.saveAvatar}>Save Image</button>
							<span><button className="btn cancel-avatar" onClick={this.exitUpload}>Cancel</button></span>
						</div>
						}
					</div>
				</div>
			</div>
		)
	}

	onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			const bytes = e.target.files[0].size;
			const mb = 2;
			var imageSizeMB = bytes / Math.pow(1024,2);

			if(imageSizeMB > mb){
				Swal.fire({
					title: "File too large",
					html: "<bold><p>Can't upload image larger than " + mb + " mb</p></bold>",
					type: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#d33',
					confirmButtonText: 'Ok',
					customClass: {
						popup: 'format-pre'
					}
					}).then((result) => {
						this.setState({
							srcAvatar: null,
							croppedImageUrl: null,
						})
						this.fileInputRef.current.value = null;
					})
			}else{
				const reader = new FileReader();
				reader.addEventListener('load', () =>
					this.setState({ src: reader.result })
				);
				reader.readAsDataURL(e.target.files[0]);
			}
		  
		}
	};
	// If you setState the crop in here you should return false.
	onImageLoaded = image => {this.imageRef = image;};
	onCropComplete = (crop) => {this.makeClientCrop(crop);};
	onCropChange = (crop) => {this.setState({ crop });};

	async makeClientCrop(crop) {
		var filname = new Date().getTime() + '.jpeg';
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
			this.imageRef,
			crop,
			filname
			);
			this.setState({ croppedImageUrl });
		}
	}
	
	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext("2d");
		ctx.canvas.width = Math.ceil(crop.width * scaleX);
		ctx.canvas.height = Math.ceil(crop.height * scaleY);

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			Math.ceil(crop.width * scaleX),
			Math.ceil(crop.height * scaleY)
		);
		
		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					Swal.fire({
						title: "Error",
						html: "<bold><p>No image detected</bold>",
						type: 'warning',
						showCancelButton: false,
						confirmButtonColor: '#d33',
						confirmButtonText: 'Ok',
						customClass: {
							popup: 'format-pre'
						}
						})
					return;
				}

				blob.name = fileName;
				var file = new File([blob], blob.name ,{type:blob.type, lastModified:new Date()})
				this.setState({avatarSrc: file});

				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, 'image/jpeg', 1);
		});
	}

	saveAvatar=(event)=>{
		event.preventDefault();
		let avatar = this.state.avatarSrc;
		const data = new FormData();
		if(avatar){
			data.append("file", avatar, avatar.name);
			this.setState({render: true, src: null, avatarUpload: data });
			this.fileInputRef.current.value = null;

			const Toast = Swal.mixin({
				toast: true,
				width: 300,
				height: 500,
				background: 'rgba(191, 85, 236, 1)',
				fontColor: "white",
				position: 'center',
				showConfirmButton: false,
				timer: 1500
			});
			Toast.fire({
				type: 'success',
				title: '<span style="color:#FFFFFF">New avatar added!</span>'
			});
			
			// this.props.dispatch(userActions.uploadAvatar(data))
			document.getElementById('uploadAvatar').style.display = "none";
		}else{
			Swal.fire({
				title: "No image saved",
				html: "<bold><p>Please crop the image</bold>",
				type: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#d33',
				confirmButtonText: 'Ok',
				customClass: {
					popup: 'format-pre'
				}
			});
			return
		}
		
	};
	//------------------------------------------ END UPLOAD AVATAR --------------------------------------------------->
	//------------------------------------------ COVER AVATAR --------------------------------------------------->
	getCover=()=>{
		document.getElementById('uploadCover').style.display = "block";
	}

	uploadCover=()=>{
		const { cropCover, srcCover } = this.state;
		return(
			<div>
			<div className="upload-container" onClick={this.exitUploadCover}></div>
			<div className="upload-max-width">
				<div className="upload-img-container">
					<div className="update-avatar-title">
						<span className="title">Update Cover</span><span className="exit-avatar-modal" onClick={this.exitUploadCover}>x</span>
					</div>				
					<div className="image-display">
						<div className="image-crop-display">
							<ReactCrop
								src={srcCover}
								crop={cropCover}
								minWidth={50}
								minHeight={50}
								onImageLoaded={this.onCoverLoaded}
								onComplete={this.onCropCoverComplete}
								onChange={this.onCropCoverChange}
							/>
						</div>
						<div className="input-crop-border">
							<label className="input-image">
								<p className="upload-pic">Upload Cover<br></br>
									<input className="upload-pic" ref={this.fileCoverRef} type="file" accept={acceptedImgFile} multiple={false} onChange={this.onSelectCover} onDrag={this.onSelectCover} />
								</p>
							</label>
						</div>
					</div>
					{srcCover &&
					<div className="upload-avatar-save">
						<button className="btn save-avatar" onClick={this.saveCover}>Save Image</button>
						<span><button className="btn cancel-avatar" onClick={this.exitUploadCover}>Cancel</button></span>
					</div>
					}
					</div>
				</div>
			</div>
		)
	}

	onSelectCover = e => {
		if (e.target.files && e.target.files.length > 0) {
			const bytes = e.target.files[0].size;
			const mb = 2;
			var imageSizeMB = bytes / Math.pow(1024,2);

			if(imageSizeMB > mb){
				Swal.fire({
					title: "File too large",
					html: "<bold><p>Can't upload image larger than " + mb + " mb</p></bold>",
					type: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#d33',
					confirmButtonText: 'Ok',
					customClass: {
						popup: 'format-pre'
					}
					}).then((result) => {
						this.setState({
							srcCover: null,
							croppedCoverUrl: null,
						})
						this.fileCoverRef.current.value = null;
					})
			}else{
				const reader = new FileReader();
				reader.addEventListener('load', () =>
					this.setState({ srcCover: reader.result })
				);
				reader.readAsDataURL(e.target.files[0]);
			}
		  
		}
	};
	// If you setState the crop in here you should return false.
	onCoverLoaded = cover => {this.coverRef = cover;};
	onCropCoverComplete = (crop) => {this.makeCoverCrop(crop);};
	onCropCoverChange = (cropCover) => {this.setState({ cropCover });};

	async makeCoverCrop(crop) {
		var filname = new Date().getTime() + '.jpeg';
		if (this.coverRef && crop.width && crop.height) {
			const croppedCoverUrl = await this.getCroppedCover(
			this.coverRef,
			crop,
			filname
			);
			this.setState({ croppedCoverUrl });
		}
	}
	
	getCroppedCover(image, crop, fileName) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext("2d");
		ctx.canvas.width = Math.ceil(crop.width * scaleX);
		ctx.canvas.height = Math.ceil(crop.height * scaleY);

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			Math.ceil(crop.width * scaleX),
			Math.ceil(crop.height * scaleY)
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					Swal.fire({
						title: "Error",
						html: "<bold><p>No image detected</bold>",
						type: 'warning',
						showCancelButton: false,
						confirmButtonColor: '#d33',
						confirmButtonText: 'Ok',
						customClass: {
							popup: 'format-pre'
						}
					})
					return;
				}

				blob.name = fileName;
				var file = new File([blob], blob.name ,{type:blob.type, lastModified:new Date()})
				this.setState({coverSrc: file});

				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, 'image/jpeg', 1);
		});
	}

	saveCover=(event)=>{
		event.preventDefault();
		let cover = this.state.coverSrc;
		const data = new FormData();
		if(cover){
			data.append("file", cover, cover.name);
			this.setState({render: true, src: null, coverUpload: data });
			this.fileCoverRef.current.value = null;

			const Toast = Swal.mixin({
				toast: true,
				width: 300,
				height: 500,
				background: 'rgba(191, 85, 236, 1)',
				fontColor: "white",
				position: 'center',
				showConfirmButton: false,
				timer: 1500
			});
			Toast.fire({
				type: 'success',
				title: '<span style="color:#FFFFFF">New cover added!</span>'
			});

			document.getElementById('uploadCover').style.display = "none";
		}else{
			Swal.fire({
				title: "No image detected",
				html: "<bold><p>Please crop the image</bold>",
				type: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#d33',
				confirmButtonText: 'Ok',
				customClass: {
					popup: 'format-pre'
				}
			})
			return;
		}
	};
	//------------------------------------------ END COVER AVATAR --------------------------------------------------->

	handleStartDatePicker=(date)=> {
		const reg_date = hrm.regularizationDate;
		const isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
		this.setState({
			startDate: isoDate
		  }, () => {
			this.handleChange({ target: { name: 'startDate', value: isoDate } });
		  });
		const regDate = moment(date).add(reg_date, 'M').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
		const regularizationDate = regDate
		this.handleChange({target: { name: 'regularizationDate', value: regularizationDate } });
	}

	handleEndDatePicker=(date)=> {
		const isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
		this.handleChange({ target: { name: 'endDate', value: isoDate } });
	}

	handleRegDatePicker=(date)=> {
		const isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
		this.handleChange({ target: { name: 'regularizationDate', value: isoDate } });
	}

	handleBirthDatePicker=(date)=> {
		const isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
		this.handleChange({ target: { name: 'birthDate', value: isoDate } });
	}

	handleNumber = (e) => {
		const { name } = e.target;
		const { user } = this.state;
    const value = e.target.value.replace(/[^0-9\+]/g, '')// eslint-disable-line

		this.setState({
			user: {
				...user,
				[name]: value
			}
		});

		// if(value.length === 0) {
		// 	this.setState({
		// 		user: {
		// 			...user,
		// 			[e.target.name]: '',
		// 		}
		// 	});
    // } else {
		// 		this.setState({
		// 			user: {
		// 				...user,
		// 				[name]: value
		// 			}
		// 		});
    // }
	
};



	handleChange = (event) => {
		const { name, value } = event.target;
		const { user } = this.state;

		if(name === 'email'){
			// Email error message
			if(this.ValidateEmail(value) === true) {
				this.setState({
					user: {
						...user,
						[name]: value
					},
					invalidEmail: false,
				});
			} else {
				this.setState({
					user: {
						...user,
						[name]: value
					}
				});
			}
		} else if (name === 'altemail' ){
			if(this.ValidateEmail(value) === true) {
				this.setState({
					user: {
						...user,
						[name]: value
					},
					invalidEmail: false,
				});
			} else {
				this.setState({
					user: {
						...user,
						[name]: value
					}
				});
			}
		}
		
		else {
			this.setState({
				user: {
					...user,
					[name]: value
				}
			});
		}	
	
		
		
	}
	ValidateEmail(mail) {
		// RFC 2822 Standard Email Validation
		let emailRegex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])");	 // eslint-disable-line
		if (emailRegex.test(mail))
		{
			return (true)
		}
			return (false)
		}

	handleSubmit(event) {
		// event.preventDefault();
		/* eslint-disable */
		this.setState({ submitted: true });
		const { user, selections } = this.state;
		const { dispatch } = this.props;
		const avatar = this.state.avatarUpload;
		const cover = this.state.coverUpload;
		if( user.role === '' || user.email === '' || user.password === '' || user.praggerName === '' || user.startDate === '' || user.status === ''  || user.officeId === ''){
			Swal.fire({
				title: 'Unable to submit form',
				html:
					"<br> <b>Kindly fill up the required fields</b> ",
				type: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#d33',
				confirmButtonText: 'Ok'
			})
		}else{
			Swal.fire({
				title: 'Submit Changes?',
				html:
					"<br> <b>User Employee Name: </b> "
					+ user.firstName + " " + user.middleName + " " + user.lastName + "<br><b>Email: </b> "
					+ user.email + "<br><b>Organization: </b> "
					+ user.organization + "<br><b>username: </b> "
					+ user.praggerUsername,
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#24DC98',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Done!'
			}).then((result) => {		
				if (result.value) {
						// Submit
				
                        if(avatar && cover ){
                            dispatch(userActions.uploadAvatar(avatar, user._id))
                            dispatch(userActions.uploadCover(cover, user._id))
                        } else {
                            if(avatar){
                                dispatch(userActions.uploadAvatar(avatar, user._id))
                            }else if(cover){
                                dispatch(userActions.uploadCover(cover, user._id))
                            }
                        }
						dispatch(userActions.edit(user))
					
				} else {
					// Cancel
					this.setState({ activeStep: this.state.activeStep - 1})
				}
			})
		}
	}


	page_userDetails=()=> {
		const { user } = this.state;
		if(	user.firstName === '' || 
			user.lastName === '' || 
			user.address1 === '' || 
			user.city === '' ||
			user.state === '' ||
			user.country === '' ||
			user.nationality === '' ||
			user.birthPlace === '' ||
			user.maritalStatus === ''
			)
			{
				Swal.fire({
					title: 'Unable to proceed',
					html:
						"<br> <b>Kindly fill up the required fields</b> ",
					type: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#d33',
					confirmButtonText: 'Ok',
			})
			this.setState({ submitted: true });
		}
		else{
			this.setState({ activeStep: this.state.activeStep + 1});
			this.setState({ submitted: false });
		}
	}

	page_UserCredentials=()=> {
		const { user , invalidEmail } = this.state;
	
		if(user.email === '' || user.praggerUsername === '' || user.organization === '' ||user.praggerCode === ''){
			Swal.fire({
				title: 'Unable to proceed',
				html:
					"<br> <b>Kindly fill up the required fields</b> ",
				type: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#d33',
				confirmButtonText: 'Ok',
			})
			this.setState({ submitted: true });
		} else if (this.ValidateEmail(user.email) === false){
			Swal.fire({
				title: 'Unable to proceed',
				html:
					"<br> <b>Invalid Email Address</b> ",
				type: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#d33',
				confirmButtonText: 'Ok',
			})
			this.setState({ submitted: true, invalidEmail: true});
		}
		else{
			this.setState({ activeStep: this.state.activeStep + 1});
			this.setState({ submitted: false });
		}
	}

	
	getStepContent(stepIndex) {
		switch (stepIndex) {
		  case 0:
			return (
				this.content_step1()
			)
		  case 1:
			return (
				this.content_step2()
			)
		  case 2:
			return (
				this.content_step3()
			)
		  default:
		 	return (
				this.content_step3()
			)
		}
	  }

	content_step1 () {
		const { user, croppedCoverUrl, croppedImageUrl, avatar, cover } = this.state;
		return (
			
			<div className="clearfix" id="page_1">
			
				<div className="row" id="zero">
					<div className="imgs-container">
						<div className="avtr-container">
							<div className="user-card photo-width">
								<p><b>Upload Profile Picture</b></p>
								<div className="blue-bg">
									<div className="photo-border">
										<div>
										{croppedImageUrl ? (
										<img alt="" style={{ width: '100%' }} src={croppedImageUrl} />
										)
										:
										(
										<img alt="" style={{ width: '100%' }} src={avatar} />
										)}
										</div>
										<label className="upload-avatar" onClick={this.getAvatar}>
											<p className="upload-new">Update avatar</p>
										</label>
									</div>
								</div>
				
							</div>
						</div>
						<div className="covr-container">
							<div className="user-card cover-width">
								<p><b>Upload Cover Image</b></p>
								<div className="blue-bg-cover">
									<div className="cover-border">
										<div>
										{croppedCoverUrl ? (
										<img alt="" style={{ width: '100%' }} src={croppedCoverUrl} />
										)
										:
										(
										<img alt="" style={{ width: '100%' }} src={cover} />
										)}
										</div>
										<label className="upload-avatar" onClick={this.getCover}>
											<p className="upload-new">Update cover</p>
										</label>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div className="col s12 m12 l12" id="zero" style={{ margin: "5px 0px" }}>
						<p><b>Bio</b></p>
					</div>
					<div className="col s12 m12 l12 mb-2">
							<TextField id="outlined-basic" label="" variant="outlined" 
								fullWidth={true}
								multiline
								rows={6}
								rowsMax={6}
								placeholder="Add some details"
								
								inputProps={{
									name: "bio",
								}}
								value={user.bio} onChange={this.handleChange} />
					</div>
				</div>
			</div>
		)
	}

	content_step2() {
		const { user, submitted, selections } = this.state;

		const useStylesInput = makeStyles({
			underline: {
			  "&&&:before": {
				borderBottom: "none"
			  },
			  "&&:after": {
				borderBottom: "none"
			  }
			}
		  });
		const useStyles = makeStyles((theme) => ({
			formControl: {
				margin: theme.spacing(1),
				minWidth: 120
				
			},
			selectEmpty: {
			marginTop: theme.spacing(2)
			},
			maxWidthField: {
				width:"100%",
			},
			borderCustom: {	
				border: "red !important"
			},
			notchedOutline: {
				borderWidth: "1px",
				borderColor: "yellow !important"
			},
		}));	
		const RemoveDefaultBorder = withStyles((theme) => ({
			root: {
			  "label + &": {
				marginTop: theme.spacing(3)
			  }
			},
			input: {
			  borderRadius: 4,
			  position: "relative",
			  backgroundColor: theme.palette.background.paper,
			  border: "1px solid #ced4da",
			  fontSize: 12,
			  width: "100%",
			
			  padding: "10px 26px 10px 12px",
			  transition: theme.transitions.create(["border-color", "box-shadow"]),
			 
			  "&:focus": {
				borderRadius: 4,
				borderColor: "blue",
				borderSize: "2px"
			  }
			}
		  }))(InputBase);
	

		const InputClasses = useStylesInput;
		const classes = useStyles;

		return (
			<div className="clearfix mb-2" id="page_2">
			<div className="row" id="zero">
				<div className="col s12 m12 l12" id="zero">
					<div className="row" id="zero">
						<div className="col s12 m3 l1" style={{padding: "0 .75rem"}}>
							<p className="user-info">Title</p>
							<FormControl variant="outlined" className={classes.formControl}>
								<Select
									value={user.title} 
									onChange={this.handleChange}
									label="title"
									inputProps={{
									name: "title",
									}}
									input={<RemoveDefaultBorder />}
								>
									<MenuItem value={"Mr"}>Mr</MenuItem>
									<MenuItem value={"Ms"}>Ms</MenuItem>
									<MenuItem value={"Mrs"}>Mrs</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="col s12 m9 l4" style={{padding: "0 .75rem"}}>
							<p className="user-info">First Name</p>
							<div className="input-border">
						
								<TextField id="outlined-basic" label="" variant="outlined" 
								fullWidth={true}
								
								inputProps={{
									name: "firstName",
								}}
								value={user.firstName} onChange={this.handleChange} />
							
							</div>

						
							{submitted && !user.firstName &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">First name is required</div>
							}
						</div>
						<div className="col s12 m3 l2" style={{padding: "0 .75rem"}}>
							<p className="user-info">Middle Name</p>

							<div className="input-border">
								
									<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "middleName",
									}}
									value={user.middleName} onChange={this.handleChange} />
								
							
							</div>
						</div>
						<div className="col s12 m7 l4" style={{padding: "0 .75rem"}}>
							<p className="user-info">Last Name</p>
							<div className="input-border">
								
									<TextField id="outlined-basic" label="" variant="outlined"
									fullWidth={true}
									inputProps={{
										name: "lastName",
									}}
									value={user.lastName} onChange={this.handleChange} />
								
							
							</div>
							{submitted && !user.lastName &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">Last name is required</div>
							}
						</div>
						<div className="col s12 m2 l1" style={{padding: "0 .75rem"}}>
							<p className="user-info">Suffix</p>
							<div className="input-border">
								
									<TextField id="outlined-basic" label="" variant="outlined"
									fullWidth={true}
									inputProps={{
										name: "suffix",
									}}
									value={user.suffix} onChange={this.handleChange} />
								
							</div>
						</div>
					</div>

					<div className="row" id="zero">
						<div className="col s12 m12" style={{padding: "0 .75rem"}}>
							<p className="user-info">Street Address Line 1</p>
							<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "address1",
									}}
									value={user.address1} onChange={this.handleChange} 
								/>	

							</div>
							{submitted && !user.address1 &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">Primary Address is required</div>
							}
						</div>
					</div>

					<div className="row" id="zero">
						<div className="col s12 m12" style={{padding: "0 .75rem"}}>
							<p className="user-info">Street Address Line 2</p>
							<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "address2",
									}}
									value={user.address2} onChange={this.handleChange} 
								/>
						
							</div>
						</div>
					</div>

					<div className="row" id="zero">
						<div className="col s12 m6 l3" style={{padding: "0 .75rem"}}>
							<p className="user-info">City</p>
							<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "city",
									}}
									value={user.city} onChange={this.handleChange} 
								/>
							
							</div>
							{submitted && !user.city &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">City is required</div>
							}
						</div>
						<div className="col s12 m6 l3" style={{padding: "0 .75rem"}}>
							<p className="user-info">State</p>
							<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "state",
									}}
									value={user.state} onChange={this.handleChange} 
								/>
								
							</div>
							{submitted && !user.state &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">State is required</div>
							}
						</div>
						<div className="col s12 m6 l3" style={{padding: "0 .75rem"}}>
							<p className="user-info">Country</p>
							<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "country",
									}}
									value={user.country} onChange={this.handleChange} 
								/>
							
							</div>
							{submitted && !user.country &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">Country is required</div>
							}
						</div>
							<div className="col s12 m6 l3 " style={{padding: "0 .75rem"}}>
								<p className="user-info">Contact number</p>
								<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "contactNumber",
									}}
									value={user.contactNumber} onChange={this.handleNumber} 
								/>
									
								</div>
								{submitted && !user.contactNumber &&
									<div className="helper-text red-text" data-error="wrong" data-success="right">Pragger Code is required</div>
								}
						</div>
						<div className="col s12 m6 l3 " style={{padding: "0 .75rem"}}>
							<p className="user-info">Nationality</p>
							<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "nationality",
									}}
									value={user.nationality} onChange={this.handleChange} 
								/>
								
							</div>
							{submitted && !user.nationality &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">Nationality is required</div>
							}
						</div>
					</div>
					<div className="row" id="zero">
						<div className="col s12 m12 l4" style={{padding: "0 .75rem"}}>
							<p className="user-info">Mothers Maiden Name</p>
							<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "mothersMaidenName",
									}}
									value={user.mothersMaidenName} onChange={this.handleChange} 
								/>
							
							</div>
						</div>
						<div className="col s12 m6 l4" style={{padding: "0 .75rem"}}>
							<p className="user-info">Marital Status</p>
							<div className="select-border">
							<FormControl variant="outlined" className={classes.formControl}>
								<Select
									value={user.maritalStatus} 
									onChange={this.handleChange}
									label="maritalStatus"
									inputProps={{
									name: "maritalStatus",
									}}
									input={<RemoveDefaultBorder />}
								>
									{selections.maritalStatus.default.map((status, index) =>
										<MenuItem value={ status.value } key={ index }>{ status.label }</MenuItem>
									)}
								</Select>
							</FormControl>
							
							</div>
							{submitted && !user.maritalStatus &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">Status is required</div>
							}
						</div>
						<div className="col s12 m6 l4" style={{padding: "0 .75rem"}}>
							<p className="user-info">Gender</p>
							<div className="select-border">
							<FormControl variant="outlined" className={classes.formControl}>
								<Select
									value={user.gender} 
									onChange={this.handleChange}
									label="gender"
									inputProps={{
									name: "gender",
									}}
									input={<RemoveDefaultBorder />}
								>
								
									<MenuItem value="male">Male</MenuItem>
									<MenuItem value="female">Female</MenuItem>
								</Select>
							</FormControl>
							
							</div>
							{submitted && !user.gender &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">Gender is required</div>
							}
						</div>
					</div>
					<div className="row " id="zero">
						<div className="col s12 m12 l6" style={{padding: "0 .75rem"}}>
							<p className="user-info">Birthdate</p>
							<div className="input-border">
								<DatePicker
									name="birthDate"
									
									onChange={(date)=>this.handleBirthDatePicker(date)}
									showMonthDropdown
									showYearDropdown
									value={user.birthDate == ''? 'No data available' : moment(user.birthDate).format('MMMM D, YYYY')}
									className="datepicker"
								/>
							</div>
							{submitted && !user.birthDate &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">Birthdate is required</div>
							}
						</div>
						<div className="col s12 m12 l6" style={{padding: "0 .75rem"}}>
							<p className="user-info">Birthplace</p>
							<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "birthPlace",
									}}
									value={user.birthPlace} onChange={this.handleChange} 
								/>
								
							</div>
							{submitted && !user.birthPlace &&
								<div className="helper-text red-text" data-error="wrong" data-success="right">Birthplace is required</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
		)
	}

	content_step3(){	
		const { user, submitted, selections, invalidEmail } = this.state;
		const useStyles = makeStyles((theme) => ({
			formControl: {
				margin: theme.spacing(1),
				minWidth: 120
				
			},
			selectEmpty: {
			marginTop: theme.spacing(2)
			},
			maxWidthField: {
				width:"100%",
			},
			borderCustom: {	
				border: "red !important"
			},
			notchedOutline: {
				borderWidth: "1px",
				borderColor: "yellow !important"
			},
		}));

		const classes = useStyles;

		return (
			<div className="clearfix mb-2" id="page_3">
				<div className="row" id="zero">
					<div className="col s12 m12 l12 " id="zero" >
						<div className="row" id="zero">
                     {/*
							<div className="col s12 m12 l4" style={{padding: "0 .75rem"}}>
								<p className="user-info">Organization</p>
								<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "organization",
									}}
									value={user.organization} onChange={this.handleChange} 
								/>
									{submitted && !user.organization &&
										<div className="helper-text red-text" data-error="wrong" data-success="right">organization is required</div>
									}
								
								</div>
							</div>
*/}
							<div className="col s6" style={{padding: "0 .75rem"}}>
								<p className="user-info" style={{padding: "0 .75rem"}}>Email</p>
								<div className="input-border email-disabled">
								<TextField type="email" id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "email",
										type: "email"
									}}
									value={user.email} onChange={this.handleChange} 
									disabled
								/>

								</div>
								{submitted && invalidEmail === true && user.email !== '' &&
									<div className="helper-text red-text" data-error="wrong" data-success="right">Email is invalid</div>
								}
								{submitted && !user.email &&
									<div className="helper-text red-text" data-error="wrong" data-success="right">Email is required</div>
								}
							</div>
                     <div className="col s6" style={{padding: "0 .75rem"}}>
                        <p className="user-info" style={{padding: "0 .75rem"}}>Alternate Email</p>
                        <div className="input-border">
                        <TextField type="email" id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
                           fullWidth={true}
                           inputProps={{
                              name: "altemail",
                              type: "email"
                           }}
                           value={user.altemail === undefined ? '' : user.altemail} onChange={this.handleChange}
                        />

                        </div>
                        {submitted && invalidEmail === true && user.altemail !== '' &&
                           <div className="helper-text red-text" data-error="wrong" data-success="right">Alternate Email is invalid</div>
                        }
                     </div>
							<div className="col s6" style={{padding: "0 .75rem"}}>
								<p className="user-info" style={{padding: "0 .75rem"}}>Username</p>
								<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "praggerUsername",
									}}
									value={user.praggerUsername} onChange={this.handleChange} 
								/>
									
								</div>
								{submitted && !user.praggerUsername &&
									<div className="helper-text red-text" data-error="wrong" data-success="right">Username is required</div>
								}
							</div>
							<div className="col s6" style={{padding: "0 .75rem"}}>
								<p className="user-info" style={{padding: "0 .75rem"}}>Pragger Code</p>
								<div className="input-border">
								<TextField id="outlined-basic" label="" variant="outlined" className={classes.borderCustom}
									fullWidth={true}
									inputProps={{
										name: "praggerCode",
									}}
									value={user.praggerCode} onChange={this.handleChange} 
								/>
									
								</div>
								{submitted && !user.praggerCode &&
									<div className="helper-text red-text" data-error="wrong" data-success="right">Pragger Code is required</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { user, submitted, selections, activeStep } = this.state;
		const { registering, users, office, roles } = this.props;
		const { croppedCoverUrl, croppedImageUrl, avatar, cover } = this.state;
		const steps = this.getSteps();
		const ColorButton = withStyles((theme) => ({
			root: {
			  color: "#FFFFFF !important",
			  backgroundColor: '#CE069E !important',
			  '&:hover': {
				backgroundColor: "#98006F !important",
			  },
			},
		  }))(Button);
		const useStyles = makeStyles((theme) => ({
			root: {
			  width: '100%',
			},
			backButton: {
			  marginRight: theme.spacing(1),
			},
			instructions: {
			  marginTop: theme.spacing(1),
			  marginBottom: theme.spacing(1),
			},
			formControl: {
				margin: theme.spacing(1),
				minWidth: 120
			},
			selectEmpty: {
			marginTop: theme.spacing(2)
			}
		}));	

		const classes = useStyles;

		const managerSelection = function () {

			let managerSelect = [];

			for (let manager in users.items) {
				//Skip push for logged in user
				if (users.items[manager]._id !== user._id) {
					// check if details array is undefine
					if (typeof users.items[manager].details[0] !== "undefined") {
						if (typeof users.items[manager].details[0].firstName !== "undefined") {
							managerSelect.push({
								label: users.items[manager].details[0].firstName + " " + users.items[manager].details[0].lastName,
								value: users.items[manager]._id
							});
						}
					}
				}
			}

			return managerSelect;
		}

		return (
			<div className={"userBodyContainer"}>
				{/* <SideMenu /> */}
				{/* <div className="userEditContainer webappUserEdit" > */}
				<div className="webappUserEdit" >
					<div className="center">
						{registering &&
							<img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
						}
					</div>
					<div id="uploadAvatar">{this.uploadAvatar()}</div>
					<div id="uploadCover">{this.uploadCover()}</div>
					<div className="user-edit-page-container" id="form">
						<div className="row" id="zero">
							<div className="col s12 m12 l12" id="zero">
								<p className="UserTitle">
									{/* <span className="UserTxt1"><b>User Management</b></span> |
									<span className="UserTxt2">{Edit Profil</span> */}
								</p>
							</div>
						</div>
						<form ref="addUser" name="form" autoComplete="off">
							<div className={classes.root}>
								<Stepper activeStep={activeStep} alternativeLabel>
									{steps.map((label) => (
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
									))}
								</Stepper>
								<div>
									<div>
										<div className={classes.instructions}>{this.getStepContent(activeStep)}</div>
										<div>
										<Button
											disabled={activeStep === 0}
											onClick={this.handleBack.bind(this)}
											className={classes.backButton}
										>
											Back
										</Button>

										<ColorButton variant="contained" color="primary" onClick={ activeStep >= steps.length - 1 ?  this.handleNext.bind(this, "completed") : this.handleNext.bind(this, "pending")}>
											{activeStep >= steps.length - 1 ? 'Submit' : 'Next'}
										</ColorButton>
										</div>
									</div>
									{/* )} */}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { users, registration, office, roles } = state;
	const { registering } = registration;

	return { registering, users, office, roles };
}

export default React.memo(connect(mapStateToProps)(UserEditProfilePage));
