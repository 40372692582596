import { combineReducers } from 'redux';

import { authentication } from './auth';
import { registration } from './registration';
import { users } from './user';
import { alert } from './alert';
import { roles } from './role';
import { roleAdd } from './roleAdd';
import { attributes } from './attribute';
import { userAttributes } from './userAttribute';
import { quests } from './quest';
import { badges } from './badges';
import { missions } from './mission';
import { challenges } from './challenge';
import { achievements } from './achievement';
import { leaves } from './leave';
import { overtimes } from './overtime';
import { undertimes } from './undertime';
import { leaveTypes } from './leaveType';
import { coes } from './coe';
import { supportTickets } from './supportTicket';
import { hrConcerns } from './hrConcern';
import { leaveCredits } from './leaveCredit';
import { addleaveCredit } from './addleaveCredit';
import { addOvertimeRequest } from './overtimeRequest';
import { fileLeave } from './fileLeave';
import { pages } from './page';
import { timekeeping } from './timekeeping';
import { office } from './office';
import { exps } from './exp';
import { notifs } from './notification';
import { permissions } from './permission';
import posts, { allPosts } from './post';
import { notification } from './createNotification';
import { currentUser } from './currentUser';
import { attrUsers } from './attrUser';
import { reactions } from './reaction';
import { userFeeds } from './feeds';
import { comments } from './comment';
import { leaveRequest } from './leaveRequest'
import requests from './requests';
import { getStats } from '../reducers/stats'
import { technical } from './technical';
import { timesheet } from './timesheet';
import teams from './teams';
import events from './events';
import { announcements }  from './announcement';
import { session } from './session';
import pageManagement from './pageManagement';

import { tenures } from './tenures';
import questionnaires from './questionnaire';
import evaluations from './evaluation';
import section from './section';

import { userChallenge } from './userChallenge';
import { userTechnicalSupport } from './userTechnicalSupport';
import { userOvertime } from './userOvertime';
import { userLeave } from './userLeave';
import { search } from './search';
import { commends } from './commend';
import { credits } from './credit';
import { reward } from './reward';
import { settings } from './settings';
import { feedback } from './feedback';
import { sms } from './sms';
import { energy } from './energy';
import ess from './ess';

const rootReducer = combineReducers({
  announcements,
  achievements,
  addleaveCredit,
  addOvertimeRequest,
  alert,
  allPosts,
  attributes,
  attrUsers,
  authentication,
  challenges,
  coes,
  commends,
  comments,
  credits,
  currentUser,
  ess,
  evaluations,
  events,
  exps,
  fileLeave,
  getStats,
  hrConcerns,
  leaveCredits,
  leaveRequest,
  leaves,
  leaveTypes,
  missions,
  notification,
  notifs,
  office,
  overtimes,
  undertimes,
  pageManagement,
  pages,
  permissions,
  posts,
  questionnaires,
  quests,
  reactions,
  registration,
  requests,
  roleAdd,
  roles,
  supportTickets,
  teams,
  search,
  section,
  session,
  technical,
  tenures,
  timekeeping,
  timesheet,
  userAttributes,
  userChallenge,
  userFeeds,
  userLeave,
  userOvertime,
  userTechnicalSupport,
  users,
  reward,
  badges,
  settings,
  feedback,
  sms,
  energy
});

export default rootReducer;