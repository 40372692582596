import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Switch, Case, Default } from 'react-if';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { Route } from 'react-router-dom';
import { history } from '../_core/helpers/history';
import { mdToDraftjs, draftjsToMd } from 'draftjs-md-converter';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import ReactTooltip from 'react-tooltip';
// import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import isEmpty from 'lodash/isEmpty';
import { If, Then, Else } from 'react-if';

import { postActions } from '../_core/actions/post';
import { commentService } from '../_core/services/comment';
import { reactionService } from '../_core/services/reaction';
import { reactionActions } from '../_core/actions/reaction';
import { statsActions } from '../_core/actions/stats';
import { grantActions } from '../_core/actions/grant';
import { grantServices } from '../_core/services/grant';
import { userService } from '../_core/services/user';
import Modal from 'react-modal';
import closeIcon from '../_templates/x_round.png';

// import { debounce } from '../_core/helpers/debounce';

import Avatar from '../Components/Avatar/Avatar';
import avatarCallback from '../_templates/man.png';

import './Feeds.scss';

import more from '../_templates/more-vertical.png'
import hearted from '../_templates/heart_red.png';
import heart from '../_templates/heart_grey.png';
import nocomment from '../_templates/comment_grayed.png';
import comment from '../_templates/comment_icon.png';
import PragmanilaLogo from '../_templates/Pragmanila_logo.png';
import PinAnnouncement from '../_templates/pin_announcement.png';
import MentionIndicator from '../_templates/MentionIndicator.png'

import CommentModal from './CommentModal';
//import { users } from '../_core/reducers/user';
// import Dashboard from '../Dashboard/Dashboard';
const _ = require('lodash');

const linkDecorator = (href, text, key) => (
	<a href={href} key={key} target="_blank" rel="noopener noreferrer">{text}</a>
);

const Post = (props) => {
	var path = history.location.pathname;
	let userDetail = JSON.parse(localStorage.getItem('user'));
	let secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

	path = path.includes('dashboard') ? '/dashboard' : path.includes('profile') ? '/profile' : path;

	const [transparent, setTransparent] = React.useState(true);
	const [commentNum, setCommentNum] = React.useState(0);
	const [reactNum, setReactNum] = React.useState(0);
	const [reacted, setReact] = React.useState(false);
	const [commented, setComment] = React.useState(false);
	const [AnnouncementAcknowledge, setAnnouncementAcknowledge] = React.useState(true);
	const [DebounceRequest, setDebounceRequest] = React.useState(false);
	const [usersListMention, setUsersListMention] = React.useState([]);
	const [usersListMultiMention, setUsersListMultiMention] = React.useState([]);
	const [showModal, setModal] = React.useState(false);
	const [updateGrant, setGrant] = React.useState(false);

	const checkRC = props.getReactComment;
	var updateReact = checkRC?.checkReact;

	const dispatch = useDispatch();

	const getReactCommentCounts = () => {


		try {
			commentService.getByOrigin(props._id, 1, 999).then(res => {
				if (res.page) {
					setDebounceRequest(false)
					if (commentNum !== res.total) {
						setCommentNum(res.total);
					}

					const comments = res.items;
					const user = JSON.parse(localStorage.getItem('userCredentials'));
					const currentUserId = user && user.user._id;
					const userCommented = comments.filter(c => c.poster === currentUserId);
					if (userCommented.length > 0) {
						setComment(true);
					}
				}
			});
			reactionService.getByOrigin(props._id, 1, 999).then(res => {
				if (res.page) {
					setReactNum(res.total);
					const reactions = res.items;
					const user = JSON.parse(localStorage.getItem('userCredentials'));
					const currentUserId = user && user.user._id;
					const userReacted = reactions.filter(c => c.poster === currentUserId);
					if (userReacted.length > 0 && userReacted[0].active !== 0) {
						setReact(true);
					} else {
						setReact(false)
					}
				}
			}).catch(error => { });
		} catch (err) {
			console.log(err);
		}
	}

	React.useEffect(() => {
		if (updateReact !== undefined) {
			getReactCommentCounts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateReact])

	const debounceReact = React.useCallback(
		_.debounce(() => {
			const user = JSON.parse(localStorage.getItem("user"));
			getReactCommentCounts();
			dispatch(statsActions.getOneUser(user.user_id));
		}, 2000), [])


	const sendHeart = async (isDisabled) => {

		if (isDisabled === "not-disabled") {
			// e.preventDefault();
			const post = props;
			let IsValid = reacted;
			let totalReact = 0;

			setReact(reacted => !reacted);
			if (IsValid === false) {
				totalReact = reactNum + 1;
			}

			if (IsValid === true) {
				totalReact = reactNum - 1
			}
			setReactNum(totalReact);

			const submitReaction = {
				originId: post._id,
				type: "1"
			};

			await dispatch(reactionActions.postReaction(submitReaction));

			if (DebounceRequest === false) {
				await debounceReact();

				setDebounceRequest(true);
			} else {
				await debounceReact();

			}
		}

	};

	const viewPost = (e, post) => {
		e.preventDefault();
		props.getPost(post);
		history.push(`${path}/view/${post._id}`);
	};

	React.useEffect(() => {
		if (updateGrant) {
			getPostAcknowledgeGrantsByOrigin();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateGrant])

	const grantAcknowledge = (e) => {
		e.preventDefault();
		const grantsContext = {
			type: "Post Announcement",
			origin: props._id,
			value: 1
		}
		dispatch(grantActions.grantPost(grantsContext));
		setTimeout(() => {
			setGrant(true)
		}, 1500)

	}

	const getPostAcknowledgeGrantsByOrigin = (e) => {
		const user = JSON.parse(localStorage.getItem('userCredentials'));
		const currentUserId = user && user.user._id;

		try {
			grantServices.getByOrigin(props._id, 1, 999).then(details => {
				setAnnouncementAcknowledge(false)
				if (!!details.page) {
					details.items.map(userDetails => {

						userDetails.createdDetails.map(userRef => {
							if (userRef._id === currentUserId) {

								setAnnouncementAcknowledge(true)
							}
							return null
						});
						return null
					})
				}

			}).catch(error => { })
		} catch (err) {
			console.log(err)
		}

	}

	const userMentionsGetOne = () => {
		if (props.mentions !== null) {
			if (props.mentions.length !== 0) {
				if (props.mentions.length > 1) {
					let page = 1;
					let totalPage = 999;
					try {
						userService.getAllActiveUsers(page, totalPage).then(res => {
							if (res.page) {

								setUsersListMultiMention(res.items)
								ReactTooltip.rebuild()

							}
						}).catch(err => { })
					} catch (error) {
						console.log(error)
					}

				} else {
					try {
						userService.getUserMention(props.mentions[0]).then(res => {
							if (res.user) {
								const userDetails = res.user[0].details[0];
								setUsersListMention([...usersListMention, userDetails])
								ReactTooltip.rebuild()
							}

						}).catch(error => { })
					} catch (error) {
						console.log(error)
					}
				}
			}
		}
	}

	const displayMentionsIndicator = (userMention) => {
		// If 1 user only mentioned
		let mentionList = userMention;
		let userName = null;

		// Display 1 user indicator only
		if (mentionList !== null) {
			if (mentionList.length === 1) {
				if (usersListMention.length !== 0) {
					usersListMention.map(users => {
						if (userMention.length === 1) {
							if (mentionList[0] === users.user_id) {
								userName = [
									users.firstName,
									users.lastName
								].join(' ')
							}
						}
						return null;
					})
				}
			}
		}

		return userName;

	}
	// const linkIndicator = (userMention) => {
	// 	// If 1 user only mentioned
	// 	let mentionList = userMention;
	// 	let link = null;

	// 	// Display 1 user indicator only
	// 	if(mentionList !== null){
	// 		if(mentionList.length === 1) {
	// 			  link = mentionList[0];
	// 			  return link
	// 		}
	// 	}

	// }

	const DisplayMentioned = (userMentioned) => {
		// React tooltip display mentioned

		let mentionList = userMentioned;
		let filterUser = '';

		let usersName = [];
		if (userMentioned !== null) {
			ReactTooltip.rebuild()

			if (userMentioned.length > 1) {
				if (usersListMultiMention.length !== 0) {
					usersListMultiMention.map((users, i) => {

						if (mentionList.find(x => x === users.details[0].user_id) !== undefined) {
							usersName.push(" " + [
								users.details[0].firstName,
								users.details[0].lastName
							].join(' '))
						}
						return users;
					})

				}
			} else {

				if (usersListMention.length !== 0) {

					usersListMention.map(users => {
						userMentioned.map((userMentionDetails) => {
							if (userMentionDetails === users.user_id) {
								usersName.push([
									users.firstName,
									users.lastName
								].join(' '))

							}
							return null;
						})
						return null;
					})
				}
			}
		}


		if (usersName.length > 1) {
			// Add "and" word in 2nd to last of array and convert it to string
			usersName.splice([usersName.length - 1], 0, " And");
			filterUser = usersName.toString();
			let removeCommas = filterUser.replace(", And,", " and");

			return removeCommas;
		} else {
			return null
		}
	}

	const userMentionImage = (userId) => {
		// Return user image mentioned
		if (usersListMultiMention.length !== 0) {
			let userDetails = usersListMultiMention.find(x => x.details[0].user_id === userId)
			if (userDetails !== undefined) {
				let isEmpty = userDetails.details[0].avatar === '' || userDetails.details[0].avatar === null ? avatarCallback : userDetails.details[0].avatar;
				return isEmpty;
			}
		}
	}

	// const isMentionedYou = (isMentioned, ownerPostId) => {
	// 	// Return total count of user mention and if user logged in are mentioned user display as "you"
	// 	const user = JSON.parse(localStorage.getItem("user"));

	// 	if(isMentioned.length >= 3) {
	// 		let myMention = isMentioned.find(userMentionList => { return user.user_id !== props.userId && userMentionList === user.user_id  })
	// 		if(myMention !== undefined) {
	// 			// If user logged in are mention
	// 			return "you and +" + [props.mentions.length -1]+  " Others";
	// 		} else {
	// 			// Return null and add condition to get total count of user mentioned
	// 			return null;
	// 		}	
	// 	} else {	
	// 		if(usersListMultiMention.length !== 0) {
	// 			let userMentionMaxTwo = [];
	// 			isMentioned.map(userid => {
	// 				let userDetails = usersListMultiMention.find(x => x.details[0].user_id === userid)
	// 				if(userDetails !== undefined) {
	// 					if(user.user_id === userid ){
	// 						// If user currently logged in mentioned
	// 						userMentionMaxTwo.push([" you "])
	// 					} else {
	// 						userMentionMaxTwo.push([
	// 							userDetails.details[0].firstName,
	// 							userDetails.details[0].lastName
	// 						].join(' '))
	// 					}

	// 				}
	// 				return null
	// 			})
	// 			if(userMentionMaxTwo.length !== 0) {
	// 				// Add 'And' word in second of the last of array then convert to string
	// 				userMentionMaxTwo.splice([userMentionMaxTwo.length - 1], 0, " And");
	// 				let filterUser = userMentionMaxTwo.toString();
	// 				let removeCommas = filterUser.replace(", And,", " and ");

	// 				return removeCommas;
	// 			}

	// 		}
	// 	}

	//}

	const isMentionedYou = (isMentioned, ownerPostId) => {
		const user = JSON.parse(localStorage.getItem("user"));

		var str = isMentioned.indexOf(user.user_id);

		const swapArrayElements = (array, index1, index2) => {
			const newArray = array.slice();
			newArray[index1] = array[index2];
			newArray[index2] = array[index1];
			return newArray;
		}

		let swap = swapArrayElements(isMentioned, str, 1)

		if (isMentioned.length >= 3) {

			var array = [];

			for (let i = 0; i < swap.length; i++) {
				let userDetails = usersListMultiMention.find(x => x.details[0].user_id === swap[i])
				if (userDetails !== undefined) {
					if (user.user_id === swap[i]) {
						array.push([" you "])
					} else {
						let name = [userDetails.details[0].firstName, userDetails.details[0].lastName].join(' ')
						array.push(
							<Link to={`/profile/${swap[i]}`} style={{ color: "#039be5" }}>{name}</Link>
						);
					}
				}
			}
			var texts;
			if (array.length >= 3) {
				texts = "others"
			} else {
				texts = "other"
			}

			return <>{array[0]}, {array[1]} and <span style={{ color: "#039be5", cursor: props.mentions.length === 2 ? 'text' : "pointer" }} onClick={OpenModal}>{[swap.length - 2]} {texts}.</span></>


		} else {

			if (usersListMultiMention.length !== 0) {
				let userMentionMaxTwo = [];
				function toEnd(list, position) {
					list.push(list.splice(position, 1));
					return list;
				}

				isMentioned.map(userid => {
					let userDetails = usersListMultiMention.find(x => x.details[0].user_id === userid)
					let name = [userDetails.details[0].firstName, userDetails.details[0].lastName].join(' ')
					if (userDetails !== undefined) {
						if (user.user_id === userid) {
							// If user currently logged in mentioned
							userMentionMaxTwo.push([" you "])
						} else {
							userMentionMaxTwo.push(<Link to={`/profile/${userid}`} style={{ color: "#039be5" }}>{name}</Link>)
						}

					}
					return null
				})
				if (userMentionMaxTwo.length !== 0) {


					userMentionMaxTwo.splice([userMentionMaxTwo.length - 0], 1, " and ");

					//let filterUser = userMentionMaxTwo.toString();
					//let removeCommas = filterUser.replace(", And,", " and ");

					if (isMentioned[0] === user.user_id) {
						return toEnd(userMentionMaxTwo, 0, 1)
					} else {
						return toEnd(userMentionMaxTwo, 1, 1)
					}


				}

			}
		}
	}

	const OpenModal = () => {
		setModal(true);
	}



	React.useEffect(() => {
		setTimeout(() => {
			setTransparent(false);
		}, 100);

		getReactCommentCounts();
		if (props.postType === 'announcement') {
			getPostAcknowledgeGrantsByOrigin();
		}

		userMentionsGetOne();
		ReactTooltip.rebuild();

		return () => { }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setTransparent, props._id]);


	const announcementFeedsContent = () => {

		let markDownContentToDraft = mdToDraftjs(props.postContent);
		let convertCustom = draftjsToMd(convertToRaw(convertFromRaw(markDownContentToDraft)))
		let md = mdToDraftjs(convertCustom)

		return convertFromRaw(md);
	}

	React.useEffect(() => {

		props.loading === false && props.postType === 'announcement' && announcementFeedsContent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.loading, props.postType])



	const OthersList = (value) => {

		const user = JSON.parse(localStorage.getItem("user"));

		if (Array.isArray(value) && value.indexOf(user.user_id) > -1) {
			if (value !== null) {

				let aux = "";

				var str = value.indexOf(user.user_id);
				const items = (arr, i1, i2) => {
					aux = arr[i1];
					arr[i1] = arr[i2];
					arr[i2] = aux;
					return arr;
				};

				let resultMention = items(value, 1, str);

				usersListMultiMention.sort(function (a, b) {
					return resultMention.indexOf(a._id) - resultMention.indexOf(b._id);
				});

				let array = [];

				usersListMultiMention.map((users, i) => {

					if (resultMention.find(x => x === users.details[0].user_id) !== undefined) {

						let name = [users.details[0].firstName, users.details[0].lastName].join(' ')

						array.push(
							<li key={users.details[0].user_id}>
								<div className="modal-img">
									<img className="people-img" src={users.details[0].avatar} id="user-mention-img" alt='' onError={(e) => { e.target.src = avatarCallback }} />
								</div>
								<Link to={`/profile/${users.details[0].user_id}`} className="people-name">{name}</Link>
							</li>
						)
					}
					return users //console.log(users)
				})

				return array.slice(2)

			}
		} else {
			//Not in the array
			let array = [];

			if (value !== null && value.length >= 3) {

				// var swapss = value, x= 1, y= 2;
				// swapss[x] = swapss.splice(y, 1, swapss[x])[0];

				// var swapFirstTwoElementsOf = function (a) {
				// 	return [a[1],a[0]].concat(a.slice(2, a.length));
				// }

				// let result = swapFirstTwoElementsOf(value); 
				const swapArrayElements = (array, index1, index2) => {
					const newArray = array.slice();
					newArray[index1] = array[index2];
					newArray[index2] = array[index1];
					return newArray;
				}

				let resultMention = swapArrayElements(value, 1, 2)

				usersListMultiMention.sort(function (a, b) {
					return resultMention.indexOf(a._id) - resultMention.indexOf(b._id);
				});


				usersListMultiMention.map((users, i) => {

					if (resultMention.find(x => x === users.details[0].user_id) !== undefined) {

						let name = [users.details[0].firstName, users.details[0].lastName].join(' ')

						array.push(
							<li key={i}>
								<div className="modal-img">
									<img className="people-img" src={users.details[0].avatar} id="user-mention-img" alt='' onError={(e) => { e.target.src = avatarCallback }} />
								</div>
								<Link to={`/profile/${users.details[0].user_id}`} className="people-name">{name}</Link>
							</li>
						)

					}
					return users //console.log(users);

				})

				return array.slice(2)
			}


		}

	}

	const handleCloseModal = () => {
		setModal(false);
	}

	const [commendPostContent, setCommendPostContent] = React.useState({});

	

	React.useEffect(() => {
		const posterUser = {
			name: [props?.user_details?.firstName, props?.user_details?.lastName].join(" "),
			_id: props?.user_details?.user_id,
			avatar: props?.user_details?.avatar
		}

		const getCommendedUserId = props.mentions?.length > 0 ? props.mentions[0] : "";

		if (getCommendedUserId) {
			try {
				const userCommended = async () => {
					const res = await userService.getUserMention(getCommendedUserId);
					if (res?.user[0]) {
						let user = res.user[0];
						let commendedUserDetails = {
							name: [user.details[0].firstName, user.details[0].lastName].join(" "),
							_id: user._id,
							avatar: user.details[0].avatar
						}

						const checkContentUser = props.postContent.indexOf(commendedUserDetails.name);
						const isCommenderAnonymous = checkContentUser === 0;

						if (isCommenderAnonymous) { //
							let draftCommendPost = {
								avatar: commendedUserDetails?.avatar,
								_id: commendedUserDetails?._id,
								content: 
									<p>
										<Link to={`/profile/${commendedUserDetails._id}`}>
											{commendedUserDetails?.name}
										</Link>
										{props.postContent.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim()
										// .replace(commendedUserDetails?.name, "") //note: user's name may change, if user's name was editted after commend, this line may not work as expected
										.substr(props.postContent.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim().indexOf(" was commended"), props.postContent.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim().length)}
									</p>,
								date: moment(props.createdAt).fromNow()
							}
							setCommendPostContent(draftCommendPost);
						} else {
							let draftCommendPost = {
								avatar: commendedUserDetails?.avatar,
								_id: commendedUserDetails?._id,
								content:
									<p>
										<Link to={`/profile/${commendedUserDetails._id}`}>
											{commendedUserDetails?.name}
										</Link>

										{
											props.postType === 'credit' ?

											<>
												{
													props.postContent.substr(props.postContent.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim().indexOf(" has been rewarded "))
												}
											</>
										:

											<>
												{props.postContent
												// .replace(posterUser?.name, "").split(commendedUserDetails?.name)[0] //note: user's name may change, if user's name was editted after commend, this line may not work as expected
												.substr(props.postContent.replace(/^\s+/g, "").trim().indexOf(" was commended by"), (" was commended by ").length)}
												<Link to={`/profile/${posterUser._id}`}>
													{posterUser?.name}
												</Link>
												{/* {props.postContent
												// .replace(posterUser?.name, "").replace(/(\b\S.+\b)(?=.*\1)/g, "").trim().split(commendedUserDetails?.name)[1] //note: user's name may change, if user's name was editted after commend, this line may not work as expected
												.substr(props.postContent.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim().indexOf(" saying "), props.postContent.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim().length)
												} */}

												{props.postContent.includes("saying") ?

												props.postContent.substr(props.postContent.replace(/^\s+/g, "").trim().indexOf(" saying "), props.postContent.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim().length)
												:
												props.postContent.substr(props.postContent.replace(/^\s+/g, "").trim().indexOf(" for "), props.postContent.replace(/(\b\S.+\b)(?=.*\1)/g, "").trim().length)
												}

											</>
										}
										
									</p>,
								date: moment(props.createdAt).fromNow()
							}
							setCommendPostContent(draftCommendPost);
						}
					}
				}
				userCommended();
			} catch (error) {
				console.error(error);
			}
		}
	}, [props]);

	if (props.postType === "commend" || props.postType === "credit") {

		return (
			<div className={cc(["card", 'z-depth-0', transparent, "commend-feed"])}>
				<div className="profile">
					<Link to={`/profile/${commendPostContent?._id}`}>
						<Avatar
							size={50}
							src={commendPostContent?.avatar}
						/>
					</Link>
					<React.Fragment>
						{commendPostContent?.content}
					</React.Fragment>
					<button>
						<img src={more} alt="more" />
					</button>
				</div>
				<div className="content">
					<p>{commendPostContent?.date}</p>
					<div className={secretDetails !== null ? "disabled-link post-stats" : 'post-stats' }>
						<div>
							<div onClick={(e) => sendHeart(props.post_userId === userDetail.user_id ? "disabled" : "not-disabled")} data-tip="" data-for={props.post_userId === userDetail.user_id ? "disabled" : "not-disabled"}>
								{reacted ?
									<img src={hearted} alt="hearted" />
									:
									<img src={heart} alt="heart" />
								}
								<p>{reactNum || 0}</p>
							</div>
							<div onClick={(e) => viewPost(e, props)}>
								{commented ?
									<img src={comment} alt="comments" />
									:
									<img src={nocomment} alt="nocomment" />
								}
								<p>{commentNum || 0}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	//else
	return (
		<div
			className={cc([
				props.postType === 'announcement' ? 'card-announcement' : "card",
				'z-depth-0',
				transparent,
			])}

		>

			<Modal
				isOpen={showModal}
				ariaHideApp={true}
				toggle={setModal}
				onRequestClose={() => setModal(false)}
				shouldCloseOnOverlayClick={true}
				className="people-mentions"
			>
				<div className="other-mentions-container">

					<div className="other-mentions-header">
						<div id="people-title">
							<p>People</p>
						</div>
						<img alt="" src={closeIcon} onClick={() => setModal(false)} style={{ cursor: "pointer" }} />
					</div>

					<div className="acknowledge-content">
						<ol onClick={handleCloseModal}>
							{OthersList(props.mentions, props.mentions && props.mentions.length)}
						</ol>

					</div>
				</div>

			</Modal>
			{/* <ReactTooltip  
					id={"userMention" + props._id}  
					type="light" 
					className="userMention-tooltip-custom"
				
				  > 
				  <span style={{ color: "#0091FF"}}>{DisplayMentioned(props.mentions)} </span> 
				  
				</ReactTooltip> */}


			<div className="profile">
				{props.postType === 'announcement' ?
					<img src={PragmanilaLogo} height="35" width="30" alt="PragmaLogo" />
					:
					<Link to={`/profile/${props.userId}`}>
						<Avatar
							size={50}
							src={props.user_details.avatar}
						/>
					</Link>
				}

				<div>
					<Link to={`/profile/${props.userId}`}>

						{props.postType === 'announcement'
							?
							<>
								<p style={{ fontSize: "18px", fontWeight: "600" }}>Announcement</p>
								<p style={{ fontWeight: "400" }}>Administrative Announcement</p>
							</>
							: <If condition={props.mentions && props.mentions.length === 0}>
								<Then>
									{
										[
											props.user_details.firstName,
											props.user_details.lastName
										].join(' ')
									}

								</Then>

								<Else>
									<>
										<span style={{ marginRight: "10px" }}>
											{
												[
													props.user_details.firstName,
													props.user_details.lastName
												].join(' ')
											}
										</span>
										{<If condition={props.mentions && displayMentionsIndicator(props.mentions) !== null}>
											<Then>
												<img src={MentionIndicator} height="10" width="10" alt="MentionIndicator" />
												<span style={{ marginLeft: "10px" }}>
													<Link to={props.mentions && `/profile/${props.mentions[0]}`}>
														{
															displayMentionsIndicator(props.mentions)
														}
													</Link>
												</span>
											</Then>
										</If>}

									</>

								</Else>

							</If>

						}

					</Link>
					<p>{moment(props.createdAt).fromNow()}</p>
				</div>
				{props.postType === 'announcement' ?
					<button>
						<img src={PinAnnouncement} alt="pinPost" />
					</button>
					:
					<button>
						<img src={more} alt="more" />
					</button>}
			</div>
			<div className="content">
				<Linkify componentDecorator={linkDecorator}>

					{props.loading === false && props.postType === 'announcement' && <div className="anouncement-contents">
						<Editor
							editorState={
								isEmpty(props?.postContent) ?
									EditorState.createEmpty() :
									EditorState.createWithContent(announcementFeedsContent())

							}
							readOnly
							toolbarHidden
						/>
					</div>

					}


					{/* { props.postType === "reward" &&
						 <p>
							<Editor
								editorState={
									isEmpty(props?.postContent) ?
										EditorState.createEmpty() :
										EditorState.createWithContent(announcementFeedsContent())
								}
								readOnly
								toolbarHidden
							/>
						</p> 
					} */}

					{ props.postType === "reward" ?
						 <p>
							<Editor
								editorState={
									isEmpty(props?.postContent) ?
										EditorState.createEmpty() :
										EditorState.createWithContent(announcementFeedsContent())
								}
								readOnly
								toolbarHidden
							/>
						</p> 
						:
						props.postType !== "announcement" && <p>{props.postContent}</p>
					}
					
					{/* { props.postType === "credit" &&
						 <p>
								{
									props?.postContent.split(' ').map((ind, index) => props?.postContent.split(' ').indexOf('rewarded') + 1 === index ? <b> {ind} </b> : ind + " ")
								}
						</p> 
					} */}
				</Linkify>
				{
					props.postImage && (
						<div className="post-image">
							<img
								src={props.postImage}
								alt="post"
							/>
						</div>
					)
				}
				<div className={secretDetails !== null ? "disabled-link post-stats" : 'post-stats' } >
					{props.postType === "announcement" &&
						<div className="post-Acknowledge">
							<button
								className={cc([AnnouncementAcknowledge === true ? "btn-Acknowledge-disabled" : "btn-Acknowledge"])}

								onClick={(e) => grantAcknowledge(e)}
								style={{ cursor: AnnouncementAcknowledge === true ? "not-allowed" : "pointer" }}
								disabled={AnnouncementAcknowledge}
							> {AnnouncementAcknowledge === true ? "ACKNOWLEDGED" : "ACKNOWLEDGE"}  </button>
						</div>
					}

					<ReactTooltip
						id='disabled'
						type="light"

					>
						{"this feature is not available for your own content"}
					</ReactTooltip>

					<div>
						<div onClick={(e) => sendHeart(props.post_userId === userDetail.user_id ? "disabled" : "not-disabled")} data-tip="" data-for={props.post_userId === userDetail.user_id ? "disabled" : "not-disabled"}>


							{reacted ?
								<img src={hearted} alt="hearted" />
								:
								<img src={heart} alt="heart" />
							}
							<p>{reactNum || 0}</p>

						</div>
						<div onClick={(e) => viewPost(e, props)}>
							{commented ?
								<img src={comment} alt="comments" />
								:
								<img src={nocomment} alt="nocomment" />
							}
							<p>{commentNum || 0}</p>
						</div>

					</div>


				</div>
				{props.mentions !== null && props.mentions.length !== 0 && DisplayMentioned(props.mentions) !== null && <div className="post-MentionDisplay">
					<span>
						<b
							style={{ color: "#0091FF" }}
							data-tip='' data-for={props.mentions.length === 2 ? "" : "userMention" + props._id}
						>
							<span className={props.mentions.length >= 3 ? "user-mention-contents-v1" : "user-mention-contents-v2"}>
								{props.mentions.length >= 3 && <span className="user-mentions-imgs">
									<img className="user1-mention-img" src={userMentionImage(props.mentions[0])} id="user-mention-img" alt='' onError={(e) => { e.target.src = avatarCallback }} />
									<img className="user2-mention-img" src={userMentionImage(props.mentions[1])} id="user-mention-img" alt='' onError={(e) => { e.target.src = avatarCallback }} />
									<img className="user3-mention-img" src={userMentionImage(props.mentions[2])} id="user-mention-img" alt='' onError={(e) => { e.target.src = avatarCallback }} />
								</span>}
								<span className="user-mention-details" style={{ top: "auto" }}>
									With {isMentionedYou(props.mentions, props.mentions && props.mentions.length)}
								</span>
							</span>

						</b>
					</span>
				</div>
				}
			</div>
		</div>
	);
};

const SkeletonPost = () => {
	const [transparent, setTransparent] = React.useState(true);

	React.useEffect(() => {
		const timeoutId = setTimeout(() => {
			setTransparent(false);
		}, 100);
	
		return () => {
			// Clear the timeout when the component is unmounted
			clearTimeout(timeoutId);
		};
	}, [setTransparent]);

	return (
		new Array(2).fill(null).map((_, index) => (
			<div
				className={cc({
					'skeleton-post': true,
					'card': true,
					'z-depth-0': true,
					transparent
				})}
				key={index}
			>
				<SkeletonTheme color="#e0e0e0">
					<div className="profile">
						<Skeleton circle width={50} height={50} />
						<div>
							<p>
								<Skeleton height={15} width={120} />
							</p>
							<p className="date">
								<Skeleton height={10} width={80} />
							</p>
						</div>
					</div>
					<div className="content">
						<p>
							<Skeleton height={15} width="100%" />
						</p>
						<p>
							<Skeleton height={15} width="80%" />
						</p>
						<p>
							<Skeleton height={15} width="80%" />
						</p>
						<div className="post-stats">
							<div>
								<Skeleton height={15} width={30} />
							</div>
							<div>
								<Skeleton height={15} width={30} />
							</div>
						</div>
					</div>
				</SkeletonTheme>
			</div>
		))
	);
}

const Feeds = (props) => {
	var path = history.location.pathname;
	path = path.includes('dashboard') ? '/dashboard' : path.includes('profile') ? '/profile' : path;
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = React.useState(1);
	const [getReactComment, setReactComment] = React.useState({});

	React.useEffect(() => {
		dispatch(postActions.resetPosts());
	}, [dispatch]);

	React.useEffect(() => {
		if (props.singleUser && !props.userId) {
			return;
		}

		if (props.userId) {
			dispatch(postActions.getAllByPage({ page: 1, userId: props.userId }));
		} else {
			dispatch(postActions.getAllByPage({ page: 1 }));
		}
		setCurrentPage(currentPage => currentPage + 1);
	}, [dispatch, setCurrentPage, props.userId, props.singleUser]);

	const loadMorePosts = React.useCallback(() => {
		dispatch(postActions.loadMore({ page: currentPage, userId: props.userId }));
		setCurrentPage(currentPage => currentPage + 1);
	}, [currentPage, dispatch, setCurrentPage, props.userId]);

	const ref = useInfiniteScroll({
		loading: props.loading,
		hasNextPage: props.hasNextPage,
		onLoadMore: loadMorePosts,
		threshold: 400
	});

	var checkPost = {};

	const getPost = (post) => {
		if (Object.keys(post).length !== 0) {
			checkPost = post;
		}
		return post
	}

	const reactCommentStats = (rc) => {
		setReactComment(rc);
	}


	return (
		<div className="feeds" ref={ref} style={{ minHeight: props.minHeight }}>
			{
				props.items.map(post => (
					<Post key={post._id} post_userId={post.userId} loading={props.loading} {...post} getPost={getPost} getReactComment={getReactComment} />
				))
			}
			<div
				className={
					cc([
						'all-caught-up',
						{
							center: (
								props.items.length === 0 &&
								props.loading === false
							)
						}
					])
				}
				style={{
					minHeight: (
						props.items.length === 0 &&
						props.loading === false
					) && props.minHeight
				}}
			>
				<Switch>
					<Case condition={props.loading}>
						<SkeletonPost />
					</Case>
					<Case condition={props.items.length === 0}>
						<p>No posts to show</p>
					</Case>
					<Default>
						All caught up
					</Default>
				</Switch>
			</div>
			<Route
				exact
				path={`${path}/view/:postId`}
				render={() => <CommentModal getPost={checkPost} checkReactComment={reactCommentStats} />}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	...state.posts,
	items: state.posts.items.map(item => ({
		...item,
		postImage: item.postImage?.[0],
		user_details: item.user_details[0]
	}))
});

export default React.memo(connect(mapStateToProps)(Feeds));
