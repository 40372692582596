import { get, put, post } from '../helpers/api';
import { userService } from '../services/user';

export const leaveService = {
    getAllById,
    getAll,
    getById,
    approve,
    hold,
    reject,
    update,
    fileLeave,
    getAllFilter

};

function getAll(page, itemsPerPage) {
    return get(`/api/v1/request?filter=type:leave&page=${page || 1}&items-per-page=${itemsPerPage || 20}`).then(handleResponse);
}

function getAllFilter(page = 1, itemsPerPage = 20, search, filter = "") {
    let fields = `${filter ==='refCode' ? "refCode:" + search : filter === 'description' ?"description:" + search : ""}`
    return get(`/api/v1/request?page=${page}&items-per-page=${itemsPerPage}&search=${search}&filter=type:leave,${fields}`).then(handleResponse);
}

function getAllById(selectPage) {
    var page = selectPage ? selectPage : "1"
    var user = JSON.parse(localStorage.getItem('userCredentials'));
    var id = user.user._id;
    return get(`/api/v1/user-leaves?page=${page}&items-per-page=10`, id).then(handleResponse);
}

function getById(id) {
    return get(`/api/v1/request/${id}`).then(handleResponse);
}

function createActivity(activity,id) {
    return post(`/api/v1/user/${id}/activity`, JSON.stringify(activity)).then(handleResponse);
}

function approve(leave) {
    let id = leave._id;
    delete leave._id;
    return put(`/api/v1/request/${id}/approve`, JSON.stringify(leave))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });

   
}

function hold(leave) {
    let id = leave._id;
    delete leave._id;
    return put(`/api/v1/request/${id}/hold`, JSON.stringify(leave))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });
}

function reject(leave) {
    let id = leave._id;
    delete leave._id;
    return put(`/api/v1/request/${id}/reject`, JSON.stringify(leave))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });
}

function update(leave) {
    let id = leave._id;
    delete leave._id;
    return put(`/api/v1/request/${id}`, JSON.stringify(leave))
        .then(handleResponse);
}

function fileLeave(leave) {
    return post(`/api/v1/leave`, JSON.stringify(leave))
        .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}
