import React from 'react';
import cc from 'classcat';

import { UserDashboardContext } from '../UserDashboard/UserDashboard';

import burger from '../_templates/burger.png';
import chevronLeft from '../_templates/chevron-left.png';

import './Sub.scss';

const Sub = (props) => {
    const device = React.useContext(UserDashboardContext).device;

    return (
        <div className="col s6 l4">
            <div
                className={cc([
                    "sub-navbar",
                    device
                ])}
            >
                <button onClick={() => { props.toggleIsSidebarExpanded(state => !state) }}>
                    <img
                        src={props.isSidebarExpanded ? chevronLeft : burger}
                        alt="expand-collapse"
                    />
                </button>
                <h6>{props.title}</h6>
            </div>
        </div>
    );
}

export default Sub;