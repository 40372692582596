import React, { useEffect }from 'react'
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
import { checkSideNav } from '../Components/checkSideMenu';
import { Route, Switch } from 'react-router-dom';
import { If, Else, Then,  } from 'react-if';
import Select, { components } from 'react-select';
import Menu, { Item as MenuItem } from 'rc-menu';
import ReactImageFallback from "react-image-fallback";
import Pagination from 'react-js-pagination';
import Swal from 'sweetalert2';
import Dropdown from 'rc-dropdown';
import BodyBackgroundColor from 'react-body-backgroundcolor';

import { userActions } from "../../_core/actions/user.js";
import teamActions from "../../_core/actions/teams";
import { history } from '../../_core/helpers/history';
import avatar from '../../_templates/man.png';
import { decryptUserDetails, decryptUserEmail } from '../../_core/helpers/crypto_config';
import { roleActions } from '../../_core/actions/role';
import { roleService } from '../../_core/services/role';

import "./UserManagementDark.scss";
import 'rc-dropdown/assets/index.css';
import DocumentTitle from "react-document-title";
import { PageWrapper } from "../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent } from "../Components/PageWrapper/DynamicWrapper";
import CreateEditUser from './CreateEditUser';
import dropdownIndicator from '../../_templates/dropdown_chevrondown.png';
import search from '../../_templates/search_icon.png'
import emptyIcon from '../../_templates/empty.png';

import styled from "styled-components";

import Cookies from 'universal-cookie';

const DivSearch = styled.div`
    display: inline-block;
    position: relative;
    margin-right: 25px;

    .search-dropdown {
        position: absolute;
        top: 50%;
        right: 7px;
        transform: translateY(-50%);
    }

    .input-search {
        background: url(${search}) no-repeat left center;
        background-position: 10px;
        background-size: 20px;
        display: inline-block;
        height: 40px !important;
        background-color: #2E3648 !important;
        border: 1px solid #323A4F !important;
        margin-top: 8px !important;
        padding: 0 10px 0 35px !important;
        font-size: 0.8125rem !important;
        width: 239px !important;
        color: #9BA4C7
    }

    .input-search::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #9BA4C7 ;
        opacity: 1
    } 
    
    .input-search:-ms-input-placeholder {
        color: #9BA4C7 
    }
    
    
    .input-search::-ms-input-placeholder {
        color: #9BA4C7 
    }


`;

const mapStateToProps = ({users,teams,roles}) => ({
    users,
    roles,
    teams: [
        {
            value: '',
            label: 'No assigned team'
        },
        ...(
            teams.items && teams.items.map(team => ({
                label: team.name,
                value: team._id
            }))
        )
    ],
});

const UserManagementDark = (props) => {
    const dispatch = useDispatch();
    let buttonHeaderContent = "ADD USER";
    let showEntries = "Show Entries";
    let admin = JSON.parse(localStorage.getItem('userCredentials'));

    const [totalData, setTotalData] = React.useState(0);
    const [ActivePage, setActivePage] = React.useState(0);
    const [Action, setAction] =  React.useState('');
    const [loggedId, setLocalId] =  React.useState('');
    const [currentValue, setCurrentValue] = React.useState(10);
    const [GetTotalPerpage, setGetTotalPerpage] = React.useState(10);

    const [update, setUpdate] =  React.useState(false);
    const cookies = new Cookies();

    const [search, setNewSearch] = React.useState("");
    const [filteredResults, setFilteredResults] = React.useState([]);

    let fromPage = ((ActivePage -1) * GetTotalPerpage) + 1
    let toPage =  Math.min((ActivePage - 1)* GetTotalPerpage + GetTotalPerpage, totalData)

    const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));
   

    var setRoles = [];
    if(props.roles?.roles?.items){
        setRoles = [
            {
                value: '',
                label: 'No assigned role'
            },
            ...(
                props.roles.roles.items.map(role => ({
                    label: role.roleName,
                    value: role._id
                }))
            )
        ]
    }


    const [filteredData,setFilteredData] = React.useState([]);
    const [filter, setFilter] =  React.useState(false);

    useEffect(() => {
        let defaultPage = 1;
       // let itemPerPage = cookies.get("Users:"+admin.user._id) ? cookies.get("Users:"+admin.user._id) : currentValue;
        dispatch(userActions.getAll(defaultPage,1000000));
        dispatch(teamActions.getAll(1000000, 1));
        dispatch(roleActions.getAll(1, 1000000));
    }, [update,dispatch,admin.user._id])//eslint-disable-line

    

    useEffect(() => {
        setFilteredData(props.users?.users?.items);
    }, [props,search]);

    useEffect(() => {

        if(filter){
            setTotalData(filteredResults.length)
            setActivePage(1)
        }else{
            setTotalData(props.users.totalPerPage)
            setActivePage(props.users.currentPage)
        }
    }, [props,filter,filteredResults])



    // Slice props when searching
    const indexOfLastItem = ActivePage * GetTotalPerpage;
    const indexOfFirstItem = indexOfLastItem - GetTotalPerpage;
    const currentPosts = filteredResults.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (page) => {
        setActivePage(page);
    };

    const statusOption = [
        { value: 1, label: "Active"},
        { value: 0, label: "InActive"}
    ];

    const handleSearchChange = (searchValue) => {
        setNewSearch(searchValue)
        const roleName = setRoles.filter((item) => {
            return Object.values(item.label).join('').toLowerCase().includes(searchValue)
        }).map((filtered, i) => {return filtered.value})

        const filterStatus = statusOption.filter((item) => {
            return Object.values(item.label).join('').toLowerCase().includes(searchValue)
        }).map((filtered, i) => {return filtered.value})

        if (search !== '') {
            const results = filteredData.filter((item) => {
                return Object.values(decryptValues(item.email) || item).join('').toLowerCase().includes(searchValue.toLowerCase()) ||
                Object.keys(item.details[0]).some(key =>
                    typeof item.details[0][key] === "string"
                    && item.details[0][key].toLowerCase().includes(searchValue.toLowerCase())
                    && item.details[0][key] !== item.details[0].avatar
                    && item.details[0][key] !== item.details[0].cover
                    && item.details[0][key] !== item.details[0].photo
                    && item.details[0][key] !== item.details[0].email
                    && item.details[0][key] !== item.details[0]._id
                    && item.details[0][key] !== item.details[0].user_id
                    && item.details[0][key] !== item.details[0].officeId
                    && item.details[0][key] !== item.details[0].department
                    && item.details[0][key] !== item.details[0].city
                ) || roleName.includes(item.role) || filterStatus.includes(item.active)
            })
            setFilteredResults(results)
            setFilter(true)
        }
        else{
            setFilteredResults(filteredData)
        }
    }

    
    const handleFilter = ({ key }) => {

        if(key === 'email') {
            const email = filteredData.filter((item) => {
                return Object.values(decryptValues(item.email)).join('').toLowerCase().includes(search)
            })
            setFilteredResults(email)
            setFilter(true)
        }else if(key === 'name'){
            const name = filteredData.filter((item) => {
                return Object.values(item.details[0].firstName).join('').toLowerCase().includes(search) ||
                Object.values(item.details[0].lastName).join('').toLowerCase().includes(search)
            })
            setFilteredResults(name)
            setFilter(true)
        }else if (key === 'role'){

            const roleName = setRoles.filter((item) => {
                return Object.values(item.label).join('').toLowerCase().includes(search)
            }).map((filtered, i) => {return filtered.value})

            const role = filteredData.filter((item) => {
                return roleName.includes(item.role)
            })

            setFilteredResults(role)
            setFilter(true)
        }else if(key === 'active'){
            const filterStatus = statusOption.filter((item) => {
                return Object.values(item.label).join('').toLowerCase().includes(search)
            }).map((filtered, i) => {return filtered.value})

            const active = filteredData.filter((item) => {
                return filterStatus.includes(item.active)
            })
            setFilteredResults(active)
            setFilter(true)

        }

    };


    useEffect(()=>{
        let newItems = cookies.get("Users:"+admin.user._id)
        if(update !== false){
           cookies.set("Users:"+admin.user._id, currentValue, { path: "/", sameSite:'lax' })
            setUpdate(false)
            setGetTotalPerpage(cookies.get("Users:"+admin.user._id))
        }else{
            setGetTotalPerpage(newItems ? newItems : currentValue);
            setUpdate(false)
        }
        
    },[props,currentValue,update,admin,cookies]);

    // const handlePageChange = (page) => {
    //     dispatch(userActions.getAllSearchFilter(page,GetTotalPerpage,search2));
    // }

    /* ----------------Custom input type number ----------------*/
        const topArrowClicked = (e) => {
            if (currentValue < 50){
                if(currentValue === 20){
                    setCurrentValue(prevState => prevState + 30);
                    setUpdate(true)
                }else{
                    setCurrentValue(prevState => prevState + 10);
                    setUpdate(true)
                }
            }
        }
        const bottomArrowClicked = (e) => {
            if (currentValue > 10){
    
                if(currentValue === 50){
                    setCurrentValue(prevState => prevState - 30);
                    setUpdate(true)
                }else{
                    setCurrentValue(prevState => prevState - 10);
                    setUpdate(true)
                }
            }
        }
    
        const InputArrows = ({topArrowClick, bottomArrowClick}) => {
    
            return (
              <div className="arrows-component">
                <div className="arrows">
                  <button onClick={topArrowClick}>
                  <i className="fa fa-angle-up" aria-hidden="true" style={{cursor:'pointer'}}></i>
                  </button>
                  <button onClick={bottomArrowClick}>
                    <i className="fa fa-angle-down" aria-hidden="true" style={{cursor:'pointer'}}></i>
                  </button>
                </div>
              </div>
            );
          };
    /* --------------------------------------------------------*/

    const decryptValues = (val) =>  {
		let isValid = !!val !== null || val !== '' || val !== undefined;
		let decryptVal = '';
		if(isValid){
			decryptVal = decryptUserEmail(val) === '' ? val : decryptUserEmail(val);
		}
		return decryptVal;
    }
    
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: 'none',
            borderColor: '#9e9e9e',
            minHeight: 35,
            height: 35,
            border: 'none',
            boxShadow: state.isFocused ? null : null,
        }),

        option: (base, state) => ({
            ...base,
            color: '#9BA3CA',
            backgroundColor: state.isSelected ? '#32394A' : '#2A3143',
            ':hover': {
              backgroundColor: state.isSelected ? '#2A3143' : '#32394A'
            }
          }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: 35,
            padding: '0 6px',
        }),

        input: (provided, state) => ({
            ...provided,
            height: 35,
            color: '#9BA3CA',
            lineHeight: state.isFocused ? 35 : 0,
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: 35,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
            transition: '0.5s'
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
          })
    }

    const saveRole = (
        e,
        users
        ) => {
        const roleName = setRoles.filter(role => role.value === e)?.[0]?.label;
        Swal.fire({
            html:
                "<p class='swal-title-dark'>Save Role?</p> <br> <b class='swal-subtitle-dark'>Role: " + roleName + "</b>",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#24DC98',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save!',
            background: '#2E3549',
        }).then(async ({ value }) => {
            if (value) {
                const updateRoles = {
                    "_id":users._id,
                    "email": decryptValues(users.email),
                    "team":users.team,
                    "organization": users.organization,
                    "praggerUsername": users.praggerUsername,
                    "role": e,
                    "sssNumber":decryptUserDetails(users.sssNumber),
                    "philhealthNumber":decryptUserDetails(users.philhealthNumber),
                    "pagibigNumber":decryptUserDetails(users.pagibigNumber),
                    "praggerCode":users.praggerCode,
                };
                await props.dispatch(userActions.editRole(updateRoles));
            }
        });
    }

    const onSelect = ({ key }) => {
        console.log(`${key} selected`);
    }
      
    const onVisibleChange = (visible) => {
        setAction(visible)
    }

    const handleEditUser = () => {
        // const userId = Action._id;
        // props.dispatch(userActions.getByIdPage(userId, ActivePage))
        props.selectedUserOnEdit(Action);
        history.push(`${props.match.path}/edit`);
    }

    const handleImpersonate = () => {

        localStorage.setItem('secretDetails', JSON.stringify(Action.details[0]));
        localStorage.setItem('secretCredentials', JSON.stringify(Action));

        roleService.getOneRole(Action.role).then((res) => {
            if (res.items) {
                localStorage.setItem('secretRole', JSON.stringify(res.items));
            }
            console.log(res.items)
          }).catch((error) => {
            throw error;
          });
        console.log(Action)
        history.push(`/profile/${Action !== null && Action._id}`)
    }

    const handleAddUserAttributes = () =>{
        Swal.fire({
            html:
                "<p class='swal-title-dark'>Sorry</p> <br> <b class='swal-subtitle-dark'>This feature is currently under development</b> ",
            type: 'error',
            showCancelButton: false,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Ok',
            background: '#2E3549',
        });
    }

    const handleActiveStatus =() => {
        const id = Action._id;
        const name = Action.details[0].firstName + ' ' + Action.details[0].lastName;
        const active = Action.active;
        const activeStatus =  Action.active === 1 ? "Active" : "Not Active";

        Swal.fire({
            title: active === 1 ? '<p class="swal-subtitle-dark">Deactivate user?</p>' : '<p class="swal-subtitle-dark"> Activate user? </p>',
            html:
                "<br> <b class='swal-subtitle-dark'>Name: "+ name + "</b>" + 
                "<br> <b class='swal-subtitle-dark'>Active Status: " + activeStatus +"</b>",

            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#24DC98',
            cancelButtonColor: '#d33',
            confirmButtonText: active === 1 ? 'Deactivate' : 'Activate',
            background: '#2E3549',
        }).then(async (result) => {
            if (result.value) {
                if(active === 1){
                    await props.dispatch(userActions.deactivate(id));
                    await props.dispatch(userActions.getAll( ActivePage, 10));
                }else{
                
                    await props.dispatch(userActions.activate(id));
                    await props.dispatch(userActions.getAll( ActivePage, 10));
                }
            }
        });
    }


    const handleDeleteUser = () => {
        const userId = Action._id;
        const name = Action.details[0].firstName + " " + Action.details[0].lastName || Action.details.firstName + " " + Action.details.lastName;
        const teamId = Action.team;
        const activeStatus =  Action.active === 1 ? "Active" : "Not Active";
        const teamName = props.teams.filter(team => team.value === teamId)?.[0]?.label;
        setLocalId(admin.user._id)

        if (loggedId !== userId) {
            Swal.fire({
                title: '<p class="swal-subtitle-dark"> Delete this user? </p>',
                html:
                    "<br> <b class='swal-subtitle-dark'>Name: " + name + "</b> <br> <b class='swal-subtitle-dark'>Team: "
                    + teamName + " </b> <br> <b class='swal-subtitle-dark'>Active Status: "
                    + activeStatus + "</b>",

                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#24DC98',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete!',
                background: '#2E3549',
            }).then(async (result) => {
                if (result.value) {
                    await props.dispatch(userActions.delete(userId));
                    await props.dispatch(userActions.getAll( ActivePage, 10));
                }
            });
        } else {
            Swal.fire({
                type: 'error',
                title: 'Unable to Delete',
                text: "Can't delete currently logged in user",
            });
        }

    }
    const addPermission = JSON.parse(localStorage.getItem('addPermission'));
    const editPermission = JSON.parse(localStorage.getItem('editPermission'));
    const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

    const menu = (
        <Menu onSelect={onSelect} className="action-dropdown">
            {
                (editPermission.includes("Access - Users") === true) || (roleAllTrue === 1) ?
                <MenuItem key="1" onClick={handleEditUser} className="action-menu"> <i className="fa fa-pencil" aria-hidden="true" style={{marginRight:'10%'}} ></i>Edit</MenuItem> : false
            }
          {(addPermission.includes("Access - Attributes") === true) || (roleAllTrue === 1) ?
              <MenuItem key="2" onClick={handleAddUserAttributes} className="action-menu" ><i className="fa fa-plus" aria-hidden="true" style={{marginRight:'10%'}}></i> Add Attribute</MenuItem> : false
          }
          <MenuItem key="3" onClick={handleActiveStatus} className="action-menu"> 
                <i className={Action.active === 1 ? "fa fa-ban" : "fa fa-check-circle-o"}  aria-hidden="true" style={{marginRight:'10%'}}></i>
                {Action.active === 1 ? "Deactivate " : "Activate "}
            </MenuItem>
          {(deletePermission.includes("Access - Users") === true) || (roleAllTrue === 1) ?
            Action.deleting ? <em> Deleting...</em>
                : Action.deleteError ? <span className="text-danger"> - ERROR: {Action.deleteError}</span>
                : <MenuItem key="4" onClick={handleDeleteUser} className="action-menu" ><i className="fa fa-trash" aria-hidden="true" style={{marginRight:'10%'}}></i> Delete </MenuItem> : false
               
         }
         <MenuItem key="5" onClick={handleImpersonate} className="action-menu"> <i class="fa fa-user-secret" aria-hidden="true" style={{marginRight:'10%'}}></i>Impersonate</MenuItem>
        </Menu>
      );

    //userOptions
    const filterUsers = (
        <Menu onSelect={onSelect} className="action-dropdown">
            <MenuItem disabled className="disabled-dropdown">Filters</MenuItem>
            <MenuItem key="name" onClick={handleFilter} className="action-menu" >Name</MenuItem>
            <MenuItem key="email" onClick={handleFilter} className="action-menu" >Email</MenuItem>
            <MenuItem key="role" onClick={handleFilter} className="action-menu" >Role</MenuItem>  
            <MenuItem key="active" onClick={handleFilter} className="action-menu" >Status</MenuItem>    
        </Menu>
    );


    const DropdownIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
        return (
          <components.DropdownIndicator {...props}>
          </components.DropdownIndicator>
        );
    };


    return (
        
        <BodyBackgroundColor backgroundColor='#1C2233'>
        <div className="list_user" >


            <div className="table-card">
                <div className="table-header">
                {
                    (addPermission.includes("Access - Users") === true) || (roleAllTrue === 1) ?
                    <button className={secretDetails !== null ? "darkmode-btn-default disabled-link" : 'darkmode-btn-default' } onClick={ (e) => history.push('/admin/users/create')}>{ buttonHeaderContent }</button> : false
                }
                    
                    

                    <div className="block">
                        <DivSearch>
                            <input className='input-search' type='text' placeholder='Search' onChange={(e) => handleSearchChange(e.target.value)}/>
                                <Dropdown
                                    trigger={['click']}
                                    overlay={filterUsers}
                                    // onVisibleChange={onVisibleChange}
                                    animation="slide-up"
                                >   
                                <div style={{width:'100%'}}><img src={dropdownIndicator} alt="Logo" className='search-dropdown'/></div>        
                                </Dropdown>
                        </DivSearch>
                        <span className="darkmode-btn-default entries test">{ showEntries } </span>
                        <div className="custom-input">
                            <input type="text" className="entries-btn" name="clicks" value={GetTotalPerpage ? GetTotalPerpage : currentValue} pattern="[0-9]*" style={{pointerEvents:'none'}}/>
                            <span className="arrows">
                                <InputArrows 
                                    topArrowClick={topArrowClicked} 
                                    bottomArrowClick={bottomArrowClicked} 
                                />
                            </span>
                        </div>
                    </div>

                </div>
                <div className="table-responsive">
                    <table className = "responsive-table responsive-user">
                        <thead>
                            <tr>
                                <th> Name </th>
                                <th> Email </th>
                                {/* <th> Team </th> */}
                                <th> Role </th>
                                <th> Status </th>
                                <th style={{textAlign: 'center'}}> Action </th>
                            </tr>   
                        </thead>
                      
                        <tbody>
                          <If condition={props.users.loading === false }> 
                            <Then>
                            {search.length > 3 ? (

                                currentPosts.length !== 0 ?                                 

                                    currentPosts.map((users) => {
                                        return (
                                            <tr className="table-td-data" key ={users._id}>
                                                <td className="username">
                                                    <div className="valign-wrapper">
                                                        <ReactImageFallback
                                                            src={users.details[0].avatar || users.details.avatar}
                                                            fallbackImage={avatar}
                                                            initialImage={avatar}
                                                            alt="user avatar"
                                                            className="user-photo avatar circle responsive-img"
                                                        />
                                                        <span className="admin-user-name">{users.details[0].firstName || users.details[0].firstName} {users.details[0].lastName || users.details.lastName} {users.details[0].suffix || users.details.suffix}</span>
                                                    </div>
                                                </td>
                                                <td className = "email">{ decryptValues(users.email) }</td>
                                                <td className="team-select role-dropdown">
                                                    <Select
                                                        components={{ DropdownIndicator }}
                                                        captureMenuScroll={false}
                                                        options={setRoles}
                                                        value = {setRoles.filter(role=>(role.value === users.role))[0]}
                                                        className="basic-single"
                                                        styles={customStyles}
                                                        onChange={(event) => {
                                                            saveRole(
                                                                event.value, 
                                                                users )
                                                            }}
                                                        maxMenuHeight={220}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={document.querySelector('body')}
                                                        classNamePrefix="select" 
                                                    />
                                                </td>
                                                <td className={users.active === 1 ? "active" : "inactive"}> {users.active === 1 ? "Active" : "In-active"} </td>
                                                <td className="user-action">
                                                    <Dropdown
                                                        trigger={['click']}
                                                        overlay={menu}
                                                        animation="slide-up"
                                                        onVisibleChange={()=>onVisibleChange(users)}
                                                    >
                                                    <div className="dot-container" >
                                                        <span className="dot"></span>
                                                        <span className="dot"></span>
                                                        <span className="dot"></span>
                                                    </div>
                                                    </Dropdown>

                                                </td>
                                                        
                                            </tr> 
                                            )})
                                            :
                                            <tr>
                                                <td colSpan="12" style={{textAlign:'center'}}>
                                                    <img src={emptyIcon} className="empty" alt="empty"/>
                                                    <p style={{fontSize:'16px'}}>No Data Available</p>
                                                    <p style={{fontSize:'14px'}}>There are currently no data available</p>
                                                </td>
                                            </tr>
                                        ) 
                                        : 
                                        (props.users.loading === false && props.users.users.items.map((users)=>  (

                                            <tr className="table-td-data" key ={users._id}>
                                                <td className="username">
                                                    <div className="valign-wrapper">
                                                        <ReactImageFallback
                                                            src={users.details[0].avatar || users.details.avatar}
                                                            fallbackImage={avatar}
                                                            initialImage={avatar}
                                                            alt="user avatar"
                                                            className="user-photo avatar circle responsive-img"
                                                        />
                                                        <span className="admin-user-name">{users.details[0].firstName || users.details[0].firstName} {users.details[0].lastName || users.details.lastName} {users.details[0].suffix || users.details.suffix}</span>
                                                    </div>
                                                </td>
                                                <td className = "email">{ decryptValues(users.email) }</td>
                                                        {/* <td  className="team-select team">
                                                            <Select
                                                                components={{ DropdownIndicator }}
                                                                captureMenuScroll={false}
                                                                className="basic-single"
                                                                menuPlacement="auto"
                                                                onChange={(event) => {
                                                                        saveTeam(
                                                                            event.value, 
                                                                            users._id, 
                                                                            decryptValues(users.email), 
                                                                            users.organization,
                                                                            users.praggerUsername,
                                                                            users.role,
                                                                            decryptValues(users.sssNumber),
                                                                            decryptValues(users.philhealthNumber),
                                                                            decryptValues(users.pagibigNumber),
                                                                            users.praggerCode )
                                                                        }}
                                                                options={props.teams}
                                                                placeholder="Select team"
                                                                styles={customStyles}
                                                                // value={props.teams.filter(team=>(team.value === users.team))[0]}
                                                                value={props.teams.filter(option => option.value === users.team)}
                                                                menuPortalTarget={document.getElementById('root')}
                                                                hideSelectedOptions={false}
                                                                classNamePrefix="select" 
                                                                maxMenuHeight={200}
                                                            />
                                                        </td> */}
                                                <td className={secretDetails !== null ? "team-select role-dropdown disabled-link" : 'team-select role-dropdown' }>
                                                    <Select
                                                        components={{ DropdownIndicator }}
                                                        captureMenuScroll={false}
                                                        options={setRoles}
                                                        value = {setRoles.filter(role=>(role.value === users.role))[0]}
                                                        className="basic-single"
                                                        styles={customStyles}
                                                        onChange={(event) => {
                                                            saveRole(
                                                                event.value, 
                                                                users )
                                                            }}
                                                        maxMenuHeight={220}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={document.querySelector('body')}
                                                        classNamePrefix="select" 
                                                    />
                                                </td>
                                                <td className={users.active === 1 ? "active" : "inactive"}> {users.active === 1 ? "Active" : "In-active"} </td>
                                                <td className={secretDetails !== null ? "user-action disabled-link" : 'user-action' }>
                                                    <Dropdown
                                                        trigger={['click']}
                                                        overlay={menu}
                                                        animation="slide-up"
                                                        onVisibleChange={()=>onVisibleChange(users)}
                                                    >
                                                    <div className="dot-container" >
                                                        <span className="dot"></span>
                                                        <span className="dot"></span>
                                                        <span className="dot"></span>
                                                    </div>
                                                    </Dropdown>
                    
                                                </td>
                                                        
                                            </tr> 
                                            )).slice(indexOfFirstItem, indexOfLastItem))}

                            </Then>
                            <Else>
                                <tr className="table-td-data">
                                    <td> 
                                    <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                                        <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </div>
                                    </td>
                                </tr>
                            </Else>
                        
                        </If> 

                        </tbody>
                    </table>
                </div>
               
                <div className="table-footer">
                    
                    {totalData !== 0 && <div className="dataCount"> Showing { fromPage } to { toPage  } of {  totalData } entries  </div> } 
                        
                

                    <Pagination
                        activeClass="pagination_navigtion_active"
                        itemClass="pagination_navigtion"
                        linkClass="pagination_navigtion_link"
                        
                        hideDisabled
                        hideFirstLastPages
                        prevPageText='Previous'
                        nextPageText='Next'
                        pageRangeDisplayed={10}
                        activePage={ActivePage}
                        itemsCountPerPage={GetTotalPerpage}
                        totalItemsCount={totalData}
                        
                        onChange={(e) => handlePageChange(e)}
                    />
                </div>
            </div>
        </div>
        </BodyBackgroundColor>
    )
}

const UserManagementDarkPage = connect(mapStateToProps)((props) => {

    const [breadCrumbs, setBreadCrumbs] = React.useState([]);
    const [editUserDetails, setEditUserDetails] = React.useState({});

    const filterBreadCrumbs = (mode) => {
        if (mode === "list") {
            setBreadCrumbs([
                { label: "Users", link: "/admin/users?page=1" },
            ]);
        } else {
            let createBreadCrumbs = [
                { label: "Users", link: "/admin/users?page=1" },
                { label: "Create User", link: "/admin/users/create" },
            ]
            let editBreadCrumbs = [
                { label: "Users", link: "/admin/users?page=1" },
                { label: "Edit User", link: "/admin/users/edit" },
            ]
            setBreadCrumbs(mode === "create" ? createBreadCrumbs : editBreadCrumbs);
        }
    };

    React.useEffect(() => {
        filterBreadCrumbs(props.mode);
    }, [props.mode]);

    return (
        <BodyBackgroundColor backgroundColor='#1C2233'>
            <PageWrapper className={cc(["UsersPage", checkSideNav()?.trim()])}>
                <DocumentTitle
                    title={
                        props.mode === "create"
                            ? `Create User | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                            : props.mode === "edit"
                                ? `Edit User | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                                : `Access | Users | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                    }
                />
                <DynamicHeaderContent
                    TitlePage="Access"
                    breadCrumbs={true}
                    breadCrumbsPages={breadCrumbs}
                />
                <If condition={['create', 'edit'].includes(props.mode)}>
                    <Then>
                        <CreateEditUser {...props} userDetails={editUserDetails}/>
                    </Then>
                    <Else>
                        <UserManagementDark {...props} selectedUserOnEdit={user => setEditUserDetails(user)}/>
                    </Else>
                </If>
            </PageWrapper>
        </BodyBackgroundColor>
    )
});
UserManagementDarkPage.WrappedComponent.displayName = 'UserManagementDarkPage';
const UserManagementDarkPageRoute = (props) => (
	<Switch>
		<Route
            exact
            path={`${props.match.path}/create`}
            render={(props) => <UserManagementDarkPage {...props} mode="create" />}
        />
        <Route
            exact
            path={`${props.match.path}/edit`}
            render={(props) => <UserManagementDarkPage {...props} mode="edit" />}
        />
        <Route
			exact
			path={`${props.match.path}`}
			render={(props) => <UserManagementDarkPage {...props} mode="list" />}
		/>
		
	</Switch>
);
export default React.memo(UserManagementDarkPageRoute);