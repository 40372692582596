import React from "react";
import { history } from '../../_core/helpers/history';
import { connect } from 'react-redux';

// import { userLeaveRequest } from '../../_core/actions/UserLeave';
import { essActions } from '../../_core/actions/ess';
// import { leaveTypeActions } from '../../_core/actions/leaveType'
import { leaveCreditActions } from '../../_core/actions/leaveCredit'

import Modal from 'react-modal';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
// import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import './RequestLeaveModal.sass';

import holiday from '../../holiday.js';
import PolicyList from "../PolicyList";
import { leaveHours } from '../../_core/helpers/config'

import Dropzone from "./Dropzone";

class RequestLeaveModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            userDetails: null,
            leave: {
                subject: "",
                description: "",
                type: "leave",
                subtype: "",
                startedAt: "",
                endedAt: "",
                files:[]
            },

            leaveCreditList: [],

            startOfDate: null,
            endOfDate: null,
            date: '',
            insufficient: '',

            HolidayList: [],

            itemPerPage: 10,

            render: true,
            submitted: false,
        }

        this.getEndDate = this.getEndDate.bind(this)
        this.getStartDate = this.getStartDate.bind(this)
        this.isWeekDay = this.isWeekDay.bind(this)
        this.disableHoliday = this.disableHoliday.bind(this)
    }

    componentDidMount() {
        if(this.props.leaveCredits.loading !== true){
            this.getAllLeaveTypes();
        }

    }

    componentDidUpdate(prevProps) {
        const { leaveCreditList } = this.state;

        if (prevProps.leaveCredits !== this.props.leaveCredits.leaveCredits) {

            // Callback Api if page refresh
            if (leaveCreditList.length === 0 && this.props.leaveCredits.errors === undefined) {
                const credits = this.props.leaveCredits.leaveCredits !== undefined ? this.setState({
                    leaveCreditList: this.props.leaveCredits?.leaveCredits,
                }, () => {
                })
                    : null;
                return credits;
            }
        }

    }

    getAllLeaveTypes() {
        const user = JSON.parse(localStorage.getItem("user"));
        this.props.dispatch(leaveCreditActions.getUser(user.user_id));
        // this.props.leaveCredits.leaveCredits && this.setState({
        //     leaveCreditList: this.props.leaveCredits?.leaveCredits,
        // }, () => {
        // });
    }


    close(e) {
        e.preventDefault();
        const { itemPerPage } = this.state;
        const userDetails = JSON.parse(localStorage.getItem("userCredentials"));
        this.props.dispatch(essActions.getAllByUser(userDetails.user._id, 1, itemPerPage))
        history.goBack();
    }

    getTitle(e) {
        const leave = this.state.leave;
        this.setState({
            leave: {
                ...leave,
                subject: e.target.value,
            }
        }, () => {
        });
    }

    getDescription(e) {

        const leave = this.state.leave;
        this.setState({
            leave: {
                ...leave,
                description: e.target.value,
            }
        }, () => {
        });
    }


    disableHoliday() {
        let holidayLists = []

        holiday.items.map((holiday) => {

            let dateDay = new Date(holiday.date);
            var day = dateDay.getDay();
            var isWeekend = (day === 6) || (day === 0);
            if (isWeekend === false) {
                holidayLists.push(new Date(holiday.date));
            }

            return null // This line Do nothing // just remove warning
        })
        return holidayLists;
    }


    getStartDate(date) {

        const leave = this.state.leave;
        this.setState({
            leave: {
                ...leave,
                startedAt: moment(date).format("YYYY-MM-DDT00:00:00.000") + "Z",
            },

            startOfDate: date,
        }, () => {
            this.isValidDate();
        });
    }

    getEndDate(date) {
        const leave = this.state.leave;
        this.setState({
            leave: {
                ...leave,
                endedAt: moment(date).format("YYYY-MM-DDT00:00:00.000") + "Z",
            }
        }, () => {
            // this.isInsufficient();
            // this.isValidDate();
            const TempLeaveTypes = {
                vacation: "vacation",
                medical: "sick",
                maternity: "maternity",
                paternity: "paternity",
                bday: "birthday"
            }
            let isInsufficient = '';
            const { leave } = this.state;
            let startDay = moment(leave.startedAt);
            let endDay = moment(leave.endedAt);
            let dateRange = endDay.diff(startDay, "days");
    
            // check weekend
            let dateRange2 = [];
            let datediff = startDay.diff(endDay, "days");
    
            while (datediff + 2 <= 0) {
                startDay.add(1, "days").format("dddd, MMMM DD YYYY");
                dateRange2.push(startDay.format("dddd, MMMM DD YYYY"));
                datediff++;
            }
    
            const myDays = dateRange2.map(daysss  => (
                daysss.split(',')[0]
            ));
            const hasSaturday = myDays.includes("Saturday");
            const hasSunday = myDays.includes("Sunday");
    
            hasSaturday 
            ? hasSunday 
                ? dateRange - 1 > this.GetLeaveCredits(TempLeaveTypes.vacation) ? isInsufficient=true:isInsufficient=false
                : dateRange + 1 > this.GetLeaveCredits(TempLeaveTypes.vacation) ? isInsufficient=true:isInsufficient=false
            : dateRange + 1 > this.GetLeaveCredits(TempLeaveTypes.vacation) ? isInsufficient=true:isInsufficient=false

            this.setState({
                leave: {
                    ...leave,
                    endedAt: moment(date).format("YYYY-MM-DDT00:00:00.000") + "Z",
                },
                endOfDate: date,
                insufficient: isInsufficient,
            })
            if(isInsufficient === false) {
                this.isValidDate();
            }
        });
    }    

    isValidDate() {
        const { leave, HolidayList } = this.state;
        const storeHolidayDate = [];

        let isWeekend = false;
        let day = '';
        let dateDay = '';

        HolidayList.splice(0, HolidayList.length);
        storeHolidayDate.splice(0, storeHolidayDate.length)

        holiday.items.map((holiday) => {

            // Check if selected start date or end date has holiday
            if ((moment(holiday.date).format() === moment(leave.startedAt).format() && leave.startedAt !== '')
                || (moment(holiday.date).format() === moment(leave.endedAt).format() && leave.endedAt !== '')) {

                // Filter to prevent duplicate holiday input in an array
                if (storeHolidayDate.indexOf(holiday) === -1) {
                    dateDay = new Date(holiday.date);
                    day = dateDay.getDay();
                    isWeekend = (day === 6) || (day === 0);
                    if (isWeekend === false) {
                        storeHolidayDate.push(holiday)
                    }
                }
                this.setState({ HolidayList: storeHolidayDate })

            }

            // Check if between seleceted start date and end date has Holiday
            if (leave.startedAt !== '' && leave.endedAt !== '') {
                if (moment(moment(holiday.date).format())
                    .isBetween(moment(leave.startedAt).format(),
                        moment(leave.endedAt).format(), null, '[]')) {

                    // Filter to prevent duplicate holiday input in an array
                    if (storeHolidayDate.indexOf(holiday) === -1) {
                        dateDay = new Date(holiday.date);
                        day = dateDay.getDay();
                        isWeekend = (day === 6) || (day === 0);
                        if (isWeekend === false) {
                            storeHolidayDate.push(holiday)
                        }
                    }

                    this.setState({ HolidayList: storeHolidayDate }, () => {

                        if (storeHolidayDate.length !== 0) {
                            Swal.fire({
                                title: 'Selected Date Range Invalid',
                                html:
                                    "<div>Selected Date Range has a holiday </div> <div style='color:rgb(38, 166, 154)'>Note: Select Date range does not have a holiday</div>",
                                type: 'error',
                                confirmButtonColor: '#26a69a',
                                confirmButtonText: 'OK'
                            })
                        }

                    })
                }
            }

            return null // To remove warning 
        })
    }

    insufficientCredits(e) {
        e.preventDefault();

        Swal.fire({
            title: ' insufficient credits',
            
            type: 'warning',
            showCancelButton: true,
            showConfirmButton: false,
            confirmButtonColor: '#26a69a',
            cancelButtonColor: '#d33',
            cancelButtonText: 'OK'
        })
    }


    handleSubmit(e) {
        e.preventDefault();
        const { leave, HolidayList, leaveCreditList} = this.state;
        const {render} = this.props;

       // const user = JSON.parse(localStorage.getItem("user"));

        this.setState({ submitted: true })

        let StartDate = document.querySelector("#startedAt").value;
        let EndDate = document.querySelector("#endedAt").value;

        let dateNow = Date(Date.now()).toLocaleString();
        let dateFormat = moment(dateNow).format("ddd, MMMM DD, YYYY");

        let getSubType = leaveCreditList.filter(item => item?.name?.toLowerCase()?.includes(render?.toLowerCase())); //TODO: refactor render - leave subtype


        if (leave.subject && leave.description && leave.startedAt && leave.endedAt && getSubType.length > 0) {
            if (HolidayList.length !== 0) {

                Swal.fire({
                    title: 'Selected Date Range Invalid',
                    html:
                        "<div>Selected Date Range has a holiday </div> <div style='color:rgb(38, 166, 154)'>Note: Select Date range does not have a holiday</div>",
                    type: 'error',
                    confirmButtonColor: '#26a69a',
                    confirmButtonText: 'OK'
                })

            } else {
                Swal.fire({
                    title: 'Submit request?',
                    html:
                        "<br> <b> Check the following  date if correct:</b> <br> " +
                        "<br> <b> Start of Date:</b> " +
                        StartDate +
                        "<br> <b>End of Date:</b>  " +
                        EndDate,
                    footer: 'Filing Date:  ' + dateFormat,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#26a69a',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Submit'
                }).then((result) => {
                    if (result.value) {
                        leave.subtype = getSubType[0]._id;
                        this.props.dispatch(essActions.create(leave));
                    }
                })
            }
        }

    }

    handleDateChangeRaw(e) {
        e.preventDefault();
    }

    validateWhiteSpaces(e) {
        if (/^\s/.test(e.target.value)) { // Not accepting Whitespaces in first input. 
            e.target.value = '';
        }
    }

    isWeekDay(date) {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    }

    onChangeDropdown(e) {
    }

    renderOption() {
        const options = [
            { value: '', label: 'Emergency Leave' },
            { value: '', label: 'Maternity Leave' },
            { value: '', label: 'Paternity leave' },
            { value: '', label: 'Sick Leave' },
            { value: '', label: 'Vacation leave' },

        ]
        return options;
    }

    GetLeaveCredits(type) {
        const { leaveCreditList } = this.state;
        let leaveAvailable = 0;
        //console.log(leaveHours.time)

            leaveCreditList.forEach(leave => {
                if (leave.name?.toLowerCase()?.includes(type)) {
                    leaveAvailable = leave.available / leaveHours.time;
                }
            })
            return leaveAvailable


    }


    render() {
        const { submitted, leave} = this.state;
        let dateNow = Date(Date.now()).toLocaleString();
        let dateFormat = moment(dateNow).format("dddd, MMMM DD, YYYY");
        const { render } = this.props;
        

        const TempLeaveTypes = {
            vacation: "vacation",
            medical: "sick",
            maternity: "maternity",
            paternity: "paternity",
            bday: "birthday"
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={this.close.bind(this)}
                // ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                className="RequestModalUser"
                centered
            >
                <div className="leaveRequest">
                    <div className="header-container">
                        <div className="title-container">
                            <span> Create {render} Leave Request </span>
                        </div>
                        <div className="dateFiled-container">
                            <span> Filing Date: {dateFormat}</span>
                        </div>
                    </div>

                    <div className="content-container">
                        <form name="form" onSubmit={this.state.insufficient ?  this.insufficientCredits :  this.handleSubmit.bind(this)} autoComplete="off">


                            {/* Request Title */}
                            <div className="row">
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Request Title
                                    </label>

                                    {/* Temporary comment Class for dropdown 'input-custom-dropdown */}
                                    {/* <div className ="input-custom-dropdown">  */}

                                    {/* Temporay hide for Dropdown request */}
                                    {/* <Select 
                                  options={this.renderOption()} 
                                  placeholder="Select Leave"
                                  onChange={this.onChangeDropdown.bind(this)}
                                /> */}

                                    <div className="input-custom">
                                        <input type="text" className="form-control requestTitle"
                                            id="requestTitle" onChange={this.getTitle.bind(this)} onInput={this.validateWhiteSpaces.bind(this)} />
                                    </div>
                                    {submitted && !this.state.leave.subject &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">Request title is required</div>
                                    }
                                </div>
                            </div>

                            {/* Request Description */}
                            <div className="row">
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Description
                                    </label>

                                    <div className="input-custom">
                                        <textarea style={{ border: "none", padding: "10px", resize: "vertical" }} placeholder="Description"  className="form-control requestDescription"
                                            id="requestDescription" onChange={this.getDescription.bind(this)} onInput={this.validateWhiteSpaces.bind(this)} />
                                    </div>
                                    {submitted && !this.state.leave.description &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">Description is required</div>
                                    }
                                </div>
                            </div>

                            {/* Date field */}
                            <div className="row">
                                {/* Select Start Date */}
                                <div className="col s12 m6 l6">
                                    <label className="label-title" htmlFor="">
                                        Select start date
                                    </label>
                                    <div className="input-custom">
                                        <DatePicker
                                            className="sqUserCalendar dateValue"
                                            name="date"
                                            id="startedAt"
                                            placeholderText="Choose date"
                                            dateFormat="dddd, MMMM DD, YYYY"
                                            selected={this.state.startOfDate}



                                            filterDate={(date) => this.isWeekDay(date)} // Disable Saturday & Sunday but still highlighted when time range set
                                            value={this.state.startOfDate ? moment(this.state.startOfDate).format('ddd, MMM DD, YYYY') : ''}
                                            onChange={(date) => this.getStartDate(date)}
                                            onChangeRaw={this.handleDateChangeRaw.bind(this)} // Prevent raw input
                                            excludeDates={this.disableHoliday()}


                                            selectsStart
                                            startDate={this.state.startOfDate}
                                            endDate={this.state.endOfDate}
                                            maxDate={this.state.endOfDate}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>
                                    {submitted && !this.state.startOfDate &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">Date is required</div>
                                    }
                                </div>


                                {/* Select End Date */}
                                <div className="col s12 m6 l6">
                                    <label className="label-title" htmlFor="">
                                        Select end date
                                    </label>
                                    <div className="input-custom">
                                        <DatePicker
                                            className="sqUserCalendar dateValue"
                                            name="date"
                                            id="endedAt"
                                            placeholderText="Choose date"
                                            dateFormat="ddd, MMM DD, YYYY"
                                            selected={this.state.endOfDate}

                                            filterDate={(date) => this.isWeekDay(date)} // Disable Saturday & Sunday but still highlighted when time range set
                                            value={this.state.endOfDate ? moment(this.state.endOfDate).format('ddd, MMM DD, YYYY') : ''}
                                            opentoDate={(moment()).isAfter(this.state.startOfDate) ? this.state.endOfDate == null ? moment() : null : null}
                                            excludeDates={this.disableHoliday()}

                                            onChange={(date) => this.getEndDate(date)}
                                            onChangeRaw={this.handleDateChangeRaw.bind(this)}  // Prevent raw input 

                                            selectsEnd
                                            startDate={this.state.startOfDate}
                                            endDate={this.state.endOfDate}
                                            minDate={this.state.startOfDate}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>
                                    {submitted && !this.state.endOfDate &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">Date is required</div>
                                    }
                                </div>

                                {this.state.insufficient &&
                                    <div className="col s12 m6 l12">
                                    <div className="help-block red-text" data-error="wrong" data-success="right">Insufficient credits</div>
                                </div>}
                            </div>

                            {/* Attachment Files*/}
                            <div className="row">
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Attach File 
                                    </label>
                                    <Dropzone item={items => {
                                        this.setState({leave: {
                                            ...leave,
                                            files: items,
                                        }})
                                    }}/>
                                </div>
                            </div>

                            {/* Temporay Guideline in the bottom*/}
                            <div className="row">
                                <div className="col s12">
                                    <PolicyList type={"leave"} index={0} />
                                </div>
                            </div>




                            {/* Button submit */}
                            <div className="footer-container">
                                <div>
                                    <button type="submit" className="waves-effect waves-light btn sroUserSubmitBtn" id="submit">Submit</button>


                                    <button className="waves-effect waves-light btn sroUserCancelBtn" id="cancel" onClick={this.close.bind(this)}>Cancel</button>
                                </div>
                            </div>
                        </form>

                        {/* Temporay guideline in rightside */}
                        <div className="row">
                            <div className="col s12 m12 l12">
                                <label className="label-title" htmlFor="">
                                    Details:
                                </label>
                                <div className="policyLeaveCredits">
                                    <p>Leave Credits</p>
                                    <div className="LeaveCredits-container">
                                        <div className="LeaveCredits">
                                            Vacation Leave  <span style={{ textAlign: "right" }}>{this.GetLeaveCredits(TempLeaveTypes.vacation)}</span>
                                        </div>
                                        <div className="LeaveCredits">
                                            <span> Sick Leave </span>
                                            <span style={{ textAlign: "right" }}>{this.GetLeaveCredits(TempLeaveTypes.medical)}</span>
                                        </div>
                                        <div className="LeaveCredits">
                                            <span> Maternity Leave </span>
                                            <span style={{ textAlign: "right" }}>{this.GetLeaveCredits(TempLeaveTypes.maternity)}</span>

                                        </div>
                                        <div className="LeaveCredits">
                                            <span> Birthday Leave </span>
                                            <span style={{ textAlign: "right" }}>{this.GetLeaveCredits(TempLeaveTypes.bday)}</span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>  {/* content-container end */}
                </div>
            </Modal>
        )

    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { userLeave, leaveTypeActions, leaveTypes, leaveCredits } = state;
    return {
        leaveCredits,
        leaveTypes,
        leaveTypeActions,
        userLeave,
        alert,
    };
}

export default React.memo(connect(mapStateToProps)(RequestLeaveModal));
