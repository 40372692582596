
import { get } from '../helpers/api';
import { userService } from '../services/user';

export const undertimeService = {
    getAllById,
    getAll

};

function getAllById(selectPage) {
    var page = selectPage ? selectPage : "1";
    var user = JSON.parse(localStorage.getItem('userCredentials'));
    var id = user.user._id;
    return get(`/api/v1/user-request?page=${page}&items-per-page=10`, id).then(handleResponse);
}

function getAll(page, itemsPerPage) {
    return get(`/api/v1/request?page=${page}&items-per-page=${itemsPerPage}&filter=subtype:undertime`).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}