import { userLeaveConstants } from '../constants/UserLeave';

let initialState = {
  isValid: null,
}

export function userLeave( state = initialState, action ){
    switch(action.type){
        case userLeaveConstants.POST_USER_LEAVE_REQUEST:
          return {
            ...state,
            isValid: null,
           
          };
        case userLeaveConstants.POST_USER_LEAVE_REQUEST_SUCCESS:
          return {
            ...state,
            isValid: true,
          }
        case userLeaveConstants.POST_USER_LEAVE_REQUEST_FAILED:
          
            return {
              ...state,
              isValid: false,
            }
        default:
        return state
    }
}