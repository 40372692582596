import React, { useEffect, useState }from 'react'

import ReactImageFallback from "react-image-fallback";
import avatar from '../../../_templates/man.png';
import { history } from '../../../_core/helpers/history';

import "./profileWidget.scss"

const ProfileWidget = (props) => {
    const [detail, setDetails] = useState(null);

    let isWelcome = "Welcome back!,";

    const filterUserDetails = (userDetails = []) => {
        const localUserDetails = JSON.parse(localStorage.getItem("userCredentials"));
        const secretUserDetails = JSON.parse(localStorage.getItem("secretCredentials"));
        const unknownDetails = secretUserDetails !== null ? secretUserDetails : localUserDetails.user;

            userDetails.length !== 0 && userDetails.map((users) => {
                if(users._id === unknownDetails._id ) {
                    setDetails({
                        firstName: users.details[0].firstName === '' || users.details[0].firstName === null ? '' : users.details[0].firstName ,
                        lastName: users.details[0].lastName === '' || users.details[0].lastName === null  ? '' : users.details[0].lastName ,
                        points: users.credit,
                        position: users.details[0].position === '' ? "N/A" : users.details[0].position ,
                        projects: 0,
                        userId: users._id,
                        userAvatar: users.details[0].avatar
                    })
                }
                return null
            })
    }

    useEffect(() => {
       if(props.users.users !== undefined){
        filterUserDetails(props.users.users.items);
       }
    }, [props])

 
    let userAvatars = detail !== null && detail.userAvatar;
    return (
        <div className="profileWidget_Admin">

            {/* <If condition={detail !== null}>
            <Then> */}
                <div className="profile_widget_container">
                    <div className="profile_widget_container_header">
                    <div className="profile_widget_container_header_title"> {isWelcome  }   </div>
                        <div className="profile_widget_container_header_label"> {detail !== null && detail.firstName + " Dashboard"}</div>
                    </div>

                    <div className="profile_widget_container_float_img">
                        <ReactImageFallback 
                            src={ userAvatars === false ? "" : userAvatars}
                            fallbackImage={avatar}
                            initialImage={avatar}
                            alt=""
                            className="wid-admin-avatar"
                        />
                    </div>
                    <div className="profile_widget_container_content"   >
                        <div className="profile_widget_container_content_userDetails_grid1">
                            <div className="profile_widget_container_content_userDetails_grid1_userName">
                                {detail !== null && detail.firstName + " " + detail.lastName }
                        </div>
                            <div className="profile_widget_container_content_userDetails_grid1_userJob">
                                {detail !== null && detail.position }
                         </div>
                            <button className="profile_widget_container_content_userDetails_grid1_btn" onClick ={ (e) => history.push(`/profile/${detail !== null && detail.userId}`) }>
                                View Profile
                             </button>
                        </div>

                        <div className="profile_widget_container_content_userDetails_grid2">
                            <div className="profile_widget_container_content_userDetails_grid2_details">
                                <div className="profile_widget_container_content_userDetails_grid2_details_count"> {detail !== null && detail.projects} </div>
                                <div className="profile_widget_container_content_userDetails_grid2_details_label"> Projects </div>
                            </div>
                            <div className="profile_widget_container_content_userDetails_grid2_details">
                                <div className="profile_widget_container_content_userDetails_grid2_details_count">  {detail !== null && detail.points} </div>
                                <div className="profile_widget_container_content_userDetails_grid2_details_label"> Points </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </Then>

            <Else>

            </Else>
             
            </If> */}

        </div>  
    )   
}

export default ProfileWidget
