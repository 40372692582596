import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../_core/actions/user';
import { timekeepingAction } from '../_core/actions/timekeeping';
import M from 'materialize-css/dist/js/materialize.min.js'

import ReactImageFallback from "react-image-fallback";
// import loading from '../Components/Ellipsis-1s-60px.svg';
import avatar from '../_templates/man.png';

import './DetailPage.sass';
//import { cpus } from 'os';

class StatusPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            render: true,
            
        }
        this.handleClose = this.handleClose.bind(this);
        this.alertTimeOut = this.alertTimeOut.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var elem = document.querySelector(".parallax");
        M.Parallax.init(elem);

        this.alertTimeOut();

        var elems = document.querySelectorAll('.carousel');
        M.Carousel.init(elems);

        this.props.dispatch(userActions.getAllActiveUsers(1));
        let user = JSON.parse(localStorage.getItem('userCredentials'));
        this.props.dispatch(timekeepingAction.get(user.user.praggerUsername));
    }

    
    addDefaultSrc(ev){
        ev.target.src = avatar
    }

    alertTimeOut = () =>
        setTimeout(function () {
            this.setState({ render: false })

        }.bind(this), 4000
        )


    // componentWillUnmount() {
    //     clearTimeout(this.alertTimeOut)
    // }

    handleClose(event) {
        event.preventDefault()
        this.setState({ render: false })
    }

    render() {
        const { users } = this.props;

        // const { imageHash } = this.state;
        // let user = JSON.parse(localStorage.getItem('user'));
        const { timekeeping } = this.props;
        let a =  timekeeping.timekeeping ? timekeeping.timekeeping.allPrags : "no data"
        try{
            var praginDigit = new Date(a[0].prag_in_at)
            var requireTime = new Date(a[0].prag_in_at).addHours(9)
            var pragoutDigit = new Date(a[0].prag_out_at)
            var nowDigit = +new Date();
            var praginTime = formatAMPM(praginDigit)
            var pragIn = "http://api.pragger.com/" + a[0].prag_in_img
            var pragOut = "http://api.pragger.com/" + a[0].prag_out_img
            var endDigit = requireTime.getTime();
            var startDigit = praginDigit.getTime();
            var outDigit = pragoutDigit.getTime();
            var praggerIn = undefined
            var praggerOut = undefined
            var pragInWidth
            var progressPrag
            var pragoutTime
            var progressTime
            // eslint-disable-next-line
            if(pragOut == "http://api.pragger.com/null"){
                pragoutTime = "Not Out Yet"
                progressTime = Math.round(( ( nowDigit - startDigit ) / ( endDigit - startDigit ) ) * 100)
            }
            else{
                progressTime = Math.round(( ( outDigit - startDigit ) / ( endDigit - startDigit ) ) * 100)
            }
            //Have pragout with undertime
            if(progressTime < 100 && pragoutTime !== "Not Out Yet"){
                progressTime = progressTime + "%"
                progressPrag = pragOut
                pragoutTime = formatAMPM(pragoutDigit)
                pragInWidth = 50
            }
            else if(progressTime >= 100){
                // eslint-disable-next-line
                //Have Pragout without undertime
                if(pragoutTime !== "Not Out Yet"){                  
                    progressTime = 100 + "%"
                    progressPrag = pragOut
                    pragoutTime = formatAMPM(pragoutDigit)
                    praggerIn = pragIn
                    pragInWidth = 50
                }
                //Haven't Pragout without undertime
                else{
                    progressTime = 100 + "%"
                    progressPrag = pragIn
                }
            }
            //Haven't Pragout
            else{
                progressTime = progressTime + "%"
                progressPrag = pragIn
            }
            // console.log(pragoutTime)
        } catch (error) {
            praginTime = "No Data"
            pragoutTime = "No Data"
        }
        if(praginTime === "12:NaN am"){
            praginTime = "No Data"
            pragoutTime = "No Data"
        }
        return(
            <div>
                <div className="statusPageCon">

                    <div>
                        <div className="hide-on-med-and-down">
                            <p className="center txtStatus"><b>Status</b></p>
                            <p><b>Prag In / Out</b></p>
                            <div className="row timeCon">
                                <div className="col s6 m6">
                                    <p>Rendered Time</p>
                                </div>
                                <div className="col s6 m6">
                                    <p className="right"><b>Check Schedule</b></p>
                                </div>
                            </div>

                            <div>
                                <div className="row">
                                    <div className="col s2 m2">
                                        <img src = {praggerIn} style={{width: pragInWidth}} initialimage={avatar} alt = "" className="pragIn"></img>
                                    </div> 
                                    <div className="offset-m1 col s7 m7">
                                        <div id="progress1">
                                            <span id="percent1" style={{left: progressTime}}>
                                                <img src = {progressPrag} alt = "" className="progressBar"></img>
                                            </span>
                                            <div id="bar1" style={{width: progressTime}}></div>
                                        </div>
                                    </div>
                                    <div className="col s2 m2">
                                            <img src = {praggerOut} initialimage={avatar} alt = "" className=""></img>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s6 m6">
                                    <p>IN{" : " + praginTime}</p>
                                </div>
                                <div className="col s6 m6">
                                    <p className="right">OUT{" : " + pragoutTime}</p>
                                </div>
                            </div> 
                            
                            <Link to="/timesheet"><button className="btnTimesheet">View Timesheet</button></Link>
                        </div>

                        <hr className="hide-on-med-and-down DPLines" />

                        <div className="hide-on-med-and-down">
                            <div className="row">
                                <div className="col s6 m6">
                                    <p>PEOPLE</p>
                                </div>
                                <div className="col s6 m6">
                                    <Link to="/people" className="spViewAllPic"><p className="right">View all</p></Link>
                                </div>
                            </div>

                            <div className="spImgCon">
                                {
                                    <div>
                                    {users.loading && <em>Loading pictures...</em>}

                                    {users.users &&
                                    <div>
                                        {users.users.items.slice(0, 10).map((user, index) =>
                                        <div className="spImgCon1" key={index}>
                                            <ReactImageFallback
                                                src={user.details[0].avatar}
                                                fallbackImage={avatar}
                                                initialImage={avatar}
                                                alt="user avatar"
                                                className="circle statusImg" 
                                            />
                                        </div>
                                        )}
                                    </div>
                                    }
                                    </div>
                                }
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
	const { users, authentication } = state;
    const { user } = authentication;
    const { timekeeping } = state;
    return {
        user,
        users,
        timekeeping
    };
}

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

// eslint-disable-next-line
Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}

const connectedPeoplesPage = connect(mapStateToProps)(StatusPage);
export default connectedPeoplesPage;