import { get } from '../helpers/api';
import { userService } from '../services/user';

export const attrUserService = {
    getUsers,
};

function getUsers(id){
    return get('/api/v1/user?page=1&items-per-page=10000000').then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}