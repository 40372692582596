import { roleConstants } from '../constants/role';
let initialState = {
  roles: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}
export function roles(state = initialState, action) {
  switch (action.type) {
    case roleConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case roleConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.roles,
        totalPerPage:  action.roles.total,
        perPage: action.roles.pages,
        currentPage: action.roles.page,
      };
    case roleConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case roleConstants.ROLE_DEL_SUCCESS:
      return {
        roles: action.roles
      };
    case roleConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(role =>
          role.id === action.id
            ? { ...role, deleting: true }
            : role
        )
      };
    case roleConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(role => role.id !== action.id)
      };
    case roleConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map(role => {
          if (role.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...roleCopy } = role;
            // return copy of user with 'deleteError:[error]' property
            return { ...roleCopy, deleteError: action.error };
          }
          return role;
        })
      };
    //get role by id
    case roleConstants.GET_REQUEST:
      return {
        saving: true
      };
    case roleConstants.GET_SUCCESS:
      return {
        ...state,
        role: action.role
      };
    case roleConstants.GET_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}