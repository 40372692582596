import React from 'react';
import { Link } from 'react-router-dom';
import MainNav from '../Components/MainNav';

import flag from '../_templates/flag.png';

import { connect } from 'react-redux';

import coins from '../_templates/coins.png';
import M from 'materialize-css/dist/js/materialize.min.js';
import MaterialIcon from 'material-icons-react';
import avatar from '../_templates/man.png';
// import loading from '../Components/Ellipsis-1s-60px.svg';
import ReactImageFallback from "react-image-fallback";
import ErrorPage from '../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../Components/ERRORPAGE/ButtonHome';
import error from '../_templates/error.png';

import './UserChallengesPage.sass';
import '../_core/styles/alert.sass'

import { challengeActions } from '../_core/actions/challenge';
import { alertActions } from '../_core/actions/alert';
class UserChallengesPage extends React.Component {

        constructor(props) {
        super(props);

        //get logged in user
        let _user_id = JSON.parse(localStorage.getItem('userCredentials'));
        this.state = {
            challenge: {
                user_id: '',
                challengeId: '',
                challenger: _user_id.user._id,       
            },
            render: true,
            submitted: false
        };


        this.handleAccept = this.handleAccept.bind(this);
        this.handleQuit = this.handleQuit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
        this.handleComplete = this.handleComplete.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.alertTimeOut = this.alertTimeOut.bind(this);
    }

    componentDidMount() {
        var elem = document.querySelector(".parallax");
        M.Parallax.init(elem);

        this.props.dispatch(challengeActions.getAll());
        this.alertTimeOut();
    }

    // componentWillUnmount(){
    //     clearTimeout(this.alertTimeOut)
    // }

    handleAccept(challenger){
            return (e) => {
                e.preventDefault();
               let { challenge }        = this.state;
                this.setState({
                    challenge: {
                        ...challenge,
                        user_id: challenger.poster_details[0].user_id,
                        challengeId: challenger._id
                    },
                    render: true
                },() => {
                            let updatedChallenge = this.state;
                            this.props.dispatch(challengeActions.acceptChallenge(updatedChallenge.challenge));
                            window.scrollTo(0, 0);
                        }
                );
             }

        }

    handleQuit(challenger){
            return (e) => {
                e.preventDefault();
               let { challenge }        = this.state;
                this.setState({
                    challenge: {
                        ...challenge,
                        user_id: challenger.poster_details[0].user_id,
                        challengeId: challenger._id
                    },
                    render: true
                },() => {
                            let updatedChallenge = this.state;
                            this.props.dispatch(challengeActions.quitChallenge(updatedChallenge.challenge));
                            window.scrollTo(0, 0);
                        }
                );
             }

        }

        handleFinish(challenger){
            return (e) => {
                e.preventDefault();
               let { challenge }        = this.state;
                this.setState({
                    challenge: {
                        ...challenge,
                        user_id: challenger.poster_details[0].user_id,
                        challengeId: challenger._id
                    },
                    render: true
                },() => {
                            let updatedChallenge = this.state;
                            this.props.dispatch(challengeActions.finishChallenge(updatedChallenge.challenge));
                            window.scrollTo(0, 0);
                        }
                );
             }

        }

        handleReset(challenger){
            return (e) => {
                e.preventDefault();
               let { challenge }        = this.state;
                this.setState({
                    challenge: {
                        ...challenge,
                        user_id: challenger.poster_details[0].user_id,
                        challengeId: challenger._id
                    },
                    render: true
                },() => {
                            let updatedChallenge = this.state;
                            this.props.dispatch(challengeActions.resetChallenge(updatedChallenge.challenge));
                            window.scrollTo(0, 0);
                        }
                );
             }

        }
        
        handleComplete(challenger){
            return (e) => {
                e.preventDefault();
               let { challenge }        = this.state;
                this.setState({
                    challenge: {
                        ...challenge,
                        user_id: challenger.poster_details[0].user_id,
                        challengeId: challenger._id
                    },
                    render: true
                },() => {
                            let updatedChallenge = this.state;
                            this.props.dispatch(challengeActions.completeChallenge(updatedChallenge.challenge));
                            window.scrollTo(0, 0);
                        }
                );
             }

        }

        handleClose(event){
            event.preventDefault()
            this.setState({render: false})
            this.props.dispatch(alertActions.clear());
            clearTimeout(this.alertTimeOut)
        }

        alertTimeOut = () =>
            setTimeout(function() {
                this.setState({render: false})
                this.props.dispatch(alertActions.clear());
            }.bind(this), 4000
        )
    render() {
        const { challenges, alert, user } = this.props;
        const { render } = this.state;
        const currentUser = user.user._id;
        
        return (
          <div className="challenges-page">
            <MainNav/>

            { challenges.error ? 
                    <div className="container error-div">
                        <ErrorPage/>
                            <div className="text-danger">
                                <img className="error" src={error} alt="error"/> 
                                    {challenges.error}
                            </div>
                        <ButtonHome/>
                    </div> : 

          	<div className="container-fluid">
                <div className="row">
                    <div className="center">
                    	<img src={flag} className="flag" alt="flag"/>
                        <h3 className="challenge-title">Challenges</h3>
                    	<h4 className="challenge-detail">I challenge you to create challenge now!</h4>
                        <div className="col s12 m4 offset-m4">
                        	<Link to="/challenge/create" className="waves-effect waves-light btn btn-create-challenge">Create a Challenge!</Link>
                        </div>
                    </div>
                </div>
              
                {challenges.items &&
                <div className="container">
                    {alert.message && render &&
                        <div id="alert" className={`alert ${alert.type}`} toggle={this.onDismiss}>{alert.message}<span className="closebtn right" onClick={this.handleClose}>&times;</span></div>
                    }
                    <div className="row">
                    {challenges.items.map((challenges, index) => 
                        <div className="col s12 m6 l6 xl4" key={index}> 
                            <div className="card hoverable">
                                <div className="card-image">
                                    <ReactImageFallback
                                        src={challenges.challengeImage}
                                        fallbackImage="https://riverstylemag.com/wp-content/uploads/2018/07/are-you-up-for-the-challenge.png"
                                        alt="background-card"
                                        className="background-card" />
                                    <div className="card-title grey-text text-darken-4 more_horiz"><MaterialIcon icon="more_horiz" color="white" id="logo"/></div>
                                </div>
                                <div className="card-content">
                                    <div className="row">
                                        <div className="col s12 m6 l4">
                                            <ReactImageFallback
                                                src={challenges.poster_details[0].avatar}
                                                fallbackImage={avatar}
                                                initialImage={avatar}
                                                alt="user avatar"
                                                className="avatar circle responsive-img" />
                                        </div>
                                        
                                         
                                        <div className="col s12 m6 l7">
                                            <h5 className="left-align firstName">{ challenges.poster_details[0].firstName ? challenges.poster_details[0].firstName : "No Name Specified" }</h5>
                                            <p className="left-align position">{ challenges.poster_details[0].position ? challenges.poster_details[0].position : "No Position Specified" }</p>
                                        </div>
                                        
                                    </div>
                                     <p className="content-title">{challenges.title}</p>
                                    <p className="content-detail">
                                        {challenges.content}
                                    </p>
                                    <Link to="#" className="waves-effect waves-light more-info">More Info</Link>
                                </div>
                                <div className="card-action">
                                    <div className="center">
                                    {challenges.poster_details[0].user_id === currentUser ? 
                                    
                                        
                                        (<div className="col s12 m12 l12">
                                             
                                            { challenges.status==='on going' ?  
                                                <p>Challenge Ongoing</p>
                                                : challenges.status==='completed' ? 
                                                <button onClick={this.handleReset(challenges)} className="waves-effect waves-light btn btn-restart-challenge">Restart Challenge</button>
                                                : challenges.status ==='finished' ?
                                                <button onClick={this.handleComplete(challenges)} className="waves-effect waves-light btn btn-accept-challenge">Complete Challenge</button>
                                                : <p>You created this challenge</p>
                                            }
                                        </div>
                                        )
                                        :
                                        (<div className="col s12 m12 l12">
                                            {challenges.status==='on going' && currentUser === challenges.challenger ?
                                                <div className="row">
                                                  <div className="col s6"><button onClick={this.handleFinish(challenges)} className="waves-effect waves-light btn btn-finish-challenge">Finish</button></div>
                                                  <div className="col s6"><button onClick={this.handleQuit(challenges)} className="waves-effect waves-light btn btn-quit-challenge">Quit</button></div>
                                                </div>
                                            :  challenges.status==='new' && currentUser !== challenges.challenger ?
                                                <button onClick={this.handleAccept(challenges)} className="waves-effect waves-light btn btn-accept-challenge">Accept Challenge</button>
                                            :  challenges.status==='finished' && currentUser === challenges.challenger ?
                                                <p className="blue-text">You finished this challenge.</p>
                                            :   <div>
                                                    <h6 className="challenger-title"> Challenger </h6>
                                                    <ReactImageFallback
                                                        fallbackImage={avatar}
                                                        initialImage={avatar}
                                                        alt="user avatar"
                                                        className="avatar circle responsive-img avatar3" />
                                                   <p>{ challenges.poster_details[0].firstName ? challenges.poster_details[0].firstName : "No Name Specified" }</p>
                                                </div>
                                            }
                                        </div>
                                        )       
                                    }
                                    </div>



                                    <div className="icon-down">
                                        <span className="thumb_up"><MaterialIcon icon="thumb_up" color="gray"/></span>
                                        <span className="bill"> 299 </span>
                                        <span className="coin"> <img src={coins} className="coins" alt="coin"/> </span>
                                        <span className="bill"> 299 </span>
                                        <span className="brightness_1"><MaterialIcon icon="brightness_1" color="red"/></span>
                                    <span className="hourglass_empty"><MaterialIcon icon="hourglass_empty" color="gray"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div> 
                } 
            </div>
        }
          </div>
        );
    }
}

function mapStateToProps(state) {
  	const { challenges,alert,authentication } = state;
    const { user } = authentication;
    return {
		challenges,
        alert,
        user
    };
}

const connectedUserChallengesPage = connect(mapStateToProps)(UserChallengesPage);
export { connectedUserChallengesPage as UserChallengesPage};
//export { UserChallengesPage };
