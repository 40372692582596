import React from "react";
import Swal from "sweetalert2";
import cc from "classcat";
import moment from "moment";
// import { requestStatus } from "../../_core/helpers/requestStatus";
import "./WarningPopup.scss";
import closeIcon from "../../../_templates/close_icon.png";

export const WarningPopup = (type, message, confirmBtn, cancelBtn) => {
  const theme = "#2a3042";
  const fontColor = "#FFFFFF";
  const getMessage = Array.isArray(message); // type of message = array of string or string

  return Swal.fire({
    position: "center",
    type: type,
    html: `<p style="color: ${fontColor}; font-size: 1.2rem;">
            ${
              !getMessage
                ? message
                : message[0] &&
                  message
                    ?.map((msg) => {
                      return msg + "<br/>";
                    })
                    .join("")
            }
        </p>`,
    background: theme,
    width: "400px",
    padding: "20px",
    showConfirmButton: confirmBtn,
    confirmButtonColor: "#5555E6",
    confirmButtonText: "Yes",
    showCancelButton: cancelBtn,
    cancelButtonColor: "#F14646",
    cancelButtonText: confirmBtn ? "No" : "Okay",
  });
};

export const RequestApprovalPopup = ({
  status = "new",
  requestorName = "",
  referenceCode = "",
  dates = [],
  remarksLabel = "Remarks",
  remarksContent = "",
  isOpen = false,
  onClickApprove,
  onClickClose
}) => {

  const [openModal, setOpenModal] = React.useState(false);

  React.useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen]);

  return (
    <div className={cc(["confirm-request-modal", openModal ? " is-open" : ""])}>
      <div className="request-modal-approver-container">
        <div className="request-modal-approver-header">
          <img src={closeIcon} alt="close" onClick={onClickClose}/>
          <p>Are you sure?</p>
        </div>
        <div className="request-modal-approver-body">
          <div>
            <div>
              <p>{requestorName}</p>
              <p>
                <span>Reference Code : </span>
                {referenceCode}
              </p>
            </div>
            <div>
              <p>
                <span>Date : </span>
                {dates?.length === 1
                  ? moment(dates[0]).format("MMMM DD YYYY")
                  : dates.length > 1
                  ? moment(dates[0]).format("MMMM DD YYYY") +
                    " - " +
                    moment(dates[dates.length - 1]).format("MMMM DD YYYY")
                  : "No date"}
              </p>
            </div>
          </div>
          {remarksContent !== "" &&
          <div className="floating-label">
            <textarea
              className="floating-input floating-textarea hr-textarea"
              value={remarksContent}
              name="description"
              cols="30"
              placeholder=" "
              style={{ resize: "none" }}
              row="10"
              readOnly
              disabled
            />
            <label className="floating-text">{remarksLabel}</label>
          </div>
          }
        </div>
        <div className="request-modal-approver-footer">
          <button className="btn-close" onClick={onClickClose}>
            Close
          </button>
          <button className="btn-close" onClick={onClickApprove}>
            Update
          </button>
          {/* <button className={cc(["btn", !status ? "btn-new" :status === requestStatus.onHold ? "btn-hold" : "btn-" + status])} onClick={onClickApprove}>
            {status === requestStatus.approved ? "Approve!" : status === requestStatus.rejected ? "Reject!" : status === requestStatus.onHold ? "Hold!" : "Update!"}
          </button> */}
        </div>
      </div>
    </div>
  );
};
