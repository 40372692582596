export default {
	"items" : [
		{
			"name" : "new year",
			"date" : "2020-01-01T00:00:00.000Z"
		}, 
		{
			"name" : "Chinese new year",
			"date" : "2020-01-25T00:00:00.000Z"
		},
		{
			"name" : "People power revolution",
			"date" : "2020-02-25T00:00:00.000Z"
		},
		{
			"name" : "Maundy Thursday",
			"date" : "2020-04-09T00:00:00.000Z"
		},
		{
			"name" : "Good Friday",
			"date" : "2020-04-10T00:00:00.000Z"
		},
		{
			"name" : "Black Saturday",
			"date" : "2020-04-11T00:00:00.000Z"
		},
		{
			"name" : "Labor Day",
			"date" : "2020-05-01T00:00:00.000Z"
		},
		{
			"name" : "Eid’l Fitr",
			"date" : "2020-05-25T00:00:00.000Z"
		},
		{
			"name" : "Independence Day",
			"date" : "2020-06-12T00:00:00.000Z"
		},
		{
			"name" : "Eidul Adha",
			"date" : "2020-07-31T00:00:00.000Z"
		},
		{
			"name" : "Ninoy Aquino Day",
			"date" : "2020-08-21T00:00:00.000Z"
		},
		{
			"name" : "National Heroes’ Day",
			"date" : "2020-08-31T00:00:00.000Z"
		},
		{
			"name" : "All Saints Day",
			"date" : "2020-11-01T00:00:00.000Z"
		},
		{
			"name" : "All Souls Day",
			"date" : "2020-11-02T00:00:00.000Z"
		},
		{
			"name" : "Bonifacio Day",
			"date" : "2020-11-30T00:00:00.000Z"
		},
		{
			"name" : "Immaculate Conception Day",
			"date" : "2020-12-08T00:00:00.000Z"
		},
		{
			"name" : "Christmas Eve",
			"date" : "2020-12-24T00:00:00.000Z"
		},
		{
			"name" : "Christmas Day",
			"date" : "2020-12-25T00:00:00.000Z"
		},
		{
			"name" : "Rizal Day",
			"date" : "2020-12-30T00:00:00.000Z"
		},
		{
			"name" : "New Year’s Eve",
			"date" : "2020-12-31T00:00:00.000Z"
		}
	]
}

