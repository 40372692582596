import React from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import { Link } from 'react-router-dom';

import MaterialIcon from 'material-icons-react';

import { userActions } from '../_core/actions/user';
import { alertActions } from '../_core/actions/alert';
import '../_core/styles/alert.sass';
import BodyBackgroundColor from 'react-body-backgroundcolor'

import './Register.sass'

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                email          : '',
                team           : '',
                password       : '',
                confirmPassword : '',
                startDate      :  new Date(Date.now()).toISOString()
            },
            submitted: false,
            render: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.alertTimeOut = this.alertTimeOut.bind(this);
    }
    handleClose(event){
        event.preventDefault()
        this.setState({render: false});
        this.props.dispatch(alertActions.clear());
        clearTimeout(this.alertTimeOut)
    }
    alertTimeOut = () =>
        setTimeout(function() {
            this.setState({render: false})
            this.props.dispatch(alertActions.clear());
        }.bind(this), 5000
    )
    handleChange(event) {
        const { name, value } = event.target;
        const { user }        = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true,
                        render: true });
        const { user }     = this.state;
        const { dispatch } = this.props;

        if (user.email && user.team && user.password && user.confirmPassword && user.startDate && user.password === user.confirmPassword) {
            dispatch(userActions.register(user));
            setTimeout(function() {
                this.setState({render: false})
                this.props.dispatch(alertActions.clear());
            }.bind(this), 5000)
        }
    }

    render() {
        const { registering, alert }    = this.props;
        const { user, submitted, render } = this.state;

        return (
            <DocumentTitle title={`Register | ${process.env.REACT_APP_DOCUMENT_TITLE}`}>
                <BodyBackgroundColor backgroundColor='#9e4670'>
                <div className="register">
                        <div className="container">
                            <div className="left-align">
                                <Link to="/login"><MaterialIcon icon="arrow_back" color="white" size="medium"/></Link>
                            </div>
                            <div className="row">
                                <div className="col s12 m8 offset-m2">
                                    <h3 className="register-title center-align">REGISTER ACCOUNT</h3>
                                </div>
                                <div className="col s12 m6 offset-m3 l4 offset-l4">
                                    <form name="form" onSubmit={this.handleSubmit}>
                                        {alert.message && render &&
                                            <div id="alert" className={`alert ${alert.type}`} toggle={this.onDismiss}>{alert.message}<span className="closebtn right" onClick={this.handleClose}>&times;</span></div>
                                        }
                                        <div className={'input-field' + (submitted && !user.email ? ' has-error' : '')}>
                                            <label htmlFor="email">Email</label>
                                            <input type="email" className="validate form-control" name="email" value={user.email} onChange={this.handleChange} />
                                            {submitted && !user.email &&
                                            <div className="helper-text red-text">Email is required</div>
                                            }
                                        </div>
                                        <div className={'input-field' + (submitted && !user.team ? ' has-error' : '')}>
                                            <label htmlFor="team">Team</label>
                                            <input type="text" name="team" className="validate form-control" value={user.team} onChange={this.handleChange} />
                                            {submitted && !user.team &&
                                            <div className="helper-text red-text">Team is required</div>
                                            }
                                        </div>
                                        <div className={'input-field' + (submitted && !user.password ? ' has-error' : '')}>
                                            <label htmlFor="password">Password</label>
                                            <input type="password" name="password" className="validate form-control" value={user.password} onChange={this.handleChange} />
                                            {submitted && !user.password &&
                                            <div className="helper-text red-text">Password is required</div>
                                            }
                                        </div>
                                        <div className={'input-field' + (submitted && !user.confirmPassword ? ' has-error' : '')}>
                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                            <input type="password" name="confirmPassword" className="validate form-control" value={user.confirmPassword} onChange={this.handleChange} />
                                            {submitted && user.password !== user.confirmPassword &&
                                                <div className="help-block red-text">Password did not match</div>
                                            }

                                        </div>

                                        <div className="input-field center-align">
                                            <button className="button btn-register">Continue</button>

                                            {registering

                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </BodyBackgroundColor>
            </DocumentTitle>
        );
    }
}

function mapStateToProps(state) {
    const { registering } = state.registration;
    const { alert } = state
    return {
        registering,
        alert
    };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);

export { connectedRegisterPage as RegisterPage };
