import * as api from '../helpers/api';
import { userService } from '../services/user';

const getAll = () => {
    return api.get('/api/v1/event/user').then((response) => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userService.logout();
                    //location.reload(true);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        });
    })
}

export default { getAll };