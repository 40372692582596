
import moment from 'moment';

// convert days [] values to moment().format("LL")
// before passing to function

export function renderedTime (timesheet, days) {

    var retVal = [];

    //group user clock ins by date
    const pragByDates = Object.values(timesheet).reduce(function(prag1, prag2) {
        prag1[moment(prag2.pragInAt).format('LL')] = prag1[moment(prag2.pragInAt).format('LL')] || [];
        prag1[moment(prag2.pragInAt).format('LL')].push(prag2)
        return prag1;
    }, Object.create(null)); //Object entries return format = moment().format("LL") - eg: "September 01, 2020"

    //get dates with clock in
    let daysWithPrag = [];
    Object.entries(pragByDates).map((data) => {
        daysWithPrag.push(data[0]);
        return data
    });

    //get included dates
    const includedDays = days;

    const getTimeDiff = (startDate, endDate) => {
        let hours = parseInt(moment.duration(moment(endDate).diff(startDate)).asHours());
        let minutes = parseInt(moment.duration(moment(endDate).diff(startDate)).asMinutes()) % 60;
        let seconds = parseInt(moment.duration(moment(endDate).diff(startDate)).asSeconds()) % 60;

        const padstring = (num) => {
            var retNum = num.toString().replace(/-/g, "").length === 1 ? "0" : "";
            return retNum;
        }
        
        let totalTime = padstring(hours) + hours.toString()
                + ":" + padstring(minutes) + minutes.toString()
                + ":" + padstring(seconds) + seconds.toString();

        
        return totalTime;
    }

    includedDays.map((day)=>{
        if(daysWithPrag.includes(day)){
            Object.entries(pragByDates).map((pragDate) => {
                if(day === pragDate[0]){
                    
                    // created start time and required hrs
                    const policy = {
                        clockInTime: '10:00:00',
                        hoursRequired: 9
                    }
                    
                    // get latest prag
                    // let firstPragIn = pragDate[1][pragDate[1].length - 1];
                    let lastPragIn = pragDate[1][0];
                    let lastPragOut = pragDate[1][0];

                    // sample data for testing
                    // let pragIn = moment("2020-09-09T09:55:00")
                    // let pragOut = moment("2020-09-09T19:01:00")

                    //get prag in and prag out
                    let pragIn = lastPragIn.pragInAt ? moment(lastPragIn.pragInAt) : "";
                    let pragOut = lastPragOut.pragOutAt ? moment(lastPragOut.pragOutAt) : "";
                    //return date if prag out has value and is same date to prag in
                    //let pragOut = moment(lastPragOut.pragOutAt).isSame(pragIn, 'day') ? moment(lastPragOut.pragOutAt) : "";

                    //get startdate, if prag in >= clockInTime, return prag in else clockInTime
                    let setStartTime = moment(moment(pragIn).format('YYYY-MM-DD') + "T" + moment(policy.clockInTime, 'HH:mm:ss').format('HH:mm:ss'))
                    let getStartTime = moment(setStartTime).isSameOrBefore(pragIn) ? pragIn : setStartTime;

                    //if pragOut, get total hours rendered
                    let computedRenderedHrs = getTimeDiff(getStartTime, pragOut);
                    let actualRT = pragOut && pragIn ? computedRenderedHrs : "";

                    // get computed rendered hrs
                    let renderedHrs = moment.duration(moment(pragOut).diff(getStartTime)).asSeconds();

                    // get computed total hrs rendered
                    let setTotalRenderedHrs = moment(getStartTime).add(renderedHrs, 'seconds');
                    
                    // get computed total hrs required
                    let setRequiredTime = moment(getStartTime).add(policy.hoursRequired, 'hours');
                    
                    //get remaining time needed - UT / excess time rendered - OT
                    let computeOTUT = getTimeDiff(moment(setRequiredTime), moment(setTotalRenderedHrs));
                    
                    var overtime = !pragIn || !pragOut || computeOTUT === "00:00:00" ? "" : computeOTUT;

                    retVal.push({
                        date: day,
                        pragIn: pragIn,
                        pragOut: pragOut,
                        totalHours: actualRT,
                        overtime: overtime
                    });

                }
                return pragDate
            })
        }else{
            retVal.push({
                date: day,
                pragIn: "",
                pragOut: "",
                totalHours: "",
                overtime: "",
                undertime: ""
            })
        }
        return day
    });

    return retVal;
}