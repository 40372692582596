import { timesheetConstants } from '../constants/timesheet';

const initialState = {
  error: false,
  loading: true
}

export function timesheet(state = initialState, action) {
  switch (action.type) {
    //pragger report api
    case timesheetConstants.USER_TIMESHEET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case timesheetConstants.USER_TIMESHEET_SUCCESS:
      return {
        timesheet: action.timesheet
      };
    case timesheetConstants.USER_TIMESHEET_FAILURE:
      return {
        error: action.error
      };
      
    default:
      return state
  }
}