import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../_core/actions/user";
import M from "materialize-css/dist/js/materialize.min.js";
import moment from "moment";
import MainNav from "../Components/MainNav";
import ReactImageFallback from "react-image-fallback";
import trophy_1 from "../UserProfilePage/trophy_1.png";
import trophy_2 from "../UserProfilePage/trophy_3.png";
import trophy_3 from "../UserProfilePage/trophy_2.png";
import RadarChart from "react-svg-radar-chart";
// import loading from "../Components/Ellipsis-1s-60px.svg";
import PeopleTab from "../Components/PeopleTab";
import avatar from "../_templates/man.png";
import { Parallax } from "react-parallax";
import "react-svg-radar-chart/build/css/index.css";
import { history } from "../_core/helpers/history";
import "./PeopleView.sass";
import { level, experienceLevel } from "../_core/constants/selections";
import ReactTooltip from "react-tooltip";

class PeopleDetails extends React.Component {
  constructor(props) {
    super(props);

    //get logged in user
    let admin = JSON.parse(localStorage.getItem("userCredentials"));

    //set users props
    const { users } = this.props;

    //check whether editing a user or creating a new one
    this.edit = typeof users.user !== "undefined" ? true : false;

    this.title = this.edit ? "Edit User" : "Create User";

    //if editing, assign passed user data to prepopulate
    let _user = this.edit ? users.user.user[0] : {};

    //setup state for new or edited profile
    this.state = {
      user: {
        _id: this.edit ? _user._id : null,
        email: this.edit ? _user.email : "",
        team: this.edit ? _user.team : admin.user.team, //logged in user's team
        officeId: this.edit ? _user.details[0].officeId : "",
        manager: this.edit ? _user.details[0].manager : null,
        bio: this.edit ? _user.details[0].bio : "",
        firstName: this.edit ? _user.details[0].firstName : "",
        middleName: this.edit ? _user.details[0].middleName : "",
        lastName: this.edit ? _user.details[0].lastName : "",
        title: this.edit ? _user.details[0].title : "",
        suffix: this.edit ? _user.details[0].suffix : "",
        address1: this.edit ? _user.details[0].address1 : "",
        address2: this.edit ? _user.details[0].address2 : "",
        city: this.edit ? _user.details[0].city : "",
        state: this.edit ? _user.details[0].state : "",
        country: this.edit ? _user.details[0].country : "",
        nationality: this.edit ? _user.details[0].nationality : "",
        mothersMaidenName: this.edit ? _user.details[0].mothersMaidenName : "",
        maritalStatus: this.edit ? _user.details[0].maritalStatus : "",
        gender: this.edit ? _user.details[0].gender : "",
        birthDate: this.edit
          ? moment(_user.details[0].birthDate).format("MMMM D, YYYY")
          : "",
        birthPlace: this.edit ? _user.details[0].birthPlace : "",
        startDate: this.edit
          ? moment(_user.details[0].startDate).format("MMMM D, YYYY")
          : new Date(Date.now()).toISOString(),
        endDate: this.edit
          ? moment(_user.details[0].endDate).format("MMMM D, YYYY")
          : "",
        regularizationDate: this.edit
          ? moment(_user.details[0].regularizationDate).format("MMMM D, YYYY")
          : "",
        position: this.edit ? _user.details[0].position : "",
        department: this.edit ? _user.details[0].department : "",
        level: this.edit ? _user.details[0].level : "",
        exp: this.edit ? _user.exp : "",
        avatar: this.edit ? _user.details[0].avatar : "",
        photo: this.edit ? _user.details[0].photo : "",
        cover: this.edit ? _user.details[0].cover : ""
      },
      submitted: false,
      imageHash: Date.now(),
      level: level.software,
      experience: experienceLevel.default
    };
    this.alertTimeOut = this.alertTimeOut.bind(this);
  }

  alertTimeOut = () =>
    setTimeout(
      function() {
        this.setState({ render: false });
      }.bind(this),
      4000
    );

  componentDidMount() {
    this.props.dispatch(userActions.getAll(1));

    window.scrollTo(0, 0);
    var elem = document.querySelector(".parallax");
    M.Parallax.init(elem);

    this.alertTimeOut();
    if (this.state.user._id === null) {
      history.goBack();
    }
  }

  render() {
    const { user, level, experience } = this.state;
    const { users } = this.props;
    // const { alert } = this.props;
    // const { imageHash } = this.state;
    // let alertTimeout = true
    // if(this.state.render) {
    // 	alertTimeout = <div id="alert" className={`alert ${alert.type} no-border`} toggle={this.onDismiss}>{alert.message}<span className="closebtn right" onClick={this.handleClose}>&times;</span></div>
    // }
    var userLevel = level.filter(level => level.value === user.level);
    const exps = this.state.user.exp;
    var getExp = [];
    var lowerExp = [];
    var lastLevel = '';
    
    if (exps !== undefined) {
      experience.map(exp => {
        if (Number(exp.exp) > Number(exps)) {
          getExp.push(exp);
        } else {
          lowerExp.push(exp);
        }
        return exp;
      });
      lastLevel = experience[experience.length - 1]
    }

    var currentLevel = lowerExp[0] ? Number(lowerExp[lowerExp.length - 1].level) : 1;
    var userCurExp = lowerExp[0] ? Number(lowerExp[lowerExp.length - 1].exp) : 0;
    var currentExp = exps && lastLevel !== undefined ? Number(exps) >= Number(lastLevel.exp) ? Number(lastLevel.exp) : Number(exps) : 0
    var targetExp = getExp[0] ? Number(getExp[0].exp) : lowerExp[0] ? Number(lowerExp[lowerExp.length - 1].exp) : 100
    var getPercent = ((currentExp - userCurExp) / (targetExp - userCurExp)) * 100;
    var percent = Number(exps) >= Number(lastLevel.exp) ? '100%' : getPercent ? getPercent.toString().slice(0,2) + "%" : "0%";

    return (
      <div className="profile">
        <MainNav />
        
        {/* Header Photo */}
        <div className="sticky">
          <div className="parallax-container coverPhoto fallback">
            <Parallax
              bgImage={`${user.cover}`}
              bgImageAlt="user cover"
              strength={100}
              bgClassName="cc_image"
              className="cover-picture"
            >
              <div className="dark-cover" />
            </Parallax>
          </div>

          <div className="row">
            <div className="offset-m1 col s12 m2 user-pic-center">
              <ReactImageFallback
                src={`${user.avatar}`}
                fallbackImage={avatar}
                initialImage={avatar}
                alt="user avatar"
                className="avatar1User"
              />
            </div>
            <div className="col s12 m9">
              <div className="row txtDetailUser">
                <div className="col s12 m4">
                  <div className="con1">
                    <h6 readOnly className="Txt1">
                      {user.firstName != null && user.lastName != null
                        ? user.firstName + " " + user.lastName
                        : ""}
                    </h6>
                    <p className="Txt2">
                      {user.position + " | "} {user.team} <br />
                    </p>
                  </div>
                </div>
                <div className="col s12 m3 offset-m1">
                  <div className="con2">
                    <p className="Txt3">
                      {"Joined: " + user.startDate}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Header Photo */}
        <PeopleTab/>
        <div className="container-userdetail">
          <div className="col s12 m4">
            <div className="row">
              {/* Detail Page */}
              <div className="col s12 m3">
                <div className="DetailPageCon pvDetailPageCon">
                  <div>
                    <p>
                      <b>Bio</b>
                    </p>
                    <p>{user.bio}</p>
                  </div>

                  <hr className="DPLines" />

                  <div className="">
                    <p className="level-job">
                      Level {currentLevel}{" "}
                      <span className="level-job-separator"> | </span>
                      {userLevel[0] ? userLevel[0].label : ""}
                    </p>

                    <div
                      id="progress"
                      data-tip={percent}
                      place="bottom"
                      data-class="tooltips"
                    >
                      <div id={currentExp >= Number(lastLevel.exp) ? "bar-last-level" : "bar"} style={{width: percent}}></div>
                    </div>
                    <ReactTooltip />
                    <p className="right txtPercent">
                      {currentExp} / {targetExp} XP
                    </p>
                  </div>

                  <hr className="DPLines DPLines2" />

                  <div>
                    <p className="TxtTrophy">
                      <b>Trophies & Badges</b>
                    </p>
                    <div className="row">
                      <div className="col s4 m4">
                        <img
                          src={trophy_1}
                          alt="trophy_1"
                          className="responsive-img trophyImage"
                        />
                      </div>
                      <div className="col s4 m4">
                        <img
                          src={trophy_2}
                          alt="trophy_2"
                          className="responsive-img trophyImage"
                        />
                      </div>
                      <div className="col s4 m4">
                        <img
                          src={trophy_3}
                          alt="trophy_3"
                          className="responsive-img trophyImage"
                        />
                      </div>
                    </div>
                    <div className="row trophyCon1">
                      <div className="col s4 m4">
                        <img
                          src={trophy_1}
                          alt="trophy_1"
                          className="responsive-img trophyImage"
                        />
                      </div>
                      <div className="col s4 m4">
                        <img
                          src={trophy_2}
                          alt="trophy_2"
                          className="responsive-img trophyImage"
                        />
                      </div>
                      <div className="col s4 m4">
                        <img
                          src={trophy_3}
                          alt="trophy_3"
                          className="responsive-img trophyImage"
                        />
                      </div>
                    </div>

                    <Link to="#!">
                      <button className="btnTimesheet">View All</button>
                    </Link>
                  </div>

                  <hr className="DPLines" />

                  <div className="hide-on-med-and-down">
                    <p>
                      <b>Stats</b>
                    </p>
                    <div className="center">
                      <RadarChart
                        captions={{
                          // columns
                          data1: "Front End",
                          data2: "Mentor",
                          data3: "Sprint",
                          data4: "Leadership",
                          data5: "Design",
                          data6: "Back End"
                        }}
                        data={[
                          // data
                          {
                            data: {
                              data1: 1,
                              data2: 0.8,
                              data3: 0.9,
                              data4: 0.67,
                              data5: 0.8,
                              data6: 0.8
                            },
                            meta: { color: "#58FCEC" }
                          }
                        ]}
                        size={200}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Detail Page */}
              <div className="col s12 m9 l6">
                <div className="BodyCon">
                  <div className="userDetailCon pvCon">
                    
                    <div className="row">
                      <div className="col s12 m12 l12">
                        <div className="row userDetailCon2">
                          <div className="col s12 m6">
                            <div className="userDetailList">
                              <p>
                                <b>Email</b>
                              </p>
                              <p>{user.email}</p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Birthdate</b>
                              </p>
                              <p>
                                {user.birthDate}
                              </p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Marital Status</b>
                              </p>
                              <p>{user.maritalStatus}</p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>BirthPlace</b>
                              </p>
                              <p>{user.birthPlace}</p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Mothers maiden name</b>
                              </p>
                              <p>{user.mothersMaidenName}</p>
                            </div>
                          </div>
                          <div className="col s12 m6">
                            <div className="userDetailList">
                              <p>
                                <b>Address</b>
                              </p>
                              <p>
                                {user.address1}, {user.address2}
                              </p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>City</b>
                              </p>
                              <p>{user.city}</p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>State</b>
                              </p>
                              <p>{user.state}</p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Country</b>
                              </p>
                              <p></p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Nationality</b>
                              </p>
                              <p>{user.country}</p>
                            </div>
                          </div>
                        </div>

                        <div class="row userDetailCon2">
                          <div className="col s12 m6">
                            <div className="userDetailList userDetailList2">
                              <p className="pvEmployDetailsTxt">
                                <b>Employment Details</b>
                              </p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Department</b>
                              </p>
                              <p>{user.department}</p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Position</b>
                              </p>
                              <p>{user.position}</p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Start Date</b>
                              </p>
                              <p>
                                {user.startDate}
                              </p>
                            </div>
                          </div>
                          <div className="col s12 m6">
                            <div className="userDetailList userDetailList1">
                              <p>
                                <b>Regularization Date</b>
                              </p>
                              <p>
                                {user.regularizationDate}
                              </p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>End Date</b>
                              </p>
                              <p>
                                {user.endDate}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Status Page */}
              <div className="col s12 m3">
                <div className="statusPageCon">
                  <div>
                    <div className="hide-on-med-and-down">
                      <p className="center txtStatus">
                        <b>Status</b>
                      </p>
                      <p>
                        <b>Prag In / Out</b>
                      </p>
                      <div className="row timeCon">
                        <div className="col s6 m6">
                          <p>Rendered Time</p>
                        </div>
                        <div className="col s6 m6">
                          <p className="right">
                            <b>Check Schedule</b>
                          </p>
                        </div>
                      </div>

                      <div>
                        <div className="row">
                          <div className="col s2 m2">
                            <ReactImageFallback
                              fallbackImage={avatar}
                              initialImage={avatar}
                              alt="user avatar"
                              className="avatarTime"
                            />
                          </div>
                          <div className="offset-m1 col s7 m7">
                            <div id="progress1">
                              <span id="percent1">
                                <ReactImageFallback
                                  fallbackImage={avatar}
                                  initialImage={avatar}
                                  alt="user avatar"
                                  className="avatarRange"
                                />
                              </span>
                              <div id="bar1"></div>
                            </div>
                          </div>
                          <div className="col s2 m2">
                            <div className="blankPic"></div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col s6 m6">
                          <p>IN: 10 : 20 AM</p>
                        </div>
                        <div className="col s6 m6">
                          <p className="right">OUT: 00 : 00 PM</p>
                        </div>
                      </div>
                    </div>

                    <hr className="hide-on-med-and-down DPLines" />

                    <div className="hide-on-med-and-down">
                      <div className="row">
                        <div className="col s6 m6">
                          <p>PEOPLE</p>
                        </div>
                        <div className="col s6 m6">
                          <Link to="/people" className="spViewAllPic">
                            <p className="right">View all</p>
                          </Link>
                        </div>
                      </div>

                      <div className="spImgCon">
                        {
                          <div>
                            {users.loading && <em>Loading pictures...</em>}

                            {users.users && (
                              <div>
                                {users.users.items
                                  .filter(users => users.active === 1)
                                  .slice(0, 10)
                                  .map((user, index) => (
                                    <div className="spImgCon1">
                                      <ReactImageFallback
                                        src={user.details[0].avatar}
                                        fallbackImage={avatar}
                                        initialImage={avatar}
                                        alt="user avatar"
                                        className="circle statusImg"
                                      />
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Status Page */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users, authentication, registration, alert } = state;
  const { auth } = authentication;
  const { registering } = registration;

  return {
    registering,
    users,
    auth,
    alert
  };
}

const connectedPeopleDetails = connect(mapStateToProps)(PeopleDetails);
export { connectedPeopleDetails as PeopleDetails };
