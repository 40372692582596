import { undertimeConstants } from '../constants/undertime';
import { undertimeService } from '../services/undertime';
// import  requestsActions from './requests';
// import { alertActions } from './alert';
import { history } from '../helpers/history';
// import Swal from 'sweetalert2'

export const undertimeActions = {
    getAll,
    getAllById
};

function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());
        undertimeService.getAll(page, itemsPerPage)
            .then(
                undertimes => {
                    dispatch(success(undertimes));
                    if(window.location.pathname === '/admin/undertime-requests'){
                        history.push('/admin/undertime-requests?page=' + Number(page) || 1);
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: undertimeConstants.GETALL_REQUEST } }
    function success(undertimes) { return { type: undertimeConstants.GETALL_SUCCESS, undertimes } }
    function failure(error) { return { type: undertimeConstants.GETALL_FAILURE, error } }
}

function getAllById(selectPage) {
    return dispatch => {
        dispatch(request());
        
        undertimeService.getAllById(selectPage)
            .then(
                undertimes => dispatch(success(undertimes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: undertimeConstants.GETUSER_REQUEST } }
    function success(undertimes) { return { type: undertimeConstants.GETUSER_SUCCESS, undertimes } }
    function failure(error) { return { type: undertimeConstants.GETUSER_FAILURE, error } }
}
