import React from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js'
import ReactImageFallback from "react-image-fallback";
import avatar from '../_templates/man.png';
import { Parallax } from 'react-parallax';
import moment from 'moment';
import './HeaderPhoto.sass';

class HeaderPhoto extends React.Component {
    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem('user'));
        let userCred = JSON.parse(localStorage.getItem('userCredentials'));

        this.state = {
            user: user,
            userCred: userCred,
            id: userCred.user._id,
            render: true,
			imageHash: Date.now(),

        }
        this.handleClose = this.handleClose.bind(this);
        this.alertTimeOut = this.alertTimeOut.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var elem = document.querySelector(".parallax");
        M.Parallax.init(elem);

        this.alertTimeOut();

        var elems = document.querySelectorAll('.carousel');
        M.Carousel.init(elems);
    }

    alertTimeOut = () =>
        setTimeout(function () {
            this.setState({ render: false })

        }.bind(this), 4000
        )


    // componentWillUnmount() {
    //     clearTimeout(this.alertTimeOut)
    // }

    handleClose(event) {
        event.preventDefault()
        this.setState({ render: false })
    }

    render() {
        const { user, userCred } = this.state;

        return(
        <div className= "sticky">
            <div className="HeaderContentPage ">
                <div className="parallax-container coverPhoto fallback">
                    <Parallax
                        bgImage={`${user.cover}`}
                        bgImageAlt="user cover"
                        strength={100}
                        bgClassName= "cc_image"
                        className="cover-picture"
                    >
                        <div className="dark-cover"/>
                    </Parallax>
                    
                </div>
                <div className="row hpDetailUser">
                    <div className="offset-m1 col s12 m2 user-pic-center">
                        <ReactImageFallback
                            src={`${user.avatar}`}
                            fallbackImage={avatar}
                            initialImage={avatar}
                            alt="user avatar"
                            className="avatar1User center"
                        >
                        </ReactImageFallback>
                    </div>
                    
                    <div className="col s12 m9">
                        <div className="row txtDetailUser">
                            <div className="col s12 m4">
                                <div className="con1">
                                    <h6 readOnly className="Txt1">
                                        {user.firstName != null && user.lastName != null ? user.firstName + ' ' + user.lastName : ""}
                                    </h6>
                                    <p className="Txt2">
                                        {user.position + " | "} {userCred.user.organization} <br />
                                    </p>
                                        <Link to="/profile/edit">
                                            <p className="Txt2 underline">
                                                Edit Profile
                                            </p>
                                        </Link>
                                    
                                </div>
                            </div>
                            <div className="col s12 m3 offset-m1">
                                <div className="con2"> 
                                    <p className="Txt3">
                                        {user.startDate ? "Joined: " + moment(user.startDate).format('MMMM D, YYYY') : ''}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        )
    }
}

// export default HeaderPhoto = connect(mapStateToProps)(HeaderPhoto);
export default HeaderPhoto;
