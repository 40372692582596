export const roleConstants = {
    POSTROLE_REQUEST: 'ROLES_POSTROLE_REQUEST',
    POSTROLE_SUCCESS: 'ROLES_POSTROLE_SUCCESS',
    POSTROLE_FAILURE: 'ROLES_POSTROLE_FAILURE',
    
    GETALL_REQUEST: 'ROLES_GETALL_REQUEST',
    GETALL_SUCCESS: 'ROLES_GETALL_SUCCESS',
    GETALL_FAILURE: 'ROLES_GETALL_FAILURE',

    ROLE_DEL_REQUEST: 'ROLE_REQUEST_DELETE',
    ROLE_DEL_SUCCESS: 'ROLE_SUCCESS_DELETE',
    ROLE_DEL_FAILURE: 'ROLE_FAILURE_DELETE',

    DELETE_REQUEST: 'ROLES_DELETE_REQUEST',
    DELETE_SUCCESS: 'ROLES_DELETE_SUCCESS',
    DELETE_FAILURE: 'ROLES_DELETE_FAILURE',

    GET_REQUEST: 'ROLES_GET_REQUEST',
    GET_SUCCESS: 'ROLES_GET_SUCCESS',
    GET_FAILURE: 'ROLES_GET_FAILURE',   

    UPDATEROLE_REQUEST: 'ROLE_UPDATEROLE_REQUEST',
    UPDATEROLE_SUCCESS: 'ROLE_UPDATEROLE_SUCCESS',
    UPDATEROLE_FAILURE: 'ROLE_UPDATEROLE_FAILURE',

    ROLEPERMISSION_REQUEST: 'ROLE_PERMISSION_REQUEST',
    ROLEPERMISSION_SUCCESS: 'ROLE_PERMISSION_SUCCESS',
    ROLEPERMISSION_FAILURE: 'ROLE_PERMISSION_FAILURE',
 
};