import { commendConstants } from '../constants/commend';

let initialState = {
    loading: false,
    error: null,
    postCommendSuccess: false,
}

export function commends(state = initialState, action) {
    switch (action.type) {
        case commendConstants.GET_TOTAL_REQUEST:
            return {
                ...state,
                totalCommendationsError: false,
            };
        case commendConstants.GET_TOTAL_SUCCESS:
            return {
                ...state,
                totalCommendations: action?.commend?.success || 0
            };
        case commendConstants.GET_TOTAL_FAILURE:
            return {
                ...state,
                totalCommendationsError: action.error
            };

        case commendConstants.GET_MYTOTAL_REQUEST:
            return {
                ...state,
                myTotalCommendationsError: false,
            };
        case commendConstants.GET_MYTOTAL_SUCCESS:
            return {
                ...state,
                myTotalCommendations: action?.commend?.success || 0
            };
        case commendConstants.GET_MYTOTAL_FAILURE:
            return {
                ...state,
                myTotalCommendationsError: action.error
            };

        case commendConstants.GET_USER_REQUEST:
            return {
                ...state,
                loadingUserCommends: true,
                userCommends: {},
                userCommendsError: false
            };
        case commendConstants.GET_USER_SUCCESS:
            return {
                ...state,
                loadingUserCommends: false,
                userCommends: action?.commends?.success || [],
                userCommendsError: false,
            };
        case commendConstants.GET_USER_FAILURE:
            return {
                ...state,
                loadingUserCommends: false,
                userCommendsError: action.error
            };

        case commendConstants.POST_REQUEST:
            return {
                ...state,
                postCommendSuccess: false,
            };
        case commendConstants.POST_SUCCESS:
            return {
                ...state,
                postCommendSuccess: true
            };
        case commendConstants.POST_FAILURE:
            return {
                ...state,
                postCommendSuccess: action.error
            };

        default:
            return state;
    }
}