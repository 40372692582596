import { coeConstants } from '../constants/coe';
import { coeService } from '../services/coe';
import { alertActions } from './alert';
import { history } from '../helpers/history';
import requestsActions from '../../_core/actions/requests'
import Swal from 'sweetalert2'

export const coeActions = {
    sendRequestCoe,
    getAll,
    update,
    approve,
    reject,
    hold,
    getById
};

function update(coes) {
    return dispatch => {
        dispatch(request(coes));

        coeService.update(coes)
        .then(
            coes => {
                dispatch(success(coes));
                history.push('/admin/coe-requests/page=:id');
                dispatch(alertActions.success('COE Request Updated!'))
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error('COE Update Failed'))
            }
        );
    };
    function request(coes) { return { type: coeConstants.UPDATE_REQUEST, coes } }
    function success(coes) { return { type: coeConstants.UPDATE_SUCCESS, coes } }
    function failure(error) { return { type: coeConstants.UPDATE_FAILURE, error } }
}

function approve(coes) {
    return dispatch => {
        dispatch(request(coes));

        coeService.approve(coes)
         .then(
             coes => {
                 dispatch(success(coes));
                //  history.push('/admin/coe-requests/page=:id');
                history.goBack(); 
                 dispatch(alertActions.success('COE Request Approved!'))
             },
             error => {
                 dispatch(failure(error.toString()));
                 dispatch(alertActions.error('COE Approve Failed'));
             }
         );
    };

    function request(coes) { return { type: coeConstants.APPROVE_REQUEST, coes } }
    function success(coes) { return { type: coeConstants.APPROVE_SUCCESS, coes } }
    function failure(error) { return { type: coeConstants.APPROVE_FAILURE, error } }

}

function reject(coes) {
    return dispatch => {
        dispatch(request(coes));

        coeService.reject(coes)
         .then(
             coes => {
                 dispatch(success(coes));
                //  history.push('/admin/coe-requests/page=:id');
                history.goBack(); 
                 dispatch(alertActions.success('COE Request Rejected!'))
             },
             error => {
                 dispatch(failure(error.toString()));
                 dispatch(alertActions.error('COE Request Reject Failed'));
             }
         );
    };

    function request(coes) { return { type: coeConstants.DECLINE_REQUEST, coes } }
    function success(coes) { return { type: coeConstants.DECLINE_SUCCESS, coes } }
    function failure(error) { return { type: coeConstants.DECLINE_FAILURE, error} }

}

function hold(coes) {
    
    return dispatch => {
        dispatch(request(coes));
        console.log(coes)
        coeService.hold(coes)
         .then(
             coes => {
                 dispatch(success(coes));
                    //history.push('/admin/coe-requests?page='+ Number(selectPage));
                    history.goBack(); 
                 dispatch(alertActions.success('coe request on hold!'))
             },
             error => {
                 dispatch(failure(error.toString()));
                 dispatch(alertActions.error('coe request on hold failed'));
             }
         );
    };

    function request(coes) { return { type: coeConstants.HOLD_REQUEST, coes } }
    function success(coes) { return { type: coeConstants.HOLD_SUCCESS, coes } }
    function failure(error) { return { type: coeConstants.HOLD_FAILURE, error} }

} 

function getAll(selectPage, itemsPerPage) {
    return dispatch => {
        dispatch(request());
    
        coeService.getAll(selectPage, itemsPerPage)
        .then(
            coe => {dispatch(success(coe));
                if(window.location.pathname === '/admin/coe-requests'){
                    history.push('/admin/coe-requests?page=' + Number(selectPage) || 1);
                }
            },
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: coeConstants.GETALL_COEREQUEST_REQUEST } }
    function success(coe) { return { type: coeConstants.GETALL_COEREQUEST_SUCCESS, coe } }
    function failure(error) { return { type: coeConstants.GETALL_COEREQUEST_FAILURE, error } }
    
}

function sendRequestCoe(coe){

     // Custom style for toast
     const Toast = Swal.mixin({
        toast: true,
        width: 400,
        height: 600,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
    });

    return dispatch => {
        dispatch(request(coe));

        coeService.sendRequestCoe(coe)
            .then(
                coe => {
                    dispatch(success());
                    history.goBack()
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:green">Certificate of Employment Request Successful</span>'
                    }).then(() => {
                        const userDetails = JSON.parse(localStorage.getItem("userCredentials"));
                        dispatch(requestsActions.getRequests({
                            itemPerPage: 10,
                            selectPage: 1,
                            userId: userDetails.user._id
                        })) 
                    }).then(() => {
                        // history.push('/employee-self-service')
                    })
                    dispatch(alertActions.success('COE Request Successfully Sent'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    Toast.fire({
                        type: 'error',
                        title: '<span style="color:red">Certificate Of Employment Request Failed!</span>'
                    }).then(() => {
                        // history.push('/profile')
                    })
                    dispatch(alertActions.error('Something went wrong'));
                }
            );


    };   

    function request(coe) { return { type: coeConstants.SEND_COE_REQUEST, coe } }
    function success(coe) { return { type: coeConstants.SEND_COE_SUCCESS, coe } }
    function failure(error) { return { type: coeConstants.SEND_COE_FAILURE, error} }

}

function getById(id) {
    return dispatch => {
        dispatch(request(id));

        coeService.getById(id)
            .then(
                coe => {
                    dispatch(success(coe));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: coeConstants.GETUSER_REQUEST } }
    function success(coe) { return { type: coeConstants.GETUSER_SUCCESS, coe }  }
    function failure(error) { return { type: coeConstants.GETUSER_FAILURE, error } }
}

