export default {
    GET_REQUEST: "GET_EVALUATION_REQUEST",
    GET_SUCCESS: "GET_EVALUATION_SUCCESS",
    GET_FAILURE: "GET_EVALUATION_FAILURE",

    CREATE_REQUEST: "CREATE_EVALUATION_REQUEST",
    CREATE_SUCCESS: "CREATE_EVALUATION_SUCCESS",
    CREATE_FAILURE: "CREATE_EVALUATION_FAILURE",

    UPDATE_REQUEST: "UPDATE_EVALUATION_REQUEST",
    UPDATE_SUCCESS: "UPDATE_EVALUATION_SUCCESS",
    UPDATE_FAILURE: "UPDATE_EVALUATION_FAILURE",

    DELETE_REQUEST: "DELETE_EVALUATION_REQUEST",
    DELETE_SUCCESS: "DELETE_EVALUATION_SUCCESS",
    DELETE_FAILURE: "DELETE_EVALUATION_FAILURE",

    GETBYID_REQUEST: "GETBYID_EVALUATION_REQUEST",
    GETBYID_SUCCESS: "GETBYID_EVALUATION_SUCCESS",
    GETBYID_FAILURE: "GETBYID_EVALUATION_FAILURE",

    GET_LAST_EVALUATION_REQUEST: "GET_LAST_EVALUATION_REQUEST",
    GET_LAST_EVALUATION_SUCCESS: "GET_LAST_EVALUATION_SUCCESS",
    GET_LAST_EVALUATION_FAILURE: "GET_LAST_EVALUATION_FAILURE",

    STATUS_REQUEST: "STATUS_EVALUATION_REQUEST",
    STATUS_SUCCESS: "STATUS_EVALUATION_SUCCESS",
    STATUS_FAILURE: "STATUS_EVALUATION_FAILURE",

    GET_USER_REQUEST: "GET_USER_EVALUATION_REQUEST",
    GET_USER_SUCCESS: "GET_USER_EVALUATION_SUCCESS",
    GET_USER_FAILURE: "GET_USER_EVALUATION_FAILURE",

    GET_PEER_REQUEST: "GET_PEER_EVALUATION_REQUEST",
    GET_PEER_SUCCESS: "GET_PEER_EVALUATION_SUCCESS",
    GET_PEER_FAILURE: "GET_PEER_EVALUATION_FAILURE",
}