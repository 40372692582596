import { hrConcernConstants } from '../constants/hrConcern';
import { hrConcernService } from '../services/hrConcern';
import { alertActions } from './alert';
import { history } from '../helpers/history';
import Swal from 'sweetalert2'
import requestsActions from '../../_core/actions/requests'



export const hrConcernActions = {
    submitHRConcern,
    getAll,
    approve,
    reject,
    hold,
    getById,
    update
};

function getById(id) {
    return dispatch => {
        dispatch(request(id));

        hrConcernService.getById(id)
            .then(
                hrConcerns => {
                    dispatch(success(hrConcerns));
                },
                error => dispatch(failure(error.toString()))   
            );
    }; 
    
    function request() { return { type: hrConcernConstants.GETUSER_REQUEST } }
    function success(hrConcerns) { return { type: hrConcernConstants.GETUSER_SUCCESS, hrConcerns } }
    function failure(error) { return { type: hrConcernConstants.GETUSER_FAILURE, error } }
}

function update(hrConcerns) {
    return dispatch => {
        dispatch(request(hrConcerns));

        hrConcernService.update(hrConcerns)
        .then(
            hrConcerns => {
                dispatch(success(hrConcerns));
                //history.push(`/admin/hr-concerns/page=:id`);
                history.goBack();
                dispatch(alertActions.success('HR request updated'))
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error('HR request update failed'))
            }
        )
   }
   function request (hrConcerns) { return { type: hrConcernConstants.UPDATEUSER_REQUEST, hrConcerns } }
   function success (hrConcerns) { return { type: hrConcernConstants.UPDATEUSER_SUCCESS, hrConcerns } }
   function failure(error) { return { type: hrConcernConstants.UPDATEUSER_FAILURE, error}}
}

function approve(hrConcerns) {
    return dispatch => {
        dispatch(request(hrConcerns));

        hrConcernService.approve(hrConcerns)
        .then(
            hrConcerns => {
                dispatch(success(hrConcerns));
                //history.push(`/admin/hr-concerns/page=:id`);
                history.goBack();
                dispatch(alertActions.success('HR request approved'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error('HR request failed'));
            }
        )
    }

    function request(hrConcerns) { return { type: hrConcernConstants.APPROVE_HR_CONCERN_REQUEST, hrConcerns } };
    function success(hrConcerns) { return { type: hrConcernConstants.APPROVE_HR_CONCERN_SUCCESS, hrConcerns } };
    function failure(error) { return { type: hrConcernConstants.APPROVE_HR_CONCERN_FAILURE, error } }
}

function reject(hrConcerns) {
    return dispatch => {
        dispatch(request(hrConcerns));

        hrConcernService.reject(hrConcerns)
        .then(
            hrConcerns => {
                dispatch(success(hrConcerns));
                //history.push(`/admin/hr-concerns/page=:id`);
                history.goBack();
                dispatch(alertActions.success('HR request rejected'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error('HR reject failed'))
            }
        )
    }

    function request(hrConcerns) { return { type: hrConcernConstants.DECLINE_REQUEST, hrConcerns } }
    function success(hrConcerns) { return { type: hrConcernConstants.DECLINE_SUCCESS, hrConcerns } }
    function failure(error) { return { type: hrConcernConstants.DECLINE_FAILURE, error } }
}

function hold(hrConcerns) {
    return dispatch => {
        dispatch(request(hrConcerns));

        hrConcernService.hold(hrConcerns)
        .then(
            hrConcerns => {
                dispatch(success(hrConcerns));
                //history.push(`/admin/hr-concerns/page=:id`);
                history.goBack();
                dispatch(alertActions.success('HR Request On-Hold'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error('HR Request on-hold failed'));
            }
        )
    }

    function request(hrConcerns) { return { type: hrConcernConstants.HOLD_HR_CONCERN_REQUEST, hrConcerns} }
    function success(hrConcerns) { return { type: hrConcernConstants.HOLD_HR_CONCERN_SUCCESS, hrConcerns } }
    function failure(error) { return { type: hrConcernConstants.HOLD_HR_CONCERN_FAILURE, error } } 
} 

function getAll(selectPage, itemsPerPage) {
    return dispatch => {
        dispatch(request());

    hrConcernService.getAll(selectPage, itemsPerPage)
    .then(
        hrConcerns => {
            dispatch(success(hrConcerns));
            if(window.location.pathname === '/admin/hr-concerns'){
                history.push('/admin/hr-concerns?page=' + Number(selectPage) || 1);
            }
        },
        error => dispatch(failure(error.toString()))
       );
    };

    function request() { return { type: hrConcernConstants.GETALL_HRCONCERN_REQUEST } }
    function success(hrConcerns) { return { type: hrConcernConstants.GETALL_HRCONCERN_SUCCESS, hrConcerns } }
    function failure(error) { return { type: hrConcernConstants.GETALL_HRCONCERN_FAILURE, error } }

}

function submitHRConcern(hrConcern){

    const Toast = Swal.mixin({
        toast: true,
        width: 400,
        height: 600,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
    });

    return dispatch => {
        dispatch(request(hrConcern));

        hrConcernService.submitHRConcern(hrConcern)
            .then(
                supportTicket => {
                    dispatch(success());
                    history.goBack();
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:green">Employment Concern Request Successful </span>'
                    }).then(() => {
                        const userDetails = JSON.parse(localStorage.getItem("userCredentials"));
                            dispatch(requestsActions.getRequests({
                                itemPerPage: 10,
                                selectPage: 1,
                                userId: userDetails.user._id
                            })) 

                    }).then(() => {
                        //history.push()
                    })
                    dispatch(alertActions.success('HR Concern Successfully Submitted'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    Toast.fire({
                        type: 'error',
                        title: '<span style="color:red;"> Hr Concern Request Failed </span>'
                    }).then(() => {
                        //histroy.push()
                    })
                    dispatch(alertActions.error('Something went wrong'));
                }
            );


    };   

    function request(supportTicket) { return { type: hrConcernConstants.SUBMIT_HRCONCERN_REQUEST, supportTicket } }
    function success(supportTicket) { return { type: hrConcernConstants.SUBMIT_HRCONCERN_SUCCESS, supportTicket } }
    function failure(error) { return { type: hrConcernConstants.SUBMIT_HRCONCERN_FAILURE, error} }

}

 