 import React from "react";
 import {useDropzone} from 'react-dropzone';

import { DragDropImage,} from "../../Components/Uploader/upload";
import dropIcon from '../../_templates/dropIcon.png'
import './Dropzone.sass';

const Dropzone = ({ item }) => {
    const [files, setFiles] = React.useState([]);
    const [requestFile, setRequestFile] = React.useState([]);

    const {getRootProps, getInputProps, open} = useDropzone({
        accept: 'image/x-png, image/png, image/jpg, image/jpeg, .pdf, .doc, .docx ,.xls , .xlsx',
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
            })));
            setRequestFile(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                    }))[0],
            );
        }
    });

    const removeSelectedImage = () => {
        setFiles([]);
        setRequestFile([]);
    } 

    React.useEffect(() => {
        if (item) {
            item(requestFile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestFile]);

  return (
    <>
        <div {...getRootProps({className: 'dropzone filesdropzone'})}>
            <div className={files[0]} >
                <input {...getInputProps()} />
                <div>
                    <p className="dropLeave" style={{display:'inline-flex'}}>
                        <img height={20} src={dropIcon} alt="" style={{ marginRight: 8 }}/>
                            Drag and drop files here 
                    </p>
                    <p className="dropLeave">or</p>
                </div>
                <div onClick={open} className="upload-es-btn">
                    <p>Upload</p>
                </div>
            </div>
        
        </div>
        <DragDropImage removeImage={removeSelectedImage} selectedImage={files}/>
    </>

  );
}

export default Dropzone;