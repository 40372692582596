import React, { useCallback, useRef } from 'react';
import cc from "classcat";
import { Link, NavLink } from 'react-router-dom';

import { history } from "../_core/helpers/history";

import { UserDashboardContext } from '../UserDashboard/UserDashboard';

import './Sidebar.scss';

import award from '../_templates/award.png';
import briefcase from '../_templates/briefcase.png';
import grid from '../_templates/grid.png';
import pragmaHrLogo from '../_templates/logo-white.svg';
import search from '../_templates/search.png';
import timekeeping from '../_templates/timekeeping.png';
import users from '../_templates/users.png';
import performance from '../_templates/performance.png';
import rewards from '../_templates/rewards_icon.png';

const sidebarLinks = [
    {
        link: '/dashboard',
        name: 'Dashboard',
        icon: grid
    },
    {
        link: '/people',
        name: 'People',
        icon: users
    },
    {
        link: '/engage',
        name: 'Engage',
        icon: award
    },
    {
        link: '/rewards',
        name: 'Rewards',
        icon: rewards
    },
    {
        link: '/timekeeping',  
        name: 'Timekeeping', 
        icon: timekeeping  
    },
    {
        link: '/employee-self-service',
        name: 'Employee Self Service',
        icon: briefcase
    },
    {
        link: '/performance',
        name: 'Performance',
        icon: performance
    },
   
];

const Sidebar = ({ toggleIsNavigationBarExpanded, isNavigationBarExpanded }) => {
    const isTablet = React.useContext(UserDashboardContext).isTablet;

    const searchRef = useRef();

    const [searchVal, setSearchVal] = React.useState('');

    const secret = JSON.parse(localStorage.getItem("secretCredentials"));

    // const searchQuery = window.location.pathname.includes('/search?') ? window.location.search?.split('=')[1] : "";

    const onClickSearch = useCallback(() => {
        toggleIsNavigationBarExpanded(true);
        searchRef.current.focus();
    }, [toggleIsNavigationBarExpanded]);

    const checkSearchVal = useCallback((e) => {
        e.preventDefault();
        const code = e.keyCode ? e.keyCode : e.which;
        const val = searchRef.current.value;
        setSearchVal(val)

        if(code === 13 && !e.shiftKey){
            const trimStr = val.replace(/\s\s+/g, ' ').trim();
            if(trimStr.length >= 3){
                history.push(`/search?search=${trimStr}`);
            }
        }
    },[searchRef]);

    React.useEffect(() => {
        if (isTablet) {
            toggleIsNavigationBarExpanded(false);
        }
    }, [isTablet, toggleIsNavigationBarExpanded]);

    return (
        <div
            className={cc({
                'desktop': !isTablet,
                'expanded': isNavigationBarExpanded,
                'tablet': isTablet,
                'side-bar': true
            })}
        >
            <Link className={cc({ 'logo-container': true, 'expanded': isNavigationBarExpanded })} to="/dashboard">
                <img
                    alt="pragmahr"
                    height={50}
                    src={pragmaHrLogo}
                />
                <div style={{textAlign:'center', position:'relative', top:'-20px', right:'-10px'}}>
                    <p>User dashboard</p>
                </div>
            </Link>

            <div className="search" onClick={onClickSearch}>
                <img src={search} alt="search" height={24}/>
                <input 
                    ref={searchRef} 
                    type="text" 
                    placeholder="Search" 
                    onChange={(e) => checkSearchVal(e)}
                    onKeyUp={(e) => checkSearchVal(e)}
                    value={searchVal}
                />
            </div>

            {
                sidebarLinks
                    .map(sideBarLink => (

                        <React.Fragment key={sideBarLink.link}>
                            {
                                sideBarLink.name === 'Performance' &&
                                secret !== null ?
                                ('')
                                :
                                <NavLink
                                activeClassName="active"
                                key={sideBarLink.link}
                                to={sideBarLink.link}
                                >
                                    <div className="sidebar-item">
                                        <img src={sideBarLink.icon} alt="icon" height={24} />
                                        <p>{sideBarLink.name}</p>
                                    </div>
                                </NavLink>
                                
                            }
                       </React.Fragment>
                        // <NavLink
                        //     activeClassName="active"
                        //     key={sideBarLink.link}
                        //     to={sideBarLink.link}
                        // >
                        //     <div className="sidebar-item">
                        //         <img src={sideBarLink.icon} alt="icon" height={24} />
                        //         <p>{sideBarLink.name}</p>
                        //     </div>
                        // </NavLink>
                    ))
            }
        </div>
    );
};

export default Sidebar;