import { get, put, post,del } from '../helpers/api';
import { userService } from './user';

export const userChallengeService = {
    getByUser,
    getByChallenge,
    acceptQuestChallenge,
    finishQuestChallenge,
    completeQuestChallenge,
    postAcceptQuestChallenge,
    quitQuestChallenge,
    deleteQuestChallenge,
    finishUploadChallenge,
    failQuestChallenge,
    getQuestParticipants,
    getUserQuestChallenges,
    completeQuest,
    revertQuest
};

function getByUser(id, page, itemsPerPage){
    return get(`/api/v1/user/${id}/challenge?page=${page}&items-per-page=${itemsPerPage || 20}`).then(handleResponse);    
}

function getByChallenge(uid, cid){
    return get(`/api/v1/user/${uid}/challenge/${cid}`).then(handleResponse);    
}

function acceptQuestChallenge(uid, cid){
    return put(`/api/v1/user/${uid}/challenge/${cid}/accept`).then(handleResponse);    
}

function finishQuestChallenge(uid, cid, files){

    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
     let challengeId = cid
     let userId = uid
     const imageFile = files;
     const fd = new FormData();
     fd.append('file',imageFile)
    
    // //checking if post images has a content file
    if(isFile(files) === true){
        files = null;
    }
    return put(`/api/v1/user/${uid}/challenge/${cid}/finish`).then(handleResponse)
    .then(response => {
        if(isFile(imageFile) === true){
            finishUploadChallenge(userId,challengeId,fd);
        }
    });
        
}

function completeQuestChallenge(uid, cid){
    return put(`/api/v1/user/${uid}/challenge/${cid}/complete`).then(handleResponse);    
}

function revertQuest(uid, cid){
    return put(`/api/v1/user/${uid}/quest/${cid}/revert`).then(handleResponse);    
}

function postAcceptQuestChallenge(uid, challenge){
    return post(`/api/v1/user/${uid}/challenge`, JSON.stringify(challenge)).then(handleResponse);    
}

function quitQuestChallenge(uid, cid){
    return put(`/api/v1/user/${uid}/challenge/${cid}/quit`,).then(handleResponse);    
}

function deleteQuestChallenge(uid, cid){
    return del(`/api/v1/user/${uid}/challenge/${cid}`,).then(handleResponse);    
}

function finishUploadChallenge(uid, cid, image){
    return post(`/api/v1/upload/${uid}/userchallenge/${cid}`, image).then(handleResponse);
}

function failQuestChallenge(uid, cid){
    return put(`/api/v1/user/${uid}/challenge/${cid}/failed`,).then(handleResponse);    
}

function getQuestParticipants(qid, page, itemsPerPage){
    return get(`/api/v1/quest/${qid}/participants?page=${page}&items-per-page=${itemsPerPage || 20}`,).then(handleResponse);    
}

function getUserQuestChallenges(id,qid){
    return get(`/api/v1/user/${id}/quest/${qid}`,).then(handleResponse);    
}

function completeQuest(id,qid){
    return put(`/api/v1/user/${id}/quest/${qid}/complete`,).then(handleResponse);    
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}
