import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
// import { userActions } from "../../../_core/actions/user";
import { userService } from "../../../_core/services/user";
import { overtimeActions } from "../../../_core/actions/overtime";
import { essActions } from "../../../_core/actions/ess";
import { alertActions } from "../../../_core/actions/alert";
import { notifActions } from "../../../_core/actions/notification";
import "./UpdateOvertimeDark.scss";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import avatar from "../../../_templates/man.png";
//import ReactImageFallback from "react-image-fallback";
import { requestStatus } from "../../../_core/helpers/requestStatus";
import { ConfirmationModal } from "../../Components/AdminModals/RequestPopup";
import fileIcon from '../../../_templates/dark/file_blank_outline.png';
import download from '../../../_templates/dark/download.png';
import close from '../../../_templates/dark/off_outline_close.png';
import FileViewer from 'react-file-viewer';
import styled, { css } from "styled-components";
import { When } from "react-if";

import {
    ContentWrapper,
  } from "../../Components/PageWrapper/ContentWrapper";
import { commentService } from "../../../_core/services/comment";
import { commentActions } from "../../../_core/actions/comment";
import PreviewLeaveUserWidget from "../../AdminWidget/PreviewUserDetailsWidget/PreviewLeaveUserWidget";


const customStyles = {
  content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
  }
};


export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;
const mapStateToProps = ({ overtimes,comments }) => ({
  overtimes,
  comments
});

const OvertimeRequestUpdate = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();
  let HeaderContent = "Overtime Request";

  const [update, setUpdate] = React.useState(false);
  const [overtimeRequest, setovertimeRequest] = React.useState([]);
  const [startD, setstartDate] = React.useState("");
  const [endD, setendDate] = React.useState("");
  const [startT, setstartTime] = React.useState("");
  const [endT, setendTime] = React.useState("");
  const [showAppModal, setAppModal] = React.useState(false);
  const [showModal, setModal] = React.useState(false);
  const [showRejModal, setRejModal] = React.useState(false);
  const [count, setRender] = React.useState(true);
  const countRef = React.useRef(count);
  countRef.current = count;
  const [showFilePreview, setFilePreview] = React.useState(false);
  const [FileUrl, setFileUrl] = React.useState('');
  const [FileType, setFileType] = React.useState('');
  const [userManager, setManager] = React.useState([]);
  const [isDisplayUserDetails, setIsDisplayUserDetails] = React.useState(false); // eslint-disable-line
  const [comment, setCommment] = React.useState([]);
  const [reply, setReply] = React.useState([]);

  let params = useParams();
  const { requestId, page } = params;
  let admin = JSON.parse(localStorage.getItem("userCredentials"));

  useEffect(() => {
    dispatch(overtimeActions.getById(requestId));
    dispatch(commentActions.getByOrigin(requestId, 1, 999));
    setIsDisplayUserDetails(true);
    alertTimeOut();
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    const { overtimes } = props || {};
    const overtimeSingle = (overtimes.overtime && overtimes.overtime.success) || [];
    setovertimeRequest(overtimeSingle);
  }, [props]);
  
  useEffect(() => {
    commentService.getByOrigin(requestId, 1, 999).then(res=>{
        if(res.items?.length !== 0){
            setCommment(res.items)
            commentService.getByOrigin(res.items[0]?._id, 1, 999).then(res=>{
                if(res.items){

                    if(res.items.length !== 0){
                        setReply(res.items)
                    }

                }
            }).catch(error=>{console.log(error)});

        }
    }).catch(error=>{console.log(error)});
  }, [props, requestId]);

  const alertTimeOut = () => {
    setTimeout(() => {
      setRender(countRef.current);
      props.dispatch(alertActions.clear());
    }, 3000);
  };

  const [OT, setOT] = React.useState({
    _id: "",
    description: "",
    startedAt: "",
    endedAt: "",
    // refCode: '',
    status: "",
    approvedBy: "",
    approvalRemark: "",
    approvedAt: "",
    rejectedBy: "",
    rejectedAt: "",
    notedBy: "",
    notedRemark: "",
    notedAt: "",
    createdAt: "",
    updatedAt: "",
    __v: "",

    approvedRemark: "",
    type: "",
    rejectedRemark: "",
  });

  const goBack = () => {
    props.history.goBack();
  };

  const goBackPath = `/admin/overtime-requests?page=:${page}`;

  const openFilePreview = () => {
    setFilePreview(true);
};

  const handleCloseModal = () => {
    setModal(false);
    setRejModal(false);
    setAppModal(false);
  };

  const onApproveOpenModal = () => {
    setAppModal(true);
  };
  const onHoldOpenModal = () => {
    setModal(true);
  };
  const onRejectOpenModal = () => {
    setRejModal(true);
  };

  const getStartTime = (event) => {
    event.preventDefault();
    var time = document.getElementById("startTime").value;
    setstartTime(time);
  };

  const getEndTime = (event) => {
    event.preventDefault();
    var time = document.getElementById("endTime").value;
    setendTime(time);
  };

  const handleChangeStart = (date) => {
    setstartDate(date);
  };

  const handleChangeEnd = (date) => {
    setendDate(date);
  };

  React.useEffect(() => {
    getDates();
    // eslint-disable-next-line
  }, [startD, endD, startT, endT]);

  const getDates = () => {
    const { overtimes } = props || {};
    const ot = (overtimes.overtime && overtimes.overtime.success) || [];
    let overtimeSingle = [];
    overtimeSingle = ot;

    let requestId = props.match.params.id;

    overtimeSingle.map((overtime) => {
      if (overtime._id === requestId) {
        var startTime = startT
          ? startT
          : moment(overtime.startedAt).utc().format("HH:mm");
        var startDate = startD
          ? moment(startD).format("YYYY-MM-DD")
          : moment(overtime.startedAt).utc().format("YYYY-MM-DD");
        var formatStartDate = startDate + "T" + startTime + ":00.000Z";
        // let newStartDate = moment(formatStartDate).format()
        var newStartDate = formatStartDate;
        var endTime = endT
          ? endT
          : moment(overtime.endedAt).utc().format("HH:mm");
        var endDate = endD
          ? moment(endD).format("YYYY-MM-DD")
          : moment(overtime.endedAt).utc().format("YYYY-MM-DD");
        var formatEndDate = endDate + "T" + endTime + ":00.000Z";
        // let newEndDate = moment(formatEndDate).format()
        var newEndDate = formatEndDate;

        setOT({
          startedAt: newStartDate,
          endedAt: newEndDate,
        });
        setUpdate(true);
        if (OT.startedAt !== "" && OT.endedAt !== "") {
          overtime.startedAt = newStartDate;
          overtime.endedAt = newEndDate;
        }
      }
      return overtime;
    });
  };

  const descriptionChange = (e) => {
    const overtimes = props.overtimes;

    overtimes.overtime.success.map((overtime) => {
      setOT(
        {
          [e.target.name]: e.target.value,
        },
        setUpdate(true),
        () => {
          let desc = OT.description;
          overtime.description = desc;
        }
      );
      return overtime;
    });
  };

  const onApproveChange = (e) => {
    let admin = JSON.parse(localStorage.getItem("userCredentials"));
    setOT({
      [e.target.name]: e.target.value,
      approvedAt: moment().format(),
      approvedBy: admin.user._id,
    });
  };

  const onHoldChange = (e) => {
    let admin = JSON.parse(localStorage.getItem("userCredentials"));
    setOT({
      [e.target.name]: e.target.value,
      notedAt: moment().format(),
      notedBy: admin.user._id,
    });
  };

  const onRejectChange = (e) => {
    let admin = JSON.parse(localStorage.getItem("userCredentials"));
    setOT({
      [e.target.name]: e.target.value,
      rejectedAt: moment().format(),
      rejectedBy: admin.user._id,
    });
  };

  const markApproved = (id) => {
    const { overtimes } = props;

    overtimes.overtime.success.map((overtime) => {
      const requestUser = [
        overtime.users[0].firstName,
        overtime.users[0].lastName,
      ].join(" ");
      const referenceCode = overtime.refCode;
      const requestDates = [overtime.startedAt, overtime.endedAt];
      const requestRemarks = OT.approvalRemark;

      ConfirmationModal(
        requestUser,
        referenceCode,
        requestDates,
        requestRemarks,
        "Remarks"
      ).then((result) => {
        if (result.value) {
          const updateOT = {
            _id: id,
            subject: overtime.subject || "Overtime Request",
            type: overtime.type,
            subtype: overtime.subtype,
            status: OT.status,
            approvalRemark: OT.approvalRemark,
            approvedAt: OT.approvedAt,
            approvedBy: OT.approvedBy,
          };
          const updateNotif = {
            type: "admin",
            title: "Overtime Request Approved",
            content: "Your overtime request was approved.",
            user_id: overtime.user_id,
            recurring: "true",
          };
          setAppModal(false);
          setRender(true);
          // dispatch(overtimeActions.approve(updateOT));
          dispatch(essActions.approve(updateOT, goBackPath));
          dispatch(notifActions.createNotif(updateNotif));
        }
      });
      return overtime;
    });
  };

  const markUpdate = (id) => {
    const { overtimes } = props;

    overtimes.overtime.success.map((overtime) => {
      const requestUser = [
        overtime.users[0].firstName,
        overtime.users[0].lastName,
      ].join(" ");
      const referenceCode = overtime.refCode;
      const requestDates = [
        OT.startedAt ? OT.startedAt : overtime.startedAt,
        OT.endedAt ? OT.endedAt : overtime.endedAt,
      ];
      const requestRemarks = "";

      ConfirmationModal(
        requestUser,
        referenceCode,
        requestDates,
        requestRemarks
      ).then((result) => {
        if (result.value) {
          if (overtime._id === id) {
            const updateOT = {
              _id: id,
              status: "new",
              subject: overtime.subject || "Overtime Request",
              type: overtime.type,
              subtype: overtime.subtype,
              description: OT.description
                ? OT.description
                : overtime.description,
              startedAt: OT.startedAt ? OT.startedAt : overtime.startedAt,
              endedAt: OT.endedAt ? OT.endedAt : overtime.endedAt,
            };
            const updateNotif = {
              type: "admin",
              title: "Overtime Request Updated",
              content: "Your Overtime request was updated.",
              user_id: overtime.user_id,
              recurring: "true",
            };
            setRender(true);
            // dispatch(overtimeActions.update(updateOT));
            dispatch(essActions.update(updateOT, goBackPath));

            dispatch(notifActions.createNotif(updateNotif));
          }
        }
      });
      return overtime;
    });
  };

  const markHold = (id) => {
    const { overtimes } = props;

    overtimes.overtime.success.map((overtime) => {
      const requestUser = [
        overtime.users[0].firstName,
        overtime.users[0].lastName,
      ].join(" ");
      const referenceCode = overtime.refCode;
      const requestDates = [overtime.startedAt, overtime.endedAt];
      const requestRemarks = OT.notedRemark;

      ConfirmationModal(
        requestUser,
        referenceCode,
        requestDates,
        requestRemarks,
        "Remarks"
      ).then((result) => {
        if (result.value) {
          const updateOT = {
            _id: id,
            subject: overtime.subject || "Overtime Request",
            type: overtime.type,
            subtype: overtime.subtype,
            status: OT.status,
            notedRemark: OT.notedRemark,
            notedAt: OT.notedAt,
            notedBy: OT.notedBy,
          };
          // const updateNotif = {
          //   type: "admin",
          //   title: "Overtime Request On-Hold",
          //   content: "Your overtime request was On-Hold.",
          //   user_id: overtime.user_id,
          //   recurring: "true",
          // };
          setModal(false);
          setRender(true);
          // dispatch(overtimeActions.hold(updateOT));
          dispatch(essActions.hold(updateOT, goBackPath));

          //dispatch(notifActions.createNotif(updateNotif));
        }
      });
      return overtime;
    });
  };

  const markRejected = (id) => {
    const { overtimes } = props;

    overtimes.overtime.success.map((overtime) => {
      const requestUser = [
        overtime.users[0].firstName,
        overtime.users[0].lastName,
      ].join(" ");
      const referenceCode = overtime.refCode;
      const requestDates = [overtime.startedAt, overtime.endedAt];
      const requestRemarks = OT.rejectedRemark;

      ConfirmationModal(
        requestUser,
        referenceCode,
        requestDates,
        requestRemarks,
        "Remarks"
      ).then((result) => {
        if (result.value) {
          const updateOT = {
            _id: id,
            subject: overtime.subject || "Overtime Request",
            type: overtime.type,
            subtype: overtime.subtype,
            status: OT.status,
            rejectedRemark: OT.rejectedRemark,
            rejectedAt: OT.rejectedAt,
            rejectedBy: OT.rejectedBy,
          };

          const updateNotif = {
            type: "admin",
            title: "Overtime Request Rejected",
            content: "Your overtime request was rejected.",
            user_id: overtime.user_id,
            recurring: "true",
          };
          setRejModal(false);
          setRender(true);
          // dispatch(overtimeActions.reject(updateCOE));
          dispatch(essActions.reject(updateOT, goBackPath));

          dispatch(notifActions.createNotif(updateNotif));
        }
      });
      return overtime;
    });
  };

  const ApproverDetails = ({ details, status, date, manager }) => {
    if (userManager.length === 0) {
        const getManager = async () => {
        try {
          const user = await userService.getById(manager);
          setManager(user.user[0].details);
        } catch (error) {
          console.error(error);
        }
      };
      getManager();
    }
    if (userManager.length !== 0) {
      return (
        <div>
          <div className="request-approver-content">
            <div>
                  <p>Approver: </p>
              <div>
                <img
                  src={userManager[0]?.avatar}
                  alt="approver-img"
                  onError={(e) => (e.target.src = avatar)}
                />
                <p>
                  {[userManager[0]?.firstName, userManager[0]?.lastName].join(" ")}
                </p>
              </div>
            </div>
              <div>
                <p>Role : Manager</p>
                {/* <p>{`Date of ${
                  status === requestStatus.rejected
                    ? "Rejection"
                    : status === requestStatus.onHold
                    ? "Hold"
                    : "Approval"
                } : ${moment(date).format("MMMM DD YYYY")}`}</p> */}
              </div>
          </div>

          {overtimeRequest[0]?.noter_details?.length !== 0 ? (
            <div>
              <div className="request-approver-content">
                <div>
                    <p>Held by : </p>
                    {
                        overtimeRequest[0].noter_details?.map(detail => (
                            <div key={detail._id}>
                                <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                                <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                            </div>
                        ))
                    }
                </div>
                <div>
                    <p>{`Date put on hold :  ${moment(overtimeRequest[0]?.notedAt).format("MMMM DD YYYY")}`}</p>
                </div>
              </div>
              <div className="floating-label">
                <textarea
                className="floating-input floating-textarea hr-textarea"
                value={overtimeRequest[0]?.notedRemark}
                name="description"
                cols="30"
                placeholder=" "
                style={{ resize: "none" }}
                row="2"
                readOnly
                disabled
                />
                <label className="floating-text">Remarks</label>
              </div>
            </div>
          ) : ''}

          {overtimeRequest[0]?.approver_details?.length !== 0 ? (
            <div>
              <div className="request-approver-content">
                <div>
                  <p>Approved by : </p>
                  {
                    overtimeRequest[0].approver_details?.map(detail => (
                      <div>
                          <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                          <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                      </div>
                    ))
                  }
                </div>
                <div>
                    <p>{`Date of Approval : ${moment(overtimeRequest[0]?.approvedAt).format("MMMM DD YYYY")}`}</p>
                </div>
              </div>
              <div className="floating-label">
                  <textarea
                  className="floating-input floating-textarea hr-textarea"
                  value={overtimeRequest[0]?.approvalRemark}
                  name="description"
                  cols="30"
                  placeholder=" "
                  style={{ resize: "none" }}
                  row="2"
                  readOnly
                  disabled
                  />
                  <label className="floating-text">Remarks</label>
              </div>
            </div>
          ) : ''}

          {overtimeRequest[0]?.rejecter_details?.length !== 0 ? (
            <div>
              <div className="request-approver-content">
                <div>
                    <p>Rejected by : </p>
                    {
                      overtimeRequest[0].rejecter_details?.map(detail => (
                        <div>
                            <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                            <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                        </div>
                      ))
                    }
                </div>
                  <div>
                    <p>{`Date rejected : ${moment(overtimeRequest[0]?.rejectedAt).format("MMMM DD YYYY")}`}</p>
                  </div>
              </div>
              <div className="floating-label">
                  <textarea
                  className="floating-input floating-textarea hr-textarea"
                  value={overtimeRequest[0]?.rejectedRemark}
                  name="description"
                  cols="30"
                  placeholder=" "
                  style={{ resize: "none" }}
                  row="2"
                  readOnly
                  disabled
                  />
                  <label className="floating-text">Remarks</label>
              </div>
            </div>
          ) : ''}
        </div>
      );
    } else {
      return <React.Fragment />;
    }
  };

  return (
    <CustomContentWrapper
        displayUserWrapper={isDisplayUserDetails}
        style={{ gridTemplateColumns: "70% 27%" }}
    >
    <ContentWrapper className="update_overtime">
      <div>
        {props.overtimes.loading && (
          <em style={{ color: "#9BA3CA" }}>loading...</em>
        )}
      </div>
      {overtimeRequest.map((overtime, index) => {
        return overtime._id === requestId ? (
          <div className="row" key={index}>
            <div className="col s4 p-0" style={{ width: "100%" }}>
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="table-header">
                  <span className="sub-header">{HeaderContent}</span>
                </div>

                {/* <div>
                  {overtime.notedRemark && overtime.status !== "new" ? (
                    <div className="col s12 m12 l12 remark-dark">
                      <p
                        className="info"
                        style={{
                          color: "#B1822D",
                          marginTop: "0",
                          marginLeft: "10px",
                        }}
                      >
                        On hold remark :{" "}
                        <span
                          style={{
                            color: "#9BA3CA",
                            fontWeight: "normal",
                            textTransform: "lowercase",
                          }}
                        >
                          {overtime.notedRemark}
                        </span>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {overtime.rejectedRemark ? (
                    <div className="col s12 m12 l12 remark-dark">
                      <p
                        className="info"
                        style={{
                          color: "#AE555C",
                          marginTop: "0",
                          marginLeft: "10px",
                        }}
                      >
                        Rejection remark :{" "}
                        <span
                          style={{
                            color: "#9BA3CA",
                            fontWeight: "normal",
                            textTransform: "lowercase",
                          }}
                        >
                          {overtime.rejectedRemark}
                        </span>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {overtime.approvalRemark ? (
                    <div className="col s12 m12 l12 remark-dark">
                      <p
                        className="info"
                        style={{
                          color: "#2B7A69",
                          marginTop: "0",
                          marginLeft: "10px",
                        }}
                      >
                        Approval remark :{" "}
                        <span
                          style={{
                            color: "#9BA3CA",
                            fontWeight: "normal",
                            textTransform: "lowercase",
                          }}
                        >
                          {overtime.approvalRemark}
                        </span>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                <div
                  className="row column2"
                  style={{ paddingLeft: "2%", paddingRight: "2%" }}
                >
                  <div className="col l3 m3 mobile-grid">
                    <p className="coe-info-dark">
                      Application Status :
                      <span
                        className={
                          OT.status
                            ? OT.status === "on hold"
                              ? "onHold"
                              : OT.status
                            : overtime.status === "on hold"
                            ? "onHold"
                            : overtime.status
                        }
                      >
                        {" "}
                        {OT.status
                          ? OT.status
                          : overtime.status
                          ? overtime.status === "new"
                            ? "In review"
                            : overtime.status
                          : "No Status"}
                      </span>
                    </p>
                  </div>
                  <div className="col l6 m6 mobile-grid">
                    <p className="info">
                      Date Filed :{" "}
                      <span className="dateInfo">
                        {moment(overtime.createdAt).format(
                          "dddd, MMMM DD YYYY"
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                {/* --------------------------------------------------> DATE PICKER <-------------------------------------------------- */}
                <div
                  className="row column3"
                  style={{ paddingLeft: "2%", paddingRight: "2%" }}
                >
                  <div className="col l6 m6 s12">
                    <div className="floating-label attrName ">
                      {overtime.status === "approved" ||
                      overtime.status === "rejected" ? (
                        <span id="main_text">
                          <DatePicker
                            selected={
                              new Date(
                                moment(overtime.startedAt)
                                  .utc()
                                  .format("YYYY-MM-DD")
                              )
                            }
                            showMonthDropdown
                            showYearDropdown
                            readOnly
                            value={moment(overtime.startedAt).utc().format(
                              "MM - D - YYYY"
                            )}
                            className="floating-input calendar-dark"
                            id="abc"
                            placeholderText=" "
                          />
                          <label className="title floating-text">From</label>
                        </span>
                      ) : (
                        <span id="main_text">
                          <DatePicker
                            selected={
                              startD
                                ? startD
                                : new Date(
                                    moment(overtime.startedAt)
                                      .utc()
                                      .format("YYYY-MM-DD")
                                  )
                            }
                            onChange={handleChangeStart}
                            showMonthDropdown
                            showYearDropdown
                            value={
                              startD
                                ? moment(startD).format("MM - D - YYYY")
                                : overtime.startedAt
                                ? moment(overtime.startedAt)
                                    .utc()
                                    .format("MM - D - YYYY")
                                : "no date"
                            }
                            className="floating-input calendar-dark"
                            id="abc"
                            placeholderText=" "
                          />
                          <label className="title floating-text">From</label>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col l6 m6 s12">
                    <div className="floating-label attrName " id="main_text">
                      {overtime.status === "approved" ||
                      overtime.status === "rejected" ? (
                        <span id="main_text">
                          <DatePicker
                            selected={
                              new Date(
                                moment(overtime.endedAt)
                                  .utc()
                                  .format("YYYY-MM-DD")
                              )
                            }
                            showMonthDropdown
                            showYearDropdown
                            readOnly
                            value={moment(overtime.endedAt).utc().format(
                              "MM - D - YYYY"
                            )}
                            className="floating-input calendar-dark"
                            id="abc"
                            placeholderText=" "
                          />
                          <label className="title floating-text">To</label>
                        </span>
                      ) : (
                        <span id="main_text">
                          <DatePicker
                            selected={
                              endD
                                ? endD
                                : new Date(
                                    moment(overtime.endedAt)
                                      .utc()
                                      .format("YYYY-MM-DD")
                                  )
                            }
                            onChange={handleChangeEnd}
                            showMonthDropdown
                            showYearDropdown
                            minDate={startD ? startD : ""}
                            value={
                              endD
                                ? moment(endD).format("MM - D - YYYY")
                                : overtime.endedAt
                                ? moment(overtime.endedAt)
                                    .utc()
                                    .format("MM - D - YYYY")
                                : "no date"
                            }
                            className="floating-input calendar-dark"
                            id="abcd"
                            placeholderText=" "
                          />
                          <label className="title floating-text">To</label>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* --------------------------------------------------> END DATE PICKER <-------------------------------------------------- */}
                {/* --------------------------------------------------> TIME PICKER <-------------------------------------------------- */}
                <div
                  className=" row column4"
                  style={{ paddingLeft: "2%", paddingRight: "2%" }}
                >
                  <div className="col l6 m6 s12">
                    <div className="floating-label attrName ">
                      {overtime.status === "approved" ||
                      overtime.status === "rejected" ? (
                        <span>
                          <input
                            id="icon"
                            className="floating-input"
                            type="text"
                            placeholder=" "
                            value={moment(overtime.startedAt)
                              .utc()
                              .format("h:mm A")}
                            readOnly
                          />
                          <label className="floating-text">From</label>
                        </span>
                      ) : (
                        <span>
                          <input
                            className="clock-dark floating-input"
                            type="time"
                            value={
                              startT
                                ? startT
                                : overtime.startedAt.split("T")[1].substr(0, 5)
                            }
                            id="startTime"
                            onChange={getStartTime}
                          />
                          <label className="floating-text">From</label>
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col l6 m6 s12">
                    <div className="floating-label attrName ">
                      {overtime.status === "approved" ||
                      overtime.status === "rejected" ? (
                        <span>
                          <input
                            id="icon"
                            className="floating-input"
                            type="text"
                            placeholder=" "
                            value={moment(overtime.endedAt)
                              .utc()
                              .format("h:mm A")}
                            readOnly
                          />
                          <label className="floating-text">To</label>
                        </span>
                      ) : (
                        <span>
                          <input
                            className="clock-dark floating-input"
                            id="endTime"
                            type="time"
                            value={
                              endT
                                ? endT
                                : moment(overtime.endedAt).utc().format("HH:mm")
                            }
                            onChange={getEndTime}
                          />
                          <label className="floating-text">To</label>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* --------------------------------------------------> END TIME PICKER <-------------------------------------------------- */}
                <div>
                  <Modal
                    isOpen={showFilePreview}
                    ariaHideApp={true}
                    toggle={setFilePreview}
                    onRequestClose={() => setFilePreview(false)}
                    shouldCloseOnOverlayClick={true}
                    className="fileModalPreview"
                    style={customStyles}
                  >
                    <div
                      className="row center rejecting-container"
                      style={{
                        width: "auto",
                        height: "auto",
                        margin: "auto",
                        padding: "50px",
                      }}
                    >
                    <div className="gAction">
                        <a href={FileUrl} download>
                            <button className="btn-download" style={{background:'none'}}>
                                <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'brightness(5.5)' }}/>
                            </button>
                        </a>
                        <img height={20} onClick={() => setFilePreview(false)} src={close} alt="" style={{ margin:'15px 7px', filter: 'brightness(5.5)', cursor:'pointer' }}/>
                    </div>
                    <div style={{ height: '100%' }}>
                    <FileViewer 
                        fileType={FileType} 
                        filePath={FileUrl} 
                        key= {FileUrl}
                    />
                    </div>

                    </div>
                  </Modal>
                </div>

                <div className="row hours-column" style={{ paddingLeft: "2%" }}>
                  <div className="col s12 m12 l12">
                    {OT.startedAt && OT.endedAt ? (
                      <p className="info" style={{ margin: "0" }}>
                        Hours :{" "}
                        <span style={{ textTransform: "lowercase" }}>
                          {parseInt(
                            moment
                              .duration(
                                moment(OT.endedAt).diff(moment(OT.startedAt))
                              )
                              .asHours()
                          )}
                          {"  "}hr{"  "},{"    "}
                          {parseInt(
                            moment
                              .duration(
                                moment(OT.endedAt).diff(moment(OT.startedAt))
                              )
                              .asMinutes()
                          ) % 60}
                          {"  "}min
                        </span>
                      </p>
                    ) : (
                      <p className="info" style={{ margin: "0" }}>
                        Hours :{" "}
                        <span style={{ textTransform: "lowercase" }}>
                          {parseInt(
                            moment
                              .duration(
                                moment(overtime.endedAt).diff(
                                  moment(overtime.startedAt)
                                )
                              )
                              .asHours()
                          )}
                          {"  "}hr{"  "},{"    "}
                          {parseInt(
                            moment
                              .duration(
                                moment(overtime.endedAt).diff(
                                  moment(overtime.startedAt)
                                )
                              )
                              .asMinutes()
                          ) % 60}
                          {"  "}min
                        </span>
                      </p>
                    )}
                  </div>
                </div>

                <div className="row textarea-column">
                  <div
                    className="col s12 m12 l12"
                    style={{ paddingLeft: "2%", paddingRight: "2%" }}
                  >
                    {overtime.status === "approved" ||
                    overtime.status === "rejected" ? (
                      <div className="floating-label">
                        <textarea
                          className="floating-input floating-textarea ot-reason"
                          value={overtime.description}
                          name="description"
                          cols="30"
                          placeholder=" "
                          style={{ resize: "none" }}
                          row="10"
                          readOnly
                        ></textarea>
                        <label className="floating-text">Reason</label>
                      </div>
                    ) : (
                      <div className="floating-label">
                        <textarea
                          className="floating-input floating-textarea ot-reason"
                          value={
                            OT.description
                              ? OT.description
                              : overtime.description
                          }
                          name="description"
                          cols="30"
                          placeholder=" "
                          style={{ resize: "none" }}
                          row="10"
                          onChange={descriptionChange}
                        >
                          {OT.description
                            ? OT.description
                            : overtime.description}
                        </textarea>
                        <label className="floating-text">Reason</label>
                      </div>
                    )}
                    
                    <div className="div-attach" style={{marginBottom:'20px'}}>
                      <p className="info" style={{ marginTop: "0" }}>
                          Attachments
                      </p>

                      {overtimeRequest[0].files?.length !== 0 || overtimeRequest[0].files === 'undefined' || overtimeRequest[0].files === '' || overtimeRequest[0].files === null ? (
                          overtimeRequest[0].files?.map((c) => (
                              // <p>{c.split('-').pop()}</p>
                              <div key={c} className="attach-div">
                                  <div className="attach-preview">
                                      <img
                                          src={c}
                                          className="attach-file"
                                          alt="Thumb"
                                          onError={(e) => (e.target.src = fileIcon) }
                                      />
                                          <p onClick={() => { openFilePreview(); setFileUrl(c); setFileType(c.split('.').pop()) }} style={{cursor:'pointer'}}>{c.split('/').pop()}</p>
                                  </div>
                                  <a href={c} download>
                                      <button className="btn-download" style={{background:'none'}} >
                                          <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'contrast(0.5)' }}/>
                                      </button>
                                  </a>
                              </div>
                          ))
                      ) : (
                      <>
                          <div className="attach-div">
                              <div className="attach-preview">
                                  <p>No File Attached</p>
                              </div>
                          </div>
                      </>
                      )}
                    </div>

                    <ApproverDetails
                      details={
                        overtime.status === requestStatus.approved ||
                        overtime.status === requestStatus.completed
                          ? overtime.approver_details
                          : overtime.status === requestStatus.onHold
                          ? overtime.noter_details
                          : overtime.status === requestStatus.rejected
                          ? overtime.rejecter_details
                          : []
                      }
                      manager={overtime.users[0].manager}
                      status={overtime.status}
                      date={
                        overtime.status === requestStatus.approved ||
                        overtime.status === requestStatus.completed
                          ? overtime.approvedAt
                          : overtime.status === requestStatus.onHold
                          ? overtime.notedAt
                          : overtime.status === requestStatus.rejected
                          ? overtime.rejectedAt
                          : ""
                      }
                    />
                    {/* {overtime.status !== requestStatus.new && (
                      <div className="floating-label">
                        <textarea
                          className="floating-input floating-textarea hr-textarea"
                          value={
                            overtime.status === requestStatus.approved ||
                            overtime.status === requestStatus.completed
                              ? overtime.approvalRemark
                              : overtime.status === requestStatus.onHold
                              ? overtime.notedRemark
                              : overtime.status === requestStatus.rejected
                              ? overtime.rejectedRemark
                              : ""
                          }
                          name="description"
                          cols="30"
                          placeholder=" "
                          style={{ resize: "none" }}
                          row="2"
                          readOnly
                        />
                        <label className="floating-text">Remarks</label>
                      </div>
                    )} */}
                  </div>
                </div>

                <div className="row ot-buttons">
                  <div
                    className="col s1 m8 l8 buttonOneOt"
                    style={{ width: "60%" }}
                  >
                    {OT.status === "on hold" ||
                    overtime.status === "new" ||
                    overtime.status === "on hold" ? (
                      <span>
                        {overtime.user_id !== admin.user._id ? (
                          <span>
                            <button
                              className="btn ot-btn-approve-dark"
                              onClick={onApproveOpenModal}
                            >
                              Approve
                            </button>
                            <button
                              className="btn ot-btn-hold-dark"
                              onClick={onHoldOpenModal}
                            >
                              Hold
                            </button>
                            <button
                              className="btn ot-btn-reject-dark"
                              onClick={onRejectOpenModal}
                            >
                              Reject
                            </button>
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="col s1 m4 l4 right buttonTwoOt"
                    style={{ width: "40%" }}
                  >
                    <span className="right">
                      <button
                        className="btn ot-btn-cancel-dark"
                        onClick={goBack}
                      >
                        BACK
                      </button>
                    </span>
                    {OT.status === "on hold" ||
                    overtime.status === "new" ||
                    overtime.status === "on hold" ? (
                      <span>
                        <button
                          className={
                            update === false
                              ? "btn-ot ot-update-dark disabled right"
                              : "btn-ot ot-update-dark right"
                          }
                          onClick={() => {
                            markUpdate(overtime._id);
                          }}
                        >
                          UPDATE
                        </button>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* --------------------------------------------------> MODAL <-------------------------------------------------- */}
            <div>
              <Modal
                isOpen={showAppModal}
                ariaHideApp={true}
                toggle={setAppModal}
                onRequestClose={() => setAppModal(false)}
                shouldCloseOnOverlayClick={true}
                className="AnnouncementModalBG"
              >
                <div
                  className="row center approval-container"
                  style={{
                    width: "auto",
                    height: "auto",
                    margin: "auto",
                    padding: "50px",
                  }}
                >
                   
                  <p className="coe-info left">
                    Please add additional mark for approving:
                  </p>
                  <div className="col s12 m12 l12">
                    
                    <div className="floating-label">
                      <textarea
                        className="floating-input floating-textarea approval ot-reason"
                        name="approvalRemark"
                        value={OT.approvalRemark}
                        cols="30"
                        placeholder=" "
                        style={{ resize: "none" }}
                        onChange={onApproveChange}
                        row="10"
                      ></textarea>
                      <label className="floating-text">Remarks</label>
                    </div>
                  </div>

                  <div className="col s10 m10 l10 coe-buttons">
                    <span className="right-dark">
                      <button
                        className="btn coe-btn-cancel-dark"
                        onClick={handleCloseModal}
                      >
                        CANCEL{" "}
                      </button>
                      <button
                        className="btn coe-btn-approve-dark"
                        onClick={() => {
                          markApproved(overtime._id);
                        }}
                      >
                        Approve
                      </button>
                    </span>
                  </div>
                </div>
              </Modal>
              <Modal
                isOpen={showModal}
                ariaHideApp={true}
                toggle={setModal}
                onRequestClose={() => setModal(false)}
                shouldCloseOnOverlayClick={true}
                className="AnnouncementModalBG"
              >
                <div
                  className="row center onhold-container"
                  style={{
                    width: "auto",
                    height: "auto",
                    margin: "auto",
                    padding: "50px",
                  }}
                >
                  <p className="coe-info left">
                    Please state your reason for on hold :
                  </p>
                  <br />
                  <div className="col s12 m12 l12">
                    <div className="floating-label">
                      <textarea
                        className="floating-input floating-textarea on-hold-text-area ot-reason"
                        value={OT.notedRemark}
                        name="notedRemark"
                        cols="30"
                        placeholder=" "
                        style={{ resize: "none" }}
                        onChange={onHoldChange}
                        row="10"
                      ></textarea>
                      <label className="floating-text">Remarks</label>
                    </div>
                  </div>

                  <div className="col s10 m10 l10 coe-buttons">
                    <span className="right-dark">
                      <button
                        className="btn coe-btn-cancel-dark"
                        onClick={handleCloseModal}
                      >
                        CANCEL{" "}
                      </button>
                      <button
                        className="btn coe-btn-hold-dark"
                        onClick={() => {
                          markHold(overtime._id);
                        }}
                      >
                        Hold
                      </button>
                    </span>
                  </div>
                </div>
              </Modal>
              <Modal
                isOpen={showRejModal}
                ariaHideApp={true}
                toggle={setRejModal}
                onRequestClose={() => setRejModal(false)}
                shouldCloseOnOverlayClick={true}
                className="AnnouncementModalBG"
              >
                <div
                  className="row center rejecting-container"
                  style={{
                    width: "auto",
                    height: "auto",
                    margin: "auto",
                    padding: "50px",
                  }}
                >
                  <p className="coe-info left">
                    Please state your reason for rejecting :
                  </p>
                  <br />

                  <div className="col s12 m12 l12">
                    <div className="floating-label">
                      <textarea
                        className="floating-input floating-textarea ot-reason rejecting"
                        value={OT.rejectedRemark}
                        name="rejectedRemark"
                        cols="30"
                        placeholder=" "
                        style={{ resize: "none" }}
                        onChange={onRejectChange}
                        row="10"
                      ></textarea>
                      <label className="floating-text">Remarks</label>
                    </div>
                  </div>
                  <div className="col s10 m10 l10 coe-buttons">
                    <span className="right-dark">
                      <button
                        className="btn coe-btn-cancel-dark"
                        onClick={handleCloseModal}
                      >
                        CANCEL{" "}
                      </button>
                      <button
                        className="btn coe-btn-reject-dark"
                        onClick={() => {
                          markRejected(overtime._id);
                        }}
                      >
                        Reject
                      </button>
                    </span>
                  </div>
                </div>
              </Modal>
            </div>
            {/* <div className="col s3">
              <div className="row table-two" id="zero">
                <div className="col s12 m11 l1">
                  <ReactImageFallback
                    src={overtime.users[0].avatar}
                    fallbackImage={avatar}
                    initialImage={avatar}
                    alt="user avatar"
                    className="ot-user-photo-dark circle responsive-img"
                  />
                </div>
              </div>
              <div>
                <p className="info-dark-ot">
                  {overtime.users[0].firstName} {overtime.users[0].lastName}
                </p>
                <p className="ot-detail">{overtime.users[0].position}</p>
              </div>
              <div>
                <p className="ot-info-header">Info</p>

                <p className="ot-info-header">Contact Number</p>
                <span className="ot-detail">
                  {overtime.users[0].contactNumber
                    ? overtime.users[0].contactNumber
                    : "None"}
                </span>
                
                <p className="ot-info-header">Address</p>
                <span className="ot-detail">
                  {overtime.users[0].address1
                    ? overtime.users[0].address1 + ","
                    : ""}
                  {overtime.users[0].address2
                    ? overtime.users[0].address2 + ","
                    : ""}
                  {overtime.users[0].city ? overtime.users[0].city + "," : ""}
                  {overtime.users[0].country ? overtime.users[0].country : ""}
                </span>
              </div>

            
              
            </div> */}
          </div>
        ) : null;
      })}
    </ContentWrapper>
    <When condition={isDisplayUserDetails === true}>
        <PreviewLeaveUserWidget
            {...props}
            subjectId={overtimeRequest[0]?.users}
            availableCredits="false"
            comments ={comment}
            replies = {reply}
            request = {overtimeRequest[0]}
            isReply={Object.keys(comment).length !== 0}
        />
    </When>
    </CustomContentWrapper>
  );
});

export default OvertimeRequestUpdate;
