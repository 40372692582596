import React from 'react';
import timeline_arrow from '../../../_templates/timeline_arrow.png';
import timeline_arrow_blue from '../../../_templates/timeline_arrow_blue.png';
import moment from 'moment';
import "./ActivityWidget.scss"

const ActivityWidget = (props) => {

    return (
      <div className="ActivityWidget-container">
        <div className="header">
          <p> Upcoming events </p>
        </div>
        <div className="Activity_content">
          {props.events.items.length !== 0 ? props.events.items.map((event, eventCount) => (
            // Max Event 4 
            eventCount <= 9 &&
            <div className="content_key" key={event.id}>
              <div className="column_arrow">
                <div className="">
                  <div className="line-time">
                    <img src={timeline_arrow} alt=''></img>
                  </div>
                </div>
              </div>
              <div className="column_date">

                {moment(event.date).utc().format('MMM D')}

              </div>
              <div className="column_blue_arrow">
                <img src={timeline_arrow_blue} alt='' height={10} width={10}></img>
              </div>
              <div className="column_users">
                <p>{
                  [
                    event.firstName,
                    event.lastName
                  ].join(' ').trim()
                }
                </p>
                <p> {event.type}</p>
              </div>
            </div>

          )) : 
          
          <div className="EmptyEvents_admin">
            No upcoming events
          </div>
        
          }

        </div>
        {props.events.items.length !== 0 &&
          <div className="upcoming_events_admin">
            <button className="viewMore_upcomingEvents">
              View More
           </button>
          </div>
        }
      

      </div>
    )
}


export default ActivityWidget;