import React from 'react';
import MainNav from '../Components/MainNav';

import BodyBackgroundColor from 'react-body-backgroundcolor'

import { Link } from 'react-router-dom';

import { Line } from 'react-chartjs-2';

import './QuestMissionLandingPage.sass'

class QuestMissionLandingPage extends React.Component {
    componentDidMount() {

    }

    render() {
    	const data = {
		    labels: [
		        '11/01', '11/02', 
		        '11/03', '11/04', 
		        '11/05', '11/06', 
		        '11/07', '11/08', 
		        '11/09', '11/10'
		    ],
	      	datasets: [
		        {
		          label: 'Mission Accepted',
		          data: [100, 60, 50, 20, 20, 30, 35,38,42,60,41,25],// Line color
				  backgroundColor: 'rgba(148,232,221,0.1)',
				  borderColor: 'rgba(148,232,221,0.5)',
				  pointBorderColor: '#4af3b9',
				  pointBackgroundColor: '#d2e9fc',
				  hoverRadius: 4,
				  hoverBorderWidth: 1,
				  padding: 10,
				  hitRadius: 1,
		        },
		        {
		          label: 'Mission Completed',
		          data: [70,80,65,75,50,40,30,35,50,70,70,80],         // Don't fill area under the line
		          backgroundColor: 'rgba(45,226,247,0.1)',
		          borderColor: 'rgba(45,226,247,0.5)',
				  pointBorderColor: '#4af3b9',
				  pointBackgroundColor: '#1b98ff',
				  hoverRadius: 4,
				  hoverBorderWidth: 1,
				  hitRadius: 1,
		        },
		        {
		          label: 'Quit',
		          data: [50,58,20,35,60,68,60,55,60,80,40,0],       // Don't fill area under the line
		          backgroundColor: 'rgba(251,14,14, 0.1)',
		          borderColor: 'rgba(251,14,14, 0.5)',
				  pointBorderColor: '#4af3b9',
				  pointBackgroundColor: '#fb0e0e',
				  hoverRadius: 4,
				  hoverBorderWidth: 1,
				  hitRadius: 1,
		        },
      		]

  		}
        
        const options = {
        	responsive: true,
      		maintainAspectRatio: false,	// Don't maintain w/h ratio

      		legend: {
			    display: true,
			    position: 'top',
			    labels: {
 					usePointStyle: true
			    }
			},

			tooltips: {
		      cornerRadius: 0
		   	}

    	}

        return (
        	<BodyBackgroundColor backgroundColor='#ffffff'>
       			<div className="quest-mission-landing-page">
       				<MainNav/>
       				<div className="container">
						<div className="bg-quest-mission-completed">
							<h6 className="quest left-align"> Quest Mission </h6>
		       				<hr/>
		       				<div className="right-align">
			       				<div className="btn-group" role="group">
								    <Link to="" className="btn btn-active">Day</Link><Link to="" className="btn btn-inactive">Month</Link><Link to="" className="btn btn-inactive">Year</Link>
								</div>
							</div>
		       				<div className="canvas-container">
		        				<Line data={data} options={options}/>
		        			</div>
		        		</div>
		        	</div>
				</div>
			</BodyBackgroundColor>
        );
    }
}

export { QuestMissionLandingPage };
