import React, { Component } from 'react';
import icon from '../steps/2.png'

export default class Step3 extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // componentWillUnmount() {}

  // not required as this component has no forms or user entry
  // isValidated() {}

  jumpToStep(toStep) {
    // We can explicitly move to a step (we -1 as its a zero based index)
    this.props.jumpToStep(toStep-1); // The StepZilla library injects this jumpToStep utility into each component
  }

  render() {
    return (
      <div className="step step3">
        <div className="col s12 m12 l12">
          <div className="card-panel white lighten-5 z-depth-1">
            <div className="card-header">
              <div className="step-icon">
                <img src={icon} alt="icon" className="step-icon"/>
              </div>
              <div className="steps-name">
                <h6 className="step-name"> User Credentials </h6>
              </div>
              <div className="right">
                <h6> Step 3 of 4 </h6>
              </div>
            </div>

            <div className="container step-2">
              <div className="row step-2">
                <div className="input-field col s12 m12 l4">
                  <input id="email" name="email" type="email" placeholder="Email" className="validate"/>
                </div>
                <div className="input-field col s12 m12 l4">
                    <input type="text" disabled name="team" placeholder="Team" className="validate"/>
                </div>
                <div className="input-field col s12 m12 l4">
                  <input id="password" type="password" autoComplete="new-password" placeholder="Password"/>
                  <div className="helper-text" data-error="wrong" data-success="right">sending a blank password automatically generates a password for the user.</div>
                </div>
              </div>
            </div>
            <div className="button-container-four">
              <div className="btn-prev">
                <button className="btn btn-previous" onClick={() => this.jumpToStep(2)}>Previous</button>
              </div>
              <div className="btn-nxt">
                <button className="btn btn-next2" onClick={() => this.jumpToStep(4)}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
