import React from 'react';
import { connect, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import Linkify from 'react-linkify';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import cc from 'classcat';
import { mdToDraftjs, draftjsToMd  } from 'draftjs-md-converter';
import { convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import isEmpty from 'lodash/isEmpty';
import { Editor } from 'react-draft-wysiwyg';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';   

//helpers
import { history } from '../_core/helpers/history';
// import { debounce } from '../_core/helpers/debounce';
//actions and services
import { postServices } from '../_core/services/post';
import { commentService } from '../_core/services/comment';
import { commentActions } from '../_core/actions/comment';
import { reactionService } from '../_core/services/reaction';
import { reactionActions } from '../_core/actions/reaction';
import { statsActions } from '../_core/actions/stats';
import { userService } from '../_core/services/user';
//images
import avatar from '../_templates/man.png';
import imgNotFound from '../_templates/img-not-found.png';
import hearted from '../_templates/heart_red.png';
import heart from '../_templates/heart_grey.png';
import comment from '../_templates/comment_icon.png';
import nocomment from '../_templates/comment_grayed.png';
import closeIcon from '../_templates/x_round.png';
import chevLeft from '../_templates/chevron-left.png';
import send_comment from '../_templates/send_message_greyed.png';
import send_comment_action from '../_templates/send_message.png';
import PragmanilaLogo from '../_templates/Pragmanila_logo.png';
import anonymous from '../_templates/dark/anonymous.png';
//styles
import './CommentModal.sass';
const _ = require('lodash');

Modal.setAppElement(document.getElementById('root'));

const linkDecorator = (href, text, key) => (
	<a href={href} key={key} target="_blank" rel="noopener noreferrer">{text}</a>
);

const SkeletonPost = () => {
	const [transparent, setTransparent] = React.useState(true);

	React.useEffect(() => {
		setTimeout(() => {
			setTransparent(false);
		}, 100);
	}, [setTransparent]);

	return (
		new Array(1).fill(null).map((_, index) => (
			<div
				className={cc({
					'skeleton-post': true,
					transparent
				})}
				key={index}
			>
				<SkeletonTheme color="#e0e0e0">
					<div className="poster-profile">
						<Skeleton circle width={50} height={50} />
						<div>
							<p>
								<Skeleton height={15} width={120} />
							</p>
							<p className="date">
								<Skeleton height={10} width={80} />
							</p>
                            <div className="content">
                                <p>
                                    <Skeleton height={15} width="90%" />
                                </p>
                                <p>
                                    <Skeleton height={15} width="60%" />
                                </p>
                            </div>
						</div>
                        
					</div>
                   
				</SkeletonTheme>
			</div>
		))
	);
}

const ReplyDisplay = (props) => {
    const dispatch = useDispatch();
    let userDetail = JSON.parse(localStorage.getItem('user'));      

    const [reactNum, setReactNum] = React.useState(0);
    const [heartedReact, setHeart] = React.useState(false);

    const [DebounceRequest, setDebounceRequest] = React.useState(false);
    
    const reply = props.reply;
    const maxLength = 300;

    const commentText = (content) => {
        if(content.length > maxLength){
            return true
        }else{
            return false
        }
    };

    const changeCommentHeight = (id) => {
        const getHeight = document.getElementById('rCshow' + id).innerHTML;
        if(getHeight === "Show More"){
            document.getElementById('rCshow' + id).innerHTML = "Show Less";
            document.getElementById('rC' + id).style.maxHeight = "100%";
        }else if(getHeight === "Show Less"){
            document.getElementById('rCshow' + id).innerHTML = "Show More";
            document.getElementById('rC' + id).style.maxHeight = "120px";
        }
    }

    const debounceReact = React.useCallback(
        _.debounce(()=> {
            const user = JSON.parse(localStorage.getItem("user"));
            postReactCount();
            dispatch(statsActions.getOneUser(user.user_id));
        }, 2000), [])
        
    const sendHeart = (e, post, isDisabled) => {
        // Reply Display
        
        if(isDisabled === "not-disabled") {
            e.preventDefault();
            let IsValid = heartedReact;
            let totalReact = 0;


            setHeart(!heartedReact);
        
            if(IsValid === false) {
                    totalReact = reactNum + 1;
            }

            if(IsValid === true) {
                    totalReact = reactNum - 1
            }
            setReactNum(totalReact);
        

            const submitReaction = {
                originId : post._id,
                type: "1"
            };

            dispatch(reactionActions.postReaction(submitReaction));

            
            if(DebounceRequest === false){
                debounceReact();
                setDebounceRequest(true);
            } else {
                debounceReact();
            }
        }
    };
    
    const postReactCount = () => {

        try{
            reactionService.getByOrigin(reply._id, 1, 999).then(res=>{
                if(res.page){
                   
                    setDebounceRequest(false)
					if(reactNum !== res.total ) {
						setReactNum(res.total);
                    } 
                    const reactions = res.items;
                    const user = JSON.parse(localStorage.getItem('userCredentials'));
                    const currentUserId = user && user.user._id;

                    const userReacted = reactions.filter(c=> c.poster === currentUserId);

                    if(userReacted.length > 0){
                        setHeart(true);
                    }else{
                        setHeart(false)
                    }
                }	
            }).catch(error=>{});
        }catch(err){
            console.log(err)
        }
    }

    React.useEffect(() => { 
        // Each reply, 1 call api to check reacts count
        postReactCount();
        ReactTooltip.rebuild()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.reply]);
    

    return(
        <> 
       
        <div className="reply-content">
       
            <img className="reply-img" src={reply.details[0].avatar || avatar} alt="" onError={(e) => { e.target.src = avatar }}/>
            <div className="reply-content-text">
                <p className="reply-name">{reply.details[0].firstName || ""} {reply.details[0].lastName || ""}</p>
                <p className="reply-date">{reply.createdAt && moment(reply.createdAt).fromNow()}</p>
                <Linkify componentDecorator={linkDecorator}>
                    <p 
                        className="reply-text"
                        id={'rC' + reply._id}
                        style={{maxHeight: commentText(reply.content) ? "120px" : ""}}
                    >
                        {reply.content}
                    </p>
                </Linkify>
                {commentText(reply.content) &&
                <p className="comment-show" onClick={ ()=> changeCommentHeight(reply._id) } id={'rCshow' + reply._id}>
                    Show More
                </p>
                }
              
                <div className="reply-react" onClick={ (e) =>  sendHeart(e, reply,reply.poster === userDetail.user_id ? "disabled_ModalReact" : "not-disabled" )}  data-tip ="" data-for={reply.poster === userDetail.user_id ? "disabled_ModalReact" : "not-disabled"} >
                    <img alt="" src={ heartedReact === true ? hearted : heart}/>
                    <p>{reactNum || 0}</p>
                </div>
            </div>
        </div>
        </>
    );
}

const RepliesSection = (props) => {
    const dispatch = useDispatch();
    let userDetail = JSON.parse(localStorage.getItem('user'));  

    const comment = props.commentReplies || {};  
    const poster = comment && comment.details && comment.details[0];
    const replies = props.replies;

    const [reactNum, setReactNum] = React.useState(0);
    const [heartedReact, setHeart] = React.useState(false);

    const [DebounceRequest, setDebounceRequest] = React.useState(false);
    
    const isEmptyObject = (obj) => {
        for(var i in obj) return false; 
        return true;
      }

    const debounceReact = React.useCallback(
        _.debounce(()=> {
            const user = JSON.parse(localStorage.getItem("user"));
            postReactCountCommentRepliesSection();
            dispatch(statsActions.getOneUser(user.user_id));
        }, 2000), [])

    

    const sendHeart = (e, post, isDisabled) => {
        // Comment Reply Display
        if(isDisabled === "not-disabled") {
            let IsValid = heartedReact;
            let totalReact = 0;


            setHeart(!heartedReact);
        
            if(IsValid === false) {
                    totalReact = reactNum + 1;
            }

            if(IsValid === true) {
                    totalReact = reactNum - 1
            }
            setReactNum(totalReact);
        

            const submitReaction = {
                originId : post._id,
                type: "1"
            };

            dispatch(reactionActions.postReaction(submitReaction));

            
            if(DebounceRequest === false){
                debounceReact();
                setDebounceRequest(true);
            } else {
                debounceReact();
            }
        }
    };

    const postReactCountCommentRepliesSection = () => {
      
        let commentId = localStorage.getItem('commentIdRef');

        if(commentId !== null){
            try{
                reactionService.getByOrigin(commentId, 1, 999).then(res=>{
                    if(res.page){
                    
                        setDebounceRequest(false)
                        if(reactNum !== res.total ) {
                            setReactNum(res.total);
                        } 
                        const reactions = res.items;
                        const user = JSON.parse(localStorage.getItem('userCredentials'));
                        const currentUserId = user && user.user._id;

                        const userReacted = reactions.filter(c=> c.poster === currentUserId);

                        if(userReacted.length > 0){
                            setHeart(true);
                        }else{
                            setHeart(false)
                        }
                    }	
                }).catch(error=>{});
            }catch(err){
                console.log(err)
            }
        }
    }

     
      React.useEffect(() => { 
           ReactTooltip.rebuild();
      },[])
    React.useEffect(() => { 
        if(isEmptyObject(props.commentReplies) === false) {
            localStorage.setItem('commentIdRef', props.commentReplies._id);
        }
        postReactCountCommentRepliesSection();
        
        return () => { 
            setHeart(false);
         };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.commentReplies]);


    React.useEffect(() => {
        // If replies made new update and isRepliedSubmitted is true, it will scroll down into the first reply
        if(props.isRepliedSubmitted === true) { // Callback if user made new reply to comment
            scrollToBottomRepliesSection("postRepliesContainer");
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [replies])

    const scrollToBottomRepliesSection  = (id) => { 
        var objDiv = document.getElementById(id);
        if(objDiv){
            objDiv.scrollTop = objDiv.scrollHeight;  
            poster && document.getElementById(id).scrollIntoView();
            props.callbackSubmitReplySection(false);
        }
     }

    if(comment && poster){
        return (
            <>
                <div className="comment-content">
                    <img className="comment-img" src={poster.avatar || avatar} alt=""/>
                    <div className="comment-content-text">
                        <p className="comment-name">{poster.firstName || ""} {poster.lastName || ""}</p>
                        <p className="comment-date">{comment.createdAt ? moment(comment.createdAt).fromNow() : ""}</p>
                        <Linkify componentDecorator={linkDecorator}>
                            <p className="comment-text">{comment.content || ""}</p>
                        </Linkify>
                        
                        {/* Tooltip for comment react */}
                        <ReactTooltip 
                            id='disabled_ModalReactComment'
                            type="light"
                            >
                            { "this feature is not available for your own content" }
                        </ReactTooltip>

                        <div className="comment-counts">
                            <div className="react-icon" onClick={ (e) =>  sendHeart(e, comment,comment.poster === userDetail.user_id ? "disabled_ModalReactComment" : "not-disabled" )}  data-tip ="" data-for={comment.poster === userDetail.user_id ? "disabled_ModalReact" : "not-disabled"}>
                            
                            <img alt="" src={ heartedReact === true ? hearted : heart}/>
                            <p>{reactNum || 0}</p>
                </div>
                        </div>
                    </div>
                </div>
                <div className="reply-section" id='postRs'>
                    {replies.length > 0 && replies.filter((r)=> r.originId === comment._id).map((reply, index)=>
                    <ReplyDisplay reply={reply} key={reply._id}/>
                    ).reverse()}
                </div>
            </>
        )
    }else{
        return(
            <div></div>
        )
    }
}

const CommentDisplay = (props) => {
    const dispatch = useDispatch();
    var comment = props.comment;
    const replies = props.replies || {};
    let userDetail = JSON.parse(localStorage.getItem('user'));          

    const user = comment.details[0] || {};
    const commentText = comment.content;
    const maxLength = 300;

    const [reactNum, setReactNum] = React.useState(0);
    const [heartedReact, setHeart] = React.useState(false);

    const [DebounceRequest, setDebounceRequest] = React.useState(false);

    const [showMore, setShowMore] = React.useState(false);
    const [displayShowBtn, setDisplayShowBtn] = React.useState(false);

    const changeCommentHeight = () => {
        if(showMore){
            document.getElementById('cC' + comment._id).style.maxHeight = "120px";
            setShowMore(false);
        }else{
            document.getElementById('cC' + comment._id).style.maxHeight = "100%";
            setShowMore(true);
        }
    };

    const displayReplies = (comment) =>{
        props.replyComment(comment);
        document.getElementById('postComments').style.display = "none";
        document.getElementById('postReplies').style.display = "inline-flex";
        document.getElementById("postRepliesContainer").scrollIntoView();
        // props.callbackReactComments(true)
    };

    const debounceCommentReact = React.useCallback(
        _.debounce(()=> {	
            const user = JSON.parse(localStorage.getItem("user"));
            postReactCount();
            dispatch(statsActions.getOneUser(user.user_id));
    }, 2000), [])

    const sendHeart = (e, post, isDisabled) => {
        // Comment Heart

        if(isDisabled === "not-disabled") {
            let IsValid = heartedReact;
            let totalReact = 0;


            setHeart(!heartedReact);
        
            if(IsValid === false) {
                    totalReact = reactNum + 1;
            }

            if(IsValid === true) {
                    totalReact = reactNum - 1
            }
            setReactNum(totalReact);
        

            const submitReaction = {
                originId : post._id,
                type: "1"
            };

            dispatch(reactionActions.postReaction(submitReaction));

                
            if(DebounceRequest === false){
                debounceCommentReact();
                setDebounceRequest(true);
            } else {
                debounceCommentReact();
            }
        }
    };
    
    const postReactCount = () => {
     // Comment React
        try{
            reactionService.getByOrigin(comment._id, 1, 999).then(res=>{
                if(res.page){
                   
                    setDebounceRequest(false)
					if(reactNum !== res.total ) {
                        setReactNum(res.total);
                    } 
                    const reactions = res.items;
                    const user = JSON.parse(localStorage.getItem('userCredentials'));
                    const currentUserId = user && user.user._id;

                    const userReacted = reactions.filter(c=> c.poster === currentUserId);

                    if(userReacted.length > 0){
                        setHeart(true);
                    }else{
                        setHeart(false)
                    }
                }	
              
            }).catch(error=>{});
           
        }catch(err){
            console.log(err)
        }
    }

    React.useEffect(() => { 
        if(props.updateReactComments === true) {
            postReactCount();
            props.callbackReactComments(false)
        }
        
        return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ props.updateReactComments]);


    React.useEffect(() => { 
        // Each reply, 1 call api to check reacts count
        postReactCount();
        ReactTooltip.rebuild()
        return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.comment]);

    React.useEffect(()=>{
        if (commentText.length > maxLength){
            setDisplayShowBtn(true);
        }

        return ()=> setDisplayShowBtn(false);
    },[commentText.length, maxLength]);

    if(comment._id && replies.length > 0){
        comment.totalReplies = replies.filter((reply)=> reply.originId === comment._id).length || comment.totalReplies;
    };

    React.useEffect(() => {
        // If post Comment made new update, it will scroll down into the first Comment
         scrollToBottomCommentSection("postCommentsContainer");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comment])

    // React.useEffect(() => { 
    //     console.log(update)
    //     var element = document.getElementById("postCommentsContainer");
    //     element.classList.add("flex");
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[update]);

    const scrollToBottomCommentSection  = (id) => { 
        var objDiv = document.getElementById(id);
        if(objDiv){
            objDiv.scrollTop =objDiv.scrollHeight;  
        }
     }
    
    return(
        <div className="comment-content">
            <img className="comment-img" src={user.avatar || avatar} alt="" onError={(e) => { e.target.src = avatar }}/>
            <div className="comment-content-text">
                <p className="commentor-name">{user.firstName + " " + user.lastName || ""}</p>
                <p className="comment-date">{comment.createdAt ? moment(comment.createdAt).fromNow() : ""}</p>
                <Linkify componentDecorator={linkDecorator}>
                    <p 
                        className="comment-text"
                        id={'cC' + comment._id}
                        style={{maxHeight: commentText.length > maxLength ? "120px" : ""}}
                    >{commentText}</p>
                </Linkify>
                {displayShowBtn &&
                <p className="comment-show" onClick={ ()=> changeCommentHeight() }>{showMore ? "Show less" : "Show more"}</p>
                }
                <p className="comment-replies" onClick={ ()=> displayReplies(comment)}>{comment.totalReplies || ""} {comment.totalReplies > 1 ? " Replies" : " Reply"}</p>
                <div className="comment-react" onClick={ (e) =>   sendHeart(e, comment,comment.poster === userDetail.user_id ? "disabled_ModalReact" : "not-disabled" )}  data-tip ="" data-for={comment.poster === userDetail.user_id ? "disabled_ModalReact" : "not-disabled"} >
                    <img alt="" src={ heartedReact === true ? hearted : heart}/>
                    <p>{reactNum  || 0}</p>
                </div>
            </div>
        </div>
    );    
};

const CommentForm = (props) => {
    const postId = props.commentId ? props.commentId : props.postId;
    const replyComment = props.isReply;
    const type = replyComment ? "reply" : "comment";
    const dispatch = useDispatch();
    
    var [commentContent, setCommentContent] = React.useState({
        originId: props.postId,
        type: type,
        content: "",
        attachFile: ""
    });

    const clearInput = () => {
        setCommentContent({
            ...commentContent,
            originId: postId,
            type: type,
            content: "",
            attachFile: ""
        });
    }

    const commentValue = (e) => {
        const code = (e.keyCode ? e.keyCode : e.which);
        let inputValue = e.target.value;
        let removeWhitespaces = inputValue.trim()
        if (removeWhitespaces !== '') {
            const text = e.target.value
            setCommentContent({
                ...commentContent,
                originId: postId,
                type: type,
                content: text,
                attachFile: ""
            });
            
            if(code === 13 && !e.shiftKey){
                sendComment();
            }
        } else {
            clearInput();
        }
    };

    const sendComment = () => {
        const submitComment = ({
            ...commentContent,
            content: commentContent.content.replace(/(\r\n|\n|\r)$/gm, "")
        });
        try{

            commentService.postComment(submitComment).then((res)=>{
                if(res.success){
                    dispatch(commentActions.getByOrigin(postId, 1, 999));
                    clearInput();
                    props.submittedComment(true);
                }
            }).catch(error=>{});
        }catch(err){
            console.log(err)
        }
    }

    React.useEffect(()=>{
        if(commentContent.type !== type){
            setCommentContent({
                ...commentContent,
                originId: postId,
                type: type,
                content: "",
                attachFile: ""
            });
        }
    },[commentContent.type, type, setCommentContent, commentContent, postId]);

    return(
        <div className="add-comment">
            <textarea 
                className="insert-comment" 
                placeholder={replyComment ? "Write a reply" : "Add a comment" }
                onKeyUp={ (e)=> commentValue(e) }
                onChange={ (e)=> commentValue(e) }
                value = {commentContent.content}
                id={'commentForm' + postId}
            >
            </textarea>
            <div className="send-comment-btn">
                {commentContent.content.length > 0 ?
                <img className="send" src={send_comment_action} onClick={ ()=> sendComment() } alt=""/>
                :
                <img src={send_comment} alt=""/>
                }
            </div>
        </div>
    )
}

const PostImage = (props) =>{
    const post = props.post;
    const numComments = props.commentCount;
    let userDetail = JSON.parse(localStorage.getItem('user'));
    // const numReacts = props.reactCount;
    const isReply = props.isReply;
    const commented = props.commented;
    const heartedPost = props.hearted;
    const reactNum = props.reactNum;

    const InputFocus = () => {
        if(Object.keys(isReply).length === 0){
            document.getElementById('commentForm' + post._id).focus();
        }else{
            props.inputFocus(true)
        }
    }
    React.useEffect(() => {
        ReactTooltip.rebuild()
    }, [post])
    return(
        <div className="post-image-content">
            
            <div className="post-image">
                <img alt="" onError={(e) => { e.target.src = imgNotFound }} src={ post.postImage || imgNotFound }/>
            </div>
            <div className="post-counts">
                <div className="react-icon" onClick={(e)=> props.submitReact(e, props.post, post.userId === userDetail.user_id ? "disabled_ModalReact" : "not-disabled")} data-tip ="" data-for={post.userId === userDetail.user_id ? "disabled_ModalReact" : "not-disabled"}>
                    <img alt="" src={heartedPost ? hearted : heart}/>
                    <p>{reactNum || 0}</p>
                </div>
                <div className="comment-icon" onClick={ ()=> InputFocus() }>
                    <img alt="" src={commented ? comment : nocomment}/>
                    <p>
                        {numComments}
                        {numComments > 1 ? ' Comments' :  ' Comment'}
                    </p>
                </div>
            </div>
        </div>
    )
}


var prevComments = {};

const commentUpdates = (comments) => {
    const commentsList = comments;
    prevComments = commentsList;
}

var prevReplies = {};

const replyUpdates = (replies) => {
    const repliesList = replies;
    prevReplies = repliesList;
}

const PostModal = (props) => {
    const getId = history.location.pathname.split('view/')[1];
    let userDetail = JSON.parse(localStorage.getItem('user'));
    const dispatch = useDispatch();

    const postFromFeeds = props.getPost;
    const getComments = props.comments;
    const getReplies = props.replies;

    var replies = prevReplies;
    var comments = prevComments;
    var totalComments = comments.length || 0;

    const [post, setPost] = React.useState({});
    const [commented, setCommented] = React.useState(false);
    const [reactNum, setReactNum] = React.useState(0);
	const [heartedPost, setHeart] = React.useState(false);
    const [hasComment, setHasComment] = React.useState({});
    const [DebounceRequest, setDebounceRequest] = React.useState(false);
    const [commendedUser, setCommendedUser] = React.useState({});

    // callback when return to replyDisplay to CommentDisplay
    const [updateReactCommentsSection, setUpdateReactCommentsSection] = React.useState(false);

    // callback for new comment/Reply to automatically scroll to first reply in replySection
    const [IsSubmitted, setIsSubmitted] = React.useState(false);

    var hasImage = false;

    const getModalWidth = () => {
        var modalWidth = {}

        if(hasPost && !hasPost.postImage){
            modalWidth = {
                content : {
                    maxWidth: "540px",
                    maxHeight: "550px"
                }
            }
            hasImage = false;
        }else if(hasPost && hasPost.postImage.length === 0){
            modalWidth = {
                content : {
                    maxWidth: "540px",
                    maxHeight: "550px"
                }
            }
            hasImage = false;
        }else{
            modalWidth = {
                content : {
                    maxWidth: "1080px",
                    maxHeight: "550px"
                }
            }
            hasImage = true;
        }

        return modalWidth
    };
    
    var getPost = () => {
        if(Object.keys(postFromFeeds).length !== 0){
            return  postFromFeeds;
        }else if(post?.success?.length > 0){
            return post.success[0];
        }
    }

    var hasPost = getPost() || {};
    
    const poster = () =>{
        var posterDetails = {};
        if( hasPost?.User_details && hasPost.User_details[0] ) {
            posterDetails = hasPost.User_details[0];
        }else if( hasPost?.user_details ) {
            posterDetails = hasPost.user_details;
        }
        return posterDetails
    }

    // var authorString = hasPost.mentions?.map(function( author ) {
    //     return author;
    // }).join(', ');

    React.useEffect(()=>{
        const commendedUserId = hasPost.mentions?.map(function( author ) {
            return author;
        }).join(', ');
        try{
            userService.getUserByIdDetails(commendedUserId).then(res=>{
                setCommendedUser(res.success[0]);
            }).catch(error=>{});
        }catch(err){
            console.log(err);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setCommendedUser, hasPost.mentions]);

    
    const displayComments = () =>{

        document.getElementById('postComments').style.display = "inline-flex";
        document.getElementById('postReplies').style.display = "none";
        document.getElementById("postCommentsContainer").scrollIntoView();
        setHasComment({});
        localStorage.removeItem('commentIdRef')
        updateReactCommentsCallback(true);

    }

    const getCommentReplies = (commentReplies) => {
        setHasComment(commentReplies);
        dispatch(commentActions.getByOrigin(commentReplies._id, 1, 999));
    }

    const debounceReactPostModal = React.useCallback(
		_.debounce(()=> {	
			const user = JSON.parse(localStorage.getItem("user"));
			postReactCommentCount();
			dispatch(statsActions.getOneUser(user.user_id));
        }, 2000), [])

    const sendHeart = (e, post, isDisabled) => {
        // PostModal
        // e.preventDefault();

        if(isDisabled === "not-disabled") {
            let IsValid = heartedPost;
            let totalReact = 0;

            setHeart(!heartedPost);
            if(IsValid === false) {
                    totalReact = reactNum + 1;
            }

            if(IsValid === true) {
                    totalReact = reactNum - 1
            }
            setReactNum(totalReact);
            
            const submitReaction = {
                originId : post._id,
                type: "1"
            };

            dispatch(reactionActions.postReaction(submitReaction));

            if(DebounceRequest === false){
        
                debounceReactPostModal();
            
                setDebounceRequest(true);
            } else {
                debounceReactPostModal();
        
            }
        }

        
    };
    
    const postReactCommentCount = () => {
        
        try{
            commentService.getByOrigin(getId, 1, 999).then(res=>{
                if(res.page){

                    const commentOrig = res.items;
                    const user = JSON.parse(localStorage.getItem('userCredentials'));
                    const currentUserId = user && user.user._id;
                    const userCommented = commentOrig.filter(c=> c.poster === currentUserId);
                    if(userCommented.length > 0){
                        setCommented(true);
                        props.checkReactComment({...props.checkReactComment, checkComment: true});
                    }
                }
            }).catch(error=>{});
            reactionService.getByOrigin(getId, 1, 999).then(res=>{
                if(res.page){
                    setDebounceRequest(false)
					if(reactNum !== res.total ) {
						setReactNum(res.total);
					} 

                    // setReactNum(res.total);
                    const reactions = res.items;
                    const user = JSON.parse(localStorage.getItem('userCredentials'));
                    const currentUserId = user && user.user._id;
                    const userReacted = reactions.filter(c=> c.poster === currentUserId);
                    if(userReacted.length > 0){
                        setHeart(true);
                        props.checkReactComment({...props.checkReactComment, checkReact: true});
                    }else{
                        setHeart(false);
                        props.checkReactComment({...props.checkReactComment, checkReact: true});
                    }

                    // commentUpdates(getComments);
                }	
            }).catch(error=>{});
        }catch(err){
            console.log(err)
        }
    }
    
    React.useEffect(()=>{
        try{
            postServices.getById(getId).then(res=>{
                setPost(res);
            }).catch(error=>{});
        }catch(err){
            console.log(err);
        }

        dispatch(commentActions.getByOrigin(getId, 1, 999));
        postReactCommentCount();

        return ()=> { 
            prevComments = {};
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setPost, dispatch, getId]);

    const close = React.useCallback(() => {
        history.goBack();
    }, []);

    if(hasPost && getComments && getComments.filter((c)=> c.originId === hasPost._id).length > 0){
        comments = getComments;
        totalComments = comments.length || 0;
        commentUpdates(getComments);
    }

    if(Object.keys(hasComment).length !== 0 && getReplies && getReplies.filter((c)=> c.originId === hasComment._id).length > 0){
        replies = getReplies;
        replyUpdates(getReplies);
    }


    const announcementFeedsContent = () => {
        let markDownContentToDraft = mdToDraftjs(hasPost.postContent);
        let convertCustom = draftjsToMd(convertToRaw(convertFromRaw(markDownContentToDraft)))
        let md = mdToDraftjs(convertCustom)

        return convertFromRaw(md);
    }

    React.useEffect(() => {
        hasPost.postType === 'announcement'  && announcementFeedsContent();
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasPost.postType])


    const updateReactCommentsCallback = (updateReact) => {
        setUpdateReactCommentsSection(updateReact);
    }

    const submittedCommentCallback = (callbackSubmit) => {
        postReactCommentCount();
        setIsSubmitted(callbackSubmit);
    }

    const callbackSubmitReplySection = (callbackSubmit) => {
        setIsSubmitted(callbackSubmit);
    }


	return (
        <>
        {/* Tooltip for comment react */}
         <ReactTooltip 
            id='disabled_ModalReact'
            type="light"
            >
            { "this feature is not available for your own content" }
        </ReactTooltip>
		<Modal
			isOpen={hasPost && true}
			onRequestClose={close}
            shouldCloseOnOverlayClick={true}
            style={getModalWidth()}
		>
            {hasPost && 
          
			<div className="post-modal-container">
                { ReactTooltip.rebuild() }   
                {hasImage &&
                <PostImage 
                    post = { hasPost }
                    reactCount = { hasPost.totalReactions || 0 }
                    commentCount = { totalComments || 0 }
                    isReply = { hasComment }
                    inputFocus = { displayComments }
                    commented={ commented }
                    hearted = { heartedPost }
                    reactNum = { reactNum }
                    submitReact = { sendHeart }
                />
                }
                <div className="post-comments-container">
                    <div className="post-comments-header">
                        <div id="postComments">
                            <p>Comments</p>
                        </div>
                        <div id="postReplies" onClick={ ()=> displayComments() }>
                            <img src={chevLeft} alt=""/>
                            <p>Replies</p>
                        </div>
                        <img alt="" src={closeIcon} onClick={close}/>
                    </div>
                    
                    <div className="comment-reply-container">
                        <div className="post-comments" id='postCommentsContainer'>
                            {poster()._id ?
                            <div className="post-content">

                                 { hasPost.postType === 'announcement' ?   
                                 
                                 <img className="post-img-admin" src={PragmanilaLogo} alt=""/> 
                                 
                                 :  hasPost.anonymous === 1 ? 
                                 
                                 <img className="post-img" src={anonymous} alt=""/>
                                 :
                                 <img className="post-img" src={ poster().avatar || avatar} alt=""/>
                                 }

                                <div className="post-content-text">
                                       { hasPost.postType === 'announcement' ?  
                                            <>
                                                <p className="poster-name">  Announcement </p> 
                                                <p> Admnistrative Announcement </p>
                                            </>
                                        :  hasPost.anonymous === 1 ?  
                                            <p className="poster-name">Anonymous</p>
                                        : 
                                            <p className="poster-name">{poster().firstName + " " + poster().lastName || ""}</p>
                                        }

                                    <p className="post-date">{hasPost.createdAt ? moment(hasPost.createdAt).fromNow() : ""}</p>
                                    <Linkify componentDecorator={linkDecorator}>
                                        { hasPost.postType === 'announcement' ? 
                                            <div className="content-announcement-FeedsModal">
                                                <div className="content-container-announcement" style={{  paddingTop: "12px" }}>
                                                <Editor
                                                        editorState={
                                                        isEmpty(hasPost?.postContent) ?
                                                        EditorState.createEmpty() :
                                                        EditorState.createWithContent(announcementFeedsContent())
                                                            
                                                        }
                                                        readOnly
                                                        toolbarHidden
                                                    />
                                                </div>
                                            </div> 
                                               
                                        : hasPost.postType === 'commend' ? 
                                            <p className="post-text">

                                                <Link to={`/profile/${commendedUser.user_id}`}>
                                                    {commendedUser.firstName + " " + commendedUser.lastName}
                                                </Link>
                                                {hasPost.postContent.replace(poster().firstName + " " + poster().lastName, "").split(commendedUser.firstName + " " + commendedUser.lastName)[0]}
                                                <Link to={`/profile/${poster().user_id}`}>
                                                    {hasPost.anonymous === 1 ? '' : poster().firstName + " " + poster().lastName}
                                                </Link>
                                                {hasPost.postContent.replace(poster().firstName + " " + poster().lastName, "").replace(/(\b\S.+\b)(?=.*\1)/g, "").trim().split(commendedUser.firstName + " " + commendedUser.lastName)[1]}
                                            </p> 
                                        : hasPost.postType === 'reward' ? 
                                            <p>
                                                <Editor
                                                        editorState={
                                                        isEmpty(hasPost?.postContent) ?
                                                        EditorState.createEmpty() :
                                                        EditorState.createWithContent(announcementFeedsContent())
                                                            
                                                        }
                                                        readOnly
                                                        toolbarHidden
                                                    />
                                            </p> 
                                        :<p className="post-text">{hasPost.postContent || ""}</p>
                                        }
                                       
                                    </Linkify>
                                   

                                        
                                    {!hasPost.postImage &&
                                    <div className="post-counts-no-img">
                                        <div className="react-icon" onClick={ (e) => sendHeart(e, hasPost, hasPost.userId === userDetail.user_id ? "disabled_ModalReact" : "not-disabled") }  data-tip ="" data-for={hasPost.userId === userDetail.user_id ? "disabled_ModalReact" : "not-disabled"}>
                                            <img alt="" src={heartedPost ? hearted : heart}/>
                                            <p>{reactNum || 0}</p>
                                        </div>
                                        <div className="comment-icon" onClick={ ()=> document.getElementById('commentForm' + hasPost._id).focus() }>
                                            <img alt="" src={commented ? comment : nocomment}/> 
                                            <p>
                                                { totalComments || 0 }
                                                { totalComments > 1 ? ' Comments' :  ' Comment' }
                                            </p>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            : <SkeletonPost/>}
                            <div className="comment-section" id='postCs'>
                                {comments.length > 0 && comments.map((comment)=>(
                                    <CommentDisplay
                                        key={comment._id} 
                                        comment={comment} 
                                        replyComment={getCommentReplies}
                                        replies={replies}
                                        updateReactComments = { updateReactCommentsSection }
                                        callbackReactComments = { updateReactCommentsCallback }

                                    />
                                )).reverse()}
                            </div>
                        </div>
                        {/* reply-comments */}
                        <div className="reply-comments" id='postRepliesContainer'>
                            <RepliesSection 
                                commentReplies={hasComment} 
                                replies={replies}
                                isRepliedSubmitted= { IsSubmitted }
                                callbackSubmitReplySection = { callbackSubmitReplySection }
                            />
                        </div>
                    </div>
                    {hasPost._id &&
                    <CommentForm 
                        postId={hasPost._id} 
                        commentId={Object.keys(hasComment).length !== 0 ? hasComment._id : false} 
                        isReply={Object.keys(hasComment).length !== 0}
                        submittedComment={ submittedCommentCallback }
                    />
                    }
                </div>
			</div>
            }
		</Modal>
    </>
	);
};

const mapStateToProps = (state) => {
    const getOrigin = state.comments?.orig_comment?.items || {};
    const getComments = getOrigin.length > 0 && getOrigin.filter(c => c.type === "comment");
    const getReplies = getOrigin.length > 0 && getOrigin.filter(c => c.type === "reply");
    return({
        comments: getComments,
        replies: getReplies
    });
};

export default React.memo(connect(mapStateToProps)(PostModal));