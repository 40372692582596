export const achievementConstants = {
    CREATE_REQUEST: 'ACHIEVEMENT_CREATE_REQUEST',
    CREATE_SUCCESS: 'ACHIEVEMENT_CREATE_SUCCESS',
    CREATE_FAILURE: 'ACHIEVEMENT_CREATE_FAILURE',

    GETALL_REQUEST: 'ACHIEVEMENT_GETALL_REQUEST',
    GETALL_SUCCESS: 'ACHIEVEMENT_GETALL_SUCCESS',
    GETALL_FAILURE: 'ACHIEVEMENT_GETALL_FAILURE',

    DELETE_REQUEST: 'ACHIEVEMENT_DELETE_REQUEST',
    DELETE_SUCCESS: 'ACHIEVEMENT_DELETE_SUCCESS',
    DELETE_FAILURE: 'ACHIEVEMENT_DELETE_FAILURE',
    
    GETBYID_REQUEST: 'ACHIEVEMENT_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'ACHIEVEMENT_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'ACHIEVEMENTT_GETBYID_FAILURE',

    UPDATE_REQUEST: 'ACHIEVEMENT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ACHIEVEMENT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ACHIEVEMENT_UPDATE_FAILURE',

};

