import eventsConstants from '../constants/events';
import eventsServices from '../services/events';

const getAll = () => async (dispatch) => {
    dispatch({ type: eventsConstants.GET_EVENTS_REQUEST });

    try {
        const { items: events } = await eventsServices.getAll();

        dispatch({ type: eventsConstants.GET_EVENTS_SUCCESS, events });
    } catch(error) {
        dispatch({ type: eventsConstants.GET_EVENTS_FAILURE, error });
    }
}

export default { getAll };