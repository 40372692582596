import { leaveRequestConstants } from '../constants/leaveRequest';
import { leaveRequestService } from '../services/leaveRequest';

export const leaveRequestActions = {
    getAll
};

function getAll() {
    return dispatch => {
        dispatch(request());

    leaveRequestService.getAll()
    .then(
        leaveRequest => dispatch(success(leaveRequest)),
        error => dispatch(failure(error.toString()))
    );
        
    };

function request() { return { type: leaveRequestConstants.GETALL_LEAVE_REQUEST}}
function success(leaveRequest) { return { type: leaveRequestConstants.GETALL_LEAVE_SUCCESS, leaveRequest}}
function failure(error) { return { type: leaveRequestConstants.GETALL_LEAVE_FAILURE, error}}

}