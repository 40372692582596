import React from 'react';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';

import './AppStoreRedirect.scss';

const AppStoreRedirect = () => {
    const shouldShowAppStoreOverlay = useMediaQuery({ maxWidth: 700 });

    return (
        <Modal
            isOpen={shouldShowAppStoreOverlay}
            style={{
                content: {
                    bottom: 100,
                    left: 20,
                    top: 100,
                    right: 20
                },
                overlay: { zIndex: 100000 }
            }}
        >
            <div className="app-store-redirect">
                <h1 className="oops">Oops!</h1>
                <h2>You seem to be using a device with a small screen</h2>
                <p>Our web app doesn't support mobile devices.<br/>To get the most out of PragmaHR, please download our app.</p>
                <a
                    href='https://play.google.com/store/apps/details?id=com.pragmanila.pragmahr&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                >
                    <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
            </div>
        </Modal>
    );
}

export default AppStoreRedirect;