import React, { Component } from 'react';
import { maritalStatus, title } from '../_core/constants/selections'
import M from 'materialize-css/dist/js/materialize.min.js';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import './step2.sass';
import './dateInput.sass';

const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

export default class Step2 extends Component {
  constructor(props) {
    super(props);
    this.edit = typeof props.getStore().manager !== 'undefined' ? true : false;
    //setup state for new or edited profile
    this.state = {
      user: {
        _id: props.getStore()._id,
        // avatar: props.getStore().avatar,
        bio: props.getStore().bio,
        // cover: props.getStore().cover,
        firstName: props.getStore().firstName,
        middleName: props.getStore().middleName,
        lastName: props.getStore().lastName,
        title: props.getStore().title,
        suffix: props.getStore().suffix,
        address1: props.getStore().address1,
        address2: props.getStore().address2,
        city: props.getStore().city,
        state: props.getStore().state,
        country: props.getStore().country,
        nationality: props.getStore().nationality,
        mothersMaidenName: props.getStore().mothersMaidenName,
        maritalStatus: props.getStore().maritalStatus,
        gender: props.getStore().gender,
        birthDate: props.getStore().birthDate,
        birthPlace: props.getStore().birthPlace,
        email: props.getStore().email,
        team: props.getStore().team,
        organization: props.getStore().organization,
        manager: this.edit ? props.getStore().manager : null,
        position: props.getStore().position,
        department: props.getStore().department,
        startDate: props.getStore().startDate,
        endDate: props.getStore().endDate,
        regularizationDate: props.getStore().regularizationDate,
        level: props.getStore().level,
        officeId: props.getStore().officeId
      },
      selections: {
        maritalStatus: maritalStatus,
        title: title
      },
      birthDate: ''
    };
    this.handleBirthDatePicker = this.handleBirthDatePicker.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleBirthDatePicker(date) {
    // const getDate = moment(date).format("YYYY-MM-DD");
    // const isoDate = getDate + "T00:00:00." + moment().format('SSS[Z]')
    const isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    this.setState({
      birthDate: isoDate
    }, () => {
      this.handleChange({ target: { name: 'birthDate', value: isoDate } });
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  handleSelectTitle = (selected) => {
    const { user } = this.state;
    
    this.setState({
      user: {
        ...user,
        title: selected.value
      }
    }, () => console.log(this.state.user))
  }

  handleSelectMaritalStatus = (selected) => {
    const { user } = this.state;
    
    this.setState({
      user: {
        ...user,
        maritalStatus: selected.value
      }
    }, () => console.log(this.state.user))
  }

  handleSelectGender = (selected) => {
    const { user } = this.state;
    
    this.setState({
      user: {
        ...user,
        gender: selected.value
      }
    }, () => console.log(this.state.user))
  }

  componentDidMount() {
    setTimeout(function () {
      var select = document.querySelectorAll('select');
      M.FormSelect.init(select);
    }, 1000)

    //datepicker for birth date
    // var birthDate = document.querySelectorAll('#birthDate');
    // M.Datepicker.init(birthDate, {
    //   onSelect: this.handleBirthDatePicker
    // });
  }

  // componentWillUnmount() { }

  // not required as this component has no forms or user entry
  // isValidated() {}

  jumpToStep(toStep) {

    const { user } = this.state

    // We can explicitly move to a step (we -1 as its a zero based index)
    this.props.updateStore(user)
    this.props.jumpToStep(toStep - 1); // The StepZilla library injects this jumpToStep utility into each component
  }

  maritalStatusOptions(){
    const selections = this.state.selections;
    var maritalStatusOpt = []
    
    selections.maritalStatus && selections.maritalStatus.default.map((status, index)=>{
      maritalStatusOpt.push({
        value: status.value,
        label: status.label,
        key: index,
      })
      return status
    })

    return maritalStatusOpt
  }

  titleOptions(){
    const selections = this.state.selections;
    var titleOpt = []
    
    selections.title && selections.title.default.map((status, index)=>{
      titleOpt.push({
        value: status.value,
        label: status.label,
        key: index,
      })
      return status
    })

    return titleOpt
  }

  render() {
    const { user } = this.state;

    const customStyles = {
      menu:(provided, state)  => ({
          ...provided,
          height: "auto",
          maxHeight: "150px",
          zIndex: "99",
          position: "absolute",
          display: "block",
      }),
      menuList:(provided, state)  => ({
          ...provided,
          height: "auto",
          maxHeight: "150px",
          padding: "2px 2px",
      }),
      input: (state) => ({
        fontFamily: 'Poppins, sans-serif',
        '& input': {
          font: 'inherit',
          textDecoration: state.isFocused ? 'none !important' : 0,
        },
      }),
      option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? 'rgb(202,108,235)' : state.isFocused ? 'rgba(202,108,235,0.5)' : '',
          color: state.isSelected ? 'white' : state.isFocused ? 'white' : '',
          '& hover': {
            backgroundColor: 'rgb(202,108,235)',
          },
        }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
      }
    }

    return (
      <div className="step step2 step2Con">
        <div className="col s12 m12 l12">
          <div className="">
            <div className="card-header">
              <p className="left step2Txt">Personal Information </p>
            </div>

            <div className="step2Cons clearfix">
              <div className="row">
                <div className="step2Input-field col s12 m2 l2">
                  <label><p className="step2Txt1 left">Title</p></label>
                  <div className="step2Select-field step2Select1 clearfix">
                    <Select 
                      options={this.titleOptions()}
                      placeholder={this.state.user.title}
                      className="basic-single"
                      styles={customStyles}
                      onChange={this.handleSelectTitle}
                    />
                  </div>
                </div>

                <div className="step2Input-field col s12 m5 l5">
                  <label><p className="step2Txt1 left">First Name</p>
                  <input id="first_name" type="text" name="firstName" value={user.firstName} placeholder="First Name" className="validate" onChange={this.handleChange} /></label>
                </div>

                <div className="step2Input-field col s12 m5 l5">
                  <label><p className="step2Txt1 left">Middle Name</p>
                  <input id="middle_name" name="middleName" value={user.middleName} type="text" placeholder="Middle Name" className="validate" onChange={this.handleChange} /></label>
                </div>

                <div className="step2Input-field col s12 m8 l8">
                  <label><p className="step2Txt1 left">Last Name</p>
                  <input id="last_name" name="lastName" type="text" value={user.lastName} placeholder="Last Name" className="validate" onChange={this.handleChange} /></label>
                </div>

                <div className="step2Input-field col s12 m4 l4">
                  <label><p className="step2Txt1 left">Suffix</p>
                  <input id="suffix" name="suffix" type="text" value={user.suffix} placeholder="Suffix" className="validate" onChange={this.handleChange} /></label>
                </div>
              </div>

              <div className="row">
                <div className="step2Input-field col s12 m12">
                  <label><p className="step2Txt2 left">Primary Address</p>
                  <input id="add1" name="address1" type="text" value={user.address1} placeholder="Primary Address" className="validate" onChange={this.handleChange} /></label>
                </div>

                <div className="step2Input-field col s12 m12">
                  <label><p className="step2Txt2a left">Secondary Address</p>
                  <input id="add2" name="address2" type="text" value={user.address2} placeholder="Secondary Address" className="validate" onChange={this.handleChange} /></label>
                </div>
              </div>

              <div className="row step2ConC">
                <div className="step2Input-field col s12 m3 l3">
                  <label><p className="step2Txt3 left">City</p>
                  <input id="city" name="city" type="text" value={user.city} placeholder="City" className="validate" onChange={this.handleChange} /></label>
                </div>

                <div className="step2Input-field col s12 m3 l3">
                  <label><p className="step2Txt3 left">State</p>
                  <input name="state" id="state" type="text" value={user.state} placeholder="State" className="validate" onChange={this.handleChange} /></label>
                </div>

                <div className="step2Input-field col s12 m3 l3">
                  <label><p className="step2Txt3 left">Country</p>
                  <input name="country" id="country" type="text" value={user.country} placeholder="Country" className="validate" onChange={this.handleChange} /></label>
                </div>

                <div className="step2Input-field col s12 m3 l3">
                  <label><p className="step2Txt3 left">Nationality</p>
                  <input name="nationality" id="nationality" type="text" value={user.nationality} placeholder="Nationality" className="validate" onChange={this.handleChange} /></label>
                </div>
              </div>

              <div className="row step2ConD">
                <div className="step2Input-field col s12 m4 l4 xl4">
                  <label><p className="step2Txt4 left">Mothers Maiden Name</p>
                  <input name="mothersMaidenName" id="mothersmaidenname" type="text" value={user.mothersMaidenName} placeholder="Mothers Maiden Name" className="validate" onChange={this.handleChange} /></label>
                </div>

                <div className="step2Input-field col s12 m4 l4 xl4">
                  <label><p className="step2Txt4 left">Marital Status</p></label>
                  <div className="step2Select-field step2Select1 clearfix">
                    <Select 
                      options={this.maritalStatusOptions()}
                      placeholder={this.state.user.maritalStatus}
                      className="basic-singlestep2Select"
                      styles={customStyles}
                      onChange={this.handleSelectMaritalStatus}
                    />
                  </div>
                </div>

                <div className="step2Input-field col s12 m4 l4 xl4">
                  <label><p className="step2Txt4 left">Gender</p></label>
                  <div className="step2Select-field step2Select1 clearfix">
                    <Select 
                      options={genderOptions}
                      placeholder={this.state.user.gender}
                      className="basic-single"
                      styles={customStyles}
                      onChange={this.handleSelectGender}
                    />
                  </div>
                </div>
              </div>

              <div className="row step2ConE">
                <div className="step2Input-field col s12 m6 l6 datepicker">
                  <label><p className="step2Txt5 left">Birthdate</p>
                  <DatePicker
                    name="birthDate"
                    selected={user.birthDate ? new Date(user.birthDate) : new Date()}
                    onChange={this.handleBirthDatePicker}
                    showMonthDropdown
                    showYearDropdown
                    value={user.birthDate === 'Invalid date' ? 'No Data Available' : moment(user.birthDate).format('MMMM D, YYYY')}
                    className="date-input"
                  />
                  </label>
                </div>

                <div className="step2Input-field col s12 m6 l6">
                  <label><p className="step2Txt5 left">Birthplace</p>
                  <input id="birth_place" name="birthPlace" value={user.birthPlace} onChange={this.handleChange} type="text" placeholder="Birth Place" className="validate" /></label>
                </div>
              </div>
               
            </div>

            <div className="button-container-four">
              <div className="">
                <button className="btn page2Btn-prev" onClick={() => this.jumpToStep(1)}>Previous</button>
              </div>
              <div className="">
                <button className="btn page2Btn-next" onClick={() => this.jumpToStep(3)}>Next</button>
              </div>
            </div>
          </div>

          <div className="right">
            <p className="page2PageNum">Step 2 of 4</p>
          </div>

        </div>
      </div>
    )
  }
}

