import { leaveTypeConstants } from '../constants/leaveType';
import { leaveTypeService } from '../services/leaveType';
import { alertActions } from './alert';
import { history } from '../helpers/history';
import Swal from 'sweetalert2';

export const leaveTypeActions = {
    getAll,
    getAllTypes,
    addLeaveType,
    deleteLeaveType,
    getById,
    updateLeaveType,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        leaveTypeService.getAll()
            .then(
                // leaveTypes => dispatch(success(leaveTypes.items)),
                leaveTypes => dispatch(success(leaveTypes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: leaveTypeConstants.GETALL_REQUEST } }
    function success(leaveTypes) { return { type: leaveTypeConstants.GETALL_SUCCESS, leaveTypes } }
    function failure(error) { return { type: leaveTypeConstants.GETALL_FAILURE, error } }
}

function getAllTypes() {
    return dispatch => {
        dispatch(request());

        leaveTypeService.getAllTypes()
            .then(
                leaveTypes => dispatch(success(leaveTypes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: leaveTypeConstants.GETALL_REQUEST } }
    function success(leaveTypes) { return { type: leaveTypeConstants.GETALL_SUCCESS, leaveTypes } }
    function failure(error) { return { type: leaveTypeConstants.GETALL_FAILURE, error } }
}

function getById(id){
    return dispatch => {
        dispatch(request(id))
        
        leaveTypeService.getLeaveByID(id)
            .then(
                type => {
                    //console.log(type)
                    dispatch(success(type.success))

                },
                error => dispatch(failure(error.toString()))
            )
    }

    function request(id) { return { type: leaveTypeConstants.GETBYID_REQUEST, id } }
    function success(leavetype) { return { type: leaveTypeConstants.GETBYID_SUCCESS, leavetype } }
    function failure(error) { return { type: leaveTypeConstants.GETBYID_FAILURE, error } }
}

function addLeaveType(leaveType){
    // custom toast mixin for sweetAlert
    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        background: '#D4EDDA',
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });
    const Error = Swal.mixin({
        toast: true,
        width: 300,
        background: '#ff9999',
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });
    return dispatch => {
        dispatch(request(leaveType));

        leaveTypeService.addLeaveType(leaveType)
            .then(
                leaveType => {
                    dispatch(success());
                    history.push('/admin/leave-type/overview');
                    dispatch(alertActions.success('New leave type was been created'));
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#0A551E">New Leave Type Added!</span>'
                    })
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    Error.fire({
                        type: 'error',
                        title: '<span style="color:#990000">Something went wrong</span>'
                    })
                }
            );
    };

    function request(leaveType) { return { type: leaveTypeConstants.POST_LEAVETYPE_REQUEST, leaveType } }
    function success(leaveType) { return { type: leaveTypeConstants.POST_LEAVETYPE_SUCCESS, leaveType } }
    function failure(error) { return { type: leaveTypeConstants.POST_LEAVETYPE_FAILURE, error} }
}

function deleteLeaveType(leaveID){
    return dispatch => {
        dispatch(request(leaveID))
        
        leaveTypeService.delLeaveType(leaveID)
            .then(
                leavetype => dispatch(success(leaveID)),
                error => dispatch(failure(error.toString()))
            )

    }
    function request(leaveID) { return { type: leaveTypeConstants.DEL_REQUEST, leaveID } }
    function success(leaveID) { return { type: leaveTypeConstants.DEL_SUCCESS, leaveID } }
    function failure(error) { return { type: leaveTypeConstants.DEL_FAILURE, error } }
}

function updateLeaveType(id, leavetype){
    // custom toast mixin for sweetAlert
    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        background: '#D4EDDA',
        position: 'center',
        showConfirmButton: false,
        timer: 5000
    });
    return dispatch => {
        dispatch(request(leavetype))

        leaveTypeService.updateLeaveType(id, leavetype)
            .then(
                leave => {
                    dispatch(success(leavetype))
                    dispatch(alertActions.success('Leave Type successfully updated'));
                    history.goBack();
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#0A551E">Leave Type Successfully Updated!</span>'
                    })
                },
                error => dispatch(failure(error.toString()))
            )
    }

    function request(leavetype) { return { type: leaveTypeConstants.UPDATE_LEAVETYPE_REQUEST, leavetype } }
    function success(leavetype) { return { type: leaveTypeConstants.UPDATE_LEAVETYPE_SUCCESS, leavetype } }
    function failure(error) { return { type: leaveTypeConstants.UPDATE_LEAVETYPE_FAILURE, error } }
}

