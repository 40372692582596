

export const announcementConstants = { 
    GETALL_REQUEST: 'ANNOUNCEMENT_GETALL_REQUEST',
    GETALL_SUCCESS: 'ANNOUNCEMENT_GETALL_SUCCESS',
    GETALL_FAILURE: 'ANNOUNCEMENT_GETALL_FAILURE',

    GET_REQUEST: 'ANNOUNCEMENT_GET_REQUEST',
    GET_SUCCESS: 'ANNOUNCEMENT_GET_SUCCESS',
    GET_FAILURE: 'ANNOUNCEMENT_GET_FAILURE',

    DELETE_REQUEST: 'ANNOUNCEMENT_DELETE_REQUEST',
    DELETE_SUCCESS: 'ANNOUNCEMENT_DELETE_SUCCESS',
    DELETE_FAILURE: 'ANNOUNCEMENT_DELETE_FAILURE',

    GETBYID_REQUEST: 'ANNOUNCEMENT_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'ANNOUNCEMENT_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'ANNOUNCEMENT_GETBYID_FAILURE',

    UPDATE_ANNOUNCEMENT_REQUEST: 'ANNOUNCEMENT_UPDATE_REQUEST',
    UPDATE_ANNOUNCEMENT_SUCCESS: 'ANNOUNCEMENT_UPDATE_SUCCESS',
    UPDATE_ANNOUNCEMENT_FAILURE: 'ANNOUNCEMENT_UPDATE_FAILURE',
}

