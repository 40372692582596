import React from 'react'
import Modal from 'react-modal'

import moment from 'moment';
import './LeavePageModal.sass';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%'
    },
    overlay: {
        backgroundColor: 'rgba(100, 100, 100, 0.75)'
    }
};

class LeavePageModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status: '',
            refCode: '',
            leaveType: '',
            dates: '',
            notedBy: '',
            noter_details: '',
            approvedBy: '',
            approver_details: '',
            reason: '',
            notedRemark: '',
            rejectRemark: '',
            approvalRemark: ''
        }
    }

    closeModal = event => {
        event.preventDefault()
        this.props.close()
    }

    componentDidMount() {
        this.setState({
            status: this.props.leave.status,
            refCode: this.props.leave.refCode,
            leaveType: this.props.leave.leaveType,
            dates: this.props.leave.dates,
            notedBy: this.props.leave.notedBy,
            noter_details: this.props.leave.noter_details,
            approvedBy: this.props.leave.approvedBy,
            approver_details: this.props.leave.approver_details,
            reason: this.props.leave.reason,
            notedRemark: this.props.leave.notedRemark,
            rejectRemark: this.props.leave.rejectRemark,
            approvalRemark: this.props.leave.approvalRemark
        })
    }

    render() {
        let { 
            status, 
            refCode, 
            leaveType,
            dates, 
            notedBy, 
            noter_details, 
            approvedBy, 
            approver_details, 
            reason,
            notedRemark,
            rejectRemark,
            approvalRemark } = this.state

        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.close}
                style={customStyles}
            >
                <div className="lpmDetailContainer">
                    <div className="row">
                        <div className="col s11 m11 l11">
                            <p className="opmTitleTxt"><b>Your Filings</b></p>
                        </div>
                        <div className="col s1 m1 l1">
                            <button className="lpmButtonCloseModal">
                                <i onClick={this.closeModal} className="small material-icons right">close</i>
                            </button>
                        </div>
                    </div>

                    <hr className="lpmLines" />

                    <div className="lpmDetailCon">
                        <div className="lpmDetailCons lpmDetailCons1">
                            <p className="lpmTxt"><b>Status</b></p>
                            <p style={{textTransform:"capitalize"}} className={status === "on hold" ? "onHold" : status}>{status ? status === "new" ? "in-review" : status : "none"}</p>
                        </div>
                        <div className="lpmDetailCons lpmDetailCons2">
                            <p className="lpmTxt"><b>Reference Code</b></p>
                            <p>{refCode ? refCode : 'No Code'}</p>
                        </div>
                        <div className="lpmDetailCons lpmDetailCons3">
                            <p className="lpmTxt"><b>Type</b></p>
                            <p>{leaveType[0] ? leaveType[0].type : 'none'}</p>
                        </div>
                        <div className="lpmDetailCons lpmDetailCons4">
                            <p className="lpmTxt"><b>From</b></p>
                            <p>{dates ? moment(dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(',')[0]).format('MMMM DD, YYYY') : "no date"}</p>
                        </div>
                        <div className="lpmDetailCons lpmDetailCons5">
                            <p className="lpmTxt"><b>To</b></p>
                            <p>{dates ? moment(dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(/[, ]+/).pop()).format('MMMM DD, YYYY') : 'no date'}</p>
                        </div>
                        <div className="lpmDetailCons lpmDetailCons6">
                            <p className="lpmTxt"><b>Days</b></p>
                            <p>{dates.slice(1, -1).split(',').length}</p>
                        </div>
                        <div className="lpmDetailCons lpmDetailCons7">
                            <p className="lpmTxt"><b>Noted by</b></p>
                            <p>{notedBy && noter_details[0].firstName + ' ' + noter_details[0].lastName ? notedBy && noter_details[0].firstName + ' ' + noter_details[0].lastName : "No name specified"}</p>
                        </div>
                        <div className="lpmDetailCons lpmDetailCons8">
                            <p className="lpmTxt"><b>Approved by</b></p>
                            <p>{approvedBy && approver_details[0].firstName + ' ' + approver_details[0].lastName ? approvedBy && approver_details[0].firstName + ' ' + approver_details[0].lastName : "No name specified"}</p>
                        </div>
                    </div>

                    <div className="clearfix">
                        <div className="lpmDetailConsA">
                            <p><b>Reason:</b><span className="lpmSpanTxt">{reason ? reason : 'No Reason'}</span></p>
                        </div>
                    </div>

                    <hr className="lpmLines" />

                    <div>
                        {notedRemark && status !== "new"? 
                            <div className="col s12 m12 l12">
                                <p className="opmTxt1" style={{color: "orange", marginTop: "0"}}>On hold remark : <span style={{color: "black", fontWeight:"normal", textTransform:"lowercase"}} className="opmSpanTxt">{notedRemark}</span></p>
                            </div>
                        :''}
                        {rejectRemark ?
                            <div className="col s12 m12 l12">
                                <p className="opmTxt1" style={{color: "red", marginTop: "0"}}>Rejection remark : <span style={{color: "black", fontWeight:"normal", textTransform:"lowercase"}} className="opmSpanTxt">{rejectRemark}</span></p>
                            </div>
                        :''}
                        {approvalRemark ?
                            <div className="col s12 m12 l12">
                                <p className="opmTxt1" style={{color: "green", marginTop: "0"}}>Approval remark : <span style={{color: "black", fontWeight:"normal", textTransform:"lowercase"}} className="opmSpanTxt">{approvalRemark}</span></p>
                            </div>
                        :''}
                    </div>
                </div>
            </Modal>
        )
    }
}

export default LeavePageModal;