import { overtimeConstants } from '../constants/overtime';

let initialState = {
  overtimes: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function overtimes(state = initialState, action) {
  switch (action.type) {
    case overtimeConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case overtimeConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        overtimes: action.overtimes,
        totalPerPage: action.overtimes.total,
        perPage: action.overtimes.pages,
        currentPage: action.overtimes.page,
      };
    case overtimeConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case overtimeConstants.GET_REQUEST:
      return {
        saving: true
      };
    case overtimeConstants.GET_SUCCESS:
      return {
        overtime: action.overtime
      };
    case overtimeConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    case overtimeConstants.GETUSER_REQUEST:
      return {
        loading: true
      };
    case overtimeConstants.GETUSER_SUCCESS:
      return {
        overtimes: action.overtimes
      };
    case overtimeConstants.GETUSER_FAILURE:
      return { 
        error: action.error
      };

    default:
      return state;
  }
}
    // case overtimeConstants.DELETE_REQUEST:
    //   // add 'deleting:true' property to overtime being deleted
    //   return {
    //     ...state,
    //     items: state.items.map(overtime =>
    //       overtime.id === action.id
    //         ? { ...overtime, deleting: true }
    //         : overtime
    //     )
    //   };
    // case overtimeConstants.DELETE_SUCCESS:
    //   // remove deleted overtime from state
    //   return {
    //     items: state.items.filter(overtime => overtime.id !== action.id)
    //   };
    // case overtimeConstants.DELETE_FAILURE:
    //   // remove 'deleting:true' property and add 'deleteError:[error]' property to overtime 
    //   return {
    //     ...state,
    //     items: state.items.map(overtime => {
    //       if (overtime.id === action.id) {
    //         // make copy of overtime without 'deleting:true' property
    //         const { deleting, ...overtimeCopy } = overtime;
    //         // return copy of overtime with 'deleteError:[error]' property
    //         return { ...overtimeCopy, deleteError: action.error };
    //       }

    //       return overtime;
    //     })
    //   };
    