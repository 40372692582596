import { timekeepingConstants } from '../constants/timekeeping';

const initialState = {
  error: false,
  loading: true,
  self: {},
  public: {}
}

export function timekeeping(state = initialState, action) {
  switch (action.type) {
    case timekeepingConstants.GET_REQUEST:
    case timekeepingConstants.GET_ALL_REQUEST:
      return {
        ...state,
        error: false,
        loading: true
      };

    case timekeepingConstants.GET_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        self: action.timekeeping,
        timekeeping: action.timekeeping
      };
    case timekeepingConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        timekeepings: action.timekeepings
      };

    case timekeepingConstants.SAVE_PRAG:
      return {
        ...state,
        error: false,
        loading: false,
        self: {
          ...state.self,
          allPrags: [
            action.prag,
            ...state.self.allPrags,
          ]
        }
      };

    case timekeepingConstants.GET_ALL_FAILURE:
    case timekeepingConstants.GET_FAILURE:
      return {
        ...state,
        error: action.error
      };
    //pragger
    case timekeepingConstants.GET_PRAGGER_REQUEST:
      return {
        loading: true
      };
    case timekeepingConstants.GET_PRAGGER_SUCCESS:
      return {
        timekeepings: action.timekeepings
      };
    case timekeepingConstants.GET_PRAGGER_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}