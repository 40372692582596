import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import styled, { css } from "styled-components";
import { useParams } from 'react-router-dom';
import { userActions } from '../../../_core/actions/user';
import { leaveCreditActions } from '../../../_core/actions/leaveCredit';
import { technicalActions } from '../../../_core/actions/technical';
import { alertActions } from '../../../_core/actions/alert';
import "./ReviewFeed.scss";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { When } from 'react-if'; 
import PreviewLeaveUserWidget from "../../AdminWidget/PreviewUserDetailsWidget/PreviewLeaveUserWidget"
import {ContentWrapper,LabelCustom} from "../../Components/PageWrapper/ContentWrapper";
import Modal from "react-modal";
import fileIcon from '../../../_templates/dark/file_blank_outline.png';
import download from '../../../_templates/dark/download.png';
import close from '../../../_templates/dark/off_outline_close.png';
import FileViewer from 'react-file-viewer';
import {
    CustomTextArea,
    // CustomDatePicker
  } from "../../Components/FloatingTextBox/TextArea";
import { commentActions } from '../../../_core/actions/comment';
import { commentService } from '../../../_core/services/comment';

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

const mapStateToProps = ({ overtimes,leaves,leaveCredits,technical,comments }) => ({
    overtimes,
    leaves,
    leaveCredits,
    technical,
    comments
});

const ReviewTech = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    // const [update, setUpdate] = React.useState(false);
    const [count, setRender] = React.useState(true);
    const [UserDetailsView, setUserDetailsView] = React.useState([]);
    const [isDisplayUserDetails, setIsDisplayUserDetails] = React.useState(false); // eslint-disable-line
    const countRef = React.useRef(count);
    // const [request, setRequest] = React.useState([]);
    const openFilePreview = () => {
    setFilePreview(true);
    };
    
    const [showFilePreview, setFilePreview] = React.useState(false);
    const [FileUrl, setFileUrl] = React.useState('');
    const [FileType, setFileType] = React.useState('');
    const [comment, setCommment] = React.useState([]);
    const [reply, setReply] = React.useState([]);
    
    const customStyles = {
        content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }
    };

    countRef.current = count;

    const { pageId } = useParams();

    useEffect(() => {
        let oid = pageId
        dispatch(technicalActions.getById(oid));
        dispatch(userActions.getAll(1, 100000));
        dispatch(leaveCreditActions.getAll());
        dispatch(commentActions.getByOrigin(oid, 1, 999));
        setIsDisplayUserDetails(true)
        alertTimeOut();
        // eslint-disable-next-line
    }, [dispatch])

    const [support, setTechSupport] = React.useState({
        _id: '',
        files: '',
        description: '',
        startedAt:'',
        endedAt:'',
        user_id:'',
        subtype:'',
        subject:'',
        status: '',
        approver:'',
        approvedBy: '',
        approvalRemark: '',
        approvedAt: '',
        rejectedBy: '',
        rejectedAt: '',
        notedBy: '',
        notedRemark: '',
        notedAt: '',
        createdAt: '',
        updatedAt: '',
        __v: '',

        approvedRemark: '',
        type: '',
        rejectedRemark: '',
    });

    useEffect(() => {

        if (pageId) {
            let technicalList =  props.technical.success ?? [];
            if(technicalList) {
                technicalList.forEach((item) => {

                    setTechSupport({
                        _id: item._id,
                        files: item.files,
                        description: item.description,
                        startedAt: item.startedAt,
                        endedAt: item.endedAt,
                        user_id:item.user_id,
                        subtype:item.subtype,
                        status: item.status,
                        approver: item.approver,
                        approvedBy: item.approvedBy,
                        approvalRemark: item.approvalRemark,
                        approvedAt: item.approvedAt,
                        rejectedBy: item.rejectedBy,
                        rejectedAt: item.rejectedAt,
                        notedBy: item.notedBy,
                        notedRemark: item.notedRemark,
                        notedAt: item.notedAt,
                        createdAt: item.createdAt,
                        updatedAt: item.updatedAt,
                        approvedRemark: item.approvedRemark,
                        type: item.type,
                        rejectedRemark: item.rejectedRemark,
                    });
                    setUserDetailsView(item.users);
                });
                
            }

        
        //   }
        }
        //eslint-disable-next-line
      }, [props.technical, pageId]);

      useEffect(() => {
        commentService.getByOrigin(pageId, 1, 999).then(res=>{
            if(res.items?.length !== 0){
                setCommment(res.items)
                commentService.getByOrigin(res.items[0]?._id, 1, 999).then(res=>{
                    if(res.items){
    
                        if(res.items.length !== 0){
                            setReply(res.items)
                        }
    
                    }
                }).catch(error=>{console.log(error)});
    
            }
        }).catch(error=>{console.log(error)});
      }, [props, pageId]);

      const alertTimeOut = () => {
        setTimeout(() => {
            setRender(countRef.current);
            props.dispatch(alertActions.clear());
        }, 3000);
    };

    const descriptionChange2 = (e) => {
        // setIsAdd(true)
        setTechSupport({ ...support, description: e.target.value });
    }

    const goBack = () => {
        props.history.goBack();
    }

    return (
        <CustomContentWrapper displayUserWrapper={isDisplayUserDetails} style={{ gridTemplateColumns:"70% 27%"}}>
            <ContentWrapper className="div-main">
                <LabelCustom className="main-label">
                    Review Feedback
                </LabelCustom>

                <div className='app_status'>
                    <div className="row">
                        <div className="col l6 m6 s12">
                        <p className="status">
                            Application Status :
                            <span className={support.status === "new" ? "new" : support.status}>{' '}
                                {support.status === "new" 
                                ? "In review" 
                                : support.status && support.status === "approved" 
                                ? "Completed" 
                                : support.status && support.status === "on hold" ? 'In progress' : support.status}
                            </span>
                        </p>
                        </div>
                        <div className="col l6 m6 s12">
                        <p className="status">
                        Date Filed :{" "}
                            <span className="rr-text-content">
                            {moment(support.createdAt).format(
                                "dddd, MMMM DD YYYY"
                            )}
                            </span>
                        </p>
                        </div>
                    </div>
                </div>

                {/* Description */}
                <div className="row textarea-column" style={{marginBottom:'0px'}}>
                    <div className="col s12 m12 l12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>

                        {support.status === "approved" || support.status === "rejected" || support.status === "completed" || support.status === "cancelled" ?
                            
                            <CustomTextArea
                                value={support.description}
                                readOnly={true}
                                label='Description'
                            />
                            :
                            <CustomTextArea
                                value={support.description}
                                onChange={descriptionChange2}
                                label='Description'
                                readOnly={true}
                            />
                        }
                                            
                    </div>
                </div>

                <p></p>

                <div className="div-attach" style={{marginBottom:'20px'}}>
                    <p className="info" style={{ marginTop: "0" }}>
                        Attachments
                    </p>

                    {support.files.length !== 0  || support.files === 'undefined' || support.files === null || support[0]?.files === '' ? (
                        support.files.map((c) => (
                            // <p>{c.split('-').pop()}</p>
                            <div key={c} className="attach-div">
                                <div className="attach-preview">
                                    <img
                                        src={c}
                                        className="attach-file"
                                        alt="Thumb"
                                        onError={(e) => (e.target.src = fileIcon) }
                                    />
                                        <p onClick={() => { openFilePreview(); setFileUrl(c); setFileType(c.split('.').pop()) }} style={{cursor:'pointer'}}>{c.split('/').pop()}</p>
                                </div>
                                <a href={c} download>
                                    <button className="btn-download" style={{background:'none'}} >
                                        <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'contrast(0.5)' }}/>
                                    </button>
                                </a>
                            </div>
                        ))
                    ) : (
                    <>
                        <div className="attach-div">
                            <div className="attach-preview">
                                <p>No File Attached</p>
                            </div>
                        </div>
                    </>
                    )}
                </div>

                <div>
                    <Modal
                        isOpen={showFilePreview}
                        ariaHideApp={true}
                        toggle={setFilePreview}
                        onRequestClose={() => setFilePreview(false)}
                        shouldCloseOnOverlayClick={true}
                        className="fileModalPreview"
                        style={customStyles}
                    >
                        <div
                        className="row center rejecting-container"
                        style={{
                            width: "auto",
                            height: "auto", 
                            margin: "auto",
                            padding: "50px",
                        }}
                        >
                        <div className="gAction">
                            <a href={FileUrl} download>
                                <button className="btn-download" style={{background:'none'}}>
                                    <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'brightness(5.5)' }}/>
                                </button>
                            </a>
                            <img height={20} onClick={() => setFilePreview(false)} src={close} alt="" style={{ margin:'15px 7px', filter: 'brightness(5.5)', cursor:'pointer' }}/>
                        </div>
                        <div style={{ height: '100%' }}>
                        <FileViewer 
                            fileType={FileType} 
                            filePath={FileUrl} 
                            key= {FileUrl}
                        />
                        </div>

                        </div>
                    </Modal>
                </div>

                {/* ------------------------------------- BUTTONS -------------------------------------------- */}
                <div className="row review-buttons">
                    <div className="col s1 m4 l4 right buttonHRTwo" style={{ width: "40%", padding: "0 0" }}>
                        <button onClick={goBack} className="btn right cancelDark-tech">CANCEL</button>
                    </div>
                </div>
            </ContentWrapper>

            <When condition={isDisplayUserDetails === true}>
                <PreviewLeaveUserWidget 
                    {...props} 
                    subjectId={UserDetailsView} 
                    availableCredits='false'
                    comments ={comment}
                    replies = {reply}
                    request = {support}
                    isReply={Object.keys(comment).length !== 0}
                />
            </When>
                
        </CustomContentWrapper>
    )
});


export default ReviewTech;
