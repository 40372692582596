import { sessionConstants } from '../constants/session';
import { sessionService } from '../services/session';

export const sessionActions = {
    getAll,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        sessionService.getAll()
            .then(
                session => dispatch(success(session.success)),
                error => dispatch(failure(error.toString())
            ));
    };

    function request() { return { type: sessionConstants.GETALL_REQUEST } }
    function success(session) { return { type: sessionConstants.GETALL_SUCCESS, session } }
    function failure(error) { return { type: sessionConstants.GETALL_FAILURE, error } }
}