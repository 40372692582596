import { leaveRequestConstants } from '../constants/leaveRequest';

export function leaveRequest (state = {}, action) {
    switch (action.type) {
        case leaveRequestConstants.GETALL_LEAVE_REQUEST:
            return {
                loading: true
            };
        case leaveRequestConstants.GETALL_LEAVE_SUCCESS:
            return {
                leaveRequest: action.leaveRequest
            };
        case leaveRequestConstants.GETALL_LEAVE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};