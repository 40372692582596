import React from 'react';
import { Link } from 'react-router-dom';

import '../MainNav.sass'

class PublicNav extends React.Component {
    render() {
        return (
          <nav className="pink darken-4">
            <div className="nav-wrapper">
              <Link to="/" className="brand-logo center">P</Link>
            </div>
          </nav> 
        );
    }
}

export default PublicNav;