import React from 'react';
import { connect } from 'react-redux';
import { coeActions } from '../_core/actions/coe';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import moment from 'moment';
import './RequestCoePage.sass'
import '../_core/styles/alert.sass'

class RequestCoePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            coe: {
                title: 'COE Request',
                description: '',
                category: 'hr-docs',
                assignee: '',
                module: 'HRM',
                type: 'coe'
            },
            submitted: false,
            render: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderAlert = this.renderAlert.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { coe } = this.state
        this.setState({
            coe: {
                ...coe,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { coe } = this.state;
        this.setState({ render: true });

        // custom toast mixin for sweetAlert
        const Toast = Swal.mixin({
            toast: true,
            width: 300,
            height: 500,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });
        // var category = document.querySelector(".categoryVal");
        // var value_Category = category.options[category.selectedIndex].innerHTML;
        var dateNow = Date(Date.now()).toLocaleString();
        var dateFormat = moment(dateNow).format("ddd, MMMM DD, YYYY");

        if (coe.title && coe.description && coe.category && coe.module && coe.type) {

            Swal.fire({
                title: 'Are you sure?',
                footer: 'Filing Date:  ' + dateFormat,
                type: 'warning',

                showCancelButton: true,
                confirmButtonColor: '#5CB85C',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Done!'
            }).then((result) => {
                if (result.value) {
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:green">COE has been Sent!</span>'
                    }).then((res) => {
                        this.props.dispatch(coeActions.sendRequestCoe(coe));

                    })
                }
            })
        }

    }

    handleClose(event) {
        event.preventDefault()
        this.setState({ render: false })
    }




    renderAlert() {
        // const { alert } = this.props;
        // if (this.state.render) {
        //     return (<div onLoad={this.autoClose} id='alert' className={`alert ${alert.type}`} toggle={this.onDismiss}>{alert.message}<span className='closebtn right' onClick={this.handleClose}>&times;</span></div>)

        // } else {
        //     return null
        // }

    }
    render() {
        const { coe, submitted } = this.state
        // eslint-disable-next-line
        const { alert } = this.props;

        return (
            
                <div className="col s12 s12 l12 userDetailConLineLeft">
                    <div className="RequestCoeCon">
                        <p className="text-left"><b>Detailss</b></p>
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className={'textarea-field' + (submitted && !coe.description ? ' has-error' : '')}>
                                    <textarea className="form-control validate" id="description" name="description" value={coe.description} onChange={this.handleChange} placeholder="State your reason" />
                                    {submitted && !coe.description &&
                                         <div className="help-block red-text" data-error="wrong" data-success="right">Description is required</div>
                                    }
                                </div>
                                                        
                            <div className="RequestCoeConButton">
                                <button className="waves-effect waves-light btn" id="submitRequestCoe">Submit</button>
                                <Link to="/profile" className="waves-effect waves-light btn" id="cancel">Cancel</Link>
                            </div>
                            </form>
                    </div>
                </div>
            
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { coe } = state;
    return {
        coe,
        alert
    };
}
const connectedRequestCoePage = connect(mapStateToProps)(RequestCoePage);

export { connectedRequestCoePage as RequestCoePage };