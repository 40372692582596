import React from 'react';
import MainNav from '../Components/MainNav';

import address from '../_templates/location-address.png';
import office from  '../_templates/location-office.png';
import phone from '../_templates/location-phone.png';

import eastwood from '../_templates/location-eastwood.jpg';
import makati from '../_templates/location-makati.jpg';
import ubp from '../_templates/location-ubp.jpg';

import M from 'materialize-css/dist/js/materialize.min.js';

import './Location.sass'

class Location extends React.Component {
    componentDidMount() {
        var elem = document.querySelector(".parallax");
        M.Parallax.init(elem);
    }

    render() {
        return (
          <div className="Location-page">
            <MainNav/>

            <div className="container">
                <div className="center">
                    <img src={office} className="office" alt="office"/>
                    <h3 className="text-center">Offices</h3>
                </div>
                 
                <div classname="col s12 m12 l12">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <img src={eastwood} className="eastwood" alt="office"/>
                        </div>
                        <div className="col s12 m6 l6">
                            <h5 className="text-left">Pragmanila HQ</h5>
                            <p className="text-left">Pragmanila Head Quarters</p>
                            <p className="location-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <div className="row">
                                <div className="col s2 m1 l1">
                                    <img src={address} className="img-address" alt="address"/>
                                </div>
                                <div className="col s9 m9 l9">
                                    <p className="address">11th floor Unit MN CyberOne Building, Eastwood Cyberpark, Bagumbayan, Quezon City, Philippines 1110</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s2 m1 l1">
                                    <img src={phone} className="img-phone" alt="phone"/>
                                </div>
                                <div className="col s9 m9 l9">
                                    <p className="address">+639289991172 <br/> +639289991172</p>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m6 l6" id="ubp-desc">
                            <h5 className="text-left">UnionBank Philipines</h5>
                            <p className="text-left">Pragmanila API Dev Team</p>                            
                            <p className="location-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <div className="row">
                                <div className="col s2 m1 l1">
                                    <img src={address} className="img-address" alt="address"/>
                                </div>
                                <div className="col s9 m9 l9">
                                    <p className="address">11th floor Unit MN CyberOne Building, Eastwood Cyberpark, Bagumbayan, Quezon City, Philippines 1110</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s2 m1 l1">
                                    <img src={phone} className="img-phone" alt="phone"/>
                                </div>
                                <div className="col s9 m9 l9">
                                    <p className="address">+639289991172 <br/> +639289991172</p>
                                    
                                </div>
                            </div>
                            
                            
                        </div>
                        <div className="col s12 m6 l6" id="ubp-pic">
                            <img src={ubp} className="eastwood2"  alt="office"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <img src={makati} className="eastwood" alt="office"/>
                        </div>
                        <div className="col s12 m6 l6">
                            <h5 className="text-left">Innovathink Corporation</h5>
                            <p className="text-left">Thinkin ahead through innovation</p>
                            <p className="location-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <div className="row">
                                <div className="col s2 m1 l1">
                                    <img src={address} className="img-address" alt="address"/>
                                </div>
                                <div className="col s9 m9 l9">
                                    <p className="address">11th floor Unit MN CyberOne Building, Eastwood Cyberpark, Bagumbayan, Quezon City, Philippines 1110</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s2 m1 l1">
                                    <img src={phone} className="img-phone" alt="phone"/>
                                </div>
                                <div className="col s9 m9 l9">
                                    <p className="address">+639289991172 <br/> +639289991172</p>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            
          </div>
        );
    }
}

export { Location };
