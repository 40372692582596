import { ESSConstants } from '../constants/ess';
import { essService } from '../services/ess';
import { history } from '../helpers/history';
import { MessageAfterSubmit, UserToast } from "../components/Alert";

export const essActions = {
    getAll,
    getById,
    getAllByUser,
    create,
    update,
    approve,
    approveTechSuppport,
    hold,
    holdTechSUpport,
    reject,
    getActivitiesByUser
}

const statusURLPath = {
    approve: "approve",
    onHold: "hold",
    reject: "reject"
}

const formatLeaveTypeText = (request) => {
    if(request.type?.includes("leave")) {
        return "Leave"
    } else {
        const formatText = request?.subtype?.split(" ")?.map((t) => {
            return t[0].toUpperCase() + t.slice(1)
        })?.join(" ");

        return formatText
    }
}

function getAll(type, page, itemPerPage) {
    return dispatch => {
        dispatch(request());

        essService.getAll(type, page, itemPerPage)
            .then(
                response => dispatch(success(response)), //TODO: include codes for request type page requirement (leave pages, overtime pages, etc)
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: ESSConstants.GETALL_REQUEST } }
    function success(response) { return { type: ESSConstants.GETALL_SUCCESS, response } }
    function failure(error) { return { type: ESSConstants.GETALL_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request());

        essService.getById(id)
            .then(
                response => dispatch(success(response)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: ESSConstants.GETBYID_REQUEST } }
    function success(response) { return { type: ESSConstants.GETBYID_REQUEST, response } }
    function failure(error) { return { type: ESSConstants.GETBYID_REQUEST, error } }
}

function getAllByUser(id, page, itemPerPage) {
    return dispatch => {
        dispatch(request());

        essService.getAllByUser(id, page, itemPerPage)
            .then(
                response => dispatch(success(response)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: ESSConstants.GETBYUSER_REQUEST } }
    function success(response) { return { type: ESSConstants.GETBYUSER_SUCCESS, response } }
    function failure(error) { return { type: ESSConstants.GETBYUSER_FAILURE, error } }
}

function create(essRequest) {
    return dispatch => {
        dispatch(request());

        essService.create(essRequest)
            .then(
                response => {
                    history.push("/employee-self-service");

                    const userDetails = JSON.parse(localStorage.getItem("user"));

                    dispatch(success(response));

                    setTimeout(() => {
                        UserToast("success", `${formatLeaveTypeText(essRequest)} Request Successful`);

                        dispatch(getActivitiesByUser(userDetails?.user_id));
                        dispatch(getAllByUser(userDetails?.user_id, 1, 10));

                    }, 1000);
                },
                error => {
                    dispatch(failure(error.toString()))
                    UserToast("error", `Insufficient credits`);
                    // UserToast("error", `${formatLeaveTypeText(essRequest)} Request Failed`);
                }
            );
    };

    function request() { return { type: ESSConstants.POST_REQUEST } }
    function success(response) { return { type: ESSConstants.POST_SUCCESS, response } }
    function failure(error) { return { type: ESSConstants.POST_FAILURE, error } }
}

function update(essRequest, redirectPath = "") {
    return dispatch => {
        dispatch(request());

        essService.update(essRequest)
            .then(
                response => {
                    dispatch(success(response));
                    MessageAfterSubmit("success", `${formatLeaveTypeText(essRequest)} Request updated!`);
                    if(redirectPath){
                        history.push(redirectPath);
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    MessageAfterSubmit("error", `${formatLeaveTypeText(essRequest)} Request update failed!`);
                }
            );
    };

    function request() { return { type: ESSConstants.UPDATE_REQUEST } }
    function success(response) { return { type: ESSConstants.UPDATE_REQUEST, response } }
    function failure(error) { return { type: ESSConstants.UPDATE_REQUEST, error } }
}

function approve(essRequest, redirectPath = "") {
    return dispatch => {
        dispatch(request());

        essService.update(essRequest, statusURLPath.approve)
            .then(
                response => {
                    dispatch(success(response));
                    MessageAfterSubmit("success", `${formatLeaveTypeText(essRequest)} Request Approved!`);
                    if(redirectPath){
                        history.push(redirectPath);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    MessageAfterSubmit("error", `${formatLeaveTypeText(essRequest)} Request Approved failed!`);
                }
            );
    };

    function request() { return { type: ESSConstants.UPDATE_REQUEST } }
    function success(response) { return { type: ESSConstants.UPDATE_REQUEST, response } }
    function failure(error) { return { type: ESSConstants.UPDATE_REQUEST, error } }
}

function approveTechSuppport(essRequest, redirectPath = "") {
    return dispatch => {
        dispatch(request());

        essService.update(essRequest, statusURLPath.approve)
            .then(
                response => {
                    dispatch(success(response));
                    MessageAfterSubmit("success", `${formatLeaveTypeText(essRequest)} Request Completed!`);
                    if(redirectPath){
                        history.push(redirectPath);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    MessageAfterSubmit("error", `${formatLeaveTypeText(essRequest)} Request Completed failed!`);
                }
            );
    };

    function request() { return { type: ESSConstants.UPDATE_REQUEST } }
    function success(response) { return { type: ESSConstants.UPDATE_REQUEST, response } }
    function failure(error) { return { type: ESSConstants.UPDATE_REQUEST, error } }
}

function hold(essRequest, redirectPath = "") {
    return dispatch => {
        dispatch(request());

        essService.update(essRequest, statusURLPath.onHold)
            .then(
                response => {
                    dispatch(success(response));
                    MessageAfterSubmit("success", `${formatLeaveTypeText(essRequest)} Request on Hold success!`);
                    if(redirectPath){
                        history.push(redirectPath);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    MessageAfterSubmit("error", `${formatLeaveTypeText(essRequest)} Request on Hold failed!`);
                }
            );
    };

    function request() { return { type: ESSConstants.UPDATE_REQUEST } }
    function success(response) { return { type: ESSConstants.UPDATE_REQUEST, response } }
    function failure(error) { return { type: ESSConstants.UPDATE_REQUEST, error } }
}

function holdTechSUpport(essRequest, redirectPath = "") {
    return dispatch => {
        dispatch(request());

        essService.update(essRequest, statusURLPath.onHold)
            .then(
                response => {
                    dispatch(success(response));
                    MessageAfterSubmit("success", `${formatLeaveTypeText(essRequest)} Request In Progress!`);
                    if(redirectPath){
                        history.push(redirectPath);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    MessageAfterSubmit("error", `${formatLeaveTypeText(essRequest)} Request In Progress failed!`);
                }
            );
    };

    function request() { return { type: ESSConstants.UPDATE_REQUEST } }
    function success(response) { return { type: ESSConstants.UPDATE_REQUEST, response } }
    function failure(error) { return { type: ESSConstants.UPDATE_REQUEST, error } }
}

function reject(essRequest, redirectPath = "") {
    return dispatch => {
        dispatch(request());

        essService.update(essRequest, statusURLPath.reject)
            .then(
                response => {
                    dispatch(success(response))
                    MessageAfterSubmit("success", `${formatLeaveTypeText(essRequest)} Request rejected!`);
                    if(redirectPath){
                        history.push(redirectPath);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    MessageAfterSubmit("error", `${formatLeaveTypeText(essRequest)} Request reject failed!`);
                }
            );
    };

    function request() { return { type: ESSConstants.UPDATE_REQUEST } }
    function success(response) { return { type: ESSConstants.UPDATE_REQUEST, response } }
    function failure(error) { return { type: ESSConstants.UPDATE_REQUEST, error } }
}

function getActivitiesByUser(userId) {
    return dispatch => {
        dispatch(request());

        essService.getActivitiesByUser(userId)
            .then(
                activities => dispatch(success(activities)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: ESSConstants.GETBYUSER_ACTIVITIES_REQUEST } }
    function success(activities) { return { type: ESSConstants.GETBYUSER_ACTIVITIES_SUCCESS, activities } }
    function failure(error) { return { type: ESSConstants.GETBYUSER_ACTIVITIES_FAILURE, error } }
}