import React from 'react';
import { Link } from 'react-router-dom';

import BodyBackgroundColor from 'react-body-backgroundcolor'

import MainNav from '../Components/MainNav';

import avatar from '../_templates/26731183_1548961821883748_5876949817012874527_n.jpg'

import M from 'materialize-css/dist/js/materialize.min.js';

import './Leaderboards.sass'

class LeaderboardsPage extends React.Component {
    componentDidMount() {

    var option = document.querySelector('.tabs'); 
    M.Tabs.init(option, {});

    }

    render() {
        return (
        	<BodyBackgroundColor backgroundColor='#effdfe'>
	          	<div className="leaderboards-page">
	          		<MainNav />
	          		<div className="tab-wrapper">
	          			<div className="container" id="content">
			          		<div className="row">
			          			<div className="col s12 m6 l7">
									<ul className="tabs">
								        <li className="tab col s3"><Link to ="#test1" className="active">Top Earners <span className="vertical-bar-1"> | </span> </Link></li>
										<li className="tab col s3"><Link to ="#test2">Top Levels <span className="vertical-bar-1"> | </span>  </Link></li>
										<li className="tab col s3"><Link to ="#test3">Top Trainees <span className="vertical-bar-1"> | </span> </Link></li>
								        <li className="tab col s3"><Link to ="#test4">Top Spenders</Link></li>
								    </ul>
								</div>
							</div>
						</div>
					</div>
	          		<div className="row">
	        			<div className="container">
		        			<div className="bg-leaderboards-page">
								<div className="row">
									<div id="test1" className="col s12">
										<h6 className="leaderboards"> <b> Leaderboards </b> <span className="vertical-bar"> | </span> <span className="tab-name">Top Earners </span></h6>
										<hr/>
										<table className="responsive-table highlight centered">
		                                    <thead>
		                                        <tr>
		                                        	<th></th>
		                                            <th><Link to="" className="sort-by">Hero</Link></th>
		                                            <th><Link to="" className="sort-by">Rank</Link></th>
		                                            <th><Link to="" className="sort-by">Credits</Link></th>
		                                        </tr>
		                                    </thead>
	                                       <tbody> 
	                                            <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                        </tbody>
	                                    </table>
									</div>

									<div id="test2" className="col s12">
										<h6 className="leaderboards"> <b> Leaderboards </b> <span className="vertical-bar"> | </span> <span className="tab-name">Top Levels </span></h6>
										<hr/>
										<table className="responsive-table highlight centered">
		                                    <thead>
		                                        <tr>
		                                        	<th></th>
		                                            <th><Link to="" className="sort-by">Hero</Link></th>
		                                            <th><Link to="" className="sort-by">Rank</Link></th>
		                                            <th><Link to="" className="sort-by">Credits</Link></th>
		                                        </tr>
		                                    </thead>
	                                       	<tbody> 
	                                            <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                        </tbody>
	                                    </table>
									</div>

								    <div id="test3" className="col s12">
								    	<h6 className="leaderboards"> <b> Leaderboards </b> <span className="vertical-bar"> | </span> <span className="tab-name">Top Trainees </span></h6>
										<hr/>
								    	<table className="responsive-table highlight centered">
		                                    <thead>
		                                        <tr>
		                                        	<th></th>
		                                            <th><Link to="" className="sort-by">Hero</Link></th>
		                                            <th><Link to="" className="sort-by">Rank</Link></th>
		                                            <th><Link to="" className="sort-by">Credits</Link></th>
		                                        </tr>
		                                    </thead>
	                                       	<tbody> 
	                                            <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                        </tbody>
	                                    </table>
								    </div>

								    <div id="test4" className="col s12">
								    	<h6 className="leaderboards"> <b> Leaderboards </b><span className="vertical-bar"> | </span> <span className="tab-name">Top Spenders </span></h6>
										<hr/>
										<table className="responsive-table highlight centered">
		                                    <thead>
		                                        <tr>
		                                        	<th></th>
		                                            <th><Link to="" className="sort-by">Hero</Link></th>
		                                            <th><Link to="" className="sort-by">Rank</Link></th>
		                                            <th><Link to="" className="sort-by">Credits</Link></th>
		                                        </tr>
		                                    </thead>
	                                       	<tbody> 
	                                           <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                             <tr>
		                                            <td width="10%" className="hero"><img src={avatar} alt="" className="avatar responsive-img"/></td>
		                                            <td width="20%" className="user-name">Heartlene Santiago</td>
		                                            <td className="rank">#1</td>
		                                            <td className="credits">5035</td>
		                                            <td></td>
	                                            </tr>
	                                        </tbody>
	                                    </table>
								    </div>
								</div>
								<div className="col s12 m12 l4">
	                                    <Link to="" className="waves-effect waves-light btn btn-view-all">View All</Link>
	                            </div>
		        			</div>
		        		</div>
		        	</div>
		        </div>
	        </BodyBackgroundColor>
        );
    }
}

export { LeaderboardsPage };
