import React from 'react';
import cc from 'classcat';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import { Link, Route } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { If, Then, Else } from 'react-if'
import { expActions } from '../_core/actions/exp';
import { userActions } from "../_core/actions/user";
import { sessionActions } from "../_core/actions/session";
import { roleService } from "../_core/services/role";
import { statsService } from '../_core/services/stats';

//import { UserDashboardContext } from "../UserDashboard/UserDashboard";

import avatar from '../_templates/man.png';
import imgNotFound from '../_templates/img-not-found.png';
//import briefcase from '../_templates/briefcase.png';
import notAtWork from '../_templates/workstatus-icons/notatwork.png';
import atWork from '../_templates/workstatus-icons/atwork.png';
import onVacation from '../_templates/workstatus-icons/onvacation.png';
import medical from '../_templates/workstatus-icons/medical.png';
import restDay from '../_templates/workstatus-icons/onrestday.png';
import holiday from '../_templates/workstatus-icons/onholiday.png';
//import award from '../_templates/award.svg';
//import heart from '../_templates/heart.svg';
import option from '../_templates/dot.png';
import './People.scss';

import { history } from "../_core/helpers/history";
import { hex } from "../_core/helpers/profile"
import moment from 'moment';
import HeaderTabsSearch from "../Components/HeaderTabsSearch";

const SkeletonPost = () => {
	const [transparent, setTransparent] = React.useState(true);

	React.useEffect(() => {
		setTimeout(() => {
			setTransparent(false);
		}, 100);
	}, [setTransparent]);

	return (
		new Array(2).fill(null).map((_, index) => (
			<div
				className={cc({
					'card people-user-card skeleton-post': true,
					transparent
				})}
				key={index}
			>
				<SkeletonTheme color="#e0e0e0">
					<Skeleton width="100%" height={130} />
					<div className="people-info">
						<div className="people-basic-info">
							<div className="people-name">
								<div>
									<Skeleton circle width={100} height={100} />
								</div>
								<div>
									<p style={{ paddingTop: "10px" }}><Skeleton height={15} width={200} /></p>
									<p style={{ paddingTop: "5px" }}><Skeleton height={15} width={150} /></p>
								</div>
							</div>
						</div>
						<div style={{ marginTop: "20px" }}>
							<Skeleton width="100%" height={50} />
						</div>
					</div>
				</SkeletonTheme>
			</div>
		))
	);
}

const UserCard = (props) => {
	const user = props.user;
	const active = props.active;

	const [currentStatus, setWorkStatus] = React.useState({});
	const [isLoading, setIsLoading] = React.useState(true);


	React.useEffect(() => {
		if (user._id) {
			try {
				statsService.getWorkStatus(user._id).then((res) => {
					setWorkStatus(res)
					setIsLoading(false)
				}).catch((error) => { })
			} catch (err) {
				console.log(err);
			}
		}
	}, [user]);

	const currentPragin = (currentStatus.data?.[0]?.prags?.filter((t,index) => index === 0)[0]) ?? {};
	const status = currentStatus.data !== undefined ? currentStatus.data?.[0]?.status : 'Not registered.'


	return (
		<div className="card people-user-card" onClick={() => { history.push("/profile/" + user._id) }}>
			<div className="people-cover">
				{user.details[0].cover
					? <img alt="" src={user.details[0].cover || imgNotFound} onError={(e) => { e.target.src = imgNotFound }} />
					: <div style={{ backgroundColor: hex(user.details[0].firstName + user.details[0].lastName), height: '100%' }}></div>
				}
			</div>
			<div className="people-info">
				<div className="people-basic-info">
					<div className="people-name">
						<div>
							{user.details[0].avatar
								? <img
									className="people-avatar"
									alt=""
									src={user.details[0].avatar || avatar}
									onError={(e) => { e.target.src = avatar }}
								/>
								: <div className="people-initials-avatar" style={{
									backgroundColor: hex(user.details[0].firstName + user.details[0].lastName)
								}}>{user.details[0].firstName.charAt(0).toUpperCase() + user.details[0].lastName.charAt(0).toUpperCase()}</div>
							}
							<div className={active ? "people-active active" : "people-active"}></div>
						</div>
						<div style={{ paddingLeft: "10px" }}>
							<p>{user.details[0].firstName || ""} {user.details[0].lastName || ""}</p>
							<p>{user.details[0].position || ""} {user.details[0].position && "|"} {user.details[0].department || ""}</p>
						</div>
					</div>
					<img alt="" src={option} />
				</div>
				<div className="people-credits">

					<If condition={isLoading === true}>
						<Then>
							<SkeletonTheme color="rgb(225, 225, 225)" highlightColor="rgb(240, 240, 240)">
								<Skeleton width={110} height={17} />
							</SkeletonTheme>
						</Then>
						<Else>
							<If condition={currentStatus.status === "Not at work."}>
								<Then>
									<div>
										<img alt="" src={notAtWork} />
										<p>Not at work</p>
									</div>
								</Then>
							</If>

							<If condition={currentStatus.status === "Not registered." || currentStatus.status === "No pragger username."}>
								<Then>
									<div>
										<img alt="" src={notAtWork} />
										<p>Not registered on Pragger</p>
									</div>
								</Then>
							</If>

							<If condition={status === "At work"}>
								<Then>
									<div>
										<img alt="" src={atWork} />
										<p>{currentPragin.pragOutAt !== null? "Went Off From Work At : "  : "At work Since : "} 
											<span>
												{ currentPragin.pragOutAt !== null ?
													moment(currentPragin.pragOutAt).format('hh:mm A') :
													moment(currentPragin.pragInAt).format('hh:mm A')
												}
											</span>
										</p>
									</div>
								</Then>
							</If>

							<If condition={currentStatus.status === "On Vacation Leave."}>
								<Then>
									<div>
										<img alt="" src={onVacation} />
										<p>On Vacation Leave</p>
									</div>
								</Then>
							</If>
							<If condition={currentStatus.status === "Medical Leave."}>
								<Then>
									<div>
										<img alt="" src={medical} />
										<p>On Medical Leave</p>
									</div>
								</Then>
							</If>
							<If condition={currentStatus.status === "On a Rest Day."}>
								<Then>
									<div>
										<img alt="" src={restDay} />
										<p>On a Rest Day</p>
									</div>
								</Then>
							</If>
							<If condition={currentStatus.status === "On Holiday."}>
								<Then>
									<div>
										<img alt="" src={holiday} />
										<p>On Holiday</p>
									</div>
								</Then>
							</If>
						</Else>
					</If>
					{/* <div>
						<img alt="" src={briefcase}/>
						<p>{currentStats.credits || 0}</p>
					</div>
					<div>
						<img alt="" src={award}/>
						<p>{currentStats.points || 0}</p>
					</div>
					<div>
						<img alt="" src={heart}/>
						<p>{currentStats.hearts || 0}</p>
					</div> */}
				</div>
			</div>
		</div>
	)
}

const Everyone = (props) => {
	const dispatch = useDispatch();
	const [recentPage, setRecentPage] = React.useState('');
	// const [itemPerPage, setItemPerPage] = React.useState(10);
	const [page, setPage] = React.useState(1);

	const { users } = props;
	const { active } = props;
	//const auth = props.authentication;
	const [filterRole, setFilterRole] = React.useState("");

	var userList = [];
	const itemPerPage = 10;
	var hasNextPage = false;
	const activeUsers = active && active.items && active.items.map((u) => {
		return u.user_id
	});

	if (users?.length > 0) {
		if (!recentPage) {
			userList = [...users.filter((u) => u.role !== filterRole).slice(0, itemPerPage * page)];
			hasNextPage = userList.length >= itemPerPage * page;
		} else {
			userList = [...users.filter((u) => u.role !== filterRole)];
			hasNextPage = false;
		}
	}

	React.useEffect(() => {
		document.addEventListener('scroll', trackScrolling);
		return () => document.removeEventListener('scroll', trackScrolling);
	});

	const trackScrolling = () => {
		if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - 1 && hasNextPage && !recentPage) {
			setTimeout(() => {
				setPage(page => page + 1);
			}, 500);
		}
	}

	// SEARCH FILTER
	const handleChange = e => {
		e.preventDefault();
		const searchVal = e.target?.value
		setFilter(searchVal);
		if (searchVal.length > 2) {
			if (!recentPage) {
				setRecentPage(page);
				document.removeEventListener('scroll', trackScrolling);
			}
		} else {
			if (recentPage) {
				setPage(recentPage);
				setRecentPage('');
				document.addEventListener('scroll', trackScrolling);
			}
		}
	}

	const [filter, setFilter] = React.useState('');
	const lowercasedFilter = filter.toLowerCase();
	const filteredData = userList.filter(item => {
		if (filter.length >= 3) {
			return Object.keys(item.details[0]).some(key =>
				typeof item.details[0][key] === "string"
				&& item.details[0][key].toLowerCase().includes(lowercasedFilter)
				&& item.details[0][key] !== item.details[0].avatar
				&& item.details[0][key] !== item.details[0].cover
				&& item.details[0][key] !== item.details[0].photo
				&& item.details[0][key] !== item.details[0].email
				&& item.details[0][key] !== item.details[0]._id
				&& item.details[0][key] !== item.details[0].user_id
				&& item.details[0][key] !== item.details[0].officeId
			);
		} else {
			return userList;
		}
	});

	//const device = React.useContext(UserDashboardContext).device;

	React.useEffect(() => {
		try {
			roleService.getAll(1, 999999).then(res => {
				if (res.items) {
					const user = JSON.parse(localStorage.getItem("user"));
					const applicantRole = res.items.filter(role => role.roleSlug === "applicant");
					const getRole = applicantRole.length > 0 ? applicantRole[0]._id : "";
					const roleFilter = getRole;
					setFilterRole(roleFilter);

					dispatch(expActions.getExp(user.user_id));
					dispatch(sessionActions.getAll());
					dispatch(userActions.getAllActiveUsers(1, 999999))
				}
			}).catch((err) => { console.log('get role error occured') })
		} catch (err) {
			console.log(err);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<HeaderTabsSearch
				currentPath={window.location.pathname}
				tabs={[
					{ name: "Everyone", path: "/people" },
					{ name: "Teams", path: "/people/teams" }
				]}
				searchValue={filter}
				searchOnChange={handleChange}
			/>
			{filteredData &&
				<div className="people-content">
					{filteredData && activeUsers && filteredData.map((user, index) =>
						<UserCard
							user={user}
							active={activeUsers.includes(user._id) ? true : false}
							key={index}
						/>
					)}
					{hasNextPage &&
						<SkeletonPost />
					}
				</div>
			}
		</React.Fragment>

	)
};

const mapStateToProps = (props) => ({
	exp: props.exps.exp?.[0]?.exp || 0,
	authentication: props.authentication,
	// loadingUsers: props.users.loading || false,
	// users: props.users?.users,
	users: props.users?.users && props.users.users.items,
	active: props.session,
});

export default React.memo(connect(mapStateToProps)(Everyone));
