import React from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect, useDispatch } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import { If, Else, Then,  } from 'react-if';
import styled from "styled-components";
import emptyIcon from '../../../_templates/empty.png';
import avatar from "../../../_templates/man.png";
import LinesEllipsis from 'react-lines-ellipsis';
import { history } from "../../../_core/helpers/history";
import Swal from 'sweetalert2'

import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent,DynamicTableAndPaginationWrapper, DynamicSearchWrapper } from "../../Components/PageWrapper/DynamicWrapper";
import { DynamicTableAction } from "../../Components/tableActions/tableActions";

import { challengeActions } from "../../../_core/actions/challenge";
import { statsService } from '../../../_core/services/stats';
import { energyActions } from '../../../_core/actions/energy';

import './Challenge.scss'
import CreateEditChallenge from './CreateEditChallenge';
import { challengeService } from "../../../_core/services/challenge";
import queryString from 'query-string';

const AdminChallengesPage = styled.div`
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4) {
        text-align: justify;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: justify;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const GetPosterAvatar = ({ data }) => {

    const listItems = data.map((posterDetails) =>  
        <>
            <img
            src={posterDetails?.avatar || avatar}
            onError={(e) => e.target.src = avatar}
            alt="poster"
            />
            <span>{`${posterDetails?.firstName} ${posterDetails?.lastName}`}</span>
        </>
    );  
    return (
        <div className="posterDetails">{listItems}</div>
    );
  }

  const Status = ({ data }) => {

    if (data === 1){
        return (
            <div className="statusDiv">
                <span className="statusPublish"/>
                <span>Publish</span>
            </div>
        );
    }else{
        return (
            <div className="statusDiv">
                <span className="statusUnpublish"/>
                <span>Unpublish</span>
            </div>
        );
    }

  }

const ChallengesPageList = (props) => {
  const dispatch = useDispatch(); //eslint-disable-line
  const [entriesValues, setentriesValues] = React.useState();
  const [quests, setQuests] = React.useState([]);
  const [search, setNewSearch] = React.useState("");
  const [keys, setKeys] = React.useState("");
  const [stats, setStats] = React.useState({});

  let tableHeader = ["User", "Title", "Description", "Content Status", "Challenge Status", "Actions"];
  let filterkeys = ["title", "description"];
  let localUserDetails = JSON.parse(localStorage.getItem("userCredentials"));

  let secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

  const UrlActivePage = React.useMemo(() => {
    return parseInt(queryString.parse(props.location.search).page);
  }, [props.location]);

    //show entries
    const entriesValue = (entries) => {
        setentriesValues(entries);
    }
    //search
    const handleSearchChange = (item) => {
        setNewSearch(item);
    };
        
    //filters
    const filterKeys = (item) => {
        setKeys(item);
    };

    React.useEffect(()=>{
        try{
            statsService.getOneUser(localUserDetails.user._id).then(res=>{
                setStats(res.success);
            }).catch(error=>{});
        }catch(err){
            console.log(err);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setStats, localUserDetails.user._id]);



  React.useEffect(()=>{
    setQuests(props.challenges.items);
  },[props.challenges])

  const editChallenge = (item) => {
    history.push(`/admin/challenges/${item}/edit`)
  }

  const deleteChallenge = (item) => {

    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    Swal.fire({
      title: "<p class='swal-title-engagement'>Confirmation</p>",
      html:
        " <div style='margin:30px'><p class='alert-first'>Do you want to delete this challenge?</p></div>",
  
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#2A3143",
        customClass: {
          header: 'header-class',
          container: 'container-class',
          popup: 'popup-class-engagament',
          actions: 'actions-class-engagament',
          cancelButton: 'cancel-button-class',
          confirmButton: 'confirm-btn-engagement',
          content: 'E-alert-conent',
        },
        reverseButtons:true,
        cancelButtonText: "LEAVE",
        confirmButtonText:"DELETE",
        background: "#2E3549",
    }).then((result) => {
        if (result.value) {
            dispatch(challengeActions.deleteChallenge(item))
            setTimeout(() => {
                ToastSuccess.fire({
                  type: 'success',
                  title: '<span style="color:#FFFFFF"> Challenge  has been deleted.</span>',
                });
                    dispatch(challengeActions.getAllAdmin(UrlActivePage,entriesValues,'',''))
              }, 200)
        }
    })
  }

  const publishLabel = (statusId, statusValue) => {

    if(statusValue === 1) {
      Swal.fire({
        title: '<p class="swal-subtitle-dark">Challenge already published</p>',
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#24DC98',
        cancelButtonColor: '#d33',
        background: '#2E3549',
      })
    }else {
  
      Swal.fire({
        title: '<p class="swal-subtitle-dark">Are you sure?</p>',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#24DC98',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, ${statusValue ? 'Unpublish' : 'Publish'} it!`,
        background: '#2E3549',
      }).then((result) => {
          if (result.value) {
            challengeService.publishChallenge(statusId).then((res) => { 
              const ToastSuccess = Swal.mixin({
                background: "#2a3042",
                toast: true,
                width: 300,
                position: 'center',
                showConfirmButton: false,
                timer: 1500
              });
          
              setTimeout(() => {
                ToastSuccess.fire({
                    type: 'success',
                    title: '<span style="color:#FFFFFF"> Challenge has been updated! </span>'
                });dispatch(challengeActions.getAllAdmin(UrlActivePage,entriesValues,'',''))
              }, 100)
          });
          }
      })
    }
  };

  const unpublishLabel = (statusId, statusValue) => {

    if(statusValue === 0) {
      Swal.fire({
        title: '<p class="swal-subtitle-dark">Challenge already unpublished</p>',
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#24DC98',
        cancelButtonColor: '#d33',
        background: '#2E3549',
      })
    }else {
      Swal.fire({
        title: '<p class="swal-subtitle-dark">Are you sure?</p>',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#24DC98',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, ${statusValue ? 'Unpublish' : 'Publish'} it!`,
        background: '#2E3549',
      }).then((result) => {
          if (result.value) {
            challengeService.unpublishChallenge(statusId).then((res) => { 
              const ToastSuccess = Swal.mixin({
                background: "#2a3042",
                toast: true,
                width: 300,
                position: 'center',
                showConfirmButton: false,
                timer: 1500
              });
          
              setTimeout(() => {
                ToastSuccess.fire({
                    type: 'success',
                    title: '<span style="color:#FFFFFF"> Challenge has been updated! </span>'
                });dispatch(challengeActions.getAllAdmin(UrlActivePage,entriesValues,'',''))
              }, 100)
          });
          }
      })
    }
  };

  const acceptChallenge = (data) => {

    const userEnergy = Array.isArray(stats) && stats.map(name => name.tips);
    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    if (localUserDetails.user._id === data.originalPoster){ //Check if login user is same with the original poster of challenge
        Swal.fire({
        title: "<p class='swal-title-engagement'>Message</p>",
        html:
            " <div style='margin:30px'><p class='alert-first'>You cannot accept your own challenge.</p></div>",
    
            showCancelButton: false,
            showCloseButton: true,
            confirmButtonColor: "#556ee6",
            cancelButtonColor: "#2A3143",
            customClass: {
            header: 'header-class',
            container: 'container-class',
            popup: 'popup-class-engagament',
            actions: 'actions-class-engagament',
            cancelButton: 'cancel-button-class',
            confirmButton: 'confirm-btn-engagement',
            content: 'E-alert-conent',
            },
            reverseButtons:true,
            cancelButtonText: "LEAVE",
            confirmButtonText:"OK",
            background: "#2E3549",
        })
    }else{

        if(data.eneryValue <= userEnergy[0]) {// check if challenger has sufficient balance of energy
            const energyInfo = {
                add: true,
                userId: localUserDetails.user._id,
                energyType: "challenge",
                value:  -data.eneryValue
            }

            Swal.fire({
            title: "<p class='swal-title-engagement'>Confirmation</p>",
            html:
                " <div style='margin:30px'><p class='alert-first'>Do you want to accept this challenge?</p></div>",
        
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#556ee6",
                cancelButtonColor: "#2A3143",
                customClass: {
                header: 'header-class',
                container: 'container-class',
                popup: 'popup-class-engagament',
                actions: 'actions-class-engagament',
                cancelButton: 'cancel-button-class',
                confirmButton: 'confirm-btn-engagement',
                content: 'E-alert-conent',
                },
                reverseButtons:true,
                cancelButtonText: "LEAVE",
                confirmButtonText:"UPDATE",
                background: "#2E3549",
            }).then((result) => {
                if (result.value) {
                    dispatch(challengeActions.acceptChallenge(data._id))
                    dispatch(energyActions.update(energyInfo));
                    ToastSuccess.fire({
                        type: 'success',
                        title: '<span style="color:#FFFFFF"> Challenge has been accepted.</span>',
                      });
                    setTimeout(() => {
                        dispatch(challengeActions.getAllAdmin(UrlActivePage,entriesValues,'',''))
                      }, 1000)
                }
            })
        }else{
            Swal.fire({
                title: "<p class='swal-title-engagement'>Insufficient Energy</p>",
                html:
                    " <div style='margin:30px'><p class='alert-first'>Required Energy: " + data.eneryValue + "</p></div>",
            
                    showCancelButton: false,
                    showCloseButton: true,
                    confirmButtonColor: "#556ee6",
                    cancelButtonColor: "#2A3143",
                    customClass: {
                    header: 'header-class',
                    container: 'container-class',
                    popup: 'popup-class-engagament',
                    actions: 'actions-class-engagament',
                    cancelButton: 'cancel-button-class',
                    confirmButton: 'confirm-btn-engagement',
                    content: 'E-alert-conent',
                    },
                    reverseButtons:true,
                    confirmButtonText:"OK",
                    background: "#2E3549",
                })
        }
        
    }
        
  }

  const completeChallenge = (data) => {
    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    Swal.fire({
      title: "<p class='swal-title-engagement'>Confirmation</p>",
      html:
        " <div style='margin:30px'><p class='alert-first'>Do you want to complete this challenge?</p></div>",
  
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#2A3143",
        customClass: {
          header: 'header-class',
          container: 'container-class',
          popup: 'popup-class-engagament',
          actions: 'actions-class-engagament',
          cancelButton: 'cancel-button-class',
          confirmButton: 'confirm-btn-engagement',
          content: 'E-alert-conent',
        },
        reverseButtons:true,
        cancelButtonText: "LEAVE",
        confirmButtonText:"UPDATE",
        background: "#2E3549",
    }).then((result) => {
        if (result.value) {
            let credit ={
                type:"available",
                value:data.credits,
                origin:"challenge"
            }
            dispatch(challengeActions.completeOneChallenge(data._id))
            challengeService.addCredit(data.userDetails[0]._id , credit)
            ToastSuccess.fire({
                type: 'success',
                title: '<span style="color:#FFFFFF"> Challenge has been completed.</span>',
              });
            setTimeout(() => {
                dispatch(challengeActions.getAllAdmin(UrlActivePage,entriesValues,'',''))
              }, 1000)
        }
    })
        
  }

  const finishChallenge = (data) => {
    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    if(localUserDetails.user._id === data.userDetails[0]._id){
        Swal.fire({
            title: "<p class='swal-title-engagement'>Confirmation</p>",
            html:
              " <div style='margin:30px'><p class='alert-first'>Do you want to finish this challenge?</p></div>",
        
              showCancelButton: true,
              showCloseButton: true,
              confirmButtonColor: "#556ee6",
              cancelButtonColor: "#2A3143",
              customClass: {
                header: 'header-class',
                container: 'container-class',
                popup: 'popup-class-engagament',
                actions: 'actions-class-engagament',
                cancelButton: 'cancel-button-class',
                confirmButton: 'confirm-btn-engagement',
                content: 'E-alert-conent',
              },
              reverseButtons:true,
              cancelButtonText: "LEAVE",
              confirmButtonText:"UPDATE",
              background: "#2E3549",
          }).then((result) => {
              if (result.value) {
                  dispatch(challengeActions.finishChallenge(data._id,''))
                  ToastSuccess.fire({
                      type: 'success',
                      title: '<span style="color:#FFFFFF"> Challenge has been finished.</span>',
                    });
                  setTimeout(() => {
                      dispatch(challengeActions.getAllAdmin(UrlActivePage,entriesValues,'',''))
                    }, 1000)
              }
          })
    }else{
        Swal.fire({
            title: "<p class='swal-title-engagement'>Message</p>",
            html:
                " <div style='margin:30px'><p class='alert-first'>You cannot finish your accepted challenge.</p></div>",
        
                showCancelButton: false,
                showCloseButton: true,
                confirmButtonColor: "#556ee6",
                cancelButtonColor: "#2A3143",
                customClass: {
                header: 'header-class',
                container: 'container-class',
                popup: 'popup-class-engagament',
                actions: 'actions-class-engagament',
                cancelButton: 'cancel-button-class',
                confirmButton: 'confirm-btn-engagement',
                content: 'E-alert-conent',
                },
                reverseButtons:true,
                cancelButtonText: "LEAVE",
                confirmButtonText:"OK",
                background: "#2E3549",
            })
    }
        
  }

  const ChallengeData = () => {
    let data = quests ?? [];
    
    return (
      <>
        <If condition={props.challenges.loading === false }> 
            <Then>
                {data.length !== 0 ?  (
                data.map((item, index) => (
                    <tr key={index}>
                    <td><GetPosterAvatar data={item.posterDetails}/></td>
                    <td style={{ width: "20%", textTransform:'capitalize'}}>{item.title}</td>
                    <td style={{ width: "30%" }}>  
                        <LinesEllipsis
                            text={item?.description}
                            maxLine='1'
                            ellipsis='...'
                            trimRight
                            basedOn='words'
                         />
                    </td>
                    <td><Status data={item.active}/></td>
                    <td style={{ textTransform:'capitalize'}}>{item.status}</td>
                    <td className={secretDetails !== null ? "disabled-link" : '' }>
                        <div>
                        <DynamicTableAction
                            originPage="Challenge"
                            iconSet="7"
                            publishLabel=  {(e) => { publishLabel(item._id, item.active);}}
                            unpublishLabel= {(e) => { unpublishLabel(item._id, item.active);}}
                            completeChallenge={item.status ==='finished'?(e) => { completeChallenge(item)}:''}
                            acceptChallenge={item.status ==='new'?(e) => { acceptChallenge(item)}:''}
                            finishChallenge={item.status ==='accepted'?(e) => { finishChallenge(item)}:''}
                            editLabel="Edit Challenge"
                            deleteLabel="Delete Challenge"
                            onClickEdit={() => editChallenge(item._id)}
                            onClickDelete={() => deleteChallenge(item._id)}
                            deletePermission="Engagement - Challenges"
                            editPermission="Engagement - Challenges"
                        />
                        </div>
                    </td>
                    </tr>
                ))
                ) :
                <tr>
                    <td colSpan="12" style={{textAlign:'center'}}>
                        <img src={emptyIcon} className="empty" alt="empty"/>
                        <p style={{fontSize:'16px'}}>No Data Available</p>
                        <p style={{fontSize:'14px'}}>There are currently no data available</p>
                    </td>
                </tr>
                }
            </Then>
            <Else>
              <tr className="table-td-data">
                <td> 
                  <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                      <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                </td>
              </tr>
            </Else>
        </If>
      </>
    );
  };

  return (
    <AdminChallengesPage>

    <DynamicSearchWrapper
        buttonLabel="ADD CHALLENGE"
        btnPath="/admin/challenges/create"
        entriesValue={entriesValue}
        data={props.challenges.items}
        location={props.location}
        searchs={handleSearchChange}
        filters={filterKeys}
        paginationPath={"/admin/challenges"}
        keys={filterkeys}
        originPage="Challenge"
        addPage="Engagement - Challenges"

      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={ChallengeData()}
        originPage="Challenge"
        data={props.challenges}
        location={props.location}
        paginationPath={"/admin/challenges"}
        entriesItem={entriesValues}
        loading = {props.challenges.loading}
        search={search}
        filter={keys}
      />
    </AdminChallengesPage>
  );
};

const mapStateToProps = ({challenges }) => ({
  challenges
});

const ChallengesPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Challenges", link: "/admin/challenges?page=1" },
        ]);
        break;
        case "create":
            setBreadCrumbs([
              { label: "Challenges", link: "/admin/challenges?page=1" },
              { label: "Create Challenge", link: "#" },
            ]);
            break;
        case "edit":
            setBreadCrumbs([
              { label: "Challenges", link: "/admin/challenges?page=1" },
              { label: "Edit Challenge", link: "#" },
            ]);
            break;
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Challenge | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Engagement | Challenges | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : `Edit Challenge | ${process.env.REACT_APP_DOCUMENT_TITLE}`
        }
      >
        <PageWrapper className={cc(["ChallengesPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Engagement"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <ChallengesPageList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={['create', 'edit'].includes(props.mode)}>
              <CreateEditChallenge {...props } />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});

ChallengesPage.WrappedComponent.displayName = "ChallengesPage";
const ChallengePageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <ChallengesPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <ChallengesPage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <ChallengesPage {...props} mode="edit" />}
    />

  </Switch>
);
export default React.memo(ChallengePageRoute);
