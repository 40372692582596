import evaluationServices from "../services/evaluation";
import evaluationConstants from "../constants/evaluation";
import { history } from "../helpers/history";
import { MessageAfterSubmit } from "../components/Alert";

function getAll(page, items) {
    return dispatch => {
        dispatch(request(page, items));
        evaluationServices.getAll(page, items)
            .then(
                evaluations => {
                    dispatch(success(evaluations));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request(page, items) { return { type: evaluationConstants.GET_REQUEST, page, items }}
    function success(evaluations) { return { type: evaluationConstants.GET_SUCCESS, evaluations } }
    function failure(error) { return { type: evaluationConstants.GET_FAILURE, error} }
}

function create(evaluation, id) {
    return dispatch => {
        dispatch(request());
        evaluationServices.create(evaluation, id)
            .then(
                evaluation => {
                    dispatch(success(evaluation));
                    MessageAfterSubmit("success", evaluation.success);
                    history.push(`/admin/evaluations?page=1`);
                },
                error => {
                    MessageAfterSubmit("error", error.toString());
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request() { return { type: evaluationConstants.CREATE_REQUEST }}
    function success(evaluation) { return { type: evaluationConstants.CREATE_SUCCESS, evaluation } }
    function failure(error) { return { type: evaluationConstants.CREATE_FAILURE, error} }
}

function update(item, id) {
    return dispatch => {
        dispatch(request());
        evaluationServices.update(item, id)
            .then(
                evaluation => {
                    dispatch(success(evaluation));
                    MessageAfterSubmit("success", evaluation.success);
                    history.goBack();
                },
                error => {
                    MessageAfterSubmit("error", error.toString());
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request() { return { type: evaluationConstants.UPDATE_REQUEST }}
    function success(evaluation) { return { type: evaluationConstants.UPDATE_SUCCESS, evaluation } }
    function failure(error) { return { type: evaluationConstants.UPDATE_FAILURE, error} }
}

function removeInList (id, page, itemsPerPage) {
    return dispatch => {
        dispatch(request());
        evaluationServices.remove(id)
            .then(
                evaluation => {
                    dispatch(success(evaluation));
                    MessageAfterSubmit("success", evaluation.success);
                    dispatch(getAll(page, itemsPerPage));
                },
                error => {
                    dispatch(failure(error.toString()));
                    MessageAfterSubmit("error", error.toString());
                }
            );
    };   

    function request() { return { type: evaluationConstants.DELETE_REQUEST }}
    function success(evaluation) { return { type: evaluationConstants.DELETE_SUCCESS, evaluation } }
    function failure(error) { return { type: evaluationConstants.DELETE_FAILURE, error} }
}

function getById(id) {
    return dispatch => {
        dispatch(request());
        evaluationServices.getById(id)
            .then(
                evaluation => {
                    dispatch(success(evaluation));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request() { return { type: evaluationConstants.GETBYID_REQUEST }}
    function success(evaluation) { return { type: evaluationConstants.GETBYID_SUCCESS, evaluation } }
    function failure(error) { return { type: evaluationConstants.GETBYID_FAILURE, error} }
}

function getLastEvaluation(id) {
    return dispatch => {
        dispatch(request());
        evaluationServices.getLastEvaluationById(id)
            .then(
                evaluation => {
                    dispatch(success(evaluation));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request() { return { type: evaluationConstants.GET_LAST_EVALUATION_REQUEST }}
    function success(evaluation) { return { type: evaluationConstants.GET_LAST_EVALUATION_SUCCESS, evaluation } }
    function failure(error) { return { type: evaluationConstants.GET_LAST_EVALUATION_FAILURE, error} }
}

function updateStatus(status, id, page, itemsPerPage, userId = "") {
    return dispatch => {
        dispatch(request());
        evaluationServices.updateStatus(status, id)
            .then(
                evaluation => {
                    dispatch(success(evaluation));
                    MessageAfterSubmit("success", evaluation.message);
                    
                    if(status ==="decline") {
                        if(userId) {
                            dispatch(getAllByPeers(page, itemsPerPage, userId))
                            history.goBack();
                        } else {
                            dispatch(getAll(page, itemsPerPage));
                            history.goBack();
                        }
                    } else {
                        if(userId) {
                            dispatch(getAllByPeers(page, itemsPerPage, userId))
                        } else {
                            dispatch(getAll(page, itemsPerPage));
                        }
                    }
                 
                },
                error => {
                    MessageAfterSubmit("error", error.toString());
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request() { return { type: evaluationConstants.UPDATE_REQUEST }}
    function success(evaluation) { return { type: evaluationConstants.UPDATE_SUCCESS, evaluation } }
    function failure(error) { return { type: evaluationConstants.UPDATE_FAILURE, error} }
}

function getAllByUser(page, items, subjectId) {
    return dispatch => {
        dispatch(request(page, items));
        evaluationServices.getAllByUser(page, items, subjectId)
            .then(
                evaluations => {
                    dispatch(success(evaluations));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request(page, items) { return { type: evaluationConstants.GET_USER_REQUEST, page, items }}
    function success(evaluations) { return { type: evaluationConstants.GET_USER_SUCCESS, evaluations } }
    function failure(error) { return { type: evaluationConstants.GET_USER_FAILURE, error} }
}

function getAllByPeers(page, items, userId) {
    return dispatch => {
        dispatch(request(page, items));
        evaluationServices.getAllByPeers(page, items, userId)
            .then(
                evaluations => {
                    dispatch(success(evaluations));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request(page, items) { return { type: evaluationConstants.GET_PEER_REQUEST, page, items }}
    function success(evaluations) { return { type: evaluationConstants.GET_PEER_SUCCESS, evaluations } }
    function failure(error) { return { type: evaluationConstants.GET_PEER_FAILURE, error} }
}

export default {
    getAll,
    create,
    update,
    removeInList,
    getById,
    getLastEvaluation,
    updateStatus,
    getAllByUser,
    getAllByPeers,
}