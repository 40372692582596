import React from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import{ userService } from '../_core/services/user';

import password from '../_templates/Email.png'

import styled from "styled-components";
import "./NewChangePass.scss";

import VerifyChangePass from "./VerifyChangePass";

const ChangePassDiv = styled.div`
    margin:25px;
    font-family:Arial, Helvetica, sans-serif;
`;

const Div1 = styled.div`
    display: grid;
    label {
        font-weight:700;
        font-size:21px;
        color:#333333
    }
    span {
        font-weight:400;
        font-size:14px;
        color:#333333
    }
`;
const Div2 = styled.div`
    border:1px solid #E4E4E4;
    width:651px;
    height:294px;
    box-shadow:0 2px 5px 0px rgb(0 0 0 / 9%);
    margin:auto;
    margin-top:50px;
    text-align: center;
    padding: 50px 70px 10px 70px;

    .email-logo {
        display:inline-grid;
        object-fit: cover;
        margin-bottom:25px;
        img{
            width: 232.5px;
            height: 97.45px; 
        }
        label{
            font-size:16px;
            color:#333333;
            font-weight:700;
            margin-top: 10px;
        }
    
    }
    .email-message {
        span{
            font-weight:400;
            color:#333333;
            font-size:14px;
        }
    }

`;


const mapStateToProps = ({user}) =>({
    user
});

const NewChangePass = connect(mapStateToProps)((props) => {
    const userDetails = JSON.parse(localStorage.getItem("userCredentials"));

    console.log(userDetails.user._id)

    React.useEffect(() => {
        userService.getToken(userDetails.user._id)
        //eslint-disable-next-line
    }, []);

    return (
        <ChangePassDiv>
            <Div1>
                <label>Settings</label>
                <span>All Requests</span>
            </Div1>
            <Div2>
                <div className='email-logo'>
                    <img src={password} className="changepassword" alt="password"/>
                    <label>Change Password</label>
                </div>
                <div className='email-message'>
                    <span>We have sent a change password request to your email address on record. Please check and follow further instructions.</span>
                </div>               
            </Div2>

        </ChangePassDiv>
    );
});


const NewChangePassRoute = (props) => (
	<Switch>
		<Route exact path={props.match.path} component={NewChangePass} />
		<Route exact path={`${props.match.path}/:token`} component={VerifyChangePass} />
	</Switch>
);

export default React.memo(NewChangePassRoute);