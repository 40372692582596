//npm packages
import React from 'react';
import orderBy from 'lodash/orderBy';
import { connect, useDispatch } from 'react-redux';
//actions
import { challengeActions } from '../_core/actions/challenge';
import { challengeService } from '../_core/services/challenge';
import  CreateChallengeModal from "../ChallengeWidget/CreateChallengeModal";
import  ChallengeVerify from "../ChallengeWidget/ChallengeVerify";
import  ChallengeCompleted from "../ChallengeWidget/ChallengeCompleted";
import  ChallengeFailed from "../ChallengeWidget/ChallengeFailed";

//styles
import Table from '../Table/Table';
import '../ChallengeWidget/ChallengeWidget.sass'
import moment from 'moment';
//image
import clock from '../_templates/clock_icon.png';
import filter from '../_templates/filter.png';
import avatar from '../_templates/man.png'


var now = moment().utc();

const formatRequest = (requests = []) => (
    
    requests.map(request => {
        const isAccepted = request.status.toLowerCase() === 'accepted' ? true : false;
        const acceptedDate = moment(request.acceptedAt).isValid() ? moment(request.acceptedAt).utc() : false;
        const getTimeLimitString = request.timeLimit !== '00:00:00' || !request.timeLimit ? request.timeLimit : false;

        const setEndDate = () => { //get accepted date + time limit
            var getTimer;
            if(getTimeLimitString){
                if(isAccepted && acceptedDate){
                    const hms_split = getTimeLimitString.split(':');
                    const getTimePerSec = (+hms_split[0]) * 60 * 60 + (+hms_split[1]) * 60 + (+hms_split[2]);
                    const computedEndTime = moment(acceptedDate).add(getTimePerSec, 'seconds');
                    getTimer = computedEndTime;
                }else{
                    getTimer = getTimeLimitString;
                }
            }else{
                getTimer = false;
            }
            return getTimer
        }
        var isSetEndDate = setEndDate();
        const formattedRequest = {
            title: {
                _id: 'title',
                element: <p>{request.title}</p>,
                value: request.title
            },
            dates: {
                _id: '_id',
                element: (
                    <div style={{
                        display: 'flex',
                        alignContent: 'center'
                    }}>
                        <img height={20} src={clock} alt="" style={{ marginRight: 8 }}/>
                        <p>
                            {
                                [
                                    moment(request.startsAt).utc().format('MM/DD/YYYY'),
                                    moment(request.expiresAt).utc().format('MM/DD/YYYY')
                                ].join(' - ')
                            }
                        </p>
                    </div>
                ),
                value: moment(request.startsAt).utc().format('MM/DD/YYYY').toString()
            },
            userDetails: {
                _id: 'userDetails',
                element: (
                    <div style={{ display: 'flex', padding:'0px 20px' }}>

                        {
                            Object.keys(request.userDetails[0]).length === 0 ? 
                                <></>
                            : 
                            <img className="challenger-avatar" onError={(e)=> e.target.src = avatar} src={request.userDetails[0].avatar || avatar} alt="..." />
                        }
                        
                    </div>
                ),
                value: request.userDetails
            },
            status: {
                _id: 'status',
                element: (
                    <div className ="challengeStatusBtn" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                        <button className={ moment(now).isSameOrAfter(moment(isSetEndDate)) ? "failed": request.status }>
                            <p style={{ textTransform: 'capitalize', fontWeight: 700 }}>
                                { moment(now).isSameOrAfter(moment(isSetEndDate)) ?
                                    "failed" 
                                    : request.status === 'new'?
                                    "pending" 
                                    :request.status
                                }
                            </p>
                        </button>

                    </div>
                ),
                value: request.status
            }
        };

        return {
            _id: request._id,
            cells: Object.values(formattedRequest)
        };
    }) || []
);


const mapStateToProps = ({ challenges }) => ({
	loading: challenges.loading ?? true,
	challenges: challenges.items ?? {},
    totalPerPage:  challenges.totalPerPage,
    perPage: challenges.perPage,
    currentPage: challenges.currentPage
});

const myChallenges =  connect(mapStateToProps)((props) => {
    const [ challengesUser, setChallengesUser ] = React.useState({});
    const [ challengesItems, setChallengesItems ] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [ itemsPerPage, setItemsPerPage ] = React.useState(10);
    const [ update, setUpdate ] = React.useState(false);
    const [setUpdateLoading ] = React.useState(false);// eslint-disable-lin
    const [showAppModal, setAppModal] = React.useState(false);// eslint-disable-lin
    const [showFinishModal, setFinishModal] = React.useState(false);// eslint-disable-lin
    const [showCompletedModal, setCompletedModal] = React.useState(false);// eslint-disable-lin
    const [showFailedModal, setFailedModal] = React.useState(false);// eslint-disable-lin
    const [ChallengeId, setChallengeId] = React.useState();

    const dispatch = useDispatch();

    const secret = JSON.parse(localStorage.getItem("secretDetails"));
    
    
    React.useEffect(() => {
		dispatch(challengeActions.getAll(1, 100000));
    }, [dispatch]);
    

    React.useEffect(() => {
        challengeService.getAllFilter(page, itemsPerPage).then((res) => {
            setChallengesUser(res);
            setUpdate(false)
          }).catch((error) => {
            throw error;
          });
         //eslint-disable-next-line
    }, [page,itemsPerPage,update]);

    React.useEffect(() => {
        setChallengesItems(formatRequest(
            challengesUser.items,
        ));
    }, [challengesUser]);


    const sort = React.useCallback((index, order) => {

        setChallengesItems(challengesItems => {
            const orders = ['asc', 'desc'];
            return (
                orderBy(challengesItems, (challengesItems) => (
                    challengesItems.cells[index].value
                ), [orders[order]])
            );
        })
    }, [setChallengesItems]);

    const headers = [
        {name: "", withOrder: false},
        {name: "Period", withOrder: true},
        {name: "Challenger", withOrder: true},
        {name: "Status", withOrder: true},
    ];

    const handleChallenge = () => {
        setAppModal(true);
    }
    const handleCloseModal =()=> {
        setAppModal(false)
        setFinishModal(false);
        setCompletedModal(false);
        setFailedModal(false);
        setChallengeId()

    }
    const modalLoading =(load)=> {
        setUpdateLoading(load)
    }


    const setEndDate = (request) => { //get accepted date + time limit
        const isAccepted = request.status.toLowerCase() === 'accepted' ? true : false;
        const acceptedDate = moment(request.acceptedAt).isValid() ? moment(request.acceptedAt).utc() : false;
        const getTimeLimitString = request.timeLimit !== '00:00:00' || !request.timeLimit ? request.timeLimit : false;
        var getTimer;
        if(getTimeLimitString){
            if(isAccepted && acceptedDate){
                const hms_split = getTimeLimitString.split(':');
                const getTimePerSec = (+hms_split[0]) * 60 * 60 + (+hms_split[1]) * 60 + (+hms_split[2]);
                const computedEndTime = moment(acceptedDate).add(getTimePerSec, 'seconds');
                getTimer = computedEndTime;
            }else{
                getTimer = getTimeLimitString;
            }
        }else{
            getTimer = false;
        }
        return getTimer
    }


    const viewRequest = React.useCallback(id => {
        if(id) {
            try{


                challengeService.getOne(id).then(res=>{
                    if(res.success[0].status === 'finished') {
                            setFinishModal(true);
                            setChallengeId(id)
                    }else if(res.success[0].status === 'completed'){
                        setCompletedModal(true);
                        setChallengeId(id)
                    }else if(res.success[0].status === 'accepted' || res.success[0].status === 'failed'){
                        var isSetEndDate = setEndDate(res.success[0]);
                        if(moment(now).isSameOrAfter(moment(isSetEndDate))){
                            setFailedModal(true)
                            setChallengeId(id)
                        }
                    }
                }).catch(error=>{console.log(error)});
            }catch(err){
                console.log(err);
            }  
        }
    }, [setFinishModal,setChallengeId,setCompletedModal,setFailedModal]);

    return (
        <div className="challenges" style={{maxWidth:'-webkit-fill-available'}}>

            <div className="card z-depth-0">
                <div className="widget-header" style={{display:'flex'}}>
                    <h6>Created Challenges by You</h6>
                        <div className="requests-widget-controls">
                            <button className="filter-requests">
                                <img src={filter} alt="filter"/>
                            </button>
                            {secret ?
                                <button className="add-new-request" style={{opacity:'0.7', pointerEvents:'none'}}>
                                    CREATE CHALLENGE
                                </button>
                            :
                                <button className="add-new-request" onClick={handleChallenge}>
                                    CREATE CHALLENGE
                                </button>
                            }
                        </div>
                </div>

                <Table
                    headers={headers}
                    itemsPerPage={itemsPerPage}
                    loading={update}
                    goToPage={page => { setPage(page); setUpdate(true) }}
                    noDataComponent={
                        <>
                        <p className="no-data-helper">Create a new challenge by clicking on the Create Challenge below</p>
                                        
                        </>
                    }
                    onHeaderClick={sort}
                    onRowClick={viewRequest}
                    page={challengesUser.page}
                    pages={challengesUser.pages || 1}
                    rows={challengesItems}
                    setItemsPerPage={setItemsPerPage}
                    columns={4}
                />

                <CreateChallengeModal
                    isOpen={showAppModal} 
                    onRequestClose={() => setAppModal(false)} 
                    toggle={setAppModal}
                    loading={modalLoading}
                    handleCloseModal={handleCloseModal}
                />
                <ChallengeVerify
                    isOpen={showFinishModal} 
                    onRequestClose={() => setFinishModal(false)} 
                    toggle={setFinishModal}
                    handleCloseModal={handleCloseModal}
                    challengeId={ChallengeId}
                    //challengeTitle={challenge.title}
                    updateModal={() => setUpdate(true)}
                />
                <ChallengeCompleted
                    isOpen={showCompletedModal} 
                    onRequestClose={() => setCompletedModal(false)} 
                    toggle={setCompletedModal}
                    handleCloseModal={handleCloseModal}
                    challengeId={ChallengeId}
                    //challengeTitle={challenge.title}
                    updateModal={() => setUpdate(true)}
                />

                <ChallengeFailed
                    isOpen={showFailedModal} 
                    onRequestClose={() => setFailedModal(false)} 
                    toggle={setFailedModal}
                    handleCloseModal={handleCloseModal}
                    challengeId={ChallengeId}
                    //challengeTitle={challenge.title}
                    updateModal={() => setUpdate(true)}
                />


            </div>

            {/* <CreateChallengeModal
                isOpen={showAppModal} 
                onRequestClose={() => setAppModal(false)} 
                toggle={setAppModal}
                loading={modalLoading}
                handleCloseModal={handleCloseModal}
            /> */}
        </div>
    
    )
})

export {myChallenges}