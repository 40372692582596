import React, { useEffect } from 'react'
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
//import BodyBackgroundColor from 'react-body-backgroundcolor';
import "./UpdateCOEDark.scss";
import { coeActions } from '../../../_core/actions/coe';
import { essActions } from '../../../_core/actions/ess';
import { userService } from "../../../_core/services/user";
import { history } from '../../../_core/helpers/history';
import moment from 'moment';
//import ReactImageFallback from "react-image-fallback";
import avatar from '../../../_templates/man.png';
import Modal from 'react-modal';
//import { notifActions } from '../../../_core/actions/notification';
import { alertActions } from '../../../_core/actions/alert';
import { requestStatus } from "../../../_core/helpers/requestStatus";
import { ConfirmationModal } from "../../Components/AdminModals/RequestPopup";
import fileIcon from '../../../_templates/dark/file_blank_outline.png';
import download from '../../../_templates/dark/download.png';
import close from '../../../_templates/dark/off_outline_close.png';
import FileViewer from 'react-file-viewer';

import PreviewLeaveUserWidget from "../../AdminWidget/PreviewUserDetailsWidget/PreviewLeaveUserWidget";
import { commentActions } from '../../../_core/actions/comment';
import { commentService } from '../../../_core/services/comment';
import styled, { css } from "styled-components";

import { When } from "react-if";

import {
    ContentWrapper,
  } from "../../Components/PageWrapper/ContentWrapper";

const customStyles = {
    content: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }
  };

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

const mapStateToProps = ({ coes,comments }) => ({
    coes,
    comments
});

const UpdateCOERequest = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    const [coeUserRequest, setcoeUserRequest] = React.useState([]);
    const [count, setRender] = React.useState(true);
    const countRef = React.useRef(count);
    countRef.current = count;
    const [showFilePreview, setFilePreview] = React.useState(false);
    const [FileUrl, setFileUrl] = React.useState('');
    const [FileType, setFileType] = React.useState('');

    const [userManager, setManager] = React.useState([]);
    const [showRemarkModal, setRemarkModal] = React.useState(false);
    let approverDetails = {
        remarks: "",
        notedBy: "",
        notedAt: "",
        status: "",
    };
    const [approversNote, setApproversNote] = React.useState(approverDetails);
    const [comment, setCommment] = React.useState([]);
    const [reply, setReply] = React.useState([]);
    const [isDisplayUserDetails, setIsDisplayUserDetails] = React.useState(false); // eslint-disable-line


    // let oid = props.match.params.id;
    // let admin = JSON.parse(localStorage.getItem('userCredentials'));
    let params = useParams();
    const { requestId, page } = params;
    let admin = JSON.parse(localStorage.getItem("userCredentials"));

    //Loading comments every 5 seconds
    // const MINUTE_MS = 5000;

    // const waitAndDoSomething = () => {
    //     dispatch(commentActions.getByOrigin(requestId, 1, 999));
    
    //   setTimeout(() => {
    //     console.log('It is now rounded 5 seconds');
    //     dispatch(commentActions.getByOrigin(requestId, 1, 999));
    //     waitAndDoSomething();
    //   }, MINUTE_MS);
      
    // }
    
    useEffect(() => {
        // let oid = props.match.params.id
        dispatch(coeActions.getById(requestId));
        setIsDisplayUserDetails(true);
        //waitAndDoSomething();
        dispatch(commentActions.getByOrigin(requestId, 1, 999));
        alertTimeOut();
        // eslint-disable-next-line
    }, [dispatch])


    useEffect(() => {
        const { coes } = props || {};
        const coeUser = (coes.coe && coes.coe.success) || [];
        setcoeUserRequest(coeUser)
    }, [props])

    useEffect(() => {
        commentService.getByOrigin(requestId, 1, 999).then(res=>{
            if(res.items?.length !== 0){
                setCommment(res.items)
                commentService.getByOrigin(res.items[0]?._id, 1, 999).then(res=>{
                    if(res.items){
    
                        if(res.items.length !== 0){
                            setReply(res.items)
                        }
    
                    }
                }).catch(error=>{console.log(error)});
    
            }
        }).catch(error=>{console.log(error)});
      }, [props, requestId]);

    const alertTimeOut = () => {
        setTimeout(() => {
            setRender(countRef.current);
            props.dispatch(alertActions.clear());
        }, 3000);
    };

    const goBack = () => {
        history.goBack();
    }

    const goBackPath = `/admin/coe-requests?page=${page}`;

    const openFilePreview = () => {
        setFilePreview(true);
    };

    const handleSubmit = (id) => {
        
        const coeRequest = coeUserRequest[0];

        const requestUser = [
            coeRequest.users[0].firstName,
            coeRequest.users[0].lastName,
          ].join(" ");
          const referenceCode = coeRequest.refCode;
          const requestDates = [coeRequest.startedAt, coeRequest.endedAt];
          const requestRemarks = approversNote.remarks;
      
        ConfirmationModal( requestUser, referenceCode, requestDates, requestRemarks, "Remarks" ).then((result) => {
            if(result.value) {
                let updateRequest = {
                _id: id,
                subject: coeRequest.subject || "Certificate of Employment",
                type: coeRequest.type,
                subtype: coeRequest.subtype,
                description: coeRequest.description,
                };
            
                switch (approversNote.status) {
                case requestStatus.onHold:
                    updateRequest = {
                    ...updateRequest,
                    status: approversNote.status,
                    notedAt: approversNote.notedAt,
                    notedBy: approversNote.notedBy,
                    notedRemark: approversNote.remarks,
                    };
                    break;
                case requestStatus.approved:
                    updateRequest = {
                    ...updateRequest,
                    status: approversNote.status,
                    approvedAt: approversNote.notedAt,
                    approvedBy: approversNote.notedBy,
                    approvalRemark: approversNote.remarks,
                    };
                    break;
                case requestStatus.rejected:
                    updateRequest = {
                    ...updateRequest,
                    status: approversNote.status,
                    rejectedAt: approversNote.notedAt,
                    rejectedBy: approversNote.notedBy,
                    rejectedRemark: approversNote.remarks,
                    };
                    break;
                default:
                    break;
                }
            
                // const updateNotif = {
                // type: "admin",
                // title: `COE Request ${
                //     approversNote.status === requestStatus.new
                //     ? "Updated"
                //     : approversNote.status.charAt(0).toUpperCase() +
                //         approversNote.status.slice(1, approversNote.status.length)
                // }`,
                // content: `Your COE request was ${
                //     approversNote.status === requestStatus.new
                //     ? "updated"
                //     : approversNote.status
                // }.`,
                // user_id: coeRequest.user_id,
                // recurring: "true",
                // };
            
                if (updateRequest.status === requestStatus.approved) {
                    dispatch(essActions.approve(updateRequest, goBackPath));
                } else if (updateRequest.status === requestStatus.onHold) {
                    dispatch(essActions.hold(updateRequest, goBackPath));
                } else if (updateRequest.status === requestStatus.rejected) {
                    dispatch(essActions.reject(updateRequest, goBackPath));
                }
            
                setApproversNote(approverDetails);
                setRemarkModal(false);
                // setConfirmModal(false);
                setRender(false);
               // dispatch(notifActions.createNotif(updateNotif));
            }
        });
      };

    const onRemarksChange = (e) => {
        let admin = JSON.parse(localStorage.getItem("userCredentials"));
        setApproversNote({
            ...approversNote,
            [e.target.name]: e.target.value,
            notedAt: moment().format(),
            notedBy: admin.user._id,
        });
    };

    const openRemarkModal = (status) => {
        setApproversNote({
            ...approverDetails,
            status: status,
        });
        setRemarkModal(true);
    };

    const ApproverDetails = ({ details, status, date, manager }) => {
        if ( userManager.length === 0) {
            const getManager = async () => {
                try {
                    const user = await userService.getById(manager);
                    setManager(user.user[0].details);
                } catch (error) {
                    console.error(error);
                }
            };
            getManager();
        }
        if ( userManager.length !== 0) {
            return (
                <div>
                    <div className="request-approver-content">
                        <div>
                            <p>Approver : </p>
                            <div>
                                <img
                                src={userManager[0]?.avatar}
                                alt="approver-img"
                                onError={(e) => (e.target.src = avatar)}
                                />
                                <p>
                                { [userManager[0]?.firstName, userManager[0]?.lastName].join(" ")}
                                </p>
                            </div>
                        </div>
                        <div>
                            <p>Role : Manager</p>
                            {/* <p>{`Date of ${
                            status === requestStatus.rejected
                                ? "Rejection"
                                : status === requestStatus.onHold
                                ? "Hold"
                                : "Approval"
                            } : ${moment(date).format("MMMM DD YYYY")}`}</p> */}
                        </div>
                    </div>

                    {coeUserRequest[0]?.noter_details?.length !== 0 ? (
                        <div>
                            <div className="request-approver-content">
                                <div>
                                    <p>Held by : </p>
                                    {
                                        coeUserRequest[0].noter_details?.map(detail => (
                                            <div key={detail._id}>
                                                <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                                                <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div>
                                    <p>{`Date put on hold :  ${moment(coeUserRequest[0]?.notedAt).format("MMMM DD YYYY")}`}</p>
                                </div>
                            </div>
                            <div className="floating-label">
                                <textarea
                                className="floating-input floating-textarea hr-textarea"
                                value={coeUserRequest[0]?.notedRemark}
                                name="description"
                                cols="30"
                                placeholder=" "
                                style={{ resize: "none" }}
                                row="2"
                                readOnly
                                disabled
                                />
                                <label className="floating-text">Remarks</label>
                            </div>
                        </div>
                    ) : ''}

                    {coeUserRequest[0]?.approver_details?.length !== 0 ? (
                        <div>
                            <div className="request-approver-content">
                                <div>
                                    <p>Approved by : </p>
                                    {
                                        coeUserRequest[0].approver_details?.map(detail => (
                                            <div key={detail._id}>
                                                <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                                                <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                    <div>
                                        <p>{`Date of Approval : ${moment(coeUserRequest[0]?.approvedAt).format("MMMM DD YYYY")}`}</p>
                                    </div>
                            </div>
                            <div className="floating-label">
                                <textarea
                                className="floating-input floating-textarea hr-textarea"
                                value={coeUserRequest[0]?.approvalRemark}
                                name="description"
                                cols="30"
                                placeholder=" "
                                style={{ resize: "none" }}
                                row="2"
                                readOnly
                                disabled
                                />
                                <label className="floating-text">Remarks</label>
                            </div>
                        </div>
                    ) : ''}

                    {coeUserRequest[0]?.rejecter_details?.length !== 0 ? (
                        <div>
                            <div className="request-approver-content">
                                <div>
                                    <p>Rejected by : </p>
                                    {
                                        coeUserRequest[0].rejecter_details?.map(detail => (
                                            <div>
                                                <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                                                <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                    <div>
                                        <p>{`Date rejected : ${moment(coeUserRequest[0]?.rejectedAt).format("MMMM DD YYYY")}`}</p>
                                    </div>
                            </div>
                            <div className="floating-label">
                                <textarea
                                className="floating-input floating-textarea hr-textarea"
                                value={coeUserRequest[0]?.rejectedRemark}
                                name="description"
                                cols="30"
                                placeholder=" "
                                style={{ resize: "none" }}
                                row="2"
                                readOnly
                                disabled
                                />
                                <label className="floating-text">Remarks</label>
                            </div>
                        </div>
                    ) : ''}
                </div>
            );
        } else {
            return <React.Fragment />;
        }
    };

    return (
    <CustomContentWrapper
        displayUserWrapper={isDisplayUserDetails}
        style={{ gridTemplateColumns: "70% 27%" }}
      >
        {/* <BodyBackgroundColor backgroundColor='#1C2233'> */}
        <ContentWrapper className="update_coe">
                <div className="table-card">
                    <div>
                        {props.coes.loading && <em style={{ color: "#9BA3CA" }}>loading...</em>}
                    </div>
                    <div>
                        {coeUserRequest.map((coeRequest, index) => (
                            <React.Fragment key={index}>
                                <div className="row">
                                    <div className="col s4 p-0" style={{ width: "100%" }}>
                                        <div className="row">
                                            <div className="table-header">
                                                <span className="sub-header">Review COE Application</span>
                                            </div>

                                            {/* {coeRequest.notedRemark && coeRequest.status !== "new" ?
                                                <div className="col s12 m12 l12 remark-dark">
                                                    <p className="info" style={{ color: "#B1822D", marginTop: "0", marginLeft: "10px" }}>On hold remark : <span style={{ color: "#9BA3CA", fontWeight: "normal", textTransform: "lowercase" }}>{coeRequest.notedRemark}</span></p>
                                                </div>
                                                : ''}
                                            {coeRequest.rejectedRemark ?
                                                <div className="col s12 m12 l12 remark-dark">
                                                    <p className="info" style={{ color: "#AE555C", marginTop: "0", marginLeft: "10px" }}>Rejection remark : <span style={{ color: "#9BA3CA", fontWeight: "normal", textTransform: "lowercase" }}>{coeRequest.rejectedRemark}</span></p>
                                                </div>
                                                : ''}
                                            {coeRequest.approvalRemark ?
                                                <div className="col s12 m12 l12 remark-dark">
                                                    <p className="info" style={{ color: "#2B7A69", marginTop: "0", marginLeft: "10px" }}>Approval remark : <span style={{ color: "#9BA3CA", fontWeight: "normal", textTransform: "lowercase" }}>{coeRequest.approvalRemark}</span></p>
                                                </div>
                                                : ''} */}
                                            <div className="col s12 m12 l12 remark-dark">
                                            <div className="col l3 m3 mobile-grid">
                                                <p className="coe-info-dark">
                                                    Application Status :
                                                    <span className={coeRequest.status ? coeRequest.status === requestStatus ? "onHold" : coeRequest.status: ""}>{' '}
                                                        {coeRequest.status ? coeRequest.status === requestStatus.new ? "In review" : coeRequest.status : "No Status"}
                                                    </span>
                                                </p>
                                            </div>

                                            <div className="col l6 m6 mobile-grid">
                                                <p className="info">Date Filed :  <span className="dateInfo">{moment(coeRequest.createdAt).format('dddd, MMMM DD YYYY')}</span></p>
                                            </div>
                                            </div>
                                        </div>
                                        <div >
                                            {coeRequest.status === "approved" || coeRequest.status === "rejected" ?
                                                <div className="floating-label">
                                                    <textarea
                                                        className="floating-input floating-textarea coe-reason"
                                                        value={coeRequest.description}
                                                        name="description"
                                                        readOnly
                                                        cols="30"
                                                        placeholder=" "
                                                        style={{ resize: 'none' }}
                                                        row="10">
                                                    </textarea>
                                                    <label className="floating-text">Reason</label>
                                                </div>
                                                :
                                                <div className="floating-label">
                                                    <textarea
                                                        className="floating-input floating-textarea coe-reason"
                                                        value={coeRequest.description ? coeRequest.description : coeRequest.description}
                                                        name="description"
                                                        readOnly
                                                        cols="30"
                                                        placeholder=" "
                                                        style={{ resize: 'none' }}
                                                        row="10">
                                                        {coeRequest.description ? coeRequest.description : coeRequest.description}
                                                    </textarea>
                                                    <label className="floating-text">Reason</label>
                                                </div>
                                            }

                                        </div>

                                        <div className="div-attach" style={{marginBottom:'20px'}}>
                                            <p className="info" style={{ marginTop: "0" }}>
                                                Attachments
                                            </p>

                                            {coeUserRequest[0].files?.length !== 0 || coeUserRequest[0].files === 'undefined' || coeUserRequest[0].files === '' || coeUserRequest[0].files === null ? (
                                                coeUserRequest[0].files?.map((c) => (
                                                    // <p>{c.split('-').pop()}</p>
                                                    <div key={c} className="attach-div">
                                                        <div className="attach-preview">
                                                            <img
                                                                src={c}
                                                                className="attach-file"
                                                                alt="Thumb"
                                                                onError={(e) => (e.target.src = fileIcon) }
                                                            />
                                                                <p onClick={() => { openFilePreview(); setFileUrl(c); setFileType(c.split('.').pop()) }} style={{cursor:'pointer'}}>{c.split('/').pop()}</p>
                                                        </div>
                                                        <a href={c} download>
                                                            <button className="btn-download" style={{background:'none'}} >
                                                                <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'contrast(0.5)' }}/>
                                                            </button>
                                                        </a>
                                                    </div>
                                                ))
                                            ) : (
                                            <>
                                                <div className="attach-div">
                                                    <div className="attach-preview">
                                                        <p>No File Attached</p>
                                                    </div>
                                                </div>
                                            </>
                                            )}
                                        </div>

                                        <div>
                                            <Modal
                                                isOpen={showFilePreview}
                                                ariaHideApp={true}
                                                toggle={setFilePreview}
                                                onRequestClose={() => setFilePreview(false)}
                                                shouldCloseOnOverlayClick={true}
                                                className="fileModalPreview"
                                                style={customStyles}
                                            >
                                                <div
                                                className="row center rejecting-container"
                                                style={{
                                                    width: "auto",
                                                    height: "auto",
                                                    margin: "auto",
                                                    padding: "50px",
                                                }}
                                                >
                                                <div className="gAction">
                                                    <a href={FileUrl} download>
                                                        <button className="btn-download" style={{background:'none'}}>
                                                            <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'brightness(5.5)' }}/>
                                                        </button>
                                                    </a>
                                                    <img height={20} onClick={() => setFilePreview(false)} src={close} alt="" style={{ margin:'15px 7px', filter: 'brightness(5.5)', cursor:'pointer' }}/>
                                                </div>
                                                <div style={{ height: '100%' }}>
                                                <FileViewer 
                                                    fileType={FileType} 
                                                    filePath={FileUrl} 
                                                    key= {FileUrl}
                                                />
                                                </div>

                                                </div>
                                            </Modal>
                                        </div>
                                        
                                        <ApproverDetails
                                            details={
                                                coeRequest.status === requestStatus.approved ||
                                                coeRequest.status === requestStatus.completed
                                                ? coeRequest.approver_details
                                                : coeRequest.status === requestStatus.onHold
                                                ? coeRequest.noter_details
                                                : coeRequest.status === requestStatus.rejected
                                                ? coeRequest.rejecter_details
                                                : []
                                            }
                                            manager={coeRequest?.users ? coeRequest.users[0]?.manager : ""}
                                            status={coeRequest.status}
                                            date={
                                                coeRequest.status === requestStatus.approved ||
                                                coeRequest.status === requestStatus.completed
                                                ? coeRequest.approvedAt
                                                : coeRequest.status === requestStatus.onHold
                                                ? coeRequest.notedAt
                                                : coeRequest.status === requestStatus.rejected
                                                ? coeRequest.rejectedAt
                                                : ""
                                            }
                                        />
                                        {/* {coeRequest.status !== requestStatus.new && (
                                        <div className="floating-label">
                                            <textarea
                                            className="floating-input floating-textarea hr-textarea"
                                            value={
                                                coeRequest.status === requestStatus.approved ||
                                                coeRequest.status === requestStatus.completed
                                                ? coeRequest.approvalRemark
                                                : coeRequest.status === requestStatus.onHold
                                                ? coeRequest.notedRemark
                                                : coeRequest.status === requestStatus.rejected
                                                ? coeRequest.rejectedRemark
                                                : ""
                                            }
                                            name="description"
                                            cols="30"
                                            placeholder=" "
                                            style={{ resize: "none" }}
                                            row="2"
                                            readOnly
                                            />
                                            <label className="floating-text">Remarks</label>
                                        </div>
                                        )} */}
                                        <div className="row coe-buttons">
                                            <div className="col s1 m8 l8 buttonOne" style={{ width: '50%', padding: "0 0" }}>
                                                {coeRequest.status === "on hold" || coeRequest.status === "new" || coeRequest.status === "on hold" ?
                                                    <span>
                                                        {coeRequest.user_id !== admin.user._id ?
                                                            <span>
                                                                <button className="btn coe-btn-approve-dark" onClick={() => openRemarkModal(requestStatus.approved)} >Approve</button>
                                                                <button className="btn coe-btn-hold-dark" onClick={() => openRemarkModal(requestStatus.onHold)} >Hold</button>
                                                                <button className="btn coe-btn-reject-dark" onClick={() => openRemarkModal(requestStatus.rejected)} >Reject</button>
                                                            </span>
                                                            : ''}
                                                    </span>
                                                    : ''}
                                            </div>
                                            <div className="col s1 m4 l4 buttonTwo" style={{ width: '50%', padding: '0 0', float: "right" }}>
                                                <span className="right"><button className="btn coe-btn-cancel-dark" onClick={goBack}>BACK</button></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <Modal
                                            isOpen={showRemarkModal}
                                            ariaHideApp={true}
                                            toggle={setRemarkModal}
                                            onRequestClose={() => setRemarkModal(false)}
                                            shouldCloseOnOverlayClick={true}
                                            className="AnnouncementModalBG"
                                            >
                                            <div
                                                className="row center rejecting-container"
                                                style={{
                                                width: "auto",
                                                height: "auto",
                                                margin: "auto",
                                                padding: "50px",
                                                }}
                                            >
                                                <p className="coe-info left">
                                                {`Please state your reason for ${
                                                    approversNote.status === requestStatus.approved
                                                    ? "approving"
                                                    : approversNote.status === requestStatus.onHold
                                                    ? "on hold"
                                                    : approversNote.status === requestStatus.rejected
                                                    ? "rejecting"
                                                    : ""
                                                } :`}
                                                </p>
                                                <br />

                                                <div className="col s12 m12 l12">
                                                <div className="floating-label">
                                                    <textarea
                                                    className="floating-input floating-textarea hr-textarea rejecting"
                                                    value={approversNote.remarks}
                                                    name="remarks"
                                                    // cols="30"
                                                    placeholder=" "
                                                    style={{ resize: "none" }}
                                                    onChange={onRemarksChange}
                                                    row="10"
                                                    ></textarea>
                                                    <label className="floating-text">Remarks</label>
                                                </div>
                                                </div>
                                                <div className="col s10 m10 l10 coe-buttons">
                                                <span className="right-dark">
                                                    <button
                                                    className="btn coe-btn-cancel-dark"
                                                    onClick={() => setRemarkModal(false)}
                                                    >
                                                    Cancel{" "}
                                                    </button>
                                                    <button
                                                    className={cc([
                                                        "btn",
                                                        approversNote.status === requestStatus.approved
                                                        ? "coe-btn-approve-dark"
                                                        : approversNote.status === requestStatus.onHold
                                                        ? "coe-btn-hold-dark"
                                                        : approversNote.status === requestStatus.rejected
                                                        ? "btn coe-btn-reject-dark"
                                                        : "",
                                                    ])}
                                                    onClick={() => {
                                                        approversNote.remarks && handleSubmit(coeRequest._id);
                                                    }}
                                                    >
                                                    {approversNote.status === requestStatus.approved
                                                        ? "Approve"
                                                        : approversNote.status === requestStatus.onHold
                                                        ? "Hold"
                                                        : approversNote.status === requestStatus.rejected
                                                        ? "Reject"
                                                        : ""}
                                                    </button>
                                                </span>
                                                </div>
                                            </div>
                                            </Modal>
                                    </div>

                                    {/* <div className="col s4">

                                        <div className="row table-two" id="zero">
                                            <div className="col s12 m11 l1">
                                                <ReactImageFallback
                                                    src={coeRequest.users[0].avatar}
                                                    fallbackImage={avatar}
                                                    initialImage={avatar}
                                                    alt="user avatar"
                                                    className="coe-user-photo-dark circle responsive-img"
                                                />
                                            </div>

                                        </div>
                                        <div>
                                            <p className="info-dark">{coeRequest.users[0].firstName} {coeRequest.users[0].lastName}</p>
                                            <p className="ot-detail">{coeRequest.users[0].position}</p>
                                        </div>
                                        <div>
                                            <p className="coe-info-header">Info</p>

                                            <p className="coe-info-header">Contact Number</p>
                                            <span className="ot-detail">{coeRequest.users[0].contactNumber ? coeRequest.users[0].contactNumber : 'None'}</span>

                                            <p className="coe-info-header">Address</p>
                                            <span className="ot-detail">
                                                {coeRequest.users[0].address1 ? coeRequest.users[0].address1 + ',' : ''}
                                                {coeRequest.users[0].address2 ? coeRequest.users[0].address2 + ',' : ''}
                                                {coeRequest.users[0].city ? coeRequest.users[0].city + ',' : ''}
                                                {coeRequest.users[0].country ? coeRequest.users[0].country : ''}
                                            </span>
                                        </div>
                                    </div> */}

                                </div>

                            </React.Fragment>
                        ))}
                    </div>
                </div>

            </ContentWrapper>
            <When condition={isDisplayUserDetails === true}>
                <PreviewLeaveUserWidget
                    {...props}
                    subjectId={coeUserRequest[0]?.users}
                    availableCredits="false"
                    comments ={comment}
                    replies = {reply}
                    request = {coeUserRequest[0]}
                    isReply={Object.keys(comment).length !== 0}
                />
            </When>
        </CustomContentWrapper>
    )
});

export default UpdateCOERequest ;