import React from 'react';
import MainNav from '../Components/MainNav';
import HeaderPhoto from '../Components/HeaderPhoto';
import DetailPage from '../Components/DetailPage';
import StatusPage from '../Components/StatusPage';
import UserTab from '../Components/UserTab';
import { connect } from 'react-redux';
import StepZilla from "react-stepzilla";
import { alertActions } from '../_core/actions/alert';
import { userActions } from '../_core/actions/user';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import './UserEditorProfile.sass';
import moment from 'moment';

class UserEditorProfile extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {};
    //get logged in user
    let admin = JSON.parse(localStorage.getItem('user'));
    let adminCred = JSON.parse(localStorage.getItem('userCredentials'));
    this.state = {
      render: false
    }
    this.sampleStore = {
      _id: adminCred.user._id,
      email: adminCred.user.email,
      team: adminCred.user.team, //logged in user's team
      organization: adminCred.user.organization,
      officeId: admin.officeId ? admin.officeId : '',
      bio: admin.bio ? admin.bio : '',
      firstName: admin.firstName ? admin.firstName : '',
      middleName: admin.middleName ? admin.middleName : '',
      lastName: admin.lastName ? admin.lastName : '',
      title: admin.title ? admin.title : '',
      suffix: admin.suffix ? admin.suffix : '',
      address1: admin.address1 ? admin.address1 : '',
      address2: admin.address2 ? admin.address1 : '',
      city: admin.city ? admin.address1 : '',
      state: admin.state ? admin.state : '',
      country: admin.country ? admin.country : '',
      nationality: admin.nationality ? admin.nationality : '',
      mothersMaidenName: admin.mothersMaidenName ? admin.mothersMaidenName : '',
      maritalStatus: admin.maritalStatus ? admin.maritalStatus : '',
      gender: admin.gender ? admin.gender : '',
      birthDate: moment(admin.birthDate).utc().format() === 'Invalid date' ? '' : moment(admin.birthDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      birthPlace: admin.birthPlace ? admin.birthPlace : '',
      startDate: moment(admin.startDate).utc().format() === 'Invalid date' ? '' :  moment(admin.startDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      endDate: moment(admin.endDate).utc().format() === 'Invalid date' ? '' : moment(admin.endDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      regularizationDate: moment(admin.regularizationDate).utc().format() === 'Invalid date' ? '' : moment(admin.regularizationDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      position: admin.position ? admin.position : '',
      department: admin.department ? admin.department : '',
      level: admin.level ? admin.level : '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount(){
    let adminCred = JSON.parse(localStorage.getItem('userCredentials'));
    this.props.dispatch(userActions.getUser(adminCred.user._id))
  }

  handleClose(event) {
    event.preventDefault()

    this.setState({ render: false });
    this.props.dispatch(alertActions.clear());
  }

  getStore() {
    return this.sampleStore;
  }

  updateStore(update) {
    const {users} = this.props;

    this.sampleStore = {
      ...this.sampleStore,
      ...update,
      avatar: users.user && users.user.user[0].details[0].avatar ? users.user && users.user.user[0].details[0].avatar : this.sampleStore.avatar,
      cover: users.user && users.user.user[0].details[0].cover ? users.user && users.user.user[0].details[0].cover : this.sampleStore.cover,
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.sampleStore;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }
  render() {
    const { render } = this.state;
    const { alert } = this.props;
    const steps =
      [
        { name: 'User Profile', component: <Step1 getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
        { name: 'Personal Information', component: <Step2 getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
        { name: 'Employment Details', component: <Step4 getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
        { name: 'User Credentials', component: <Step3 getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },

      ]

    return (
      <div className="profile">
        <MainNav />
        
        <HeaderPhoto />

        <UserTab />

        <div className="container-userdetail">

          <div className="col s12 m4">
            <div className="row">
              <div className="col s12 m3">
                <DetailPage />
              </div>
              <div className="col s12 m9 l6">
                <div className="BodyCon">
                  <div className="userDetailCon">
                    <div className="row"> 

                      <div className="col s12 m12 l12">
                          
                        {/* content */}
                        <div className="user-editor-page">
                          <div className="center-align">
                            {alert.message && render &&
                              <div id="alert" className={`alert ${alert.type} no-border`} toggle={this.onDismiss}>{alert.message}<span className="closebtn right" onClick={this.handleClose}>&times;</span></div>
                            }
                            <div className='step-progress'>
                              <StepZilla
                                steps={steps}
                                showSteps={false}
                                showNavigation={false}
                              />
                            </div>
                          </div>
                        </div>
                        {/* end of content */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col s12 m3">
                <StatusPage />
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export { UserEditorProfile };
function mapStateToProps(state) {
  const { alert, users } = state;
  return {
    alert, users
  };
}
const connectedUserEditorProfile = connect(mapStateToProps)(UserEditorProfile);
export { connectedUserEditorProfile as UserEditorProfile };