import {  post, get, put } from '../helpers/api';
import { userService } from '../services/user';

export const hrConcernService = {
    submitHRConcern,
    getAll,
    reject,
    hold,
    approve,
    getById,
    update

};

function update(hrConcerns) {
    let id = hrConcerns._id;
    delete hrConcerns._id
    return put(`/api/v1/request/${id}`, JSON.stringify(hrConcerns)).then(handleResponse)
}

function getById(id) {
    return get (`/api/v1/request/${id}`).then(handleResponse)
}

function createActivity(activity,id) {
    return post(`/api/v1/user/${id}/activity`, JSON.stringify(activity)).then(handleResponse);
}

function approve(hrConcerns) {
    let id = hrConcerns._id;
    delete hrConcerns.id
    return put(`/api/v1/request/${id}/approve`, JSON.stringify(hrConcerns))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });
};

function reject(hrConcerns) {
    let id = hrConcerns._id;
    delete hrConcerns.id
    return put(`/api/v1/request/${id}/reject`, JSON.stringify(hrConcerns))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });
};

function hold(hrConcerns) {
    let id = hrConcerns._id;
    delete hrConcerns.id
    return put(`/api/v1/request/${id}/hold`, JSON.stringify(hrConcerns))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });
};

function submitHRConcern(hrConcern){
    return post('/api/v1/request', JSON.stringify(hrConcern))
        .then(handleResponse)
        .then(response => {
            getById(response._id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });
}

function getAll(page, itemsPerPage) {
    return get(`/api/v1/request?page=${page}&items-per-page=${itemsPerPage}&filter=subtype:hr%20concern`).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}
