import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Link, Route, Switch } from 'react-router-dom';
import { If, Else, Then, When, Switch as SwitchCase, Case, Default } from 'react-if';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import slugify from 'slugify';
import sweetAlert from 'sweetalert2';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import Pagination from 'react-js-pagination';
import { DynamicHeaderContent } from "../Components/PageWrapper/DynamicWrapper";
import pageManagementActions from '../../_core/actions/pageManagement';
import pageManagementServices from '../../_core/services/pageManagement';
import { PageWrapper } from "../Components/PageWrapper/ContentWrapper";
import { checkSideNav } from '../Components/checkSideMenu';
import Cookies from 'universal-cookie';
// import { setPagination, assignPage } from '../Pagination';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './PageManagementPage.scss';
// import { CircularProgress } from '@material-ui/core';

import emptyIcon from '../../_templates/empty.png';

const toast = sweetAlert.mixin({
	toast: true,
	width: 300,
	position: 'top-end',
	showConfirmButton: false,
	timer: 1500
});

const mapStateToProps = ({ pageManagement }) => ({
    pages: pageManagement
});

const CreateEditPage = (props) => {
	const dispatch = useDispatch();
	const featuredImageSelectorRef = React.useRef();
	const [editorState, setEditorState] = React.useState(() => {
		return EditorState.createEmpty();
	});
	const [featuredImage, setFeaturedImage] = React.useState('');
	const isEditorStateEmpty = React.useMemo(() => !editorState.getCurrentContent().hasText(), [editorState]);
	const [isFeaturedImageDataUrl, setIsFeaturedImageDataUrl] = React.useState(true);
	const [isSubmitted, setIsSubmitted] = React.useState(false);
	const [originalPageTitle, setOriginalPageTitle] = React.useState('');
	const [status, setStatus] = React.useState('published');
	const [title, setTitle] = React.useState('');

	
	React.useEffect(
		() => {
			if(
				props.mode !== 'edit' ||
				isEmpty(props.match.params.pageId)
			) {
				return;
			}

			const getById = async () => {
				let result;
				try {
					result = (await pageManagementServices.getById(props.match.params.pageId)).success[0];
				} catch (error) {
					toast.fire({
						type: 'error',
						title: `
							<span>Invalid link</span>
						`
					});

					dispatch(pageManagementActions.getAll(props.location.state.currentPage ?? 1)).then(() => {
						if (props.location.state?.from) {
							props.history.goBack();
						} else {
							props.history.push(`/admin/page-management?page=${props.location.state.currentPage ?? 1}`);
						}
					});

					return;
				}

				try {
					setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(result.content))));
				} catch (error) {

				}

				setFeaturedImage(result.featuredImage ?? '');
				setIsFeaturedImageDataUrl(isEmpty(result.featuredImage) ? true : false);
				setOriginalPageTitle(result.title);
				setStatus(result.status);
				setTitle(result.title);
			};

			getById();
		},
		[
			dispatch,
			props.history,
			props.location,
			props.match,
			props.mode,
			setEditorState,
			setFeaturedImage,
			setIsFeaturedImageDataUrl,
			setOriginalPageTitle,
			setStatus,
			setTitle,
		]
	);

	const cancel = React.useCallback((event) => {

		if(props.location.state === undefined) {
			props.history.push(`/admin/page-management?page=1`);
		} else {
			props.history.push(`/admin/page-management?page=${props.location.state.currentPage}`);
		}
		//eslint-disable-next-line
	}, [props.history, props.location]);

	const onSelectFeaturedImage = React.useCallback((event) => {
		event.preventDefault();

		featuredImageSelectorRef.current.click();
	}, [featuredImageSelectorRef]);

	const selectFeaturedImage = React.useCallback((event) => {
		const featuredImage = event.target.files[0];

		if(featuredImage === undefined){
			return;
		}

		const reader = new FileReader();

		reader.addEventListener('load', () => {
			setFeaturedImage(reader.result)
			setIsFeaturedImageDataUrl(true);
		});
		reader.readAsDataURL(featuredImage);

		featuredImageSelectorRef.current.value = '';
	}, [featuredImageSelectorRef, setFeaturedImage, setIsFeaturedImageDataUrl]);

	const submit = React.useCallback(
		(event) => {
			event.preventDefault();
			setIsSubmitted(true);

			if(
				title === '' ||
				isEditorStateEmpty
			){
				return;
			}

			const submit = async () => {
				let pageId;

				try {
					const page = {
						content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
						featuredImage,
						slug: slugify(title, { lower: true }),
						status,
						title,
					};

					switch (props.mode) {
						case 'create':
							pageId = (await dispatch(pageManagementActions.create(page)))._id;
							break;
						case 'edit':
							await dispatch(pageManagementActions.edit(page, props.match.params.pageId));
							break;
						default:
							break;
					}
				} catch(error) {

				}

				if(isEmpty(featuredImage) === false && isFeaturedImageDataUrl){
					const formData = new FormData();

					try {
						formData.append('file', await (await fetch(featuredImage)).blob());

						await dispatch(pageManagementActions.uploadFeaturedImage(formData, props.match.params?.pageId ?? pageId));
					} catch (error) {

					}
				}

				dispatch(pageManagementActions.getAll(props.location.state.currentPage ?? 1)).then(() => {
					if (props.location.state?.from) {
						props.history.goBack();
					} else {
						if(props.location.state === undefined) {
							props.history.push(`/admin/page-management?page=1`);
						} else {
							props.history.push(`/admin/page-management?page=${props.location.state.currentPage}`);
						}
					}
				});

				toast.fire({
					type: 'success',
					title: `
						<span>
							${originalPageTitle || title} has been successfully ${props.mode === 'create' ? 'created' : 'edited'}!
						</span>
					`
				});
			}

			submit();
		},
		// eslint-disable-next-line
		[
			dispatch,
			editorState,
			featuredImage,
			isEditorStateEmpty,
			isFeaturedImageDataUrl,
			originalPageTitle,
			props.history,
			props.location,
			props.match,
			props.mode,
			setIsSubmitted,
			status,
			title,
		]
	); 	 

	return (
		<DocumentTitle
			title={
				props.mode === 'create' ?
				`Create new page | ${process.env.REACT_APP_DOCUMENT_TITLE}` :
				`Edit ${originalPageTitle || 'page'} | ${process.env.REACT_APP_DOCUMENT_TITLE}`
			}
		>
			<form onSubmit={submit}>
				<input
					accept="image/*"
					id="logo"
					multiple={false}
					onChange={selectFeaturedImage}
					ref={featuredImageSelectorRef}
					style={{ display: 'none' }}
					type="file"
				/>
				<label htmlFor="page_title">
					Title *
					<When condition={isSubmitted && isEmpty(title)}>
						<span className="helper-text red-text" style={{ marginLeft: 10 }}>
							This field is required
						</span>
					</When>
				</label>
				<input
					id="page_title"
					name="page_title"
					onChange={(event) => { setTitle(event.target.value) }}
					type="text"
					value={title}
				/>
				<label htmlFor="editor">
					Content *
					<When condition={isSubmitted && isEditorStateEmpty}>
						<span className="helper-text red-text" style={{ marginLeft: 10 }}>
							Content is required
						</span>
					</When>
				</label>
				<Editor
					{...{editorState }}
					name="editor"
					onEditorStateChange={setEditorState}
					toolbar={{
						options: [
							'inline',
							'blockType',
							'fontSize',
							'fontFamily',
							'list',
							'textAlign',
							'colorPicker',
							'link',
							'embedded',
							'emoji',
							'remove',
							'history'
						]
					}}
					wrapperClassName="editor-wrapper"
				/>
				<label htmlFor="feature_image">
					Featured image
				</label>
				<div
					className={cc([
						'file-upload',
						{
							'solid': featuredImage !== ''
						}
					])}
				>
					<If condition={featuredImage === ''}>
						<Then>
							<p>
								<a
									href="#select-featured-image"
									onClick={onSelectFeaturedImage}
								>
									Select
								</a>
								<span> featured image</span>
							</p>
						</Then>
						<Else>
							<img
								alt="featured"
								src={featuredImage}
								style={{ width: '100%', objectFit: 'cover' }}
							/>
							<div className="action">
								<button className= "page-action  blue-default btn-max-width" onClick={onSelectFeaturedImage}>CHANGE FEATURED IMAGE</button>
								<button
									className= "page-action page-delete btn-max-width"
									onClick={(event) => {
										event.preventDefault();
										setFeaturedImage('')
									}}
								>
									REMOVE
								</button>
							</div>
						</Else>
					</If>
				</div>
				<div className="action">
					<button className="cancel"  onClick={cancel}>CANCEL</button>
					<button className="blue-default" onClick={submit}> PUBLISH </button>
				</div>
			</form>
		</DocumentTitle>
	)
};

const PageManagementPage = connect(mapStateToProps)((props) => {
	const dispatch = useDispatch();

	const [currentValue, setCurrentValue] = React.useState(10)
	const [totalData, setTotalData] = React.useState(0);
	const [ActivePage, setActivePage] = React.useState(0);
	const [GetTotalPerpage, setGetTotalPerpage] = React.useState(10); // eslint-disable-line
	const [update, setUpdate] =  React.useState(false);
    const cookies = new Cookies();
	let admin = JSON.parse(localStorage.getItem('userCredentials'));

	const currentPage = React.useMemo(
		() => parseInt(queryString.parse(props.location.search).page ?? 1, 10) || 1,
		[props.location]
	);

    React.useEffect(() => {
		let itemPerPage = cookies.get("Pages:"+admin.user._id) ? cookies.get("Pages:"+admin.user._id) : currentValue;
		dispatch(pageManagementActions.getAll(currentPage,itemPerPage));
	}, [dispatch, currentPage,update]);//eslint-disable-line

	const changePage = React.useCallback(({ page }) => {
		props.history.push(`${props.location.pathname}?${queryString.stringify({ page })}`)
	}, [props.history, props.location])

	React.useEffect(()=>{
        let newItems = cookies.get("Pages:"+admin.user._id)
        if(update !== false){
           cookies.set("Pages:"+admin.user._id, currentValue, { path: "/", sameSite:'lax' })
            setUpdate(false)
            setGetTotalPerpage(cookies.get("Pages:"+admin.user._id))
        }else{
            setGetTotalPerpage(newItems ? newItems : currentValue);
            setUpdate(false)
        }
        
    },[props,currentValue,update,admin,cookies]);


	const topArrowClicked = (e) => {
		if (currentValue < 50){
			if(currentValue === 20){
				setCurrentValue(prevState => prevState + 30);
				setUpdate(true)
			}else{
				setCurrentValue(prevState => prevState + 10);
				setUpdate(true)
			}
		}
	}
	const bottomArrowClicked = (e) => {
		if (currentValue > 10){

			if(currentValue === 50){
				setCurrentValue(prevState => prevState - 30);
				setUpdate(true)
			}else{
				setCurrentValue(prevState => prevState - 10);
				setUpdate(true)
			}
		}
	}

	const handlePageChange = (page) => {
		changePage({page: page});
	}

	
	const InputArrows = ({topArrowClick, bottomArrowClick}) => {

        return (
          <div className="arrows-component">
            <div className="arrows">
              <button onClick={topArrowClick}>
              <i className="fa fa-angle-up" aria-hidden="true" style={{cursor:'pointer'}}></i>
              </button>
              <button onClick={bottomArrowClick}>
                <i className="fa fa-angle-down" aria-hidden="true"  style={{cursor:'pointer'}}></i>
              </button>
            </div>
          </div>
        );
      };
	

	

	const deletePage = React.useCallback(async ({ _id, title }) => {
		const { value: shouldDelete } = await sweetAlert.fire(
			{
				type: 'warning',
				confirmButtonText: 'Delete',
				showCancelButton: true,
				confirmButtonColor: '#24DC98',
				cancelButtonColor: '#d33',
				background: '#2E3549',
				html: (
					`<p class="swal-title-dark">Delete ${title} page?</p>`
				),
				className: "swal-back"
			});

		if(!shouldDelete){
			return;
		}

		try {
			await dispatch(pageManagementActions.remove(_id));
		} catch (error) {
			return;
		}

		toast.fire({
			type: 'success',
			title: `
				<span>
					${title} page has been successfully deleted!
				</span>
			`
		});

		dispatch(pageManagementActions.getAll(currentPage));
	}, [currentPage, dispatch]);

	const publish = React.useCallback((pageId) => {
		dispatch(pageManagementActions.edit({ status: 'published' }, pageId)).then(() => {
			dispatch(pageManagementActions.getAll(currentPage));
		});
	}, [dispatch, currentPage]);

	const setAsDraft = React.useCallback((pageId) => {
		dispatch(pageManagementActions.edit({ status: 'draft' }, pageId)).then(() => {
			dispatch(pageManagementActions.getAll(currentPage));
		});
	}, [dispatch, currentPage]);

	React.useEffect(() => {
        setTotalData(props.pages.total)
        setActivePage(props.pages.page)
    }, [props])

	let fromPage = ((ActivePage -1) * GetTotalPerpage) + 1
    let toPage =  Math.min((ActivePage - 1)* GetTotalPerpage + GetTotalPerpage, totalData)

	const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

	const addPermission = JSON.parse(localStorage.getItem('addPermission'));
	const editPermission = JSON.parse(localStorage.getItem('editPermission'));
	const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
	const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

	const [breadCrumbs, setBreadCrumbs] = React.useState([])

  
	const filterBreadCrumbs = (mode) => {
		console.log(mode)
		switch (mode) {
				case "list":
					setBreadCrumbs([
						{  label: "List of Pages", link: "/admin/page-management?page=1" },
					]);
					break;
				case "create":
					setBreadCrumbs([
						{  label: "List of Pages", link: "/admin/page-management?page=1" },
						{ label: "Create Page", link: "/admin/page-management/create"},
					]);
					break;
				case "edit":
					setBreadCrumbs([
						{  label: "List of Pages", link: "/admin/page-management?page=1" },
						{  label: "Edit Page", link: "/admin/page-management/edit" },
					]);
					break;
					default:
					// Error page
					break;
				}
};

	React.useEffect(() => {
		filterBreadCrumbs(props.mode);
	}, [props.mode]);

    console.log(props.pages)

    return (
		<BodyBackgroundColor backgroundColor='#1C2233'>
			
				<PageWrapper className={cc(['page-management-page', checkSideNav()?.trim()])}>
				<DocumentTitle
						title={
								props.mode === "create"
										? `Create Page | ${process.env.REACT_APP_DOCUMENT_TITLE}`
										: props.mode === "edit"
												? `Edit Page | ${process.env.REACT_APP_DOCUMENT_TITLE}`
												: `Pages | Page | ${process.env.REACT_APP_DOCUMENT_TITLE}`
						}
				/>
				<DynamicHeaderContent
					TitlePage="Pages"
					breadCrumbs={true}
					breadCrumbsPages={breadCrumbs}
				/>
				<If condition={props.pages.loading && ['create', 'edit'].includes(props.mode) === false}>
					<Then>
						<div className="center">
							<img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
						</div>
					</Then>
					<Else>
						<div className="table-card" data-mode={props.mode}>

							<When condition={['create', 'edit'].includes(props.mode) === false}>
								<div className="table-header">
									{
											(addPermission.includes("Pages") === true) || (roleAllTrue === 1) ?
												<Link
													to={{
														pathname: '/admin/page-management/create',
														state: {
															from: '/admin/page-management/create',
															currentPage
														}
													}}
													className={secretDetails !== null ? "darkmode-btn-default disabled-link" : 'darkmode-btn-default' }>
													<p> CREATE PAGE </p>
												</Link>   : false
									}

									<div className="block">
										<span className="darkmode-btn-default entries"> Show Entries </span>
										<div className="custom-input">
											<input type="text" className="entries-btn" name="clicks" value={GetTotalPerpage ? GetTotalPerpage : currentValue} pattern="[0-9]*" style={{pointerEvents:'none'}}/>
											<span className="arrows">
												<InputArrows
													topArrowClick={topArrowClicked}
													bottomArrowClick={bottomArrowClicked}
												/>
											</span>
										</div>
									</div>
								</div>
							</When>

							<SwitchCase>

								<Case condition={['create', 'edit'].includes(props.mode)}>
									<CreateEditPage {...props} />
								</Case>
								<Default>
									<table className="responsive-table">
										<thead>
											<tr>
												<th>Title</th>
												<th>Status</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
                                        <If condition={props.pages.loading === false}>
                                            <Then>
                                                <If  condition={ props.pages?.items?.length === 0}>
                                                    <Then>
                                                        <tr>
                                                            <td colSpan="12" style={{textAlign:'center'}}>
                                                                <img src={emptyIcon} className="empty" alt="empty"/>
                                                                <p style={{fontSize:'16px'}}>No Data Available</p>
                                                                <p style={{fontSize:'14px'}}>There are currently no data available</p>
                                                            </td>
                                                        </tr>
                                                    </Then>

                                                     <Else>

                                                        {props.pages.loading === false &&
                                                            props.pages.items.map((page) => (
                                                                <tr key={page._id}>
                                                                    <td>{page.title}</td>
                                                                    <td
                                                                        style={{
                                                                            textTransform: 'capitalize'
                                                                        }}
                                                                    >
                                                                        {page.status}
                                                                    </td>
                                                                    <td data-actions>
                                                                        <If condition={page.status === 'published'}>
                                                                            <Then>
                                                                                <button
                                                                                    onClick={() => { setAsDraft(page._id) }}
                                                                                    className={secretDetails !== null ? "btn page-action page-setDraft disabled-link" : 'btn page-action page-setDraft' }
                                                                                >
                                                                                    Set as draft
                                                                            </button>
                                                                            </Then>
                                                                            <Else>
                                                                                <button
                                                                                    className={secretDetails !== null ? "btn page-action page-publish disabled-link" : 'btn page-action page-publish' }
                                                                                    onClick={() => { publish(page._id) }}
                                                                                >
                                                                                    Publish
                                                                            </button>
                                                                            </Else>
                                                                        </If>
                                                                        {(editPermission?.includes("Pages") === true) || (roleAllTrue === 1) ? 
                                                                            <Link
                                                                                to={{
                                                                                    pathname: `${props.match.path}/${page._id}/edit`,
                                                                                    state: {
                                                                                        from: '/admin/page-management/create',
                                                                                        currentPage
                                                                                    }
                                                                                }}
                                                                                className={secretDetails !== null ?  "disabled-link" : '' }
                                                                            >
                                                                                <button className="btn page-action page-edit">
                                                                                    Edit
                                                                            </button>
                                                                            </Link> : false
                                                                        }
                                                                        {
                                                                            (deletePermission?.includes("Pages") === true) || (roleAllTrue === 1) ? 
                                                                            <button className={secretDetails !== null ?  "btn page-action page-delete disabled-link" : 'btn page-action page-delete' }  onClick={() => { deletePage(page) }}>
                                                                                Delete
                                                                            </button> : false
                                                                        }
                                                                        <If condition={page.status === 'published'}>
                                                                            <Then>
                                                                                <Link to={`/page/${page.slug}`} target="_blank" className={secretDetails !== null ?  "disabled-link" : '' }>
                                                                                    <button className="btn blue darken-1">
                                                                                        View
                                                                                </button>
                                                                                </Link>
                                                                            </Then>
                                                                            <Else>
                                                                                <button className={secretDetails !== null ?  "btn blue darken-1 disabled-link" : 'btn blue darken-1' }  disabled>
                                                                                    View
                                                                            </button>
                                                                            </Else>
                                                                        </If>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                {
                                                
                                                    
											}
                                                </Else>

                                            
                                            </If>
                                        </Then>
                                        <Else>
                                            <tr className="table-td-data">
                                                <td> 
                                                <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                                                    <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                </div>
                                                </td>
                                            </tr>
                                        </Else>  
                                    </If> 
											
										</tbody>
									</table>
									<div className="table-footer" data-mode={props.mode}>

										{totalData !== 0 && <div className="dataCount"> Showing {fromPage} to {toPage} of {totalData} entries  </div>}

										<Pagination
											activeClass="pagination_navigtion_active"
											itemClass="pagination_navigtion"
											linkClass="pagination_navigtion_link"

											hideDisabled
											hideFirstLastPages
											prevPageText='Previous'
											nextPageText='Next'
											pageRangeDisplayed={10}
											activePage={ActivePage}
											itemsCountPerPage={GetTotalPerpage}
											totalItemsCount={totalData}
											onChange={(e) => handlePageChange(e)}
										/>
									</div>
								</Default>
							
							</SwitchCase>
						
						</div>
					</Else>
				</If>
			</PageWrapper>
		</BodyBackgroundColor>
    )
});
PageManagementPage.WrappedComponent.displayName = 'PageManagementPage';

const PageManagementPageRoute = (props) => (
	<Switch>
		<Route
			exact
			path={props.match.path}
			render={(props) => <PageManagementPage {...props} mode="list" />}
		/>
		<Route
			exact
			path={`${props.match.path}/create`}
			render={(props) => <PageManagementPage {...props} mode="create" />}
		/>
		<Route
			exact
			path={`${props.match.path}/:pageId/edit`}
			render={(props) => <PageManagementPage {...props} mode="edit" />}
		/>
	</Switch>
);

export default React.memo(PageManagementPageRoute);