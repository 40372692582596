import { leaveConstants } from '../constants/leave';
import { leaveService } from '../services/leave';
import{ userService } from '../services/user';
import { alertActions } from './alert';
import { history } from '../helpers/history';
import Swal from 'sweetalert2';

export const leaveActions = {
    getAllById,
    getAll,
    getById,
    approve,
    hold,
    reject,
    update,
    fileLeave,
    getAllFilter
};

function getAll(page, itemPerPage) {
    return dispatch => {
        dispatch(request());

        leaveService.getAll(page, itemPerPage)
            .then(
                leaves =>{ 
                    dispatch(success(leaves));
                    // if(window.location.pathname === '/admin/leave-requests'){
                    //     history.push('/admin/leave-requests?page=' + Number(page) || 1);
                    // }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: leaveConstants.GETALL_REQUEST } }
    function success(leaves) { return { type: leaveConstants.GETALL_SUCCESS, leaves } }
    function failure(error) { return { type: leaveConstants.GETALL_FAILURE, error } }
}

function getAllById(selectPage) {
    return dispatch => {
        dispatch(request());

        leaveService.getAllById(selectPage)
            .then(
                leaves => dispatch(success(leaves)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: leaveConstants.GETUSER_REQUEST } }
    function success(leaves) { return { type: leaveConstants.GETUSER_SUCCESS, leaves } }
    function failure(error) { return { type: leaveConstants.GETUSER_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request(id));

        leaveService.getById(id)
            .then(
                leave => {
                    dispatch(success(leave));
                    // history.push('/admin/leave-request/update');
                    var uid = leave.success[0].user_id;
                    userService.getById(uid)
                },
                error => dispatch(failure(error.toString()))
            );

    };

    function request() { return { type: leaveConstants.GET_REQUEST } }
    function success(leave) { return { type: leaveConstants.GET_SUCCESS, leave } }
    function failure(error) { return { type: leaveConstants.GET_FAILURE, error } }
}

function getAllFilter(page, itemsPerPage, search, filter) {
    return dispatch => {
        dispatch(request());

        leaveService.getAllFilter(page, itemsPerPage, search, filter)
            .then(
                leaves =>{ 
                    dispatch(success(leaves));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: leaveConstants.GETALL_REQUEST } }
    function success(leaves) { return { type: leaveConstants.GETALL_SUCCESS, leaves } }
    function failure(error) { return { type: leaveConstants.GETALL_FAILURE, error } }
}

function approve(leave) {
    return dispatch => {
        dispatch(request(leave));

        leaveService.approve(leave)
            .then(
                leave => {
                    dispatch(success(leave));

                    // custom toast mixin for sweetAlert
                    const Toast = Swal.mixin({
                        toast: true,
                        width: 300,
                        height: 500,
                        background: 'rgba(191, 85, 236, 0.9)',
                        fontColor: "white",
                        position: 'center',
                        showConfirmButton: false,
                        timer: 5000
                    });

                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#FFFFFF">Leave Approved!</span>'
                    }).then((res) => {
                        history.push('/admin/leave-requests/page=:id');
                    })
                    dispatch(alertActions.success(''));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Leave approve failed'));
                }
            );
    };

    function request(leave) { return { type: leaveConstants.LEAVE_REQUEST, leave } }
    function success(leave) { return { type: leaveConstants.LEAVE_SUCCESS, leave } }
    function failure(error) { return { type: leaveConstants.LEAVE_FAILURE, error } }
}

function hold(leave) {
    return dispatch => {
        dispatch(request(leave));

        leaveService.hold(leave)
            .then(
                leave => {
                    dispatch(success(leave));
                    history.push('/admin/leave-requests/page=:id');
                    dispatch(alertActions.success('Leave request on hold'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Leave on hold failed'));
                }
            );
    };

    function request(leave) { return { type: leaveConstants.HOLD_REQUEST, leave } }
    function success(leave) { return { type: leaveConstants.HOLD_SUCCESS, leave } }
    function failure(error) { return { type: leaveConstants.HOLD_FAILURE, error } }
}

function reject(leave) {
    return dispatch => {
        dispatch(request(leave));

        leaveService.reject(leave)
            .then(
                leave => {
                    dispatch(success(leave));
                    history.push('/admin/leave-requests/page=:id');
                    dispatch(alertActions.success('Leave request rejected'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Leave reject failed'));
                }
            );
    };

    function request(leave) { return { type: leaveConstants.DECLINE_REQUEST, leave } }
    function success(leave) { return { type: leaveConstants.DECLINE_SUCCESS, leave } }
    function failure(error) { return { type: leaveConstants.DECLINE_FAILURE, error } }
}

function update(leave) {
    return dispatch => {
        dispatch(request(leave));

        leaveService.update(leave)
            .then(
                leave => {
                    dispatch(success(leave));
                    // custom toast mixin for sweetAlert
                    const Toast = Swal.mixin({
                        toast: true,
                        width: 300,
                        height: 500,
                        background: 'rgba(191, 85, 236, 0.9)',
                        fontColor: "white",
                        position: 'center',
                        showConfirmButton: false,
                        timer: 5000
                    });
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#FFFFFF">Leave Updated!</span>'
                    }).then((res) => {
                        history.push('/admin/leave-requests/page=:id');
                    })
                    dispatch(alertActions.success(''));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Leave update failed'));
                }
            );
    };


    function request(leave) { return { type: leaveConstants.UPDATE_REQUEST, leave } }
    function success(leave) { return { type: leaveConstants.UPDATE_SUCCESS, leave } }
    function failure(error) { return { type: leaveConstants.UPDATE_FAILURE, error } }
}



function fileLeave(leave) {
    return dispatch => {
        dispatch(request(leave));

        leaveService.fileLeave(leave)
            .then(
                leave => {
                    dispatch(success(leave));
                    const Toast = Swal.mixin({
                        toast: true,
                        width: 300,
                        height: 500,
                        background: 'rgba(191, 85, 236, 0.9)',
                        fontColor: "white",
                        position: 'center',
                        showConfirmButton: false,
                        timer: 5000
                    });
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:green">Leave request has been Sent!</span>'
                    }).then(() => {
                        history.push('/user/leave');
                    })
                    dispatch(alertActions.success(''));
                },
                error => {
                    dispatch(failure(error.toString()));
                    const Toast = Swal.mixin({
                        toast: true,
                        width: 300,
                        height: 500,
                        background: 'rgba(191, 85, 236, 0.9)',
                        fontColor: "white",
                        position: 'center',
                        showConfirmButton: false,
                        timer: 5000
                    });
                    Toast.fire({
                        type: 'error',
                        title: '<span style="color:red">Sending request failed!</span>'
                    }).then(() => {
                    })
                    dispatch(alertActions.error(''));
                }
            );
    };

    function request(leave) { return { type: leaveConstants.FILELEAVE_REQUEST, leave } }
    function success(leave) { return { type: leaveConstants.FILELEAVE_SUCCESS, leave } }
    function failure(error) { return { type: leaveConstants.FILELEAVE_FAILURE, error } }
}
