export function exp_level(data) {
    var points = Number(data)
    var level = 0;
    var target = (Math.pow(level, 2)+level) / 2*100 - (level*100);
    var levels = []

    if(points !== 0){
        while(target <= points){
            level++
            target = (Math.pow(level, 2)+level) / 2*100 - (level*100)
        }
        levels.push({
            current: level - 1,
            next: level,
        });
    }else{
        levels.push({
            current: 1,
            next: 2,
        });
    }
    
    return levels;
}
 
export function exp_targetPoints(current, next) {
    var nextLevel = next;
    var currentLevel = current;
    var nextTarget = (Math.pow(nextLevel, 2)+nextLevel) / 2*100 - (nextLevel*100);
    var currentTarget = (Math.pow(currentLevel, 2)+currentLevel) / 2*100 - (currentLevel*100);
    var targets = [];

    if(nextLevel && currentLevel){
        targets.push({
            next: nextTarget,
            current: currentTarget,
        });
    }else{
        targets.push({
            next: 0,
            current: 0,
        });
    }

    return targets
    
}