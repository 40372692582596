export const grantConstants = { 
    GETALL_REQUEST: 'GRANT_GETALL_REQUEST',
    GETALL_SUCCESS: 'GRANT_GETALL_SUCCESS',
    GETALL_FAILURE: 'GRANT_GETALL_FAILURE',

    GET_ORIGIN_REQUEST: 'GRANT_GET_ORIGIN_REQUEST',
    GET_ORIGIN_SUCCESS: 'GRANT_GET_ORIGIN_SUCCESS',
    GET_ORIGIN_FAILURE: 'GRANT_GET_ORIGIN_FAILURE',

    POST_REQUEST: 'POST_GRANT_REQUEST',
    POST_SUCCESS: 'POST_GRANT_SUCCESS',
    POST_FAILURE: 'POST_GRANT_FAILURE',
}

