import { missionConstants } from '../constants/mission';
import { missionService } from '../services/mission';
import { alertActions } from './alert';
//import { history } from '../helpers/history';

export const missionActions = {
    getMissions,
    startMission
};

function getMissions(id) {
    return dispatch => {
        dispatch(request(id));

        missionService.getMissions(id)
            .then(
                mission => {
                    dispatch(success(mission.success));
                },
                error => dispatch(failure(error.toString()))

            );
    };

    function request() { return { type: missionConstants.GETALL_MISSION_REQUEST } }
    function success(missions) { return { type: missionConstants.GETALL_MISSION_SUCCESS, missions } }
    function failure(error) { return { type: missionConstants.GETALL_MISSION_FAILURE, error } }
}


function startMission(mission) {
    return dispatch => {
        dispatch(request(mission));

        missionService.startMission(mission)
            .then(
                mission => { 
                    dispatch(success(mission.success));
                   
                    dispatch(alertActions.success('Mission Started'));
                    setTimeout(function(){window.location.reload(1);}, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('something went wrong'));
                }
            );
    };

    function request(mission) { return { type: missionConstants.START_REQUEST, mission } }
    function success(mission) { return { type: missionConstants.START_SUCCESS, mission } }
    function failure(error) { return { type: missionConstants.START_FAILURE, error } }
}
