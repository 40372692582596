import trophy_1 from "../../UserProfilePage/trophy_1.png";
import trophy_2 from "../../UserProfilePage/trophy_3.png";
import trophy_3 from "../../UserProfilePage/trophy_2.png";
import staticImg1 from "../../PeoplesPage/Basic Front End_icon.png";
import staticImg2 from "../../PeoplesPage/Generous Tipster_icon.png";
import staticImg3 from "../../PeoplesPage/Challenge_icon.png";

export const title = {
  default: [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Ms', label: 'Ms' },
  ]
}

export const gender = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' }
]

export const maritalStatus = {
  default: [
    { value: 'married', label: 'Married' },
    { value: 'solo-parent', label: 'Solo Parent' },
    { value: 'divorced', label: 'Divorced' },
    { value: 'widowed', label: 'Widowed' },
    { value: 'living-common-law', label: 'Living common law' },
    { value: 'separated', label: 'Separated (not living common law)' },
    { value: 'never-married', label: 'Never Married' },
  ],
};


export const level = {
  default: [
    { value: 'applicant', label: 'Applicant' },
    { value: 'rank-and-file', label: 'Rank and File' },
    { value: 'team-leader', label: 'Team Leader' },
    { value: 'officer', label: 'Officer' },
    { value: 'senior-officer', label: 'Senior Officer' },
    { value: 'supervisor', label: 'Supervisor' },
    { value: 'senior-supervisor', label: 'Senior Supervisor' },
    { value: 'entry-manager', label: 'Entry Manager' },
    { value: 'manager', label: 'Manager' },
    { value: 'senior-manager', label: 'Senior Manager' }
  ],
  software: [
    { value: 'senior-executive', label: 'Senior Executive' },
    { value: 'executive', label: 'Executive' },
    { value: 'senior-director', label: 'Senior Director' },
    { value: 'director', label: 'Director' },
    { value: 'senior-manager', label: 'Senior Manager' },
    { value: 'manager', label: 'Manager' },
    { value: 'senior', label: 'Senior' },
    { value: 'intermediate', label: 'Intermediate' },
    { value: 'junior', label: 'Junior' }
  ]
};

export const position = {
  default: [
    { value: 'employee', label: 'Employee' }
  ],
  software: [
    { value: 'applicant', label: 'Applicant' },
    { value: 'trainee', label: 'Trainee' },
    { value: 'intern', label: 'Intern' },
    { value: 'software-engineer', label: 'Software Engineer' },
    { value: 'qa-officer', label: 'Quality Assurance Officer' },
    { value: 'project-manager', label: 'Project Manager' },
    { value: 'executive', label: 'Executive' },
    { value: 'ui/ux-developer', label: 'UI/UX Developer' },
    { value: 'marketing-associate', label: 'Marketing Associate' },
    { value: 'outsourced-provider', label: 'Outsourced Provider' },
  ]
};


export const department = {

  default: [
    { value: 'development', label: 'Development' },
    { value: 'management', label: 'Management' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'human-resources', label: 'Human Resources' },
    { value: 'finance', label: 'Finance' },
    { value: 'executive', label: 'Executive' }
  ],
  archiving: [
    { value: 'ops-records-center', label: 'Ops-Records Center' },
    { value: 'ops-service-request-center', label: 'Ops-Service Request Center' },
    { value: 'ops-e-data-processing-center', label: 'Ops-E-Data Processing Center' },
    { value: 'ops-logistics-center', label: 'Ops-Logistics Center' },
    { value: 'ops-cebu', label: 'Ops-Cebu' },
    { value: 'ops-davao', label: 'Ops-Davao' },
    { value: 'ops-north', label: 'Ops-North' },
    { value: 'ops-destruction-center', label: 'Ops-Destruction Center' },
    { value: 'business-development-department', label: 'Business Development Department' },
    { value: 'admin-housekeeping-section', label: 'Admin-Housekeeping Section' },
    { value: 'admin-purchasing-section', label: 'Admin-Purchasing Section' },
    { value: 'admin-security-and-safety-section', label: 'Admin-Security and Safety Section' },
    { value: 'admin-facility-repair-section', label: 'Admin-Facility Repair Section' },
    { value: 'admin-it-section', label: 'Admin-IT Section' },
    { value: 'hr-and-admin-department', label: 'HR and Admin Department' },
    { value: 'finance-and-accounting-department', label: 'Finance and Accounting Department' },
    { value: 'fa-billing-and-collection-section', label: 'FA-Billing and Collection Section' },
    { value: 'fa-internal-audit', label: 'FA-Internal Audit' },
    { value: 'preventive-maintenance-section', label: 'Preventive Maintenance Section' }
  ],
  software: [
    { value: 'creatives', label: 'Creatives' },
    { value: 'management', label: 'Management' },
    { value: 'quality-control', label: 'Quality Control' },
    { value: 'support', label: 'Support' },
    { value: 'development', label: 'Development' },
    { value: 'operations', label: 'Operations' },
    { value: 'human-resources', label: 'Human Resources' },
    { value: 'accounting', label: 'Accounting' },
    { value: 'security', label: 'Security' },
    { value: 'administration', label: 'Administration' },
    { value: 'reception', label: 'Reception' },
    { value: 'utility', label: 'Utility' },
    { value: 'training', label: 'Training' }
  ]
}

export const officeLocation = {
  default: [
    { value: '1', label: 'Eastwood' },
    { value: '2', label: 'UBP' },
    { value: '3', label: 'ORITGAS' },
    { value: '4', label: 'CYBERMALL' },
    { value: '5', label: 'CyberOne' }
  ],
};

export const difficulty = {
  default: [
    { value: '1', label: 'Easy' },
    { value: '2', label: 'Medium' },
    { value: '3', label: 'Hard' }
  ],
};

export const wagertype = {
  default: [
    { value: '1', label: 'Credits' },
    { value: '2', label: 'Item' },
    { value: '3', label: 'Commend' }
  ],
};

export const achivement = {
  default: [
    { value: '1', label: 'Great' },
    { value: '2', label: 'Superior' },
    { value: '3', label: 'Masters of Masters' },
    { value: '4', label: 'Superior' },
    { value: '5', label: 'Masters of Masters' }
  ],
};

export const experienceLevel = {
  default: [
    { level: '1', exp: '0' }, { level: '2', exp: '100' }, { level: '3', exp: '300' },
    { level: '4', exp: '600' }, { level: '5', exp: '1000' }, { level: '6', exp: '1500' },
    { level: '7', exp: '2100' }, { level: '8', exp: '2800' }, { level: '9', exp: '3600' },
    { level: '10', exp: '4500' }, { level: '11', exp: '5500' }, { level: '12', exp: '6600' },
    { level: '13', exp: '7800' }, { level: '14', exp: '9100' }, { level: '15', exp: '10500' },
    { level: '16', exp: '12000' }, { level: '17', exp: '13600' }, { level: '18', exp: '15300' },
    { level: '19', exp: '17100' }, { level: '20', exp: '19000' }, { level: '21', exp: '21000' },
    { level: '22', exp: '23100' }, { level: '23', exp: '25300' }, { level: '24', exp: '27600' },
    { level: '25', exp: '30000' }, { level: '26', exp: '32500' }, { level: '27', exp: '35100' },
    { level: '28', exp: '37800' }, { level: '29', exp: '40600' }, { level: '30', exp: '43500' },
    { level: '31', exp: '46500' }, { level: '32', exp: '49600' }, { level: '33', exp: '52800' },
    { level: '34', exp: '56100' }, { level: '35', exp: '59500' }, { level: '36', exp: '63000' },
    { level: '37', exp: '66600' }, { level: '38', exp: '70300' }, { level: '39', exp: '74100' },
    { level: '40', exp: '78000' }, { level: '41', exp: '82000' }, { level: '42', exp: '86100' },
    { level: '43', exp: '90300' }, { level: '44', exp: '94600' }, { level: '45', exp: '99000' },
    { level: '46', exp: '103500' }, { level: '47', exp: '108100' }, { level: '48', exp: '112800' },
    { level: '49', exp: '117600' }, { level: '50', exp: '122500' }, { level: '51', exp: '127500' },
    { level: '52', exp: '132600' }, { level: '53', exp: '137800' }, { level: '54', exp: '143100' },
    { level: '55', exp: '148500' }, { level: '56', exp: '154000' }, { level: '57', exp: '159600' },
    { level: '58', exp: '165300' }, { level: '59', exp: '171100' }, { level: '60', exp: '177000' },
    { level: '61', exp: '183000' }, { level: '62', exp: '189100' }, { level: '63', exp: '195300' },
    { level: '64', exp: '201600' }, { level: '65', exp: '208000' }, { level: '66', exp: '214500' },
    { level: '67', exp: '221100' }, { level: '68', exp: '227800' }, { level: '69', exp: '234600' },
    { level: '70', exp: '241500' }, { level: '71', exp: '248500' }, { level: '72', exp: '255600' },
    { level: '73', exp: '262800' }, { level: '74', exp: '270100' }, { level: '75', exp: '277500' }
  ],
};


export const badges = {
  default: [
    {
      _id: '1',
      userId: '5d7633f14d1da8136c69ed15',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Champ Badge",
        badgeSlug: "createlug2",
        badgeImage: trophy_2,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '2',
      userId: '5d7633f14d1da8136c69ed15',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Honor Badge",
        badgeSlug: "createlug2",
        badgeImage: trophy_1,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '3',
      userId: '5d7633f14d1da8136c69ed15',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "FrontEnd Badge",
        badgeSlug: "createlug2",
        badgeImage: staticImg1,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '4',
      userId: '5d776460f6bb1c29b8c6192d',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Kind Badge",
        badgeSlug: "createlug2",
        badgeImage: staticImg2,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '5',
      userId: '5d776460f6bb1c29b8c6192d',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Challenge Badge",
        badgeSlug: "createlug2",
        badgeImage: staticImg3,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '6',
      userId: '5d776460f6bb1c29b8c6192d',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Initiative Badge",
        badgeSlug: "createlug2",
        badgeImage: trophy_3,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '1',
      userId: '5d776460f6bb1c29b8c6192d',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Champ Badge",
        badgeSlug: "createlug2",
        badgeImage: trophy_2,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '2',
      userId: '5ddde5ae674e5f4f9c782327',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Honor Badge",
        badgeSlug: "createlug2",
        badgeImage: trophy_1,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '3',
      userId: '5ddde5ae674e5f4f9c782327',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Punctual Badge",
        badgeSlug: "createlug2",
        badgeImage: trophy_1,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '4',
      userId: '5d7f2945d4c1913b1b0b0491',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Dedication Badge",
        badgeSlug: "createlug2",
        badgeImage: trophy_3,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '5',
      userId: '5d7f2945d4c1913b1b0b0491',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Jolly Badge",
        badgeSlug: "createlug2",
        badgeImage: trophy_3,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
    {
      _id: '6',
      userId: '5d7f2945d4c1913b1b0b0491',
      badgeId: '5def50342cff973c9df96777',
      details: [{
        badgeName: "Initiative Badge",
        badgeSlug: "createlug2",
        badgeImage: trophy_2,
        badgeContent: "createcontento",
        badgeType: "createtypeo1",
        badgeCategory: "createcategorya1",
        createdAt: "2019-12-10T07:58:44.814Z",
        updatedAt: "2019-12-10T07:58:44.814Z",

      }]
    },
  ]
}