import React from 'react';
import { Link } from 'react-router-dom';


export const SubPanelBtn = ({ name = "", link = "" }) => {
    return (
        <div className="card z-depth-0" style={{ padding: '15px 20px' }}>
            <Link to={link}>
                <button className="linkBtn" style={{
                    backgroundColor: '#CE069E',
                    width: '100%',
                    minHeight: '40px',
                    fontWeight: '500',
                    color: '#FFF',
                    fontSize: '15px',
                    textTransform: 'uppercase',
                }}>
                    {name}
                </button>
            </Link>
        </div>
    )
}