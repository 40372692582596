import React, { useEffect, useState } from 'react'
import ReactImageFallback from 'react-image-fallback'
import moment from 'moment'
import notifHeader from '../_templates/notif-admin-header.png'
import avatar from '../_templates/man.png'
import './UserNotif.scss'
import { notifService } from '../_core/services/notification'
import M from 'materialize-css/dist/js/materialize.min.js'

function UserNotif() {

    const [ notifications, setNotification ] = useState([])

    useEffect(() => {

        let elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });

        fetchNotif()
        const interval = setInterval(() => {
            fetchNotif()
        }, 5000); // fetch updates every 5 seconds
        
        return () => clearInterval(interval);

        // notifService.getNotifFilter(1,9999, user.user_id)
        //     .then(data => {
        //         setNotification(data.items)
        //         setNotifUnread(data.items.filter(notifUnread => notifUnread.isRead === 0))
        //     })
        //     let elems = document.querySelectorAll('.dropdown-trigger');
        //     M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });
        
    }, [])
    
    const fetchNotif  = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        notifService.getNotifFilter(1,9999, user.user_id)
            .then(data => {
            setNotification(data.items)
    })

    } 

    const formatDate = (date) => {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const lastWeek = moment().subtract(7, 'days').startOf('day');
    
        const formattedDate = moment(date);
    
        if (formattedDate.isSame(today, 'day')) {
            return 'Today, ' + formattedDate.format('LT');
        } else if (formattedDate.isSame(yesterday, 'day')) {
            return 'Yesterday, ' + formattedDate.format('LT');
        } else if (formattedDate.isAfter(lastWeek)) {
            return formattedDate.format('dddd, MMMM D, YYYY, LT');
        } else {
            return formattedDate.format('dddd, MMMM D, YYYY, LT');
        }
    }

    const filterUnread = () => {
        return notifications.filter(notifUnread => notifUnread.isRead === 0)
    }

    const handleNotifRead = (notifId) => {
        let body ={
            isRead: 1
       }
       notifService.updateNotif(notifId, body)
           .then(data => {
            setNotification(notifications.map(notif => notif._id == notifId
                ? {...notif, isRead: 1}
                : notif
            ))
               console.log('Fetched notifications from API:', data);
               // Update notifications state with the fetched notifications
           })
           .catch(error => {
               console.error('Error marking notification as read:', error);
           });
    }

  return (
    <div className='user-notif' style={{ position: 'relative' }}>
        <span className='dropdown-trigger settings-admin-header bellnotif' style={{ position: 'relative', overflow: 'inherit'}} data-target='dropdown2'>
            <img
                src={notifHeader}
                className='notifHeader'
                alt=''
                style={{ cursor: 'pointer' }}
                data-target='dropdown2'
            />
            {filterUnread().length > 0 && (
                <div className="notification-badges">
                <span className="badge-contents">{filterUnread().length}</span>
                </div>
            )}
        </span>
        
        <ul id='dropdown2' className='dropdown-content user-content-notif notifDiv' 
            style={{ height: 0, overflowY: 'auto', maxHeight: '300px', 'msOverflowStyle': 'none', 'scrollbarWidth': 'none' }}
        >
            {notifications.length === 0 
                ?
                    (
                        <li className='ch-notif' style={{wordBreak:'break-word', color:'black', fontSize:'14px', padding:'10px', textAlign:'center'}}>No notifications</li>
                    )
                :
                notifications.map((notif) => (
                    <li  className='ch-notif' onClick={() => handleNotifRead(notif._id)}>
                    <div className="notification-item" style={{ display: 'block', padding: '10px' }}>
                        <div style={{display:'flex'}}>
                            <div className="notification-avatar" style={{ marginRight: '10px' }}>
                                <ReactImageFallback
                                    src={notif?.userDetails?.[0]?.avatar}
                                    fallbackImage={avatar}
                                    initialImage={avatar}
                                    alt='user avatar'
                                    className='dropdown-trigger profile-img-admin'
                                    style={{ cursor: 'default', width: '35px', height: '35px', borderRadius: '50%', borderColor:'#ffffff', objectFit:'cover' }}
                                />
                            </div>
                            <div className="notification-details" style={{ flex: 1 , wordBreak:'break-word', color:'black', fontSize:'14px'}}>
                                {
                                    notif.type === 'ACCEPT_CHALLENGE' ?
                                        `${notif?.userDetails?.[0]?.firstName} ${notif?.userDetails?.[0]?.lastName} accepted the challenge title of "${notif.title}".`
                                    :                           
                                    notif.type === 'FINISH_CHALLENGE' ?
                                        `${notif?.userDetails?.[0]?.firstName} ${notif?.userDetails?.[0]?.lastName} finished the challenge title of "${notif.title}".`  
                                    : ''                
                                }

                            </div>
                        </div>

                        <div style={{ color: '#000000', fontSize: '12px', marginTop: '0px', position:'relative', display:'flex', justifyContent:'center' }}>
                            <p style={{marginRight:'10px'}}>{formatDate(notif.createdAt)}</p>
                            <p>{notif.isRead ? 'Read' : 'Unread'}</p>
                        </div>       
                    </div>
                </li>
                ))
            }
            
        </ul>

    </div>

  )
}

export default UserNotif