import React, { useEffect }from 'react'
// import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
// import { checkSideNav } from '../../Components/checkSideMenu';
// import { Route, Switch } from 'react-router-dom';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import "./MainRoles.scss";
import { roleActions } from '../../../_core/actions/role';
import { permissionActions } from '../../../_core/actions/permission';
import { roleService } from '../../../_core/services/role';
import Swal from 'sweetalert2';
import Select, { components } from 'react-select';

const mapStateToProps = ({ roles,specialChar,actions,permissions,value }) => ({
    roles,
    specialChar,
    actions,
    permissions,
    value
});

const RolesAction = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();


    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [specialChar, setChar] = React.useState(false); 
    const [actions, setActions] =React.useState([]);
    const [roleId, setRoleId] =React.useState([]);
    const [editRole, setEdit] =  React.useState()
    const [colorError, setcolorError] = React.useState(false);
    
    let rolesData = JSON.parse(localStorage.getItem('role'));

    const edit = rolesData ? true : false;
    let _role = edit ? rolesData[0] : {};

    const getPermissions = edit ? _role.permissions === null ? [] : _role.permissions : [];

    var setPermissions = [];

    getPermissions && getPermissions.filter((p)=> p._id).map((p)=>{
        setPermissions.push({
            permissions: p._id,
            actions: p.actions
        })
        return p
    })


    const [role, setRoles] =  React.useState({ 
        _id: edit ? _role._id : null,
        roleName: edit ? _role.roleName : '',
        roleSlug: edit ? _role.roleSlug : '',
        roleAll: edit ? _role.roleAll : '',
        permissions: setPermissions || [],

    });

    useEffect(() => {
        setEdit(edit ? true : false);
        localStorage.removeItem('role'); 
        setActions(['View', 'Add', 'Edit', 'Delete'])
        dispatch(permissionActions.getAll(1, 100000));
        // eslint-disable-next-line
    }, [dispatch])


    
    useEffect(() => {
        allPermissions(props?.permissions?.permissions?.items);
        //eslint-disable-next-line
    }, [props.permissions]);

    const allPermissions = (item) => {
       let data = item ?? [];
       var perms = [];
        if(editRole === true && role.roleAll === 1) {
            data.map((permission) => {
                var updateActions = [];
                role.permissions.filter((p)=> p.permissions === permission._id).map((a)=>{
                    updateActions = a.actions
                    return a
                });

                perms.push({
                    permissions: permission._id,
                    actions: updateActions
                })
                return permission
            })
            setRoles({
                    ...role,
                    permissions: perms
            })
          
        }
      };

    // const HeaderSub = editRole ? 'Edit Role' : 'Add New Role';
    let HeaderContent = editRole ? 'Edit Role' : 'Create Role';


    const handleChange = (event) => {
        const { name, value } = event.target;

        var test = /^[ A-Za-z0-9-]*$/;

        if (name === "roleName") {
            setRoles({
                    ...role,
                    [name]: value,
                    roleSlug: value.replace(/\s+/g, "-").replace(/[-]+/g, "-").replace(/[!^|@;&/\\#,+=()$~%._'":*?<>{}]/g, '').toLowerCase().replace(/^[-]+|[-]+$/g, "")
            });
        } else if (name === "roleSlug") {
            if (test.test(value) === true) {
                setRoles({
                        ...role,
                        [name]: value.toLowerCase().replace(/\s+/g, "-").replace(/[-]+/g, "-")
                })
                setChar(false)
            } else {
                if (value === "") {
                    setRoles({
                            ...role,
                            [name]: value
                    })
                    setChar(false)
                } else {
                    setChar(true)
                }
            }
        }else {

            var perms = [];
            if(name === "roleAll"){
                if(Number(value) === 1){
                    props.permissions.permissions && props.permissions.permissions.items.map((permission) => {
                        var updateActions = [];
                        role.permissions.filter((p)=> p.permissions === permission._id).map((a)=>{
                            updateActions = a.actions
                            return a
                        });

                        perms.push({
                            permissions: permission._id,
                            actions: updateActions
                        })
                        return permission
                    })
                    setRoles({
                            ...role,
                            [name]: value ,
                            permissions: perms
                    })
                }else{
                    setRoles({
                            ...role,
                            [name]: value 
                    });
                }
            }else{
                setRoles({
                        ...role,
                        [name]: value 
                });
            }
        }
    }

    const selectedPermissions = (id) => {

        var checkedBox = document.getElementById(id);
        var getPermissions = role.permissions || [];

        // If the checkbox is checked
        if (checkedBox.checked === true) {
            if(getPermissions[0]){
                getPermissions.map((p)=>{
                    if(p.permissions === id){
                        setRoles({

                                ...role,
                                permissions: role.permissions.filter((p)=> p.permissions === id).map((p)=>{
                                    return{
                                            permissions: p.permissions,
                                            actions: p.actions
                                        }
                                    })

                            })
                    }else{
                        setRoles({
                                ...role,
                                permissions: [
                                    ...getPermissions,
                                    {
                                        permissions: id,
                                        actions: []
                                    }
                                ]
                        })
                    }
                    return p
                })
            }else{
                setRoles({
                        ...role,
                        permissions: [
                            {
                                permissions: id,
                                actions: []
                            }
                        ]
                })
            }
        } else if (checkedBox.checked === false) {
            setRoles({
                    ...role,
                    permissions: role.permissions.filter((list) => list.permissions !== id)
            })
            setRoleId([...roleId, id])
        }
    }

    const selectedActions = (id, action) => {

        const permissions = role.permissions;
        var checkedBox = document.getElementById(id + action);
        var updatePermissions = []

        permissions.map((p)=>{
            if(checkedBox.checked === true){
                if(p.permissions === id){
                    updatePermissions.push({
                        permissions: p.permissions,
                        actions: p.actions ? p.actions.concat(action) : [action]
                    })
                }else{
                    updatePermissions.push({
                        permissions: p.permissions,
                        actions: p.actions
                    })
                }
            }else if(checkedBox.checked === false){
                if(p.permissions === id){
                    updatePermissions.push({
                        permissions: p.permissions,
                        actions: p.actions.filter((a)=> a !== action)
                    })
                }else{
                    updatePermissions.push({
                        permissions: p.permissions,
                        actions: p.actions
                    })
                }
            }
            return p
        });

        setRoles({
                ...role,
                permissions: updatePermissions
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setIsSubmitted(true);

        
        const permissionItems = props.permissions.permissions && props.permissions.permissions.items.length;
        var perms = [];

        props.permissions.permissions && props.permissions.permissions.items.map((permission) => {
            perms.push({
                _id: permission._id,
                actions: role.permissions.filter((p)=> p.permissions === permission._id).map((p)=>p.actions)
            })
            return permission
        })

        let RoleAll = role.permissions.filter((p)=> p.permissions).length >= permissionItems ? "Yes" : Number(role.roleAll) === 1 ? "Yes" : Number(role.roleAll) === 0 ? 'No' : '';

        //select-validation-style
        if (RoleAll !== '') {
            setcolorError(true);
        };

        if (role.roleName && role.roleSlug && RoleAll !== '') {
            const textDisplay = editRole ? '<p class="swal-subtitle-dark">Update Role</p>' : '<p class="swal-subtitle-dark"> Add Role </p>';
            Swal.fire({
                title: textDisplay,
                html:
                    "<br> <b class='swal-subtitle-dark'>Role Name:  "
                    + role.roleName + "</b><br><b class='swal-subtitle-dark'>Role Slug: "
                    + role.roleSlug.replace(/\s+/g, "-").replace(/[-]+/g, "-").replace(/[!^|@;&/\\#,+=()$~%._'":*?<>{}]/g, '').toLowerCase().replace(/^[-]+|[-]+$/g, "") + " </b><br><b class='swal-subtitle-dark'>Role All: "
                    + RoleAll +"</b>",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#24DC98',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                background: '#2E3549',
            }).then((result) => {
                if (result.value) {
                    setRoles({
                            ...role,
                            roleSlug: role.roleSlug.replace(/\s+/g, "-").replace(/[-]+/g, "-").replace(/[!^|@;&/\\#,+=()$~%._'":*?<>{}]/g, '').toLowerCase().replace(/^[-]+|[-]+$/g, ""),
                            roleAll: RoleAll === "Yes" ? '1' : '0',
                            permissions: RoleAll === "Yes" ? perms : role.permissions,
       
                    })
                    const updateRole = {
                        _id: role._id,
                        roleName: role.roleName,
                        roleSlug: role.roleSlug,
                        roleAll: role.roleAll,
                    }

                    const updateRolePermissions = role.roleAll === 1 ? [] : role.permissions
                    if(editRole === true){
                        dispatch(roleActions.roleUpdate(updateRole, updateRolePermissions));

                        if(roleId.length !== 0){
                            roleId.map((p)=>{
                                return roleService.roleDeletepermissions(role._id, p)
                            })//Update Role Permissions
                        }
                    
                    }else{
                        dispatch(roleActions.roleAdd(updateRole, updateRolePermissions));
                    }
                }


            })

           
        } else {
            const textDisplay = editRole ? '<p class="swal-subtitle-dark">Update Error</p>' : '<p class="swal-subtitle-dark">Add Error</p>';
            Swal.fire({
                title: textDisplay,
                html:
                    "<br> <b class='swal-subtitle-dark'>Fill up all fields</b> ",
                type: 'error',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Ok',
                background: '#2E3549',
                timer: 1500
            })
        }


    }

    const customStyles = {
          control: (base, state) => (colorError ? {
            ...base,
            background: "#2e3648",
            height:'30px',
            // match with the menu
            borderRadius: '5px',
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#323a4f" : "#A13D45",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
              // Overwrittes the different states of border
              borderColor: state.isFocused ? "#556ee6" : "#556ee6"
            },
          }:{
            ...base,
            background: "#2e3648",
            height:'30px',
            borderRadius: '5px',
            borderColor: state.isFocused ? "#323a4f" : "#323a4f",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {

              borderColor: state.isFocused ? "#556ee6" : "#556ee6",
            },
          }),

        container: (provided, state) => ({
          ...provided,
          marginTop: 0,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          overflow: "visible"
        }),
        placeholder: (provided, state) => (colorError ? {
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
            transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
            background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
            color: state.hasValue ? "#9BA3CA" :"#A13D45",
        }:{
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
            transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
            background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
            color: "#9BA3CA",
        }),
        
        option: (base, state) => ({
            ...base,
            color: '#9BA3CA',
            backgroundColor: state.isSelected ? '#32394A' : '#2A3143',
            ':hover': {
              backgroundColor: state.isSelected ? '#2A3143' : '#32394A'
            }
          }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        input: (provided, state) => ({
            ...provided,
            height: 35,
            color: '#9BA3CA',
            lineHeight: state.isFocused ? 35 : 0,
            transition:  "top 0.1s, font-size 0.1s"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: ' #9BA3CA',
            fontSize:"14px",
            fontWeight:'normal',
            top:'35%'
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            marginTop: '-15px',
          }),
      }

  // create the options
    const statusOptions = [
        { value: 1, label: 'Yes'},
        { value: 0, label: 'No'}
    ];
    const { ValueContainer, Placeholder } = components;

    const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused} className="menu">
            {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
            child && child.type !== Placeholder ? child : null
        )}
        </ValueContainer>
    );
    };

    return (
        <BodyBackgroundColor backgroundColor='#1C2233'>
         <div> 
            <div className="row">
                {/* <div className="col s6 mainTitle">Access Management</div>
                <div className="col s6 sub-title-main">Roles/{HeaderSub}</div> */}
            </div>
        
        <div className="mainContainer" >

        <form name="form" onSubmit={handleSubmit}>
            
            <div className="row table-card">
                
                <div className="table-header">
                    <span className="main-title">{HeaderContent}</span>
                </div>

                <div className="floating-form">

                    <div className={(isSubmitted && !role.roleName ? ' has-error' : '')} id="RolesInput1">
                        <div className="floating-label attrName"> 
                            <input  className="floating-input" type="text" placeholder=" "  name="roleName" value={role.roleName} onChange={handleChange} id="roleName"/>
                            <label>Role Name</label>
                            {isSubmitted && !role.roleName &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Role Name is required</div>
                            }
                        </div>
                    </div>

                    <div className={(isSubmitted && !role.roleSlug ? ' has-error' : '')} id="RolesInput2">
                        <div className="floating-label attrName"> 
                            <input  className="floating-input" type="text" placeholder=" " name="roleSlug" value={role.roleSlug} onChange={handleChange} id="roleSlug" />
                            <label>Role Slug</label>
                            {isSubmitted && !role.roleSlug &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Role Slug is required</div>
                            }
                            {specialChar &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Slug can't accept special characters</div>
                            }
                        </div>
                    </div>

                    <div className={(isSubmitted && !role.roleAll ? ' has-error' : '')} id="RolesInput3">

                            <Select 
                                className="basic-single"
                                classNamePrefix="select"
                                options={statusOptions} 
                                defaultValue={statusOptions.find(option => option.value === role.roleAll || '' )}
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                placeholder="Role All"
                                name="roleAll"
                                onChange={(statusValue)=> {handleChange({target: { name:'roleAll', value: statusValue === null ? '' : statusValue.value }})}}
                                maxMenuHeight={220}
                                menuPlacement="auto"
                                menuPortalTarget={document.querySelector('body')} 
                                styles={customStyles}
                                isSearchable={false}
                                isClearable={true}
                            />
                        {isSubmitted && role.roleAll === '' &&
                            <div className="help-block red-text-dark" data-error="wrong" data-success="right">Role All is required</div>
                        }

                    </div>


                </div>
                
                {Number(role.roleAll) === 0 && role.roleAll !== '' &&
                    <div className="role-permission-tbl col m6 s12">

                        <div className="permission-list">
                            <p className="sub-title">Select Role Permissions:</p>
                            <div style={{marginBottom:'15px'}}></div>
                            {props.permissions?.loading === false && props.permissions.permissions.items.map((per, index) =>
                                <div key={index}>
                                    <label key={per._id}>
                                    <input type="checkbox" className="filled-in" 
                                            value={per._id} id={per._id} name="checkPermission" 
                                            checked={role.permissions.map((p)=> p.permissions).includes(per._id) ? true : false} 
                                             onChange={() => selectedPermissions(per._id)}
                                    />
                                    <span className="permission-name">{per.permissionName}</span>
                                    </label>

                            {role.permissions.map((p)=> p.permissions).includes(per._id)}

                                {role.permissions.length > 0 && role.permissions.map((p)=> p.permissions).includes(per._id) && actions.map((action, index) =>
                                    <div key={index}>
                                        <label>
                                    <input type="checkbox" className="filled-in" value={action}
                                            checked={role.permissions.filter((p)=> p.permissions ===  per._id).map((a)=>a.actions)[0]?.includes(action) ? true : false}
                                            name="checkPermissionAction" 
                                            id={per._id + action}
                                           onChange={() => selectedActions(per._id, action)}
                                    />
                                    <span className="action-name">{action}</span>
                                    </label>
                                    </div>
                                )}

                        </div>
                            )}
                        </div>
                    </div>
                }

                {role.permissions[0] &&
                    <div className="role-permission-tbl col m6 s12">
                        <div className="permission-list">
                            <table>
                                <tbody>
                                    {/* <p className="sub-title">Role Permissions</p> */}
                                    <tr><td><label className="sub-title">Role Permissions</label></td></tr>
                                        {props.permissions?.loading === false && props.permissions.permissions.items
                                            .filter((p) => role.permissions.map((p)=>p.permissions).includes(p._id))
                                            .map((per, index) =>
                                                <tr key={index}>
                                                    <td>{per.permissionName}</td>
                                                </tr>
                                        )}
                                </tbody>
                            </table>
                            </div>
                    </div>
                }

                <div className="table-footer left-align RolesBtn clearfix">
                   <button className="waves-effect waves-light btn cancelDark" onClick={(e)=>{e.preventDefault(); props.history.goBack()}} >CANCEL</button>
                   <button className="waves-effect waves-light btn submitDark">SAVE</button>
                </div>
            </div>
        </form>
        </div>
        </div>
         </BodyBackgroundColor>
    )
});


// const RolesActionPage = connect(mapStateToProps)((props) => {
    
//     return (
//         <div
//             className={cc([
//                 'mainRoles',
//                 checkSideNav()?.trim()
//             ])}
//         >   

//            <RolesAction {...props}/>

//         </div>
//     )
// });
// RolesActionPage.WrappedComponent.displayName = 'RolesActionPage';
// const RolesActionPageRoute = (props) => (
// 	<Switch>
//         <Route
// 			exact
// 			path={`${props.match.path}`}
// 			render={(props) => <RolesActionPage {...props} mode="list" />}
// 		/>
		
// 	</Switch>
// );
export default RolesAction;