import React, { Component } from 'react'
import ReactImageFallback from "react-image-fallback";
import './TimesheetPage.sass';
import { connect } from 'react-redux';
import { timekeepingAction } from '../_core/actions/timekeeping';
import moment from 'moment';
import MainNav from '../Components/MainNav';
import avatar from '../_templates/man.png'
// import Loading from '../Components/Ellipsis-1s-60px.svg'
// import { timekeepingServices } from '../_core/services/timekeeping';


class TimesheetPage extends Component {

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('userCredentials'));
    this.props.dispatch(timekeepingAction.get(user.user.praggerUsername))
  }
  
  render() {
    const { timekeeping } = this.props;
    let a =  timekeeping.timekeeping ? timekeeping.timekeeping.allPrags : "no data"

    const t = Object.values(a).reduce(function(r, b) {
      r[moment(b.prag_in_at).format('LL')] = r[moment(b.prag_in_at).format('LL')] || [];
      r[moment(b.prag_in_at).format('LL')].push(b)
      return r;
    }, Object.create(null));

    var today = new Date()
    var loop = 30

    const final = [];
    let dayss = [];
    let dataday = [];
    
    Object.entries(t).map((data) => {
      dataday.push(
        data[0]
      )
      return data
    })
    
    for (let i = 1; i <= loop; i++) {
      dayss.push(
        moment(today).format('LL')
      )    
      today.setDate(today.getDate() - 1);
    }


    dayss.map((date, index) => {
      
      if(dataday.includes(date)){
        
        Object.entries(t).map((data) => {
  
          if(date === data[0]){
            
              //Get the first and last data
              var firstPragIn = data[1][data[1].length - 1]
              var lastPragOut = data[1][0]

              //Get the first In and Last Out
              var pragIn = moment(firstPragIn.prag_in_at);
              var pragOut = lastPragOut.prag_out_at ? moment(lastPragOut.prag_out_at) : pragIn;
              
              //Get the difference of time
              var actualRT = moment.duration(pragOut.diff(pragIn))
              
              //Removing "Hrs" in data that I get from API
              var requiredRT =  lastPragOut.requiredrt ? moment(lastPragOut.requiredrt.replace("Hrs", ""), 'HH:mm').format('HH:mm') : ""

              //Convert requiredRT to moment duration
              var rrt2 = moment.duration(requiredRT)

              // for testing only, to change required RT. just comment rrt2 above
              // var rrt2 = moment.duration(3, 'hours')
   
              //Computation of overtime
              var overtime = moment.duration(actualRT).subtract(rrt2)

              //Computation of undertime
              var undertime = moment.duration(rrt2).subtract(actualRT)
              
              //Push to array final
              final.push(
                <tr key={index}>
                  <td>{date}</td>
                  <td>{moment(firstPragIn.prag_in_at).format('LT')}</td>
                  <td>{lastPragOut.prag_out_at ? moment(lastPragOut.prag_out_at).format('LT') : "--:--:--" }</td>
                  <td>{lastPragOut.requiredrt}</td>
                  <td>{moment(actualRT.hours(), 'HH').format('HH') + ":" + moment(actualRT.minutes(), 'mm').format('mm') + ":" + moment(actualRT.seconds(), 'ss').format('ss')}</td>
                  <td>{overtime.hours() && overtime.minutes() > 0 ? moment(overtime.hours(), 'HH').format('HH') + ":" + moment(overtime.minutes(), 'mm').format('mm') + ":" + moment(overtime.seconds(), 'ss').format('ss') : "--:--:--"}</td>
                  <td>{undertime.hours() && undertime.minutes() < 0 ? "--:--:--" : moment(undertime.hours(), 'HH').format('HH') + ":" + moment(undertime.minutes(), 'mm').format('mm') + ":" + moment(undertime.seconds(), 'ss').format('ss')}</td>
                </tr>
              )
       
          }
          return data  
        })
        
      }else{
        
        final.push(
          <tr key={index}>
            <td>{date}</td>
            <td>--:--:--</td>
            <td>--:--:--</td>
            <td>--:--:--</td>
            <td>--:--:--</td>
            <td>--:--:--</td>
            <td>--:--:--</td>
          </tr>
        )
      }
      return date
    })



    let user = JSON.parse(localStorage.getItem('user'));

    return (
              
      <div className="container-fluid">
      <MainNav />

        <div className="header">
          <div className="card">
            <div className="imageName">
              
              <div className="image">
                <ReactImageFallback
                  alt="user avatar"
                  src={user.avatar}
                  fallbackImage={avatar}
                  initialImage={avatar}
                  className="dropdown-trigger circle responsive-img"
                  data-target=""
                  style={{border: "1px solid black"}}
                />
              </div>
              
              <div className="wraptext">
                <span className="name">{user.firstName} {user.lastName}</span>
                <span>{user.position}</span>
              </div>

            </div>

            
          </div>
        </div>

      {!final && <center><h5><em>No records found</em></h5></center>}

      {final &&
      <div className="timesheet">
        <div className="card">
          <div className="tableBoard">
            <table className="responsive-table highlight">
              <thead className="thead">
                <tr>
                  <th className="days">Days</th>
                  <th className="cIn">Clock In</th>
                  <th className="cOut">Clock Out</th>
                  <th className="reqRT">Required RT</th>
                  <th className="actRT">Actual RT</th>
                  <th className="ot">Overtime</th>
                  <th className="ut">Undertime</th>
                </tr>
              </thead>
              <tbody>
                {final}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      } 
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { timekeeping } = state;
  return {
    timekeeping
  }
}

export default connect(mapStateToProps)(TimesheetPage)
