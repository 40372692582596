import { supportTicketConstants } from '../constants/supportTicket';
import { supportTicketService } from '../services/supportTicket';
import { alertActions } from './alert';
import { history } from '../helpers/history';

export const supportTicketActions = {
    sendSupportTicket
};



function sendSupportTicket(supportTicket){

    return dispatch => {
        dispatch(request(supportTicket));

        supportTicketService.sendSupportTicket(supportTicket)
            .then(
                supportTicket => {
                    dispatch(success());
                    history.push('/profile');
                    dispatch(alertActions.success('Support Ticket Successfully Submitted'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );


    };   

    function request(supportTicket) { return { type: supportTicketConstants.SEND_SUPPORTTICKET_REQUEST, supportTicket } }
    function success(supportTicket) { return { type: supportTicketConstants.SEND_SUPPORTTICKET_SUCCESS, supportTicket } }
    function failure(error) { return { type: supportTicketConstants.SEND_SUPPORTTICKET_FAILURE, error} }

}

