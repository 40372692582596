import React, { forwardRef } from "react";
import cc from "classcat";
import { connect, useDispatch, } from "react-redux";
import DocumentTitle from "react-document-title";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import DatePicker from "react-datepicker";
import styled from "styled-components";


import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent,DynamicTableAndPaginationWrapper,DynamicEntriesOnly } from "../../Components/PageWrapper/DynamicWrapper";

import avatar from '../../../_templates/man.png';
import moment from 'moment';
import './TodayPageDark.sass'
import { timekeepingAction } from "../../../_core/actions/timekeeping";


const TimekeepingPage = styled.div`
  table {
    >thead>tr>th {
       &:last-child {
        text-align: inherit;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: inherit;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const LeaveTypeList = (props) => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [today, setToday] = React.useState(moment()); // eslint-disable-line
  const [entriesValues, setentriesValues] = React.useState(); //showEntries

  const datePickerRef = React.useRef(null);
  
  let tableHeader = ["Name", "Clock In", "Clock Out", "Required RT", "Actual RT", "Overtime", "Undertime"];

  React.useEffect(()=>{
    let startDates = moment(selectedDate).format("YYYY-MM-DDT00:00:00.000") + "Z"
    let endDates = moment(selectedDate).format("YYYY-MM-DDT00:00:00.000") + "Z"


    dispatch(timekeepingAction.getAllPrags(1,100000,startDates,endDates));
  },[dispatch,selectedDate])



    //showEntries
    const entriesValue = (entries) => {
     setentriesValues(entries);
    }


    const setStartDate = (date) => {
        setSelectedDate(moment(date))
    }

    /* eslint-enable */
    const ExampleCustomInput = forwardRef(({ onClick }, ref) => (
        <button className="btn selectDate" onClick={onClick}>
            <div className="date-picker">
                <p className="select-date-text" style={{marginLeft:'10px'}}>Select Date</p>
            </div>
        </button>
    ));

    const previousDate =(e)=> {
        e.preventDefault();
        var startdate = selectedDate
        var startdate1 = startdate.subtract(1, "days");
        console.log(moment(startdate1))
        setSelectedDate(moment(startdate1))
    }
        
    const nextDate=(e)=> {
        e.preventDefault();
        var startdate = selectedDate;
        var startdate1 = startdate.add(1, "days");
        setSelectedDate(moment(startdate1))
    }


    const formatTime = (undertime) => {

        //Parse the original string to extract hours, minutes, and seconds
        const matches = undertime.match(/(\d+)\sDays,\s(\d+)\sHrs,\s(\d+)\sMin,\s(\d+)\sSec/);
        if (!matches) return ''; // Return empty string if the format doesn't match
    
        // Extract hours, minutes, and seconds from the matches
        const days = parseInt(matches[1]);
        const hours = parseInt(matches[2]);
        const mins = parseInt(matches[3]);
        const secs = parseInt(matches[4]);
    
        // Convert total time to hours and minutes
        const totalHours = (days * 24) + hours + mins / 60 + secs / 3600;
        const formattedHours = Math.floor(totalHours);
        const formattedMins = Math.round((totalHours - formattedHours) * 60);
    
        // Format hours and minutes to display
        return `${formattedHours}:${formattedMins.toString().padStart(2, '0')} Hours`;

      
    };


  const LeaveTypeData = () => {
    let data = props.users?.users?.items ?? [];
    let pragsList = props.timekeeping?.timekeepings?.data ?? []
    
    return (
      <>
        {data.length !== 0 ? (
            data.map((item, index) => {
                // Find the corresponding prag item for the current user
                const userPrag = pragsList.find(prag => prag.username === item.praggerUsername);

                return (
                <tr key={index}>
                    <td className="praggerDetails">
                    <img
                        src={item.details[0].avatar}
                        onError={(e) => e.target.src = avatar}
                        alt="poster"
                    />
                    <span style={{textTransform:'capitalize'}}>
                        {`${item.details[0].firstName + " " + item.details[0].lastName}`}
                    </span>
                    </td>
                    <td>
                        {userPrag && userPrag?.prags[0]?.pragInAt === '--:--:--' 
                            ? '--:--:--' 
                            : userPrag?.prags[0]?.pragInAt && userPrag?.prags[0]?.pragInAt !== '--:--:--'
                                ? moment(userPrag?.prags[0]?.pragInAt).format("hh:mm A")
                                : '--:--:--'
                        }
                    </td>
                    <td>
                        {userPrag && userPrag?.prags[0]?.pragOutAt === '--:--:--' 
                        ? '--:--:--' 
                        : userPrag?.prags[0]?.pragOutAt && userPrag?.prags[0]?.pragOutAt !== '--:--:--'
                            ? moment(userPrag?.prags[0]?.pragOutAt).format("hh:mm A")
                            : '--:--:--'
                        }
                    </td>
                    <td>{userPrag && userPrag?.prags[0]?.requiredrt !== undefined ?  userPrag?.prags[0]?.requiredrt + " Hours" : '--:--:--'}</td>
                    <td>
                    {userPrag && userPrag?.prags[0]?.actualrt === '--:--:--' 
                        ? '--:--:--' 
                        : userPrag?.prags[0]?.actualrt && userPrag?.prags[0]?.actualrt !== '--:--:--'
                            ? formatTime(userPrag?.prags[0]?.actualrt) 
                            : '--:--:--'
                        }
                    </td>
                    <td>
                    {userPrag && userPrag?.prags[0]?.overtime === '--:--:--' 
                        ? '--:--:--' 
                        : userPrag?.prags[0]?.overtime && userPrag?.prags[0]?.overtime !== '--:--:--'
                            ? formatTime(userPrag?.prags[0]?.overtime) 
                            : '--:--:--'
                        }
                    </td>
                    <td>
                    {userPrag && userPrag?.prags[0]?.undertime === '--:--:--' && userPrag === undefined
                        ? '--:--:--' 
                        : userPrag?.prags[0]?.undertime && userPrag?.prags[0]?.undertime !== '--:--:--'
                            ? formatTime(userPrag?.prags[0]?.undertime) 
                            : '--:--:--'
                        }
                    </td> 
                </tr>
                );
            })
            ) : (
            <>
                <tr className="table-td-data">
                <td> 
                    <div>
                    <span style={{marginRight: '50px'}}>Loading Data...</span>
                    <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                </td>
                </tr>
            </>
            )}
      </>
    );
  };

  return (
    <TimekeepingPage>
        <div>
            <div className="timekeepingDate" style={{float:'left'}}>
                    <DatePicker
                        selected={new Date(selectedDate)}
                        onChange={date => setStartDate(date)}
                        showMonthDropdown
                        showYearDropdown
                        customInput={<ExampleCustomInput />}
                        maxDate={new Date()}
                        ref={datePickerRef}
                    />
            </div>



            <div className="dateDisplay">
                <div className="Row">
                    <div className="Column left-col">
                        <button className="btn btn-prev" onClick={(e) => previousDate(e)}>
                            <div className="arrow-left">
                                <p className="prevDateText" style={{textTransform:'capitalize'}}>
                                    {moment(selectedDate).subtract(1, "days").format("ddd, MM - D - YYYY")}
                                </p>
                            </div>
                        </button>          
                    </div>
                    <div className="Column center-col">
                        <p className="txtToday">{moment(selectedDate).format("ddd, MM - D - YYYY")}</p>
                    </div>
                    <div className="Column right-col">
                        {moment(selectedDate).format() === moment(today).format() ? "" :
                            <button className="btn btn-next" onClick={(e) => nextDate(e)}>
                                <div className="arrow-right">
                                    <p className="nextDateText"  style={{textTransform:'capitalize'}}>
                                        {moment(selectedDate).add(1, "days").format("ddd, MM - D - YYYY")}
                                    </p>
                                </div>
                            </button>
                        }
                    </div>
                </div>
            </div>

            <div className="timeEntries" style={{float:'right'}} >
                <DynamicEntriesOnly
                    buttonLabel="ADD LEAVE TYPES"
                    btnPath="/admin/today"
                    entriesValue={entriesValue}
                    originPage="Timekeeping"
                />
            </div>

        </div>

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={LeaveTypeData()}
        originPage="Users"
        data={props.users.users}
        location={props.location}
        paginationPath={"/admin/today"}
        entriesItem={entriesValues}
      />
    </TimekeepingPage>
  );
};



const mapStateToProps = ({users, timekeeping }) => ({
    users,
    timekeeping
  });

const TodayPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Today", link: "/admin/leave-type/overview?page=1" },
        ]);
        break;
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={ `Timekeeping | Today | ${process.env.REACT_APP_DOCUMENT_TITLE}`}
      >
        <PageWrapper className={cc(["LeaveTypesPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Timekeeping"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <LeaveTypeList {...props} />
              </ContentWrapper>
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});


TodayPage.WrappedComponent.displayName = "TodayPage";
const TodayPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <TodayPage {...props} mode="list" />}
    />
  </Switch>
);
export default React.memo(TodayPageRoute);
