import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Select from 'react-select';
import MainNav from '../Components/MainNav';
import HeaderPhoto from '../Components/HeaderPhoto';
import DetailPage from '../Components/DetailPage';
import RequestList from '../Components/RequestList';
import StatusPage from '../Components/StatusPage';
import UserTab from '../Components/UserTab';
import { leaveActions } from '../_core/actions/leave';
import { leaveTypeActions } from '../_core/actions/leaveType';
import { leaveCreditActions } from '../_core/actions/leaveCredit';
import './RequestTimeOff.sass';
import "../Admin/LeavesPage/dateInfo.sass";

class RequestTimeOff extends React.Component {
  
  constructor(props) {
		super(props);

        this.state = {
            leave:{
              dates: '',
              reason: '',
              leave_type: ''
            },
            startDate: '',
            endDate: '',
            render: true,
            submitted: false
        }
  }
  
  componentDidMount(){
    this.props.dispatch(leaveTypeActions.getAll());
    this.props.dispatch(leaveCreditActions.getAll());
  }

  handleSubmit=(e)=>{
    e.preventDefault();
    let user = JSON.parse(localStorage.getItem('userCredentials'));
    let leave_type = this.state.leave.leave_type;
    let numDays = this.state.leave.dates.slice(1, -1).split(',').length
    const { leaveCredits } = this.props;
    var dateFormat = moment().format("ddd, MMMM DD, YYYY");

    this.setState({ submitted: true })
    
    leaveCredits.leaveCredits && leaveCredits.leaveCredits.users.filter((credits)=> credits._id === user.user._id)
    .map((userCredit)=>{
      userCredit.leave_types.filter((credits)=>credits._id === leave_type).map((credit)=>{
        const leave = this.state.leave
          if(leave.leave_type && leave.dates && leave.reason){
            if(credit.available >= numDays){
              const leave = this.state.leave
              
              Swal.fire({
                title: 'Submit request?',
                html:
                    "<br> <b>Leave Type:</b> " +
                    credit.name +
                    "<br> <b>Number of days:</b>  " +
                    numDays,
                footer: 'Filing Date:  ' + dateFormat,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5CB85C',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit'
              }).then((result) => {
                  if (result.value) {
                    this.props.dispatch(leaveActions.fileLeave(leave));
                  }
              })
            }else{
              Swal.fire({
                title: 'Insufficient Credits',
                type: 'error',
                text: "you don't have enough credits for " + credit.name,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Ok',
              }).then(() => {
              })
            }
          }
        return credit
      })
      return userCredit
    })
  }

  getType = (e) => {
    const type = e.value;
    const { leave } = this.state

    this.setState({
      leave:{
        ...leave,
        leave_type: type,
      },
      // submitted: false
    },()=>{
      // console.log(this.state.leave)
    })
  }

  getReason = (e) => {
    e.preventDefault()
    const reason = document.getElementById("reason").value;
    const { leave } = this.state

    this.setState({
      leave:{
        ...leave,
        reason: reason,
      },
      // submitted: false
    },()=>{
      // console.log(this.state.leave)
    })
  }

  handleStart = (date) => {
    const start = date;

    this.setState({
      startDate: moment(start).format()
    },()=>{
      this.getDates()
    })
  }

  handleEnd = (date) => {
    const end = date;

    this.setState({
      endDate: moment(end).format()
    },()=>{
      this.getDates()
    })
  }

  getDates(){
    let startD = moment(this.state.startDate).isSameOrAfter(this.state.endDate) ? this.state.startDate : this.state.startDate ? this.state.startDate : this.state.endDate;
    let endD = moment(this.state.startDate).isSameOrAfter(this.state.endDate) ? this.state.startDate : this.state.endDate ? this.state.endDate : this.state.startDate;
    var dates = [];
    const { leave } = this.state

    for (var inputDates = moment(startD); inputDates.diff(endD, 'days') <= 0; inputDates.add(1, 'days')) {
        //if (moment(inputDates).day() !== 0 && moment(inputDates).day() !== 6) {
            dates.push(inputDates.format());
        //}
    }
    this.setState({
      leave: {
        ...leave,
        dates: dates.toString()
      },
      startDate: dates.toString().replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(',')[0],
      endDate: dates.toString().replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(/[, ]+/).pop(),
      // submitted: false
    })

    return dates
    
  }

  delDate = (date) => {
    const day = date
    const { leave } = this.state;
    
    const _dates = this.state.leave.dates.split("]").join("").split("[").join("").split("'").join("").split('"').join('').split(",").filter((_date) => {
        return _date !== day
    });

    this.setState({
      leave: {
        ...leave,
        dates: _dates.toString()
      },
      startDate: _dates.toString().replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(',')[0],
      endDate: _dates.toString().replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(/[, ]+/).pop(),
      // submitted: false
    },()=>{
      // console.log(this.state)
    })
  }

  lvTypeOptions(){ 
    const { leaveCredits } = this.props;
    let user = JSON.parse(localStorage.getItem('userCredentials'));
    var lvTypes = [];

    leaveCredits.leaveCredits && leaveCredits.leaveCredits.users.filter((credits)=> credits._id === user.user._id)
    .map((userCredit)=>{
      userCredit.leave_types.filter((credits)=> credits.name && credits.available > 0).map((credit, index)=>{
        lvTypes.push({
          value: credit._id,
          label: credit.name,
          key: index
        })
        return credit
      })
      return userCredit
    })
    return lvTypes
  }

  getLeaveCredits(){
    const { leaveCredits } = this.props;
    let user = JSON.parse(localStorage.getItem('userCredentials'));
    var credits = [];

    leaveCredits.leaveCredits && leaveCredits.leaveCredits.users.filter((credits)=> credits._id === user.user._id)
    .map((userCredit)=>{
      userCredit.leave_types.filter((credits)=> credits.name && credits.available > 0).map((credit)=>{
            credits.push({
              id: credit._id,
              type_name: credit.name ? credit.name : "Unknown",
              available: credit.available
            })
        return credit
      })
      return userCredit
    })
    return credits
  }

  render(){
    const dates = this.state.leave.dates;
    const {submitted} = this.state;

    const customStyles = {
      control: (base, state) => ({
        ...base,
        fontFamily: 'Poppins, sans-serif',
        border: state.isFocused ? '1px solid #e1e1d0 !important' : '1px solid #e1e1d0',
        boxShadow: state.isFocused ? '1px solid #e1e1d0' : '1px solid #e1e1d0',
        outline: 'none',
        height: '50px',
        padding: '0px 5px',
        alignContent: "center"
      }),
      input: (state) => ({
        fontFamily: 'Poppins, sans-serif',
        '& input': {
          font: 'inherit',
          textDecoration: state.isFocused ? 'none !important' : 0,
        },
      }),
      menu:(provided)  => ({
          ...provided,
          height: "auto",
          maxHeight: "150px",
          zIndex: "99",
          position: "absolute",
          display: "block",
      }),
      menuList:(provided)  => ({
          ...provided,
          height: "auto",
          maxHeight: "150px",
          padding: "2px 2px",
      }),
      option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? 'rgb(202,108,235)' : state.isFocused ? 'rgba(202,108,235,0.5)' : '',
          color: state.isSelected ? 'white' : state.isFocused ? 'white' : '',
          '& hover': {
            backgroundColor: 'rgb(202,108,235)',
          },
        }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
      }
    }

    return(
       <div className="profile">
        <MainNav />
        <HeaderPhoto />
        <UserTab />
        <div className="container-userdetail">
          <div className="col s12 m4">
            <div className="row">
              <div className="col s12 m3">
                  <DetailPage />
              </div>
              <div className="col s12 m9 l6">
                <div className="BodyCon">
                  <div className="userDetailCon">
                    <div className="row">
                        <RequestList/>
                        <div className="col s9 s9 l9 userDetailConLineLeft leave-req">
                        <div className="leave-request">
                          <div className="row" id="zero">
                            <div className="col s12 lvTitle">
                              <b>File for leave</b>
                              <b className="right">Filing Date:{' '}{moment().format('dddd, DD MMMM YYYY')}</b>
                              </div>
                            <div className="col s12 m12 l2 lvcreditsT">
                              <span className="creditTitle">Leave Credits:{'  '}</span>
                            </div>
                            <div className="col s12 m12 l10 lvcredits">
                              {this.getLeaveCredits().map((credit, index)=>
                                <p className="credit" key={index}>{credit.available + "  " + credit.type_name}</p>
                              )}
                            </div>
                            <div className="col s12">
                              <form className="row" onSubmit={this.handleSubmit}>
                                <div className="col s12 lvTypeTitle"><p>Type of Leave</p></div>
                                <div className="col s12 selectType">
                                  <Select 
                                      options={this.lvTypeOptions()}
                                      placeholder="Select Type"
                                      className="basic-single"
                                      styles={customStyles}
                                      onChange={this.getType}
                                  />
                                </div>
                                {submitted && !this.state.leave.leave_type &&
                                  <div className="help-block red-text" data-error="wrong" data-success="right">Leave type is required</div>
                                }
                                <div className="col s6 lvTypeTitle">
                                  <p>From</p>
                                  <div className="from-date">
                                    <DatePicker
                                      selected={this.state.leave.dates ? new Date(this.state.leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(',')[0]) : this.state.startDate ? this.state.startDate : ''}
                                      selectsStart
                                      startDate={this.state.leave.dates ? new Date(this.state.leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(',')[0]) : ''}
                                      endDate={this.state.leave.dates ? new Date(this.state.leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(/[, ]+/).pop()) : ''}
                                      //minDate={new Date()}
                                      //maxDate={this.state.endDate}
                                      //filterDate={this.isWeekday}
                                      showMonthDropdown
                                      showYearDropdown
                                      value={this.state.leave.dates ? moment(this.state.leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(',')[0]).format('MM - DD - YYYY') : ''}
                                      placeholderText="Choose date"
                                      onChange={this.handleStart}
                                      className="calendar"
                                    />
                                  </div>
                                </div>
                                <div className="col s6 lvTypeTitle">
                                  <p>To</p>
                                  <div className="to-date">
                                    <DatePicker
                                      selected={this.state.leave.dates ? new Date(this.state.leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(/[, ]+/).pop()) : this.state.endDate ? this.state.endDate : ''}
                                      selectsEnd
                                      startDate={this.state.leave.dates ? new Date(this.state.leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(',')[0]) : this.state.startDate ? this.state.startDate : ''}
                                      endDate={this.state.leave.dates ? new Date(this.state.leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(/[, ]+/).pop()) : this.state.endDate ? this.state.endDate :  ''}
                                      //minDate={this.state.leave.dates ? new Date(this.state.leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(',')[0]) : this.state.startDate ? this.state.startDate : new Date()}
                                      //filterDate={this.isWeekday}
                                      showMonthDropdown
                                      showYearDropdown
                                      value={this.state.leave.dates ? moment(this.state.leave.dates.replace("[", "").replace("]", "").split("'").join("").split('"').join('').split(/[, ]+/).pop()).format('MM - DD - YYYY') : ''}
                                      placeholderText="Choose date"
                                      onChange={this.handleEnd}
                                      className="calendar"
                                    />
                                  </div>
                                </div>
                                {submitted && !this.state.leave.dates &&
                                  <div className="help-block red-text" data-error="wrong" data-success="right">dates is required</div>
                                }
                                <div className="col s12 lvTypeTitle">
                                  <p>Inclusive Days <span>{dates ? " ( " + dates.slice(1, -1).split(',').length + " )" : ''}</span></p>
                                  <div className=" row dates-border">
                                    {dates ? dates.replace("[", "").replace("]", "").split("'").join("").split('"').join("").split(",").map((date, index) => {
                                      return <div key={index} className="col s4 date-align">
                                        <div className="date">
                                          <span className="day">{moment(date).format("MMMM D, YYYY")}</span><i className="material-icons cancel" onClick={this.delDate.bind(this, date)}>close</i>
                                        </div>
                                      </div>
                                    })
                                    :''}
                                  </div>
                                </div>
                                <div className="col s12 lvTypeTitle">
                                  <p>Reason</p>
                                  <div className="reason-border">
                                    <textarea className="reason-input" id="reason" onChange={this.getReason}></textarea>
                                  </div>
                                </div>
                                {submitted && !this.state.leave.reason &&
                                  <div className="help-block red-text" data-error="wrong" data-success="right">Your reason is required</div>
                                }
                                <div className="col s12 button-field">
                                  <button className="btn btn-submit" type="submit">Submit</button>
                                  <Link to="/user/leave" className="btn btn-cancel">Cancel</Link>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col s12 m3">
                <StatusPage />
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
	const { leaveTypes, leaveCredits } = state;
	return { leaveTypes, leaveCredits };
}

const connectedRequestTimeOff = connect(mapStateToProps)(RequestTimeOff);
export { connectedRequestTimeOff as RequestTimeOff };
