import React, { Component } from 'react'
import MainNav from '../Components/MainNav';
import { userActions } from '../_core/actions/user';
import { peopleActions } from '../_core/actions/people';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import avatar from '../_templates/man.png';
import ErrorPage from '../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../Components/ERRORPAGE/ButtonHome';
import error from '../_templates/error.png';
import './LeaderBoards.sass';

class LeaderBoards extends Component {
	constructor(props) {
        super(props);
        this.state = {
            users: []
        };
	}
	
	componentDidMount() {
		this.props.dispatch(userActions.getAllActive());
	}

	static getDerivedStateFromProps(props){
        return{
            users: props.users
        }
    }

	handleViewProfile = (id) => {
		 return this.props.dispatch(peopleActions.getById(id));
	}
	
	goBack(){
		this.props.dispatch(userActions.getAllActiveUsers(1));
	}

	render() {
		const {users} = this.state

		const allUsers = users.users && users.users.items
		
		var allUserDataTable = '';

		if(allUsers !== undefined){
			let allUserData = allUsers.sort((a, b) => Number(b.credit) - Number(a.credit))
			let creditData = allUserData.filter((allUserData)=>allUserData.credit!==0)
			let activeUser = creditData.filter((creditData)=>creditData.active===1)
			allUserDataTable = activeUser;
			var credit = allUserDataTable.map(a => a.credit)
			let ranking = credit.map(function (rank) {
				return function (a, i, aa) {
					return (a, aa[i - 1] === a ? rank : ++rank);
				};
			}(0));
			var rank = []
			for(let i = 0; i < allUserDataTable.length; i++){
				if(ranking[i] < i){
					rank.push(ranking[i - 1]);
				}
			}
			for(let a = 0;a < rank.length; a++)
			{
				var sameRank = allUserDataTable.splice(rank[a],1)
				allUserDataTable.push(sameRank[0])
			}
			allUserDataTable = allUserDataTable.filter((users) => users.active===1).slice(0,20)
		}
		var allUsers1 = allUserDataTable.slice(0, 1);
		var allUsers2 = allUserDataTable.slice(1, 2);
		var allUsers3 = allUserDataTable.slice(2, 3);

		const LeaderBoards = () => allUserDataTable.filter((users)=>users.active===1).slice(0, allUserDataTable.length - rank.length).map((user, index) => 
		<div key={index++}>
			<div className="row" style={{marginTop:"-20px"}}>
				<div className="col s2 m2">
					{allUserDataTable.filter((users)=>users.credit === allUserDataTable[index- 1].credit).slice(0, 1).map((user) =>
					<span className = "position-rank right"
						style= {{ 
							fontSize:
							allUsers1.includes(user) ? '30px' : 
							allUsers2.includes(user) ? '25px' : 
							allUsers3.includes(user) ? '25px' : 
							''
							, 
							marginTop: 
							allUsers1.includes(user) ? '15px' : 
							allUsers2.includes(user) ? '16px' : 
							allUsers3.includes(user) ? '16px' : 
							''
							}}
					><b>{index}</b></span>
					)}
				</div>
				<div className = "col s5 m4">
					<div className="position-image left"
						style= {{ 
						width: 
						allUsers1.includes(user) ? '80px' : 
						allUsers2.includes(user) ? '70px' : 
						allUsers3.includes(user) ? '70px' : 
						''
						, 
						height: 
						allUsers1.includes(user) ? '80px' : 
						allUsers2.includes(user) ? '70px' : 
						allUsers3.includes(user) ? '70px' : 
						''
						}}>
							{allUserDataTable.filter((users)=>users.credit === allUserDataTable[index- 1].credit).slice(0, 3).map((user) => 
							<div>
								<ReactImageFallback
								src={user.details[0].avatar}
								fallbackImage={avatar}
								initialImage={avatar}
								alt="user avatar"
								className="profile-photo avatar"/>
							</div>
							)}
					</div>
					<div className="position-image left"
						style= {{ 
						width: 
						allUsers1.includes(user) ? '80px' : 
						allUsers2.includes(user) ? '70px' : 
						allUsers3.includes(user) ? '70px' : 
						''
						, 
						height: 
						allUsers1.includes(user) ? '80px' : 
						allUsers2.includes(user) ? '70px' : 
						allUsers3.includes(user) ? '70px' : 
						''
						}}>
							{allUserDataTable.filter((users)=>users.credit === allUserDataTable[index- 1].credit).slice(1, 3).map((user) => 
							<div>
								<ReactImageFallback
								src={user.details[0].avatar}
								fallbackImage={avatar}
								initialImage={avatar}
								alt="user avatar"
								className="profile-photo avatar"/>
							</div>
							)}
					</div>
					<div className="position-image left"
						style= {{ 
						width: 
						allUsers1.includes(user) ? '80px' : 
						allUsers2.includes(user) ? '70px' : 
						allUsers3.includes(user) ? '70px' : 
						''
						, 
						height: 
						allUsers1.includes(user) ? '80px' : 
						allUsers2.includes(user) ? '70px' : 
						allUsers3.includes(user) ? '70px' : 
						''
						}}>	
							{allUserDataTable.filter((users)=>users.credit === allUserDataTable[index- 1].credit).slice(2, 20).map((user) => 
							<div>
								<ReactImageFallback
								src={user.details[0].avatar}
								fallbackImage={avatar}
								initialImage={avatar}
								alt="user avatar"
								className="profile-photo avatar"/>
								
							</div>
							)}
					</div>
					<div className="position-image1"
						style= {{ 
						width: 
						allUsers1.includes(user) ? '80px' : 
						allUsers2.includes(user) ? '70px' : 
						allUsers3.includes(user) ? '70px' : 
						''
						, 
						height: 
						allUsers1.includes(user) ? '80px' : 
						allUsers2.includes(user) ? '70px' : 
						allUsers3.includes(user) ? '70px' : 
						'',
						marginTop:'21px'
						}}>	
						<div>
							{allUserDataTable.filter((users)=>users.credit === allUserDataTable[index- 1].credit).slice(3, 4).map((user) => 
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+{rank.length % index}
							</div>
							)}
						</div>
					</div>
				</div>
				<div className="col s4 m4">
					<div className = "name">
						{allUserDataTable.filter((users)=>users.credit === allUserDataTable[index - 1].credit).slice(0, 1).map((user) =>
						<div className ="position-name left">
							<span style= {{ 
									fontSize: 
									allUsers1.includes(user) ? '30px' : 
									allUsers2.includes(user) ? '25px' : 
									allUsers3.includes(user) ? '25px' : 
									''
							}}>
								{user.details[0].firstName}
							</span>
						</div>
						)}
						{allUserDataTable.filter((users)=>users.credit === allUserDataTable[index - 1].credit).slice(1, 2).map((user) =>
						<div className ="position-name left">
							<span style= {{ 
									fontSize: 
									allUsers1.includes(user) ? '30px' : 
									allUsers2.includes(user) ? '25px' : 
									allUsers3.includes(user) ? '25px' : 
									''
							}}>
								,{user.details[0].firstName}
							</span>
						</div>
						)}
						{allUserDataTable.filter((users)=>users.credit === allUserDataTable[index - 1].credit).slice(2, 3).map((user) =>
						<div className ="position-name left">
							<span style= {{ 
									fontSize: 
									allUsers1.includes(user) ? '30px' : 
									allUsers2.includes(user) ? '25px' : 
									allUsers3.includes(user) ? '25px' : 
									''
							}}>
								,{user.details[0].firstName}
							</span>
						</div>
						)}
						{allUserDataTable.filter((users)=>users.credit === allUserDataTable[index - 1].credit).slice(3, 4).map((user) =>
						<div className ="position-name left">
							<span style= {{ 
									fontSize: 
									allUsers1.includes(user) ? '30px' : 
									allUsers2.includes(user) ? '25px' : 
									allUsers3.includes(user) ? '25px' : 
									''
							}}>
								........
							</span>
						</div>
						)}
					</div>
				</div>
				<div className ="col m1 s1" 
					style={{'fontSize' : 
						allUsers1.includes(user) ? '30px' : 
						allUsers2.includes(user) ? '25px' : 
						allUsers3.includes(user) ? '25px' : 
						''}}
					>{user.credit}
				</div>
			</div>
		</div> 
		)
		
		return (
			<div className="peoplesPage"> 
				<MainNav />

				{/* <LeaderBoards  /> */}
				<div className="container p-container">
					<Link to="/people" className="link-goback" onClick={this.goBack}>
                        <i className="material-icons backicon">keyboard_backspace</i> 
                        <span className="backtext">Go back</span>
                    </Link>

					<div className="center">
						<h5><b>Leaderboards</b></h5>
                        <h6>Top 20 heroes with the highest credits earned and experience gained</h6>
                        {/* <div className="row parent"> */}
						<h6><b>Top Earners</b></h6>
						<div className = "row">
							<div class="Rank">
								<span><b>Rank</b></span>
							</div>
							<div class="Hero">
								<span><b>Hero</b></span>
							</div>
							<div class="Credits">
								<span><b>Credits</b></span>
							</div>
						</div>
						<div className="people-page">
        	
							{ 
								users.error ? 
								<div className="container error-div">
									<ErrorPage/>
										<div className="text-danger">
											<img className="error" src={error} alt="error"/> 
												{users.error}
										</div>
									<ButtonHome/>
								</div> : 
							<div className="parallax-background"> 
								<div className="container">
									{users.error && <div className="text-danger">ERROR: {users.error}</div>}

											
											
									{users.loading && <em>Loading users...</em>}

									{users.users &&
										<div className="row">
											<div className="grid-container">
												<LeaderBoards/>
											</div>     
										</div>
									} 
								</div>
							</div>
							}
						</div>

                        {/* </div> */}

					</div>

				</div>

			</div>
		)
	}
}

function mapStateToProps(state) {
	const { users } = state;
    return {
        users,
    }
}

export default connect(mapStateToProps)(LeaderBoards)
