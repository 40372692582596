import React from 'react'
import { connect, useDispatch } from 'react-redux';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import "./MainAttribute.scss";
import { attributeActions } from '../../../_core/actions/attribute'
import Swal from 'sweetalert2';

const mapStateToProps = ({ creating, attribute, submitted, specialChar }) => ({
    creating,
    attribute, 
    submitted, 
    specialChar
});

const CreateAttribute = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    let HeaderContent = "Add Attributes";
    

    const [attribute, setAttribute] =  React.useState({ 
        attrName: '',
        attrCategory: '',
        attrType: '',
        attrSlug: '',
        active: '1'
});
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [specialChar, setChar] = React.useState(false); 


    
    const handleChange = (event) => {

        const { name, value } = event.target;
        var test = /^[ A-Za-z0-9-]*$/;

        if(name === "attrName"){
            setAttribute({
                    ...attribute,
                    [name]: value,
                    attrSlug: value.replace(/\s+/g, "-").replace(/[-]+/g, "-").replace(/[!^|@;&/\\#,+=()$~%._'":*?<>{}]/g, '').toLowerCase().replace(/^[-]+|[-]+$/g, "")
            });
        }else if(name === "attrSlug"){
            if(test.test(value) === true){
                setAttribute({
                        ...attribute,
                        [name]: value.toLowerCase().replace(/\s+/g, "-").replace(/[-]+/g, "-")
                })
                setChar(false)
            }else{
                if(value === ""){
                    setAttribute({
                            ...attribute,
                            [name]: value
                    })
                    setChar(false)
                }else{
                    setChar(true)
                }
            }
        }else{
            setAttribute({
                    ...attribute,
                    [name]: value
            });
        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        setIsSubmitted(true);
        if (attribute.attrName && attribute.attrSlug && attribute.attrCategory && attribute.attrType && attribute.active) {
            Swal.fire({
                title: '<p class="swal-subtitle-dark"> Are you sure?</p>',
                html:
                    "<br> <b class='swal-subtitle-dark'>Attribute Name:  "
                    + attribute.attrName +
                    "<br>Attribute Slug:  "
                    + attribute.attrSlug.replace(/^[-]+|[-]+$/g, "") +
                    "<br>Attribute Category: "
                    + attribute.attrCategory +
                    "<br>Attribute Type:"
                    + attribute.attrType + "</b>",

                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#24DC98',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Done!',
                background: '#2E3549',
            }).then((result) => {
                if (result.value) {
                     dispatch(attributeActions.create(attribute));
                }
            })
        }
    }
    
    return (
        <BodyBackgroundColor backgroundColor='#1C2233'>
        <div className="create_attribute" >


            <form name="form" onSubmit={handleSubmit}>


                <div className="table-card">
                    

                    <div className="table-header">
                        <span className="main-title">{HeaderContent}</span>
                    </div>

                    <div className={(isSubmitted && !attribute.attrName ? ' has-error' : '')} id="AttrLeftInput">

                        <div className="floating-label attrName"> 

                            <input  className="floating-input" type="text" placeholder=" "  name="attrName" value={attribute.attrName} onChange={handleChange} id="attrName"/>
                            <label>Name</label>
                            {isSubmitted && !attribute.attrName &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Attribute name is required</div>
                            }
                            
                        </div>

                     </div>

                    <div className={(isSubmitted && !attribute.attrCategory ? ' has-error' : '')} id="AttrRightInput">

                        <div className="floating-label attrName"> 

                            <input  className="floating-input" type="text" placeholder=" "  name="attrCategory" value={attribute.attrCategory} onChange={handleChange} id="attrCategory"/>
                            <label>Category</label>
                            {isSubmitted && !attribute.attrCategory &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Attribute category is required</div>
                            }
                            
                        </div>

                    </div>

                    <div className={(isSubmitted && !attribute.attrType ? ' has-error' : '')} id="AttrLeftInput">

                        <div className="floating-label attrName"> 

                            <input  className="floating-input" type="text" placeholder=" "  name="attrType" value={attribute.attrType} onChange={handleChange} id="attrType"/>
                            <label>Type</label>
                            {isSubmitted && !attribute.attrType &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Attribute type is required</div>
                            }
                            
                        </div>

                    </div>

                    <div className={(isSubmitted && !attribute.attrSlug ? ' has-error' : '')} id="AttrRightInput">

                        <div className="floating-label attrName"> 

                            <input  className="floating-input" type="text" placeholder=" "  name="attrSlug" value={attribute.attrSlug} onChange={handleChange} id="attrSlug" />
                            <label>Slug</label>
                            {isSubmitted && !attribute.attrSlug &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Attribute slug is required</div>
                            }
                            {specialChar &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Slug can't accept special characters</div>
                            }
                            
                        </div>

                    </div>

                <div className="table-footer">
                    <button className="waves-effect waves-light btn cancelDark" onClick={(e)=>{e.preventDefault(); props.history.goBack()}} >CANCEL</button>
                    <button className="waves-effect waves-light btn submitDark">SAVE</button>

                </div>
                </div>
            </form>
        </div>
    </BodyBackgroundColor>
    )
});


// const CreateAttributePage = connect(mapStateToProps)((props) => {
    

//     return (
//         <div
//             className={cc([
//                 'createAttribute',
//                 checkSideNav()?.trim()
//             ])}
//         >   
//             <div> 
//                 <div className="row">
//                     <div className="col s6 mainTitle"> Access Management</div>
//                     <div className="col s6 sub-title-main">Attributes/ Add Attributes</div>
//                 </div>
//              </div>
//            <CreateAttribute {...props}/>

//         </div>
//     )
// });
// CreateAttributePage.WrappedComponent.displayName = 'CreateAttributePage';
// const CreateAttributePageRoute = (props) => (
// 	<Switch>
//         <Route
// 			exact
// 			path={`${props.match.path}`}
// 			render={(props) => <CreateAttributePage {...props} mode="list" />}
// 		/>
		
// 	</Switch>
// );
export default CreateAttribute;