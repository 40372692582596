import React, { useState} from 'react'
// import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
// import { checkSideNav } from '../../Components/checkSideMenu';
// import { Route, Switch } from 'react-router-dom';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import "./MainLeaveType.scss";
import { leaveTypeActions } from '../../../_core/actions/leaveType';
import Swal from 'sweetalert2';
import Select, { components } from 'react-select';
import { leaveTypeService } from "../../../_core/services/leaveType";

const mapStateToProps = ({ creating, leaveType, submitted }) => ({
    creating,
    leaveType, 
    submitted, 
});

const LeaveType = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    let HeaderContent = "Create Leave Type";

    const [leaveType, setLeaveType] =  React.useState({ 
        name: '',
        description: '',
        type: '',
        accrue: '',
    });

    const [leaveTypeOption, setLeaveTypeOption] = useState();

    React.useEffect(() => {
        leaveTypeService.getAll().then((res) => { 
            const leaveTypes = res.items;

            const uniqueType = Array.from(leaveTypes.reduce((map, obj) => map.set(obj.type, obj),new Map()).values());
            const leaveTypes2 = uniqueType.map(leaveType => leaveType.type);

            let optionGroupData = [];
            leaveTypes2 && leaveTypes2.map((items) => {
                if (items.length !== 0) {
                optionGroupData.push({
                    label: items,
                    value: items
                });
                }
                return null
            });
        
            setLeaveTypeOption(optionGroupData);
        });
    },);


    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [colorError, setcolorError] = React.useState(false);
    const [accrueError, setAccrueError] = React.useState(false);

    //const [render, setRender] = React.useState(false);

    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    
    const handleNumber = (evt) => {
        if (rx_live.test(evt.target.value)){
            setLeaveType({ ...leaveType, accrue : evt.target.value });
        }

     }


    const handleChange = (event) => {
        const { name, value } = event.target;

        setLeaveType({
            ...leaveType,
            [name]: value
        });
        
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        setIsSubmitted(true)

        //select-validation-style
        if (leaveType.type !== "'") {
            setcolorError(true);
        };


        if (leaveType.name && leaveType.description && leaveType.type && leaveType.accrue) {

            let accrueChecking = leaveType.accrue.indexOf(".") === -1 //checking if accrue is in decimal format
            if(accrueChecking !== false){
                setAccrueError(true)
            }else{
                Swal.fire({
                    title: '<p class="swal-subtitle-dark">Are you sure?</p>',
                    html:
                        "<br> <b class='swal-subtitle-dark'>Leave Type Name:  "
                        + leaveType.name + "<br>Leave Type: "
                        + leaveType.type + "<br>Acrrue:  "
                        + leaveType.accrue,

                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#24DC98',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Done!',
                    background: '#2E3549',
                }).then((result) => {
                    if (result.value) {
                        dispatch(leaveTypeActions.addLeaveType(leaveType));
                        //setRender(true);
                    }
                })
            }

        }

    }

    const { ValueContainer, Placeholder } = components;

    const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused} className="menu">
            {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
            child && child.type !== Placeholder ? child : null
        )}
        </ValueContainer>
    );
    };

    const customStyles = {
        control: (base, state) => (colorError ? {
          ...base,
          background: "#2e3648",
          height:'47px',
          // match with the menu
          borderRadius: '5px',
          // Overwrittes the different states of border
          borderColor: state.isFocused ? "#323a4f" : "#A13D45",
          // Removes weird border around container
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#556ee6" : "#556ee6"
          },
        }:{
          ...base,
          background: "#2e3648",
          height:'47px',
          borderRadius: '5px',
          borderColor: state.isFocused ? "#323a4f" : "#323a4f",
          boxShadow: state.isFocused ? null : null,
          "&:hover": {

            borderColor: state.isFocused ? "#556ee6" : "#556ee6",
          },
        }),

      container: (provided, state) => ({
        ...provided,
        marginTop: 0,
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible"
      }),
      placeholder: (provided, state) => (colorError ? {
          ...provided,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
          transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
          fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
          background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
          color: state.hasValue ? "#9BA3CA" :"#A13D45",
      }:{
          ...provided,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
          transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
          fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
          background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
          color: "#9BA3CA",
      }),
      
      option: (base, state) => ({
          ...base,
          color: '#9BA3CA',
          backgroundColor: state.isSelected ? '#32394A' : '#2A3143',
          ':hover': {
            backgroundColor: state.isSelected ? '#2A3143' : '#32394A'
          }
        }),
      menuList: base => ({
          ...base,
          // kill the white space on first and last option
          padding: 0
      }),
      indicatorSeparator: state => ({
          display: 'none',
      }),
      input: (provided, state) => ({
          ...provided,
          height: 35,
          color: '#9BA3CA',
          lineHeight: state.isFocused ? 35 : 0,
          transition:  "top 0.1s, font-size 0.1s"
      }),
      singleValue: (provided) => ({
          ...provided,
          color: ' #9BA3CA',
          fontSize:"14px",
          fontWeight:'normal',
          top:'35%'
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          marginTop: '-15px',
        }),
    }
    
    return (
        <BodyBackgroundColor backgroundColor='#1C2233'>
        <div className="create_leave_type" >

            <form name="form" onSubmit={handleSubmit}>


                <div className="table-card">
                    

                    <div className="table-header">
                        <span className="main-title">{HeaderContent}</span>
                    </div>

                    <div class="row row-types">
                        <div class="col s6">

                            <div className={(isSubmitted && !leaveType.name ? ' has-error' : '')} id="AttrLeftInput">

                                <div className="floating-label attrName"> 

                                    <input  className="floating-input" type="text" placeholder=" "  name="name" value={leaveType.name} onChange={handleChange} />
                                    <label>Name of Leave</label>
                                    {isSubmitted && !leaveType.name &&
                                        <div className="help-block red-text-dark" data-error="wrong" data-success="right">Name is required</div>
                                    }
                                </div>

                            </div>
                        </div>
                        <div class="col s6">
                            
                        <div className={(isSubmitted && !leaveType.type ? ' has-error' : '')} id="RolesInput3">

                                <Select 
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={leaveTypeOption} 
                                    defaultValue={leaveTypeOption?.find(option => option.value === leaveType.type || '' )}
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    placeholder="Category"
                                    name="type"
                                    onChange={(statusValue)=> {handleChange({target: { name:'type', value: statusValue === null ? '' : statusValue.value }})}}
                                    maxMenuHeight={220}
                                    menuPlacement="auto"
                                    menuPortalTarget={document.querySelector('body')} 
                                    styles={customStyles}
                                    isSearchable={false}
                                    isClearable={true}
                                />
                                {isSubmitted && leaveType.type === '' &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Category is required</div>
                                }

                                </div>
                        </div>
                    </div>

                    <div className={(isSubmitted && !leaveType.accrue ? ' has-error' : '' || accrueError ? ' has-error' :  '')} id="accrue">

                        <div className="floating-label attrName"> 
                            <input 
                                className="floating-input" 
                                type="text" 
                                placeholder=" " 
                                name="accrue" 
                                maxLength={9} 
                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                value={leaveType.accrue} 
                                onChange={handleNumber}
                            />
                            <label>Accrue</label>
                            {isSubmitted && !leaveType.accrue &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Accrue is required</div>
                            }
                            {accrueError &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Accrue format should be 0.0</div>
                            }
                        </div>

                    </div>

                    <div className="floating-label" id="description">
                        <textarea 
                            className="floating-input floating-textarea coe-reason" 
                            value={leaveType.description}
                            name="description"
                            cols="80"
                            placeholder=" "
                            style={{resize:'none'}}
                            onChange={handleChange}
                            row="30">
                        </textarea>
                        <label className="floating-description">Description</label>
                    </div>

                <div className="table-footer">
                    <button className="waves-effect waves-light btn submitDark">CREATE</button>

                </div>
                </div>
            </form>
        </div>
    </BodyBackgroundColor>
    )
});


// const CreateLeaveType = connect(mapStateToProps)((props) => {
    

//     return (
//         <div
//             className={cc([
//                 'createLeaveType',
//                 checkSideNav()?.trim()
//             ])}
//         >   
//             <div> 
//                 <div className="row">
//                     <div className="col s6 mainTitle"> Leave Types</div>
//                     <div className="col s6 sub-title-main">Leave Types Overview/ Add Leave Type</div>
//                 </div>
//              </div>
//            <LeaveType {...props}/>

//         </div>
//     )
// });
// CreateLeaveType.WrappedComponent.displayName = 'CreateLeaveType';
// const LeaveTypePageRoute = (props) => (
// 	<Switch>
//         <Route
// 			exact
// 			path={`${props.match.path}`}
// 			render={(props) => <CreateLeaveType {...props} mode="list" />}
// 		/>
		
// 	</Switch>
// );

export default LeaveType;