import React from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../../_templates/dark/admin_calendar.png'

export const FloatWrapper = styled.div`
    position:relative; 
    margin-bottom:20px;
    font-family: ${props => props.theme.fontFamily};

    &:focus-within label.floating-text{
        color: #556ee6 !important;
    }

    .floating-input {
        font-size: 14px !important;
        padding:4px 4px;
        display:block;
        width:100%;
        height:30px;
        background-color: #2e3648;
        border: 1px solid #323a4f !important; 
        border-bottom: 1px solid #323a4f !important;
        text-indent: 10px;
        color: #9BA3CA;
        border-radius: 5px !important;
    }

    .is-error {
        border: 1px solid #f14646 !important;
        border-radius: 3px;
    }

    .is-error-label {
        color:#f14646 !important
    }


    .calendar-dark{
        background-image:url(${calendar});
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-size: 25px;
        background-position-y: 50%;
    }

    .floating-label { 
        position:relative; 
        margin-bottom:20px; 
    }

    > label.floating-text {
        color: #9BA3CA;
        font-size: 14px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 15px;
        top: 10px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    input[type=text]:focus, input[type=time]:focus, .floating-textarea:focus {
        border: 1px solid #556ee6 !important;
        border-bottom: 1px solid #556ee6 !important;
        outline: none;
        color: #9BA3CA;
        font-size: 14px;
        font-weight: normal;
    }

    input[type=text], textarea, input[type=time]  {
        background-color : #2e3648 !important; 
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s !important;
        -webkit-text-fill-color: #9BA3CA !important;
        -webkit-box-shadow: 0 0 0 30px #2e3648 inset !important;
        -webkit-animation: autofill 0s forwards !important;
        animation: autofill 0s forwards !important;
        caret-color: #9BA3CA !important;

    }

    .title {
        color: #9BA3CA;
        font-size: 14px;
        font-weight: 400;
        position: absolute;
        pointer-events: none;
        left: 15px;
        top: 10px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    #main_text > .title {
        top: -9px;
        left: 15px !important;
        font-size: 10px !important;
        font-weight: normal !important;
        color: #9BA3CA;
        background-color: #2A3143 !important;
    }

    .floating-input:focus ~ label.floating-text {
        color: #556ee6 !important;
    }

    input[type=text]:focus,
    input[type=time]:focus,
    .floating-textarea:focus {
       border: 1px solid #556ee6 !important;
       border-bottom: 1px solid #556ee6 !important;
        outline: none;
        color: #9BA3CA;
        font-size: 14px;
        font-weight: normal;
    }

    input[type=text], textarea, input[type=time]  {
        background-color : #2e3648 !important;
    }

    .floating-input:focus {
        outline:none;
        border-bottom:2px solid #5264AE; 
    }

    .floating-input:focus ~ label.floating-text, .floating-input:not(:placeholder-shown) ~ label.floating-text {
        top: -9px;
        left: 15px;
        font-size: 10px;
        font-weight: normal;
        color: #9BA3CA;
        background-color: #2A3143;
    }

    .floating-input:focus ~ label.floating-text, .floating-input:not(:placeholder-shown) ~ label.floating-text {
        top: -9px;
        left: 15px;
        font-size: 10px;
        font-weight: normal;
        color: #9BA3CA;;
        background-color: #2A3143;
    }

    .floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
        width:50%;    
    }

    .floating-textarea {
        min-height: 100px;
        max-height: 260px; 
        overflow:hidden;
        overflow-x: hidden;   
    }

    .floating-input:focus ~ .highlight {
        -webkit-animation:inputHighlighter 0.3s ease;
        -moz-animation:inputHighlighter 0.3s ease;
        animation:inputHighlighter 0.3s ease;
    }

    > small {
        font-size: 10px;
        font-weight: 400;
        color: #f14646;
        margin: 0 15px;
    }
    

`;

const theme = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    bg: "#2A3143"
  };


export const CustomTextArea = (props) => {
    return (
      <FloatWrapper className="floating-label" theme={theme}>
            <textarea
                className="floating-input floating-textarea"
                value={props.value}
                name="description"
                cols="30"
                placeholder=" "
                style={{ resize: 'none' }}
                row="10"
                onChange={props.onChange}
                readOnly= {props.readOnly}>
                                                        
                 {props.value ? props.value : ""}
            </textarea>
            <label className="floating-text">{props.label}</label>

      </FloatWrapper>
    );
  };
export const CustomTextAreaBadges = (props) => {
    return (
      <FloatWrapper className="floating-label" theme={theme}>
            <textarea
                className="floating-input floating-textarea"
                value={props.value}
                name={props.name} 
                cols="30"
                placeholder=" "
                style={{ resize: 'none' }}
                row="10"
                onChange={props.inputOnChange}
                maxlength={props.maxlength}
                readOnly= {props.readOnly}>
                
                 {props.value ? props.value : ""}
            </textarea>
        <label className="floating-text">{props.label}</label>
      </FloatWrapper>
    );
  };

  export const CustomInputTextBadges = (props) => {
    return (
      <FloatWrapper className="floating-label">
            <input  
                type="text" placeholder=" "  
                name={props.name} 
                value={props.value} 
                onChange={props.inputOnChange} 
                className={!props.value.trim() && props.isError ? "is-error floating-input" : "floating-input"}
            />
            <label className={!props.value.trim() && props.isError ? "is-error-label title floating-text" : "title floating-text"}>{props.label}</label>
      </FloatWrapper>
    );
  };

export const CustomDatePicker = (props) => {
    return (
      <FloatWrapper className="floating-label">
            <span id="main_text">
                <DatePicker           
                    selected={props.selected}
                    endDate={props.endDate}
                    maxDate={props.maxDate}
                    startDate={props.startDate}
                    onChange={props.onChange}
                    className={ props.isError ? "is-error floating-input calendar-dark" : "floating-input calendar-dark"}
                    id="abc"
                    placeholderText=" "
                    dateFormat="MM-dd-yyyy"
                    showMonthDropdown
                    showYearDropdown
                    readOnly= {props.readOnly}
                    value={props.value}
                />    
                <label className="title floating-text">{props.label}</label>
            </span>
            {props.isError && (
                <small>Please fill-up this field.</small>
            )}
      </FloatWrapper>
    );
  };

  export const CustomInputText = (props) => {
    return (
      <FloatWrapper className="floating-label">
            <input  
                type="text" 
                placeholder=" "  
                name={props.name} 
                value={props.value} 
                onChange={props.inputOnChange} 
                className={!props.value.trim() && props.isError ? "is-error floating-input" : "floating-input"}
                readOnly={props.readOnly}
                maxLength={props.maxLength}
            />
            <label className={!props.value.trim() && props.isError ? "is-error-label title floating-text" : "title floating-text"}>{props.label}</label>
            {!props.value.trim() && props.isError && (
                <small>Please fill-up this field.</small>
            )}
      </FloatWrapper>
    );
  };

  export const CustomTextAreaEngage = (props) => {
    return (
      <FloatWrapper className="floating-label" theme={theme}>
            <textarea
                className={props.isError ? "is-error floating-input floating-textarea" : "floating-input floating-textarea"}
                value={props.value}
                name={props.name}
                maxLength={props.maxlength}
                cols="30"
                placeholder=" "
                style={{ resize: 'none' }}
                row="10"
                onChange={props.inputOnChange}
                readOnly= {props.readOnly}>
                                                        
                 {props.value ? props.value : ""}
            </textarea>
        <label className={props.isError ? "is-error-label floating-text" : "floating-text"}>{props.label}</label>
        {props.isError && (
            <small>Please fill-up this field.</small>
        )}
      </FloatWrapper>
    );
  };