import React from 'react'
import Modal from 'react-modal'

import moment from 'moment';
import './OvertimePageModal.sass';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%'
    },
    overlay: {
        backgroundColor: 'rgba(100, 100, 100, 0.75)'
    }
};

class OvertimePageModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status: '',
            refCode: '',
            startedAt: '',
            endedAt: '',
            notedBy: '',
            noter_details: '',
            approvedBy: '',
            approver_details: '',
            description: '',
            notedRemark: '',
            rejectedRemark: '',
            approvalRemark: ''
        }
    }

    closeModal = event => {
        event.preventDefault()
        this.props.close()
    }

    componentDidMount() {
        this.setState({
            status: this.props.overtime.status,
            refCode: this.props.overtime.refCode,
            startedAt: this.props.overtime.startedAt,
            endedAt: this.props.overtime.endedAt,
            notedBy: this.props.overtime.notedBy,
            noter_details: this.props.overtime.noter_details,
            approvedBy: this.props.overtime.approvedBy,
            approver_details: this.props.overtime.approver_details,
            description: this.props.overtime.description,
            notedRemark: this.props.overtime.notedRemark,
            rejectedRemark: this.props.overtime.rejectedRemark,
            approvalRemark: this.props.overtime.approvalRemark
        })
    }

    render() {
        let { 
            startedAt, 
            refCode, 
            endedAt, 
            status, 
            notedBy, 
            noter_details, 
            approvedBy, 
            approver_details, 
            description,
            notedRemark,
            rejectedRemark,
            approvalRemark } = this.state

        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.close}
                style={customStyles}
            >
                <div className="opmDetailContainer">
                    <div className="row">
                        <div className="col s11 m11 l11">
                            <p className="opmTitleTxt"><b>Your Overtime Request</b></p>
                        </div>
                        <div className="col s1 m1 l1">
                            <button className="opmButtonCloseModal">
                                <i onClick={this.closeModal} className="small material-icons right">close</i>
                            </button>
                        </div>
                    </div>

                    <hr className="opmLines"/>

                    <div className="opmDetailCon">
                        <div className="opmDetailCons opmDetailCons1">
                            <p className="opmTxt"><b>Status</b></p>
                            <p style={{textTransform:"capitalize"}} className={status === "on hold" ? "onHold" : status}>{status ? status === "new" ? "in-review" : status : "none"}</p>
                        </div>
                        <div className="opmDetailCons opmDetailCons2">
                            <p className="opmTxt"><b>Reference Code</b></p>
                            <p>{refCode ? refCode  : 'No Code'}</p>
                        </div>
                        <div className="opmDetailCons opmDetailCons3">
                            <p className="opmTxt"><b>Date</b></p>
                            <p>{moment(startedAt).utc().format('MMMM DD YYYY')}</p>
                        </div>
                        <div className="opmDetailCons opmDetailCons4"> 
                            <p className="opmTxt"><b>From</b></p>
                            <p>{moment(startedAt).utc().format('h:mm a')}</p>
                        </div>
                        <div className="opmDetailCons opmDetailCons5">
                            <p className="opmTxt"><b>To</b></p>
                            <p>{moment(endedAt).utc().format('h:mm a')}</p>
                        </div>
                        <div className="opmDetailCons opmDetailCons6">
                            <p className="opmTxt"><b>Noted by</b></p>
                            <p>{notedBy && noter_details[0].firstName && noter_details[0].lastName ? notedBy && noter_details[0].firstName +' '+ noter_details[0].lastName : "No name specified"}</p>
                        </div>
                        <div className="opmDetailCons opmDetailCons7">
                            <p className="opmTxt"><b>Approved by</b></p>
                            <p>{approvedBy && approver_details[0].firstName && approver_details[0].lastName ? approver_details[0].firstName + ' ' + approver_details[0].lastName : 'No name specified'}</p>
                        </div>
                    </div>

                    <div className="clearfix">
                        <div className="row opmDetailConsA">
                            <p><b>Reason: </b>
                            <span className="opmSpanTxt">{description}</span></p>
                        </div>
                    </div>

                    <hr className="opmLines" />

                    <div>
                        {notedRemark && status !== "new"? 
                            <div className="col s12 m12 l12">
                                <p className="opmTxt1" style={{color: "orange", marginTop: "0"}}>On hold remark : <span style={{color: "black", fontWeight:"normal", textTransform:"lowercase"}} className="opmSpanTxt">{notedRemark}</span></p>
                            </div>
                        :''}
                        {rejectedRemark ?
                            <div className="col s12 m12 l12">
                                <p className="opmTxt1" style={{color: "red", marginTop: "0"}}>Rejection remark : <span style={{color: "black", fontWeight:"normal", textTransform:"lowercase"}} className="opmSpanTxt">{rejectedRemark}</span></p>
                            </div>
                        :''}
                        {approvalRemark ?
                            <div className="col s12 m12 l12">
                                <p className="opmTxt1" style={{color: "green", marginTop: "0"}}>Approval remark : <span style={{color: "black", fontWeight:"normal", textTransform:"lowercase"}} className="opmSpanTxt">{approvalRemark}</span></p>
                            </div>
                        :''}
                    </div>
                </div>
            </Modal>
        )
    }
}

export default OvertimePageModal;