import { notifConstants } from '../constants/notification';


export function notifs(state = {}, action){
    switch(action.type){
        case notifConstants.GET_NOTIF_REQUEST:
          return {loading: true};
        case notifConstants.GET_NOTIF_SUCCESS:
          return {notif: action.notif};
        case notifConstants.GET_NOTIF_FAILURE:
          return {error: action.error};
        
        case notifConstants.GET_NOTIFPAGE_REQUEST:
          return {loading: true};
        case notifConstants.GET_NOTIFPAGE_SUCCESS:
          return {notif: action.notif};
        case notifConstants.GET_NOTIFPAGE_FAILURE:
          return {error: action.error};

        default:
          return state
    }
}