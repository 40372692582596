import React from 'react';
import moment from 'moment';
import './RequestHistory.scss';
import leaveIcon from '../_templates/ess_leave_icon.png';
import medIcon from '../_templates/ess_leave_med_icon.png';
import empIcon from '../_templates/ess_briefcase_icon.png';
import techIcon from '../_templates/ess_tech_icon.png';
import otIcon from '../_templates/ess_ot_icon.png';

const RequestHistory = ({ requestHistoryList, leaveTypeList }) => {
    const requests = requestHistoryList;
    const leaveTypes = leaveTypeList;
    const userDetails = JSON.parse(localStorage.getItem("user"));
    const currentUser = [userDetails?.firstName, userDetails?.lastName].join(" ");

    const status = {
        new: "new",
        hold: "on hold",
        approve: "approved",
        reject: "rejected",
        complete: "completed"
    }

    const requestTypes = {
        leave: "leave",

        sickLeave: "sick",
        medical: "medical",
        overtime: "overtime",
        undertime: "undertime",
        employment: "certificate of employment",
        concern: "hr concern",
        tech: "technical support"
    }

    const ActivityItem = ({ item }) => {

        const getRequestType = (type, subtype) => {
            var requestType = subtype?.toLowerCase();
            var requestIcon = leaveIcon;

            if (type === requestTypes.leave) {
                requestType = leaveTypes?.filter((lt) => lt._id === subtype)[0]?.name;
                requestType = requestType?.toLowerCase().includes(requestTypes.leave) ? requestType : requestType + ` ${requestTypes.leave}`

                requestIcon = leaveTypes?.filter((lt) => lt._id === subtype)[0]?.name?.toLowerCase()?.includes(requestTypes.sickLeave) || leaveTypes?.filter((lt) => lt._id === subtype)[0]?.name?.toLowerCase()?.includes(requestTypes.medical) ? medIcon : leaveIcon;

            } else {
                if (subtype === requestTypes.concern) {
                    requestType = "HR " + subtype?.slice(2, subtype.length);
                    requestIcon = empIcon;
                }
                const getSubType = subtype?.toLowerCase();
                requestIcon = getSubType === requestTypes.overtime || getSubType === requestTypes.undertime ? otIcon : getSubType === requestTypes.employment ? empIcon : getSubType === requestTypes.tech ? techIcon : leaveIcon
            }

            return {requestType, requestIcon}
        }

        const {requestType, requestIcon} = getRequestType(item.type, item.subtype);
        const vowels = ("aeiouAEIOU");

        return (
            <div className="ess_request_history_item">
                <div>
                    <div>{/** icons**/}
                        <img src={item?.icon || requestIcon} alt="ess_icon" />
                    </div>
                </div>
                <div>{/**desc **/}
                    {item.status === status.new ?
                        <p><span className="ess_request_type">{currentUser}</span> requested {vowels.indexOf(requestType[0]) !== -1 ? "an" : "a"} <span className="ess_request_type">{requestType}</span></p>
                        : item.status ?
                            <p>Your request for <span className="ess_request_type">{requestType}</span> <span>{item.status === status.hold ? `is on hold` : `has been ${item.status}`}!</span></p>
                            : ""}
                    <p>{moment(item.createdAt).fromNow()}</p>
                    <p>{item.description}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="ess_request_history_wrapper">
            <h6>Requests History</h6>
            <div className="ess_request_history_content">
                {requests?.map((item, index) =>
                    <ActivityItem item={item} key={index} />
                )}
            </div>
        </div>
    )
}

export default RequestHistory;