import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { timekeepingAction } from '../../../_core/actions/timekeeping';
// import SideMenu from '../Components/SideMenu';
import ErrorPage from '../../../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../../../Components/ERRORPAGE/ButtonHome';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import error from '../../../_templates/error.png';
import './UserTimesheetDark.sass';
import moment from 'moment';
import ReactImageFallback from "react-image-fallback";
import avatar from '../../../_templates/man.png';
// import loading from '../../Components/Ellipsis-1s-60px.svg';
import {checkSideNav} from '../../Components/checkSideMenu'; //adjust component width if admin sidenav is open

import { userActions } from '../../../_core/actions/user';

const formatTime = (undertime) => {

    //Parse the original string to extract hours, minutes, and seconds
    const matches = undertime.match(/(\d+)\sDays,\s(\d+)\sHrs,\s(\d+)\sMin,\s(\d+)\sSec/);
    if (!matches) return ''; // Return empty string if the format doesn't match

    // Extract hours, minutes, and seconds from the matches
    const days = parseInt(matches[1]);
    const hours = parseInt(matches[2]);
    const mins = parseInt(matches[3]);
    const secs = parseInt(matches[4]);

    // Convert total time to hours and minutes
    const totalHours = (days * 24) + hours + mins / 60 + secs / 3600;
    const formattedHours = Math.floor(totalHours);
    const formattedMins = Math.round((totalHours - formattedHours) * 60);

    // Format hours and minutes to display
    return `${formattedHours}:${formattedMins.toString().padStart(2, '0')} Hours`;

  
};

class UserTimesheetPageDark extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentValue: 10   
        }

        this.thirtyDays = this.thirtyDays.bind(this);
        this.getPrags = this.getPrags.bind(this);
        this.getData = this.getData.bind(this);
        this.getUserDetails = this.getUserDetails.bind(this);
    }

    componentDidMount() {
        // Call the thirtyDays function to get the start and end dates
        const { start, end } = this.thirtyDays();
    
        // Get the user from the URL params
        const user = this.props.match.params.user;
    
        // Dispatch the API call with the start and end dates
        this.props.dispatch(timekeepingAction.getUser(user, start, end));
        this.props.dispatch(userActions.getAll(1, 100000));
    }
    
    thirtyDays() {
        // Get the current date
        const currentDate = moment();
    
        // Calculate the start date (30 days ago)
        const startDate = currentDate.clone().subtract(29, 'days').startOf('day');
    
        // Calculate the end date (today)
        const endDate = currentDate.clone().endOf('day');
    
        // Create an array to hold the dates
        const thirtyDays = [];
    
        // Loop through each day from start to end date and add to the array
        for (let date = startDate.clone(); date.isSameOrBefore(endDate, 'day'); date.add(1, 'day')) {
            thirtyDays.push({ date: date.format('YYYY-MM-DD') });
        }
    
        // Return both the array of thirty days and an object containing the start and end dates
        return {
            thirtyDays: thirtyDays.reverse(),
            start: startDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z', // Format start date as needed
            end: endDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z', // Format end date as needed
        };
    }
    
    getPrags(){
        const { timekeeping } = this.props
    
        let timeRecord =  timekeeping.timekeeping ? timekeeping.timekeeping.data : ""
        
        var data = []

        //console.log(timeRecord)

        if(timeRecord){
         
            const recordPerDate = Object.values(timeRecord[0] || {}).reduce(function(prags, pragArray) {
                if (Array.isArray(pragArray)) {
                    pragArray.forEach(function(prag) {
                        // Check if prag has the pragInAt property and if it's a valid date
                        if (prag && prag.pragInAt && moment(prag.pragInAt).isValid()) {
                            const dateKey = moment(prag.pragInAt).format('LL');
                            prags[dateKey] = prags[dateKey] || [];
                            prags[dateKey].push(prag);
                        }
                    });
                }
                return prags;
            }, {});

        

              for(var record in recordPerDate){
      
                var obj = recordPerDate[record];
                
                //get first and last data
                var firstPragIn = obj[obj.length - 1]
                var lastPragOut = obj[0]

                //Get the first In and Last Out
                //var pragIn = moment(firstPragIn.pragInAt);
                //var pragOut = lastPragOut.pragOutAt ? moment(lastPragOut.pragOutAt) : pragIn;

                

                //Get the difference of time
                // var actualRT = moment.duration(pragOut.diff(pragIn))

                // //Removing "Hrs" in data that I get from API
                // var requiredRT =  lastPragOut.requiredrt ? moment(lastPragOut.requiredrt.replace("Hrs", ""), 'HH:mm').format('HH:mm') : ""

                // //Convert requiredRT to moment duration
                // var rrt2 = moment.duration(requiredRT)
    
                // //Computation of overtime
                // var overtime = moment.duration(actualRT).subtract(rrt2)

                // //Computation of undertime
                // var undertime = moment.duration(rrt2).subtract(actualRT)

                data.push({
                    date: moment(firstPragIn.pragInAt).format("YYYY-MM-DD"),
                    //username: username,
                    prag_in: firstPragIn.pragInAt,
                    prag_out: lastPragOut.pragOutAt,
                    required_rt: moment(lastPragOut.requiredrt, 'HH:mm').format('HH:mm'),
                    actual_rt: lastPragOut.actualrt && lastPragOut.actualrt !== '--:--:--' ? formatTime(lastPragOut.actualrt): '',
                    overtime: lastPragOut.overtime && lastPragOut.overtime !== '--:--:--' ? formatTime(lastPragOut.overtime): '',
                    undertime: lastPragOut.undertime && lastPragOut.undertime !== '--:--:--' ? formatTime(lastPragOut.undertime): '',
                })
              }
        }

        return data
    }

    getUserDetails(){
        const { users } = this.props
        const username = this.props.match.params.user;
        let userList =  users.users ? users.users.items : ""
        let userDetails = userList?.find(user => user.praggerUsername === username);

        return userDetails
    }

    getData(){
        var data = []
        var pragDates =[]
        const thirtyDays = this.thirtyDays()
        const getPrags = this.getPrags()
        /* eslint-enable */
        getPrags.map((prag)=>{
            pragDates.push(prag.date)
            return prag
        })

        thirtyDays.thirtyDays.map((day) => {
            if(pragDates.includes(day.date)){
                getPrags.map((prag)=>{
                    if(day.date === prag.date){
                    data.push({
                        date: prag.date,
                        username: prag.username,
                        prag_in_at: prag.prag_in,
                        prag_out_at: prag.prag_out,
                        required_rt: prag.required_rt,
                        actual_rt: prag.actual_rt,
                        overtime: prag.overtime,
                        undertime: prag.undertime
                    })
                    }
                    return prag
                })
            }else if(day.date){
                data.push({
                    date: day.date
                })
            }
            return day
        })
        return data
    }

    topArrowClicked = () =>{
        if(this.state.currentValue === 10){
          this.setState({
              currentValue: this.state.currentValue + 20
          }); 
        }
        else{
            this.setState({
                currentValue: this.state.currentValue
            });
        }
    }

    bottomArrowClicked = () => {
        if(this.state.currentValue === 30){
            this.setState({
                currentValue: this.state.currentValue - 20
            }); 
          }
          else{
              this.setState({
                  currentValue: this.state.currentValue
              });
          }
    }

 

    render() {
        const { timekeeping } = this.props
        const userDetails = this.getUserDetails()

        const InputArrows = ({ topArrowClicked, bottomArrowClicked }) => {

             return (
                 <div className="arrows-component">
                     <div className="arrows">
                         <button onClick={this.topArrowClicked}>
                             <i className="fa fa-angle-up" aria-hidden="true"></i>
                         </button>
                         <button onClick={this.bottomArrowClicked}>
                             <i className="fa fa-angle-down" aria-hidden="true"></i>
                         </button>
                     </div>
                 </div>
             );
         };

        return (
            <BodyBackgroundColor backgroundColor='#222736'>
                <div className={"tsBodyContainer" + checkSideNav()}>
                    {/* <SideMenu /> */}
                    {timekeeping.error ?
                    <div className="container error-div">
                        <ErrorPage />
                        <div className="text-danger">
                            <img className="error" src={error} alt="error" />
                            {timekeeping.error}
                        </div>
                        <ButtonHome />
                    </div> :
                    <div>
                        <div className="row valign-wrapper">
                            <div className="col s12 m12 l12" id="zero">
                                <h5 className="text-left" id="header">Timekeeping</h5>
                                <p className="tkTitle">
                                    <span className="tkTxt1"><Link to='/admin/timekeeping/reports'><b>Reports</b></Link></span>
                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAFo9M/3AAAAAXNSR0IArs4c6QAAAL5JREFUOBFjYEAG2cUdVch8ytg5pe3BGCZklXSkwgVzins04BySGNmlXUZZRe07kTWxIHMY/zKoMjAxXkEWoze7oWE+B7qdTMgCrz6/eIZNEbIaBqA3/6MIIHOyijr+IPOHC5sRm0dyKyeJ/v319SbDf4bTYnwS/g0NiT+wqQOJYTUApji3qN3wHwPjnv8M/8/gMgglNcE0wuh/TIyqDP//8zEyMl7B5QqsBoC88O/n13tAg2ZN7a1khRk4OGkAIGg5ahMEf20AAAAASUVORK5CYII=" id="chevron-rigth" alt="" />
                                    <span className="tkTxt2"><b>Result</b></span> 
                                </p>
                            </div>
                        </div>
					{timekeeping.timekeeping &&
                        <React.Fragment>
                        <div className="user-details">
							<div className="row info" id="zero">
                                <div className="col s12 m2 l1 user-pic">
                                    <ReactImageFallback
                                        src={userDetails.details[0].avatar}
                                        fallbackImage={avatar}
                                        initialImage={avatar}
                                        alt="user avatar"
                                        className="avatar circle responsive-img"
                                        />
                                </div>
                                <div className="col s12 m10 l11 details">
                                    <p className="name">{userDetails.details[0].firstName} {userDetails.details[0].lastName}</p>
                                    <p className="position">{userDetails.praggerUsername}</p>
                                </div>
                            </div>
                        </div>
                    
                        
						<div className="timesheet-page-container"> 
                            <div className="card-panel clearfix">
                            <div className="block">
                            <span className="darkmode-btn-default entries"> Show Entries </span>
                                    <div className="custom-input">
                                        <input type="text" className="entries-btn" name="clicks" value={this.state.currentValue} pattern="[0-9]*" onChange={e => this.handlEntries(e)} />
                                        <span className="arrows">
                                            <InputArrows/>
                                        </span>
                                    </div>
                                    </div>
                                <table className="responsive-table highlight time-in ">
                                    <thead>
                                        <tr>
                                            <th>Days</th>
                                            <th>Clock In</th>
                                            <th>Clock Out</th>
                                            <th>Required RT</th>
                                            <th>Actual RT</th>
                                            <th>Overtime</th>
                                            <th>Undertime</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.getData().slice(0, 30).map((prag, index)=>
                                        <tr key={index}>
                                            <td>{moment(prag.date).format('MMMM D, YYYY')}</td>
                                            <td>{prag.prag_in_at ? moment(prag.prag_in_at).format("hh:mm A") : "--:--:--"}</td>
                                            <td>{prag.prag_out_at ? moment(prag.prag_out_at).format("hh:mm A") : "--:--:--"}</td>
                                            <td>{prag.required_rt ? prag.required_rt : "--:--:--"}</td>
                                            <td>{prag.actual_rt ? prag.actual_rt : "--:--:--"}</td>
                                            <td>{prag.overtime ? prag.overtime : "--:--:--"}</td>
                                            <td>{prag.undertime ? prag.undertime : "--:--:--"}</td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
						</div>
                        </React.Fragment>
                    }
                    </div>
                    }
                </div>
            </BodyBackgroundColor>
        );

    }
}

function mapStateToProps(state) {
    const { timekeeping,users } = state;
    return {
        timekeeping,
        users
    };
}


const connectedUserTimesheetPage = connect(mapStateToProps)(UserTimesheetPageDark);
export { connectedUserTimesheetPage as UserTimesheetPageDark };