import { userChallengeConstants } from '../constants/userChallenge';
import { userChallengeService } from '../services/userChallenge';
import { alertActions } from './alert';

export const userChallengeActions = {
    getByUser,
    getByChallenge,
    acceptQuestChallenge,
    finishQuestChallenge,
    completeQuestChallenge,
    postAcceptQuestChallenge,
    quitQuestChallenge,
    deleteQuestChallenge,
    failQuestChallenge,
    getQuestParticipants,
    getUserQuestChallenges,
    completeQuest,
    revertQuest
}

function getByUser(id, page, itemsPerPage){
    return dispatch => {
        dispatch(request());
        userChallengeService.getByUser(id, page, itemsPerPage)
        .then(
            userChallenges => dispatch(success(userChallenges)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.GET_USER_CHALLENGES_REQUEST } }
    function success(userChallenges) { return { type: userChallengeConstants.GET_USER_CHALLENGES_SUCCESS, userChallenges } }
    function failure(error) { return { type: userChallengeConstants.GET_USER_CHALLENGES_FAILURE, error } } 
}

function getByChallenge(uid, cid){
    return dispatch => {
        dispatch(request());
        userChallengeService.getByChallenge(uid, cid)
        .then(
            userChallenge => dispatch(success(userChallenge)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.GET_USER_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.GET_USER_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.GET_USER_CHALLENGE_FAILURE, error } } 
}

function acceptQuestChallenge(uid, cid){
    return dispatch => {
        dispatch(request());
        userChallengeService.accceptQuestChallenge(uid, cid)
        .then(
            userChallenge => dispatch(success(userChallenge.success)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.ACCEPT_QUEST_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.ACCEPT_QUEST_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.ACCEPT_QUEST_CHALLENGE_FAILURE, error } }
}

function finishQuestChallenge(uid, cid, files){
    return dispatch => {
        dispatch(request(uid,cid));
        userChallengeService.finishQuestChallenge(uid, cid, files)
        .then(
            userChallenge => {
                dispatch(success(userChallenge));
                // setTimeout(function(){
                //     dispatch(userChallengeActions.getByChallenge(uid,cid))}
                // , 2000);
                dispatch(alertActions.success('User successfully finish the Quest challenge'));
            },    
            error => {
                dispatch(failure(error.toString()))
                dispatch(alertActions.error('Something went wrong'));
            }
        );
    };

    function request() { return { type: userChallengeConstants.FINISH_QUEST_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.FINISH_QUEST_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.FINISH_QUEST_CHALLENGE_FAILURE, error } }
}

function completeQuestChallenge(uid, cid){
    return dispatch => {
        dispatch(request());
        userChallengeService.completeQuestChallenge(uid, cid)
        .then(
            userChallenge => dispatch(success(userChallenge.success)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.COMPLETE_QUEST_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.COMPLETE_QUEST_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.COMPLETE_QUEST_CHALLENGE_FAILURE, error } }
}

function quitQuestChallenge(uid, cid){
    return dispatch => {
        dispatch(request());
        userChallengeService.quitQuestChallenge(uid, cid)
        .then(
            userChallenge => dispatch(success(userChallenge.message)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.QUIT_QUEST_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.QUIT_QUEST_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.QUIT_QUEST_CHALLENGE_FAILURE, error } }
}

function postAcceptQuestChallenge(uid, challenge){
    return dispatch => {
        dispatch(request());
        userChallengeService.postAcceptQuestChallenge(uid, challenge)
        .then(
            userChallenge => dispatch(success(userChallenge.success)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.POST_QUEST_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.POST_QUEST_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.POST_QUEST_CHALLENGE_FAILURE, error } }
}


function deleteQuestChallenge(uid, cid){
    return dispatch => {
        dispatch(request());
        userChallengeService.deleteQuestChallenge(uid, cid)
        .then(
            userChallenge => dispatch(success(userChallenge.message)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.DEL_QUEST_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.DEL_QUEST_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.DEL_QUEST_CHALLENGE_FAILURE, error } }
}

function failQuestChallenge(uid, cid){
    return dispatch => {
        dispatch(request());
        userChallengeService.failQuestChallenge(uid, cid)
        .then(
            userChallenge => dispatch(success(userChallenge.success)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.FAIL_QUEST_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.FAIL_QUEST_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.FAIL_QUEST_CHALLENGE_FAILURE, error } }
}

function getQuestParticipants(qid, page, itemsPerPage){
    return dispatch => {
        dispatch(request());
        userChallengeService.getQuestParticipants(qid,page,itemsPerPage)
        .then(
            userChallenge => dispatch(success(userChallenge)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.GET_USER_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.GET_USER_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.GET_USER_CHALLENGE_FAILURE, error } } 
}

function getUserQuestChallenges(id,qid){
    return dispatch => {
        dispatch(request());
        userChallengeService.getUserQuestChallenges(id,qid)
        .then(
            userChallenge => dispatch(success(userChallenge)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.GET_USER_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.GET_USER_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.GET_USER_CHALLENGE_FAILURE, error } } 
}


function completeQuest(uid, qid){
    return dispatch => {
        dispatch(request());
        userChallengeService.completeQuest(uid, qid)
        .then(
            userChallenge => dispatch(success(userChallenge.success)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.COMPLETE_QUEST_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.COMPLETE_QUEST_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.COMPLETE_QUEST_CHALLENGE_FAILURE, error } }
}

function revertQuest(uid, qid){
    return dispatch => {
        dispatch(request());
        userChallengeService.revertQuest(uid, qid)
        .then(
            userChallenge => dispatch(success(userChallenge.success)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: userChallengeConstants.UPDATE_QUEST_CHALLENGE_REQUEST } }
    function success(userChallenge) { return { type: userChallengeConstants.UPDATE_QUEST_CHALLENGE_SUCCESS, userChallenge } }
    function failure(error) { return { type: userChallengeConstants.UPDATE_QUEST_CHALLENGE_FAILURE, error } }
}