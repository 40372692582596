import { get, put, post } from '../helpers/api';
import { userService } from './user';
import { leaveTypeService } from './leaveType';
import leaveIcon from '../../_templates/ess_leave_icon.png';
import medIcon from '../../_templates/ess_leave_med_icon.png';
import empIcon from '../../_templates/ess_briefcase_icon.png';
import techIcon from '../../_templates/ess_tech_icon.png';
import otIcon from '../../_templates/ess_ot_icon.png';

export const essService = {
    getAll,
    getById,
    getAllByUser,
    create,
    update,
    createActivity,
    getActivitiesByUser,
    notificationBell,
    getAllAdminUser
}

function getAll(type = "leave", page = 1, itemsPerPage = 20) {
    return get(`/api/v1/request?filter=type:${type}&page=${page}&items-per-page=${itemsPerPage}`)
        .then(handleResponse);
}

function getById(id) {
    return get(`/api/v1/request/${id}`)
        .then(handleResponse)
}

function getAllByUser(userId, page = 1, itemsPerPage = 20) {
    return get(`/api/v1/request?filter=active:1,user_id:${userId}&page=${page}&items-per-page=${itemsPerPage}`)
        .then(handleResponse);
}

function getActivitiesByUser(id) {
    return get(`/api/v1/user/${id}/activity`)
        .then(handleResponse);
}

function createActivity(id) {
    // 	Request Types:
    // 		LEAVE_REQUEST = leave
    // 		REQUEST_OVERTIME = request
    // 		EMPLOYMENT_CONCERN = support
    // 		TECHNICAL_SUPPORT = support
    // 		CERTIFICATE_OF_EMPLOYMENT = request
    // 	request subtypes:
    // 		leaveType:_id
    // 		overtime
    // 		undertime
    // 		certificate of employment
    // 	support subtypes:
    // 		hr concern
    // 		technical support

    const requestTypes = {
        leave: "leave",

        sickLeave: "sick",
        medical: "medical",
        overtime: "overtime",
        undertime: "undertime",
        employment: "certificate of employment",
        concern: "hr concern",
        tech: "technical support"
    }

    getById(id).then(data => {
        const request = data?.success[0] || {};

        let activityDraft = {
            "_id": request._id,
            "user_id": request.user_id,
            "title": request.subject,
            "description": request.description,
            "status": request.status,
            "type": request.type,
            "subtype": request.subtype,
            "icon": "",
            "image": ""
        }

        if(request._id) {
            if (request.type === requestTypes.leave) {
                leaveTypeService.getLeaveByID(request.subtype).then(leaveType => {
                    if (leaveType?.success?.length > 0) {
                        const isSickLeave = leaveType?.success[0].name?.toLowerCase().includes(requestTypes.sickLeave) || leaveType?.success[0].name?.toLowerCase().includes(requestTypes.medical);
                        const essIcon = isSickLeave ? medIcon : leaveIcon

                        const activity = {
                            ...activityDraft,
                            icon: essIcon
                        }
                        
                        return post(`/api/v1/user/${activity.user_id}/activity`, JSON.stringify(activity))
                            .then(handleResponse);
                    }
                });

            } else {
                const essIcon = request.subtype === requestTypes.overtime || request.subtype === requestTypes.undertime ? otIcon
                    : request.subtype === requestTypes.tech ? techIcon
                        : request.subtype === requestTypes.concern || request.subtype === requestTypes.employment ? empIcon
                            : "";
    
                const activity = {
                    ...activityDraft,
                    icon: essIcon
                }
    
                return post(`/api/v1/user/${activity.user_id}/activity`, JSON.stringify(activity))
                    .then(handleResponse);
            }
        }
    });
}

function create(request) {

    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
     const imageFile = request.files;
     const fd = new FormData();
     fd.append('file',imageFile)

    // //checking if post images has a content file
    if(isFile(request.files) === true){
        request.files = null;
    }
    
    return post(`/api/v1/request`, JSON.stringify(request))
        .then(handleResponse)
        .then(response => {
            const id = response._id;
            console.log(response)
            if (id) {
                createActivity(id) 
                leaveRequestEmail(id)
                notificationBell(request, response)
            }
            if(isFile(imageFile) === true){
                uploadFiles(id,fd)
            }
        });
}

function uploadFiles(id , files){
    return post(`/api/v1/upload/${id}/request`, files).then(handleResponse);
}

function leaveRequestEmail(id){
    const userDetails = JSON.parse(localStorage.getItem("userCredentials"));
    let body = {
        "email": userDetails.user.email,
        "link": window.location.origin+"/employee-self-service",
        "adminLink": window.location.origin+"/admin/leave-requests/review/1/"+id
    }
    return post(`/api/v1/mail/${id}/leave`, body).then(handleResponse);
}

function notificationBell(request, response){

    const contentD = 
    response.type !== undefined ? 
        "/admin/leave-requests/review/1/" + response._id 
    : request.subtype === "certificate of employment" ? 
        "/admin/coe-requests/review/1/" + response._id 
    : request.subtype === "overtime" ? 
        "/admin/overtime-requests/review/1/" + response._id
    : request.subtype === "undertime" ? 
        "/admin/undertime-requests/review/1/" + response._id
    : request.subtype === "hr concern" ? 
        "/admin/hr-concerns/review/1/" + response._id      
    : request.subtype === "technical support" ? 
        "/admin/technical-supports/review/1/" + response._id        
    : request.subtype === "feedback" ? 
        "/admin/feedback/review/1/" + response._id                 
    : ""; // Default fallback

    getAllAdminUser()
    .then(result => {

        // Iterate through each admin user and send notification
        const notificationPromises = result.items.map(adminUser => {
            const notifDetails = {
                "type": response.type !== undefined ? response.type : request.subtype,
                "title": request.subject,
                "content": contentD,
                "receiverId": adminUser._id // Send notification to individual admin user
            };
            return post(`/api/v1/notification`, notifDetails).then(handleResponse);
        });

        // Wait for all notifications to be sent
        return Promise.all(notificationPromises);
    })
    .then(notificationResponses => {
        console.log('Notifications sent:', notificationResponses);
        // Handle response if needed
    })
    .catch(error => {
        console.error(error); // Handle any errors
    });

    
}


function update(request, status = "") {
    let id = request._id;
    delete request._id;

    //console.log(request)
    return put(`/api/v1/request/${id}${status ? "/" + status : ""} `, JSON.stringify(request))
        .then(handleResponse)
        .then(response => {
            console.log(response)
            if (status) {
                createActivity(id);
                leaveStatusEmail(response.data, id);
                //notificationBell(request, response)
            }
        });
}

function leaveStatusEmail(request, id) {
    let body = {
        "link": window.location.origin + "/employee-self-service/view/" + id,
        "adminLink": window.location.origin + "/admin/leave-requests/review/1/" + id
    };

    //console.log(request)

    return post(`/api/v1/mail/${id}/status`, body)
        .then(handleResponse)
        .then(response => {
            //console.log(response)
            if (
                (response && response.receiver && response.receiver.length !== 0 && request && request.status === 'approved') ||
                (response && response.receiver && response.receiver.length !== 0 && request && (request.status === 'rejected' || request.status === 'on hold'))
              ) {
                return Promise.all(response.receiver.map((item, index) => {
                    const notifDetails = {
                        "type": "request",
                        "title": request.status +" : "+response.type,
                        "content": window.location.origin + "/admin/leave-requests/review/1/" + id,
                        "receiverId": item ,// Send notification to individual admin user
                        "request_userId": request.user_id,
                    };

                    // Return the promise from post() to ensure proper chaining
                    return post(`/api/v1/notification`, notifDetails).then(handleResponse);
                }));
            } else {
                // Return a resolved promise if no userIds were found
                return Promise.resolve();
            }
        })
        .catch(error => {
            // Handle errors here
            console.error('Error:', error);
            // You may want to throw the error or handle it in some other way
            throw error;
        });
}


function getAllAdminUser(page = 1, itemsPerPage = 9999999) {
    return get(`/api/v1/users/admin?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}