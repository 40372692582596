import { expConstants } from '../constants/exp';


export function exps(state = {}, action){
    switch(action.type){
        case expConstants.GET_EXP_REQUEST:
          return {loading: true};
        case expConstants.GET_EXP_SUCCESS:
          return {exp: action.exp};
        case expConstants.GET_EXP_FAILURE:
          return {error: action.error};
        default:
          return state
    }
}