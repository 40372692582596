import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from "react-redux";
import moment from 'moment';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

import { UserDashboardContext } from "../UserDashboard/UserDashboard";

import info from '../_templates/popUp-info.png';
import warningSign from '../_templates/timesheet-alert.png';
import redInfoDot from '../_templates/info-red-dot.png';
import greenInfoDot from '../_templates/info-green-dot.png';
import chevLeft from '../_templates/chevron-left.png';

import './Timekeeping.scss';
import './TimekeepingDatePicker.sass';

import Footer from '../Footer/Mainfooter.js';
import ProfileWidget from '../ProfileWidget/ProfileWidget';
import TimekeepingWidget from '../TimekeepingWidget/TimekeepingWidget';
import DailyTimesheet from './Timesheet/DailyTimesheet';
import CalendarTimesheet from './Timesheet/CalendarTimesheet';
import { timesheetActions } from '../_core/actions/timesheet';


const InfoToggle = (props) => {

	const [isInfoShow, info_setIsToggled] = React.useState(true);
	const info_toggle = React.useCallback(() => info_setIsToggled(!isInfoShow)); // eslint-disable-line 

	return(
		<div className="info-container">
			<div className="info-container-relative">
				<button onClick={info_toggle}>
					<div className="center">
						<img src={info} alt="icon" height="auto" width={20} />
					</div>
				</button>

				<div className={cc(["info-container-absolute", isInfoShow === false ? "info-active" : ''])}>
					<div className="info-container-absolute__arrow"></div>
					<table className="info-description" style={{ width: "100%", border: "none" }}>
						<tbody>
							<tr style={{ border: "none" }}>
								<td style={{ textAlign: "center", verticalAlign: "middle" }}>
									<img src={greenInfoDot} alt="icon" height="auto" width={6} /> </td>
								<td>Total number of hours</td>
							</tr>
							<tr style={{ border: "none" }}>
								<td style={{ textAlign: "center", verticalAlign: "middle" }}>
									<img src={redInfoDot} alt="icon" height="auto" width={6} /> </td>
								<td>Overtime/Undertime</td>
							</tr>
							<tr style={{ border: "none" }}>
								<td><img src={warningSign} alt="icon" height="auto" width="100%" /> </td>
								<td>Warning sign for issues on the day <br /> (no pragin/pragout/absent without leave)</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

const MonthlyDatePicker = (props) => {
	const firstDate = moment(props.startOf).format('MMMM D, YYYY')|| "";
	const lastDate = moment(props.endOf).format('MMMM D, YYYY') || "";
	const error = props.isError;
	const IsCutOff = props.IsPayday;
	const MonthPaydayTrack = props.PayDayTrack;

	const [getDate, setGetDate] = React.useState(new Date());

	// Set default date
	const [SaveCurrentDate, setSaveCurrentDate] = React.useState(new Date());  // eslint-disable-line 

	const [monthPlaceHolder, setmonthPlaceHolder] = React.useState(null)
	const [isDefault, setisDefault] = React.useState(true)


	const changeMonth = React.useCallback((date, trigger ='', CheckCurrentDate= null, firstLoad = false) => {
		
		if(!error){

		
			if(IsCutOff === false) {

				if(date === firstDate) {
					const setDate =  moment(date).subtract(1, 'days');
					setGetDate(new Date(date));
					props.selectedDate(moment(setDate));
	
					// Calendar Props
					props.isPrevious("prev") 	
					props.CalendarDate(moment(setDate).format("YYYY-MM-DD"))

				} else {
				
	
					if(trigger === "gotoDate"){
						const setDate = moment(date);
						setGetDate(new Date(date));
						props.selectedDate(moment(setDate));
	
						// Calendar Props
						props.isPrevious("gotoDate")
						props.CalendarDate(moment(setDate).format("YYYY-MM-DD"))
					} else {
					
						const setDate =  moment(date).add(1, 'days');
						setGetDate(new Date(date));
						props.selectedDate(moment(setDate));
					
						// Calendar Props
						props.isPrevious("next") 
						props.CalendarDate(moment(setDate).format("YYYY-MM-DD"))
			
					}
				
				}
			} else {
				
			

				let IsRangeFilter = moment(moment(CheckCurrentDate).startOf('months')).add(5, 'days').format("YYYY-MM-DD")
				
				let FilterDate;
				if(firstLoad === true){
					
					if(moment(IsRangeFilter).isSame(firstDate, 'days')){
						setisDefault(false)
						if(trigger === "prev"){
							FilterDate = moment(moment(moment(CheckCurrentDate).startOf('months')).subtract(1, 'months')).add(20, 'days');
						}
						if(trigger === "next"){
							FilterDate = moment(moment(CheckCurrentDate).startOf('months')).add(20, 'days');
						}
						
					} else {
						setisDefault(false)
						if(trigger === "prev"){
							FilterDate = moment(moment(CheckCurrentDate).startOf('months')).add(5, 'days');
						}
						if(trigger === "next"){
							FilterDate = moment(moment(moment(CheckCurrentDate).startOf('months')).add(1, 'months')).add(5, 'days');
						}
					}
				} else {
					let prevRangeFilter = moment(moment(firstDate).startOf('month')).add(5, 'days').format("YYYY-MM-DD");
					if(moment(prevRangeFilter).isSame(firstDate, 'days')){ // 6th day
						if(trigger === "prev"){
							FilterDate = moment(moment(moment(firstDate).startOf('months')).subtract(1, 'months')).add(20, 'days');
						}
						if(trigger === "next"){
							FilterDate = moment(moment(firstDate).startOf('months')).add(20, 'days');
						}
					} else {  // 21 current month
						if(trigger === "prev"){
							FilterDate = moment(moment(firstDate).startOf('months')).add(5, 'days');
						}
						if(trigger === "next"){
							FilterDate = moment(moment(moment(firstDate).startOf('months')).add(1, 'months')).add(5, 'days');
						}
					}
				}
			
			
				if(trigger === "prev"){
					const setDate =  moment(FilterDate).format("YYYY-MM-DD");
					let cutOffDate =   { date: moment(setDate), calendarSwitch: 'prev' }
					setGetDate(new Date(date));
					props.selectedDate(cutOffDate);
	
					// Calendar Props
					props.isPrevious("prev") 	
					props.CalendarDate(moment(setDate).format("YYYY-MM-DD"))
				

				}

				if(trigger === "next"){
					const setDate =  moment(FilterDate);
					let cutOffDate =   { date: moment(setDate), calendarSwitch: 'next' }
					setGetDate(new Date(date));
					props.selectedDate(cutOffDate);
				
					// Calendar Props
					props.isPrevious("next") 
					props.CalendarDate(moment(setDate).format("YYYY-MM-DD"))
		
				}
	
				if(trigger === "gotoDate"){
				
					const setDate = moment(date);
					let cutOffDate =   { date: moment(setDate), calendarSwitch: 'gotoDate' }
					setGetDate(new Date(date));
					props.selectedDate(cutOffDate);

					// Calendar Props
					props.isPrevious("gotoDate")
					props.CalendarDate(moment(setDate).format("YYYY-MM-DD"))
				} 
			
			
			}
		}
	},[firstDate, props, error, MonthPaydayTrack]); // eslint-disable-line 


	React.useEffect(() => {
		setGetDate(moment().toDate());
	}, [IsCutOff])

	React.useEffect(() => {
		setmonthPlaceHolder( IsCutOff === false ? moment(firstDate).format('MMMM YYYY') : moment(firstDate).format("MMM DD, YYYY") + " - " + moment(lastDate).format("MMM DD, YYYY"))
	}, [firstDate, getDate])  // eslint-disable-line 


	return(
		<div className="timesheet-dates">
			<div className="ts-btn-prev" onClick={() => changeMonth(firstDate, "prev", SaveCurrentDate, isDefault, IsCutOff)}>
				<img alt="<" src={chevLeft}/>
			</div>
			<div className="ts-dates-text-calendar">
			
				{!error ?
				<DatePicker
					className="ts-datepicker-ui-calendar"
					selected={getDate}
					value=""
					// placeholderText={moment(firstDate).format('MMMM YYYY') }
					placeholderText={monthPlaceHolder}
					onChange={ (date)=> changeMonth(date, 'gotoDate', SaveCurrentDate, isDefault, IsCutOff) }
					showMonthDropdown
					showYearDropdown
				/>
				:
				<></>
				}
			
				
			</div>
			<div className="ts-btn-next" onClick={() => changeMonth(lastDate, "next", SaveCurrentDate, isDefault, IsCutOff)}>
				<img alt=">" src={chevLeft}/>
			</div>
		</div>
	)
}

const WeeklyDatePicker = (props) => {
	const firstDate = props.sunDate || "";
	const lastDate = props.satDate || "";
	const error = props.isError;

	const [getDate, setGetDate] = React.useState(new Date());

	const changeWeek = React.useCallback((date) => {
		if(!error){
			if(date === firstDate){
				const setDate = moment(date).subtract(1, 'days');
				setGetDate(new Date(date));
				props.selectedDate(moment(setDate));
			}else{
				const setDate = moment(date).add(1, 'days');
				setGetDate(new Date(date));
				props.selectedDate(moment(setDate));
			}
		}
	},[firstDate, props, error]);

	return(
		<div className="timesheet-dates">
			<div className="ts-btn-prev" onClick={() => changeWeek(firstDate)}>
				<img alt="<" src={chevLeft}/>
			</div>
			<div className="ts-dates-text">
				{!error ?
				<DatePicker
					className="ts-datepicker-ui"
					selected={getDate}
					value=""
					placeholderText={moment(firstDate).add(1, 'days').format('MMM D, YYYY') + " - " + moment(lastDate).add(1, 'days').format('MMM D, YYYY')}
					onChange={ (date)=> changeWeek(date) }
					showMonthDropdown
					showYearDropdown
				/>
				:
				<></>
				}
			</div>
			<div className="ts-btn-next" onClick={() => changeWeek(lastDate)}>
				<img alt=">" src={chevLeft}/>
			</div>
		</div>
	)
}

const TimeKeeping = (props) => {
	
	const device = React.useContext(UserDashboardContext).device;
	const dispatch = useDispatch();
	const [isDaily, setIsToggled] = React.useState(true);
	const toggle = React.useCallback(() => setIsToggled(!isDaily)); // eslint-disable-line 

	const { timesheet } = props;
	const { timesheetNotFound } = props;
	const [selectedWeek, setSelectedWeek] = React.useState(moment()); // eslint-disable-line 
	// const [selectedMonth, setSelectedMonth] = React.useState(moment()); // eslint-disable-line 
	const [oneWeek, setOneWeek] = React.useState([]);
	const [oneMonth, setOneMonth] = React.useState([]); // eslint-disable-line 
	const [calendarUpdate, setMonthCalendar] = React.useState(null); 
	const [CalendarDateUpdate, setCalendarDate] = React.useState(null); 
	// const [monthDate, setmonthDate] = React.useState(null);
	const [PaydayRange, setPaydayRange] = React.useState(null);
	const [startCutOffCurrentDate, setstartCutOffCurrentDate] = React.useState(true)
	// CutOff
	const [isCutOff, setIsToggledCutOff] = React.useState(false);

	const secret = JSON.parse(localStorage.getItem('secretCredentials'))
	

	//if isDaily === true, get selected week
	const weekSelected = React.useCallback((date)=>{
		const user = JSON.parse(localStorage.getItem('userCredentials'));
		const unknown = secret ? secret.praggerUsername : user.user.praggerUsername

		var currentDate = date;
		var weekStart = currentDate.startOf('week');
		var getWeek = [];
		for (var i = 0; i <= 6; i++) {
			getWeek.push(moment(weekStart).add(i, 'days').format('LL'));
		};

		setOneWeek(getWeek);

		const firstDay = moment(getWeek[0]).format('YYYY-MM-DD') + "T00:00:00.00Z";
		const lastDay = moment(getWeek[getWeek.length - 1]).format('YYYY-MM-DD') + "T00:00:00.00Z";
		dispatch(timesheetActions.getByUsername(unknown , firstDay, lastDay));
	},[dispatch]);// eslint-disable-line 

	
	//if isDaily === false, get selected monthly
	const monthlySelected = React.useCallback((date, payday = false, calendarSwitch = null, IsCurrentDate = null)=>{
		const user = JSON.parse(localStorage.getItem('userCredentials'));
		const unknown = secret ? secret.praggerUsername : user.user.praggerUsername

		let currentDate = date;
		let monthStart = moment(currentDate).startOf('month');
		let monthEnd = moment(currentDate).endOf('month');
		var getMonth = [];
		
		let Payday_StartRange;
		let Payday_EndRange;

		if(payday === false){
			let getCurrentMonth= moment(currentDate).startOf('month');
			let days = moment(currentDate).daysInMonth();
			let ExactCountDayInMonth= days - 1;
			for (var i = 0; i <= ExactCountDayInMonth; i++) {
				if(i <= days){
					getMonth.push(moment(getCurrentMonth).add(i, 'days').format('LL'));
				}
			};
		} else {
			let startCurrentDate;
			let formatedCurrentDate; 
			let payDayCurrentDate;
			let payday_v1_range1;
			let payday_v1_range2;
			let payDay_v1_range1_isAfter;
			let payDay_v1_range2_isBefore;
			
			// Payday v2
			let payday_v2_nextMonth;
			let payday_v2_startOfNextMonth;
			let payday_v2_range1;
			let payday_v2_range2;
		
			let gotoDateFilterCutOff = null;
		

			if(calendarSwitch === 'gotoDate') {
				if(moment(currentDate).format("D") >= 6 && moment(currentDate).format("D") <= 20 ){
					gotoDateFilterCutOff = "gotoDate_6To20";
				} else if(moment(currentDate).format("D") >= 1 && moment(currentDate).format("D") <= 5 ) {
					gotoDateFilterCutOff = "gotoDate_21To05_custom";
				} else {
					gotoDateFilterCutOff = "gotoDate_21To05";
				}
			}
		

			if(calendarSwitch === "next" || IsCurrentDate === true || gotoDateFilterCutOff === "gotoDate_6To20") {
				startCurrentDate = moment(currentDate).format("YYYY-MM-DD");
				
				formatedCurrentDate = moment( moment(startCurrentDate).startOf('month')).format("YYYY-MM-DD") //
				payDayCurrentDate = moment(startCurrentDate).format("YYYY-MM-DD"); //
				payday_v1_range1 = moment(formatedCurrentDate).add(5, 'days') // 6th day of current month
				payday_v1_range2 = moment(formatedCurrentDate).add(19, 'days') // 20th day of current month
				payDay_v1_range1_isAfter = moment(moment(payday_v1_range1)).format("YYYY-MM-DD")
				payDay_v1_range2_isBefore = moment(moment(payday_v1_range2)).format("YYYY-MM-DD")
					
				// Payday v2
				payday_v2_nextMonth = moment(formatedCurrentDate).add(1, 'month')
				payday_v2_startOfNextMonth = moment(payday_v2_nextMonth).startOf('month')
				payday_v2_range1 = moment(formatedCurrentDate).add(20, 'days') // 21th day of current month
				payday_v2_range2 = moment(payday_v2_startOfNextMonth).add(4, 'days') // 5th day of the next month
				
			

			} 

			if(calendarSwitch === "prev" || gotoDateFilterCutOff === "gotoDate_21To05" || gotoDateFilterCutOff === "gotoDate_21To05_custom" ) {
				startCurrentDate = moment(currentDate).format("YYYY-MM-DD");
				formatedCurrentDate = moment( moment(startCurrentDate).startOf('month')).format("YYYY-MM-DD") //
				payDayCurrentDate = moment(startCurrentDate).format("YYYY-MM-DD"); //
			
				payday_v1_range1 = moment(formatedCurrentDate).add(5, 'days') // 6th day of current month
				payday_v1_range2 = moment(formatedCurrentDate).add(19, 'days') // 20th day of current month
				payDay_v1_range1_isAfter = moment(moment(payday_v1_range1)).format("YYYY-MM-DD")
				payDay_v1_range2_isBefore = moment(moment(payday_v1_range2)).format("YYYY-MM-DD")
				
				// Payday v2
				payday_v2_nextMonth = moment(formatedCurrentDate).subtract( 1, 'month')
				payday_v2_startOfNextMonth = moment(payday_v2_nextMonth).startOf('month')
				payday_v2_range1 = moment( gotoDateFilterCutOff === "gotoDate_21To05_custom" ? payday_v2_nextMonth : formatedCurrentDate).add(20, 'days') // 21th day of current month
				payday_v2_range2 = moment(moment(payday_v2_startOfNextMonth).add( gotoDateFilterCutOff === "gotoDate_21To05_custom" ? 1 : 2, 'months')).add(4, 'days') // 5th day of the next month

			}
		
			
	
			if((moment(payDayCurrentDate).isAfter(payDay_v1_range1_isAfter, 'days') || moment(payDayCurrentDate).isSame(payDay_v1_range1_isAfter, 'days')) && (moment(payDayCurrentDate).isSame(payDay_v1_range2_isBefore, 'days') || moment(payDayCurrentDate).isBefore(payDay_v1_range2_isBefore, 'days') ) ){
				// If current date has range of Current month 6th to 20th day
				setPaydayRange(false);	
				Payday_StartRange = payday_v1_range1;
				Payday_EndRange = payday_v1_range2;
			}  else {
				setPaydayRange(true);
				Payday_StartRange = payday_v2_range1;
				Payday_EndRange = payday_v2_range2;
			}
			
			let countDiffDays = Payday_EndRange.diff(Payday_StartRange, 'days')
			
			for (var x = 0; x <= countDiffDays; x++) {
				getMonth.push(moment(Payday_StartRange).add(x, 'days').format('LL'));
			};
		}

		setOneMonth(getMonth);
	
		if(payday === false){
			setstartCutOffCurrentDate(true);
			const MonthStart = moment(monthStart).format('YYYY-MM-DD') + "T00:00:00.00Z";
			const MonthEnd = moment(monthEnd).format('YYYY-MM-DD') + "T00:00:00.00Z";
			dispatch(timesheetActions.getByUsername(unknown , MonthStart, MonthEnd));
		} else {
			setstartCutOffCurrentDate(false);
			const MonthStart = moment(Payday_StartRange).format('YYYY-MM-DD') + "T00:00:00.00Z";
			const MonthEnd = moment(Payday_EndRange).format('YYYY-MM-DD') + "T00:00:00.00Z";
			dispatch(timesheetActions.getByUsername(unknown , MonthStart, MonthEnd));
		}
		

	},[dispatch])// eslint-disable-line 

	const handleChangeWeek = (date) => {
		weekSelected(moment(date));
	}
	
	const handleChangeMonth = (date) => {
		let customDate = isCutOff === true ? date['date'] : date;
		let calendarSwitch= isCutOff === true ? date['calendarSwitch'] : null;
		monthlySelected(moment(customDate), isCutOff , calendarSwitch, startCutOffCurrentDate );
	}

	const handlePaydayRange = (PaydayTrack) => {
		setPaydayRange(PaydayTrack)
	}
	const handleCalendarUpdate = (trigger) => {
		setMonthCalendar(trigger);
	}
	const handleCalendarDate= (trigger) => {
		setCalendarDate(trigger)
	}
	React.useEffect(() => {
		let defaultDate  = null;
		monthlySelected(moment().toDate(), isCutOff, defaultDate, startCutOffCurrentDate);
	}, [isCutOff]) // eslint-disable-line 

	React.useEffect(()=>{
		if(isDaily){
		
			weekSelected(selectedWeek);	

			setCalendarDate(moment().toDate())  // Reset calendar date
			setIsToggledCutOff(false); // reset Calendar cutoff toggle
		}else{
			
			setIsToggledCutOff(false); // reset cutoff toggle
			setMonthCalendar(null) // reset calendar
			setCalendarDate(moment().toDate()) // reset calendar
			monthlySelected(moment()); // reset selected day
		}
	},[dispatch, isDaily, selectedWeek, weekSelected, monthlySelected, setOneMonth]);


	return (
		<div className={device === 'desktop' ? "row timekeeping desktop-footer" : "row timekeeping"}>
		

			<div
				className={cc([
					'sub-panel',
					'col',
					's12',
					'l4',
					device
				])}
			>
				<div>
					<ProfileWidget />
					<TimekeepingWidget 
						username={secret ? secret.praggerUsername : props.authentication?.user?.user?.praggerUsername}
					/>
				</div>
				{device === 'desktop' &&
					<div><Footer /></div>
				}
			</div>
			<div
				className={cc([
					'main-panel',
					'col',
					's12',
					'l8',
					device
				])}
			>
				
				{/* Timesheet Daily & Calendar */}
				<div className="timesheet-container">
				
					<div className="timesheet-header">

								{/* Switch toggle */}
							<div className="switch-container">
								<button onClick={toggle}>
									<div className="switch-box">
										<div className={cc(["switch-v1", isDaily === true ? "switch-active" : ''])}>
											Daily
										</div>
										<div className={cc(["switch-v1", isDaily === false ? "switch-active" : ''])}>
											Calendar
										</div>
									</div>
								</button>
							</div>

							

							<div className="info-dates-container">
							{ !isDaily  ?	
								<div className="flex-row-cutOff">
									<span>
										View cut off
									</span>
									<div className="switch-container-cutoff">
										<button onClick={(e) => setIsToggledCutOff(!isCutOff)}>
												<div className={cc([isCutOff === false ? "switch-box-cutoff" : "switch-box-cutoff-active" ])} >	
													
													<div className={cc(["switch-v1-cutoff", isCutOff === false ? "switch-active-cutoff" : ''])}></div>
													<div className={cc(["switch-v1-cutoff", isCutOff === true ? "switch-active-cutoff" : ''])}></div>
													
											</div>
										</button>
									</div>
								</div>
							: null }
							
								
								<InfoToggle
									
								/>
								{isDaily ?
									<WeeklyDatePicker 
										sunDate={oneWeek[0]} 
										satDate={oneWeek[oneWeek.length - 1]} 
										selectedDate={ handleChangeWeek }
										isError={ timesheetNotFound }
									/>
									:
									<div className="timesheet-dates">
										<MonthlyDatePicker 
											handlePayday = { handlePaydayRange }
											PayDayTrack = { PaydayRange }
											IsPayday = {isCutOff}
											startOf={oneMonth[0]} 
											endOf={oneMonth[oneMonth.length - 1]} 
											selectedDate ={ handleChangeMonth }
											isError={ timesheetNotFound }
											isPrevious = { handleCalendarUpdate }
											CalendarDate= { handleCalendarDate }
										/>
									</div>
								}
							</div>

						</div>
					
					<hr /> 
				
					<div className="timesheet-content">
						
						{timesheetNotFound ?
						<center><h5>Timesheet not found</h5></center>
						:
						<React.Fragment>
							{!isDaily &&
							<div className="timesheet-calendar">
								<CalendarTimesheet
							   
								 isCutOff = { isCutOff }
								 timesheet={timesheet} 
								 setMonth={oneMonth}
								 isUpdate={calendarUpdate} 
								 isCalendarUpdate = { CalendarDateUpdate }
								 />
							</div>
							}
								
							<DailyTimesheet 
								timesheet={timesheet}
								setWeek={oneWeek}
								setMonth={oneMonth}
								isWeekly={isDaily}
							/>
						</React.Fragment>
						}
					</div>
				</div>
			</div>
			{device === 'tablet' &&
				<div><Footer /></div>
			}
		</div>
	)
};


const mapStateToProps = (state) => ({
	authentication: state.authentication,
	timesheet: state.timesheet?.timesheet?.data[0]?.prags,
	timesheetNotFound: state.timesheet.error
});

export default React.memo(connect(mapStateToProps)(TimeKeeping));
