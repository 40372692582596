import React from "react";
import {useDispatch } from "react-redux";
import Modal from 'react-modal';
import sweetAlert from 'sweetalert2';

import closeIcon from '../_templates/x_square.png';

import { challengeActions } from "../_core/actions/challenge";
import { challengeService } from '../_core/services/challenge';
import './ChallengeWidget.sass'

const toast = sweetAlert.mixin({
	toast: true,
	width: 300,
	position: 'center',
	showConfirmButton: false,
	timer: 1500
});

const ChallengeVerify = (props) => {
    const dispatch = useDispatch();
    const [challenge, setChallenge] = React.useState([]);


    React.useEffect(()=>{
        try{
            challengeService.getOne(props.challengeId).then(res=>{
                setChallenge(res.success[0]);
            }).catch(error=>{});
        }catch(err){
            console.log(err);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.challengeId]);


    const failedChallenge = () => {
        dispatch(challengeActions.revertChallenge(props.challengeId));
        toast.fire({
            type: 'success',
            title: `You've failed the challenge.`
        });
        props.handleCloseModal(false)
        setTimeout(function(){
            dispatch(challengeActions.getAll(1, 100000));
            props.updateModal();
        }
        , 2000);
        

    }

    const completeChallenge = () => {
        let credit ={
            type:"available",
            value:challenge.credits,
            origin:"challenge"
        }
        dispatch(challengeActions.completeOneChallenge(props.challengeId));
        toast.fire({
            type: 'success',
            title: `You've completed the challenge.`
        });
        props.handleCloseModal(false)
        setTimeout(function(){
            dispatch(challengeActions.getAll(1, 100000));
            challengeService.addCredit(challenge.userDetails[0]._id , credit)}
        , 2000);
        props.updateModal()

    }

    const cancel = () => {
        props.handleCloseModal(false)
    }
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        toggle={props.toggle}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
        centered
        className="ChallengeUpdate challenge-verify"
      >
        <div className="row center create-challenge-container"
            style={{
                width: "100%",
                height: "auto",
                margin: "auto",
                padding: "20px 0px 20px",
                borderBottom: "1px solid #979797"
            }}>
            
            <p className="usermodal_title_update center"> Verify Completion</p>
            <button className="close-modal" onClick={cancel}>
                <img height={20} src={closeIcon} alt="" style={{ marginRight: 7}}/>
			</button>
        </div>

        <div className="request-details-container">
            <div className="div-image" style={{marginBottom:'50px'}}>
                <img height={274} width={370.94} src={challenge.finishImages} alt="" style={{ objectFit:'contain' }}/>
            </div>
            <div className="div1" style={{width:'299px',margin:'auto', marginBottom:'50px'}}>
                {challenge.userDetails?.map((details,index) => (
                    <div className="div1-title" key={index}>
                        <span style={{fontWeight:'700', textTransform:'capitalize'}}>{details.firstName} {details.lastName}, </span>Finished the Challenge! <span style={{fontWeight:'700', textTransform:'capitalize'}}> {challenge.title}</span>
                    </div>
                ))}
                
               
            </div>
            <div className="footer-container">
                <div style={{textAlign:'center', margin:'25px'}}>
                    <button type="submit" className="btn-updateC" onClick={() => failedChallenge()}>FAILED</button>
                    <button className="btn-updateC" onClick={() => completeChallenge()} >COMPLETE</button>
                </div>
            </div>

        </div>


      </Modal>
    </>
  );
};

export default ChallengeVerify;