import { get,post, put, del } from '../helpers/api';
import { userService } from '../services/user';

export const questService = {
    getAll,
    getById,
    getAllUserQuest,
    getAllFilter,
    getAllQuestChallenge,
    createQuest,
    imageUpload,
    createQuestChallenge,
    updateQuestChallenge,
    imageUploadChallenge,
    updateQuest,
    deleteQuest,
    getAllParticipants,
    getAllAcceptedQuest,
};

function getAll(page = 1, itemsPerPage = 20) {
    return get(`/api/v1/quest?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}

function getAllFilter(page = 1, itemsPerPage = 20, search, filter = "") {
    let fields = `${filter ==='questTitle' ? "questTitle:" + search : filter === 'questContent' ?"questContent:" + search : ""}`
    return get(`/api/v1/quest?page=${page}&items-per-page=${itemsPerPage}&search=${search}&filter=${fields}`).then(handleResponse);
}


function getById(id) {
    return get(`/api/v1/quest/${id}`).then(handleResponse);
}

function getAllUserQuest() {
    return get('/api/v1/user-quest').then(handleResponse);
}

function getAllQuestChallenge(id, page = 1, itemsPerPage = 20) {
    return get(`/api/v1/quest/${id}/challenges?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}

function getAllParticipants(questId, challengeId, page = 1, itemsPerPage = 20) {
    return get(`/api/v1/quest/${questId}/challenges/${challengeId}?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}

function createQuest(quest) {
    return post('/api/v1/quest', JSON.stringify(quest)).then(handleResponse)
}

function imageUpload(id , image){
    return post(`/api/v1/upload/${id}/quest`, image).then(handleResponse);
}

function createQuestChallenge(challenge) {
    return post('/api/v1/challenge', JSON.stringify(challenge))
    .then(handleResponse)
}

function updateQuestChallenge(challenge) {
    const id = challenge._id
    return put(`/api/v1/challenge/${id}`, JSON.stringify(challenge))
    .then(handleResponse)
}

function imageUploadChallenge(id , image){
    return post(`/api/v1/upload/${id}/challenge`, image).then(handleResponse);
}

function getAllAcceptedQuest(page = 1, itemsPerPage = 20, id) {
    return get(`/api/v1/quest?page=${page}&items-per-page=${itemsPerPage}&filter=participants:${id}`).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}

function updateQuest(id, quest){

    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
    const fd = new FormData();
    const imageFile = quest.questImage;
    fd.append('file',imageFile)
    
    // //checking if post images has a content file
    if(isFile(quest.questImage) === true){
        quest.questImage = null;
    }

    return put(`/api/v1/quest/${id}`, JSON.stringify(quest)).then(handleResponse)
    .then(response => {
        if(isFile(imageFile) === true){
            imageUpload(id,fd);
        }
        
    });
}

function deleteQuest(id){
    return del(`/api/v1/quest/${id}`).then(handleResponse)
}