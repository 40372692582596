import { get, post } from '../helpers/api';
import { userService } from '../services/user';

export const commendService = {
    getCommendations,
    getUserCommends,
    postCommend,
    commendedFrequency
}

function getCommendations(userId) {
    return get(`/api/v1/user/${userId}/commendation`).then(handleResponse);
}

function getUserCommends(userId) {
    return get(`/api/v1/user/${userId}/commend`).then(handleResponse);
}

function createPost(postFeed) {
    return post('/api/v1/post', JSON.stringify(postFeed)).then(handleResponse);
}

function addTip(userId, tip) {
    return post(`/api/v1/user/${userId}/credit`, JSON.stringify(tip)).then(handleResponse);
}

function postCommend(commendedUserId, commendingUserId, commendData) {
    const commendUser = {
        commended_user: commendedUserId
    }

    const postFeed = {
        postType: 'commend',
        postTitle: commendData.postTitle,
        postSlug: 'commend',
        userId: commendData.postUser,
        postContent: commendData.postContent,
        postImage: null,
        mentions: commendData.postMention,
        status: 1,
        withImage: false,
        anonymous: commendData.anonymous
    }

    const tip = {
        type: "tip",
        value: commendData.tip,
        origin: "commend"
    }

    const lessTip = {
        type: "tip",
        value: -commendData.tip,
        origin: "commend"
    }

    return post(`/api/v1/user/${commendingUserId}/commend`, JSON.stringify(commendUser))
        .then(handleResponse)
        .then(response => {

            createPost(postFeed);

            if (commendData.tip > 0) {
                addTip(commendedUserId, tip);
                addTip(commendingUserId, lessTip)
            }
        });
}

function commendedFrequency(userId, commendedUser, frequency) {
    return get(`/api/v1/user/${userId}/commendedUser/${commendedUser}?frequency=${frequency}`).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}