export function convertToObjectId (props){
    console.log(props)
    const tk = props;
    //create objectId as originId
    if(tk && tk.id){
        const originIdLength = 24;
        const idSeparator = "a";
        const pragDateTime = tk.pragOutAt ? tk.pragOutAt : tk.pragInAt;
        const buildOriginId = tk.id + idSeparator + pragDateTime.replace(/-/g,"").replace(/:/g,"").replace(/\s/g,"");
        const fillIdLength = originIdLength > buildOriginId.length ? originIdLength - buildOriginId.length : 0;
        const idFillers = "0";
        const pragOriginId = fillIdLength !== 0 ? buildOriginId + idFillers.repeat(fillIdLength) : buildOriginId.substr(0, originIdLength);

        return pragOriginId
    }else{
        return '1'
    }
}