import { smsConstants } from '../constants/sms';

let initialState = {
  items: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function sms( state = initialState, action) {
  switch (action.type) {
    //getall sms
    case smsConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case smsConstants.GETALL_SUCCESS:
      return {
        loading: false,
        items: action.sms
      };
    case smsConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

    //getOne sms
    case smsConstants.GET_REQUEST:
      return {
        saving: true
      };
    case smsConstants.GET_SUCCESS:
      return {
        items: action.sms
      };
    case smsConstants.GET_FAILURE:
      return {
        error: action.error
      };

    //create
    case smsConstants.CREATE_REQUEST:
      return { creating: true };
    case smsConstants.CREATE_SUCCESS:
      return {};
    case smsConstants.CREATE_FAILURE:
      return {};

    //DELETE SMS
    case smsConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      console.log(state)
      return {
        ...state,
        items: state.items.map(sms =>
          sms._id === action.id
            ? { ...sms, deleting: true }
            : sms
        )
      };

    case smsConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(sms => sms._id !== action.id)
      };

    case smsConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(sms => {
          if (sms._id === action.id) {
            // make copy of permission without 'deleting:true' property
            const { deleting, ...smsCopy } = sms;
            // return copy of permission with 'deleteError:[error]' property
            return { ...smsCopy, deleteError: action.error };
          }
          return sms;
        })
      };

    default:
        return state
  }
}