import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { essActions } from '../_core/actions/ess';
import { leaveTypeService } from '../_core/services/leaveType';
import option from '../_templates/dot.png';
import leaveIcon from '../_templates/ess_leave_icon.png';
import medIcon from '../_templates/ess_leave_med_icon.png';
import empIcon from '../_templates/ess_briefcase_icon.png';
import techIcon from '../_templates/ess_tech_icon.png';
import otIcon from '../_templates/ess_ot_icon.png';
import './ESSRequestHistory.scss';

const ESSRequestHistory = ({ getHistory, getLeaveTypes, requestHistory }) => {
    const [activities, setActivities] = React.useState([]);
    const [leaveTypes, setLeaveTypes] = React.useState([]);
    const [itemIsRendered, setItemIsRendered] = React.useState(false);

    const userDetails = JSON.parse(localStorage.getItem("user"));
    const secretDetails = JSON.parse(localStorage.getItem("secretDetails"));
    const unknown = secretDetails ? secretDetails : userDetails

    const currentUser = [unknown?.firstName, unknown?.lastName].join(" ");
    const numOfItems = 10;

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(essActions.getActivitiesByUser(unknown?.user_id));
    }, []) //eslint-disable-line

    React.useEffect(() => {
        setActivities(requestHistory?.data);
        setItemIsRendered(!requestHistory?.loading);
        getHistory(requestHistory?.data);
    }, [requestHistory, getHistory]);

    React.useEffect(() => {
        try {
            leaveTypeService.getAll().then(res => {
                setLeaveTypes(res?.items);
                getLeaveTypes(res?.items);
            }).catch(err => console.log(err));
        } catch (err) {
            throw err
        }
    }, []) //eslint-disable-line

    const status = {
        new: "new",
        hold: "on hold",
        approve: "approved",
        reject: "rejected",
        complete: "completed"
    }

    const requestTypes = {
        leave: "leave",

        sickLeave: "sick",
        medical: "medical",
        overtime: "overtime",
        undertime: "undertime",
        employment: "certificate of employment",
        concern: "hr concern",
        tech: "technical support"
    }

    const ActivityItem = ({ item }) => {

        const getRequestType = (type, subtype) => {
            var requestType = subtype;
            var requestIcon = "";

            // switch (subtype = subtype.toLowerCase()) {
            //     case type === requestTypes.leave:
            //         requestType = leaveTypes?.filter((lt) => lt._id === subtype)[0]?.name;
            //         requestType = requestType?.toLowerCase().includes(requestTypes.leave) ? requestType : requestType + ` ${requestTypes.leave}`
            //         requestIcon = leaveTypes?.filter((lt) => lt._id === subtype)[0]?.name?.toLowerCase()?.includes(requestTypes.sickLeave) || leaveTypes?.filter((lt) => lt._id === subtype)[0]?.name?.toLowerCase()?.includes(requestTypes.medical) ? medIcon : leaveIcon;
            //         break;
            //     case subtype === requestTypes.overtime || subtype === requestTypes.undertime:
            //         requestIcon = otIcon;
            //         break;
            //     case subtype === requestTypes.employment || subtype === requestTypes.concern:
            //         requestIcon = empIcon;
            //         requestType = subtype?.slice(0, 3).includes("hr") ? requestType = "HR " + subtype?.slice(2, subtype.length) : subtype
            //         break;
            //     case subtype === requestTypes.tech:
            //         requestIcon = techIcon;
            //         break;
            //     default:
            //         requestIcon = leaveIcon
            //         break;
            // }

            if (type === requestTypes.leave) {
                requestType = leaveTypes?.filter((lt) => lt._id === subtype)[0]?.name;
                requestType = requestType?.toLowerCase().includes(requestTypes.leave) ? requestType : requestType + ` ${requestTypes.leave}`

                requestIcon = leaveTypes?.filter((lt) => lt._id === subtype)[0]?.name?.toLowerCase()?.includes(requestTypes.sickLeave) || leaveTypes?.filter((lt) => lt._id === subtype)[0]?.name?.toLowerCase()?.includes(requestTypes.medical) ? medIcon : leaveIcon;

            } else {
                if (subtype === requestTypes.concern) {
                    requestType = "HR " + subtype?.slice(2, subtype.length);
                    requestIcon = empIcon;
                }
                const getSubType = subtype?.toLowerCase();
                requestIcon = getSubType === requestTypes.overtime || getSubType === requestTypes.undertime ? otIcon : getSubType === requestTypes.employment ? empIcon : getSubType === requestTypes.tech ? techIcon : leaveIcon
            }

            return { requestType, requestIcon }
        }

        const { requestType, requestIcon } = getRequestType(item.type, item.subtype);
        const vowels = ("aeiouAEIOU");

        return (
            <div className="ess_history_widget_item">
                <div>
                    <div>{/** icons**/}
                        <img src={item.icon || requestIcon} alt="ess_icon" />
                    </div>
                    <div>{/** line**/}
                    </div>
                </div>
                <div>{/**desc **/}
                    <div>
                        {item.status === status.new ?
                            <p><span className="ess_request_type">{currentUser}</span> requested {vowels.indexOf(requestType[0]) !== -1 ? "an" : "a"} <span className="ess_request_type">{requestType}</span></p>
                            : item.status ?
                                <p>Your request for <span className="ess_request_type">{requestType}</span> <span>{item.status === status.hold ? `is on hold` : `has been ${item.status}`}!</span></p>
                                : ""}
                        <p>{moment(item.createdAt).fromNow()}</p>
                    </div>
                    <div>
                        {item.status === status.new &&
                            <p>{item.description}</p>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="ess_history_widget_wrapper">
            <div className="subpanel_widget_title_wrapper">
                <h6>Requests History</h6>
                <img src={option} alt="options" />
            </div>
            {activities?.length === 0 && itemIsRendered ?
                <div className="ess_history_widget_content"><center>No Request Found</center></div>
                :
                activities?.length > 0 && itemIsRendered &&
                <div className="ess_history_widget_content">{/** **/}
                    <div>
                        {activities?.slice(0, numOfItems)?.map((ess, index) =>
                            <ActivityItem item={ess} key={index} />
                        )}
                    </div>
                    <div className="ess_history_widget_link_wrapper">
                        <Link to={'/employee-self-service/history'}>View All Request</Link>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    requestHistory: state?.ess?.getActivitiesByUser
});

export default React.memo(connect(mapStateToProps)(ESSRequestHistory));