//npm packages
import React from 'react';
import { connect } from 'react-redux';
//actions
import { questActions } from '../_core/actions/quest';
import { userActions } from '../_core/actions/user';
//components
import { QuestCard } from './QuestCard';
//styles
import './QuestWidget.sass';
//image
import QuestEmpty  from '../_templates/quest-empty-state.png'

class QuestWidget extends React.Component {

    componentDidMount() {
        this.props.dispatch(questActions.getAll(1, 100000));
        this.props.dispatch(userActions.getAll(1, 100000));
    };

    render() {
        const questList = this.props.quests?.items?.items;
        const users = this.props.users?.users?.items;

        return (
            <div className="recent-quests">
                {questList ?
                <div className="rq-container">
                    <p className="rq-title">Recent Quests</p>
                    {questList.length > 0 ? 
                        <div className="row rq-card-container">
                        {questList.map((quest, index) =>
                            <QuestCard 
                                key={ index } 
                                quest={ quest } 
                                users={ users }
                            />
                        )}
                    </div> :

                        <div className="rq-container"> 
                            <img src={QuestEmpty} className="QuestEmptyState" alt="Quest Empty State"/>
                        </div> 
                    }
                </div> 
                : <div className="rq-container"> 
                    <img src={QuestEmpty} className="QuestEmptyState" alt="Quest Empty State"/> 
                </div>              
                }
            </div>
        );
    };
};

function mapStateToProps(state) {
    const { quests, users } = state;
    return { quests, users, };
};

const connectedQuestWidget = connect(mapStateToProps)(QuestWidget);

export { connectedQuestWidget as QuestWidget };
