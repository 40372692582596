import { get, post } from '../helpers/api';
import { userService } from '../services/user';

export const creditService = {
    getTotalCredits,
    postCredit
}

function getTotalCredits(userId) {
    return get(`/api/v1/user/${userId}/credit-total`).then(handleResponse);
}

// function createPost(postFeed) {
//     return post('/api/v1/post', JSON.stringify(postFeed)).then(handleResponse);
// }

function postCredit(commendedUserId, commendingUserId, commendData) {
    
    const postFeed = {
        postType: 'credit',
        postTitle: commendData.postTitle,
        postSlug: 'credit',
        userId: commendData.postUser,
        postContent: commendData.postContent,
        postImage: null,
        mentions: commendData.postMention,
        status: 1,
        withImage: false,
        anonymous: commendData.anonymous
    }

    return post('/api/v1/post', JSON.stringify(postFeed)).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}