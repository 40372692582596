import React from "react";
import cc from "classcat";
import moment from "moment";
import styled from "styled-components";
import DocumentTitle from "react-document-title";
import LinesEllipsis from "react-lines-ellipsis";
import BodyBackgroundColor from "react-body-backgroundcolor";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { Switch as SwitchCase, Case } from "react-if";
import { connect, useDispatch } from "react-redux";
import { leaveTypeService } from "../../../_core/services/leaveType";
import { If, Else, Then,  } from 'react-if';

import { checkSideNav } from "../../Components/checkSideMenu";
import { leaveTypeActions } from "../../../_core/actions/leaveType";
import emptyIcon from '../../../_templates/empty.png';

import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import {
  DynamicHeaderContent,
  DynamicTableAndPaginationWrapper,
  DynamicSearchEntriesWrapper
} from "../../Components/PageWrapper/DynamicWrapper";
import "../../Components/StatusStyle/status.scss";
import "./LeavePageDark.scss";
import ReviewLeavePage from "./ReviewLeavePage";

const AdminLeavePage = styled.div`
  table {
    > thead > tr > th {
      &:nth-child(3),
      &:nth-child(4),
      &:last-child {
        text-align: inherit;

        @media (max-width: 993px) {
          text-align: right;
        }
      }
    }

    > tbody > tr > td {
      &:nth-child(3),
      &:nth-child(4) {
        text-align: inherit;

        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px) {
          text-align: left;
        }
      }
    }
  }

  .subjectTooltip {
    background-color: #121d38 !important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9ba3ca;
    font-size: 14px;
    font-weight: 400;
  }
`;

const LeavePageList = (props) => {
  const dispatch = useDispatch();
  const [leaves, setLeaves] = React.useState([]);
  const [categoriest, setCategories] = React.useState();
  const [types, setTypes] = React.useState([]);
  const [entriesValues, setentriesValues] = React.useState();
  const [search, setNewSearch] = React.useState("");
  const [keys, setKeys] = React.useState("");

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  const ActivePage = Number(queryString.parse(props.location.search).page) || 1
  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

  let tableHeader = (editPermission.includes("Leaves - Leave Filings") === true) || (roleAllTrue === 1) ? [
    "Ref Code",
    "Days",
    "From",
    "To",
    "Reason",
    "Type",
    "Submitted",
    "Status",
    "Actions",
  ] : [
    "Ref Code",
    "Days",
    "From",
    "To",
    "Reason",
    "Type",
    "Submitted",
    "Status",
    "",
  ];

    //search
    const handleSearchChange = (item) => {
        setNewSearch(item);
    };
          
    //filters
    const filterKeys = (item) => {
        setKeys(item);
    };

  React.useEffect(() => {
    setLeaves(props.leaves.items);
    dispatch(leaveTypeActions.getAll());
  }, [dispatch, props.leaves]);

  React.useEffect(() => {
    try {
      leaveTypeService
        .getAll(1, 999999)
        .then((res) => {
          if (res.items) {
            setTypes(res.items);
          }
        })
        .catch((err) => {
          console.log("get leave error occured");
        });
    } catch (err) {
      console.log(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entriesValue = (entries) => {
    setentriesValues(entries);
  };

  React.useEffect(() => {
    let categories = [];
    types.forEach((film) => {
      leaves.map((data, id) => {
        if (data.subtype === film._id) {
          let array = {
            _id: data._id,
            refCode: data.refCode,
            type: film.name,
            endedAt: data.endedAt,
            startedAt: data.startedAt,
            description: data.description,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            status: data.status,
          };
          categories.push(array);
        }
        return null;
      });
    });
    setCategories(categories);
  }, [leaves, types]);


  // console.log(props.leaves)


  const LeaveData = () => {
    let data = categoriest ?? [];

    return (
      <>
        <If condition={props.leaves.loading === false }> 
            <Then>
                {data.length !== 0 ? (
                    data.sort((first, second) => {
                        return first.updatedAt > second.updatedAt ? -1 : 1;
                    }).map((item, index) => {
                      let dateRange = [];
                      let startDate = moment(item.startedAt);
                      let endedAt = moment(item.endedAt);
                      let datediff = startDate.diff(endedAt, "days");
                
                      while (datediff + 2 <= 0) {
                        startDate.add(1, "days").format("dddd, MMMM DD YYYY");
                        dateRange.push(startDate.format("dddd, MMMM DD YYYY"));
                        datediff++;
                      }

                      let hasWeekEnd = dateRange.map(checkDays => (
                        checkDays.split(',')[0]
                      )).includes("Saturday");
                      
                      let hasWeekEnd2 = dateRange.map(checkDays => (
                        checkDays.split(',')[0]
                      )).includes("Sunday");

                      return (<tr key={index}>
                          <td>{item.refCode ? item.refCode : "No Code"}</td>
                          <td>
                          {
                            hasWeekEnd 
                            ? hasWeekEnd2 
                              ? moment(item.endedAt).diff(moment(item.startedAt), "days") - 1
                              : moment(item.endedAt).diff(moment(item.startedAt), "days")
                            : moment(item.endedAt).diff(moment(item.startedAt), "days") + 1
                          }
                          </td>
                          <td>
                          {item.startedAt
                              ? moment(
                                  item.startedAt
                                  .replace("[", "")
                                  .replace("]", "")
                                  .split("'")
                                  .join("")
                                  .split('"')
                                  .join("")
                                  .split(",")[0]
                              ).format("MMM DD, YYYY")
                              : "No Dates"}
                          </td>
                          <td>
                          {item.endedAt
                              ? moment(
                                  item.endedAt
                                  .replace("[", "")
                                  .replace("]", "")
                                  .split("'")
                                  .join("")
                                  .split('"')
                                  .join("")
                                  .split(/[, ]+/)
                                  .pop()
                              ).format("MMM DD, YYYY")
                              : "No Dates"}
                          </td>
                          <td style={{ width: "200px" }}>
                          <LinesEllipsis
                              text={item.description !== null ? item.description : ""}
                              maxLine="1"
                              ellipsis="..."
                              trimRight
                              basedOn="words"
                          />
                          </td>
                          <td>{item.type}</td>
                          <td>{moment(item.createdAt).fromNow()}</td>
                          <td className={item.status}>
                          {item.status === "new"
                              ? "In Review"
                              : item.status && item.status === "approved"
                              ? "Approved"
                              : item.status && item.status === "rejected"
                              ? "Rejected"
                              : item.status && item.status === "cancelled"
                              ? "Cancelled"
                              : item.status && item.status === "on hold"
                              ? "On Hold"
                              : item.status && item.status === "completed"
                              ? "Completed"
                              : item.status}
                          </td>
                          {
                            (editPermission.includes("Leaves - Leave Filings") === true) || (roleAllTrue === 1) ?
                              <td className={secretDetails !== null ? "disabled-link" : '' }>
                              <Link to={`/admin/leave-requests/review/${ActivePage}/${item._id}`}>
                                  <button
                                  className="btn waves-effect waves-light"
                                  id="review"
                                  >
                                  Review
                                  </button>
                              </Link>
                            </td> : false
                          }
                      </tr>)
                    })
                ) : (
                <>
                    <tr>
                        <td colSpan="12" style={{textAlign:'center'}}>
                            <img src={emptyIcon} className="empty" alt="empty"/>
                            <p style={{fontSize:'16px'}}>No Data Available</p>
                            <p style={{fontSize:'14px'}}>There are currently no data available</p>
                        </td>
                    </tr>
                </>
                )}
            </Then>
            <Else>
                <tr className="table-td-data">
                  <td> 
                    <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                        <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                </td>
                </tr>
            </Else>
        </If>
      </>
    );
  };

  return (
    <AdminLeavePage>
      {/* <DynamicEntriesOnly 
        entriesValue={entriesValue} 
        paginationPath={"/admin/leave-requests"}
        originPage="Leaves"
      /> */}
      <DynamicSearchEntriesWrapper
        entriesValue={entriesValue}
        data={props.leaves.items}
        location={props.location}
        searchs={handleSearchChange}
        filters={filterKeys}
        paginationPath={"/admin/leave-requests"}
        originPage="Leaves"

      />

    <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={LeaveData()}
        originPage="Leaves"
        data={props.leaves}
        location={props.location}
        paginationPath={"/admin/leave-requests"}
        entriesItem={entriesValues}
        search={search}
        loading = {props.leaves.loading}
        filter={keys}
      />

      {/* <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={LeaveData()}
        originPage="Leaves"
        data={props.leaves}
        location={props.location}
        paginationPath={"/admin/leave-requests"}
        entriesItem={entriesValues}
      /> */}
    </AdminLeavePage>
  );
};

const mapStateToProps = ({ leaves, leaveTypes }) => ({
  leaves,
  leaveTypes,
});

const LeavePage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([]);

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Leave Filings", link: "/admin/leave-requests?page=1" },
        ]);
        break;
      case "review":
        setBreadCrumbs([
          { label: "Leave Filings", link: "/admin/leave-requests?page=1" },
          { label: "Review", link: "#" },
        ]);
        break;
      default:
        // Error page
        break;
    }
  };

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);

  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "review"
            ? `Review | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Leaves | Leave Filings | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : ""
        }
      >
        <PageWrapper className={cc(["LeavePage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Leaves"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={["list"].includes(props.mode)}>
              <ContentWrapper>
                <LeavePageList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={["review"].includes(props.mode)}>
              <ReviewLeavePage {...props} />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});

LeavePage.WrappedComponent.displayName = "LeavePage";

const LeavePageDarkRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <LeavePage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/review/:page/:requestId`}
      render={(props) => <LeavePage {...props} mode="review" />}
    />
  </Switch>
);
export default React.memo(LeavePageDarkRoute);
