import { commendConstants } from '../constants/commend';
import { commendService } from '../services/commend';
import sweetAlert from 'sweetalert2';

const toast = sweetAlert.mixin({
	toast: true,
	width: 300,
	position: 'center',
	showConfirmButton: false,
	timer: 1500
});

export const commendActions = {
    getCommendations,
    currentUserCommendations,
    getUserCommends,
    postCommend,
}

function getCommendations(userId) {
    return dispatch => {
        dispatch(request());
        commendService.getCommendations(userId)
            .then(
                commend => {
                    dispatch(success(commend));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: commendConstants.GET_TOTAL_REQUEST } }
    function success(commend) { return { type: commendConstants.GET_TOTAL_SUCCESS, commend } }
    function failure(error) { return { type: commendConstants.GET_TOTAL_FAILURE, error } }
}

function currentUserCommendations() {
    let userDetails = localStorage.getItem('user');
    let userParsed = JSON.parse(userDetails);
    let userId = userParsed.user_id;

    return dispatch => {
        dispatch(request());
        commendService.getCommendations(userId)
            .then(
                commend => {
                    dispatch(success(commend));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: commendConstants.GET_MYTOTAL_REQUEST } }
    function success(commend) { return { type: commendConstants.GET_MYTOTAL_SUCCESS, commend } }
    function failure(error) { return { type: commendConstants.GET_MYTOTAL_FAILURE, error } }
}

function getUserCommends(userId) {

    return dispatch => {
        dispatch(request());
        commendService.getUserCommends(userId)
            .then(
                commends => {
                    dispatch(success(commends));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: commendConstants.GET_USER_REQUEST } }
    function success(commends) { return { type: commendConstants.GET_USER_SUCCESS, commends } }
    function failure(error) { return { type: commendConstants.GET_USER_FAILURE, error } }
}

function postCommend(commendedUserId, commendingUserId, commend) {

    return dispatch => {
        dispatch(request());
        commendService.postCommend(commendedUserId, commendingUserId, commend)
            .then(
                commend => {
                    dispatch(success(commend));
                    //setTimeout(function(){window.location.reload(1);}, 2000);
                    toast.fire({
                        type: 'success',
                        title: commend?.success || 'Successfully commended user'
                    });
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.fire({
                        type: 'error',
                        title: 'Something went wrong.'
                    });
                }
            );
    };

    function request() { return { type: commendConstants.POST_REQUEST } }
    function success(commend) { return { type: commendConstants.POST_SUCCESS, commend } }
    function failure(error) { return { type: commendConstants.POST_FAILURE, error } }
}