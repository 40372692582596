//npm packages
import React from 'react';
import { connect, useDispatch } from 'react-redux';
//actions
import { challengeActions } from '../_core/actions/challenge';
import '../ChallengeWidget/ChallengeWidget.sass'
import {DisplayChallenge} from '../ChallengeWidget/ChallengeCard';

import { history } from '../_core/helpers/history';


//image
import ChallengeEmpty  from '../_templates/challenges-empty-state.png'

const mapStateToProps = ({ challenges }) => ({
	loading: challenges.loading ?? true,
	challenges: challenges.items ?? {},
    totalPerPage:  challenges.totalPerPage,
    perPage: challenges.perPage,
    currentPage: challenges.currentPage
});

const AcceptedChallenges =  connect(mapStateToProps)((props) => {

    const dispatch = useDispatch();
    
    React.useEffect(() => {
		dispatch(challengeActions.getAlllAcceptedChallenge(1, 100000));
    }, [dispatch]);
    

    const handleChallenge = () => {
        //setAppModal(true);
        history.push('/engage/challenges');
    }

    return (
        <div className="challenges" style={{maxWidth:'-webkit-fill-available'}}>
            <div className="widget-header">
                {props.loading === false && <></> }
                
                {props.challenges? 
                <div className="challenges-list">
                <p className="recent-challenges">My Accepted Challenges</p>
                    
                    {props.challenges.length !== 0  ? 
                        <div className="card-columns challenge-cards">
                            {props.challenges[0] && props.challenges.map((challenge, index)=> <DisplayChallenge key={index} getChallenge={challenge}/>)} 
                        </div>
                    :
                        <div style={{display:'grid', justifyContent:'center'}}>   
                            <img src={ChallengeEmpty} className="ChallengeEmptyState" alt="Challenge Empty State" style={{margin:'30px'}}/> 
                            <h5 className="challenge-empty-state-main-text">No Accepted Challenges</h5>
                            <p className="challenge-empty-state-sub-text">Ready for any challenge? Let's do this!</p>
                            <button className="waves-effect waves-light btn btn-create-challenge" onClick={handleChallenge} style={{marginLeft:'0'}}>List of Challenges</button>
                        </div>
                    } 
                </div> :
                    <div className="challenges-list"  >
                    <p className="recent-challenges">My Accepted Challenges</p>  
                        <div style={{display:'grid', justifyContent:'center'}}>   
                            <img src={ChallengeEmpty} className="ChallengeEmptyState" alt="Challenge Empty State" style={{margin:'30px'}}/> 
                            <h5 className="challenge-empty-state-main-text">No Accepted Challenges</h5>
                            <p className="challenge-empty-state-sub-text">Ready for any challenge? Let's do this!</p>
                            <button className="waves-effect waves-light btn btn-create-challenge" onClick={handleChallenge} style={{marginLeft:'0'}}>List of Challenges</button>
                        </div>
                    </div>
                }
            </div>
        
        </div>
    
    )
})

export {AcceptedChallenges}