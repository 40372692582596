import React, { Component } from 'react';
import { maritalStatus } from '../../_core/constants/selections.js'
import M from 'materialize-css/dist/js/materialize.min.js';
import icon from '../steps/3.png'

export default class Step2 extends Component {
  constructor(props) {
    super(props);

    //  //set users props
    // const { users } = this.props;

    //if editing, assign passed user data to prepopulate
    // let _user = this.edit ? users.user.user[0] : {};

     //get logged in user
  let admin = JSON.parse(localStorage.getItem('user'));

    //setup state for new or edited profile
    this.state = {
      user: {
        title: admin.title,
 
      },
      selections: {
        maritalStatus: maritalStatus,
      }
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user }        = this.state;

    this.setState({
        user: {
            ...user,
            [name]: value
        }
    });
  }

  componentDidMount() {
    setTimeout(function() {
      var select = document.querySelectorAll('select');
      M.FormSelect.init(select);
      }, 1000)

  //datepicker for start date
    var startDate = document.querySelectorAll('#startDate');
    M.Datepicker.init(startDate,{
      onSelect: this.handleStartDatePicker
    });

    //datepicker for end date
    var endDate = document.querySelectorAll('#endDate');
    M.Datepicker.init(endDate,{
      onSelect: this.handleEndDatePicker
    });

    //datepicker for reg date
    var regDate = document.querySelectorAll('#regDate');
    M.Datepicker.init(regDate,{
      onSelect: this.handleRegDatePicker
    });

    //datepicker for birth date
    var birthDate = document.querySelectorAll('#birthDate');
    M.Datepicker.init(birthDate,{
        onSelect: this.handleBirthDatePicker
    });
 }

  // componentWillUnmount() {}

  // not required as this component has no forms or user entry
  // isValidated() {}

  jumpToStep(toStep) {
    // We can explicitly move to a step (we -1 as its a zero based index)
    this.props.jumpToStep(toStep-1); // The StepZilla library injects this jumpToStep utility into each component
  }

  render() {
    const { user, selections } = this.state;
    return (
      <div className="step step2">
        <div className="col s12 m12 l12">
          <div className="card-panel white lighten-5 z-depth-1">
            <div className="card-header">
              <div className="step-icon">
                <img src={icon} alt="icon" className="step-icon2"/>
              </div>
              <div className="steps-name">
                <h6 className="step-name"> Personal Information </h6>
              </div>
              <div className="right">
                <h6> Step 2 of 4 </h6>
              </div>
            </div>

            <div className="container step-2">
              <div className="row step-2">
                <div className="input-field col s12 m3 l2 xl1">
                  <select name="title" value={user.title} onChange={this.handleChange}>                     
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                    <option value="Mrs">Mrs</option>
                  </select>
                </div>

                <div className="input-field col s12 m3 l3 xl2">
                  <input id="first_name" type="text" name="firstName" placeholder="First Name" className="validate"/>
                </div>

                <div className="input-field col s12 m3 l2 xl3">
                  <input id="middle_name" name="middleName" type="text" placeholder="Middle Name" className="validate"/>
                </div>

                <div className="input-field col s12 m3 l3 xl4">
                  <input id="last_name" name="lastName" type="text" placeholder="Last Name"className="validate"/>
                </div>

                <div className="input-field col s12 m2 l2 xl2">
                  <input id="suffix" name="suffix" type="text" placeholder="Suffix" className="validate"/>
                </div>

                 <div className="input-field col s12 m5">
                  <input id="add1" name="address1" type="text" placeholder="Primary Address" className="validate"/>
                </div>

                <div className="input-field col s12 m5">
                  <input id="add2" name="address2" type="text" placeholder="Secondary Address" className="validate"/>
                </div>

                <div className="input-field col s12 m3 l2">
                  <input id="city" name="city" type="text" placeholder="City" className="validate"/>
                </div>

                <div className="input-field col s12 m3 l4">
                  <input name="state" id="state" type="text" placeholder="State" className="validate"/>
                </div>

                <div className="input-field col s12 m3 l4">
                  <input name="country" id="country" type="text" placeholder="Country" className="validate"/>
                </div>

                <div className="input-field col s12 m3 l4">
                  <input name="nationality" id="nationality" type="text" placeholder="Nationality" className="validate"/>
                </div>

                <div className="input-field col s12 m6 l4 xl4">
                  <input name="mothersMaidenName" id="mothersmaidenname" type="text" placeholder="Mothers Maiden Name" className="validate"/>
                </div>

                <div className="input-field col s12 m6 l3 xl4">
                  <select name="maritalStatus" value={user.maritalStatus} onChange={this.handleChange}>
                    <option defaultValue="">Marital Status</option>
                    {selections.maritalStatus.default.map((status, index) =>
                             <option key={status.value} value={status.value}>{status.label}</option>
                          )}
                  </select>
                </div>

                <div className="input-field col s12 m6 l5 xl4">
                  <p className="gender">
                    Gender
                    <label>
                      <input name="gender" type="radio" value="male"/>
                      <span>Male</span>
                    </label>

                    <label>
                      <input name="gender" type="radio" value="female"/>
                      <span>Female</span>
                    </label>
                  </p>             
                </div>

                <div className="input-field col s12 m6 l4">
                  <input type="text" id="birthDate" name="birthDate" placeholder="Birth Date" className="datepicker"/>
                </div>

                <div className="input-field col s12 m12 l4">
                  <input id="birth_place" name="birthPlace" type="text" placeholder="Birth Place" className="validate"/>
                </div>
              </div>
            </div>

            <div className="button-container-four">
              <div className="btn-prev">
                <button className="btn btn-previous" onClick={() => this.jumpToStep(1)}>Previous</button>
              </div>
              <div className="btn-nxt">
                <button className="btn btn-next2" onClick={() => this.jumpToStep(3)}>Next</button>
              </div>
            </div>





          </div>
        </div>
      </div>
    )
  }
}

