import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from './../_core/actions/user';
import MainNav from '../Components/MainNav';
import { maritalStatus, level, department, position, officeLocation } from './../_core/constants/selections'
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import moment from 'moment';


registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);


class UserProfileEditor extends React.Component {
	constructor(props) {
		super(props);

		//get logged in user
		let admin = JSON.parse(localStorage.getItem('user'));
		let adminCred = JSON.parse(localStorage.getItem('userCredentials'));

		//setup state for new or edited profile
		this.state = {
			user: {
				_id: adminCred.user._id,
				email: adminCred.user.email,
				team: adminCred.user.team, //logged in user's team
				organization: adminCred.user.organization,
				officeId: admin.officeId,
				bio: admin.bio,
				firstName: admin.firstName,
				middleName: admin.middleName,
				lastName: admin.lastName,
				title: admin.title,
				suffix: admin.suffix,
				address1: admin.address1,
				address2: admin.address2,
				city: admin.city,
				state: admin.state,
				country: admin.country,
				nationality: admin.nationality,
				mothersMaidenName: admin.mothersMaidenName,
				maritalStatus: admin.maritalStatus,
				gender: admin.gender,
				birthDate: moment(admin.birthDate).format('MMMM D, YYYY'),
				birthPlace: admin.birthPlace,
				startDate: moment(admin.startDate).format('MMMM D, YYYY'),
				endDate: moment(admin.endDate).format('MMMM D, YYYY'),
				regularizationDate: moment(admin.regularizationDate).format('MMMM D, YYYY'),
				position: admin.position,
				department: admin.department,
				level: admin.level,
				avatar: admin.avatar,
				photo: admin.photo,
				cover: admin.cover
			},
			selections: {
				maritalStatus: maritalStatus,
				department: department,
				position: position,
				level: level,
				officeLocation: officeLocation
			},
			avatar: {
				id: adminCred.user._id,

			}

		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleStartDatePicker = this.handleStartDatePicker.bind(this);
		this.handleEndDatePicker = this.handleEndDatePicker.bind(this);
		this.handleRegDatePicker = this.handleRegDatePicker.bind(this);
		this.handleBirthDatePicker = this.handleBirthDatePicker.bind(this);
		this.handleInit = this.handleInit.bind(this);


	}

	handleInit() {
		console.log('FilePond instance has initialised', this.pond);
	}


	handleChange(event) {
		const { name, value } = event.target;
		const { user } = this.state;

		this.setState({
			user: {
				...user,
				[name]: value
			}
		});
	}

	handleStartDatePicker(date) {
		const isoDate = new Date(date).toISOString();
		this.handleChange({ target: { name: 'startDate', value: isoDate } });
	}

	handleEndDatePicker(date) {
		const isoDate = new Date(date).toISOString();
		this.handleChange({ target: { name: 'endDate', value: isoDate } });
	}

	handleRegDatePicker(date) {
		const isoDate = new Date(date).toISOString();
		this.handleChange({ target: { name: 'regularizationDate', value: isoDate } });
	}

	handleBirthDatePicker(date) {
		const isoDate = new Date(date).toISOString();
		this.handleChange({ target: { name: 'birthDate', value: isoDate } });
	}

	handleSubmit(event) {
		event.preventDefault();
		const { user } = this.state;
		const { dispatch } = this.props;
		const formData = new FormData();
		const formCover = new FormData();

	


		this.pond.getFiles()
			.map(fileItem => fileItem.file)
			.forEach(file => {
				formData.append("id", user._id);
				formData.append("file", file);

			});

		this.cover.getFiles()
			.map(fileItem => fileItem.file)
			.forEach(file => {
				formCover.append("id", user._id);
				formCover.append("file", file);

			});



		dispatch(userActions.uploadAvatar(formData, user._id))
		dispatch(userActions.uploadCover(formCover, user._id))
		setTimeout(function () {
			dispatch(userActions.updateUser(user))
		}, 1000
		)

	}
	componentDidMount() {
		this.props.dispatch(userActions.getAll(1, 100000));

		var elem = document.querySelector(".sidenav");
		M.Sidenav.init(elem, {
			edge: "left",
			inDuration: 250
		});

		var option = document.querySelector('.tabs');
		M.Tabs.init(option, {});

		setTimeout(function () {
			var select = document.querySelectorAll('select');
			M.FormSelect.init(select);
		}, 1000)

		//datepicker for start date
		var startDate = document.querySelectorAll('#startDate');
		M.Datepicker.init(startDate, {
			onSelect: this.handleStartDatePicker
		});

		//datepicker for end date
		var endDate = document.querySelectorAll('#endDate');
		M.Datepicker.init(endDate, {
			onSelect: this.handleEndDatePicker
		});

		//datepicker for reg date
		var regDate = document.querySelectorAll('#regDate');
		M.Datepicker.init(regDate, {
			onSelect: this.handleRegDatePicker
		});

		//datepicker for birth date
		var birthDate = document.querySelectorAll('#birthDate');
		M.Datepicker.init(birthDate, {
			onSelect: this.handleBirthDatePicker
		});
	}



	render() {
		const { user, selections } = this.state;
		const { updating } = this.props;



		return (


			<div className="profile">
				<MainNav />
				<div className="container">
					<form name="form" onSubmit={this.handleSubmit}>

						<h4 className="title_1">Update Profile</h4>

						<div className="col s12 m8 offset-m2 l6 offset-l3">
							<div className="card-panel grey lighten-5 z-depth-1">
								<div className="row valign-wrapper">


									<div className="col s12 m4 l4">
										<div className="row rpl15px">

											<span className="black-text">
												Upload Avatar
							            	</span>
											<div>

												<FilePond ref={ref => this.pond = ref}
													file={user.avatar}
													allowMultiple={false}
													acceptedFileTypes={['image/png', 'image/jpeg']}
												/>

											</div>
											<span className="black-text">
												Upload Proffessional Photo
							            	</span>
											<div className="file-field input-field">
												<FilePond ref={ref => this.pondref = ref}
													allowMultiple={false}
													maxFiles={1}

												/>
											</div>
											<span className="black-text">
												Cover Image
							            	</span>

											<FilePond ref={ref => this.cover = ref}
												allowMultiple={false}
												acceptedFileTypes={['image/png', 'image/jpeg']}
											/>
										</div>
									</div>

									<div className="col s12 m6 l6">
										<div className="input-field col s12">
											<textarea id="bio" name="bio" className="materialize-textarea" data-length="120" value={user.bio} onChange={this.handleChange}>{user.bio}</textarea>
											<label htmlFor="bio" className={(user.bio ? 'active' : '')}>Bio</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="card grey lighten-5 z-depth-1">
							<div className="card-tabs">
								<div className="row">


									<div id="personal-info" className="col s12">
										<h5 className="title_1">Personal Information</h5>
										<div className="row">
											<div className="input-field col s12 m1">
												<select name="title" value={user.title} onChange={this.handleChange}>
													<option value="Mr">Mr</option>
													<option value="Ms">Ms</option>
													<option value="Mrs">Mrs</option>
												</select>
												<label>Title</label>
											</div>

											<div className="input-field col s12 m4">
												<input id="first_name" type="text" name="firstName" className="validate" value={user.firstName} onChange={this.handleChange} />
												<label htmlFor="first_name" className={(user.firstName ? 'active' : '')}>First Name</label>
											</div>

											<div className="input-field col s12 m2">
												<input id="middle_name" name="middleName" type="text" className="validate" value={user.middleName} onChange={this.handleChange} />
												<label htmlFor="middle_name" className={(user.middleName ? 'active' : '')}>Middle Name</label>
											</div>

											<div className="input-field col s12 m4">
												<input id="last_name" name="lastName" type="text" className="validate" value={user.lastName} onChange={this.handleChange} />
												<label htmlFor="last_name" className={(user.lastName ? 'active' : '')}>Last Name</label>
											</div>

											<div className="input-field col s12 m1">
												<input id="suffix" name="suffix" type="text" className="validate" value={user.suffix} onChange={this.handleChange} />
												<label htmlFor="suffix" className={(user.suffix ? 'active' : '')}>Suffix</label>
											</div>
										</div>

										<div className="row">
											<div className="input-field col s12 m5">
												<input id="address1" name="address1" type="text" className="validate" value={user.address1} onChange={this.handleChange} />
												<label htmlFor="address1" className={(user.address1 ? 'active' : '')}>Address 1</label>
											</div>

											<div className="input-field col s12 m5">
												<input id="add2" name="address2" type="text" className="validate" value={user.address2} onChange={this.handleChange} />
												<label htmlFor="add2" className={(user.address2 ? 'active' : '')}>Address 2</label>
											</div>

											<div className="input-field col s12 m2">
												<input id="city" name="city" type="text" className="validate" value={user.city} onChange={this.handleChange} />
												<label htmlFor="city" className={(user.city ? 'active' : '')}>City</label>
											</div>

											<div className="input-field col s12 m4 l4">
												<input name="state" id="state" type="text" className="validate" value={user.state} onChange={this.handleChange} />
												<label htmlFor="state" className={(user.state ? 'active' : '')}>State</label>
											</div>

											<div className="input-field col s12 m4 l4">
												<input name="country" id="country" type="text" className="validate" value={user.country} onChange={this.handleChange} />
												<label htmlFor="country" className={(user.country ? 'active' : '')}>Country</label>
											</div>

											<div className="input-field col s12 m4 l4">
												<input name="nationality" id="nationality" type="text" className="validate" value={user.nationality} onChange={this.handleChange} />
												<label htmlFor="nationality" className={(user.nationality ? 'active' : '')}>Nationality</label>
											</div>

											<div className="input-field col s12 m4 l4">
												<input name="mothersMaidenName" id="mothersmaidenname" type="text" className="validate" value={user.mothersMaidenName} onChange={this.handleChange} />
												<label htmlFor="mothersmaidenname" className={(user.mothersMaidenName ? 'active' : '')}>Mothers Maiden Name</label>
											</div>

											<div className="input-field col s12 m4 l4">
												<select name="maritalStatus" value={user.maritalStatus} onChange={this.handleChange}>
													<option defaultValue="">--- Choose Marital Status ---</option>
													{selections.maritalStatus.default.map((status, index) =>
														<option key={status.value} value={status.value}>{status.label}</option>
													)}
												</select>
												<label>Marital Status</label>
											</div>

											<div className="input-field col s12 m4 l4">
												<span><label>Gender:</label></span>
												<p>
													<label>
														<input name="gender" type="radio" value="male" checked={user.gender === 'male'} onChange={this.handleChange} />
														<span>Male</span>
													</label>

													<label>
														<input name="gender" type="radio" value="female" checked={user.gender === 'female'} onChange={this.handleChange} />
														<span>Female</span>
													</label>
												</p>

											</div>

											<div className="input-field col s12 m4 l4">
												<input type="text" id="birthDate" name="birthDate" className="datepicker" value={user.birthDate ? moment(user.birthDate).format('MMMM D, YYYY') : ''} onChange={this.handleChange} />
												<label htmlFor="birthDate" className={(user.birthDate ? 'active' : '')}>Birthdate</label>
											</div>

											<div className="input-field col s12 m4 l4">
												<input id="birth_place" name="birthPlace" type="text" className="validate" value={user.birthPlace} onChange={this.handleChange} />
												<label htmlFor="birth_place" className={(user.birthPlace ? 'active' : '')}>Birth Place</label>
											</div>
										</div>
									</div>

									<div id="personal-info" className="col s12">
										<h5 className="title_1">Credentials Info</h5>
										<div className="row">
											<div className="input-field col s12 m12 l12">
												<input id="email" disabled name="email" type="email" className="validate" value={user.email} onChange={this.handleChange} />
												<label>Email</label>
											</div>

											<div className="input-field col s12 m12 l12">
												<input type="text" disabled name="team" className="validate" value={user.team} onChange={this.handleChange} />
												<label>Team</label>
											</div>
										</div>
									</div>

									<div id="personal-info" className="col s12">
										<h5 className="title_1">Employment Info</h5>
										<div className="row">




											<div className="input-field col s12 m4 l4">
												<input type="text" id="startDate" name="startDate" className="datepicker" value={user.startDate ? moment(user.startDate).format('MMMM D, YYYY') : ''} onChange={this.handleChange} />
												<label htmlFor="startdate" className={(user.startDate ? 'active' : '')}><span className="color_asterisk">*</span>Start Date</label>
											</div>

											<div className="input-field col s12 m4 l4">
												<input type="text" id="endDate" name="endDate" className="datepicker" value={user.endDate ? moment(user.endDate).format('MMMM D, YYYY') : ''} onChange={this.handleChange} />
												<label htmlFor="enddate" className={(user.endDate ? 'active' : '')}>End Date</label>
											</div>

											<div className="input-field col s12 m4 l4">
												<input type="text" id="regDate" name="regularizationDate" className="datepicker" value={user.regularizationDate ? moment(user.regularizationDate).format('MMMM D, YYYY') : ''} onChange={this.handleChange} />
												<label htmlFor="regularization_date" className={(user.regularizationDate ? 'active' : '')}>Regularization Date</label>
											</div>

											<div className="input-field col s12 m4">
												<select name="position" value={user.position} onChange={this.handleChange}>
													<option defaultValue="">--- Choose Position ---</option>
													{selections.position.software.map((status, index) =>
														<option key={status.value} value={status.value}>{status.label}</option>
													)}
												</select>
												<label>Position</label>
											</div>

											<div className="input-field col s12 m4">
												<select name="level" value={user.level} onChange={this.handleChange}>
													<option defaultValue="">--- Choose Level ---</option>
													{selections.level.software.map((status, index) =>
														<option key={status.value} value={status.value}>{status.label}</option>
													)}
												</select>
												<label>Level</label>
											</div>

											<div className="input-field col s12 m4">
												<select name="department" value={user.department} onChange={this.handleChange}>
													<option defaultValue="">--- Choose Department ---</option>
													{selections.department.software.map((status, index) =>
														<option key={status.value} value={status.value}>{status.label}</option>
													)}
												</select>
												<label>Department</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="input-field center-align">
							<button className="waves-effect waves-light btn btn-save" onClick={this.handleSubmit}>SAVE</button>
							{updating &&
								<img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
							}
							<Link to="/profile" className="btn btn-link btn-cancel">Cancel</Link>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { authentication, registration, alert } = state;
	const { user } = authentication;
	const { updating } = registration;

	return {
		updating,
		user,
		alert
	};
}

const connecteduserProfileEditor = connect(mapStateToProps)(UserProfileEditor);
export { connecteduserProfileEditor as UserProfileEditor };