import eventsConstants from '../constants/events';

const initialState = {
    items: [],
    loading: false,
    error: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case eventsConstants.GET_EVENTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case eventsConstants.GET_EVENTS_SUCCESS:
            return {
                items: action.events,
                loading: false,
                error: null,
            }

        case eventsConstants.GET_EVENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state;
    }
}