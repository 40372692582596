import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
// import Select, { components } from "react-select";
// import moment from "moment";
import { history } from "../../../_core/helpers/history";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
// import Swal from 'sweetalert2'
import {useDropzone} from 'react-dropzone';
import {
  ContentWrapper,
  LabelCustom,
  SaveAndCancelButton,
} from "../../Components/PageWrapper/ContentWrapper";
import { DragDropImage,} from "../../Components/ImageUploader/updatedUpload";

import calendar from '../../../_templates/dropdown_chevrondown.png'

import { badgesActions } from "../../../_core/actions/badges";
import { rewardActions } from "../../../_core/actions/reward";
import { rewardService } from "../../../_core/services/reward";
import Swal from 'sweetalert2'


import "./Reward.scss";

import {
    CustomInputText,
    CustomTextAreaEngage,
  } from "../../Components/FloatingTextBox/TextArea";

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;
export const SearchIconFindUser = styled.img`
  margin-bottom: -30px;
  margin-left: 15px;
  display: flex;
  position: relative;
  z-index: 1;
  height: 15px;
  width: 15px;
  object-fit: contain;
`;

export const FindUserWrapper = styled.div`
  > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
    margin-left: 15px;
  }
`;

export const GridContainer = styled.div`
  ${(props) =>
    props.isShowUserDetails === false
      ? css`
          margin-top: 20px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;

          // > div > label {
          //   margin-top: 10px;
          // }

        
        `
      : css`
          display: block;
        `}

  > div > label.label__questionnaires, > div > label.label__tenures {
    display: flex;
    float: left;
    top: 10px;
    position: relative;
    z-index: 1;
    margin-left: 25px;
    color: #9ba2ce;
  }

  > div > label.Error__message {
    margin-left: 10px;
  }

  > div > label.label__questionnaires {
    background: #2a3143;
    width: 72px;
    margin-top: 5px;
   
  }
  > div > label.label__tenures {
    background: #2a3143;
    width: 40px;
    margin-top: 5px;
  }

  > div > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
  }

  > div > div.error_date {
    border: 1px solid #f14646;
    border-radius: 3px;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
  margin-top: 20px;

    > img.admin_calendar_icon1 {
        display: flex;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
    }
    > img.admin_calendar_icon2 {
        display: flex;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
    }
  
    .floating-label {
        display: inline-block;
    }

    .floating-label:nth-child(2) {
        float: right; 
    }

   .calendar-dark {
        background-image:url(${calendar}); !important;
    }

`;

export const BadgeSelectWrapper = styled.div`
    background-color: #3F4A64;
    width: 155px;

`;

const mapStateToProps = ({ evaluations, questionnaires, tenures, users, badges, reward}) => ({
  evaluations,
  questionnaires,
  tenures,
  users,
  badges,
  reward
});

const CreateEditQuestPage = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();
  const [InputError, setInputError] = useState(false);
  const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
  const [files, setFiles] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [remove, setRemove] = useState(false);

  const [minMaxValueError, setMinMaxValueError] = useState(false);

  const { pageId } = useParams();

  // const userCred = JSON.parse(localStorage.getItem('userCredentials'))


  const [checked, setchecked] = useState(false);

  // const [startDate, setStartDate] = useState(new Date());
  // const [ExpirationDate, setExpirationDate] = useState(new Date());

  //const [IsEditedEval, setIsEditedEval] = useState(false)
  // const [IsEditedExpire, setIsEditedExpire] = useState(false)

  const [reward, setReward] = useState({
    active: checked ? 1 : 0,
    title: "",
    content:"",
    image: "",
    expiresAt: "",
    value: "",
    stock: "",
    status: "new"
  });

  React.useEffect(() => {
    if (pageId) {
      setIsAdd(false);
      rewardService.getContent(pageId).then((res) => { 
        if(res.success !== undefined) {
          let data = res.success[0];
     
          setReward({
            active: data.active ,
            title: data.title,
            content: data.content,
            expiresAt: data.expiresAt,
            value: data.value,
            stock: data.stock,
            image: data.image,
          })
          setchecked(data.active !== 1 ? false : true)
          setCount(data.content.length)
        }
      });
    }
    //eslint-disable-next-line
  }, [ pageId]);

  console.log(reward)

  const handleNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    const total = parseInt(value);
    if(value.length === 0) {
      setReward({ ...reward, [e.target.name]: '', });
    } else {
        setReward({ ...reward, [e.target.name]: total, });
    }
};
  const handleNumberValue = (e) => {
    const minimumValue = 50;
    const maximumValue = 9999999;

    const value = e.target.value.replace(/\D/g, "");
    const total = parseInt(value);
    if(value.length === 0) {
      setReward({ ...reward, [e.target.name]: '', });
    } else {
        if(e.target.value < minimumValue || e.target.value > maximumValue) {
          setReward({ ...reward, [e.target.name]: total, });
          setMinMaxValueError(true);
          console.log(minMaxValueError);
        }else {
          setMinMaxValueError(false);
          setReward({ ...reward, [e.target.name]: total, });
        }
    }
  };


  const {getRootProps, getInputProps, open} = useDropzone({
    accept: 'image/x-png, image/png, image/jpg, image/jpeg, image/gif',
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
        })));
        setReward({
            ...reward,
            image:acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
                }))[0],
          });

    }
    });
 


  React.useEffect(() => {
    dispatch(badgesActions.getAll(1,10000));
    dispatch(rewardActions.getAll(100000,1));
    //eslint-disable-next-line
  }, [dispatch]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    if(name === "content"){
        setCount(value.length)
    }
      setReward({
        ...reward,
        [name]: value,
      });

    }

    const removeSelectedImage = () => {
      setFiles([]);
      setReward({ ...reward, image: null});
      setRemove(true);
  }   

    // const [challenge, setChallenge] = useState([])

  // const { ValueContainer, Placeholder } = components;

  // const CustomValueContainer = ({ children, ...props }) => {
  // return (
  //     <ValueContainer {...props}>
  //     <Placeholder {...props} isFocused={props.isFocused} className="menu">
  //         {props.selectProps.placeholder}
  //     </Placeholder>
  //     {React.Children.map(children, child =>
  //         child && child.type !== Placeholder ? child : null
  //     )}
  //     </ValueContainer>
  // );
  // };

  // const customStyles2 = {
  //   control: (base, state) => (InputError ? {
  //       ...base,
  //       background: "#2e3648",
  //       height:"40px",
  //       // match with the menu
  //       borderRadius: '5px',
  //       marginBottom:"15px",
  //       // Overwrittes the different states of border
  //       borderColor: state.isFocused ? "#323a4f" : "#f14646",
  //       // Removes weird border around container
  //       boxShadow: state.isFocused ? null : null,
  //       "&:hover": {
  //         // Overwrittes the different states of border
  //         borderColor: state.isFocused ? "#556ee6" : "#323A4F",
  //       },
  //     }:{
  //       ...base,
  //       background: "#2e3648",
  //       height:"40px",
  //       borderRadius: '5px',
  //       marginBottom:"20px",
  //       borderColor: state.isFocused ? "#323a4f" : "#323a4f",
  //       boxShadow: state.isFocused ? null : null,
  //       "&:hover": {
  //         borderColor: state.isFocused ? "#556ee6" : "#323A4F",
  //       },
  //     }),
  //   container: (provided, state) => ({
  //       ...provided,
  //       marginTop: 0,
  //   }),
  //   indicatorSeparator: (provided, state) => ({
  //     ...provided,
  //     display: "none",
  //   }),
  //   indicatorsContainer: (provided, state) => ({
  //       ...provided,
  //       marginTop: '-15px',
  //     }),
  //   dropdownIndicator: (base, state) => ({
  //       ...base,
  //       color: "#9BA3CA",
  //       "&:hover": {
  //           color: state.isFocused ? "#556ee6" : "#556ee6",
  //         }, 
  //   }),
  //   option: (base, state) => ({
  //       ...base,
  //       color: "#9ba2ce",
  //       backgroundColor: state.isSelected ? "#32394A" : "#2e3648",
  //       ":hover": {
  //         backgroundColor: state.isSelected ? "#2e3648" : "#32394A",
  //       },
  //       zIndex: "999",
  //     }),

  //   menuList: (base) => ({
  //       ...base,
  //       // kill the white space on first and last option
  //       padding: 0
  //   }),
  //   menu: (provided) => ({
  //     ...provided,
  //     height: "auto",
  //     maxHeight: "208px",
  //     position: "absolute",
  //     display: "block",
  //     background: "#2E3648",
  //   }),
  //   valueContainer: (provided, state) => ({
  //       ...provided,
  //       overflow: "visible"
  //   }),
  //   input: (provided, state) => ({
  //       ...provided,
  //       height: 35,
  //       color: '#9BA3CA',
  //       lineHeight: state.isFocused ? 35 : 0,
  //       transition:  "top 0.1s, font-size 0.1s"
  //   }),
  //   placeholder: (provided, state) => (InputError ? {
  //       ...provided,
  //       position: "absolute",
  //       top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
  //       transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
  //       fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
  //       background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
  //       color: state.hasValue ? "#9BA3CA" :"#f14646",
  //   }:{
  //       ...provided,
  //       position: "absolute",
  //       top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
  //       transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
  //       fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
  //       background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
  //       color: "#9BA3CA",
  //   }),
  //   singleValue: (provided) => ({
  //       ...provided,
  //       color: ' #9BA3CA',
  //       fontSize:"14px",
  //       fontWeight:'normal',
  //       top:'35%'
  //     }),
  // };

  const cancelQuest = () => {
    setReward({
      active: "",
      title: "",
      content:"",
      image: "",
      expiresAt:"",
      value: "",
      stock: "",
      status: "new"
    });
    setFiles([]);
    // setChallenge([]);
    setInputError(false);
    history.goBack();

  }

  const submitEvaluation = () => {

    if(minMaxValueError) {
      setMinMaxValueError(true);

      console.log(minMaxValueError)
    }
    else {
      setMinMaxValueError(false);
      if(
          !reward.title ||
          !reward.content ||
          !reward.value ||
          !reward.stock 
      ) {
          setInputError(true);
      }else{
          if(isAdd){
            dispatch(rewardActions.create(reward))
              setInputError(false);
  
            setTimeout(() => {
              cancelQuest()
            }, 50)
          }else {
            rewardService.updateReward(pageId, reward).then((res) => { 
                const ToastSuccess = Swal.mixin({
                  background: "#2a3042",
                  toast: true,
                  width: 300,
                  position: 'center',
                  showConfirmButton: false,
                  timer: 1500
                });
            
                const ToastFailed = Swal.mixin({
                    background: "#2a3042",
                    toast: true,
                    width: 300,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 1500
                });
  
                setTimeout(() => {
                  ToastSuccess.fire({
                      type: 'success',
                      title: '<span style="color:#FFFFFF"> Reward has been updated! </span>'
                  })
                }, 100)
                history.goBack()  
  
                ToastFailed.fire({
                  type: 'error',
                  title: `<span style="color:#FFFFFF"> Badge Update Failed</span> `
              });
            });
          }
      }
    }

  };

    // const handleSelect = (data) => {
    //     const names = data.target.name
    //     const value = data.target.value
    //     setReward({ ...reward, [names]: value });
    // };
    
    const handleChecked=(checked)=> {
        setchecked(checked);
        setReward({ ...reward, active: checked });
    }

    // const handleToDate = (date) => {
    //     setExpirationDate(date);
    //     setIsEditedExpire(true);
    //     const days = moment(date).diff(moment(startDate), "days") + 1
    //     setReward({ ...reward, expiresAt: moment(date).utc().format(), duration: days });
    // };

    // const handleFromDate = (date) => {
    //     setStartDate(date);
    // };

    let isValidTitle = reward.title.trim() === '';

    // const creditsOptions = [
    //     { value: 10, label: '10'},
    //     { value: 20, label: '20'},
    //     { value: 50, label: '50'},
    //     { value: 100, label: '100'},
    //     { value: 250, label: '250'},
    //     { value: 300, label: '300'},
    //     { value: 500, label: '500'},
    // ];

  return (
    <CustomContentWrapper>
      <ContentWrapper>
        <LabelCustom className="main-label">
          {isAdd ? "Create" : "Edit"} a Reward
        </LabelCustom>
        <div className="toggleSwitch">
            <div className="titleSwitch">
                <p style={{fontWeight:'700'}}>Rewards availability </p>
                <p>Rewards will be displayed on rewards page</p>
            </div>
                <Switch 
                    onColor="#556EE6"
                    height={16}
                    width={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={handleChecked} 
                    checked={checked} 
                    className="toggleBtn"
                />
        </div> 
        <CustomInputText
            label={"Reward Name"}
            name={"title"}
            value={reward.title}
            inputOnChange={handleChangeInput}
            isError={isValidTitle && InputError}
        />
        <CustomTextAreaEngage
            label={"Description"}
            name={"content"}
            value={reward.content}
            inputOnChange={handleChangeInput}
            maxlength="250"
            isError={!reward.content && InputError}
        />
        <p className="charCount">{count}</p>

        <label className="questCover">Reward Cover photo</label>
        <div {...getRootProps({className: 'dropzone'})}>
            <div className={files[0] || reward.image? 'active-dz': 'unactive-dz'} >
                <input {...getInputProps()} />
                <div>
                    <p className="dropTitle">
                      Drag and Drop Cover Photo here or
                      <button onClick={open} className="custom-file-upload-engage">
                        Browse
                      </button>
                    </p>
                    <small>max file size : 20mb</small>
                </div>
               
            </div>
            <DragDropImage 
              removeImage={removeSelectedImage} 
              selectedImage={files} 
              existingImage={reward.image} 
              remove={remove}
              isAdd={isAdd}
            />
        </div>
        
        {/* <DatePickerWrapper>
            <label className="rewardCover">Valid till</label>
            <div style={{marginTop:'20px'}}>
            <CustomDatePicker
                type="text"
                label="From"
                selected={startDate ? startDate : new Date(reward.createdAt)}
                startDate={startDate}
                endDate={ExpirationDate}
                value={startDate}
                dateFormat="dd/MM/yyyy"
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                selectsStart
                maxDate={ExpirationDate}
                readOnly
            />
            <CustomDatePicker
                type="text"
                label="To"
                selected={ExpirationDate && IsEditedExpire === false ?  new Date(reward.expiresAt)  :  ExpirationDate}
                startDate={startDate}
                minDate={startDate}
                endDate={ExpirationDate}
                selectsEnd
                onChange={(date) => handleToDate(date)}
                dateFormat="dd/MM/yyyy"
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                isError={!reward.expiresAt && InputError}
            />
            </div>

        </DatePickerWrapper> */}

        {/* <Select
            value={creditsOptions.find(option => option.value === reward.value || '' )}
            onChange={(statusValue)=> {handleSelect({target: { name:'value', value: statusValue === null ? '' : statusValue.value }})}}
            components={{
                ValueContainer: CustomValueContainer
            }}
            captureMenuScroll={false}
            placeholder="Value"
            styles={customStyles2}
            options={creditsOptions}
            openMenuOnClick={false}
            isSearchable={false}
            menuPlacement="bottom"
            menuPortalTarget={document.querySelector('body')} 
            hideSelectedOptions={false}
            isClearable={true}
            maxMenuHeight={200}
            className="basic-single"
            classNamePrefix="select"
            name="value"
        />
        {InputError && !reward.value && (
            <div className="Error_div"><label className="Error__message">Please fill-up this field</label></div>
        )} minMaxValueError*/}
        <CustomInputText
            label={"Value"}
            name={"value"}
            value={String(reward.value)}
            inputOnChange={(e) => handleNumberValue(e)}
            isError={!reward.value && InputError}
        />

        {minMaxValueError && (
            <div className="Error_div" style={{marginTop: '-10px', marginBottom: '15px'}}><label className="Error__message">Min Value is 50 and Max value is 9999999</label></div>
        )} 

        <CustomInputText
            label={"Stock"}
            name={"stock"}
            value={String(reward.stock)}
            inputOnChange={(e) => handleNumber(e)}
            isError={!reward.stock && InputError}
        />
        <SaveAndCancelButton
          cancelFn={() => {cancelQuest()}}
          saveFn={() => submitEvaluation()}
        />
      </ContentWrapper>
    </CustomContentWrapper>
  );
});

export default CreateEditQuestPage;
