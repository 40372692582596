import { badgesConstants } from '../constants/badges';
import { badgesService } from '../services/badges';
// import { alertActions } from './alert';
import Swal from 'sweetalert2'

export const badgesActions = {
    getAll,
    create,
    badgeDelete,
    getContent,
    updateBadge
    
};

function badgeDelete(id){
    // console.log(id)
    return dispatch => {
        dispatch(request(id));
        badgesService.badgeDelete(id)
            .then(
                badges => dispatch(success(id)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: badgesConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: badgesConstants.DELETE_SUCCESS, id } }
    function failure(error) { return { type: badgesConstants.DELETE_FAILURE, error } }
}

function getAll(page, itemsPerPage, search, filter) {
    return dispatch => {
        dispatch(request());
        
        badgesService.getAll(page, itemsPerPage, search, filter)
            .then(
                badges => dispatch(success(badges)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: badgesConstants.GETALL_REQUEST } }
    function success(badges) { return { type: badgesConstants.GETALL_SUCCESS, badges } }
    function failure(error) { return { type: badgesConstants.GETALL_FAILURE, error } }
}

function getContent(id){
    return dispatch => {
        dispatch(request(id))
        
        badgesService.getContent(id)
            .then(
                badges => {
                    dispatch(success(badges.success))

                },
                error => dispatch(failure(error.toString()))
            )
    }

    function request(id) { return { type: badgesConstants.GET_REQUEST, id } }
    function success(badges) { return { type: badgesConstants.GET_SUCCESS, badges } }
    function failure(error) { return { type: badgesConstants.GET_FAILURE, error } }
}

function create(createBadge) {

    const ToastSuccess = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });

    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const imageFile = createBadge.badgeImage;
    const fd = new FormData();

    fd.append('file',imageFile)
    createBadge.badgeImage = null;

    function failure(error) { return { type: badgesConstants.SUBMIT_POST_FAILURE, error } }
    // function request(post) { return { type: badgesConstants.SUBMIT_POST_REQUEST, post } }
    function success(createBadge) { return { type: badgesConstants.SUBMIT_POST_SUCCESS, createBadge } }

    return async dispatch => {
        let createdPost;
        let imageData;
        let isError = null;

        try {
            createdPost = await badgesService.create(createBadge);
        } catch(error) {
            isError = error.toString();
            dispatch(failure(error.toString()));
            Toast.fire({
                type: 'error',
                title: `
                    <span>
                        Something went wrong
                    </span>
                `
            });

            return;
        }

        try {
            if( imageFile ){
                imageData = await badgesService.imageUpload(createdPost._id, fd)
            }
        } catch(error) {
            isError = error;
            Toast.fire({
                type: 'error',
                title: `
                    <span>
                        Something went wrong
                    </span>
                `
            });

            return;
        }



        dispatch(success({
            ...createBadge,
            badgeImage: imageData?.badgeImage ?? null,
            _id: createdPost._id
        }));

        if(isError === null){
            await ToastSuccess.fire({
                type: 'success',
                title: '<span style="color:green">Badge has been created! </span>'
            })
        }
    };
}


function updateBadge(id, posts){
    // custom toast mixin for sweetAlert
    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });

    const ToastFailed = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });

    return dispatch => {
        dispatch(request(posts))

        badgesService.updateBadge(id, posts)
            .then(
                badges => {
                    dispatch(success());
                    setTimeout(() => {
                        ToastSuccess.fire({
                            type: 'success',
                            title: '<span style="color:#FFFFFF"> Badge has been updated! </span>'
                        })
                    }, 100)
                },
                error => {
                    dispatch(failure(error.toString()));
                    ToastFailed.fire({
                        type: 'error',
                        title: `<span style="color:#FFFFFF"> Badge Update Failed</span> `
                    });
                }
            )
    }

    function request(posts) { return { type: badgesConstants.UPDATE_BADGES_REQUEST, posts } }
    function success(posts) { return { type: badgesConstants.UPDATE_BADGES_SUCCESS, posts } }
    function failure(error) { return { type: badgesConstants.UPDATE_BADGES_FAILURE, error } }
}



