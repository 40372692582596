export const reactionConstants = {
    POST_REQUEST: 'POST_REACTION_REQUEST',
    POST_SUCCESS: 'POST_REACTION_SUCCESS',
    POST_FAILURE: 'POST_REACTION_FAILURE',

    GETALL_REQUEST: 'REACTION_GETALL_REQUEST',
    GETALL_SUCCESS: 'REACTION_GETALL_SUCCESS',
    GETALL_FAILURE: 'REACTION_GETALL_FAILURE',

    GET_REQUEST: 'REACTION_GET_REQUEST',
    GET_SUCCESS: 'REACTION_GET_SUCCESS',
    GET_FAILURE: 'REACTION_GET_FAILURE',

    GET_ORIGIN_REQUEST: 'REACTION_GET_ORIGIN_REQUEST',
    GET_ORIGIN_SUCCESS: 'REACTION_GET_ORIGIN_SUCCESS',
    GET_ORIGIN_FAILURE: 'REACTION_GET_ORIGIN_FAILURE',
};

