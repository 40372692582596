import { attrUserConstants } from '../constants/attrUser';
import { attrUserService } from '../services/attrUser';

export const attrUserActions = {
    getUsers
};

function getUsers(id) {
    return dispatch => {
        dispatch(request(id));

        attrUserService.getUsers(id)
            .then(
                attrUsers => {
                    dispatch(success(attrUsers));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: attrUserConstants.ATTR_USERS_REQUEST } }
    function success(attrUsers) { return { type: attrUserConstants.ATTR_USERS_SUCCESS, attrUsers } }
    function failure(error) { return { type: attrUserConstants.ATTR_USERS_FAILURE, error } }
}
