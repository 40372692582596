import { supportTicketConstants } from '../constants/supportTicket';


export function supportTickets(state = {}, action){
    switch(action.type){
        
        //POST COE
        case supportTicketConstants.SEND_SUPPORTTICKET_REQUEST:
          return {saving: true};
        case supportTicketConstants.SEND_SUPPORTTICKET_SUCCESS:
          return {};
        case supportTicketConstants.SEND_SUPPORTTICKET_FAILURE:
          return {error: action.error};
        default:
          return state

    }
}