export const leaveTypeConstants = {
    GETALL_REQUEST: 'LEAVETYPES_GETALL_REQUEST',
    GETALL_SUCCESS: 'LEAVETYPES_GETALL_SUCCESS',
    GETALL_FAILURE: 'LEAVETYPES_GETALL_FAILURE',

    POST_LEAVETYPE_REQUEST: 'LEAVETYPES_POSTLEAVETYPES_REQUEST',
    POST_LEAVETYPE_SUCCESS: 'LEAVETYPES_POSTLEAVETYPES_SUCCESS',
    POST_LEAVETYPE_FAILURE: 'LEAVETYPES_POSTLEAVETYPES_FAILURE',

    DEL_REQUEST: 'LEAVETYPE_DEL_REQUEST',
    DEL_SUCCESS: 'LEAVETYPE_DEL_SUCCESS',
    DEL_FAILURE: 'LEAVETYPE_DEL_FAILURE',

    GETBYID_REQUEST: 'LEAVETYPE_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'LEAVETYPE_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'LEAVETYPE_GETBYID_FAILURE',

    UPDATE_LEAVETYPE_REQUEST: 'LEAVETYPE_UPDATE_REQUEST',
    UPDATE_LEAVETYPE_SUCCESS: 'LEAVETYPE_UPDATE_SUCCESS',
    UPDATE_LEAVETYPE_FAILURE: 'LEAVETYPE_UPDATE_FAILURE',

};


