import { post, get, del, put } from '../helpers/api';
import { userService } from '../services/user';

export const settingsService = {
    create,
    uploadAvatar,
    uploadCover,
    getAll,
    deleteOrg,
    getById,
    update
};


function create(createSettings,logo,cover){

    return post(`/api/v1/settings`, JSON.stringify(createSettings))
    .then(handleResponse)
    .then(response => {
        if(logo !== null){
            uploadAvatar(logo,response._id);
        }
        if(cover !== null){
            uploadCover(cover,response._id);
        }
       
    });
}

function uploadAvatar(avatar, id){
  return post(`/api/v1/upload/${id}/orgAvatar`, avatar).then(handleResponse);
}
function uploadCover(cover, id){
  return post(`/api/v1/upload/${id}/orgCover`, cover).then(handleResponse);
}

function getAll(page,itemsPerPage) {
    return get(`/api/v1/settings?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}

function deleteOrg(id){
    return del(`/api/v1/settings/${id}`).then(handleResponse)
}

function getById(id){
    return get(`/api/v1/settings/${id}`).then(handleResponse)
}

function update(id,data,logo,cover){
    
    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
    // const fd = new FormData();
    // const imageFile = challenge.images;
    // fd.append('file',imageFile)
    
    // //checking if challenge images has a content file
    // if(isFile(challenge.images) === true){
    //     challenge.images = null;
    // }

    console.log(logo)
    console.log(cover)

    return put(`/api/v1/settings/${id}`, JSON.stringify(data)).then(handleResponse)
    .then(response => {
        console.log(response.success?.details?._id)
        let id = response.success?.details?._id
        if(logo !== null){
            uploadAvatar(logo,id);
        }
        if(cover !== null){
            uploadCover(cover,id);
        }
    });
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              userService.logout();
              //location.reload(true);
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);

      }
      return data;
  });
}
