import { announcementConstants } from '../constants/announcement';
import { announcementServices } from '../services/announcement';
import { alertActions } from './alert';
import Swal from 'sweetalert2';

export const announcementActions = {
    getAll,
    getContent,
    deleteAnnouncement,
    updateStatusValue,
    getStatusByID,
    update
};

function getAll(selectPage, itemsPerPage) {
    return dispatch => {
        dispatch(request());

        announcementServices.getAllAnnouncement(selectPage, itemsPerPage)
        .then(
            announcements => { dispatch(success(announcements));
                dispatch(success(announcements))
                    // if(window.location.pathname === '/admin/attributes'){
                    //     history.push('/admin/attributes?page=' + Number(page) || 1);
                    // }
            },
            error => dispatch(failure(error.toString()))
        );
    };
    function request() { return { type: announcementConstants.GETALL_REQUEST } }
    function success(announcements) { return { type: announcementConstants.GETALL_SUCCESS, announcements } }
    function failure(error) { return { type: announcementConstants.GETALL_FAILURE, error } }
}

function getContent(id){
    return dispatch => {
        dispatch(request(id))
        
        announcementServices.getContent(id)
            .then(
                announcement => {
                    //console.log(announcement)
                    dispatch(success(announcement.success))

                },
                error => dispatch(failure(error.toString()))
            )
    }

    function request(id) { return { type: announcementConstants.GET_REQUEST, id } }
    function success(announcement) { return { type: announcementConstants.GET_SUCCESS, announcement } }
    function failure(error) { return { type: announcementConstants.GET_FAILURE, error } }
}


function deleteAnnouncement(id){
    return dispatch => {
        dispatch(request(id))
        
        announcementServices.deleteAnnouncement(id)
            .then(
                announcement => {
                    dispatch(success(announcement.success))
                },
                error => dispatch(failure(error.toString()))
            )

    }
    function request(id) { return { type: announcementConstants.GET_REQUEST, id } }
    function success(announcement) { return { type: announcementConstants.GET_SUCCESS, announcement } }
    function failure(error) { return { type: announcementConstants.GET_FAILURE, error } }
}


function getStatusByID(id){
    return dispatch => {
        dispatch(request(id))
        
        announcementServices.getStatusByID(id)
            .then(
                announcement => {
                    //console.log(type)
                    dispatch(success(announcement.success))

                },
                error => dispatch(failure(error.toString()))
            )
    }

    function request(id) { return { type: announcementConstants.GETBYID_REQUEST, id } }
    function success(announcement) { return { type: announcementConstants.GETBYID_SUCCESS, announcement } }
    function failure(error) { return { type: announcementConstants.GETBYID_FAILURE, error } }
}


function updateStatusValue(id, statusvalue){
    // custom toast mixin for sweetAlert
    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        background: '#D4EDDA',
        position: 'center',
        showConfirmButton: false,
        timer: 5000
    });
    return dispatch => {
        dispatch(request(statusvalue))

        announcementServices.updateStatusValue(id, statusvalue)
            .then(
                announcement => {
                    dispatch(success(statusvalue))
                    dispatch(alertActions.success('Announcement Type successfully updated'));
                    setTimeout(() => {
                        Toast.fire({
                            type: 'success',
                            title: '<span style="color:#0A551E"> Announcement has been updated.</span>',
                        });
                        dispatch(announcementActions.getAll())
                    }, 100)
                },
                error => dispatch(failure(error.toString()))
            )
    }

    function request(statusvalue) { return { type: announcementConstants.UPDATE_ANNOUNCEMENT_REQUEST, statusvalue } }
    function success(statusvalue) { return { type: announcementConstants.UPDATE_ANNOUNCEMENT_SUCCESS, statusvalue } }
    function failure(error) { return { type: announcementConstants.UPDATE_ANNOUNCEMENT_FAILURE, error } }
}


function update(id, posts){
    // custom toast mixin for sweetAlert
    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const ToastFailed = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    return dispatch => {
        dispatch(request(posts))

        announcementServices.update(id, posts)
            .then(
                announcement => {
                    dispatch(success());
                    setTimeout(() => {
                        ToastSuccess.fire({
                            type: 'success',
                            title: '<span style="color:#FFFFFF">Announcement has been updated! </span>'
                        })
                    }, 100)
                },
                error => {
                    dispatch(failure(error.toString()));
                    ToastFailed.fire({
                        type: 'error',
                        title: `<span style="color:#FFFFFF"> Announcement Update Failed</span> `
                    });
                }
            )
    }

    function request(posts) { return { type: announcementConstants.UPDATE_ANNOUNCEMENT_REQUEST, posts } }
    function success(posts) { return { type: announcementConstants.UPDATE_ANNOUNCEMENT_SUCCESS, posts } }
    function failure(error) { return { type: announcementConstants.UPDATE_ANNOUNCEMENT_FAILURE, error } }
}