import { userAttributeConstants } from '../constants/userAttribute';

export function userAttributes(state = {}, action) {
  switch (action.type) {
       //get user attributes
    case userAttributeConstants.GET_USERATTRIBUTE_REQUEST:
      return {
        loading: true
      };
    case userAttributeConstants.GET_USERATTRIBUTE_SUCCESS:
      return {
        userAttributes: action.userAttributes
      };
    case userAttributeConstants.GET_USERATTRIBUTE_FAILURE:
      return {
        error: action.error
      };
        //delete user
    // case userAttributeConstants.DELETE_USERATTRIBUTE_REQUEST:
    // // add 'deleting:true' property to userAttribute being deleted
    //   return {
    //     ...state,
    //     items: state.items.map(userAttribute =>
    //       userAttribute.id === action.id
    //         ? { ...userAttribute, deleting: true }
    //         : userAttribute
    //   )
    // };
    // case userAttributeConstants.DELETE_USERATTRIBUTE_SUCCESS:
    // // remove deleted user Attribute from state
    //   return {
    //     items: state.items.filter(userAttribute => userAttribute.id !== action.id)
    //   };
    // case userAttributeConstants.DELETE_USERATTRIBUTE_FAILURE:
    // // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
    //   return {
    //   ...state,
    //     items: state.items.map(userAttribute => {
    //       if (userAttribute.id === action.id) {
    //         // make copy of user without 'deleting:true' property
    //         const { deleting, ...userAttrbuteCopy } = userAttribute;
    //         // return copy of user with 'deleteError:[error]' property
    //         return { ...userAttrbuteCopy, deleteError: action.error };
    //     }

    //     return userAttribute;
    //   })
    // };
      
      //post user attribute
    case userAttributeConstants.ADD_USERATTRIBUTE_REQUEST:
      return { saving: true };
    case userAttributeConstants.ADD_USERATTRIBUTE_SUCCESS:
      return {};
    case userAttributeConstants.ADD_USERATTRIBUTE_FAILURE:
      return {};
    default:
      return state
  }
}