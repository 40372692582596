import { get, post, put, del } from "../helpers/api";
import { userService } from "../services/user";

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

const basePath = "/api/v1" ;

const getAll = (page = 1, items = 10) => (
    get(`${basePath}/evaluation?page=${page}&items-per-page=${items}`).then(handleResponse)
);

const create = (evaluation) => (
    post(`${basePath}/evaluation`, evaluation).then(handleResponse)
);

const update = (evaluation, id) => (
    put(`${basePath}/evaluation/${id}`, evaluation).then(handleResponse)
);

const remove = (id) => (
    del(`${basePath}/evaluation/${id}`).then(handleResponse)
);

const getById = (id) => (
    get(`${basePath}/evaluation/${id}`).then(handleResponse)
);
const getLastEvaluationById = (id) => (
    get(`${basePath}/evaluation/last/${id}`).then(handleResponse)
);

const updateStatus = (status, id) => (
    put(`${basePath}/evaluations/${id}/${status}`).then(handleResponse)
);

const getAllByUser = (page = 1, items = 10, subjectId = null) => (
    get(`${basePath}/user-evaluation?&page=${page}&items-per-page=${items}&filter=subject:${subjectId}`).then(handleResponse)
);

const getAllByPeers = (page = 1, items = 10, userId = null) => (
    get(`${basePath}/user-evaluation?&page=${page}&items-per-page=${items}&filter=user_id:${userId}`).then(handleResponse)
);

export default {
    getAll,
    create,
    update,
    remove,
    getById,
    getLastEvaluationById,
    updateStatus,
    getAllByUser,
    getAllByPeers,
}
