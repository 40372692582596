import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { smsActions } from '../../../_core/actions/sms';
import { smsService } from "../../../_core/services/sms";
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import {
    SaveAndCancelButton,
  } from "../../Components/PageWrapper/ContentWrapper";

import {
    CustomInputText,
  } from "../../Components/FloatingTextBox/TextArea";


const SMSModal = (props) => {

    const dispatch = useDispatch();
    const userCred = JSON.parse(localStorage.getItem('user'))

    const [InputError, setInputError] = useState(false);
    const [smsHandleError, setSMSHandleError] = React.useState(null);
    const [contactNumber, setNumber] = useState('');
    const [otp, setOTP] = useState({
        code: "",
    })


    React.useEffect(()=>{
        let number = '+63*******'+userCred.contactNumber?.slice(-3)
        if(userCred.contactNumber?.slice(0,2) !== '63'){
            setNumber(number)
        }else{
            setNumber(number)
        }
        
    },[props,otp,userCred.contactNumber]);

    const handleNumber = (e) => {
        e.preventDefault(); // crashes
        const value = e.target.value.replace(/[^\d.]/ig, "");
        const total = parseInt(value);
        if(value.length === 0) {
            setOTP({ ...otp, [e.target.name]: '', });
        } else {
            setOTP({ ...otp, [e.target.name]: total, });
        }
    };

    const cancelSMS = () => {
        dispatch(smsActions.deleteAll(userCred.user_id)) //delete all sms otp messages of user login.
        props.handleCloseModal(false)
        setInputError(false);
    }

    const submitOTP = () => {

        const ToastSuccess = Swal.mixin({
            background: "#2a3042",
            toast: true,
            width: 300,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });

        const ToastFailed = Swal.mixin({
            background: "#2a3042",
            toast: true,
            width: 300,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });

        setSMSHandleError(null);

        smsService.verifyOTP(otp.code).then((res) => {
            if (res?.item?.length > 0) {
                ToastSuccess.fire({
                    type: 'success',
                    title: '<span style="color:#FFFFFF">OTP Verified Successfully!</span>'
                }).then(() => {
                    props.active(true)
                    props.handleCloseModal(false)
                    setOTP({code: ""})
                    dispatch(smsActions.deleteAll(userCred.user_id)) //delete all sms otp messages of user login.
                })
            }
            
          }).catch(error=> {
            setSMSHandleError(error); 
            console.error(`Error: $(error)`)
        })

        if(smsHandleError === null){
            ToastFailed.fire({
                type: 'error',
                title: `<span style="color:#FFFFFF">Invalid OTP Provided.</span> `
            })
        }
        
    }


  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        toggle={props.toggle}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
        centered
        className="AnnouncementModalBG SMSModal"
      >
        <div className="row center approval-container"
            style={{
                width: "auto",
                height: "auto",
                margin: "auto",
                padding: "20px",
                borderBottom: "1px solid #4A4E5E"
            }}>
            
            <p className="modal_title left">Verification Code.</p>
        </div>

        <div style={{padding:'20px'}}>
            <div className="subText">
                <span>Enter the 6-digit OTP we've just sent to {contactNumber}</span>
            </div>
            
            <CustomInputText
                label={"OTP Number"}
                name={"code"}
                value={String(otp.code)}
                inputOnChange={(e) => handleNumber(e)}
                isError={!otp.code && InputError}
                maxLength={6}
            />
        <SaveAndCancelButton
          cancelFn={() => cancelSMS()}
          saveFn={() => submitOTP()}
        />
        </div>
      </Modal>
    </>
  );
};

export default SMSModal;