import React, { useState } from "react";
import { history } from "../../../_core/helpers/history"
import { connect, useDispatch } from "react-redux";
import ReactTooltip from 'react-tooltip';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useParams } from "react-router-dom";
import { CreateBtn, QuestionPreview, SwitchAndLabelCustom, DropdownWrapper2Set, LabelCustom, InputTextCustom, SaveAndCancelButton } from "../../Components/PageWrapper/ContentWrapper";
import { StaticDropdownQuestion } from "../../Components/DynamicDropdown/DynamicDropdown";
import { WarningPopup } from '../../Components/AdminModals/WarningPopup';
import { QuestionSectionDropdown } from './QuestionSectionDropdown';

import styled, { css } from "styled-components";
import unfoldIcon from '../../../_templates/unfold.png';
import reorder from '../../../_templates/re-order.png';
import edit_icon from "../../../_templates/dark/pencil_edit.png";
import delete_icon from "../../../_templates/dark/delete.png";

import { sectionActions } from "../../../_core/actions/section";

const mapStateToProps = ({ section, questionnaires }) => ({
  section,
  questionnaires,
});

export const QuestionRatingWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30%;
  gap: 5%;
  margin-bottom: 20px;
`;
export const QuestionRatingDropdown = styled.div`
  width: 100%;

  > label {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
    margin: 0 15px;
  }

  > select {
    display: inline;
    height: 40px;
    width: 100%;
    background-color: #2e3648 !important;
    padding: 0 10px;
    border: 2px solid #2e3648;
    border-radius: 2px;
    color: #9ba3ca;
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    background-image: url(${unfoldIcon});
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 13px;
    ${props => props.isError === false
    ? css`
      border: none;
        `
    : css`
      border: 2px solid #f14646;
        `}
    &:focus {
      outline: none;
    }
    
  
  }
`;

const QuestionsWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
`;

const QuestionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 100%;
  width: 100%;

  >p {
    margin: auto 10px auto 0;
  }

  >div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 461px;
    
    >div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 266px;

      >label {
        margin-right: 10px; //35px;
        color: #777D93;
        font-size: 12px;
        font-weight: 500;
      }
    
      >select {
        display: inline; 
        height: 26px;
        width: 100%;
        max-width: 174px;
        background-color: #2E3648!important;
        padding: 0 10px;
        border: none;
        border-radius: 2px;
        color: #9BA3CA;
        font-size: 14px;
        font-weight: 500;
        text-overflow: ellipsis;
    
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        background-image: url(${unfoldIcon}); 
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 5px;
    
        &:focus {
          outline: none;
        }
      }
    }
  }
`;

const QuestionsContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  >button {
    margin: 30px auto;
  }
`;

const QuestionsListWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
`;

const QuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  width: 100%;
  height: 100%;

  >div {
    width: 100%;

    &:nth-child(1) { // QUESTION
      width: 100%;
      // max-width: 745px;
      height: auto;
      min-height: 40px;
      background-color: #2E3648;
      border: 1px solid #323A4F;
      border-radius: 3px;
      padding: 0 10px;
      overflow: hidden;

      color: #9BA4C7;
      font-size: 14px;
      font-weight: 400;

      display: flex;
      align-items: center;

      >img {
        margin-right: 10px;
        cursor: pointer;
      }

      // >span {
      // }
    }

    &:nth-child(2) { // QUESTION SECTION
      width: 100%;
      // height: 40px;
      max-width: 251px;
      margin: 0 20px;
    }

    &:nth-child(3) { // QUESTION ACTIONS
      width: 100%;
      max-width: 48px;
      display: flex;
      justify-content: space-between;

      >img {
        cursor: pointer
      }
    }

  }
`;
// { data, id, sections, isAddingQuestion }
export const QuestionList = connect(mapStateToProps)((props) => {
  const [questions, setQuestions] = React.useState([]);
  const [sections, setSections] = React.useState([]);
  const [altVersion, setAltVersion] = React.useState(0);

  const { pageId } = useParams(); //get id in url

  React.useEffect(() => {
    if (props?.data && props.data?.length > 0) {
      setQuestions(props.data);
    }

    if (props?.sections?.length > 0 && sections?.length <= 0) {
      const section = props?.sections;
      let sectionItems = [];

      section.map((item) => {
        sectionItems.push({ label: item.title, value: item._id });
        return null;
      });
      
      setSections(sectionItems);
    }
  }, [props, sections]);

  const handleVersionOnChange = () => {
    let e = document.getElementById("qVersion");
    let selectedValue = e.options[e.selectedIndex].value;
    setAltVersion(Number(selectedValue));
  }

  const handleSectionOnChange = (item) => {
    
    props.sectionOnChange(item);
  }

  const handleReorderList = (result) => {
    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);


    props.questionsReordered(items);
  }

  const handleDeleteQuestion = React.useCallback((question_id, questionnaire_id, index) => {

    const message = "Are you sure you want to delete?";

    WarningPopup("warning", message, true, true).then((res) => {
      if (res?.value) {
        if (question_id && questionnaire_id) {
          const questionsList = questions;
          const filterQuestions = questionsList.filter((item) => item._id !== question_id);
          props.questionsOnDelete(filterQuestions);

        } else { //if not yet saved to api
          let filterQuestions = questions.filter((item, index1) => index1 !== index);
          props.questionsOnDelete(filterQuestions);
        }
      }
    });
  }, [questions, props]);

  const handleEditQuestion = (item, index) => {
    let questionOrderIndex = index;
    if(props.isAdd){
      history.push(`/admin/questionnaires/question/${questionOrderIndex}/edit`);
    } else {
      history.push(`/admin/questionnaires/${pageId}/question/${questionOrderIndex}/edit`);
    }

   props.isUpdatingQuestion(true);
 
    /* Step 1. Pass questions data in list to edit page */ 
    props.selectedEditQuestions(questions);

  }

  const handleAddQuestion = () => {
    if(pageId) {
      history.push(`/admin/questionnaires/${pageId}/question/create`);
    } else {
      history.push(`/admin/questionnaires/question/create`);
    }
  
    props.isAddingQuestion(true);
  }

  const addPermission = JSON.parse(localStorage.getItem('addPermission'));
  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;


  return (
    <QuestionsWrapper>
      <QuestionsHeader>
        <LabelCustom className="sub-label">Questions</LabelCustom>
        <div>
          <div>
            <label htmlFor="qVersion">Versions: </label>
            <select id="qVersion" onChange={handleVersionOnChange}>
              <option value={0}>Self Assessment</option>
              <option value={1}>Peer Review</option>
            </select>
          </div>
          {
            (addPermission.includes("Appraisal - Questionnaires ") === true) || (roleAllTrue === 1) ?
            <CreateBtn style={{ maxWidth: "176px" }} onClick={() => handleAddQuestion() }>Add a Question</CreateBtn> : false
          }
        </div>
      </QuestionsHeader>
      <QuestionsContent>
        <DragDropContext onDragEnd={handleReorderList}>
          <Droppable droppableId="questionsReorder">
            {(provided) => (
              <QuestionsListWrapper className="questionsReorder" {...provided.droppableProps} ref={provided.innerRef}> {/* questions list*/}
                {questions?.map((item, index) =>
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(provided) => (
                      <QuestionWrapper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}> {/* loop questions*/}
                        <div><img src={reorder} alt="re-order" /><span>{altVersion === 1 ? item.alt_version || "" : item.question}</span></div>
                        <div>
                          <QuestionSectionDropdown
                            maxWidth={"40px"}
                            sections={sections}
                            value={item.section_id}
                            index={index}
                            sectionIdOnChange={handleSectionOnChange}
                            questionId={item._id}
                          />
                        </div>
                        <div>
                          {(editPermission.includes("Appraisal - Questionnaires ") === true) || (roleAllTrue === 1) ?
                            <img
                              src={edit_icon}
                              alt="edit"
                              data-tip={"edit question"}
                              place="top"
                              data-class="tooltips"
                              onClick={()=> handleEditQuestion(item, index)}
                            /> : false
                          }
                          {
                            (deletePermission.includes("Appraisal - Questionnaires ") === true) || (roleAllTrue === 1) ?
                            <img
                              src={delete_icon}
                              alt="delete"
                              data-tip={"delete question"}
                              place="top"
                              data-class="tooltips"
                              onClick={() => handleDeleteQuestion(item._id, item.questionnaire_id, index)}
                            /> : false
                          }
                          <ReactTooltip />
                        </div>
                      </QuestionWrapper>
                    )}
                  </Draggable>
                )}
                {provided.placeholder}
              </QuestionsListWrapper>
            )}
          </Droppable>
        </DragDropContext>
        {
          (addPermission.includes("Appraisal - Questionnaires ") === true) || (roleAllTrue === 1) ?
          <CreateBtn style={{ maxWidth: "260px" }} onClick={() =>  handleAddQuestion() }>Add a Question</CreateBtn> : false
        }
      </QuestionsContent>
    </QuestionsWrapper>
  );
})

export const QuestionCreateAndEdit = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();

  const [inputError, setInputError] = useState(false); // eslint-disable-line
  const [isAdd, setIsAdd] = useState(true); // eslint-disable-line

  const [previewQuestion, setPreviewQuestion] = useState(true)
  const [questionType] = useState([
    { value: "single", label: "Single Answer" },
    { value: "rating", label: "Rating" },
  ]);
  const [section, setSection] = useState([]);

  const [questionTypeSelected, setQuestionTypeSelected] = useState({
    value: "single",
    label: "Single Answer",
  });
  const [sectionSelected, setSectionSelected] = useState({
    value: "",
    label: "",
  });

  const [min, setMin] = useState([{ value: 0, label: 0 }]);
  const [max, setMax] = useState([{ value: 0, label: 0 }]);
  const [passingScore, setPassingScore] = useState([{ value: 0, label: 0 }]);
  const [minSelected, setMinSelected] = useState([{ value: 0, label: 0 }]);
  const [maxSelected, setMaxSelected] = useState([{ value: 0, label: 0 }]);
  const [passingSelected, setPassingSelected] = useState([{ value: 0, label: 0 }]);
  const [minDefault, setMinDefault] = useState(true);
  const [maxDefault, setMaxDefault] = useState(true);
  const [passingDefault, setPassingDefault] = useState(true);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [RatingRange, setRatingRange] = useState([{ value: 0, label: 0 }])

  const [question, setQuestion] = useState({
    questionnaire_id: null,
    section_id: null,
    question: "",
    alt_version: "",
    type: "single",
    subject: "",
    min: 0,
    max: 0,
    passing: 0,
    order: 0,
  });

  const { questionnairePageId, questionOrder } = useParams(); //get id in url

    React.useEffect( () => {
    
       dispatch(sectionActions.getAllSection());

      if( questionOrder ) {
         setIsAdd(false);
        //  dispatch(questionnaireActions.getAllQuestions(questionnairePageId));
      }

    }, [dispatch, questionOrder, questionnairePageId]);


  React.useEffect(() => {
    
    filterSection(props.section?.sectionList);

    if(isAdd === false){
      if(props.getSelectedEditQuestionsStep3.length !== 0 && props.getSelectedEditQuestionsStep3) {
        getEditQuestionStep4(props.getSelectedEditQuestionsStep3, props.section?.sectionList);
      } 
    }
    //eslint-disable-next-line
  }, [props, isAdd]);


  const getEditQuestionStep4 = (questionData, sectionData) => {
   /* Step 4. Get All questions in list and filter by selected question
      then prepopulate all fields. 
   */

    let getQuestionIndex = questionData.findIndex((el, i) => {
      if(i == questionOrder) {  // eslint-disable-line
        return true;
      }
      return null;
    }); 
    let getQuestion = questionData[getQuestionIndex];

    if(getQuestion){
      if(getQuestion._id){
        // If question has a ID
        setQuestion({
          _id: getQuestion._id,
          question: getQuestion.question,
          alt_version: getQuestion.alt_version,
          section: getQuestion.section_id,
          type: getQuestion.type,
          order: getQuestion.order,
          subject: getQuestion.subject,
          questionnaire_id: getQuestion.questionnaire_id,
          section_id: getQuestion.section_id,
          min: getQuestion.min,
          max: getQuestion.max,
          passing: getQuestion.passing,
        });
      } else {
        setQuestion({
          question: getQuestion.question,
          alt_version: getQuestion.alt_version,
          section: getQuestion.section_id,
          type: getQuestion.type,
          order: getQuestion.order,
          subject: getQuestion.subject,
          questionnaire_id: getQuestion.questionnaire_id,
          section_id: getQuestion.section_id,
          min: getQuestion.min,
          max: getQuestion.max,
          passing: getQuestion.passing,
        });
      }
    
      
      // PrePopulate Section
      let selectedType = { label: getQuestion.type === "single" ? "Single Answer" : "Rating", value: getQuestion.type }
      setQuestionTypeSelected(selectedType);
  
      sectionData.map((section) => {
        if(section._id === getQuestion.section_id){
         let selectedSection = { label: section.title, value: section._id }
          setSectionSelected(selectedSection);
        }
        return null;
      })
  
      // PrePopulate Rating
      if(getQuestion.type === 'rating') {
        setMinDefault(false);
        setMinSelected([{label: getQuestion.min, value: getQuestion.min}]);
        
        setMaxDefault(false);
        setMaxSelected([{label: getQuestion.max, value:  getQuestion.max}]);
        
        setPassingDefault(false);
        setPassingSelected({label:  getQuestion.passing, value:  getQuestion.passing});
  
      }
    }
  
  }

  const cancelQuestion = () => {
    history.goBack();
  }

  const filterSection = (data) => {
    // prepopulate Section type Dropdown
    let sectionItems = [];
    let sectionData = data ?? [];
    if (sectionData.length !== 0) {
      sectionData.map(sectionItem => {
        sectionItems.push({ label: sectionItem.title, value: sectionItem._id });
        return null;
      });
    }
 
      if (sectionItems.length === 0) {
        setSection([{ label: "Add Section", value: "" }]);
      } else {
        sectionItems = [...sectionItems, { label: "Add Section", value: "" }]
        setSection(sectionItems);
      }
  }
  const handleChangeQuestionType = (e) => {
    setQuestionTypeSelected(e);
  }


  const handleChangeSection = (e) => {
    let field = "section_id";
    if (e.label !== "Add Section") {
      setSectionSelected(e);
      setQuestion({
        ...question,
        [field]: e.value,
      })
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setQuestion({
      ...question,
      [name]: value,
    });
  };

  const filterUpdatedQuestion = () => {
    let questionList = props.getSelectedEditQuestionsStep3;
    let getQuestionIndex = questionList.findIndex((el, i) => {
      if(i == questionOrder) { // eslint-disable-line
        return true;
      }
      return null;
    }); 
    questionList.splice(getQuestionIndex, 1, question) 

    props.updatedQuestion(questionList);
  }

  const submitQuestion = async () => {
    if (!question.question || (questionTypeSelected.value === "rating" && (minDefault || maxDefault || passingDefault))) {
      setInputError(true);
      setIsSubmitted(true);
    } else {

      if(isAdd) {
        // Add Question
          props.createdQuestion(question);
         // reset
        setQuestion({
          questionnaire_id: null,
          section_id: null,
          question: "",
          alt_version: "",
          type: "single",
          subject: "",
          min: 0,
          max: 0,
          passing: 0,
          order: 0,
        });
        setInputError(false);
        setIsSubmitted(false); 
        history.goBack();
      } else {
        // Update quesiton
        await filterUpdatedQuestion();

        // reset
        setQuestion({
          questionnaire_id: null,
          section_id: null,
          question: "",
          alt_version: "",
          type: "single",
          subject: "",
          min: 0,
          max: 0,
          passing: 0,
          order: 0,
        });
          setInputError(false);
          setIsSubmitted(false); 
          history.goBack();
      }
    }
  }

  const onClickSwitch = () => {
    setPreviewQuestion(!previewQuestion);
  }

  const prePopulateRating = (e) => {
    let rating = 100;
    let index = 0;
    let ratingData = [];
    for (index = 0; index <= rating; index++) {
      ratingData.push({ label: index, value: index })
    }

    return ratingData;
  }


    
    const handleRatingOnCnhange = (trigger) => {
      let e = document.getElementById(trigger);
      let selectedValue = e.options[e.selectedIndex].value;
      let convertStringToInteger = parseInt(selectedValue);
      if (trigger === "min") {
        setMinDefault(false);
        setMinSelected([
          { label: convertStringToInteger, value: convertStringToInteger },
        ]);

        setQuestion({
          ...question,
          [trigger]: convertStringToInteger,
        });
      }
      if (trigger === "max") {
        setMaxDefault(false);
        setMaxSelected([
          { label: convertStringToInteger, value: convertStringToInteger },
        ]);
        setQuestion({
          ...question,
          [trigger]: convertStringToInteger,
        });
      }
      if (trigger === "passing") {
        setPassingDefault(false);
        setPassingSelected([
          { label: convertStringToInteger, value: convertStringToInteger },
        ]);
        setQuestion({
          ...question,
          [trigger]: convertStringToInteger,
        });
      }
    };

    const filterRating = () => {
     let minRating = minSelected;
     let index = minRating[0].value;
     let ratingRange = [];
     for (index; index <= maxSelected[0].value; index++) {
      ratingRange.push({label: index, value: index});
     }
     setRatingRange(ratingRange); // PrePopulate radio button in Question Preview
     if(maxDefault === false && minDefault === false){
       // Prepopulate Passing Rating
       setPassingScore(ratingRange);
     }
    } 

    const filterMaxValue = (data, selected) => {
      let maxValue = 100;
    
      let ratingData = [];
      if(selected === "max"){
        let startingIndex = data[0].value;
        for (startingIndex; startingIndex <= maxValue; startingIndex++) {
          ratingData.push({label: startingIndex, value: startingIndex })
        }
      
      } else {
        // Max
        let startingIndex = 0;
        let maxIndex = data[0].value;
        for (startingIndex; startingIndex <= maxIndex; startingIndex++) {
          ratingData.push({label: startingIndex, value: startingIndex })
        }
      }
    
      return ratingData;
    }

    React.useEffect(() => {
      filterRating();
      if(minSelected.value  !==0){
        let range = filterMaxValue(minSelected, 'max');
         setMax(range);
      }

     //eslint-disable-next-line
    }, [minSelected])
    React.useEffect(() => {
      filterRating();
      if(maxSelected.value  !==0){
        let range = filterMaxValue(maxSelected, 'min');
         setMin(range);
      }
     //eslint-disable-next-line
    }, [maxSelected])

    React.useEffect(() => {
      if(questionTypeSelected.value === "rating" && (props.getSelectedEditQuestionsStep3.length === 0)) {
        // Prepopulate if not editing question
        setMax(prePopulateRating());
        setMin(prePopulateRating());
        setPassingScore(prePopulateRating());
      }
      if(isAdd && questionTypeSelected.value === "single"){
        // Prepopulate if editing question and selected type single answer
        setMax(prePopulateRating());
        setMin(prePopulateRating());
        setPassingScore(prePopulateRating());
      }
      // eslint-disable-next-line
    }, [questionTypeSelected])

    React.useEffect(() => {
      // HandleChange question Type
      // Prepopulate default value "Single"
      let questionType = "type";
      setQuestion({
        ...question,
        [questionType]: questionTypeSelected.value,
      });
       // eslint-disable-next-line
    }, [questionTypeSelected]);
  
  return (
    <div>
      <LabelCustom className="main-label">
        {isAdd ? "Create" : "Edit"} a Question
      </LabelCustom>
      <InputTextCustom
        placeholder={"Title"}
        name={"question"}
        value={question.question}
        inputOnChange={handleChange}
        isError={!question.question && inputError}
      />
      <LabelCustom className="sub-label">Peer Review Version</LabelCustom>
      <InputTextCustom
        placeholder={"Title"}
        name={"alt_version"}
        value={question.alt_version}
        inputOnChange={handleChange}
        isError={false}
      />
      <LabelCustom className="sub-label">Subject</LabelCustom>
      <InputTextCustom
        placeholder={""}
        name={"subject"}
        value={question.subject}
        inputOnChange={handleChange}
        isError={false}
      />
      <DropdownWrapper2Set>
        <StaticDropdownQuestion
          handleChangeQuestionType={(e) => handleChangeQuestionType(e)}
          options1={questionType}
          value1={questionTypeSelected}
          handleChangeSection={(e) => handleChangeSection(e)}
          options2={section}
          value2={sectionSelected}
        />
      </DropdownWrapper2Set>

      {questionTypeSelected.label === "Rating" && (
        <>
          <LabelCustom className="main-label">Rating</LabelCustom>
          <QuestionRatingWrapper>
            <QuestionRatingDropdown
              isError={minDefault === true && isSubmitted}
            >
              <select
                id="min"
                name="min"
                value={minDefault === false ? minSelected[0].value : "default"}
                onChange={() => handleRatingOnCnhange("min")}
              >
                <option value="default" disabled hidden>
                  Minimum Rating
                </option>
                {min.map((minData, count) => (
                  <option value={minData.value} key={count}>
                    {minData.label}
                  </option>
                ))}
              </select>

              {minDefault === true && isSubmitted && (
                <label htmlFor="min">please fill-up this field</label>
              )}
            </QuestionRatingDropdown>
            <QuestionRatingDropdown
              isError={maxDefault === true && isSubmitted}
            >
              <select
                id="max"
                name="max"
                value={maxDefault === false ? maxSelected[0].value : "default"}
                onChange={() => handleRatingOnCnhange("max")}
              >
                <option value="default" disabled hidden>
                  Maximum Rating
                </option>
                {max.map((maxData, count) => (
                  <option value={maxData.value} key={count}>
                    {maxData.label}
                  </option>
                ))}
              </select>

              {maxDefault === true && isSubmitted && (
                <label htmlFor="max">please fill-up this field</label>
              )}
            </QuestionRatingDropdown>
            <QuestionRatingDropdown
              isError={passingDefault === true && isSubmitted}
            >
              <select
                id="passing"
                name="passing"
                value={passingDefault === true ? "default" : passingSelected.value}
                onChange={() => handleRatingOnCnhange("passing")}
              >
                <option value="default" disabled hidden>
                  Passing Rating
                </option>
                {passingScore.map((passingData, count) => (
                  <option value={passingData.value} key={count}>
                    {passingData.label}
                  </option>
                ))}
              </select>

              {passingDefault === true && isSubmitted && (
                <label htmlFor="min">please fill-up this field</label>
              )}
            </QuestionRatingDropdown>
          </QuestionRatingWrapper>
        </>
      )}

      <SwitchAndLabelCustom
        onClick={onClickSwitch}
        position="right"
        switchLabel="Alternate Version"
      />
      <QuestionPreview
        questionTitle={"Question Preview"}
        questionDescription={
          previewQuestion === true ? question.question : question.alt_version
        }
        isRating={questionTypeSelected.value === "rating"}
        getRating={RatingRange}
        isDisable={true}
      />

      <SaveAndCancelButton
        cancelFn={() => cancelQuestion()}
        saveFn={() => submitQuestion()}
        submitLabel={ isAdd ? "ADD" : "EDIT"}
      />
    </div>
  );
})
