export const userAttributeConstants = {

    ATTR_USERS_REQUEST: 'GET_ATTRUSERS_REQUEST',
    ATTR_USERS_SUCCESS: 'GET_ATTRUSERS_SUCCESS',
    ATTR_USERS_FAILURE: 'GET_ATTRUSERS_FAILURE',
    
	ADD_USERATTRIBUTE_REQUEST: 'USERATTRIBUTE_ADDUSERATTRIBUTE_REQUEST',
    ADD_USERATTRIBUTE_SUCCESS: 'USERATTRIBUTE_ADDUSERATTRIBUTE_SUCCESS',
    ADD_USERATTRIBUTE_FAILURE: 'USERATTRIBUTE_ADDUSERATTRIBUTE_FAILURE',
    
    GET_USERATTRIBUTE_REQUEST: 'USERATTRIBUTE_GETUSERATTRIBUTE_REQUEST',
    GET_USERATTRIBUTE_SUCCESS: 'USERATTRIBUTE_GETUSERATTRIBUTE_SUCCESS',
    GET_USERATTRIBUTE_FAILURE: 'USERATTRIBUTE_GETUSERATTRIBUTE_FAILURE',

    DELETE_USERATTRIBUTE_REQUEST: 'USERATTRIBUTE_DELETEUSERATTRIBUTE_REQUEST',
    DELETE_USERATTRIBUTE_SUCCESS: 'USERATTRIBUTE_DELETEUSERATTRIBUTE_SUCCESS',
    DELETE_USERATTRIBUTE_FAILURE: 'USERATTRIBUTE_DELETEUSERATTRIBUTE_FAILURE'
  
};

