import React from 'react';
import { Link } from 'react-router-dom';


 
import ReactTooltip from 'react-tooltip'

import BodyBackgroundColor from 'react-body-backgroundcolor'

import sampleImage from '../_templates/bg-challenge.jpg';
import sampleBadge from '../_templates/man.png';

import SideNav from '../Admin/SideNav';


import MaterialIcon from 'material-icons-react';
import './QuestManagement.sass';
class QuestManagement extends React.Component {
    
    //body
    render() {
       
       

        return (
            <BodyBackgroundColor backgroundColor='#f4f4f4'>
                <div className="quest-management">
                    <SideNav />
                    <div className="container" id="content">
                        <h6 className="text-left">Quest Management</h6>
                        <div className="bg-quest-management">
                            <div className="row">
                                <div className="col s3 m4 l2">
                                    <Link to="/admin/user/create" className="btn-floating btn-large waves-effect waves-light left" id="add-new"><MaterialIcon icon="add" color="white"/></Link>
                                      <p className="quest-create"> Create Quest </p>
                                </div>
                                <div className="col s12 m4 l3">
                                    <div class="input-field">
                                      <label className="label-icon" for="search"><MaterialIcon icon="search" size="small" color="black" id="icon-search"/></label>
                                      <input id="search-user" type="search" placeholder="Search" required/>
                                    </div>
                                </div> 
                                <div className="col s12 m4 l3">
                                  <div className="check">
                                    <MaterialIcon icon="check" size="small" color="white"/>
                                    
                                  </div>
                                  <p className="align-left">Active Quest</p>

                                </div>
                                </div>
                            <hr/>
                            
                            
                                <table className="responsive-table highlight">
                                    <thead>
                                        <tr>
                                            <th className="space-margin">ID</th>
                                            <th className="space-margin">Image</th>
                                            <th className="space-margin">Badge</th>
                                            <th className="space-margin">Badge Required</th>
                                            <th className="space-margin">Name</th> 
                                            <th className="space-margin">Description</th>
                                            <th className="space-margin">Level</th>
                                            <th className="space-margin">Challenges</th>
                                            <th className="space-margin">Actions</th> 
                                        </tr>
                                    </thead>
                                       <tbody> 
                                            <tr>
                                            <td width="5%" className="id">1</td>
                                            <td width="10%"><img src={sampleImage} className="quest-pic" alt="quest-pic"/></td>
                                            <td width="7%"><img src={sampleBadge} className="quest-badge" alt="quest-pic"/></td>
                                            <td width="10%"><img src={sampleBadge} className="quest-badge" alt="quest-pic"/></td>
                                            <td width="15%" className="Name">Sample Quest Name</td>
                                            
                                            <td width="30%" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo  </td>
                                            <td width="5%" className="level">1</td>
                                            <td width="5%" className="challenge">0</td>

                                            <td>
                                                <Link to="#"  className="btn-floating btn-small waves-effect waves-light btn-edit" data-tip="Edit Quest" place="top" data-class="tooltips"><MaterialIcon icon="edit" size="tiny" color="white"/></Link>
                                                <Link to="#"  className="btn-floating btn-small waves-effect waves-light green" data-tip="" place="top" data-class="tooltips"><MaterialIcon icon="check" size="small" color="white"/></Link>
                                               
                                            <ReactTooltip />
                                            </td>
                                            </tr>
                                            
                                            <tr>
                                            <td width="5%" className="id">2</td>
                                            <td width="10%"><img src={sampleImage} className="quest-pic" alt="quest-pic"/></td>
                                            <td width="7%"><img src={sampleBadge} className="quest-badge" alt="quest-pic"/></td>
                                            <td width="10%"><img src={sampleBadge} className="quest-badge" alt="quest-pic"/></td>
                                            <td width="15%" className="Name">Sample Quest Name</td>
                                            
                                            <td width="30%">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo  </td>
                                            <td width="5%" className="level">1</td>
                                            <td width="5%" className="challenge">0</td>

                                            <td width="10%">
                                                <Link to="#"  className="btn-floating btn-small waves-effect waves-light btn-edit" data-tip="Edit Quest" place="top" data-class="tooltips"><MaterialIcon icon="edit" size="tiny" color="white"/></Link>
                                                <Link to="#"  className="btn-floating btn-small waves-effect waves-light green" data-tip="" place="top" data-class="tooltips"><MaterialIcon icon="check" size="small" color="white"/></Link>
                                               
                                            <ReactTooltip />
                                            </td>
                                            </tr>

                                            <tr>
                                            <td width="5%" className="id">3</td>
                                            <td width="10%"><img src={sampleImage} className="quest-pic" alt="quest-pic"/></td>
                                            <td width="7%"><img src={sampleBadge} className="quest-badge" alt="quest-pic"/></td>
                                            <td width="10%"><img src={sampleBadge} className="quest-badge" alt="quest-pic"/></td>
                                            <td width="15%" className="Name">Sample Quest Name</td>
                                            
                                            <td width="30%">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo  </td>
                                            <td width="5%" className="level">1</td>
                                            <td width="5%" className="challenge">0</td>

                                            <td>
                                                <Link to="#"  className="btn-floating btn-small waves-effect waves-light btn-edit" data-tip="Edit Quest" place="top" data-class="tooltips"><MaterialIcon icon="edit" size="tiny" color="white"/></Link>
                                                <Link to="#"  className="btn-floating btn-small waves-effect waves-light green" data-tip="" place="top" data-class="tooltips"><MaterialIcon icon="check" size="small" color="white"/></Link>
                                               
                                            <ReactTooltip />
                                            </td>
                                            </tr>

                                           <tr>
                                           <td width="5%" className="id">4</td>
                                            <td width="10%"><img src={sampleImage} className="quest-pic" alt="quest-pic"/></td>
                                            <td width="7%"><img src={sampleBadge} className="quest-badge" alt="quest-pic"/></td>
                                            <td width="10%"><img src={sampleBadge} className="quest-badge" alt="quest-pic"/></td>
                                            <td width="15%" className="Name">Sample Quest Name</td>
                                            
                                            <td width="30%">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo  </td>
                                            <td width="5%" className="level">1</td>
                                            <td width="5%" className="challenge">0</td>

                                            <td>
                                                <Link to="#"  className="btn-floating btn-small waves-effect waves-light btn-edit" data-tip="Edit Quest" place="top" data-class="tooltips"><MaterialIcon icon="edit" size="tiny" color="white"/></Link>
                                                <Link to="#"  className="btn-floating btn-small waves-effect waves-light green" data-tip="" place="top" data-class="tooltips"><MaterialIcon icon="check" size="small" color="white"/></Link>
                                               
                                            <ReactTooltip />
                                            </td>
                                            </tr> 

                                            <tr>
                                            <td width="5%" className="id">5</td>
                                            <td width="10%"><img src={sampleImage} className="quest-pic" alt="quest-pic"/></td>
                                            <td width="7%"><img src={sampleBadge} className="quest-badge" alt="quest-pic"/></td>
                                            <td width="10%"><img src={sampleBadge} className="quest-badge" alt="quest-pic"/></td>
                                            <td width="15%" className="Name">Sample Quest Name</td>
                                            
                                            <td width="30%">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo  </td>
                                            <td width="5%" className="level">1</td>
                                            <td width="5%" className="challenge">0</td>

                                            <td>
                                                <Link to="#"  className="btn-floating btn-small waves-effect waves-light btn-edit" data-tip="Edit Quest" place="top" data-class="tooltips"><MaterialIcon icon="edit" size="tiny" color="white"/></Link>
                                                <Link to="#"  className="btn-floating btn-small waves-effect waves-light green" data-tip="" place="top" data-class="tooltips"><MaterialIcon icon="check" size="small" color="white"/></Link>
                                               
                                            <ReactTooltip />
                                            </td>
                                            </tr>  
                                        </tbody>
                                </table>
                            
                            <div className="right-align">
                                <ul className="pagination">
                                    <li className="disabled"><Link to="#"><i className="material-icons">chevron_left</i></Link></li>
                                    <li className="active"><Link to="#">1</Link></li>
                                    <li className="waves-effect"><Link to="#">2</Link></li>
                                    <li className="waves-effect"><Link to="#">3</Link></li>
                                    <li className="waves-effect"><Link to="#">4</Link></li>
                                    <li className="waves-effect"><Link to="#">5</Link></li>
                                    <li className="waves-effect"><Link to="#"><i className="material-icons">chevron_right</i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>  
            </BodyBackgroundColor>
        );
    }
}


export { QuestManagement };
