import { reactionConstants } from '../constants/reaction';
import { reactionService } from '../services/reaction';
import { alertActions } from './alert';

export const reactionActions = {
    postReaction, //POST ONE REACTION
    getAll, //GET ALL REACTIONS
    getOne, //GET ONE REACTION
    getByOrigin, //GET REACTIONS BY ONE POST/COMMENT(ORIGIN)
};

function postReaction(react){
    return dispatch => {
        dispatch(request(react));
        reactionService.postReaction(react)
            .then(
                reaction => {
                    dispatch(success());
                    dispatch(alertActions.success('reaction successfully sent'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };   

    function request(react) { return { type: reactionConstants.POST_REQUEST, react } }
    function success() { return { type: reactionConstants.POST_SUCCESS } }
    function failure(error) { return { type: reactionConstants.POST_FAILURE, error} }
}

function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());
        reactionService.getAll(page, itemsPerPage)
            .then(
                reactions => dispatch(success(reactions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: reactionConstants.GETALL_REQUEST } }
    function success(reactions) { return { type: reactionConstants.GETALL_SUCCESS, reactions } }
    function failure(error) { return { type: reactionConstants.GETALL_FAILURE, error } }
}

function getOne(id) {
    return dispatch => {
        dispatch(request(id));
        reactionService.getOne(id)
            .then(
                reaction => dispatch(success(reaction)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: reactionConstants.GET_REQUEST } }
    function success(reaction) { return { type: reactionConstants.GET_SUCCESS, reaction } }
    function failure(error) { return { type: reactionConstants.GET_FAILURE, error } }
}

function getByOrigin(id, page, itemsPerPage) {
    return dispatch => {
        dispatch(request(id));
        reactionService.getByOrigin(id, page, itemsPerPage)
            .then(
                reactions => dispatch(success(reactions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: reactionConstants.GET_ORIGIN_REQUEST } }
    function success(reactions) { return { type: reactionConstants.GET_ORIGIN_SUCCESS, reactions } }
    function failure(error) { return { type: reactionConstants.GET_ORIGIN_FAILURE, error } }
}