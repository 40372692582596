import React from 'react';
import { connect, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import Linkify from 'react-linkify';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import cc from 'classcat';
//helpers
import { history } from '../_core/helpers/history';
import { debounce } from '../_core/helpers/debounce';
import { convertToObjectId } from './convertToObjectId';
//actions and services
// import { postServices } from '../_core/services/post';
import { commentService } from '../_core/services/comment';
import { commentActions } from '../_core/actions/comment';
import { reactionService } from '../_core/services/reaction';
// import { reactionActions } from '../_core/actions/reaction';
//images
import avatar from '../_templates/man.png';
import imgNotFound from '../_templates/img-not-found.png';
import hearted from '../_templates/heart_red.png';
import heart from '../_templates/heart_grey.png';
import haveComment from '../_templates/comment_icon.png';
import nocomment from '../_templates/comment_grayed.png';
import closeIcon from '../_templates/x_round.png';
import send_comment from '../_templates/send_message_greyed.png';
import send_comment_action from '../_templates/send_message.png';
// styles
import './TimekeepingModal.sass';

Modal.setAppElement(document.getElementById('root'));
const praggerLink = process.env.REACT_APP_PRAGGER_API;

const linkDecorator = (href, text, key) => (
	<a href={href} key={key} target="_blank" rel="noopener noreferrer">{text}</a>
);

const SkeletonPost = () => {
	const [transparent, setTransparent] = React.useState(true);

	React.useEffect(() => {
		setTimeout(() => {
			setTransparent(false);
		}, 100);
	}, [setTransparent]);

	return (
		new Array(1).fill(null).map((_, index) => (
			<div
				className={cc({
					'skeleton-post': true,
					transparent
				})}
				key={index}
			>
				<SkeletonTheme color="#e0e0e0">
					<div className="poster-profile">
						<Skeleton circle width={50} height={50} />
						<div>
							<p>
								<Skeleton height={15} width={120} />
							</p>
							<p className="date">
								<Skeleton height={10} width={80} />
							</p>
                            <div className="content">
                                <p>
                                    <Skeleton height={15} width="90%" />
                                </p>
                                <p>
                                    <Skeleton height={15} width="60%" />
                                </p>
                            </div>
						</div>
                        
					</div>
                   
				</SkeletonTheme>
			</div>
		))
	);
}

const CommentForm = (props) => {
    const postId = props.postId;
    const type = "comment";
    const dispatch = useDispatch();
    
    var [commentContent, setCommentContent] = React.useState({
        originId: props.postId,
        type: type,
        content: ""
    });

    const clearInput = () => {
        setCommentContent({
            ...commentContent,
            originId: postId,
            type: type,
            content: ""
        });
    }

    const commentValue = (e) => {
        const code = (e.keyCode ? e.keyCode : e.which);
        if (e.target.value) {
            const text = e.target.value
            setCommentContent({
                ...commentContent,
                originId: postId,
                type: type,
                content: text
            });
            
            if(code === 13 && !e.shiftKey){
                sendComment();
            }
        } else {
            clearInput();
        }
    };

    const sendComment = () => {
        const submitComment = ({
            ...commentContent,
            content: commentContent.content.replace(/(\r\n|\n|\r)$/gm, "")
        });
        try{
            commentService.postComment(submitComment).then((res)=>{
                if(res.success){
                    dispatch(commentActions.getByOrigin(postId, 1, 100000));
                    clearInput();
                    props.userCommented(true);
                }
            }).catch(error=>{});
        }catch(err){
            console.log(err)
        }
    }

    React.useEffect(()=>{
        if(commentContent.type !== type){
            setCommentContent({
                ...commentContent,
                originId: postId,
                type: type,
                content: ""
            });
        }
    },[commentContent.type, type, setCommentContent, commentContent, postId]);
    
    return(
        <div className="add-comment">
            <textarea 
                className="insert-comment" 
                placeholder="Add a comment"
                onKeyUp={ (e)=> commentValue(e) }
                onChange={ (e)=> commentValue(e) }
                value = {commentContent.content}
                id={'commentForm' + postId}
            >
            </textarea>
            <div className="send-comment-btn">
                {commentContent.content.length > 0 ?
                <img className="send" src={send_comment_action} onClick={ ()=> sendComment() } alt=""/>
                :
                <img src={send_comment} alt=""/>
                }
            </div>
        </div>
    )
}

const CommentDisplay = (props) => {
    var comment = props.comment;

    const user = comment.details[0] || {};
    const commentText = comment.content;
    const maxLength = 300;

    const [showMore, setShowMore] = React.useState(false);
    const [displayShowBtn, setDisplayShowBtn] = React.useState(false);

    const changeCommentHeight = () => {
        if(showMore){
            document.getElementById('cC' + comment._id).style.maxHeight = "120px";
            setShowMore(false);
        }else{
            document.getElementById('cC' + comment._id).style.maxHeight = "100%";
            setShowMore(true);
        }
    };

    React.useEffect(()=>{
        if (commentText.length > maxLength){
            setDisplayShowBtn(true);
        }

        return ()=> setDisplayShowBtn(false);
    },[commentText.length, maxLength]);
    
    return(
        <div className="comment-content">
            <img className="comment-img" src={user.avatar || avatar} alt="" onError={(e) => { e.target.src = avatar }}/>
            <div className="comment-content-text">
                <p className="commentor-name">{user.firstName + " " + user.lastName || ""}</p>
                <p className="comment-date">{comment.createdAt ? moment(comment.createdAt).fromNow() : ""}</p>
                <Linkify componentDecorator={linkDecorator}>
                    <p 
                        className="comment-text"
                        id={'cC' + comment._id}
                        style={{maxHeight: commentText.length > maxLength ? "120px" : ""}}
                    >{commentText}</p>
                </Linkify>
                {displayShowBtn &&
                <p className="comment-show" onClick={ ()=> changeCommentHeight() }>{showMore ? "Show less" : "Show more"}</p>
                }
                <div className="comment-react">
                    <img alt="" src={heart}/>
                    <p>{comment.totalReactions || 0}</p>
                </div>
            </div>
        </div>
    );    
};

var prevComments = {};

const commentUpdates = (comments) => {
    const commentsList = comments;
    prevComments = commentsList;
}

const TimeKeepingModal = (props) => {
    const dispatch = useDispatch();
    const timesheetInfo = props.getClocks || "";
    const profile = timesheetInfo?.user.details[0] || "";
    const clock = timesheetInfo.prag || "";
    const requiredHrs = timesheetInfo.getClocks?.policy.hoursRequired || 9;
    const [commentCount, setCommentCount] = React.useState(0);
    const [reactCount, setReactCount] = React.useState(0);
    const [isReacted, setIsReacted] = React.useState(false);
    const [isCommented, setIsCommented] = React.useState(false);
    
    const getOriginId = convertToObjectId(timesheetInfo.prag || '');

    const getComments = props.comments
    var comments = prevComments;

    if(clock && getComments && getComments.length > 0){
        comments = getComments;
        commentUpdates(getComments);
    }

    const modalWidth = {
        content : {
            maxWidth: "775px",
            maxheight: "620px",
            border: "1px solid #F3F3F3"
        }
    }

    const getReactCommentCounts = () => {
		try{
			commentService.getByOrigin(getOriginId, 1, 999999).then(res=>{
                
				if(res.page){
					setCommentCount(res.total);
					const comments = res.items;
					const user = JSON.parse(localStorage.getItem('userCredentials'));
					const currentUserId = user && user.user._id;
					const userCommented = comments.filter(c=> c.poster === currentUserId);
					if(userCommented.length > 0){
                        setIsCommented(true);
                        props.checkReactComment({...props.checkReactComment, checkComment: true});
					}
				}
			});
			reactionService.getByOrigin(getOriginId, 1, 999999).then(res=>{
				if(res.page){
					setReactCount(res.total);
                    const reactions = res.items;
					const user = JSON.parse(localStorage.getItem('userCredentials'));
					const currentUserId = user && user.user._id;
					const userReacted = reactions.filter(c=> c.poster === currentUserId);
					if(userReacted.length > 0){
                        setIsReacted(true);
                        props.checkReactComment({...props.checkReactComment, checkReact: true});
					}else{
                        setIsReacted(false);
                        props.checkReactComment({...props.checkReactComment, checkReact: false});
					}
				}
			}).catch(error=>{});
		}catch(err){
			console.log(err);
		}
    }

    const sendReact = (e) =>{
        // e.preventDefault();

        setIsReacted(isReacted => !isReacted);
        // todo: update react count after clicked

		const submitReaction = {
			originId : getOriginId,
			type: "1"
        };
        
        try{
            reactionService.postReaction(submitReaction).then((res)=>{
                if(res.success){
                    getReactCommentCounts();
                }
            }).catch(err=>{console.log(err)})
        }catch(err){
            console.log(err);
        }
    }

    React.useEffect(()=>{
        if(timesheetInfo.prag.id){
            dispatch(commentActions.getByOrigin(getOriginId, 1, 999999));
            getReactCommentCounts();
        }else{
            history.goBack();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[dispatch, timesheetInfo]);


    const close = React.useCallback(() => {
        history.goBack();
    }, []);

	return (
		<Modal
			isOpen={true}
			onRequestClose={close}
            shouldCloseOnOverlayClick={true}
            style={modalWidth}
		>
            <div className="clock-modal-container">
                <div className="clock-image-content">
                    <div className="clock-image">
                        <img 
                            alt="" 
                            onError={(e) => { e.target.src = imgNotFound }}
                            src={[praggerLink, clock.prag_out_img !== null ? clock.prag_out_img : clock.prag_in_img].join('/')}
                        />
                    </div>
                    <div className="clock-counts">
                        <div className="react-icon" onClick={debounce((e)=> sendReact(e), 1000)}>
                            <img alt="" src={isReacted ? hearted : heart}/>
                            <p>{reactCount || 0}</p>
                        </div>
                        <div className="comment-icon" onClick={()=> document.getElementById('commentForm' + getOriginId).focus()}>
                            <img alt="" src={isCommented ? haveComment : nocomment}/>
                            <p>
                                {commentCount || 0}
                                {commentCount > 1 ? ' Comments' :  ' Comment'}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="clock-info">
                    <div className="clock-comments-header">
                        <div className="clock-profile">
                            <img alt="" src={profile.avatar || avatar}/>
                            <div>
                                <p>{profile.firstName || ""} {profile.lastName || ""}</p>
                                <p>{profile.position} | {timesheetInfo.teamName}</p>
                            </div>
                        </div>
                        <div className="clock-det">
                            <img alt="" src={closeIcon} onClick={close}/>
                            <p>
                                {clock.prag_out_at ? "Clocked out " : "Clocked in "}
                                {clock.prag_out_at ? moment(clock.prag_out_at).format('hh:mm:ss') : moment(clock.prag_in_at).format('hh:mm:ss')}
                                <span>{" / " + moment(requiredHrs.toString(),"LT").format("hh:ss")}</span>
                            </p>
                        </div>
                    </div>
                    <div className="clock-comments-container">
                        <div className="comment-section">
                            {comments.length > 0 && comments.map((comment, index)=>(
                                <CommentDisplay
                                    key={index} 
                                    comment={comment}
                                />
                            )).reverse()}
                            {props.loadingComments &&
                            <SkeletonPost/>
                            }
                        </div>
                    </div>
                    {clock.id &&
                    <CommentForm 
                        postId={getOriginId}
                        userCommented = {getReactCommentCounts}
                    />
                    }
                </div>
            </div>

		</Modal>
	);
};

const mapStateToProps = (state) => {
    const getOrigin = state.comments?.orig_comment?.items || {};
    const getComments = getOrigin.length > 0 && getOrigin.filter(c => c.type === "comment");
    const getReplies = getOrigin.length > 0 && getOrigin.filter(c => c.type === "reply");
    const loading = state.comments?.loading;
    return({
        comments: getComments,
        replies: getReplies,
        loadingComments: loading
    });
};

export default React.memo(connect(mapStateToProps)(TimeKeepingModal));