import React from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect, useDispatch } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import { If, Else, Then,  } from 'react-if';
import styled from "styled-components";
import emptyIcon from '../../../_templates/empty.png';
import avatar from "../../../_templates/man.png";

import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent,DynamicTableAndPaginationWrapper, DynamicSearchWrapper } from "../../Components/PageWrapper/DynamicWrapper";
import { DynamicTableAction } from "../../Components/tableActions/tableActions";

import SettingsDark from './SettingsDark';
import SettingsAddUser from "./SettingsAddUser";

import Swal from 'sweetalert2';

import { badgesActions } from '../../../_core/actions/badges';
import { history } from "../../../_core/helpers/history";
import { settingsActions } from "../../../_core/actions/settings";



const AdminBadgestsPage = styled.div`
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4) {
        text-align: center;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: center;
        
        img {
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;


const SettingsDarkList = (props) => {
  const dispatch = useDispatch(); //eslint-disable-line
  const [entriesValues, setentriesValues] = React.useState();
  const [badges, setBadges] = React.useState([]);
  const [search, setNewSearch] = React.useState("");
  const [keys, setKeys] = React.useState("");

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  let tableHeader = (editPermission.includes("Settings - Organizations") === true) || (deletePermission.includes("Settings - Organizations") === true) || (roleAllTrue === 1) ? ["Name", "Slug", "Admin User", "Total Members", "Actions"] : ["Name", "Slug", "Admin User", "Total Members"];
  let filterkeys = ["org_name", "org_slug"];

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

    //show entries
    const entriesValue = (entries) => {
        setentriesValues(entries);
    }

    //search
    const handleSearchChange = (item) => {
      setNewSearch(item);
  	};
    
    //filters
    const filterKeys = (item) => {
        setKeys(item);
    };

    const deleteOrg = (id) => {

        const ToastSuccess = Swal.mixin({
            background: "#2a3042",
            toast: true,
            width: 300,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });
    
        // const ToastFailed = Swal.mixin({
        //     background: "#2a3042",
        //     toast: true,
        //     width: 300,
        //     position: 'top-end',
        //     showConfirmButton: false,
        //     timer: 1500
        // });
      Swal.fire({
        title: "<p class='swal-title-engagement'>Confirmation</p>",
        html:
          " <div style='margin:30px'><p class='alert-first'>Do you want to delete this Organization?</p></div>",
    
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#556ee6",
          cancelButtonColor: "#2A3143",
          customClass: {
            header: 'header-class',
            container: 'container-class',
            popup: 'popup-class-engagament',
            actions: 'actions-class-engagament',
            cancelButton: 'cancel-button-class',
            confirmButton: 'confirm-btn-engagement',
            content: 'E-alert-conent',
          },
          reverseButtons:true,
          cancelButtonText: "LEAVE",
          confirmButtonText:"DELETE",
          background: "#2E3549",
      }).then((result) => {
          if (result.value) {
        dispatch(settingsActions.deleteOrg(id)); 
        setTimeout(() => {
            ToastSuccess.fire({
            type: 'success',
            title: '<span style="color:#FFFFFF">Organization has been deleted.</span>',
          });
            dispatch(settingsActions.getAll(props.settings.currentPage, entriesValues))
        }, 100)
                  
          }
      })
    
    }

  React.useEffect(()=>{
    const timer = setTimeout(() => {
        setBadges(props.settings?.items);
      }, 100);
      return () => clearTimeout(timer);
  },[props.settings])
  

  const updateOrg = (item) => {
    history.push(`/admin/settings/${item}/edit`)
  }

  const GetPosterAvatar = ({ data }) => {

    const listItems = data.map((posterDetails) =>  
        <>
            <img
            src={posterDetails?.avatar || avatar}
            onError={(e) => e.target.src = avatar}
            alt="poster"
            />
            <span>{`${posterDetails?.firstName} ${posterDetails?.lastName}`}</span>
        </>
    );  
    return (
        <div className="posterDetails" style={{justifyContent:'center'}}>{data.length !== 0 ? listItems: 'No Default Admin'}</div>
    );
  }

//   console.log(props.settings)

  const OrgData = () => {
    let data = badges ?? [];

    //console.log(data)
    
    return (
      <>
        <If condition={props.settings.loading === false }> 
            <Then>
                    {data.length !== 0 ?  (
                data.map((item, index) => (
                    <tr key={index}>
                    <td style={{ width: "30%" }}>{item.org_name}</td>
                    <td>{item.org_slug}</td>
                    <td><GetPosterAvatar data={item.details}/></td>
                    <td>{item.totalMembers}</td>
                    {(editPermission.includes("Settings - Organizations") === true) || (deletePermission.includes("Settings - Organizations") === true) || (roleAllTrue === 1) ?
                    <td className={secretDetails !== null ? "disabled-link" : '' } style={{ width: "7%" }}>
                        <div>
                        <DynamicTableAction
                            originPage="Settings"
                            iconSet="2"
                            editLabel="Edit Organization"
                            deleteLabel="Delete Organization"
                            onClickDelete={ (e) => {deleteOrg(item.org_slug);}}
                            onClickEdit={() => updateOrg(item._id)}
                            deletePermission="Settings - Organizations"
                            editPermission="Settings - Organizations"
                        />
                        </div>
                    </td> : false
                    }
                    </tr>
                ))
                ) :
                <tr>
                    <td colSpan="12" style={{textAlign:'center'}}>
                        <img src={emptyIcon} className="empty" alt="empty"/>
                        <p style={{fontSize:'16px'}}>No Data Available</p>
                        <p style={{fontSize:'14px'}}>There are currently no data available</p>
                    </td>
                </tr>
                }
            </Then>
            <Else>
              <tr className="table-td-data">
                <td> 
                  <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                      <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                </td>
              </tr>
            </Else>
        </If>
      </>
    );
  };

  return (
    <AdminBadgestsPage>

        <DynamicSearchWrapper
            buttonLabel="ADD ORGANIZATION"
            btnPath="/admin/settings/create"
            entriesValue={entriesValue}
            data={props.settings}
            location={props.location}
            searchs={handleSearchChange}
            filters={filterKeys}
            paginationPath={"/admin/settings"}
            keys={filterkeys}
            originPage="Settings"
            addPage="Settings - Organizations"

        />

        <DynamicTableAndPaginationWrapper
            tableHeader={tableHeader}
            tableBodyData={OrgData()}
            originPage="Settings"
            data={props.settings}
            location={props.location}
            paginationPath={"/admin/settings"}
            entriesItem={entriesValues}
            loading = {props.settings.loading}
            search={search}
            filter={keys}
        />
    </AdminBadgestsPage>
  );
};


const mapStateToProps = ({quests, badges, settings }) => ({
  quests,
  badges,
  settings
});


const SettingsPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Organizations", link: "/admin/settings?page=1" },
        ]);
        break;
      case "create":
        setBreadCrumbs([
          { label: "Organizations", link: "/admin/settings?page=1" },
          { label: "Create Organization", link: "#" },
        ]);
        break;
        case "edit":
          setBreadCrumbs([
            { label: "Organizations", link: "/admin/settings?page=1" },
            { label: "Edit Organization", link: "#" },
          ]);
          break;
        case "user":
            setBreadCrumbs([
              { label: "Organizations", link: "/admin/settings?page=1" },
              { label: "Create Organization", link: "/admin/settings/create" },
              { label: "User Admin Account", link: "#" },
            ]);
            break;          
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Orgnaization | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Settings | Organizations | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : `Edit Organization | ${process.env.REACT_APP_DOCUMENT_TITLE}`
        }
      >
        <PageWrapper className={cc(["SettingsPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Settings"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <SettingsDarkList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={['create', 'edit'].includes(props.mode)}>
              <SettingsDark {...props } />
            </Case>
            <Case condition={['user'].includes(props.mode)}>
              <SettingsAddUser {...props } />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});

SettingsPage.WrappedComponent.displayName = "SettingsPage";
const SettingsPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <SettingsPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <SettingsPage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <SettingsPage {...props} mode="edit" />}
    />
    <Route
      exact
      path={`${props.match.path}/user/create`}
      render={(props) => <SettingsPage {...props} mode="user" />}
    />
  </Switch>
);
export default React.memo(SettingsPageRoute);
