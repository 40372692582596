export const userChallengeConstants = {
    GET_USER_CHALLENGES_REQUEST: 'GETALL_REQUEST',
    GET_USER_CHALLENGES_SUCCESS: 'GETALL_SUCCESS',
    GET_USER_CHALLENGES_FAILURE: 'GETALL_FAILURE',

    GET_USER_CHALLENGE_REQUEST: 'GET_REQUEST',
    GET_USER_CHALLENGE_SUCCESS: 'GET_SUCCESS',
    GET_USER_CHALLENGE_FAILURE: 'GET_FAILURE',

    ACCEPT_QUEST_CHALLENGE_REQUEST: 'ACCEPT_REQUEST',
    ACCEPT_QUEST_CHALLENGE_SUCCESS: 'ACCEPT_SUCCESS',
    ACCEPT_QUEST_CHALLENGE_FAILURE: 'ACCEPT_FAILURE',

    FINISH_QUEST_CHALLENGE_REQUEST: 'FINISH_REQUEST',
    FINISH_QUEST_CHALLENGE_SUCCESS: 'FINISH_SUCCESS',
    FINISH_QUEST_CHALLENGE_FAILURE: 'FINISH_FAILURE',

    COMPLETE_QUEST_CHALLENGE_REQUEST: 'COMPLETE_REQUEST',
    COMPLETE_QUEST_CHALLENGE_SUCCESS: 'COMPLETE_SUCCESS',
    COMPLETE_QUEST_CHALLENGE_FAILURE: 'COMPLETE_FAILURE',

    QUIT_QUEST_CHALLENGE_REQUEST: 'QUIT_REQUEST',
    QUIT_QUEST_CHALLENGE_SUCCESS: 'QUIT_SUCCESS',
    QUIT_QUEST_CHALLENGE_FAILURE: 'QUIT_FAILURE',

    POST_QUEST_CHALLENGE_REQUEST: 'POST_REQUEST',
    POST_QUEST_CHALLENGE_SUCCESS: 'POST_SUCCESS',
    POST_QUEST_CHALLENGE_FAILURE: 'POST_FAILURE',

    DEL_QUEST_CHALLENGE_REQUEST: 'DEL_REQUEST',
    DEL_QUEST_CHALLENGE_SUCCESS: 'DEL_SUCCESS',
    DEL_QUEST_CHALLENGE_FAILURE: 'DEL_FAILURE',

    FAIL_QUEST_CHALLENGE_REQUEST: 'FAIL_REQUEST',
    FAIL_QUEST_CHALLENGE_SUCCESS: 'FAIL_SUCCESS',
    FAIL_QUEST_CHALLENGE_FAILURE: 'FAIL_FAILURE',

    UPDATE_QUEST_CHALLENGE_REQUEST: 'UPDATE_REQUEST',
    UPDATE_QUEST_CHALLENGE_SUCCESS: 'UPDATE_SUCCESS',
    UPDATE_QUEST_CHALLENGE_FAILURE: 'UPDATE_FAILURE'

};