const hex = ( string ) => {
      //const alpha = ['7','f','9','2','5','0','b','8','d','3','4','ee','6','a','1','c']
      const alpha =  '0123456789ABCDEF'.split('');
      const code = string.slice(0,6).split('');
      let hash = '#';
      for(let i in code){
         let hex = code[i].charCodeAt(0) % 16

         hash += alpha[hex]
      }
      return hash;
}

export { hex }
