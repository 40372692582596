export const commendConstants = {
    
    GET_TOTAL_REQUEST: 'GET_COMMENDATIONS_REQUEST',
    GET_TOTAL_SUCCESS: 'GET_COMMENDATIONS_SUCCESS',
    GET_TOTAL_FAILURE: 'GET_COMMENDATIONS_FAILURE',

    GET_MYTOTAL_REQUEST: 'GET_MYCOMMENDATIONS_REQUEST',
    GET_MYTOTAL_SUCCESS: 'GET_MYCOMMENDATIONS_SUCCESS',
    GET_MYTOTAL_FAILURE: 'GET_MYCOMMENDATIONS_FAILURE',

    GET_USER_REQUEST: 'GET_USER_COMMENDS_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_COMMENDS_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_COMMENDS_FAILURE',

    POST_REQUEST: 'POST_COMMEND_REQUEST',
    POST_SUCCESS: 'POST_COMMEND_SUCCESS',
    POST_FAILURE: 'POST_COMMEND_FAILURE',
}