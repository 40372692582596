import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import styled  from "styled-components";
import {useDispatch } from 'react-redux';

import Modal from 'react-modal';
import FileViewer from 'react-file-viewer';
import ReactImageFallback from "react-image-fallback";

import avatar from '../../../_templates/man.png';
import { If, Then, Else } from 'react-if';
import isEmpty from 'lodash/isEmpty';

import { leaveHours } from '../../../_core/helpers/config';
import send from '../../../_templates/send.png';
import { commentService } from '../../../_core/services/comment';
import { commentActions } from '../../../_core/actions/comment';
import download from '../../../_templates/dark/download.png';
import close from '../../../_templates/dark/off_outline_close.png';
import Dropzone from "../../../Components/AttachFile/Dropzone";

//import { commentActions } from '../../../_core/actions/comment';

function isFile(input) {
	if ('File' in window && input instanceof File)
	   return true;
	else return false;
}

const ImgWrapper = styled.div`
margin-top: 40px;

 > img {
   height: 100px;
   width: 100px;
   border-radius: 50%;
   object-fit: cover;
 }
`;

const UserDetailsWrapper = styled.div``;
const NameWrapper = styled.div`

display: flex;
flex-direction: column;
 
 > label.UserName__Details {
   font-size: 18px;
   font-weight: 700;
   color: #fff;
 }

 
 > label.UserPosition__Details {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
`;


const InfoWrapper = styled.div`
display: flex;
flex-direction: column;
margin: 20px 0px;

 > label.Info__Details {
  font-size: 14px;
  font-weight: 700;
  color: #9BA3CA;
  word-break: break-word;
 }

 > label.ContactTitle__Details {
  font-size: 14px;
  font-weight: 400;
  color: #9BA3CA;
  word-break: break-word;
}

> label.Contact__Details {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  word-break: break-word;
}

> label.AddressTitle__Details {
  font-size: 14px;
  font-weight: 400;
  color: #9BA3CA;
  max-width: 235px;
  word-break: break-word;
  margin-top: 10px;
}


> label.Address__Details {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  word-break: break-word;
}
`;


const LeaveCreditsWrapper = styled.div`
display: flex;
flex-direction: column;

 > label.EvalTitle__LastEvaluation {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #9BA3CA;
 }

 > label.Date__LastEvaluation, label.Respondent__LastEvaluation, label.Primary__LastEvaluation {
  font-size: 14px;
  font-weight: 400;
  color: #9BA3CA;
  margin-bottom: 5px;
  word-break: break-word;
 }
`;


const Divider = styled.hr`
  background: #2E3549;
  border: solid 1px  #2E3549;
`;

const ChatContainer = styled.div`
    margin-top: 10px
`;

const ImgWrapperChat = styled.div`
margin-right: 20px;

 > img {
   height: 40px;
   width: 40px;
   border-radius: 50%;
   object-fit: cover;
   border: 3px solid #ffff;
 }
`;

const ImgWrapperChatLeft = styled.div`
margin-left: 10px;

 > img {
   height: 40px;
   width: 40px;
   border-radius: 50%;
   object-fit: cover;
   border: 3px solid #ffff;
 }
`;

const ImgWrapperChatRight = styled.div`
margin-right: 10px;

 > img {
   height: 40px;
   width: 40px;
   border-radius: 50%;
   object-fit: cover;
   border: 3px solid #ffff;
 }
`;

const AppStatus = styled.div `
    display: flex;
    align-items: center;
    background: #2A3143;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-left: 15px;

    > div {
        color: #9BA5C4;
        font-size: 14px;

        > h6 {
            font-weight: 700;
        }
    }
`;

const ChatDiv = styled.div `
    background: #2E3648;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 368px;
    overflow-y: scroll;
    margin-bottom: 20px;

    ::-webkit-scrollbar {
        width: 3px;
    }
        
    ::-webkit-scrollbar-track {
        background: #3E4556;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #858CAF;
        border-radius: 2.5px;
    }

    .commetReply-Div {
		padding: 10px;	
		border-top: none;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
		position: relative;
		display: inline-grid;
		width: -webkit-fill-available;

        div.comment-container{
            align-items: center;
            display: flex;
            float: right;

            p{
                font-size: 14px;
                font-weight: 700;
                font-family: Arial, Helvetica, sans-serif;
            }
    
            span {
                font-weight: 700;
                font-size: 14px;
                text-transform: capitalize;
            }
    
            span.hold{
                color: #DE6204;
            }
        }

        div.comment-left{
            line-height: 100%;
            display: block;
            position: relative;
            padding: .25em .5em;
            -webkit-border-radius: 11px;
            -moz-border-radius: 11px;
            border-radius: 11px;
            margin-bottom: 2em;
            clear: both;
            max-width: 500px;
            float:left;
            margin-right:10%;
    
            div.comment-container{
                float: left;
    
                p {
                    margin-left: 5px;
                }
            }
    
            div.text-content {
                margin-left: 50px;
                background-color: #222736;
                margin-top: 30px;
                padding: 10px;
                border: 1px solid #222736;
                border-radius: 5px;
                text-align: inherit;
    
                p{
                    line-height: 1.35;
                    color: #FFFFFF
    
                }
            }
    
        }

        div.comment-right{
            line-height: 100%;
            display: block;
            position: relative;
            padding: .25em .5em;
            -webkit-border-radius: 11px;
            -moz-border-radius: 11px;
            border-radius: 11px;
            margin-bottom: 2em;
            clear: both;
            max-width: 500px;
            float:right;
            margin-left:10%
        }

        div.text-content{
            margin-right: 45px;
            background-color: #222736;
            margin-top: 35px;
            padding: 10px;
            border: 1px solid #222736;
            border-radius: 5px;
            text-align: inherit;
    
            p{
                line-height: 1.35;
                color: #FFFFFF
    
            }
        }

        div.comment-hide{
            display: none;
        }

        div.add-comment {
            padding-top: 10px;
            flex: 0 1;
            display: flex;
            align-items: center;
            position: relative;
        
            .insert-comment {
                resize: none;
                height: 36px;
                min-height: 36px;
                border: 1px solid #6D7278 !important;
                border-radius: 5px !important;
                background-color: #2A3143;
                font-size: 14px !important;
                display: inline-block;
                color: #FFFFFF;
                padding:10px
            }
            ::-webkit-scrollbar {
                width: 0; 
                background: transparent;
            }
        
            img {
                position: absolute;
                z-index: 9999;
                right: 3px;
                bottom: 6px;
                cursor: pointer;
                top: 16px;
                filter: brightness(0) saturate(100%) invert(49%) sepia(55%) saturate(5027%) hue-rotate(215deg) brightness(93%) contrast(94%);
            }
        }

        div.attachDivAdmin {
            resize: none;
            height: 36px;
            min-height: 36px;
            border: 1px solid #6D7278 !important;
            border-radius: 5px !important;
            background-color: #2A3143;
            font-size: 14px !important;
            display: inline-block;
            color: #FFFFFF !important;
            padding:10px            
        }
    }
`;

const customStyles = {
    content: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }
  };

const PreviewLeaveUserWidget = (props) => {
  const dispatch = useDispatch();
  const [UserDetails, setUserDetails] = useState(null)
  const [UserId, setUserId] = useState(null)
  const [UserCredits, setUserCredits] = useState(null)
  const [UserRequest, setUserRequest] = useState(null)
  const [Comments, setHasComment] = useState([])
  const [Replies, setHasReply] = useState()
  const [showFilePreview, setFilePreview] = useState(false);
  const [FileUrl, setFileUrl] = useState('');
  const [FileType, setFileType] = useState('');
  const [ clearUpdate, setClearUp ] = useState(false);
  const [ fileStatus, setFileStatus ] = useState(false);

  let user = JSON.parse(localStorage.getItem('user'));
  let isReply = props.isReply
  const type = isReply ? "reply" : "comment";

  const [commentContent, setCommentContent] = React.useState({
        originId: "",
        type: type,
        content: "",
        attachFile: ""
  });
  
  const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };


  const filterViewUserDetails = () => {
    let userList = props.subjectId ?? [];
    if(userList) {
      userList.map((item) => {
          setUserDetails(item);
          setUserId(item.user_id)
        return item;
      });
      
    }
  };

  const filterCreditUserDetails = () => {
    let userCredits = props.credits ?? [];
    if(userCredits) {

        var leaveCredit = []
            userCredits.length !== 0 && userCredits.users.filter((credits) => credits._id === UserId)
              .map((userCredit) => {
                  setUserCredits(userCredit.leave_types)
                  return userCredit
              })
        return leaveCredit
    }

  };
  const filterUserRequest = () => {
    let userRequest = props.request ?? [];
        if(userRequest) {
            setUserRequest(userRequest)
        }

  };


    
    useEffect(() => {
      filterViewUserDetails();
      filterCreditUserDetails();
      filterUserRequest();
      filterComments();
      filterReplies();
    //   dispatch(commentActions.getByOrigin(UserRequest._id, 1, 999));

      // eslint-disable-next-line
    }, [props])

    const filterComments = () => {
        let comments = props.comments ?? [];
            if(comments) {
                setHasComment(comments)
            }
    
      };
    const filterReplies = () => {
        let replies = props.replies ?? [];
            if(replies) {
                setHasReply(replies)
            }
    
      };
    const creditsArray = UserCredits?.map((key) => {
        return (
            <label key={key._id} className="Date__LastEvaluation"> {key.name} : {key.available} hours ({key.available / leaveHours.time} days)</label>
        )
    })

    const clearInput = () => {
        setCommentContent({
            ...commentContent,
            originId: isReply ? props.comments[0]._id : UserRequest._id,
			type: type,
            content: "",
			attachFile: ""
        });
		setClearUp(true)
    }

    const commentValue = (e) => {
        const code = (e.keyCode ? e.keyCode : e.which);
        let inputValue = e.target.value;
        let removeWhitespaces = inputValue.trim()
        if (removeWhitespaces !== '') {
            const text = e.target.value
            setCommentContent({
                ...commentContent,
                originId: isReply ? props.comments[0]._id : UserRequest._id,
                type: type,
                content: text
            });
            
            if(code === 13 && !e.shiftKey){
                sendComment();
            }
        } else {
            clearInput();
        }
    };



    const replySubmit = (submitComment) => {

        setTimeout(function () {
            commentService.getByOrigin(submitComment.originId, 1, 999999999).then(res=>{
                if(res.items){
                    setHasReply(res.items)
                }
            }).catch(error=>{
                console.log(error)
            });
        }, 500)
		// commentService.getByOrigin(submitComment.originId, 1, 999).then(res=>{
		// 	if(res.items){
		// 		setHasReply(res.items)
		// 	}
		// }).catch(error=>{console.log(error)});
    }

    const sendComment = () => {
		let status = UserRequest.status === 'on hold' ? 'hold' : UserRequest.status;
        const submitComment = ({
            ...commentContent,
            content: status+": " + commentContent.content.replace(/(\r\n|\n|\r)$/gm, "")
        });
        try{

            dispatch(commentActions.postComment(submitComment))
			clearInput();
			setTimeout(function () {
				dispatch(commentActions.getByOrigin(submitComment.originId, 1, 999999999));
			}, 500)

			if(submitComment.type === "reply") {
				replySubmit(submitComment)
		
			}
			setTimeout(function () {
				setClearUp(false)
			}, 1000)
            // commentService.postComment(submitComment).then((res)=>{

            //     if(res.success){
			// 		// console.log(submitComment)
			// 		clearInput();
            //         dispatch(commentActions.getByOrigin(submitComment.originId, 1, 999));
                    
			// 		if(submitComment.type === "reply") {
			// 			replySubmit(submitComment)
			// 		}

					
            //     }
            // }).catch(error=>{console.log(error)});
        }catch(err){
            console.log(err)
        }
    }
    const setimage = (url) => {
		return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
	}

    const openFilePreview = () => {
		setFilePreview(true);
	};


 return (
   <div>
     {UserDetails && (
       <>
         <ImgWrapper>
           <ReactImageFallback
             src={`${UserDetails.avatar}`}
             fallbackImage={avatar}
             initialImage={avatar}
             alt="user avatar"
           />
         </ImgWrapper>
         <UserDetailsWrapper>
           <NameWrapper>
             <label className="UserName__Details">
               {toTitleCase(UserDetails.firstName + " " + UserDetails.lastName)}
             </label>
             <label className="UserPosition__Details">
               {UserDetails.position !== null && toTitleCase(UserDetails.position)}
             </label>
           </NameWrapper>

           <InfoWrapper>
             <label className="Info__Details"> Info </label>
             <label className="ContactTitle__Details"> Contact Number </label>
             <label className="Contact__Details">
               {UserDetails.contactNumber}
             </label>
             <label className="AddressTitle__Details"> Address </label>
             <label className="Address__Details">{UserDetails.address1}</label>
           </InfoWrapper>
         </UserDetailsWrapper>

         <If condition={props.availableCredits === 'true'}>
                <Then>
                    <Divider></Divider>
                    <LeaveCreditsWrapper>
                        <label className="EvalTitle__LastEvaluation">
                            Leaves Available
                        </label>
                        <If condition={UserCredits !== null}>
                            <Then>
                                {creditsArray}
                            </Then>
                            <Else>
                                <label> Loading Data... </label>
                            </Else>
                        </If>
                    </LeaveCreditsWrapper>
                </Then>
                <Else>
                    <Divider></Divider>
                </Else>
         </If>

         <ChatContainer>
            {
                isEmpty(UserRequest.rejecter_details) === false &&
                    <>
                        <AppStatus>
                            <ImgWrapperChat>
                                <ReactImageFallback
                                    src={`${UserRequest.rejecter_details[0].avatar}`}
                                    fallbackImage={avatar}
                                    initialImage={avatar}
                                    alt="user avatar"
                                />
                            </ImgWrapperChat>
                            <div>
                                <h6>Reject Remark</h6>
                                <p>{ UserRequest.rejectedRemark }</p>
                            </div>
                        </AppStatus>
                        <ChatDiv id="#style-2">
                            <div className='commetReply-Div'>
                                <div className="commentReply-container">
                                    {Comments.length > 0 && Comments.filter(venue =>venue.content?.toLowerCase().startsWith('rejected')).map((comments)=>(
                                        <div key ={comments._id} className ={comments.poster !== user.user_id ? 'comment-left' : 'comment-right'}>

                                                {
                                                    comments.poster !== user.user_id ?
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${comments.poster}`}>
                                                            <ImgWrapperChatRight>
                                                                <ReactImageFallback
                                                                    src={`${comments.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatRight>
                                                        </Link>
                                                        <Link to={`/profile/${comments.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(UserRequest.rejectedRemark) === false ? 'rejected' : ""}>{ isEmpty(UserRequest.rejectedRemark) === false ? 'Rejected' : "" }</span></p>
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${comments.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(UserRequest.rejectedRemark) === false ? 'rejected' : ""}>{ isEmpty(UserRequest.rejectedRemark) === false ? 'Rejected' : "" }</span></p>
                                                        </Link>
                                                        <Link to={`/profile/${comments.poster}`}>
                                                            <ImgWrapperChatLeft>
                                                                <ReactImageFallback
                                                                    src={`${comments.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatLeft>
                                                        </Link>
                                                    </div>
                                                }
                                                

                                            <div className='text-content'><p>{ comments.content.split(':')[1] }</p></div>
                                        </div>
                                        
                                        
                                    )).reverse()}
                                    {Replies.length > 0 && Replies.filter(venue =>venue.content?.toLowerCase().startsWith('rejected')).map((reply)=>(
                                        <div key={reply._id} className ={reply.poster !== user.user_id ? 'comment-left' : 'comment-right'} id='postRepliesContainer'>
                                            
                                                {
                                                    reply.poster !== user.user_id ?
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${reply.poster}`}>
                                                            <ImgWrapperChatRight>
                                                                <ReactImageFallback
                                                                    src={`${reply.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatRight>
                                                        </Link>
                                                        <Link to={`/profile/${reply.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(UserRequest.rejectedRemark) === false ? 'rejected' : ""}>{ isEmpty(UserRequest.rejectedRemark) === false ? 'Rejected' : "" }</span></p>
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${reply.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(UserRequest.rejectedRemark) === false ? 'rejected' : ""}>{ isEmpty(UserRequest.rejectedRemark) === false ? 'Rejected' : "" }</span></p>
                                                        </Link>
                                                        <Link to={`/profile/${reply.poster}`}>
                                                            <ImgWrapperChatLeft>
                                                                <ReactImageFallback
                                                                    src={`${reply.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatLeft>
                                                        </Link>
                                                    </div>
                                                }

                                            
                                            <div className='text-content'><p>{ reply.content.split(':')[1] }</p></div>
                                        </div>
                                    )).reverse()}
                                </div>

                                <div className={UserRequest.status !== 'rejected' ? 'comment-hide' : 'add-comment'}>
                                    <textarea 
                                        className="insert-comment" 
                                        placeholder={isReply ? "Type your reply here" : "Add a comment" }
                                        onKeyUp={ (e)=> commentValue(e) }
                                        onChange={ (e)=> commentValue(e) }
                                        value = {commentContent.content}
                                        id={'commentForm' + UserRequest._id}
                                    >
                                    </textarea>
                                    <img src={send} height={20} alt="" onClick={ ()=> sendComment() }/>
                                </div>
                            </div>

                        </ChatDiv>
                    </>
                
            }
            {
                isEmpty(UserRequest.approver_details) === false &&
                    <>
                        <AppStatus>
                            <ImgWrapperChat>
                                <ReactImageFallback
                                    src={`${UserRequest.approver_details[0].avatar}`}
                                    fallbackImage={avatar}
                                    initialImage={avatar}
                                    alt="user avatar"
                                />
                            </ImgWrapperChat>
                            <div>
                                <h6>{UserRequest.subtype === 'technical support' ?  'Completed Remark' : 'Approval Remark'}</h6>
                                <p>{ UserRequest.approvalRemark }</p>
                            </div>
                        </AppStatus>
                        <ChatDiv id="#style-2">
                            <div className='commetReply-Div'>
                                <div className="commentReply-container">
                                    {Comments.length > 0 && Comments.filter(venue =>venue.content?.toLowerCase().startsWith('approved')).map((comments)=>(
                                        <div key ={comments._id} className ={comments.poster !== user.user_id ? 'comment-left' : 'comment-right'}>

                                                {
                                                    comments.poster !== user.user_id ?
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${comments.poster}`}>
                                                            <ImgWrapperChatRight>
                                                                <ReactImageFallback
                                                                    src={`${comments.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatRight>
                                                        </Link>
                                                        <Link to={`/profile/${comments.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(UserRequest.approver_details) === false ? 'approved' : ""}>{ isEmpty(UserRequest.approver_details) === false ? UserRequest.subtype === 'technical support' ?  'Completed' : 'Approved' : ""}</span></p>
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${comments.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(UserRequest.approver_details) === false ? 'approved' : ""}>{ isEmpty(UserRequest.approver_details) === false ? UserRequest.subtype === 'technical support' ?  'Completed' : 'Approved' : "" }</span></p>
                                                        </Link>
                                                        <Link to={`/profile/${comments.poster}`}>
                                                            <ImgWrapperChatLeft>
                                                                <ReactImageFallback
                                                                    src={`${comments.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatLeft>
                                                        </Link>
                                                    </div>
                                                }
                                                

                                            <div className='text-content'><p>{ comments.content.split(':')[1] }</p></div>
                                        </div>
                                        
                                        
                                    )).reverse()}
                                    {Replies.length > 0 && Replies.filter(venue =>venue.content?.toLowerCase().startsWith('approved')).map((reply)=>(
                                        <div key={reply._id} className ={reply.poster !== user.user_id ? 'comment-left' : 'comment-right'} id='postRepliesContainer'>
                                            
                                                {
                                                    reply.poster !== user.user_id ?
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${reply.poster}`}>
                                                            <ImgWrapperChatRight>
                                                                <ReactImageFallback
                                                                    src={`${reply.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatRight>
                                                        </Link>
                                                        <Link to={`/profile/${reply.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(UserRequest.approver_details) === false ? 'approved' : ""}>{ isEmpty(UserRequest.approver_details) === false ? UserRequest.subtype === 'technical support' ?  'Completed' : 'Approved' : "" }</span></p>
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${reply.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(UserRequest.approver_details) === false ? 'approved' : ""}>{ isEmpty(UserRequest.approver_details) === false ? UserRequest.subtype === 'technical support' ?  'Completed' : 'Approved' : ""}</span></p>
                                                        </Link>
                                                        <Link to={`/profile/${reply.poster}`}>
                                                            <ImgWrapperChatLeft>
                                                                <ReactImageFallback
                                                                    src={`${reply.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatLeft>
                                                        </Link>
                                                    </div>
                                                }

                                            
                                            <div className='text-content'>
                                                <p>{ reply.content.split(':')[1] }</p>

                                            </div>
                                        </div>
                                    )).reverse()}
                                </div>

                                <div className={UserRequest.status !== 'approved' ? 'comment-hide' : 'add-comment'}>
                                    <textarea 
                                        className="insert-comment" 
                                        placeholder={isReply ? "Type your reply here" : "Add a comment" }
                                        onKeyUp={ (e)=> commentValue(e) }
                                        onChange={ (e)=> commentValue(e) }
                                        value = {commentContent.content}
                                        id={'commentForm' + UserRequest._id}
                                    >
                                    </textarea>
                                    <img src={send} height={20} alt="" onClick={ ()=> sendComment() }/>
                                </div>
                            </div>

                        </ChatDiv>
                    </>
                
            }
            {
                isEmpty(UserRequest.noter_details) === false &&
                    <>
                        <AppStatus>
                            <ImgWrapperChat>
                                <ReactImageFallback
                                    src={`${UserRequest.noter_details[0].avatar}`}
                                    fallbackImage={avatar}
                                    initialImage={avatar}
                                    alt="user avatar"
                                />
                            </ImgWrapperChat>
                            <div>
                                <h6>{UserRequest.subtype === 'technical support' ? 'In Progress Remark' : 'Hold Remark'}</h6>
                                <p>{ UserRequest.notedRemark }</p>
                            </div>
                        </AppStatus>
                        <ChatDiv>
                            <div className='commetReply-Div'>
                                <div className="commentReply-container">
                                    {Comments.length > 0 && Comments.filter(venue =>venue.content?.toLowerCase().startsWith('hold')).map((comments)=>(
                                        <div key ={comments._id} className ={comments.poster !== user.user_id ? 'comment-left' : 'comment-right'}>

                                                {
                                                    comments.poster !== user.user_id ?
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${comments.poster}`}>
                                                            <ImgWrapperChatRight>
                                                                <ReactImageFallback
                                                                    src={`${comments.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatRight>
                                                        </Link>
                                                        <Link to={`/profile/${comments.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(UserRequest.noter_details) === false ? 'hold' : ""}>{ isEmpty(UserRequest.noter_details) === false ? UserRequest.subtype === 'technical support' ? 'In Progress' : 'Hold': "" }</span></p>
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${comments.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(UserRequest.noter_details) === false ? 'hold' : ""}>{ isEmpty(UserRequest.noter_details) === false ? UserRequest.subtype === 'technical support' ? 'In Progress' : 'Hold': "" }</span></p>
                                                        </Link>
                                                        <Link to={`/profile/${comments.poster}`}>
                                                            <ImgWrapperChatLeft>
                                                                <ReactImageFallback
                                                                    src={`${comments.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatLeft>
                                                        </Link>
                                                    </div>
                                                }
                                                

                                            <div className='text-content'>
                                                {/* <p>{ comments.content.split(':')[1] }</p> */}
                                                {
                                                        comments.attachFile !== null ?

                                                        <>
                                                            {
                                                                setimage(comments.attachFile) !== false ?
                                                                    <>
                                                                        <p>{ comments.content.split(':')[1] }</p>
                                                                        <img src={comments.attachFile} width={150} height={150} alt=""  onClick={() => {  openFilePreview(); setFileUrl(comments.attachFile); setFileType(comments.attachFile.split('.').pop()) }} style={{cursor:'pointer', paddingTop:"10px",border:'none', borderRadius:0, objectFit:'contain'}} />
                                                                    
                                                                    </>
                                                                :
                                                                    <>
                                                                        <p style={{whiteSpace:'initial', wordWrap:'anywhere', marginBottom:'5px'}}>{ comments.content.split(':')[1] }</p>
                                                                        <p onClick={() => {  openFilePreview(); setFileUrl(comments.attachFile); setFileType(comments.attachFile.split('.').pop()) }} style={{cursor:'pointer', fontWeight:'700', color:'blue'}}>{ comments.attachFile.split('/').pop()}</p>
                                                                    </>


                                                            }
                                                            
                                                        </>

                                                        :
                                                        <p>{ comments.content.split(':')[1] }</p>

                                                    }                                                
                                            </div>
                                        </div>
                                        
                                        
                                    )).reverse()}
                                    {Replies.length > 0 && Replies.filter(venue =>venue.content?.toLowerCase().startsWith('hold')).map((reply)=>(
                                        <div key={reply._id} className ={reply.poster !== user.user_id ? 'comment-left' : 'comment-right'} id='postRepliesContainer'>
                                            
                                                {
                                                    reply.poster !== user.user_id ?
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${reply.poster}`}>
                                                            <ImgWrapperChatRight>
                                                                <ReactImageFallback
                                                                    src={`${reply.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatRight>
                                                        </Link>
                                                        <Link to={`/profile/${reply.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(UserRequest.noter_details) === false ? 'hold' : ""}>{ isEmpty(UserRequest.noter_details) === false ? UserRequest.subtype === 'technical support' ? 'In Progress' : 'Hold': "" }</span></p>
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div className='comment-container'>
                                                        <Link to={`/profile/${reply.poster}`} style={{color:'#9BA5C4'}}>
                                                            <p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(UserRequest.noter_details) === false ? 'hold' : ""}>{isEmpty(UserRequest.noter_details) === false ? UserRequest.subtype === 'technical support' ? 'In Progress' : 'Hold': "" }</span></p>
                                                        </Link>
                                                        <Link to={`/profile/${reply.poster}`}>
                                                            <ImgWrapperChatLeft>
                                                                <ReactImageFallback
                                                                    src={`${reply.details[0].avatar}`}
                                                                    fallbackImage={avatar}
                                                                    initialImage={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </ImgWrapperChatLeft>
                                                        </Link>
                                                    </div>
                                                }

                                            
                                            <div className='text-content'>
                                                {/* <p>{ reply.content.split(':')[1] }</p> */}
                                                {
                                                        reply.attachFile !== null ?

                                                        <>
                                                            {
                                                                setimage(reply.attachFile) !== false ?
                                                                    <>
                                                                        <p>{ reply.content.split(':')[1] }</p>
                                                                        <img src={reply.attachFile} width={150} height={150} alt=""  onClick={() => {  openFilePreview(); setFileUrl(reply.attachFile); setFileType(reply.attachFile.split('.').pop()) }} style={{cursor:'pointer', paddingTop:"10px",border:'none', borderRadius:0, objectFit:'contain'}} />
                                                                    
                                                                    </>
                                                                :
                                                                    <>
                                                                        <p style={{whiteSpace:'initial', wordWrap:'anywhere', marginBottom:'5px'}}>{ reply.content.split(':')[1] }</p>
                                                                        <p onClick={() => {  openFilePreview(); setFileUrl(reply.attachFile); setFileType(reply.attachFile.split('.').pop()) }} style={{cursor:'pointer', fontWeight:'700', color:'blue'}}>{ reply.attachFile.split('/').pop()}</p>
                                                                    </>


                                                            }
                                                            
                                                        </>

                                                        :
                                                        <p>{ reply.content.split(':')[1] }</p>

                                                    }
                                            </div>
                                        </div>
                                    )).reverse()}
                                </div>
                                <div>
                                    <Modal
                                        isOpen={showFilePreview}
                                        ariaHideApp={true}
                                        toggle={setFilePreview}
                                        onRequestClose={() => setFilePreview(false)}
                                        shouldCloseOnOverlayClick={true}
                                        className="fileModalPreview"
                                        style={customStyles}
                                    >
                                        <div
                                            className="row center rejecting-container"
                                            style={{
                                                width: "auto",
                                                height: "auto",
                                                margin: "auto",
                                                padding: "50px",
                                            }}
                                        >
                                            <div className="gAction">
                                                <a href={FileUrl} download>
                                                    <button className="btn-download" style={{background:'none'}}>
                                                        <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'brightness(5.5)' }}/>
                                                    </button>
                                                </a>
                                                <img height={20} onClick={() => setFilePreview(false)} src={close} alt="" style={{ margin:'15px 7px', filter: 'brightness(5.5)', cursor:'pointer' }}/>
                                            </div>
                                            <div style={{ height: '100%' }}>
                                                <FileViewer 
                                                    fileType={FileType} 
                                                    filePath={FileUrl} 
                                                    key= {FileUrl}
                                                />
                                            </div>

                                        </div>
                                    </Modal>
                                </div>	

                                <div className={UserRequest.status !== 'on hold' ? 'comment-hide' : 'add-comment attachDivAdmin'} style={fileStatus !== true ? {height:'70%'} : {height:'auto'}} >
                                    {/* <textarea 
                                        className="insert-comment" 
                                        placeholder={isReply ? "Type your reply here" : "Add a comment" }
                                        onKeyUp={ (e)=> commentValue(e) }
                                        onChange={ (e)=> commentValue(e) }
                                        value = {commentContent.content}
                                        id={'commentForm' + UserRequest._id}
                                    >
                                    </textarea>
                                    <img src={send} height={20} alt="" onClick={ ()=> sendComment() }/> */}
                                    <div >

                                        <Dropzone 
                                            item={items => {
                                                setCommentContent({
                                                    ...commentContent,
                                                    originId: isReply ? props.comments[0]._id : UserRequest._id,
                                                    attachFile: items.attachFile,
                                                    content: items.content,
                                                })
                                                setFileStatus(items.attachFile?.length !== 0 ? true : false)
                                            }}
                                            clear={clearUpdate}
                                            page='admin'
                                            
                                            />
                                        <img src={send} height={20} alt="" onClick={ ()=> commentContent.content.length !== 0  || isFile(commentContent.attachFile) !== false? sendComment() : ''} style={fileStatus !== true ? {top:'10px'} : {top:'16px'}}/>
                                    </div>                                    
                                </div>
                            </div>

                        </ChatDiv>
                    </>
                
            }

        </ChatContainer>
       </>
     )}
   </div>
 );
}


export default PreviewLeaveUserWidget;