import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import Select, { components } from "react-select";
import moment from "moment";
import { history } from "../../../_core/helpers/history";
//import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import Swal from 'sweetalert2'
import {useDropzone} from 'react-dropzone';
import {
  ContentWrapper,
  LabelCustom,
  CreateBtn,
  SaveAndCancelButton,
} from "../../Components/PageWrapper/ContentWrapper";
import { DragDropImage,} from "../../Components/ImageUploader/updatedUpload";

import { questService } from "../../../_core/services/quest";

import calendar from '../../../_templates/dropdown_chevrondown.png'
import defaultCover from '../../../_templates/alt_cover.png';

import { badgesActions } from "../../../_core/actions/badges";
import { rewardActions } from "../../../_core/actions/reward";
import { questActions } from "../../../_core/actions/quest";

import ReactTooltip from 'react-tooltip';
import reorderImg from '../../../_templates/dark/menu_duo.png';
import edit_icon from "../../../_templates/dark/edit_pencil.png";
import delete_icon from "../../../_templates/dark/trash.png";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
    CustomDatePicker,
    CustomInputText,
    CustomTextAreaEngage
  } from "../../Components/FloatingTextBox/TextArea";

import "./Quests.scss";
import  AddChallengeModal from "./AddChallengeModal";

import { useParams } from "react-router-dom";
import { challengeActions } from "../../../_core/actions/challenge";
import { userChallengeActions } from "../../../_core/actions/userChallenge";



export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;
export const SearchIconFindUser = styled.img`
  margin-bottom: -30px;
  margin-left: 15px;
  display: flex;
  position: relative;
  z-index: 1;
  height: 15px;
  width: 15px;
  object-fit: contain;
`;

export const FindUserWrapper = styled.div`
  > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
    margin-left: 15px;
  }
`;

export const GridContainer = styled.div`
  ${(props) =>
    props.isShowUserDetails === false
      ? css`
          margin-top: 20px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;

          // > div > label {
          //   margin-top: 10px;
          // }

        
        `
      : css`
          display: block;
        `}

  > div > label.label__questionnaires, > div > label.label__tenures {
    display: flex;
    float: left;
    top: 10px;
    position: relative;
    z-index: 1;
    margin-left: 25px;
    color: #9ba2ce;
  }

  > div > label.Error__message {
    margin-left: 10px;
  }

  > div > label.label__questionnaires {
    background: #2a3143;
    width: 72px;
    margin-top: 5px;
   
  }
  > div > label.label__tenures {
    background: #2a3143;
    width: 40px;
    margin-top: 5px;
  }

  > div > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
  }

  > div > div.error_date {
    border: 1px solid #f14646;
    border-radius: 3px;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
  margin-top: 20px;

    > img.admin_calendar_icon1 {
        display: flex;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
    }
    > img.admin_calendar_icon2 {
        display: flex;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
    }
  
    .floating-label {
        display: inline-block;
    }

    .floating-label:nth-child(2) {
        float: right; 
    }

   .calendar-dark {
        background-image:url(${calendar}); !important;
    }

`;

const ChallengeContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  >button {
    margin: 30px auto;
  }
`;

export const BadgeSelectWrapper = styled.div`
    background-color: #3F4A64;
    width: 155px;

`;

const QuestionsListWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
`;

const QuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  width: 100%;
  height: 100%;

  >div {
    width: 100%;

    &:nth-child(1) { // QUESTION
      width: 95%;
      // max-width: 745px;
      height: auto;
      min-height: 40px;
      background-color: #2E3648;
      border: 1px solid #323A4F;
      border-radius: 3px;
      padding: 0 10px;
      overflow: hidden;

      color: #9BA4C7;
      font-size: 14px;
      font-weight: 400;

      display: flex;
      align-items: center;

      >img {
        margin-right: 10px;
        cursor: pointer;
      }

      // >span {
      // }
    }

    &:nth-child(2) { // QUESTION ACTIONS
        width: 100%;
        max-width: 65px;
        display: flex;
        justify-content: space-between;
  
        >img {
          cursor: pointer
        }
    }

    &:nth-child(3) { // QUESTION ACTIONS
      width: 100%;
      max-width: 48px;
      display: flex;
      justify-content: space-between;

      >img {
        cursor: pointer
      }
    }

  }
`;

const mapStateToProps = ({ evaluations, questionnaires, tenures, users, badges, reward}) => ({
  evaluations,
  questionnaires,
  tenures,
  users,
  badges,
  reward
});

const CreateEditQuestPage = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();
  const [InputError, setInputError] = useState(false);
  const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
  const [badgeOptions, setBadgeOption] = useState([]);
  const [rewardsOptions, setRewardsOption] = useState([]);
  const [files, setFiles] = React.useState([]);
  const [showAppModal, setAppModal] = React.useState(false);// eslint-disable-line
  const [count, setCount] = React.useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [editStartDate, setEditStartDate] = useState();
  const [ExpirationDate, setExpirationDate] = useState(new Date());
  const [IsEditedExpire, setIsEditedExpire] = useState(false)

  const userCred = JSON.parse(localStorage.getItem('userCredentials'))

  const [remove, setRemove] = useState(false);

  const { pageId } = useParams();

  const [checked, setchecked] = useState(false);

  const [challenge, setChallenge] = useState([]);
  const [challenge2, setChallenge2] = useState([]);
  const [challengeEdit, setChallengeEdit] = useState({})
  const [challengeIndex, setChallengeIndex] = useState()
  const [editChallenge, setEditChallenge] = useState(false)
  

  const [quest, setQuest] = useState({
    questTitle: "",
    questContent:"",
    questType: "",
    questCat: "",
    userId: userCred.user._id,
    questImage: "",
    difficulty: "",
    recurring: checked,
    frequency: "",
    duration: 0,
    rewardType: "",
    rewardValue: "",
    expiresAt: moment(ExpirationDate).utc().format(),
    eneryValue: 0,
    credits: "",
    exp: "",
    level:"",
    rewards:null,
    badge:null,
    questChallenges:[],
    participants:[]
  });


  React.useEffect(() => {
    if (pageId) {
      setIsAdd(false);
      questService.getById(pageId).then((res) => { 
        if(res.success !== undefined) {
          let data = res.success[0];
          
          setQuest({
            questTitle: data.questTitle,
            questContent:data.questContent,
            questType: data.questType,
            questCat: data.questCat,
            userId: userCred.user._id,
            questImage: data.questImage,
            difficulty: data.difficulty,
            recurring: data.recurring,
            frequency: data.frequency,
            duration: data.duration,
            rewardType: data.rewardType,
            rewardValue: data.rewardValue,
            expiresAt: data.expiresAt,
            eneryValue: data.eneryValue,
            credits: data.credits,
            exp: data.exp,
            level:data.level,
            rewards:data.rewards,
            badge:data.badge,
            questChallenges:data.questChallenges,
            participants:data.participants,
            
          })
          setchecked(data.recurring ? true : false)
          var startdate = moment(data.expiresAt).subtract(parseInt(data.duration), 'days').toDate()
          setEditStartDate(startdate)
          setChallenge2(data.questChallenges.concat(challenge))
          setCount(data.questContent.length)
        }
      });
    }else{
        if(!editChallenge){
            setChallenge2(challenge)
        }else{
            const updatedData = challenge2.map(x => (x.order === challenge.order ? challenge : x));

            setChallenge2(updatedData)
        }
        
    }
    //eslint-disable-next-line
  }, [pageId,challenge,startDate]);



  const {getRootProps, getInputProps, open} = useDropzone({
    accept: 'image/x-png, image/png, image/jpg, image/jpeg, image/gif',
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
        })));
        setQuest({
            ...quest,
            questImage:acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
                }))[0],
          });

    }
    });

  React.useEffect(() => {
    dispatch(badgesActions.getAll(1,10000,'',''));
    dispatch(rewardActions.getAll(100000,1,'',''));
    //eslint-disable-next-line
  }, [dispatch]);


  useEffect(() => {
    filterBadgeDropdownValue(props?.badges?.items);
    //eslint-disable-next-line
  }, [props.badges.items]);
  useEffect(() => {
    filterRewardsDropdownValue(props?.reward?.items);
    //eslint-disable-next-line
  }, [props.reward]);


  const filterBadgeDropdownValue = (data) => {
    let optionGroupData = [];
    data?.items && data.items.map((items) => {
      if (items.length !== 0) {
        optionGroupData.push({
          label: 
                <div style={{display:'flex',alignItems:'center'}}>
                    <img src={items.badgeImage ? items.badgeImage : defaultCover} alt ="badge" onError={(e) => e.target.src = defaultCover} height="33px" width="33px" style={{objectFit:'contain', marginRight:'10px'}}/>
                    <p>{items.badgeName}</p>
                </div>,
          value: items._id,
          
        });
      }
      return null
    });

    setBadgeOption(optionGroupData);
  };

  const filterRewardsDropdownValue = (data) => {
    let optionGroupData = [];
    data && data.map((items) => {
      if (items.length !== 0) {
        optionGroupData.push({
          label: 
                <div style={{display:'flex',alignItems:'center'}}>
                    <img src={items.image ? items.image : defaultCover} onError={(e) => e.target.src = defaultCover} alt="rewards" height="33px" width="33px" style={{objectFit:'contain', marginRight:'10px'}}/>
                    <p>{items.title}</p>
                </div>,
          value: items._id,
          
        });
      }
      return null
    });

    setRewardsOption(optionGroupData);
  };


  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    if(name === "questContent"){
        setCount(value.length)
    }
      setQuest({
        ...quest,
        [name]: value,
      });

    }

    const removeSelectedImage = () => {
      setFiles([]);
      setQuest({ ...quest, questImage: null});
      setRemove(true);
  }  


    //QuestChallengeModalData
    const handleReorderList = (result) => {
        console.log(result)
        if (!result.destination) {
            return;
        }

        const [reorderedItem] = challenge2.splice(result.source.index, 1);
        challenge2.splice(result.destination.index, 0, reorderedItem);
            
        //setChallenge2(challenge2)
        var newArray = [];
        challenge2.forEach((item,index) => {
            let newOrder = ({
                _id: item._id,
                questId:item.questId,
                recurring: item.recurring,
                timeLimit: item.timeLimit,
                active:item.active,
                requiredBadges:item.requiredBadges,
                title: item.title,
                description: item.description,
                bestTime: item.bestTime,
                location: item.location,
                lat:item.lat,
                lon:item.lon,
                eneryValue:item.eneryValue,
                credits:item.credits,
                exp:item.exp,
                expiresAt: item.expiresAt,
                startsAt: item.startsAt,
                images:item.images,
                order:index
            })
            newArray.push(newOrder)
            
        })

        setChallenge2(newArray)

        
    }

    const saveEnergy = (e,users) => {
        console.log(e)
        console.log(users)
    }

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
  return (
      <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused} className="menu">
          {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
      )}
      </ValueContainer>
  );
  };

  const customStyles2 = {
    control: (base, state) => (InputError ? {
        ...base,
        background: "#2e3648",
        height:"40px",
        // match with the menu
        borderRadius: '5px',
        marginBottom:"15px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#323a4f" : "#f14646",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          // Overwrittes the different states of border
          borderColor: state.isFocused ? "#556ee6" : "#323A4F",
        },
      }:{
        ...base,
        background: "#2e3648",
        height:"40px",
        borderRadius: '5px',
        marginBottom:"20px",
        borderColor: state.isFocused ? "#323a4f" : "#323a4f",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? "#556ee6" : "#323A4F",
        },
      }),
    container: (provided, state) => ({
        ...provided,
        marginTop: 0,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        marginTop: '-15px',
      }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: "#9BA3CA",
        "&:hover": {
            color: state.isFocused ? "#556ee6" : "#556ee6",
          }, 
    }),
    option: (base, state) => ({
        ...base,
        color: "#9ba2ce",
        backgroundColor: state.isSelected ? "#32394A" : "#2e3648",
        ":hover": {
          backgroundColor: state.isSelected ? "#2e3648" : "#32394A",
        },
        zIndex: "999",
      }),

    menuList: (base) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    menu: (provided) => ({
      ...provided,
      height: "auto",
      maxHeight: "208px",
      position: "absolute",
      display: "block",
      background: "#2E3648",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible"
    }),
    input: (provided, state) => ({
        ...provided,
        height: 35,
        color: '#9BA3CA',
        lineHeight: state.isFocused ? 35 : 0,
        transition:  "top 0.1s, font-size 0.1s"
    }),
    placeholder: (provided, state) => (InputError ? {
        ...provided,
        position: "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
        transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
        background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
        color: state.hasValue ? "#9BA3CA" :"#f14646",
    }:{
        ...provided,
        position: "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
        transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
        background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
        color: "#9BA3CA",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: ' #9BA3CA',
        fontSize:"14px",
        fontWeight:'normal',
        top:'35%'
      }),
  };


  const reactSelectCustomStyles = {
    control: (base, state) => ({
        ...base,
        background: "#2e3648",
        height:"80px",
        borderRadius: '5px',
        marginBottom:"20px",
        borderColor: state.isFocused ? "#323a4f" : "#323a4f",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? "#556ee6" : "#323A4F",
        }, 
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible"
    }),

	input: (styles, state) => ({
		...styles,
		height: 45,
		margin: 0,
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        marginTop: '-15px',
      }),
    menu: (provided) => ({
        ...provided,
        height: "auto",
        maxHeight: "208px",
        position: "absolute",
        display: "block",
        background: "#2E3648",
      }),
    option: (base, state) => ({
        ...base,
        color: '#9BA3CA',
        backgroundColor: state.isSelected ? '#32394A' : '#2A3143',
        ':hover': {
          backgroundColor: state.isSelected ? '#2A3143' : '#32394A'
        }
    }),
    multiValue: (base) => ({
        ...base,
        backgroundColor: '#3F4A64',
        width:'max-content',
        height:'34px'
    }),
    multiValueLabel: (base) => ({
        ...base,
        color:'#9BA4C7',
        width:'155px',
        padding:'0px',
        paddingLeft:'0px'
    }),
    multiValueRemove:(base, state) => ({
        ...base,
        color: '#3F4A64',
        borderRadius:'50%',
        height:'24px',
        width:'24px',
        margin:'5px',
        backgroundColor: state.isSelected ? '#556EE6' : '#556EE6',
        ':hover': {
          backgroundColor: state.isSelected ? '#556EE6' : '#556EE6'
        }
    }),
    placeholder: (provided, state) => ({
        ...provided,
        position: "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -15 : "35%",
        transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
        background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
        color: "#9BA3CA",
    }),

};


const questChallengeOptions = {
    control: (provided, state) => ({
        ...provided,
        background: '#3F4A64',
        minHeight: 34,
        height: 34,
        width:'50%',
        borderColor: state.isFocused ? "#323a4f" : "#323a4f",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? "#556ee6" : "#323A4F",
        }, 
    }),

    option: (base, state) => ({
        ...base,
        color: '#9BA3CA',
        backgroundColor: state.isSelected ? '#32394A' : '#2A3143',
        ':hover': {
          backgroundColor: state.isSelected ? '#2A3143' : '#32394A'
        }
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible"
    }),

	input: (styles, state) => ({
		...styles,
		height: 45,
		margin: 0,
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        marginTop: '-15px',
      }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        transition: '0.5s',
        marginTop: '-10px'
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
      }),
    placeholder: (provided, state) => ({
        ...provided,
        display:'none'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: ' #9BA3CA',
        fontSize:"12px",
        fontWeight:'400',
        top:'15'
      }),
};


  const cancelQuest = () => {

    if (
        quest.questTitle.trim() !== '' ||
        quest.questTitle.trim() !== '' ||
        quest.questTitle.trim() !== '' ||
        quest.questTitle.trim() !== '' ||
        quest.questTitle.trim() !== '' ||
        quest.questTitle.trim() !== '' ||
        quest.questTitle.trim() !== '' ||
        quest.questTitle.trim() !== '' ||
        quest.questTitle.trim() !== ''
    ){
        confirmationModal().then((result) => {
            if(result.value){
                submitEvaluation()
            }else if (result.dismiss === 'cancel'){
                // reset
                setQuest({
                    questTitle: "",
                    questContent:"",
                    questType: "",
                    questCat: "",
                    userId: "",
                    questImage: "",
                    difficulty: "",
                    recurring: "",
                    frequency: "",
                    duration: "",
                    rewardType: "",
                    rewardValue: "",
                    expiresAt: null,
                    eneryValue: 0,
                    credits: "",
                    exp: "",
                    level:"",
                    rewards:null,
                    badge:null
                });
                setFiles([]);
                setChallenge([]);
                setInputError(false);
                history.goBack();

            }
        })
    }else{
        history.goBack();
    }


  }


  const confirmationModal = () => {
  
    return Swal.fire({
      title: "<p class='swal-title-engagement'>Confirmation</p>",
      html:
        " <div style='margin-bottom:10px'><p class='alert-first'>Before leaving this page would you like to save?</p></div>"+
        "<div style='margin-bottom:20px'><p class='alert-second'>If you don’t save your progress your changes will be lost.</p></div>",

      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#556ee6",
      cancelButtonColor: "#2A3143",
      customClass: {
        header: 'header-class',
        container: 'container-class',
        popup: 'popup-class-engagament',
        actions: 'actions-class-engagament',
        cancelButton: 'cancel-button-class',
        confirmButton: 'confirm-btn-engagement',
        content: 'E-alert-conent',
      },
      reverseButtons:true,
      cancelButtonText: "LEAVE",
      confirmButtonText:"SAVE CHANGES",
      background: "#2E3549",
    });

    
  };

  const submitEvaluation = () => {
    if(
        !quest.questContent ||
        !quest.questTitle ||
        !quest.questType ||
        !quest.questCat ||
        !quest.expiresAt ||
        !quest.difficulty ||
        !quest.level ||
        !quest.credits ||
        !quest.exp
    ) {
        setInputError(true);
    }else{
        if(isAdd){
            dispatch(questActions.create(quest,challenge2))
            setInputError(false);
        }else {
            dispatch(questActions.update(pageId,quest,challenge2))
            // setTimeout(() => {
            //     history.goBack()    
            // }, 1500)
        }
    }
  };

    const handleSelect = (data) => {
        const names = data.target.name
        const value = data.target.value
        setQuest({ ...quest, [names]: value });
    };

    const handleBadges = (data) => {
        const badgeList =[]
        data && data.map((items) => {
        if (items.length !== 0) {
            badgeList.push(items.value);
        }
        return null
        });
        setQuest({ ...quest, badge: badgeList });
    };

    const handleRewards = (data) => {
        const rewardsList =[]
        data && data.map((items) => {
        if (items.length !== 0) {
            rewardsList.push(items.value);
        }
        return null
        });
        setQuest({ ...quest, rewards: rewardsList });
    };

    
    const handleChecked=(checked)=> {
        setchecked(checked);
        setQuest({ ...quest, recurring: checked });
    }

    const handleNumber = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const total = parseInt(value);
        if(value.length === 0) {
          setQuest({ ...quest, [e.target.name]: '', });
        } else {
            setQuest({ ...quest, [e.target.name]: total, });
        }
    };

    const handleToDate = (date) => {
        setExpirationDate(date);
        setIsEditedExpire(true);
        const days = moment.duration(moment(date).diff(moment(startDate))).asDays()
        setQuest({ ...quest, expiresAt: moment(date).utc().format(), duration: days });
    };

    const handleFromDate = (date) => {
        setStartDate(date);
        const days = moment.duration(moment(quest.expiresAt).diff(moment(date))).asDays()
        setQuest({ ...quest, duration: days });
    };

    let isValidTitle = quest.questTitle.trim() === '';
    

    const handleChallenge = () => {
      setAppModal(true);
    }

    const handleCloseModal =()=> {
        setAppModal(false)
        setChallengeEdit({})
        setChallengeIndex()
        setChallenge([])
    }

    const TypeOptions = [
        { value: "limited", label: 'Limited'},
        { value: "regular", label: 'Regular'}
    ];
    const CategoryOptions = [
        { value: "productivity", label: 'Productivity'},
        { value: "recreational", label: 'Recreational'},
        { value: "training", label: 'Training'},
        { value: "hr", label: 'HR'}
    ];
    const DifficultyOptions = [
        { value: "easy", label: 'Easy'},
        { value: "hard", label: 'Hard'},
        { value: "advanced", label: 'Advanced'}
    ];
    const LevelOptions = [
        { value: "1", label: 'Level 1'},
        { value: "2", label: 'Level 2'},
        { value: "3", label: 'Level 3'},
        { value: "5", label: 'Level 5'},
        { value: "8", label: 'Level 8'},
        { value: "13", label: 'Level 13'},
        { value: "21", label: 'Level 21'},
        { value: "34", label: 'Level 34'},
        { value: "55", label: 'Level 55'}

    ];

    const expOptions = [
        { value: "10", label: '10'},
        { value: "25", label: '25'},
        { value: "50", label: '50'},
        { value: "100", label: '100'},
        { value: "250", label: '250'},
        { value: "500", label: '500'},
        { value: "750", label: '750'},
        { value: "1000", label: '1000'},
    ];

    const creditsOptions = [
        { value: "10", label: '10'},
        { value: "20", label: '20'},
        { value: "50", label: '50'},
        { value: "100", label: '100'},
        { value: "250", label: '250'},
        { value: "300", label: '300'},
        { value: "500", label: '500'},
    ];

    const energyOptions = [
        { value: 1, label: '1'},
        { value: 2, label: '2'},
        { value: 3, label: '3'},
        { value: 4, label: '4'},
        { value: 5, label: '5'},
        { value: 6, label: '6'},
        { value: 7, label: '7'},
        { value: 8, label: '8'},
        { value: 9, label: '9'},
        { value: 10, label: '10'}
    ];

    const badgePage = () => {

        if (
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== ''
        ){
            confirmationModal().then((result) => {
                if(result.value){
                    submitEvaluation()
                }else if (result.dismiss === 'cancel'){
                    // reset
                    setQuest({
                        questTitle: "",
                        questContent:"",
                        questType: "",
                        questCat: "",
                        userId: "",
                        questImage: "",
                        difficulty: "",
                        recurring: "",
                        frequency: "",
                        duration: "",
                        rewardType: "",
                        rewardValue: "",
                        expiresAt: null,
                        eneryValue: 0,
                        credits: "",
                        exp: "",
                        level:"",
                        rewards:null,
                        badge:null
                    });
                    setFiles([]);
                    setChallenge([]);
                    setInputError(false);
                    history.push(`/admin/badges/create`)
    
                }
            })
        }else{
            history.push(`/admin/badges/create`)
        }
    
    }

    const rewardPage = () => {

        if (
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== '' ||
            quest.questTitle.trim() !== ''
        ){
            confirmationModal().then((result) => {
                if(result.value){
                    submitEvaluation()
                }else if (result.dismiss === 'cancel'){
                    // reset
                    setQuest({
                        questTitle: "",
                        questContent:"",
                        questType: "",
                        questCat: "",
                        userId: "",
                        questImage: "",
                        difficulty: "",
                        recurring: "",
                        frequency: "",
                        duration: "",
                        rewardType: "",
                        rewardValue: "",
                        expiresAt: null,
                        eneryValue: 0,
                        credits: "",
                        exp: "",
                        level:"",
                        rewards:null,
                        badge:null
                    });
                    setFiles([]);
                    setChallenge([]);
                    setInputError(false);
                    history.push(`/admin/rewards/create`)
    
                }
            })
        }else{
            history.push(`/admin/rewards/create`)
        }
    
    }

    const handleEditChallenge = (items, id, index) => {

        if(id === undefined){
            setChallengeIndex(index)
            setAppModal(true)
            setChallengeEdit(items)
            setEditChallenge(true)

        }else{
            setChallengeIndex(id)
            setAppModal(true)
            setChallengeEdit(items)
            setEditChallenge(true)
        }

    }

    const handleDeleteChallenge = (item) => {

        const ToastSuccess = Swal.mixin({
            background: "#2a3042",
            toast: true,
            width: 300,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });
    
        Swal.fire({
          title: "<p class='swal-title-engagement'>Confirmation</p>",
          html:
            " <div style='margin:30px'><p class='alert-first'>Do you want to delete this challenge?</p></div>",
      
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#556ee6",
            cancelButtonColor: "#2A3143",
            customClass: {
              header: 'header-class',
              container: 'container-class',
              popup: 'popup-class-engagament',
              actions: 'actions-class-engagament',
              cancelButton: 'cancel-button-class',
              confirmButton: 'confirm-btn-engagement',
              content: 'E-alert-conent',
            },
            reverseButtons:true,
            cancelButtonText: "LEAVE",
            confirmButtonText:"DELETE",
            background: "#2E3549",
        }).then((result) => {
            if (result.value) {

                if(item._id !== undefined){
                    dispatch(challengeActions.deleteChallenge(item._id))
                    dispatch(userChallengeActions.deleteQuestChallenge(quest.participants,item._id))
                    dispatch(userChallengeActions.revertQuest(quest.participants,pageId))
                    setTimeout(() => {
                        ToastSuccess.fire({
                        type: 'success',
                        title: '<span style="color:#FFFFFF"> Challenge  has been deleted.</span>',
                        });
                        window.location.reload() 
                    }, 1500)
                }else{
                    let someArray = challenge2.filter(person => person.order !== item.order);
                    setChallenge2(someArray)
                }
                

            }
        })
      }


    const addPermission = JSON.parse(localStorage.getItem('addPermission'));
    const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

    // console.log(addPermission.includes("Engagement - Challenges"));
    // console.log(deletePermission.incl);


  return (
    <CustomContentWrapper>
      <ContentWrapper>
        <LabelCustom className="main-label">
          {isAdd ? "Create" : "Edit"} a Quest
        </LabelCustom>
        <CustomInputText
            label={"Quest Name"}
            name={"questTitle"}
            value={quest.questTitle}
            inputOnChange={handleChangeInput}
            isError={isValidTitle && InputError}
        />
        <CustomTextAreaEngage
            label={"Description"}
            name={"questContent"}
            value={quest.questContent}
            inputOnChange={handleChangeInput}
            maxlength="250"
            isError={!quest.questContent && InputError}
        />
        <p className="charCount2">{count}</p>

        <label className="questCover">Quest Cover photo</label>
        <div {...getRootProps({className: 'dropzone'})}>
            <div className={files[0] || quest.questImage? 'active-dz': 'unactive-dz'} >
                <input {...getInputProps()} />
                <div>
                    <p className="dropTitle">Drag and Drop</p>
                    <p className="dropTitle">Cover Photo here</p>
                    <p className="dropTitle">or</p>
                </div>
                <button onClick={open} className="custom-file-upload-engage">
                    Browse
				</button>
            </div>
            <DragDropImage
               removeImage={removeSelectedImage} 
               selectedImage={files} 
               existingImage={quest.questImage} 
               remove={remove}
               isAdd={isAdd}

            />
        </div>
        
        <DatePickerWrapper>
            <label className="questCover">Valid till</label>
            <div style={{marginTop:'20px'}}>
            <CustomDatePicker
                type="text"
                label="From"
                selected={isAdd === true ? startDate :  editStartDate}
                startDate={startDate}
                endDate={ExpirationDate}
                value={startDate}
                onChange={(date) => handleFromDate(date)}
                dateFormat="dd/MM/yyyy"
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                selectsStart
                maxDate={ExpirationDate}
            />
            <CustomDatePicker
                type="text"
                label="To"
                selected={ExpirationDate && IsEditedExpire === false ?  new Date(quest.expiresAt)  :  ExpirationDate}
                startDate={startDate}
                minDate={startDate}
                endDate={ExpirationDate}
                selectsEnd
                onChange={(date) => handleToDate(date)}
                dateFormat="dd/MM/yyyy"
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                isError={!quest.expiresAt && InputError}
            />
            </div>

        </DatePickerWrapper>

        <Select
            value={TypeOptions.find(option => option.value === quest.questType || '' )}
            onChange={(statusValue)=> {handleSelect({target: { name:'questType', value: statusValue === null ? '' : statusValue.value }})}}
            components={{
                ValueContainer: CustomValueContainer
            }}
            captureMenuScroll={false}
            placeholder="Type"
            styles={customStyles2}
            options={TypeOptions}
            openMenuOnClick={false}
            isSearchable={false}
            menuPlacement="bottom"
            menuPortalTarget={document.querySelector('body')} 
            hideSelectedOptions={false}
            isClearable={true}
            maxMenuHeight={200}
            className="basic-single"
            classNamePrefix="select"
            name="questType"
        />
        {InputError && !quest.questType && (
            <div className="Error_div"><label className="Error__message">Please fill-up this field</label></div>
        )}
        <Select
            value={CategoryOptions.find(option => option.value === quest.questCat || '' )}
            onChange={(statusValue)=> {handleSelect({target: { name:'questCat', value: statusValue === null ? '' : statusValue.value }})}}
            components={{
                ValueContainer: CustomValueContainer
            }}
            captureMenuScroll={false}
            placeholder="Category"
            styles={customStyles2}
            options={CategoryOptions}
            openMenuOnClick={false}
            isSearchable={false}
            menuPlacement="bottom"
            menuPortalTarget={document.querySelector('body')} 
            hideSelectedOptions={false}
            isClearable={true}
            maxMenuHeight={200}
            className="basic-single"
            classNamePrefix="select"
            name="questCat"
        />
        {InputError && !quest.questCat && (
            <div className="Error_div"><label className="Error__message">Please fill-up this field</label></div>
        )}
        <Select
            value={DifficultyOptions.find(option => option.value === quest.difficulty || '' )}
            onChange={(statusValue)=> {handleSelect({target: { name:'difficulty', value: statusValue === null ? '' : statusValue.value }})}}
            components={{
                ValueContainer: CustomValueContainer
            }}
            captureMenuScroll={false}
            placeholder="Difficulty"
            styles={customStyles2}
            options={DifficultyOptions}
            openMenuOnClick={false}
            isSearchable={false}
            menuPlacement="bottom"
            menuPortalTarget={document.querySelector('body')} 
            hideSelectedOptions={false}
            isClearable={true}
            maxMenuHeight={200}
            className="basic-single"
            classNamePrefix="select"
            name="difficulty"
        />
        {InputError && !quest.difficulty && (
            <div className="Error_div"><label className="Error__message">Please fill-up this field</label></div>
        )}
        <Select
            value={LevelOptions.find(option => option.value === quest.level || '' )}
            onChange={(statusValue)=> {handleSelect({target: { name:'level', value: statusValue === null ? '' : statusValue.value }})}}
            components={{
                ValueContainer: CustomValueContainer
            }}
            captureMenuScroll={false}
            placeholder="Level Qualifications"
            styles={customStyles2}
            options={LevelOptions}
            openMenuOnClick={false}
            isSearchable={false}
            menuPlacement="bottom"
            menuPortalTarget={document.querySelector('body')} 
            hideSelectedOptions={false}
            isClearable={true}
            maxMenuHeight={200}
            className="basic-single"
            classNamePrefix="select"
            name="level"
        />
        {InputError && !quest.level && (
           <div className="Error_div"><label className="Error__message">Please fill-up this field</label></div>
        )}
        <div className="toggleSwitch">
            <div className="titleSwitch">Recurring</div>
                <Switch 
                    onColor="#556EE6"
                    height={16}
                    width={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={handleChecked} 
                    checked={checked} 
                    className="toggleBtn"
                />
        </div> 
        <CustomInputText
            label={"Credits"}
            name={"credits"}
            value={String(quest.credits)}
            inputOnChange={(e) => handleNumber(e)}
            isError={!quest.credits && InputError}
        />
        <CustomInputText
            label={"Experience"}
            name={"exp"}
            value={String(quest.exp)}
            inputOnChange={(e) => handleNumber(e)}
            isError={!quest.exp && InputError}
        />
        <div className="divChallenge">
            <label className="questCover">Quest Challenge(s)</label>
            {
              (addPermission.includes("Engagement - Challenges") === true) || (roleAllTrue === 1) ? 
              <CreateBtn style={{ maxWidth: "176px" }} onClick={handleChallenge}>ADD CHALLENGE</CreateBtn> : false
            }
        </div>
        <ChallengeContent>
                <DragDropContext onDragEnd={handleReorderList} ReOrder>
                    <Droppable droppableId="questionsReorder">
                        {provided => (
                        <QuestionsListWrapper className="questionsReorder" {...provided.droppableProps} ref={provided.innerRef}>
                            {challenge2.sort((a, b) => a.order - b.order).map((item, index) => (
                                <Draggable key={index} draggableId={`${index}`} index={index}>
                                {provided => (
                                    <QuestionWrapper
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    >
                                    <div className="containerQuest">
                                        <div className="fixed">
                                            <img src={reorderImg} alt="re-order"  style={{marginRight:"10px", verticalAlign:'middle'}}/>
                                            <span>{item.title}</span>
                                        </div>
                                        <div style={{display:'inline-flex'}} className="actions-div">
                                            <div className="flex-item" style={{marginRight:"-20px"}}>
                                                <span>EXP :</span>
                                                <Select
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    captureMenuScroll={false}
                                                    options={expOptions}
                                                    value = {
                                                        expOptions.filter(option => 
                                                        option.value === item.exp)
                                                    }
                                                    className="basic-single questOption"
                                                    styles={questChallengeOptions}
                                                        // onChange={(event) => {
                                                        //     saveRole(
                                                        //         event.value, 
                                                        //         users )
                                                        //     }}
                                                    maxMenuHeight={220}
                                                    menuPlacement="auto"
                                                    menuPortalTarget={document.querySelector('body')}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                />
                                            </div>
                                            <div className="flex-item">
                                                <span>Credits :</span>
                                                <Select
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    captureMenuScroll={false}
                                                    options={creditsOptions}
                                                    value = {
                                                        creditsOptions.filter(option => 
                                                        option.value === item.credits)
                                                    }
                                                    className="basic-single questOption"
                                                    styles={questChallengeOptions}
                                                        // onChange={(event) => {
                                                        //     saveRole(
                                                        //         event.value, 
                                                        //         users )
                                                        //     }}
                                                    maxMenuHeight={220}
                                                    menuPlacement="auto"
                                                    menuPortalTarget={document.querySelector('body')}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                />
                                            </div>
                                            <div className="flex-item">
                                                <span>Energy :</span>
                                                <Select
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    captureMenuScroll={false}
                                                    options={energyOptions}
                                                    value = {
                                                        energyOptions.filter(option => 
                                                        option.value === item.eneryValue)
                                                    }
                                                    className="basic-single questOption"
                                                    styles={questChallengeOptions}
                                                        onChange={(event) => {
                                                            saveEnergy(
                                                                event.value, 
                                                                challenge )
                                                            }}
                                                    maxMenuHeight={220}
                                                    menuPlacement="auto"
                                                    menuPortalTarget={document.querySelector('body')}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div>
                                    <img
                                        src={edit_icon}
                                        alt="edit"
                                        data-tip={"edit"}
                                        place="top"
                                        data-class="tooltips"
                                        onClick={()=> handleEditChallenge(item, item._id, index)}
                                    />
                                    {
                                      (deletePermission.includes("Engagement - Challenges") === true) || (roleAllTrue === 1) ? 
                                        <img
                                            src={delete_icon}
                                            alt="delete"
                                            data-tip={"delete"}
                                            place="top"
                                            data-class="tooltips"
                                            onClick={() => handleDeleteChallenge(item)}
                                        /> : false
                                    }
                                    <ReactTooltip />
                                    </div>
                                    </QuestionWrapper>
                                )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </QuestionsListWrapper>
                        )}
                    </Droppable>
                </DragDropContext>
                {
                  (addPermission.includes("Engagement - Challenges") === true) || (roleAllTrue === 1) ? 
                  <CreateBtn style={{ maxWidth: "260px" }} onClick={() => handleChallenge() }>ADD CHALLENGE</CreateBtn> : false
                }
        </ChallengeContent>
        <div style={{marginBottom:'20px'}}>
            <Select
                value={badgeOptions.filter(f => quest.badge?.includes(f.value) || "")}
                classNamePrefix="select"
                id="badge"
                isClearable
                isMulti
                onChange={handleBadges}
                options={badgeOptions}
                styles={reactSelectCustomStyles}
                components={{
                    ValueContainer: CustomValueContainer
                }}
                menuPortalTarget={document.querySelector('body')} 
                // value={badgeOptions}
                placeholder="Badge"
                maxMenuHeight={200}
                captureMenuScroll={false}
                openMenuOnClick={false}
                />
                {
                   (addPermission.includes("Engagement - Badges") === true) || (roleAllTrue === 1) ? 
                   <span className="pageurl" onClick={badgePage}>+ Add more Badge</span> : false
                }
        </div>
        <div>
            <Select
                value={rewardsOptions.filter(f => quest.rewards?.includes(f.value) || "")}
                classNamePrefix="select"
                id="rewards"
                isClearable
                isMulti
                onChange={handleRewards}
                options={rewardsOptions}
                styles={reactSelectCustomStyles}
                components={{
                    ValueContainer: CustomValueContainer
                }}
                menuPortalTarget={document.querySelector('body')} 
                //value={members}
                placeholder="Reward(s)"
                maxMenuHeight={200}
                captureMenuScroll={false}
                openMenuOnClick={false}
                />
                {
                  (addPermission.includes("Engagement - Badges") === true) || (roleAllTrue === 1) ? 
                  <span className="pageurl" onClick={rewardPage}>+ Add more Rewards</span> : false
                }
        </div>

        <AddChallengeModal
            isOpen={showAppModal} 
            onRequestClose={() => setAppModal(false)} 
            toggle={setAppModal}
            questStatus={isAdd}
            data={items => {
                setChallenge(items)
            }}
    
            edit={challenge2}
            challengeEdit={challengeEdit}
            challengeId={challengeIndex}
            editStatus={editChallenge}
            handleCloseModal={handleCloseModal}
        />
        <SaveAndCancelButton
          cancelFn={() => {cancelQuest()}}
          saveFn={() => submitEvaluation()}
        />
      </ContentWrapper>
    </CustomContentWrapper>
  );
});

export default CreateEditQuestPage;
