import React from 'react';
import { connect, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

//helpers
import { history } from '../_core/helpers/history';
//actions and services
import evaluationActions from "../_core/actions/evaluation";
import questionnaireActions from "../_core/actions/questionnaire";
import { sectionActions } from "../_core/actions/section";

//components
import AttributesWidget from '../AttributesWidget/AttributesWidget';

//images
import clock from '../_templates/clock_icon.png';
import closeIcon from '../_templates/x_square.png';
import cover from '../_templates/img-not-found.png';
import avatar from '../_templates/man.png';

//styles
import './PerformanceFormModal.scss';

Modal.setAppElement(document.getElementById('root'));

const getStatusText = (stat) => {
    switch (stat) {
        case 'accepted':
            return 'in progress';
        case 'on going':
            return 'in progress';
        case 'finished':
            return 'finish';
        case 'rejected':
            return 'rejected';
        case 'completed':
            return 'complete';
        case 'expired':
            return 'expired';
        case 'pending':
            return 'pending';
        default:
            return '';
    }
};

const AcceptEvaluation = (props) => {
    const evaluationDetails = props?.evaluationItem;
    const dispatch = useDispatch();
    const { subjectDetails, evaluationItem, auth } = props;

    const formatSubjectDetails = () => {
        const getTenure = moment(subjectDetails[0].details[0].startDate).isValid() ? moment().diff(moment(subjectDetails[0].details[0].startDate), "years") : 0;

        const userSubj = {
            name: subjectDetails[0].details[0].firstName || "",
            cover: subjectDetails[0].details[0].cover || cover,
            avatar: subjectDetails[0].details[0].avatar || avatar,
            position_department: [(subjectDetails[0].details[0].position || ""), (subjectDetails[0].details[0].department)].join(" | "),
            startDate: moment(subjectDetails[0].details[0].startDate).isValid() ? moment(subjectDetails[0].details[0].startDate).format("MMMM DD, YYYY") : "No Date",
            tenure: getTenure > 0 ? getTenure + (getTenure > 1 ? " Years" : " Year") : 0,
            attributes: subjectDetails[0].attributes,
        }

        return userSubj;
    }

    const formatEvaluationDetails = () => {
        const data = {
            title: evaluationDetails?.title || "No Title",
            refCode: evaluationDetails?.refCode || "No Ref Code",
            period: [moment(evaluationDetails?.startedAt).utc().format("MM/DD/YYYY"),
            (moment(evaluationDetails?.expiresAt).utc().isValid() ? moment(evaluationDetails?.expiresAt).utc().format("MM/DD/YYYY") : "No Date")
            ].join(" - "),
            status: getStatusText(evaluationDetails?.status),
        }
        return data
    }

    const handleInvitationResponse = (reply) => {
        dispatch(evaluationActions.updateStatus(reply, evaluationItem._id, props.evaluationCurrentPage, props.evaluationItemsPerPage, auth?.user?._id))
    }

    return (
        <div className="performance-modal-main-content">
            <div className="performance-accept-modal-header">{/* header */}
                <div>{/* title */}
                    <p>{formatEvaluationDetails().title}</p>
                    <p>{formatEvaluationDetails().refCode}</p>
                </div>
                <div>{/* period */}
                    <img src={clock} alt="period" />
                    <p>{formatEvaluationDetails().period}</p>
                </div>
                <div>{/* status */}
                    <p>Current Status:</p>
                    <div className=""><p>{formatEvaluationDetails().status}</p></div>
                </div>
            </div>
            <div className="performance-modal-body">
                <div className="evaluation-wrapper">
                    <div className="accept-eval-content">
                        <div className="accept-eval-intro">{/* intro */}
                            <h5>Performance Evaluation</h5>
                            <p>You have been invited by
                                <span> Pragmanila HRD </span>
                                to participate on the <span>Software Engineer Self Assessment Performance Evaluation</span>
                            </p>
                            <p>Kindly answer all questions diligently.</p>
                        </div>
                        {subjectDetails.length > 0 &&
                            <div className="accept-eval-subject">{/* subject */}
                                <p>Subject</p>
                                <div>
                                    <div>{/* user details */}
                                        <img src={formatSubjectDetails().cover} alt="cover_photo" onError={(e) => e.target.src = cover} />
                                        <div className="subject-details">
                                            <div className="subj-details">
                                                <img src={formatSubjectDetails().avatar} alt="display_photo" onError={(e) => e.target.src = avatar} />
                                                <div className="is-active"></div>
                                                <div className="subj-name">
                                                    <p>{formatSubjectDetails().name}</p>
                                                    <p>{formatSubjectDetails().position_department}</p>
                                                </div>
                                            </div>
                                            <div className="subj-term">
                                                <p>Start Date: <span>{formatSubjectDetails().startDate}</span></p>
                                                <p>Tenure: <span>{formatSubjectDetails().tenure}</span></p>
                                            </div>
                                            <div className="subj-attributes">
                                                <AttributesWidget
                                                    userId={subjectDetails[0]._id}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="accept-eval-btns"> {/* footer */}
                        <button className="eval-primary-btn" onClick={() => handleInvitationResponse('accept')}>Start</button>
                        <button className="eval-secondary-btn" onClick={() => handleInvitationResponse('decline')}>Reject</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const EvaluationForm = (props) => {
    const evaluationDetails = props?.evaluationItem;
    const { evaluationQuestions, questionCategories } = props;
    const [questionNumber, setQuestionNumber] = React.useState(1);
    const singleQuestionTextCount = 400;
    const numOfQuestionItems = evaluationQuestions?.length || 0;

    const getQuestionSections = () => {
        const questions = evaluationQuestions;
        const categories = questionCategories;
        let categoryName = "";
        let questionsWithNoSections = []
        let questionsWithSections = [];
        let retVal = [];

        const c = categories?.map((category) => { //eslint-disable-line
            categoryName = category.title;
            const q = questions?.map((question, index) => {//eslint-disable-line
                if (question.section_id === category._id) {
                    questionsWithSections.push(index);
                }
                return null
            });
            if(questionsWithSections.length > 1){
                retVal.push({
                    section: categoryName,
                    questionsIndex: questionsWithSections
                });
            }
            categoryName = "";
            questionsWithSections = [];

            return null
        });

        const q = questions?.map((question, index) => {//eslint-disable-line
            if (!question.section_id || question.section_id === null) {
                questionsWithNoSections.push(index);
            }
            return null
        });

        if (questionsWithNoSections?.length > 0) {
            retVal.push({
                section: "Unspecified",
                questionsIndex: questionsWithNoSections,
            })
            questionsWithNoSections = [];
        }

        return retVal
    }

    const formatEvaluationDetails = () => {
        const data = {
            title: evaluationDetails?.title || "No Title",
            refCode: evaluationDetails?.refCode || "No Ref Code",
            period: [moment(evaluationDetails?.startedAt).utc().format("MM/DD/YYYY"),
            (moment(evaluationDetails?.expiresAt).utc().isValid() ? moment(evaluationDetails?.expiresAt).utc().format("MM/DD/YYYY") : "No Date")
            ].join(" - "),
            status: getStatusText(evaluationDetails?.status),
        }
        return data
    }

    const getRatingQuestion = (question) => {
        const formatQuestion = props.userIsSubject ? question.question : question.alt_version;
        let ratingRange = [];

        for (var i = question?.min; i <= question?.max; i++) {
            ratingRange.push(i);
        }

        return (
            <div className="eval-question-wrapper">
                <p className="eval-question">{formatQuestion}</p>
                <p className="rating-note">Set an honest rating from {question.min || 0} being the lowest, and {question.max || 0} the highest</p>
                <div className="question-rating-button">
                    {ratingRange.length > 0 && ratingRange.map((num) =>
                        <label key={num}>
                            <input className="q-radio" name="qrange" type="radio" />
                            <span>{num}</span>
                        </label>
                    )}
                </div>
            </div>
        )
    }

    const getSingleAnswerQuestion = (question) => {
        const formatQuestion = props.userIsSubject ? question.question : question.alt_version;

        return (
            <div className="eval-question-wrapper">
                <p className="eval-question">{formatQuestion}</p>
                <div className="question-single-answer">
                    <textarea />
                    <div className="question-text-count">{singleQuestionTextCount}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="performance-modal-main-content">
            <div className="performance-start-modal-header">{/* header */}
                <div>{/* title */}
                    <p>{formatEvaluationDetails().title || 
                    "Performance Evaluation"} Questions - {evaluationQuestions?.length <= 0 ? 0 : questionNumber} of {numOfQuestionItems}</p>
                </div>
                <div>{/* status */}
                    <p>Current Status:</p>
                    <div className=""><p>{formatEvaluationDetails().status}</p></div>
                </div>
            </div>
            <div className="performance-modal-body">
                <div className="evaluation-wrapper">
                    <div className="eval-questions-content">
                        <div className="eval-questions-wrapper">
                            <div className="eval-questions-list">
                                {evaluationQuestions[questionNumber - 1]?.type === "single" ?
                                    getSingleAnswerQuestion(evaluationQuestions[questionNumber - 1]) :
                                    evaluationQuestions[questionNumber - 1]?.type === "rating" ?
                                        getRatingQuestion(evaluationQuestions[questionNumber - 1]) :
                                        ""
                                }
                                {evaluationQuestions?.length <= 0 && <p>No Questions Found</p>}
                            </div>
                            <div className="eval-questions-reminder">
                                <p className="eqr-header">Here is where the content for simple reminders of policy rules:</p>
                                <p className="eqr-item">1. First item describes the first rule</p>
                                <p className="eqr-item">2. Second item to identify another point on the request policy to this request type</p>
                            </div>
                        </div>
                        <div className="eval-sections">
                            <p>Progress</p>
                            <div className="eval-questions-tracker">
                                <p>Performance Evaluation</p>
                                <div className="eval-question-sections">
                                    {getQuestionSections()?.length > 0 && getQuestionSections().map((section, index) =>
                                        <div className="eqs-item" key={index}>
                                            <p>{section.section}</p>
                                            <div>
                                                {section.questionsIndex.map((num) =>
                                                    <div key={num}></div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accept-eval-btns"> {/* footer */}
                        {questionNumber > 1 && <button className="eval-secondary-btn" onClick={() => setQuestionNumber(questionNumber > 1 ? questionNumber - 1 : 1)}>Back</button>}
                        {questionNumber < numOfQuestionItems && <button className="eval-primary-btn" onClick={() => setQuestionNumber(questionNumber < numOfQuestionItems ? questionNumber + 1 : numOfQuestionItems)}>Next</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const PerformanceFormModal = (props) => {
    const dispatch = useDispatch();
    const evaluationDetails = props?.evaluationItem;
    const shouldModifyModalWidth = useMediaQuery({ maxWidth: 1200 });

    const closeModal = React.useCallback(() => {
        history.push('/performance');
    }, []);

    React.useEffect(() => {
        // if(evaluationDetails <= 0){
        //     history.push("/performance");
        // }
        if (props.evaluationItem?.questionaireid) {
            dispatch(questionnaireActions.getById(props.evaluationItem?.questionaireid));
            dispatch(questionnaireActions.getAllQuestions(props.evaluationItem?.questionaireid, 1, 10000));
            dispatch(sectionActions.getAllSection())
        }
    }, [props.evaluationItem]); //eslint-disable-line

    return (
        <>
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={true}
                style={{
                    content: {
                        left: shouldModifyModalWidth ? 50 : 200,
                        right: shouldModifyModalWidth ? 50 : 200,
                        maxHeight: "721px",
                        maxWidth: "1055px",
                    },
                }}
            >
                {evaluationDetails._id &&
                    <div className="performance-modal-wrapper">{/* wrapper */}
                        {evaluationDetails.status !== "accepted" ?
                            <AcceptEvaluation {...props} />
                            :
                            <EvaluationForm {...props} />
                        }
                        <div className="performance-modal-close" onClick={() => closeModal()}>
                            <img src={closeIcon} alt="close" />
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    const userAuth = state?.authentication?.user || {};
    const selectedCode = history.location.pathname.replace("/performance/", "") || "";
    const evaluationItems = state?.evaluations?.peerEvaluations?.items || [];
    const filterEvaluationItem = evaluationItems?.length > 0 && selectedCode ? evaluationItems.filter((item) => item.refCode === selectedCode) : [];

    const getEvaluationItem = filterEvaluationItem?.length > 0 ? filterEvaluationItem[0]?.data?.filter((item) => item.user_id === userAuth?.user?._id) : [];
    const users = state?.users?.users?.items || [];
    const getSubjectUser = filterEvaluationItem?.length > 0 && users.length > 0 ? users.filter((user) => user._id === filterEvaluationItem[0].subject) : [];
    const checkIfUserIsSubject = getEvaluationItem.length > 0 && getEvaluationItem[0].subject === userAuth?.user?._id;

    return ({
        auth: userAuth,
        usersList: state?.users?.users?.items,
        evaluationsList: evaluationItems,
        evaluationItem: getEvaluationItem.length > 0 ? getEvaluationItem[0] : [],
        subjectDetails: getSubjectUser || [],
        evaluationCurrentPage: state?.evaluations?.peerEvaluations?.page || 1,
        evaluationItemsPerPage: state?.evaluations?.peerEvaluationsItemsPerPage || 10,
        evaluationQuestionnaire: state?.questionnaires?.dataById || [],
        evaluationQuestions: state?.questionnaires?.questions || [],
        questionCategories: state?.section?.sectionList || [],
        userIsSubject: checkIfUserIsSubject,
    })
};

export default React.memo(connect(mapStateToProps)(PerformanceFormModal));