// FirstName, LastName, MiddleName = string
// existingUsernames = array of strings;

export const UsernameCreator = (FirstName = "", LastName = "", MiddleName = "", existingUsernames = []) => {
    const usernamesList = existingUsernames

    let firstNameToArray = FirstName.trim().split(" ");
    let getFirstNameInitials = firstNameToArray.map((f_name) => f_name[0]).join('');
    let getLastname = LastName.trim().replace(" ", "");
    let getMiddleName = MiddleName ? MiddleName.trim().replace(" ", "") : "";
    let getMiddleNameInitial = getMiddleName ? getMiddleName[0] : "";

    let buildUsername = getFirstNameInitials + getMiddleNameInitial + getLastname;
    let usernameInitialValue = buildUsername.toLowerCase();

    var finalizeUsername = removeAccents(usernameInitialValue);
    var incrementedNumber = 0;

    if (usernamesList.includes(finalizeUsername)) {
        incrementedNumber = 1;
        while (usernamesList.includes(finalizeUsername + incrementedNumber)) {
            incrementedNumber++;
        }

        return finalizeUsername + incrementedNumber;
    }

    return finalizeUsername
}

const removeAccents = (value) => {

    const accentsList = {
        a: 'á|à|ã|â|À|Á|Ã|Â',
        e: 'é|è|ê|É|È|Ê',
        i: 'í|ì|î|Í|Ì|Î',
        o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        c: 'ç|Ç',
        n: 'ñ|Ñ',
    };

    let returnValue = Object.keys(accentsList).reduce((accValue, curKey) => accValue.replace(new RegExp(accentsList[curKey], 'g'), curKey), value);

    return returnValue;
}