import { attributeConstants } from '../constants/attribute';

let initialState = {
  attributes:[],
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function attributes(state = initialState, action) {
  switch (action.type) {
    case attributeConstants.CREATE_REQUEST:
      return { 
        ...state,
        creating: true
       };
    case attributeConstants.CREATE_SUCCESS:
      return { };
    case attributeConstants.CREATE_FAILURE:
      return {};

    case attributeConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case attributeConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        attributes: action.attributes,
        totalPerPage: action.attributes.total,
        perPage: action.attributes.pages,
        currentPage: action.attributes.page,
        
      };
    case attributeConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      case attributeConstants.ATTRIBUTE_DEL_SUCCESS:
        return {
          attributes: action.attributes
        };

    case attributeConstants.DELETE_REQUEST:
      // add 'deleting:true' property to attribute being deleted
      return {
        ...state,
        items: state.items.map(attribute =>
          attribute.id === action.id
            ? { ...attribute, deleting: true }
            : attribute
        )
      };
    case attributeConstants.DELETE_SUCCESS:
      // remove deleted attribute from state
      return {
        items: state.items.filter(attribute => attribute.id !== action.id)
      };
    case attributeConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to attribute 
      return {
        ...state,
        items: state.items.map(attribute => {
          if (attribute.id === action.id) {
            // make copy of attribute without 'deleting:true' property
            const { deleting, ...attributeCopy } = attribute;
            // return copy of attribute with 'deleteError:[error]' property
            return { ...attributeCopy, deleteError: action.error };
          }

          return attribute;
        })
      };

    //get attribute by id
    case attributeConstants.GET_REQUEST:
      return {
        saving: true
      };
    case attributeConstants.GET_SUCCESS:
      return {
        attribute: action.attribute
      };
    case attributeConstants.GET_FAILURE:
      return { 
        error: action.error
      };

    default:
      return state
  }
}