import { get } from '../helpers/api';
import { userService } from '../services/user';

export const sectionServices = {
    getAll,
}

const basePath = "/api/v1"

function getAll() {
    return get(`${basePath}/category/question?page=1&items-per-page=999`).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
};

