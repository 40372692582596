export default {
    GET_REQUEST: "GET_QUESTIONNAIRE_REQUEST",
    GET_SUCCESS: "GET_QUESTIONNAIRE_SUCCESS",
    GET_FAILURE: "GET_QUESTIONNAIRE_FAILURE",

    CREATE_REQUEST: "CREATE_QUESTIONNAIRE_REQUEST",
    CREATE_SUCCESS: "CREATE_QUESTIONNAIRE_SUCCESS",
    CREATE_FAILURE: "CREATE_QUESTIONNAIRE_FAILURE",

    UPDATE_REQUEST: "UPDATE_QUESTIONNAIRE_REQUEST",
    UPDATE_SUCCESS: "UPDATE_QUESTIONNAIRE_SUCCESS",
    UPDATE_FAILURE: "UPDATE_QUESTIONNAIRE_FAILURE",

    DELETE_REQUEST: "DELETE_QUESTIONNAIRE_REQUEST",
    DELETE_SUCCESS: "DELETE_QUESTIONNAIRE_SUCCESS",
    DELETE_FAILURE: "DELETE_QUESTIONNAIRE_FAILURE",

    GETBYID_REQUEST: "GETBYID_QUESTIONNAIRE_REQUEST",
    GETBYID_SUCCESS: "GETBYID_QUESTIONNAIRE_SUCCESS",
    GETBYID_FAILURE: "GETBYID_QUESTIONNAIRE_FAILURE",

    GET_QUESTION_REQUEST: "GET_QUESTIONNAIRE_QUESTION_REQUEST",
    GET_QUESTION_SUCCESS: "GET_QUESTIONNAIRE_QUESTION_SUCCESS",
    GET_QUESTION_FAILURE: "GET_QUESTIONNAIRE_QUESTION_FAILURE",
}