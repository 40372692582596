import React from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js'
import MaterialIcon from 'material-icons-react';
import './SideNav.sass';

class SideNav extends React.Component {
    componentDidMount() {
        var elem = document.querySelector(".sidenav");
        M.Sidenav.init(elem, {
            edge: "left",
            inDuration: 250,
            onCloseEnd: function (elem) {
                document.querySelector(".sidenav-overlay").style.opacity = "0";
            }
        });

        elem = document.querySelector('.collapsible.expandable');
        M.Collapsible.init(elem, {
            accordion: false
        });


    }

    //body
    render() {
        return (
            <div className="allMenu">
                <ul id="slide-out" className="sidenav collapsible expandable" onLoad={this.componentDidMount()}>
                    <li>
                        <div className="user-view" id="user-card">
                            <div className="background">
                                <img alt="admin user bg" src="/img/bg/violet.jpg" />
                            </div>
                            <Link to="/admin"><MaterialIcon icon="account_circle" size="large">account_circle</MaterialIcon> admin</Link>
                        </div>

                    </li>
                    <li><Link to="/admin" className="sub-header">Welcome Admin!</Link></li>
                    <li><div className="divider" /></li>
                    <li><Link to="/admin/users" className="waves-effect">User Management</Link></li>
                    <li><Link to="/admin/roles" className="waves-effect" >Roles</Link></li>
                    <li><Link to={"/admin/attributes/page=" + 1} className="waves-effect">Attributes</Link></li>
                    <li><Link to={"/admin/permissions"} className="waves-effect">Permissions</Link></li>
                    <li>
                        <div className="collapsible-header">Leave Management</div>
                        <ul className="collapsible-body">
                            <li><Link to="/admin/leave-requests">Leave Filings</Link></li>
                            <li><Link to="/admin/leave-type/create">Create Leave Type</Link></li>
                            <li><Link to="">Leave Types</Link></li>
                            <li><Link to="/admin/leave-credit/create">Add Leave Credits</Link></li>
                        </ul>

                    </li>
                    <li>
                        <div className="collapsible-header">Time Keeping</div>
                        <ul className="collapsible-body">
                            <li><Link to="/admin/overtime-requests">Overtime</Link></li>
                        </ul>

                    </li>
                    <li><Link to="#!" className="waves-effect">Teams</Link></li>
                    <li><Link className="sidenav-close" to="#!">Close Menu</Link></li>

                </ul>
                <nav className="navbar-fixed">
                    <div>
                        <Link to="#" data-target="slide-out" className="sidenav-trigger show-on-large"><MaterialIcon icon="menu" size="small"></MaterialIcon></Link>

                        <ul className="right">
                            <li><Link to="/admin"><MaterialIcon icon="home" size="small">Home</MaterialIcon></Link></li>
                            <li><Link to="/profile"><MaterialIcon icon="account_circle" size="small">account_circle</MaterialIcon></Link></li>
                            <li><Link to="/login">Log-Out</Link></li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}

export default SideNav;