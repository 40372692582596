import { reactionConstants } from '../constants/reaction';

export function reactions(state = {}, action) {
  switch (action.type) {
    case reactionConstants.POST_REQUEST:
        return {saving: true};
    case reactionConstants.POST_SUCCESS:
        return {};
    case reactionConstants.POST_FAILURE:
        return {error: action.error};

    case reactionConstants.GETALL_REQUEST:
        return {loading: true};
    case reactionConstants.GETALL_SUCCESS:
        return {reactions: action.reactions};
    case reactionConstants.GETALL_FAILURE:
        return {error: action.error};

    case reactionConstants.GET_REQUEST:
        return {loading: true};
    case reactionConstants.GET_SUCCESS:
        return {reaction: action.reaction};
    case reactionConstants.GET_FAILURE:
        return {error: action.error};
    
    case reactionConstants.GET_ORIGIN_REQUEST:
        return {loading: true};
    case reactionConstants.GET_ORIGIN_SUCCESS:
        return {reactions: action.reactions};
    case reactionConstants.GET_ORIGIN_FAILURE:
        return {error: action.error};

    default:
        return state;
  }
}