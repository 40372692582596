import React, { useEffect }from 'react'
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
import { checkSideNav } from '../../Components/checkSideMenu';
import { Route, Switch } from 'react-router-dom';
import { If, Else, Then, Case, Default, Switch as CaseSwitch } from 'react-if';
import { Link } from 'react-router-dom';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import Pagination from 'react-js-pagination';
import "./AttributeDark.scss";
import { attributeActions } from '../../../_core/actions/attribute'
import { history } from '../../../_core/helpers/history';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import DocumentTitle from "react-document-title";
import { PageWrapper } from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent } from "../../Components/PageWrapper/DynamicWrapper";
import CreateAttribute from './CreateAttributeDark'
import EditAttribute from './EditAttributeDark'


import emptyIcon from '../../../_templates/empty.png';

const mapStateToProps = ({ attributes }) => ({
    attributes,
});

const ListPermission = (props) => {
    const dispatch = useDispatch();
    let buttonHeaderContent = "ADD NEW ATTRIBUTES";
    

    const [totalData, setTotalData] = React.useState(0);
    const [ActivePage, setActivePage] = React.useState(0);
    const [GetTotalPerpage, setGetTotalPerpage] = React.useState(10);
    const [currentValue, setCurrentValue] = React.useState(10);

    const [update, setUpdate] =  React.useState(false);
    const cookies = new Cookies();
    const userCred = JSON.parse(localStorage.getItem('userCredentials'))
 
    let showEntries = "Show Entries ";

    let fromPage = ((ActivePage -1) * GetTotalPerpage) + 1
    let toPage =  Math.min((ActivePage - 1)* GetTotalPerpage + GetTotalPerpage, totalData)

    const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

    useEffect(() => {
        let defaultPage = 1;
        let itemPerPage = cookies.get("Attributes:"+userCred.user._id) ? cookies.get("Attributes:"+userCred.user._id) : currentValue;
        dispatch(attributeActions.getAll(defaultPage,itemPerPage));
    }, [update,dispatch])//eslint-disable-line

    useEffect(() => {
        let newItems = cookies.get("Attributes:"+userCred.user._id)
       
        if(update !== false){
           cookies.set("Attributes:"+userCred.user._id, currentValue, { path: "/", sameSite:'lax' })
            setUpdate(false)
        }else{
            setGetTotalPerpage(newItems ? newItems : currentValue);
            setUpdate(false)
        }
        setTotalData(props.attributes.totalPerPage)
        setActivePage(props.attributes.currentPage)
    }, [props,cookies,currentValue,update,userCred])

    

    const handlePageChange = (page) => {
        dispatch(attributeActions.getAll(page,GetTotalPerpage));
    }

    const handleEditAttribute = (event) => {
        const {id} = event.target;
        console.log(id);
        
        props.dispatch(attributeActions.attributeById(id));

	}

    /* ----------------Custom input type number ----------------*/
    const topArrowClicked = (e) => {
        if (currentValue < 50){
            if(cookies.get("Attributes:"+userCred.user._id) === "20" && currentValue === 20){
                setCurrentValue(prevState => prevState + 30);
                setUpdate(true)
            }else{
                setCurrentValue(prevState => prevState + 10);
                setUpdate(true)
            }
        }
    }
    const bottomArrowClicked = (e) => {
        if (currentValue > 10){
            if(cookies.get("Attributes:"+userCred.user._id) === "50" && currentValue === 50){
                setCurrentValue(prevState => prevState - 30);
                setUpdate(true)
            }else{
                setCurrentValue(prevState => prevState - 10);
                setUpdate(true)
            }
        }

    }

    const InputArrows = ({topArrowClick, bottomArrowClick}) => {

        return (
          <div className="arrows-component">
            <div className="arrows">
              <button onClick={topArrowClick}>
              <i className="fa fa-angle-up" aria-hidden="true" style={{cursor:'pointer'}}></i>
              </button>
              <button onClick={bottomArrowClick}>
                <i className="fa fa-angle-down" aria-hidden="true" style={{cursor:'pointer'}}></i>
              </button>
            </div>
          </div>
        );
      };
/* --------------------------------------------------------*/


    const deleteAttribute = (attributeID) => {
		const Toast = Swal.mixin({
			toast: true,
			width: 300,
			background: '#D4EDDA',
			position: 'center',
			showConfirmButton: false,
			timer: 1500
		});
		
		Swal.fire({
			title:  '<p class="swal-subtitle-dark"> Are you sure?</p>',
            html: "<p class='swal-subtitle-dark'>You won't be able to revert this!</p>",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#24DC98',
			cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            background: '#2E3549',
		}).then((result) => {
			if (result.value) {
					dispatch(attributeActions.attributeDelete(attributeID));
					setTimeout(() => {
						Toast.fire({
							type:'success',
							title:'<span style="color:#0A551E">Attribute has been deleted.</span>',
						});
						dispatch(attributeActions.getAll(ActivePage, GetTotalPerpage))
					}, 100);
					
				
			}
		})
    }

    const addPermission = JSON.parse(localStorage.getItem('addPermission'));
    const editPermission = JSON.parse(localStorage.getItem('editPermission'));
    const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;
    
    return (
        <BodyBackgroundColor backgroundColor='#1C2233'>
        <div className="list_attribute" >

            <div className="table-card">
                <div className="table-header">
                    {
                        (addPermission.includes("Access - Attributes") === true) || (roleAllTrue === 1) ?
                        <button className={secretDetails !== null ? "darkmode-btn-default disabled-link" : 'darkmode-btn-default' } onClick={ (e) => history.push('/admin/attributes/create')}>{ buttonHeaderContent }</button> : false
                    }
                    <div className="block">
                        <span className="darkmode-btn-default entries test">{ showEntries } </span>
                        <div className="custom-input">
                            <input type="text" className="entries-btn" name="clicks" value={GetTotalPerpage ? GetTotalPerpage : currentValue} pattern="[0-9]*" />
                            <span className="arrows">
                                <InputArrows 
                                    topArrowClick={topArrowClicked} 
                                    bottomArrowClick={bottomArrowClicked} 
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className = "responsive-table responsive-attributes">
                        <thead>
                            <tr>
                                <th> Name </th>
                                <th> Category </th>
                                <th> Type </th>
                                <th> Slug </th>
                                {(editPermission.includes("Access - Attributes") === true) || (deletePermission.includes("Access - Attributes") === true) || (roleAllTrue === 1) ?
                                    <th className="action-tbl-title"> Actions </th> : false
                                }
                            </tr>   
                        </thead>
                      
                        <tbody>
                        <If condition={props.attributes.loading === false }> 
                            <Then>

                                <If  condition={props.attributes.attributes.total === 0}>
                                    <Then>
                                        <tr>
                                            <td colSpan="12" style={{textAlign:'center'}}>
                                                <img src={emptyIcon} className="empty" alt="empty"/>
                                                <p style={{fontSize:'16px'}}>No Data Available</p>
                                                <p style={{fontSize:'14px'}}>There are currently no data available</p>
                                            </td>
                                        </tr>
                                    </Then>

                                    <Else>
                                        {props.attributes.loading === false && props.attributes.attributes.items.map((attribute)=>  (
                                            <tr className="table-td-data" key ={attribute._id}>
                                                <td>{attribute.attrName}</td>
                                                <td>{attribute.attrCategory}</td>
                                                <td>{attribute.attrType}</td>
                                                <td className="attr-slug-text">{attribute.attrSlug}</td>
                                                <td className={secretDetails !== null ? "action-tbl disabled-link" : 'action-tbl' }>
                                                    {(editPermission.includes("Access - Attributes") === true) || (roleAllTrue === 1) ? 
                                                        <Link to="#" id={attribute._id} key={attribute._id} onClick={handleEditAttribute} className="btn-action btn-edit"  data-tip={"Edit "+ attribute.attrName} place="top" data-class="tooltips"></Link> : false
                                                    }
                                                    {(deletePermission.includes("Access - Attributes") === true) || (roleAllTrue === 1) ? 
                                                        <Link to="#" onClick={() => { deleteAttribute(attribute._id) }} className="btn-action btn-del" data-tip={"Delete "+ attribute.attrName} place="top" data-class="tooltips"></Link> : false
                                                    }
                                                    <ReactTooltip />
                                                </td>
                                            </tr>
                                         ))}
                                    </Else>

                                </If>

                           
                            </Then>
                            <Else>
                                <tr className="table-td-data">
                                    <td> 
                                        <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                                            <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </td>
                                </tr>
                            </Else>
                           
                        </If>
                        </tbody>
                    </table>
                </div>
                
                <div className="table-footer">
                    
                {totalData !== 0 && <div className="dataCount"> Showing { fromPage } to { toPage  } of {  totalData } entries  </div> }
              
                    <Pagination
                        activeClass="pagination_navigtion_active"
                        itemClass="pagination_navigtion"
                        linkClass="pagination_navigtion_link"
                        
                        hideDisabled
                        hideFirstLastPages
                        prevPageText='Previous'
                        nextPageText='Next'
                        pageRangeDisplayed={10}
                        activePage={ActivePage}
                        itemsCountPerPage={GetTotalPerpage}
                        totalItemsCount={totalData}
                        onChange={(e) => handlePageChange(e)}
                    />
                </div>
            </div>
        </div>
    </BodyBackgroundColor>
    )
}


const ListPermissionPage = connect(mapStateToProps)((props) => {

    const [breadCrumbs, setBreadCrumbs] = React.useState([])

  
    const filterBreadCrumbs = (mode) => {
      console.log(mode)
      switch (mode) {
          case "list":
            setBreadCrumbs([
              {  label: "Attributes", link: "/admin/attributes?page=1" },
            ]);
            break;
          case "create":
            setBreadCrumbs([
              {  label: "Attributes", link: "/admin/attributes?page=1" },
              { label: "Create Attribute", link: "/admin/attributes/create"},
            ]);
            break;
          case "edit":
            setBreadCrumbs([
              {  label: "Attributes", link: "/admin/attributes?page=1" },
              {  label: "Edit Attribute", link: "/admin/attributes/edit" },
            ]);
            break;
            default:
            // Error page
            break;
          }
  };
  
    React.useEffect(() => {
      filterBreadCrumbs(props.mode);
    }, [props.mode]);
    

    return (
        // <div
        //     className={cc([
        //         'mainAttribute',
        //         checkSideNav()?.trim()
        //     ])}
        // >   
        //     <div> 
        //         <div className="row">
        //             <div className="col s6 mainTitle">Access Management</div>
        //             <div className="col s6 sub-title-main">Attributes</div>
        //         </div>
        //      </div>
        //    <ListPermission {...props}/>

        // </div>
        <BodyBackgroundColor backgroundColor='#1C2233'>
            <PageWrapper className={cc(["RolesPage", checkSideNav()?.trim()])}>
                <DocumentTitle
                    title={
                        props.mode === "create"
                            ? `Create Attributes | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                            : props.mode === "edit"
                                ? `Edit Attributes | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                                : `Access | Attributes | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                    }
                />
                <DynamicHeaderContent
                    TitlePage="Access"
                    breadCrumbs={true}
                    breadCrumbsPages={breadCrumbs}
                />
                <CaseSwitch>
                    <Case condition={['create'].includes(props.mode)}><CreateAttribute {...props} /></Case>
                    <Case condition={['edit'].includes(props.mode)}><EditAttribute {...props} /></Case>
                    <Default><ListPermission {...props}/></Default>
                </CaseSwitch>
            </PageWrapper>
        </BodyBackgroundColor>
    )
});
ListPermissionPage.WrappedComponent.displayName = 'ListPermissionPage';
const ListPermissionPageRoute = (props) => (
	<Switch>
        <Route
			exact
			path={`${props.match.path}/create`}
			render={(props) => <ListPermissionPage {...props} mode="create" />}
		/>
        <Route
			exact
			path={`${props.match.path}/edit`}
			render={(props) => <ListPermissionPage {...props} mode="edit" />}
		/>
        <Route
			exact
			path={`${props.match.path}`}
			render={(props) => <ListPermissionPage {...props} mode="list" />}
		/>
	</Switch>
);
export default React.memo(ListPermissionPageRoute);