import React from "react";
import Modal from 'react-modal';
import moment from 'moment';

import closeIcon from '../_templates/x_square.png';

import { challengeService } from '../_core/services/challenge';
import './ChallengeWidget.sass'


const ChallengeCompleted = (props) => {
    const [challenge, setChallenge] = React.useState([]);


    React.useEffect(()=>{
        try{
            setTimeout(() => {
                challengeService.getOne(props.challengeId).then(res=>{
                    setChallenge(res.success[0]);
                }).catch(error=>{
                    console.log(error)
                });
            }, 100)

        }catch(err){
            console.log(err);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.challengeId]);



    const cancel = () => {
        props.handleCloseModal(false)
    }
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        toggle={props.toggle}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
        centered
        className="ChallengeUpdate challenge-verify"
      >
        <div className="row center create-challenge-container"
            style={{
                width: "100%",
                height: "auto",
                margin: "auto",
                padding: "20px 0px 20px",
                borderBottom: "1px solid #979797"
            }}>
            
            <p className="usermodal_title_update center"> Details Completion</p>
            <button className="close-modal" onClick={cancel}>
                <img height={20} src={closeIcon} alt="" style={{ marginRight: 7}}/>
			</button>
        </div>

        <div className="request-details-container">
            <div className="div-image" style={{marginBottom:'50px'}}>
                <img height={274} width={370.94} src={challenge.finishImages} alt="" style={{ objectFit:'contain' }}/>
            </div>
            <div className="div1" style={{width:'299px',margin:'auto', marginBottom:'50px'}}>
                {challenge.userDetails?.map((details,index) => (
                    <div className="div1-title" key={index}>
                        <span style={{fontWeight:'700', textTransform:'capitalize'}}>{details.firstName} {details.lastName}, </span>Completed the Challenge! <span style={{fontWeight:'700', textTransform:'capitalize'}}> {challenge.title}</span>
                    </div>
                ))}
                
               
            </div>
            <div className="footer-container">
                <div style={{textAlign:'center', margin:'25px'}}>
                    <span>Period from</span> <span style={{fontWeight:'700', textTransform:'capitalize'}}>{ moment(challenge.createdAt).format('L')} - { moment(challenge.expiresAt).format('L')}</span>
                </div>
            </div>

        </div>


      </Modal>
    </>
  );
};

export default ChallengeCompleted;