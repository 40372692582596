
import React from "react";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import bold from '../../../_templates/editor-icons/bold.png';
import italic from '../../../_templates/editor-icons/italic.png';
import underline from '../../../_templates/editor-icons/underline.png';
import strike from '../../../_templates/editor-icons/strike.png';
import superscript from '../../../_templates/editor-icons/superscript.png';
import subscript from '../../../_templates/editor-icons/subscript.png';
import unordered from '../../../_templates/editor-icons/unordered.png';
import ordered from '../../../_templates/editor-icons/ordered.png';
import indent from '../../../_templates/editor-icons/indent.png';
import outdent from '../../../_templates/editor-icons/outdent.png';
import left from '../../../_templates/editor-icons/left.png';
import center from '../../../_templates/editor-icons/center.png';
import right from '../../../_templates/editor-icons/right.png';
import justify from '../../../_templates/editor-icons/justify.png';

import styled from "styled-components";

const EditorWrapper = styled.div`
    width: 100%;
    margin: 0; 
    margin-bottom: 20px;

    .editor-wrapper {
        padding: 15px 15px;
        background-color: #2E3648;
        border: 1px solid #323A4F;
        border-radius: 3px;

        &.is-error {
            border: 1px solid #F14646;
        }
    }

    .editor-toolbar {
        padding: 0;
        background-color: transparent;
        border: none;

        width: 100%;
        max-width: 640px;

        >div>div { //buttons
            background-color: #323A4F;
            border: 1px solid #323A4F;
            color: #9BA4C7;

            img {
                filter: invert(0.7);
            }

            >a {
                color: #9BA4C7;

                >div.rdw-dropdown-carettoopen{
                    border-top: 6px solid #9BA4C7;
                }

                >div.rdw-dropdown-carettoclose{
                    border-bottom: 6px solid #9BA4C7;
                }
            }
        }

        .rdw-dropdown-wrapper {
            height: 20px;
            border-radius: 2px;
            background-color: #323A4F;
            font-size: 14px;
        }

        .rdw-option-wrapper {
            backgorund-color: #323A4F;
            border-radius: 2px;
            border: 1px solid #323A4F;
            width: 20px!important;
            height: 20px!important;
            padding: 0;

            &[title="Monospace"] {
                display: none;
            }

            &[title="Bold"] {
                >img {
                    display: none;
                }
                background: url(${bold}) no-repeat center;
                background-size: cover;
            }

            &[title="Italic"] {
                >img {
                    display: none;
                }
                background: url(${italic}) no-repeat center;
                background-size: cover;
            }

            &[title="Underline"] {
                >img {
                    display: none;
                }
                background: url(${underline}) no-repeat center;
                background-size: cover;
            }
            
            &[title="Strikethrough"] {
                >img {
                    display: none;
                }
                background: url(${strike}) no-repeat center;
                background-size: cover;
            }

            &[title="Superscript"] {
                >img {
                    display: none;
                }
                background: url(${superscript}) no-repeat center;
                background-size: cover;
            }

            &[title="Subscript"] {
                >img {
                    display: none;
                }
                background: url(${subscript}) no-repeat center;
                background-size: cover;
            }

            &[title="Unordered"] {
                >img {
                    display: none;
                }
                background: url(${unordered}) no-repeat center;
                background-size: cover;
            }

            &[title="Ordered"] {
                >img {
                    display: none;
                }
                background: url(${ordered}) no-repeat center;
                background-size: cover;
            }

            &[title="Indent"] {
                >img {
                    display: none;
                }
                background: url(${indent}) no-repeat center;
                background-size: cover;
            }

            &[title="Outdent"] {
                >img {
                    display: none;
                }
                background: url(${outdent}) no-repeat center;
                background-size: cover;
            }

            &[title="Left"] {
                >img {
                    display: none;
                }
                background: url(${left}) no-repeat center;
                background-size: cover;
            }

            &[title="Center"] {
                >img {
                    display: none;
                }
                background: url(${center}) no-repeat center;
                background-size: cover;
            }

            &[title="Right"] {
                >img {
                    display: none;
                }
                background: url(${right}) no-repeat center;
                background-size: cover;
            }

            &[title="Justify"] {
                >img {
                    display: none;
                }
                background: url(${justify}) no-repeat center;
                background-size: cover;
            }
        }
    }

    .editor-input {
        height: auto;
        min-height: 200px;
        padding: 15px 15px;
        background-color: #2A3142;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;

        border: 1px solid #323A4F;
        border-radius: 5px;
    }

    .rdw-editor-main pre { //code 
        background-color: #323A4F;
    }

    >small {
        font-size: 10px;
        font-weight: 400;
        color: #F14646;
        margin: 0 15px;
    }
    
`;

const EditorComponent = (props) => {
    const [editorState, setEditorState] = React.useState(() => {
        return EditorState.createEmpty();
    });

    const [ isAcceptingInitialValue, setAcceptingInitialValue ] = React.useState(true); //for editing content, accept data once to avoid data re-entering.

    const returnValue = React.useCallback(() => {
        props.returnContent(editorState); //passing editor content to parent

    }, [editorState, props]);

    React.useEffect(() => {
        const isEditorStateEmpty = !editorState.getCurrentContent().hasText();
        const { initialValue } = props;

        if(initialValue && isEditorStateEmpty && isAcceptingInitialValue){
            checkPrevContent(initialValue);
            setAcceptingInitialValue(false);
        }
        
        returnValue();
       
    }, [props.initialValue, editorState, setEditorState]); // eslint-disable-line

    const checkPrevContent = (content) => {
        const val = content;
        
        const validValue = () => {
            try {
                JSON.parse(val)
            } catch (e) {
                return false
            }

            return true
        }

        if (validValue() && JSON.parse(val)?.blocks) {
            const edittedVal = EditorState.createWithContent(convertFromRaw(JSON.parse(val)));
            setEditorState(()=>edittedVal);
        } else if (val){
            const toHtml = convertFromHTML(val);
            const contentState = ContentState.createFromBlockArray(toHtml);
            const edittedVal = EditorState.createWithContent(contentState);
            setEditorState(()=>edittedVal);
        }
    }

    return (
        <EditorWrapper>
            <Editor
                {...{ editorState }}
                name="editor"
                onEditorStateChange={setEditorState}
                toolbar={{
                    options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'fontFamily',
                        'list',
                        'textAlign',
                    ]
                }}
                wrapperClassName={props.isError ? "editor-wrapper is-error" : "editor-wrapper"}
                toolbarClassName="editor-toolbar"
                editorClassName="editor-input"
            />
            {props.isError && <small>Please fill-up this field.</small>}
        </EditorWrapper>
    );
}

export default EditorComponent;