import { notifConstants } from '../constants/notification';

export function notification(state = {}, action){
    switch(action.type){
        case notifConstants.POST_NOTIF_REQUEST:
            return {saving: true};
        case notifConstants.POST_NOTIF_SUCCESS:
            return {};
        case notifConstants.POST_NOTIF_FAILURE:
            return {error: action.error};
        default:
          return state
    }
}