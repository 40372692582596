import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import Select from "react-select";
import { useParams } from 'react-router-dom';
import { leaveCreditActions } from '../../../_core/actions/leaveCredit';
import { ContentWrapper} from "../../Components/PageWrapper/ContentWrapper";
import moment from 'moment';
import Pagination from 'react-js-pagination';

import { userActions } from "../../../_core/actions/user";

import Swal from 'sweetalert2';
import "react-datepicker/dist/react-datepicker.css";
import "./LeaveCreditsDark.scss";
import avatar from '../../../_templates/man.png';

import ReactImageFallback from "react-image-fallback";

import { leaveCreditService } from '../../../_core/services/leaveCredit';

import {DynamicContentHeaderWrapper  } from "../../Components/PageWrapper/DynamicWrapper";

import { leaveHours } from '../../../_core/helpers/config'

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;
export const SearchIconFindUser = styled.img`
  margin-bottom: -30px;
  margin-left: 15px;
  display: flex;
  position: relative;
  z-index: 1;
  height: 15px;
  width: 15px;
  object-fit: contain;
`;

export const FindUserWrapper = styled.div`
  > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    margin-left: 15px;
    color: #f14646 !important ;
    position: initial !important;
  }
`;

export const GridContainer = styled.div`
  ${(props) =>
    props.isShowUserDetails === false
      ? css`
          margin-top: 15px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;
        
        `
      : css`
          display: block;
        `}

  > div > label.label__questionnaires, > div > label.label__tenures {
    display: flex;
    float: left;
    top: 10px;
    position: relative;
    z-index: 1;
    margin-left: 25px;
    color: #9ba2ce;
  }

  > div > label.Error__message {
    margin-left: 10px;
    
  }

  > div > label.label__questionnaires {
    background: #2a3143;
    width: 102px;
    margin-top: 5px;
   
  }
  > div > label.label__tenures {
    background: #2a3143;
    width: 40px;
    margin-top: 5px;
  }

  > div > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646 !important ;
    position: initial !important;
  }

  > div > div.error_date {
    border: 1px solid #f14646;
    border-radius: 3px;
  }
`;

const ImgWrapper = styled.div`
 > img {
   height: 50px;
   width: 50px;
   border-radius: 50%;
   object-fit: cover;
 }
`;

const AdminLeaveCreadits = styled.div`
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4), &:last-child {
        text-align: inherit;

		@media (max-width: 993px){
			text-align: right;
		} 
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: inherit;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const TableWrapper = styled.div`
  // overflow-x: auto; // Responsive
`;
const CustomTable = styled.table`
  color: #9ba3ca;
  margin-top: 15px;
  > thead > tr > th {
    font-size: 16px;
    font-weight: 500;
  }

  > tbody > tr > td,
  > thead > tr > th {
    padding: 10px;
  }
  > tbody > tr> td {
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
  }
  > tbody > tr,
  > thead > tr {
    border-top: 1px solid #2e3549 !important;
    border-bottom: 1px solid #2e3549 !important;
  }
  > tbody > tr:hover {
    background-color: #2e3549 !important;
  }

  > thead > tr > th:last-child {
    text-align: center
  }

  @media (max-width: 993px) {
    > tbody > tr > td {
      width: 100%!important;
    }
  }
`;

const PaginationWrapper = styled.div`
  color: #9ba2ce;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  > ul.pagination {
    width: auto !important;
  }

  > ul.pagination > li {
    border: 1px solid #323A4F !important ;
    color: #9ba3ca;
  }
  > ul > li.pagination_navigation_active {
    border: 1px solid #323a4f ;
    color: #9ba3ca;
  }
  > ul > li > a.pagination_navigation_link {
    color: #9ba3ca;
  }
  > ul > li.pagination_navigation_active {
    background-color: #556ee6;
  }

  > ul > li.pagination_navigation_active a {
    color: #ffffff !important;
  }

  >div:nth-child(1) {
    font-size: 14px;
    font-weight: 500;
  }

  @media (max-width: 800px){
    justify-content: center;
    align-items: center;
    flex-direction: column;

    
    > div > div.dataCount {
      display: block;
      margin-bottom: 20px;
    }
  }
`;


const mapStateToProps = ({ evaluations, questionnaires, tenures, users,leaveCredits }) => ({
  evaluations,
  questionnaires,
  tenures,
  users,
  leaveCredits
});

const ManageCredits = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();
  const { pageId } = useParams();
  const [update, setUpdate] = useState(false); // eslint-disable-line
  const [UserDetails, setUserDetails] = useState({})
  const [Credits, setCredits] = useState([]);
  const [entriesValues, setentriesValues] = React.useState(); //showEntries
  
  const [types, setTypes] = React.useState([{
    user_id: '',
    leave_type: '',
    value: '',
    status: '',
  }]);
  //pagination
  const [totalData, setTotalData] = React.useState(0);
  const [ActivePage, setActivePage] = React.useState(0);
  const [GetTotalPerpage, setGetTotalPerpage] = React.useState(10); // eslint-disable-line

    //showEntries
    const entriesValue = (entries) => {
        setentriesValues(entries);
    }

  let tableHeader = ["Leave Class", "Accrue Date", "Update At", "Expiration", "Status", "Actions"];

  useEffect(() => {
    let isMounted = true; // note mutable flag
    if (pageId) {
       if (isMounted) 
       setUpdate(false); // add conditional check
       dispatch(userActions.getUser(pageId));
    }
    return () => {
       isMounted = false
    }; // cleanup toggles value, if unmounted
 }, [dispatch,pageId]); // adjust dependencies to your needs

  useEffect(() => {
    if (pageId) {
         //setIsAdd(false);
        let userList = props.users?.user?.user[0].details ?? [];
        if(userList){
            userList.map((item) => {
                setUserDetails(item);
                return item;
            });
        }

    }
    //eslint-disable-next-line
  }, [props, pageId]);

  
  React.useEffect(() => {
	try {
		leaveCreditService.getUser(pageId).then(res => {
			if (res) {
				setCredits(res.user[0].leave_types)
			}
		}).catch((err) => { console.log('get leave credits error occured') })
	} catch (err) {
		console.log(err);
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


  React.useEffect(() => {
    let categories = []
    Credits.forEach((film) => {
        if(film.name){
            film.details.forEach((data)=>{
                    let array = {
                        '_id': data._id,
                        'user_id':data.user_id,
                        "name": film.name,
                        "status":data.status,
                        "createdAt": data.createdAt,
                        "updatedAt": data.updatedAt,
                        "expiredAt": data.expiredAt
                    }
                    categories.push(array);

            })
        }
        return null
    })
    setTypes(categories)
}, [Credits])

const creditsArray = Credits.map((key) => {
    return (
        <span className="manage_creditsI"> {key.name} : {key.available} hours ({key.available / leaveHours.time} days)</span>
    )
})

const handleChange = (index,selected) => {

    let newArr = types.map((item, i) => {
        if (index === i) {
          return { ...item, status: selected.value };
        } else {
          return item;
        }
      });
   setTypes(newArr)
   setUpdate(true)

}

const switchStatus =(param)=>{
    switch(param){
      case 'true':
        return "Available";
      case 'false':
        return "Used";
      default:
        return null
    }
  }

  const statusOptions = [
    { value: 'true', label: 'Available'},
    { value: 'false', label: 'Used'},
];

  React.useEffect(() => {
    setTotalData(types?.length ?? 0);
    setActivePage(1 ?? 0);
    setGetTotalPerpage(10);
  }, [types])

  
  const handlePageChange =(page) => {
    setActivePage(page)
  }

  const customStyle = {
    control: (base, state) => ({
        ...base,
        background: "#2e3648",
        height:'30px',
        width:'112px',
        borderRadius: '5px',
        borderColor: state.isFocused ? "#6A7287" : "#6A7287",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {

          borderColor: state.isFocused ? "#556ee6" : "#556ee6",
        },
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible"
    }),
    
    option: (base, state) => ({
        ...base,
        color: '#9BA3CA',
        top:'100%',
        backgroundColor: state.isSelected ? '#2A3143' : '#2A3143',
        ':hover': {
          backgroundColor: state.isSelected ? '#2A3143' : '#6A7287'
        }
      }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    indicatorSeparator: state => ({
        display: 'none',
        top: 'revert',
    }),
    input: (provided, state) => ({
        ...provided,
        height: 35,
        color: '#9BA3CA',
        lineHeight: state.isFocused ? 35 : 0,
        transition:  "top 0.1s, font-size 0.1s"
    }),
    singleValue: (provided) => ({
        ...provided,
        color: ' #9BA3CA',
        fontSize:"14px",
        fontWeight:'normal',
        top:'35%',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        marginTop: '-20px',
        color: '#9BA3CA',
      }),

      placeholder: (base) => {
        return {
            ...base,
            top:'inherit',
            color: '#9BA3CA',
        }
    }
  }
  const handleSubmit = (e,_id) => {
    e.preventDefault()

      const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        background: '#D4EDDA',
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });
          

    if(update){
        types.map((item, i) => {
            if (_id === item._id) {
              return Swal.fire({
                title: '<p class="swal-subtitle-dark">Are you sure?</p>',
                html:
                    "<br> <b class='swal-subtitle-dark'>Change Status: "
                    + switchStatus(item.status) + "</b>",
    
      
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#24DC98',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Done!',
                background: '#2E3549',
              }).then((result) => {
                  if (result.value) {
                    let body = {
                        "_id":item._id,
                        "user_id": item.user_id,
                        "leave_type": item.leave_type,
                        "status": item.status
                    }
    
                    dispatch(leaveCreditActions.editCreditStat(body))                 
                setTimeout(() => {
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#0A551E">Leave Credit Status Updated!</span>'
                    })
                    dispatch(leaveCreditActions.getAll())   
                }, 2000)    
                      
                  }
              })

            } else {
              return item;
            }
          });

    }
   

  };

    // Logic for displaying todos
    const indexOfLastTodo = ActivePage * entriesValues;
    const indexOfFirstTodo = indexOfLastTodo - entriesValues;
    const currentTodos = types.sort((a, b) => { return (a.updatedAt > b.updatedAt) ? -1 : 1 }).slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos =  currentTodos.map((item, index) => {
        return <tr key={index}>
                    <td>{item.name}</td>
                    <td>{moment.utc(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>{moment.utc(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>{moment.utc(item.expiredAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={statusOptions}
                            defaultValue={statusOptions.filter(({value}) => value === item.status)}
                            styles={customStyle} 
                            onChange={(data) => handleChange(index,data)}
                            isSearchable={false}
                            openMenuOnClick={false}
                            isClearable={false}
                            maxMenuHeight={220}
                            menuPlacement="bottom"
                            menuPortalTarget={document.getElementById("root")}
                         />
                    </td>
                    <td><button id="review" className="btn updatebtn" onClick={ (e) => handleSubmit(e,item._id)}>UPDATE</button></td>   
               </tr>;
    });
  

    let fromPage = ((ActivePage -1) * entriesValues) + 1
    let toPage =  Math.min((ActivePage - 1)* entriesValues + entriesValues, totalData)


  return (
    <CustomContentWrapper>
        <ContentWrapper>
            <div class="row">
                <div class="col s1">
                    <ImgWrapper>
                        <ReactImageFallback
                            src={`${UserDetails.avatar}`}
                            fallbackImage={avatar}
                            initialImage={avatar}
                            alt="user avatar"
                        />
                    </ImgWrapper>
                </div>
                <div class="col s2">
                    <div className="manage_name"><span>{UserDetails.firstName + " " + UserDetails.lastName}</span></div>
                    <div className="manage_position"><span>{UserDetails.position}</span></div>
                </div>
                <div class="col s9">
                    <div className="manage_titleC">Leave</div>
                    <div className="manage_credits">{creditsArray}</div>
                </div>
            </div>
        
        </ContentWrapper>

        <ContentWrapper>
            <AdminLeaveCreadits>

                <DynamicContentHeaderWrapper
                originPage="ManageCredits"
                buttonLabel="ADD LEAVE CREDITS"
                btnPath={`/admin/leave-credits-overview/${pageId}/create`}
                entriesValue={entriesValue}
                addPage="Leaves - User Leave Overview"
                />
                <TableWrapper>
                    <CustomTable className="responsive-table">
                    <thead>
                        <tr>
                        {tableHeader.map((HeaderItem, index) => (
                            <th key={index}>{HeaderItem}</th>
                        ))}
                        </tr>
                    </thead>
                    <tbody>{renderTodos}</tbody>

                    </CustomTable>
                </TableWrapper>

                
                <PaginationWrapper>
                    <div>
                        {totalData !== 0 && <div className="dataCount"> Showing {fromPage} to {toPage} of {totalData} entries  </div>}
                    </div>
                <Pagination
                    activeClass="pagination_navigation_active"
                    itemClass="pagination_navigation"
                    linkClass="pagination_navigation_link"

                    hideDisabled
                    hideFirstLastPages
                    prevPageText='Previous'
                    nextPageText='Next'
                    pageRangeDisplayed={10}
                    activePage={ActivePage}
                    itemsCountPerPage={entriesValues}
                    totalItemsCount={totalData}
                    onChange={(e) => handlePageChange(e)}
                    />
                            
                </PaginationWrapper>

            </AdminLeaveCreadits>
        </ContentWrapper>

    </CustomContentWrapper>
    
  );
});

export default ManageCredits;
