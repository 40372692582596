import { hrConcernConstants } from '../constants/hrConcern';

let initialState = {
  hrConcerns: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}


export function hrConcerns(state = initialState, action){
    switch(action.type){
        
        //POST COE
        case hrConcernConstants.SUBMIT_HRCONCERN_REQUEST:
          return {saving: true};
        case hrConcernConstants.SUBMIT_HRCONCERN_SUCCESS:
          return {};
        case hrConcernConstants.SUBMIT_HRCONCERN_FAILURE:
          return {error: action.error};
        //GETALL
        case hrConcernConstants.GETALL_HRCONCERN_REQUEST:
          return { 
            ...state,
            loading: true 
          };
        case hrConcernConstants.GETALL_HRCONCERN_SUCCESS:
          return { 
            ...state,
            loading: false,
            error: null,
            hrConcerns: action.hrConcerns,
            totalPerPage: action.hrConcerns.total,
            perPage: action.hrConcerns.pages,
            currentPage: action.hrConcerns.page,
          };
        case hrConcernConstants.GETALL_HRCONCERN_FAILURE:
          return {
            error: action.error
          };
        //GET USER
        case hrConcernConstants.GETUSER_REQUEST:
          return {
            loading: true
          };
        case hrConcernConstants.GETUSER_SUCCESS:
          return {
            hrConcerns: action.hrConcerns
          }
        case hrConcernConstants.GETUSER_FAILURE:
          return {
            error: action.error
          };
          
        default:
          return state

    }
} 

// let initialState = {
//   isInvalid: null
// }

// export function hrConcerns( state = initialState, action) {
//   switch(action.type) {
//       case hrConcernConstants.SUBMIT_HRCONCERN_REQUEST:
//         return{
//           ...state,
//           isInvalid: null 
//         };
//       case hrConcernConstants.SUBMIT_HRCONCERN_SUCCESS:
//         return {
//           ...state,
//           isInvalid: true
//         };
//       case hrConcernConstants.SUBMIT_HRCONCERN_FAILURE:
//         return {
//           ...state,
//           isInvalid: false
//         }
//         default:
//           return state
//   }
// }