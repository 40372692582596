import React from 'react';
import sad from '../../_templates/sad.png';
import './error.sass'

class Errorhandler extends React.Component {

	render() {
		return (
	      		<div className="col s12">
	      			<img className="error-sad" src={sad} alt="error"/>
	      			<h1> OOPS!</h1>
	      			<h3>Something Went Wrong</h3>
	      		</div>
		);
	}
}

export default Errorhandler;