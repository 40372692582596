//npm packages
import React from 'react';
import { connect } from 'react-redux';
import { ChallengeWidget } from '../ChallengeWidget/ChallengeWidget';
import { QuestWidget } from '../QuestWidget/QuestWidget';

const LandingPage =  connect()((props) => {
    //const dispatch = useDispatch();
    const url = window.location.href.slice(window.location.href.lastIndexOf("/"));
    console.log(url);
    return (
        <div className={url !== "/challenges" ? "challengeDiv": ""}>
            {url === "/engage" || url === "/challenges" ?
                <ChallengeWidget/> 
                : <QuestWidget/>
            }
            
            
        
        </div>

    )
})

export {LandingPage}