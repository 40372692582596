import React from 'react';
import { If, Then, Else } from 'react-if';
import { Link , useParams}  from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDispatch, connect } from "react-redux";
import useEffectWithPrevious from 'use-effect-with-previous';

import Avatar from '../Components/Avatar/Avatar';

import teamActions from '../_core/actions/teams';
import teamServices from '../_core/services/teams';
import { userService } from '../_core/services/user';

import './TeamWidget.scss';

const avatarSize = 65;
const border = 'black solid 5px';

const TeamWidget = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(true);
    const [selectedTeam, setSelectedTeam] = React.useState({});
    //const [members, setMembers] = React.useState([]);
    const [userTeam, setUserTeam] = React.useState([]);
    const [selectedUserTeam, setSelectedUserTeam] = React.useState([]);
    const [ teamActive, setTeamActive] = React.useState(0);
    const [userDetails, setUserDetails] = React.useState([]);

    const  id  = useParams();
    const [selectedId, setselectedId] = React.useState(id && Object.keys(id).length !== 0 ? id.user: '');

    

    React.useEffect(() => {
        dispatch(teamActions.getAll());
    }, [dispatch]);
    
    const teamDetails = (e, post) => {
        e.preventDefault();
        setSelectedUserTeam(post)
    };

    React.useEffect(() => {
        if (!selectedTeam._id && !id.user && !selectedId) {
            return;
        }

        // const fetchUsers = async () => {
        //     setIsLoading(true);
        //     setMembers((await teamServices.getUsersByTeamId(selectedTeam._id)).items);
        //     setIsLoading(false);
        // };

        const fetchUserTeam= async () => {
            setIsLoading(true);
            setUserTeam((await teamServices.getUserTeam(selectedId)).items);
            setIsLoading(false);
        };
        
        const fetchDetails= async () => {
            setIsLoading(true);
            setUserDetails((await userService.getUserById(selectedId)).user);
            setIsLoading(false);
        };

        //fetchUsers();
        fetchUserTeam();
        fetchDetails();
    }, [selectedTeam, id, selectedId, setIsLoading,setUserTeam,setUserDetails]);


    useEffectWithPrevious(([previousTeams]) => {
        if (!props.userId && !selectedUserTeam) {
            return;
        }

        //setselectedId(Object.getOwnPropertyNames(id).length === 0 ? props.userId : id.user)
        setselectedId(props.userId)
        setSelectedUserTeam(userTeam.filter((t,index) => index === teamActive)[0]??{})

    }, [setselectedId, setSelectedTeam, userTeam,props.userId]);


    const [devs, setDevs] = React.useState([]);

    React.useEffect(() => {

        if (!selectedUserTeam) {
            return;
        }
            
        const f = async () => {
            let initialState = [];  

                for(let i = 0; i < selectedUserTeam.users?.length; i++) {
                    let data = await userService.getUserMention(selectedUserTeam.users[i]);
                    initialState.push(data);
                }
            setDevs([initialState]);
                 
        };
        f();
    }, [selectedUserTeam, userTeam, teamActive, setDevs]);

    const membersArray = devs[0]?.map((key) => {
        return (
            key.user.map((dataItem) => {
                return (
                    dataItem.details.map((info,index)=> {
                        return (
                            
                        <Link key={info.user_id} to={`/profile/${info.user_id}`} onClick={() => setselectedId(info.user_id)}>
                            <Avatar
                               {...{ border }}
                                size={avatarSize}
                                src={info.avatar}
                            />
                        </Link>
                        )
                    })
                )
            })
        
        )
    })

    return (
        <div className="team widget">
            <div className="header">
                <If condition={props.loading}>
                    <Then>
                        <SkeletonTheme color="rgb(225, 225, 225)" highlightColor="rgb(240, 240, 240)">
                            <Skeleton width={150} height={17} />
                        </SkeletonTheme>
                    </Then>
                    <Else>
                        {userDetails.map((item,i) => 
                            <h6 key={i}>{item.details[0].firstName}'s Teams</h6>
                        )}
                    </Else>
                </If>
                <div style={{ height: 40 }} />
            </div>
            <div className="body card z-depth-0">
                <If condition={userTeam && userTeam.length > 0}>
                    <Then>
                        <div className="team">
                            <If condition={props.loading}>
                                <Then>
                                    <div>
                                        <Skeleton
                                            circle
                                            width={65}
                                            height={65}
                                        />
                                        <Skeleton
                                            circle
                                            width={65}
                                            height={65}
                                        />
                                        <Skeleton
                                            circle
                                            width={65}
                                            height={65}
                                        />
                                        <Skeleton
                                            circle
                                            width={65}
                                            height={65}
                                        />
                                    </div>
                                </Then>
                                <Else>
                                    <div>
                                        {/* {
                                            props.teams.items.map(team => (
                                                <Link key={team._id} to="#!" onClick={(e) => { e.preventDefault(); setSelectedTeam(team) }}>
                                                    <If condition={selectedTeam._id === team._id}>
                                                        <Then>
                                                            <div style={{ height: avatarSize, width: avatarSize, position: 'relative' }}>
                                                                <div className="active">
                                                                    <Avatar
                                                                        {...{ border }}
                                                                        size={avatarSize + 25}
                                                                        src={team.logo}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Then>
                                                        <Else>
                                                            <Avatar
                                                                {...{ border }}
                                                                size={avatarSize}
                                                                src={team.logo}
                                                            />
                                                        </Else>
                                                    </If>
                                                </Link>
                                            ))
                                        } */}

                                        {
                                            userTeam.map((team,index) => (
                                                <Link key={team._id} to="#!" onClick={ (e)=> {teamDetails(e, team); setTeamActive(index)} }>
                                                    <If condition={selectedUserTeam._id === team._id}>
                                                        <Then>
                                                            <div style={{ height: avatarSize, width: avatarSize, position: 'relative' }}>
                                                                <div className="active">
                                                                    <Avatar
                                                                        {...{ border }}
                                                                        size={avatarSize + 25}
                                                                        src={team.logo}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Then>
                                                        <Else>
                                                            <Avatar
                                                                {...{ border }}
                                                                size={avatarSize}
                                                                src={team.logo}
                                                            />
                                                        </Else>
                                                    </If>
                                                </Link>
                                               
                                            ))
                                        }
                                    </div>
                                </Else>
                            </If>
                        </div>
                        <div className="members">
                            <If condition={props.loading || isLoading}>
                                <Then>
                                    <Skeleton width={150} height={17} />
                                </Then>
                                <Else>
                                    <h6>{selectedUserTeam.name}</h6>
                                </Else>
                            </If>
                            <div>
                                <If condition={props.loading || isLoading}>
                                    <Then>
                                        <Skeleton
                                            circle
                                            width={65}
                                            height={65}
                                        />
                                        <Skeleton
                                            circle
                                            width={65}
                                            height={65}
                                        />
                                        <Skeleton
                                            circle
                                            width={65}
                                            height={65}
                                        />
                                        <Skeleton
                                            circle
                                            width={65}
                                            height={65}
                                        />
                                    </Then>
                                    <Else>
                                        <If condition={userTeam.length > 0}>
                                            <Then>
                                                {membersArray}
                                            </Then>
                                            <Else>
                                                <p className="info">This team has no members</p>
                                            </Else>
                                        </If>
                                    </Else>
                                </If>
                            </div>
                            <Link to="/people/teams" className="link_team" >View All Teams</Link>
                        </div>
                    </Then>
                    <Else>
                        <p className="info">User has no team</p>
                    </Else>
                </If>
            </div>
        </div>
    );
};

const mapStateToProps = ({ teams, users }) => ({
    loading: !!(teams.loading || users.loading),
    teams,
    users
});

export default connect(mapStateToProps)(React.memo(TeamWidget));