import {  post, get, del, put } from '../helpers/api'
import { userService } from '../services/user';

export const leaveTypeService = {
    getAll,
    addLeaveType,
    delLeaveType,
    getLeaveByID,
    updateLeaveType,
    getAllTypes
};

function getAllTypes(page, itemPerPage) {
    return get(`/api/v1/leave-type?page=${page || 1}&items-per-page=${itemPerPage || 20}`).then(handleResponse);
}

function getAll(page = 1, items = 999999) {
    return get(`/api/v1/leave-type?page=${page}&items-per-page=${items}`).then(handleResponse);
}

function addLeaveType(leaveTypes){
    return post('/api/v1/leave-type', JSON.stringify(leaveTypes))
        .then(handleResponse)
}

function delLeaveType(id){
    return del(`/api/v1/leave-type/${id}`)
}

function getLeaveByID(id){
    return get(`/api/v1/leave-type/${id}`).then(handleResponse)
}

function updateLeaveType(id, leavetype){
    // let id = leavetype._id
    return put(`/api/v1/leave-type/${id}`, JSON.stringify(leavetype))
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}