export const leaveConstants = {
    GETALL_REQUEST: 'LEAVE_GETALL_REQUEST',
    GETALL_SUCCESS: 'LEAVE_GETALL_SUCCESS',
    GETALL_FAILURE: 'LEAVE_GETALL_FAILURE',

    GET_REQUEST: 'LEAVES_GET_REQUEST',
    GET_SUCCESS: 'LEAVES_GET_SUCCESS',
    GET_FAILURE: 'LEAVES_GET_FAILURE',

    GETUSER_REQUEST: 'LEAVES_GETUSER_REQUEST',
    GETUSER_SUCCESS: 'LEAVES_GETUSER_SUCCESS',
    GETUSER_FAILURE: 'LEAVES_GETUSER_FAILURE',

    UPDATE_REQUEST: 'LEAVES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'LEAVES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'LEAVES_UPDATE_FAILURE',

    LEAVE_REQUEST: 'LEAVES_APPROVE_REQUEST',
    LEAVE_SUCCESS: 'LEAVES_APPROVE_SUCCESS',
    LEAVE_FAILURE: 'LEAVES_APPROVE_FAILURE',

    HOLD_REQUEST: 'LEAVES_HOLD_REQUEST',
    HOLD_SUCCESS: 'LEAVES_HOLD_SUCCESS',
    HOLD_FAILURE: 'LEAVES_HOLD_FAILURE',

    DECLINE_REQUEST: 'LEAVES_REJECT_REQUEST',
    DECLINE_SUCCESS: 'LEAVES_REJECT_SUCCESS',
    DECLINE_FAILURE: 'LEAVES_REJECT_FAILURE',

    FILELEAVE_REQUEST: 'LEAVES_POST_REQUEST',
    FILELEAVE_SUCCESS: 'LEAVES_POST_SUCCESS',
    FILELEAVE_FAILURE: 'LEAVES_POST_FAILURE',
};