import React, { useState }  from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
// import sweetAlert from 'sweetalert2';
import {useDropzone} from 'react-dropzone';
import { badgesActions } from '../../../_core/actions/badges';
// import { When } from 'react-if'; 
import { history } from "../../../_core/helpers/history";


import {
  ContentWrapper,
  LabelCustom,
  SaveAndCancelButton,
} from "../../Components/PageWrapper/ContentWrapper";
import { DragDropImage,} from "../../Components/ImageUploader/updatedUpload";
import { CustomInputTextBadges, CustomTextAreaBadges} from "../../Components/FloatingTextBox/TextArea";
import "./Badges.scss";

import "react-datepicker/dist/react-datepicker.css";

import { useParams } from "react-router-dom";


export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

const mapStateToProps = ({posts }) => ({
  posts,
});

const CreateBadgePage = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    // const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    const [remove, setRemove] = useState(false);
    const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
    const [InputError, setInputError] = useState(false);// eslint-disable-line
    let [characterCount, setcharacterCount] = React.useState(0);
    
    const { pageId } = useParams();

    const [inputValues, setInputValue] = React.useState({
        badgeName: "",
        badgeContent: "",
        badgeImage: "",
    });

    React.useEffect(() => {
        if (pageId) {
            setIsAdd(false); // Set update
            dispatch(badgesActions.getContent(pageId)); // call selected challege id
        }
        //eslint-disable-next-line
    }, [dispatch, pageId]);

    console.log(remove)

      React.useEffect(() => {

        // prepopulate selected challenge to edit
        if (pageId) {
          let selectedData =  props.badges.items ?? [];
            if(selectedData) {
                

                Array.from(selectedData).forEach((item) => {
                    setInputValue({
                        badgeName: item.badgeName,
                        badgeContent: item.badgeContent,
                        badgeImage: item.badgeImage
                    });
                    setcharacterCount(item.badgeContent.length)
              })
            }
        }
        //eslint-disable-next-line
      }, [props.badges.items, pageId, dispatch]);
      

    function handleChange(event) {
        const { name, value } = event.target;

        setInputValue({ ...inputValues, [name]: value });

        if(name === "badgeContent"){
            setcharacterCount(value.length)
        }
    }

    // const isBadgeNameStateEmpty  = typeof str === 'string' ? inputValues.badgeName.trim() : '';
    // const isbadgeDescriptionStateEmpty= typeof str === 'string' ? inputValues.badgeContent.trim() : '';

    // const isBadgeNameStateEmpty = React.useMemo(() => !inputValues.badgeName.trim(), [inputValues]);
    // const isbadgeDescriptionStateEmpty = React.useMemo(() => !inputValues.badgeDescription.trim(), [inputValues]);

    const {getRootProps, getInputProps, open} = useDropzone({
    accept: 'image/x-png, image/png, image/jpg, image/jpeg, image/gif',
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
        })));
        setInputValue({
            ...inputValues,
            badgeImage:acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
                }))[0],
          });
    }
    });

    const clearInput = () => {
        setInputValue({
            badgeName: "",
            badgeContent: "",
            badgeImage: "",
        })

        
        // setIsSubmitted(false);
        setFiles([]);
    }


    const cancelEvaluation = () => {
        history.goBack();
        setInputValue({
            badgeName: "",
            badgeContent: "",
            badgeImage: "",
        })
        // setIsSubmitted(false);
        setFiles([]);
    }

    // const submit = React.useCallback(
        // async (event) => {
        // event.preventDefault();
        // setIsSubmitted(true);
        
        const submit =  () => {
            const badgeNameSlug = inputValues.badgeName;
            const res = badgeNameSlug.replace(/\s+/g,'').toLowerCase();

            if(!inputValues.badgeContent || !inputValues.badgeName){
                setInputError(true);
            }else {
                if(isAdd){
                    let createBadge = {
                        badgeName: inputValues.badgeName,
                        badgeSlug: res,
                        badgeImage: inputValues.badgeImage !== null ? inputValues.badgeImage : null,
                        badgeContent: inputValues.badgeContent,
                        badgeType: "",
                        badgeCategory: ""
                    }
                
                    dispatch(badgesActions.create(createBadge));
                    setInputError(false);
                    history.goBack()  
                    clearInput();  
                }else {

                    let badgeId = pageId;

                    let updateBadge = {
                        badgeName: inputValues.badgeName,
                        badgeSlug: res,
                        badgeImage: inputValues.badgeImage !== null ? inputValues.badgeImage : null,
                        badgeContent: inputValues.badgeContent,
                        badgeType: "",
                        badgeCategory: ""
                    }
                
                    dispatch(badgesActions.updateBadge(badgeId, updateBadge));
                    setInputError(false);
                    history.goBack()    
                }
            }
        }
            

    //     }
    //     if(isBadgeNameStateEmpty === false  && isbadgeDescriptionStateEmpty === false){
    //         await submit();
    //         await clearInput();
    //         await cancelEvaluation();
    //     }
    // },
    // [
    //     dispatch,
    //     isBadgeNameStateEmpty,
    //     isbadgeDescriptionStateEmpty,
    //     inputValues,
    //     setIsSubmitted,
    //     isAdd,
    //     files
    // ]
// );

  const removeSelectedImage = () => {
        setFiles([]);
        setInputValue({ ...inputValues, badgeImage: null});
        setRemove(true);
    }   

  return (
    <CustomContentWrapper>
      <ContentWrapper>
        <LabelCustom className="main-label">
            {isAdd ? "Create" : "Edit"} a Badge
                {/* <When condition={isSubmitted}> */}
                {/* <When condition={isSubmitted && (isBadgeNameStateEmpty ||  isbadgeDescriptionStateEmpty)}>
                    <span className="helper-text red-text" style={{ marginLeft: 10, fontWeight: 200 }}>
                        Content is required
                    </span>
                </When> */}
        </LabelCustom>


        <CustomInputTextBadges
            label={"Badge Name"}
            name="badgeName"
            value={inputValues.badgeName}
            inputOnChange={(e) => handleChange(e)}
        />
        <div style={{position:"relative"}}>
            <CustomTextAreaBadges
                label={"Description"}
                name="badgeContent"
                value={inputValues.badgeContent}
                inputOnChange={(e) => handleChange(e)}
                maxlength="250"
            />
            <LabelCustom className="maxChar" style={{position:"absolute", right:"10px", bottom:"-15px" }}>
            {`${characterCount}`}
            {/* <p className="max-character-description">{`${characterCount}`} characters entered out of 800</p> */}
            </LabelCustom>
        </div>

            <span htmlFor="feature_image" className="featured">
                Badge Image
            </span>
            <div {...getRootProps({className: 'dropzone'})}>
                <div className={files[0] || inputValues.badgeImage? 'active-dz': 'unactive-dz'} >
                        <input {...getInputProps()} />
                    <div>
                        <p className="dropTitle">Drag and Drop</p>
                        <p className="dropTitle">Badge Image here</p>
                        <p className="dropTitle">or</p>
                    </div>
                    <button type="button" onClick={open} className="custom-file-upload-badge">
                        Browse
                    </button>
                </div>
                <DragDropImage
                    removeImage={removeSelectedImage} 
                    selectedImage={files} 
                    existingImage={inputValues.badgeImage} 
                    remove={remove}
                    isAdd={isAdd}
                />
            </div>

            <div style={{marginBottom:"20px"}}></div>
            <SaveAndCancelButton
                cancelFn={() => cancelEvaluation()}
                saveFn={() => submit()}
                submitLabel="SAVE"
            />
      </ContentWrapper>

    </CustomContentWrapper>
  );
});



export default CreateBadgePage;
