import { rewardConstants } from '../constants/reward';
import { rewardService } from '../services/reward';
import { alertActions } from './alert';
import sweetAlert from 'sweetalert2';
import Swal from 'sweetalert2';

export const rewardActions = {
    getAll,
    postUserReward,
    getOne,
    redeemReward,
    getAllByUser,
    create,
    deleteRewards,
    getAllFilter
};

function deleteRewards(id){
    return dispatch => {
        dispatch(request(id))
        
        rewardService.deleteRewards(id)
            .then(
                reward => {
                    dispatch(success(reward.success))
                },
                error => dispatch(failure(error.toString()))
            )

    }
    function request(id) { return { type: rewardConstants.DELETE_REQUEST, id } }
    function success(reward) { return { type: rewardConstants.DELETE_SUCCESS, reward } }
    function failure(error) { return { type: rewardConstants.DELETE_FAILURE, error } }
}

function getAll(itemsPerPage, page, search, filter) {
    return dispatch => {
        dispatch(request());

        rewardService.getAll(itemsPerPage, page, search, filter)
            .then(
                reward => dispatch(success(reward)),
                error => dispatch(failure(error.toString())
            ));
    };

    function request() { return { type: rewardConstants.GETALL_REQUEST } }
    function success(reward) { return { type: rewardConstants.GETALL_SUCCESS, reward } }
    function failure(error) { return { type: rewardConstants.GETALL_FAILURE, error } }
}

function getAllFilter(itemsPerPage, page) {
    return dispatch => {
        dispatch(request());

        rewardService.getAllFilter(itemsPerPage, page)
            .then(
                reward => dispatch(success(reward)),
                error => dispatch(failure(error.toString())
            ));
    };

    function request() { return { type: rewardConstants.GETALL_REQUEST } }
    function success(reward) { return { type: rewardConstants.GETALL_SUCCESS, reward } }
    function failure(error) { return { type: rewardConstants.GETALL_FAILURE, error } }
}

function postUserReward(userId, rewardId, reward) {

    return dispatch => {
        dispatch(request());

        const Toast = sweetAlert.mixin({
            toast: true,
            width: 300,
            position: 'center',
            showConfirmButton: false,
            timer: 1500
        });

        rewardService.postUserReward(userId, rewardId, reward)
            .then(

                reward => { 
                    dispatch(success(rewardId.success));
                    dispatch(alertActions.success('Reward successfully redeemed.'));
                    setTimeout(function(){window.location.reload(1);}, 2000);
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#0A551E">Reward successfully redeemed.</span>'
                    })
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Reward redeemed failed'));
                    Error.fire({
                        type: 'error',
                        title: '<span style="color:#990000">Reward redeemed failed</span>'
                    })
                }
            );
    };

    function request() { return { type: rewardConstants.POST_REQUEST } }
    function success(reward) { return { type: rewardConstants.POST_SUCCESS, reward } }
    function failure(error) { return { type: rewardConstants.POST_FAILURE, error } }
}

function getOne(userId, rewardId) {
    return dispatch => {
        dispatch(request());

        rewardService.getOne(userId, rewardId)
            .then(
                reward => dispatch(success(reward)),
                error => dispatch(failure(error.toString())
            ));
    };

    function request() { return { type: rewardConstants.GETONE_REQUEST } }
    function success(reward) { return { type: rewardConstants.GETONE_SUCCESS, reward } }
    function failure(error) { return { type: rewardConstants.GETONE_FAILURE, error } }
}


function redeemReward(rewardId) {
    //const roleId = role._id
    return dispatch => {
        dispatch(request());
        rewardService.redeemReward(rewardId)
            .then(
                reward => dispatch(success(reward)),
                error => dispatch(failure(error.toString())
            ));
    };

    function request(reward) { return { type: rewardConstants.UPDATE_REWARD_REQUEST, reward } }
    function success(reward) { return { type: rewardConstants.UPDATE_REWARD_SUCCESS, reward } }
    function failure(error) { return { type: rewardConstants.UPDATE_REWARD_FAILURE, error } }
}

function getAllByUser(userId, page, itemsPerPage) {
    return dispatch => {
        dispatch(request());

        rewardService.getAllByUser(userId, page, itemsPerPage)
            .then(
                userRewards => dispatch(success(userRewards)),
                error => dispatch(failure(error.toString())
            ));
    };

    function request() { return { type: rewardConstants.GETBYUSER_REQUEST } }
    function success(userRewards) { return { type: rewardConstants.GETBYUSER_SUCCESS, userRewards } }
    function failure(error) { return { type: rewardConstants.GETBYUSER_FAILURE, error } }
}


function create(reward) {

    const ToastSuccess = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });

    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
    const fd = new FormData();
    const imageFile = reward.image;
    fd.append('file',imageFile)
    
    //checking if challenge images has a content file
    if(isFile(reward.image) === true){
        reward.image = null;
    }


    function failure(error) { return { type: rewardConstants.POST_FAILURE, error } }
    function success(reward) { return { type: rewardConstants.POST_SUCCESS, reward } }

    return async dispatch => {
        let createdPost;
        let imageData;
        let isError = null;

        try {
            createdPost = await rewardService.create(reward);
        } catch(error) {
            isError = error.toString();
            dispatch(failure(error.toString()));
            Toast.fire({
                type: 'error',
                title: `
                    <span>
                        Something went wrong11111
                    </span>
                `
            });

            return;
        }

        try {
            if( imageFile ){
                imageData = await rewardService.imageUpload(createdPost._id, fd)
            }
        } catch(error) {
            isError = error;
            Toast.fire({
                type: 'error',
                title: `
                    <span>
                        Something went wrong22222
                    </span>
                `
            });

            return;
        }



        dispatch(success({
            ...reward,
            image: imageData?.image ?? null,
            _id: createdPost._id
        }));

        if(isError === null){
            await ToastSuccess.fire({
                type: 'success',
                title: '<span style="color:green">Reward has been created! </span>'
            })
        }
    };
}