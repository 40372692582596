import { settingsConstants } from '../constants/settings';

let initialState = {
  items: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function settings( state = initialState, action) {
  switch (action.type) {
    case settingsConstants.CREATE_REQUEST:
      return { creating: true };
    case settingsConstants.CREATE_SUCCESS:
      return {};
    case settingsConstants.CREATE_FAILURE:
      return {};
    case settingsConstants.GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
    case settingsConstants.GETALL_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          items: action.settings.items,
          totalPerPage:  action.settings.total,
          perPage: action.settings.pages,
          currentPage: action.settings.page,
        };
    case settingsConstants.GETALL_FAILURE:
        return { 
          error: action.error
        };
    case settingsConstants.GETONE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        success: false
      };
    case settingsConstants.GETONE_SUCCESS:
      return {
        ...state,
        ...action.setting.success,
        loading: false,
      };
    case settingsConstants.GETONE_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };		
	  case settingsConstants.UPDATE_REQUEST:
		  return {
			  loading: true
		  };
	  case settingsConstants.UPDATE_SUCCESS:
		  return {
			  success: true
		  };
	  case settingsConstants.UPDATE_FAILURE:
		  return {
			  error: action.error
		  };		
    default:
    return state
  }
}