import { ESSConstants } from "../constants/ess";

const intitialState = {
    getAll: {
        data: [],
        loading: false,
        error: ""
    },
    getById: {
        data: {},
        loading: false,
        error: ""
    },
    getAllByUser: {
        data: [],
        loading: false,
        error: ""
    },
    create: {
        data: {},
        error: ""
    },
    update: {
        data: {},
        error: ""
    },
    getActivitiesByUser: {
        data: [],
        loading: false,
        error: ""
    }
}

export default function (state = intitialState, action) {
    switch (action.type) {
        case ESSConstants.GETALL_REQUEST:
            return {
                ...state,
                getAll: {
                    data: state.getAll.data,
                    loading: true,
                    error: ""
                }
            };
        case ESSConstants.GETALL_SUCCESS:
            return {
                ...state,
                getAll: {
                    data: action.response,
                    loading: false,
                    error: ""
                }
            };
        case ESSConstants.GETALL_FAILURE:
            return {
                ...state,
                getAll: {
                    loading: false,
                    error: action.error
                }
            };

        case ESSConstants.GETBYUSER_REQUEST:
            return {
                ...state,
                getAllByUser: {
                    data: state.getAllByUser.data,
                    loading: true,
                    error: ""
                }
            };
        case ESSConstants.GETBYUSER_SUCCESS:
            return {
                ...state,
                getAllByUser: {
                    data: action.response,
                    loading: false,
                    error: ""
                }
            };
        case ESSConstants.GETBYUSER_FAILURE:
            return {
                ...state,
                getAllByUser: {
                    loading: false,
                    error: action.error
                }
            };

        case ESSConstants.GETBYID_REQUEST:
            return {
                ...state,
                getById: {
                    data: {},
                    loading: true,
                    error: ""
                }
            };
        case ESSConstants.GETBYID_SUCCESS:
            return {
                ...state,
                getById: {
                    data: action.response,
                    loading: false,
                    error: ""
                }
            };
        case ESSConstants.GETBYID_FAILURE:
            return {
                ...state,
                getById: {
                    loading: false,
                    error: action.error
                }
            };

        case ESSConstants.POST_REQUEST:
            return {
                ...state,
                create: {
                    data: {},
                    error: ""
                }
            };
        case ESSConstants.POST_SUCCESS:
            return {
                ...state,
                create: {
                    data: action.response,
                    error: ""
                }
            };
        case ESSConstants.POST_FAILURE:
            return {
                ...state,
                create: {
                    data: {},
                    error: action.error
                }
            };

        case ESSConstants.UPDATE_REQUEST:
            return {
                ...state,
                update: {
                    data: {},
                    error: ""
                }
            };
        case ESSConstants.UPDATE_SUCCESS:
            return {
                ...state,
                update: {
                    data: action.response,
                    error: ""
                }
            };
        case ESSConstants.UPDATE_FAILURE:
            return {
                ...state,
                update: {
                    data: {},
                    error: action.error
                }
            };

        case ESSConstants.GETBYUSER_ACTIVITIES_REQUEST:
            return {
                ...state,
                getActivitiesByUser: {
                    data: state.getActivitiesByUser.data,
                    loading: true,
                    error: ""
                }
            };
        case ESSConstants.GETBYUSER_ACTIVITIES_SUCCESS:
            return {
                ...state,
                getActivitiesByUser: {
                    data: action.activities.ess[0].history,
                    loading: false,
                    error: ""
                }
            };
        case ESSConstants.GETBYUSER_ACTIVITIES_FAILURE:
            return {
                ...state,
                getActivitiesByUser: {
                    loading: false,
                    error: action.error
                }
            };

        default:
            return state
    }
}