import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'react-modal';

import warningSign from '../../_templates/timesheet-alert.png';
import redInfoDot from '../../_templates/info-red-dot.png';
import greenInfoDot from '../../_templates/info-green-dot.png';
import closeIcon from '../../_templates/x_square.png';

import holiday from '../../holiday.js' ;
import { renderedTime } from './renderedTime';

import Swal from 'sweetalert2';

import './DailyTimesheet.scss';




const DailyTimesheet = (props) =>{
    const timesheet = props?.timesheet;
    const isWeekly = props?.isWeekly;
    let days = isWeekly ? props?.setWeek || [] : props?.setMonth || [];
    var retVal = [];

    const [modalOpen, setmodalOpen] = React.useState(false)
    const [ClockDetails, setClockDetails] = React.useState([])
    const [isHoliday, setisHoliday] = useState(undefined)
    const [holidayName, setHolidayName] = React.useState(false)
   

    const showModalDetails = (data) => {

        setmodalOpen(modalOpen === true ? false : true)
        setClockDetails(data);
        holiday.items.find(holidayData => {
            if(moment(holidayData.date).format("YYYY-MM-DD") === moment(data.date).format("YYYY-MM-DD")){
                setHolidayName(holidayData.name);
            }
            return null
        })
    }
    
  
    const closeModal = (e) => {
        e.preventDefault();
        setmodalOpen(modalOpen === true ? false : true)
    }

    React.useEffect(() => {
        setisHoliday(holiday.items.find(isHolidayValid => moment(isHolidayValid.date).format("YYYY-MM-DD") === moment(ClockDetails.date).format("YYYY-MM-DD")))
    
    }, [ClockDetails])

    React.useEffect(() => {
        days = !isWeekly && props.setMonth; // eslint-disable-line 
    }, [props.setMonth, timesheet]) 

    const notAvailable = () => {
        Swal.fire({
            title: 'This feature is not available at the moment',
            
            type: 'warning',
            showCancelButton: true,
            showConfirmButton: false,
            confirmButtonColor: '#26a69a',
            cancelButtonColor: '#d33',
            cancelButtonText: 'OK'
        })
    }
 
    const getModalWidth = () => {
        var modalWidth = {
            content : {
                maxWidth: "420px",
                maxHeight: isHoliday === undefined ? "480px" : "540px",
                paddingRight: "40px",
                paddingBottom: "20px",
                paddingTop:  "20px",
                paddingLeft:"40px",
            }
        }
        return modalWidth;
    }

    //get days, exclude saturday and sunday
    if(isWeekly) {
        const weekDays = days?.filter((day, index)=> index > 0 && index < 6);
        if(timesheet && weekDays){
            retVal = renderedTime(timesheet, weekDays);
        }
    } else {
        if(timesheet && days){
            retVal = renderedTime(timesheet, days);
        }
    }
   
   
    return(
        <div className="timesheet-daily">
            <Modal
                isOpen={modalOpen}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                onRequestClose={(e) => closeModal(e)}
                style={getModalWidth()}
            >
                <div className="modal-clock">
                    <div className="modal-clock-details-header">
                        <div className = "modal-clock-details-title">
                            Clock Details 
                        </div>
                        <div className="modal-clock-details-close">
                            <button className="close-modal" onClick={(e) => closeModal(e)}>
                                <img src={closeIcon} alt="close"/>
                            </button>
                        </div>
                    </div>

                    <div className="modal-clock-content">
                            
                        {ClockDetails.date && isHoliday !== undefined &&
                           <div className="modal-clock-event modal-clock-bg-holiday">
                           <div className = "modal-clock-label-event"> {holidayName} </div>
                         </div>  
                         
                         }
                     
                        <div className = "modal-clock-label"> Day </div>
                        <div className="modal-clock-label-content">{ ClockDetails?.date === undefined ? "-" :  ClockDetails?.date && moment(ClockDetails?.date).format('ddd MMMM D, YYYY')   }</div>
                        
                        <div className = "modal-clock-label"> Time In </div>
                        <div className="modal-clock-label-content"> {ClockDetails.pragIn === "" ? "-" :  moment(ClockDetails.pragIn).format("hh:mm A") } </div>
                        
                        <div className = "modal-clock-label"> Time Out </div>
                        <div className="modal-clock-label-content"> { ClockDetails.pragOut === "" ? "-" : moment(ClockDetails.pragOut).format("hh:mm A") } </div>
                        
                        <div className = "modal-clock-label"> Total Hours </div>
                         
                    
                        
                       
                        <div className="modal-clock-label-content" > 
                        {  ClockDetails.totalHours === "" ? null : <img className="clock-indicator" alt="clock_ind" src={ !!ClockDetails.totalHours === true && parseInt(ClockDetails?.totalHours.substring(0, 2)) >= 9 ? greenInfoDot: redInfoDot } />}
                        &nbsp;
                        { ClockDetails.totalHours  === "" ? "-" : ClockDetails.totalHours } </div>
                       
                        <div className = "modal-clock-label"> UT/OT </div>
                        <div className="modal-clock-label-content"> { ClockDetails.overtime === "" ? "-" : ClockDetails.overtime && ClockDetails.overtime.replace(/-/g, "")} </div>
           
                    </div>

                    <div className="modal-footer">
                      <button className="modal-clock-details-custom-btn" onClick={(e) => closeModal(e)} style={{ cursor: "pointer"}}> Close </button>
                    </div>
                </div>
            
            
            </Modal> 
           
            {timesheet && retVal &&
        
            <table className="timesheet-tbl">
                <thead>
                    <tr>
                        <th>Day</th>
                        <th>Time in</th>
                        <th>Time out</th>
                        <th>Total hours</th>
                        <th>UT/OT</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {retVal.map((clock, index)=> {
                        
                          // <ClockInVal clock={clock} key={index}  />
                          const weekday = moment(moment(clock.date).format("YYYY-MM-DD")).format('dddd'); // Monday ... Sunday
                          const isWeekend = weekday === 'Sunday' || weekday === 'Saturday';
                          let isHoliday= holiday.items.find(isHolidayValid =>  moment(isHolidayValid.date).format("YYYY-MM-DD") === moment(clock.date).format("YYYY-MM-DD") )
                          let isBefore = moment(moment(clock.date).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD"), 'days')
                          return (
                            <tr className="timesheet-data"  key={index}>
                                <td  onClick={(e) => showModalDetails(clock) } style={{ cursor: "pointer"}}>{moment(clock.date).format('ddd MMMM D, YYYY')}</td>
                                <td  onClick={(e) => showModalDetails(clock) } style={{ cursor: "pointer"}}>{clock.pragIn && moment(clock.pragIn).format('hh:mm A')}</td>
                                <td  onClick={(e) => showModalDetails(clock) } style={{ cursor: "pointer"}}>{clock.pragOut && moment(clock.pragOut).format('hh:mm A')}</td>
                                <td  onClick={(e) => showModalDetails(clock) } style={{ cursor: "pointer"}}>
                                    {clock.overtime &&
                                    <img className="clock-indicator" alt="clock_ind" src={parseInt(clock?.totalHours.substring(0, 2)) >= 9  ?  greenInfoDot   : redInfoDot} />
                                    }
                                    {isBefore && (!clock.pragIn || !clock.pragOut)  ? 
                                    !isWeekend  ? // No warning sign for Saturday and sunday 
                                        isHoliday !== undefined ? '' // If holiday no warning sign
                                            :  <img className="warning-sign" alt="warning_sign" src={warningSign} /> // Show warning sign if current day has no holiday or not weekend
                                        : ''    
                                    : 
                                    ""}
                                    {clock.totalHours}
                                </td>
                                <td  onClick={(e) => showModalDetails(clock) } style={{ cursor: "pointer"}}>
                                    {clock.overtime && clock.overtime.replace(/-/g, "")}
                                </td>
                                <td>
                                    <button onClick={notAvailable} className="custom-btn" >Edit Time</button>
                                </td>
                            </tr>
                          )	
                    })}
                </tbody>
            </table>
            }
        </div>
    )
}

export default React.memo(DailyTimesheet);