import React from 'react';

export var assignPage = {
    page: 1,
    goto: ''
}
//--------------------------------------------> PAGINATION <-----------------------------------------------------------
export const setPagination = (totalpages, currentpage) => {
    var totalPages = totalpages;
    var curPage = currentpage;
    var pages = [];
    var a = 1;

    for (var i = 1; i <= totalPages; i++) {
        var setPage = i
        if (setPage === assignPage.page || setPage === curPage) {
            pages.push(<li key={i} className={assignPage.page === setPage ? "selected" : "btn-page"} id="zero" onClick={(e) => checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{setPage}</span></li>);
        } else {
            if (a <= 4) {
                if (setPage === assignPage.page - 1 || setPage === assignPage.page + 1) {
                    pages.push(<li key={i} className={assignPage.page === setPage ? "selected" : "btn-page"} id="zero" onClick={(e) => checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{setPage}</span></li>);
                    a++
                } else if (setPage === assignPage.page - 2 || setPage === assignPage.page + 2) {
                    pages.push(<li key={i} className={assignPage.page === setPage ? "selected" : "btn-page"} id="zero" onClick={(e) => checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{setPage}</span></li>);
                    a++
                } else if (assignPage.page === 1 || assignPage.page === 2) {
                    pages.push(<li key={i} className={assignPage.page === setPage ? "selected" : "btn-page"} id="zero" onClick={(e) => checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{setPage}</span></li>);
                    a++
                } else if (setPage === totalPages - 3 || setPage === totalPages - 4) {
                    pages.push(<li key={i} className={assignPage.page === setPage ? "selected" : "btn-page"} id="zero" onClick={(e) => checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{setPage}</span></li>);
                    a++
                }
            }
        }
    }

    return (
        <div className="pages">
            {curPage > totalPages ?
            <div></div>
            :
            <ul className="pagination">
                {curPage === 1 ? ""
                    : <span>
                        <li className={curPage === 1 ? "btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="Go to first page" id="zero" onClick={(e) => firstPage(e)}><i className="tiny material-icons arrow-icons" id="zero" >first_page</i><span className="arrow-texts">First</span></li>
                        <li className={curPage === 1 ? "btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="prev" id="zero" onClick={(e) => prevPage(e)}><i className="tiny material-icons arrow-icons" id="zero">chevron_left</i></li>
                    </span>
                }
                {pages}
                {curPage === totalPages ? ""
                    : <span>
                        <li className={curPage === totalPages ? " btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="next" id="zero" onClick={(e) => nextPage(e, totalPages)}><i className="tiny material-icons arrow-icons" id="zero">chevron_right</i></li>
                        <li className={curPage === totalPages ? " btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="Go to last page" id="zero" onClick={(e) => lastPage(e, totalPages)}><span className="arrow-texts">Last</span><i className="tiny material-icons arrow-icons" id="zero">last_page</i></li>
                    </span>
                }
                <span>
                    <li className="goText">Go to Page</li>
                    <input className="pageNum" type="number" id="zero" min="1" max={totalPages} placeholder={curPage ? curPage : ''} onChange={(e) => getPage(e)} />
                    <li className="pageGo text-page" id="zero" onClick={(e) => goToPage(e, totalPages, curPage)}>Go</li>
                </span>
            </ul>
            }
        </div>
    );
}

const checkPage = (e, totalpages, currentpage) =>{
    e.preventDefault(); 
    var totalPages = totalpages;
    var curPage = currentpage;
    var getpage = e.target.textContent;

    if (Number(getpage) === curPage) {
        return
    } else if (getpage >= totalPages) {
        assignPage.page = totalPages
    } else {
        assignPage.page = Number(getpage)
    }
}

const nextPage = (e, pages) => {
    e.preventDefault();
    var totalPages = pages;
    var setPage = Number(assignPage.page) + 1;

    if (setPage >= totalPages) {
        assignPage.page = totalPages
    } else if (assignPage.page) {
        assignPage.page = setPage
    }
}

const prevPage = (e) => {
    e.preventDefault();
    var setPage = Number(assignPage.page) - 1
    if (setPage <= 1) {
        assignPage.page =  1
    } else if (assignPage.page) {
        assignPage.page = setPage
    }
}

const firstPage = (e) => {
    e.preventDefault();
    assignPage.page = 1
}

const lastPage = (e, pages) => {
    e.preventDefault();
    var totalPages = pages
    assignPage.page = totalPages
}

const getPage = (e) => {
    var setPage = e.target.value;
    assignPage.goto = setPage
}

const goToPage = (e, pages, pageNo) => {
    e.preventDefault();
    var setPage = assignPage.goto;
    var curPage = pageNo;
    var totalPages = pages;

    if (setPage === "" || setPage === null || Number(setPage) === curPage) {
        // assignPage.page = assignPage.page
        assignPage.goto = ''
    } else if (setPage >= totalPages) {
        assignPage.page = totalPages
        assignPage.goto = ''
    } else if (setPage <= 1) {
        assignPage.page = 1
        assignPage.goto = ''
    } else {
        assignPage.page = Number(setPage)
        assignPage.goto = ''
    }
}
//--------------------------------------------> END PAGINATION <-----------------------------------------------------------
