import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import MainNav from '../Components/MainNav';
import {MissionsList} from './MissionsList.js'
import { questActions } from '../_core/actions/quest';
import BackgroundImage from 'react-background-image-loader';
class QuestPage extends React.Component {
    componentDidMount() {
		let questId = JSON.parse(localStorage.getItem('quest'));
       	this.props.dispatch(questActions.getById(questId));
    }

    render() {
    	// console.log(this.props)
    	const { quests } = this.props;
    	const localImage = 'https://via.placeholder.com/350x150';
        return (
       		<div className= "quest-container">
       			<MainNav/>
				{quests.quest && 
				<div className="container">
				<BackgroundImage src={quests.quest[0].questImage} placeholder={localImage} className="quest-banner-img card quest-info">
						<h4 className="quest-title-index center">{quests.quest[0].questTitle}</h4>
					 	
						<div className="quest-content">
							<p className="center">{quests.quest[0].questContent}</p>
						</div>

						<div className="row quest-content-info">
					     	<div className="col s4">
					      		<div className="quest-row">
					        		<p className="quest-p">Type:</p>
					        		<p className="quest-p-info">{quests.quest[0].questType}</p>
					        	</div>
					      	</div>
					      	<div className="col s4">
					        	<div className="quest-row">
					        		<p className="quest-p">Category:</p>
					        		<p className="quest-p-info">{quests.quest[0].questCat}</p>
					        	</div>
					      	</div>
					      	<div className="col s4">
					       		<div className="quest-row">
					        		<p className="quest-p">Difficulty:</p>
					        		<p className="quest-p-info">{quests.quest[0].difficulty}</p>
					        	</div>
					      	</div>
					      	<div className="col s4">
					       		<div className="quest-row">
					        		<p className="quest-p">Frequency:</p>
					        		<p className="quest-p-info">{quests.quest[0].frequency}</p>
					        	</div>
					      	</div>
					      	<div className="col s4">
					       		<div className="quest-row">
					        		<p className="quest-p">Reward Type:</p>
					        		<p className="quest-p-info">{quests.quest[0].rewardType}</p>
					        	</div>
					      	</div>
					      	<div className="col s4">
					       		<div className="quest-row">
					        		<p className="quest-p">Reward Value:</p>
					        		<p className="quest-p-info">{quests.quest[0].rewardValue}</p>
					        	</div>
					      	</div>
					    </div>	
					 </BackgroundImage>

					<div className="card">
						<MissionsList/>
					</div>
					<Link to="/quests" className="waves-effect waves-light btn grey btn-cancel">Back</Link>
				
				</div>
			}
       		</div>
        );
    }
}

function mapStateToProps(state) {
  	const { quests } = state;
    return {
		quests
    };
}

const connectedQuestPage = connect(mapStateToProps)(QuestPage);
export { connectedQuestPage as QuestPage};