import React from "react";
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import teamActions from '../_core/actions/teams';
import { userActions } from '../_core/actions/user';
import HeaderTabsSearch from "../Components/HeaderTabsSearch";
import { hex } from "../_core/helpers/profile";
import avatar from '../_templates/man.png';
import option from '../_templates/dot.png';

const TeamDetails = (props) => {
    const { loading } = props;
    const params = useParams();
    const dispatch = useDispatch();
    const [team, setTeam] = React.useState({});
    const [users, setUsers] = React.useState([]);

    React.useEffect(() => {
        dispatch(teamActions.getById(params.teamId));
        dispatch(userActions.getAll(1, 1000000));
    }, []);//eslint-disable-line

    React.useEffect(() => {
        if (props.team?.length > 0) {
            setTeam(props.team[0])
        }
        if (props.users?.length > 0) {
            setUsers(props.users)
        }
    }, [setTeam, props.team, setUsers, props.users]);

    const TeamCard = ({ team }) => (
        <div className="p-team-details-card">
            <div style={{ background: hex(team.name.length >= 6 ? team.name : team.name + team._id) }}>
                <img src={option} alt="options" className="p-team-details-options" />
                {/* {team?.cover ?
                    <img src={team.cover} className="p-team-details-cover"/>
                : ""} */}
            </div>
            <div>
                <div>
                    {team?.logo ?
                        <img src={team.logo} alt="team_logo" onError={e => e.target.src = avatar} />
                        :
                        <div style={{ background: hex(team.name.length >= 6 ? team.name : team.name + team._id) }}><p>{team.name[0]}</p></div>
                    }
                </div>
                <div>
                    <p>{team.name}</p>
                </div>
            </div>
        </div>
    )

    const getMemberDetails = React.useCallback((userId) => {
        const getUser = users?.filter(u => u._id === userId)[0]?.details[0]
        return getUser
    }, [users]);

    const Members = ({details = {}, isLeader = false, isManager = false}) => {
        return (
            <React.Fragment>
                {details?._id ?
                    isLeader ?
                    <div className="p-team-details-leader">
                        <div>
                            <img src={details?.avatar || avatar} alt="member_avatar" onError={e => e.target.src = avatar} />
                        </div>
                        <div>
                            <p>Leader</p>
                            <p>{`${details?.firstName || ""} ${details?.lastName || ""}`}</p>
                        </div>
                    </div>
                    : isManager ?
                        // <div className="p-team-details-manager">
                        //     <div>
                        //         <img src={details?.avatar || avatar} alt="member_avatar" onError={e => e.target.src = avatar} />
                        //     </div>
                        //     <div>
                        //         <p>Manager</p>
                        //         <p>{`${details?.firstName || ""} ${details?.lastName || ""}`}</p>
                        //     </div>
                        // </div>
                        ""
                        :
                        <div>
                            <div>
                                <img src={details?.avatar || avatar} alt="member_avatar" onError={e => e.target.src = avatar} />
                            </div>
                            <div>
                                <p>{`${details?.firstName || ""} ${details?.lastName || ""}`}</p>
                            </div>
                        </div>
                :
                ""
                }
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {!loading && team?._id &&
                <div className="people-team-details">
                    <HeaderTabsSearch
                        currentPath={window.location.pathname}
                        tabs={[
                            { name: "Everyone", path: `/people` },
                            { name: "Team", path: `/people/team/${team._id}` }
                        ]}
                    // searchValue={filter}
                    // searchOnChange={handleChange}
                    searchDisabled={true}
                    />
                    <div className="people-team-details-content">
                        <TeamCard team={team} />

                        <div className="p-team-details-wrapper">
                            <p>Team Details</p>
                            <div>
                                <div>
                                    <p>Description</p>
                                    <p>{team.description || "-"}</p>
                                </div>
                                <div>
                                    <p>Slogan</p>
                                    <p>{team.slogan || "-"}</p>
                                </div>
                            </div>
                        </div>

                        <div className="p-team-details-members-wrapper">
                            <p>Members</p>
                            <div>
                                <Members isLeader={true} details={getMemberDetails(team.lead)}/>
                                <Members isManager={true} details={getMemberDetails(team.manager)}/>
                                {team?.users?.length > 0 &&
                                    team.users.filter((u) => u !== team.lead || u !== team.manager).map((user, index) =>
                                    <Members key={index} details={getMemberDetails(user)}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (props) => ({
    team: props.teams?.team?.success || [],
    users: props.users?.users?.items || [],
    loading: props.teams?.teamByIdLoading || false
})

export default connect(mapStateToProps)(TeamDetails);