import buildUrl from 'build-url';
import { get, post } from '../helpers/api';
import { userService } from '../services/user';

export const grantServices = {
    getAllGrant,
    getByOrigin,
    grantPost
};

// api/v1/post?page=1&items-per-page=10

function grantPost(grant){
    return post('/api/v1/grant', JSON.stringify(grant)).then(handleResponse);
}

function getAllGrant(page){
    return get(
        buildUrl('/api/v1/grant', {
            queryParams: {
                'page': page,
                'items-per-page': 10,
            }
        })
    ).then(handleResponse);
}

function getByOrigin(id){
    return get(
        buildUrl(`/api/v1/grant/origin/${id}`, {
            queryParams: {
                'page': 1,
                'items-per-page': 998,
            }
        })
    ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}