export const permissions = [
                            // {url: "/admin/dashboard", permissionName: "Dashboard"},

                            {url: "/admin/announcement", permissionName: "Announcements"},
                            {url: "/admin/announcement/create", permissionName: "Announcements"},
                            {url: "/admin/announcement/edit", permissionName: "Announcements"},

                            {url: "/admin/users", permissionName: "Access - Users"},
                            {url: "/admin/users/create", permissionName: "Access - Users"},
                            {url: "/admin/users/edit", permissionName: "Access - Users"},

                            {url: "/admin/roles", permissionName: "Access - Roles"},
                            {url: "/admin/roles/add", permissionName: "Access - Roles"},
                            {url: "/admin/roles/edit", permissionName: "Access - Roles"},
                            
                            {url: "/admin/permissions", permissionName: "Access - Permissions"},
                            {url: "/admin/permissions/create", permissionName: "Access - Permissions"},
                            {url: "/admin/permissions/edit", permissionName: "Access - Permissions"},
                            
                            {url: "/admin/attributes", permissionName: "Access - Attributes"},
                            {url: "/admin/attributes/create", permissionName: "Access - Attributes"},
                            {url: "/admin/attributes/edit", permissionName: "Access - Attributes"},
                            
                            {url: "/admin/today", permissionName: "Timekeeping - Today"},
                            {url: "/admin/timekeeping/reports", permissionName: "Timekeeping - Reports"},

                            {url: "/admin/leave-requests", permissionName: "Leaves - Leave Filings"},
                            {url: "/admin/leave-requests/review", permissionName: "Leaves - Leave Filings"},

                            {url: "/admin/leave-credits-overview", permissionName: "Leaves - User Leave Overview"},
                            {url: "/admin/leave-credits-overview/create", permissionName: "Leaves - User Leave Overview"},
                            {url: "/admin/leave-credits-overview/edit", permissionName: "Leaves - User Leave Overview"},
                            
                            {url: "/admin/leave-type/overview", permissionName: "Leaves - Leave Types Overview"},
                            {url: "/admin/leave-type/overview/create", permissionName: "Leaves - Leave Types Overview"},
                            {url: "/admin/leave-type/overview/edit", permissionName: "Leaves - Leave Types Overview"},
                            
                            {url: "/admin/coe-requests", permissionName: "Requests - COE Requests"},
                            {url: "/admin/coe-requests/review", permissionName: "Requests - COE Requests"},
                            
                            {url: "/admin/overtime-requests", permissionName: "Requests - Overtime Requests"},
                            {url: "/admin/overtime-requests/review", permissionName: "Requests - Overtime Requests"},
                            
                            {url: "/admin/undertime-requests", permissionName: "Requests - Undertime Requests"},
                            {url: "/admin/undertime-requests/review", permissionName: "Requests - Undertime Requests"},
                            
                            {url: "/admin/hr-concerns", permissionName: "Support - HR Concerns"},
                            {url: "/admin/hr-concerns/review", permissionName: "Support - HR Concerns"},
                            
                            {url: "/admin/technical-supports", permissionName: "Support - Technical Support"},
                            {url: "/admin/technical-supports/review", permissionName: "Support - Technical Support"},
                            
                            {url: "/admin/feedback", permissionName: "Support - Feedback"},
                            {url: "/admin/feedback/review", permissionName: "Support - Feedback"},
                            
                            {url: "/admin/quests", permissionName: "Engagement - Quests"},
                            {url: "/admin/quests/create", permissionName: "Engagement - Quests"},
                            {url: "/admin/quests/edit", permissionName: "Engagement - Quests"},
                            
                            {url: "/admin/challenges", permissionName: "Engagement - Challenges"},
                            {url: "/admin/challenges/create", permissionName: "Engagement - Challenges"},
                            {url: "/admin/challenges/edit", permissionName: "Engagement - Challenges"},
                            
                            {url: "/admin/badges", permissionName: "Engagement - Badges"},
                            {url: "/admin/badges/create", permissionName: "Engagement - Badges"},
                            {url: "/admin/badges/edit", permissionName: "Engagement - Badges"},
                            
                            {url: "/admin/rewards", permissionName: "Engagement - Rewards"},
                            {url: "/admin/rewards/create", permissionName: "Engagement - Rewards"},
                            {url: "/admin/rewards/edit", permissionName: "Engagement - Rewards"},
                            
                            {url: "/admin/reset-energy", permissionName: "Engagement - Reset Values"},
                            
                            {url: "/admin/team-management", permissionName: "Teams"},
                            {url: "/admin/team-management/create", permissionName: "Teams"},
                            {url: "/admin/team-management/edit", permissionName: "Teams"},
                            
                            {url: "/admin/evaluations", permissionName: "Appraisal - Evaluations"},
                            {url: "/admin/evaluations/create", permissionName: "Appraisal - Evaluations"},
                            {url: "/admin/evaluations/edit", permissionName: "Appraisal - Evaluations"},
                            
                            {url: "/admin/questionnaires", permissionName: "Appraisal - Questionnaires "},
                            {url: "/admin/questionnaires/create", permissionName: "Appraisal - Questionnaires "},
                            {url: "/admin/questionnaires/edit", permissionName: "Appraisal - Questionnaires "},
                            {url: "/admin/questionnaires/question/create", permissionName: "Appraisal - Questionnaires "},
                            
                            {url: "/admin/tenures", permissionName: "Appraisal - Tenures"},
                            {url: "/admin/tenures/create", permissionName: "Appraisal - Tenures"},
                            {url: "/admin/tenures/edit", permissionName: "Appraisal - Tenures"},
                            
                            {url: "/admin/page-management", permissionName: "Pages"},
                            {url: "/admin/page-management/create", permissionName: "Pages"},
                            {url: "/admin/page-management/edit", permissionName: "Pages"},

                            {url: "/admin/settings", permissionName: "Settings"},
                            ] ;

                            