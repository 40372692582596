import React, { useEffect, useState }from 'react'
import activePeople from '../../../_templates/activePeople.png';
import date from '../../../_templates/date.png';
import paper from '../../../_templates/paper.png';
import './UserLogsWidget.scss';

const UserLogsWidget = (props) => { 

    const [NewApplicantsCount, setNewApplicantsCount] = useState(0);
    const getNewApplicant = (props) => {
        let applicants = []
        const { roles: { roles } , //loading,
                users: { users }
        } = props;
        if (props.roles.loading === false && props.users.users !== undefined) {

            const applicantID = roles.items.find(({
                roleSlug
            }) =>
                roleSlug === 'applicant') || { _id: 'n/a' }
            applicants = users.items
                .filter(user => user.role === applicantID._id && user.active === 1)
            setNewApplicantsCount(applicants.length)
        }
    }
    const ActiveUserCount = (data) =>{
        let activeUsers = [];
        data.items.map(user => {
            if(user.active === 1){
                activeUsers.push(user);
            }
            return null
        })
        return activeUsers.length;
    }
    useEffect(() => {
    if(props.users.loading === false) {
        getNewApplicant(props);
        
    }

    }, [props]);

    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;
    const viewPermission = JSON.parse(localStorage.getItem('viewPermission'));

    return (

        <div className="userLogsContainer" style={{ marginBottom: (viewPermission.includes("Access - Users") === true) || (viewPermission.includes("Leaves - Leave Filings") === true) || (roleAllTrue === 1) === true ? '20px' : '-20px'}}>
            {(viewPermission.includes("Access - Users") === true) || (roleAllTrue === 1) ?
                <div className="new_applicants">
                    <div className="container_flex">
                        <div className="new_appicants_labels">
                            <p className="title"> New Applicants </p>
                            <p className="count"> {NewApplicantsCount} </p>
                        </div>
                        <div className="img-container newApplicants" data-mode="newApplicants">
                        <img src={paper} alt='' />
                            </div>
                    </div>
                </div> : false
            }
            {(viewPermission.includes("Leaves - Leave Filings") === true) || (roleAllTrue === 1) ?
                <div className="leave_application">
                    <div className="container_flex">
                        <div className="new_appicants_labels">
                            <p className="title"> Leave Applications</p>
                            <p className="count"> {props.leaves !== undefined ? props.leaves.totalPerPage : 0 } </p>
                        </div>
                        <div className="img-container " data-mode="leaveApplication" >
                        <img src={date} alt='' />
                        </div>
                    </div>
                </div> : false
            }
            {(viewPermission.includes("Access - Users") === true) || (roleAllTrue === 1) ?
                <div className="active_people">
                    <div className="container_flex">
                        <div className="new_appicants_labels">
                            <p className="title"> Active People</p>
                        { props.users.loading === false &&
                        <p className="count"> {  ActiveUserCount(props.users.users) } / {props.users.users.total}
                        </p> }
                        </div>
                        <div className="img-container "  data-mode="activePeople">
                        <img src={activePeople} alt='' data-mode="activePeople"/>
                        </div>
                    </div>
                </div> : false
            }
        </div>
    )
}

export default UserLogsWidget