import React from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { If, Else, Then,  } from 'react-if';
import { useParams } from "react-router-dom";

import moment from 'moment';

import emptyIcon from '../../../_templates/empty.png';
import avatar from '../../../_templates/man.png'
import calendar from '../../../_templates/dropdown_chevrondown.png'

import { history } from "../../../_core/helpers/history";
import { userActions } from "../../../_core/actions/user";
import { questService } from "../../../_core/services/quest";
import { userChallengeActions } from "../../../_core/actions/userChallenge";

import { DynamicEngagementAction } from "../../Components/tableActions/engagementActions";
import { DynamicTableAndPaginationWrapper,DynamicEntriesOnly } from "../../Components/PageWrapper/DynamicWrapper";

import "./Quests.scss";
import "react-datepicker/dist/react-datepicker.css";

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;
export const SearchIconFindUser = styled.img`
  margin-bottom: -30px;
  margin-left: 15px;
  display: flex;
  position: relative;
  z-index: 1;
  height: 15px;
  width: 15px;
  object-fit: contain;
`;

export const FindUserWrapper = styled.div`
  > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
    margin-left: 15px;
  }
`;

export const GridContainer = styled.div`
  ${(props) =>
    props.isShowUserDetails === false
      ? css`
          margin-top: 20px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;

          // > div > label {
          //   margin-top: 10px;
          // }

        
        `
      : css`
          display: block;
        `}

  > div > label.label__questionnaires, > div > label.label__tenures {
    display: flex;
    float: left;
    top: 10px;
    position: relative;
    z-index: 1;
    margin-left: 25px;
    color: #9ba2ce;
  }

  > div > label.Error__message {
    margin-left: 10px;
  }

  > div > label.label__questionnaires {
    background: #2a3143;
    width: 72px;
    margin-top: 5px;
   
  }
  > div > label.label__tenures {
    background: #2a3143;
    width: 40px;
    margin-top: 5px;
  }

  > div > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
  }

  > div > div.error_date {
    border: 1px solid #f14646;
    border-radius: 3px;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
  margin-top: 20px;

    > img.admin_calendar_icon1 {
        display: flex;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
    }
    > img.admin_calendar_icon2 {
        display: flex;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
    }
  
    .floating-label {
        display: inline-block;
    }

    .floating-label:nth-child(2) {
        float: right; 
    }

   .calendar-dark {
        background-image:url(${calendar}); !important;
    }

`;



const AdminQuestsPage = styled.div`

    border-radius: 4px;
    margin-top: 20px;
    padding: 20px;
    background-color: #2a3143;
    width: 100%;
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        text-align: center;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        text-align: center;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const mapStateToProps = ({users,userChallenge,quests}) => ({
  users,
  userChallenge,
  quests
});

const ViewChallengePage = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();
  const { pageId } = useParams();

  const [questChallenges, setQuest] = React.useState([]);
  const [entriesValues, setentriesValues] = React.useState();
  const [questParticipants, setQuestParticipants] = React.useState([]);
  const [questDetails,setQuestDetails] = React.useState([]);

  //let filterkeys = ["questTitle", "questContent"];
  let tableHeader = ["Participant Name", "Over All Status", "Actions"]
  

    //show entries
      const entriesValue = (entries) => {
        setentriesValues(entries);
    }


  React.useEffect(() => {
    if (pageId) {
      questService.getAllQuestChallenge(pageId, 1, 100000).then((res) => { 
        setQuest(res?.items)

      }).catch(err => console.error(err));

      questService.getById(pageId).then((res) => { 
        setQuestDetails(res?.success)

      }).catch(err => console.error(err));

    }
    //eslint-disable-next-line
  }, [pageId,setQuest,setQuestDetails]);


  React.useEffect(() => {
    dispatch(userActions.getAllActiveUsers(1,100000,'',''));
    dispatch(userChallengeActions.getQuestParticipants(pageId,1,100000))

    //eslint-disable-next-line
  }, [dispatch]);

  React.useEffect(() => {
    setQuestParticipants(props.userChallenge?.userChallenge)
    //eslint-disable-next-line
  }, [setQuestParticipants,props.userChallenge,props]);

 
    const questChallengeParticipants = (users) => {
            
         let setParticipants = props.users.users?.items && props.users.users?.items.filter(user => users.includes(user.details[0].user_id));

        return (
            
                setParticipants?.map((challenger, index)=>(
                        <div key={index} className="rq-participants" style={{display:'inline-flex'}}>
                            <div className="participants-imgs">
                                <img  className="img-participant" alt="" onError={(e) => { e.target.src = avatar }} src={challenger.details[0]?.avatar || avatar} style={{marginRight:'-10px'}}/>
                            </div>
                            <span style={{marginLeft:'25px',textTransform:'capitalize'}}>
                                {challenger.details[0]?.firstName + ' ' + challenger.details[0]?.lastName}
                            </span> 
                        </div>

                    ))
                
        );
    };    

    const viewPartcipants = (userId) => {
         history.push(`/admin/quests/${pageId}/user/${userId}/challenges`)     
    }

    const now = moment().utc();

    const setEndDate = (status,date) => { //get accepted date + time limit

        const isAccepted = status.toLowerCase() === 'accepted' ? true : false;
        const acceptedDate = moment(date).isValid() ? moment(date).utc() : false;
        const timelimit = questChallenges?.map((userCredit) => {
            return userCredit.timeLimit
        })[0]
        const getTimeLimitString = timelimit !== '00:00:00' || !timelimit ? timelimit : false;

        var getTimer;

          if(getTimeLimitString){
              if(isAccepted && acceptedDate){
                  const hms_split = getTimeLimitString.split(':');
                  const getTimePerSec = (+hms_split[0]) * 60 * 60 + (+hms_split[1]) * 60 + (+hms_split[2]);
                  const computedEndTime = moment(acceptedDate).add(getTimePerSec, 'seconds');
                  getTimer = computedEndTime;
              }else{
                  getTimer = getTimeLimitString;
              }
          }else{
              getTimer = false;
          }

        return getTimer

      }


    const completedCount = (data) =>{

        let result;
        
       
    
        data && data.map((items) => {
            let status = [];
            let acceptedStatus = [];

            if (items.length !== 0) {

                items.challenges && items.challenges.map((data) => {
                    
                    if(data.status === 'accepted'){
                        // console.log(data.userId+moment(now).isSameOrAfter(moment(setEndDate(data.status,data.acceptedAt))))
                        acceptedStatus.push(moment(now).isSameOrAfter(moment(setEndDate(data.status,data.acceptedAt))));
                    }else{
                        status.push(data.status);
                    }
                    
                    return data
                })
                
            }
            
            //Checking if quest challenges is all completed
            const counts = {};
            status.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

            
            const timelimit = acceptedStatus.includes(false)

            if(counts.completed === questChallenges.length && timelimit !== true){
                result = true  //If all quest challenge is completed and no failed challenge
            }else if(questChallenges.length !== items.challenges.length && timelimit !== true){
                result = true //If all quest challenges are not accepted/finished and no failed challenge  
            }else if(questChallenges.length === items.challenges.length && timelimit === true){
                result = true // If all quest challenges is accepted/finished and has a failed challenge 
            }else if(questChallenges.length !== items.challenges.length && timelimit === true){
                result = true // If all quest challenges are not yet accepted/finished and has a failed challenge
            }else if( questChallenges.length === items.challenges.length && timelimit !== true && counts.finished){
                result = true // If all quest challenges is accepted/finishded and has no failed challenges
            
            }else{
                result = false // If all quest challenges is failed.
            }
            return items;
        });

        return result

    }   
    
    
    const overAllStatus = (id,overAllStatus) => {

     const timelimit = questParticipants.items?.filter(user => user.userId === id).map((userCredit) => {
        return userCredit
    })

       return (
           
            moment(now).isSameOrAfter(moment.utc(questDetails?.[0]?.expiresAt)) && overAllStatus === null && completedCount(timelimit) === true?
                <span className="rejected">Failed - (Quest Expired and Not Completed)</span>
            :completedCount(timelimit) === true && overAllStatus === null ?
                <span> ----- </span> 
            : completedCount(timelimit) === true  && overAllStatus === 'completed'?
                <span className="completed">Completed</span> 
            :
                <span className="rejected">Failed</span>
                
            
               
       );
   };    


    const QuestsData = () => {
        let data = questParticipants?.items ?? [];
        return (
          <>
            <If condition={props.userChallenge.loading === false }> 
                <Then>
                    {data.length !== 0 ?  (
                        data.map((item, index) => (
                            <tr key={index}>
                                <td  style={{width:'33.33%'}}>{questChallengeParticipants(item.userId)}</td>
                                <td style={{width:'33.33%',textTransform:'capitalize',paddingLeft:'30px'}}>
                                    {overAllStatus(item.userId,item.overAllStatus)}
                                </td>
                                <td style={{width:'33.33%'}}>
                                    <div>
                                        <DynamicEngagementAction
                                            originPage="viewParticipants"
                                            viewChallengeParticipants="View Challenges"
                                            onClickViewParticipants={ (e) => {viewPartcipants(item.userId)}}
                                            editStatus = {false}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) :
                    <tr>
                        <td colSpan="12" style={{textAlign:'center'}}>
                            <img src={emptyIcon} className="empty" alt="empty"/>
                            <p style={{fontSize:'16px'}}>No Data Available</p>
                            <p style={{fontSize:'14px'}}>There are currently no data available</p>
                        </td>
                    </tr>
                    }
                </Then>
                <Else>
                  <tr className="table-td-data">
                    <td> 
                      <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                          <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                      </div>
                    </td>
                  </tr>
                </Else>
            </If>
          </>
        );
      };

  return (
    
    <AdminQuestsPage>

      <DynamicEntriesOnly 
        entriesValue={entriesValue} 
        originPage="UserChallengeParticpants"
      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={QuestsData()}
        originPage="Quest"
        data={questParticipants}
        location={props.location}
        paginationPath={"/admin/quests"}
        entriesItem={entriesValues}
        loading = {props.userChallenge.loading}
        pageId={pageId}
      />
    </AdminQuestsPage>
  );
});

export default ViewChallengePage;
