import React from 'react';
import cc from 'classcat';
import { If, Then, Else } from 'react-if';

import './Table.scss';

import arrowLeft from '../_templates/arrow_left.png';
import arrowRight from '../_templates/arrow_right.png';
import flag from '../_templates/flag_black_large.png';
import chevdown from '../_templates/chevron-down.png';

// Code obtained from https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
const getPaginationNumbers = (currentPage, totalNumberOfPages) => {
    let delta = 2,
        left = currentPage - delta,
        right = currentPage + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= totalNumberOfPages; i++) {
        // eslint-disable-next-line
        if (i == 1 || i == totalNumberOfPages || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

const itemsPerPage = [5, 10, 20];

const Table = (props) => {
    // 0 - Ascending, 1 - Descending
    const [headersWithOrder, setHeadersWithOrder] = React.useState(props.headers.map((_, index) => ({
        index,
        order: 0
    })) || []);

    const onHeaderClick = React.useCallback(
        (index, order) => {
            // Early return if header is empty
            if (props.headers[index]?.length < 1) {
                return;
            }

            const newOrder = order === 0 ? 1 : 0;

            props.onHeaderClick(index, newOrder);

            setHeadersWithOrder(headersWithOrder => (
                headersWithOrder.map(header => ({
                    ...header,
                    order: header.index === index ? newOrder : 0
                }))
            ));
        },
        // eslint-disable-next-line
        [
            props.headers,
            props.onHeaderClick,
            setHeadersWithOrder
        ]
    );

    const dataBody = () => {
        const itemsPerPage = props.itemsPerPage;
        const columns = props.columns || 0;
        const rowItems = props.rows;
        

        return (
            <tbody>
                {
                    rowItems?.length > 0 && new Array(itemsPerPage).fill(null).map((_, key_id) => (
                        <tr key={key_id} onClick={() => { rowItems[key_id] && props.onRowClick && props.onRowClick(rowItems[key_id]?._id) }} className={!rowItems[key_id] ? "no-item" : ""}>
                            {
                                rowItems[key_id]?.cells ? rowItems[key_id]?.cells.map(cell => (
                                    <td key={cell._id} data-id={key_id}>
                                        { cell.element}
                                    </td>
                                ))
                                :
                                new Array(columns).fill(null).map((cell, index)=> (
                                    <td key={index}></td>
                                ))
                            }
                        </tr>
                    ))
                }
                {/* {
                    rowItems.map((row, key_id) => (
                        <tr key={row._id} onClick={() => { props.onRowClick && props.onRowClick(row._id) }}>
                            {
                                row.cells.map(cell => (
                                    <td key={cell._id} data-id={key_id}>
                                        { cell.element}
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                } */}
            </tbody>
        )
    }

    return (
        <div className="pragmahr-table-container">
            {
                props.loading &&
                <div className="loading" />
            }
            <table className="pragmahr-table">
                <thead>
                    <tr>
                        {
                            props.headers.map((header, index) => (
                                <th
                                    key={index}
                                    onClick={() => header.withOrder && onHeaderClick(index, headersWithOrder[index]?.order ?? 0)}
                                >
                                    { header.name}
                                    {
                                        (props.rows || []).length > 0 && 
                                        header.withOrder && (
                                            headersWithOrder[index]?.order === 0 ?
                                                <> <img src={chevdown} alt="down" className="caret-order"/></> :
                                                <> <img src={chevdown} alt="up" className="caret-order up"/></>
                                        )
                                    }
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                {dataBody()}
            </table>
            {
                (props.loading === false && (props.rows || []).length <= 0) &&
                <div className="pragmahr-table-no-data">
                    <img src={flag} alt="" />
                    <p className="no-data-available">No data available</p>
                    {props.noDataComponent}
                </div>
            }
            <div className="pragmahr-table-controls-container">
                <div className="page-controls">
                    <button
                        disabled={props.page === 1}
                        onClick={() => { props.goToPage(props.page - 1) }}
                    >
                        <img height={18} src={arrowLeft} alt="arrow-left" />
                    </button>
                    {
                        // Generate 1 to n buttons
                        getPaginationNumbers(
                            props.page,
                            [...Array(props.pages).keys()].length
                        )
                            .map((page, key_id) => (
                                <If condition={typeof page === 'string'} key={key_id}>
                                    <Then>
                                        <p style={{ fontWeight: 600, marginLeft: 2 }}>...</p>
                                    </Then>
                                    <Else>
                                        <button
                                            className={cc({
                                                'page-number': true,
                                                'current': props.page === page
                                            })}
                                            onClick={() => { props.goToPage(page) }}
                                        >
                                            {page}
                                        </button>
                                    </Else>
                                </If>
                            ))
                    }
                    <button
                        disabled={props.page === props.pages}
                        onClick={() => { props.goToPage(props.page + 1) }}
                    >
                        <img height={18} src={arrowRight} alt="arrow-right" />
                    </button>
                </div>
                <div className="items-per-page-control-container">
                    <p>Show</p>
                    <div className="items-per-page-control">
                        {
                            itemsPerPage.indexOf(props.itemsPerPage) > 0 &&
                            <button
                                onClick={() => {
                                    props.setItemsPerPage(itemsPerPage[itemsPerPage.indexOf(props.itemsPerPage) - 1])
                                }}
                            >
                                <img height={18} src={arrowLeft} alt="arrow-left" />
                            </button>
                        }
                        <p>
                            {
                                [
                                    itemsPerPage[itemsPerPage.indexOf(props.itemsPerPage)],
                                    'items'
                                ].join(' ')
                            }
                        </p>
                        {
                            itemsPerPage.indexOf(props.itemsPerPage) < itemsPerPage.length - 1 &&
                            <button
                                onClick={() => {
                                    props.setItemsPerPage(itemsPerPage[itemsPerPage.indexOf(props.itemsPerPage) + 1])
                                }}
                            >
                                <img height={18} src={arrowRight} alt="arrow-right" />
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Table);