//npm packages
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
//actions
import '../ChallengeWidget/ChallengeWidget.sass'
import { QuestCard } from '../QuestWidget/QuestCard';

import { history } from '../_core/helpers/history';
///styles
import '../QuestWidget/QuestWidget.sass';

//image
import ChallengeEmpty  from '../_templates/challenges-empty-state.png'
import { questActions } from '../_core/actions/quest';
import { userActions } from '../_core/actions/user';

const mapStateToProps = ({ quests,users }) => ({
    quests,
    users
});

const AcceptedQuests =  connect(mapStateToProps)((props) => {

    const dispatch = useDispatch();
    let user = JSON.parse(localStorage.getItem('userCredentials'));
    
    React.useEffect(() => {
        dispatch(questActions.getAllAcceptedQuest(1, 100000, user.user._id));
        dispatch(userActions.getAll(1, 100000));
    }, [dispatch,user.user._id]);
    

    const handleChallenge = () => {
        //setAppModal(true);
        history.push('/engage/quests');
    }

    return (

        <div className="recent-quests" >
            {props.quests.loading !== true ?
                <div className="rq-container">
                    <p className="rq-title">My Accepted Quests</p>
                    {props.quests?.items?.total > 0 ? 
                            <div className="row rq-card-container">
                                {props.quests?.items?.items.map((quest, index) =>
                                    <QuestCard 
                                        key={ index } 
                                        quest={ quest } 
                                        users={ props.users?.users?.items }
                                    />
                                )}
                            </div> :

                            <div style={{display:'grid', justifyContent:'center'}}>   
                                <img src={ChallengeEmpty} className="ChallengeEmptyState" alt="Challenge Empty State" style={{margin:'30px'}}/> 
                                <h5 className="challenge-empty-state-main-text">No Accepted Quest</h5>
                                <p className="challenge-empty-state-sub-text">Quest accepted! Let's make it happen!</p>
                                <button className="waves-effect waves-light btn btn-create-challenge" onClick={handleChallenge} style={{marginLeft:'0'}}>List of Quests</button>
                            </div>
                    }
                </div> 
            : 
                <div style={{display:'grid', justifyContent:'center'}}>   
                    <Skeleton className="ChallengeEmptyState" height={106} width={289} />
                    <h5 className="challenge-empty-state-main-text" style={{marginBottom:'10px', marginTop:'10px'}}><Skeleton height={20} width={100} /></h5>
                    <p className="challenge-empty-state-sub-text" style={{marginBottom:'10px'}}><Skeleton height={20} width={100} /></p>
                    <Skeleton height={49} width={289} />
                </div>            
        }
    </div>
    
    )
})

export {AcceptedQuests}