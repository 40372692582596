import { del, get, post, put } from '../helpers/api';
import { userService } from '../services/user';

function getAll(itemsPerPage, page) {
    return get(`/api/v1/team?page=${page || 1}&items-per-page=${itemsPerPage || 10}`).then(handleResponse);
}

function getUsersByTeamId(id) {
    return get(`/api/v1/user?items-per-page=100000&filter=team:${id}`).then(handleResponse);
}

function getById(id) {
    return get(`/api/v1/team/${id}`).then(handleResponse);
}

function getUserTeam(id) {
    return get(`/api/v1/user/${id}/team?items-per-page=100000`).then(handleResponse);
}
const addMembers = (userId, team) => post('/api/v1/user/' + userId + '/team', team).then(handleResponse);
const deleteMembers = (userId, teamId) => del('/api/v1/user/' + userId + '/team/' + teamId).then(handleResponse);
const deleteTeam = (id) => del('/api/v1/team/' + id).then(handleResponse);
const uploadLogo = (id, formData) => post(`/api/v1/upload/${id}/team`, formData).then(handleResponse);

const create = (team, members, logo) => {

    return post('/api/v1/team', team)
        .then(handleResponse)
        .then(response => {
            const teamId = response?._id;

            if (teamId) {
                //upload logo
                if(logo){
                    uploadLogo(teamId, logo);
                }

                //add members
                if (members?.length > 0) {
                    members.forEach(member => {
                        addMembers(member, { team_id: teamId });
                    });
                }
            }
        });
}

const update = (team, removeMembers, members, logo) => {
    return put('/api/v1/team/' + team._id, team)
        .then(handleResponse)
        .then(response => {
            const teamId = team._id;

            if (teamId) {
                //upload logo
                if(logo){
                    uploadLogo(teamId, logo);
                }
                
                //add members
                if (members?.length > 0) {
                    members.forEach(member => {
                        addMembers(member, { team_id: teamId });
                    });
                }

                //remove previous members
                if (removeMembers?.length > 0) {
                    removeMembers.forEach(member => {
                        deleteMembers(member, teamId);
                    });
                }
            }
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}

export default {
    create,
    update,
    delete: deleteTeam,
    getAll,
    getById,
    getUsersByTeamId,
    getUserTeam,
    uploadLogo,
    addMembers,
    deleteMembers,
};