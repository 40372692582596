import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_core/actions/user';
import { timekeepingAction } from '../../../_core/actions/timekeeping';
// import SideMenu from '../Components/SideMenu';
import ErrorPage from '../../../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../../../Components/ERRORPAGE/ButtonHome';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import avatar from '../../../_templates/man.png';
// import error from '../../_templates/error.png';
import './ResetEnergyPage.scss';
// import M from 'materialize-css/dist/js/materialize.min.js';
import Select from 'react-select';
import {checkSideNav} from '../../Components/checkSideMenu'; //adjust component width if admin sidenav is open
import Swal from 'sweetalert2';
import { resetEnergyServices } from "../../../_core/services/resetEnergy";

class ResetEnergyPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: '',
            userFullName: '',
            image: '',
            loading: false,
            error: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.searchUser = this.searchUser.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.getTimesheetUser = this.getTimesheetUser.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(userActions.getAll(1, 100000));
        this.props.dispatch(timekeepingAction.getAll(1, 100000));
    }

    componentWillUnmount(){
        this.props.dispatch(userActions.getAll(1, 100000));
        this.props.dispatch(timekeepingAction.getAll(1, 100000));
        this.getUsers();
    }
    
    searchUser = (e) =>{
        e.preventDefault();

        let username = this.state.user
        this.setState({
            loading: true
        })

        const users = this.props.users;

        const userDetails = [];

        users.users.items.map(user => {
          userDetails.push({
            value : user.praggerUsername,
            key: user._id
          })
        
          return user
        });
        
        const userToReset = userDetails.filter(un => (
          un.value === username
        ))[0].key;

        const test = {
          add: true,
          userId: userToReset,
          energyType: "challenge",
          value:  10
        }

        if(username === ''){
          this.setState({
              loading: false
          },()=>{
              alert("please select user")
          })
        }else {
          const ToastSuccess = Swal.mixin({
            background: "#2a3042",
            toast: true,
            width: 300,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
          });
          Swal.fire({
            title: "<p class='swal-title-engagement'>Confirmation</p>",
            html:
              " <div style='margin:30px'><p class='alert-first'>Are you sure you want to reset energy value?</p></div>",
        
              showCancelButton: true,
              showCloseButton: true,
              confirmButtonColor: "#556ee6",
              cancelButtonColor: "#2A3143",
              customClass: {
                header: 'header-class',
                container: 'container-class',
                popup: 'popup-class-engagament',
                actions: 'actions-class-engagament',
                cancelButton: 'cancel-button-class',
                confirmButton: 'confirm-btn-engagement',
                content: 'E-alert-conent',
              },
              reverseButtons:true,
              cancelButtonText: "CANCEL",
              confirmButtonText:"RESET ENERGY",
              background: "#2E3549",
          }).then((result) => {
            if (result.value) {
              resetEnergyServices.deletEnergy(userToReset).then((res) => { 
                setTimeout(() => {
                  ToastSuccess.fire({
                      type: 'success',
                      title: '<span style="color:#FFFFFF"> Energy Reset </span>',
                      position: 'center',
                  });resetEnergyServices.createEnergy(test).then(() => {window.location.reload()})
                }, 100)
              });
            }
          })
          this.setState({
            loading: false
          })
        }
    }
    
    getTimesheetUser = () => {
        const timekeeping = this.props.timekeeping
        var timesheetUser = []

        timekeeping.timekeepings && timekeeping.timekeepings.data.map((user)=>{
            timesheetUser.push(
                user.username
            )
            return user
        })
        return timesheetUser
    }

    getUsers(){
        const users = this.props.users;
        const usersList = [];
        const user =  users.users.items ;

        const def = (user) => (
          user?.map((myItems,index) => {
            // myItems.praggerUsername
              usersList.push({
                value: myItems.praggerUsername,
                label: myItems.details[0].firstName + " " + myItems.details[0].lastName,
                image: myItems.details[0].avatar,
                key: index,
            })
            return myItems
          })
        );
            
        def(user);
        // users.users && users.users.items.map((user, index) => {
        //     usersList.push({
        //         value: user.praggerUsername,
        //         label: user.details[0].firstName + " " + user.details[0].lastName,
        //         key: index,
        //     });
        //     return user
        // })

        return usersList
    }

    handleChange = (event) => {
        const value = event.value;
        const users = this.props.users;
        const userDetails = [];

        users.users.items.map(user => {
          userDetails.push({
            value : user.praggerUsername,
            image: user.details[0].avatar,
            fullName: user.details[0].firstName + " " + user.details[0].lastName,
            key: user._id
          })
        
          return user
        });

        console.log(userDetails)
        
        const userImage = userDetails.filter(un => (
          un.value === value
        ))[0].image;
        
        const fullName = userDetails.filter(un => (
          un.value === value
        ))[0].fullName;

        this.setState({     
            user: value,
            image: userImage,
            userFullName: fullName
        }, () => {
            // console.log(this.state)
        })
    }

    render() {
        const { users } = this.props
        const loading = this.state.loading
        const error = this.state.error
        this.getTimesheetUser()

        const customStyles = {
            control: (base, state) => ({
                ...base,
                fontFamily: '"Helvetica Neue", sans-serif',
                border: state.isFocused ? '1px solid #556EE6 !important' : '1px solid #2E3648',
                boxShadow: state.isFocused ? '1px solid #556EE6' : '1px solid #2E3648',
                outline: 'none',
                height: '50px',
                padding: '0px 3px',
                borderRadius: '5px',
                alignContent: "center",
                backgroundColor: '#2E3648',
                color: 'white'
              }),
              input: (state) => ({
                fontFamily: '"Helvetica Neue", sans-serif',
                '& input': {
                  font: 'inherit',
                  textDecoration: state.isFocused ? 'none !important' : 0,
                  color: 'white',
                  marginLeft: '50px',
                },
                backgroundColor: '#2E3648',
                color: 'white',
                height: '30px !important',
                marginLeft: '50px'
              }),
            menu:(provided)  => ({
                ...provided,
                height: "auto",
                maxHeight: "180px",
                zIndex: "99",
                position: "absolute",
                display: "block",
                backgroundColor: '#2E3648',
                color: '#9BA2CE'
            }),
            menuList:(provided)  => ({
                ...provided,
                height: "auto",
                maxHeight: "180px",
                padding: "2px 2px",
                backgroundColor: '#2E3648',
                color: '#9BA2CE'
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#323A4F' : state.isFocused ? '#323A4F' : '',
                color: state.isSelected ? 'white' : state.isFocused ? 'white' : '',
                "&:hover": {
                    // Overwrittes the different states of border
                    backgroundColor: state.isFocused ? "#323A4F" : "",
                    color: state.isSelected ? 'white' : '',
                }
            }),
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = 'opacity 300ms';
              const color = '#9BA2CE';
              const marginLeft = '50px';
              return { ...provided, opacity, transition, color, marginLeft };
            }
          }

        return (
            <BodyBackgroundColor backgroundColor='#222736'>
                

                <div className={"tkBodyContainer" + checkSideNav()}>
                    {/* <SideMenu /> */}
                    {users.error ?
                    <div className="container error-div">
                        <ErrorPage />
                        <div className="text-danger">
                            <img className="error" src={error} alt="error" />
                            {users.error}
                        </div>
                        <ButtonHome />
                    </div> :
                    <div className="timekeep-page-container">
                        <div className="row valign-wrapper">
                            <div className="col s12 m12 l12" id="zero">
                                <h5 className="text-left" id="header">Engagement</h5>
                                <p className="tkTitle">
                                    <span className="tkTxt1"><b>Reset Values</b></span>
                                                 
                                </p>
                            </div>
                        </div>
                        {loading && 
                            <div>
                                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </div>
                        }
                        {users.loading ?
                        <div className="center">
                            <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </div>
                        : 
                        <div>
                        {users.users &&
                            <div className="card-panel clearfix">
                                <b>User</b>
                                <div className="row" id="zero">
                                    <div className="col s12 m12 l12" id="zero">
                                        <div className="search-border">
                                            <Select 
                                                options={this.getUsers()}
                                                placeholder={<div className="placeholder">Select user</div>}
                                                className="basic-single"
                                                onChange={this.handleChange}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                    {error &&
                                        <div className="col s12 m12 l12" id="zero">
                                            <em className="error">user record not found</em>
                                        </div>
                                    }
                                    {
                                      this.state.user 
                                        ? <div className="col s12 m12 l12 right" id="zero" style={{display: 'flex', alignItems: 'center'}}>
                                            <div className="col s12 m12 l6 pl-0" style={{display: 'flex'}}>
                                              <img src={this.state.image ? this.state.image : avatar} alt="" className="imgCircle"  style={{height: '25px', width: '25px', marginRight: '15px', borderRadius: '50%', objectFit: 'cover'}}/>
                                              <p>{this.state.userFullName}</p>
                                            </div>
                                            <div className="col s12 m12 l6 pr-0" >
                                              <button className="btn btn-large right generate-btn" onClick={this.searchUser}>Reset Energy</button>
                                            </div>
                                          </div> 
                                      : false
                                    }
                                </div>
                        </div>
                        }
                        </div>
                        }
                    </div>
                    }
                </div>
            </BodyBackgroundColor>
        );

    }
}

function mapStateToProps(state) {
    const { timekeeping, users } = state;
    return {
        timekeeping,
        users
    };
}


const connectedReportsPage = connect(mapStateToProps)(ResetEnergyPage);
export { connectedReportsPage as ResetEnergyPage };