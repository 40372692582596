import { feedbackConstants } from '../constants/feedback';
import { feedbackService } from '../services/feedback';

export const feedbackActions = {
    getAll
};

function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());
        
        feedbackService.getAllFeedback(page, itemsPerPage)
            .then(
                feedback => dispatch(success(feedback)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(feedback) { return { type: feedbackConstants.GETALL_FEEDBACK_REQUEST,feedback } }
    function success(feedback) { return { type: feedbackConstants.GETALL_FEEDBACK__SUCCESS, feedback } }
    function failure(error) { return { type: feedbackConstants.GETALL_FEEDBACK__FAILURE, error } }
}
