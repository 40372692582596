import React from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect, useDispatch } from "react-redux";
import { checkSideNav } from "../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import { If, Else, Then,  } from 'react-if';
import styled from "styled-components";
import emptyIcon from '../../_templates/empty.png';
import ReactImageFallback from "react-image-fallback";
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { history } from "../../_core/helpers/history";


import {
  PageWrapper,
  ContentWrapper,
} from "../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent,DynamicTableAndPaginationWrapper, DynamicContentHeaderWrapper } from "../Components/PageWrapper/DynamicWrapper";
//import { DynamicTableAction } from "../Components/tableActions/tableActions";

import "./Achievements.scss";

//import Swal from 'sweetalert2';

// { history } from "../../_core/helpers/history";
import avatar from '../../_templates/man.png';
import defaultCover from '../../_templates/alt_cover.png';
import { questActions } from "../../_core/actions/quest";


import CreateEditAhievement from './CreateEditAhievement';
import { badgesActions } from "../../_core/actions/badges";

const AdminBadgestsPage = styled.div`
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4) {
        text-align: center;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: center;
        
        img {
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;


const AchievementsPageList = (props) => {
  const dispatch = useDispatch(); //eslint-disable-line
  const [entriesValues, setentriesValues] = React.useState();
  const [achievement, setAchievement] = React.useState([]);
  const [quests, setQuest] = React.useState([]);
  const [search, setNewSearch] = React.useState("");
  const [keys, setKeys] = React.useState("");

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const viewPermission = JSON.parse(localStorage.getItem('viewPermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  let tableHeader = (editPermission.includes("Access - Achievements") === true) || (deletePermission.includes("Access - Achievements") === true) || (roleAllTrue === 1) ? ["Users", "Badge", "Requirements", "Actions"] : ["Users", "Badge", "Requirements", ""];
  let filterkeys = ["badgeName", "badgeContent"];

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

    //show entries
    const entriesValue = (entries) => {
        setentriesValues(entries);
    }

    //search
    const handleSearchChange = (item) => {
      setNewSearch(item);
  	};
    
    //filters
    const filterKeys = (item) => {
        setKeys(item);
    };


    React.useEffect(() => {
        dispatch(questActions.getAll(1,100000,'',''));
        dispatch(badgesActions.getAll(1,100000,'',''));
        //eslint-disable-next-line
      }, [dispatch]);

  React.useEffect(()=>{
    const timer = setTimeout(() => {
        setAchievement(props.achievements?.items);
        setQuest(props?.quests?.items)
      }, 100);
      return () => clearTimeout(timer);
      //eslint-disable-next-line
  },[props.achievements,props.quests,setQuest,setAchievement])


  const handleEditAchievement = (item) => {
    history.push(`/admin/achievements/${item}/edit`)
  }

  const handleViewAchievement = (item) => {
    history.push(`/admin/achievements/${item}/view`)
  }

  const achievementTitle = (id,name) => { //Quest Title

   var result = quests?.items?.find(x => x._id === id[0]);

     return (
            <span>
                {
                    name === 'anniversary' ? 
                    "Anniversary" 
                : name === 'none' ? 
                    "None" 
                : result?.questTitle
                
                }

            </span>
        )

  }

  const achievementBages = (item) => { //Badges

    const badgesList = props.badges?.items?.items

    return (   
         
        badgesList && badgesList.filter(value => item.includes(value._id)).map((items,i) => {

        return (
            <div key={i} data-tip={items.badgeName}>
                <ReactImageFallback
                    src={items.badgeImage}
                    fallbackImage={defaultCover}
                    initialImage={defaultCover}
                    alt="badge avatar"
                    className="badge-photo achieveavatar circle responsive-img"
                />
            </div>
        )
    
    }))

  }

//   console.log(achievement)

  const BadgesData = () => {
    let data = achievement ?? [];
    
    return (
      <>
        <If condition={props.achievements.loading === false }> 
            <Then>
                {data.length !== 0 ?  (
                data.map((item, index) => (
                    <tr key={index}>
                    <td style={{ width: "30%" }}>
                        { item.userDetails && item.userDetails.map((object, i) => {
                            return (
                                <div key={i} style={{position:'relative', display:'flex', justifyContent:'flex-start'}}>
                                    <ReactImageFallback
                                        src={object.avatar}
                                        fallbackImage={avatar}
                                        initialImage={avatar}
                                        alt="user avatar"
                                        className="user-photo achieveavatar circle responsive-img"
                                        
                                    />
                                    <span>{object.firstName + " " + object.lastName}</span>
                                </div>
                            );
                        })}
                    </td>
                    <td style={{display:'flex',justifyContent:'flex-start'}}>
                        {achievementBages(item.badge_id)}
                    </td>
                    <td>
                        {achievementTitle(item.requirementIds, item.requirement)}
                    </td>
                    <td className={secretDetails !== null ? "action-tbl disabled-link" : 'action-tbl' }>
                        {(editPermission.includes("Access - Achievements") === true) || (roleAllTrue === 1) ? 
                            <Link to="#" id={item._id} key={item._id} onClick={() => handleEditAchievement(item._id)} className="btn-action btn-edit"  data-tip={"Edit Achievement"} place="top" data-class="tooltips"></Link> : false
                            //
                        }
                        {(deletePermission.includes("Access - Achievements") === true) || (roleAllTrue === 1) ? 
                            <Link to="#"  className="btn-action btn-del" data-tip={"Delete Achievement"} place="top" data-class="tooltips"></Link> : false
                           // onClick={() => { deleteAttribute(item._id) }}
                        }
                        {(viewPermission.includes("Access - Achievements") === true) || (roleAllTrue === 1) ? 
                            <Link to="#"  className="btn-action btn-view" onClick={() => handleViewAchievement(item._id)} data-tip={"View Achievement"} place="top" data-class="tooltips"></Link> : false
                           // onClick={() => { deleteAttribute(item._id) }}
                        }
                        <ReactTooltip />
                    </td>
                    </tr>
                ))
                ) :
                <tr>
                    <td colSpan="12" style={{textAlign:'center'}}>
                        <img src={emptyIcon} className="empty" alt="empty"/>
                        <p style={{fontSize:'16px'}}>No Data Available</p>
                        <p style={{fontSize:'14px'}}>There are currently no data available</p>
                    </td>
                </tr>
                }
            </Then>
            <Else>
              <tr className="table-td-data">
                <td> 
                  <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                      <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                </td>
              </tr>
            </Else>
        </If>
      </>
    );
  };

  return (
    <AdminBadgestsPage>

    <DynamicContentHeaderWrapper
        buttonLabel="ADD ACHIEVEMENT"
        btnPath="/admin/achievements/create"
        entriesValue={entriesValue}
        data={props.achievements.items}
        location={props.location}
        searchs={handleSearchChange}
        filters={filterKeys}
        paginationPath={"/admin/achievements"}
        keys={filterkeys}
        originPage="Achievements"
        addPage="Access - Achievements"

      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={BadgesData()}
        originPage="Achievements"
        data={props.achievements}
        location={props.location}
        paginationPath={"/admin/achievements"}
        entriesItem={entriesValues}
        loading = {props.achievements.loading}
        search={search}
        filter={keys}
      />
    </AdminBadgestsPage>
  );
};


const mapStateToProps = ({quests, badges, achievements, users }) => ({
  quests,
  badges,
  achievements,
  users
});


const AchievementsPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Achievements", link: "/admin/achievements?page=1" },
        ]);
        break;
      case "create":
        setBreadCrumbs([
          { label: "Achievements", link: "/admin/achievements?page=1" },
          { label: "Create Achievement", link: "#" },
        ]);
        break;
        case "edit":
          setBreadCrumbs([
            { label: "Achievements", link: "/admin/achievements?page=1" },
            { label: "Edit Achievement", link: "#" },
          ]);
          break;
        default:
            case "view":
                setBreadCrumbs([
                  { label: "Achievements", link: "/admin/achievements?page=1" },
                  { label: "View Achievement", link: "#" },
                ]);
        // Error page
        break;         
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Achievement | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Access | Achievements | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : `Edit Achievement | ${process.env.REACT_APP_DOCUMENT_TITLE}`
        }
      >
        <PageWrapper className={cc(["AchievementsPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Access"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <AchievementsPageList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={['create', 'edit', 'view'].includes(props.mode)}>
              <CreateEditAhievement {...props } />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});

AchievementsPage.WrappedComponent.displayName = "AchievementsPage";
const AchievementsPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <AchievementsPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <AchievementsPage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <AchievementsPage {...props} mode="edit" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/view`}
      render={(props) => <AchievementsPage {...props} mode="view" />}
    />
  </Switch>
);
export default React.memo(AchievementsPageRoute);
