import React from 'react';
import cc from 'classcat';
import Chart from 'react-apexcharts';

import * as experience from '../_core/helpers/exp';

import './CurrentLevelWidget.scss';

import ranking from '../_templates/ranking-grayscale.png';
import dot from '../_templates/dot.png';

const CurrentLevelWidget = ({ engageMode = false, exp }) => {
    const level = React.useMemo(() => (
        experience.exp_level(exp)[0]
    ), [exp]);

    const targetPoints = React.useMemo(() => (
        experience.exp_targetPoints(level.current, level.next)[0]
    ), [level]);

    return (
        <div className="current-level-widget">
            <div className="current-level-title">
                <h6>Current level</h6><img src={dot} className="options" alt="options" />
            </div>
            <div
                className={cc({
                    'card': true,
                    'z-depth-0': true,
                    'engage-mode': engageMode
                })}
            >
                <div>
                    <Chart
                        height={250}
                        options={{
                            colors: ['#009900'],
                            labels: [level.current],
                            xaxis: {
                                categories: ['exp']
                            },
                            plotOptions: {
                                radialBar: {
                                    hollow: {
                                        size: '70%'
                                    },
                                    dataLabels: {
                                        show: true,
                                        name: {
                                            color: 'black',
                                            fontSize: 80,
                                            offsetY: 4
                                        },
                                        value: {
                                            show: false,
                                        }
                                    }
                                }
                            },
                            stroke: {
                                lineCap: 'round'
                            }
                        }}
                        series={[((exp - targetPoints.current) / (targetPoints.next - targetPoints.current)) * 100]}
                        type="radialBar"
                    />
                    <p className={cc({
                        'next-level': true,
                    })}>
                        <span>Next level:</span>
                        <br />
                        <span>{targetPoints.next} EXP</span>
                    </p>
                    {
                        engageMode === false &&
                        <p className="current-exp">EXP: {exp} / {targetPoints.next}</p>
                    }
                </div>
                {
                    engageMode === true &&
                    <div className="ranking">
                        <p>Ranking</p>
                        <div>
                            <img src={ranking} className="rank-badge-img" alt="badge" />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CurrentLevelWidget;