import React from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect, useDispatch } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch, useParams } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import { If, Else, Then,  } from 'react-if';
import moment from "moment";
import styled from "styled-components";
import avatar from '../../../_templates/man.png'
import emptyIcon from '../../../_templates/empty.png';
import Swal from 'sweetalert2';

import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent,DynamicTableAndPaginationWrapper, DynamicSearchWrapper } from "../../Components/PageWrapper/DynamicWrapper";
import { DynamicTableAction } from "../../Components/tableActions/tableActions";

import CreateEditQuestPage from './CreateEditQuestPage';
import { history } from "../../../_core/helpers/history";
import { questActions } from '../../../_core/actions/quest';
import  AddChallengeModal from "./AddChallengeModal";
import ViewChallengePage from "./ViewChallengePage";
import ViewParticipants from "./ViewParticipants";
import { userActions } from "../../../_core/actions/user";


const AdminQuestsPage = styled.div`
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        text-align: center;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        text-align: center;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;


const QuestsPageList = (props) => {
  const dispatch = useDispatch(); //eslint-disable-line
  const [entriesValues, setentriesValues] = React.useState();
  const [quests, setQuests] = React.useState([]);
  const [search, setNewSearch] = React.useState("");
  const [keys, setKeys] = React.useState("");
  const [showAppModal, setAppModal] = React.useState(false);// eslint-disable-line
  const [isAddQuestList, setIsAddQuestList] = React.useState(false)
  const [questItem, setQuestItem] =  React.useState("");

  const addPermission = JSON.parse(localStorage.getItem('addPermission'));
  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;


  let tableHeader = (addPermission.includes("Engagement - Challenges") === true) || (editPermission.includes("Engagement - Quests") === true) || (deletePermission.includes("Engagement - Quests") === true) || (roleAllTrue === 1) ? ["Title", "Description", "Qualifications", "Challenges", "Participants", "Valid till","Actions"] : ["Title", "Description", "Qualifications", "Challenges","Participants", "Valid till", ""];
  let filterkeys = ["questTitle", "questContent"];

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));
  

  const { page } = useParams();

    //show entries
    const entriesValue = (entries) => {
        setentriesValues(entries);
    }

    //search
    const handleSearchChange = (item) => {
      setNewSearch(item);
  	};
    
    //filters
    const filterKeys = (item) => {
        setKeys(item);
    };

    const handleChallenge = (item) => {
      setIsAddQuestList(true)
      setAppModal(true);
      setQuestItem(item)
    }

    const handleCloseModal =()=> {
      setAppModal(false)
  }

  React.useEffect(()=>{
    const timer = setTimeout(() => {
        setQuests(props.quests?.items?.items);
      }, 100);
      return () => clearTimeout(timer);
  },[props.quests])

  React.useEffect(() => {
    dispatch(userActions.getAllActiveUsers(1,100000,'',''));
    //eslint-disable-next-line
  }, [dispatch]);

  const updateQuest = (item) => {
    history.push(`/admin/quests/${item}/edit`)
  }

  const viewQuest = (item,name) => {
    history.push(`/admin/quests/${item}/view?page=1`)
    localStorage.setItem('questName', name)
  }

  const deleteQuest = (rewardId) => {

    const id = rewardId;

    const Toast = Swal.mixin({
      toast: true,
      width: 300,
      height: 500,
      background: '#D4EDDA',
      position: 'center',
      showConfirmButton: false,
      timer: 5000
    });
    Swal.fire({
      title: "<p class='swal-title-engagement'>Confirmation</p>",
      html:
        " <div style='margin:30px'><p class='alert-first'>Do you want to delete this Quest?</p></div>",
  
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#2A3143",
        customClass: {
          header: 'header-class',
          container: 'container-class',
          popup: 'popup-class-engagament',
          actions: 'actions-class-engagament',
          cancelButton: 'cancel-button-class',
          confirmButton: 'confirm-btn-engagement',
          content: 'E-alert-conent',
        },
        reverseButtons:true,
        cancelButtonText: "LEAVE",
        confirmButtonText:"DELETE",
        background: "#2E3549",
    }).then((result) => {
        if (result.value) {
      dispatch(questActions.deleteQuest(id)); 
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: '<span style="color:#0A551E"> Quest has been deleted.</span>',
        });
          dispatch(questActions.getAll())
      }, 100)
                
        }
    })
  
  }

  const questChallengeParticipants = (users) => {
            
    let numOfParticipants = users.length;
    let challengerLimit = 3;
    let otherParticipants = numOfParticipants > challengerLimit ? numOfParticipants - challengerLimit + ' others' : '';
    let setParticipants = props.users.users?.items && props.users.users?.items.filter(user => users.includes(user.details[0].user_id));

   return (
       <div className="rq-participants" style={{display:'inline-flex'}}>
           {numOfParticipants > 0 &&
           <div className="participants-imgs">
               {setParticipants && setParticipants.slice(0, challengerLimit).map((challenger, index) =>
                   <img key={index} className="img-participant" alt="" onError={(e) => { e.target.src = avatar }} src={challenger.details[0]?.avatar || avatar} style={{marginRight:'-10px'}}/>

               )}
           </div>
           }
           <span style={{marginLeft:'15px'}}>
               {numOfParticipants <= 0 && 'No Participants'}
               {otherParticipants && '+ ' + otherParticipants}
           </span>
       </div>
   );
};    
  
  const QuestsData = () => {
    let data = quests ?? [];
    let today = moment().utc();
    return (
      <>
        <If condition={props.quests.loading === false }> 
            <Then>
                    {data.length !== 0 ?  (
                data.map((item, index) => (
                    <tr key={index}>
                    <td>{item.questTitle}</td>
                    <td style={{ width: "400px" }}>{item.questContent}</td>
                    <td>{item.level !== null ? 'Level '+ item.level : '---'}</td>
                    <td>{item.questChallengeTotal}</td>
                    <td>
                        {questChallengeParticipants(item.participants)}
                    </td>
                    <td>{moment(today).isSameOrAfter(moment.utc(item.expiresAt)) ? 'Expired': item.expiresAt===null? '---' : moment.utc(item.expiresAt).fromNow(true)}</td>
                    {(addPermission.includes("Engagement - Challenges") === true) || (editPermission.includes("Engagement - Quests") === true) || (deletePermission.includes("Engagement - Quests") === true) || (roleAllTrue === 1) ?
                      <td className={secretDetails !== null ? "disabled-link" : '' }>
                          <div>
                          <DynamicTableAction
                              originPage="Quest"
                              iconSet="2"
                              editLabel="Edit Quest"
                              deleteLabel="Delete Quest"
                              addChallengeLabel="Add Challenge"
                              viewChallengeLabel="View Participants"
                              onClickAddChallenge={ (e) => {handleChallenge(item)}}
                              // completeLabel={item.status === "finished" ? "Complete Evaluation" : ""}
                              onClickEdit={() => updateQuest(item._id)}
                              // onClickDelete={() => deleteEvaluation(item?.data[0]?._id, index)}
                              onClickDelete={ (e) => {deleteQuest(item._id);}}
                              onClickView={ (e) => {viewQuest(item._id, item.questTitle)}}
                              addPage="Engagement - Challenges"
                              deletePermission="Engagement - Quests"
                              editPermission="Engagement - Quests"
                          />
                          </div>
                      </td> : false
                    }
                    </tr>
                ))
                ) :
                <tr>
                    <td colSpan="12" style={{textAlign:'center'}}>
                        <img src={emptyIcon} className="empty" alt="empty"/>
                        <p style={{fontSize:'16px'}}>No Data Available</p>
                        <p style={{fontSize:'14px'}}>There are currently no data available</p>
                    </td>
                </tr>
                }
            </Then>
            <Else>
              <tr className="table-td-data">
                <td> 
                  <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                      <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                </td>
              </tr>
            </Else>
        </If>
        <AddChallengeModal
            isOpen={showAppModal} 
            onRequestClose={() => setAppModal(false)} 
            toggle={setAppModal}
            list={isAddQuestList}
            listId={questItem}
            entriesItem={entriesValues}
            activePage={page}
            handleCloseModal={handleCloseModal}
        />
      </>
    );
  };

  return (
    <AdminQuestsPage>
      <DynamicSearchWrapper
        buttonLabel="ADD QUEST"
        btnPath="/admin/quests/create"
        entriesValue={entriesValue}
        data={props.quests.items}
        location={props.location}
        searchs={handleSearchChange}
        filters={filterKeys}
        keys={filterkeys}
        paginationPath={"/admin/quests"}
        originPage="Quest"
        addPage="Engagement - Quests"
      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={QuestsData()}
        originPage="Quest"
        data={props.quests.items}
        location={props.location}
        paginationPath={"/admin/quests"}
        entriesItem={entriesValues}
        search={search}
        loading = {props.quests.loading}
        filter={keys}
      />
    </AdminQuestsPage>
  );
};

const mapStateToProps = ({quests,users }) => ({
  quests,
  users
});

const QuestsPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])
  const questName = localStorage.getItem("questName");
  const {pageId } = useParams();

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Quests", link: "/admin/quests?page=1" },
        ]);
        localStorage.removeItem('questName');
        break;
      case "create":
        setBreadCrumbs([
          { label: "Quests", link: "/admin/quests?page=1" },
          { label: "Create Quest", link: "#" },
        ]);
        localStorage.removeItem('questName');
        break;
        case "edit":
          setBreadCrumbs([
            { label: "Quests", link: "/admin/quests?page=1" },
            { label: "Edit Quest", link: "#" },
          ]);
          localStorage.removeItem('questName');
          break;
        case "view":
            setBreadCrumbs([
              { label: "Quests", link: "/admin/quests?page=1" },
              { label: questName, link: "#" },
            ]);
            break; 
        case "participants":
            setBreadCrumbs([
                { label: "Quests", link: "/admin/quests?page=1" },
                { label: questName, link: `/admin/quests/${pageId}/view?page=1`  },
                { label: "Challenges", link: "#" },
              ]);         
            break;                               
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
     //eslint-disable-next-line
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Quests | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Engagement | Quests | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            :props.mode === "view"
            ? `View Quest| ${process.env.REACT_APP_DOCUMENT_TITLE}`
            :props.mode === "participants"
            ? `View Participants| ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : `Edit Quests | ${process.env.REACT_APP_DOCUMENT_TITLE}`
        }
      >
        <PageWrapper className={cc(["QuestsPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Engagement"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <QuestsPageList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={['create', 'edit'].includes(props.mode)}>
              <CreateEditQuestPage {...props } />
            </Case>
            <Case condition={['view'].includes(props.mode)}>
              <ViewChallengePage {...props } />
            </Case>
            <Case condition={['participants'].includes(props.mode)}>
              <ViewParticipants {...props } />
            </Case>             
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});

QuestsPage.WrappedComponent.displayName = "QuestsPage";
const QuestsPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <QuestsPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <QuestsPage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <QuestsPage {...props} mode="edit" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/view`}
      render={(props) => <QuestsPage {...props} mode="view" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/user/:userId/challenges`}
      render={(props) => <QuestsPage {...props} mode="participants" />}
    />    
  </Switch>
);
export default React.memo(QuestsPageRoute);
