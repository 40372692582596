import { postConstants } from '../constants/post';

export function allPosts(state = {}, action){
    switch(action.type){
        //get post
        case postConstants.GETALL_POST_REQUEST:
            return {
                loading: true
            };
        case postConstants.GETALL_POST_SUCCESS:
            return {
                items: action.allPosts
            };
        case postConstants.GETALL_POST_FAILURE:
            return {
                error: action.error
            };
        default:
            return state


    }
}

const initialState = {
    hasNextPage: false,
    items: [],
    loading: true,
    error: null,
}

export default function posts(state = initialState, action){
    switch(action.type){
        case postConstants.RESET_POSTS:
            return initialState;

        //get post
        case postConstants.GETALL_POST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case postConstants.GETALL_POST_SUCCESS:
            return {
                ...state,
                ...action.allPosts,
                hasNextPage: action.allPosts.items.length > 0,
                loading: false
            };
        case postConstants.GETALL_POST_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case postConstants.LOAD_MORE_POSTS_SUCCESS:
            return {
                ...state,
                ...action.posts,
                hasNextPage: action.posts.items.length > 0,
                items: [
                    ...state.items,
                    ...action.posts.items
                ],
                loading: false
            };
        case postConstants.SUBMIT_POST_SUCCESS:
            return {
                ...state,
                error: null,
                items: [
                    action.post,
                    ...state.items
                ]
            };
        case postConstants.SUBMIT_POST_FAILURE:
            return {
               error: action.error
            };
        default:
            return state
    }
}