//npm packages
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';

//import images
import avatar from '../_templates/man.png';
import imgNotFound from '../_templates/img-not-found.png';

//actions
import { userActions } from '../_core/actions/user';
//components
import {DisplayQuestChallenge} from './QuestChallengeCard';
//styles
import './QuestChallenge.sass';
//helpers
import { history } from '../_core/helpers/history';
//services
import { userChallengeService } from '../_core/services/userChallenge';

class QuestChallenge extends Component {
    constructor(props) {
        super(props);

        const user = JSON.parse(localStorage.getItem('userCredentials'));
        const questChallenges = JSON.parse(localStorage.getItem('questChallenges'));
        localStorage.removeItem('questChallenges');

        this.state = {
            currentUser:{
                _id: user && user.user._id
            },
            quest: questChallenges || '',
            challengerLimit: 3,
            challengers: questChallenges && questChallenges.questChallenges ? questChallenges.questChallenges : [],
            getExpDate: questChallenges && questChallenges.expiresAt ? questChallenges.expiresAt : '',
            isExpDateValid: questChallenges && moment(questChallenges.expiresAt).isValid(),
            setTimer: '',
            isExpired: false,
            userChallenges: [],
            load:false
        }
    }

    componentDidMount() {
        const { quest, currentUser } = this.state;

        if(!quest){
            history.push('/engage/quests');
        }

        this.props.dispatch(userActions.getAll(1, 100000));

        quest?.questChallenges?.length > 0 && quest.questChallenges.map((challenge)=>{
            try{
                userChallengeService.getByChallenge(currentUser._id, challenge._id).then((res) => {
                    if(res?.success?.length > 0){
                        const details = res.success[0];

                        challenge.status = details?.status || "new";
                        challenge.acceptedAt = details?.acceptedAt || null;
                        challenge.updatedAt = details?.updatedAt || null;
                        challenge.user = details?.userId || null;
                        this.setState({
                            userChallenges:[
                                ...this.state.userChallenges,
                                challenge
                            ]
                        })
                    }else{
                        challenge.status = "new";
                        challenge.acceptedAt = null;
                        this.setState({
                            userChallenges:[
                                ...this.state.userChallenges,
                                challenge
                            ]
                        })
                    }
                }).catch(err=>{
                    challenge.status = "new";
                    challenge.acceptedAt = null;
                    this.setState({
                        userChallenges:[
                            ...this.state.userChallenges,
                            challenge
                        ]
                    })
                });
            }catch(err){
                console.log(err)
            }
            return challenge
        });

        this.runTimer();
    }

    runTimer = () => {
        const { getExpDate, isExpDateValid } = this.state;

        if(isExpDateValid){
            var getTimer = setInterval(()=> {
                if (getExpDate) {
                    var now = moment().utc();
                    var hours = parseInt(moment.duration(moment(getExpDate).diff(now)).asHours());
                    var minutes = parseInt(moment.duration(moment(getExpDate).diff(now)).asMinutes()) % 60;
                    var seconds = parseInt(moment.duration(moment(getExpDate).diff(now)).asSeconds()) % 60;
    
                    const countdown = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');
                    this.setState({ setTimer: countdown})
    
                    if (moment(now).isSameOrAfter(moment(getExpDate))) {
                        clearInterval(getTimer);
                        this.setState({ setTimer: '00:00:00', isExpired: true})
                    }
                }else{
                    clearInterval(getTimer);
                    this.setState({ setTimer: '00:00:00', isExpired: true})
                }
            }, 1000);
            return () => clearInterval(getTimer)
        }
    }

    backupImages = () => {
        const { quest } = this.state;
        const totalChallengers = Number(quest?.totalParticipants) || 0;
        var elements = [];
        for(var i = 0; i < totalChallengers; i++){
            elements.push(<img className="img-participant"  alt="" src={avatar}/>)
        }
        return elements;
    }

    
    
    render() {
        const { quest, setTimer, isExpired, challengerLimit, userChallenges } = this.state;
        const { users } = this.props;
        const totalChallengers = Number(quest?.totalParticipants) || 0;
        
        return (
         <div className="quest-challenges">
            {quest &&
            <div className="row quest-details">
                <div className="card horizontal z-depth-0 col s12">
                    {quest.questImage && <img className="card-image" alt="" onError={(e)=>{e.target.src = imgNotFound}} src={quest.questImage || imgNotFound}/>}
                    {quest.questImage && <img className="qc-creator-image" alt="" onError={(e)=>{e.target.src = avatar}} src={quest.creator?.avatar || avatar}/>}
                    <div className="card-content">
                        <div className="quest-content-top">
                            <p className="quest-title">{quest.questTitle || 'Quest Title'}<span className="quest-timer">{setTimer}</span></p>
                            <p className="quest-content">{quest.questContent}</p>
                        </div>
                        <div className="quest-content-bottom">
                            <div className="quest-participants">
                                {quest.qcParticipants.length > 0 ?
                                <div className="participants-imgs">
                                    {quest.qcParticipants?.slice(0, challengerLimit).map((challenger, index)=>
                                    <img key={index} className="img-participant"  alt="" onError={(e)=>{e.target.src = avatar}} src={challenger.details[0]?.avatar || avatar}/>
                                    )}
                                </div>
                                :
                                <div className="participants-imgs">
                                    {this.backupImages()?.slice(0, challengerLimit)}
                                </div>
                                }
                                <span>
                                    {totalChallengers === 0 && 'No Challengers'}
                                    {totalChallengers > challengerLimit && '+ '}
                                    {totalChallengers > challengerLimit ? totalChallengers - challengerLimit : ''}
                                    {totalChallengers > challengerLimit && ' others'}
                                </span>
                            </div>
                            <p style={{fontWeight:'700'}}>EXP : {quest.exp}</p>
                            {!isExpired ?
                            <div className="quest-ch-cred">
                                <p>{quest.questChallengeTotal || 0} {quest.questChallengeTotal > 1 ? 'Challenges' : 'Challenge'}</p>
                                <p>{quest.rewardValue || quest.credits} {quest.rewardValue < quest.credits ? 'Credits' : 'Credit'}</p>
                            </div>
                            :
                            <div className="quest-ch-cred-exp">
                                <p>Quest Expired</p>
                            </div>
                            }
                            <small><i>Posted <b>{moment(quest.createdAt).fromNow(true)}</b> ago by <b>{quest.creator?.name || 'No name specified'}</b></i></small>
                        </div>
                    </div>
                </div>
                {!isExpired &&
                <div className="col s12 card-columns qc-container">
                   { quest.questChallenges.length > 0 ? <p> Quest Challenge </p> :

                        <div className="pragmahr-table-no-data">
                        <p className="no-data-available">No Quest Challenge Available</p>
                        </div> 
                        
                   }
                    <div className="qc-cards">
                        {userChallenges?.length > 0 && userChallenges?.map((challenge, index)=> 
                            <DisplayQuestChallenge key={index} questId={quest._id} getChallenge={challenge} getUsers={users} />
                        )}
                    </div>
                </div>
                }
            </div>
            }
         </div>
        )
    }
}

function mapStateToProps(state) {
    const { users } = state;
    return {  
        users: users?.users?.items?.length > 0 ? users.users.items : {},
    }
}

const  connectChallenges = connect(mapStateToProps)(QuestChallenge)
export { connectChallenges as QuestChallenge };