import axios from 'axios';
import handleError from './errorHandler';

const endpoint = process.env.REACT_APP_PRAGMAHR_API;
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

let headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'x-client-id': clientId,
  'x-client-secret': clientSecret,
}

const getHeaders = () => {
  //if we have logged in user stored. add token to header
  let user = JSON.parse(localStorage.getItem('userCredentials'));
  if (user && user.token) {
    headers = Object.assign(headers, { 
      'authorization': 'Bearer ' + user.token,
      'x-user-org': user.user.organization
     });
  }
  
  return {
    headers: headers
  }
};

// HTTP GET Request - Returns Resolved or Rejected Promise
export const get = (path: string) => {
  return new Promise((resolve, reject) => {
    axios.get(`${endpoint}${path}`, getHeaders())
      .then(response => {
        resolve({
          ok: true,
          text: () => Promise.resolve(JSON.stringify(response.data))
        })
      })
      .catch(error => { reject(handleError(error)) });
  });
};
// HTTP PATCH Request - Returns Resolved or Rejected Promise
export const patch = (path: string, data: any) => {
  return new Promise((resolve, reject) => {
    axios.patch(`${endpoint}${path}`, data, getHeaders())
      .then(response => {
        resolve({
          ok: true,
          text: () => Promise.resolve(JSON.stringify(response.data))
        })
      })
      .catch(error => { reject(handleError(error)) });
  });
};

// HTTP PUT Request - Returns Resolved or Rejected Promise
export const put = (path: string, data: any) => {
  return new Promise((resolve, reject) => {
    axios.put(`${endpoint}${path}`, data, getHeaders())
      .then(response => {
        resolve({
          ok: true,
          text: () => Promise.resolve(JSON.stringify(response.data))
        })
      })
      .catch(error => { reject(handleError(error)) });
  });
};

// HTTP POST Request - Returns Resolved or Rejected Promise
export const post = (path: string, data: any) => {
  return new Promise((resolve, reject) => {
    axios.post(`${endpoint}${path}`, data, getHeaders())
      .then(response => {
        resolve({
          ok: true,
          text: () => Promise.resolve(JSON.stringify(response.data))
        })
      })
      .catch(error => { reject(handleError(error)) });
  });
};
// HTTP DELETE Request - Returns Resolved or Rejected Promise
export const del = (path: string) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${endpoint}${path}`, getHeaders())
      .then(response => {
        resolve({
          ok: true,
          text: () => Promise.resolve(JSON.stringify(response.data))
        })
      })
      .catch(error => { reject(handleError(error)) });
  });
};