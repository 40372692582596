import { sectionConstants } from '../constants/section';
import { sectionServices } from '../services/section';

export const sectionActions = {
    getAllSection,
};

function getAllSection() {
    return dispatch => {
        dispatch(request());
        sectionServices.getAll()
            .then(
                section => dispatch(success(section)),
                error => dispatch(failure(error.toString())
            ));
    };

    function request() { return { type: sectionConstants.GETALL_SECTION_REQUEST } }
    function success(section) { return { type: sectionConstants.GETALL_SECTION_REQUEST_SUCCESS, section } }
    function failure(error) { return { type: sectionConstants.GETALL_SECTION_REQUEST_FAILED, error } }
}