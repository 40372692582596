export const missionConstants = {

	GETALL_MISSION_REQUEST: 'MISSION_GETALL_REQUEST',
    GETALL_MISSION_SUCCESS: 'MISSION_GETALL_SUCCESS',
    GETALL_MISSION_FAILURE: 'MISSION_GETALL_FAILURE',

    GETMISSION_REQUEST: 'MISSION_GET_REQUEST',
    GETMISSION_SUCCESS: 'MISSION_GET_SUCCESS',
    GETMISSION_FAILURE: 'MISSION_GET_FAILURE',

    START_REQUEST: 'START_MISSION_REQUEST',
    START_SUCCESS: 'START_MISSION_SUCCESS',
    START_FAILURE: 'START_MISSION_FAILURE',
};

