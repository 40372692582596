import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from "react-redux";
// import xorBy from 'lodash/xorBy';
import { If, Then, Else, Switch as SwitchCase, Case, Default, When } from 'react-if';
import isEmpty from 'lodash/isEmpty';
import { Link, Route, Switch } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import queryString from 'query-string';
import ReactImageCrop from 'react-image-crop';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import Pagination from 'react-js-pagination';
import avatar from "../../_templates/man.png";
import { checkSideNav } from '../Components/checkSideMenu';
import { DynamicHeaderContent } from "../Components/PageWrapper/DynamicWrapper";
import DocumentTitle from "react-document-title";
import { PageWrapper } from "../Components/PageWrapper/ContentWrapper";

import { userActions } from '../../_core/actions/user';
import teamActions from '../../_core/actions/teams';
import teamServices from '../../_core/services/teams';

import './TeamManagementPage.scss';
import { assignPage } from '../Pagination';

import Cookies from 'universal-cookie';
import emptyIcon from '../../_templates/empty.png';


const reactSelectCustomStyles = {
	control: (styles) => ({
		...styles,
		background: '#2E3648',
		borderColor: '#323A4F',
		height: 45,
		boxShadow: null,
	}),
	valueContainer: (styles, state) => ({
		...styles,
		height: 45,
		padding: '0 8px'
	}),

	input: (styles, state) => ({
		...styles,
		height: 45,
		margin: 0,
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	indicatorsContainer: (styles, state) => ({
		...styles,
		height: 45,
	}),
	menu: (styles, state) => ({
		...styles,
		background: "#2E3648",
	}),
	option: (provided, state) => ({
		...provided,
		background: state.isFocused === true ? "#323A4F " : "#2E3648"
	}),

};

// TODO: Refactor these components

const getCroppedLogo = (logo, crop) => {
	const canvas = document.createElement('canvas');
	const scaleX = logo.naturalWidth / logo.width;
	const scaleY = logo.naturalHeight / logo.height;
	canvas.width = crop.width;
	canvas.height = crop.height;
	const ctx = canvas.getContext('2d');

	ctx.drawImage(
		logo,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width,
		crop.height,
	);

	return canvas.toDataURL('image/png');
}

const getTeamMembersIds = (members, leadId) => (
	members?.filter(member => (
		member !== leadId
	)) ?? []
);

const getUserFullName = (users, userId) => {
	const user = users?.filter(user => (
		user._id === userId
	));

	return user[0]?.name;
}

const CreateEditTeamPage = (props) => {

	const { usersList, teamsList, teamToEdit, teams, users, mode, teamCreateUpdateSuccess } = props;

	const dispatch = useDispatch();

	const logoRef = React.useRef();
	const logoSelectorRef = React.useRef();

	const [crop, setCrop] = React.useState({
		aspect: 1 / 1,
		unit: '%',
		height: 100,
	});

	const [croppedLogo, setCroppedLogo] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [logo, setLogo] = React.useState('');
	const [lead, setLead] = React.useState(null);
	const [members, setMembers] = React.useState([]);
	const [draftMembers, setDraftMembers] = React.useState([]);
	const [prevLogo, setPrevLogo] = React.useState('');
	const [name, setName] = React.useState('');
	const [slogan, setSlogan] = React.useState('');
	const [currentTeamId, setCurrentTeamId] = React.useState('');

	const [nonLeadUsers, setNonLeadUsers] = React.useState([]);
	const [isSubmitted, setIsSubmitted] = React.useState(false);
	
	console.log(lead)

	const usersWithNoTeam = React.useMemo(() => (
		usersList ?
			orderBy(usersList.filter(user => (
				user._id !== lead?.value
			))
				.map(user => ({
					label: user.name,
					value: user._id
				})), 'label', 'asc') :
			[]
	), [usersList, lead]);
	
	const getNonLeadUsers = (users = [], teams = []) => {
		let leadUsers = [];
		let getTeamsId = teams?.filter(team => lead?.value ? team.lead !== lead.value : team)?.map(team => team.lead);

		users.forEach(user => {
			if (getTeamsId.includes(user._id)) {
				return
			}
			leadUsers.push(user);
		});

		return orderBy(
			leadUsers?.map(user => ({
				value: user._id,
				label: user.name,
			})),
			'label',
			'asc'
		);
	}

	const onSelectLogo = React.useCallback((e) => {
		e.preventDefault();
		logoSelectorRef.current.click();
	}, [logoSelectorRef]);

	const selectLogo = React.useCallback((event) => {
		const logo = event.target.files[0];

		if (logo === undefined) {
			return;
		}

		const reader = new FileReader();

		reader.addEventListener('load', () => {
			setCroppedLogo('');
			setLogo(reader.result)
		});
		reader.readAsDataURL(logo);

		logoSelectorRef.current.value = '';
	}, [logoSelectorRef, setCroppedLogo, setLogo]);

	const saveLogo = React.useCallback((e) => {
		e.preventDefault();
		setCroppedLogo(getCroppedLogo(logoRef.current, crop));
	}, [logoRef, crop, setCroppedLogo]);

	const submit = React.useCallback(
		(e) => {
			e.preventDefault();
			setIsSubmitted(true);

			let allMembersId = [
				lead?.value,
				...members.map(member => member.value)
			]

			if (isEmpty(name) || isEmpty(logo)) {
				return;
			}

			const formatLogo = new FormData();

			if (croppedLogo) {
				try {
					const formatImage = async () => formatLogo.append('file', await (await fetch(croppedLogo)).blob());
					formatImage();
				} catch (error) {
					console.error(error);
				}
			}

			switch (mode) {
				case 'create':

					const addTeam = {
						name,
						members: allMembersId,
						manager: lead.value,
						lead: lead.value,
						logo: croppedLogo ? formatLogo : '',
						rawLogo: croppedLogo,
						slogan,
						description,
					}

					dispatch(teamActions.create(addTeam));

					break;

				case 'edit':

					const getPrevMembersId = draftMembers;
					const membersToBeRemoved = getPrevMembersId.filter(member => allMembersId.includes(member) === false).map(member => member);
					const newMembersToAdd = allMembersId.filter(member => getPrevMembersId.includes(member) === false).map(member => member);

					const editTeam = {
						name,
						members: newMembersToAdd,
						manager: lead.value,
						lead: lead.value,
						logo: formatLogo,
						rawLogo: croppedLogo,
						slogan,
						description,
						removedMembers: membersToBeRemoved,
					}

					dispatch(teamActions.update(currentTeamId, editTeam));

					break;
				default:
					break;
			}

		},
		//eslint-disable-next-line	
		[
			croppedLogo,
			description,
			dispatch,
			lead,
			draftMembers,
			members,
			name,
			setIsSubmitted,
			slogan,
			props.match,
			props.history,
			props.location,
			mode,
			teams,
			teamCreateUpdateSuccess,
			currentTeamId
		]
	);

	const cancel = React.useCallback((e) => {
		e.preventDefault();

		if (props.location.state?.from) {
			props.history.goBack();
		} else {
			props.history.push(`/admin/team-management?page=1&items-per-page=20`);
		}

	}, [props.history, props.location]);


	const remove = React.useCallback((e) => {
		let newMembers = members.filter(function(person) { 
			return person.value !== e 
		})
		setMembers(newMembers)
		// eslint-disable-next-line
	}, [members,users]); //remove members from the list.

	React.useEffect(() => {

		if (teamCreateUpdateSuccess) {
			if (props.location.state?.from) {
				props.history.goBack();
			} else {
				props.history.push('/admin/team-management?page=1&items-per-page=20');
			}
			dispatch(userActions.getAll(1, 100000));
			dispatch(teamActions.getAll(10, 1));
		}
		//eslint-disable-next-line
	}, [teamCreateUpdateSuccess, dispatch, props.history, props.location.state]);

	// Set initial values
	React.useEffect(() => {

		setNonLeadUsers(
			getNonLeadUsers(usersList, teamsList, teamToEdit?._id ? teamToEdit.lead : lead?.value)
		);

		if (mode === 'edit') {
			if (teamToEdit?._id) {
				setCurrentTeamId(teamToEdit._id);
				setName(teamToEdit.name);
				setSlogan(teamToEdit.slogan);
				setDescription(teamToEdit.description);
				setLogo(teamToEdit.logo);
				setPrevLogo(teamToEdit.logo);
				setMembers(
					usersList?.filter(user => teamToEdit?.users?.includes(user._id)).map((member) => ({
						label: member.name,
						value: member._id
					}))
				);

			} else {
				let getTeam = teamsList?.filter(team => team._id === props.match.params.teamId);
				setCurrentTeamId(getTeam[0]?._id)
				setName(getTeam[0]?.name);
				setSlogan(getTeam[0]?.slogan);
				setDescription(getTeam[0]?.description);
				setLogo(getTeam[0]?.logo);
				setPrevLogo(getTeam[0]?.logo);
				setMembers(
					usersList?.filter(user => getTeam[0]?.users?.includes(user._id) && user._id !== getTeam[0]?.lead).map((member) => ({
						label: member.name,
						value: member._id
					}))
				);
			}
		}
	}, [props.match, users, teams, mode, teamsList, teamToEdit, usersList, lead]); //eslint-disable-line

	// Pre-select lead
	React.useEffect(() => {
		if (mode === 'edit') {
			const lead = teamToEdit?._id ? (
				usersList?.filter(user => user._id === teamToEdit.lead)?.[0]
			)
				: (
					usersList?.filter(user => user._id === teamsList?.filter(team => team._id === props.match.params.teamId)[0]?.lead)?.[0]
				);

			setLead(
				lead ?
					{
						label: lead.name,
						value: lead._id
					} :
					null
			)
		}
	}, [users, teams, props.match, teamToEdit, usersList, mode, teamsList])

	// Set draft members	
	React.useEffect(() => {
		var draftMembers = [];

		if (mode === 'edit') {
			draftMembers = teamToEdit?._id ?
				teamToEdit?.users
					?.map(member => member)
				:
				teamsList
					?.filter(team => team._id === props.match.params.teamId)[0]?.users
					?.map(member => member)
		}

		setDraftMembers(draftMembers);
	},
		[
			members,
			teams,
			users,
			props.match,
			setDraftMembers,
			teamToEdit,
			usersList,
			teamsList,
			mode
		]
	);

	// Remove lead from selected members
	React.useEffect(() => {
		setMembers(members => members.filter(member => teamToEdit?._id ? member?.value !== teamToEdit?.lead : member?.value !== lead?.value));
	}, [lead, setMembers, teamToEdit]);


	return (
		<BodyBackgroundColor backgroundColor='#1C2233'>
			<form className='formCreate'>
				{/* <p className="team-create-float-title"> {mode === 'create' ? "Create" : "Edit"} Team</p> */}

				<div className={(isSubmitted && isEmpty(name) ? ' has-error' : '')} id="">
					<div className="floating-label attrName" style={{ marginTop: "-20px" }}>
						<input
							id="team_name"
							className="floating-input"
							type="text"
							placeholder=" "
							name="name"
							value={name}
							onChange={(event) => { setName(event.target.value) }}
						/>
						<label>Team Name</label>
						{isSubmitted && isEmpty(name) &&
							<div className="help-block red-text" data-error="wrong" data-success="right">Team Name is required</div>
						}
					</div>
				</div>

				<Select
					classNamePrefix="react-select"
					id="lead"
					isClearable
					onChange={setLead}
					options={nonLeadUsers}
					styles={reactSelectCustomStyles}
					value={lead}
					placeholder="Lead"
				/>

				<Select
					classNamePrefix="react-select"
					id="members"
					isClearable={false}//set to false to remove x button.
					isMulti
					onChange={setMembers}
					options={usersWithNoTeam}
					styles={reactSelectCustomStyles}
					value={members}
					controlShouldRenderValue={ false } //set to false to remove selected item.
					placeholder="Members"
				/>

				{members.length !== 0 ? (
					<table className="responsive-table">
					<thead>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Position</th>
							<th>Department</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{
							members.length !== 0 ? (
								members.map(item => (
									users.items?.map((val) => val._id ===  item.value ? (
										// console.log(val.details)
										<tr key={val._id}>
											<td><img src={val.details[0].avatar} alt="avatar" style={{width: '25px', borderRadius: '1em'}} onError={(e) =>  (e.target.src = avatar)}/></td>
											<td>{`${val.details[0].firstName} ${val.details[0].lastName}`}</td>
											<td>{val.details[0].position ? val.details[0].position  : '---'}</td>
											<td>{val.details[0].department ? val.details[0].department : '---'}</td>
											<td><button className="cancel" onClick={(e) => {
												e.preventDefault();
												remove(val._id)
											}}>Remove</button></td>
										</tr>
									) : '')
								))
							) : (
								''
							)
						}
					</tbody>
				</table>
				) : (
								''
							)
					
					}

				<div className={(isSubmitted && isEmpty(slogan) ? ' has-error' : '')} id="">
					<div className="floating-label attrName">
						<input
							id="slogan"
							className="floating-input"
							type="text"
							placeholder=" "
							name="slogan"
							value={slogan}
							onChange={(event) => { setSlogan(event.target.value) }}
						/>

						<label>Slogan</label>
					</div>
				</div>


				<label htmlFor="description">Description</label>
				<textarea
					name="description"
					id="description"
					rows="2"
					onChange={(event) => { setDescription(event.target.value) }}
					value={description}
					placeholder="Description"
				/>

				<label>
					Logo *
					<When condition={isSubmitted && isEmpty(croppedLogo)}>
						<span className="helper-text red-text" style={{ marginLeft: 10 }}>
							Team logo is required
						</span>
					</When>
				</label>

				<div
					className={cc(
						[
							'file-upload',
							{ 'solid': logo !== '' }
						]
					)}
				>
					<input
						accept="image/x-png, image/png, image/jpg, image/jpeg"
						id="logo"
						multiple={false}
						onChange={selectLogo}
						ref={logoSelectorRef}
						style={{ display: 'none' }}
						type="file"
					/>

					<If condition={logo === ''}>
						<Then>
						<div  style={{ display: 'grid', justifyContent: 'center' }}>
							<p>Drag and drop files here</p>
							<button type="button" onClick={onSelectLogo} className="custom-file-upload">
									UPLOAD
							</button>
						</div>
							{/* <p>
								<a href="#select-logo" onClick={onSelectLogo}>Select</a>
								<span> a logo for your team</span>
							</p> */}
						</Then>
						<Else>saveLogo
							<If condition={croppedLogo === ''}>
								<Then>
									<p>Crop your logo</p>
									<ReactImageCrop
										crop={crop}
										imageStyle={{ maxHeight: 400 }}
										keepSelection
										minHeight="100"
										src={logo}
										onChange={crop => { setCrop(crop) }}
										onImageLoaded={logo => { logoRef.current = logo }}
									/>
									<div className="action">
										<button className="cancel" onClick={onSelectLogo} style={{ width: "200px" }}	>Choose another logo</button>
										{prevLogo !== logo &&
											<button className="blue-default" onClick={saveLogo}>Save</button>
										}
									</div>
								</Then>
								<Else>
									<img src={croppedLogo} alt="" />
									<div className="action">
										<button className="cancel" onClick={onSelectLogo} style={{ width: "200px" }}>Choose another logo</button>
										<button
											className="blue-default"
											onClick={(e) => {
												e.preventDefault();
												setCroppedLogo('')
											}}
										>
											Re-crop image
										</button>
									</div>
								</Else>
							</If>
						</Else>
					</If>
				</div>
				{isSubmitted && isEmpty(logo) &&
					<div className="help-block red-text" data-error="wrong" data-success="right">Team Logo is required</div>
				}
				<div className="action">
					<button className="cancel" onClick={cancel}>Cancel</button>
					<button className="blue-default" onClick={submit}>Submit</button>
				</div>
			</form>
		</BodyBackgroundColor>
	);
}

const mapStateToProps = ({ teams, users }) => {

	let getNames = users?.users?.items?.map(user => {
		return {
			name: [user.details[0].firstName, user.details[0].lastName].join(" ").trim(),
			_id: user._id
		}
	});

	return ({
		loading: (teams.loading && users.loading) ?? true,
		teams,
		users: users.users ?? {},
		usersList: getNames || [],
		teamCreateUpdateSuccess: teams?.success || false,
	})
};

const TeamManagementPage = connect(mapStateToProps)((props) => {
	const dispatch = useDispatch();

	const [currentValue, setCurrentValue] = React.useState(10)
	const [totalData, setTotalData] = React.useState(0);
	const [ActivePage, setActivePage] = React.useState(0);
	const [GetTotalPerpage, setGetTotalPerpage] = React.useState(10); // eslint-disable-line

	const [teamToEdit, setTeamToEdit] = React.useState([]);
	const [teamsList, setTeamsList] = React.useState([]);

	const [update, setUpdate] =  React.useState(false);
    const cookies = new Cookies();
	let admin = JSON.parse(localStorage.getItem('userCredentials'));

	const page = React.useMemo(() => {
		return parseInt(queryString.parse(props.location.search).page ?? 1, 1);
	}, [props.location]);

	//const [itemsPerPage] = React.useState(10);
	const currentPage = props.teams.page

	React.useEffect(() => {
		teamServices.getAll(1000000, 1).then(response => setTeamsList(response?.items))
			.catch(error => console.log(error));
	}, []);

	React.useEffect(() => {
		let itemPerPage = cookies.get("Teams:"+admin.user._id) ? cookies.get("Teams:"+admin.user._id) : currentValue;
		dispatch(teamActions.getAll(itemPerPage, page));
		dispatch(userActions.getAll(1, 100000));
		//initialize page number in pagination fn
		assignPage.page = 1
	}, [dispatch, page, currentValue, update, admin.user._id]); //eslint-disable-line

	React.useEffect(()=>{
        let newItems = cookies.get("Teams:"+admin.user._id)
        if(update !== false){
           cookies.set("Teams:"+admin.user._id, currentValue, { path: "/", sameSite:'lax' })
            setUpdate(false)
            setGetTotalPerpage(cookies.get("Teams:"+admin.user._id))
        }else{
            setGetTotalPerpage(newItems ? newItems : currentValue);
            setUpdate(false)
        }
        
    },[props,currentValue,update,cookies,admin.user._id]);

	const InputArrows = ({ topArrowClick, bottomArrowClick }) => {
		return (
			<div className="arrows-component">
				<div className="arrows">
					<button onClick={topArrowClick}>
						<i className="fa fa-angle-up" aria-hidden="true" style={{cursor:'pointer'}}></i>
					</button>
					<button onClick={bottomArrowClick}>
						<i className="fa fa-angle-down" aria-hidden="true" style={{cursor:'pointer'}}></i>
					</button>
				</div>
			</div>
		);
	};

	const changePage = (assignPage) => {
		let itemPerPage = cookies.get("Teams:"+admin.user._id) ? cookies.get("Teams:"+admin.user._id) : currentValue;
		if (assignPage.page !== currentPage) {
			dispatch(teamActions.getAll(itemPerPage, assignPage.page));
		}
	}

	// console.log(props.teams?.items)

	const topArrowClicked = (e) => {
		if (currentValue < 50){
			if(currentValue === 20){
				setCurrentValue(prevState => prevState + 30);
				setUpdate(true)
			}else{
				setCurrentValue(prevState => prevState + 10);
				setUpdate(true)
			}
		}
	}
	const bottomArrowClicked = (e) => {
		if (currentValue > 10){

			if(currentValue === 50){
				setCurrentValue(prevState => prevState - 30);
				setUpdate(true)
			}else{
				setCurrentValue(prevState => prevState - 10);
				setUpdate(true)
			}
		}
	}

	const handlePageChange = (page) => {
		changePage({ page: page });
	}

	React.useEffect(() => {
		setTotalData(props.teams.total)
		setActivePage(props.teams.page)
	}, [props])


	let fromPage = ((ActivePage - 1) * GetTotalPerpage) + 1;
	let toPage = Math.min((ActivePage - 1) * GetTotalPerpage + GetTotalPerpage, totalData);

	let secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

	const addPermission = JSON.parse(localStorage.getItem('addPermission'));
	const editPermission = JSON.parse(localStorage.getItem('editPermission'));
	const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;


	const [breadCrumbs, setBreadCrumbs] = React.useState([])

	console.log(props.teams)
  
	const filterBreadCrumbs = (mode) => {
		switch (mode) {
				case "list":
					setBreadCrumbs([
						{  label: "List of Teams", link: "/admin/team-management?page=1" },
					]);
					break;
				case "create":
					setBreadCrumbs([
						{  label: "List of Teams", link: "/admin/team-management?page=1" },
						{ label: "Create Team", link: "/admin/team-management/create"},
					]);
					break;
				case "edit":
					setBreadCrumbs([
						{  label: "List of Teams", link: "/admin/team-management?page=1" },
						{  label: "Edit Team", link: "/admin/team-management/edit" },
					]);
					break;
					default:
					// Error page
					break;
				}
};

	React.useEffect(() => {
		filterBreadCrumbs(props.mode);
	}, [props.mode]);

	return (
		<BodyBackgroundColor backgroundColor='#1C2233'>
			<PageWrapper className={cc(['team-management-page', checkSideNav()?.trim()])}>
				<DocumentTitle
						title={
								props.mode === "create"
										? `Create Team | ${process.env.REACT_APP_DOCUMENT_TITLE}`
										: props.mode === "edit"
												? `Edit Team | ${process.env.REACT_APP_DOCUMENT_TITLE}`
												: `Teams | Team | ${process.env.REACT_APP_DOCUMENT_TITLE}`
						}
				/>
				<DynamicHeaderContent
					TitlePage="Teams"
					breadCrumbs={true}
					breadCrumbsPages={breadCrumbs}
				/>
				<If condition={props.loading}>
					<Then>
						<div className="center">
							<img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
						</div>
					</Then>
					<Else>
						<div className="table-card" data-mode={props.mode}>
							<When condition={['create', 'edit'].includes(props.mode) === false}>
								<div className="table-header">
								{
									(addPermission.includes("Teams") === true) || (roleAllTrue === 1) ?
									<Link
									to={{
										pathname: '/admin/team-management/create',
										state: {
											from: '/admin/team-management/create'
										}
									}}
									className={secretDetails !== null ? "darkmode-btn-default disabled-link" : 'darkmode-btn-default' }>
									<p>CREATE TEAM</p>
								</Link> : false
								}
								
									

									<div className="block">
										<span className="darkmode-btn-default entries"> Show Entries </span>
										<div className="custom-input">
											<input type="text" className="entries-btn" name="clicks" value={GetTotalPerpage ? GetTotalPerpage : currentValue} pattern="[0-9]*" style={{pointerEvents:'none', color: '#9ba3ca'}}/>
											<span className="arrows">
												<InputArrows
													topArrowClick={topArrowClicked}
													bottomArrowClick={bottomArrowClicked}
												/>
											</span>
										</div>
									</div>
								</div>
							</When>

							<SwitchCase>
								<Case condition={['create', 'edit'].includes(props.mode)}>
									<CreateEditTeamPage {...props} teamToEdit={teamToEdit} teamsList={teamsList} />
								</Case>
								<Default>
									<table className="responsive-table">
										<thead>
											<tr>
												<th></th>
												<th>Name</th>
												<th>Lead</th>
												<th>Members</th>
												<th>Status</th>
												{(editPermission.includes("Teams") === true) || (roleAllTrue === 1) ?
													<th style={{ textAlign: 'center' }}>Actions</th> : false
												}
											</tr>
										</thead>
										<tbody>
											{
												props.teams.loading !== false ?
													<>
														<tr className="table-td-data">
														<td> 
															<div><span style={{marginRight: '50px'}}>Loading Data...</span>
																<img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
															</div>
														</td>
														</tr>
													</>
												:
												props.teams.total !== 0 ? (

													props.teams?.items?.map(team => (
														<tr key={team._id}>
															<td><img src={team.logo} alt="" style={{width: '25px', borderRadius: '1em'}} onError={(e) =>  (e.target.src = avatar)}/></td>
															<td>{team.name}</td>
															<td>{getUserFullName(props.usersList, team.lead)}</td>
															<td>{getTeamMembersIds(team.users, team.lead)?.length}</td>
															{
																team.active === 1 ?
																	<td style={{ color: "#45C389" }}>Active</td> :
																	<td style={{ color: "#F14646" }}>Inactive</td>
															}
															{(editPermission.includes("Teams") === true) || (roleAllTrue === 1) ?
																	<td className={secretDetails !== null ? "disabled-link" : '' } style={{ textAlign: 'center' }}>
																		<Link to={`${props.match.path}/${team._id}/edit`}>
																			<button
																				className="edit-team"
																				data-class="tooltips"
																				data-tip={`Edit ${team.name}`}
																				place="top"
																				style={{ cursor: "pointer" }}
																				onClick={() => setTeamToEdit(team)}
																			>
																				EDIT
																			</button>
																		</Link>
																		<ReactTooltip />
																	</td> : false
															}
														</tr>
													))
												)
												:

												(
													<>
														<tr>
															<td colSpan="12" style={{textAlign:'center'}}>
																<img src={emptyIcon} className="empty" alt="empty"/>
																<p style={{fontSize:'16px'}}>No Data Available</p>
																<p style={{fontSize:'14px'}}>There are currently no data available</p>
															</td>
														</tr>
													</>
												)
												
											}
											
										</tbody>
									</table>

									<div className="table-footer" data-mode={props.mode}>

										{totalData !== 0 && <div className="dataCount"> Showing {fromPage} to {toPage} of {totalData} entries  </div>}

										<Pagination
											activeClass="pagination_navigtion_active"
											itemClass="pagination_navigtion"
											linkClass="pagination_navigtion_link"

											hideDisabled
											hideFirstLastPages
											prevPageText='Previous'
											nextPageText='Next'
											pageRangeDisplayed={10}
											activePage={ActivePage}
											itemsCountPerPage={GetTotalPerpage}
											totalItemsCount={totalData}
											onChange={(e) => handlePageChange(e)}
										/>
									</div>
								</Default>
							</SwitchCase>
						</div>
					</Else>
				</If>
			</PageWrapper>
		</BodyBackgroundColor>
	);
});

TeamManagementPage.WrappedComponent.displayName = 'TeamManagementPage';

const TeamManagementPageRoute = (props) => (
	<Switch>
		<Route
			exact
			path={props.match.path}
			render={(props) => <TeamManagementPage {...props} mode="list" /> }
		/>
		<Route
			exact
			path={`${props.match.path}/create`}
			render={(props) => <TeamManagementPage {...props} mode="create" />}
		/>
		<Route
			exact
			path={`${props.match.path}/:teamId/edit`}
			render={(props) => <TeamManagementPage {...props} mode="edit" />}
		/>
	</Switch>
);

export default React.memo(TeamManagementPageRoute);