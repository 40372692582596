import React from 'react';
import { Link } from 'react-router-dom';
import MainNav from '../Components/MainNav';

import M from 'materialize-css/dist/js/materialize.min.js'

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';

import BodyBackgroundColor from 'react-body-backgroundcolor'

import './CreateChallenge.sass'

registerPlugin(FilePondPluginImagePreview);

class CreateChallengePage extends React.Component {
    componentDidMount() {
        var Select = document.querySelectorAll('select');
            M.FormSelect.init(Select);
    }

    render() {
        return (
        	<BodyBackgroundColor backgroundColor='#ffffff'>
       			<div className="create-challenge-page">
        			<MainNav/>
        			<div className="row">
	        			<div className="col s12 m6 offset-m3">
	        				<h6 className="challenges"> Challenge Management </h6>
		        			<div className="bg-create-challenge">
								<h6 className="create-challenges"> Create Challenge </h6>
		        				<hr/>
		        				<div className="container">
		        					<form name="form">
		                                <div className="input-field">
		                                    <label htmlFor="challenges" className="challenge-name">Challenge Name</label>
		                                    <input type="text" className="form-control validate" name="roleName" id="challenge"/>
		                                </div>
		                                <div className="input-field">
		                                	<label htmlFor="textarea1" className="descp">Description</label>
								        	<textarea id="textarea1" className="materialize-textarea"></textarea>
								        </div>
								        <div className="input-field col s12 m12 l6">
										    <select>
										      <option value="1">Easy</option>
										      <option value="2">Option 2</option>
										      <option value="3">Option 3</option>
										    </select>
										    <label htmlFor="difficulty">Difficulty</label>
										</div>
										<div className="input-field col s12 m12 l6">
										    <select>
										      <option value="1">Easy</option>
										      <option value="2">Medium</option>
										      <option value="3">Hard</option>
										    </select>
										    <label htmlFor="type">Type</label>
										</div>
										<div className="input-field col s12 m12 l6">
											<label className="wager-points">Wager Points</label>
											<input type="number" className="form-control validate" id="wager-points"  min="1" max="30"/> 
										</div>
										<div className="input-field col s12 m12 l6">
										    <select>
										      <option value="1">Sample</option>
										      <option value="2">Option 2</option>
										      <option value="3">Option 3</option>
										    </select>
										    <label>Required Achievements</label>
										</div>
										<label className="img-lbl">Image:</label>
										<div className="file-field input-field">
									    	<FilePond allowMultiple={false} maxFiles={1} server='null'/>
									    </div>
									    <div className="left-align">
	                                        <button className="waves-effect waves-light btn btn-create">Create</button>
	                                        <Link to="#" className="waves-effect waves-light btn red btn-cancel">Cancel</Link>
                                   		 </div>
		                            </form>
		        				</div>
		        			</div>
		        		</div>
	        		</div>
				</div>
			</BodyBackgroundColor>
        );
    }
}

export { CreateChallengePage };
