import { coeConstants } from '../constants/coe';


let initialState = {
  coe: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function coes(state = initialState, action){
    switch(action.type){
        
        //POST COE
        case coeConstants.SEND_COE_REQUEST:
          return {saving: true};
        case coeConstants.SEND_COE_SUCCESS:
          return {};
        case coeConstants.SEND_COE_FAILURE:
          return {error: action.error};
        //GETALL COE
        case coeConstants.GETALL_COEREQUEST_REQUEST:
          return {
            ...state,
            loading: true
          };
        case coeConstants.GETALL_COEREQUEST_SUCCESS:
            return {
            ...state,
            loading: false,
            error: null,
            coe: action.coe,
            totalPerPage:  action.coe.total,
            perPage: action.coe.pages,
            currentPage: action.coe.page,
          }
        case coeConstants.GETALL_COEREQUEST_FAILURE:
          return {
            error: action.error
          };
        //GET USER
        case coeConstants.GETUSER_REQUEST: 
          return {
            loading: true
          };
        case coeConstants.GETUSER_SUCCESS:
          return {
            coe: action.coe
          };
        case coeConstants.GETUSER_FAILURE:
          return {
            error: action.error
          };

        default:
          return state

    }
}