import React, { useEffect }from 'react'
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
import { checkSideNav } from '../../Components/checkSideMenu';
import { Route, Switch } from 'react-router-dom';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import moment from 'moment';

import { userActions } from '../../../_core/actions/user';
import { leaveActions } from '../../../_core/actions/leave';
import { challengeActions } from '../../../_core/actions/challenge';
import { questActions } from '../../../_core/actions/quest';
import { sessionActions } from '../../../_core/actions/session';
import { roleActions } from '../../../_core/actions/role';
// import { peopleActions } from '../../../_core/actions/people';
import eventsActions from '../../../_core/actions/events';

import  ProfileWidget  from '../../AdminWidget/ProfileWidgetAdmin/ProfileWidget';
// import  AttributesWidget  from '../../AdminWidget/AttributesWidget/AttributesWidget';
import  UserLogsWidget  from '../../AdminWidget/UserLogsWidget/UserLogsWidget';
import  LeaderBoardsDashboard  from '../../AdminWidget/LeaderBoardsDashboard/LeaderBoardsDashboard';
import  ActivityWidget  from '../../AdminWidget/ActivityWidget/ActivityWidget';
import  StatisticChart  from '../../AdminWidget/StatisticChart/StatisticChart';
import { permissionActions } from '../../../_core/actions/permission';

import  './dashboard.scss';

const mapStateToProps = ({ challenges, events, users, leaves, roles, quests, session, permissions}) => ({
    challenges,
    events,
    users,
    leaves,
    roles,
    quests,
    session,
    permissions,
});


const DashboardPage = connect(mapStateToProps)((props) => {
  
    const dispatch = useDispatch();
    // const localUserDetails = JSON.parse(localStorage.getItem("userCredentials"));

    
    useEffect(() => {
        // dispatch(userActions.getAllActiveUsers(1, 100000));
        dispatch(userActions.getAll(1, 999));
        dispatch(roleActions.getAll(1, 99));
        dispatch(leaveActions.getAll(1, 20));
        dispatch(challengeActions.getAll());
		dispatch(questActions.getAll());
		dispatch(eventsActions.getAll());
		dispatch(sessionActions.getAll());
        dispatch(permissionActions.getAll(1,1000));
    }, [dispatch])



    return (
        <div
            className={cc([
                'mainDashboard_Admin',
                checkSideNav()?.trim()
            ])}
        >   
    
            <div className="dashboard_header_admin">
                <div className="title_header_dashboard">
                   <p>Dashboard</p>
                </div>

                <div className="header_date_admin">
                    {moment().format("dddd MMMM DD, YYYY")}
                </div>
            </div>

            <BodyBackgroundColor backgroundColor='#1C2233'>
                <div className='dashboard_container_grid'>
                    {props.users.loading  && props.leaves.loading ?

                        <div style={{textAlign: "center"}}>
                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </div>
                        :
                        <>
                            <div className="left-content">
                                <ProfileWidget {...props} />
                                {/* <AttributesWidget userId={localUserDetails.user._id} /> */}
                                <ActivityWidget  {...props}></ActivityWidget>
                        
                            </div>
                            <div className="right-content">
                                {/* New Applicants, Leave Application, Active People */}
                                <UserLogsWidget {...props}></UserLogsWidget>
                                
                                <StatisticChart {...props}></StatisticChart>
                                <LeaderBoardsDashboard {...props}></LeaderBoardsDashboard> 
                            </div>
                        </>
                    }
                </div>
            </BodyBackgroundColor>

        </div>
    )
});

DashboardPage.WrappedComponent.displayName = 'DashboardPage';
const DashboardPageRoute = (props) => (
	<Switch>
        <Route
			exact
			path={`${props.match.path}`}
			render={(props) => <DashboardPage {...props} mode="list" />}
		/>
		
	</Switch>
);
export default React.memo(DashboardPageRoute);