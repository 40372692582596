export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    USER_DETAIL_REQUEST: 'USER_REQUEST',
    USER_DETAIL_SUCCESS: 'USER_SUCCESS',
    USER_DETAIL_FAILURE: 'USER_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GET_REQUEST: 'USERS_GET_REQUEST',
    GET_SUCCESS: 'USERS_GET_SUCCESS',
    GET_FAILURE: 'USERS_GET_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GETALLACTIVE_REQUEST: 'USERS_GETALLACTIVE_REQUEST',
    GETALLACTIVE_SUCCESS: 'USERS_GETALLACTIVE_SUCCESS',
    GETALLACTIVE_FAILURE: 'USERS_GETALLACTIVE_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    ASSIGNROLE_REQUEST: 'USERS_ASSIGNROLE_REQUEST',
    ASSIGNROLE_SUCCESS: 'USERS_ASSIGNROLE_SUCCESS',
    ASSIGNROLE_FAILURE: 'USERS_ASSIGNROLE_FAILURE' ,
    
    UPDATEPROFILE_REQUEST: 'USERS_UPDATEPROFILE_REQUEST',
    UPDATEPROFILE_SUCCESS: 'USERS_UPDATEPROFILE_SUCCESS',
    UPDATEPROFILE_FAILURE: 'USERS_UPDATEPROFILE_FAILURE',

    CHANGEPASSWORD_REQUEST: 'USERS_CHANGEPASSWORD_REQUEST',
    CHANGEPASSWORD_SUCCESS: 'USERS_CHANGEPASSWORD_SUCCESS',
    CHANGEPASSWORD_FAILURE: 'USERS_CHANGEPASSWORD_FAILURE',
    
    RESETPASS_REQUEST: 'USERS_RESETPASS_REQUEST',
    RESETPASS_SUCCESS: 'USERS_RESETPASS_SUCCESS',
    RESETPASS_FAILURE: 'USERS_RESETPASS_FAILURE' ,

    UPLOADAVATAR_REQUEST: 'USERS_UPLOADAVATAR_REQUEST',
    UPLOADAVATAR_SUCCESS: 'USERS_UPLOADAVATAR_SUCCESS',
    UPLOADAVATAR_FAILURE: 'USERS_UPLOADAVATAR_FAILURE',

    UPLOADCOVER_REQUEST: 'USERS_UPLOADCOVER_REQUEST',
    UPLOADCOVER_SUCCESS: 'USERS_UPLOADCOVER_SUCCESS',
    UPLOADCOVER_FAILURE: 'USERS_UPLOADCOVER_FAILURE',
    
    IMPERSONATE: 'STOP_IMPERSONATE',
 
};
