import React, { useEffect }from 'react'
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
import { checkSideNav } from '../../Components/checkSideMenu';
import { Route, Switch} from 'react-router-dom';
import { If, Else, Then, Case, Default, Switch as CaseSwitch } from 'react-if';
import { Link } from 'react-router-dom';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import Pagination from 'react-js-pagination';
import "./PermissionsPageDark.scss";
import { permissionActions } from '../../../_core/actions/permission';
import { history } from '../../../_core/helpers/history';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import CreatePermission from './AddPermissionDark';
import EditPermission from './EditPermissionDark';
import DocumentTitle from "react-document-title";
import { PageWrapper } from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent } from "../../Components/PageWrapper/DynamicWrapper";

const mapStateToProps = ({ permissions }) => ({
    permissions,
});

const ListPermission = (props) => {
    const dispatch = useDispatch();
    let buttonHeaderContent = "ADD PERMISSION";
    

    const [totalData, setTotalData] = React.useState(0);
    const [ActivePage, setActivePage] = React.useState(0);
    const [GetTotalPerpage, setGetTotalPerpage] = React.useState(10);
    const [currentValue, setCurrentValue] = React.useState(10);

    const [update, setUpdate] =  React.useState(false);
    const cookies = new Cookies();
    const userCred = JSON.parse(localStorage.getItem('userCredentials'))
 
    let showEntries = "Show Entries ";

    let fromPage = ((ActivePage -1) * GetTotalPerpage) + 1
    let toPage =  Math.min((ActivePage - 1)* GetTotalPerpage + GetTotalPerpage, totalData)

    const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

    useEffect(() => {
        let defaultPage = 1;
        let itemPerPage = cookies.get("Permissions:"+userCred.user._id) ? cookies.get("Permissions:"+userCred.user._id) : currentValue;
        dispatch(permissionActions.getAll(defaultPage,itemPerPage));
    }, [update,dispatch])//eslint-disable-line

    useEffect(() => {
        let newItems = cookies.get("Permissions:"+userCred.user._id)
        if(update !== false || newItems === null){
            cookies.set("Permissions:"+userCred.user._id, currentValue, { path: "/", sameSite:'lax' })
             setUpdate(false)
         }else{
             setGetTotalPerpage(newItems ? newItems : currentValue);
             setUpdate(false)
         }
        setTotalData(props.permissions.totalPerPage)
        setActivePage(props.permissions.currentPage)
    }, [props,update,cookies,currentValue,userCred])

    
    const handleEdit = (event) => {
        const {id} = event.target;
        dispatch(permissionActions.getById(id))
    }
    
    const handlePageChange = (page) => {
        props.dispatch(permissionActions.getAll(page,GetTotalPerpage));
    }

    const handleDelete = (id) => {
		Swal.fire({
			title: '<p class="swal-subtitle-dark"> Delete this item? </p>',
            html: "<p class='swal-subtitle-dark'>You won't be able to revert this!</p>",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#24DC98',
			cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            background: '#2E3549',
			fontColor: "#0A551E",
		}).then((result) => {
            if(result.value){
				props.dispatch(permissionActions.permissionDelete(id))
                setTimeout(() => {
                    props.dispatch(permissionActions.getAll(ActivePage, GetTotalPerpage))
                }, 2000)
            }
		})
    }

    /* ----------------Custom input type number ----------------*/
    const topArrowClicked = (e) => {
        if (currentValue < 50){
            if(currentValue === 20){
                setCurrentValue(prevState => prevState + 30);
                setUpdate(true)
            }else{
                setCurrentValue(prevState => prevState + 10);
                setUpdate(true)
            }
        }
    }
    const bottomArrowClicked = (e) => {
        if (currentValue > 10){
            if(currentValue === 50){
                setCurrentValue(prevState => prevState - 30);
                setUpdate(true)
            }else{
                setCurrentValue(prevState => prevState - 10);
                setUpdate(true)
            }
        }
    }
    
      const InputArrows = ({topArrowClick, bottomArrowClick}) => {

        return (
          <div className="arrows-component">
            <div className="arrows">
              <button onClick={topArrowClick}>
              <i className="fa fa-angle-up" aria-hidden="true"  style={{cursor:'pointer'}}></i>
              </button>
              <button onClick={bottomArrowClick}>
                <i className="fa fa-angle-down" aria-hidden="true"  style={{cursor:'pointer'}}></i>
              </button>
            </div>
          </div>
        );
      };

      const addPermission = JSON.parse(localStorage.getItem('addPermission'));
      const editPermission = JSON.parse(localStorage.getItem('editPermission'));
      const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
      const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;
    /* --------------------------------------------------------*/

    return (
        <BodyBackgroundColor backgroundColor='#1C2233'>
        <div className="list_permission" >

            <div className="table-card">
                <div className="table-header">

                    {
                        (addPermission.includes("Access - Permissions") === true) || (roleAllTrue === 1) ?
                        <button className={secretDetails !== null ? "darkmode-btn-default disabled-link" : 'darkmode-btn-default' } onClick={ (e) => history.push('/admin/permissions/create')}>{ buttonHeaderContent }</button> : false
                    }
                    
                    
                    <div className="block">
                        <span className="darkmode-btn-default entries test">{ showEntries } </span>
                        <div className="custom-input">
                            <input type="text" className="entries-btn" name="clicks" value={GetTotalPerpage} pattern="[0-9]*" style={{pointerEvents:'none'}} min="10" max="50"/>
                            <span className="arrows">
                                <InputArrows 
                                    topArrowClick={topArrowClicked} 
                                    bottomArrowClick={bottomArrowClicked} 
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className = "responsive-table responsive-attributes">
                        <thead>
                            <tr style={{fontSize:'1.1em'}}>
                                <th> Name </th>
                                <th> Code </th>
                                {(editPermission.includes("Access - Permissions") === true) || (deletePermission.includes("Access - Permissions") === true) || (roleAllTrue === 1) ?
                                    <th className="action-tbl-title"> Actions </th> : false
                                }
                            </tr>   
                        </thead>
                      
                        <tbody>
                        <If condition={props.permissions.loading === false }> 
                            <Then>

                            {props.permissions.loading === false && props.permissions.permissions.items.map((permission)=>  (
                                    <tr className="table-td-data" key ={permission._id}>
                                    <td style={{width:'40%',textTransform:'capitalize'}}>{permission.permissionName}</td>
                                    <td>{permission.code}</td>
                                    <td className={secretDetails !== null ? "action-tbl disabled-link" : 'action-tbl' }>
                                        {(editPermission.includes("Access - Permissions") === true) || (roleAllTrue === 1)  ? 
                                            <Link to="#" id={permission._id} key={permission._id} onClick={handleEdit} className="btn-action btn-edit"  data-tip={"Edit " + permission.permissionName} place="top" data-class="tooltips"></Link> : false
                                        }
                                        {(deletePermission.includes("Access - Permissions") === true) || (roleAllTrue === 1)  ? 
                                            <Link to="#"  onClick={() => { handleDelete(permission._id) }} className="btn-action btn-del" data-tip={"Delete " + permission.permissionName} place="top" data-class="tooltips"></Link> : false
                                        }
                                        <ReactTooltip />
                                    </td>
                                    </tr>
                            ))}
                            </Then>
                            <Else>
                                <tr className="table-td-data">
                                    <td> 
                                        <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                                            <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </td>
                                </tr>
                            </Else>
                           
                        </If>
                        </tbody>
                    </table>
                </div>
                
                <div className="table-footer">
                    
                {totalData !== 0 && <div className="dataCount"> Showing { fromPage } to { toPage  } of {  totalData } entries  </div> }
              
                    <Pagination
                        activeClass="pagination_navigtion_active"
                        itemClass="pagination_navigtion"
                        linkClass="pagination_navigtion_link"
                        
                        hideDisabled
                        hideFirstLastPages
                        prevPageText='Previous'
                        nextPageText='Next'
                        pageRangeDisplayed={10}
                        activePage={ActivePage}
                        itemsCountPerPage={GetTotalPerpage}
                        totalItemsCount={totalData}
                        onChange={(e) => handlePageChange(e)}
                    />
                </div>
            </div>
        </div>
    </BodyBackgroundColor>
    )
}


const ListPermissionPage = connect(mapStateToProps)((props) => {

    const [breadCrumbs, setBreadCrumbs] = React.useState([])

  
    const filterBreadCrumbs = (mode) => {
      console.log(mode)
      switch (mode) {
          case "list":
            setBreadCrumbs([
              {  label: "Permissions", link: "/admin/permissions?page=1" },
            ]);
            break;
          case "create":
            setBreadCrumbs([
              {  label: "Permissions", link: "/admin/permissions?page=1" },
              { label: "Create Permission", link: "/admin/permissions/create"},
            ]);
            break;
          case "edit":
            setBreadCrumbs([
              {  label: "Permissions", link: "/admin/permissions?page=1" },
              {  label: "Edit Permission", link: "/admin/permissions/edit" },
            ]);
            break;
            default:
            // Error page
            break;
          }
  };
  
    React.useEffect(() => {
      filterBreadCrumbs(props.mode);
    }, [props.mode]);
    

    return (
        // <div
        //     className={cc([
        //         'mainPermission',
        //         checkSideNav()?.trim()
        //     ])}
        // >   
        //     <div> 
        //         <div className="row">
        //             <div className="col s6 mainTitle"><p>Access Management</p></div>
        //             <div className="col s6 sub-title-main"><p>Permissions</p></div>
        //         </div>
        //      </div>
        //    <ListPermission {...props}/>

        // </div>
        <BodyBackgroundColor backgroundColor='#1C2233'>
            <PageWrapper className={cc(["RolesPage", checkSideNav()?.trim()])}>
                <DocumentTitle
                    title={
                        props.mode === "create"
                            ? `Create Permission | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                            : props.mode === "edit"
                                ? `Edit Permission | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                                : `Access | Permission | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                    }
                />
                <DynamicHeaderContent
                    TitlePage="Access"
                    breadCrumbs={true}
                    breadCrumbsPages={breadCrumbs}
                />
                {/* <If condition={['create', 'edit'].includes(props.mode)}>
                    <Then>
                        <CreatePermission {...props} />
                    </Then>
                    <Then>
                        <EditPermission {...props} />
                    </Then>
                    <Else>
                        <ListPermission {...props}/>
                    </Else>
                </If> */}
                <CaseSwitch>
                    <Case condition={['create'].includes(props.mode)}><CreatePermission {...props} /></Case>
                    <Case condition={['edit'].includes(props.mode)}><EditPermission {...props} /></Case>
                    <Default><ListPermission {...props}/></Default>
                </CaseSwitch>
            </PageWrapper>
        </BodyBackgroundColor>
    )
});
ListPermissionPage.WrappedComponent.displayName = 'ListPermissionPage';
const ListPermissionPageRoute = (props) => (
	<Switch>
        <Route
			exact
			path={`${props.match.path}/create`}
			render={(props) => <ListPermissionPage {...props} mode="create" />}
		/>
        <Route
			exact
			path={`${props.match.path}/edit`}
			render={(props) => <ListPermissionPage {...props} mode="edit" />}
		/>
        <Route
			exact
			path={`${props.match.path}`}
			render={(props) => <ListPermissionPage {...props} mode="list" />}
		/>
	</Switch>
);
export default React.memo(ListPermissionPageRoute);
