import { attrUserConstants } from '../constants/attrUser';

export function attrUsers(state = {}, action) {
  switch (action.type) {
      //get users
    case attrUserConstants.ATTR_USERS_REQUEST:
      return {
        loading: true
      };
    case attrUserConstants.ATTR_USERS_SUCCESS:
      return {
        attrUsers: action.attrUsers
      };
    case attrUserConstants.ATTR_USERS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}