import React from 'react';
import { Link } from 'react-router-dom';


class ButtonHome extends React.Component {
    _refreshPage() {
        console.log("Clicked");
        window.location.reload();
      }
	render() {
		return (
	      <Link to="/" className="waves-effect waves-light btn btn-Home">Home</Link>
		);
	}
}

export default ButtonHome;