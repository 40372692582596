import { tenuresConstant } from '../constants/tenures';
import { tenuresServices } from '../services/tenures';
import { history } from "../helpers/history"
import { MessageAfterSubmit } from '../components/Alert'

export const tenuresAction = {
    getAllTenures,
    addTenure,
    editTenure,
    removeTenure,
};

function getAllTenures(selectedPage, totalPerPage) {
  return (dispatch) => {
    dispatch(request());

    tenuresServices.getAll(selectedPage, totalPerPage).then(
      (tenures) => {
        dispatch(success(tenures));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(tenures) {
    return { type: tenuresConstant.GET_ALL_TENURES, tenures };
  }
  function success(tenures) {
    return { type: tenuresConstant.GET_ALL_TENURES_SUCCESS, tenures };
  }
  function failure(error) {
    return { type: tenuresConstant.GET_ALL_TENURES_FAILED, error };
  }
}

function addTenure(data) {
  return (dispatch) => {
    dispatch(request());

    tenuresServices.addTenure(data).then(
      (tenures) => {
        dispatch(success(tenures));
        history.goBack();
        MessageAfterSubmit("success", tenures.success);
      },
      (error) => {
        dispatch(failure(error.toString()));
        MessageAfterSubmit("error", error.toString());
      }
    );
  };

  function request(tenures) {
    return { type: tenuresConstant.POST_TENURE_REQUEST, tenures };
  }
  function success(tenures) {
    return { type: tenuresConstant.POST_TENURE_REQUEST_SUCCESS, tenures };
  }
  function failure(error) {
    return { type: tenuresConstant.POST_TENURE_REQUEST_FAILED, error };
  }
}


function editTenure(pageId, data) {
 
  return (dispatch) => {
    dispatch(request());
    console.log(pageId, data);

    tenuresServices.editTenure(pageId, data).then(
      (tenures) => {
        dispatch(success(tenures));
      
        MessageAfterSubmit("success", tenures.success['message']);
        history.goBack();
      },
      (error) => {
        dispatch(failure(error.toString()));
        MessageAfterSubmit("error", error.toString());
      }
    );
  };

  function request(tenures) {
    return { type: tenuresConstant.EDIT_TENURE_REQUEST, tenures };
  }
  function success(tenures) {
    return { type: tenuresConstant.EDIT_TENURE_REQUEST_SUCCESS, tenures };
  }
  function failure(error) {
    return { type: tenuresConstant.EDIT_TENURE_REQUEST_FAILED, error };
  }
}


function removeTenure (id, page, itemsPerPage) {
  return dispatch => {
      dispatch(request());
      tenuresServices._delete(id)
          .then(
              tenure => {
                  dispatch(success(tenure));
                  dispatch(getAllTenures(page, itemsPerPage));
                  MessageAfterSubmit("success", tenure.success);
              },
              error => {
                  MessageAfterSubmit("error", error.toString());
                  dispatch(failure(error.toString()));
              }
          );
  };   

  function request() { return { type: tenuresConstant.DELETE_TENURE_REQUEST }}
  function success(tenure) { return { type: tenuresConstant.DELETE_TENURE_REQUEST_SUCCESS, tenure } }
  function failure(error) { return { type: tenuresConstant.DELETE_TENURE_REQUEST_FAILED, error} }
}