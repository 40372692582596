import React from "react";
import { Link } from "react-router-dom";
import { level } from "../_core/constants/selections";
import M from "materialize-css/dist/js/materialize.min.js";
import RadarChart from "react-svg-radar-chart";
import { expActions } from '../_core/actions/exp';
import { connect } from 'react-redux';
import "./DetailPage.sass";
import ReactTooltip from 'react-tooltip';
import MaterialIcon from 'material-icons-react';
import { attributeActions } from '../_core/actions/attribute';
import { userAttributeActions } from '../_core/actions/userAttribute';
import { attrUserActions } from '../_core/actions/attrUser';
import { exp_level, exp_targetPoints } from '../_core/helpers/exp';
import { badges } from "../_core/constants/selections";

class DetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      render: true,
      imageHash: Date.now(),
      level: level.software
    };
    this.handleClose = this.handleClose.bind(this);
    this.alertTimeOut = this.alertTimeOut.bind(this);
  }
  componentDidMount() {
    const userCred = JSON.parse(localStorage.getItem("userCredentials"));
    window.scrollTo(0, 0);
    var elem = document.querySelector(".parallax");
    M.Parallax.init(elem);

    var element = document.querySelectorAll('.modal');
    M.Modal.init(element);

    this.alertTimeOut();

    var elems = document.querySelectorAll(".carousel");
    M.Carousel.init(elems);

    this.props.dispatch(expActions.getExp(userCred.user._id))
    this.props.dispatch(attrUserActions.getUsers(userCred.user._id))
    this.props.dispatch(attributeActions.getAll(1, 1000000))
    this.props.dispatch(userAttributeActions.getUserAttributes(userCred.user._id))
  }
  alertTimeOut = () =>
    setTimeout(
      function() {
        this.setState({ render: false });
      }.bind(this),
      4000
    );

  // componentWillUnmount() {
  //   clearTimeout(this.alertTimeOut);
  // }

  handleClose(event) {
    event.preventDefault();
    this.setState({ render: false });
  }


  getAttributes(){
    let { attributes, attrUsers } = this.props;
    var userAttrList = [];
    var attrIds = [];
    var attrUserList = '';
    var attributeList = '';
    var attrList = [];
    const userCred = JSON.parse(localStorage.getItem("userCredentials"));
    var user_attributes = attrUsers.attrUsers && attrUsers.attrUsers.items.filter((item)=>item._id === userCred.user._id)

    if(user_attributes && user_attributes[0]){
      if(user_attributes[0].attributes && user_attributes[0].attributes[0][0]){
        user_attributes[0].attributes[0].map((userAttr)=>{
          userAttrList.push({
            user: userAttr.user_id,
            attr: userAttr.attribute_id,
            type: userAttr.type,
            value: userAttr.value,
            max: userAttr.max
          });
          attrIds.push(userAttr.attribute_id)
          return userAttr
        });

        if(attributes.attributes && attributes.attributes.items[0]){
          attributes.attributes.items.map((attr)=>{
            if(attrIds.includes(attr._id)){
              attrList.push({
                id: attr._id,
                name: attr.attrName
              });
              return attr
            }else{
              return attr
            }
          });

          if(attrList[0]){
            attrList.map((attr)=>{
              if(attributeList === ""){
                attributeList += '"' + attr.id + '": "' + attr.name + '"'
              }else{
                attributeList += ', "' + attr.id + '": "' + attr.name + '"'
              }
              return attr
            })
          }

          if(userAttrList[0]){
            userAttrList.map((uAttr)=>{
              if(attrUserList === ""){
                attrUserList += '"' + uAttr.attr + '": ' + ((uAttr.value / uAttr.max)*100) / Math.pow(10, 2) + ''
              }else{
                attrUserList += ', "' + uAttr.attr + '": ' + ((uAttr.value / uAttr.max)*100) / Math.pow(10, 2) + ''
              }
              return uAttr
            })
          }

          var attrToObj = '{' + attributeList + '}';
          var captionsObj = JSON.parse(attrToObj);
          var attrToObject = "{" + attrUserList + "}";
          var dataObj = JSON.parse(attrToObject);

          //passing data to graph
          const captions =  captionsObj;
          const data = [
            {
              "data": dataObj,
              "meta": { "color": '#24DC98' }
            }
          ];

          if(captions && data){
            return(
              <div className="hide-on-med-and-down">
                <p><b>Stats</b></p>
                <div className="center">
                  <RadarChart
                    captions={captions}
                    data={data}
                    size={250}
                  />
                </div>
              </div>
            )
          }else{
            return (<div></div>)
          }
        }
      }else{
        return (<div></div>)
      }
    }else{
      return (<div></div>)
    }
  }

  render() {
    // const { imageHash } = this.state;
    // this.getAttributes()
    let user = JSON.parse(localStorage.getItem("user"));
    let userCred = JSON.parse(localStorage.getItem("userCredentials"));
    const { level } = this.state;
    var userLevel = level.filter(level => level.value === user.level);
    const {exps} = this.props;

    var currentExp = exps.exp && exps.exp[0] ? Number(exps.exp && exps.exp[0].exp) : 0;
    var levels = exp_level(currentExp)[0];
    var curLevel = levels.current;
    var nxtLevel = levels.next;
    var targets = exp_targetPoints(curLevel, nxtLevel)[0];
    var curTarget = targets.current;
    var nxtTarget = targets.next;
    var getPercent = ((currentExp - curTarget) / (nxtTarget - curTarget))*100;
    var percent = exps.exp && exps.exp[0] ? getPercent.toString().slice(0,2) + "%" : "0%";
    
    return (
      <div>
        <div className="DetailPageCon">
          <div>
            <p>
              <b>Bio</b>
            </p>
            <p>{user.bio}</p>
          </div>
          <hr className="DPLines" />
          <div className="">
            <p>
              <b>Level</b>
            </p>
            <p className="level-job">
              Level {curLevel} <span className="level-job-separator"> | </span>
              {userLevel[0] ? userLevel[0].label : ''}
            </p>
            <div id="progress" data-tip={percent} place="bottom" data-class="tooltips">
              <div id="bar" style={{width: percent}}></div>
            </div>
            <ReactTooltip />
            <p className="right txtPercent">{currentExp} / {nxtTarget} XP</p>
          </div>
          <hr className="DPLines DPLines2"/>
          <div>
            <p className="TxtTrophy">
              <b>Badges</b>
            </p>
            <div className="row">
              {badges.default.filter((badges)=> badges.userId === userCred.user_id).slice(0,6).map((badge, index) =>
                  <div className="col s4 m4" key={index}>
                  <img
                    src={badge.details[0].badgeImage}
                    alt="trophy_1"
                    className="responsive-img trophyImage"
                  />
                </div>
               
              )}{badges.default.filter((badges)=> badges.userId === userCred.user_id).length <= 0 &&
                <center><h5 id="render-none"><em>No Badges Found</em></h5></center>
              }
              
            </div>

            <Link to="#!">
              <button className="btnTimesheet modal-trigger" data-target="modalBadges">View All</button>
            </Link>
          </div>

          <hr className="DPLines" />

          <div id="modalBadges" className="modal">
            <div className="modal-header" id="badgeHeader">
            <h5 className="TxtTrophy">
              <b>View All Badges</b>
              <a href="#!" className="modal-close waves-effect waves-green btn-flat right"><MaterialIcon icon="close"/></a>
            </h5>
            
            </div>
            <div className="modal-content" id="badgeContent">
            <div className="row">
              {badges.default.filter((badges)=> badges.userId === userCred.user_id).map((badge, index) =>
                  <div className="col s4 m4" key={index}>
                  <div className="card badgeCards">
                    <div className="row">
                        <div className="col s4 m4 l4">
                        <img
                        src={badge.details[0].badgeImage}
                        alt="trophy_1"
                        className="responsive-img trophyImage"
                        
                        />
                       
                        </div>
                
                        <div className="col s8 m8 l8">
                        <p className="TxtTrophy textHeader">
                          <b>{badge.details[0].badgeName}</b>
                        </p>
                        <p className="TxtTrophy">{badge.details[0].badgeContent}</p>
                        
                        </div>
                    </div>
                  </div>
                  
                </div>
               
              )}{badges.default.filter((badges)=> badges.userId === userCred.user_id).length <= 0 &&
                <center><h5 id="render-none"><em>No Badges Found</em></h5></center>
              }
              
            </div>

            </div>
            <div className="modal-footer">
            </div>
          </div>


          <div className="DPStatusCon">
            <p className="left DPStatusConTxt">
              <b>Status</b>
            </p>
            <Link to="/timesheet">
              <button className="btnTimesheet">View Timesheet</button>
            </Link>
          </div>

          
              {this.getAttributes()}
          
        </div>
        
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { exps, attributes, userAttributes, attrUsers} = state;
  return { exps, attributes, userAttributes, attrUsers };
}

const DetailPage = connect(mapStateToProps)(DetailsPage);
export default DetailPage;
