//npm packages
import React from 'react';
//images
import sun from '../_templates/sun.png';
//global imports
import { history } from '../_core/helpers/history';

const EngageSelection = () => {
    const checkUrl = window.location.pathname;
    var challengesPage = false;
    var questsPage = false;

    //buttons behavior will depend on url/pathname
    if(checkUrl === '/engage/challenges'){
        challengesPage = true
        questsPage = false
    }else if(checkUrl === '/engage/quests'){
        challengesPage = false;
        questsPage = true;
    }else if(checkUrl === '/engage'){
        challengesPage = false;
        questsPage = false;
    }
    
    const changePage = (page) => {
        const currentPage = checkUrl.replace('/engage/','');
        
        if(currentPage === page){
            history.push('/engage')
        }else{
            history.push('/engage/' + page)
        }
    }

	return (
		<div className="engage-challenges-quests">
            <div onClick={()=>changePage('challenges')} className={challengesPage ? "challenges-btn s6 offset-s3 choose" : "challenges-btn s6 offset-s3"}>
                <img src={sun} alt="..." className="sun" />
                <p>Challenges</p>
                <p>00</p>
                <p>00</p>
                <p>00</p>
            </div>
            <div onClick={()=>changePage('quests')} className={questsPage ? "quests-btn s6 offset-s3 choose" : "quests-btn s6 offset-s3"}>
                <img src={sun} alt="..." className="sun" />
                <p>Quests</p>
                <p>00</p>
                <p>00</p>
                <p>00</p>
            </div>
        </div>	
	)
};


export default EngageSelection;