export const rewardConstants = {
    GETALL_REQUEST: 'GET_REWARD_REQUEST',
    GETALL_SUCCESS: 'GET_REWARD_SUCCESS',
    GETALL_FAILURE: 'GET_REWARD_FAILURE', 

    POST_REQUEST: 'POST_USERREWARD_REQUEST',
    POST_SUCCESS: 'POST_USERREWARD_SUCCESS',
    POST_FAILURE: 'POST_USERREWARD_FAILURE',

    GETONE_REQUEST: 'GET_REWARD_REQUEST',
    GETONE_SUCCESS: 'GET_REWARD_SUCCESS',
    GETONE_FAILURE: 'GET_REWARD_FAILURE', 

    GETBYUSER_REQUEST: 'GETUSER_REWARD_REQUEST',
    GETBYUSER_SUCCESS: 'GETUSER_REWARD_SUCCESS',
    GETBYUSER_FAILURE: 'GETUSER_REWARD_FAILURE', 

    UPDATE_REWARD_REQUEST: 'UPDATE_REWARD_REQUEST',
    UPDATE_REWARD_SUCCESS: 'UPDATE_REWARD_SUCCESS',
    UPDATE_REWARD_FAILURE: 'UPDATE_REWARD_FAILURE',

    DELETE_REQUEST: 'REWARD_DELETE_REQUEST',
    DELETE_SUCCESS: 'REWARD_DELETE_SUCCESS',
    DELETE_FAILURE: 'REWARD_DELETE_FAILURE',
};
