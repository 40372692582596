import React from 'react';
import { connect } from 'react-redux';
import { overtimeActions } from '../_core/actions/overtime';
// import LinesEllipsis from 'react-lines-ellipsis';
import OvertimePageModal from './OvertimePageModal';
import { Link } from 'react-router-dom';
//import ReactTooltip from 'react-tooltip'
import moment from 'moment';
import M from 'materialize-css/dist/js/materialize.min.js'
//import MaterialIcon from 'material-icons-react';
import ErrorPage from '../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../Components/ERRORPAGE/ButtonHome';
import error from '../_templates/error.png';
import '../_core/styles/alert.sass'
import './OvertimePage.sass';
import './../Pagination.sass';

class UserOvetimePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            render: true,
            //pagination
            page: 1,
            pages: '',
            goto: '',
            rejectedRemark: '',
            getByIdModalOpen: false,
            id: ''
        }
    }

    componentDidMount() {
        var elem = document.querySelector(".sidenav");
        M.Sidenav.init(elem, {
            edge: "left",
            inDuration: 250
        });

        // init page
        var selectPage = this.state.page;
        this.props.dispatch(overtimeActions.getAllById(selectPage));
        // this.itemCount();
    }

    handleClose(event){
        event.preventDefault()
        var alertId = document.getElementById("alert")
       alertId.style.display='none';
   }

   openGetByIdModal = (id) => {
        this.setState({
            getByIdModalOpen: true,
            id
        })
    }

    closeGetByIdModal = () => {
        this.setState({
            getByIdModalOpen: false,
            id: ''
        })
    }

    //--------------------------------------------> PAGINATION <-----------------------------------------------------------
    checkPage(e, totalpages, currentpage) {
        e.preventDefault();
        var totalPages = totalpages;
        var curPage = currentpage;
        var page = e.target.textContent;

        if (Number(page) === curPage){
            return
        }else if (page >= totalPages) {
            this.setState({
                page: totalPages,
            }, () => {
                var selectPage = this.state.page;
                this.changePage(selectPage);
            })
        } else {
            this.setState({
                page: Number(page),
            }, () => {
                var selectPage = this.state.page;
                this.changePage(selectPage);
            })
        }
    }

    pageNum(totalpages, currentpage) {
        var totalPages = totalpages;
        var curPage = currentpage;
        var pages = [];
        var a = 1;

        for (var i = 1; i <= totalPages; i++) {
            var page = i
            if (page === this.state.page || page === curPage) {
                pages.push(<li key={i} className={this.state.page === page ? "selected" : "btn-page"} id="zero" onClick={(e) => this.checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{page}</span></li>);
            } else {
                if (a <= 4) {
                    if (page === this.state.page - 1 || page === this.state.page + 1) {
                        pages.push(<li key={i} className={this.state.page === page ? "selected" : "btn-page"} id="zero" onClick={(e) => this.checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{page}</span></li>);
                        a++
                    } else if (page === this.state.page - 2 || page === this.state.page + 2) {
                        pages.push(<li key={i} className={this.state.page === page ? "selected" : "btn-page"} id="zero" onClick={(e) => this.checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{page}</span></li>);
                        a++
                    } else if (this.state.page === 1 || this.state.page === 2) {
                        pages.push(<li key={i} className={this.state.page === page ? "selected" : "btn-page"} id="zero" onClick={(e) => this.checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{page}</span></li>);
                        a++
                    } else if (page === totalPages - 3 || page === totalPages - 4) {
                        pages.push(<li key={i} className={this.state.page === page ? "selected" : "btn-page"} id="zero" onClick={(e) => this.checkPage(e, totalPages, curPage)} style={{ margin: "0px 2px" }}><span className="text-page">{page}</span></li>);
                        a++
                    }
                }
            }
        }

        return (
            <div className="pages">
                <ul className="pagination">
                    {curPage === 1 ? ""
                        : <span>
                            <li className={curPage === 1 ? "btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="Go to first page" id="zero" onClick={(e) => this.firstPage(e)} style={{ margin: "0px 5px", padding: "0px 10px" }}><i className="tiny material-icons" id="zero" style={{ lineHeight: "0.5", display: "-webkit-inline-box", fontSize: "20px" }}>first_page</i><span style={{ padding: "0 10px" }}>First</span></li>
                            <li className={curPage === 1 ? "btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="prev" id="zero" style={{ margin: "0px 5px", padding: "0px 5px" }} onClick={(e) => this.prevPage(e)}><i className="tiny material-icons" id="zero" style={{ lineHeight: "0.5", display: "-webkit-inline-box", fontSize: "20px" }}>chevron_left</i></li>
                        </span>
                    }
                    {pages}
                    {curPage === totalPages ? ""
                        : <span>
                            <li className={curPage === totalPages ? " btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="next" id="zero" style={{ margin: "0px 5px", padding: "0px 5px" }} onClick={(e) => this.nextPage(e, totalPages)}><i className="tiny material-icons" id="zero" style={{ lineHeight: "0.5", display: "-webkit-inline-box", fontSize: "20px" }}>chevron_right</i></li>
                            <li className={curPage === totalPages ? " btn-arrow btn disabled text-page" : "btn-arrow text-page"} title="Go to last page" id="zero" style={{ margin: "0px 5px", padding: "0px 10px" }} onClick={(e) => this.lastPage(e, totalPages)}><span style={{ padding: "0 10px" }}>Last</span><i className="tiny material-icons" id="zero" style={{ lineHeight: "0.5", display: "-webkit-inline-box", fontSize: "20px" }}>last_page</i></li>
                        </span>
                    }
                    <span>
                        <li className="goText">Go to Page</li>
                        <input className="pageNum" type="number" id="zero" min="1" max={totalPages} placeholder={curPage ? curPage : ''} style={{ border: "1px solid #ccc", width: "40px", height: "30px", overflow: "auto", textAlign: "center" }} onChange={(e) => this.getPage(e)} />
                        <li className="pageGo text-page" id="zero" onClick={(e) => this.goToPage(e, totalPages, curPage)} style={{ padding: "0px 10px" }}>Go</li>
                    </span>
                </ul>
            </div>
        );
    }

    nextPage(e, pages) {
        e.preventDefault();
        var totalPages = pages;
        var setPage = Number(this.state.page) + 1;

        if (setPage >= totalPages) {
            this.setState({
                page: totalPages
            }, () => {
                var selectPage = this.state.page;
                this.changePage(selectPage);
            })
        } else if (this.state.page) {
            this.setState({
                page: setPage
            }, () => {
                var selectPage = this.state.page;
                this.changePage(selectPage);
            })
        }
    }

    prevPage(e) {
        e.preventDefault();
        var setPage = Number(this.state.page) - 1
        if (setPage <= 1) {
            this.setState({
                page: 1
            }, () => {
                var selectPage = this.state.page;
                this.changePage(selectPage);
            })
        } else if (this.state.page) {
            this.setState({
                page: setPage
            }, () => {
                var selectPage = this.state.page;
                this.changePage(selectPage);
            })
        }
    }

    firstPage(e) {
        e.preventDefault();
        this.setState({
            page: 1
        }, () => {
            var selectPage = this.state.page;
            this.changePage(selectPage);
        })
    }

    lastPage(e, pages) {
        e.preventDefault();
        var totalPages = pages
        this.setState({
            page: totalPages
        }, () => {
            var selectPage = this.state.page;
            this.changePage(selectPage);
        })
    }

    getPage(e) {
        var page = e.target.value;
        this.setState({ goto: page });
    }

    goToPage(e, pages, pageNo) {
        e.preventDefault();
        var page = this.state.goto;
        var curPage = pageNo;
        var totalPages = pages;

        if (page === "" || page === null || Number(page) === curPage){
            this.setState({
                page: this.state.page,
                goto: ''
            })
        } else if (page >= totalPages) {
            this.setState({
                page: totalPages,
                goto: ''
            }, () => {
                var selectPage = this.state.page
                this.changePage(selectPage);
            })
        } else if (page <= 1) {
            this.setState({
                page: 1,
                goto: ''
            }, () => {
                var selectPage = this.state.page
                this.changePage(selectPage);
            })
        } else {
            this.setState({
                page: Number(page),
                goto: ''
            }, () => {
                var selectPage = this.state.page
                this.changePage(selectPage);
            })
        }
    }

    changePage(page){
        var selectPage = page;
        this.props.dispatch(overtimeActions.getAllById(selectPage));
    }
    //--------------------------------------------> END PAGINATION <-----------------------------------------------------------

    render() {
        const { overtimes } = this.props;
        
        // const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
        // let user = JSON.parse(localStorage.getItem('user'));
        return (
           
                                            <div className="col s12 m12 l12 userDetailConLineLeft">
                                                <div className="user-overtime-management">
                                                    { overtimes.error ? 
                                                    <div className="container error-div">
                                                        <ErrorPage/>
                                                            <div className="text-danger">
                                                                <img className="error" src={error} alt="error"/> 
                                                                    {overtimes.error}
                                                            </div>
                                                        <ButtonHome/>
                                                    </div> : 
                                                    <div>
                                                        <div className="row">
                                                            <div className="col s12 m6 l6">
                                                                <p className="text-left overtimePageTxt1"><b>Your Overtime Request</b></p>
                                                            </div>
                                                            <div className="col s12 m6 l6">
                                                                <Link to="/user/overtime/request" className="btn right overtimePageBtn">File for Overtime</Link>
                                                            </div>  
                                                        </div>
                                                        {overtimes.loading ?
                                                            <div className="center">
                                                                <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                            </div>
                                                        : overtimes.overtimes && overtimes.overtimes.total <= 0 ?
                                                            <center><h5><em>no records found</em></h5></center>
                                                        :
                                                        <div>
                                                        {overtimes.overtimes &&
                                                        <div>
                                                            <table className="responsive-table highlight">
                                                                <thead className="thead">
                                                                    <tr>
                                                                        <th>Status</th>
                                                                        <th>Date</th>
                                                                        <th>From</th>
                                                                        <th>To</th>
                                                                        <th>Noted By</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody> 
                                                                {overtimes.overtimes.items.map((overtime, index) =>
                                                                    <tr key={index}>
                                                                        {
                                                                            this.state.id === overtime._id ?
                                                                            <OvertimePageModal
                                                                                isOpen={this.state.getByIdModalOpen}
                                                                                close={this.closeGetByIdModal}
                                                                                overtime={overtime}
                                                                            /> : null
                                                                        }
                                                                        <td 
                                                                            onClick={() => this.openGetByIdModal(overtime._id)} width="5%" 
                                                                            style={{textTransform:"capitalize"}} 
                                                                            className={overtime.status === "on hold" ? "onHold" : overtime.status}
                                                                            >
                                                                                {overtime.status ? overtime.status === "new" ? "in-review" : overtime.status : "none"}
                                                                        </td>
                                                                        <td 
                                                                            onClick={() => this.openGetByIdModal(overtime._id)}
                                                                            width="10%" 
                                                                            className=""
                                                                            >
                                                                                {moment(overtime.startedAt).utc().format('MMMM DD YYYY')}
                                                                        </td>
                                                                        <td 
                                                                            onClick={() => this.openGetByIdModal(overtime._id)}
                                                                            width="10%" 
                                                                            className=""
                                                                            >
                                                                                {moment(overtime.startedAt).utc().format('h:mm a')}
                                                                        </td>
                                                                        <td 
                                                                            onClick={() => this.openGetByIdModal(overtime._id)}
                                                                            width="10%" 
                                                                            className=""
                                                                            >
                                                                                {moment(overtime.endedAt).utc().format('h:mm a')}
                                                                        </td>                             
                                                                        <td 
                                                                            onClick={() => this.openGetByIdModal(overtime._id)}
                                                                            width="10%"
                                                                            >
                                                                                {overtime && overtime.notedBy && overtime.noter_details[0].firstName && overtime.noter_details[0].lastName ? overtime && overtime.notedBy && overtime.noter_details[0].firstName +' '+ overtime.noter_details[0].lastName : "No name specified"}
                                                                        </td>
                                                                    </tr>                                         
                                                                )}
                                                                </tbody>
                                                            </table>
                                                        
                                                            <div className="pages overtimePagePagination">
                                                                {/* -----------------------------------------------------> PAGINATION <----------------------------------------------- */}
                                                                {this.pageNum(overtimes.overtimes.pages, overtimes.overtimes.page)}
                                                                {/* -----------------------------------------------------> END PAGINATION <----------------------------------------------- */}
                                                            </div>
                                                        </div>
                                                        }
                                                        </div>
                                                        }  
                                                    </div>
                                                    }
                                                </div>

                                            </div>
            
        );
    }
}

function mapStateToProps(state) {
    const { overtimes } = state;
    const { alert } = state;
    return {
        overtimes,
        alert
    };
}


const connectedUserOvetimePage = connect(mapStateToProps)(UserOvetimePage);
export { connectedUserOvetimePage as UserOvertimePage };