import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import cc from "classcat";
//import ReactImageFallBack from "react-image-fallback";
import moment from "moment";
import Modal from "react-modal";
import { history } from "../../../_core/helpers/history";
import avatar from "../../../_templates/man.png";
//import { notifActions } from "../../../_core/actions/notification";
import { userActions } from "../../../_core/actions/user";
import { userService } from "../../../_core/services/user";
import { hrConcernActions } from "../../../_core/actions/hrConcern";
import { essActions } from "../../../_core/actions/ess";
import { alertActions } from "../../../_core/actions/alert";
import { requestStatus } from "../../../_core/helpers/requestStatus";
import { ConfirmationModal } from "../../Components/AdminModals/RequestPopup";
import "./UpdateHRPageDark.scss";
import "../../Components/StatusStyle/status.scss";

import fileIcon from '../../../_templates/dark/file_blank_outline.png';
import download from '../../../_templates/dark/download.png';
import close from '../../../_templates/dark/off_outline_close.png';
import FileViewer from 'react-file-viewer';

import PreviewLeaveUserWidget from "../../AdminWidget/PreviewUserDetailsWidget/PreviewLeaveUserWidget";
import { commentActions } from '../../../_core/actions/comment';
import { commentService } from '../../../_core/services/comment';
import styled, { css } from "styled-components";

import { When } from "react-if";

import {
    ContentWrapper,
  } from "../../Components/PageWrapper/ContentWrapper";



const mapStateToProps = ({ hrConcerns, comments }) => ({
  hrConcerns,
  comments
});

const customStyles = {
  content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
  }
};

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

const UpdateConcernPage = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();
  const [concernRequest, setConcernRequest] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [showRemarkModal, setRemarkModal] = React.useState(false);
  const [userManager, setManager] = React.useState([]);
  const [count, setRender] = React.useState(true);
  const countRef = React.useRef(count);
  countRef.current = count;
  let approverDetails = {
    remarks: "",
    notedBy: "",
    notedAt: "",
    status: "",
  };
  const [approversNote, setApproversNote] = React.useState(approverDetails);

  const openFilePreview = () => {
    setFilePreview(true);
  };
  
  const [showFilePreview, setFilePreview] = React.useState(false);
  const [FileUrl, setFileUrl] = React.useState('');
  const [FileType, setFileType] = React.useState('');
  const [comment, setCommment] = React.useState([]);
  const [reply, setReply] = React.useState([]);
  const [isDisplayUserDetails, setIsDisplayUserDetails] = React.useState(false); // eslint-disable-line

  let params = useParams();
  const { requestId, page } = params;
  let admin = JSON.parse(localStorage.getItem("userCredentials"));

  useEffect(() => {
    dispatch(hrConcernActions.getById(requestId));
    dispatch(userActions.getAll(1, 100000));
    dispatch(commentActions.getByOrigin(requestId, 1, 999));
    setIsDisplayUserDetails(true);
    alertTimeOut();
    // eslint-disable-next-line
  }, [requestId]);

  useEffect(() => {
    const { hrConcerns } = props || {};
    const concernList =
      (hrConcerns?.hrConcerns?.success?.length > 0 &&
        hrConcerns.hrConcerns.success[0]) ||
      [];
      setConcernRequest(concernList);
  }, [props]);

  useEffect(() => {
    commentService.getByOrigin(requestId, 1, 999).then(res=>{
        if(res.items?.length !== 0){
            setCommment(res.items)
            commentService.getByOrigin(res.items[0]?._id, 1, 999).then(res=>{
                if(res.items){

                    if(res.items.length !== 0){
                        setReply(res.items)
                    }

                }
            }).catch(error=>{console.log(error)});

        }
    }).catch(error=>{console.log(error)});
  }, [props, requestId]);


  const alertTimeOut = () => {
    setTimeout(() => {
      setRender(countRef.current);
      props.dispatch(alertActions.clear());
    }, 3000);
  };

  const goBack = () => {
    history.goBack();
  };

  const goBackPath = `/admin/hr-concerns?page=${page}`;

  const openRemarkModal = (status) => {
    setApproversNote({
      ...approverDetails,
      status: status,
    });
    setRemarkModal(true);
  };

  const onRemarksChange = (e) => {
    let admin = JSON.parse(localStorage.getItem("userCredentials"));
    setApproversNote({
      ...approversNote,
      [e.target.name]: e.target.value,
      notedAt: moment().format(),
      notedBy: admin.user._id,
    });
  };

  const handleSubmit = (id) => {
    const requestUser = [
      concernRequest.users[0].firstName,
      concernRequest.users[0].lastName,
    ].join(" ");
    const referenceCode = concernRequest.refCode;
    const requestDates = [concernRequest.startedAt, concernRequest.endedAt];
    const requestDescription = concernRequest.description;

    ConfirmationModal( requestUser, referenceCode, requestDates, requestDescription ).then((result) => {
      if (result.value) {
        let updateConcern = {
          _id: id,
          subject: concernRequest.subject || "HR Concern",
          type: concernRequest.type,
          subtype: concernRequest.subtype,
          description: concernRequest.description,
        };

        switch (approversNote.status) {
          case requestStatus.onHold:
            updateConcern = {
              ...updateConcern,
              status: approversNote.status,
              notedAt: approversNote.notedAt,
              notedBy: approversNote.notedBy,
              notedRemark: approversNote.remarks,
            };
            break;
          case requestStatus.approved:
            updateConcern = {
              ...updateConcern,
              status: approversNote.status,
              approvedAt: approversNote.notedAt,
              approvedBy: approversNote.notedBy,
              approvalRemark: approversNote.remarks,
            };
            break;
          case requestStatus.rejected:
            updateConcern = {
              ...updateConcern,
              status: approversNote.status,
              rejectedAt: approversNote.notedAt,
              rejectedBy: approversNote.notedBy,
              rejectedRemark: approversNote.remarks,
            };
            break;
          default:
            updateConcern = {
              ...updateConcern,
              startedAt: concernRequest.startedAt,
              endedAt: concernRequest.endedAt,
            };
            break;
        }

        // const updateNotif = {
        //   type: "admin",
        //   title: `HR Request ${
        //     approversNote.status === requestStatus.new
        //       ? "Updated"
        //       : approversNote.status.charAt(0).toUpperCase() +
        //         approversNote.status.slice(1, approversNote.status.length)
        //   }`,
        //   content: `Your HR request was ${
        //     approversNote.status === requestStatus.new
        //       ? "updated"
        //       : approversNote.status
        //   }.`,
        //   user_id: concernRequest.user_id,
        //   recurring: "true",
        // };

        if (updateConcern.status === requestStatus.approved) {
          dispatch(essActions.approve(updateConcern, goBackPath));
        } else if (updateConcern.status === requestStatus.onHold) {
          dispatch(essActions.hold(updateConcern, goBackPath));
        } else if (updateConcern.status === requestStatus.rejected) {
          dispatch(essActions.reject(updateConcern, goBackPath));
        } else {
          dispatch(essActions.update(updateConcern, goBackPath));
        }
        setApproversNote(approverDetails);
        setRemarkModal(false);
        setRender(false);
        //dispatch(notifActions.createNotif(updateNotif));
      }
    });
  };

  const descriptionChange = (e) => {
    setConcernRequest({
      ...concernRequest,
      [e.target.name]: e.target.value,
    });
    setUpdate(true);
  };

  const ApproverDetails = ({ details, status, date, manager }) => {
    if (userManager.length === 0) {
      const getManager = async () => {
        try {
          const user = await userService.getById(manager);
          setManager(user.user[0].details);
        } catch (error) {
          console.error(error);
        }
      };
      getManager();
    }
    if (userManager.length !== 0) {
      return (
        <div>
          <div className="request-approver-content">
            <div>
              <p>Approver : </p>
              <div>
                <img
                  src={userManager[0]?.avatar}
                  alt="approver-img"
                  onError={(e) => (e.target.src = avatar)}
                />
                <p>
                  {[userManager[0]?.firstName, userManager[0]?.lastName].join(" ")}
                </p>
              </div>
            </div>
              <div>
                <p>Role : Manager</p>
                {/* <p>{`Date of ${
                  status === requestStatus.rejected
                    ? "Rejection"
                    : status === requestStatus.onHold
                    ? "Hold"
                    : "Approval"
                } : ${moment(date).format("MMMM DD YYYY")}`}</p> */}
              </div>
          </div>

          {concernRequest.noter_details?.length !== 0 ? (
            <div>
              <div className="request-approver-content">
                <div>
                    <p>Held by : </p>
                    {
                        concernRequest.noter_details?.map(detail => (
                            <div>
                                <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                                <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                            </div>
                        ))
                    }
                </div>
                <div>
                    <p>{`Date put on hold :  ${moment(concernRequest?.notedAt).format("MMMM DD YYYY")}`}</p>
                </div>
              </div>
              <div className="floating-label">
                <textarea
                className="floating-input floating-textarea hr-textarea"
                value={concernRequest?.notedRemark}
                name="description"
                cols="30"
                placeholder=" "
                style={{ resize: "none" }}
                row="2"
                readOnly
                disabled
                />
                <label className="floating-text">Remarks</label>
              </div>
            </div>
          ) : ''}

          {concernRequest?.approver_details?.length !== 0 ? (
            <div>
              <div className="request-approver-content">
                <div>
                  <p>Approved by : </p>
                  {
                    concernRequest.approver_details?.map(detail => (
                      <div>
                          <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                          <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                      </div>
                    ))
                  }
                </div>
                <div>
                    <p>{`Date of Approval : ${moment(concernRequest?.approvedAt).format("MMMM DD YYYY")}`}</p>
                </div>
              </div>
              <div className="floating-label">
                  <textarea
                  className="floating-input floating-textarea hr-textarea"
                  value={concernRequest?.approvalRemark}
                  name="description"
                  cols="30"
                  placeholder=" "
                  style={{ resize: "none" }}
                  row="2"
                  readOnly
                  disabled
                  />
                  <label className="floating-text">Remarks</label>
              </div>
            </div>
          ) : ''}

          {concernRequest?.rejecter_details?.length !== 0 ? (
            <div>
              <div className="request-approver-content">
                <div>
                    <p>Rejected by : </p>
                    {
                      concernRequest.rejecter_details?.map(detail => (
                        <div>
                            <img src={detail.avatar} alt="holder-img" onError={(e) => (e.target.src = avatar) }/>
                            <p>{[detail.firstName, detail.lastName].join(" ")}</p>
                        </div>
                      ))
                    }
                </div>
                  <div>
                    <p>{`Date rejected : ${moment(concernRequest?.rejectedAt).format("MMMM DD YYYY")}`}</p>
                  </div>
              </div>
              <div className="floating-label">
                  <textarea
                  className="floating-input floating-textarea hr-textarea"
                  value={concernRequest?.rejectedRemark}
                  name="description"
                  cols="30"
                  placeholder=" "
                  style={{ resize: "none" }}
                  row="2"
                  readOnly
                  disabled
                  />
                  <label className="floating-text">Remarks</label>
              </div>
            </div>
          ) : ''}
        </div>
      );
    } else {
      return <React.Fragment />;
    }
  };

  return (

    <CustomContentWrapper
        displayUserWrapper={isDisplayUserDetails}
        style={{ gridTemplateColumns: "70% 27%" }}
    >
    <ContentWrapper className="update_concern">
      <div className="table-card">
        <div>
          {props.hrConcerns.loading && (
            <em style={{ color: "#9BA3CA" }}>loading...</em>
          )}
        </div>
        <div>
          {concernRequest._id === requestId && (
            <div className="row">
              <div className="col s4 p-0" style={{ width: "100%" }}>
                <p className="rr-text rr-text-title rr-row-spacing">
                  Review Hr Concern Application
                </p>
                {/* {concernRequest?.notedRemark && concernRequest.status !== "new" ? (
                    <div className="rr-text-content">
                        <p className="info" style={{ color: "#B1822D", marginTop: "0",}}>
                            On hold remark :{" "}
                            <span style={{color: "#9BA3CA", fontWeight: "normal",textTransform: "lowercase",}}>
                                {concernRequest?.notedRemark}
                              </span>
                            </p>
                          </div>
                        ) : ( "" )}
                {concernRequest.rejectedRemark && update !== true? (
                    <div className="rr-text-content">
                        <p className="info"style={{ color: "#AE555C", marginTop: "0",}}>
                            Rejection remark :{" "}
                            <span style={{color: "#9BA3CA",fontWeight: "normal",textTransform: "lowercase"}}>
                                {concernRequest.rejectedRemark}
                              </span>
                            </p>
                          </div>
                        ) : ("")}
                {concernRequest.approvalRemark && update !== true? (
                    <div className="rr-text-content">
                        <p  className="info" style={{ color: "#2B7A69",marginTop: "0" }}>
                            Approval remark :{" "}
                        <span style={{ color: "#9BA3CA",fontWeight: "normal",textTransform: "lowercase"}}>
                            {concernRequest.approvalRemark}
                        </span>
                        </p>
                    </div>
                    ) : ( "" )} */}
                <div
                  className="rr-text rr-row-spacing"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <p className="rr-text-title">
                    Application Status :
                    <span
                      className={cc([
                        "rr-text-content",
                        concernRequest.status === requestStatus.onHold
                          ? "onHold"
                          : concernRequest.status,
                      ])}
                    >
                      {" "}
                      {concernRequest.status
                        ? concernRequest.status === requestStatus.new
                          ? "In review"
                          : concernRequest.status
                        : "No Status"}
                    </span>
                  </p>
                  <p className="rr-text-title">
                    Date Filed :{" "}
                    <span className="rr-text-content">
                      {moment(concernRequest.createdAt).format(
                        "dddd, MMMM DD YYYY"
                      )}
                    </span>
                  </p>
                </div>
                <div>
                  <div className="floating-label">
                    <textarea
                      className="floating-input floating-textarea hr-textarea"
                      value={concernRequest.description}
                      name="description"
                      cols="30"
                      placeholder=" "
                      style={{ resize: "none" }}
                      row="10"
                      onChange={descriptionChange}
                      readOnly={
                        concernRequest.status === "approved" ||
                        concernRequest.status === "rejected"
                      }
                    />
                    <label className="floating-text">Description</label>
                  </div>
                </div>

                <div className="div-attach" style={{marginBottom:'20px'}}>
                  <p className="info" style={{ marginTop: "0" }}>
                      Attachments
                  </p>

                  {concernRequest.files?.length !== 0 || concernRequest.files === 'undefined' || concernRequest.files === '' || concernRequest.files === null ? (
                      concernRequest.files?.map((c) => (
                          // <p>{c.split('-').pop()}</p>
                          <div key={c} className="attach-div">
                              <div className="attach-preview">
                                  <img
                                      src={c}
                                      className="attach-file"
                                      alt="Thumb"
                                      onError={(e) => (e.target.src = fileIcon) }
                                  />
                                      <p onClick={() => { openFilePreview(); setFileUrl(c); setFileType(c.split('.').pop()) }} style={{cursor:'pointer'}}>{c.split('/').pop()}</p>
                              </div>
                              <a href={c} download>
                                  <button className="btn-download" style={{background:'none'}} >
                                      <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'contrast(0.5)' }}/>
                                  </button>
                              </a>
                          </div>
                      ))
                  ) : (
                  <>
                      <div className="attach-div">
                          <div className="attach-preview">
                              <p>No File Attached</p>
                          </div>
                      </div>
                  </>
                  )}
                </div>

                <div>
                  <Modal
                    isOpen={showFilePreview}
                    ariaHideApp={true}
                    toggle={setFilePreview}
                    onRequestClose={() => setFilePreview(false)}
                    shouldCloseOnOverlayClick={true}
                    className="fileModalPreview"
                    style={customStyles}
                  >
                    <div
                      className="row center rejecting-container"
                      style={{
                        width: "auto",
                        height: "auto",
                        margin: "auto",
                        padding: "50px",
                      }}
                    >
                    <div className="gAction">
                        <a href={FileUrl} download>
                            <button className="btn-download" style={{background:'none'}}>
                                <img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'brightness(5.5)' }}/>
                            </button>
                        </a>
                        <img height={20} onClick={() => setFilePreview(false)} src={close} alt="" style={{ margin:'15px 7px', filter: 'brightness(5.5)', cursor:'pointer' }}/>
                    </div>
                    <div style={{ height: '100%' }}>
                    <FileViewer 
                        fileType={FileType} 
                        filePath={FileUrl} 
                        key= {FileUrl}
                    />
                    </div>

                    </div>
                  </Modal>
                </div>

                <ApproverDetails
                  details={
                    concernRequest.status === requestStatus.approved ||
                    concernRequest.status === requestStatus.completed
                      ? concernRequest.approver_details
                      : concernRequest.status === requestStatus.onHold
                      ? concernRequest.noter_details
                      : concernRequest.status === requestStatus.rejected
                      ? concernRequest.rejecter_details
                      : []
                  }
                  manager={concernRequest.users[0].manager}
                  status={concernRequest.status}
                  date={
                    concernRequest.status === requestStatus.approved ||
                    concernRequest.status === requestStatus.completed
                      ? concernRequest.approvedAt
                      : concernRequest.status === requestStatus.onHold
                      ? concernRequest.notedAt
                      : concernRequest.status === requestStatus.rejected
                      ? concernRequest.rejectedAt
                      : ""
                  }
                />
                {/* {concernRequest.status !== requestStatus.new && (
                  <div className="floating-label">
                    <textarea
                      className="floating-input floating-textarea hr-textarea"
                      value={
                        concernRequest.status === requestStatus.approved ||
                        concernRequest.status === requestStatus.completed
                          ? concernRequest.approvalRemark
                          : concernRequest.status === requestStatus.onHold
                          ? concernRequest.notedRemark
                          : concernRequest.status === requestStatus.rejected
                          ? concernRequest.rejectedRemark
                          : ""
                      }
                      name="description"
                      cols="30"
                      placeholder=" "
                      style={{ resize: "none" }}
                      row="2"
                      readOnly
                    />
                    <label className="floating-text">Remarks</label>
                  </div>
                )} */}
                <div className="row concern-buttons">
                  <div
                    className="col s1 m8 l8 buttonHROne"
                    style={{ width: "60%" }}
                  >
                    {concernRequest.status === "on hold" ||
                    concernRequest.status === "new" ? (
                      <span>
                        {concernRequest.user_id !== admin.user._id ? (
                          <span>
                            <button
                              className="btn concern-btn-approve-dark"
                              onClick={() =>
                                openRemarkModal(requestStatus.approved)
                              }
                            >
                              Approve
                            </button>
                            <button
                              className="btn concern-btn-hold-dark"
                              onClick={() =>
                                openRemarkModal(requestStatus.onHold)
                              }
                            >
                              Hold
                            </button>
                            <button
                              className="btn concern-btn-reject-dark"
                              onClick={() =>
                                openRemarkModal(requestStatus.rejected)
                              }
                            >
                              Reject
                            </button>
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="col s1 m4 l4 right buttonHRTwo"
                    style={{ width: "40%" }}
                  >
                    <span className="right">
                      <button
                        className="btn concern-btn-cancel-dark"
                        onClick={goBack}
                      >
                        BACK
                      </button>
                    </span>
                    {concernRequest.status === "new" ||
                    concernRequest.status === "on hold" ? (
                      <span>
                        <button
                          className={
                            update === false
                              ? "btn-concern concern-update-dark disabled right"
                              : "btn-concern concern-update-dark right"
                          }
                          onClick={() => {
                            handleSubmit(concernRequest._id);
                          }}
                        >
                          UPDATE
                        </button>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div>
                <Modal
                  isOpen={showRemarkModal}
                  ariaHideApp={true}
                  toggle={setRemarkModal}
                  onRequestClose={() => setRemarkModal(false)}
                  shouldCloseOnOverlayClick={true}
                  className="AnnouncementModalBG"
                >
                  <div
                    className="row center rejecting-container"
                    style={{
                      width: "auto",
                      height: "auto",
                      margin: "auto",
                      padding: "50px",
                    }}
                  >
                    <p className="coe-info left">
                      {`Please state your reason for ${
                        approversNote.status === requestStatus.approved
                          ? "approving"
                          : approversNote.status === requestStatus.onHold
                          ? "on hold"
                          : approversNote.status === requestStatus.rejected
                          ? "rejecting"
                          : ""
                      } :`}
                    </p>
                    <br />

                    <div className="col s12 m12 l12">
                      <div className="floating-label">
                        <textarea
                          className="floating-input floating-textarea hr-textarea rejecting"
                          value={approversNote.remarks}
                          name="remarks"
                          // cols="30"
                          placeholder=" "
                          style={{ resize: "none" }}
                          onChange={onRemarksChange}
                          row="10"
                        ></textarea>
                        <label className="floating-text">Remarks</label>
                      </div>
                    </div>
                    <div className="col s10 m10 l10 coe-buttons">
                      <span className="right-dark">
                        <button
                          className="btn coe-btn-cancel-dark"
                          onClick={() => setRemarkModal(false)}
                        >
                          Cancel{" "}
                        </button>
                        <button
                          className={cc([
                            "btn",
                            approversNote.status === requestStatus.approved
                              ? "coe-btn-approve-dark"
                              : approversNote.status === requestStatus.onHold
                              ? "coe-btn-hold-dark"
                              : approversNote.status === requestStatus.rejected
                              ? "btn coe-btn-reject-dark"
                              : "",
                          ])}
                          onClick={() => {
                            approversNote.remarks &&
                              handleSubmit(concernRequest._id);
                          }}
                        >
                          {approversNote.status === requestStatus.approved
                            ? "Approve"
                            : approversNote.status === requestStatus.onHold
                            ? "Hold"
                            : approversNote.status === requestStatus.rejected
                            ? "Reject"
                            : ""}
                        </button>
                      </span>
                    </div>
                  </div>
                </Modal>
              </div>
              {/* <div className="col s3">
                <div className="row table-two" id="zero">
                  <div className="col s12 m11 l1">
                    <ReactImageFallBack
                      src={concernRequest.users[0].avatar}
                      fallbackImage={avatar}
                      initialImage={avatar}
                      alt="user avatar"
                      className="hr-user-photo-dark circle responsive-img"
                    />
                  </div>
                </div>
                <div>
                  <p className="info-dark">
                    {concernRequest.users[0].firstName}{" "}
                    {concernRequest.users[0].lastName}
                  </p>
                  <p className="coe-detail">
                    {concernRequest.users[0].position}
                  </p>
                </div>
                <div>
                  <p className="concern-info-header">Info</p>

                  <p className="concern-info-header">Contact Number</p>
                  <span className="coe-detail">
                    {concernRequest.users[0].contactNumber
                      ? concernRequest.users[0].contactNumber
                      : "No Contact"}
                  </span>

                  <p className="concern-info-header">Address</p>
                  <span className="coe-detail">
                    {concernRequest.users[0].address1
                      ? concernRequest.users[0].address1 + ","
                      : ""}
                    {concernRequest.users[0].address2
                      ? concernRequest.users[0].address2 + ","
                      : ""}
                    {concernRequest.users[0].city
                      ? concernRequest.users[0].city + ","
                      : ""}
                    {concernRequest.users[0].country
                      ? concernRequest.users[0].country
                      : ""}
                  </span>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </ContentWrapper>
    <When condition={isDisplayUserDetails === true}>
        <PreviewLeaveUserWidget
            {...props}
            subjectId={concernRequest.users}
            availableCredits="false"
            comments ={comment}
            replies = {reply}
            request = {concernRequest}
            isReply={Object.keys(comment).length !== 0}
        />
    </When>
    </CustomContentWrapper>
  );
});

export default UpdateConcernPage;
