import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from "react-redux";

import { expActions } from '../_core/actions/exp';
import { UserDashboardContext } from "../UserDashboard/UserDashboard";
import  RewardMainPage  from './RewardPage';
import RedemptionPage from './RedemptionPage';

import Footer from '../Footer/Mainfooter.js';
import { Route } from 'react-router-dom';
import ProfileWidget from '../ProfileWidget/ProfileWidget';
import AttributesWidget from '../AttributesWidget/AttributesWidget';
import TeamWidget from '../TeamWidget/TeamWidget';
import CurrentLevelWidget from '../CurrentLevelWidget/CurrentLevelWidget';
import { SubPanelBtn } from '../Components/SubPanelBtn';

import './Rewards.scss';


const Rewards = (props) => {
	const dispatch = useDispatch();

	const secret = JSON.parse(localStorage.getItem("secretDetails"));

	React.useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		const unknown = secret ? secret.user_id : user.user_id

		dispatch(expActions.getExp(unknown));
		
	}, [dispatch]);	// eslint-disable-line 
	
    const device = React.useContext(UserDashboardContext).device;
    const auth = props.authentication;

	//const url = window.location.pathname;

	return (
		<div className={device === 'desktop' ? "row rewards desktop-footer" : "row rewards"}>
			<div
				className={cc([
					'sub-panel',
					'col',
					's12',
					'l4',
					device
				])}
			>
			
				{auth &&
					<div>
                        <ProfileWidget />
                        <CurrentLevelWidget
                            engageMode
                            exp={props.exp}
                        />
                        <AttributesWidget
                            userId={secret ? secret.user_id : props.authentication.user?.user?._id}
                        />
                        <TeamWidget 
							team={secret ? secret.user_id : props.authentication?.user?.user?.team} 
							userId={ secret ? secret.user_id : props.authentication.user?.user?._id} 
						/>
						<>
						<SubPanelBtn name="LeaderBoards" link="/engage/leaderboards"/>
						</>
					</div>
				}
				{device === 'desktop' &&
					<div><Footer /></div>
				}
			</div>
			<div
				className={cc([
					'main-panel',
					'col',
					's12',
					'l8',
					device
				])}
			>
				<Route exact path="/rewards" component={RewardMainPage}/>
				<Route exact path="/rewards/redemption" component={RedemptionPage}/>	
				
			</div>
			{device === 'tablet' &&
				<div><Footer /></div>
			}
		</div>
	)
};

const mapStateToProps = (state) => ({
	isLoading: (
		state.exps.loading
	) === true,
	exp: state.exps.exp?.[0]?.exp || 0,
	authentication: state.authentication,
});

export default React.memo(connect(mapStateToProps)(Rewards));