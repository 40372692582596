export const pageConstants = {
    GETUSER_REQUEST: 'USERS_GETALL_REQUEST',
    GETUSER_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETUSER_FAILURE: 'USERS_GETALL_FAILURE',

    GETLEAVE_REQUEST: 'LEAVES_GETALL_REQUEST',
    GETLEAVE_SUCCESS: 'LEAVES_GETALL_SUCCESS',
    GETLEAVE_FAILURE: 'LEAVES_GETALL_FAILURE',

    GETOVERTIME_REQUEST: 'OVERTIMES_GETALL_REQUEST',
    GETOVERTIME_SUCCESS: 'OVERTIMES_GETALL_SUCCESS',
    GETOVERTIME_FAILURE: 'OVERTIMES_GETALL_FAILURE',

    //attributes
    GETALL_REQUEST: 'ATTRIBUTES_GETALL_REQUEST',
    GETALL_SUCCESS: 'ATTRIBUTES_GETALL_SUCCESS',
    GETALL_FAILURE: 'ATTRIBUTES_GETALL_FAILURE',

    //roles
    GETROLE_REQUEST: 'ROLES_GETALL_REQUEST',
    GETROLE_SUCCESS: 'ROLES_GETALL_SUCCESS',
    GETROLE_FAILURE: 'ROLES_GETALL_FAILURE',

    //pragger
    GETPRAGGER_REQUEST: 'PRAGGER_GETALL_REQUEST',
    GETPRAGGER_SUCCESS: 'PRAGGER_GETALL_SUCCESS',
    GETPRAGGER_FAILURE: 'PRAGGER_GETALL_FAILURE',
}