import { post, get, put, del } from '../helpers/api';
import Swal from 'sweetalert2';
import { activationTime } from '../helpers/config'
import { history } from '../helpers/history';
import { decryptUserEmail } from '../helpers/crypto_config';
import { permissionService } from './permission'

function checkLogin(user) {
    let username = user.email
    let password = user.currentPassword
    let newUserPass = user

    return post('/api/v1/auth/signin', { email: username, password: password })
        .then(handleResponse)
        .then(user => {
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('userCredentials', JSON.stringify(user));
                changePass(newUserPass);
            }

            return user;
        });
}
function changePass(user) {
    return put('/api/v1/auth/password', JSON.stringify(user))
        .then(handleResponse)
}

function login(username, password, refreshToken) {
    return post('/api/v1/auth/signin', { email: username, password: password })

        .then(handleResponse)
        .then(user => {
            if ((user.token)) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('userCredentials', JSON.stringify(user));
                if (refreshToken) {
                    localStorage.setItem('refresh_token', user.refreshtoken);
                    localStorage.setItem('ref_token_request', true)
                }
                var token = user.expired_at;
                localStorage.setItem('token_expire', token);

                permissionService.getAll(1, 1000).then(allPermission => {
                    const addItems =  hasAddPermission(allPermission.items, user.user.permissions);
                    const deleteItems =  hasDeletePermission(allPermission.items, user.user.permissions);
                    const editItems =  hasEditPermission(allPermission.items, user.user.permissions);
                    const viewItems =  hasViewPermission(allPermission.items, user.user.permissions);
                    localStorage.setItem('addPermission', JSON.stringify(addItems));
                    localStorage.setItem('deletePermission', JSON.stringify(deleteItems));
                    localStorage.setItem('editPermission', JSON.stringify(editItems));
                    localStorage.setItem('viewPermission', JSON.stringify(viewItems));
                })
            }
            return user;
        });
}

function hasAddPermission(allPermission, userPermission) {
    const idsWithAddAction =  userPermission?.filter(y=>(y.actions.includes("Add"))).map(z=>z._id);
    const permissionWithAdd = allPermission?.filter(item => idsWithAddAction.includes(item._id)).map(p=> p.permissionName);

    return permissionWithAdd
}

function hasDeletePermission(allPermission, userPermission) {
    const idsWithDeleteAction =  userPermission?.filter(y=>(y.actions.includes("Delete"))).map(z=>z._id);
    const permissionWithDelete = allPermission?.filter(item => idsWithDeleteAction.includes(item._id)).map(p=> p.permissionName);

    return permissionWithDelete
}

function hasEditPermission(allPermission, userPermission) {
    const idsWithEditAction =  userPermission?.filter(y=>(y.actions.includes("Edit"))).map(z=>z._id);
    const permissionWithEdit = allPermission?.filter(item => idsWithEditAction.includes(item._id)).map(p=> p.permissionName);

    return permissionWithEdit
}
function hasViewPermission(allPermission, userPermission) {
    const idsWithView =  userPermission?.filter(y=>(y.actions.includes("View"))).map(z=>z._id);
    const permissionWithView = allPermission?.filter(item => idsWithView.includes(item._id)).map(p=> p.permissionName);

    return permissionWithView
}

function getUserDetails(id) {
    const uid = JSON.parse(id)
    return get(`/api/v1/user/${uid}`).then(handleResponse)
        .then(user => {
            const userDetails = user.user[0].details[0];
            let userCredsEmail = user.user[0]["email"];
            let userCredsOrg = user.user[0]["organization"];
            localStorage.setItem('user', JSON.stringify(userDetails));
            let spreadData = localStorage.getItem('userCredentials');
            let spreadDataParsed = JSON.parse(localStorage.getItem('userCredentials'));
            let userCredsDetails = [];
            let userStoreLocalStorage = []; // eslint-disable-line

            if(spreadData !== undefined) {
    
               userCredsDetails = {
                    ...spreadDataParsed['user'],
                    ["email"]: decryptUserEmail(userCredsEmail),  // eslint-disable-line
                    ["organization"]: userCredsOrg, // eslint-disable-line
               }

               let userStoreLocalStorage = {
                    expired_at: spreadDataParsed['expired_at'],
                    refreshtoken: spreadDataParsed['refreshtoken'],
                    token: spreadDataParsed['token'],
                    user: userCredsDetails
                }
               localStorage.setItem('userCredentials', JSON.stringify(userStoreLocalStorage))
            }
        }).catch(err => console.error(err))
}

function getAll(selectPage, itemPerPage) {
    return get(`/api/v1/user?page=${selectPage || 1}&items-per-page=${itemPerPage || 20}`).then(handleResponse);
}

function getAllSearchFilter(selectPage,itemPerPage, search) {
    return get(`/api/v1/user?page=${selectPage || 1}&items-per-page=${itemPerPage || 20}&search=${search}`).then(handleResponse);
}

function getAllActiveUsers(page = 1, itemsPerPage = 10, filter) {
    return get(`/api/v1/user?page=${page}&items-per-page=${itemsPerPage}&filter=active:1`).then(handleResponse);
}
function getAllActive() {
    return get(`/api/v1/user?page=1&items-per-page=10000000&filter=active:1`).then(handleResponse);
}

function getById(id) {
    return get(`/api/v1/user/${id}`).then(handleResponse).then(localStorage.setItem('userid', JSON.stringify(id)));
}

function getUser(id) {
    const uid = JSON.parse(id)
    return get(`/api/v1/user/${uid}`).then(handleResponse);
}

function getUserMention(id) {
    return get(`/api/v1/user/${id}`).then(handleResponse);
}

function getUserById(id) {
    return get(`/api/v1/user/${id}`).then(handleResponse);
}

function getUserByIdDetails(id) {
    return get(`/api/v1/user-details/${id}`).then(handleResponse);
}

function getToken(id) {
    return post(`/api/v1/auth/token/${id}`)
        .then(handleResponse)
        .then(response => {
            const tokenB = response.success;
            if (tokenB) {
                console.log(window.location.origin)
                changePasswordEmail(id,tokenB.token)
            }
        });
}

function changePasswordEmail(id,tokenB){
    let body = {
        "link": window.location.origin+"/changepassword/"+tokenB
    }
    return post(`/api/v1/mail/${id}/password`, body).then(handleResponse);
}

function getUserCore() {

    let coreToken =  JSON.parse(localStorage.getItem('coreCredentials'));

    return fetch('http://api-dev.pragmacore.prgmnl.com/api/v1/users/me', {
        method: "GET",
        headers: {"Authorization": "Bearer "+coreToken.access_token}
      })
  .then(response => response.json())
  .then(data => data.tenant.organizations.map(filteredName => {
    return filteredName.organization.guid
  }))

}

function postUserCore(id,data) {
    
     let coreToken =  JSON.parse(localStorage.getItem('coreCredentials'));

    return fetch(`http://api-dev.pragmacore.prgmnl.com/api/v1/organizations/${id}/users`, {
        method: 'POST',
        headers: {
            'Authorization': "Bearer " + coreToken.access_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
        console.log('Success:', data.message);
    })
    .catch((error) => {
        console.error('Error:', error);
    });

}


function register(user) {
    user.pragmacore = 0;
    return post('/api/v1/auth/signup', JSON.stringify(user)).then(handleResponse)
    .then(response => {
        let currentUser = JSON.parse(localStorage.getItem('userCredentials'))
        let core = currentUser.user.pragmacore;

        if(core === 1){
            const postFeed = {
                "email": user.email,
                "password": user.password,
                "firstName": user.firstName,
                "lastName": user.lastName
            }

            getUserCore().then(data => {
                data.map(users => {
                    return postUserCore(users,postFeed);
                });
            })
        }else{
            return response
        }
    });
}

function update(user) {
    let currentUser = JSON.parse(localStorage.getItem('userCredentials'))
    let logId = currentUser.user._id;
    let id = user._id;
    delete user._id;
    return put(`/api/v1/user/${id}`, JSON.stringify(user))
        .then(handleResponse)
        .then(id === logId ? getUserDetails(JSON.stringify(id)) : '')
}

function editRole(user) {
    let id = user._id;
    delete user._id;
    return put(`/api/v1/user/${id}`, JSON.stringify(user))
        .then(handleResponse)
}

function updateUser(user) {
    let id = user._id;
    delete user._id;
    return put(`/api/v1/user/${id}`, JSON.stringify(user))
        .then(handleResponse)
        .then(getUserDetails(JSON.stringify(id)))
}

function roleAssign(user, role) {
    return put(`/api/v1/user/${user.id}/role`, JSON.stringify(role))
        .then(handleResponse)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return del(`/api/v1/user/${id}`).then(handleResponse)
}

function _deactivate(id) {
    return put(`/api/v1/user/${id}/deactivate`).then(handleResponse)
}

function _activate(id) {
    return put(`/api/v1/user/${id}/activate`).then(handleResponse)
}

function uploadAvatar(avatar, id) {
    let currentUser = JSON.parse(localStorage.getItem('userCredentials'))
    let logId = currentUser.user._id;
    // let id = JSON.parse(localStorage.getItem('userid'));
    return post(`/api/v1/upload/${id}/avatar`, avatar)
        .then(handleResponse)
        .then(id === logId ? getUserDetails(JSON.stringify(id)) : '')
}

function uploadCover(cover, id) {
    let currentUser = JSON.parse(localStorage.getItem('userCredentials'))
    let logId = currentUser.user._id;
    // let id = JSON.parse(localStorage.getItem('userid'));
    return post(`/api/v1/upload/${id}/cover`, cover)
        .then(handleResponse)
        .then(id === logId ? getUserDetails(JSON.stringify(id)) : '')
}

function verifyEmail(verifyEmail) {
    return get(`/api/v1/auth/verify/${verifyEmail}`).then(handleResponse)
}

function verifyPassword(id, userPassword) {
    console.log(userPassword)
    return post(`/api/v1/auth/password/${id}`,userPassword).then(handleResponse)
}

function refreshToken(reftoken) {
    const token = JSON.parse(reftoken)
    return post('/api/v1/auth/refresh-token', { refreshToken: token }).then(handleResponse)
        .then(user => {
            if ((user.token)) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                if ((user.token)) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('userCredentials', JSON.stringify(user));

                    //add refresh token
                    // localStorage.setItem('refresh_token', user.refreshtoken);
                    // localStorage.setItem('ref_token_request', true)

                    var token = user.expired_at;
                    localStorage.setItem('token_expire', token);
                }
                return user;
            }
            return user;
        });
}

function logout() {
    const userId = JSON.parse(localStorage.getItem('userCredentials'))?.user._id;

    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('userCredentials');
    // remove previous token datetime expiration
    localStorage.removeItem('token_expire');
    localStorage.removeItem('userid');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('ref_token_request');
    localStorage.removeItem('loggingOut');
    localStorage.removeItem('userRole');
    localStorage.removeItem('coreCredentials');
    localStorage.removeItem('secretDetails');
    localStorage.removeItem('secretRole');
    localStorage.removeItem('secretCredentials');
    localStorage.removeItem('addPermission');
    localStorage.removeItem('deletePermission');
    localStorage.removeItem('editPermission');
    localStorage.removeItem('viewPermission');

    put('/api/v1/session/user', JSON.stringify({userId: userId})).then(handleResponse);
    // return history.push('/login')
}

function stopImpersonate() {

    // remove user from local storage to stop impersonate
    localStorage.removeItem('secretDetails');
    localStorage.removeItem('secretRole');
    localStorage.removeItem('secretCredentials');
}

const userActivityListener = new (
    class UserActivityListener {
        constructor() {
            this.eventList = [
                'load',
                'mousemove',
                'mousedown',
                'touchstart',
                'keypress',
                'scroll'
            ]
            this.reg_token_timeout = activationTime.regularToken; //5 mins
            this.ref_token_timeout = activationTime.refreshToken; //15 mins activation
            this.refToken = localStorage.getItem('ref_token_request');
            this.timer = {};
            this.user = localStorage.getItem('userCredentials');
        }

        addListeners = () => {
            this.eventList.forEach(event => {
                window.addEventListener(event, this.resetThenStart);
            })
        }

        forceLogout = () => {
            clearTimeout(this.timer);

            logout();

            Swal.fire({
                title: 'Logged out!',
                html: "<p> Session time out </p>",
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Log out',
            });

            this.removeListeners();

            history.push('/login');
        }

        removeListeners = () => {
            this.eventList.forEach(event => {
                window.removeEventListener(event, this.resetThenStart);
            })
        }

        reset = () => {
            clearTimeout(this.timer);
        }

        resetThenStart = () => {
            clearTimeout(this.timer);

            this.start();
        }

        start = () => {
            if (this.refToken && this.user) {
                this.timer = setTimeout(this.forceLogout, this.ref_token_timeout);
            } else if (this.user) {
                this.timer = setTimeout(this.forceLogout, this.reg_token_timeout);
            }
        }
    }
)();



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete,
    roleAssign,
    updateUser,
    deactivate: _deactivate,
    activate: _activate,
    changePass,
    checkLogin,
    uploadAvatar,
    uploadCover,
    getUser,
    getUserMention,
    refreshToken,
    userActivityListener,
    getAllActiveUsers,
    getAllActive,
    editRole,
    getUserDetails,
    getUserById,
    getUserByIdDetails,
    getUserCore,
    postUserCore,
    getToken,
    verifyEmail,
    verifyPassword,
    stopImpersonate,
    getAllSearchFilter
};