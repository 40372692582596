export const teamConstants = {
    CREATE_TEAM_REQUEST: 'CREATE_TEAM_REQUEST',
    CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
    CREATE_TEAM_FAILURE: 'CREATE_TEAM_FAILURE',

    UPDATE_TEAM_REQUEST: 'UPDATE_TEAM_REQUEST',
    UPDATE_TEAM_SUCCESS: 'UPDATE_TEAM_SUCCESS',
    UPDATE_TEAM_FAILURE: 'UPDATE_TEAM_FAILURE',

    GETALL_REQUEST: 'GETALL_TEAM_REQUEST',
    GETALL_SUCCESS: 'GETALL_TEAM_SUCCESS',
    GETALL_FAILURE: 'GETALL_TEAM_FAILURE',

    GETBYID_REQUEST: 'GETBYID_TEAM_REQUEST',
    GETBYID_SUCCESS: 'GETBYID_TEAM_SUCCESS',
    GETBYID_FAILURE: 'GETBYID_TEAM_FAILURE'
};
