import React, { useState } from "react";
import {useDispatch } from "react-redux";
import Modal from 'react-modal';
import {useDropzone} from 'react-dropzone';
import { DragDropImage,} from "../Admin/Components/ImageUploader/upload";
import sweetAlert from 'sweetalert2';

import dropIcon from '../_templates/dropIcon.png'
import closeIcon from '../_templates/x_square.png';
import warning from '../_templates/warning_outline.png';

import { challengeActions } from "../_core/actions/challenge";
import { userChallengeActions } from "../_core/actions/userChallenge";

import './ChallengeWidget.sass'
import { userChallengeService } from "../_core/services/userChallenge";
import { challengeService } from "../_core/services/challenge";
import { notifActions } from "../_core/actions/notification";
import { essService } from "../_core/services/ess";

const toast = sweetAlert.mixin({
	toast: true,
	width: 300,
	position: 'center',
	showConfirmButton: false,
	timer: 1500
});

const ChallengeUpdate = (props) => {
    const dispatch = useDispatch();
    const [InputError, setInputError] = useState(false);
    const [files, setFiles] = React.useState([]);

    const removeSelectedImage = () => {
        setFiles([]);
    } 
 
    const {getRootProps, getInputProps, open} = useDropzone({
        accept: 'image/x-png, image/png, image/jpg, image/jpeg, image/gif',
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
            })));
        }
    });

    const quitChallenge = () => {

        if(props.challengeType === "questChallenge"){
            dispatch(userChallengeActions.quitQuestChallenge(props.challengerId, props.challengeId));
            toast.fire({
                type: 'success',
                title: `You've quit the quest challenge.`
            });
            props.handleCloseModal(false)
            setTimeout(function(){
                challengeService.getOne(props.challengeId).then(res=> {
                    if(res.success){
                        props.loadQuest(res.success)
                    }
                })
            }
            , 2000);
        
        }else{
            dispatch(challengeActions.revertChallenge(props.challengeId));
            toast.fire({
                type: 'success',
                title: `You've quit the challenge.`
            });
            props.handleCloseModal(false)
            setTimeout(function(){
                dispatch(challengeActions.getAll(1, 100000));}
            , 2000);
        }

    }
    const finishChallenge = () => {
        if(
            files === undefined || files.length === 0
        ) {
            setInputError(true);
        }else{
            if(props.challengeType === "questChallenge"){

                // const notifInfo = {
                //     "type": "questChallengeFinish",
                //     "title": props.questTitle,
                //     "content": `/admin/quests/${props.questId}/user/${props.challengerId}/challenges`
                // }
    
                dispatch(userChallengeActions.finishQuestChallenge(props.challengerId, props.challengeId,files[0]));
                //dispatch(notifActions.createNotif(notifInfo))
                
                //Notification
                essService.getAllAdminUser()
                    .then(result => {
                        
                        // Iterate through each admin user and send notification
                        const notificationPromises = result.items.map(adminUser => {
                            const notifDetails = {
                                "type": "questChallengeFinish",
                                "title": props.questTitle,
                                "content": `/admin/quests/${props.questId}/user/${props.challengerId}/challenges`,
                                "receiverId": adminUser._id // Send notification to individual admin user
                            };
                            return dispatch(notifActions.createNotif(notifDetails))
                        });
                
                        // Wait for all notifications to be sent
                        return Promise.all(notificationPromises);
                    })
                    .then(notificationResponses => {
                        console.log('Notifications sent:', notificationResponses);
                        // Handle response if needed
                    })
                    .catch(error => {
                        console.error(error); // Handle any errors
                    });
                    
                toast.fire({
                    type: 'success',
                    title: `You've finished the challenge.`
                });
                setTimeout(function(){
                    userChallengeService.getByChallenge(props.challengerId, props.challengeId).then(res=> {
                        if(res.success){
                            props.loadQuest(res.success)
                        }
                    })
                }
                , 2000);
                props.handleCloseModal(false)
                setFiles([])
            }else{
                if(props.challenge){
                    let body = {  
                        "type": "FINISH_CHALLENGE",
                        "title": props.challenge.title,
                        "content": props.challenge.description,
                        "receiverId": props.challenge.originalPoster
                        // "request_userId": "668b8524e7881c41ecfce8c9"
                    }
                    challengeService.createChallengeNotif(body) // send notif api
                }
                
                dispatch(challengeActions.finishChallenge(props.challengeId,files[0]));
                toast.fire({
                    type: 'success',
                    title: `You've finished the challenge.`
                });
                setTimeout(function(){
                    dispatch(challengeActions.getAll(1, 100000));}
                , 2000);
                props.handleCloseModal(false)
                setFiles([])
            }

        }

    }

    const cancel = () => {
        setFiles([]);
        props.handleCloseModal(false)
    }


  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        toggle={props.toggle}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
        centered
        className="ChallengeUpdate"
      >
        <div className="row center create-challenge-container"
            style={{
                width: "100%",
                height: "auto",
                margin: "auto",
                padding: "20px 0px 20px",
                borderBottom: "1px solid #979797"
            }}>
            
            <p className="usermodal_title_update center"> Verify Completion</p>
            <button className="close-modal" onClick={cancel}>
                <img height={20} src={closeIcon} alt="" style={{ marginRight: 7}}/>
			</button>
        </div>

        <div className="request-details-container">
            <div className="div1">
                <p>Any updates about challenge </p>
                <p style={{fontWeight:'700', textTransform:'capitalize'}}>{props.challengeTitle}?</p>
            </div>
            <div {...getRootProps({className: 'dropzone Userdropzone'})}>
                <div className={files[0] ? 'active-dz': 'unactive-dz'} >
                    <input {...getInputProps()} />
                <div>
                    <p className="dropTitle" style={{display:'inline-flex'}}>
                    <img height={20} src={dropIcon} alt="" style={{ marginRight: 8 }}/>
                            Drag and drop files here 
                    </p>
                    <p className="dropTitle">or</p>
                </div>
                <button onClick={open} className="custom-file-upload-challenge">
                    Upload
                </button>
                <p className="dropTitle" style={{fontSize:'10px', marginTop:'10px'}}>Supports: JPG, JPEG &PNG</p>
            </div>
                <DragDropImage removeImage={removeSelectedImage} selectedImage={files}/>
            </div>
            {InputError&&(
                <div className="ErrorImage">
                    <img height={20} src={warning} alt="" style={{ marginRight: 7,float:'left'}}/>
                    <p style={{float:'left'}}>Please upload an image file to finish the challenge.</p>
                    <div style={{clear:'left'}}/>
                </div>
            )}
            <div className="footer-container">
                <div style={{textAlign:'center', margin:'25px'}}>
                    <button type="submit" className="btn-updateC" onClick={() => quitChallenge()}>QUIT</button>
                    <button className="btn-updateC" onClick={() => finishChallenge()} >FINISH</button>
                </div>
            </div>

        </div>


      </Modal>
    </>
  );
};

export default ChallengeUpdate;