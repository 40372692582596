export const challengeConstants = {
    CREATE_REQUEST: 'CHALLENGE_CREATE_REQUEST',
    CREATE_SUCCESS: 'CHALLENGE_CREATE_SUCCESS',
    CREATE_FAILURE: 'CHALLENGE_CREATE_FAILURE',

    GETALL_REQUEST: 'CHALLENGE_GETALL_REQUEST',
    GETALL_SUCCESS: 'CHALLENGE_GETALL_SUCCESS',
    GETALL_FAILURE: 'CHALLENGE_GETALL_FAILURE',

    GET_REQUEST: 'CHALLENGE_GET_REQUEST',
    GET_SUCCESS: 'CHALLENGE_GET_SUCCESS',
    GET_FAILURE: 'CHALLENGE_GET_FAILURE',

    DELETE_REQUEST: 'CHALLENGE_DELETE_REQUEST',
    DELETE_SUCCESS: 'CHALLENGE_DELETE_SUCCESS',
    DELETE_FAILURE: 'CHALLENGE_DELETE_FAILURE',

    ACCEPT_REQUEST: 'ACCEPT_CHALLENGE_REQUEST',
    ACCEPT_SUCCESS: 'ACCEPT_CHALLENGE_SUCCESS',
    ACCEPT_FAILURE: 'ACCEPT_CHALLENGE_FAILURE',

    QUIT_REQUEST: 'QUIT_CHALLENGE_REQUEST',
    QUIT_SUCCESS: 'QUIT_CHALLENGE_SUCCESS',
    QUIT_FAILURE: 'QUIT_CHALLENGE_FAILURE',    

    COMPLETE_REQUEST: 'COMPLETE_CHALLENGE_REQUEST',
    COMPLETE_SUCCESS: 'COMPLETE_CHALLENGE_SUCCESS',
    COMPLETE_FAILURE: 'COMPLETE_CHALLENGE_FAILURE',  

    FINISH_REQUEST: 'FINISH_CHALLENGE_REQUEST',
    FINISH_SUCCESS: 'FINISH_CHALLENGE_SUCCESS',
    FINISH_FAILURE: 'FINISH_CHALLENGE_FAILURE',  

    RESET_REQUEST: 'RESET_CHALLENGE_REQUEST',
    RESET_SUCCESS: 'RESET_CHALLENGE_SUCCESS',
    RESET_FAILURE: 'RESET_CHALLENGE_FAILURE',  

    UPDATE_REQUEST: 'CHALLENGE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CHALLENGE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CHALLENGE_UPDATE_FAILURE',

};

