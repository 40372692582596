import { officeConstants } from '../constants/office';

export function office(state = {}, action) {
  switch (action.type) {
    case officeConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case officeConstants.GETALL_SUCCESS:
      return {
        offices: action.offices
      };
    case officeConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

    case officeConstants.GET_REQUEST:
      return {
        loading: true
      };
    case officeConstants.GET_SUCCESS:
      return {
        office: action.office
      };
    case officeConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    
    default:
      return state;
  }
}