import { settingsConstants } from '../constants/settings';
import { settingsService } from '../services/settings';
import Swal from 'sweetalert2';

export const settingsActions = {
    create,
    getAll,
    deleteOrg,
    getById,
    update
};

function create(settings,logo,cover) {

    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const ToastFailed = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });


    function failure(error) { return { type: settingsConstants.CREATE_FAILURE, error } }

    return async dispatch => {
        let isError = null;

        try {
            await settingsService.create(settings,logo,cover);
        } catch(error) {
            isError = error.toString();
            dispatch(failure(error.toString()));
            ToastFailed.fire({
                type: 'error',
                title: `<span style="color:#FFFFFF"> Something went wrong</span> `
            });

            return;
        }

        if(isError === null){
            await ToastSuccess.fire({
                type: 'success',
                title: '<span style="color:#FFFFFF">Organization has been created</span>'
            })
        }
    };
}

function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());

        settingsService.getAll(page, itemsPerPage)
            .then(
                settings => dispatch(success(settings)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: settingsConstants.GETALL_REQUEST } }
    function success(settings) { return { type: settingsConstants.GETALL_SUCCESS, settings } }
    function failure(error) { return { type: settingsConstants.GETALL_FAILURE, error } }
}


function deleteOrg(id){
    return dispatch => {
        dispatch(request(id))
        
        settingsService.deleteOrg(id)
            .then(
                setting => {
                    dispatch(success(setting.success))
                },
                error => dispatch(failure(error.toString()))
            )

    }
    function request(id) { return { type: settingsConstants.DELETE_REQUEST, id } }
    function success(setting) { return { type: settingsConstants.DELETE_SUCCESS, setting } }
    function failure(error) { return { type: settingsConstants.DELETE_FAILURE, error } }
}


function getById(id) {
    return dispatch => {
        dispatch(request());

        settingsService.getById(id)
            .then(
                setting => dispatch(success(setting)),
                error => dispatch(failure(error.toString())
            ));
    };

    function request() { return { type: settingsConstants.GETONE_REQUEST } }
    function success(setting) { return { type: settingsConstants.GETONE_SUCCESS, setting } }
    function failure(error) { return { type: settingsConstants.GETONE_FAILURE, error } }
}



function update(id,data,logo,cover) {

    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const ToastFailed = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    return dispatch => {
        dispatch(request());

        settingsService.update(id,data,logo,cover)
        .then(
            setting => {
                dispatch(success(setting));

                setTimeout(() => {
                    ToastSuccess.fire({
                        type: 'success',
                        title: '<span style="color:#FFFFFF">Organization has been updated! </span>'
                    })
                }, 100)
                
            },
            error => {
                dispatch(failure(error.toString()));
                ToastFailed.fire({
                    type: 'error',
                    title: `<span style="color:#FFFFFF"> Organization Update Failed</span> `
                });
            }
        );
    };
    function request(setting) { return { type: settingsConstants.UPDATE_REQUEST, setting } }
    function success(setting) { return { type: settingsConstants.UPDATE_SUCCESS, setting } }
    function failure(error) { return { type: settingsConstants.UPDATE_FAILURE, error } }
}