
import React, { useState, useEffect, createRef, } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import Modal from 'react-modal';

import warningSign from '../../_templates/timesheet-alert.png';
import redInfoDot from '../../_templates/info-red-dot.png';
import greenInfoDot from '../../_templates/info-green-dot.png';
import closeIcon from '../../_templates/x_square.png';
import { renderedTime } from './renderedTime'
import holiday from '../../holiday.js';


import './CalendarTimesheet.sass';
import './fullCalendarOverrideStyle.sass';
import './reactTooltipOverrideStyle.sass';


const CalendarTimesheet = (props) => {
	let timesheet = props.timesheet;
	let setMonth = props?.setMonth;
	let calendarTrigger = props?.isUpdate;
	let isCalendarUpdate = props?.isCalendarUpdate;
	let isCutOff = props?.isCutOff; // Toggle for cutOff
	const [IsFirstLoad, setIsFirstLoad] = useState(true);
	let [mainEvents, setEvents] = useState([]);
	const [hoverTitle, setHoverTitle] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [modalDetails, setModalDetails] = useState(null)
	const [payOff, setpayOff] = useState(false)
	const [DateCutOff, setDateCutOff] = useState(null)
	const calendarRef = createRef();
	




	/* 
	Readme
	 timesheet = user timesheet
	 rendertime = it requires timesheet or Empty Array and days count in a
	 month. if timesheet has data it will compute user timesheet details else return user timesheet empty
	 setMonth =  count of days or range of days in current timesheet

	 (Dynamic Control)


	 Props
		 calendarTrigger = prev/next/gotoDate of month datepicker
		 isCalendarUpdate = get exact date of month datepicker reference for gotoDate 
		 isCutOff = Toggle for cutOff

	 calendarTrigger = datepicker dynamic control to initiate in calendar view
		 next = next month
		 previous = previous month
		 GotoDate = custom date
	 isCalendarUpdate = it store datepicker Date
	*/



	useEffect(() => {
		if (isCutOff === true) {
			setpayOff(true)
			
		} else {
			if(IsFirstLoad === false){ // it only call when toggle the cutOff
				AsyncFunctionCalendarEvents(); // If timesheet return empty, it will return default empty event or warning events
				OverrideStartDateCalendar();
				setIsFirstLoad(false); // reset
			}
			setpayOff(false) 
		}
		return () => {
			clearPreviousState();
		}

	}, [isCutOff]); // eslint-disable-line 


	useEffect(() => {
		setIsFirstLoad(true);
		AsyncFunctionCalendarEvents();
		OverrideStartDateCalendar()
		return () => {
			clearPreviousState();
		}
	// }, [timesheet, DateCutOff]) // eslint-disable-line 
	}, [timesheet, DateCutOff]) // eslint-disable-line 





	useEffect(() => {
		calendarCallbackSwitch();
		return () => {
			clearPreviousState();
		}
	}, [isCalendarUpdate]) // eslint-disable-line 

	async function AsyncFunctionCalendarEvents()  {
		await calendarUserEventData()
		await CalendarOverrideStyle()
	}
	
	const clearPreviousState = () => {
		setEvents([]) // Clear Calendar Events
		CalendarResetOverride(); // Clear Previous Override
		if(DateCutOff !== null){
			setDateCutOff(null); // End date cutOff
		}
		
	}
	

	const OverrideStartDateCalendar = () => {

		// To Override default calendar start date in cutOff and it will reset when cutOff turn off
		let calendarApi = calendarRef.current.getApi();
		calendarApi.gotoDate(moment(setMonth[0]).format("YYYY-MM-DD"));
	}

	const CalendarResetOverride = () => {

		let daily = document.querySelectorAll('.fc-day')

		// Clear previous override when datepicker date changed using previous/next/Search date
		daily.forEach((data, i) => {
			let dayDate = data.getAttribute('data-date');
			let getLastDayofPrevMonth = moment(moment(moment(props.setMonth[0]).startOf('month')).subtract(1, 'days')).format("YYYY-MM-DD");
			let getFirstDayNextMonth = moment(moment(moment(props.setMonth[0]).endOf('month')).add(1, 'days')).format("YYYY-MM-DD");
			let MonthStartNo = moment(getLastDayofPrevMonth).format("DD");
			let ResetCurrentMonthStartNo = 1;
		
			let payday_filter_v1 = moment(moment(setMonth[0]).subtract(5, 'days')).format('YYYY-MM-DD');
			let payday_filter_v1_endDate =  moment(moment(setMonth[0]).add(4, 'days')).format('YYYY-MM-DD')
			let setMonthFilter = moment(setMonth[0]).format("YYYY-MM-DD");
			let setMonthFilter2 = moment(setMonth[setMonth.length -1]).format("YYYY-MM-DD");
			

			if( dayDate > moment(setMonthFilter2).format("YYYY-MM-DD")  || dayDate <= payday_filter_v1 || dayDate <= DateCutOff) {
				data.children[0].classList.remove("calendar-custom-payday-filter");
			}			

			if(dayDate < setMonthFilter || dayDate <= payday_filter_v1_endDate ){
				data.children[0].classList.remove("calendar-custom-payday-filter");
			}

			
			if (dayDate === getLastDayofPrevMonth) {
				data.children[0].childNodes[0].firstChild.innerHTML = MonthStartNo;
			}
			if (dayDate === getFirstDayNextMonth) {
				data.children[0].childNodes[0].firstChild.innerHTML = ResetCurrentMonthStartNo;
			}

		})
	}

	const CalendarOverrideStyle = () => {
		


		/*
			Get dynamically calendar day attribute and replace the value day number with custom value
			Ex:
			In full view of calendar, you can see previous day of the month and future day in next month,
			the last day of previous month and first day of next month will be change.

			Current view of the month

			[Previous Month name] [Day number of last day of previous month]
			Ex: Day number of last day of previous month "30" replace into "November 30"
			vice-versa on the first day of next month
		*/



		let daily = document.querySelectorAll('.fc-day')
	

		daily.forEach((data, i) => {

			// It will Find the last day of previous month and first day of next month
			// And replace it with new custom value

			let dayDate = data.getAttribute('data-date');
	
			let getLastDayofPrevMonth = moment(moment(moment(props.setMonth[0]).startOf('month')).subtract(1, 'days')).format("YYYY-MM-DD");
			let getFirstDayNextMonth = moment(moment(moment(props.setMonth[0]).endOf('month')).add(1, 'days')).format("YYYY-MM-DD");
			let monthStart = moment(getLastDayofPrevMonth).format("MMMM");
			let MonthStartNo = moment(getLastDayofPrevMonth).format("DD");
			let monthEnd = moment(getFirstDayNextMonth).format("MMMM");
			let MonthEndNo = moment(getFirstDayNextMonth).format("DD");
		

			// Replace end of day of previous month and start date of next month in calendar view
			/*
			Example
				Current month: September2020
				Previous month: August 2020
				next Month: October 2020

				August end of day display: 
					Default: 31
					Override: August 31

				October start of day display: 
					Default: 1
					Override: October 31
			*/
			
			if (dayDate === getLastDayofPrevMonth) {

				let isSlice1 = null;
				data.children[0].childNodes[0].firstChild.innerHTML = '';
				if (MonthStartNo.charAt(0) === "0") {
					// Convert 01 to 1 
					isSlice1 = MonthStartNo.slice(1, 2);
					data.children[0].childNodes[0].firstChild.innerHTML = monthStart + " " + isSlice1;
				} else {
					data.children[0].childNodes[0].firstChild.innerHTML = monthStart + " " + MonthStartNo;
				}
			}
			if (dayDate === getFirstDayNextMonth) {
				let isSlice = null;
				data.children[0].childNodes[0].firstChild.innerHTML = '';
				if (MonthEndNo.charAt(0) === "0") {
					// Convert 01 to 1 
					isSlice = MonthEndNo.slice(1, 2);
					data.children[0].childNodes[0].firstChild.innerHTML = monthEnd + " " + isSlice;
				} else {
					data.children[0].childNodes[0].firstChild.innerHTML = monthEnd + " " + MonthEndNo;
				}
			}



				
			/* cutOff range override
				It only highlight cutOff range date
				else disabled or grey out
			*/

			let setMonthFilter = moment(setMonth[0]).format("YYYY-MM-DD");
			let setMonthFilter2 = moment(setMonth[setMonth.length -1]).format("YYYY-MM-DD");
			if(payOff === true){
				if( DateCutOff === null) {
					if( dayDate >= moment(setMonthFilter2).format("YYYY-MM-DD") ) {
						data.children[0].classList.add("calendar-custom-payday-filter");
					}
				} 
				else {
					if( dayDate === moment(DateCutOff).format("YYYY-MM-DD") ) {
						data.children[0].classList.remove("calendar-custom-payday-filter");
					}
				}
		
			
				if(dayDate < setMonthFilter){
					data.children[0].classList.add("calendar-custom-payday-filter");
				}
			}
		})

	}


	const calendarCallbackSwitch = () => {
		let calendarApi = calendarRef.current.getApi()
		
		// Check current Range in calendar data
		// console.log(calendarApi)
		// console.log(calendarApi.view.calendar.currentDataManager.state.dateProfile );
		// console.log(calendarApi.view.calendar.currentDataManager.state.dateProfile.renderRange );


		if (
			moment(isCalendarUpdate).isBefore(moment().format('YYYY-MM-DD'))
			||
			moment(isCalendarUpdate).isAfter(moment().format('YYYY-MM-DD'))
		) {

			/*
				Dynamic next/prev calendar view on fullCalendar.

				1. It will Check the current Date if changes are Before/After of Current Date
				2. If Before/After it will check if the calendarTrigger prev or next
				3. calendar view will changes depends on what calendarTrigger changes
			*/

		
			if(payOff === true) {
			

				if (calendarTrigger === 'prev') {
					calendarApi.gotoDate(moment(moment(setMonth[0])).toDate())
				}
				if (calendarTrigger === 'next') {
					calendarApi.gotoDate(moment(moment(setMonth[0])).toDate())
				}

				if (calendarTrigger === null) {
					
					calendarApi.gotoDate(moment(moment(setMonth[0])).toDate())
				}

			} else {
				if (calendarTrigger === 'prev') {
					calendarApi.prev()
				}
				if (calendarTrigger === 'next') {
					calendarApi.next()
				}

				if (calendarTrigger === null) {
					calendarApi.gotoDate(moment().toDate())
				}
			}
			
		}


		
	

		// Default Value
		// Datepicker if search date.
		if (calendarTrigger === "gotoDate") {
			calendarApi.gotoDate(moment(setMonth[0]).format("YYYY-MM-DD"))
		}

	}

	const RenderCutOffEvent = () => {
	
		// Policy
		let policy_cutOff = 14 // 14 	 policy
		let startingRange_payDay = policy_cutOff / 2; // 7 days before/after of current date of payDay


		// Payday Range
		let Payday_v1 = moment(setMonth[setMonth.length -1]).format("YYYY-MM-DD");
		let payday_v1_nearest = null;
		let startPoint_v1 = moment(moment(Payday_v1).subtract(startingRange_payDay, 'days')).format("YYYY-MM-DD");
		let dateRaw_data_v1 = [];


 
	
		for (let index = 0; index < policy_cutOff ; index++) {
			// Validate the date if business day without holiday/weekend

			// PayDay 1
			let weekendDay_v1 = moment(moment(startPoint_v1).add(index, 'days')).day(); // Number of day  Example: Saturday = 0 and Sunday = 6
			let weekendDate_v1 = moment(moment(startPoint_v1).add(index, 'days')).format("YYYY-MM-DD");
			let notWeeked_v1 = weekendDay_v1 !== 0 && weekendDay_v1 !== 6;
			let isHolidayValid_v1 = holiday.items.find(isHolidayValid =>
				moment(isHolidayValid.date).format("YYYY-MM-DD") === moment(moment(startPoint_v1).add(index, 'days')).format("YYYY-MM-DD")
			);
			!!isHolidayValid_v1 === false && notWeeked_v1 && dateRaw_data_v1.push(weekendDate_v1);

		
		}
	
		// get nearest Date.

		// Payday 1
		const closestDate_v1 = dates => dates.reduce((x, date) => {
			const distance = Math.abs(moment(Payday_v1).toDate() - new Date(date))
			if (!x.distance || distance < x.distance) return { distance, date }
			return x
		}, {}).date;


		payday_v1_nearest = closestDate_v1(dateRaw_data_v1)


		let cutOffEventValue = []; // store CutOffEvent

		
			// Override Calendar view style.
			if(payOff === true){
			
				let daily = document.querySelectorAll('.fc-day')
		

				daily.forEach((data, i) => {
					let dayDate = data.getAttribute('data-date');
					
					
					if( dayDate > moment(payday_v1_nearest).format("YYYY-MM-DD") ) {
						data.children[0].classList.add("calendar-custom-payday-filter");
					}
					if(dayDate ===  moment(payday_v1_nearest).format("YYYY-MM-DD")) {
						data.children[0].classList.remove("calendar-custom-payday-filter");
					}
				})
			}
		

		if(payOff === true){
			setDateCutOff(payday_v1_nearest)	
			cutOffEventValue.push({
				id: 'Payday',
				title: 'Payday',
				extendedProps: {
					holidayTitle: "Payday",
					extendedDate: moment(payday_v1_nearest).format('dddd MMMM D, YYYY'),
					date: moment(payday_v1_nearest).format('dddd MMMM D, YYYY'),
					note: "Payday",
					pragIn: '',
					pragOut: '',
					totalHours: '',
					overtime:  '',
				},
				description: '',
				className: 'fullcalendar-day-payday-custom',
				textColor: 'white',
				date: payday_v1_nearest,
			
			})
			return cutOffEventValue;
		}
	
	}


	const calendarUserEventData = () => {
		let retVal = [];
		let dateList = [];
		let dateListPayDay = [];
		let UserEventsValue = [];
		let getCurrentMonth = props.setMonth[0];
		let days = '';
		let startOf = '';
		let UniversalEventDetails = [];
		let daysCountPayday = '';


		// Set number of days in current Month
		days = moment(getCurrentMonth).daysInMonth();
		// daysCountPayday = moment(moment(getCurrentMonth).add(1, "months")).daysInMonth() + days;
		daysCountPayday = moment(moment(setMonth[setMonth.length -1 ]).add(1, 'days') ).diff(setMonth[0], 'days');
		// daysCountPayday = 30;
		startOf = moment(getCurrentMonth).startOf('month');

		let  startOfPayDay = moment(setMonth[0]).format("YYYY-MM-DD");

		for (let index = 0; index < days; index++) {
			dateList.push(moment(startOf).add(index, 'days').format('LL'))
		}
		for (let index = 0; index < daysCountPayday; index++) {
			dateListPayDay.push(moment(startOfPayDay).add(index, 'days').format('LL'))
		}


		// if timesheet return undefined instead an array it will return empty array to prevent passing undefined to retval that will cause error.
		// It only attach if timesheet return invalid format of data or intermittent on fetching data. Note: just refresh the page to fix intermittent problem
		let isUserTimesheetValid = Array.isArray(timesheet) ? timesheet : [];
		!isUserTimesheetValid && console.log("Unable to fetch user timesheet. Kindly refresh the page")

		if(payOff === false){
			retVal = renderedTime(isUserTimesheetValid, dateList) // store timesheet and days count and fetch computed time

		} else {
			retVal = renderedTime(isUserTimesheetValid, dateListPayDay)
			
			// store timesheet and days count and fetch computed time
		}
		
	
		if (retVal.length) {
	
			// User totalhours events
			retVal.forEach((data, i) => {
				let currentDate = payOff === false ? true :  moment(data.date).format("YYYY-MM-DD");
				let validRange = moment(moment(data.date).format("YYYY-MM-DD")).isBefore(moment(moment().add(1, "days")).format("YYYY-MM-DD"), 'days');
				let cutOff_condition2 = payOff === false ? true :  moment(moment(moment(setMonth[0]).add(1, 'months').add(4, 'days'))).format('YYYY-MM-DD');
				let isBefore = moment(moment(data.date).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD"), 'days') // if yesterday date has no pragIn/pragOut

				/* 
				Payday true
					currentDate = true
					cutOff_condition2 = true
					validRange = greater than

				Payday false
					currentDate = holiday date event
					cutOff_condition2 = true
					validRange = greater than
				*/
				
				if (currentDate <= cutOff_condition2 && validRange) {
			
					const weekday = moment(moment(data.date).format("YYYY-MM-DD")).format('dddd'); // Monday ... Sunday
					const isWeekend = weekday === 'Sunday' || weekday === 'Saturday';
					
					// Pragger Info

					if (!data.pragIn && moment(data.date).isSame(moment(), "day")) {
						//If data.date is same of date today and pragIn is empty.
						let isHoliday = holiday.items.find(isHolidayValid => moment(isHolidayValid.date).format("YYYY-MM-DD") === moment(data.date).format("YYYY-MM-DD"))
					
						if (isHoliday === undefined) {
							!isWeekend &&
								UserEventsValue.push({
									id: i,
									title: '',
									extendedProps: {
										extendedDate: moment(data.date).format('dddd MMMM D, YYYY'),
										date: moment(data.date).format("YYYY-MM-DD"),
										icon_warning: warningSign,
										note: 'Today',
										pragIn: data.pragIn === '' ? '' : moment(data.pragIn).format("hh:mm A"),
										pragOut: data.pragOut === '' ? '' : moment(data.pragOut).format("hh:mm A"),
										totalHours: data.totalHours === '' || data.totalHours === undefined ? '' : data.totalHours,
										overtime: data.overtime === '' ? '' : data.overtime.replace(/-/g, ""),
									},
									className: 'fullcalendar-day-custom greenWarning',
									textColor: 'black',
									date: moment(data.date).format("YYYY-MM-DD"),
								})

						}
						
						!isWeekend && UniversalEventDetails.push({
							extendedDate: moment(data.date).format('dddd MMMM D, YYYY'),
							pragIn: data.pragIn === '' ? '' : moment(data.pragIn).format("hh:mm A"),
							pragOut: data.pragOut === '' ? '' : moment(data.pragOut).format("hh:mm A"),
							totalHours: data.totalHours === '' || data.totalHours === undefined ? '' : data.totalHours,
							overtime: data.overtime === '' ? '' : data.overtime.replace(/-/g, ""),
						})

					}
					if ((data.totalHours === '' && isBefore) || (data.pragOut === '' && isBefore)) {
						let isHoliday = holiday.items.find(isHolidayValid => moment(isHolidayValid.date).format("YYYY-MM-DD") === moment(data.date).format("YYYY-MM-DD"))
					
						if (isHoliday === undefined) {
							!isWeekend &&
								UserEventsValue.push({
									id: i,
									title: '',
									extendedProps: {
										extendedDate: moment(data.date).format('dddd MMMM D, YYYY'),
										date: moment(data.date).format("YYYY-MM-DD"),
										icon_warning: warningSign,
										note: 'No pragin/pragout',
										pragIn: data.pragIn === '' ? '' : moment(data.pragIn).format("hh:mm A"),
										pragOut: data.pragOut === '' ? '' : moment(data.pragOut).format("hh:mm A"),
										totalHours: data.totalHours === '' || data.totalHours === undefined ? '' : data.totalHours,
										overtime: data.overtime === '' ? '' : data.overtime.replace(/-/g, ""),
									},
									className: 'fullcalendar-day-custom',
									textColor: 'black',
									date: moment(data.date).format("YYYY-MM-DD"),
								})

						}
						
						!isWeekend && UniversalEventDetails.push({
							extendedDate: moment(data.date).format('dddd MMMM D, YYYY'),
							pragIn: data.pragIn === '' ? '' : moment(data.pragIn).format("hh:mm A"),
							pragOut: data.pragOut === '' ? '' : moment(data.pragOut).format("hh:mm A"),
							totalHours: data.totalHours === '' || data.totalHours === undefined ? '' : data.totalHours,
							overtime: data.overtime === '' ? '' : data.overtime.replace(/-/g, ""),
						})

					}
					if (data.totalHours !== '' || data.overtime !== '') {
						let isValid = parseInt(data.totalHours.substring(0, 2)) >= 9; // "Overtime greater than 9 hrs/min (Green Icon) and undertime Less than 9 hrs/min (Red Icon)"

						UniversalEventDetails.push({
							extendedDate: moment(data.date).format('dddd MMMM D, YYYY'),
							pragIn: moment(data.pragIn).format("hh:mm A"),
							pragOut: moment(data.pragOut).format("hh:mm A"),
							totalHours: data.totalHours,
							overtime: data.overtime === '' ? '' : data.overtime.replace(/-/g, ""),
						})

						UserEventsValue.push({
							id: i,
							title: data.totalHours,
							extendedProps: {
								extendedDate: moment(data.date).format('dddd MMMM D, YYYY'),
								icon: isValid ? greenInfoDot : redInfoDot,
								note: "Total time: " + data.totalHours,
								pragIn: data.pragIn === '' ? '' : moment(data.pragIn).format("hh:mm A"),
								pragOut: data.pragOut === '' ? '' : moment(data.pragOut).format("hh:mm A"),
								totalHours: data.totalHours,
								overtime: data.overtime === '' ? '' : data.overtime.replace(/-/g, ""),
							},
							className: 'fullcalendar-day-custom',
							textColor: 'black',
							date: moment(data.date).format("YYYY-MM-DD")
						})
					}

					if (data.pragIn && !data.pragOut) {
						// "If user has a pragIn it will show Prag In Time"

						UniversalEventDetails.push({
							extendedDate: moment(data.date).format('dddd MMMM D, YYYY'),
							pragIn: moment(data.pragIn).format("hh:mm A"),
							pragOut: moment(data.pragOut).format("hh:mm A"),
							totalHours: data.totalHours,
							overtime: data.overtime === '' ? '' : data.overtime.replace(/-/g, ""),
						})

						UserEventsValue.push({
							id: i,
							title: "Time In: " + moment(data.pragIn).format("hh:mm A"),
							extendedProps: {
								extendedDate: moment(data.date).format('dddd MMMM D, YYYY'),
								icon: greenInfoDot,
								note: "Today",
								pragIn: data.pragIn === '' ? '' : moment(data.pragIn).format("hh:mm A"),
								pragOut: data.pragOut === '' ? '' : moment(data.pragOut).format("hh:mm A"),
								totalHours: data.totalHours,
								overtime: data.overtime === '' ? '' : data.overtime.replace(/-/g, ""),
							},
							className: 'fullcalendar-day-custom dateToday',
							textColor: 'black',
							date: moment(data.date).format("YYYY-MM-DD")
						})
					}


				}
			

			
			}) // End loop pragger info user event

		
			// Holiday events
			let holidayEvents = []
			holiday.items.forEach((holiday) => {
			
				if(payOff === true){
					let isBeforeOrIsSame = moment(moment(holiday.date).format("YYYY-MM-DD")).isAfter(moment(setMonth[0]).format("YYYY-MM-DD"), 'days') || moment(moment(holiday.date).format("YYYY-MM-DD")).isSame(moment(setMonth[0]).format("YYYY-MM-DD"), 'days');
					let isAfterOrIsSame = moment(moment(holiday.date).format("YYYY-MM-DD")).isBefore(moment(setMonth[setMonth.length - 1]).format("YYYY-MM-DD"), 'days') || moment(moment(holiday.date).format("YYYY-MM-DD")).isSame(moment(setMonth[setMonth.length - 1]).format("YYYY-MM-DD"), 'days');
					if(isBeforeOrIsSame && isAfterOrIsSame){
					
					
						
							UserEventsValue.push({
								id: 'holiday',
								title: holiday.name,
		
								extendedProps: {
									holidayTitle: holiday.name,
									extendedDate: moment(holiday.date).format('dddd MMMM D, YYYY'),
									date: moment(holiday.date).format("YYYY-MM-DD"),
									note: holiday.name,
									pragIn: '',
									pragOut: '',
									totalHours: '',
									overtime:  '',
								},
								className: 'fullcalendar-day-holiday-custom',
								textColor: 'white',
								date: moment(holiday.date).format("YYYY-MM-DD")
		
							})
						

					}
				
				
				} else {
					if (moment(moment(getCurrentMonth).format("YYYY-MM-DD")).isSame(moment(holiday.date).format("YYYY-MM-DD"), 'month') ) {
					
						
							UserEventsValue.push({
								id: 'holiday',
								title: holiday.name,
		
								extendedProps: {
									holidayTitle: holiday.name,
									extendedDate: moment(holiday.date).format('dddd MMMM D, YYYY'),
									date: moment(holiday.date).format("YYYY-MM-DD"),
									note: holiday.name,
									pragIn: '',
									pragOut: '',
									totalHours: '',
									overtime:  '',
								},
								className: 'fullcalendar-day-holiday-custom',
								textColor: 'white',
								date: moment(holiday.date).format("YYYY-MM-DD")
		
							})
						
						
					}
						
			
				}
				
				return null; // remove warning
			})

			// UserEventsValue = [...UserEventsValue, ...holidayEvents]
			if (payOff === true) {
				UserEventsValue = [...UserEventsValue, ...holidayEvents, ...RenderCutOffEvent(retVal, UniversalEventDetails)]
			} else {
				UserEventsValue = [...UserEventsValue, ...holidayEvents]
			}
		} // End retval 



		// Store Calendar events
		setEvents(UserEventsValue)
	
	
	}

	
	const renderEventContent = (eventInfo) => {
		return (
			<div style={{ width: "100%", cursor: "pointer" }}>
				{/* data-tip and data-for are dependency of React-Tooltip */}
				<b data-tip = "" data-for='title'>

					{eventInfo.event.extendedProps.icon && <img src={eventInfo.event.extendedProps.icon} height="5" width="5" alt="" />}
					{eventInfo.event.extendedProps.icon_warning && <img src={eventInfo.event.extendedProps.icon_warning} height="21" width="17" alt="" />}
						 &nbsp; {eventInfo.event.title}
				</b>

		
				<ReactTooltip
					id='title'
					place="bottom"
					type="light"
				>
				
					{hoverTitle}
				</ReactTooltip>
			</div>
		)
	}


	const mouseHoverOnChange = (eventInfo, trigger = null) => {
		let hoverData = eventInfo.event._def.title === ''
			? eventInfo.event._def.extendedProps.note : eventInfo.event._def.extendedProps.note;

		setHoverTitle(hoverData) // store hover value
	}

	const getModalWidth = () => {
		var modalWidth = {
			content: {
				maxWidth: "420px",
				maxHeight: modalDetails?.holidayTitle === undefined ? "480px" : "540px",
				paddingRight: "40px",
				paddingBottom: "20px",
				paddingTop: "20px",
				paddingLeft: "40px",
			}
		}
		return modalWidth;
	}


	const eventOnClick = (calEvent) => {
		let allEventsInCurrentRange = calEvent.event._context.options.events;
		let target = [];
		setShowModal(showModal === true ? false : true)
		

		allEventsInCurrentRange.forEach(data => {
			if(moment(calEvent.event._def.extendedProps.extendedDate).format("YYYY-MM-DD") === data.date) {
				target.push(data) ;
			}
			if((data.id === "Payday" || data.id === "holiday")  && moment(calEvent.event._def.extendedProps.date).format("YYYY-MM-DD") === data.date){
			
				if(data.id === "Payday"){
					target[0].extendedProps = {
						...target[0].extendedProps,
						['SpecialEvent_Payday']: data.id, // eslint-disable-line 
					}
				}
				if(data.id === "holiday"){
					target[0].extendedProps = {
						...target[0].extendedProps,
						['SpecialEvent_Holiday']: data.id, // eslint-disable-line 
						['HolidayName']: data.title, // eslint-disable-line 
					}
				}
			}
		})

		// Store modal details
		setModalDetails(target.length !== 0 && target[0].extendedProps);

	
	}

	const closeModal = (e) => {
		e.preventDefault();
		setShowModal(showModal === true ? false : true);
	}



	const setValidRange = () => {
		// Set valid range here
		// let range ={ 
		// 	start: moment(moment(setMonth[0])).format('YYYY-MM-DD'),
		// 	end:   moment(DateCutOff).add(1, 'days').format('YYYY-MM-DD')
		// }

		// let setDefaultView = null;
		// // let returnValue = payOff === false ? setDefaultView : range;
		// return returnValue;

		return null; // Need to set null to make a custom range in override style in date
	}


	const dateOnClick = (e) => {
		// alert('Clicked on: ' + e.dateStr);
	}


	return (
		<div className="timekeeping-calendar-container">


			<Modal
				isOpen={showModal}
				ariaHideApp={true}
				shouldCloseOnOverlayClick={true}
				onRequestClose={(e) => closeModal(e)}
				style={getModalWidth()}
			>

				<div className="modal-clock">
					<div className="modal-clock-details-header">
						<div className="modal-clock-details-title">
							Clock Details
							</div>


						<div className="modal-clock-details-close">
							<button className="close-modal" onClick={(e) => closeModal(e)}>
								<img src={closeIcon} alt="close" />
							</button>
						</div>


					</div>
					{/* cc(["switch-v1", isDaily === true ? "switch-active" : ''])} */}
					<div className="modal-clock-content">
					
						{ modalDetails && modalDetails?.SpecialEvent_Holiday !== undefined && modalDetails?.SpecialEvent_Holiday === "holiday" &&
							<div className="modal-clock-event modal-clock-bg-holiday">
								<div className="modal-clock-label-event"> {modalDetails?.HolidayName } </div>
							</div>
						}
						{ payOff === true && modalDetails?.SpecialEvent_Payday !== undefined && modalDetails?.SpecialEvent_Payday === 'Payday' &&
							<div className="modal-clock-event modal-clock-bg-payday">
								<div className="modal-clock-label-event"> {modalDetails?.SpecialEvent_Payday} </div>
							</div>
						}

						<div className="modal-clock-label"> Day </div>
						<div className="modal-clock-label-content"> {modalDetails?.extendedDate === undefined ? "-" : moment(modalDetails?.extendedDate).format('ddd MMMM D, YYYY')} </div>

						<div className="modal-clock-label"> Time In </div>
						{/* <div className="modal-clock-label-content"> { modalDetails?.pragIn === "" ? "-" : moment(modalDetails?.pragIn).format("hh:mm A") }</div> */}
						<div className="modal-clock-label-content"> {modalDetails?.pragIn === "" ? "-" : modalDetails?.pragIn}</div>

						<div className="modal-clock-label"> Time Out </div>
						{/* <div className="modal-clock-label-content"> { modalDetails?.pragOut === "" ? "-" : moment(modalDetails?.pragOut).format("hh:mm A") } </div> */}
						<div className="modal-clock-label-content"> {modalDetails?.pragOut === "" ? "-" : modalDetails?.pragOut} </div>

						<div className="modal-clock-label"> Total Hours </div>
						<div className="modal-clock-label-content" >
						{ modalDetails && modalDetails.totalHours === '' ? null : parseInt(modalDetails?.totalHours.substring(0, 2)) >= 9 ?
								<img src={greenInfoDot} height="5" width="5" alt="" style={{ marginRight: "5px" }} /> :
									modalDetails?.totalHours !== "" ? <img src={redInfoDot} height="5" width="5" alt="" style={{ marginRight: "5px" }} /> : ''} 

			
							{modalDetails?.totalHours === "" ? "-" : modalDetails?.totalHours}
						</div>

						<div className="modal-clock-label"> UT/OT </div>
						<div className="modal-clock-label-content"> {modalDetails?.overtime === "" ? "-" : modalDetails?.overtime} </div>

					</div>


					<div className="modal-footer">
						<button className="modal-clock-details-custom-btn" onClick={(e) => closeModal(e)} style={{ cursor: "pointer"}}> Close </button>
					</div>
				</div>

			</Modal>
				{isCutOff === false ?  <span className="calendar-month-date"> {moment(props.setMonth[0]).format("MMMM YYYY")} </span>
				:   <span className="calendar-month-date"> {moment(props.setMonth[0]).format("MMM DD, YYYY") + " - " + moment(props.setMonth[props.setMonth.length - 1]).format("MMM DD, YYYY")} </span>
				}
               
              
		
			<FullCalendar
				headerToolbar={{
					// Header default. I set empty to headers options to remove default header
					left: '',
					center: '',
					right: '',
				}}
				plugins={[dayGridPlugin, interactionPlugin]}
				ref={calendarRef}
				initialView="dayGridMonth"
				// initialDate={moment().subtract(1, 'month').format("YYYY-MM-DD") }
				initialDate={moment(setMonth[0]).format("YYYY-MM-DD") }
				views ={{
					dayGrid: {
						type: 'dayGrid',
						duration:   payOff === false ? { months:  1 } : { weeks: 4},
					}
				}}
				fixedWeekCount={false}

				// Minimum Events in a day. 
				// if exceed it will add see more and its clickable to show all events in current day
				dayMaxEvents={2}
				dayMaxEventRows={2}

				eventClick={(calEvent) => eventOnClick(calEvent)}
				eventMouseEnter={(eventInfo) => {
					mouseHoverOnChange(eventInfo, "enter")
				}}
				eventOrder="-title" // Sort order
				weekends={true} // visible weekend
				lazyFetching={true}

				events={mainEvents} // Events in Calendar view

				eventContent={renderEventContent}
				dateClick={(e) => {
					dateOnClick(e)
				}}
				// showNonCurrentDates={false}
				validRange={(e)=>  setValidRange(e) }
			
			/>

			<br></br>
		
		</div>
	)


}


export default React.memo(CalendarTimesheet);


