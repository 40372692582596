import { roleConstants } from '../constants/role';

export function roleAdd(state = {}, action) {
  switch (action.type) {
    case roleConstants.POSTROLE_REQUEST:
      return { roleAdd: true };
    case roleConstants.POSTROLE_SUCCESS:
      return {};
    case roleConstants.POSTROLE_FAILURE:
      return {};
    default:
      return state
  }
}