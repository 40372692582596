import { searchConstants } from '../constants/search';

const initialState = {
    loading: true,
    error: ""
};

export function search(state = initialState, action) {
    switch(action.type) {
        case searchConstants.SEARCH_REQUEST:
            return {
                loading: true
            };
        case searchConstants.SEARCH_SUCCESS:
            return {
                search: action.search
            };
        case searchConstants.SEARCH_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}