import { sessionConstants } from '../constants/session';

export function session(state = {}, action) {
    switch(action.type) {
        case sessionConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case sessionConstants.GETALL_SUCCESS:
            return {
                items: action.session
            };
        case sessionConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}