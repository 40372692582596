import { tenuresConstant } from '../constants/tenures';

let initialState = {
  loading: true,
  isError: null,
  tenuresList: [],
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function tenures(state = initialState, action) {
  switch (action.type) {
    case tenuresConstant.GET_ALL_TENURES:
      return {
        loading: true,
      };
    case tenuresConstant.GET_ALL_TENURES_SUCCESS:
      return {
        ...state,
        loading: false,
        tenuresList: action.tenures.success,
        totalPerPage: action.tenures.total,
        perPage: action.tenures.pages,
        currentPage: action.tenures.page,
      };
    case tenuresConstant.GET_ALL_TENURES_FAILED:
      return {
        loading: true,
        isError: action.error,
      };
    case tenuresConstant.POST_TENURE_REQUEST:
      return {
        loading: true,
      };
    case tenuresConstant.POST_TENURE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case tenuresConstant.POST_TENURE_REQUEST_FAILED:
      return {
        loading: true,
        isError: action.error,
      };
    default:
      return state;
  }
}