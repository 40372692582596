export const badgesConstants = {
  
  GETALL_REQUEST: 'BADGES_GETALL_REQUEST',
  GETALL_SUCCESS: 'BADGES_GETALL_SUCCESS',
  GETALL_FAILURE: 'BADGES_GETALL_FAILURE',

  GET_REQUEST: 'BADGES_GET_REQUEST',
  GET_SUCCESS: 'BADGES_GET_SUCCESS',
  GET_FAILURE: 'BADGES_GET_FAILURE',

  CREATE_REQUEST: 'BADGES_CREATE_REQUEST',
  CREATE_SUCCESS: 'BADGES_CREATE_SUCCESS',
  CREATE_FAILURE: 'BADGES_CREATE_FAILURE',

  SUBMIT_POST_REQUEST: 'POST_SUBMITPOST_REQUEST',
  SUBMIT_POST_SUCCESS: 'POST_SUBMITPOST_SUCCESS',
  SUBMIT_POST_FAILURE: 'POST_SUBMITPOST_FAILURE',

  BADGE_DEL_REQUEST: 'BADGE_REQUEST_DELETE',
  BADGE_DEL_SUCCESS: 'BADGE_SUCCESS_DELETE',
  BADGE_DEL_FAILURE: 'BADGE_FAILURE_DELETE',

  DELETE_REQUEST: 'BADGES_DELETE_REQUEST',
  DELETE_SUCCESS: 'BADGES_DELETE_SUCCESS',
  DELETE_FAILURE: 'BADGES_DELETE_FAILURE' ,

  UPDATE_BADGES_REQUEST: 'BADGES_UPDATE_REQUEST',
  UPDATE_BADGES_SUCCESS: 'BADGES_UPDATE_SUCCESS',
  UPDATE_BADGES_FAILURE: 'BADGES_UPDATE_FAILURE',

};

