import React from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect,useDispatch } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case, Default } from 'react-if';
import styled from "styled-components";
import LinesEllipsis from 'react-lines-ellipsis';
import { leaveTypeActions } from '../../../_core/actions/leaveType';
import { history } from "../../../_core/helpers/history";
import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent, DynamicContentHeaderWrapper, DynamicTableAndPaginationWrapper } from "../../Components/PageWrapper/DynamicWrapper";
import { DynamicTableAction } from "../../Components/tableActions/tableActions";
import Swal from 'sweetalert2';
import EditLeaveTypeDark from './EditLeaveTypeDark';
import LeaveType from './CreateLeaveTypePage';


const AdminAnnouncementPage = styled.div`
  table {
    >thead>tr>th {
       &:last-child {
        text-align: center;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: inherit;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const LeaveTypeList = (props) => {

  const dispatch = useDispatch();
  const [leaveTypes, setLeaveTypes] = React.useState([]);
  const [entriesValues, setentriesValues] = React.useState(); //showEntries

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;
  
  let tableHeader = (editPermission.includes("Leaves - Leave Types Overview") === true) || (deletePermission.includes("Leaves - Leave Types Overview") === true) || (roleAllTrue === 1) ? ["Name", "Category", "Description", "Accrue (hours)", "Actions"] :  ["Name", "Category", "Description", "Accrue (hours)", ""];

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));
  

  React.useEffect(()=>{
    setLeaveTypes(props.leaveTypes.items);
  },[props.leaveTypes])

    //showEntries
    const entriesValue = (entries) => {
     setentriesValues(entries);
    }

  const updateType = (item) => {
    let typeId = item._id;
    history.push(`/admin/leave-type/overview/${typeId}/edit`)

  }
  const deleteEvaluation = (item) => {

    let name = item.name
    let id = item._id;
      const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        background: '#D4EDDA',
        position: 'center',
        showConfirmButton: false,
        timer: 5000
    });
          
          Swal.fire({
              title: '<p class="swal-subtitle-dark">Are you sure?</p>',
              html: '<p class="swal-subtitle-dark">You wont be able to revert this!</p>',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#24DC98',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!',
              background: '#2E3549',
          }).then((result) => {
              if (result.value) {
            dispatch(leaveTypeActions.deleteLeaveType(id)); 
            setTimeout(() => {
              Toast.fire({
                type: 'success',
                title: '<span style="color:#0A551E">' + name + ' has been deleted.</span>',
              });
                dispatch(leaveTypeActions.getAll())
            }, 2000)
                      
              }
          })
  }

  const LeaveTypeData = () => {
    let data = leaveTypes ?? [];
    
    return (
      <>
        {data.length !== 0 ? (
          data.map((item, index) => (
            <tr key={index}>
                <td>{item.name}</td>
                <td>{item.type}</td>
                <td style={{width: '500px'}}>
                    <LinesEllipsis
                        text={item?.description}
                        maxLine='1'
                        ellipsis='...'
                        basedOn='words' />
                </td>
                <td>{item.accrue.toFixed(1)}</td>
                {(editPermission.includes("Leaves - Leave Types Overview") === true) || (deletePermission.includes("Leaves - Leave Types Overview") === true) || (roleAllTrue === 1) ?
                <td className={secretDetails !== null ? "disabled-link" : '' }>
                    <div>
                        <DynamicTableAction
                            originPage="LeaveTypes"
                            iconSet="2"
                            editLabel="Edit"
                            deleteLabel="Delete"
                            onClickEdit={() => updateType(item)}
                            onClickDelete={() => deleteEvaluation(item)}
                            deletePermission="Leaves - Leave Types Overview"
                            editPermission="Leaves - Leave Types Overview"
                        />
                    </div>
                </td> : false
                }
            </tr>
          ))
        ) : (
            <>
              <tr className="table-td-data">
                <td> 
                  <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                      <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                </td>
              </tr>
            </>
          )}
      </>
    );
  };

  return (
    <AdminAnnouncementPage>
      <DynamicContentHeaderWrapper
        buttonLabel="ADD LEAVE TYPES"
        btnPath="/admin/leave-type/overview/create"
        entriesValue={entriesValue}
        originPage="LeaveTypes"
        addPage="Leaves - Leave Types Overview"
      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={LeaveTypeData()}
        originPage="LeaveTypes"
        data={props.leaveTypes}
        location={props.location}
        paginationPath={"/admin/leave-type/overview"}
        entriesItem={entriesValues}
      />
    </AdminAnnouncementPage>
  );
};


const mapStateToProps = ({ announcement,leaveTypes }) => ({
  announcement,leaveTypes
});

const LeaveTypePage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Leave Types Overview", link: "/admin/leave-type/overview?page=1" },
        ]);
        break;
      case "create":
        setBreadCrumbs([
          { label: "Leave Types Overview", link: "/admin/leave-type/overview?page=1" },
          { label: "Create Leave Type", link: "/admin/leave-type/overview/create" },
        ]);
        break;
        case "edit":
          setBreadCrumbs([
            { label: "Leave Types Overview", link: "/admin/leave-type/overview?page=1" },
            { label: "Edit Leave Type",  link: "/admin/leave-type/overview/edit" },
          ]);
          break;
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Announcement | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Announcement | List of Announcement | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : ''
        }
      >
        <PageWrapper className={cc(["LeaveTypesPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Leaves"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['create'].includes(props.mode)}>
              <LeaveType {...props } />
            </Case>
            <Case condition={['edit'].includes(props.mode)}>
              <EditLeaveTypeDark {...props } />
            </Case>
            <Default>
              <ContentWrapper>
                <LeaveTypeList {...props} />
              </ContentWrapper>
            </Default>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});


LeaveTypePage.WrappedComponent.displayName = "LeaveTypePage";
const LeaveTypePageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <LeaveTypePage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <LeaveTypePage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <LeaveTypePage {...props} mode="edit" />}
    />
  </Switch>
);
export default React.memo(LeaveTypePageRoute);
