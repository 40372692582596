
import { get, post, put  } from '../helpers/api';
import { userService } from '../services/user';

export const overtimeService = {
    getAllById,
    getAll,
    getById,
    addOvertimeRequest,
    approve,
    hold,
    reject,
    update

};

function getAllById(selectPage) {
    var page = selectPage ? selectPage : "1";
    var user = JSON.parse(localStorage.getItem('userCredentials'));
    var id = user.user._id;
    return get(`/api/v1/user-request?page=${page}&items-per-page=10`, id).then(handleResponse);
}

function getAll(page, itemsPerPage) {
    return get(`/api/v1/request?page=${page}&items-per-page=${itemsPerPage}&filter=subtype:overtime`).then(handleResponse);
}

function getById(id) {
    return get(`/api/v1/request/${id}`).then(handleResponse);
}

function createActivity(activity,id) {
    return post(`/api/v1/user/${id}/activity`, JSON.stringify(activity)).then(handleResponse);
}

function addOvertimeRequest(overtime) {
    return post(`/api/v1/request`, overtime).then(handleResponse);
}


function approve(overtime) {
    let id = overtime._id;
    delete overtime._id;
    return put(`/api/v1/request/${id}/approve`, JSON.stringify(overtime))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });
}

function hold(overtime) {
    let id = overtime._id;
    delete overtime._id;
    return put(`/api/v1/request/${id}/hold`, JSON.stringify(overtime))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });
}

function reject(overtime) {
    let id = overtime._id;
    delete overtime._id;
    return put(`/api/v1/request/${id}/reject`, JSON.stringify(overtime))
        .then(handleResponse)
        .then(response => {
            getById(id).then(data => {
                data.success.map(users => {
                    const activity = {
                        "_id": users._id,
                        "user_id": users.user_id,
                        "title": users.subject,
                        "description": users.description,
                        "status": users.status,
                        "type": users.type,
                        "subtype": users.subtype,
                        "icon":  "",
                        "image": ""
                    }
                    return createActivity(activity,users.user_id);
                });
            })
        });
}

function update(overtime) {
    let id = overtime._id;
    delete overtime._id;
    return put(`/api/v1/request/${id}`, JSON.stringify(overtime))
        .then(handleResponse);
}

// function updateOvertime(overtime) {
//     let id = overtime._id;
//     delete overtime._id;
//     let admin = JSON.parse(localStorage.getItem('overtime'));
// 	admin.details = overtime;
//     return put(`/api/v1/request/${id}`, JSON.stringify(overtime))
//         .then(handleResponse)
//         .then(localStorage.setItem('overtime', JSON.stringify(admin)))
// } 

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}