import { achievementConstants } from '../constants/achievement';
import { achievementService } from '../services/achievement';
import { alertActions } from './alert';
//import { history } from '../helpers/history';
import Swal from 'sweetalert2'

export const achievementActions = {
    create,
    getAll,
    getOne,
    update,
    delete: _delete
};


function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());

        achievementService.getAll(page, itemsPerPage)
            .then(
                achievements => dispatch(success(achievements)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: achievementConstants.GETALL_REQUEST } }
    function success(achievements) { return { type: achievementConstants.GETALL_SUCCESS, achievements } }
    function failure(error) { return { type: achievementConstants.GETALL_FAILURE, error } }
}

function create(achievement) {

    const ToastSuccess = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });

    const ToastError = Swal.mixin({
        toast: true,
        width: 300,
        
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    return dispatch => {
        dispatch(request(achievement));

        achievementService.create(achievement)
            .then(
                achievement => { 
                    dispatch(success(achievement));
                    dispatch(alertActions.success('New achievement has been created'));
                    ToastSuccess.fire({
                        type: 'success',
                        title: '<span style="color:green">New achievement has been created! </span>'
                    })
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                    ToastError.fire({
                        type: 'error',
                        title: '<span style="color:#990000">Something went wrong! </span>'
                    })
                }
            );
    };

    function request(achievement) { return { type: achievementConstants.CREATE_REQUEST, achievement } }
    function success(achievement) { return { type: achievementConstants.CREATE_REQUEST, achievement } }
    function failure(error) { return { type: achievementConstants.CREATE_REQUEST, error } }
}

function getOne(id){
    return dispatch => {
        dispatch(request(id))
        
        achievementService.getOne(id)
            .then(
                achievement => {
                    dispatch(success(achievement.success))

                },
                error => dispatch(failure(error.toString()))
            )
    }

    function request(id) { return { type: achievementConstants.GETBYID_REQUEST, id } }
    function success(achievements) { return { type: achievementConstants.GETBYID_SUCCESS, achievements } }
    function failure(error) { return { type: achievementConstants.GETBYID_FAILURE, error } }
}

function update(id, achievement){
    // custom toast mixin for sweetAlert
    const ToastSuccess = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });

    const ToastError = Swal.mixin({
        toast: true,
        width: 300,
        
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    return dispatch => {
        dispatch(request(achievement))

        achievementService.update(id, achievement)
            .then(
                achievements => {
                    dispatch(success(achievements));
                    ToastSuccess.fire({
                        type: 'success',
                        title: '<span style="color:green">Achievement has been updated! </span>'
                    })
                },
                error => {
                    dispatch(failure(error.toString()));
                    ToastError.fire({
                        type: 'error',
                        title: '<span style="color:#990000">Achievement Update Failed! </span>'
                    })
                }
            )
    }

    function request(achievements) { return { type: achievementConstants.UPDATE_REQUEST, achievements } }
    function success(achievements) { return { type: achievementConstants.UPDATE_SUCCESS, achievements } }
    function failure(error) { return { type: achievementConstants.UPDATE_FAILURE, error } }

}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        achievementService.delete(id)
            .then(
                achievement => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: achievementConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: achievementConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: achievementConstants.DELETE_FAILURE, id, error } }
}