import { post,get,del } from '../helpers/api';
import { userService } from '../services/user';

export const smsService = {
    sendOTP,
    getOne,
    verifyOTP,
    deleteAll,
    sendMessage
};

function sendOTP(){
  return post(`/api/v1/sms/otp`).then(handleResponse);
}

function getOne(id) {
    return get(`/api/v1/sms/message/${id}`).then(handleResponse);
}

function verifyOTP(id) {
    return get(`/api/v1/sms/otp/${id}`).then(handleResponse);
}

function deleteAll(id) {
    return del(`/api/v1/sms/message/${id}`).then(handleResponse);
}// Delete all otp of login user

function sendMessage(sms) {
    return post('/api/v1/sms', JSON.stringify(sms)).then(handleResponse)
}
  

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              userService.logout();
              //location.reload(true);
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);

      }
      return data;
  });
}
