import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Modal from 'react-modal';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

import { hex } from "../_core/helpers/profile"
import { userActions } from "../_core/actions/user";
import { rewardActions } from "../_core/actions/reward";
import { statsService } from '../_core/services/stats';

import avatar from '../_templates/man.png';
import rewardAvatar from '../_templates/reward_avatar.png';
import imgNotFound from '../_templates/img-not-found.png';
import closeIcon from '../_templates/x_round.png';
import dot from '../_templates/dot.png';
import HeaderTabsSearch from "../Components/HeaderTabsSearch";

import './Rewards.scss';

const SkeletonPost = () => {
    const [transparent, setTransparent] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {
            setTransparent(false);
        }, 100);
    }, [setTransparent]);

    return (
        new Array(2).fill(null).map((_, index) => (
            <div
                className={cc({
                    'card people-user-card skeleton-post': true,
                    transparent
                })}
                key={index}
            >
                <SkeletonTheme color="#e0e0e0">
                    <Skeleton width="100%" height={130} />
                    <div className="people-info">
                        <div className="people-basic-info">
                            <div className="people-name">
                                <div>
                                    <Skeleton circle width={100} height={100} />
                                </div>
                                <div>
                                    <p style={{ paddingTop: "10px" }}><Skeleton height={15} width={200} /></p>
                                    <p style={{ paddingTop: "5px" }}><Skeleton height={15} width={150} /></p>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <Skeleton width="100%" height={50} />
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
        ))
    );
}


const RewardPage = (props) => {
    const dispatch = useDispatch();
    const [recentPage, setRecentPage] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [reward, setReward] = React.useState([]);
    const [showModal, setModal] = React.useState(false);
    const [isModalOpen, toggleModal] = React.useState(false);
    const [stats, setStats] = React.useState({});
    const [rewardUsers, setRewardUsers] = React.useState([]);
    const [isDisabled, secretDisabled] = React.useState(false);


    const user = JSON.parse(localStorage.getItem('userCredentials'));
    const secret = JSON.parse(localStorage.getItem("secretDetails"));

    React.useEffect(() => {
        dispatch(userActions.getAllActiveUsers(1, 999999));
        dispatch(rewardActions.getAllFilter(999999, 1));
        //dispatch(rewardActions.getOne(currentUserId,reward._id));
        secretDisabled(secret ? true : false)
    }, [dispatch]);// eslint-disable-line 

    const currentUserId = isDisabled ? secret.user_id: user && user.user._id;

    React.useEffect(() => {
        try {
            statsService.getOneUser(currentUserId).then(res => {
                setStats(res.success);
            }).catch(error => { });

        } catch (err) {
            console.log(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setStats, currentUserId]);

    const itemPerPage = 10;
    var hasNextPage = false;
    var rewardList = [];

    if (props.reward.items?.length > 0) {
        if (!recentPage) {
            rewardList = [...props.reward.items.slice(0, itemPerPage * page)];
            hasNextPage = rewardList.length >= itemPerPage * page;
        } else {
            rewardList = [...props.reward.items];
            hasNextPage = false;
        }
    }

    React.useEffect(() => {
        document.addEventListener('scroll', trackScrolling);
        return () => document.removeEventListener('scroll', trackScrolling);
    });

    const trackScrolling = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - 1 && hasNextPage && !recentPage) {
            setTimeout(() => {
                setPage(page => page + 1);
            }, 500);
        }
    }
    const [filter, setFilter] = React.useState('');

    // SEARCH FILTER
    const handleChange = e => {
        e.preventDefault();
        const searchVal = e.target?.value
        setFilter(searchVal);
        if (searchVal.length > 2) {
            if (!recentPage) {
                setRecentPage(page);
                document.removeEventListener('scroll', trackScrolling);
            }
        } else {
            if (recentPage) {
                setPage(recentPage);
                setRecentPage('');
                document.addEventListener('scroll', trackScrolling);
            }
        }
    }

    const lowercasedFilter = filter.toLowerCase();
    const filteredData = rewardList?.filter(item => {
        if (filter.length >= 3) {
            return Object.keys(item).some(key =>
                typeof item[key] === "string"
                && item[key].toLowerCase().includes(lowercasedFilter)
            );
        } else {
            return rewardList;
        }
    }) || [];

    function RewwardItem({ item }) {
        let id = item._id;
        var getExpDate = moment(item.expiresAt).isValid() ? item.expiresAt : '';

        var getTimer = setInterval(() => {
            if (getExpDate) {
                var now = moment().utc();
                var hours = parseInt(moment.duration(moment(getExpDate).diff(now)).asHours());
                var minutes = parseInt(moment.duration(moment(getExpDate).diff(now)).asMinutes()) % 60;
                var seconds = parseInt(moment.duration(moment(getExpDate).diff(now)).asSeconds()) % 60;
                var elem = document.getElementById('rq-timer-' + id);

                const countdown = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');

                if (typeof elem !== 'undefined' && elem !== null) {
                    elem.innerHTML = countdown;

                    if (moment(now).isSameOrAfter(moment(getExpDate))) {
                        clearInterval(this);
                        elem.innerHTML = "00:00:00";
                        elem.style.color = "#F14646";
                    }
                }
            } else {
                clearInterval(this);
            }
        }, 1000);
        return () => clearInterval(getTimer);
    }

    var now = moment().utc();


    const Yes = (item) => {
        const userCredits = Array.isArray(stats) && stats.map(name => name.credits);

        if (item.value <= userCredits[0]) {

            if (!item) {
                return;
            }

            let rewardId = {
                reward_id: item._id,
            }

            let userreward = {
                rewardValue: item.value
            }

            toggleModal(false)
            dispatch(rewardActions.postUserReward(currentUserId, rewardId, userreward));
            dispatch(rewardActions.redeemReward(rewardId));
        } else {
            Swal.fire({
                title: "Insufficient Credits",
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#ff0000',
                cancelButtonColor: '#d33',
                background: '#ffff',
            })
        }
    }


    return (
        <div>
            {/* Redeemed Modal */}
            <Modal
                isOpen={isModalOpen}
                ariaHideApp={true}
                toggle={toggleModal}
                onRequestClose={() => toggleModal(false)}
                shouldCloseOnOverlayClick={true}
                className="rewardModal"
            >

                <div className="reward-modal-container">
                    <div className="reward-container">
                        <div className="reward-header">
                            <div id="reward-close">
                            </div>
                            <img alt="" src={closeIcon} onClick={() => toggleModal(false)} />
                        </div>
                    </div>
                </div>
                <div className="reward-content-modal">
                    <span>Would you like to redeem again?</span>
                </div>
                <div class="row reward-btn-main">
                    <div class="col s6">
                        <button className="reward-btn-modal" style={{
                            backgroundColor: '#fff',
                            width: '150px',
                            minHeight: '49px',
                            border: '1px solid #BC0490',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                            onClick={() => toggleModal(false)}>

                            <p style={{ fontWeight: '700', color: '#CE069E', fontSize: '18px' }}>No Thanks</p>
                        </button>

                    </div>
                    <div class="col s6">
                        <button className="reward-btn-modal" style={{
                            backgroundColor: '#CE069E',
                            width: '150px',
                            minHeight: '49px',
                            border: '1px solid #BC0490',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                            onClick={() => { Yes(reward) }}>

                            <p style={{ fontWeight: '700', color: '#FFFFFF', fontSize: '18px' }}>Yes</p>
                        </button>
                    </div>
                </div>
            </Modal>

            {/* User Reward Modal */}
            <Modal
                isOpen={showModal}
                ariaHideApp={true}
                toggle={setModal}
                onRequestClose={() => setModal(false)}
                shouldCloseOnOverlayClick={true}
                className="rewardUsers"
            >
                <div className="reward-modal-container">
                    <div className="reward-container">

                        <div className="reward-users-header">
                            <div className="reward-users-title">
                                <p>Claimed Rewards</p>
                            </div>
                            <img alt="" src={closeIcon} onClick={() => setModal(false)} />
                        </div>
                    </div>
                </div>
                <div className="redeem-users-content-modal">
                    {
                        Array.from(new Set(rewardUsers)).map(rewardUser => (

                            props.users?.users && props.users.users.items.filter(user => user._id === rewardUser).map((user, index) => {
                                return (

                                    <div className="redeem_users">
                                        <img key={index} style={{ verticalAlign: 'middle' }} className="img-user" alt="" onError={(e) => { e.target.src = avatar }} src={user.details[0]?.avatar || avatar} />
                                        <Link to={`/profile/${user._id}`}>
                                            <span style={{ verticalAlign: 'middle', color: 'RGB(51, 51, 51)' }}>{user.details[0]?.firstName + " " + user.details[0]?.lastName}</span>
                                        </Link>
                                    </div>

                                );
                            })
                        ))
                    }
                </div>
            </Modal>
            <div className="reward-header">
                <HeaderTabsSearch
                    currentPath={window.location.pathname}
                    tabs={[
                        { name: "Rewards", path: "/rewards" }
                    ]}
                    searchValue={filter}
                    searchOnChange={handleChange}
                />
            </div>
            <div className="reward-content">
                {filteredData && filteredData.map(reward => (
                    <div key={reward._id} className="card reward-card">
                        <RewwardItem item={reward} />
                        <div className="reward-cover">
                            {reward.image
                                ? <img alt="" src={reward.image || imgNotFound} onError={(e) => { e.target.src = imgNotFound }} />
                                : <div style={{ backgroundColor: hex(reward.title + reward.title), height: '100%' }}></div>
                            }
                            <div className="topright"><img src={dot} alt="options" /></div>
                            <span className="rq-timer countdown" id={"rq-timer-" + reward._id}>{reward.expiresAt ? "00:00:00" : ''}</span>
                        </div>
                        <div className="reward-info">
                            <div className="reward-basic-info">
                                <div className="reward-name">
                                    <div>
                                        <img className="reward-avatar" alt="" src={rewardAvatar} />
                                    </div>
                                    <div style={{ paddingLeft: "10px" }}>
                                        <p>{reward.title || ""}</p>
                                        <p>{reward.content || ""}</p>
                                    </div>
                                </div>

                            </div>
                            {reward.stock === 0 ||  reward.stock < 0 ?
                                <button className="reedeemBtn" style={{
                                    backgroundColor: '#EEEEEE',
                                    width: '100%',
                                    minHeight: '49px',
                                    border: '1px solid #979797',
                                    borderRadius: '5px',
                                    pointerEvents: 'none'
                                }}
                                    disabled>

                                    <p style={{ fontWeight: '700', color: '#333333', fontSize: '18px' }}>CURRENTLY OUT OF STOCK</p>
                                </button>
                                
                                : moment(now).isSameOrAfter(moment(reward.expiresAt)) === true ?
                                    <button className="reedeemBtn" style={{
                                        backgroundColor: '#EEEEEE',
                                        width: '100%',
                                        minHeight: '49px',
                                        border: '1px solid #979797',
                                        borderRadius: '5px',
                                        pointerEvents: 'none'
                                    }}
                                    disabled>

                                    <p style={{ fontWeight: '700', color: '#333333', fontSize: '18px' }}>REWARD EXPIRED</p>
                                    </button>
                                : reward.users?.indexOf(currentUserId) !== -1 ?
                                    <button className="reedeemBtn" style={{
                                        backgroundColor: '##FFFFFF',
                                        width: '100%',
                                        minHeight: '49px',
                                        border: '1px solid #53DB68',
                                        borderRadius: '5px',
                                    }}
                                        onClick={(e) => { toggleModal(!isModalOpen); setReward(reward) }}>

                                        <p style={{ fontWeight: '700', color: '#49D465', fontSize: '18px' }}>REDEEMED</p>
                                    </button>

                                :isDisabled === true ?
                                    <button className="reedeemBtn" style={{
                                        backgroundColor: '#CE069E',
                                        width: '100%',
                                        minHeight: '49px',
                                        border: '1px solid #BC0490',
                                        borderRadius: '5px',
                                        pointerEvents: 'none',
                                        opacity: '0.7'
                                    }}
                                        disabled>

                                    <p style={{ fontWeight: '700', color: '#FFF', fontSize: '18px' }}>REDEEM</p>
                                    </button>                               
                                :
                                    <button className="reedeemBtn" style={{
                                        backgroundColor: '#CE069E',
                                        width: '100%',
                                        minHeight: '49px',
                                        border: '1px solid #BC0490',
                                        borderRadius: '5px'
                                    }}
                                        onClick={(e) => { Yes(reward) }}>

                                        <p style={{ fontWeight: '700', color: '#FFF', fontSize: '18px' }}>REDEEM</p>
                                    </button>

                            }

                            <div className="reedeem-info">
                                <div className="row">
                                    <div className="col s7 reward-points">{reward?.value?.toLocaleString()} Credit points</div>
                                    <div className="col s5">
                                        {/* {
                                            Array.from(new Set(reward.users)).length <= 1 ? 

                                            <div className=" redeem-users redeem-right" onClick= {(e) => { setModal(!showModal); setRewardUsers(reward.users)}} style={{cursor:'pointer'}}>
                                                {
                                                    reward.users.map(rewardUser => (
    
                                                        props.users?.users && props.users.users.items.filter(user => user._id === rewardUser).map((user,index) => {
                                                            return (
                                                                    <div className="redeem_avatar">
                                                                        <img key={index} className="img-participant"  id={user._id} alt="" onError={(e)=>{e.target.src = avatar}} src={user.details[0]?.avatar || avatar}/>
                                                                    </div>                              
                                                            );
                                                        })
                                                    ))
                                                }
                                            </div>
                                            :
                                            <div className=" redeem-users">
                                            
                                            </div>
                                        } */}

                                        <div key={reward._id} className={Array.from(new Set(reward.users)).length > 4 ? "redeem-users" : " redeem-users redeem-right"} onClick={(e) => { setModal(!showModal); setRewardUsers(reward.users) }} style={{ cursor: 'pointer' }}>
                                            {Array.from(new Set(reward.users)).length > 4 ?

                                                Array.from(new Set(reward.users)).slice(0, 4).map(rewardUser => (

                                                    props.users?.users && props.users.users.items?.filter(user => user._id === rewardUser).map((user, index) => {
                                                        return (
                                                            <div key={user._id} className="redeem_avatar">
                                                                <img key={index} className="img-participant" id={user._id} alt="" onError={(e) => { e.target.src = avatar }} src={user.details[0]?.avatar || avatar} />
                                                            </div>

                                                        );
                                                    })
                                                ))
                                                :
                                                Array.from(new Set(reward?.users)).map(rewardUser => (

                                                    props.users?.users && props.users.users.items?.filter(user => user._id === rewardUser).map((user, index) => {
                                                        return (
                                                            <div className="redeem_avatar">
                                                                <img key={index} className="img-participant" alt="" onError={(e) => { e.target.src = avatar }} src={user.details[0]?.avatar || avatar} />
                                                            </div>
                                                        );
                                                    })
                                                ))

                                            }

                                            <span>
                                                {Array.from(new Set(reward.users)).length > 4 && '+ '}
                                                {Array.from(new Set(reward.users)).length > 4 ? Array.from(new Set(reward.users)).length - 4 : ''}
                                                {Array.from(new Set(reward.users)).length > 4 && ' others'}
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))}
                {hasNextPage &&
                    <SkeletonPost />
                }

            </div>
        </div>
    )

};

const mapStateToProps = ({ teams, users, reward }) => ({
    loading: !!(teams.loading || users.loading || reward.loading),
    teams,
    users,
    reward
});

export default connect(mapStateToProps)(React.memo(RewardPage));