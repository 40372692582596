//npm packages
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
//images
import avatar from '../_templates/man.png'
import imgNotFound from '../_templates/img-not-found.png';

export const QuestCard = (props) => {
    const quest = props.quest;
    let users = props.users;

    React.useEffect(()=>{
        let expDate = quest.expiresAt;
        let id = quest._id;
        var getExpDate = moment(expDate).isValid() ? expDate : '';
        var getTimer = setInterval(() => {
            if (getExpDate) {
                var now = moment().utc();
                var hours = parseInt(moment.duration(moment(getExpDate).diff(now)).asHours());
                var minutes = parseInt(moment.duration(moment(getExpDate).diff(now)).asMinutes()) % 60;
                var seconds = parseInt(moment.duration(moment(getExpDate).diff(now)).asSeconds()) % 60;

                const countdown = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');
                document.getElementById('rq-timer-' + id).innerHTML = countdown;
                
                if (moment(now).isSameOrAfter(moment(getExpDate))) {
                    clearInterval(this);
                    document.getElementById('rq-timer-' + id).innerHTML = "00:00:00";
                    document.getElementById('rq-timer-' + id).style.color = "#333";
                }
            } else {
                clearInterval(this);
            }
        }, 1000);
        return () => clearInterval(getTimer);
    },[quest.expiresAt, quest._id]);

    const displayQuest = () => {
        let today = moment().utc();
        let expDateValid = moment(quest.expiresAt).isValid();
        let isExpired = moment(today).isSameOrAfter(moment(quest.expiresAt));

        const getCreator = () => {
            const creatorId = quest?.userId;
            var setCreator = [];
    
            creatorId && users?.length > 0 && users.filter((u)=> u.details[0].user_id === creatorId).map((u)=>{
                setCreator.push({
                    _id: creatorId,
                    name: [
                        u.details[0]?.firstName,
                        u.details[0]?.lastName,
                        ].join(' ').trim(),
                    avatar: u.details[0]?.avatar
                })
                return u
            });
            
            quest.creator = setCreator[0];
            return setCreator[0];
        };

        const questChallengeParticipants = () => {
            
            let participantsId = quest.participants;
            let numOfParticipants = quest.totalParticipants;
            let challengerLimit = 3;
            let otherParticipants = numOfParticipants > challengerLimit ? numOfParticipants - challengerLimit + ' others' : '';
            let setParticipants = users && users.filter(user => participantsId.includes(user.details[0].user_id));
            quest.qcParticipants = setParticipants;

            return (
                <div className="rq-participants">
                    {numOfParticipants > 0 &&
                    <div className="participants-imgs">
                        {setParticipants && setParticipants.slice(0, challengerLimit).map((challenger, index) =>
                            <img key={index} className="img-participant" alt="" onError={(e) => { e.target.src = avatar }} src={challenger.details[0]?.avatar || avatar} />
                        )}
                    </div>
                    }
                    <span>
                        {numOfParticipants <= 0 && 'No Challengers'}
                        {otherParticipants && '+ ' + otherParticipants}
                    </span>
                </div>
            );
        };

        return (
            <div className="rq-card card horizontal z-depth-0 col-s12" id={"phrQuest" + quest._id}>
                {quest.questImage && <img className="card-image" alt="" onError={(e) => { e.target.src = imgNotFound }} src={quest.questImage || imgNotFound} />}
                {quest.questImage && <img className="rq-creator-image" alt="" onError={(e) => { e.target.src = avatar }} src={getCreator()?.avatar || avatar} />}
                <div className="card-content">
                    <div className="rq-content-top">
                        <p className="rq-title">
                            {quest.questTitle || 'Quest Title'}
                            <span className="rq-timer" id={"rq-timer-" + quest._id}>{quest.expiresAt ? "00:00:00" : ''}</span>
                        </p>
                        <p className="rq-content">{quest.questContent}</p>
                    </div>
                    <div className="rq-content-bottom">
                        {questChallengeParticipants()}
                        <p style={{fontWeight:'700'}}>EXP : {quest.exp}</p>
                        {expDateValid ?
                        !isExpired ?
                        <Link to={`/engage/quest/challenges`} onClick={() => { localStorage.setItem('questChallenges', JSON.stringify(quest)) }} className="rq-ch-cred">
                            <p>{quest.questChallengeTotal || 0} {quest.questChallengeTotal > 1 ? 'Challenges' : 'Challenge'}</p>
                            <p>{quest.rewardValue || quest.credits} {quest.rewardValue < quest.credits ? 'Credits' : 'Credit'}</p>
                        </Link>
                        :
                        <div className="rq-ch-cred-exp">
                            <p>Quest Expired</p>
                        </div>
                        :
                        <Link to={`/engage/quest/challenges`} onClick={() => { localStorage.setItem('questChallenges', JSON.stringify(quest)) }} className="rq-ch-cred">
                            <p>{quest.questChallengeTotal || 0} {quest.questChallengeTotal > 1 ? 'Challenges' : 'Challenge'}</p>
                            <p>{quest.rewardValue || quest.credits} {quest.rewardValue < quest.credits ? 'Credits' : 'Credit'}</p>
                        </Link>
                        }
                        <small><i>Posted <b>{moment(quest.createdAt).fromNow(true)}</b> ago by <b>{getCreator()?.name || 'No name specified'}</b></i></small>
                    </div>
                </div>
            </div>
        );
    };

    return displayQuest();
};