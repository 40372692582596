import { feedsConstants } from '../constants/feeds';

export function userFeeds(state = {}, action){
    switch(action.type){
        case feedsConstants.GET_USERFEEDS_REQUEST:
            return {
                loading: true
            };
        case feedsConstants.GET_USERFEEDS_SUCCESS:
            return {
                items: action.userFeeds
            };
        case feedsConstants.GET_USERFEEDS_FAILURE:
            return { 
                error: action.error
            };

        default:
            return state
    }
}