import { teamConstants } from '../constants/teams';

const initialState = {
  items: [],
  error: '',
  loading: true,
  total: 0,
  page: 1,
  pages: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case teamConstants.GETALL_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        success: false // for add and edit team success
      };

    case teamConstants.GETALL_SUCCESS:
      return {
        ...state,
        ...action.teams,
        loading: false,
      };

    case teamConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case teamConstants.CREATE_TEAM_REQUEST:
      return {
        ...state,
        loading: false,
        error: '',
        success: false,
      }

    case teamConstants.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        success: true,
      }

    case teamConstants.CREATE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      }

    case teamConstants.UPDATE_TEAM_REQUEST:
      return {
        ...state,
        loading: false,
        error: '',
        success: false,
      }

    case teamConstants.UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        success: true,
      }

    case teamConstants.UPDATE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      }

      case teamConstants.GETBYID_REQUEST:
        return {
          ...state,
          teamByIdLoading: true,
          error: ''
        }
      case teamConstants.GETBYID_SUCCESS:
        return {
          teamByIdLoading: false,
          team: action.team 
        };
      case teamConstants.GETBYID_FAILURE:
        return { 
          teamByIdLoading: false,
          error: action.error 
        };

    default:
      return state;
  }
}