import React from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect, useDispatch } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import { If, Else, Then,  } from 'react-if';
import styled from "styled-components";
import emptyIcon from '../../../_templates/empty.png';

import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent,DynamicTableAndPaginationWrapper, DynamicSearchWrapper } from "../../Components/PageWrapper/DynamicWrapper";
import { DynamicTableAction } from "../../Components/tableActions/tableActions";

import CreateBadgePage from './CreateBadgePage';

import Swal from 'sweetalert2';

import { badgesActions } from '../../../_core/actions/badges';
import { history } from "../../../_core/helpers/history";


const AdminBadgestsPage = styled.div`
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4) {
        text-align: center;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: center;
        
        img {
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;


const BadgesPageList = (props) => {
  const dispatch = useDispatch(); //eslint-disable-line
  const [entriesValues, setentriesValues] = React.useState();
  const [badges, setBadges] = React.useState([]);
  const [search, setNewSearch] = React.useState("");
  const [keys, setKeys] = React.useState("");

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  let tableHeader = (editPermission.includes("Engagement - Badges") === true) || (deletePermission.includes("Engagement - Badges") === true) || (roleAllTrue === 1) ? ["Title", "Description", "Actions"] : ["Title", "Description", ""];
  let filterkeys = ["badgeName", "badgeContent"];

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

    //show entries
    const entriesValue = (entries) => {
        setentriesValues(entries);
    }

    //search
    const handleSearchChange = (item) => {
      setNewSearch(item);
  	};
    
    //filters
    const filterKeys = (item) => {
        setKeys(item);
    };

    const deleteBadge = (badgeId) => {
      const id = badgeId;
      const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        background: '#D4EDDA',
        position: 'center',
        showConfirmButton: false,
        timer: 5000
      });
      Swal.fire({
        title: "<p class='swal-title-engagement'>Confirmation</p>",
        html:
          " <div style='margin:30px'><p class='alert-first'>Do you want to delete this Badge?</p></div>",
    
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#556ee6",
          cancelButtonColor: "#2A3143",
          customClass: {
            header: 'header-class',
            container: 'container-class',
            popup: 'popup-class-engagament',
            actions: 'actions-class-engagament',
            cancelButton: 'cancel-button-class',
            confirmButton: 'confirm-btn-engagement',
            content: 'E-alert-conent',
          },
          reverseButtons:true,
          cancelButtonText: "LEAVE",
          confirmButtonText:"DELETE",
          background: "#2E3549",
      }).then((result) => {
          if (result.value) {
        dispatch(badgesActions.badgeDelete(id)); 
        setTimeout(() => {
          Toast.fire({
            type: 'success',
            title: '<span style="color:#0A551E"> Badge has been deleted.</span>',
          });
            dispatch(badgesActions.getAll(props.badges.currentPage, entriesValues))
        }, 100)
                  
          }
      })
    
    }

  React.useEffect(()=>{
    const timer = setTimeout(() => {
        setBadges(props.badges?.items?.items);
      }, 100);
      return () => clearTimeout(timer);
  },[props.badges])
  

  const updateBadge = (item) => {
    history.push(`/admin/badges/${item}/edit`)
  }

  // console.log(props.badges.items)

  const BadgesData = () => {
    let data = badges ?? [];
    
    return (
      <>
        <If condition={props.badges.loading === false }> 
            <Then>
                    {data.length !== 0 ?  (
                data.map((item, index) => (
                    <tr key={index}>
                    <td style={{ width: "30%" }}>{item.badgeName}</td>
                    <td>{item.badgeContent}</td>
                    {(editPermission.includes("Engagement - Badges") === true) || (deletePermission.includes("Engagement - Badges") === true) || (roleAllTrue === 1) ?
                    <td className={secretDetails !== null ? "disabled-link" : '' } style={{ width: "7%" }}>
                        <div>
                        <DynamicTableAction
                            originPage="Badges"
                            iconSet="2"
                            editLabel="Edit Badges"
                            deleteLabel="Delete Badges"
                            onClickDelete={ (e) => {deleteBadge(item._id);}}
                            // completeLabel={item.status === "finished" ? "Complete Evaluation" : ""}
                            onClickEdit={() => updateBadge(item._id)}
                            // onClickDelete={() => deleteEvaluation(item?.data[0]?._id, index)}
                            // onClickCompleted={() => updateEvaluationStatus("complete", item?.data[0]?._id, index)}
                            deletePermission="Engagement - Badges"
                            editPermission="Engagement - Badges"
                        />
                        </div>
                    </td> : false
                    }
                    </tr>
                ))
                ) :
                <tr>
                    <td colSpan="12" style={{textAlign:'center'}}>
                        <img src={emptyIcon} className="empty" alt="empty"/>
                        <p style={{fontSize:'16px'}}>No Data Available</p>
                        <p style={{fontSize:'14px'}}>There are currently no data available</p>
                    </td>
                </tr>
                }
            </Then>
            <Else>
              <tr className="table-td-data">
                <td> 
                  <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                      <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                </td>
              </tr>
            </Else>
        </If>
      </>
    );
  };

  return (
    <AdminBadgestsPage>

      {/* <DynamicContentHeaderWrapper
        buttonLabel="ADD BADGE"
        btnPath="/admin/badges/create"
        entriesValue={entriesValue}
        originPage="Badges"
      /> */}

    <DynamicSearchWrapper
        buttonLabel="ADD BADGE"
        btnPath="/admin/badges/create"
        entriesValue={entriesValue}
        data={props.badges.items}
        location={props.location}
        searchs={handleSearchChange}
        filters={filterKeys}
        paginationPath={"/admin/badges"}
        keys={filterkeys}
        originPage="Badges"
        addPage="Engagement - Badges"

      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={BadgesData()}
        originPage="Badges"
        data={props.badges.items}
        location={props.location}
        paginationPath={"/admin/badges"}
        entriesItem={entriesValues}
        loading = {props.badges.loading}
        search={search}
        filter={keys}
      />
    </AdminBadgestsPage>
  );
};


const mapStateToProps = ({quests, badges }) => ({
  quests,
  badges
});


const BadgesPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Badges", link: "/admin/badges?page=1" },
        ]);
        break;
      case "create":
        setBreadCrumbs([
          { label: "Badges", link: "/admin/badges?page=1" },
          { label: "Create Badge", link: "#" },
        ]);
        break;
        case "edit":
          setBreadCrumbs([
            { label: "Badges", link: "/admin/badges?page=1" },
            { label: "Edit Badge", link: "#" },
          ]);
          break;
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Badge | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Engagement | Badges | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : `Edit Badges | ${process.env.REACT_APP_DOCUMENT_TITLE}`
        }
      >
        <PageWrapper className={cc(["BadgesPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Engagement"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <BadgesPageList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={['create', 'edit'].includes(props.mode)}>
              <CreateBadgePage {...props } />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});

BadgesPage.WrappedComponent.displayName = "BadgesPage";
const BadgesPageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <BadgesPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <BadgesPage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <BadgesPage {...props} mode="edit" />}
    />
  </Switch>
);
export default React.memo(BadgesPageRoute);
