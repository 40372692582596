// sectionConstants



import { sectionConstants } from '../constants/section';

let initialState = {
    sectionList: [],
    loading: true,
    error: null,
}

export default function section(state = initialState, action) {
    switch(action.type) {
        case sectionConstants.GETALL_SECTION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case sectionConstants.GETALL_SECTION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                sectionList: action.section,
                error: null,
            };
        case sectionConstants.GETALL_SECTION_REQUEST_FAILED:
            return {
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}

