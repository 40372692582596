import React from "react";
import styled from "styled-components";

import close from '../../../_templates/icon-close.png';
import dropdown_chevrondown from '../../../_templates/dropdown_chevrondown.png';
import dropdown_chevronup from '../../../_templates/dropdown_chevronup.png';

const DropdownWrapper = styled.div`
  width: 100%;
  height: 100%;

  >label {
    position: absolute;
    margin-top: -8px;
    margin-left: 20px;
    overflow: visible!important;
    z-index: 1;

    color: #9BA4C7;
    font-size: 10px;
    font-weight: 700;
  }
`;

const SelectSectionWrapper = styled.div`
  width: 100%;
  height: 100%;

    >div {
        overflow: visible!important;

        &:nth-child(1) {
            height: auto;
            width: 100%;
            background-color: #2E3648;
            border: 1px solid #323A4F;
            border-radius: 3px;

            >input[type="text"] { // DROPDOWN INPUT
                height: 100%;
                width: calc(100% - 40px); //40px >>from padding
                border-bottom: none!important;
                padding: 10px 20px;
                background: url(${dropdown_chevrondown}) no-repeat 98%;
                background-size: 20px 15px;
                cursor: context-menu;

                color: #FFFFFF;
                font-size: 14px;
                font-weight: 400;

                &.isOpen {
                    background: url(${dropdown_chevronup}) no-repeat 98%;
                    background-size: 20px 15px;
                }
            }
        }

        &:nth-child(2) {
            position: absolute;
            width: 100%;
            max-width: 295px;
            height: auto;
            max-height 208px;
            right: 118px;
            z-index: 2;

            background-color: #2A3143;
            // border: 1px solid #2E3549;
            box-shadow: 0 5px 4px 5px rgba(0, 0, 0, 0.03);

            transition: height 5s;

            &.isClose {
                height: 0px;
                overflow: hidden!important;
            }
            
            >div {
                height: auto;
                width: 100%;
                margin: 10px 0;

                ::-webkit-scrollbar {
                    width: 3px;
                }
                    
                ::-webkit-scrollbar-track {
                    background: #3E4556;
                }
                
                ::-webkit-scrollbar-thumb {
                    background: #9BA2CE;
                    border-radius: 2.5px;
                }
    
                ::-webkit-scrollbar-thumb:hover {
                    background: #ccc;
                }
    
                >div.scroll::-webkit-scrollbar {
                    width: 0px;
                    height: 0px;
                    background-color: transparent;
                }

                &:nth-child(1){
                    max-height: 140px;
                    overflow-y: auto;

                    >div {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        height: 35px;
                        padding: 0 20px;
                        cursor: context-menu;
        
                        &:hover {
                            background-color: #2E3648;
    
                            >img {
                                display: inline!important;
                                cursor: pointer;
                                
                                &:hover {
                                    background-color: #293041;
                                }
                            }
                        }
        
                        >span {
                            color: #9BA3CA;
                            font-size: 14px;
                            font-weight: 500;
                            width: 100%;
                        }
    
                        >img {
                            display: none;
                        }
                    }
                }

                &:nth-child(2) {
                    width: 100%;
                    height: 100%;
                    margin: 0 10px;

                    >input[type="text"] { // ADD SECTION INPUT
                        height: 100%;
                        width: calc(100% - 40px); //40px >>from padding
                        border-bottom: none!important;
                        padding: 5px 10px;
                        background-color: #32394A;
        
                        color: #9BA3CA;
                        font-size: 14px;
                        font-weight: 700;

                        &::placeholder {
                            color: #9BA3CA;
                        }
                    }
                }        
            }

            @media (max-width: 993px){
                right: auto;
                width: calc(100vw - 75%);
            } 
        }
    }

`;
// TODO: fix display value when reordering questions
const SelectSection = (props) => {
    const sectionInputRef = React.useRef(null);
    const sectionItemRef = React.useRef(null);
    const sectionDeleteRef = React.useRef(null);
    const sectionAddRef = React.useRef(null);

    const [isDropdownOpen, setDropdownOpen] = React.useState(false);
    const [ deleteItem, setDeleteItem ] = React.useState(false);    

    const handleClickEvents = React.useCallback((event) => {
        let inputRef = sectionInputRef;
        let itemRef = sectionItemRef;
        let deleteRef = sectionDeleteRef;
        let addRef = sectionAddRef;

        let isClickedOutsideSectionInput = inputRef.current && !inputRef.current.contains(event.target);
        let isClickedAnItem = itemRef.current && itemRef.current.contains(event.target);
        let isClickedDeleteBtn = deleteRef.current && deleteRef.current.contains(event.target);
        let isAddingItem = addRef.current && addRef.current.contains(event.target);

        if (isClickedOutsideSectionInput) {
            if(isClickedAnItem){
                if (isClickedDeleteBtn) {
                    setDeleteItem(true);
                } else {
                    setTimeout(()=>{
                        setDropdownOpen(false);
                    },250);
                    setDeleteItem(false);
                }
            } else if(isAddingItem){
                //
            } else {
                setDropdownOpen(false);
            }
        }
    }, [sectionInputRef, sectionDeleteRef, sectionItemRef]);

    React.useEffect(() => {

        // Bind the event listener
        document.addEventListener("mousedown", handleClickEvents);
        return () => {
            document.removeEventListener("mousedown", handleClickEvents);
        };
    }, [sectionInputRef, sectionItemRef, props]); // eslint-disable-line

    const getItem = React.useCallback((id)=>{
        if(props?.options?.length > 0 && props?.value){
            const getCurrentValue = props.options.filter((item) => item.value === id)[0];

            return getCurrentValue;
        }
    },[props]);

    const selectedItem = React.useCallback((id) => {
        if(deleteItem) { //TODO: delete section
            console.log("delete");
        } else { //TODO: select section
            const getItem = props?.options?.filter((item)=> item.value === id)[0];
            props.sectionIdOnChange({
                section_id: getItem.value,
                question_id: props.questionId,
                index: props.index,
            });
        }
    },[deleteItem, props]);

    const label = getItem(props.value)?.value === props.value ? getItem(props.value)?.label : "" ;

    return (
        <SelectSectionWrapper>
            <div style={{ maxHeight: "40px" }} onClick={() => setDropdownOpen(!isDropdownOpen)}>{/*dropdown input*/}
                <input 
                    type="text" 
                    ref={sectionInputRef} 
                    value={ label }
                    id={"section" + props?.index}
                    className={isDropdownOpen ? "isOpen" : ""}
                    disabled 
                />
            </div>
            <div className={isDropdownOpen ? "" : "isClose"}>{/*dropdown menu*/}
                <div>{/*list wrapper*/}
                    {props?.options?.length > 0 && props?.options.map((section, index) =>
                        <div 
                            key={index} 
                            ref={sectionItemRef} 
                            id={"section" + index}
                            onClick={()=> selectedItem(section.value)}
                        >
                            <span >{section.label}</span>
                            <img src={close} alt="delete section" ref={sectionDeleteRef}/>
                        </div>
                    )}
                </div>
                <div>
                    <input type="text" placeholder="Add Section" ref={sectionAddRef} id={"delsec" + props?.index}/>
                </div>
            </div>
        </SelectSectionWrapper>
    );
}

//NOTE: add div wrapper on parent component to control width
export const QuestionSectionDropdown = (props) => {
    return (
        <DropdownWrapper>
            <label htmlFor={`sectionQ ${props.index}`}>Section</label>
            <SelectSection
                id={`sectionQ ${props.index}`}
                maxWidth={props.maxWidth}
                options={props?.sections || {}}
                value={props?.value || ""}
                index={props.index}
                sectionIdOnChange={(section) => props.sectionIdOnChange(section)}
                questionId={props.questionId}
            />
        </DropdownWrapper>
    );
};
