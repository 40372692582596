import React from "react";
import styled from "styled-components";
import dot from "../../../_templates/table-icons/action-dot-highlight.png";
// import edit_icon_text from "../../../_templates/table-icons/edit.png";
// import delete_icon_text from "../../../_templates/table-icons/delete.png";

import ReactTooltip from "react-tooltip";
import edit_icon from "../../../_templates/dark/pencil_edit.png";
import delete_icon from "../../../_templates/dark/delete.png";
import complete_icon from "../../../_templates/dark/completed.png";
import addChallenge_icon from "../../../_templates/dark/add_challenge.png";
import publishChallenge_icon from "../../../_templates/publish.png";
import unpublishChallenge_icon from "../../../_templates/unpublish.png";
import approved_challenge from "../../../_templates/approved.png";
import reject_challenge from "../../../_templates/reject.png";
import complete_challenge from "../../../_templates/complete.png";
// import viewChallenge_icon from "../../../_templates/dark/external_link.png";
import viewParticipants_icon from "../../../_templates/dark/group.png";

const ActionWrapperTwoIcon = styled.div`
  display: none;

  > img {
    margin: 0 5px;
  }
  > img:hover {
    cursor: pointer;
  }

  @media (max-width: 993px){
    display: block;
  } 
`;
// export const DynamicTableAction = (props) => {
//   return (
//     <div>
//       {props.originPage !== undefined && props.iconSet === "2" && (
//         <ActionWrapperTwoIcon>
//           <img
//             src={edit_icon}
//             alt=""
//             data-tip={props.editLabel}
//             place="top"
//             data-class="tooltips"
//             onClick={props.onClickEdit}
//           />
//           <img
//             src={delete_icon}
//             alt=""
//             data-tip={props.deleteLabel}
//             place="top"
//             data-class="tooltips"
//             onClick={props.onClickDelete}
//           />
//           <ReactTooltip />
//         </ActionWrapperTwoIcon>
//       )}
//     </div>
//   );
// };

const ActionWrapperDots = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  
  >img{
    cursor: pointer;
  }

  >div.dropownAction{
    position: absolute;
    width: 200px;
    height: auto;
    min-height: 97px;
    right: 82px;
    margin-top: -12px;
    overflow: visible!important;

    &.open {
      display: block;
    }

    &.close {
      display: none;
    }

    >div {
      width: 5px;
      height: 5px;
      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #566282;
      margin-left: auto;
      margin-right: 5px;
    }

    >ul {
      background-color: #2A3143;
      border-top: 2px solid #566282;
      width: 100%;
      height: 100%;
      box-shadow: 0 5px 4px 5px rgba(0, 0, 0, 0.03);
      padding: 10px 0;

      >li {
        width: 100%;
        height: 37px;
        padding: 10px 10px;
        text-align: left;
        cursor: pointer;
        

        >img {
          margin-right: 15px;
          
          margin-top: -5px;
          vertical-align: middle;
          display: inline-block; 
        }

        >span {
          margin: auto 0;
          font-size: 14px;
          font-weight: 500;
          color: inherit;

          margin-top: -5px;
          vertical-align: middle;
          display: inline-block;
        }

        &:hover {
          background-color: #32394A;
        }
      }
    }
  }

  .filter-view{
    filter: invert(45%) sepia(17%) saturate(463%) hue-rotate(184deg) brightness(94%) contrast(89%);
  }
  
  @media (max-width: 993px){
    display: none;
  }
`;

export const DynamicTableAction = (props) => {
  const [isOpen, setOpen] = React.useState(false);
  const [defaultWidth, setDefaultWidth] = React.useState(0);
  const wrapperRef = React.useRef(null);

  const handleClickOutside = React.useCallback((event) => {
    let ref = wrapperRef;
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  }, [wrapperRef]);

  React.useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]); // eslint-disable-line

  const addPermission = JSON.parse(localStorage.getItem('addPermission'));
  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  React.useEffect(() => {
    setDefaultWidth(wrapperRef.current ? wrapperRef.current.offsetWidth : 0);
}, [wrapperRef.current, defaultWidth]);// eslint-disable-line


 
  return (
    <>
      <ActionWrapperDots className={props.originPage !== 'QuestChallenge' ? "dropdown" : "dropdown hide" } ref={wrapperRef} >
        <img src={dot} alt="actions" onClick={() => setOpen(() => !isOpen)} />
        <div className={isOpen ? "dropownAction open" : "dropownAction close"}>
          <div></div>
          <ul className="">
            {props.completeLabel && <li className="" onClick={props.onClickCompleted}><img src={complete_icon} alt="edit"/><span>Complete</span></li>}
            {props.publishLabel && <li className="" onClick={props.publishLabel}><img src={publishChallenge_icon} alt="edit"/><span>Publish</span></li>}
            {props.unpublishLabel && <li className="" onClick={props.unpublishLabel}><img src={unpublishChallenge_icon} alt="edit"/><span>Unpublish</span></li>}  
            {props.completeChallenge && <li className="" onClick={props.completeChallenge}><img src={complete_challenge} alt="edit"/><span>Complete</span></li>}
            {props.finishChallenge && <li className="" onClick={props.finishChallenge}><img src={complete_challenge} alt="edit"/><span>Finish</span></li>}   
            {props.acceptChallenge && <li className="" onClick={props.acceptChallenge}><img src={complete_challenge} alt="edit"/><span>Accept</span></li>}   
            {props.approveChallenge && <li className="" onClick={props.approveChallenge}><img src={approved_challenge} alt="edit"/><span>Approve</span></li>}  
            {props.rejectChallenge && <li className="" onClick={props.rejectChallenge}><img src={reject_challenge} alt="edit"/><span>Reject</span></li>}   

            {
               (editPermission?.includes(props.editPermission) && props.originPage === "LeaveTypes" === true) || 
               (editPermission?.includes(props.editPermission) && props.originPage === "Quest" === true) || 
               (editPermission?.includes(props.editPermission) && props.originPage === "Challenge" === true) || 
               (editPermission?.includes(props.editPermission) && props.originPage === "Badges" === true) || 
               (editPermission?.includes(props.editPermission) && props.originPage === "Reward" === true) || 
               (editPermission?.includes(props.editPermission) && props.originPage === "Evaluations" === true) || 
               (editPermission?.includes(props.editPermission) && props.originPage === "Tenures" === true) || 
               (editPermission?.includes(props.editPermission) && props.originPage === "Questionnaires" === true) ||
               (editPermission?.includes(props.editPermission) && props.originPage === "Settings" === true) ||
               (roleAllTrue === 1)  ||
               (props.editStatus !== false)
               ?
               <li className="" onClick={props.onClickEdit}><img src={edit_icon} alt="edit"/><span>Edit</span></li> : false
            }
            {
              (deletePermission?.includes(props.deletePermission) && props.originPage === "LeaveTypes" === true) || (deletePermission?.includes(props.deletePermission) && props.originPage === "Quest" === true) || (deletePermission?.includes(props.deletePermission) && props.originPage === "Challenge" === true) || (deletePermission?.includes(props.deletePermission) && props.originPage === "Badges" === true) || (deletePermission?.includes(props.deletePermission) && props.originPage === "Reward" === true) || (deletePermission?.includes(props.deletePermission) && props.originPage === "Evaluations" === true) || (deletePermission?.includes(props.deletePermission) && props.originPage === "Tenures" === true) || (deletePermission?.includes(props.deletePermission) && props.originPage === "Questionnaires" === true) || (roleAllTrue === 1) ? <li className="" onClick={props.onClickDelete}><img src={delete_icon} alt="delete" /><span>Delete</span></li> : false
            }
            {(props.originPage === "Quest" === true) ?  
             props.viewChallengeLabel && <li className="" onClick={props.onClickView}><img src={viewParticipants_icon} alt="edit" className="filter-view"/><span>{props.viewChallengeLabel} </span></li> : false
            }
            
            {/* <li className="" onClick={props.onClickDelete}><img src={delete_icon} alt="delete" /><span>Delete</span></li> */}
            {(addPermission?.includes(props.addPage) && props.originPage === "Quest" === true) || (roleAllTrue === 1) ?  
            props.addChallengeLabel && <li className="" onClick={props.onClickAddChallenge}><img src={addChallenge_icon} alt="edit"/><span>Add Challenge</span></li> : false}
          </ul>
        </div>
      </ActionWrapperDots>
      {props.originPage !== undefined 
        && props.iconSet === "2" 
        && props.editStatus === false
        && (
        <ActionWrapperTwoIcon>
          {props.completeLabel &&
          <img
            src={complete_icon}
            alt=""
            data-tip={props.completeLabel}
            place="top"
            data-class="tooltips"
            onClick={props.onClickAddChallenge}
          />
          }
          <img
            src={edit_icon}
            alt=""
            data-tip={props.editLabel}
            place="top"
            data-class="tooltips"
            onClick={props.onClickEdit}
          />
          <img
            src={delete_icon}
            alt=""
            data-tip={props.deleteLabel}
            place="top"
            data-class="tooltips"
            onClick={props.onClickDelete}
          />
          
        </ActionWrapperTwoIcon>
      )}

      {
        props.originPage === "QuestChallenge" === true
        && (
            <ActionWrapperDots className="dropdown" ref={wrapperRef}>
                
                <img src={dot} alt="actions" onClick={() => setOpen(() => !isOpen)} />

            <div className={isOpen ? "dropownAction open" : "dropownAction close"}>
              <div></div>
                <ul className="">
                    {props.viewChallengeParticipants && <li className="" onClick={props.onClickViewParticipants}><img src={viewParticipants_icon} alt="delete"/><span>{props.viewChallengeParticipants}</span></li>}
                    {props.deleteParticipants && <li className="" onClick={props.onDeleteParticipants}><img src={delete_icon} alt="delete"/><span>{props.deleteParticipants}</span></li>}
                    {props.oncompleteChallenge && <li className="" onClick={props.onCompleteChallenge}><img src={complete_challenge} alt="delete"/><span>{props.oncompleteChallenge}</span></li>}
                    {props.failedChallenge && <li className="" onClick={props.onFailedChallenge}><img src={delete_icon} alt="delete"/><span>{props.failedChallenge}</span></li>}
                </ul>
            </div>
            </ActionWrapperDots>
        )

      }
      <ReactTooltip place="bottom"/>
    </>
  );
};
