import { attributeConstants } from '../constants/attribute';
import { attributeService } from '../services/attribute';
import { alertActions } from './alert';
import { history } from '../helpers/history';
import Swal from 'sweetalert2';

export const attributeActions = {
    create,
    getAll,
    attributeDelete,
    attributeById,
    attributeUpdate,
    delete: _delete
};
 
function getAll(page, itemPerPage) {
    return dispatch => {
        dispatch(request());

        attributeService.getAll(page, itemPerPage)
            .then(
                attributes => {
                    dispatch(success(attributes))
                    if(window.location.pathname === '/admin/attributes'){
                        history.push('/admin/attributes?page=' + Number(page) || 1);
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: attributeConstants.GETALL_REQUEST } }
    function success(attributes) { return { type: attributeConstants.GETALL_SUCCESS, attributes } }
    function failure(error) { return { type: attributeConstants.GETALL_FAILURE, error } }
}

function create(attribute) {
    // custom toast mixin for sweetAlert
    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        background: '#D4EDDA',
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });
    const Error = Swal.mixin({
        toast: true,
        width: 300,
        background: '#ff9999',
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });
    return dispatch => {
        dispatch(request(attribute));

        attributeService.create(attribute)
            .then(
                attribute => { 
                    dispatch(success());
                    history.goBack();
                    dispatch(alertActions.success('New attribute has been created'));
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#0A551E">Attribute Successfully Added!</span>'
                    })
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    Error.fire({
                        type: 'error',
                        title: '<span style="color:#990000">Something went wrong</span>'
                    })
                }
            );
    };

    function request(attribute) { return { type: attributeConstants.CREATE_REQUEST, attribute } }
    function success(attribute) { return { type: attributeConstants.CREATE_REQUEST, attribute } }
    function failure(error) { return { type: attributeConstants.CREATE_REQUEST, error } }
}

function attributeDelete(id){
    // console.log(id)
    return dispatch => {
        dispatch(request(id));
        attributeService.attributeDelete(id)
            .then(
                attribute => dispatch(success(id)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: attributeConstants.ATTRIBUTE_DEL_REQUEST, id } }
    function success(id) { return { type: attributeConstants.ATTRIBUTE_DEL_SUCCESS, id } }
    function failure(error) { return { type: attributeConstants.ATTRIBUTE_DEL_FAILURE, error } }
}

function attributeById(id) {
    return dispatch => {
        dispatch(request(id));

        attributeService.getOneAttribute(id)
            .then(
                attribute => {
                    dispatch(success(attribute.success));
                    localStorage.setItem('attribute', JSON.stringify(attribute.success))
                    history.push('/admin/attributes/edit');
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: attributeConstants.GET_REQUEST } }
    function success(attribute) { return { type: attributeConstants.GET_SUCCESS, attribute } }
    function failure(error) { return { type: attributeConstants.GET_FAILURE, error } }
}

function attributeUpdate(attribute) {
    // custom toast mixin for sweetAlert
    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        background: '#D4EDDA',
        position: 'center',
        showConfirmButton: false,
        timer: 5000
    });
    const Error = Swal.mixin({
        toast: true,
        width: 300,
        background: '#ff9999',
        position: 'center',
        showConfirmButton: false,
        timer: 5000
    });
    return dispatch => {
        dispatch(request(attribute));

        attributeService.updateAttribute(attribute)
            .then(
                attribute => {
                    dispatch(success(attribute));
                    dispatch(alertActions.success('Attribute successfully updated'));
                    history.goBack();
                    Toast.fire({
                        type: 'success',
                        title: ' <span style="color:#0A551E">Attribute successfully updated</span>'
                    })
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Edit attribute failed'));
                    Error.fire({
                        type: 'error',
                        title: '<span style="color:#990000">Something went wrong</span>'
                    })
                }
            );
    };

    function request(attribute) { return { type: attributeConstants.UPDATE_ATTRIBUTE_REQUEST, attribute } }
    function success(attribute) { return { type: attributeConstants.UPDATE_ATTRIBUTE_SUCCESS, attribute } }
    function failure(error) { return { type: attributeConstants.UPDATE_ATTRIBUTE_FAILURE, error } }
}



// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        attributeService.delete(id)
            .then(
                attribute => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: attributeConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: attributeConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: attributeConstants.DELETE_FAILURE, id, error } }
}