import React from 'react';
import { connect } from 'react-redux'	;
import { SubmitHrConcern  } from '../HRConcernPage/SubmitHrConcern';
import { RequestCoePage } from '../COEPage/RequestCoePage';
import { SendSupportTicket } from '../SupportTicketPage/SendSupportTicket';
import { LeavePage } from '../LeavePages/LeavePage';
import { UserOvertimePage } from '../OvertimePages/OvertimePage';


// import LinesEllipsis from 'react-lines-ellipsis';
import MainNav from '../Components/MainNav';
import HeaderPhoto from '../Components/HeaderPhoto';
import DetailPage from '../Components/DetailPage';
import RequestList from '../Components/RequestList';
import StatusPage from '../Components/StatusPage';
import UserTab from '../Components/UserTab';
import M from 'materialize-css/dist/js/materialize.min.js'
import '../_core/styles/alert.sass'
import './../Pagination.sass';


class SelfService extends React.Component {

	constructor(props) {
		super(props);
		//alert Timeout

        this.state = {
            render: true,
            //pagination
            page: 1,
            pages: '',
			goto: '',
			getByIdModalOpen: false,
            id: ''
        }
	}

	componentDidMount(id) {
		var elem = document.querySelector(".sidenav");
		M.Sidenav.init(elem, {
			edge: "left",
			inDuration: 250
		});

		
	}

	render() {
		return (
			<div className="profile">
                <MainNav />
               
                <HeaderPhoto />

				<UserTab />

                <div className="container-userdetail">

                    <div className="col s12 m4">
                        <div className="row">
                            <div className="col s12 m3">
                                <DetailPage />
                            </div>
                            <div className="col s12 m9 l6">
                                <div className="BodyCon">
                                    <div className="userDetailCon">
                                        <div className="row">
                                            
											<RequestList />

                                            <div className="col s9 m9 l9">
                                                <div id="test1" className="tab-content">
													<LeavePage/>
												</div>
												<div id="test2" className="tab-content">
													<UserOvertimePage/>
												</div>
                                                <div id="test3" className="tab-content">
													<SubmitHrConcern/>
												</div>
                                                <div id="test4" class="tab-content">
													<SendSupportTicket/>
												</div>
                                                <div id="test5" class="tab-content">
													<RequestCoePage/>		
												</div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

							<div className="col s12 m3">
                                <StatusPage />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

function mapStateToProps(state) {
	const { leaves } = state;
	const { alert } = state;
	return {
		leaves,
		alert
	};
}


const connectedSelfServicePage = connect(mapStateToProps)(SelfService);
export { connectedSelfServicePage as SelfServicePage };