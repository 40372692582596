import React, { useEffect, useState }from 'react';
import { If, Else, Then,  } from 'react-if';

import Pagination from 'react-js-pagination';
// import { exp_level } from '../../../_core/helpers/exp';
import * as experience from '../../../_core/helpers/exp';

import './LeaderBoardsDashboard.scss'

const LeaderBoardsDashboard = (props) => { 
    const [UsersLeaderBoards, setUsersLeaderBoards] = useState(null)
    const [PaginationData, setPaginationData] = useState([]) // eslint-disable-line
    const [currentValue, setCurrentValue] = React.useState(10)

    const getLeaderBoards =(props) => { 
		const { users, roles: { roles } } = props || {}
		const allUsers = users.users?.items;
		let sort = [];
		let userLists = [];

		if (typeof allUsers !== 'undefined' && typeof roles !== 'undefined') {
			const filterRoles = roles.items.find(({ roleSlug }) => roleSlug === 'applicant') || { _id: 'n/a'}
			userLists = allUsers.filter(user => user.active === 1 && user.role !== filterRoles._id && user.credit !== 0)
		}

		sort = userLists.sort((a, b) => (
			b.credit - a.credit
        ))
        setUsersLeaderBoards(sort);
		return sort; 
    }

    const handlePageChange = (page) => {
      
    }
    const pagination_pages = (page = 0, prevPage = 0) => {

        // Todo Pagination
        // This function are not yet finish

        if(UsersLeaderBoards !== null){
            let getCurrent = UsersLeaderBoards.length / 10;
            let getTotalPage = 0;
            for(let i = 1; i <= Math.ceil(getCurrent); i++){
                getTotalPage += 1
            }
            if(getTotalPage !== 0) {
                let start = 0;
                let end = 0;
                let page = getTotalPage;
                let tempArray = [];

                for(let i = 1; i <= page; i++){
                    if(start === 0){
                        start = 1;
                        end = 10;
                    } else {
                        start += 10;
                        end += 10;
                    }
                }
                UsersLeaderBoards.map((data, i) => {
                   
                    if(i >= start && i < end ){
 
                        tempArray.push(data)
                    }
                    return null
                })
                if(tempArray.length !==0 ){
                    setPaginationData(tempArray)
                }
              
            }
        }
    
    }
    const InputArrows = ({ topArrowClick, bottomArrowClick }) => {

		return (
			<div className="arrows-component">
				<div className="arrows">
					<button onClick={topArrowClick}>
						<i className="fa fa-angle-up" aria-hidden="true"></i>
					</button>
					<button onClick={bottomArrowClick}>
						<i className="fa fa-angle-down" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		);
    };
    const handlEntries = e => {
		setCurrentValue(e.target.value.replace(/\D/, ""));
	};
	const topArrowClicked = (e) => {
		setCurrentValue(prevState => prevState + 1);
	}

	const bottomArrowClicked = (e) => {
		setCurrentValue(prevState => prevState - 1);
	}

    useEffect(() => {
        if(props.users.users !== undefined && props.roles.loading === false){
            getLeaderBoards(props)
        }
    }, [props])

    useEffect(() => {
        if(UsersLeaderBoards !== null){
            pagination_pages()
        }
    // eslint-disable-next-line
    }, [UsersLeaderBoards])

    return (
        <div className="LeaderBoardsDashboard">
            <div className="leaderBoards_container">
                <div className="table-card">
                    <div className="table-header">

                        <p > Leaderboards</p>

                        <div className="block">
                            <span className="darkmode-btn-default entries"> Show Entries </span>
                            <div className="custom-input">
                                <input type="text" className="entries-btn" name="clicks" value={currentValue} pattern="[0-9]*" onChange={e => handlEntries(e)} />
                                <span className="arrows">
                                    <InputArrows
                                        topArrowClick={topArrowClicked}
                                        bottomArrowClick={bottomArrowClicked}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
             
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th> Rank </th>
                                    <th> Name </th>
                                    <th> Level </th>
                                    <th> Credits </th>
                                </tr>
                            </thead>

                            <tbody>
                                <If condition={UsersLeaderBoards !== null}>
                                    <Then>

                                        {UsersLeaderBoards !== null && UsersLeaderBoards.length !== 0 ? UsersLeaderBoards.map((leaderBoardsDetails, rank) => (
                                            // Custom pagination
                                            rank < 10 &&
                                            <tr key ={leaderBoardsDetails._id}>
                                                <td >{rank + 1 }</td>
                                                <td>{leaderBoardsDetails.details[0].firstName + " " +leaderBoardsDetails.details[0].lastName}</td>
                                                <td>{experience.exp_level(leaderBoardsDetails.exp)[0]?.current}</td>
                                                <td>{leaderBoardsDetails.credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                            
                                            </tr>

                                        )
                                        )
                                            :
                                            <tr className="table-td-data">
                                                <td colSpan="6"> No Record found... </td>
                                            </tr>
                                        }

                                    </Then>
                                    <Else>
                                        <tr className="table-td-data">
                                            <td colSpan="6"> Loading data... </td>
                                        </tr>
                                    </Else>

                                </If>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-footer">

                    {UsersLeaderBoards !== null  && UsersLeaderBoards.length !== 0 && <div className="dataCount"> Showing 1 to 10 of 10 entries  </div> }   


                    {UsersLeaderBoards !== null && UsersLeaderBoards.length !== 0 && 
                        <Pagination
                            activeClass="pagination_navigtion_active"
                            itemClass="pagination_navigtion"
                            linkClass="pagination_navigtion_link"

                            hideDisabled
                            hideFirstLastPages
                            prevPageText='Previous'
                            nextPageText='Next'
                            pageRangeDisplayed={10}
                            activePage={1}
                            itemsCountPerPage={10}
                            totalItemsCount={10}
                            onChange={(e) => handlePageChange(e)}
                        />
                    }
                      
                    </div>
                </div>
            </div>
        </div>
    )
}

    
export default LeaderBoardsDashboard