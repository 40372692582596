import pageService from '../services/pageManagement'
import pageConstants from '../constants/pageManagement';

const getAll = (page,itemPerPage) => async (dispatch) => {
    dispatch({ type: pageConstants.GET_PAGES_REQUEST });

    const data = await pageService.getAll(page,itemPerPage);

    dispatch({ type: pageConstants.GET_PAGES_SUCCESS, data });
}

const create = (page) => async () => {
    try {
        return await pageService.create(page);
    } catch (error) {
        throw error;
    }
}

const edit = (page, pageId) => async () => {
    try {
        return await pageService.edit(page, pageId);
    } catch (error) {
        throw error;
    }
}

const remove = (pageId) => async () => {
    try {
        return await pageService.remove(pageId);
    } catch (error) {
        throw error;
    }
}

const uploadFeaturedImage = (formData, pageId) => async  () => {
    try {
        const result = await pageService.uploadFeaturedImage(formData, pageId);

        return result;
    } catch(error) {
        throw error;
    }
}

export default {
    create,
    edit,
    getAll,
    remove,
    uploadFeaturedImage
}