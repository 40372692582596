import { leaveCreditConstants } from '../constants/leaveCredit';


export function leaveCredits(state = {}, action){
    switch(action.type){
        //GETALL LEAVE CREDIT
        case leaveCreditConstants.GETALL_REQUEST:
          return {loading: true};
        case leaveCreditConstants.GETALL_SUCCESS:
          return {leaveCredits: action.leaveCredits};
          // return {items: action.leaveCredits};
        case leaveCreditConstants.GETALL_FAILURE:
          return {error: action.error};
        case leaveCreditConstants.GETUSER_CREDIT_REQUEST:
          return {loading: true}
        case leaveCreditConstants.GETUSER_CREDIT_SUCCESS:
          return {leaveCredits: action.leaveCredits};
        case leaveCreditConstants.GETUSER_CREDIT_FAILURE:
          return {error: action.error};
        case leaveCreditConstants.UPDATE_REQUEST:
          return {loading: true};
        case leaveCreditConstants.UPDATE_SUCCESS:
          return {success: true};
        case leaveCreditConstants.UPDATE_FAILURE:
          return {error: action.error};
        default:
          return state

    }
}