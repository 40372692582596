import { searchConstants } from '../constants/search';
import { searchServices } from '../services/search';
import { history } from '../helpers/history';

export const searchActions = {
    searchAll,
};

function searchAll(item, page, itemsPerPage) {
    return dispatch => {
        dispatch(request());

        searchServices.searchAll(item, page, itemsPerPage)
            .then(
                search => {
                    dispatch(success(search));
                    const trimStr = item.trim().replace(/\s\s+/g, ' ');
                    history.push(`/search?search=${trimStr}`);
                },
                error => {
                    dispatch(failure(error.toString()));
                    const trimStr = item.trim().replace(/\s\s+/g, ' ');
                    history.push(`/search?search=${trimStr}`);
                }
            );
    };

    function request() { return { type: searchConstants.SEARCH_REQUEST } }
    function success(search) { return { type: searchConstants.SEARCH_SUCCESS, search } }
    function failure(error) { return { type: searchConstants.SEARCH_FAILURE, error } }
}