import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from "react-redux";

import { expActions } from '../_core/actions/exp';
import { UserDashboardContext } from "../UserDashboard/UserDashboard";

import CurrentLevelWidget from '../CurrentLevelWidget/CurrentLevelWidget';
import ChallengesHistoryWidget from '../ChallengeWidget/ChallengesHistoryWidget';
import ProfileWidget from '../ProfileWidget/ProfileWidget';
import { QuestWidget } from '../QuestWidget/QuestWidget';
import { ChallengeWidget } from '../ChallengeWidget/ChallengeWidget';
import {QuestChallenge} from '../QuestWidget/QuestChallenge';
import { LandingPage } from './LandingPage';
import Footer from '../Footer/Mainfooter.js';
import { SubPanelBtn } from '../Components/SubPanelBtn';
import { LeaderBoards } from '../LeaderBoards/LeaderBoards_v3';
import { myChallenges } from './myChallenges.js';
import { AcceptedChallenges } from './AcceptedChallenges.js';
import { AcceptedQuests } from './AcceptedQuests.js';
import { Route } from 'react-router-dom';
import EngageSelection from './EngageSelection';
import './EngageSelection.sass';


import './Engage.scss';

const Engage = (props) => {
	const dispatch = useDispatch();
	React.useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		const secret = JSON.parse(localStorage.getItem("secretDetails"));
		const unknown = secret ? secret.user_id : user.user_id

		dispatch(expActions.getExp(unknown));
		
	}, [dispatch]);	
	
	const device = React.useContext(UserDashboardContext).device;

	const url = window.location.pathname;

	return (
		<div className={device === 'desktop' ? "row engage desktop-footer" : "row engage"}>
			<div
				className={cc([
					'sub-panel',
					'col',
					's12',
					'l4',
					device
				])}
			>
				<div>
					<ProfileWidget />
					<CurrentLevelWidget
						engageMode
						exp={props.exp}
					/>
					{url === '/engage/challenges' &&
					
					<>
						<ChallengesHistoryWidget/>
						<SubPanelBtn name="My Challenges" link="/engage/mychallenges"/>
						<SubPanelBtn name="My Accepted Challenges" link="/engage/acceptedchallenges"/>
						<SubPanelBtn name="My Accepted Quests" link="/engage/acceptedquests"/>
						<SubPanelBtn name="Leaderboards" link="/engage/leaderboards"/>
						<SubPanelBtn name="Redemption" link="/rewards/redemption"/>
					</>
					}
					{url === '/engage' &&
					<>
						<SubPanelBtn name="My Challenges" link="/engage/mychallenges"/>
						<SubPanelBtn name="My Accepted Challenges" link="/engage/acceptedchallenges"/>
						<SubPanelBtn name="My Accepted Quests" link="/engage/acceptedquests"/>
						<SubPanelBtn name="Leaderboards" link="/engage/leaderboards"/>
						<SubPanelBtn name="Redemption" link="/rewards/redemption"/>
					</>
					}
					{url === '/engage/quests' &&
					<>
						<SubPanelBtn name="My Challenges" link="/engage/mychallenges"/>
						<SubPanelBtn name="My Accepted Challenges" link="/engage/acceptedchallenges"/>
						<SubPanelBtn name="My Accepted Quests" link="/engage/acceptedquests"/>
						<SubPanelBtn name="Leaderboards" link="/engage/leaderboards"/>
						<SubPanelBtn name="Redemption" link="/rewards/redemption"/>
					</>
					}
				</div>
				{device === 'desktop' &&
					<div><Footer /></div>
				}
			</div>
			<div
				className={cc([
					'main-panel',
					'col',
					's12',
					'l8',
					device
				])}
			>
				{ window.location.pathname === "/engage" ||
					window.location.pathname === "/engage/quests" ||
					window.location.pathname === "/engage/challenges" ?
				<EngageSelection/>
				: ''}
				<Route exact path="/engage/quests" component={QuestWidget}/>
				<Route exact path="/engage/quest/challenges" component={QuestChallenge}/>
				<Route exact path="/engage/acceptedquests" component={AcceptedQuests}/>
				<Route exact path="/engage/leaderboards" component={LeaderBoards}/>
				<Route exact path="/engage/mychallenges" component={myChallenges}/>
				<Route exact path="/engage/acceptedchallenges" component={AcceptedChallenges}/>
				<Route exact path="/engage/challenges" component={ChallengeWidget}/>
				<Route exact path="/engage" component={LandingPage}/>
			</div>
			{device === 'tablet' &&
				<div><Footer /></div>
			}
		</div>
	)
};

const mapStateToProps = (state) => ({
	isLoading: (
		state.exps.loading
	) === true,
	exp: state.exps.exp?.[0]?.exp || 0
});

export default React.memo(connect(mapStateToProps)(Engage));
