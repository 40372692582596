import { overtimeConstants } from '../constants/overtime';

export function addOvertimeRequest(state = {}, action) {
  switch (action.type) {
    case overtimeConstants.CREATE_REQUEST:
      return { addOvertimeRequest: true };
    case overtimeConstants.CREATE_SUCCESS:
      return { addOvertimeRequest: false };
    case overtimeConstants.CREATE_FAILURE:
      return {};
    default:
      return state
  }
}