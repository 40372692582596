import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import { officeLocation, level, position, department } from '../_core/constants/selections';
// import moment from 'moment';
import Select from 'react-select';
import './dateInput.sass';

import './step4.sass';

export default class Step4 extends Component {
  constructor(props) {
    super(props);
    this.edit = typeof props.getStore().manager !== 'undefined' ? true : false;
    this.state = {
      user: {
        _id: props.getStore()._id,
        avatar: props.getStore().avatar,
        bio: props.getStore().bio,
        cover: props.getStore().cover,
        firstName: props.getStore().firstName,
        middleName: props.getStore().middleName,
        lastName: props.getStore().lastName,
        title: props.getStore().title,
        suffix: props.getStore().suffix,
        address1: props.getStore().address1,
        address2: props.getStore().address2,
        city: props.getStore().city,
        state: props.getStore().state,
        country: props.getStore().country,
        nationality: props.getStore().nationality,
        mothersMaidenName: props.getStore().mothersMaidenName,
        maritalStatus: props.getStore().maritalStatus,
        gender: props.getStore().gender,
        birthDate: props.getStore().birthDate,
        birthPlace: props.getStore().birthPlace,
        email: props.getStore().email,
        team: props.getStore().team,
        organization: props.getStore().organization,
        manager: this.edit ? props.getStore().manager : null,
        position: props.getStore().position,
        department: props.getStore().department,
        startDate: props.getStore().startDate,
        endDate: props.getStore().endDate,
        regularizationDate: props.getStore().regularizationDate,
        level: props.getStore().level,
        officeId: props.getStore().officeId
      },
      selections: {
        officeLocation: officeLocation,
        position: position,
        level: level,
        department: department
      },
      startDate: '',
      endDate: '',
      regDate: ''
    }
    this.handleChange = this.handleChange.bind(this);
    // this.handleStartDatePicker = this.handleStartDatePicker.bind(this);
    // this.handleEndDatePicker = this.handleEndDatePicker.bind(this);
    // this.handleRegDatePicker = this.handleRegDatePicker.bind(this);
  }

  // handleStartDatePicker(date) {
  //   // const getDate = moment(date).format().split('T')[0]
	// 	// const formatDate = "T00:00:00." + moment().format('SSS[Z]')
  //   // const isoDate = getDate + formatDate
  //   const isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
	// 	this.setState({
	// 		startDate: isoDate
	// 	  }, () => {
	// 		this.handleChange({ target: { name: 'startDate', value: isoDate } });
	// 	  });
	// 	const regDate = moment(date).add(process.env.REACT_APP_HRM_REGULARIZATION_DATE, 'M').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
	// 	const regularizationDate = regDate
  //   this.handleChange({target: { name: 'regularizationDate', value: regularizationDate } });
    
  // }

  // handleEndDatePicker(date) {
  //   // const getDate = moment(date).format("YYYY-MM-DD");
  //   // const isoDate = getDate + "T00:00:00." + moment().format('SSS[Z]')
  //   const isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  //   this.setState({
  //     endDate: isoDate
  //   }, () => {
  //     this.handleChange({ target: { name: 'endDate', value: isoDate } });
  //   });
  // }

  // handleRegDatePicker(date) {
  //   // const getDate = moment(date).format("YYYY-MM-DD");
  //   // const isoDate = getDate + "T00:00:00." + moment().format('SSS[Z]')
  //   const isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  //   this.setState({
  //     regDate: isoDate
  //   }, () => {
  //     this.handleChange({ target: { name: 'regularizationDate', value: isoDate } });
  //   });
  // }

  handleChange(event) {
    const { name, value } = event.target
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    }, () => {
      // console.log(this.state.user);
    });
  }

  handleSelectOfficeLocation = (selected) => {
    const { user } = this.state;
    
    this.setState({
      user: {
        ...user,
        officeId: selected.value
      }
    }, () => console.log(''))
    
  }

  handleSelectPosition = (selected) => {
    const { user } = this.state;
    
    this.setState({
      user: {
        ...user,
        position: selected.value
      }
    }, () => console.log(this.state.user))
    
  }

  handleSelectLevel = (selected) => {
    const { user } = this.state;
    
    this.setState({
      user: {
        ...user,
        level: selected.value
      }
    }, () => console.log(this.state.user))
    
  }

  handleSelectDepartment = (selected) => {
    const { user } = this.state;
    
    this.setState({
      user: {
        ...user,
        department: selected.value
      }
    }, () => console.log())
    
  }

  componentDidMount() {
    setTimeout(function () {
      var select = document.querySelectorAll('select');
      M.FormSelect.init(select);
    }, 1000)
    // console.log(this.state.user);
   
  }

  jumpToStep(toStep) {
    const { user } = this.state;

    // We can explicitly move to a step (we -1 as its a zero based index)
    this.props.updateStore(user)
    this.props.jumpToStep(toStep - 1); // The StepZilla library injects this jumpToStep utility into each component
  }

  officeLocationOptions(){
    const selections = this.state.selections;
    var officeLocationOpt = []
    
    selections.officeLocation && selections.officeLocation.default.map((status, index)=>{
      officeLocationOpt.push({
        value: status.value,
        label: status.label,
        key: index,
      })
      return status
    })

    return officeLocationOpt
  }

  render() {
    const { user } = this.state;

    const customStyles = {
      menu:(provided, state)  => ({
          ...provided,
          height: "auto",
          maxHeight: "150px",
          zIndex: "99",
          position: "absolute",
          display: "block",
      }),
      menuList:(provided, state)  => ({
          ...provided,
          height: "auto",
          maxHeight: "150px",
          padding: "2px 2px",
      }),
      input: (state) => ({
        fontFamily: 'Poppins, sans-serif',
        '& input': {
          font: 'inherit',
          textDecoration: state.isFocused ? 'none !important' : 0,
        },
      }),
      option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? 'rgb(202,108,235)' : state.isFocused ? 'rgba(202,108,235,0.5)' : '',
          color: state.isSelected ? 'white' : state.isFocused ? 'white' : '',
          '& hover': {
            backgroundColor: 'rgb(202,108,235)',
          },
        }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
      }
    }

    return (
      <div className="step step4 step4Con">
        <div className="col s12 m12 l12">
          <div className="">
            <div className="card-header">
              <p className="left step4Txt">Employment Details</p>
            </div>

            <div className="clearfix step4Cons">
              <div className="">
                <div className="step4Con1">
                  <div className="step4Input-field col s12 m6 l6">
                    <label><p className="step4Txt1 left">Office Location</p></label>
                    <div className="step4Select-field step4Select1 clearfix">
                      <Select 
                        options={this.officeLocationOptions()}
                        placeholder="Select Office"
                        className="basic-single"
                        styles={customStyles}
                        onChange={this.handleSelectOfficeLocation}
                      />
                    </div>
                  </div>

                  <div className="step4Input-field col s12 m6 l6">
                    <label><p className="step4Txt1 left">Manager</p>
                    <input type="text" disabled value={user.manager ? user.manager : "No Manager Specified"} placeholder="Manager" /></label>
                  </div>
                </div>
          
                {/* <div className=""> */}
                  {/* <div className=""> */}
                    <div className="step4Con2">
                      
                        
                      
                    {/* </div> */}
                  {/* </div> */}

                  <div className="button-container-four clearfix page4BtnCon">
                    <div className="">
                      <button className="btn page4Btn-prev" onClick={() => this.jumpToStep(2)}>Previous</button>
                    </div>
                    <div className="">
                      <button className="btn page4Btn-next" onClick={() => this.jumpToStep(4)}>Next</button>
                    </div>
                  </div>

                </div>

                <div className="right">
                    <p className="page4PageNum">Step 3 of 4</p>
                  </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}