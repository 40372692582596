import { creditConstants } from '../constants/credit';
import { creditService } from '../services/credit';

export const creditActions = {
    getTotalCredits,
}

function getTotalCredits(userId) {
    return dispatch => {
        dispatch(request());
        creditService.getTotalCredits(userId)
            .then(
                credits => {
                    dispatch(success(credits));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: creditConstants.GET_TOTAL_REQUEST } }
    function success(credits) { return { type: creditConstants.GET_TOTAL_SUCCESS, credits } }
    function failure(error) { return { type: creditConstants.GET_TOTAL_FAILURE, error } }
}