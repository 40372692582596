import { commentConstants } from '../constants/comment';
import { commentService } from '../services/comment';
import { alertActions } from './alert';

export const commentActions = {
    postComment, //POST ONE COMMENT
    getAll, //GET ALL COMMENTS
    getById, //GET ONE COMMENT
    getByOrigin, //GET COMMENT BY ONE POST/COMMENT(ORIGIN)
};

function postComment(comment){
    return dispatch => {
        dispatch(request(comment));
        commentService.postComment(comment)
            .then(
                comment => {
                    dispatch(success());
                    dispatch(alertActions.success('comment successfully sent'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };   

    function request(comment) { return { type: commentConstants.POST_REQUEST, comment } }
    function success() { return { type: commentConstants.POST_SUCCESS } }
    function failure(error) { return { type: commentConstants.POST_FAILURE, error} }
}

function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());
        commentService.getAll(page, itemsPerPage)
            .then(
                comments => dispatch(success(comments)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: commentConstants.GETALL_REQUEST } }
    function success(comments) { return { type: commentConstants.GETALL_SUCCESS, comments } }
    function failure(error) { return { type: commentConstants.GETALL_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request(id));
        commentService.getById(id)
            .then(
                comment => dispatch(success(comment)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: commentConstants.GET_REQUEST } }
    function success(comment) { return { type: commentConstants.GET_SUCCESS, comment } }
    function failure(error) { return { type: commentConstants.GET_FAILURE, error } }
}

function getByOrigin(id, page, itemsPerPage) {
    return dispatch => {
        dispatch(request(id));
        commentService.getByOrigin(id, page, itemsPerPage)
            .then(
                orig_comment => dispatch(success(orig_comment)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: commentConstants.GET_ORIGIN_REQUEST, id } }
    function success(orig_comment) { return { type: commentConstants.GET_ORIGIN_SUCCESS, orig_comment } }
    function failure(error) { return { type: commentConstants.GET_ORIGIN_FAILURE, error } }
}