import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from "react-redux";
import moment from 'moment';
import Linkify from 'react-linkify';

import { history } from "../_core/helpers/history";

import { searchActions } from '../_core/actions/search';
import { userActions } from '../_core/actions/user';
import { commentService } from '../_core/services/comment';
import { reactionService } from '../_core/services/reaction';

import { UserDashboardContext } from "../UserDashboard/UserDashboard";

import ProfileWidget from '../ProfileWidget/ProfileWidget';
import Footer from '../Footer/Mainfooter.js';

import avatar from '../_templates/man.png';
import imgNotFound from '../_templates/img-not-found.png';
import chevLeft from '../_templates/chevron-left.png';
import hearted from '../_templates/heart_red.png';
import heart from '../_templates/heart_grey.png';
import haveComment from '../_templates/comment_icon.png';
import nocomment from '../_templates/comment_grayed.png';

import './Search.scss';

const linkDecorator = (href, text, key) => (
	<a href={href} key={key} target="_blank" rel="noopener noreferrer">{text}</a>
);

const SearchPeople = (props) =>{
    const peopleList = props?.people;
    const { isPeopleView } = props;

    const maxDisplay = 3;
    const hasMore = peopleList.length > maxDisplay;
    
    const displayPeople = isPeopleView && peopleList.length > 0 ? peopleList : peopleList.length > 0 && peopleList?.slice(0, maxDisplay);

    const seeAllPeople = React.useCallback(()=>{
        props.seeAll(props.code);
    },[props]);

    const checkProfile = React.useCallback((id)=>{
        history.push(`/profile/${id}`);
    },[]);

    return(
        <>
        {displayPeople &&
        <div className="search-people">
            {!isPeopleView && <div className="sp-header">People</div>}
            {displayPeople && displayPeople?.map((user, index) => (
            <div key={index} onError={(e)=> e.target.src = avatar} className="sp-content" onClick={() => checkProfile(user._id)}>
                <img alt="avatar" src={user.avatar || avatar}/>
                <div>
                    <p>{user.name || "No name specified"}</p>
                    <p>{user.position}{user.department ? " | " + user.department : ""}</p>
                </div>
            </div>
            ))}
            {!isPeopleView && 
            <div className="sp-see-all">
                {hasMore &&
                    <p onClick={()=> seeAllPeople()}>SEE ALL</p>
                }
            </div>
            }
        </div>
        }
        </>
    );
}

const SearchEngage = (props) =>{
    const engageList = props?.engage;
    const { isEngageView } = props;
    const { isDevice } = props;

    const firstEngCard = React.useRef();
    const lastEngCard = React.useRef();
    const engCard = React.useRef();
    const [isPrev, setPrev] = React.useState(false);
    const [isNext, setNext] = React.useState(true);

    const cardInitialCount = 3;
    const maxDisplay = 6;
    const hasMore = engageList.length > maxDisplay;
    const maxCharDesc = 43;
    const displayEngage = isEngageView && engageList.length > 0 ? engageList : engageList.length > 0 && engageList.slice(0 , maxDisplay);    

    const seeAllEngage = React.useCallback(()=>{
        props.seeAll(props.code)
    },[props]);

    const viewChallenge = React.useCallback((eng)=>{
        if(eng.engageType === "challenge" && !eng.questChallenge){
            history.push("/engage/challenges");
            //scroll to selected challenge on engage challenges page
            setTimeout(()=>{
                const getRef = document.getElementById('phrChallenge' + eng.challengeId);
                getRef && getRef.scrollIntoView({behavior: "smooth", block:"center"});//prevent error if page loads after setTimeout
            }, 1500);
        }else{
            history.push("/engage/quests");
            //scroll to selected quest on engage quest page
            setTimeout(()=>{
                const setId = eng.questChallenge ? eng.questChallenge : eng.questId
                const getRef = document.getElementById('phrQuest' + setId);
                getRef && getRef.scrollIntoView({behavior: "smooth", block:"center"});//prevent error if page loads after setTimeout
            }, 1500);
        }
    },[]);

    const prevList = React.useCallback(()=>{
        setPrev(false);
        setNext(true);
        firstEngCard.current.scrollIntoView({behavior: "smooth", block: "nearest" , inline: "start"});
    },[firstEngCard]);

    const nextList = React.useCallback(()=>{
        setPrev(true);
        setNext(false);
        lastEngCard.current.scrollIntoView({behavior: "smooth", block: "nearest" , inline: "end"});
    },[lastEngCard]);

    return(
        <>
        {displayEngage &&
        <div className={isEngageView ? "search-engage engage-view" : "search-engage"}>
            {!isEngageView && <div className="se-header">Quest or Challenge</div>}
            {!isEngageView &&
            <div className="se-nav-btns">
                {isPrev && <div className="se-prev" onClick={prevList}><img alt="<" src={chevLeft}/></div>}
                {isNext && engageList.length > cardInitialCount ? 
                    <div className="se-next" onClick={nextList}><img alt=">" src={chevLeft}/></div>
                :
                    isDevice && isNext && engageList.length > cardInitialCount - 1 ? <div className="se-next" onClick={nextList}><img alt=">" src={chevLeft}/></div>
                :
                ""}
            </div>
            }
            <div className="se-content">
                <div className={isEngageView ? "se-cards row" : "se-cards-slide"}>
                    {displayEngage && displayEngage?.map((eng, index) =>
                    <div key={index} className={isEngageView ? "se-card card col s6" : "se-card card"}
                        ref={index === 0 ? firstEngCard : index === engageList?.slice(0 , maxDisplay).length - 1 ? lastEngCard : engCard}
                        onClick={()=> viewChallenge(eng)}
                    >
                        <img alt="eng-img" onError={(e)=> e.target.src= imgNotFound } src={eng.image || imgNotFound}/>
                        <div className="se-card-content">
                            <p>{eng.title || ""}</p>
                            <p>
                                {eng.description.slice(0, maxCharDesc)}
                                {eng.description.length > maxCharDesc ? "..." : ""}
                            </p>
                        </div>
                        <div className="se-card-footer">
                            <img alt="avatar" onError={(e)=> e.target.src= avatar} src={eng.poster.avatar || avatar}/>
                            <div>
                                <p>{eng.poster.name || "No name specified"}</p>
                                <p>{eng.poster.position}{eng.poster.department ? " | " + eng.poster.department : ""}</p>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
            {!isEngageView && 
            <div className="se-see-all">
                {hasMore && 
                    <p onClick={()=> seeAllEngage()}>SEE ALL</p>
                }
            </div>
            }
        </div>
        }
        </>
    );
}

const SocialUpdate = (props) =>{
    const { postUpdate } = props;

    const [commentNum, setCommentNum] = React.useState(0);
	const [reactNum, setReactNum] = React.useState(0);
	const [reacted, setReact] = React.useState(false);
	const [commented, setComment] = React.useState(false);

    const getPostUpdate = (id) => {
		try{
			commentService.getByOrigin(id, 1, 100000).then(res=>{
				if(res.page){
					setCommentNum(res.total);
					const comments = res.items;
					const user = JSON.parse(localStorage.getItem('userCredentials'));
					const currentUserId = user && user.user._id;
					const userCommented = comments.filter(c=> c.poster === currentUserId);
					if(userCommented.length > 0){
						setComment(true);
					}
				}	
			});
			reactionService.getByOrigin(id, 1, 100000).then(res=>{
				if(res.page){
					setReactNum(res.total);
					const reactions = res.items;
					const user = JSON.parse(localStorage.getItem('userCredentials'));
					const currentUserId = user && user.user._id;
					const userReacted = reactions.filter(c=> c.poster === currentUserId);
					if(userReacted.length > 0){
						setReact(true);
					}else{
						setReact(false)
					}
				}
			}).catch(error=>{});
		}catch(err){
			console.log(err);
		}
    }

    React.useEffect(()=>{
       getPostUpdate(postUpdate);
    },[postUpdate]);


    return(
        <div className="spost-social">
            <div className="spost-react">
                <img alt="sreact-img" src={reacted ? hearted : heart}/>
                <p className="social-count">{reactNum || 0}</p>
            </div>
            <div className="spost-comment">
                <img alt="scomment-img" src={commented ? haveComment : nocomment}/>
                <p className="social-count">{commentNum || 0}</p>
            </div>
        </div>
    );
}

const SearchPost = (props) => {
    const postList = props?.posts;
    const maxDisplay = 3;
    // const hasMore = postList.length > maxDisplay;
    const { isPostsView } = props;
    const displayPosts = isPostsView && postList.length > 0 ? postList : postList.length > 0 && postList?.slice(0, maxDisplay);

    return(
        <div className="search-posts">
            {displayPosts && displayPosts.map((post, index)=>
            <div key={index} className="search-post-content">
                <div className="spost-img">
                    <img alt="avatar" onError={(e)=> e.target.src = avatar} src={post.poster.avatar || avatar}/>
                </div>
                <div className="spost-text">
                    <div>
                        <p>{post.poster.name}</p>
                        <p>{moment(post.createdAt).fromNow()}</p>
                    </div>
                    <Linkify componentDecorator={linkDecorator}>
                        <p className="spost-content">
                            {post.content}
                        </p>
				    </Linkify>
                    {post.image && post.image.toString().match(/^http.*\.(jpeg|jpg|gif|png)$/) != null &&
                    <img className="spost-img" alt="post-img" onError={(e)=> e.target.src = imgNotFound} src={post.image || imgNotFound}/>
                    }
                    <SocialUpdate postUpdate={ post._id }/>
                </div>
            </div>
            )}
        </div>
    );
}

const Search = (props) => {
    const dispatch = useDispatch();
    const page = 1;
    const perPage = 9999999;
    const { searchPeople } = props;
    const { searchPost } = props;
    const { searchEngage } = props;
    const { emptySearch } = props;

    const [isAll, setAll] = React.useState(true);
    const [isPeople, setPeople] = React.useState(false);
    const [isEngage, setEngage] = React.useState(false);
    const [isPosts, setPosts] = React.useState(false);

    const viewAll = 'all';
    const viewPeople = 'people';
    const viewEngage = 'engage';
    const viewPosts = 'posts'
    
    const searchView = React.useCallback((tab)=>{
        setAll(tab === viewAll);
        setPeople(tab === viewPeople);
        setEngage(tab === viewEngage);
        setPosts(tab === viewPosts)
    },[]);

    // search query format : ?search=item
    const searchItem = window.location.search?.split('=')[1] || ""; //get item
    const filterItem = encodeURIComponent(decodeURIComponent(searchItem).replace(/\s+/g, ' '));

	React.useEffect(() => {
        const trimStr = filterItem.replace(/\s\s+/g, ' ').trim();

        dispatch(searchActions.searchAll(trimStr, page, perPage));
        dispatch(userActions.getAllActive(page, perPage));
    }, [dispatch, searchItem, filterItem]);	
	
    const device = React.useContext(UserDashboardContext).device;

	return (
		<div className={device === 'desktop' ? "row search desktop-footer" : "row search"}>
			<div
				className={cc([
					'sub-panel',
					'col',
					's12',
					'l4',
					device
				])}
			>
				<div>
					<ProfileWidget />
				</div>
				{device === 'desktop' &&
					<div><Footer /></div>
				}
			</div>
			<div
				className={cc([
					'main-panel',
					'col',
					's12',
					'l8',
					device
				])}
			>
				<div className="search-header">
                    <div onClick={()=>searchView(viewAll)} className={isAll ? "sh-active" : ""}>All</div>
                    {searchPeople.length > 0 &&
                    <div onClick={()=>searchView(viewPeople)} className={isPeople ? "sh-active" : ""}>Profile</div>
                    }
                    {searchPost.length > 0 &&
                    <div onClick={()=>searchView(viewPosts)} className={isPosts ? "sh-active" : ""}>Post</div>
                    }
                    {searchEngage.length > 0 &&
                    <div onClick={()=>searchView(viewEngage)} className={isEngage ? "sh-active" : ""}>Engage</div>
                    }
                </div>
                {emptySearch ?
                <div><center><h5>No data found</h5></center></div>
                :
                <div className="search-content">
                    {isEngage || isPosts ?
                    ""
                    :
                    <SearchPeople 
                        people={ searchPeople }
                        isPeopleView={ isPeople }
                        code={ viewPeople }
                        seeAll={ searchView }
                    />
                    }
                    {isPeople || isPosts ?
                    ""
                    :
                    <SearchEngage
                        isDevice={ device !== 'desktop' }
                        engage={ searchEngage }
                        isEngageView={ isEngage }
                        code={ viewEngage }
                        seeAll={ searchView }
                    />
                    }
                    {isPeople || isEngage ?
                    ""
                    :
                    <SearchPost 
                        posts={ searchPost }
                        isPostsView={ isPosts }
                        code={ viewPosts }
                    />
                    }
                </div>
                }
			</div>
			{device === 'tablet' &&
				<div><Footer /></div>
			}
		</div>
	)
};

const mapStateToProps = (state) => {
    const search = state.search?.search;
    const people = "users";
    const posts = "post";
    const ch = "challenge";
    const qst = "quest";

    const activeUsers = state.users?.users?.items.map((u)=>{
        return({
            _id: u._id || "",
            avatar: u.details[0]?.avatar || "",
            name: (u.details[0]?.firstName + " " + u.details[0]?.lastName) || "",
            position: u.details[0]?.position || "",
            department: u.details[0]?.department || "",
        })
    });

    //to filter searched users if in active users // org restriction not yet applied in search api
    const userIds = activeUsers?.map((a)=>{
        return (a._id)
    });
    
    const peopleFiltered = userIds && search?.items?.filter((s) => s.collection === people && userIds.includes(s.user_id))?.map((u)=>{
        return({
            _id: u.user_id || "",
            avatar: u.avatar || "",
            name: (u.firstName + " " + u.lastName) || "",
            position: u.position || "",
            department: u.department || "",
        });
    });

    const engageFiltered = userIds && search?.items?.filter((s) => s.collection === ch || s.collection === qst)?.filter((e)=> userIds.includes(e?.userId) || userIds.includes(e?.originalPoster) )
    .map((eng)=>{
        const getPosterId = eng?.collection === qst ? eng.userId : eng.originalPoster;
        const user = activeUsers?.filter((u)=> u._id === getPosterId);
        const setPoster = user.length > 0 ? user[0] : ""

        return({
            engageType: eng?.collection === qst ? "quest" : "challenge",
            title: eng?.collection === qst ? eng?.questTitle : eng?.title,
            description: eng?.collection === qst ? eng?.questContent : eng?.description,
            image: eng?.collection === qst ? eng?.questImage : eng?.images,
            questId: eng?.collection === qst ? eng?._id : "",
            challengeId: eng?.collection === qst ? "" : eng._id,
            questChallenge: eng?.collection === ch && eng?.questId ? eng.questId : "",
            poster: setPoster
        }) 
    });

    const postFiltered = userIds && search?.items?.filter((s) => s.collection === posts && userIds.includes(s.userId)).map((post)=>{
        const user = activeUsers?.filter((u)=> u._id === post.userId)[0];
        return({
            _id: post._id,
            createdAt: post.createdAt,
            content: post.postContent,
            image: post.postImage,
            poster: user,
        })
    });

    return({
        searchPeople: peopleFiltered || {},
        searchPost: postFiltered || {},
        searchEngage: engageFiltered || {},
        emptySearch: peopleFiltered?.length <= 0 && postFiltered?.length <= 0 && engageFiltered?.length <= 0 ? true : state.search.error ? true : false
    })
    
};

export default React.memo(connect(mapStateToProps)(Search));
