import { expConstants } from '../constants/exp';
import { expService } from '../services/exp';

export const expActions = {
    getExp
};

function getExp(id){
    return dispatch => {
        dispatch(request(id));
        expService.getExp(id)
            .then(
                exp => dispatch(success(exp.success)),
                error => dispatch(failure(error.toString())),
            );
    };   

    function request(id) { return { type: expConstants.GET_EXP_REQUEST, id} }
    function success(exp) { return { type: expConstants.GET_EXP_SUCCESS, exp } }
    function failure(error) { return { type: expConstants.GET_EXP_FAILURE, error} }

}

