export default {
    GET_PAGES_REQUEST: 'GET_PAGES_REQUEST',
    GET_PAGES_SUCCESS: 'GET_PAGES_SUCCESS',
    GET_PAGES_FAILURE: 'GET_PAGES_FAILURE',
    CREATE_PAGE_REQUEST: 'CREATE_PAGE_REQUEST',
    CREATE_PAGE_SUCCESS: 'CREATE_PAGE_SUCCESS',
    CREATE_PAGE_FAILURE: 'CREATE_PAGE_FAILURE',
    UPDATE_PAGE_REQUEST: 'UPDATE_PAGE_REQUEST',
    UPDATE_PAGE_SUCCESS: 'UPDATE_PAGE_SUCCESS',
    UPDATE_PAGE_FAILURE: 'UPDATE_PAGE_FAILURE',
}