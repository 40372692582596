import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { NotFound } from '../../Components/ERRORPAGE/NotFound';
import { NoPermission } from '../../Components/ERRORPAGE/NoPermission';

import SideMenu from '../../Admin/Components/SideMenu';

import { permissions } from "../helpers/permissions";

/*
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location.pathname } }} />
    )} />
)
*/

const PrivateRoute = ({ component: Component, ...privateRouteProps }) => (
    <Route
        {...privateRouteProps}
        render={
            (props) => {
                // console.log(window.location.pathname.split('/').slice(0, 3).join('/')+'/edit');
                // console.log( `${window.location.pathname.split('/').slice(0, 3).join('/')}/edit`);
                const pathName = window.location.pathname;
                const pathNameReview = window.location.pathname.split('/').slice(0, 4).join('/');
                const pathNameEdit = `${window.location.pathname.split('/').slice(0, 3).join('/')}/edit`;
                const pathNameMode = (window.location.pathname.includes('review'))  ?  pathNameReview : window.location.pathname.includes('/edit') || window.location.pathname.includes('manage') ? pathNameEdit : pathName;
                const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;


                // console.log(pathNameMode);
                // console.log(permissions.filter(item => pathNameMode === item.url)?.[0]?.permissionName);

                switch (true) {
                    case privateRouteProps.isLoggedIn === false:
                        return <Redirect to={{ pathname: '/login', state: { from: props.location.pathname } }} />;
                    case (
                        (roleAllTrue === 0) ? (privateRouteProps.routeType === "admin" &&
                        privateRouteProps.isAdmin === true && privateRouteProps.userPermissions?.includes(permissions.filter(item => pathNameMode === item.url)?.[0]?.permissionName) === false) : false
                        // privateRouteProps.routeType === 'admin' &&
                        // privateRouteProps.isAdmin === false
                    ):
                        return <Route path="*" component={NoPermission} />;
                        // return <Route path="*" component={NotFound} />;
                    case (
                        privateRouteProps.routeType === 'admin' &&
                        privateRouteProps.isAdmin === false
                    ):
                        return <Redirect to="/dashboard" />;
                    case (
                            privateRouteProps.routeType === 'admin' &&
                            privateRouteProps.isAdmin === true
                    ):
                        return <>
                        <SideMenu {...props} />
                        <Component {...props} />
                      </>;   

                    default:
                        return <Component {...props} /> ;
                }
            }
        }

        
    />


);

export default PrivateRoute;