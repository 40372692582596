export const commentConstants = {
    POST_REQUEST: 'POST_COMMENT_REQUEST',
    POST_SUCCESS: 'POST_COMMENT_SUCCESS',
    POST_FAILURE: 'POST_COMMENT_FAILURE',

    GETALL_REQUEST: 'COMMENT_GETALL_REQUEST',
    GETALL_SUCCESS: 'COMMENT_GETALL_SUCCESS',
    GETALL_FAILURE: 'COMMENT_GETALL_FAILURE',

    GET_REQUEST: 'COMMENT_GET_REQUEST',
    GET_SUCCESS: 'COMMENT_GET_SUCCESS',
    GET_FAILURE: 'COMMENT_GET_FAILURE',

    GET_ORIGIN_REQUEST: 'COMMENT_GET_ORIGIN_REQUEST',
    GET_ORIGIN_SUCCESS: 'COMMENT_GET_ORIGIN_SUCCESS',
    GET_ORIGIN_FAILURE: 'COMMENT_GET_ORIGIN_FAILURE',
};

