import { undertimeConstants } from '../constants/undertime';

let initialState = {
  undertimes: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function undertimes(state = initialState, action) {
  switch (action.type) {
    case undertimeConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case undertimeConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        undertimes: action.undertimes,
        totalPerPage: action.undertimes.total,
        perPage: action.undertimes.pages,
        currentPage: action.undertimes.page,
      };
    case undertimeConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case undertimeConstants.GET_REQUEST:
      return {
        saving: true
      };
    case undertimeConstants.GET_SUCCESS:
      return {
        undertime: action.undertime
      };
    case undertimeConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    case undertimeConstants.GETUSER_REQUEST:
      return {
        loading: true
      };
    case undertimeConstants.GETUSER_SUCCESS:
      return {
        undertimes: action.undertimes
      };
    case undertimeConstants.GETUSER_FAILURE:
      return { 
        error: action.error
      };

    default:
      return state;
  }
}
    