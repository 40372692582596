import React, { useEffect, useState } from 'react'
import styled  from "styled-components";
import moment from "moment";

import ReactImageFallback from "react-image-fallback";
import avatar from '../../../_templates/man.png';


const ImgWrapper = styled.div`
margin-top: 40px;

 > img {
   height: 100px;
   width: 100px;
   border-radius: 50%;
 }
`;

const UserDetailsWrapper = styled.div``;
const NameWrapper = styled.div`

display: flex;
flex-direction: column;
 
 > label.UserName__Details {
   font-size: 18px;
   font-weight: 700;
   color: #fff;
 }

 
 > label.UserPosition__Details {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
`;


const InfoWrapper = styled.div`
display: flex;
flex-direction: column;
margin: 20px 0px;

 > label.Info__Details {
  font-size: 14px;
  font-weight: 700;
  color: #9BA3CA;
  word-break: break-word;
 }

 > label.ContactTitle__Details {
  font-size: 14px;
  font-weight: 400;
  color: #9BA3CA;
  word-break: break-word;
}

> label.Contact__Details {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  word-break: break-word;
}

> label.AddressTitle__Details {
  font-size: 14px;
  font-weight: 400;
  color: #9BA3CA;
  max-width: 235px;
  word-break: break-word;
  margin-top: 10px;
}


> label.Address__Details {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  word-break: break-word;
}
`;


const LastEvaluationWrapper = styled.div`
display: flex;
flex-direction: column;

 > label.EvalTitle__LastEvaluation {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #9BA3CA;
 }

 > label.Date__LastEvaluation, label.Respondent__LastEvaluation, label.Primary__LastEvaluation {
  font-size: 14px;
  font-weight: 400;
  color: #9BA3CA;
  margin-bottom: 5px;
  word-break: break-word;
 }
`;


const Divider = styled.hr`
  background: #2E3549;
  border: solid 1px  #2E3549;
`;


const PreviewUserDetailsWidget = (props) => {
  const [UserDetails, setUserDetails] = useState(null)
  const [LastEvalDetails, setLastEvalDetails] = useState(null);
  const [PrimaryRespondent, setPrimaryRespondent] = useState(null)
  
  const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  

  const filterPrimaryRespondent = () => {
    let userList = props.users?.users?.items ?? [];
    if(LastEvalDetails) {
      userList.length !== 0 && userList.map(item => {
        if(item._id ===  LastEvalDetails.user_id) {
          setPrimaryRespondent(item.details[0].firstName + " " + item.details[0].lastName);
        }
        return null;
      })
    }
  }

  const filterViewUserDetails = () => {
    let userList = props.users?.users?.items ?? [];
    if(userList) {
      userList.map((item) => {
        if (item._id === props.subjectId) {
          setUserDetails(item.details[0]);
        }
        return item;
      });
      
    }
  };

    
    useEffect(() => {
      filterViewUserDetails();
      
      // eslint-disable-next-line
    }, [props])

    useEffect(() => {
      setLastEvalDetails(props.evaluations.dataLastEvaluation);
      // eslint-disable-next-line
    }, [props.evaluations])

    useEffect(() => {
      filterPrimaryRespondent();
    // eslint-disable-next-line
    }, [props.users, LastEvalDetails])

    
 return (
   <div>
     {UserDetails && (
       <>
         <ImgWrapper>
           <ReactImageFallback
             src={`${UserDetails.avatar}`}
             fallbackImage={avatar}
             initialImage={avatar}
             alt="user avatar"
           />
         </ImgWrapper>
         <UserDetailsWrapper>
           <NameWrapper>
             <label className="UserName__Details">
               {toTitleCase(UserDetails.firstName + " " + UserDetails.lastName)}
             </label>
             <label className="UserPosition__Details">
               {UserDetails.position !== null && toTitleCase(UserDetails.position)}
             </label>
           </NameWrapper>

           <InfoWrapper>
             <label className="Info__Details"> Info </label>
             <label className="ContactTitle__Details"> Contact Number </label>
             <label className="Contact__Details">
               {UserDetails.contactNumber}
             </label>
             <label className="AddressTitle__Details"> Address </label>
             <label className="Address__Details">{UserDetails.address1}</label>
           </InfoWrapper>
         </UserDetailsWrapper>

         <Divider></Divider>

         {LastEvalDetails  && PrimaryRespondent ? (
           <LastEvaluationWrapper>
             <label className="EvalTitle__LastEvaluation">
               Last Evaluation
             </label>
             <label className="Date__LastEvaluation"> Date: {moment(LastEvalDetails.startedAt).utc().format("MMMM DD, YYYY")} </label>
             <label className="Respondent__LastEvaluation">
               Respondent: {LastEvalDetails.respondents }
             </label>
             <label className="Primary__LastEvaluation">
               
               Primary Respondent: { PrimaryRespondent }
             </label>
           </LastEvaluationWrapper>
         ) : 
         <LastEvaluationWrapper>
           
         <label className="EvalTitle__LastEvaluation">
         User has no Evaluation
       </label> 
       </LastEvaluationWrapper>
       }
       </>
     )}
   </div>
 );
}


export default PreviewUserDetailsWidget;