import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import { department, position, officeLocation } from '../../_core/constants/selections'
import icon from '../steps/1.png'

export default class Step4 extends Component {
  constructor(props) {
    super(props);

  //set users props
    const { users } = this.props;

    //if editing, assign passed user data to prepopulate
    let _user = this.edit ? users.user.user[0] : {};

    //setup state for new or edited profile
    this.state = {
      user: {
        manager: this.edit ? _user.details[0].manager : null,
        position: this.edit ? _user.details[0].position : '',
        department: this.edit ? _user.details[0].department : ''
      },
      submitted: false,
      selections: {
        department: department,
        position: position,
        officeLocation: officeLocation
            }
      };

      this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user }        = this.state;

    this.setState({
        user: {
            ...user,
            [name]: value
        }
    });
  }

  componentDidMount() {
    setTimeout(function() {
      var select = document.querySelectorAll('select');
      M.FormSelect.init(select);
    }, 1000)
    
    //datepicker for start date
    var startDate = document.querySelectorAll('#startDate');
    M.Datepicker.init(startDate,{
      onSelect: this.handleStartDatePicker
    });

    //datepicker for end date
    var endDate = document.querySelectorAll('#endDate');
    M.Datepicker.init(endDate,{
      onSelect: this.handleEndDatePicker
    });

    //datepicker for reg date
    var regDate = document.querySelectorAll('#regDate');
    M.Datepicker.init(regDate,{
      onSelect: this.handleRegDatePicker
    });

    //datepicker for birth date
    var birthDate = document.querySelectorAll('#birthDate');
    M.Datepicker.init(birthDate,{
      onSelect: this.handleBirthDatePicker
    });
  }

  jumpToStep(toStep) {
    // We can explicitly move to a step (we -1 as its a zero based index)
    this.props.jumpToStep(toStep-1); // The StepZilla library injects this jumpToStep utility into each component
  }

render() {
    const { user, selections } = this.state;
    return (
        <div className="step step4">
          <div className="col s12 m12 l12">
            <div className="card-panel white lighten-5 z-depth-1">
              <div className="card-header">
                <div className="step-icon">
                  <img src={icon} alt="icon" className="step4-icon"/>
                </div>
                <div className="steps-name">
                  <h6 className="step4-name"> Employment Details </h6>
                </div>
                <div className="right">
                  <h6> Step 4 of 4 </h6>
                </div>
              </div>

              <div className="container step-2">
                <div className="row step-2">
                  <div className="input-field col s12 m6 l6">
                    <select name="officeId"value={user.officeId} onChange={this.handleChange}>
                      {selections.officeLocation.default.map((officeLocation, index) =>
                        <option key={officeLocation.value} value={officeLocation.value}>{officeLocation.label}</option>
                        )}
                    </select>
                    <label htmlFor="">Office Location</label>
                  </div>

                  <div className="input-field col s12 m6 l6">
                    <select name="manager">
                        <option defaultValue="">--- Choose User's Manager ---</option>
                    </select> 
                    <label htmlFor="">Manager</label>
                  </div>

                  <div className="input-field col s12 m4 l4">
                    <input type="text" id="startDate" name="startDate" placeholder="Start Date" className="datepicker"/>
                  </div>

                  <div className="input-field col s12 m4 l4">
                    <input type="text" id="endDate" name="endDate" placeholder="End Date" className="datepicker"/>  
                  </div>
                  <div className="input-field col s12 m4 l4">
                    <input type="text" id="regDate" name="regularizationDate" placeholder="Regularization Date" className="datepicker"/>
                  </div>

                  <div className="input-field col s12 m6">
                    <select name="position" value={user.position} onChange={this.handleChange}>
                      <option defaultValue="">--- Choose Position ---</option>
                        {selections.position.software.map((status, index) =>
                          <option key={status.value} value={status.value}>{status.label}</option>
                        )}
                    </select>
                    <label className={(user.position ? 'active' : '')}>Position</label> 
                  </div>

                  <div className="input-field col s12 m6">
                    <select name="department" value={user.department} onChange={this.handleChange}>
                      <option defaultValue="">--- Choose Department ---</option>
                        {selections.department.software.map((status, index) =>
                          <option key={status.value} value={status.value}>{status.label}</option>
                        )}
                    </select>
                    <label className={(user.department ? 'active' : '')}>Department</label> 
                  </div>
                </div>
              </div>
              <div className="button-container-four">
                <div className="btn-prev">
                  <button className="btn btn-previous" onClick={() => this.jumpToStep(3)}>Previous</button>
                </div>
                <div className="btn-nxt">
                  <button className="btn btn-save" onClick={() => this.jumpToStep(1)}>Save</button>
                </div>
              </div>
          </div>
          </div>
      </div>
    )
  }
}
