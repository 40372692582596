import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MainNav from '../Components/MainNav';
import address from '../_templates/address_icon.png';
import group from '../_templates/group.png';
import phone from '../_templates/phone_icon.png'
import ReactImageFallback from "react-image-fallback";
// import loading from '../Components/Ellipsis-1s-60px.svg';
import avatar from '../_templates/man.png';
import { userActions } from '../_core/actions/user';
import { peopleActions } from "../_core/actions/people";
import { history } from '../_core/helpers/history';
import './OfficeEmployees.sass';

class OfficeEmployeesPage extends React.Component {
    constructor(props) {
        super(props);
        
        const {office} = this.props

        this.state = {
            office: office.office === undefined ? "" : office.office[0],
            users: []
        };
    }

    componentDidMount(){
        if(this.state.office === ''){
            history.push('/offices')
        }
        this.props.dispatch(userActions.getAllActive());
    }

    static getDerivedStateFromProps(props){
        return{
            users: props.users
        }
    }
    
    viewProfile = (id) => {
        this.props.dispatch(peopleActions.getById(id));
    };

    render() {
        const { office, users } = this.state;

        return (
            <div className="emp-office-page">
                <MainNav />
                <div className="backbtn">
                    <Link to="/offices" className="link-goback">
                        <i className="material-icons backicon">keyboard_backspace</i> 
                        <span className="backtext">Go back</span>
                    </Link>
                </div>
                <div className="emp-office-con" >
                    <div className="offc-cover">
                        <div className="offc-info">
                            <p className="offc-name">{office.name ? office.name : "no office name"}</p>
                            <p className="offc-address1">
                                <span>
                                    <ReactImageFallback src={address} className="emp-add"/>      
                                </span>
                                {office.address ? office.address : "no address"}
                            </p>
                            <p className="offc-contct">
                                <span>
                                    <ReactImageFallback src={phone} className="emp-contct"/>  
                                </span>
                                {office.contact ? office.contact : "no contact"}
                            </p>
                        </div>
                        <div className="offc-emp-grp" >
                            <p className="emp-grp">
                            <span>
                                <ReactImageFallback src={group} className="offc-grp"/>  
                            </span>
                            {users.users && users.users.items.filter((user) => user.details[0].officeId === office._id).length}{" "}People</p>
                        </div>
                    </div>
                    {/* <hr className="offc-border"/> */}
                    <div className="offc-emp-list">
                        {users.error ? 
                            <div className="emp-list-error">
                                <h4>An error has occured while loading page</h4>
                                <h5>{users.error}</h5>
                            </div>
                        :
                        <div>
                        {users.loading ?
                        <div className="offc-load">
                            <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </div>
                        :
                        users.users && users.users.items.filter((user) => user.details[0].officeId === office._id).length <= 0 ?
                        <div className="offc-no-result">
                            <center><h5><em>no records found</em></h5></center>
                        </div>
                        :
                        <div className="offc-emp-success">
                            <div className="offc-emp">
                                {users.users && users.users.items.filter((user) => user.details[0].officeId === office._id).map((user, index)=>
                                <div key={index} className="emp-con" onClick={() => this.viewProfile(user._id)}>
                                    <ReactImageFallback
                                        src={user.details[0].avatar}
                                        fallbackImage={avatar}
                                        initialImage={avatar}
                                        alt="employee_img"
                                        className="offc-emp-img"/>
                                    <p className="emp-name">{user.details[0].firstName + ' ' + user.details[0].lastName}</p>
                                    <p className="emp-job">{user.details[0].position}</p>
                                </div>
                                )}
                            </div>
                        </div>
                        }
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { users, office } = state;
    return { users, office };
}
const connectedOfficeEmployees = connect(mapStateToProps)(OfficeEmployeesPage);

export { connectedOfficeEmployees as OfficeEmployeesPage };