//npm packages
import React from 'react';
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
import { If, Then, Else } from 'react-if';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './People.scss';
import teamActions from '../_core/actions/teams';
import avatar from '../_templates/man.png';
import option from '../_templates/dot.png';
import { hex } from "../_core/helpers/profile"
import { history } from "../_core/helpers/history"
import { userActions } from "../_core/actions/user";
import Avatar from '../Components/Avatar/Avatar';
import './People.scss';
import HeaderTabsSearch from "../Components/HeaderTabsSearch";

const SkeletonPost = () => {
	const [transparent, setTransparent] = React.useState(true);

	React.useEffect(() => {
		setTimeout(() => {
			setTransparent(false);
		}, 100);
	}, [setTransparent]);

	return (
		new Array(2).fill(null).map((_, index) => (
			<div
				className={cc({
					'card people-user-card skeleton-post': true,
					transparent
				})}
				key={index}
			>
				<SkeletonTheme color="#e0e0e0">
					<Skeleton width="100%" height={130} />
					<div className="people-info">
						<div className="people-basic-info">
							<div className="people-name">
								<div>
									<Skeleton circle width={100} height={100} />
								</div>
								<div>
									<p style={{paddingTop:"10px"}}><Skeleton height={15} width={200}/></p>
									<p style={{paddingTop:"5px"}}><Skeleton height={15} width={150}/></p>
								</div>
							</div>
						</div>
						<div style={{marginTop: "20px"}}>
						<Skeleton width="100%" height={50}/>
						</div>
					</div>
				</SkeletonTheme>
			</div>
		))
	);
}


const Teams = (props) => {
    const dispatch = useDispatch();
    const [recentPage, setRecentPage] = React.useState('');
    const [page, setPage] = React.useState(1);
    

    React.useEffect(() => {
        dispatch(teamActions.getAll(999999,1));
        dispatch(userActions.getAllActiveUsers(1, 999999));
    }, [dispatch]);

    const avatarSize = 50;
    const itemPerPage = 10;
    var hasNextPage = false;
    var userList = [];

    if(props.teams.items?.length > 0){
		if(!recentPage){
			userList = [...props.teams.items.slice(0, itemPerPage * page)];
			hasNextPage = userList.length >= itemPerPage * page;
        }else{
			userList = [...props.teams.items];
			hasNextPage = false;
		}
	}

    React.useEffect(()=>{
		document.addEventListener('scroll', trackScrolling);
		return ()=> document.removeEventListener('scroll', trackScrolling);
	});

    const trackScrolling = () => {
		if((window.innerHeight + window.scrollY) >= document.body.scrollHeight - 1 && hasNextPage && !recentPage){
			setTimeout(()=>{
				setPage(page=> page + 1);
			}, 500);
		}
	}
    const [filter, setFilter] = React.useState('');

    // SEARCH FILTER
	const handleChange = e => {
		e.preventDefault();
		const searchVal = e.target?.value
		setFilter(searchVal);
		if(searchVal.length > 2){
			if(!recentPage){
				setRecentPage(page);
				document.removeEventListener('scroll', trackScrolling);
			}
		}else{
			if(recentPage){
				setPage(recentPage);
				setRecentPage('');
				document.addEventListener('scroll', trackScrolling);
			}
		}
	}

	const lowercasedFilter = filter.toLowerCase();
	const filteredData = userList.filter(item => {
		if(filter.length >= 3){
		  return Object.keys(item).some(key =>
			typeof item[key] === "string" 
			&& item[key].toLowerCase().includes(lowercasedFilter) 
		  );
		}else{
		  return userList;
		}
	});

    return (
        <div>
            <HeaderTabsSearch
				currentPath={window.location.pathname}
				tabs={[
					{ name: "Everyone", path: "/people" },
					{ name: "Teams", path: "/people/teams" }
				]}
				searchValue={filter}
				searchOnChange={handleChange}
			/>
            <div className="people-content">
                {filteredData && filteredData.map(team => (
                    <div className="card people-user-card" onClick={()=> history.push(`/people/team/${team._id}`)}>
                        <div className="people-cover">
                            <div style={{backgroundColor: hex(team.name.length >= 6 ? team.name : team.name + team._id) ,height:'100%'}}></div>
                        </div>
                        <div className="people-info">
                            <div className="people-basic-info">
                                <div className="people-name">
                                    <div>    
                                        { team.logo 
                                            ? <img 
                                                className="people-avatar"
                                                alt=""
                                                src={ team.logo  || avatar}
                                                onError={(e) => { e.target.src = avatar }}
                                             />
                                            : <div className="people-initials-avatar" style={{
                                                backgroundColor: hex(team.name.length >= 6 ? team.name : team.name + team._id)}}>{team.name.charAt(0).toUpperCase() + team.name.charAt(0).toUpperCase()}</div>
                                        }
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <p>{team.name || "" }</p>
                                        <p></p>
                                    </div>
                                </div>
                                <img alt="" src={option}/>
                            </div>
                            <div className="team-lead">
                                { props.users?.users && props.users.users.items
                                    .filter(user => user._id === team.lead)
                                    .map((user, index) => {
                                        return (
                                            <If condition={props.users.loading === true}>
                                                <Then>
                                                    <SkeletonTheme color="rgb(225, 225, 225)" highlightColor="rgb(240, 240, 240)">
                                                        <Skeleton width={110} height={17} />
                                                    </SkeletonTheme>
                                                </Then>
                                                <Else>
                                                    <div className="container-info" >
                                                        <div className="avatar-lead">
                                                            <Avatar
                                                                size={avatarSize}
                                                                src={user.details[0].avatar}
                                                                className="avatar-team"
                                                            />
                                                        </div>
                                                        <div className="lead-info">
                                                            <p>Team Lead</p>
                                                            <p>{ user.details[0].firstName || "" } { user.details[0].lastName || "" }</p>
                                                        </div>
                                                    </div>
                                                </Else>
                                            </If>

                                        );
                                    })
                                }
                            </div>
                        </div>          
                    </div>
                ))}
                { hasNextPage && 
                    <SkeletonPost/>
                }

            </div>            
        </div> 
    )
    
};

const mapStateToProps = ({ teams, users }) => ({
    loading: !!(teams.loading || users.loading),
    teams,
    users
});

export default connect(mapStateToProps)(React.memo(Teams));
