import React from 'react';
import cc from 'classcat';
import { Route } from 'react-router-dom';

import { UserDashboardContext } from "../UserDashboard/UserDashboard";

import SelfServiceWidget from '../SelfServiceWidget/SelfServiceWidget';
import ProfileWidget from '../ProfileWidget/ProfileWidget';
import RequestModal from '../RequestModal/RequestModal';
import RequestModalOvertime from './RequestModalOvertime/RequestModal';
import RequestModalTechnicalSupport from './RequestModalTechnicalSupport/RequestModalTechSupp'
import RequestCOE from './RequestCOE/RequestCOE';
import RequestFeedBack from './RequestFeedBack/RequestFeedBack';
import RequestModalEmploymentConcern from './RequestModalEmploymentConcern/RequestEmploymentConcern';

import RequestLeaveModal from './RequestLeave/RequestLeaveModal';
import RequestsWidget from '../RequestsWidget/RequestsWidget';
import Footer from '../Footer/Mainfooter.js';
import ESSRequestHistory from './ESSRequestHistory';
import RequestHistory from './RequestHistory';

import './EmployeeSelfService.scss';


const EmployeeSelfService = (props) => {
	const device = React.useContext(UserDashboardContext).device;
	const [actvHistory, setActvHistory] = React.useState([]);
	const [leaveTypes, setLeaveTypes] = React.useState([]);

	return (
		<>
			<div className={device === 'desktop' ? "row employee-self-service desktop-footer" : "row employee-self-service"}>
				<div
					className={cc([
						'sub-panel',
						'col',
						's12',
						'l4',
						device
					])}
				>
					<div>
						<ProfileWidget />
						<ESSRequestHistory getHistory={items => setActvHistory(items)} getLeaveTypes={items => setLeaveTypes(items)} />
					</div>
					{device === 'desktop' &&
						<div><Footer /></div>
					}
				</div>
				<div
					className={cc([
						'main-panel',
						'col',
						's12',
						'l8',
						device
					])}
				>
					{props.match.path + "/history" === window.location.pathname?
						<RequestHistory requestHistoryList={actvHistory} leaveTypeList={leaveTypes}/>
						:
						<React.Fragment>
							<SelfServiceWidget render="employee-self-service" />
							<RequestsWidget />
						</React.Fragment>
					}


				</div>
				{device === 'tablet' &&
					<div><Footer /></div>
				}
			</div>

			<Route
				exact
				path={`${props.match.path}/view/:requestId`}
				render={props => <RequestModal {...props} mode="view" />}
			/>
			<Route
				exact
				path={`${props.match.path}/request/overtime`}
				render={() => <RequestModalOvertime render="OvertimeModal" />}
			/>
			<Route
				exact
				path={`${props.match.path}/request/undertime`}
				render={() => <RequestModalOvertime render="UndertimeModal" isUndertime={true} />}
			/>
			<Route
				exact
				path={`${props.match.path}/request/technical-support`}
				render={() => <RequestModalTechnicalSupport render="TechnicalSupportModal" />}
			/>

			<Route
				exact
				path={`${props.match.path}/request/certificate-of-employment`}
				render={() => <RequestCOE render="RequestCOEModal" />}
			/>

			<Route
				exact
				path={`${props.match.path}/request/employment-concern`}
				render={() => <RequestModalEmploymentConcern render="EmploymentConcernModal" />}
			/>

			<Route
				path={`${props.match.path}/request/vacation-leave`}
				render={() => <RequestLeaveModal render="Vacation" />}
			/>

			<Route
				exact
				path={`${props.match.path}/request/sick-leave`}
				render={() => <RequestLeaveModal render="Sick" />}
			/>

			<Route
				exact
				path={`${props.match.path}/request/feedback`}
				render={() => <RequestFeedBack render="RequestFeedBackModal" />}
			/>

		</>
	)
}

export default React.memo(EmployeeSelfService);
