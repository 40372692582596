import { post, get, del, put } from '../helpers/api';
import { userService } from '../services/user';

function getAll(itemsPerPage, page, search, filter = "") {
    let fields = `${filter ==='title' ? "title:" + search : filter === 'content' ?"content:" + search : ""}`
    return get(`/api/v1/reward?page=${page || 1}&items-per-page=${itemsPerPage || 10}&search=${search}&filter=${fields}`).then(handleResponse);
}

function getAllFilter(itemsPerPage, page) {
    return get(`/api/v1/reward?page=${page || 1}&items-per-page=${itemsPerPage || 20}&filter=active:1`).then(handleResponse);
}

function getOne(userId,rewardId) {
    return get(`/api/v1/user/${userId}/reward/${rewardId}`).then(handleResponse);
}

function getAllByUser(userId, page = 1, itemsPerPage = 10) {
    return get(`/api/v1/user/${userId}/reward?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}

function redeemReward(rewardId) {
    let id = rewardId.reward_id
    return put(`/api/v1/reward/${id}/redeem`).then(handleResponse);
}

function addCredit(userId, credit) {
    return post(`/api/v1/user/${userId}/credit`, JSON.stringify(credit)).then(handleResponse);
}


function postUserReward(userId, rewardId) {
    return post(`/api/v1/user/${userId}/reward`, JSON.stringify(rewardId)).then(handleResponse);
}

function create(createReward){
    return post(`/api/v1/reward`, JSON.stringify(createReward))
    .then(handleResponse)
}

function imageUpload(id , image){
    return post(`/api/v1/upload/${id}/rewards`, image).then(handleResponse);
}

function deleteRewards(id){
    return del(`/api/v1/reward/${id}`).then(handleResponse)
}
// function postUserRewardAll(userId, rewardId, reward) {

//     const lessTip = {
//         type: "credit",
//         value: -reward.rewardValue,
//         origin: "reward"
//     }

//     return post(`/api/v1/user/${userId}/reward`, JSON.stringify(rewardId))
//         .then(handleResponse)
//         .then(response => {

//             //createPost(postFeed);
//             addCredit(userId, lessTip)
            
//         });
// }


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
    
}

function getContent(rewardId) {
    return get(`/api/v1/reward/${rewardId}`).then(handleResponse);
}

function updateReward(id, posts){

    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
    const fd = new FormData();
    const imageFile = posts.image;
    fd.append('file',imageFile)
    
    //checking if post images has a content file
    if(isFile(posts.image) === true){
        posts.image = null;
    }

    return put(`/api/v1/reward/${id}`, JSON.stringify(posts)).then(handleResponse)
    .then(response => {
        imageUpload(id,fd);
    });
}

export const rewardService = {
    getAll,
    postUserReward,
    addCredit,
    getOne,
    redeemReward,
    getAllByUser,
    create,
    imageUpload,
    deleteRewards,
    getContent,
    updateReward,
    getAllFilter
};
