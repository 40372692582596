export const notifConstants = {
    
    GET_NOTIF_REQUEST: 'GETNOTIF_REQUEST',
    GET_NOTIF_SUCCESS: 'GETNOTIF_SUCCESS',
    GET_NOTIF_FAILURE: 'GENOTIF_FAILURE',

    GET_NOTIFPAGE_REQUEST: 'GETNOTIFPAGE_REQUEST',
    GET_NOTIFPAGE_SUCCESS: 'GETNOTIFPAGE_SUCCESS',
    GET_NOTIFPAGE_FAILURE: 'GENOTIFPAGE_FAILURE',
    
    POST_NOTIF_REQUEST: 'POSTNOTIF_REQUEST',
    POST_NOTIF_SUCCESS: 'POSTNOTIF_SUCCESS',
    POST_NOTIF_FAILURE: 'POSTNOTIF_FAILURE',

    UPDATE_NOTIF_REQUEST: 'UPDATENOTIF_REQUEST',
    UPDATE_NOTIF_SUCCESS: 'UPDATENOTIF_SUCCESS',
    UPDATE_NOTIF_FAILURE: 'UPDATENOTIF_FAILURE',

};


