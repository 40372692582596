import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { WarningPopup } from '../../Components/AdminModals/WarningPopup';

const FileSizeLimitInMb = 2; //set min file size

const IsFileSizeAccepted = (fileSize) => {
    const fileInBytes = fileSize;
    const mbLimit = FileSizeLimitInMb;
    var imageSizeMB = fileInBytes / Math.pow(1024, 2);

    return imageSizeMB < mbLimit
}

const WarningMessage = (message) => {
    WarningPopup("error", message, false, true).then((res) => {
        // 
    });
}

const DragAndDrop = (props) => {
    const dropImgRef = React.createRef();

    let imageDropListeners = ['dragenter', 'dragover', 'dragleave', 'drop'];

    React.useEffect(() => {
        let dropElement = dropImgRef.current;
        if (props.isActive) {
            imageDropListeners.forEach(eventName => {
                dropElement.addEventListener(eventName, imageDropHandler);
            });

            imageDropListeners.forEach(eventName => {
                dropElement.addEventListener(eventName, highlightOnDrop)
            });

        } else {
            imageDropListeners.forEach(eventName => {
                dropElement.removeEventListener(eventName, imageDropHandler);
                dropElement.removeEventListener(eventName, highlightOnDrop);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isActive]);

    const imageDropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let dataFile = e.dataTransfer.files;

        if (e.type === "drop" && dataFile && dataFile.length > 0) {
            if (dataFile.length > 1) {
                WarningMessage("Can't upload multiple files");
            } else {
                var url = window.URL || window.webkitURL;
                const image = new Image();

                image.onload = () => {
                    let isFileSizeAccepted = IsFileSizeAccepted(dataFile[0].size);

                    if (isFileSizeAccepted) {
                        const reader = new FileReader();
                        reader.addEventListener('load', () => props.handleDrop(reader.result));
                        reader.readAsDataURL(dataFile[0]);
                    } else {
                        WarningMessage(["File too large", "<p style='font-size: 1rem'>Can't upload image larger than 2 mb</p>"])
                    }
                }

                image.onerror = () => {
                    WarningMessage("File is not an image");
                }

                image.src = url.createObjectURL(dataFile[0]);
            }
        }
    }

    const highlightOnDrop = (e) => {
        // let unhighlightEvent = ['dragleave', 'drop'];
        let highlightEvent = ['dragenter', 'dragover'];

        if (highlightEvent.includes(e.type)) {
            e.target.classList.add(props.highlightClass);
        } else {
            e.target.classList.remove(props.highlightClass);
        }
    }

    return (
        <div ref={dropImgRef}>
            {props.children}
        </div>
    );
}

const ImageUploadModal = ({ isOpen = false, closeModal, modalTitle = "Change Photo", savedImage, isProfilePhoto }) => {
    //setup crop image default size for profile and cover
    const profileMinImgWidth = 50;
    const coverMinImgWidth = 100;
    const profileCropSize = { unit: '%', width: profileMinImgWidth, aspect: 1 / 1 }
    const coverCropSize = { unit: '%', width: coverMinImgWidth, aspect: 4 / 1 }
    //
    const [imageToCrop, setImageToCrop] = React.useState(); //img binary data
    const imgRef = React.useRef(null);
    const [crop, setCrop] = React.useState(profileCropSize); // {"x": 0.0, "y": 0.0, "width": 0, "height": 0, "unit": "px" ,aspect": 1 } //crop on change
    const [completedCrop, setCompletedCrop] = React.useState(null); // {"x": 0.0, "y": 0.0, "width": 0, "height": 0, "unit": "px" ,aspect": 1 } //latest crop
    //
    const fileInputRef = React.createRef();
    const [croppedImage, setCroppedImage] = React.useState(null);

    React.useEffect(() => {
        //set crop ratio
        if (!isProfilePhoto) {
            setCrop(coverCropSize);
        } else {
            setCrop(profileCropSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProfilePhoto]);

    React.useEffect (() => {
        // clear data on modal open
        if(isOpen) {
            removeUploadedImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isOpen]);

    const uploadImage = React.useCallback((id) => {
        const inputImgFileId = document.getElementById(id);
        inputImgFileId.click();
    }, []);

    const removeUploadedImage = () => {
        setImageToCrop(undefined);
        setCompletedCrop(null);
        setCrop(isProfilePhoto ? profileCropSize : coverCropSize);
        setCroppedImage(null);
        if(fileInputRef.current?.value) {
            fileInputRef.current.value = null;
        } else {
            fileInputRef.current = null;
        }
    }

    const convertImage = (canvas) => {
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                blob.name = new Date().getTime() + '.jpeg';
                resolve(blob);
            }, 'image/png', 1);
        });
    }

    const uploadCroppedImage = () => {
        const canvas = croppedImage;

        if (!canvas) {
            WarningMessage("No image detected");
            return
        }

        convertImage(canvas).then(res => {
            const imgSrc = URL.createObjectURL(res);
            const imgFile = new FormData();
            // imgFile.append("file", res, res.name);
            imgFile.append('file', res);
            savedImage(imgSrc, imgFile);
            closeModal();
        });
    }
    // 
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            let isFileSizeAccepted = IsFileSizeAccepted(e.target.files[0].size);

            if (isFileSizeAccepted) {
                const reader = new FileReader();
                reader.addEventListener('load', () => setImageToCrop(reader.result));
                reader.readAsDataURL(e.target.files[0]);
            } else {
                WarningMessage(["File too large", "<p style='font-size: 1rem'>Can't upload image larger than 2 mb</p>"])
            }

        }
    };

    const onLoad = React.useCallback((img) => {
        imgRef.current = img;
    }, []);

    React.useEffect(() => {
        if (!completedCrop || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = document.createElement("canvas");
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        setCroppedImage(canvas);
    }, [completedCrop]);

    return (
        <div className={isOpen ? "image-upload-modal-overlay" : "image-upload-modal-overlay is--hidden"}>
            <div className={isProfilePhoto ? "image-upload-modal-content is--profile" : "image-upload-modal-content is--cover"}>
                <div className="image-upload-modal__header">
                    <p>{modalTitle}</p>
                </div>
                <div className="image-upload-modal__body">
                    <DragAndDrop isActive={isOpen} handleDrop={(item) => setImageToCrop(item)} highlightClass={"highlight-ondrop"}>
                        <ReactCrop
                            src={imageToCrop}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                            className="user-profile-img-crop"
                            minWidth={isProfilePhoto ? profileMinImgWidth : coverMinImgWidth}
                        />
                        {imageToCrop === undefined &&
                            <button onClick={() => uploadImage("UserProfileImgUploadInput")}>Upload a photo</button>
                        }
                    </DragAndDrop>
                    {imageToCrop !== undefined &&
                        <button onClick={() => removeUploadedImage()}>Remove photo</button>
                    }

                    <input id="UserProfileImgUploadInput" ref={fileInputRef} type="file" accept="image/*" onChange={onSelectFile} />
                </div>
                <div className="image-upload-modal__footer">
                    <div>
                        <button onClick={() => uploadCroppedImage()} className={crop.width <= 30 ? "disabled-btn" : ""}>Upload</button>
                        <button onClick={() => closeModal()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageUploadModal;