import React, { Component } from 'react'
import { history } from '../../_core/helpers/history';
import { connect } from 'react-redux';

import { essActions } from '../../_core/actions/ess';
// import { coeActions } from '../../_core/actions/coe';
import requestsActions from '../../_core/actions/requests'
import moment from 'moment';
import Swal from 'sweetalert2'

import Modal from 'react-modal';

import Dropzone from "./Dropzone";

//import PolicyList from "../PolicyList";

import './RequestFeedBack.sass'
class RequestFeedBack extends Component {
    constructor(props) {
        super(props)

        this.state = {
            feedback: {
                subject: '',
                description: '',
                type: 'request',
                subtype: 'feedback',
                startedAt: new Date().toISOString(),
                endedAt: new Date().toISOString(),
                files:[]
            },
            itemPerPage: 10,
            submitted: false

        }



    }

    getDescription = (e) => {
        const feedback = this.state.feedback;
        this.setState({
            feedback: {
                ...feedback,
                description: e.target.value
            }
        }, () => {
        })
    }

    getTitle = (e) => {
        const feedback = this.state.feedback;
        this.setState({
            feedback: {
                ...feedback,
                subject: e.target.value
            }
        }, () => {

        })
    }

    close(e) {
        e.preventDefault();

        const { itemPerPage } = this.state
        const userDetails = JSON.parse(localStorage.getItem("userCredentials"));
        this.props.dispatch(requestsActions.getRequests({
            itemPerPage,
            selectPage: 1,
            userId: userDetails.user._id
        }));
        history.goBack()
    }

    handleSubmit(e) {
        e.preventDefault();
        const { feedback } = this.state;
        let dateNow = Date(Date.now()).toLocaleString();
        let dateFormat = moment(dateNow).format("ddd, MMMM DD, YYYY");

        this.setState({ submitted: true })

        if (feedback.subject && feedback.description && feedback.type && feedback.subtype && feedback.startedAt && feedback.endedAt) {
            Swal.fire({
                title: 'Submit Request ?',
                type: 'warning',
                html: "<br> <b> Check the following  data if correct:</b> <br> " +
                    "<br> <b> Title: </b> " + this.state.feedback.subject +
                    "<br> <b> Description: </b> " + this.state.feedback.description,
                footer: 'Filing Date:  ' + dateFormat,
                showCancelButton: true,
                confirmButtonColor: '#26a69a',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit'
            }).then((result) => {
                if (result.value) {
                    // this.props.dispatch(coeActions.sendRequestCoe(coe))
                    this.props.dispatch(essActions.create(feedback));
                }
            })
        }
    }
    render() {
        const { feedback, submitted } = this.state;
        let dateNow = Date(Date.now()).toLocaleString();
        let dateFormat = moment(dateNow).format("dddd, MMMM DD, YYYY");

        return (
            <Modal
                isOpen={true}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.close.bind(this)}
                className="RequestModalUser"
                centered
            >
                <div className="RequestFeedBack">
                    {/* Header  */}
                    <div className="header-container">
                        <div className="title-container">
                            <span> Create Feedback Request </span>
                        </div>

                        <div className="dateFiled-container">
                            <span> Filing Date: {dateFormat}</span>
                        </div>
                    </div>
                    {/* closing Header  */}

                    <div className="content-container">
                        <form name="form" onSubmit={this.handleSubmit.bind(this)} autoComplete="off">

                            <div className="row">
                                {/* Row for Request title */}
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Request Title
                                    </label>

                                    <div className="Request-input-border">
                                        <input type="text" className="form-control requestTitle" id="requestTitle" onChange={this.getTitle.bind(this)} placeholder="Write Request Title" />
                                    </div>
                                    {submitted && !this.state.feedback.subject &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right"> Title is required</div>
                                    }
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col s12">
                                    <div className="center-textarea">
                                        <label className="label-title">Description:</label>
                                        <div className="input-custom">
                                            <textarea style={{ border: "none", padding: "10px", resize: "vertical" }} className="form-control requestDescription" id="requestDescription" onChange={this.getDescription.bind(this)} placeholder="Description" />
                                        </div>
                                        {submitted && !this.state.feedback.description &&
                                            <div className="help-block red-text" data-error="wrong" data-success="right"> Description is required</div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Attachment Files*/}
                            <div className="row">
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Attach File 
                                    </label>
                                    <Dropzone item={items => {
                                        this.setState({feedback: {
                                            ...feedback,
                                            files: items,
                                        }})
                                    }}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12">
                                    <div className="policy">
                                        <p>Here is where content for simple reminders of policy rules</p>
                                        <br />
                                        <p>1. Request Title</p>
                                        <p>2. Description</p>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-container">
                                <div>
                                    <button type="submit" className="waves-effect waves-light btn sroUserSubmitBtn" id="submit">Submit</button>
                                    <button type="submit" className="waves-effect waves-light btn sroUserCancelBtn" id="cancel" onClick={this.close.bind(this)}> Cancel</button>
                                </div>
                            </div>
                        </form>



                        <div className="row">
                            <div className="col s12">
                                <label className="label-title" htmlFor="">
                                    Details:
                                </label>

                                <div className="policy">
                                    <p>Here is where content for simple reminders of policy rules</p>
                                    <br />
                                    <p>1. Request Title</p>
                                    <p>2. Description</p>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </Modal>


        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { coeActions } = state;
    return {
        coeActions,
        alert
    };
}
export default React.memo(connect(mapStateToProps)(RequestFeedBack));

