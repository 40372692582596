import React from "react";
import hr_policies from "../hr_policies";
import TextToLink from "../_core/helpers/textToLink";
import "./PolicyList.scss";


const PolicyList = ({ type = "", index = 0 }) => {
    const getPolicies = hr_policies?.filter(policy => policy.type === type)?.[0]?.policies || [];
    const checkPolicyIndex = getPolicies?.length !== 0 && getPolicies?.length >= index + 1;
    const Policy = checkPolicyIndex ? getPolicies[index] : getPolicies[0];

    const ListFormat = (item, index) => (
        <div className={"phr-policy-sub-list"}>
            {
                Array.isArray(item) ?
                    item?.map((item2, index2) =>
                        ListFormat(item2, index2)
                    )
                    : <p key={index}>{TextToLink(item)}</p>
            }
        </div>
    )

    return (
        <div className={"phr-policy-container"}>
            <p className={"phr-policy-title"}>{Policy.title}</p>
            {Policy.subtitle &&
                <p className={"phr-policy-subtitle"}>{Policy.subtitle}</p>
            }
            <div className={"phr-policy-list"}>
                <ul>
                    {Policy?.items.map((item, index) =>
                        Array.isArray(item) ?
                            item?.map((item2, index2) =>
                                ListFormat(item2, index2)
                            )
                            : <li key={index}>{TextToLink(item)}</li>
                    )}
                </ul>
            </div>
            {Policy.note &&
                <p className={"phr-policy-note"}>{Policy.note}</p>
            }
        </div>
    )
};

export default PolicyList;