import { post, get, put } from '../helpers/api';
import { userService } from '../services/user';

export const technicalService = {
    getAll,
    getById,
    reject,
    hold,
    approve,
    update
}

function getAll(page, itemsPerPage) {
    return get(`/api/v1/request?filter=subtype:technical%20support&page=${page || 1}&items-per-page=${itemsPerPage || 20}`).then(handleResponse);
}

function getById(id) {
    return get (`/api/v1/request/${id}`).then(handleResponse);
}

function createActivity(activity,id) {
    return post(`/api/v1/user/${id}/activity`, JSON.stringify(activity)).then(handleResponse);
}

function reject(technical) {
    let id = technical._id;
    delete technical._id;
    return put (`/api/v1/request/${id}/reject`, JSON.stringify(technical))
    .then(handleResponse)
    .then(response => {
        getById(id).then(data => {
            data.success.map(users => {
                const activity = {
                    "_id": users._id,
                    "user_id": users.user_id,
                    "title": users.subject,
                    "description": users.description,
                    "status": users.status,
                    "type": users.type,
                    "subtype": users.subtype,
                    "icon":  "",
                    "image": ""
                }
                return createActivity(activity,users.user_id);
            });
        })
    });
}

function hold(technical) {
    let id = technical._id;
    delete technical._id;
    return put (`/api/v1/request/${id}/hold`, JSON.stringify(technical))
    .then(handleResponse)
    .then(response => {
        getById(id).then(data => {
            data.success.map(users => {
                const activity = {
                    "_id": users._id,
                    "user_id": users.user_id,
                    "title": users.subject,
                    "description": users.description,
                    "status": users.status,
                    "type": users.type,
                    "subtype": users.subtype,
                    "icon":  "",
                    "image": ""
                }
                return createActivity(activity,users.user_id);
            });
        })
    });
}

function approve(technical) {
    let id = technical._id;
    delete technical._id;
    return put (`/api/v1/request/${id}/approve`, JSON.stringify(technical))
    .then(handleResponse)
    .then(response => {
        getById(id).then(data => {
            data.success.map(users => {
                const activity = {
                    "_id": users._id,
                    "user_id": users.user_id,
                    "title": users.subject,
                    "description": users.description,
                    "status": users.status,
                    "type": users.type,
                    "subtype": users.subtype,
                    "icon":  "",
                    "image": ""
                }
                return createActivity(activity,users.user_id);
            });
        })
    });
}

function update(technical) {
    let id = technical._id;
    delete technical._id;
    return put (`/api/v1/request/${id}`, JSON.stringify(technical)).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}