import React from 'react';
import './SelfServiceWidget.sass';

import sun from '../_templates/sun@2x.svg';
import plus from '../_templates/plus-square@2x.svg';
import clock from '../_templates/clock@2x.svg';
import zap from '../_templates/ess_tech_icon.png';
import briefcase from '../_templates/briefcase.png'
//import others from '../_templates/more-horizontal.png'
import feedback from '../_templates/message_writing.png'

import { connect } from 'react-redux';
//import { userActions } from '../_core/actions/user';
import { leaveRequestActions } from '../_core/actions/leaveRequest';
import { roleActions } from '../_core/actions/role';
import { Link } from 'react-router-dom';
import { leaveTypeActions } from '../_core/actions/leaveType';
import { twoDigitsFormatter } from '../helper'
import { leaveCreditService } from '../_core/services/leaveCredit';

import { leaveHours } from '../_core/helpers/config'

class SelfServiceWidget extends React.Component {

	constructor(props) {
		super(props);

		const secret = JSON.parse(localStorage.getItem('secretCredentials'))
		
		this.state = {
			show: false,
			disabled: secret ? true : false,
			vacationCredits:0,
			medicalCredits:0
		}
	
	}
	componentDidMount() {
		//this.props.dispatch(userActions.getById());
		this.props.dispatch(leaveRequestActions.getAll());
		this.props.dispatch(roleActions.getAll(1, 1000000));
		this.props.dispatch(leaveTypeActions.getAll());
		this.getLeaveCredits()
	}

// 	Request Types:
// 		LEAVE_REQUEST = leave
// 		REQUEST_OVERTIME = request
// 		EMPLOYMENT_CONCERN = support
// 		TECHNICAL_SUPPORT = support
// 		CERTIFICATE_OF_EMPLOYMENT = request
// 	request subtypes:
// 		leaveType:_id
// 		overtime
// 		undertime
// 		certificate of employment
// 	support subtypes:
// 		hr concern
// 		technical support



	getVacationLeave() {

		const { leaveRequest: { leaveRequest }, leaveTypes } = this.props || {};
		const allLeaveRequest = (leaveRequest && leaveRequest.items) || [];
		let user = JSON.parse(localStorage.getItem('userCredentials'));
		let secret = JSON.parse(localStorage.getItem("secretCredentials"))
		let unknown = secret ? secret._id: user.user._id

		let pendingCount = [];
		let approvedCount = [];
		let rejectedCount = [];

		if (typeof leaveTypes !== 'undefined' && typeof allLeaveRequest !== 'undefined') {
			const vacationLeaveId = leaveTypes.items.find(({ type }) => type === 'leave') || { _id: 'n/a' };

			let request = allLeaveRequest && allLeaveRequest.filter((req) => req.user_id === unknown);

			let pending = request.filter((req) => req.status ===  'new' && req.subtype ===  vacationLeaveId._id);
			let approved = request.filter((req) => req.status === 'approved' && req.subtype === vacationLeaveId._id);
			let rejected = request.filter((req) => req.status === 'rejected' && req.subtype === vacationLeaveId._id);

			pendingCount = twoDigitsFormatter(pending.length) || [];
			approvedCount = twoDigitsFormatter(approved.length) || [];
			rejectedCount = twoDigitsFormatter(rejected.length) || [];
			
        }
        return { 
            pendingCount, 
            approvedCount, 
            rejectedCount
        }

    }

    getMedicalLeave() {
		const { leaveRequest: { leaveRequest }, leaveTypes } = this.props || {};
		const allLeaveRequest = (leaveRequest && leaveRequest.items) || [];
		let user = JSON.parse(localStorage.getItem('userCredentials'));
		let secret = JSON.parse(localStorage.getItem("secretCredentials"))
		let unknown = secret ? secret._id: user.user._id
        
		let pendingCount = [];
		let approvedCount = [];
		let rejectedCount = [];

		if (typeof leaveTypes !== 'undefined' && typeof allLeaveRequest !== 'undefined') {
			const medicalLeaveId = leaveTypes.items.find(({ type, name }) => name === "Sick Leave" && type === "leave") || { _id: 'n/a' };

			let request = allLeaveRequest && allLeaveRequest.filter((req) => req.user_id === unknown);

			let pending = request.filter((req) => req.status === "new" && req.subtype === medicalLeaveId._id );
			let approved = request.filter((req) => req.status === 'approved' && req.subtype === medicalLeaveId._id);
			let rejected = request.filter((req) => req.status === 'rejected' && req.subtype === medicalLeaveId._id);

			pendingCount = twoDigitsFormatter(pending.length) || [];
			approvedCount = twoDigitsFormatter(approved.length) || [];
			rejectedCount = twoDigitsFormatter(rejected.length) || [];
			
        }
        return { 
            pendingCount, 
            approvedCount, 
            rejectedCount
        }
    }

    getOverTimeRequest() {
		const { leaveRequest: { leaveRequest }, leaveTypes } = this.props || {};
		const allLeaveRequest = (leaveRequest && leaveRequest.items) || [];
		let user = JSON.parse(localStorage.getItem('userCredentials'));
		let secret = JSON.parse(localStorage.getItem("secretCredentials"))
		let unknown = secret ? secret._id: user.user._id


		let pendingCount = [];
		let approvedCount = [];
		let rejectedCount = [];

		if (typeof leaveTypes !== 'undefined' && typeof allLeaveRequest !== 'undefined') {

			let request = allLeaveRequest && allLeaveRequest.filter((req) => req.user_id === unknown);

			let pending = request.filter((req) => req.status === 'new' && req.subtype === "overtime");
			let approved = request.filter((req) => req.status === 'approved' && req.subtype === "overtime");
			let rejected = request.filter((req) => req.status === 'rejected' && req.subtype === "overtime");

			pendingCount = twoDigitsFormatter(pending.length) || [];
			approvedCount = twoDigitsFormatter(approved.length) || [];
			rejectedCount = twoDigitsFormatter(rejected.length) || [];

        }
        return { 
            pendingCount, 
            approvedCount, 
            rejectedCount
        }
	}
	
	getUndertimeRequest() {
		const { leaveRequest: { leaveRequest }, leaveTypes } = this.props || {};
		const allLeaveRequest = (leaveRequest && leaveRequest.items) || [];
		let user = JSON.parse(localStorage.getItem('userCredentials'));
		let secret = JSON.parse(localStorage.getItem("secretCredentials"))
		let unknown = secret ? secret._id: user.user._id

		let pendingCount = [];
		let approvedCount = [];
		let rejectedCount = [];

		if (typeof leaveTypes !== 'undefined' && typeof allLeaveRequest !== 'undefined') {

			let request = allLeaveRequest && allLeaveRequest.filter((req) => req.user_id === unknown);

			let pending = request.filter((req) => req.status === 'new' && req.subtype === "undertime");
			let approved = request.filter((req) => req.status === 'approved' && req.subtype === "undertime");
			let rejected = request.filter((req) => req.status === 'rejected' && req.subtype === "undertime");

			pendingCount = twoDigitsFormatter(pending.length) || [];
			approvedCount = twoDigitsFormatter(approved.length) || [];
			rejectedCount = twoDigitsFormatter(rejected.length) || [];
        }
        return { 
            pendingCount, 
            approvedCount, 
            rejectedCount
        }
	}

	getEmployeeConcern = () => {
		const { leaveRequest: { leaveRequest }, leaveTypes } = this.props || {};
		const allLeaveRequest = (leaveRequest && leaveRequest.items) || [];
		
		let user = JSON.parse(localStorage.getItem('userCredentials'));
		let secret = JSON.parse(localStorage.getItem("secretCredentials"))
		let unknown = secret ? secret._id: user.user._id

		let pendingCount = [];
		let approvedCount = [];
		let rejectedCount = [];

		if (typeof leaveTypes !== 'undefined' && typeof allLeaveRequest !== 'undefined') {
			let request = allLeaveRequest && allLeaveRequest.filter((req) => req.user_id === unknown);

			let pending = request.filter((req) => req.status ===  'new' && req.subtype ===  "hr concern");
			let approved = request.filter((req) => req.status === 'approved' && req.subtype === "hr concern");
			let rejected = request.filter((req) => req.status === 'rejected' && req.subtype === "hr concern");

			pendingCount = twoDigitsFormatter(pending.length) || [];
			approvedCount = twoDigitsFormatter(approved.length) || [];
			rejectedCount = twoDigitsFormatter(rejected.length) || [];
			
        }
        return { 
            pendingCount, 
            approvedCount, 
            rejectedCount
        }
	}

	getTechnicalSupport = () => {
		const { leaveRequest: { leaveRequest }, leaveTypes } = this.props || {};
		const allLeaveRequest = (leaveRequest && leaveRequest.items) || [];
		
		let user = JSON.parse(localStorage.getItem('userCredentials'));
		let secret = JSON.parse(localStorage.getItem("secretCredentials"))
		let unknown = secret ? secret._id: user.user._id

		let pendingCount = [];
		let approvedCount = [];
		let rejectedCount = [];

		if (typeof leaveTypes !== 'undefined' && typeof allLeaveRequest !== 'undefined') {
			let request = allLeaveRequest && allLeaveRequest.filter((req) => req.user_id === unknown);

			let pending = request.filter((req) => req.status ===  'new' && req.subtype ===  "technical support");
			let approved = request.filter((req) => req.status === 'approved' && req.subtype === "technical support");
			let rejected = request.filter((req) => req.status === 'rejected' && req.subtype === "technical support");

			pendingCount = twoDigitsFormatter(pending.length) || [];
			approvedCount = twoDigitsFormatter(approved.length) || [];
			rejectedCount = twoDigitsFormatter(rejected.length) || [];
			
        }
        return { 
            pendingCount, 
            approvedCount, 
            rejectedCount
        }
	}

	getLeaveCredits() {
		let user = JSON.parse(localStorage.getItem('userCredentials'));
		let secret = JSON.parse(localStorage.getItem("secretCredentials"))
		let unknown = secret ? secret._id: user.user._id

		leaveCreditService.getUser(unknown).then(res => {
			if (res) {
				// eslint-disable-next-line
				res.user[0].leave_types && res.user[0].leave_types.map((items,i) => {
					if(items.type === 'vacation' && items.name=== 'Vacation Leave'){
						this.setState({ vacationCredits: items.available/leaveHours.time});
					}else if(items.type === 'medical' && items.name === 'Sick Leave'){
						this.setState({ medicalCredits: items.available/leaveHours.time});
					}
				
				})
			}
		}).catch((err) => { console.log('get leave credits error occured') })
 		
    }
	
	
	render() {
        // const vacationLeave = this.getVacationLeave();
        // const medicalLeave = this.getMedicalLeave();
		// const getOverTimeRequest = this.getOverTimeRequest();
		// const getUndertimeRequest = this.getUndertimeRequest();
		// const getEmployeeConcern = this.getEmployeeConcern();
		// const getTechnicalSupport = this.getTechnicalSupport();

		const { render } = this.props;
		return (
			<React.Fragment>
				{render === "dashboard" ? 
					<div id="request-counter">
						<div className="row">
							<h6 style={{fontWeight: '600', fontSize: '16px', color: '#4b515D', paddingBottom: '10px', marginLeft: '7px'}}>Employee Self Service</h6>
							<Link to="employee-self-service/request/vacation-leave" style={{color: 'inherit'}}>
							<div className="col s12 m6 l4" style={{padding: '0 8px'}}>
								<div id="leave">
									<img src={sun} alt="vacation-leave"/>
									<p>Vacation Leave</p>
								<div className="counter">
									<p className="approvedCount">{twoDigitsFormatter(this.state.vacationCredits)}</p>
								</div>
								</div>
							</div>
							</Link>
							<Link to="employee-self-service/request/sick-leave" style={{color: 'inherit'}}>
							<div className="col s12 m6 l4" style={{padding: '0 8px'}}>
								<div id="medical-leave">
									<img src={plus} alt="medical-leave" />
									<p>Medical / Sick Leave</p>
								<div className="counter">
									<p className="approvedCount">{twoDigitsFormatter(this.state.medicalCredits)}</p>
								</div>
								</div>
							</div>
							</Link>
							<Link to="employee-self-service/request/overtime" style={{color: 'inherit'}}>	
							<div className="col s12 m6 l4" style={{padding: '0 8px'}}>
								<div id="overtime-request" style={{paddingTop:'15px', paddingBottom:'15px'}}>
									<img src={clock} alt="overtime-request"/>
									<p>Overtime Request</p>
								</div>
							</div>
							</Link>
						</div>
						<div className="row">
							<Link to="employee-self-service/request/employment-concern" style={{color: 'inherit'}}>
								<div className="col s12 m6 l4" style={{padding: '0 8px'}}>
									<div id="overtime-request" style={{paddingTop:'15px', paddingBottom:'15px'}}>
										<img src={briefcase} alt="overtime-request"/>
										<p>Employee Concern</p>
									</div>
								</div>
							</Link>
							<Link to="employee-self-service/request/technical-support" style={{color: 'inherit'}}>
								<div className="col s12 m6 l4" style={{padding: '0 8px'}}>
									<div id="overtime-request" style={{paddingTop:'15px', paddingBottom:'15px'}}>
										<img src={zap} alt="overtime-request"/>
										<p>Technical Support</p>
									</div>
								</div>
							</Link>	
							<Link to="employee-self-service/request/feedback" style={{color: 'inherit'}}>
								<div className="col s12 m6 l4" style={{padding: '0 8px',}}>
									<div id="overtime-request" style={{paddingTop:'25px', paddingBottom:'25px'}}>
										<img src={feedback} alt="feedback"/>
										<p>Feedback</p>
									</div>
								</div>
							</Link>		
						</div>
										
					</div>
					
					// 	{/*
					// 	<Desktop>
					// 	<div className="row widgetSub style={{marginTop: '30px'}}">
					// 	<Link to="employee-self-service" style={{color: 'inherit'}}>
					// 		<div className="card col s2 z-depth-0" id="employmentConcern">
					// 		<img src={briefcase} className="self-service-icon" alt="employmentConcern" />
					// 		<p>Employment Concern</p>
					// 		</div>
                    //     	</Link>
                    //    	 	<Link to="employee-self-service" style={{color: 'inherit'}}>
					// 		<div className="card col s2 z-depth-0" id="technicalSupport">
					// 		<img src={zap} className="self-service-icon" alt="technicalSupport"/>
					// 		<p>Technical Support</p>
					// 		</div>
                    //     	</Link>
                    //     	<Link to="employee-self-service" style={{color: 'inherit'}}>
					// 		<div className="card col s2 z-depth-0" id="requestforCOE">
					// 		<img src={briefcase} className="self-service-icon" alt="certificateOfEmployment"/>
					// 		<p>Request for <br/>COE</p>
					// 		</div>
					// 		</Link>
					// 		</div>
					// 	</Desktop>
						
					// <div className="row widgetSub style={{marginTop: '30px'}}">
					// 	<Tablet>
					// 	<div className="row" style={{paddingLeft: '50px'}}>
					// 	<Link to="employee-self-service" style={{color: 'inherit'}}>
					// 		<div className="card col s3 z-depth-0" id="vacationLeave">
					// 			<img src={sun} className="self-service-icon" alt="sun"/>
					// 			<p>
					// 				Vacation <br />Leave
					// 			</p>
					// 			<div className="selfServiceCount">
					// 				<p className="pendingCount">{vacationLeave.pendingCount}</p>
					// 				<p className="approvedCount">{vacationLeave.approvedCount}</p>
					// 				<p className="rejectedCount">{vacationLeave.rejectedCount}</p>
					// 			</div>
					// 		</div>
					// 	</Link>
                    //     <Link to="employee-self-service" style={{color: 'inherit'}}>
					// 	<div className="card col s3 z-depth-0" id="medicalLeave">
					// 		<img src={plus} className="self-service-icon" alt="plus" />
					// 		<p>
					// 			Medical / Sick <br />Leave
					// 		</p>
					// 		<div className="selfServiceCount">
					// 			<p className="pendingCount">{medicalLeave.pendingCount}</p>
					// 			<p className="approvedCount">{medicalLeave.approvedCount}</p>
					// 			<p className="rejectedCount">{medicalLeave.rejectedCount}</p>
					// 		</div>
					// 	</div>
                    //     </Link>
                    //     <Link to="employee-self-service" style={{color: 'inherit'}}>
					// 	<div className="card col s3 z-depth-0" id="overtimeRequest">
					// 		<img src={clock} className="self-service-icon" alt="undertime" />
					// 		<p>
					// 			Overtime <br />Request
					// 		</p>
					// 		<div className="selfServiceCount">
					// 			<p className="pendingCount">{getOverTimeRequest.pendingCount}</p>
					// 			<p className="approvedCount">{getOverTimeRequest.approvedCount}</p>
					// 			<p className="rejectedCount">{getOverTimeRequest.rejectedCount}</p>
					// 		</div>
					// 	</div>
                    //     </Link>
					// 	</div>
					// 	<div style={{paddingLeft: '50px'}}>
					// 	{show === true ?
					// 		<React.Fragment>
					// 		<Link to="employee-self-service" style={{color: 'inherit'}}>
					// 		<div className="card col s2 z-depth-0" id="employmentConcern">
					// 		<img src={briefcase} className="self-service-icon" alt="employmentConcern" />
					// 		<p>Employment Concern</p>
					// 		</div>
                    //     	</Link>
                    //    	 	<Link to="employee-self-service" style={{color: 'inherit'}}>
					// 		<div className="card col s2 z-depth-0" id="technicalSupport">
					// 		<img src={zap} className="self-service-icon" alt="technicalSupport"/>
					// 		<p>Technical Support</p>
					// 		</div>
                    //     	</Link>
                    //     	<Link to="employee-self-service" style={{color: 'inherit'}}>
					// 		<div className="card col s2 z-depth-0" id="requestforCOE">
					// 		<img src={briefcase} className="self-service-icon" alt="certificateOfEmployment"/>
					// 		<p>Request for <br/>COE</p>
					// 		</div>
					// 		</Link>
					// 		<div className="col s7"style={{marginTop: '10px', cursor: 'pointer'}}>
					// 		<p className="info left" onClick={this.showLess}>Show Less...</p>
					// 		</div>
					// 		</React.Fragment>
					// 	: <div style={{marginTop: '10px', cursor: 'pointer'}}><p className="info left" onClick={this.showMore}>Show More...</p></div>
					// 	}
					// </div>
					// 	</Tablet>
					// </div>
					// */}
			
				: 
				<div className='container' style={{width: '70%',margin:'unset'}}>
					<div className="row">
						<h6 style={{ fontSize: '16px', fontWeight: '600', color: '#4b515D', paddingBottom: '20px' }}>
							Employee Self Service
						</h6>
					<Link to="employee-self-service/request/vacation-leave" style={{color: 'inherit'}}>
					<div className="col s12 m6 l3" style={{ textAlign: 'center'}} disabled={this.state.disabled}>
						<div style={{marginBottom: '10px'}} className="counter-border">
							<div id="vacationLeave">
								<img src={sun} alt="vacationLeave"/>
								<p>Vacation <br/> Leave</p>
							<div className="counter">
								<p className="approvedCount">{twoDigitsFormatter(this.state.vacationCredits)}</p>
							</div>
							</div>
						</div>
					</div>
					</Link>
					<Link to="employee-self-service/request/sick-leave" style={{color: 'inherit'}}>
					<div className="col s12 m6 l3" style={{textAlign: 'center'}} disabled={this.state.disabled}>
						<div style={{marginBottom: '10px'}} className="counter-border">
						<div id="med-leave">
							<img src={plus} alt="medLeave"/>
							<p> Medical/Sick <br/>  Leave </p>
						<div className="counter">
							<p className="approvedCount">{twoDigitsFormatter(this.state.medicalCredits)}</p>
						</div>
						</div>
						</div> 
					</div>
					</Link>
					<Link to="employee-self-service/request/overtime" style={{color: 'inherit'}}>
					<div className="col s12 m6 l3" style={{textAlign: 'center'}} disabled={this.state.disabled}>
						<div style={{marginBottom: '10px'}} className="counter-border">
							<div id="overtimeRequest">
								<img src={clock} alt="overtimeRequest"/>
								<p>Overtime <br/>Request</p>
							</div>
						</div>
					</div>
					</Link>
					<Link to="employee-self-service/request/undertime" style={{color: 'inherit'}}>
					<div className="col s12 m6 l3" style={{textAlign: 'center'}} disabled={this.state.disabled}>
						<div style={{marginBottom: '10px'}} className="counter-border">
							<div id="undertimeRequest">
								<img src={clock} alt="undertime"/>
								<p>Undertime <br/>Request</p>
							</div>
						</div>
					</div>
					</Link>
					</div>
					<div className="row">
						<Link to="employee-self-service/request/employment-concern" style={{color: 'inherit'}}>
						<div className="col s12 m6 l3" style={{textAlign: 'center'}} disabled={this.state.disabled}>
							<div style={{marginBottom: '10px'}} className="counter-border">
								<div id="employeeCon">
									<img src={briefcase} alt="empConcern"/>
									<p>Employment <br/> Concern</p>
								
								</div>
							</div>
						</div>
						</Link>
						<Link to="employee-self-service/request/technical-support" style={{color: 'inherit'}}>
						<div className="col s12 m6 l3" style={{textAlign: 'center'}} disabled={this.state.disabled}>
							<div style={{marginBottom: '10px'}} className="counter-border">
								<div id="tech-sup">
									<img src={zap} alt="techSupport"/>
									<p>Technical <br/>Support</p>
								</div>
							</div>
						</div>
						</Link>
						<Link to="employee-self-service/request/certificate-of-employment" style={{color: 'inherit'}}>
						<div className="col s12 m6 l3" style={{textAlign: 'center'}} disabled={this.state.disabled}>
							<div style={{marginBottom: '10px'}} className="counter-border">
								<div id="req-COE">
									<img src={briefcase} alt="coe"/>
									<p>Request for <br/> COE</p>
								</div>
							</div>
						</div>
						</Link>
						<Link to="employee-self-service/request/feedback" style={{color: 'inherit'}}>
						<div className="col s12 m6 l3" style={{textAlign: 'center'}} disabled={this.state.disabled}>
							<div style={{marginBottom: '20px'}} className="counter-border">
								<div id="others">
									<div>
									<img src={feedback} alt="feedback"/>
									</div>
									<p>Feedback</p>
								</div>
							</div>
						</div>
						</Link>
					</div>
				</div>
				

				}
				
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	const { users, leaveRequest, roles, leaveTypes } = state;
	return {
		users,
		leaveRequest,
		roles,
		leaveTypes
	};
}

const selfService = connect(mapStateToProps)(SelfServiceWidget);
export default selfService;