import { get, post } from '../helpers/api';
import { userService } from '../services/user';

export const commentService = {
    postComment, //POST ONE COMMENT
    getAll, //GET ALL COMMENTS
    getById, //GET ONE COMMENT
    getByOrigin, //GET COMMENTS BY ONE POST/COMMENT(ORIGIN)
    uploadAttachFile
};

function postComment(comment) {
    //console.log(comment)
    //return post('/api/v1/comment', JSON.stringify(comment)).then(handleResponse)
    
    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
     const imageFile = comment.attachFile;
     const fd = new FormData();
     fd.append('file',imageFile)
    
    // //checking if post images has a content file
    if(isFile(comment.attachFile) === true){
        comment.attachFile = null;
    }

    if(isFile(imageFile)){
        return post('/api/v1/comment', JSON.stringify(comment))
        .then(handleResponse)
        .then(response => {
            uploadAttachFile(response._id,fd);
        });
    }else{
        return post('/api/v1/comment', JSON.stringify(comment)).then(handleResponse)
    }

}

function getAll(page = 1, itemsPerPage = 999999) {
    return get(`/api/v1/comment?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}

function getById(id) {
    return get(`/api/v1/comment/${id}`).then(handleResponse);
}

function getByOrigin(id, page = 1, itemsPerPage = 20) {
    return get(`/api/v1/comment/origin/${id}?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}  

function uploadAttachFile(id, image) {
    return post(`/api/v1/upload/${id}/comment`, image).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}