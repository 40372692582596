import React from "react";
import cc from "classcat";
import DocumentTitle from 'react-document-title';
import { connect, useDispatch } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch, useParams } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import moment from "moment";
import { Switch as SwitchCase, Case, Default } from 'react-if';
import { convertToRaw } from 'draft-js';

import { history } from "../../../_core/helpers/history";
import questionnaireActions from "../../../_core/actions/questionnaire";
import questionActions from "../../../_core/actions/question";
import { sectionActions } from '../../../_core/actions/section';

import EditorComponent from '../../Components/Editor/EditorComponent';
import { PageWrapper, ContentWrapper, LabelCustom, InputTextCustom, SaveAndCancelButton } from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent, DynamicContentHeaderWrapper, DynamicTableAndPaginationWrapper } from "../../Components/PageWrapper/DynamicWrapper";
import { DynamicTableAction } from "../../Components/tableActions/tableActions";
import { WarningPopup } from '../../Components/AdminModals/WarningPopup';
import { QuestionList } from './QuestionPage';

import { QuestionCreateAndEdit } from './QuestionPage'

import emptyIcon from '../../../_templates/empty.png';

const CreateEditQuestionnaire = (props) => {
  const dispatch = useDispatch();
  const [questionnaire, setQuestionnaire] = React.useState({
    title: "",
    purpose: "",
    type: "evaluation",
  });

  const [inputError, setInputError] = React.useState(false);
  const [questionSections, setQuestionSections] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [isAdd, setIsAdd] = React.useState(true);
  const [limit, setLimit] = React.useState(1);
  const { pageId } = useParams(); //get id in url

  let questionnaireById = props?.questionnaires?.dataById;

  React.useEffect(() => {

    if (pageId && limit === 1) { // edit
      setIsAdd(false);
      setLimit(0); //to avoid multiple request

      const getQuestionnaire = props?.questionnaires?.list?.filter(item => item._id === pageId) || [];

      if (getQuestionnaire?.length > 0) {
        setQuestionnaire({
          _id: getQuestionnaire[0]._id,
          title: getQuestionnaire[0].title,
          purpose: getQuestionnaire[0].purpose,
        });
      }

      dispatch(questionnaireActions.getById(pageId));
      dispatch(questionnaireActions.getAllQuestions(pageId));
    }

    if (props?.getQuestionnaireDraft?.title || props?.getQuestionnaireDraft?.purpose) { // get prev value from state
      setQuestionnaire(props?.getQuestionnaireDraft);
      props.emptyQuestionnaire(true);

    } else if (questionnaireById?.length > 0 && !questionnaire._id) { // if received data from api
      const data = questionnaireById[0];
      setQuestionnaire({
        _id: data._id,
        title: data.title,
        purpose: data.purpose,
      });
    }

  }, [pageId, props, dispatch, limit, questionnaire, isAdd, questionnaireById]);

  const getContent = React.useCallback((content) => {
    const isEditorStateEmpty = content ? !content.getCurrentContent().hasText() : true;

    setQuestionnaire({
      ...questionnaire,
      purpose: isEditorStateEmpty ? "" : JSON.stringify(convertToRaw(content.getCurrentContent())),
    });

  }, [questionnaire]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setQuestionnaire({
      ...questionnaire,
      [name]: value,
    });
  };

  const submitQuestionnaire = async () => {
    if (!questionnaire.title || !questionnaire.purpose) {
      setInputError(true);

    } else {
      if (isAdd) {
        await dispatch(questionnaireActions.create(questionnaire, questions));
      } else {
        await dispatch(questionnaireActions.update(questionnaire, questionnaire._id, questions));

        let dataFromProps = props?.getQuestionsFromProps;
        let getSubmittedQuestions = questions;
        let filterDeletedQuestions = dataFromProps?.filter((item) => !getSubmittedQuestions?.includes(item));

        if (filterDeletedQuestions?.length > 0) {
          await filterDeletedQuestions.map((item) => {
            dispatch(questionActions.remove(item._id, item.questionnaire_id));
            return null
          });
        }

      }
    }
  };

  const [receivedQuestionsFromProps, setReceivedQuestionsFromProps] = React.useState(false);

  const getQuestions = React.useCallback(() => {

    let newQuestion = props.newQuestionAdded;
    let dataSaved = questions.length > 0 ? questions : props.getQuestionsDraft;
    let dataFromProps = props?.questionnaires?.questions;
    let modifyData = dataSaved;
    let returnedData = [];

    if (props.getQuestionsFromProps.length <= 0 && dataFromProps?.length > 0 && !receivedQuestionsFromProps && pageId === dataFromProps[0].questionnaire_id) {
      let filteredDataFromProps = dataSaved?.length > 0 ? dataFromProps?.filter(item => !dataSaved.some(i => i._id === item._id)) : dataFromProps;
      modifyData.push(...filteredDataFromProps);
      setReceivedQuestionsFromProps(true);
      props.passQuestionsFromProps(dataFromProps);
    }

    if (newQuestion[0]) {
      modifyData.push(...newQuestion);
      props.removeNewQuestion(true);
    }

    returnedData = modifyData;

    return returnedData;

  }, [props, receivedQuestionsFromProps, questions, pageId]);

  const addingItemHandler = (status) => {
    if (status) {
      props.createQuestionnaireDraft(questionnaire);
      props.createQuestionsDraft(questions);
    }
  }
  const UpdateItemHandler = (status) => {
    if (status) {
      props.createQuestionnaireDraft(questionnaire);
    }
  }

  React.useEffect(() => {
    if (props.mode !== "list") {
      let getQuestionList = getQuestions();
      setQuestions(getQuestionList);
    }

    if (props?.section?.sectionList?.length > 0 && questionSections?.length <= 0) { // get questions category/section list
      setQuestionSections(props?.section?.sectionList);
    }
  }, [props, getQuestions, questionSections]);

  const getSelectedEditQuestion = (data) => {
    // Step 2: Pass questions data to edit question page
    props.selectedEditQuestionStep2(data);
  }


  const questionsAfterDelete = (result) => {
    const handleResult = result;

    if (handleResult?.length <= 0) {
      let removeLastItem = questions;
      removeLastItem.splice(-1, 1); //weird
      setQuestions([]);

    } else {
      setQuestions(handleResult);
      props.createQuestionsDraft(handleResult);
    }
  }

  const handleChangeSection = (sectionItem) => {
    const questionsToFilter = questions;

    if (questionsToFilter?.length > 0) {
      const updatedQuestions = questionsToFilter.map((item, i) => {
        if (item._id === sectionItem.question_id || i === sectionItem.index) {
          item.section_id = sectionItem.section_id;
        }
        return item
      });
      setQuestions(updatedQuestions);
      props.createQuestionsDraft(updatedQuestions);
    }
  }

  return (
    <>
      <LabelCustom className="main-label">{isAdd ? "Create" : "Edit"} a Questionnaire</LabelCustom>
      <InputTextCustom
        placeholder={"Title"}
        name={"title"}
        value={questionnaire.title}
        inputOnChange={handleChange}
        isError={!questionnaire.title && inputError}
      />
      <LabelCustom className="sub-label">Purpose</LabelCustom>
      <EditorComponent
        returnContent={getContent}
        initialValue={questionnaire.purpose}
        isEdit={!isAdd}
        isError={!questionnaire.purpose && inputError}
      />
      <QuestionList
        data={questions}
        id={questionnaire?._id || ""}
        sections={questionSections}
        selectedEditQuestions={getSelectedEditQuestion}
        isAdd={isAdd}
        isAddingQuestion={addingItemHandler}
        isUpdatingQuestion={UpdateItemHandler}
        questionsOnDelete={questionsAfterDelete}
        questionsReordered={items => {
          setQuestions(items);
          props.createQuestionsDraft(items);
        }}
        sectionOnChange={handleChangeSection}
      />
      <SaveAndCancelButton
        cancelFn={() => {
          history.push(`/admin/questionnaires?page=${props?.questionnaires?.currentPage || 1}`);
          props.cancelAddQuestions(true);
        }}
        saveFn={submitQuestionnaire}
      />
    </>
  );
}


const QuestionnaireList = (props) => {
  const dispatch = useDispatch();
  const [entriesValues, setentriesValues] = React.useState(); // eslint-disable-line

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  let tableHeader = (editPermission.includes("Appraisal - Questionnaires ") === true) || (deletePermission.includes("Appraisal - Questionnaires ") === true) || (roleAllTrue === 1) ? ["Title", "Questions", "Purpose", "Type", "Updated", "Actions"] : ["Title", "Questions", "Purpose", "Type", "Updated", ""];

    //showEntries
    const entriesValue = (entries) => {
      setentriesValues(entries);
    }

  const convertToTextOnly = (content) => {

    const getValue = () => {
      var getContent = content;

      try {
        getContent = JSON.parse(content)?.blocks && JSON.parse(content)?.blocks[0]?.text
      } catch (e) {
        getContent = content
      }

      return getContent.substr(0, 20) + (getContent.length > 20 ? "..." : "");
    }

    return getValue();
  };

  const deleteQuestionnaire = (id) => {
    const message = "Are you sure you want to delete questionnaire?";

    WarningPopup("warning", message, true, true).then((res) => {
      if (res?.value) {
        const itemsInPage = props?.questionnaires?.list?.length;
        const remainingItemsInPage = itemsInPage - 1;
        const currentPage = props?.questionnaires?.currentPage;
        const itemsPerPage = props?.questionnaires?.itemsPerPage; // get from questionnaire action to reducer

        if (remainingItemsInPage > 0) {
          //call api with current page
          dispatch(questionnaireActions.removeInList(id, currentPage, itemsPerPage))
        } else {
          //call previous page
          const prevPage = (currentPage - 1) <= 0 ? 1 : currentPage;
          dispatch(questionnaireActions.removeInList(id, prevPage, itemsPerPage));
        }
      }
    });

  }

  const updateQuestionnaire = (item) => {
    history.push(`/admin/questionnaires/${item._id}/edit`);
  }

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

  const QuestionnairesData = () => {
    let data = props.questionnaires?.list ?? [];

    const editPermission = JSON.parse(localStorage.getItem('editPermission'));
    const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

    console.log(props.questionnaires)

    return (
      <>

        {
            props.questionnaires.loading !== false ?
                <>
                    <tr className="table-td-data">
                    <td> 
                        <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                            <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </div>
                    </td>
                    </tr>
                </>
            :
            data.length !== 0 ? (
                data.map((item) => (
                  <tr key={item._id}>
                    <td style={{ width: "400px" }}>{item.title}</td>
                    <td>{item.totalQuestions}</td>
                    <td>{convertToTextOnly(item.purpose)}</td>
                    <td>{item.type}</td>
                    <td>{moment(item.updatedAt || item.createdAt).fromNow()}</td>
                    {(editPermission.includes("Appraisal - Questionnaires ") === true) || (deletePermission.includes("Appraisal - Questionnaires ") === true) || (roleAllTrue === 1) ? 
                        <td className={secretDetails !== null ? "disabled-link" : '' }>
                          <div>
                            <DynamicTableAction
                              originPage="Questionnaires"
                              iconSet="2"
                              editLabel="Edit Questionnaire"
                              deleteLabel="Delete Questionnaire"
                              onClickEdit={() => updateQuestionnaire(item)}
                              onClickDelete={() => deleteQuestionnaire(item._id)}
                              deletePermission="Appraisal - Questionnaires "
                              editPermission="Appraisal - Questionnaires "
                            />
                          </div>
                        </td> : false
                    }
                  </tr>
                ))
              ) : (
                    <>
                        <tr>
                            <td colSpan="12" style={{textAlign:'center'}}>
                                <img src={emptyIcon} className="empty" alt="empty"/>
                                <p style={{fontSize:'16px'}}>No Data Available</p>
                                <p style={{fontSize:'14px'}}>There are currently no data available</p>
                            </td>
                        </tr>
                    </>
                )
        }
        
      </>
    );
  };

  return (
    <>
      <DynamicContentHeaderWrapper
        buttonLabel="ADD QUESTIONNAIRE"
        btnPath="/admin/questionnaires/create"
        entriesValue={entriesValue}
        originPage="Questionnaires"
        addPage="Appraisal - Questionnaires "
      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={QuestionnairesData()}
        originPage="Questionnaires"
        data={props.questionnaires}
        location={props.location}
        paginationPath={"/admin/questionnaires"}
        entriesItem={entriesValues}
      />
    </>
  );
};

const mapStateToProps = ({ questionnaires, section, question }) => ({
  questionnaires,
  section,
  question
});

const QuestionnairePage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";
  const [breadCrumbs, setBreadCrumbs] = React.useState([]);

  const [questionnaireDraft, setQuestionnaireDraft] = React.useState({});
  const [selectedEditQuestion, setSelectedEditQuestion] = React.useState([]);
  const [questionsDraft, setQuestionsDraft] = React.useState([]);
  const [newQuestionToAdd, setNewQuestionToAdd] = React.useState([]);
  const [questionsFromProps, setQuestionsFromProps] = React.useState([]);

  const { questionnairePageId, pageId } = useParams(); // Page id. from edit question to edit questionnaire

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Questionnaires", link: "/admin/questionnaires?page=1" },
        ]);
        break;
      case "create":
        setBreadCrumbs([
          { label: "Questionnaires", link: "/admin/questionnaires?page=1" },
          { label: "Create Questionnaire", link: "#" },
        ]);
        break;
      case "edit":
        setBreadCrumbs([
          { label: "Questionnaires", link: "/admin/questionnaires?page=1" },
          { label: "Edit Questionnaire", link: "#" },
        ]);
        break;
      case "create_question":
        setBreadCrumbs([
          { label: "Questionnaires", link: "/admin/questionnaires?page=1" },
          { label: "Create Questionnaire", link: "/admin/questionnaires/create" },
          { label: "Create Question", link: "#" },

        ]);
        break;
      case "create_questionnaire_edit_question":
        setBreadCrumbs([
          { label: "Questionnaires", link: "/admin/questionnaires?page=1" },
          { label: "Create  Questionnaire", link: `/admin/questionnaires/create` },
          { label: "Edit Question", link: "#" },
        ]);
        break;
      case "edit_questionnaire_edit_question":
        setBreadCrumbs([
          { label: "Questionnaires", link: "/admin/questionnaires?page=1" },
          { label: "Edit Questionnaire", link: `/admin/questionnaires/${questionnairePageId}/edit` },
          { label: "Edit Question", link: "#" },
        ]);
        break;
      case "edit_questionnaire_create_question":
        setBreadCrumbs([
          { label: "Questionnaires", link: "/admin/questionnaires?page=1" },
          { label: "Edit Questionnaire", link: `/admin/questionnaires/${pageId}/edit` },
          { label: "Create Question", link: "#" },
        ]);
        break;
      default:
        break;
    }
  }

  const dispatch = useDispatch();

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
    dispatch(sectionActions.getAllSection(1, 1000));

    if (props.mode === "list" || props?.questionnaire?.message) {
      removeItem(true);
      emptyQuestionList(true);
    }

  }, [props.mode, dispatch, props?.questionnaire?.message]); //eslint-disable-line

  const newEditedListQuestions = React.useCallback((questionItem) => {
    // console.log(questionItem) // Weird??
  }, []); //eslint-disable-line

  const newQuestion = React.useCallback((questionItem) => {
    setNewQuestionToAdd([questionItem]);
  }, []); //eslint-disable-line

  const removeItem = (status) => {
    if (status) {
      setQuestionsDraft([]);
      setQuestionnaireDraft({});
      setNewQuestionToAdd([]);
      setQuestionsFromProps([]);
    }
  }

  const emptyQuestionList = (status) => {
    if (status) {
      setQuestionsDraft([]);
    }
  }

  const emptyQuestionnaire = (status) => {
    if (status) {
      setQuestionnaireDraft([]);
    }
  }

  const saveQuestionnaireDraft = (draft) => {
    setQuestionnaireDraft(draft);
  }

  const saveQuestionDraft = (result) => {
    setQuestionsDraft(result);
  }

  const removeNewQuestion = (status) => {
    if (status) {
      setNewQuestionToAdd([]);
    }
  }

  const handleGetSelectedEditQuestions = (questionData) => {
    // Step 3: pass questions data to edit question page
    setSelectedEditQuestion(questionData);
  }

  const getQuestionsFromProps = (item) => {
    setQuestionsFromProps(item);
  }

  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Questionnaire | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Appraisal Management | Questionnaire | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : `Edit Questionnaire | ${process.env.REACT_APP_DOCUMENT_TITLE}`
        }
      >
        <PageWrapper
          className={cc(["QuestionnairePage", checkSideNav()?.trim()])}
        >
          <DynamicHeaderContent
            TitlePage="Appraisal"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <ContentWrapper>
            <SwitchCase>
              <Case condition={["create", "edit"].includes(props.mode)}>
                <CreateEditQuestionnaire
                  {...props}
                  filterSection={props?.section}
                  cancelAddQuestions={removeItem}
                  getQuestionsDraft={questionsDraft}
                  createQuestionnaireDraft={saveQuestionnaireDraft}
                  getQuestionnaireDraft={questionnaireDraft}
                  emptyQuestionList={emptyQuestionList}
                  selectedEditQuestionStep2={handleGetSelectedEditQuestions}
                  createQuestionsDraft={saveQuestionDraft}
                  newQuestionAdded={newQuestionToAdd}
                  removeNewQuestion={removeNewQuestion}
                  emptyQuestionnaire={emptyQuestionnaire}
                  passQuestionsFromProps={getQuestionsFromProps}
                  getQuestionsFromProps={questionsFromProps}
                />
              </Case>
              <Case
                condition={[
                  "create_question",
                  "edit_questionnaire_edit_question",
                  "create_questionnaire_edit_question",
                  "edit_questionnaire_create_question",
                ].includes(props.mode)}
              >
                <QuestionCreateAndEdit
                  {...props}
                  createdQuestion={newQuestion}
                  updatedQuestion={newEditedListQuestions}
                  getSelectedEditQuestionsStep3={selectedEditQuestion}
                />
              </Case>
              <Default>
                <QuestionnaireList {...props} />
              </Default>
            </SwitchCase>
          </ContentWrapper>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});

QuestionnairePage.WrappedComponent.displayName = "QuestionnairePage";
const QuestionnairePageRoute = (props) => (
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <QuestionnairePage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <QuestionnairePage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <QuestionnairePage {...props} mode="edit" />}
    />
    <Route
      exact
      path={`${props.match.path}/question/create`}
      render={(props) => <QuestionnairePage {...props} mode="create_question" />}
    />
    <Route
      exact
      path={`${props.match.path}/:questionnairePageId/question/:questionOrder/edit`}
      render={(props) => <QuestionnairePage {...props} mode="edit_questionnaire_edit_question" />}
    />
      <Route
      exact
      path={`${props.match.path}/:pageId/question/create`}
      render={(props) => <QuestionnairePage {...props} mode="edit_questionnaire_create_question" />}
    />
    <Route
      exact
      path={`${props.match.path}/question/:questionOrder/edit`}
      render={(props) => <QuestionnairePage {...props} mode="create_questionnaire_edit_question" />}
    />
  </Switch>
);

export default React.memo(QuestionnairePageRoute);


