export const attributeConstants = {
    CREATE_REQUEST: 'ATTRIBUTES_CREATE_REQUEST',
    CREATE_SUCCESS: 'ATTRIBUTES_CREATE_SUCCESS',
    CREATE_FAILURE: 'ATTRIBUTES_CREATE_FAILURE',

    GETALL_REQUEST: 'ATTRIBUTES_GETALL_REQUEST',
    GETALL_SUCCESS: 'ATTRIBUTES_GETALL_SUCCESS',
    GETALL_FAILURE: 'ATTRIBUTES_GETALL_FAILURE',

    ATTRIBUTE_DEL_REQUEST: 'ATTRIBUTE_REQUEST_DELETE',
    ATTRIBUTE_DEL_SUCCESS: 'ATTRIBUTE_SUCCESS_DELETE',
    ATTRIBUTE_DEL_FAILURE: 'ATTRIBUTE_FAILURE_DELETE',

    DELETE_REQUEST: 'ATTRIBUTES_DELETE_REQUEST',
    DELETE_SUCCESS: 'ATTRIBUTES_DELETE_SUCCESS',
    DELETE_FAILURE: 'ATTRIBUTES_DELETE_FAILURE' ,

    GET_REQUEST: 'ATTRIBUTES_GET_REQUEST',
    GET_SUCCESS: 'ATTRIBUTES_GET_SUCCESS',
    GET_FAILURE: 'ATTRIBUTES_GET_FAILURE', 

    UPDATE_ATTRIBUTE_REQUEST: 'ATTRIBUTES_UPDATEATTRIBUTES_REQUEST',
    UPDATE_ATTRIBUTE_SUCCESS: 'ATTRIBUTES_UPDATEATTRIBUTES_SUCCESS',
    UPDATE_ATTRIBUTE_FAILURE: 'ATTRIBUTES_UPDATEATTRIBUTES_FAILURE',   

};

