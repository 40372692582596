const reg_token = process.env.REACT_APP_TIMEOUT_REG_TOKEN;
const refresh_token = process.env.REACT_APP_TIMEOUT_REFRESH_TOKEN;
const regularization_date = process.env.REACT_APP_HRM_REGULARIZATION_DATE;
const coreUrl = process.env.REACT_APP_CORE_URL;
const leaveTime = process.env.REACT_APP_LEAVE_TIME;

export let activationTime = {
  'regularToken': reg_token,
  'refreshToken': refresh_token,
}

export let hrm = {
  'regularizationDate': regularization_date
}

export let pragmaCore = {
  'core': coreUrl
}

export let randomTips = {
  'tip': [
    'Clean your hands often. Use soap and water, or an alcohol-based hand rub.',
    'Do not forget to eat breakfast and choose a nutritious meal with more protein and fiber and less fat, sugar, and calories.',
    'Drink water regularly to stay healthy.',
    'Fruits and vegetables are among the most important foods for giving us enough vitamins, minerals and fibre.',
    'Physical activity is important for people of all weight ranges and health conditions.',
    'Regular exercise can help increase self-esteem and self-confidence, decrease stress and anxiety, enhance mood, and improve general mental health.',
    'Be kind to yourself and acknowledge that you might not be as productive as you usually would be.',
    'Be realistic about what you can achieve given the circumstances, and relax when your work is done.',
    'Making time for breaks is important to help manage feelings of stress.',
    'Stay calm. No matter how pissed off you are, gather yourself first.'
  ] 
}

export let leaveHours = {
  'time': leaveTime
}