import React from 'react';

class EmailTemplate extends React.Component {

  
    render() {
      // const noPM = {
      //   margin: '0',
      //   padding: '0'
      // }
      const bodyStyle = {
        width: '500px',
        margin: 'auto',
        padding: '0'
      }
      const headerStyle = {
        height: '100px',
        textAlign: 'center'
      }
      const imgStyle = {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '40px',
        height: '55px',
        margin: '25px'

      }

      const mainContent = {
        backgroundColor: '#424242',
        height: '400px',
        position: 'relative'
      }

      const messageStyle = {
        textAlign: 'center',
        paddingTop: '10px',
        margin: '0',
        color: 'white'
      }
      const titleStyle = {
        textAlign: 'center',
        paddingTop: '80px',
        margin: '0',
        color: 'white',
        fontWeight: '900'
      }

      const footerStyle = {
        textAlign: 'center',
        padding: '20px'
      }
      const footerText = {
        margin: '0',
        color: '#424242',
        fontSize: '10px'
      }
      const social = {
        paddingTop: '120px'
      }
      const socialStyle = {
        textAlign: 'center',
        margin: '0',
        color: 'white',
        fontWeight: '900'
      }
   
      
      const socialMedia = {
        height: '35px',
        width: '35px',
        padding: '5px'
      }



      const rocketBG = {
        backgroundSize: '150px 128px',
        backgroundRepeat: 'no-repeat',
        borderRadius: '0px'
      }

      const alignCenter = {
        textAlign: 'center'
      }

       const marginTop = {
         marginTop: '10px',
         borderCollapse: 'inherit'
       }
          return (
          
 
            <div style={bodyStyle}>
              <div style={headerStyle}>
               
                <img 
                style={imgStyle}
                src="http://dev.pragmahr.com/img/logo/white-logo.png"
                alt="logo"/>
              </div>

              <div style={mainContent}>
                <h5 style={titleStyle}>Greetings from PragmaHR</h5>
                <h6 style={messageStyle}>You have successfully changed your password !</h6>
                <div style={social}>
                  <h6 style={socialStyle}>Follow us on</h6>
             
                </div>
                <div>
                  <table height="128px" style={marginTop}>
                  <tbody>
                  <tr>
                      <td width="150px" background="http://dev.pragmahr.com/img/logo/rocket-clouds.png" style={rocketBG}>  </td>
                      <td align="center" style={alignCenter} width="200px">   
                          <a href="https://www.pragmahr.com/"><img style={socialMedia} src='http://dev.pragmahr.com/img/logo/twitter.png' alt='TWITTER'></img></a>
                          <a href="https://www.pragmahr.com/"><img style={socialMedia} src='http://dev.pragmahr.com/img/logo/youtube.png' alt='YOUTUBE'></img></a>
                          <a href="https://www.pragmahr.com/"><img style={socialMedia} src='http://dev.pragmahr.com/img/logo/facebook.png' alt='FACEBOOK'></img></a>
                          <a href="https://www.pragmahr.com/"><img style={socialMedia} src='http://dev.pragmahr.com/img/logo/googleplus.png' alt='GOOGLEPLUS'></img></a> 
                      </td>
                      <td>  </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <div style= {footerStyle}>
                  <p style={footerText}>www.PragmaHR.com</p>
                  <p style={footerText}>Email Sent by PragmaHR.com</p>
                  <p style={footerText}>Copyrights &copy; {new Date().getFullYear()}  Pragmanila Alrights reserved.</p>
              </div>
            </div>
           
          
          )
    }
}

export { EmailTemplate } ;