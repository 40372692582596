import React, { Component } from 'react'

//Mainfooter sass
import "./Mainfooter.sass";

export class Mainfooter extends Component {
    getYear() {
        return new Date().getFullYear();
    }
    render() {
        return (
            <footer className="footer">
                    <div className="row">
                        <div className="col-md-12 text-center"> 
                            {/* <div className="mb-5 d-flex">
                                <a className="footer-links" href="#!"> Terms   </a> 
                                <span> | </span>
                                <a className="footer-links" href="#!"> Privacy  </a> 
                                <span> | </span>
                                <a className="footer-links" href="#!"> Company  </a> 
                                <span> | </span>
                                <a className="footer-links" href="#!"> Developers </a> 
                            </div> */}
                            <div className="footer-text">
                                    <p>&#169; {this.getYear()} PragmaHR and Pragmanila Solutions, Inc.</p>
                            </div>
                        </div>
                    </div>
            </footer>
        )
    }
}

export default Mainfooter
