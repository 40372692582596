import buildUrl from 'build-url';
import { get, post } from '../helpers/api';
import { userService } from '../services/user';

export const postServices = {
    getAllByPage,
    create,
    imageUpload,
    getById
};

function getAllByPage({ page, userId }){
    return get(
        buildUrl('/api/v1/feed', {
            path: userId,
            queryParams: {
                'page': page ? page : 1,
                'items-per-page': 10,
            }
        })
    ).then(handleResponse);
}

function create(posts, image){
    return post('/api/v1/post', JSON.stringify(posts)).then(handleResponse);
}

function imageUpload(id , image){
    //var sample = JSON.parse(id)
    return post(`/api/v1/upload/${id}/post`, image).then(handleResponse);
}

function getById(id){
    return(get(`/api/v1/post/${id}`).then(handleResponse))
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}