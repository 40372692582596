import { postConstants } from '../constants/post';
import { postServices } from '../services/post';
// import { alertActions } from './alert';
import Swal from 'sweetalert2'

export const postActions = {
    getAllByPage,
    create,
    loadMore,
    resetPosts
}

function create(post) {
    const ToastSuccess = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        position: 'center',
        showConfirmButton: false,
        timer: 1500
    });

    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });
    const imageFile = post.postImage;
    const fd = new FormData();

    fd.append('id', post.userId)
    fd.append('file',imageFile)
    post.postImage = null;

    function failure(error) { return { type: postConstants.SUBMIT_POST_FAILURE, error } }
    // function request(post) { return { type: postConstants.SUBMIT_POST_REQUEST, post } }
    function success(post) { return { type: postConstants.SUBMIT_POST_SUCCESS, post } }

    return async dispatch => {
        let createdPost;
        let imageData;
        let isError = null;

        try {
            createdPost = await postServices.create(post);
        } catch(error) {
            isError = error.toString();
            dispatch(failure(error.toString()));
            Toast.fire({
                type: 'error',
                title: `
                    <span>
                        Something went wrong
                    </span>
                `
            });

            return;
        }

        try {
            if( imageFile ){
                imageData = await postServices.imageUpload(createdPost._id, fd)
            }
        } catch(error) {
            isError = error;
            Toast.fire({
                type: 'error',
                title: `
                    <span>
                        Something went wrong
                    </span>
                `
            });

            return;
        }

        dispatch(success({
            ...post,
            postImage: imageData?.postImage ?? null,
            _id: createdPost._id
        }));
        
        if(isError === null){
            await ToastSuccess.fire({
                type: 'success',
                title: '<span style="color:green">Post has been created! </span>'
            })
        }
        
    };
};

function getAllByPage({ page, userId }){
    return dispatch => {
        dispatch(request())

        postServices.getAllByPage({ page, userId })
            .then(
                allPosts => dispatch(success(allPosts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: postConstants.GETALL_POST_REQUEST } }
    function success(allPosts) { return { type: postConstants.GETALL_POST_SUCCESS, allPosts } }
    function failure(error) { return { type: postConstants.GETALL_POST_FAILURE, error } }
}

function loadMore({ page, userId }){
    return dispatch => {
        dispatch(request())

        postServices.getAllByPage({ page, userId })
            .then(
                allPosts => dispatch(success(allPosts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: postConstants.GETALL_POST_REQUEST } }
    function success(posts) { return { type: postConstants.LOAD_MORE_POSTS_SUCCESS, posts } }
    function failure(error) { return { type: postConstants.GETALL_POST_FAILURE, error } }
}

function resetPosts() {
    return { type: postConstants.RESET_POSTS };
}

/*
function create(posts){
    const Toast = Swal.mixin({
        toast: true,
        width: 300,
        height: 500,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });
    var imageFile = posts.postImage;
    var fd = new FormData();
    fd.append('id', posts.userId)
    fd.append('file',imageFile)
    posts.postImage = null
    return dispatch => {
        dispatch(request(posts));
        postServices.create(posts)
            .then(
                post => {
                    postServices.imageUpload(post._id, fd)
                    .then(data => {
                        console.log({
                            _id: post._id,
                            ...posts
                        });
                    });

                    dispatch(success(post));
                    dispatch(alertActions.success('New post successfully created'));
                    setTimeout(() => {
                        Toast.fire({
                            type: 'success',
                            title: '<span style="color:green">Upload Successfully</span>'
                        })
                    }, 6000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    Toast.fire({
                        type: 'error',
                        title: '<span style="color:green">Failed to add Post!</span>'
                    }).then(() => {
                        // history.push('/overtime')
                    })
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };

    function request(post) { return { type: postConstants.SUBMIT_POST_REQUEST, post } }
    function success(post) { return { type: postConstants.SUBMIT_POST_SUCCESS, post } }
    function failure(error) { return { type: postConstants.SUBMIT_POST_FAILURE, error } }

}
 */