import React from "react";
import {useDropzone} from 'react-dropzone';

import { DragDropImage,} from "./DragDropImage";
import attach from '../../_templates/attach-file.png';
import './Dropzone.sass';

const Dropzone = ({ item,clear,page}) => {
   const [files, setFiles] = React.useState([]);
   const [requestFile, setRequestFile] = React.useState({
        attachFile:'',
        content: '',
   });

   const {getRootProps, getInputProps, open} = useDropzone({
       accept: 'image/x-png, image/png, image/jpg, image/jpeg, .pdf, .doc, .docx ,.xls , .xlsx',
       // Disable click and keydown behavior
       noClick: true,
       noKeyboard: true,
       onDrop: acceptedFiles => {
           setFiles(acceptedFiles.map(file => Object.assign(file, {
           preview: URL.createObjectURL(file)
           })));
           setRequestFile({
            ...requestFile,
            attachFile:acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
                }))[0]
          });

       }
   });

   const removeSelectedImage = () => {
       setFiles([]);
       setRequestFile({
        content: "",
        attachFile: ""
    });
   } 

   React.useEffect(() => {
       if (item) {
           item(requestFile);
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [requestFile]);

   React.useEffect(() => {
    if (clear === true) {
        setRequestFile({
            content: "",
            attachFile: ""
        });
        setFiles([]);
        
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [clear]);

const clearInput = () => {
    setRequestFile({
        ...requestFile,
        content: "",
    });
}

   const commentValue = (e) => {
    const code = (e.keyCode ? e.keyCode : e.which);
    
    let inputValue = e.target.value;
    let removeWhitespaces = inputValue.trim()
    if (removeWhitespaces !== '') {
        const text = e.target.value
        setRequestFile({
            ...requestFile,
            content: text
        });
        
        if(code === 13 && !e.shiftKey){
            setRequestFile();
        }
    } else {
        clearInput();
    }
};

 return (    
   <>
       <div {...getRootProps({className: 'attachDropzone'})}  style={page !== 'admin' ? {position:'relative', top:'-5px', overflow:'scroll'}:{position:'relative', top:'-10px', overflow:'auto'}}>
            <textarea 
				className="attach-textarea" 
				placeholder={"Type your reply here" }
				onKeyUp={ (e)=> commentValue(e) }
			    onChange={ (e)=> commentValue(e) }
				value = {requestFile.content}
								// id={'commentForm' + request._id}
                style={page !== 'admin' ? {color:"#000000",overflow:'auto',resize:'none',  paddingBottom:'13px'} : {color:"#FFFFFF", overflow:'auto',resize:'none',  paddingBottom:'13px'}}
			>
			</textarea> 
           <div className={files[0]}>
               <input {...getInputProps()} />
               <img src={attach} height={20} alt="" onClick={open} style={files.length !== 0 ? {right:'28px',top:'16px'}: {right:'28px',top:'15px'}}/>
           </div>
       
       </div>
       <DragDropImage removeImage={removeSelectedImage} selectedImage={files}/>
   </>

 );
}

export default Dropzone;