import React from 'react';
import { Link } from 'react-router-dom';

import ReactTooltip from 'react-tooltip'

import BodyBackgroundColor from 'react-body-backgroundcolor'

import sampleImage from '../_templates/bg-challenge.jpg';

import SideNav from '../Admin/SideNav';

import MaterialIcon from 'material-icons-react';
import './RewardsManagement.sass';
class RewardsManagement extends React.Component {
    render() {
        return (
            <BodyBackgroundColor backgroundColor='#f4f4f4'>
                <div className="rewards-management">
                    <SideNav />
                    <div className="container" id="content">
                        <h6 className="text-left">Rewards Management</h6>
                        <div className="bg-rewards-management">
                            <div className="row">
                                <div className="col s3 m2 l1 xl1">
                                    <Link to="//" className="btn-floating btn-large waves-effect waves-light left" id="add-new"><MaterialIcon icon="add" color="white"/></Link>
                                </div>
                                <div className="col s8 m4 l3 xl2">
                                    <p className="rewards-create"> Create Reward </p>
                                </div>
                                <div className="col s12 m6 xl3 l5">
                                   <div className="input-search">
                                        <i className="fa fa-search fa-lg fa-fw" aria-hidden="true"></i>
                                        <input id="search-user" type="text" placeholder="Search" required/>
                                    </div>
                                </div>
                                <div className="col s12 m4 l3">
                                  <p className="align-left">Active Rewards</p>
                                </div>
                                </div>
                            <hr/>
                            
                            
                                <table className="responsive-table highlight table-rewards">
                                    <thead>
                                        <tr>
                                            <th className="th-pad">ID</th>
                                            <th className="th-pad">Image</th>
                                            <th className="th-pad">Name</th> 
                                            <th className="th-pad">Description</th>
                                            <th className="th-pad">Price</th>
                                            <th className="th-pad">Updated</th>
                                            <th className="th-pad">Actions</th> 
                                        </tr>
                                    </thead>
                                       <tbody> 
                                            <tr>
                                                <td width="5%" className="id-pad rewardsId">1</td>
                                                <td width="10%" className="image-pad rewardsImage"><img src={sampleImage} className="quest-pic" alt="quest-pic"/></td>
                                                <td width="15%" className="name-pad rewardsName">Merienda 50 php Cc</td>
                                                <td width="30%" className="descp-pad rewardsDescp">Load up your Merienda Wallet Accounts with 50 PHP </td>
                                                <td width="10%" className="price-pad rewardsPrice">50</td>
                                                <td width="20%" className="updated-pad rewardsUpdated">6/12/2018 10:36 PM</td>

                                                <td width="30%" className="rewardsActions">
                                                    <Link to="#"  className="btn-floating btn-small waves-effect waves-light btn-edit" data-tip="Edit Rewards" place="top" data-class="tooltips"><MaterialIcon icon="edit" size="tiny" color="white"/></Link>
                                                    <ReactTooltip />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="5%" className="id-pad rewardsId">1</td>
                                                <td width="10%" className="image-pad rewardsImage"><img src={sampleImage} className="quest-pic" alt="quest-pic"/></td>
                                                <td width="15%" className="name-pad rewardsName">Merienda 50 php Cc</td>
                                                <td width="30%" className="descp-pad rewardsDescp">Load up your Merienda Wallet Accounts with 50 PHP </td>
                                                <td width="10%" className="price-pad rewardsPrice">50</td>
                                                <td width="20%" className="updated-pad rewardsUpdated">6/12/2018 10:36 PM</td>

                                                <td width="30%" className="rewardsActions">
                                                    <Link to="#"  className="btn-floating btn-small waves-effect waves-light btn-edit" data-tip="Edit Rewards" place="top" data-class="tooltips"><MaterialIcon icon="edit" size="tiny" color="white"/></Link>
                                                    <ReactTooltip />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="5%" className="id-pad rewardsId">1</td>
                                                <td width="10%" className="image-pad rewardsImage"><img src={sampleImage} className="quest-pic" alt="quest-pic"/></td>
                                                <td width="15%" className="name-pad rewardsName">Merienda 50 php Cc</td>
                                                <td width="30%" className="descp-pad rewardsDescp">Load up your Merienda Wallet Accounts with 50 PHP </td>
                                                <td width="10%" className="price-pad rewardsPrice">50</td>
                                                <td width="20%" className="updated-pad rewardsUpdated">6/12/2018 10:36 PM</td>

                                                <td width="30%" className="rewardsActions">
                                                    <Link to="#"  className="btn-floating btn-small waves-effect waves-light btn-edit" data-tip="Edit Rewards" place="top" data-class="tooltips"><MaterialIcon icon="edit" size="tiny" color="white"/></Link>
                                                    <ReactTooltip />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="5%" className="id-pad rewardsId">1</td>
                                                <td width="10%" className="image-pad rewardsImage"><img src={sampleImage} className="quest-pic" alt="quest-pic"/></td>
                                                <td width="15%" className="name-pad rewardsName">Merienda 50 php Cc</td>
                                                <td width="30%" className="descp-pad rewardsDescp">Load up your Merienda Wallet Accounts with 50 PHP </td>
                                                <td width="10%" className="price-pad rewardsPrice">50</td>
                                                <td width="20%" className="updated-pad rewardsUpdated">6/12/2018 10:36 PM</td>

                                                <td width="30%" className="rewardsActions">
                                                    <Link to="#"  className="btn-floating btn-small waves-effect waves-light btn-edit" data-tip="Edit Rewards" place="top" data-class="tooltips"><MaterialIcon icon="edit" size="tiny" color="white"/></Link>
                                                    <ReactTooltip />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="5%" className="id-pad rewardsId">1</td>
                                                <td width="10%" className="image-pad rewardsImage"><img src={sampleImage} className="quest-pic" alt="quest-pic"/></td>
                                                <td width="15%" className="name-pad rewardsName">Merienda 50 php Cc</td>
                                                <td width="30%" className="descp-pad rewardsDescp">Load up your Merienda Wallet Accounts with 50 PHP </td>
                                                <td width="10%" className="price-pad rewardsPrice">50</td>
                                                <td width="20%" className="updated-pad rewardsUpdated">6/12/2018 10:36 PM</td>

                                                <td width="30%" className="rewardsActions">
                                                    <Link to="#"  className="btn-floating btn-small waves-effect waves-light btn-edit" data-tip="Edit Rewards" place="top" data-class="tooltips"><MaterialIcon icon="edit" size="tiny" color="white"/></Link>
                                                    <ReactTooltip />
                                                </td>
                                            </tr>


                                        </tbody>
                                </table>
                            
                            <div className="footer-container">
                                <div className="btn-view">
                                    <Link to="#"><button className="btn btn-view-all"> View all </button></Link>
                                </div>
                                <div className="right-align">
                                    <ul className="pagination">
                                        <li className="disabled"><Link to="#"><i className="material-icons">chevron_left</i></Link></li>
                                        <li className="active"><Link to="#">1</Link></li>
                                        <li className="waves-effect"><Link to="#">2</Link></li>
                                        <li className="waves-effect"><Link to="#">3</Link></li>
                                        <li className="waves-effect"><Link to="#">4</Link></li>
                                        <li className="waves-effect"><Link to="#">5</Link></li>
                                        <li className="waves-effect"><Link to="#"><i className="material-icons">chevron_right</i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </BodyBackgroundColor>
        );
    }
}


export { RewardsManagement };
