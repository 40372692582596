import { userChallengeConstants } from '../constants/userChallenge';

export function userChallenge( state = {}, action ){
    switch(action.type){
        case userChallengeConstants.GET_USER_CHALLENGES_REQUEST:
          return {
            loading: true
          };
        case userChallengeConstants.GET_USER_CHALLENGES_SUCCESS:
          return {
              loading: false,
              userChallenges: action.userChallenges
          }
        case userChallengeConstants.GET_USER_CHALLENGES_FAILURE:
          return{
              error: action.error
          }
        case userChallengeConstants.GET_USER_CHALLENGE_REQUEST:
          return {
            loading: true
          };
        case userChallengeConstants.GET_USER_CHALLENGE_SUCCESS:
          return {
              loading: false,
              userChallenge: action.userChallenge
          }
        case userChallengeConstants.GET_USER_CHALLENGE_FAILURE:
          return{
              error: action.error
          }
        case userChallengeConstants.POST_USER_CHALLENGES_REQUEST:
          return {
            loading: true
          };
        case userChallengeConstants.POST_USER_CHALLENGES_SUCCESS:
          return {
              success: action.userChallenges
          }
        case userChallengeConstants.POST_USER_CHALLENGES_FAILURE:
          return{
              error: action.error
          }
        default:
          return state
    }
}