import React, { Component } from 'react'
import { connect } from 'react-redux';
// import { hrConcernActions } from '../../_core/actions/hrConcern';
import { essActions } from '../../_core/actions/ess';
import requestsActions from '../../_core/actions/requests'
import { history } from '../../_core/helpers/history';
import Modal from 'react-modal';
import moment from 'moment';
// import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import holiday from '../../holiday.js';
import PolicyList from "../PolicyList";
import './RequestEmploymentConcern.sass';
import Dropzone from "./Dropzone";
export class RequestEmploymentConcern extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            empConcern: {
                subject: "",
                description: "",
                type: "support",
                subtype: "hr concern",
                startedAt: new Date(),
                endedAt: new Date(),
                files:[]
            },
            submitted: false,
            HolidayList: [],

            startOfDate: null,
            endOfDate: null,
            date: '',

            render: true
        }

        this.isWeekDay = this.isWeekDay.bind(this)
        this.getEndDate = this.getEndDate.bind(this)
        this.getStartDate = this.getStartDate.bind(this)
        this.disableHoliday = this.disableHoliday.bind(this)


    }

    close(e) {
        e.preventDefault();

        const { itemPerPage } = this.state
        const userDetails = JSON.parse(localStorage.getItem("userCredentials"));
        this.props.dispatch(requestsActions.getRequests({
            itemPerPage,
            selectPage: 1,
            userId: userDetails.user._id
        }));
        history.goBack()
    }

    getTitle(e) {
        const empConcern = this.state.empConcern;
        this.setState({
            empConcern: {
                ...empConcern,
                subject: e.target.value,
            }
        }, () => {
        });
    }

    disableHoliday() {

        let holidayLists = []

        holiday.items.map((holiday) => {
            let dateDay = new Date(holiday.date);
            let day = dateDay.getDay();
            let isWeekend = (day === 6) || (day === 0);

            if (isWeekend === false) {
                holidayLists.push(new Date(holiday.date));
            }
            return null
        })

        return holidayLists;
    }

    isValidDate() {
        const { empConcern, HolidayList } = this.state;
        const storeHolidayDate = [];

        let isWeekend = false;
        let day = '';
        let dateDay = '';

        HolidayList.splice(0, HolidayList.length);
        storeHolidayDate.splice(0, storeHolidayDate.length)

        holiday.items.map((holiday) => {

            // Check if selected start date or end date has holiday
            if ((moment(holiday.date).format() === moment(empConcern.startedAt).format() && empConcern.startedAt !== '')
                || (moment(holiday.date).format() === moment(empConcern.endedAt).format() && empConcern.endedAt !== '')) {

                // Filter to prevent duplicate holiday input in an array
                if (storeHolidayDate.indexOf(holiday) === -1) {
                    dateDay = new Date(holiday.date);
                    day = dateDay.getDay();
                    isWeekend = (day === 6) || (day === 0);
                    if (isWeekend === false) {
                        storeHolidayDate.push(holiday)
                    }
                }
                this.setState({ HolidayList: storeHolidayDate })

            }

            if (empConcern.startedAt !== '' && empConcern.endedAt !== '') {
                if (moment(moment(holiday.date).format())
                    .isBetween(moment(empConcern.startedAt).format(),
                        moment(empConcern.endedAt).format(), null, '[]')) {

                    if (storeHolidayDate.indexOf(holiday) === -1) {
                        dateDay = new Date(holiday.date);
                        day = dateDay.getDay();
                        isWeekend = (day === 6) || (day === 0);
                        if (isWeekend === false) {
                            storeHolidayDate.push(holiday)
                        }
                    }

                    this.setState({ HolidayList: storeHolidayDate }, () => {

                        if (storeHolidayDate.length !== 0) {
                            Swal.fire({
                                title: 'Selected Date Range Invalid',
                                html:
                                    "<div>Selected Date Range has a holiday </div> <div style='color:rgb(38, 166, 154)'>Note: Select Date range does not have a holiday</div>",
                                type: 'error',
                                confirmButtonColor: '#26a69a',
                                confirmButtonText: 'OK'
                            })
                        }

                    })
                }
            }

            return null // remove warning 
        })
    }


    getDescription(e) {
        const empConcern = this.state.empConcern;
        this.setState({
            empConcern: {
                ...empConcern,
                description: e.target.value,
            }
        }, () => {
        });
    }

    getStartDate(date) {

        const empConcern = this.state.empConcern;
        this.setState({
            empConcern: {
                ...empConcern,
                startedAt: moment(date).format("YYYY-MM-DDT00:00:00.000") + "Z",
            },

            startOfDate: date,
        }, () => {
            this.isValidDate();
        });
    }

    getEndDate(date) {

        const empConcern = this.state.empConcern;
        this.setState({
            empConcern: {
                ...empConcern,
                endedAt: moment(date).format("YYYY-MM-DDT00:00:00.000") + "Z",
            },

            endOfDate: date,
        }, () => {
            this.isValidDate();
        });
    }

    isWeekDay(date) {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    }

    handleDateChangeRaw(e) {
        e.preventDefault();
    }




    handleSubmit(e) {
        e.preventDefault();
        const { empConcern, HolidayList } = this.state;

        this.setState({ submitted: true })

        // let StartDate = document.querySelector("#startedAt").value;
        // let EndDate = document.querySelector("#endedAt").value;

        let dateNow = Date(Date.now()).toLocaleString();
        let dateFormat = moment(dateNow).format("ddd, MMMM DD, YYYY");


        if (empConcern.subject && empConcern.description && empConcern.startedAt && empConcern.endedAt) {
            if (HolidayList.length !== 0) {

                Swal.fire({
                    title: 'Selected Date Range Invalid',
                    html:
                        "<div>Selected Date Range has a holiday </div> <div style='color:rgb(38, 166, 154)'>Note: Select Date range does not have a holiday</div>",
                    type: 'error',
                    confirmButtonColor: '#26a69a',
                    confirmButtonText: 'OK'
                })

            } else {
                Swal.fire({
                    title: 'Submit Request ?',
                    html: "<br> <b> Check the following  data if correct:</b> <br> " +
                    "<br> <b> Title: </b> " + empConcern.subject +
                    "<br> <b> Description: </b> " + empConcern.description,
                    footer: 'Filing Date:  ' + dateFormat,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#26a69a',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Submit'
                }).then((result) => {
                    if (result.value) {
                        this.props.dispatch(essActions.create(empConcern));
                    }
                })
            }
        }

    }

    // close(e) {
    //     e.preventDefault();

    //     // const { itemPerPage } = this.state
    //     // const userDetails = JSON.parse(localStorage.getItem("userCredentials"));
    //     // this.props.dispatch(requestsActions.getRequests({
    //     //     itemPerPage,
    //     //     selectPage: 1,
    //     //     userId: userDetails.user._id
    //     // }));
    //     history.goBack()
    // }
    render() {
        const { empConcern, submitted } = this.state
        let dateNow = Date(Date.now()).toLocaleString();
        let dateFormat = moment(dateNow).format("dddd, MMMM DD, YYYY");
        return (
            <Modal
                isOpen={true}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.close.bind(this)}
                className="RequestModalUser"
                centered
            >
                <div className="employment-concern">
                    <div className="header-container">
                        <div className="title-container">
                            <span> Create Employment Concern  Request  </span>
                        </div>
                        <div className="dateFiled-container">
                            <span> Filing Date: {dateFormat} </span>
                        </div>
                    </div>

                    <div className="content-container">
                        <form name="form" onSubmit={this.handleSubmit.bind(this)} autoComplete="off">
                            <div className="row">
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Request Title
                                    </label>

                                    <div className="input-custom">
                                        <input type="text" onChange={this.getTitle.bind(this)} className="form-control requestTitle" id="requestTitle" />
                                    </div>

                                    {submitted && !this.state.empConcern.subject &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right"> title is required</div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Description
                                    </label>

                                    <div className="input-custom">
                                        <textarea style={{ border: "none", padding: "10px", resize: "vertical" }} placeholder="Description" onChange={this.getDescription.bind(this)} className="form-control requestTitle" id="requestTitle" />
                                    </div>
                                    {submitted && !this.state.empConcern.description &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">  Description is required </div>
                                    }
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className="col s12 m6 l6">
                                    <label className="label-title" htmlFor="">
                                        Select start date
                                    </label>
                                    <div className="input-custom">
                                        <DatePicker
                                            className="sqUserCalendar dateValue"
                                            name="date"
                                            id="startedAt"
                                            placeholderText="Choose date"
                                            dateFormat="ddd, MMM DD, YYYY"
                                            selected={this.state.startOfDate}



                                            filterDate={(date) => this.isWeekDay(date)} // Disable Saturday & Sunday but still highlighted when time range set
                                            value={this.state.startOfDate ? moment(this.state.startOfDate).format('ddd, MMM DD, YYYY') : ''}
                                            onChange={(date) => this.getStartDate(date)}
                                            onChangeRaw={this.handleDateChangeRaw.bind(this)} // Prevent raw input
                                            excludeDates={this.disableHoliday()}


                                            selectsStart
                                            startDate={this.state.startOfDate}
                                            endDate={this.state.endOfDate}
                                            maxDate={this.state.endOfDate}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>

                                    {submitted && !this.state.startOfDate &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">  Date  is required</div>
                                    }
                                </div>

                                <div className="col s12 m6 l6">
                                    <label className="label-title" htmlFor="">
                                        Select end date
                                    </label>
                                    <div className="input-custom">
                                        <DatePicker
                                            className="sqUserCalendar dateValue"
                                            name="date"
                                            id="endedAt"
                                            placeholderText="Choose date"
                                            dateFormat="ddd, MMM DD, YYYY"
                                            selected={this.state.endOfDate}

                                            filterDate={(date) => this.isWeekDay(date)} // Disable Saturday & Sunday but still highlighted when time range set
                                            value={this.state.endOfDate ? moment(this.state.endOfDate).format('ddd, MMM DD, YYYY') : ''}
                                            opentoDate={(moment()).isAfter(this.state.startOfDate) ? this.state.endOfDate == null ? moment() : null : null}
                                            excludeDates={this.disableHoliday()}

                                            onChange={(date) => this.getEndDate(date)}
                                            onChangeRaw={this.handleDateChangeRaw.bind(this)}  // Prevent raw input 

                                            selectsEnd
                                            startDate={this.state.startOfDate}
                                            endDate={this.state.endOfDate}
                                            minDate={this.state.startOfDate}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>
                                    {submitted && !this.state.endOfDate &&
                                        <div className="help-block red-text" data-error="wrong" data-success="right">Date is required</div>
                                    }
                                </div>
                            </div> */}

                            {/* Attachment Files*/}
                            <div className="row">
                                <div className="col s12">
                                    <label className="label-title" htmlFor="">
                                        Attach File 
                                    </label>
                                    <Dropzone item={items => {
                                        this.setState({empConcern: {
                                            ...empConcern,
                                            files: items,
                                        }})
                                    }}/>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col s12">
                                    <PolicyList type={"concern"} index={0} />
                                </div>
                            </div>

                            <div className="footer-container">
                                <div>
                                    <button type="submit" className="waves-effect waves-light btn sroUserSubmitBtn" id="submit">Submit</button>
                                    <button className="waves-effect waves-light btn sroUserCancelBtn" id="cancel" onClick={this.close.bind(this)} >Cancel</button>
                                </div>
                            </div>
                        </form>

                        <div className="row">
                            <div className="col s12">
                                <label className="label-title" htmlFor="">
                                    Details:
                                </label>
                                <div className="policy">
                                    <p>Here is where content for simple reminders of policy rules</p>
                                    <br />
                                    <p>1. Request Title</p>
                                    <p>2. Description</p>
                                    <p>3. Start Date</p>
                                    <p>4. End Date</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state
    const { hrConcernActions } = state

    return {
        hrConcernActions,
        alert
    }
}

export default React.memo(connect(mapStateToProps)(RequestEmploymentConcern))