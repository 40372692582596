import React from 'react';
import { connect } from 'react-redux';
import mistake from './img/mistake.png';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import ButtonHome from './ButtonHome';
import './error.sass'
class NotFound extends React.Component {

    render() {
        return (
        	<BodyBackgroundColor backgroundColor='#9e4670'>
        	<div className="bgc-notfound">
				<div className="container center">
					<img className="img-responsive" src={mistake} alt="404"/>
				    <h2 className="notfound-text">404-page not found</h2>
				    <p className="notfound-text">We are sorry but the page you are looking for does not exist.</p>
				    <ButtonHome/>
				</div>
			</div>
			</BodyBackgroundColor>
        );
    }
}

function mapStateToProps(state) {

}

const connectedNotFound = connect(mapStateToProps)(NotFound);
export { connectedNotFound as NotFound };