import { teamConstants } from '../constants/teams';
import teamService from '../services/teams';
import { history } from '../helpers/history';
import sweetAlert from 'sweetalert2';

const toast = sweetAlert.mixin({
	toast: true,
	width: 300,
	position: 'center',
	showConfirmButton: false,
	timer: 1500
});

export default {
    getAll,
    getById,
    create,
    update,
};

function getAll(itemsPerPage, page) {
    return dispatch => {
        dispatch(request());

        teamService.getAll(itemsPerPage, page)
            .then(
                teams => {
                    dispatch(success(teams))
                    if(window.location.pathname === '/admin/team-management'){
                        history.push(`/admin/team-management?page=${Number(page) || 1}`)
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: teamConstants.GETALL_REQUEST } }
    function success(teams) { return { type: teamConstants.GETALL_SUCCESS, teams } }
    function failure(error) { return { type: teamConstants.GETALL_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request());

        teamService.getById(id)
            .then(
                team => dispatch(success(team)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: teamConstants.GETBYID_REQUEST } }
    function success(team) { return { type: teamConstants.GETBYID_SUCCESS, team } }
    function failure(error) { return { type: teamConstants.GETBYID_FAILURE, error } }
}

function create(team) {
    return dispatch => {
        dispatch(request());

        const addTeam = {
            name: team.name,
            slug: team.name.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-'),
            // logo: '',
            lead: team.lead,
            manager: team.manager,
            slogan: team.slogan,
            description: team.description
        }

        teamService.create(addTeam, team.members, team.logo)
            .then(
                team => {
                    dispatch(success(team));
                    toast.fire({
                        type: 'success',
                        title: `<span>Successfully created team</span>`
                    });
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.fire({
                        type: 'error',
                        title: `<span>Error creating team</span>`
                    });
                }
            )
    }

    function request() { return { type: teamConstants.CREATE_TEAM_REQUEST } }
    function success(team) { return { type: teamConstants.CREATE_TEAM_SUCCESS, team } }
    function failure(error) { return { type: teamConstants.CREATE_TEAM_FAILURE, error } }
}

function update(id, team) {
    return dispatch => {
        dispatch(request());

        const editTeam = {
            _id: id,
            name: team.name,
            slug: team.name.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-'),
            // logo: '',
            lead: team.lead,
            manager: team.manager,
            slogan: team.slogan,
            description: team.description
        }

        teamService.update(editTeam, team.removedMembers, team.members, team.logo)
            .then(
                team => {
                    dispatch(success(team));
                    toast.fire({
                        type: 'success',
                        title: `<span>Successfully updated team</span>`
                    });
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.fire({
                        type: 'error',
                        title: `<span>Error updating team</span>`
                    });
                }
            )
    }

    function request() { return { type: teamConstants.UPDATE_TEAM_REQUEST } }
    function success(team) { return { type: teamConstants.UPDATE_TEAM_SUCCESS, team } }
    function failure(error) { return { type: teamConstants.UPDATE_TEAM_FAILURE, error } }
}