import React from 'react';
import MainNav from '../Components/MainNav';

import M from 'materialize-css/dist/js/materialize.min.js';

import boat from '../_templates/quest-mission-boat.png';
import  background from '../_templates/quest-mission-background.png';


import start from '../_templates/quest-mission-start.png';
import restart from '../_templates/quest-mission-restart.png';
import info from '../_templates/quest-mission-info.png';


import './QuestMission.sass'

class QuestMission extends React.Component {
    componentDidMount() {
        var elem = document.querySelector(".parallax");
        M.Parallax.init(elem);
    }

    render() {
        return (
          <div className="quest-mission-page">
            <MainNav/>

            <div className="container-fluid">
                <div className="container">
                <div className="center" id="stats">
                        <ul className="quest-mission-stats">
                            <li>
                                <img src={boat} className="for-cover" alt="boat"/>
                                <p className="card-action-text">Badge</p>
                            </li>
                            <li>
                                <img src={background} className="for-cover" alt="boat" value=""/>
                                <p className="value">1</p>
                                <p className="card-action-text">Level</p>
                            </li>
                            <li>
                                <img src={background} className="for-cover" alt="boat"/>
                                <p className="value">6</p>
                                <p className="card-action-text">Mission</p>
                            </li>
                        </ul>
                    
                </div>
                </div>

            </div>
            <div className="container">
                <div className="center">
                    <h4 className="text-center">Employee Onboarding Missions</h4>
                    <p className="text-center">Welcome to Pragmanila! your first task is to complete this quest, Goodluck and have fun!</p>
                    
                    <div className="row">
                        <div className="col s12 m6 l4">
                            <div className="card hoverable">
                                <div className="card-image">
                                    <img src={boat} className="background-card" alt="background-card"/>
                                    
                                </div>
                                <div className="card-content">  
                                    <h5 className="content-title">Mission Impossible</h5>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque.
                                    </p><br/><hr/>
                                   <div className="row">
                                        <div className="col s6 m6 l6">
                                                <button className="waves-effect waves-light btn btn-create-challenge green" id="finish">Finish</button>
                                        </div>
                                        <div className="col s6 m6 l6">
                                                <button className="waves-effect waves-light btn btn-create-challenge red">Quit</button>
                                        </div>
                                   </div>
                                </div>
                            <div className="card-action">
                                <div className="row">
                                    <div className="col s4 m4 l4">
                                        <img src={start} className="icon-1" alt="boat"/>
                                        <p className="card-action-text">Mission</p>                                   
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <img src={boat} className="icon-2" alt="boat"/>
                                        <p className="card-action-text">Badge</p>
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <div className="level">
                                            <p className="level-text">1</p>
                                        </div>
                                        <p className="card-action-text">Levels</p>
                                    </div>
                                </div>
                                
                            </div>
                          </div>
                        </div>
                        <div className="col s12 m6 l4">
                            <div className="card hoverable">
                                <div className="card-image">
                                    <img src={boat} className="background-card" alt="background-card"/>
                                    
                                </div>
                                <div className="card-content">
                                    <h5 className="content-title">Mission Impossible</h5>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque.
                                    </p><br/><hr/>
                                    <div className="row">
                                        <div className="col s6 m6 l6">
                                                <button className="waves-effect waves-light btn btn-create-challenge green" id="finish">Finish</button>
                                        </div>
                                        <div className="col s6 m6 l6">
                                                <button className="waves-effect waves-light btn btn-create-challenge red">Quit</button>
                                        </div>
                                   </div>                                           
                                </div>
                            <div className="card-action">
                                <div className="row">
                                    <div className="col s4 m4 l4">
                                        <img src={info} className="icon-1" alt="boat"/>
                                        <p className="card-action-text">Mission</p>                                   
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <img src={boat} className="icon-2" alt="boat"/>
                                        <p className="card-action-text">Badge</p>
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <div className="level">
                                            <p className="level-text">1</p>
                                        </div>
                                        <p className="card-action-text">Levels</p>
                                    </div>
                                </div>
                                
                            </div>
                          </div>
                        </div>
                        <div className="col s12 m6 l4">
                            <div className="card hoverable">
                                <div className="card-image">
                                    <img src={boat} className="background-card" alt="background-card"/>
                                    
                                </div>
                                <div className="card-content">
                                    <h5 className="content-title">Mission Impossible</h5>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque.
                                    </p><br/><hr/>
                                    <div className="row">
                                        <div className="col s12 m12 l12">
                                                <button className="waves-effect waves-light btn btn-create-challenge green">Start</button>
                                        </div>
                                   </div>
                                </div>
                            <div className="card-action">
                                <div className="row">
                                    <div className="col s4 m4 l4">
                                        <img src={start} className="icon-1" alt="boat"/>
                                        <p className="card-action-text">Mission</p>                                   
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <img src={boat} className="icon-2" alt="boat"/>
                                        <p className="card-action-text">Badge</p>
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <div className="level">
                                            <p className="level-text">1</p>
                                        </div>
                                        <p className="card-action-text">Levels</p>
                                    </div>
                                </div>
                                
                            </div>
                          </div>
                        </div>
                    <div className="col s12 m6 l4">
                            <div className="card hoverable">
                                <div className="card-image">
                                    <img src={boat} className="background-card" alt="background-card"/>
                                    
                                </div>
                                <div className="card-content">
                                    <h5 className="content-title">Mission Impossible</h5>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque.
                                    </p><br/><hr/>
                                    <div className="row">
                                        <div className="col s12 m12 l12">
                                                <button className="waves-effect waves-light btn btn-create-challenge">Restart</button>
                                        </div>
                                   </div>
                                </div>
                            <div className="card-action">
                                <div className="row">
                                    <div className="col s4 m4 l4">
                                        <img src={restart} className="icon-1" alt="boat"/>
                                        <p className="card-action-text">Mission</p>                                   
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <img src={boat} className="icon-2" alt="boat"/>
                                        <p className="card-action-text">Badge</p>
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <div className="level">
                                            <p className="level-text">1</p>
                                        </div>
                                        <p className="card-action-text">Levels</p>
                                    </div>
                                </div>
                                
                            </div>
                          </div>
                        </div>
                        <div className="col s12 m6 l4">
                            <div className="card hoverable">
                                <div className="card-image">
                                     <img src={boat} className="background-card" alt="background-card"/>
                                    
                                </div>
                                <div className="card-content">
                                    <h5 className="content-title">Mission Impossible</h5>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque.
                                    </p><br/><hr/>
                                    <div className="row">
                                        <div className="col s12 m12 l12">
                                                <button className="waves-effect waves-light btn btn-create-challenge green">Start</button>
                                        </div>
                                   </div>
                                </div>
                            <div className="card-action">
                                <div className="row">
                                    <div className="col s4 m4 l4">
                                        <img src={start} className="icon-1" alt="boat"/>
                                        <p className="card-action-text">Mission</p>                                   
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <img src={boat} className="icon-2" alt="boat"/>
                                        <p className="card-action-text">Badge</p>
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <div className="level">
                                            <p className="level-text">1</p>
                                        </div>
                                        <p className="card-action-text">Levels</p>
                                    </div>
                                </div>
                                
                            </div>
                          </div>
                        </div>
                        <div className="col s12 m6 l4">
                            <div className="card hoverable">
                                <div className="card-image">
                                       <img src={boat} className="background-card" alt="background-card"/>
                                    
                                </div>
                                <div className="card-content">
                                    <h5 className="content-title">Mission Impossible</h5>
                                    <p className="content-detail">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque.
                                    </p><br/><hr/>
                                    <div className="row">
                                        <div className="col s12 m12 l12">
                                                <button className="waves-effect waves-light btn btn-create-challenge green">Start</button>
                                        </div>
                                   </div>
                                </div>
                            <div className="card-action">
                                <div className="row">
                                    <div className="col s4 m4 l4">
                                        <img src={start} className="icon-1" alt="boat"/>
                                        <p className="card-action-text">Mission</p>                                   
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <img src={boat} className="icon-2" alt="boat"/>
                                        <p className="card-action-text">Badge</p>
                                    </div>
                                    <div className="col s4 m4 l4">
                                        <div className="level">
                                            <p className="level-text">1</p>
                                        </div>
                                        <p className="card-action-text">Levels</p>
                                    </div>
                                </div>
                                
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                </div>
        
                </div>  
        
        );
    }
}

export { QuestMission };
