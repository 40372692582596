import { userTechnicalSupportConstants } from '../constants/userTechnicalSupport';

let initialState = {
  isValid: null,
}

export function userTechnicalSupport( state = initialState, action ){
    switch(action.type){
        case userTechnicalSupportConstants.POST_USER_TECHNICAL_SUPPORT_REQUEST:
          return {
            ...state,
            isValid: null,
          };
        case userTechnicalSupportConstants.POST_USER_TECHNICAL_SUPPORT_REQUEST_SUCCESS:
        return {
            ...state,
            isValid: true,
        }
        case userTechnicalSupportConstants.POST_USER_TECHNICAL_SUPPORT_REQUEST_FAILED:
            return {
              ...state,
              isValid: false,
            }
        default:
        return state
    }
}