import { statsConstants } from '../constants/stats';
import { statsService } from '../services/stats';

export const statsActions = {
    getOneUser
}

function getOneUser(id) {
    return dispatch => {
        dispatch(request(id));

        statsService.getOneUser(id)
        .then(
            stats => {
                dispatch(success(stats));
            },
            error => dispatch(failure(error.toString()))



        )
    };
}



function request() { return { type: statsConstants.GET_STATS_REQUEST } }
function success(stats) { return { type: statsConstants.GET_STATS_SUCCESS, stats} }
function failure(error) { return { type: statsConstants.GET_STATS_FAILURE, error }}