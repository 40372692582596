import React from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { postActions } from '../_core/actions/post';
import { userService } from '../_core/services/user';
import './PostWidget.sass'
import ImagePreview from '../_templates/image_icon_preview.png'
import UserMentionIcon from '../_templates/UserMentionIcon.png'
import CloseIcon from '../_templates/x_round.png'
// import { notifActions } from '../_core/actions/notification';
import { history } from '../_core/helpers/history';
import Modal from 'react-modal';
import Select from 'react-select'

const acceptedImgFile = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
class PostWidget extends React.Component {
    constructor(props) {
        super(props);

        this.fileInputRef = React.createRef();
        
        const user = JSON.parse(localStorage.getItem('user'));
        const userCred = JSON.parse(localStorage.getItem('userCredentials'))

        const secret = JSON.parse(localStorage.getItem('secretCredentials'))

        this.state = {
            mentionSelect: [],
            isShowMentions: false,
            usersList: [],
            userMentionList: [],
            render: true,
            imageHash: Date.now(),
            comments: [],
            currentPosts: [],
            currentPage: '',
            recentPosts: '',
            recentPage: '',
            showModal: false,
            imagePreview: null, // Store image to display image preview in modal
            postImagePreview: null, // Store image to display in post image preview
            StashPostImage: null, // Store image of uploaded file in inputfield
            posts: {
                postType: 'Text',
                postTitle: 'Sample Post',
                postSlug: 'Post',
                userId: userCred.user._id,
                postContent: '',
                postImage: null,
                mentions: [],
                status: 1,
                anonymous: 0,
                createdAt: Date(Date.now()).toLocaleString(),
                user_details:[{
                    firstName: user.firstName,
                    middleName: user.middleName,
                    lastName: user.lastName,
                    avatar: user.avatar
                }]      
            },
            // notification: {
            //     type: "admin",
            //     title: "New Post",
            //     content: "There is new post available.",
            //     organization: userCred.user.organization,
            //     recurring: "true"
            // },
            id: '',
            userPhoto: '',
            photo: '',
            name: '',
            content: '',
            date: '',
            isOpen: false,
            submitted: false,
            reaction:{
                originId: '',
                type: ''
            },
            heartPosted:[],
            comment:{
                "originId" : "",
                "type"	: "",
                "content" : ""
            },
            disabled: secret ? true : false

        }
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleCancelUpload = this.handleCancelUpload.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleDefaultCloseModal = this.handleDefaultCloseModal.bind(this);
        this.handleRemoveImageUploaded = this.handleRemoveImageUploaded.bind(this);
        this.handleChangeMention = this.handleChangeMention.bind(this);
        this.filterUserMention = this.filterUserMention.bind(this);
        this.isProfilePage = this.isProfilePage.bind(this);
    }

    _isMounted = false;
    componentDidMount() {
        this._isMounted = true; // Set _isMounted to true when component mounts
        document.getElementById('mention-content').display = "none";

        let page = 1;
        let totalPage = 999;
        try {
            userService.getAllActiveUsers(page, totalPage).then(res => {
                if (this._isMounted) { // Check if component is still mounted
                    if (res.page) {
                        this.setState({ 
                            usersList: res.items,
                        }, () => {
                            this.filterUserMention(this.state.usersList);
                        });
                    }
                }
            }).catch(err => {})
        } catch (error) {
            console.log(error)
        }
      
        this.isProfilePage();
    }

    componentWillUnmount() {
        this._isMounted = false; // Set _isMounted to false when component unmounts
    }


     isProfilePage()  {
        let path = history.location.pathname;
        let isProfilePage = path.includes('/profile/') ? true : false;
		let isProfile = false;

		if(isProfilePage === true) {
			isProfile = true;
			return isProfile;
		} else {
			return isProfile;
		}
	}

    filterUserMention(userList) {
        let userDetails = [];
        let userDetail= JSON.parse(localStorage.getItem('user'));
        !!userList && userList.map(users => {
            if(userDetail.user_id  !== users.details[0].user_id) {
                userDetails.push({ 
                    value: users.details[0].user_id,
                    label: users.details[0].firstName + " " + users.details[0].lastName,
                })
            }
            return users;
        })

        if(userDetails.length !== 0 ){
            this.setState({ 
                userMentionList: userDetails,
            })
        }
    }

    handleModal() {
        this.setState({ 
            showModal: true,
            imagePreview: null  }
            , () => {
                this.setState({
                    imagePreview: this.state.postImagePreview
                })
            }
            )
    }

    handleShowMentions() {
       this.setState({
         isShowMentions: this.state.isShowMentions === false ? true : false,
       })
    }

    handleDefaultCloseModal() {
        this.setState({ 
            showModal: false,
            imagePreview: null,
            StashPostImage: null,
            
        })
    }

    handleRemoveImageUploaded() {
        const { posts } = this.state; 
        this.setState({ 
            imagePreview: null,
            postImagePreview: null,
            StashPostImage: null,
            posts: {
                ...posts,
                postType: 'Text',
                postTitle: 'Sample Post',
                postImage: null,
            }
        })
    }

    handleCancelUpload() {
        const { posts } = this.state; 
        this.setState({ 
            showModal: false,
            imagePreview: null,
            postImagePreview: null,
            StashPostImage: null,
            posts: {
                ...posts,
                postType: 'Text',
                postTitle: 'Sample Post',
                postImage: null,
            }
        })
    }

    getInitialState() {
        const user = JSON.parse(localStorage.getItem('user'));
        const userCred = JSON.parse(localStorage.getItem('userCredentials'));

        return{
            posts: {
                postType: 'Text',
                postTitle: 'Sample Post',
                postSlug: 'Post',
                postImage: null,
                userId: userCred.user._id,
                postContent: '',
                status: 1,
                anonymous: 0,
                createdAt: Date(Date.now()).toLocaleString(),
                user_details:[{
                    firstName: user.firstName,
                    middleName: user.middleName,
                    lastName: user.lastName,
                    avatar: user.avatar
                }]      
            },
            comment:{
                "originId" : "",
                "type"	: "",
                "content" : ""
            }
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const user = JSON.parse(localStorage.getItem("user"));
        
        let path = history.location.pathname;
        let isMyProfile= path.includes('/profile/'+ user.user_id) ? true : false;
        let isProfilePage = path.includes('/profile/') ? true : false;
        let userProfileIdMention =  path.split('/')[2];
        const { posts } = this.state;
    
        this.setState({ submitted: true });
        // const { posts, notification } = this.state;
        let filterMultiMention = [];
        posts.mentions.length !== 0 && posts.mentions.map((userMentionDetails) =>{
            filterMultiMention.push( userMentionDetails.value)

            return userMentionDetails;
        })
        let userMentions = [];
        if(posts.mentions.length !== 0  || ( isMyProfile === false && isProfilePage === true ) ){
            if(filterMultiMention.length !== 0 ){
                userMentions = filterMultiMention; // Mention Multi User
            } 
            if(isProfilePage === true && filterMultiMention.length === 0) {
                userMentions = [userProfileIdMention]; // Mention Only one user by Profile
            }
        }

        let postMention = {
            ...posts,   
            mentions: userMentions
        }

        let trimContent = posts.postContent.trim();
        
        if(/<\/?[a-z][\s\S]*>/i.test(trimContent)){
          
        } else if (posts.postType && posts.postTitle && posts.postSlug && trimContent !== '') {
            if (posts) {
                    if(userMentions && userMentions.length === 0){
                        this.props.dispatch(postActions.create(posts));
                    } else {
                        this.props.dispatch(postActions.create(postMention));
                    }
                    // this.props.dispatch(notifActions.createNotif(notification)); //  not yet implmented, it cause error in api.
                    this.setState({ submitted: false, 
                        imagePreview: null, 
                        postImagePreview: null,
                        StashPostImage: null,
                        isShowMentions: false,
                        mentionSelect: [],

                    });
                    document.getElementById("name").value = "";
                    this.setState(this.getInitialState());
            }
        }
    }

   
    handleCloseModal() {

        const { postImage  } = this.state.posts;
        const { posts, StashPostImage } = this.state

        if(postImage) {
            const Toast = Swal.mixin({
				toast: true,
				width: 300,
				height: 500,
				background: 'rgba(191, 85, 236, 1)',
				fontColor: "white",
				position: 'center',
				showConfirmButton: false,
				timer: 1500
			});
			Toast.fire({
				type: 'success',
				title: '<span style="color:#FFFFFF">Image Added</span>'
            });
        }

        this.setState({ 
             showModal: false,
             postImagePreview: this.state.imagePreview,
             posts: {
                 ...posts,
                 postImage: StashPostImage,
             }
            })

    }

    handleChange(event) {
        const { name, value } = event.target;
        const { posts } = this.state;
        let inputValue = event.target.value;
        let removeWhitespacesStartandEnd = inputValue.trim();
        let postContentRaw = this.state.posts.postContent;

        let contentTrim = postContentRaw.replace(/^\s+|\s+$/g, '');
        if (removeWhitespacesStartandEnd !== '' || contentTrim !== '') {
            this.setState({
                posts: {
                    ...posts,
                    [name]: value
                }
            });
        }
    }

    onSelectFile = e => {

        if (e.target.files && e.target.files.length > 0) {
            const bytes = e.target.files[0].size;
            const mb = 2;
            var imageSizeMB = bytes / Math.pow(1024,2);
            if(imageSizeMB > mb){
                Swal.fire({
                    title: "File t oo large",
                    html: "<bold><p>Can't upload image larger than " + mb + " mb</p></bold>",
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Ok',
                    customClass: {
                        popup: 'format-pre'
                    }
                    }).then((result) => {
                        this.setState({
                            postImage: null
                        })
                    })
            }else{
                const { posts } = this.state;
                const imageUrl = e.target.files[0]
                this.setState({
                    StashPostImage: imageUrl,
                    imagePreview: URL.createObjectURL(e.target.files[0]),
                    posts:{
                        ...posts,
                        postType: 'image',
                        postSlug: 'post',
                    },
                })
            }
          
        }
    };

    handleChangeMention(newValue) {
        const {  posts } = this.state;

        this.setState({
            mentionSelect: newValue,
        }, () => {
                this.setState({
                    posts: {
                        ...posts,
                        mentions:  newValue,
                    }
                });
         
        })  
       
      
    }

   
    render() {
        const { posts, isShowMentions, userMentionList } = this.state;
          const customStyles = {
            option: (provided, state) => ({
              ...provided,
              borderBottom: '1px dotted #d9d9d9',
              padding: 20,
              zIndex: 999,
            }),
            menu: (provided) => ({
                ...provided,
                zIndex: 999,
            }),
            control: (control) => ({
                ...control,
              // none of react-select's styles are passed to <Control />
              width: "100%",
            }),
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = 'opacity 300ms';
          
              return { ...provided, opacity, transition };
            }
          }
       
        return (
            <React.Fragment>
                {/* {
                    secret ?

                } */}
                <div disabled={this.state.disabled} className="postBody" >
                    <form className="postForm" onSubmit={this.handleSubmit} id="formSubmit">
                    <label className ="post__label">POST</label>
                        <input type="text" placeholder="What's on your mind?" name="postContent" value={posts.postContent} onChange={this.handleChange} className="browser-default postContent" id="name" autoComplete="off"/>
  
                        <label className="uploadImage">
                            <img src={ImagePreview} className="upload" alt="canvas" style={{fontSize: '30px'}} onClick={this.handleModal}  />
                            <img src={isShowMentions === false ? UserMentionIcon : CloseIcon} className="upload" alt="users" style={{fontSize: isShowMentions === false ? '40px' : '30px', marginLeft: "10px"}} onClick={this.handleShowMentions.bind(this)}  />
                        </label>
                    </form>
                        <button form="formSubmit" id="submit">Send</button>
                      
                    </div>
                
                    <div id ="mention-content" style={{ marginTop: this.isProfilePage() === true ? '0px' : '-20px', display: isShowMentions === false ? "none" : "block"}}>
                      <Select 
                        isMulti
                        value={this.state.mentionSelect}
                        styles={customStyles}
                        options={userMentionList}
                        placeholder="Select user to mention"
                        onChange={this.handleChangeMention}
                       />
                    </div>
                 
                 
                    <div>
                        {/* Modal Starts Here */}
                        <Modal isOpen={this.state.showModal} ariaHideApp={false}>
                            <div className="modal-container">
                                <h6 style={{fontWeight: 'bold'}}>Image Preview <span className="right" onClick={this.handleDefaultCloseModal} style={{cursor: 'pointer'}}>X</span></h6>
                                <span className ="InputFile-container" style={{display: 'grid', justifyContent: 'center'}}>
                                    <input type="file" id="postImage" accept={acceptedImgFile} name="postImage" onChange={this.onSelectFile} style={{display: 'block'}}/>
                                </span> 
                                <hr className="borderline"/>
                                {this.state.imagePreview === null ?
                                <div>
                                    <h5>Image Show Here</h5>
                                </div>
                                : 
                                !!this.state.imagePreview === true &&
                                <React.Fragment>
                                    <div>
                                        <img src={this.state.imagePreview} className="imagePreview" alt="upload"/> 
                                    </div>
                                
                                    <div>
                                        <button onClick={this.handleCloseModal} className="selectBtn" >Select Image</button>
                                        <button onClick={this.handleCancelUpload} className="cancelBtn">Cancel Upload</button>
                                    </div>
                                </React.Fragment>
                                }
                            </div>
                        </Modal>
                       
                </div>
                { !!this.state.postImagePreview === true && 
                    <div>  
                        <div className="image--preview--container">
                            <img src={this.state.postImagePreview}  style ={{ marginTop: this.isProfilePage() === true ? '0px' : '-20px' }}className="" alt="upload"/>
                            <button className="image--preview--container-btn" onClick={this.handleRemoveImageUploaded}> X </button>  
                        </div>
                    </div>
                  
                  }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { allPosts, user } = state;

    return { allPosts, user }
}

const connectedPostWidget = connect(mapStateToProps)(PostWidget);

export {connectedPostWidget as PostWidget};
