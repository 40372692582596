import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import Select, { components } from "react-select";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import { history } from "../../../_core/helpers/history";

import { leaveTypeActions } from '../../../_core/actions/leaveType';
import {
  ContentWrapper,
  LabelCustom,
  SaveAndCancelButton,
} from "../../Components/PageWrapper/ContentWrapper";

import "./Leavetypes.scss";

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

export const GridContainer = styled.div`
  ${(props) =>
    props.isShowUserDetails === false
      ? css`
          margin-top: 20px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;

          // > div > label {
          //   margin-top: 10px;
          // }

        
        `
      : css`
          display: block;
        `}

  > div > label.label__questionnaires, > div > label.label__tenures {
    display: flex;
    float: left;
    top: 10px;
    position: relative;
    z-index: 1;
    margin-left: 25px;
    color: #9ba2ce;
  }

  > div > label.Error__message {
    margin-left: 10px;
  }

  > div > label.label__questionnaires {
    background: #2a3143;
    width: 72px;
    margin-top: 5px;
   
  }
  > div > label.label__tenures {
    background: #2a3143;
    width: 40px;
    margin-top: 5px;
  }

  > div > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
  }

  > div > div.error_date {
    border: 1px solid #f14646;
    border-radius: 3px;
  }
`;

const mapStateToProps = ({ evaluations, questionnaires, tenures, users,leaveTypes }) => ({
  evaluations,
  questionnaires,
  tenures,
  users,
  leaveTypes
});

const CreateEditLeaveTypePage = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();
  
  const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
  const [InputError, setInputError] = useState(false);
  const [SelectedTypes, setSelectedTypes] = useState(null)
  const { pageId } = useParams();

  const [leaveType, setLeaveType] =  React.useState({ 
    name: '',
    description: '',
    type: '',
    accrue: '',
});

  React.useEffect(() => {
    if (pageId) {
        setIsAdd(false); // Set update
        dispatch(leaveTypeActions.getById(pageId))
    }
    //eslint-disable-next-line
  }, [dispatch, pageId]);

  useEffect(() => {
    if (pageId) {
         //setIsAdd(false);
        let userList =  props.leaveTypes?.items ?? [];
        if(userList){
            userList.map((selectedData) => {
                setLeaveType({
                    name: selectedData.name,
                    description: selectedData.description,
                    type: selectedData.type,
                    accrue: selectedData.accrue,
                });
                setSelectedTypes({ label:selectedData.type === 'vacation' ? "Vacation" : selectedData.type === 'medical' ? "Medical" : "Emergency", value: selectedData.type })
                return selectedData;
            });
        }

    }
    //eslint-disable-next-line
  }, [props, pageId]);

  const handleChange = (data) => {
    if (!data) {
        setSelectedTypes(null)
        setLeaveType({
                ...leaveType,
                type: ''
        });
      }else {
        setSelectedTypes(data)
        setLeaveType({
            ...leaveType,
            type: data.value
        });
      }
}

const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setLeaveType({
            ...leaveType,
            [name]: value
        
    })
    
  }

  const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

  const handleChangeAccrue = (event) => {
    if (rx_live.test(event.target.value)){
            setLeaveType({ ...leaveType, accrue : event.target.value });
        }
    
  }

  const cancelEvaluation = () => {
    history.goBack();
    setLeaveType({
        name: '',
        description: '',
        type: '',
        accrue: '',
    });
  
    setInputError(false);
    setSelectedTypes(null);
  }

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
return (
    <ValueContainer {...props}>
    <Placeholder {...props} isFocused={props.isFocused} className="menu">
        {props.selectProps.placeholder}
    </Placeholder>
    {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
    )}
    </ValueContainer>
);
};

const statusOptions = [
    { value: 'vacation', label: 'Vacation'},
    { value: 'medical', label: 'Medical'},
    { value: 'emergency', label: 'Emergency'}
];

const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#2e3648",
      height:'40px',
      borderRadius: '5px',
      borderColor: state.isFocused ? "#323a4f" : "#323a4f",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {

        borderColor: state.isFocused ? "#556ee6" : "#556ee6",
      },
    }),

  container: (provided, state) => ({
    ...provided,
    marginTop: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible"
  }),
  placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
      transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
      fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
      background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
      color: "#9BA3CA",
  }),
  
  option: (base, state) => ({
      ...base,
      color: '#9BA3CA',
      backgroundColor: state.isSelected ? '#32394A' : '#2A3143',
      ':hover': {
        backgroundColor: state.isSelected ? '#2A3143' : '#32394A'
      }
    }),
  menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
  }),
  indicatorSeparator: state => ({
      display: 'none',
  }),
  input: (provided, state) => ({
      ...provided,
      height: 35,
      color: '#9BA3CA',
      lineHeight: state.isFocused ? 35 : 0,
      transition:  "top 0.1s, font-size 0.1s"
  }),
  singleValue: (provided) => ({
      ...provided,
      color: ' #9BA3CA',
      fontSize:"14px",
      fontWeight:'normal',
      top:'35%'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      marginTop: '-15px',
    }),
}

const validErrorEvaluator = {
    control: (provided, state) => ({
      ...provided,
      borderColor: InputError && !leaveType.type ? "#f14646" : "#2e3648",
      minHeight: 40,
      height: 40,
      background: "#2e3648",
      width: "100%",
      boxShadow: state.isFocused ? null : null,
    }),
  };


const submitLeaveType = () => {
    if (
      !leaveType.name ||
      !leaveType.description ||
      !leaveType.type ||
      !leaveType.accrue
    ) {
      setInputError(true);
    } else {
      if(isAdd){
        console.log('Add')
        setInputError(false);
      } else {
        let id = pageId;
        Swal.fire({
            title: '<p class="swal-subtitle-dark">Are you sure?</p>',
            html:
                "<br> <b class='swal-subtitle-dark'>Leave Type Name:  "
                + leaveType.name + "<br>Leave Type: "
                + leaveType.type + "<br>Acrrue:  "
                + leaveType.accrue,

            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#24DC98',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Done!',
            background: '#2E3549',
        }).then((result) => {
            if (result.value) {
                dispatch(leaveTypeActions.updateLeaveType(id, leaveType));
                setInputError(false);
            }
        })
        
      }
    }
  };

  return (
    <CustomContentWrapper>
      <ContentWrapper className="type-div">
        <LabelCustom className="main-label">
          {isAdd ? "Create" : "Edit"} Leave Type
        </LabelCustom>
        <div className="row div-1">
            <div className="col s6" style={{padding:0}}>
                <div className="floating-label"> 
                    <input  className={InputError ? " floating-input is-error" : "floating-input"}  type="text" placeholder=" "  name="name"  value={leaveType.name} onChange={handleChangeInput}/>
                    <label>Name of Leave</label>
                </div>
                {InputError && !leaveType.name && (
                <label className="Error__message">
                    Please fill-up this field
                </label>
                )}
            </div>
            <div class="col s6">
            <Select
              value={SelectedTypes}
                onChange={(e) => handleChange(e)}
              components={{
                ValueContainer: CustomValueContainer
            }}
              captureMenuScroll={false}
              placeholder="Category"
              styles={{ ...customStyles, ...validErrorEvaluator  }}
              options={statusOptions}
              openMenuOnClick={false}
              isSearchable={false}
              isClearable={true}
              menuPlacement="bottom"
              menuPortalTarget={document.getElementById("root")}
              hideSelectedOptions={false}
              classNamePrefix="select"
              maxMenuHeight={200}
            ></Select>
                {InputError && !leaveType.type && (
                <label className="Error__message">
                    Please fill-up this field
                </label>
                )}
            </div>
        </div>
        <div className="accrue" style={{marginBottom:"25px"}}>
            <div className="floating-label attrName"> 
                <input  className="floating-input" type="text" placeholder=" "  name="accrue" value={leaveType.accrue} onChange={handleChangeAccrue}/>
                <label>Accrue</label>
            </div>
        </div>
        <div className="floating-label" id="description">
            <textarea 
                className="floating-input floating-textarea coe-reason" 
                value={leaveType.description}
                name="description"
                cols="80"
                placeholder=" "
                style={{resize:'none'}}
                onChange={handleChangeInput}
                row="30">
                </textarea>
            <label className="floating-description">Description</label>
        </div>
        {/* <div style={{maxWidth:"fit-content",marginLeft:"auto"}}>
            <button className="waves-effect waves-light btn submitDark" onClick={() => submitLeaveType()}>{isAdd ? "Add" : " Edit"}</button>
        </div> */}
        <SaveAndCancelButton
          cancelFn={() => cancelEvaluation()}
          saveFn={() => submitLeaveType()}
          submitLabel={isAdd ? "Add" : " Save"}
        />
      </ContentWrapper>
    </CustomContentWrapper>
  );
});

export default CreateEditLeaveTypePage;
