import { get, post } from '../helpers/api';
import { userService } from '../services/user';

export const reactionService = {
    postReaction, //POST ONE REACTION
    getAll, //GET ALL REACTIONS
    getOne, //GET ONE REACTION
    getByOrigin, //GET REACTIONS BY ONE POST/COMMENT(ORIGIN)
};

function postReaction(react) {
    return post('/api/v1/reaction', JSON.stringify(react)).then(handleResponse)
}

function getAll(page = 1, itemsPerPage = 10) {
    return get(`/api/v1/reaction?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}

function getOne(id) {
    return get(`/api/v1/reaction/${id}`).then(handleResponse);
}

function getByOrigin(id, page = 1, itemsPerPage = 10) {
    return get(`/api/v1/reaction/origin/${id}?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}