import { leaveConstants } from '../constants/leave';

export function fileLeave(state = {}, action) {
  switch (action.type) {
    case leaveConstants.FILELEAVE_REQUEST:
      return { fileLeave: true };
    case leaveConstants.FILELEAVE_SUCCESS:
      return { fileLeave: false };
    case leaveConstants.FILELEAVE_FAILURE:
      return {};
    default:
      return state
  }
}