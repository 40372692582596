import React from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { questActions } from '../_core/actions/quest';

import badge from './sample-badge.png';
import	icon from  './icon-quest.png';
import MainNav from '../Components/MainNav';

import MaterialIcon from 'material-icons-react';

//import MaterialIcon from 'material-icons-react';
import ErrorPage from '../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../Components/ERRORPAGE/ButtonHome';
import error from '../_templates/error.png';
import './quest.sass'

class QuestIndex extends React.Component {

    componentDidMount() {
		this.props.dispatch(questActions.getAll());
	}

    handleEditUser(id) {
        return (e) => this.props.dispatch(questActions.getById(id));
    }
    render() {
    	const { quests } = this.props;
        return (
       		<div className="quest-page">
       			<MainNav/>

       			{ quests.error ? 
        			<div className="container error-div">
        				<ErrorPage/>
        					<div className="text-danger">
        						<img className="error" src={error} alt="error"/> 
        							{quests.error}
        					</div>
        				<ButtonHome/>
        			</div> : 

       			<div className="container">
       				<div className="center">
       					<img src={icon} className="header-image" alt="header"/>
	        	   		<h4 className="align-center">Quest</h4>
	        	   		<h5 className="align-center"> Maecenas sodales suscipit nunc id efficitur </h5>
        	   		</div><br/>

	       			{quests.loading && <em>Loading quests...</em>}
	                
	                {quests.error && <span className="text-danger">ERROR: {quests.error}</span>}

	                {quests.items &&
	                    <div className="row">
	       						<div className="col s12 m6 l4">

	                           		<div className="card hoverable">
	                           			  <div className="center">
	                           			  	<p className="center-align">You're not qualified to accept this<br/>quest. I'm exited to see you again.</p>
	                           			   	<MaterialIcon icon="lock" color="black" id="icons"/>
	                           			   <p className="quest-lock-text-3"> Comeback again soon.</p>
	                           			  </div>
	                           			   <div className="cover">
		                        	
	                           			    <div className="card-image">
									          	<img className="img-quest" src={badge} alt="Quest Banner" />
									          	
									          
									        </div>

									        <div className="card-content">
									          <span className="align-left">Quest Title</span>
									          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque. In vehicula eget ligula vitae elementum. </p>
									        </div>
									        <div className="card-action">
									            <div className="row">
												    <div className="col s4 m4 l4">
												    	<div className="center">
												    	<p className="center-image"><img src={badge} className="center" alt="badge"/></p>
												    	<p className="align-center" id="badge-text">Badge</p>	
												    	</div>
												       
												    </div>
												    <div className="col s4 m4 l4">
												       <div className="center">
												    	<p className="center">4</p>
												    	<p className="align-center" >Level</p>
												    	</div>
												    
												    </div>
												    <div className="col s4 m4 l4">
												      <div className="center">
												      	<p className="center">3</p>
												      	<p className="align-center">Task</p>
												      </div>
												      
												    </div>
											    </div>
									        </div>
									        </div>
		                            </div>
		                        	</div>
		                        	 <div className="col s12 m6 l4">

	                           		<div className="card hoverable">
	                           		<p className="center-align">Congratulations! You are qualified to<br/>take this quest. Give your best shot</p>
	                           		<MaterialIcon icon="favorite" color="black" id="heart"/>
	                           		<p className="quest-lock-text-3"> Mission is waiting for you.</p>
	                           			<div className="cover">
		                        			<div className="card-image">
									          	<img className="img-quest" src={badge} alt="Quest Banner" />
									          	
									          
									        </div>

									        <div className="card-content">
									          <span className="align-left">Quest Title</span>
									          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam justo, tempor a quam non, pulvinar tincidunt neque. In vehicula eget ligula vitae elementum. </p>
									        </div>
									        <div className="card-action">
									            <div className="row">
												    <div className="col s4 m4 l4">
												    	<div className="center">
												    	<p className="center-image"><img src={badge} className="center" alt="badge"/></p>
												    	<p className="align-center" id="badge-text">Badge</p>	
												    	</div>
												       
												    </div>
												    <div className="col s4 m4 l4">
												       <div className="center">
												    	<p className="center">4</p>
												    	<p className="align-center" >Level</p>
												    	</div>
												    
												    </div>
												    <div className="col s4 m4 l4">
												      <div className="center">
												      	<p className="center">3</p>
												      	<p className="align-center">Task</p>
												      </div>
												      
												    </div>
											    </div>
									        </div>
		                            </div>
		                        	</div>

		                        	</div>

	                           
	                           	{quests.items.map((quest, index) =>
	                           		<div className="col s12 m6 l4" key={index}>

	                           		<div className="card hoverable"  onClick={this.handleEditUser(quest._id)}>
	                           			    <div className="card-image">
									          	<img className="img-quest" src={quest.questImage} onError={(e)=>{e.target.onerror = null; e.target.src="https://via.placeholder.com/350x150"}} alt="Quest Banner" />
									          	{/*{quest.questImage}*/}
									          
									        </div>

									        <div className="card-content">
									          <span className="align-left">{quest.questTitle}</span>
									          <p>{quest.questContent}</p>
									        </div>
									        <div className="card-action">
									            <div className="row">
												    <div className="col s4 m4 l4">
												    	<div className="center">
												    	<p className="center-image"><img src={badge} className="center" alt="badge"/></p>
												    	<p className="align-center" id="badge-text">Badge</p>	
												    	</div>
												       
												    </div>
												    <div className="col s4 m4 l4">
												       <div className="center">
												    	<p className="center">4</p>
												    	<p className="align-center" >Level</p>
												    	</div>
												    
												    </div>
												    <div className="col s4 m4 l4">
												      <div className="center">
												      	<p className="center">3</p>
												      	<p className="align-center">Task</p>
												      </div>
												      
												    </div>
											    </div>
									        </div>
		                            </div>
		                        	</div>
		                          )}
	                                 
	                                 
	                    </div>
	                } 
            	</div>
            }
 
       		</div>
            
        );
    }
}

function mapStateToProps(state) {
  	const { quests,alert  } = state;

    return {
		quests,
        alert,
    };

}

const connectedQuestIndex = connect(mapStateToProps)(QuestIndex);
export { connectedQuestIndex as QuestIndex };