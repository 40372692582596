import React from 'react';
import { Link } from 'react-router-dom';

class TemplatesIndex extends React.Component {
    componentDidMount() {
    }

    render() {
        return (
          <div className="container">
            <div className="collection">
              <Link to="/templates/homepage" className="collection-item">Homepage</Link>
              <Link to="#!" className="collection-item">Login</Link>
              <Link to="#!" className="collection-item">Register</Link>
              <Link to="/templates/feed-page" className="collection-item">Feed</Link>
              <Link to="#!" className="collection-item">Profile</Link>
              <Link to="#!" className="collection-item">Dashboard</Link>
              <Link to="/templates/challenges-page" className="collection-item">Challenges Page</Link>
              <Link to="/templates/Quest-management" className="collection-item">Quest Management</Link>
              <Link to="/templates/user-editor-page" className="collection-item">User Editor Page</Link>
              <Link to="/templates/create-challenge-page" className="collection-item">Create Challenge Page</Link>
              <Link to="/templates/user-editor-page" className="collection-item">User Editor Page</Link>
              <Link to="/templates/quest-mission" className="collection-item">Quest Mission</Link>
              <Link to="/templates/create-quest-page" className="collection-item">Create Quest Page</Link>
              <Link to="/templates/location" className="collection-item">Location</Link>
              <Link to="/templates/quest-mission-landing-page" className="collection-item">Quest Mission Completed Landing Page</Link>
              <Link to="/templates/quest-completed-landing-page" className="collection-item">Quest Completed Landing Page</Link>
              <Link to="/templates/challenges-completed-landing-page" className="collection-item">Challenges Completed Landing Page</Link>
              <Link to="/templates/leaderboards-page" className="collection-item">Admin Leaderboards Page</Link> 
              <Link to="/templates/rewards-management-page" className="collection-item">Rewards Management Page</Link>
              <Link to="/templates/reward-redemption-management-page" className="collection-item">Rewards Redemption Management Page</Link>                            
            </div>

          </div>
        );
    }
}

export { TemplatesIndex };
