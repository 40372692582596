import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userActions } from '../../_core/actions/user';
import { leaveActions } from '../../_core/actions/leave';
import { challengeActions } from '../../_core/actions/challenge';
import { questActions } from '../../_core/actions/quest';
import { sessionActions } from '../../_core/actions/session';
import { roleActions } from '../../_core/actions/role';
import { peopleActions } from '../../_core/actions/people';
import eventsActions from '../../_core/actions/events';
import ReactTooltip from 'react-tooltip';
import events_icon from '../../_templates/events_icon.png';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css';
import ReactImageFallback from "react-image-fallback";
import avatar from '../../_templates/man.png';
import ErrorPage from '../../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../../Components/ERRORPAGE/ButtonHome';
import error from '../../_templates/error.png';
import M from 'materialize-css/dist/js/materialize.min.js';
import { Line } from 'react-chartjs-2';
import './Dashboard.sass';
import '../../_core/styles/alert.sass';
import { sortBy } from 'lodash';
import {checkSideNav} from '../Components/checkSideMenu'; //adjust component width if admin sidenav is open
import moment from 'moment';
import Avatar from '../../Components/Avatar/Avatar';
import * as experience from '../../_core/helpers/exp';

class Dashboard extends React.Component {
	constructor(props) {
		super(props);

		this.recentUser = this.recentUser.bind(this);
		this.handleChartData = this.handleChartData.bind(this);
		this.recentUserPlusTable = this.recentUserPlusTable.bind(this);

		//alert Timeout
		//leave credits
		this.state = {
			render: true,
		}
	}
	componentDidMount() {

		setTimeout(function () {
			var select = document.querySelectorAll('select');
			M.FormSelect.init(select);
		}, 1000)

		this.props.dispatch(userActions.getAllActiveUsers(1, 100000));
		this.props.dispatch(leaveActions.getAll(1, 100000));
		this.props.dispatch(challengeActions.getAll());
		this.props.dispatch(roleActions.getAll(1, 100000));
		this.props.dispatch(questActions.getAll());
		this.props.dispatch(eventsActions.getAll());
		this.props.dispatch(sessionActions.getAll());
	}

	getLeaves() {
		const leaves = (
			this.props.leaves?.leaves?.items
			.map(leave => ({
				_id: leave._id,
				name: (
					[
						leave.users[0]?.firstName,
						leave.users[0]?.lastName
					].join(" ").trim( ) ||
					"No name specified"
				),
				avatar: leave.users[0]?.avatar
			}))
			.slice(0, 3)
		) || [];

		return {
			total: this.props.leaves?.leaves?.total ?? 0,
			leaves
		};
	}

	getApplicants() {
        let applicants = [];
        let sort = [];
        const { roles: { roles } , //loading,
                users: { users }
        } = this.props;
        if (this.props.roles.loading === false && typeof roles !== 'undefined' && typeof users !== 'undefined') {
            const applicantID = roles.items.find(({
                roleSlug
            }) =>
                roleSlug === 'applicant') || { _id: 'n/a' }
            applicants = users.items
                .filter(user => user.role === applicantID._id && user.active === 1)
        }
        sort = sortBy(applicants, (obj) => obj.details[0]?.updatedAt).reverse();
        return sort;
	}
	
    getOnboardUsers() {
            if(
                (
                    typeof this.props.roles.roles === "undefined" ||
                    this.props.roles.loading === true
                ) ||
                (
                    typeof this.props.users.users === "undefined" ||
                    this.props.users.loading === true
                )
            ){
                return [];
            }
            if( this.props.roles.loading === false) {
            const applicantId = this.props.roles.roles.items.filter(role => (
                role.roleSlug === 'applicant'
            ))[0]._id;
            const onboardUsers = (
                this.props.users.users.items.filter(user => (
                    user.role !== applicantId &&
                    user.active === 1
                ))
                .map(onboardUser => ({
                    ...onboardUser,
                    name: `${onboardUser.details[0].firstName} ${onboardUser.details[0].lastName}`
                }))
            );
            return onboardUsers;
        }
    }

	handleViewProfile(id) {
		return (e) => this.props.dispatch(peopleActions.getById(id));
	}
	handleEditUser(id) {

		// return (e) => this.props.dispatch(userActions.getByIdPage(id));
		return (e) => this.props.dispatch(userActions.getById(id));
	}

	recentUser() {
		const { users } = this.props;
		try {
			let recentUserList = []; // add recent user here and called in return
			let userList = users.users.items;
			if (userList.length > 3) {


				for (let user in userList) {
					if (recentUserList.length === 3) {

						break;
					}
					if (users.users.items[user].active !== 0) {
						recentUserList.push({
							id: users.users.items[user].details.user_id,
							name: users.users.items[user].details.title + ". " + users.users.items[user].details.firstName != null && users.users.items[user].details.lastName != null ? users.users.items[user].details.firstName + " " + users.users.items[user].details.lastName : "No Name Specified",
							label: users.users.items[user].email,
							value: users.users.items[user]._id,
							avatar: users.users.items[user].details.avatar
						});
					}

				}

			} else {

				for (let user in userList) {

					if (users.users.items[user].active !== 0) {
						recentUserList.push({
							id: users.users.items[user].details.user_id,

							name: users.users.items[user].details.title + ". " + users.users.items[user].details.firstName != null && users.users.items[user].details.lastName != null ? users.users.items[user].details.firstName + " " + users.users.items[user].details.lastName : "No Name Specified",
							label: users.users.items[user].email,
							value: users.users.items[user]._id,
							avatar: users.users.items[user].details.avatar
						});
					}
				}
			}
			return recentUserList;
		}


		catch (error) {
			console.log("Unable to Get Users");
		}

	}

	recentUserPlusTable() {
		try {
			const { users } = this.props;
			// user.users.items[users].details[0].title
			const userList = users.users.items.length;
			let plusUser = 0;
			if (userList > 3) {
				plusUser = userList - 3
			} else {
				plusUser = userList
			}
			return plusUser;
		} catch (error) {
			console.log("Unable to get Users");
		}


	}

	//leave credit
	handleClose(event) {
		event.preventDefault()
		// var alertId = document.getElementById("alert")
		// alertId.style.display = 'none';
	}

	handleChartData = (canvas) => {
		const ctx = canvas.getContext("2d")
		const gradient = ctx.createLinearGradient(0, 0, 10, 400);
		gradient.addColorStop(0, ' rgba( 255,182,193, 0.8)');
		gradient.addColorStop(1, 'rgba(26, 22, 200, 0.4)');
		const gradient2 = ctx.createLinearGradient(0, 0, 10, 400);
		gradient2.addColorStop(0, 'rgba(26, 22, 200, 0.7)');
		gradient2.addColorStop(1, 'rgba(86, 217, 254, 0.2)');

		return {
			// Chart Data
			labels: [
				'11/01', '11/02',
				'11/03', '11/04',
				'11/05', '11/06',
				'11/07', '11/08',
				'11/09', '11/10'
			],
			datasets: [
				{
					label: 'Credits Given',
					data: [30, 25, 20, 25, 30, 25, 20, 25, 20, 19],// Line color
					borderColor: 'rgba(86, 217, 254, 0.2)',
					backgroundColor: gradient2,
					pointBorderColor: '#A3A1FB',
					pointBackgroundColor: '#d2e9fc',
					hoverRadius: 4,
					hoverBorderWidth: 1,
					padding: 10,
					hitRadius: 1
				},
				{
					label: 'Credits Earned',
					data: [35, 30, 25, 30, 35, 30, 25, 30, 25, 23],
					backgroundColor: gradient,
					borderColor: 'rgba(86, 217, 254, 0.2)',
					pointBorderColor: '#A3A1FB',
					pointBackgroundColor: '#1b98ff',
					hoverRadius: 2,
					hoverBorderWidth: 1,
					hitRadius: 1
				}
			]
		}
	};

	getLeaderBoards() { 
		const { users, roles: { roles } } = this.props || {}
		const allUsers = users.users?.items;
		let sort = [];
		let userLists = [];

		if (typeof allUsers !== 'undefined' && typeof roles !== 'undefined') {
			const filterRoles = roles.items.find(({ roleSlug }) => roleSlug === 'applicant') || { _id: 'n/a'}
			userLists = allUsers.filter(user => user.active === 1 && user.role !== filterRoles._id && user.credit !== 0)
		}

		sort = userLists.sort((a, b) => (
			b.credit - a.credit
		))
		return sort; 
	}

	//body
	render() {
		const { users, challenges, quests, currentLoggedIn } = this.props;
		const allChallenges = challenges.items && challenges.items.items
		const allQuests = quests.items && quests.items.items
		if(allQuests !== undefined){
			let activeQuests = allQuests.filter((allQuests)=> allQuests.active===1)
			var activeQuest = activeQuests.length
		}
		if(allChallenges !== undefined){
			let completedChallenge = allChallenges.filter((allChallenges)=>allChallenges.status==="completed")
			let finishedChallenge = allChallenges.filter((allChallenges)=>allChallenges.status==="finished")
			let activeChallenge = allChallenges.filter((allChallenges)=> allChallenges.status==="active")
			let expiredChallenge = allChallenges.filter((allChallenges)=> allChallenges.status==="expired")
			var activeCount = activeChallenge.length
			var completedCount = completedChallenge.length
			var finishedCount = finishedChallenge.length
			var challengeCount = allChallenges.length
			var expireCount = expiredChallenge.length
		}
		const options = {
			responsive: true,
			maintainAspectRatio: false, // Don't maintain w/h ratio
			legend: {
				display: true,
				position: 'top',
				labels: {
					usePointStyle: true
				}
			},
			tooltips: {
				cornerRadius: 0
			}
		}
		const leaves = this.getLeaves();
		const onboardUsers = this.getOnboardUsers();
		const applicants = this.getApplicants();
		const userLists = this.getLeaderBoards();
		const topRanked = userLists.slice(0, 4)

		var getSessionUsers = currentLoggedIn && currentLoggedIn?.map((s)=>{
			return s.user_id
		});

		const loggedInUsers = getSessionUsers.length > 0 ? users?.users?.items?.filter((u)=> getSessionUsers.includes(u._id)).length || 0 : 0
		const totalUsers = users?.users ? users.users.total : 0;

		//alert Timeout
		//leave credits
		// const { alert } = this.props;
		// let alertTimeout = true
		// if (this.state.render) {
		// 	alertTimeout = <div id="alert" className={`alert ${alert.type}`} toggle={this.onDismiss}>{alert.message}<span className="closebtn right" onClick={this.handleClose}>&times;</span></div>
		// }

		// eslint-disable-next-line
		return (
			<BodyBackgroundColor backgroundColor='#ffffff'>

				<div className={'admin-dashboard' + checkSideNav()}>
					{/* <SideMenu /> */}

					{this.props.leaves.error || users.error ?

						<div className="container error-div">
							<ErrorPage />

							<div className="text-danger">
								<img className="error" src={error} alt="error" />
								{this.props.leaves.error || users.error}
							</div>

							<ButtonHome />

						</div>

						: <div className="userLoading">

							{users.loading && this.props.leaves.loading ?

								<div className="LoadingCenter">
									<img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
									<h6>Loading Request Data..</h6>
								</div>

								: <div className="dashboard-MainContainer" id="content">

									{/* 	{alert.message && alertTimeout} */}
									<div className="text-left">
										<h6 ><b> Administrative Dashboard </b> </h6>
									</div>

									<div className="row">
										<div className="col s12  m6 l3">
											<Link to="/admin/challenge">
												<div className="card card-all card-1">
													<div className="card-content white-text ">
														<div className="row">
															<div className="col s12 widgetContent">
																<div className="text-value widgetCount">{challengeCount}
																	<span className="text-status widgetText">Total Challenges</span>
																</div>
																<div className="text-value widgetCount">{activeCount}
																	<span className="text-status widgetText">Active</span>
																</div>
																<div className="text-value widgetCount">{completedCount}
																	<span className="text-status widgetText">Completed</span>
																</div>
																<div className="text-value widgetCount">{finishedCount}
																	<span className="text-status widgetText">Finished</span>
																</div>
																<div className="text-value widgetCount">{expireCount}
																	<span className="text-status widgetText">Expired</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>

										<div className="col s12  m6 l3">
											<Link to="/admin/quest">
												<div className="card  card-all card-2">
													<div className="card-content white-text">
														<div className="row widgetContent">
															<div className="col s12">
															<div className="text-value widgetCount">{activeQuest}
															<span className="text-status widgetText">Total Quest</span>
															</div>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>

										<div className="col s12  m6 l3">
											<Link to="/templates/quest-mission-landing-page">
												<div className="card  card-all card-3">
													<div className="card-content white-text">
														<div className="row">
															<div className="col s12">
																<div className="text-value">24 of 70</div>
																<div className="text-status">Quest Mission Completed</div>

															</div>

														</div>
													</div>
												</div>
											</Link>
										</div>

										<div className="col s12 m6 l3">
											<Link to="/admin/users">
												<div className="card card-all card-4">
													<div className="card-content white-text">
														<div className="row">
															<div className="col s12">
																<div className="text-value">{loggedInUsers} of {totalUsers}</div>
																<div className="text-status">Active People</div>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>


										<div className="col s12 col m4 col l4">

											<div className="card white card-primary">
												{users.users ?
													<div className="parentLoading">
														<div className="row row-col-custom">
															<div className="col s8 m7">
																<span className="card-title mtb0p"> {applicants.length} New Applicants</span>
															</div>
															<div className="col s4 m5">
																<div className="mtb0p right-align">

																</div>
															</div>
														</div>
														<div className="row">
															<div className="photo-list">
																{applicants.length > 0 ?
                                                                    <ul>
                                                                        {
                                                                            applicants.slice(0, 3).map(applicant => (
                                                                                <li
                                                                                    className="recentUser"
                                                                                    data-tip={`${applicant.details[0]?.firstName} ${applicant.details[0]?.lastName}`}
                                                                                    data-class="tooltips"
                                                                                    key={applicant._id}
                                                                                    place="top"
                                                                                >
                                                                                    <ReactImageFallback
                                                                                        src={applicant.details[0].avatar}
                                                                                        fallbackImage={avatar}
                                                                                        initialImage={avatar}
                                                                                        alt="New Applicant"
                                                                                        className="circle responsive-img"
                                                                                        data-tip={`${applicant.details[0]?.firstName} ${applicant.details[0]?.lastName}`}
                                                                                        data-class="tooltips"
                                                                                    />
                                                                                    <ReactTooltip />
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                    : <div className="EmptyField">
                                                                        <p>No New Applicants Found. </p>
                                                                    </div>
                                                                }
																{
                                                                    applicants.length > 3
                                                                    && <span className="mtb0p flex-center-totalValue">
                                                                        &#43; {applicants.length - 3}
                                                                    </span>
                                                                }
															</div>

														</div>

													</div>
													:
													<div className="LoadingCenterSmall">
														<img alt="" height="25px" width="25px"
															src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
														<span className="clearfix"></span>
													</div>
												}
											</div>
											<span className="clearfix"></span>
										</div>




										<div className="col s12 col m4 col l4">
											<Link to="/admin/users">
												<div className="card white card-primary">
													{users.users ?
														<div className="parentLoading">
															<div className="row">
																<div className="col s9 m7">
																	<span className="card-title mtb0p">{onboardUsers.length} People Onboard</span>
																</div>
																<div className="col s3 m5">

																</div>
															</div>
															<div className="row">
																<div className="photo-list">
																	{
																			onboardUsers.length > 0
																		?	<ul>
																				{
																					onboardUsers.slice(0, 3).map(onboardUser => (
																						<li
																							className="recentUser"
																							data-tip={onboardUser.name}
																							data-class="tooltips"
																							key={onboardUser._id}
																							place="top"
																						>
																							<ReactImageFallback
																								src={onboardUser.details[0].avatar}
																								fallbackImage={avatar}
																								initialImage={avatar}
																								alt="Recent User"
																								className="circle responsive-img"
																								data-tip={onboardUser.name}
																								data-class="tooltips"
																							/>
																							<ReactTooltip />
																						</li>
																					))
																				}
																			</ul>
																		:	<div className="EmptyField">
																				<p>No Onboard Users Found. </p>
																			</div>
																	}
																	{
																			onboardUsers.length > 0
																		&&	<span className="mtb0p flex-center-totalValue">
																				&#43; {onboardUsers.length - 3}
																			</span>
																	}
																</div>

															</div>

														</div>
														:
														<div className="LoadingCenterSmall">
															<img alt="" height="25px" width="25px"
																src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
															<span className="clearfix"></span>
														</div>
													}
												</div>
											</Link>
											<span className="clearfix"></span>
										</div>


										<div className="col s12 col m4 col l4">
											<Link to="/admin/leave-requests">
												<div className="card white card-primary">
													<div className="parent-loading">
														<div className="row">
															<div className="col s9 m7">
																<span className="card-title mtb0p">
																	<span>
																		{`${leaves.total} leave applications`}
																	</span>
																</span>
															</div>
														</div>
														<div className="row">
															<div className="photo-list">
																{
																	leaves.total > 0 ?
																	<>
																		<ul>
																			{
																				leaves.leaves.map(leave =>
																					<li
																						key={leave._id}
																						className="recentUser"
																						data-tip={leave.name}
																						data-class="tooltips"
																						place="top"
																					>
																						<Link to={`/admin/leave-request/update/1/${leave._id}`}>
																							<ReactImageFallback
																								src={leave.avatar}
																								fallbackImage={avatar}
																								initialImage={avatar}
																								alt="Recent User"
																								className="circle responsive-img"
																							/>
																						</Link>
																						<ReactTooltip />
																					</li>
																				)
																			}
																		</ul>
																		<span className="mtb0p flex-center-totalValue">&#43; {leaves.total - 3}</span>
																	</> :
																	<div className="EmptyField">
																		<p>No leave applications found</p>
																	</div>
																}
															</div>
														</div>
													</div>
													<span className="clearfix"></span>
												</div>
											</Link>
										</div>


										<div className="col xs12 s12 l12 xl8">
											<div className="card white card-table">
												<table className="responsive-table highlight">
													<thead>
														<tr>
															<th className="leaderboard  center-align">Leaderboard</th>
															<th>User</th>
															<th>Rank</th>
															<th>Level</th>
															<th>Credits</th>
														</tr>
													</thead>
													<tbody>
														{topRanked.map((users, index) => (
														<tr key={index}>

														<td className="img  center-align">
														<ReactImageFallback
														src={users.details[0].avatar}
														fallbackImage={avatar}
														className="avatarLeaderboards"
														/>
														</td>
														<td>
														<p>{users.details[0].firstName} <span>{users.details[0].lastName}</span></p>
														</td>
														<td>
														<p>#{index + 1}</p>
														</td>
														<td>
														<p>{experience.exp_level(users?.exp)[0]?.current}</p>
														</td>
														<td>
														<p>{users.credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
														</td> 
														</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>

										<div className="col xs12 s12 l12 xl4">
											<ul className="collection with-header card">
												<li className="collection-header">
													<span className="title">Attributes</span>
												</li>
												<li className="collection-item avatar">
													<div className="center">
														<RadarChart
															captions={{
																// columns
																battery: 'Battery Capacity',
																design: 'Design',
																useful: 'Usefulness',
																speed: 'Speed',
																weight: 'Weight'
															}}
															data={[
																// data
																{
																	data: {
																		battery: 0.7,
																		design: .8,
																		useful: 0.9,
																		speed: 0.67,
																		weight: 0.8
																	},
																	meta: { color: '#58FCEC' }
																},
															]}
															size={250}
														/>
													</div>
												</li>
											</ul>
										</div>

										<div className="col s12 xl6 dashboard-container">
											<div className="card white chart">
												<h6 className="chart-title"> <b> Points - Credit Statistics </b> <br /> Points given compared to credits earned </h6>
												<div className="canvas-container">
													<Line data={this.handleChartData} options={options} />
												</div>
											</div>
										</div>

										<div className="col s12 xl6 info-container">
											<ul className="collection with-header card">
												<li className="collection-header">
													<span className="title">Upcoming events</span>
													<Link to="#" className="secondary-content"><img src={events_icon} alt="points" className="coin" /></Link>
												</li>
												<li className="collection-item">
													<table className="responsive-table highlight">
														<thead>
															<tr style={{ border: 'none' }}>
																<th>Name</th>
																<th>Type</th>
																<th>Date</th>
															</tr>
														</thead>
														<tbody>
															{
																this.props.events.items.map((event) => (
																	<tr key={event.id}>
																		<td>
																			<div style={{ display: 'flex', alignItems: 'center' }}>
																				<Avatar src={event.avatar} size={40}/>
																				<b style={{ marginLeft: 15 }}>
																					{
																						[
																							event.firstName,
																							event.lastName
																						].join(' ').trim()
																					}
																				</b>
																			</div>
																		</td>
																		<td style={{ textTransform: 'capitalize' }}>{ event.type }</td>
																		<td>{ moment(event.date).utc().format('MMMM D, YYYY') }</td>
																	</tr>
																))
															}
														</tbody>
													</table>
												</li>
											</ul>
										</div>


										<div className="row">
											<div className="col xs12 s12 l12 xl12">
												<div className="card white card-table">
													<table className="responsive-table highlight">
														<thead>
															<tr>
																<th>Activities</th>
																<th>Users</th>
																<th>Set by</th>
																<th>Time</th>
															</tr>

														</thead>
														<tbody>
															<tr>
																<td>Updated User</td>
																<td>Jones Hanes</td>
																<td>Jones Hanes</td>
																<td>1 week ago</td>
															</tr>

															<tr>
																<td> All Quest unlock </td>
																<td>Lanny robes</td>
																<td>Jones Hanes</td>
																<td>A month ago</td>
															</tr>



															<tr>
																<td>Completed introduce yourself</td>
																<td>Rainy Crane</td>
																<td>Jones Hanes</td>
																<td>24 hrs ago</td>
															</tr>
															<tr>
																<td>Complete your profile</td>
																<td>Jane Hane</td>
																<td>Jones Hanes</td>
																<td>16 mins ago</td>
															</tr>
															<tr>
																<td>Unlock new Quest</td>
																<td>Hills boston</td>
																<td>Jones Hanes</td>
																<td>12 mins ago</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>

										{/* <div className="row">
											<div className="col s12 m6 l6">
												<ul className="collection with-header collect-achievement card">
													<li className="collection-header">
														<span className="title">Reward Achievements</span>

													</li>

													<li className="collection-item">
														<div className="row">
															<div className="input-field col s12">
																<select>
																	<option >Select User</option>
																	<option value="1">Option 1</option>
																	<option value="2">Option 2</option>
																</select>
																<label>User</label>
															</div>
															<div className="input-field col s12">
																<select>
																	<option >Select User</option>
																	<option value="2">Option 2</option>
																</select>
																<label>Achievement</label>
															</div>
															<div className="input-field col s12">
																<input id="points" type="text" className="validate" />
																<label htmlFor="points" className="lblpoints">Set Points</label>
															</div>
															<div className="input-field col s12">
																<Link to="#" className="btn-large btn-apply">Apply</Link>
															</div>
														</div>
													</li>
												</ul>
											</div>

											<div className="col s12 m6 l6">
												<ul className="collection with-header card">
													<li className="collection-header">
														<span className="title">Reward Achievements</span>

													</li>

													<li className="collection-item">
														<div className="row">
															<div className="input-field col s12">
																<select>
																	<option>Select User</option>
																	<option value="1">Option 1</option>
																	<option value="2">Option 2</option>
																</select>
																<label>User</label>
															</div>

															<div className="input-field col s12">
																<select>
																	<option>Select User</option>
																	<option value="1">Option 1</option>
																	<option value="2">Option 2</option>
																</select>
																<label>Achievement</label>
															</div>

															<div className="input-field col s12">
																<Link to="#" className="btn-large btn-reward valign-wrapper"> <p className="	">REWARD</p> </Link>
															</div>
														</div>
													</li>
												</ul>
											</div>


										</div> */}


									</div>
								</div>
							} {/* End here: Error Leaves and User */}
						</div>
					}
				</div>
			</BodyBackgroundColor>
		);
	}
}

function mapStateToProps(state) {
	const {
		challenges,
		events,
		users,
		leaves,
		roles,
		quests,
		session
	} = state;
	// const { alert } = state;

	const filteredEvents = {
		...events,
		items: events.items.filter((event) => {
			return moment(event.date).utc().isSameOrAfter(moment().utc());
		}).splice(0, 10)
	}

	return {
		challenges,
		events: filteredEvents,
		users,
		leaves,
		roles,
		quests,
		currentLoggedIn: session?.items ? session.items : 0
		// alert
	};
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as Dashboard };
