import React, { Component } from 'react';
import moment from 'moment'
import { userActions } from '../_core/actions/user'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import './step3.sass';

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.edit = typeof props.getStore().manager !== 'undefined' ? true : false;
    this.state = {
      user: {
        _id: props.getStore()._id,
        // avatar: props.getStore().avatar,
        bio: props.getStore().bio,
        // cover: props.getStore().cover,
        firstName: props.getStore().firstName,
        middleName: props.getStore().middleName,
        lastName: props.getStore().lastName,
        title: props.getStore().title,
        suffix: props.getStore().suffix,
        address1: props.getStore().address1,
        address2: props.getStore().address2,
        city: props.getStore().city,
        state: props.getStore().state,
        country: props.getStore().country,
        nationality: props.getStore().nationality,
        mothersMaidenName: props.getStore().mothersMaidenName,
        maritalStatus: props.getStore().maritalStatus,
        gender: props.getStore().gender,
        birthDate: props.getStore().birthDate,
        birthPlace: props.getStore().birthPlace,
        email: props.getStore().email,
        team: props.getStore().team,
        organization: props.getStore().organization,
        manager: this.edit ? props.getStore().manager : null,
        position: props.getStore().position,
        department: props.getStore().department,
        startDate: props.getStore().startDate,
        endDate: props.getStore().endDate,
        regularizationDate: props.getStore().regularizationDate,
        level: props.getStore().level,
        officeId: props.getStore().officeId
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  jumpToStep(toStep) {
    // We can explicitly move to a step (we -1 as its a zero based index)
    const { user } = this.state
    this.props.updateStore(user)
    this.props.jumpToStep(toStep - 1); // The StepZilla library injects this jumpToStep utility into each component
  }
  handleSubmit(event) {

    event.preventDefault();
    const { user } = this.state
    const { dispatch } = this.props;
    var newObj = {};

    var dateNow = Date(Date.now()).toLocaleString();
    var dateFormat = moment(dateNow).format("ddd, MMMM DD, YYYY");

    Swal.fire({
      title: 'Update Changes?',
      html:
        "<br> <b>Do you want to update your Profile? </b> ",

      footer: 'Filing Date:  ' + dateFormat,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5CB85C',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Done!'
    }).then((result) => {
      if (result.value) {
          Object.keys(user).forEach(function (key) {
            if (user[key] !== "")
              newObj[key] = user[key];
          });
          dispatch(userActions.updateUser(newObj))
      }
    });
  }
  
  render() {
    const { user } = this.state;
    const { updating } = this.props;
    return (
      <div className="step step3 step3Con">
        <div className="col s12 m12 l12">
          <div className="">
            <div className="">
              <p className="left step3Txt">User Credentials</p>
            </div>

            <div className="clearfix step3Cons">
              <div className="">
                <div className="row step-2">
                  <div className="step3Input-field col s12 m12 l6">
                    <label><p className="step3Txt1 left">Email Address</p>
                    <input id="email" name="email" type="email" value={user.email} disabled placeholder="Email" className="validate" /></label>
                  </div>
                  <div className="step3Input-field col s12 m12 l6">
                    <label><p className="step3Txt1 left">Organization</p>
                    <input type="text" disabled name="organization" value={user.organization} placeholder="Organization" className="validate" /></label>
                  </div>
                </div>
              </div>

              <div className="button-container-four page3BtnCon">
                <div className="">
                  <button className="btn page3Btn-prev" onClick={() => this.jumpToStep(3)}>Previous</button>
                </div>
                <div className="">
                  <button className="btn page3Btn-save" onClick={this.handleSubmit}>Save</button>
                  {updating &&
                    <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  }
                </div>
              </div>
            </div>

          </div>

          <div className="right">
            <p className="page3PageNum">Step 4 of 4</p>
          </div>

        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { registration } = state;
  const { updating } = registration;

  return {
    updating
  };
}

export default connect(mapStateToProps)(Step3)