import { userConstants } from '../constants/user';
let initialState = {
  users:[],
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function users(state = initialState, action) {
  switch (action.type) {
    //get user
    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        // items: action.users
        ...state,
        users: action.users,
        totalPerPage: action.users.total,
        perPage: action.users.pages,
        currentPage: action.users.page,
        loading: false
      };
    case userConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    //get user details
    case userConstants.USER_DETAIL_REQUEST:
      return {
        loading: true
      };
    case userConstants.USER_DETAIL_SUCCESS:
      return {
        userDetails: action.userDetails
      };
    case userConstants.USER_DETAIL_FAILURE:
      return { 
        error: action.error
      };
    //get active
    case userConstants.GETALLACTIVE_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALLACTIVE_SUCCESS:
      return {
        // items: action.users
        users: action.users
      };
    case userConstants.GETALLACTIVE_FAILURE:
      return { 
        error: action.error
      };
    //delete user
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      // console.log(state)
      return {
        ...state,
        items: state.users.items.map(user =>
          user._id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.users.items.filter(user => user._id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.users.items.map(user => {
          if (user._id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    //get user by id
    case userConstants.GET_REQUEST:
      return {
        saving: true
      };
    case userConstants.GET_SUCCESS:
      return {
        user: action.user
      };
    case userConstants.GET_FAILURE:
      return { 
        error: action.error
      };

    //UserAttribute
    case userConstants.ASSIGNATTRIBUTE_REQUEST:
      return { attribAssignPost: true };
    case userConstants.ASSIGNATTRIBUTE_SUCCESS:
      return {};
    case userConstants.ASSIGNATTRIBUTE_FAILURE:
      return {error: action.error};

    default:
      return state
  }
}