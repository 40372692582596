import { statsConstants } from '../constants/stats';

export function getStats(state = {}, action) {

    switch(action.type) {
        case statsConstants.GET_STATS_REQUEST:
            return {
                loading: true
            };
        case statsConstants.GET_STATS_SUCCESS:
            return {
                stats: action.stats
            };
        case statsConstants.GET_STATS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}