import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../_core/actions/user";
import M from "materialize-css/dist/js/materialize.min.js";
import moment from "moment";
import MainNav from "../Components/MainNav";
import ReactImageFallback from "react-image-fallback";
import MaterialIcon from 'material-icons-react';
import RadarChart from "react-svg-radar-chart";
// import loading from "../Components/Ellipsis-1s-60px.svg";
import PeopleTab from "../Components/PeopleTab";
import avatar from "../_templates/man.png";
import { badges } from "../_core/constants/selections";
import { Parallax } from "react-parallax";
import "react-svg-radar-chart/build/css/index.css";
import { history } from "../_core/helpers/history";
import "./PeopleView.sass";
import { level } from "../_core/constants/selections";
import { exp_level, exp_targetPoints } from '../_core/helpers/exp';
import ReactTooltip from "react-tooltip";
import { attributeActions } from '../_core/actions/attribute';
import { userAttributeActions } from '../_core/actions/userAttribute';
import ExifOrientationImg from 'react-exif-orientation-img';
// import { attrUserActions } from '../_core/actions/attrUser';
import { feedsActions } from '../_core/actions/feeds';
import {hrm} from '../_core/helpers/config';

class PeoplesView extends React.Component {
  constructor(props) {
    super(props);
    
    //get logged in user
    let admin = JSON.parse(localStorage.getItem("userCredentials"));

    //set users props
    // const users = JSON.parse(localStorage.getItem('selectedUser'));
    const { users } = this.props;

    //check whether editing a user or creating a new one
    this.edit = typeof users.user !== "undefined" ? true : false;

    // this.title = this.edit ? "Edit User" : "Create User";

    //if editing, assign passed user data to prepopulate
    // let _user = this.edit ? users.user[0] : {};
    let _user = this.edit ? users.user.user[0] : {};

    //setup state for new or edited profile
    this.state = {
      user: {
        _id: this.edit ? _user._id : null,
        user_id: this.edit ? _user.details[0].user_id : "",
        email: this.edit ? _user.email : "",
        organization: this.edit ? _user.organization : admin.user.organization, //logged in user's team
        officeId: this.edit ? _user.details[0].officeId : "",
        manager: this.edit ? _user.details[0].manager : null,
        bio: this.edit ? _user.details[0].bio : "",
        firstName: this.edit ? _user.details[0].firstName : "",
        middleName: this.edit ? _user.details[0].middleName : "",
        lastName: this.edit ? _user.details[0].lastName : "",
        title: this.edit ? _user.details[0].title : "",
        suffix: this.edit ? _user.details[0].suffix : "",
        address1: this.edit ? _user.details[0].address1 : "",
        address2: this.edit ? _user.details[0].address2 : "",
        city: this.edit ? _user.details[0].city : "",
        state: this.edit ? _user.details[0].state : "",
        country: this.edit ? _user.details[0].country : "",
        nationality: this.edit ? _user.details[0].nationality : "",
        mothersMaidenName: this.edit ? _user.details[0].mothersMaidenName : "",
        maritalStatus: this.edit ? _user.details[0].maritalStatus : "",
        gender: this.edit ? _user.details[0].gender : "",
        birthDate: this.edit ? moment(_user.details[0].birthDate).format() : 'Invalid date',
        birthPlace: this.edit ? _user.details[0].birthPlace : "",
        startDate: this.edit ? moment(_user.details[0].startDate).format() : moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        endDate: this.edit ? moment(_user.details[0].endDate).format() : 'Invalid date',
        regularizationDate: this.edit ? _user.details[0].regularizationDate : moment().add(hrm.regularizationDate, 'M').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        position: this.edit ? _user.details[0].position : "",
        department: this.edit ? _user.details[0].department : "",
        level: this.edit ? _user.details[0].level : "",
        exp: this.edit ? _user.exp : "",
        avatar: this.edit ? _user.details[0].avatar : "",
        photo: this.edit ? _user.details[0].photo : "",
        cover: this.edit ? _user.details[0].cover : ""
      },
      id: '',
      userPhoto: '',
      photo: '',
      name: '',
      content: '',
      date: '',
      total: 0,
      pages: 0,
      page: 0,
      selectPage: 1,
      feeds: [],
      recentFeeds: [],
      recentPage: '',
      currentFeeds: [],
      currentPage: '',
      submitted: false,
      imageHash: Date.now(),
      level: level.software,
    };
    this.alertTimeOut = this.alertTimeOut.bind(this);
    this.trackScrolling = this.trackScrolling.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  alertTimeOut = () =>
    setTimeout(
      function() {
        this.setState({ render: false });
      }.bind(this),
      4000
    );

  componentDidMount() {
    if (this.state.user._id === null) {history.goBack()}

    var selectPage = this.state.page + 1;
    this.props.dispatch(userActions.getAllActiveUsers(1));

    this.props.dispatch(attributeActions.getAll(1, 100000))
    this.props.dispatch(userAttributeActions.getUserAttributes(this.state.user._id));
    this.props.dispatch(feedsActions.getFeedsByUser(this.state.user._id, selectPage));
    
    document.addEventListener('scroll', this.trackScrolling);
    
    window.scrollTo(0, 0);
    var elem = document.querySelector(".parallax");
    M.Parallax.init(elem);

    var element = document.querySelectorAll('.modal');
    M.Modal.init(element);


    let el = document.querySelector('.tabs');
    M.Tabs.init(el, {});

    this.alertTimeOut();
  }
  

  static getDerivedStateFromProps(props, state) {

    if(props.userFeeds.items){
        var addItem, page, pages, total

        if(props.userFeeds.items.page === state.page || state.feeds.length >= props.userFeeds.items.total){
          addItem = state.feeds
          page = state.page
          pages = state.pages
          total = state.total
        }else if(props.userFeeds.items.items !== state.feeds && state.feeds.length < props.userFeeds.items.total) {
          addItem = state.feeds.concat(props.userFeeds.items.items)
          page = props.userFeeds.items.page
          pages = props.userFeeds.items.pages
          total = props.userFeeds.items.total
        }

        return {
          feeds: addItem,
          page: page,
          pages: pages,
          total: total,
        };
    }

  return null;
  }


  trackScrolling(){
    if((window.innerHeight + window.scrollY) >= document.body.scrollHeight){
      this.setState({
        recentFeeds: [],
        recentPage: '',
      },()=>{
        const nextPage = Number(this.state.page) + 1;
        if(nextPage <= this.state.pages){
          if(this.state.page === nextPage){
            this.props.dispatch(feedsActions.getFeedsByUser(this.state.user._id, nextPage + 1));
          }else if(nextPage !== this.pageValue()){
              this.pageValue(nextPage)
              this.props.dispatch(feedsActions.getFeedsByUser(this.state.user._id, nextPage));
              document.removeEventListener('scroll', this.trackSrolling);
          }
        }
      })
    } 
}


  pageValue(page){
      var pages = (page ? page : 0) + 1;
      setTimeout(() => {
        document.addEventListener('scroll', this.trackSrolling);
      }, 1000);
      return pages
  }

  componentWillUnmount(){
    this.setState({
      feeds: []
    });
  }

  handleCloseModal(){
    this.setState({
        photo: ''
    });
  }

  getAttributes(){
    let {attributes, userAttributes } = this.props;
    let attributeList = '';
    let attrId = [];
    let userAttrList = [];
    // let attrMaxValue;

    if(attributes.attributes){
      
      var listAttributes = [];

      //for graph captions
      if(userAttributes.userAttributes){
        userAttributes.userAttributes.map((attr)=>{
          listAttributes.push(attr.attribute_id)
          return attr
        });
        attributes.attributes.items.map((attr, index)=>{
          if(listAttributes.includes(attr._id)){
            if(index === 0){
              attributeList += '"' + attr._id + '":"' + attr.attrName + '"'
            }else{
              attributeList += ', "' + attr._id + '":"' + attr.attrName + '"'
            }
            attrId.push(attr._id)
            return attr
          }else{
            return attr
          }
        });
      }

      const attrToObj = '{' + attributeList + '}'
      let captionsObj = JSON.parse(attrToObj);

      //for graph data
      if(userAttributes.userAttributes){
        userAttributes.userAttributes.map((userAttr)=>{
          if(attrId.includes(userAttr.attribute_id)){
            var test2 = userAttrList.find((attr)=>userAttr.attribute_id === attr.attr);
            if(test2){
              var uid = userAttr.user_id;
              var aid = userAttr.attribute_id;
              var atype = userAttr.type
              var a = Number(userAttr.value);
              var b = Number(test2.value);
              var c = (a + b) + '';

              userAttrList.splice(userAttrList.findIndex(attr => userAttr.attribute_id === attr.attr), 1)
              
              userAttrList.push({ 
                user: uid,
                attr: aid,
                type: atype,
                value: c,
                max: userAttr.max
              });

            }else if(!test2){
              userAttrList.push({ 
                user: userAttr.user_id,
                attr: userAttr.attribute_id,
                type: userAttr.type,
                value: userAttr.value,
                max: userAttr.max,
              });
            }
          }
          return userAttr
        })
      }

      var userAttrId =  [];
      var attrDat = '';

      attributes.attributes.items.map((attr2)=>{
        userAttrList.map((userAttr2)=>{
          if(userAttr2.attr === attr2._id){
            if(attrDat){
              attrDat += ', "' + attr2._id + '":' + ((userAttr2.value / userAttr2.max)*100) / Math.pow(10, 2) + ''
            }else{
              attrDat += '"' + attr2._id + '":' + ((userAttr2.value / userAttr2.max)*100) / Math.pow(10, 2) + ''
            }
            userAttrId.push(attr2._id)
          }
          return userAttr2
        })

        //if attribute === 0
        if(userAttrId.includes(attr2._id)){
          return attr2
        }else{
          if(attrDat){
            attrDat += ', "' + attr2._id + '":' + 0 + ''
          }else{
            attrDat += '"' + attr2._id + '":' + 0 + ''
          }
          return attr2
        }
      })

      var attrToObject = "{" + attrDat + "}";
      var dataObj = JSON.parse(attrToObject)

      //passing data to graph
      const captions =  captionsObj;
      const data = [
        {
          "data": dataObj,
          "meta": { "color": '#24DC98' }
        }
      ];

      if(attributes.attributes.items && userAttributes.userAttributes){
        return(
          <RadarChart
            captions={captions}
            data={data}
            size={250}
          />
        )
      } 
    }
  }

  render() {
    const { user, level, feeds, photo, name, content, date, id } = this.state;
    const {saving} = this.props;
    const { users } = this.props;
    var userLevel = level.filter(level => level.value === user.level);
    const exps = this.state.user.exp;
    var currentExp = exps ? exps : 0;
    var levels = exp_level(currentExp)[0];
    var curLevel = levels.current;
    var nxtLevel = levels.next;
    var targets = exp_targetPoints(curLevel, nxtLevel)[0];
    var curTarget = targets.current;
    var nxtTarget = targets.next;
    var getPercent = ((currentExp - curTarget) / (nxtTarget - curTarget))*100;
    var percent = exps ? getPercent.toString().slice(0,2) + "%" : "0%";
    const currentUser = JSON.parse(localStorage.getItem('user'))

    return (
      <div className="profile">
        <MainNav />
        
        {/* Header Photo */}
        <div className="sticky">
          <div className="parallax-container coverPhoto fallback">
            <Parallax
              bgImage={`${user.cover}`}
              bgImageAlt="user cover"
              strength={100}
              bgClassName="cc_image"
              className="cover-picture"
            >
              <div className="dark-cover" />
            </Parallax>
          </div>
          <div className="container-fluid">
          <div className="row">
            <div className="offset-m1 col s12 m2 lg2 user-pic-center" id="peoplePic">
              <ReactImageFallback
                src={`${user.avatar}`}
                fallbackImage={avatar}
                initialImage={avatar}
                alt="user avatar"
                className="avatar1User"
              />
            </div>
            <div className="col s12 m9 l9">
              <div className="row txtDetailUser">
                <div className="col s12 m4">
                  <div className="con1">
                    <h6 readOnly className="Txt1" id="name-text">
                      {user.firstName != null && user.lastName != null
                        ? user.firstName + " " + user.lastName
                        : ""}
                    </h6>
                    <p className="Txt2" id="name-text">
                      {user.position + " | "} {user.organization} <br />
                    </p>
                  </div>
                </div>
                <div className="col s12 m6 offset-m1">
                  <div className="con2">
                    <p className="Txt3">
                      {user.startDate
                        ? "Joined: " +
                          moment(user.startDate).format("MMMM D, YYYY")
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        {/* End Header Photo */}
        <PeopleTab/>
        <div className="container-userdetail">
          <div className="col s12 m4">
            <div className="row">
              {/* Detail Page */}
              <div className="col s12 m3">
                <div className="DetailPageCon pvDetailPageCon">
                  <div>
                    <p>
                      <b>Bio</b>
                    </p>
                    <p>{user.bio}</p>
                  </div>

                  <hr className="DPLines" />

                  <div className="">
                    <p className="level-job">
                      Level {curLevel}{" "}
                      <span className="level-job-separator"> | </span>
                      {userLevel[0] ? userLevel[0].label : ""}
                    </p>

                    <div
                      id="progress"
                      data-tip={percent}
                      place="bottom"
                      data-class="tooltips"
                    >
                      <div id="bar" style={{width: percent}}></div>
                    </div>
                    <ReactTooltip />
                    <p className="right txtPercent">
                      {currentExp} / {nxtTarget} XP
                    </p>
                  </div>

                  <hr className="DPLines DPLines2" />

                  <div>
                    <p className="TxtTrophy">
                      <b>Badges</b>
                    </p>
                    
                    <div className="row">
                      {badges.default.filter((badges)=> badges.userId === user.user_id).slice(0, 6).map((badge, index) =>
                          <div className="col s4 m4" key={index}>
                          <img
                            src={badge.details[0].badgeImage}
                            alt="trophy_1"
                            className="responsive-img trophyImage"
                          />
                        </div>
                      
                      )}{badges.default.filter((badges)=> badges.userId === user.user_id).length <= 0 &&
                        <center><h5 id="render-none"><em>No Badges Found</em></h5></center>
                      }
                    </div>

                    <Link to="#!">
                      <button className="btnTimesheet modal-trigger" data-target="modalBadges">View All</button>
                    </Link>
                  </div>
                  
                  {/*modal*/}

                  <div id="modalBadges" class="modal">
                      <div className="modal-header" id="badgeHeader">
                      <h5 className="TxtTrophy">
                        <b>View All Badges</b>
                        <a href="#!" class="modal-close waves-effect waves-green btn-flat right"><MaterialIcon icon="close" size="medium"/></a>
                      
                      </h5>
                      </div>
                      <div class="modal-content" id="badgeContent">
                      {badges.default &&
                      <div className="row">
                        {badges.default.filter((badges)=> badges.userId === user.user_id).map((badge, index) =>
                            <div className="col s4 m4" key={index}>
                            <div className="card badgeCards">
                              <div className="row">
                                  <div className="col s4 m4 l4">
                                  <img
                                  src={badge.details[0].badgeImage}
                                  alt="trophy_1"
                                  className="responsive-img trophyImage"
                                  
                                  />
                                
                                  </div>
                          
                                  <div className="col s8 m8 l8">
                                  <p className="TxtTrophy textHeader">
                                    <b>{badge.details[0].badgeName}</b>
                                  </p>
                                  <p className="TxtTrophy">{badge.details[0].badgeContent}</p>
                                  
                                  </div>
                              </div>
                            </div>  
                          </div>

                        
                        )}{badges.default.filter((badges)=> badges.userId === user.user_id).length <= 0 &&
                          <center><h5 id="render-none"><em>No Badges Found</em></h5></center>
                        }
                        
                      </div>
                      }
                      </div>
                      
                    </div>

                    {/*Modal End*/}



                  <hr className="DPLines" />

                  <div className="hide-on-med-and-down">
                    {/* <p>
                      <b>Stats</b>
                    </p>
                    <div className="center">
                      {this.getAttributes()}
                    </div> */}
                  </div>
                </div>
              </div>
              {/* End Detail Page */}
                          <div className="col s12 m9 l6">
                          <div className="BodyCon">
                                
                            <div id="test1" class="col s12">
                            <div className="conAddComment">
                          
                                        <div className="listAddComment listAddComment1 hide-on-med-and-down">
                                            <ReactImageFallback
                                                fallbackImage={avatar}
                                                initialImage={avatar}
                                                alt="user avatar"
                                                className="commentAvatar"
                                            />
                                        </div>
                                        <div className="listAddComment listAddComment2">
                                            <input type="text" className="uppInputStatus" id="name" placeholder="Any thoughts?" />
                                        </div>
                                        <div className="listAddComment listAddComment3">
                                            <button className="uppBtn btn" id="submit"><p>Share</p></button>
                                        </div>
                                    </div>
                                    {feeds.length <= 0 ?
                                      <center><h5><em>no post</em></h5></center>
                                    :
                                    feeds.filter((feeds) => feeds.userId === user._id).map((feed, index) =>
                                    <div key={index}>
                                    
                                    <hr className="postLines clearfix" />

                                    <div className="row clearfix">
                                        <div className="col s1 m1">
                                            <ReactImageFallback
                                                src={`${feed.user_details[0].avatar}`}
                                                fallbackImage={avatar}
                                                initialImage={avatar}
                                                alt="user avatar"
                                                className="commentAvatar"
                                            />
                                        </div>
                                        <div className="col s11 m11 commentCon commentTxts">
                                            <div className="row">
                                                
                                                <div className="col s6 m6"><p className="txtUser1"><b>{feed.user_details[0].firstName + ' ' + feed.user_details[0].middleName + ' ' + feed.user_details[0].lastName}</b></p></div>
                                                <div className="col s6 m6"><p className="right commentHrs likes">{moment(feed.createdAt).fromNow()}</p></div>

                                            </div>
                                            
                                            <p>{feed.postContent}</p><br/>
                                            {feed.postImage !== [] ? (<ExifOrientationImg
                                                    src={feed.postImage}
                                                    className="postImage modal-trigger"
                                                    data-target="modalPhoto"
                                                    onClick={() => this.setState({ id: feed._id, userPhoto: feed.user_details[0].avatar, photo: feed.postImage[0], name: feed.user_details[0].firstName + ' ' + feed.user_details[0].middleName + ' ' + feed.user_details[0].lastName, content: feed.postContent, date: feed.createdAt})}
                                                />): ''
                                            }
                                                
                                                <hr className="lines"/>
                                                <div className="commentCon1 valign-wrapper">
                                                    <div className="heart-success-con">
                                                        <i className="small material-icons heart-hidden" id={"hrt" + feed._id}>favorite</i>
                                                    </div>
                                                    <div className="valign-wrapper number-likes">
                                                        <i className="small material-icons heart-like " id={feed._id}>favorite</i>
                                                        <p className="likes">0 <span className="react_text">like</span></p>
                                                    </div>
                                                    <div className="valign-wrapper number-likes" onClick={()=> document.getElementById('cmnt' + feed._id).focus()}>
                                                        <i className="small material-icons comment-icon">chat_bubble</i>
                                                        <p className="likes">0 <span className="react_text">Comment</span></p>
                                                    </div>
                                                </div>
                                                {saving && this.state.comment.originId === feed._id ?
                                                <center>
                                                    <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                </center>
                                                :
                                                <div className="valign-wrapper">
                                                    <ReactImageFallback
                                                        src={`${currentUser.avatar}`}
                                                        fallbackImage={avatar}
                                                        initialImage={avatar}
                                                        alt="user avatar"
                                                        className="comment-avatar"
                                                    />
                                                    <textarea placeholder="Write a comment" name="" id={'cmnt'+ feed._id} rows="1" cols="" className="comment-textarea"></textarea>
                                                    {/* <div className="comment-btn-send"><p>send</p></div> */}
                                                </div>
                                                }
                                        </div>
                                    </div>
                                    </div>
                                    )}
                                    {feeds.length > 0? 
                                      <center><h5><em>end of post</em></h5></center>
                                    : ''}
                                   </div> 


                                   <div id="modalPhoto" className="modal">
                                    <Link to="#" className="modal-close right material-icons" onClick={this.handleCloseModal}>close</Link>
                                                  
                                        <div className="modal-content">
                                        <div className="row">
                                            <div className="col s12 m6 l6">
                                                <ExifOrientationImg
                                                src={photo}
                                                className="modalImage"                                                
                                                />
                                            </div>
                                            <div className="col s12 m6 l6">
                                                <div className="card" id="cardContent">
                                                               
                                                    <div className="row-clearfix">
                                                        
                                                        <div className="col s1 m1">
                                                            <ReactImageFallback
                                                                src={`${currentUser.avatar}`}
                                                                fallbackImage={avatar}
                                                                initialImage={avatar}
                                                                alt="user avatar"
                                                                className="modalAvatar"
                                                            />
                                                        </div>
                                                        <div className="col s11 m11 commentCon commentTxts">
                                                            <div className="row">
                                                                <div class="col s12 m6 l6">
                                                                    <p className="txtUser1"><b>{name}</b></p>
                                                                    <p className="commentHrs">{moment(date).fromNow()}</p>
                                                                </div>
                                                                <div class="col s12 m6 l6">
                                                                
                                                                </div>
                                                            </div>
                                                            <p>{content}</p><br/>
                                               
                                                            <hr className="lines"/>
                                                            <div className="commentCon1 valign-wrapper">
                                                                <div className="heart-success-con">
                                                                    <i className="small material-icons heart-hidden" id={"hrt" + id}>favorite</i>
                                                                </div>
                                                                <div className="valign-wrapper number-likes" >
                                                                    <i className="small material-icons heart-like " id={id}>favorite</i>
                                                                    <p className="likes">0 <span className="react_text">like</span></p>
                                                                </div>
                                                                <div className="valign-wrapper number-likes" onClick={()=> document.getElementById('cmnt' + id).focus()}>
                                                                    <i className="small material-icons comment-icon">chat_bubble</i>
                                                                    <p className="likes">0 <span className="react_text">Comment</span></p>
                                                                </div>
                                                            </div>
                                                        </div>    
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        </div>
                                    </div>


                                
                      <div id="test2" class="col s12">
                      <div className="col s12 m9 12">
                    
                    <div className="row">
                      <div className="col s12 m12 l12">
                        <div className="row userDetailCon2">
                          <div className="col s12 m6">
                            <div className="userDetailList">
                              <p>
                                <b>Email</b>
                              </p>
                              {user.email ? 
                              <p>{user.email}</p>
                              : <em>No Data Available</em>}
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Gender</b>
                              </p>
                              {user.gender ? 
                              <p>{user.gender}</p>
                              : <em>No Data Available</em>}
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Birthdate</b>
                              </p>
                              <p>
                                {user.birthDate === 'Invalid date' ? <em>No Data Available</em> : moment(user.birthDate).format("MMMM D, YYYY")}
                              </p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Marital Status</b>
                              </p>
                              {user.maritalStatus ?
                              <p>{user.maritalStatus}</p>
                              : <em>No Data Available</em>}
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>BirthPlace</b>
                              </p>
                              {user.birthPlace ?
                              <p>{user.birthPlace}</p>
                              : <em>No Data Available</em>}
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Mothers maiden name</b>
                              </p>
                              {user.mothersMaidenName ?
                              <p>{user.mothersMaidenName}</p>
                              : <em>No Data Available</em>}
                            </div>
                          </div>
                          <div className="col s12 m6">
                            <div className="userDetailList">
                              <p>
                                <b>Address</b>
                              </p>
                              {user.address1 || user.address2 ?
                              <p>
                                {user.address1}, {user.address2}
                              </p>
                              : <em>No Data Available</em>}
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>City</b>
                              </p>
                              {user.city ?
                              <p>{user.city}</p>
                              : <em>No Data Available</em>}
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>State</b>
                              </p>
                              {user.state ?
                              <p>{user.state}</p>
                              : <em>No Data Available</em>}
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Country</b>
                              </p>
                              {user.country ?
                              <p>{user.country}</p>
                              : <em>No Data Available</em>}
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Nationality</b>
                              </p>
                              {user.nationality ?
                              <p>{user.nationality}</p>
                              : <em>No Data Available</em>}
                            </div>
                          </div>
                        </div>

                        <div class="row userDetailCon2">
                          <div className="col s12 m6">
                            <div className="userDetailList userDetailList2">
                              <p className="pvEmployDetailsTxt">
                                <b>Employment Details</b>
                              </p>
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Department</b>
                              </p>
                              {user.department ?
                              <p>{user.department}</p>
                              : <em>No Data Available</em>}
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Position</b>
                              </p>
                              {user.position ?
                              <p>{user.position}</p>
                              : <em>No Data Available</em>}
                            </div>
                            <div className="userDetailList">
                              <p>
                                <b>Start Date</b>
                              </p>
                              {user.startDate === 'Invalid date' ? <em>No Data Available</em> : moment(user.startDate).format("MMMM D, YYYY")}
                            </div>
                          </div>
                          <div className="col s12 m6">
                            <div className="userDetailList userDetailList1">
                              <p>
                                <b>Regularization Date</b>
                              </p>
                              <p>
                                {user.regularizationDate === 'Invalid date' ? <em>No Data Available</em> : moment(user.regularizationDate).format("MMMM D, YYYY")}
                              </p>
                            </div>
                            <div className="userDetailList">
                                <p><b>End Date</b></p>
                                  <p>{user.endDate === 'Invalid date' ? <em>No Data Available</em> : moment(user.endDate).format("MMMM D, YYYY")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               
                      </div>
                      </div> 
                      </div>


                  
              {/* Status Page */}
              <div className="col s12 m3">
                <div className="statusPageCon">
                  <div>
                    <div className="hide-on-med-and-down">
                      <p className="center txtStatus">
                        <b>Status</b>
                      </p>
                      <p>
                        <b>Prag In / Out</b>
                      </p>
                      <div className="row timeCon">
                        <div className="col s6 m6">
                          <p>Rendered Time</p>
                        </div>
                        <div className="col s6 m6">
                          <p className="right">
                            <b>Check Schedule</b>
                          </p>
                        </div>
                      </div>

                      <div>
                        <div className="row">
                          <div className="col s2 m2">
                            <ReactImageFallback
                              fallbackImage={avatar}
                              initialImage={avatar}
                              alt="user avatar"
                              className="avatarTime"
                            />
                          </div>
                          <div className="offset-m1 col s7 m7">
                            <div id="progress1">
                              <span id="percent1">
                                <ReactImageFallback
                                  fallbackImage={avatar}
                                  initialImage={avatar}
                                  alt="user avatar"
                                  className="avatarRange"
                                />
                              </span>
                              <div id="bar1"></div>
                            </div>
                          </div>
                          <div className="col s2 m2">
                            <div className="blankPic"></div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col s6 m6">
                          <p>IN: 10 : 20 AM</p>
                        </div>
                        <div className="col s6 m6">
                          <p className="right">OUT: 00 : 00 PM</p>
                        </div>
                      </div>
                    </div>

                    <hr className="hide-on-med-and-down DPLines" />

                    <div className="hide-on-med-and-down">
                      <div className="row">
                        <div className="col s6 m6">
                          <p>PEOPLE</p>
                        </div>
                        <div className="col s6 m6">
                          <Link to="/people" className="spViewAllPic">
                            <p className="right">View all</p>
                          </Link>
                        </div>
                      </div>

                      <div className="spImgCon">
                        {
                          <div>
                            {users.loading && <em>Loading pictures...</em>}

                            {users.users && (
                              <div>
                                {users.users.items
                                  .filter(users => users.active === 1)
                                  .slice(0, 10)
                                  .map((user, index) => (
                                    <div className="spImgCon1">
                                      <ReactImageFallback
                                        src={user.details[0].avatar}
                                        fallbackImage={avatar}
                                        initialImage={avatar}
                                        alt="user avatar"
                                        className="circle statusImg"
                                      />
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Status Page */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users, authentication, registration, alert, attributes, userAttributes, userFeeds } = state;
  const { auth } = authentication;
  const { registering } = registration;

  return {
    registering,
    users,
    auth,
    alert,
    attributes,
    userAttributes,
    userFeeds
  };
}

const connectedPeoplesView = connect(mapStateToProps)(PeoplesView);
export { connectedPeoplesView as PeoplesView };
