export const coeConstants = {
    
    SEND_COE_REQUEST: 'COE_SENDCOE_REQUEST',
    SEND_COE_SUCCESS: 'COE_SENDCOE_SUCCESS',
    SEND_COE_FAILURE: 'COE_SENDCOE_FAILURE',

    GETALL_COEREQUEST_REQUEST: 'GETALL_COEREQUEST_REQUEST',
    GETALL_COEREQUEST_SUCCESS: 'GETALL_COEREQUEST_SUCCESS',
    GETALL_COEREQUEST_FAILURE: 'GETALL_COEREQUEST_FAILURE',

    UPDATE_REQUEST: 'COE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'COE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'COE_UPDATE_FAILURE',

    APPROVE_REQUEST: 'COE_APPROVE_REQUEST',
    APPROVE_SUCCESS: 'COE_APPROVE_SUCCESS',
    APPROVE_FAILURE: 'COE_APPROVE_FAILURE',

    HOLD_REQUEST: 'COE_HOLD_REQUEST',
    HOLD_SUCCESS: 'COE_HOLD_SUCCESS',
    HOLD_FAILURE: 'COE_HOLD_FAILURE',

    DECLINE_REQUEST: 'COE_REJECT_REQUEST',
    DECLINE_SUCCESS: 'COE_REJECT_SUCCESS',
    DECLINE_FAILURE: 'COE_REJECT_FAILURE',

    GETUSER_REQUEST: 'COE_GETUSER_REQUEST',
    GETUSER_SUCCESS: 'COE_GETUSER_SUCCESS',
    GETUSER_FAILURE: 'COE_GETUSER_FAILURE',

};


