import buildUrl from 'build-url';
import { get, del, put, post } from '../helpers/api';
import { userService } from '../services/user';

export const announcementServices = {
    getAllAnnouncement,
    getAcknowledge,
    getContent,
    deleteAnnouncement,
    getStatusByID,
    updateStatusValue,
    update
};

// function getAllAnnouncement(page,itemPerPage){
//     return get(
//         buildUrl('/api/v1/post', {
//             queryParams: {
//                 'page': page,
//                 'items-per-page': itemPerPage,
//                 'filter': "postType:announcement"
//             }
//         })
//     ).then(handleResponse);
    
// }

function getAllAnnouncement(page, itemsPerPage) {
    return get (`/api/v1/post?page=${page}&items-per-page=${itemsPerPage}&filter=postType:announcement`).then(handleResponse);
}

function getContent( id) {
    return get (`/api/v1/post/${id}`).then(handleResponse);
}

function deleteAnnouncement(id){
    return del(`/api/v1/post/${id}`).then(handleResponse)
}

function getStatusByID(id){
    return get(`/api/v1/post/${id}`).then(handleResponse)
}

function updateStatusValue(id, statusvalue){
    // let id = statusvalue._id
    return put(`/api/v1/post/${id}`, JSON.stringify(statusvalue))
        .then(handleResponse)
}

function imageUpload(id , image){
    //var sample = JSON.parse(id)
    return post(`/api/v1/upload/${id}/post`, image).then(handleResponse);
}

function update(id, posts){
    
    function isFile(input) {
        if ('File' in window && input instanceof File)
           return true;
        else return false;
     }
    
    const fd = new FormData();
    const imageFile = posts.postImage;
    fd.append('file',imageFile)
    
    //checking if post images has a content file
    if(isFile(posts.postImage) === true){
        posts.postImage = null;
    }

    return put(`/api/v1/post/${id}`, JSON.stringify(posts)).then(handleResponse)
    .then(response => {
        imageUpload(id,fd);
    });
}


function getAcknowledge(id){
    return get(
        buildUrl('/api/v1/post', {
            queryParams: {
                'page': 1,
                'items-per-page': 998,
                'filter': "postType:announcement"
            }
        })
    ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}