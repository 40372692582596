import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { When } from "react-if";
import styled, { css } from "styled-components";
import Select, { components } from "react-select";
import moment from "moment";
import { history } from "../../../_core/helpers/history";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import {
  ContentWrapper,
  LabelCustom,
  InputTextCustom,
  SaveAndCancelButton,
} from "../../Components/PageWrapper/ContentWrapper";

import PreviewUserDetailsWidget from "../../AdminWidget/PreviewUserDetailsWidget/PreviewUserDetailsWidget";
import search_icon from "../../../_templates/dark/search_default.png";
import admin_calendar from "../../../_templates/dark/admin_calendar.png";

import evaluationActions from "../../../_core/actions/evaluation";
// import evaluation from "../../../_core/services/evaluation";
import { userActions } from "../../../_core/actions/user";
import questionnaireActions from "../../../_core/actions/questionnaire";
import { tenuresAction } from "../../../_core/actions/tenures";

import "./overrideDropdownEvaluationPage.scss";

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;
export const SearchIconFindUser = styled.img`
  margin-bottom: -30px;
  margin-left: 15px;
  display: flex;
  position: relative;
  z-index: 1;
  height: 15px;
  width: 15px;
  object-fit: contain;
`;

export const FindUserWrapper = styled.div`
  > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
    margin-left: 15px;
  }
`;

export const GridContainer = styled.div`
  ${(props) =>
    props.isShowUserDetails === false
      ? css`
          margin-top: 20px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;

          // > div > label {
          //   margin-top: 10px;
          // }

        
        `
      : css`
          display: block;
        `}

  > div > label.label__questionnaires, > div > label.label__tenures {
    display: flex;
    float: left;
    top: 10px;
    position: relative;
    z-index: 1;
    margin-left: 25px;
    color: #9ba2ce;
  }

  > div > label.Error__message {
    margin-left: 10px;
  }

  > div > label.label__questionnaires {
    background: #2a3143;
    width: 72px;
    margin-top: 5px;
   
  }
  > div > label.label__tenures {
    background: #2a3143;
    width: 40px;
    margin-top: 5px;
  }

  > div > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
  }

  > div > div.error_date {
    border: 1px solid #f14646;
    border-radius: 3px;
  }
`;

export const DatePickerWrapper = styled.div`
  height: 40px;
  width: 100%;
  background-color: #2e3648;
  overflow: hidden;
  padding-left: 20px;
  margin-top: 20px;
  border-radius: 4px;

  ${(props) =>
    (props.isShowUserDetails === false) &
    css`
      // display: block;
      // margin-top: 20px;
      // > div {
      //   margin-top: 20px;
      // }
    `}

  > label {
    position: absolute;
  }
  > label.label_date_questionnaires {
    top: 355px;
  }
  > label.label_date_tenures {
    top: 420px;
  }

  > img.admin_calendar_icon1 {
    display: flex;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
  }
  > img.admin_calendar_icon2 {
    display: flex;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
  }
  > div.react-datepicker-wrapper
    > div.react-datepicker__input-container
    > input[type="text"]:not(.browser-default) {
    border: none !important;
    color: #9ba2ce;
    font-size: 12px;
    font-weight: 400;
  }

  > div.react-datepicker-wrapper
    > div.react-datepicker__input-container
    > input::placeholder {
    border: none !important;
    color: #9ba2ce;
    font-size: 12px;
    font-weight: 400;
  }

  > div.react-datepicker-wrapper {
    display: flex;
  }
`;
const mapStateToProps = ({ evaluations, questionnaires, tenures, users }) => ({
  evaluations,
  questionnaires,
  tenures,
  users,
});

const CreateEditEvaluationPage = connect(mapStateToProps)((props) => {
  const dispatch = useDispatch();
  const [InputError, setInputError] = useState(false);
  const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
  const [isDisplayUserDetails, setIsDisplayUserDetails] = useState(false); // eslint-disable-line
  const [questionnaireDropdown, setQuestionnaireDropdown] = useState([]);
  const [TenuresDropdown, setTenuresDropdown] = useState([]);

  const [SelectedSubject, setSelectedSubject] = useState(null)
  const [SelectedQuestionnaires, setSelectedQuestionnaires] = useState(null)
  const [SelectedTenures, setSelectedTenures] = useState(null)
  const [SelectedEvaluator, setSelectedEvaluator] = useState(null)
  const [UserData, setUserData] = useState([]);
  const [UserDetailsView, setUserDetailsView] = useState(null);

  const [EditSubjectId, setEditSubjectId] = useState([]);
  const [evaluation, setEvaluation] = useState({
    title: "",
    description: "evaluation",
    tenureid: "",
    subject: null,
    user_id: null,
    questionaireid: "",
    startedAt: null,
    endedAt: "",
    expiresAt: null,
  });

  const [startDate, setStartDate] = useState('');
  const [ExpirationDate, setExpirationDate] = useState(null);

  const [IsEditedEval, setIsEditedEval] = useState(false)
  const [IsEditedExpire, setIsEditedExpire] = useState(false)
  const { pageId } = useParams();

  React.useEffect(() => {
    if (pageId) {
        setIsAdd(false); // Set update
        dispatch(evaluationActions.getById(pageId)); // call selected evaluation id
        setIsDisplayUserDetails(true); // View subject details
    }
    //eslint-disable-next-line
  }, [dispatch, pageId]);

  
  useEffect(() => {

    // prepopulate selected evaluation to edit
    if (pageId) {
      
      let selectedData = props.evaluations?.dataById.length !== 0;

      if(selectedData) {
        selectedData =  props.evaluations?.dataById?.success[0];

        setEvaluation({
          title: selectedData.title,
          description: "evaluation",
          tenureid: selectedData.tenureid,
          subject: selectedData.subject,
          user_id: selectedData.user_id,
          questionaireid: selectedData.questionaireid,
          startedAt: selectedData.startedAt,
          endedAt: selectedData.expiresAt,
          expiresAt: selectedData.expiresAt,
        });

       
        setUserDetailsView(selectedData.subject);
        setStartDate(new Date(selectedData.startedAt))
        setExpirationDate(new Date(selectedData.expiresAt))

        // get last evaluation of subject value
        dispatch(evaluationActions.getLastEvaluation(selectedData.subject));
      }
    }
    //eslint-disable-next-line
  }, [props.evaluations.dataById, pageId, dispatch]);

  useEffect(() => {

    if (pageId) {

      let selectedData = props.evaluations?.dataById.length !== 0;
      if(selectedData){
        selectedData =  props.evaluations?.dataById?.success[0];
        setEditSubjectId(selectedData.subject);
        // Filter prepopulate questionnare, tenure, evaluator
        filterEditValues(selectedData.questionaireid, selectedData.tenureid, selectedData.user_id)
      }
    }
      //eslint-disable-next-line
  }, [pageId, props])

  useEffect(() => {
    // Filter prepopulate Edit Subject
    filterSubjectEditValues(EditSubjectId)
      //eslint-disable-next-line
  }, [props.users, EditSubjectId])


  React.useEffect(() => {
    let page = 1;
    let totalItems = 9999;
    dispatch(userActions.getAllActiveUsers(page, totalItems));
    dispatch(tenuresAction.getAllTenures(page, totalItems));
    dispatch(questionnaireActions.getAll(page, totalItems));
  }, [dispatch]);


  useEffect(() => {
    filterQuestionnaireDropdownValue(props?.questionnaires?.list);
    //eslint-disable-next-line
  }, [props.questionnaires]);
  useEffect(() => {
    filterTenuresDropdownValue(props?.tenures?.tenuresList);
    //eslint-disable-next-line
  }, [props.tenures]);
  useEffect(() => {
    filterUserDropdownValue(props.users?.users?.items);
    //eslint-disable-next-line
  }, [props.users]);

  const filterSubjectEditValues = (subjectId = null) => {
    if(subjectId) {
      let userList = props.users.users?.items;
      if(userList){
        userList.map(item => {
          if(item._id === subjectId) {
            let subjectName = item.details[0].firstName + " " + item.details[0].lastName;
            let userId = item._id;
              setSelectedSubject({ label: subjectName, value: userId })
          }
          return null;
        })
      }
    }
  }
  const filterEditValues = (questionnaireId = null, tenureId = null, evaluatorId = null) => {
    
    if(evaluatorId) {
      let userList = props.users.users?.items;
      if(userList){
        userList.map(item4 => {
          if(item4._id === evaluatorId) {
            let subjectName = item4.details[0].firstName + " " + item4.details[0].lastName;
            let userId = item4._id;
            setSelectedEvaluator({ label: subjectName, value: userId })
          }
          return null;
        })
      }
    }

    if(questionnaireId) {
      let questionnaireList = props.questionnaires?.list;
      if(questionnaireList) {
        questionnaireList.map(item2 => {
          if(item2._id ===  questionnaireId) {
           
            setSelectedQuestionnaires({ label: item2.title, value: item2._id })
          }
          return null;
        })
      }
    }
    if(tenureId){
      let tenureList = props.tenures?.tenuresList;
      if(tenureList) {
        tenureList.map(item3 => {
          if(item3._id ===  tenureId) {
            setSelectedTenures({ label: item3.name, value: item3._id })
          }
          return null;
        })
      }
    }


  }
  const filterQuestionnaireDropdownValue = (data) => {
    let optionGroupData = [];
    let questionnairesData = data ?? [];
    const optionGroup = [
      {
        label: "Evaluation",
        type: "evaluation",
        options: [],
      },
      {
        label: "Interview",
        type: "interview",
        options: [],
      },
      {
        label: "Survey",
        type: "survey",
        options: [],
      },
    ];

    questionnairesData.length !== 0 &&
      questionnairesData.forEach((item) => {
        if (item.type === "evaluation") {
          optionGroup[0].options.push({ label: item.title, value: item._id });
        }
        if (item.type === "interview") {
          optionGroup[1].options.push({ label: item.title, value: item._id });
        }
        if (item.type === "survey") {
          optionGroup[2].options.push({ label: item.title, value: item._id });
        }
      });

    optionGroup.map((data) => {
      if (data.options.length !== 0) {
        optionGroupData.push({
          label: data.label,
          options: data.options,
        });
      }
      return null;
    });

    setQuestionnaireDropdown(optionGroupData);
  };

  const filterTenuresDropdownValue = (data) => {
    let tenureData = data ?? [];
    const optionGroup = [
      {
        label: "Evaluation",
        type: "evaluation",
        options: [],
      },
    ];

    tenureData.length !== 0 &&
      tenureData.map((item) => {
        optionGroup[0].options.push({ label: item.name, value: item._id });
        return null;
      });

    setTenuresDropdown(optionGroup);
  };

  const filterUserDropdownValue = (data) => {
    let userList = [];
    let userData = data ?? [];

    userData.length !== 0 &&
      userData.map((item) => {
        userList.push({
          label: item.details[0].firstName + " " + item.details[0].lastName,
          value: item._id,
        });
        return null;
      });

    setUserData(userList);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    // let isEmpty = value.trim();
      setEvaluation({
        ...evaluation,
        [name]: value,
      });

    }


  const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
  ) => {
    return (
      <components.DropdownIndicator {...props}></components.DropdownIndicator>
    );
  };

  const validErrorSubject = {
    control: (provided, state) => ({
      ...provided,
      borderColor: InputError && !evaluation.subject ? "#f14646" : "#2e3648",
      minHeight: 40,
      height: 40,
      background: "#2e3648",
      width: "100%",
      boxShadow: state.isFocused ? null : null,
    }),
  };
  const validErrorEvaluator = {
    control: (provided, state) => ({
      ...provided,
      borderColor: InputError && !evaluation.user_id ? "#f14646" : "#2e3648",
      minHeight: 40,
      height: 40,
      background: "#2e3648",
      width: "100%",
      boxShadow: state.isFocused ? null : null,
    }),
  };

  const customStyles = {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: "#fff",
      // height: 46,
    }),
    option: (base, state) => ({
      ...base,
      color: "#9ba2ce",
      backgroundColor: state.isSelected ? "#32394A" : "#2e3648",
      ":hover": {
        backgroundColor: state.isSelected ? "#2e3648" : "#32394A",
      },
      zIndex: "999",
    }),

    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: "0",
      marginTop: "-5px",
      background: "#2e3648",
      zIndex: "999",
    }),
    menu: (provided) => ({
      ...provided,
      height: "auto",
      maxHeight: "208px",
      position: "absolute",
      display: "block",
      background: "#2e3648",
      zIndex: "999",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "auto",
      padding: "0 5px",
    }),
    input: (provided, state) => ({
      ...provided,

      height: 40,
      color: "#9ba2ce",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: state.isFocused ? 0 : 0,
      paddingLeft: "40px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#9ba2ce",
      paddingLeft: "40px",
      top: "50%",
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingLeft: "40px",
      color: "#9ba2ce",
      top: "50%",
    }),
  };

  const validErrorQuestionnaire = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 40,
      height: 40,
      background: "#2E3648",
      borderColor:
        InputError && !evaluation.questionaireid ? "#f14646" : "#2e3648",
      width: "100%",
      // border: "none",
      boxShadow: state.isFocused ? null : null,
    }),
  };
  const validErrorTenure = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 40,
      height: 40,
      background: "#2E3648",
      borderColor: InputError && !evaluation.tenureid ? "#f14646" : "#2e3648",
      width: "100%",
      // border: "none",
      boxShadow: state.isFocused ? null : null,
    }),
  };
  const customStyles2 = {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: "#fff",
      // height: 46,
    }),
    option: (base, state) => ({
      ...base,
      color: "#9ba2ce",
      backgroundColor: state.isSelected ? "#32394A" : "#2A3143",
      ":hover": {
        backgroundColor: state.isSelected ? "#2A3143" : "#32394A",
      },
    }),

    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: "0",
      marginTop: "-5px",
      background: "#2E3648",
    }),
    menu: (provided) => ({
      ...provided,
      height: "auto",
      maxHeight: "208px",
      position: "absolute",
      display: "block",
      background: "#2E3648",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "auto",
      padding: "0 5px",
    }),
    input: (provided, state) => ({
      ...provided,

      height: 40,
      color: "#fff",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: state.isFocused ? 0 : 0,
      paddingLeft: "20px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#9ba2ce",
      paddingLeft: "20px",
      top: "50%",
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      paddingLeft: "20px",
      color: "#fff",
      top: "50%",
    }),
  };

  const cancelEvaluation = () => {
    history.goBack();
    setEvaluation({
      title: "",
      description: "evaluation",
      tenureid: "",
      subject: null,
      user_id: null,
      questionaireid: "",
      startedAt: null,
      endedAt: "",
      expiresAt: null,
    });
  
    setInputError(false);
    setSelectedEvaluator(null);
    setSelectedQuestionnaires(null);
    setSelectedSubject(null);
    setSelectedTenures(null);
    setUserDetailsView(null);
  }
  const submitEvaluation = () => {
    let isValidTitle =  evaluation.title.trim();

    if (
      !isValidTitle ||
      !evaluation.startedAt ||
      !evaluation.user_id ||
      !evaluation.questionaireid ||
      !evaluation.subject ||
      !evaluation.tenureid
    ) {
      setInputError(true);
    } else {
      if(isAdd){
        dispatch(evaluationActions.create(evaluation));
        setEvaluation({
          title: "",
          description: "evaluation",
          tenureid: "",
          subject: null,
          user_id: null,
          questionaireid: "",
          startedAt: null,
          endedAt: "",
          expiresAt: null,
        });
        setInputError(false);
      } else {
        let evaluationId = pageId;
        dispatch(evaluationActions.update(evaluation, evaluationId))
        setEvaluation({
          title: "",
          description: "evaluation",
          tenureid: "",
          subject: null,
          user_id: null,
          questionaireid: "",
          startedAt: null,
          endedAt: "",
          expiresAt: null,
        });
        setUserDetailsView(null);
        setInputError(false);
      }
    }
  };

  const handleQuestionnaire = (data) => {
    setSelectedQuestionnaires(data);
    setEvaluation({ ...evaluation, questionaireid: data.value });
   
  };
  const handleTenure = (data) => {
    setSelectedTenures(data)
    setEvaluation({ ...evaluation, tenureid: data.value });
  };

  const handleExpirationDate = (date) => {
    setExpirationDate(date);
    setIsEditedExpire(true);

    if(isAdd) {
      setEvaluation({ ...evaluation, expiresAt: moment(date).add('days', 1).format()  });
    } 
    // Custom configure for date utc
    if(isAdd === false && IsEditedExpire === true){
      setEvaluation({ ...evaluation, expiresAt: moment(date).utc().format() });
    }

    if(isAdd === false && IsEditedExpire === false){

      setEvaluation({ ...evaluation, expiresAt: moment(date).add('days', 1).format() }); 
    }
  };

  const handleEvaluationDate = (date) => {
    setStartDate(date);
    setIsEditedEval(true);

    if(isAdd) {
      setEvaluation({ ...evaluation, startedAt: moment(date).add('days', 1).format() });
    } 
  

     // Custom configure for date utc
    if(isAdd === false && IsEditedEval === true){
      setEvaluation({ ...evaluation, startedAt: moment(date).utc().format() });
    }

    if(isAdd === false && IsEditedEval === false){
      setEvaluation({ ...evaluation, startedAt: moment(date).add('days', 1).format() });
    }

  };

  const handleSubject = (data) => {
    let userId = data.value;
    setSelectedSubject(data);
    setEvaluation({ ...evaluation, subject: userId });
    setIsDisplayUserDetails(true);
    setUserDetailsView(userId);
  
    dispatch(evaluationActions.getLastEvaluation(userId));
  };

  const handleEvaluator = (data) => {
    setSelectedEvaluator(data);
    setEvaluation({ ...evaluation, user_id: data.value });
  };

  let isValidTitle = evaluation.title.trim() === '';

  return (
    <CustomContentWrapper displayUserWrapper={isDisplayUserDetails}>
      <ContentWrapper>
        <LabelCustom className="main-label">
          {isAdd ? "Create" : "Edit"} an Evaluation
        </LabelCustom>
        <InputTextCustom
          placeholder={"Title"}
          name={"title"}
          value={evaluation.title}
          inputOnChange={handleChangeInput}
          isError={isValidTitle && InputError}
        />
        <FindUserWrapper>
          <LabelCustom className="sub-label" style={{ marginBottom: "30px" }}>
            Subject
          </LabelCustom>

          <SearchIconFindUser src={search_icon} alt=""></SearchIconFindUser>
          <Select
            value={SelectedSubject}
            captureMenuScroll={false}
            placeholder="Find User"
            styles={{ ...customStyles, ...validErrorSubject }}
            options={UserData}
            openMenuOnClick={false}
            isSearchable={true}
            menuPlacement="bottom"
            onChange={(data) => handleSubject(data)}
            menuPortalTarget={document.getElementById("root")}
            hideSelectedOptions={false}
            classNamePrefix="select"
            maxMenuHeight={200}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          ></Select>
          {InputError && !evaluation.subject && (
            <label className="Error__message">Please fill-up this field</label>
          )}
        </FindUserWrapper>

        <GridContainer isShowUserDetails={isDisplayUserDetails}>
          <div>
            <label className="label__questionnaires"> Questionnaires </label>
            <Select
              value={SelectedQuestionnaires}
              onChange={(e) => handleQuestionnaire(e)}
              components={{ DropdownIndicator }}
              captureMenuScroll={false}
              placeholder=""
              styles={{ ...customStyles2, ...validErrorQuestionnaire }}
              options={questionnaireDropdown}
              openMenuOnClick={false}
              isSearchable={true}
              menuPlacement="bottom"
              menuPortalTarget={document.getElementById("root")}
              hideSelectedOptions={false}
              classNamePrefix="select"
              maxMenuHeight={200}
            ></Select>
            {InputError && !evaluation.questionaireid && (
              <label className="Error__message">
                Please fill-up this field
              </label>
            )}
          </div>
          {isDisplayUserDetails === false && (
            <div>
              <DatePickerWrapper
                className={
                  InputError && !evaluation.startedAt ? "error_date" : ""
                }
                isShowUserDetails={isDisplayUserDetails}
              >
                {/* <label className="label_date_questionnaires"> Test </label> */}
                <img
                  src={admin_calendar}
                  alt=""
                  className="admin_calendar_icon1"
                ></img>
                <DatePicker
                  selected={ startDate }
                  endDate={ExpirationDate}
                  maxDate={ExpirationDate}
                  onChange={(date) => handleEvaluationDate(date)}
                  dateFormat="MMMM dd, yyyy"
                  placeholderText="Evaluation Date"
                />
              </DatePickerWrapper>
              {InputError && !evaluation.startedAt && (
                <label className="Error__message">
                  Please fill-up this field
                </label>
              )}
            </div>
          )}
        </GridContainer>

        <GridContainer isShowUserDetails={isDisplayUserDetails}>
          <div>
            <label className="label__tenures"> Tenures </label>
            <Select
              value={SelectedTenures}
              onChange={(e) => handleTenure(e)}
              components={{ DropdownIndicator }}
              captureMenuScroll={false}
              placeholder=""
              styles={{ ...customStyles2, ...validErrorTenure }}
              options={TenuresDropdown}
              openMenuOnClick={false}
              isSearchable={true}
              menuPlacement="bottom"
              menuPortalTarget={document.getElementById("root")}
              hideSelectedOptions={false}
              classNamePrefix="select"
              maxMenuHeight={200}
            ></Select>
            {InputError && !evaluation.tenureid && (
              <label className="Error__message">
                Please fill-up this field
              </label>
            )}
          </div>

          {isDisplayUserDetails && (
            <div>
              <DatePickerWrapper
                className={
                  InputError && !evaluation.startedAt ? "error_date" : ""
                }
                isShowUserDetails={isDisplayUserDetails}
              >
                {/* <label className="label_date_questionnaires"> Test </label> */}
                <img
                  src={admin_calendar}
                  alt=""
                  className="admin_calendar_icon1"
                ></img>
                <DatePicker
                   selected={startDate && IsEditedEval === false ? new Date(moment(startDate).utc().format("MMMM DD, YYYY"))  :  startDate  }
                   endDate={ExpirationDate}
                   maxDate={ExpirationDate}
                  onChange={(date) => handleEvaluationDate(date)}
                  placeholderText="Evaluation Date"

                  dateFormat="MMMM dd, yyyy"
                />
              </DatePickerWrapper>
              {InputError && !evaluation.startedAt && (
                <label className="Error__message">
                  Please fill-up this field
                </label>
              )}
            </div>
          )}

          <div>
            <DatePickerWrapper isShowUserDetails={isDisplayUserDetails}>
              {/* <label className="label_date_tenures"> Tenure Date  </label> */}
              <img
                src={admin_calendar}
                alt=""
                className="admin_calendar_icon2"
              ></img>
              <DatePicker
                
                selected={ExpirationDate && IsEditedExpire === false ? new Date(moment(ExpirationDate).utc().format("MMMM DD, YYYY"))  :  ExpirationDate  }
                startDate={startDate}
                minDate={startDate}
                dateFormat="MMMM dd, yyyy"
                onChange={(date) => handleExpirationDate(date)}
                placeholderText="Expiration Date"
              />
            </DatePickerWrapper>
          </div>
        </GridContainer>

        <FindUserWrapper style={{ marginTop: "20px", marginBottom: "20px" }}>
          <LabelCustom className="sub-label" style={{ marginBottom: "30px" }}>
            Evaluator
          </LabelCustom>
          <SearchIconFindUser src={search_icon} alt=""></SearchIconFindUser>
          <Select
            value={SelectedEvaluator}
            onChange={(data) => handleEvaluator(data)}
            captureMenuScroll={false}
            placeholder="Find User"
            styles={{ ...customStyles, ...validErrorEvaluator }}
            options={UserData}
            openMenuOnClick={false}
            isSearchable={true}
            menuPlacement="bottom"
            menuPortalTarget={document.getElementById("root")}
            hideSelectedOptions={false}
            classNamePrefix="select"
            maxMenuHeight={200}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          ></Select>
          {InputError && !evaluation.user_id && (
            <label className="Error__message">Please fill-up this field</label>
          )}
        </FindUserWrapper>

        <SaveAndCancelButton
          cancelFn={() => cancelEvaluation()}
          saveFn={() => submitEvaluation()}
          submitLabel={isAdd ? "Add" : " Edit"}
        />
      </ContentWrapper>

      <When condition={isDisplayUserDetails === true}>
        <PreviewUserDetailsWidget {...props} subjectId={UserDetailsView} />
      </When>
    </CustomContentWrapper>
  );
});

export default CreateEditEvaluationPage;
