//npm packages
import React from 'react';
import cc from 'classcat';
//styles
import './ChallengesHistoryWidget.sass';
//images
import dot from '../_templates/dot.png';
// import ranking from '../_templates/ranking-grayscale.png';
// import questBoat from '../_templates/quest-mission-boat.png';

const ChallengesHistoryWidget = () => {

    return (
        <div className="challenges-history-widget">
            <div className="challenges-history-title">
                <h6>Challenges History</h6><img src={dot} className="options" alt="options" />
            </div>
            <div className={cc({'card': true, 'z-depth-0': true})}>
                <center><p>No history</p></center>
                {/* display history line if there's users challenge history 
                <div className="challenges-history">
                    <div className="challenge-history-icon">
                        <img src={ranking} alt="badge" className="challenge-history-badge"/>
                        <div className="challenge-history-line"></div>
                    </div>
                    <div className="challenge-history-desc">
                        <div>
                            <p>Received Hackerman Badge</p>
                            <i>5 mins ago</i>
                        </div>
                        <p>Received <b>Hackerman Badge</b> upon completing the challenge by <b>Jonathan Wick</b></p>
                        <img src={questBoat} alt="challenge_img"/>
                    </div>
                </div>*/}
            </div>
        </div>
    );
};

export default ChallengesHistoryWidget;