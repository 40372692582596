const CryptoJS = require("crypto-js");
// const key = process.env.CRYPTO_KEY;
const key = "55a51621a6648525";
const keyutf = CryptoJS.enc.Utf8.parse(key);
const iv = CryptoJS.enc.Base64.parse(key);


export const decryptUserDetails = (data = "test") => {
	let decStr = null; // eslint-disable-line
	const dec = CryptoJS.AES.decrypt(
		{ ciphertext: CryptoJS.enc.Base64.parse(data) },
		keyutf,
		{
			iv: iv
		});
	return decStr = CryptoJS.enc.Utf8.stringify(dec)
}	
export const encrypted = (data = 'test') => {
	const enc = CryptoJS.AES.encrypt(data, keyutf, { iv: iv });
	const encStr = enc.toString();
	return encStr;
}

export const decryptUserEmail = (email = 'test') => {
	let decStr = null; // eslint-disable-line
	const dec = CryptoJS.AES.decrypt(
		{ ciphertext: CryptoJS.enc.Base64.parse(email) },
		keyutf,
		{
			iv: iv
		});
	return decStr = CryptoJS.enc.Utf8.stringify(dec)
}