import React from 'react';
import { connect } from 'react-redux';
import star_credit from '../_templates/star_credit.png';
import award from '../_templates/award.svg';
import zap from '../_templates/zap.svg';
import heart from '../_templates/heart.svg';
import './CurrentStats.sass'
import { statsActions } from '../_core/actions/stats';

class CurrentStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateStats: '',
      currentStats: {}
    }
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    const secret = JSON.parse(localStorage.getItem("secretCredentials"));
    const unknown = secret ? secret._id : user.user_id

    this.props.dispatch(statsActions.getOneUser(unknown));
  }

  static getDerivedStateFromProps(props, state) {
    if (props.stats) {
      var updatedStats;
      if (state.stats) {
        updatedStats = props.stats
      } else {
        updatedStats = props.stats
      }
      return {
        currentStats: updatedStats
      }
    }
    return null;
  }

  render() {
    let { currentStats } = this.state;

    return (
      <div className="current-stats-wrapper">
        <h6>Current Stats</h6>
        <div className="current-stats-container">
          <div className="stats stats-credit">
            <div className="stats-counter">
              <div><img src={star_credit} alt="credit" className="stats-icon" /></div>
              <div>{currentStats?.credits || 0}</div>
              <div>{` Credit ${currentStats?.credits > 1 ? "points" : "point"}`}</div>
            </div>
          </div>
          <div className="stats">
            <div className="stats-counter">
              <div><img src={award} alt="award" className="stats-icon" /></div>
              <div>{currentStats?.points || 0}</div>
              <div>{" Credit points"}</div>
            </div>
          </div>
          <div className="stats">
            <div className="stats-counter">
              <div><img src={heart} alt="heart" className="stats-icon" /></div>
              <div>{currentStats?.hearts || 0}</div>
              <div>{" Credit points"}</div>
            </div>
          </div>
          <div className="stats">
            <div className="stats-counter">
              <div><img src={zap} alt="tip" className="stats-icon" /></div>
              <div>{currentStats?.tips || 0}</div>
              <div>{" Credit points"}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const { getStats } = state;
  return {
    stats: getStats?.stats?.success && getStats.stats.success[0],
  };
}

const connectedCurrentStats = connect(mapStateToProps)(CurrentStats);
export { connectedCurrentStats as CurrentStats };
