import React, { useState } from "react";
import {useDispatch } from "react-redux";
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import {useDropzone} from 'react-dropzone';
import Select, { components } from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { DragDropImage,} from "../Admin/Components/ImageUploader/upload";

import dropIcon from '../_templates/dropIcon.png'
import calendarIcon from '../_templates/calendar.png';
import avatar from '../_templates/man.png'
import imgNotFound from '../_templates/img-not-found.png';
import closeIcon from '../_templates/x_square.png';

import { challengeActions } from "../_core/actions/challenge";
import { statsService } from '../_core/services/stats';
import { challengeService } from '../_core/services/challenge';



const CreateChallengeModal = (props) => {
    const dispatch = useDispatch();
    const [InputError, setInputError] = useState(false);
    const [files, setFiles] = React.useState([]);
    const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
    const [count, setCount] = React.useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [ExpirationDate, setExpirationDate] = useState(new Date());
    const [IsEditedExpire, setIsEditedExpire] = React.useState(false)
    //const [TimeLimitError, setTimeError] = useState(false); 
    const userDetails = JSON.parse(localStorage.getItem("user"));
    const [stats, setStats] = React.useState({});

    const [challenge, setChallenge] = useState({
        questId:"",
        recurring: false,
        timeLimit: "",
        active:1,
        requiredBadges:"",
        title: "",
        description: "",
        bestTime: "00:00:00",
        location: "",
        lat:"",
        lon:"",
        eneryValue:"",
        credits:"",
        exp:"100",
        expiresAt: moment(ExpirationDate).utc().format(),
        startsAt: moment(startDate).utc().format(),
        images:""
    });

    React.useEffect(()=>{
        try{
            statsService.getOneUser(userDetails.user_id).then(res=>{
                setStats(res.success);
            }).catch(error=>{});
        }catch(err){
            console.log(err);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setStats, userDetails.user_id]);


    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        if(name === "description"){
            setCount(value.length)
        }
        setChallenge({
            ...challenge,
            [name]: value,
        });
    }

    const handleSelect = (data) => {
        const names = data.target.name
        const value = data.target.value
        setChallenge({ ...challenge, [names]: value });
    };

    const handleFromDate = (date) => {
        setStartDate(date);
        const days = moment(date).diff(moment(startDate), "days") + 1
        setChallenge({ ...challenge, startsAt: moment(date).utc().format(), duration: days });
    };

    const handleToDate = (date) => {
        setExpirationDate(date);
        setIsEditedExpire(true);
        const days = moment(date).diff(moment(startDate), "days") + 1
        setChallenge({ ...challenge, expiresAt: moment(date).utc().format(), duration: days });
    };

    const removeSelectedImage = () => {
        setFiles([]);
    } 
    

    const { ValueContainer, Placeholder } = components;

    const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused} className="menu">
            {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
            child && child.type !== Placeholder ? child : null
        )}
        </ValueContainer>
    );
    };

    const customStyles2 = {
        control: (base, state) => (InputError ? {
            ...base,
            background: "transparent",
            height:"60px",
            // match with the menu
            borderRadius: '5px',
            marginBottom:"15px",
            // Overwrittes the different states of border
            borderColor: "#DDDDDD",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
              // Overwrittes the different states of border
              borderColor: "#DDDDDD",
            },
          }:{
            ...base,
            background: "transparent",
            height:"60px",
            borderRadius: '5px',
            marginBottom:"20px",
            borderColor: "#DDDDDD",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
              borderColor: "#DDDDDD",
            },
          }),
        container: (provided, state) => ({
            ...provided,
            marginTop: 0,
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            marginTop: '-15px',
          }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: "#000000",
            "&:hover": {
                color: state.isFocused ? "#DDDDDD" : "#DDDDDD",
              }, 
        }),
        option: (base, state) => ({
            ...base,
            color: "#000000",
            backgroundColor: state.isSelected ? "#DDDDDD" : "#FFFFFF",
            ":hover": {
              backgroundColor: state.isSelected ? "#FFFFFF" : "#DDDDDD",
            },
            zIndex: "999",
          }),
    
        menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        }),
        menu: (provided) => ({
          ...provided,
          height: "auto",
          maxHeight: "208px",
          position: "absolute",
          display: "block",
          background: "#FFFFFF",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible"
        }),
        input: (provided, state) => ({
            ...provided,
            height: 35,
            color: '#9BA3CA',
            lineHeight: state.isFocused ? 35 : 0,
            transition:  "top 0.1s, font-size 0.1s"
        }),
        placeholder: (provided, state) => (InputError ? {
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
            transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
            background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
            color: state.hasValue ? "#9BA3CA" :"#f14646",
        }:{
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
            transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
            background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
            color: "#9BA3CA",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#333333',
            fontSize:"14px",
            fontWeight:'normal',
            top:'35%'
          }),
      };

      const {getRootProps, getInputProps, open} = useDropzone({
        accept: 'image/x-png, image/png, image/jpg, image/jpeg, image/gif',
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
            })));
            setChallenge({
                ...challenge,
                images:acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                    }))[0],
              });
    
        }
        });


    const energyOptions = [
        { value: 1, label: '1'},
        { value: 2, label: '2'},
        { value: 3, label: '3'},
        { value: 4, label: '4'},
        { value: 5, label: '5'},
        { value: 6, label: '6'},
        { value: 7, label: '7'},
        { value: 8, label: '8'},
        { value: 9, label: '9'},
        { value: 10, label: '10'}
    ];

    const creditsOptions = [
        { value: 10, label: '10'},
        { value: 20, label: '20'},
        { value: 50, label: '50'},
        { value: 100, label: '100'},
        { value: 250, label: '250'},
        { value: 300, label: '300'},
        { value: 500, label: '500'},
    ];


    const getExpDate = () => {
        const currentTime = Date.parse(startDate);
        const dateTime = Date.parse(ExpirationDate);

        let getDate = ExpirationDate ?
            moment(currentTime).isSameOrAfter(moment(dateTime)) ?
                moment(ExpirationDate).utc().format('MMMM D, YYYY - hh:mm:ss') + ' Expired'
                : 'Expires ' + moment(ExpirationDate).utc().format('MMMM D, YYYY - hh:mm:ss') + ' PHT'
            : 'No date';

        return getDate
    };


    const getTimeLimitString = challenge.timeLimit !== '' || !challenge.timeLimit + ":00:00" ? challenge.timeLimit : false;
    //get challenge description, if no image include timelimit
    const displayTimeNoImage = () => {
            var displayTime = '';
            if(getTimeLimitString){
                if(!challenge.images){
                    displayTime = challenge.timeLimit + ":00:00";
                }
            };
    
            return(
                <p className="card-text">
                    <span>
                        {displayTime}
                    </span>
                    {getTimeLimitString && !challenge.images && ' - '}
                    {challenge.description || ''}
                </p>
            );
        };

    const challengeDateCreated = moment(challenge.createdAt).utc().fromNow().replace('ago', '');
    const challengePosterName = userDetails.firstName ? userDetails.firstName + ' ' + userDetails.lastName : ' No name specified';


    
    //get challenge image else challenge title if no image
    const thumbs = files.map(file => {return file.preview});
    const challengeImage = (challenge.images ?
        <div className="img-overlay">
        <div></div>
        <img className="challenger-avatar" onError={(e)=> e.target.src = avatar} src={userDetails.avatar || avatar} alt="..." />
        <img className="card-img-top challenge-img" onError={(e)=> e.target.src = imgNotFound} src={thumbs[0] || imgNotFound} alt="..." />
        {getTimeLimitString &&
        <p className="challenge-time eng-time-on">
            {getTimeLimitString + ":00:00" || ''}
        </p>
        }
        </div>
        : <div className="challenge-no-images">
            <img className="challenger-avatar-no-img" onError={(e)=> e.target.src = avatar} src={userDetails.avatar || avatar} alt="..." />
            <h6 className="challenge-no-image-title" style={{fontSize:'16px'}}>{challenge.title || ''}</h6>
        </div>
    );

    const handleNumber = (e) => {
        const value = e.target.value.replace(/[^\d.]/ig, "");
        const total = parseInt(value);
        if(value.length === 0) {
            setChallenge({ ...challenge, [e.target.name]: '', });
        } else {
            setChallenge({ ...challenge, [e.target.name]: total, });
        }
    };

    const submitUserChallenge = () => {
        if(
            !challenge.title ||
            !challenge.description ||
            !challenge.expiresAt ||
            !challenge.eneryValue ||
            !challenge.credits ||
            !challenge.timeLimit
        ) {
            setInputError(true);
            //setTimeError(true)
        }else{
            if(isAdd){
                const userCredits = Array.isArray(stats) && stats.map(name => name.credits);
                if(challenge.credits <= userCredits[0]) {
                    let challengeItems ={
                        questId: challenge.questId,
                        recurring: challenge.recurring,
                        timeLimit: challenge.timeLimit+":00:00",
                        active: challenge.active,
                        requiredBadges: challenge.requiredBadges,
                        title: challenge.title,
                        description: challenge.description,
                        bestTime: challenge.bestTime,
                        location: challenge.location,
                        lat: challenge.lat,
                        lon: challenge.lon,
                        eneryValue:challenge.eneryValue,
                        credits:challenge.credits,
                        exp:challenge.exp,
                        expiresAt: challenge.expiresAt,
                        startsAt: challenge.startsAt,
                        images:challenge.images,
                        status: challenge.status
                    }
                    let credit ={
                        type:"available",
                        value:"-"+challenge.credits,
                        origin:"challenge"
                    }
                    dispatch(challengeActions.createChallenge(challengeItems,'user'))
                    challengeService.addCredit(userDetails.user_id , credit)
                    setInputError(false);
                    //setTimeError(false)
                    props.handleCloseModal(false)
                    props.loading(true)
                    cancelUserChallenge()
                }else{
                    Swal.fire({
                        title: "Insufficient Credits",
                        type: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#ff0000',
                        cancelButtonColor: '#d33',
                        background: '#ffff',
                    }).then((res) => {
                    
                        cancelUserChallenge()
                    })
                }  
            }
        }
    }
    const cancelUserChallenge = () => {
        props.handleCloseModal(false)
        setInputError(false);
        setChallenge({
            questId:"",
            recurring: false,
            timeLimit: "",
            active:1,
            requiredBadges:"",
            title: "",
            description: "",
            bestTime: "00:00:00",
            location: "",
            lat:"",
            lon:"",
            eneryValue:"",
            credits:"",
            exp:"100",
            expiresAt: moment(ExpirationDate).utc().format(),
            startsAt: moment(startDate).utc().format(),
            images:""
        });
        setCount(0)
        setStartDate(new Date())
        setExpirationDate(new Date())
        //setTimeError(false)
        setFiles([])

    }
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        toggle={props.toggle}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
        centered
        className="AddUserChallengeModal"
      >
        <div className="row center create-challenge-container"
            style={{
                width: "90%",
                height: "auto",
                margin: "auto",
                padding: "20px 0px 20px",
                borderBottom: "1px solid #979797"
            }}>
            
            <p className="usermodal_title left"> Create Challenge</p>
            <button className="close-modal" onClick={cancelUserChallenge}>
                <img height={20} src={closeIcon} alt="" style={{ marginRight: 7 }}/>
			</button>
        </div>

        <div className="request-details-container">
                <div className="request-details" style={{marginRight:"50px"}}>
                    <div className="userChallenge-name">
                        <label className="challenge-title" htmlFor="">
                            Challenge Name
                        </label>
                        <input type="text" className="input-userchallenge"  name="title" onChange={handleChangeInput}/>
                        {InputError && !challenge.title &&(
                            <div className="ErrorTimeLimit">Please fill-up this field.</div>
                        )}
                    </div>
                    <div className="userChallenge-description">
                        <label className="challenge-title" htmlFor="">
                            Description
                        </label>
                        <textarea
                            className="challenge-description"
                            //value={props.value}
                            name="description"
                            maxlength="250"
                            cols="30"
                            placeholder=" "
                            style={{ resize: 'none' }}
                            row="10"
                            onChange={handleChangeInput}
                        >
                        </textarea>
                        <p className="countChallenge">{count}</p>
                        {InputError && !challenge.description &&(
                            <div className="ErrorTimeLimit">Please fill-up this field.</div>
                        )}
                    </div>
                    <div className="userChallenge-timeLimit">
                        <label className="challenge-title" htmlFor="">
                            Time limit
                        </label>
                        <label data-domain="hrs" className="text">
                            <input type="text" className="input-userchallenge" name="timeLimit" placeholder="12" value={challenge.timeLimit} onChange={(e) => handleNumber(e)}/>
                        </label>
                        {InputError && !challenge.timeLimit && (
                            <small>Please fill-up this field.</small>
                        )}
                    </div>
                    <div className="userChallenge-timeLimit">
                        <label className="challenge-title" htmlFor="">
                            Energy Value
                        </label>
                        <Select
                            defaultValue={energyOptions.find(option => option.value === challenge.eneryValue || '' )}
                            onChange={(statusValue)=> {handleSelect({target: { name:'eneryValue', value: statusValue === null ? '' : statusValue.value }})}}
                            components={{
                                ValueContainer: CustomValueContainer
                            }}
                            captureMenuScroll={false}
                            placeholder=""
                            styles={customStyles2}
                            options={energyOptions}
                            openMenuOnClick={false}
                            isSearchable={false}
                            menuPlacement="bottom"
                            hideSelectedOptions={false}
                            isClearable={true}
                            maxMenuHeight={200}
                            className="basic-single"
                            classNamePrefix="select"
                            name="eneryValue"
                        />
                        {InputError && !challenge.eneryValue &&(
                            <div className="ErrorTimeLimit">Please fill-up this field.</div>
                        )}
                    </div>
                    <div className="userChallenge-timeLimit">
                        <label className="challenge-title" htmlFor="">
                            Credits
                        </label>
                        <Select
                            defaultValue={creditsOptions.find(option => option.value === challenge.credits || '' )}
                            onChange={(statusValue)=> {handleSelect({target: { name:'credits', value: statusValue === null ? '' : statusValue.value }})}}
                            components={{
                                ValueContainer: CustomValueContainer
                            }}
                            captureMenuScroll={false}
                            placeholder=""
                            styles={customStyles2}
                            options={creditsOptions}
                            openMenuOnClick={false}
                            isSearchable={false}
                            menuPlacement="bottom"
                            hideSelectedOptions={false}
                            isClearable={true}
                            maxMenuHeight={200}
                            className="basic-single"
                            classNamePrefix="select"
                            name="credits"
                        />
                        {InputError && !challenge.credits &&(
                            <div className="ErrorTimeLimit">Please fill-up this field.</div>
                        )}
                    </div>
                    <div className="userChallenge-timeLimit">
                        <div className="row">
                            <div className="col s12 m6 l6">
                                <label className="challenge-title" htmlFor="">
                                        From
                                    </label>
                                <div className="input-custom">
                                    <DatePicker
                                        selected={startDate}
                                        startDate={startDate}
                                        endDate={ExpirationDate}
                                        value={startDate}
                                        onChange={(date) => handleFromDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        selectsStart
                                        maxDate={ExpirationDate}
                                        className="datepicker"
                                    />
                                </div>
                            </div>
                            <div className="col s12 m6 l6">
                                <label className="challenge-title" htmlFor="">
                                    To
                                </label>
                                <div className="input-custom">
                                    <DatePicker
                                        selected={ExpirationDate && IsEditedExpire === false ?  new Date(ExpirationDate)  :  ExpirationDate}
                                        startDate={startDate}
                                        minDate={startDate}
                                        endDate={ExpirationDate}
                                        selectsEnd
                                        onChange={(date) => handleToDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        className="datepicker"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="userChallenge-timeLimit">
                        <label className="challenge-title" htmlFor="">
                            Cover photo
                        </label>
                        <div {...getRootProps({className: 'dropzone Userdropzone'})}>
                            <div className={files[0] ? 'active-dz': 'unactive-dz'} >
                                <input {...getInputProps()} />
                                <div>
                                    <p className="dropTitle" style={{display:'inline-flex'}}>
                                        <img height={20} src={dropIcon} alt="" style={{ marginRight: 8 }}/>
                                            Drag and drop files here 
                                    </p>
                                    <p className="dropTitle">or</p>
                                </div>
                                <button onClick={open} className="custom-file-upload-challenge">
                                    Upload
                                </button>
                            </div>
                            <DragDropImage removeImage={removeSelectedImage} selectedImage={files}/>
                        </div>
                    </div>
                     {/* Temporay Guideline in the bottom*/}
                     <div className="row">
                        <div className="col s12" style={{padding:"0px"}}>
                            <div className="phr-policy-container">
                                <p>Here is where content for simple reminders of policy rules</p>
                                <br />
                                <p>1. First item describes the first rule</p>
                                <p>2. Second item to identify another point on the request policy fo this request type</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="request-history">
                    <span className="preview-title">Challenge Preview</span>
                    <div className="card-body challenge-details">
                        {challengeImage}
                        <div className="preview-body">
                            {ExpirationDate &&
                            <p className="challenge-expires" style={{display:'inline-flex'}}>
                                <img height={20} src={calendarIcon} alt="" style={{ marginRight: 7 }}/>
                                <span className={getExpDate().includes('Expired') ? 'expired-date' : ''} style={{fontSize:"10px",marginTop:" 3px"}}>
                                    {getExpDate()}
                                </span>
                            </p>
                            }
                            {challenge.images ? <h6 className="card-title">{challenge.title || ''}</h6> : ''}
                            {displayTimeNoImage()}
                            <button className="btn eng-challenge-btn">Accept</button>
                            <br />
                            <i style={{fontSize:'10px'}}><small>Posted <b>{challengeDateCreated}</b> ago by
                            <b> {challengePosterName}</b></small>
                            </i>
                        </div>
                       
                    </div>
                </div>
                <div className="footer-container">
                    <div>
                        <button type="submit" className="submit-challenge" onClick={() => submitUserChallenge()}>Save</button>
                        <button className="cancel-challenge" onClick={() => cancelUserChallenge()} >Cancel</button>
                    </div>
                </div>
        </div>


      </Modal>
    </>
  );
};

export default CreateChallengeModal;