import { get } from '../helpers/api';
import { userService } from '../services/user';

// TODO: Use `build-url`
function getRequests(options) {
    return get(
        [
            '/api/v1/request',
            '?filter=',
            [
                'active:1',
                !!options.userId ?
                `user_id:${options.userId}` :
                ''
            ].join(','),
            options.getAll === false ?
            `&page=${options.selectPage}&items-per-page=${options.itemsPerPage}` :
            ''
        ].join('')
    )
    .then(handleResponse)
}


function getActivities(id) {
    return get(`/api/v1/user/${id}/activity`).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}

export default { getRequests, getActivities };