import React from 'react';
import { connect, useDispatch } from "react-redux";
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { withRouter } from 'react-router-dom';

import { leaveTypeActions } from '../_core/actions/leaveType';
import { essActions } from '../_core/actions/ess';

import Table from '../Table/Table';

import './RequestsWidget.scss';

import clock from '../_templates/clock_icon.png';
import filter from '../_templates/filter.png';
import statusPillApproved from '../_templates/status_pill_approved.png';
import statusPillCompleted from '../_templates/status_pill_completed_new.svg';
import statusPillOnHold from '../_templates/status_pill_on_hold.png';
import statusPillInProgress from '../_templates/status_pill_in_progress.svg';
import statusPillPending from '../_templates/status_pill_pending.png';
import statusPillRejected from '../_templates/status_pill_rejected.png';

const constructTypeCell = (string) => <p style={{ fontWeight: 600, textTransform: 'uppercase' }}>{string}</p>;
const getStringInitials = (arrayOfString = []) => arrayOfString?.length > 0 && arrayOfString?.join(" ").trim().match(/\b(\w)/g)?.join('').toLowerCase();
const formatRequest = (requests = [], leaveTypes = {}) => (
    requests.map(request => {
        let dateRange = [];
        let startDate = moment(request.startedAt);
        let endedAt = moment(request.endedAt);
        let datediff = startDate.diff(endedAt, "days");

        while (datediff + 2 <= 0) {
        startDate.add(1, "days").format("dddd, MMMM DD YYYY");
        dateRange.push(startDate.format("dddd, MMMM DD YYYY"));
        datediff++;
        }

        let hasWeekEnd = dateRange.map(checkDays => (
        checkDays.split(',')[0]
        )).includes("Saturday");
        
        let hasWeekEnd2 = dateRange.map(checkDays => (
        checkDays.split(',')[0]
        )).includes("Sunday");

        const formattedRequest = {
            Reference: {
                _id: 'Reference',
                element: <p>{request.refCode}</p>,
                value: request.refCode
            },
            title: {
                _id: 'title',
                element: <p>{request.subject}</p>,
                value: request.subject
            },
            type: {
                _id: 'type',
                element: '',
                value: ''
            },
            dates: {
                _id: '_id',
                element: (
                    <div style={{
                        display: 'flex',
                        alignContent: 'center'
                    }}>
                        <img height={20} src={clock} alt="" style={{ marginRight: 8 }}/>
                        <p>
                            {
                                [
                                    moment(request.startedAt).utc().format('MM/DD/YYYY'),
                                    moment(request.endedAt).utc().format('MM/DD/YYYY')
                                ].join(' - ')
                            }
                        </p>
                    </div>
                ),
                value: moment(request.startedAt).utc().format('MM/DD/YYYY').toString()
            },
            days: {
                _id: 'days',
                element: (
                    <p>
                        <img src="" alt=""/>
                        {
                            hasWeekEnd 
                            ? hasWeekEnd2 
                              ? moment(request.endedAt).diff(moment(request.startedAt), "days") - 1
                              : moment(request.endedAt).diff(moment(request.startedAt), "days")
                            : moment(request.endedAt).diff(moment(request.startedAt), "days") + 1
                        }
                    </p>
                ),
                value: Number(
                    hasWeekEnd 
                    ? hasWeekEnd2 
                      ? moment(request.endedAt).diff(moment(request.startedAt), "days") - 1
                      : moment(request.endedAt).diff(moment(request.startedAt), "days")
                    : moment(request.endedAt).diff(moment(request.startedAt), "days") + 1
                )
            },
            status: {
                _id: 'status',
                element: (
                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                        {
                            request.subtype === 'technical support'  && request.status === 'on hold' ? <img src={statusPills['in progress']} alt="status-pill"/> : request.subtype === 'technical support'  && request.status === 'approved' ? <img src={statusPills['completed']} alt="status-pill"/> : statusPills[request.status] ?
                            <img src={statusPills[request.status]} alt="status-pill"/> :
                            <p style={{ textTransform: 'capitalize', fontWeight: 600 }}>{ request.status }</p> 
                        }
                    </div>
                ),
                value: request.status
            }
        };

        switch(request.type) {
            case 'request':
                if(request.subtype === 'certificate of employment'){
                    const initials = getStringInitials([request.subtype]);

                    formattedRequest.type.element = constructTypeCell(initials);
                    formattedRequest.type.value = initials;
                } else {
                    const initials = getStringInitials([request.subtype, request.type]);
                    
                    formattedRequest.type.element = constructTypeCell(initials);
                    formattedRequest.type.value = initials;
                }

                break;
            case 'leave':
                const initials = getStringInitials([leaveTypes[request.subtype]?.name]);

                formattedRequest.type.element = constructTypeCell(initials);
                formattedRequest.type.value = initials;
                break;
            case 'support':
                if(request.subtype === 'hr concern'){
                    formattedRequest.type.value = 'hr'
                    formattedRequest.type.element = constructTypeCell('hr');
                } else {
                    const initials = getStringInitials([request.subtype]);

                    formattedRequest.type.element = constructTypeCell(initials);
                    formattedRequest.type.value = initials;
                }

                break;
            default:
                break;
        }

        return {
            _id: request._id,
            cells: Object.values(formattedRequest)
        };
    }) || []
);

const statusPills = {
    'approved': statusPillApproved,
    'completed': statusPillCompleted,
    'new': statusPillPending,
    'on hold': statusPillOnHold,
    'in progress': statusPillInProgress,
    'rejected': statusPillRejected
};

// const headers = [
//     '',
//     'Type',
//     'Dates',
//     'Days',
//     'Status',
// ];

const headers = [
    {name: "Reference Code", withOrder: false},
    {name: "", withOrder: false},
    {name: "Type", withOrder: true},
    {name: "Dates", withOrder: true},
    {name: "Days", withOrder: true},
    {name: "Status", withOrder: true},
];

const RequestsWidget = (props) => {
    const [ requests, setRequests ] = React.useState([]);
    const [ itemsPerPage, setItemsPerPage ] = React.useState(10);
    const [ page, setPage ] = React.useState(1);

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(leaveTypeActions.getAll());
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(essActions.getAllByUser(props.userId, page, itemsPerPage))
    }, [dispatch, itemsPerPage, page, props.userId]);

	React.useEffect(() => {
        // make object out of leaveTypes array with _id as its only property
		const leaveTypes = props.leaveTypes.reduce(
            (accumulator, leaveType) => ({
                ...accumulator,
                [leaveType._id]: leaveType
            }),
            {}
        );

        if(isEmpty(leaveTypes) === false){
            setRequests(formatRequest(
                props.requests.items,
                leaveTypes
            ));
        }
    }, [props.requests, props.leaveTypes]);

    const sort = React.useCallback((index, order) => {
        setRequests(requests => {
            const orders = ['asc', 'desc'];

            return (
                orderBy(requests, (request) => (
                    request.cells[index].value
                ), [orders[order]])
            );
        })
    }, [setRequests]);

    const viewRequest = React.useCallback(id => {
        props.history.push(`${props.match.path}/view/${id}`);
    }, [props.history, props.match]);

    return (
        <div className="requests-widget">
            <div className="widget-header">
                <h6>Requests ({ props.requests.total || 0 })</h6>
                <div className="requests-widget-controls">
                    <button className="filter-requests">
                        <img src={filter} alt="filter"/>
                    </button>
                    {/* <button className="add-new-request">
                        Add new
                    </button> */}
                </div>
            </div>
            <div className="card z-depth-0">
                <Table
                    headers={headers}
                    itemsPerPage={itemsPerPage}
                    loading={props.requests.loading}
                    goToPage={page => { setPage(page) }}
                    noDataComponent={
                        <>
                            <p className="no-data-helper">Create a new request by clicking on the Add new button below</p>
                            
                        </>
                    }
                    onHeaderClick={sort}
                    onRowClick={viewRequest}
                    page={props.requests.page}
                    pages={props.requests.pages || 1}
                    rows={requests}
                    setItemsPerPage={setItemsPerPage}
                    columns={5}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: JSON.parse(localStorage.getItem("secretDetails")) !== null ? JSON.parse(localStorage.getItem("secretDetails")).user_id : state.authentication?.user?.user?._id,
    leaveTypes: state.leaveTypes?.items || [],
	requests: {
        ...state.ess.getAllByUser?.data,
        page: state.ess.getAllByUser?.data?.page ?? 1,
        items: state.ess.getAllByUser?.data?.items ?? [],
        loading: state.ess.getAllByUser?.loading
    }
});

export default React.memo(connect(mapStateToProps)(withRouter(RequestsWidget)));