import { permissionConstants } from '../constants/permission';
import { permissionService } from '../services/permission';
import { alertActions } from './alert';
import { history } from '../helpers/history';
import Swal from 'sweetalert2';

export const permissionActions = {
    getAll,
    getById,
    permissionAdd,
    permissionDelete,
    permissionUpdate,
};

function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());

        permissionService.getAll(page, itemsPerPage)
            .then(
                permissions => {
                    dispatch(success(permissions))
                    if(window.location.pathname === '/admin/permissions'){
                        history.push('/admin/permissions?page=' + Number(page) || 1);
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: permissionConstants.GETALL_REQUEST } }
    function success(permissions) { return { type: permissionConstants.GETALL_SUCCESS, permissions } }
    function failure(error) { return { type: permissionConstants.GETALL_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request(id));
        permissionService.getById(id)
            .then(
                permission => {
                    dispatch(success(permission.success));
                    // localStorage.setItem('permission', JSON.stringify(permission.success))
                    history.push('/admin/permissions/edit');
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: permissionConstants.GET_REQUEST, id } }
    function success(permission) { return { type: permissionConstants.GET_SUCCESS, permission } }
    function failure(error) { return { type: permissionConstants.GET_FAILURE, error } }
}

function permissionAdd(permission) {
    return dispatch => {
        dispatch(request(permission));
        const Toast = Swal.mixin({
            toast: true,
			width: 300,
			height: 500,
			background: '#D4EDDA',
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        const Error = Swal.mixin({
            toast: true,
			width: 300,
			background: '#ff9999',
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        permissionService.permissionAdd(permission)
            .then(
                permissions => { 
                    dispatch(success(permissions));
                    history.goBack()
                    dispatch(alertActions.success('New permission has been created'));
                    Toast.fire({
						type: 'success',
						title: '<span style="color:#0A551E">New permission has been created.</span>'
					});
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('something went wrong'));
                    Error.fire({
						type: 'error',
						title: '<span style="color:#990000">Something went wrong...</span>'
					});
                }
            );
    };

    function request(permission) { return { type: permissionConstants.POST_PERMISSION_REQUEST, permission } }
    function success(permission) { return { type: permissionConstants.POST_PERMISSION_SUCCESS, permission } }
    function failure(error) { return { type: permissionConstants.POST_PERMISSION_FAILURE, error } }
}

function permissionUpdate(permission) {
    return dispatch => {
        dispatch(request(permission));
        const Toast = Swal.mixin({
            toast: true,
			width: 300,
			height: 500,
			background: '#D4EDDA',
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        const Error = Swal.mixin({
            toast: true,
			width: 300,
			background: '#ff9999',
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        permissionService.permissionUpdate(permission)
            .then(
                permission => {
                    dispatch(success(permission));
                    history.goBack();
                    dispatch(alertActions.success('permission successfully updated'));
                    Toast.fire({
						type: 'success',
						title: '<span style="color:#0A551E">Permission successfully updated.</span>'
					});
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Edit permission failed'));
                    Error.fire({
						type: 'error',
						title: '<span style="color:#990000">Edit permission failed.</span>'
					});
                }
            );
    };

    function request(permission) { return { type: permissionConstants.UPDATE_PERMISSION_REQUEST, permission } }
    function success(permission) { return { type: permissionConstants.UPDATE_PERMISSION_SUCCESS, permission } }
    function failure(error) { return { type: permissionConstants.UPDATE_PERMISSION_FAILURE, error } }
}

function permissionDelete(id){
    
    return dispatch => {
        dispatch(request(id));
        const Toast = Swal.mixin({
            toast: true,
			width: 300,
			height: 500,
			background: '#D4EDDA',
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        const Error = Swal.mixin({
            toast: true,
			width: 300,
			background: '#ff9999',
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        permissionService.permissionDelete(id)
            .then(
                permission => {
                    dispatch(success(id))
                    console.log(permission)
                    // history.goBack();
                    Toast.fire({
						type: 'success',
						title:  '<span style="color:#0A551E">Permission successfully deleted.</span>'
					});
                },
                error => {
                    dispatch(failure(error.toString()))
                    Error.fire({
						type: 'error',
						title: '<span style="color:#990000">Deleting permission failed.</span>'
					});
                }
            );
    };

    function request(id) { return { type: permissionConstants.DELETE_PERMISSION_REQUEST, id } }
    function success(id) { return { type: permissionConstants.DELETE_PERMISSION_SUCCESS, id } }
    function failure(error) { return { type: permissionConstants.DELETE_PERMISSION_FAILURE, error } }
}