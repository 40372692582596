import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_core/actions/user'
import { roleActions } from '../_core/actions/role'
import './LeaderBoards_v3.sass'
import ReactImageFallback from 'react-image-fallback'
import avatar from '../_templates/alt_avatar.png'
import starLarge from '../_templates/star40x40.png'
import starMedium from '../_templates/star30x30.png'
import starSmall from '../_templates/stars-sm.png'
import sun from '../_templates/sun@2x.svg'
import empty from '../_templates/leaderboard/emptystates_logo.png'

import { If, Then, Else } from 'react-if';
import { Link }  from 'react-router-dom';

class LeaderBoards extends React.Component {

    constructor(props) {
      super(); 

      this.state = {
         topEarners: true,
         rankingLeaders: false
      }
    }

    handleTopEarners = (e) => {
      e.preventDefault();
      this.setState({
        topEarners: true,
        rankingLeaders: false
      })
    }

    handleRankingLeaders = (e) => {
      e.preventDefault();
      this.setState({
        rankingLeaders: false,
        topEarners: false
      })
    }

    componentDidMount() {
        this.props.dispatch(userActions.getAll(1, 100000));
		    this.props.dispatch(roleActions.getAll(1, 100000));
    }

    getLeaderBoards() {
        const { users, roles: { roles }  } = this.props || {};
        const allUsers = users.users?.items;
        let sort = [];
        let userList = [];

        if (typeof allUsers !== 'undefined' && typeof roles !== 'undefined') {
            const filterRoles = roles.items.find(({ roleSlug }) => roleSlug === 'applicant') || { _id: 'n/a' }
            userList = allUsers.filter(user => user.active === 1 && user.role !== filterRoles._id && user.credit !== 0)
        }
        sort = userList.sort((a, b) => (
            b.credit - a.credit
        ))
        return sort;
    }


    render() {
        let userList = this.getLeaderBoards();
        let ranked1 = userList.slice(0, 1)
        let ranked2 = userList.slice(1, 2)
        let ranked3 = userList.slice(2, 3)
        let usersWithCredits = userList.slice(3, 20)

        console.log (userList)

       return (
        <React.Fragment>
           <div className="leaderboards-title">
             <h6 className="title">Leaderboards</h6>
           </div>
           <div className="leaderboards-main">
           <div className="leaderboards-widget">
             <div className="row main-widget-container">
                <div className="col l5 s12 m12 offset-l0">
                  <div className="top-earners-container" onClick={this.handleTopEarners} style={{cursor: 'pointer'}}>
                      <img src={sun} alt="topEarners"/>
                      <p>Top Earners</p>
                  </div>
                </div>
                <div className="col l5 s12 m12 offset-l2">
                  <div className="ranking-leaders-container" onClick={this.handleRankingLeaders} style={{cursor: 'pointer'}}>
                    <img src={sun} alt="rankingLeaders"/>
                    <p>Ranking Leaders</p>
                  </div>
                </div>        
             </div>
           </div>

              <If condition={ userList && userList.length}>
                <Then>
                  <div className="card leaderboards-one z-depth-1 hoverable">
                  <p style={{paddingBottom: '10px'}}>Top earner</p>

                    {ranked1.map((user, index) => (
                      <div className="row" key={index}>
                        <div className="leaderboards-avatar-container col s12 l3">
                            <p className="ranked-number1">1st</p>
                            <ReactImageFallback
                            className="leaderboards-rankedOneAvatar"
                            fallbackImage={avatar}
                            src={user.details[0].avatar}
                            />
                        </div>
                      <div className="col s12 l4 leaderboards-info">
                          <h6>{user.details[0]?.firstName} <span>{user.details[0].lastName}</span></h6>
                          <p style={{fontSize: '13px'}}>{user.details[0].position ? user.details[0].position : 'No position'} | <span>{user.details[0]?.department ? user.details[0]?.department : 'No department'}</span></p>
                      </div>
                      <div className="leaderboards-credit col s12 l5">
                          <img src={starLarge} alt="large" style={{margin: '0 10px'}}/>
                          <h5 style={{fontWeight: 'bold', fontSize: '28px'}}>{user.credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                      </div>
                    </div>
                    ))
                    }
                </div>
                {ranked2.map((user, index) => (
                <div className="card leaderboards-two hoverable z-depth-1">
                    <div className="row" key={index}>
                    <div className="col s12 l3">
                    <p className="ranked-number2">2nd</p>
                    <ReactImageFallback
                    src={user.details[0].avatar}
                    fallbackImage={avatar}
                    className="leaderboards-two-avatar"
                    />
                    </div>
                    <div className="leaderboards-two-info col s12 l6">
                    <h6>{user.details[0].firstName} <span>{user.details[0].lastName}</span></h6>
                    <p style={{fontSize: '12px'}}>{user.details[0].position ? user.details[0].position : 'No position'} | <span>{user.details[0]?.department ? user.details[0]?.department : 'No department'}</span></p>
                    </div>
                    <div className="leaderboards-two-credit col s12 l3">
                    <img src={starMedium} alt="ranked2"/> 
                    <h6>{user.credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                    </div>
                    </div>
                </div>
                ))}
                {ranked3.map((user, index) => (
                <div className="card z-depth-1 leaderboards-three hoverable">
                    <div className="row" key={index}>
                    <div className="col s12 l3">
                    <p className="ranked-number3">3rd</p>
                    <ReactImageFallback
                    src={user.details[0].avatar}
                    fallbackImage={avatar}
                    className="leaderboards-three-avatar"
                    />
                    </div>
                    <div className="leaderboards-three-info col s12 l6">
                    <h6>{user.details[0].firstName} <span>{user.details[0].lastName}</span></h6>
                    <p style={{fontSize: '12px'}}>{user.details[0].position ? user.details[0].position : 'No position'} | <span>{user.details[0]?.department ? user.details[0]?.department : 'No department'}</span></p>
                    </div>
                    <div className="leaderboards-three-credit col s12 l3">
                    <img src={starMedium} alt="ranked2"/> 
                    <h6 >{user.credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                    </div>
                    </div>
                </div>
                ))}
                {this.state.rankingLeaders === true ?
                <div>
                <table className="striped">
                  <thead>
                      <tr className="users-ranked-table-header">
                          <th><h6>Rank</h6></th>
                          <th><h6>Name</h6></th>
                          <th><h6>Credits</h6></th>
                      </tr>
                  </thead>
                  <tbody>
                    {usersWithCredits.map((users, index) => (
                    <tr key={index}>
                    <td><p style={{fontWeight: '600', paddingLeft: '15px'}}>{index + 4}</p></td>
                    <td>
                      <div style={{display: 'flex'}}>
                      <div style={{padding: '0 10px'}}>
                        <ReactImageFallback
                        src={users.details[0]?.avatar}
                        fallbackImage={avatar}
                        
                        className="usersWithCreditsAvatar"
                        />
                      </div>
                      <div>
                        <p style={{fontWeight: '600', fontSize: '16px'}}>{users.details[0].firstName} <span>{users.details[0].lastName}</span></p>
                        <p>{users.details[0].position ? users.details[0].position : 'No position'} | <span>{users.details[0]?.department ? users.details[0]?.department : 'No department'}</span></p>
                      </div>
                      </div>
                    </td>
                    <td style={{display: 'flex'}}>
                      <img src={starSmall} alt="userRanked"/>
                      <p style={{fontWeight: '600', fontSize: '14px', padding: '0 10px'}}>{users.credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    </td>
                  </tr>
                    ))
                    }
                  </tbody>
                </table>
              </div>
            : null}
            {this.state.topEarners ===  true ?
                <div>
                <table className="striped">
                  <thead>
                      <tr className="users-ranked-table-header">
                          <th><h6>Rank</h6></th>
                          <th><h6>Name</h6></th>
                          <th><h6>Credits</h6></th>
                      </tr>
                  </thead>
                  <tbody>
                    {usersWithCredits.map((users, index) => (
                    <tr key={index}>
                    <td><p style={{fontWeight: '600', paddingLeft: '15px'}}>{index + 4}</p></td>
                    <td>
                      <div style={{display: 'flex'}}>
                      <div style={{padding: '0 10px'}}>
                        <ReactImageFallback
                        src={users.details[0]?.avatar}
                        fallbackImage={avatar}
                        
                        className="usersWithCreditsAvatar"
                        />
                      </div>
                      <div>
                        <p style={{fontWeight: '600', fontSize: '16px'}}>{users.details[0].firstName} <span>{users.details[0].lastName}</span></p>
                        <p style={{color: '#c4c4c4'}}>{users.details[0].position ? users.details[0].position : 'No position'} | <span>{users.details[0]?.department ? users.details[0]?.department : 'No department'}</span></p>
                      </div>
                      </div>
                    </td>
                    <td style={{display: 'flex'}}>
                      <img src={starSmall} alt="userRanked"/>
                      <p style={{fontWeight: '600', fontSize: '14px', padding: '0 10px'}}>{users.credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    </td>
                  </tr>
                    ))
                    }
                  </tbody>
                </table>
              </div>
            : null}
                </Then>
                <Else>
                  <div >
                    <img src={empty} alt="empty" className="empty_leaderboard"/>
                    <span className="empty_title">No Results Found</span>
                    <p className="empty_subtitle">There are no active users in leaderboards yet,</p>
                    <p className="empty_subtitle"> get some quest or challenges to gain some points.</p>
                    <button className="empty_button"><Link to={`/engage`} className="empty_text">Go to Engage </Link></button>
                  </div>
                </Else>
              </If>


           </div>
        </React.Fragment>
       )
   }
}

function mapStateToProps(state) {

   const {
        users,
        roles,
   } = state;

   return {
       users,
       roles
   };
};

const leaderBoards = connect(mapStateToProps)(LeaderBoards);
export { leaderBoards as LeaderBoards}