import { roleConstants } from '../constants/role';
import { roleService } from '../services/role';
import { alertActions } from './alert';
import { history } from '../helpers/history';
import Swal from 'sweetalert2';

export const roleActions = {
    getAll,
    roleAdd,
    roleDelete,
    roleById,
    roleUpdate,
    getOneRole,
    delete: _delete,
    rolePermission,
};

function getAll(page, itemPerPage) {
    return dispatch => {
        dispatch(request());

        roleService.getAll(page, itemPerPage)
            .then(
                // roles => dispatch(success(roles.success)),
                roles => {
                    dispatch(success(roles));
                    if(window.location.pathname === '/admin/roles'){
                        history.push('/admin/roles?page=' + Number(page) || 1);
                    }
                },
                error => dispatch(failure(error.toString())
            ));
    };

    function request() { return { type: roleConstants.GETALL_REQUEST } }
    function success(roles) { return { type: roleConstants.GETALL_SUCCESS, roles } }
    function failure(error) { return { type: roleConstants.GETALL_FAILURE, error } }
}

function roleAdd(newRole, rolePermissions) {
    return dispatch => {
        dispatch(request());
        const Toast = Swal.mixin({
            toast: true,
			width: 300,
			height: 500,
			background: '#D4EDDA',
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        const Error = Swal.mixin({
            toast: true,
			width: 300,
			background: '#ff9999',
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        roleService.roleAdd(newRole)
            .then(
                role => { 
                    dispatch(success(role.success));
                    rolePermissions.map((rp)=>{
                        return dispatch(rolePermission(role._id, rp))
                     })
                    history.goBack();
                    dispatch(alertActions.success('New role has been created'));
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#0A551E">New role has been created</span>'
                    })
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Adding role failed'));
                    Error.fire({
                        type: 'error',
                        title: '<span style="color:#990000">Adding role failed</span>'
                    })
                }
            );
    };

    function request(role) { return { type: roleConstants.POSTROLE_REQUEST, role } }
    function success(role) { return { type: roleConstants.POSTROLE_SUCCESS, role } }
    function failure(error) { return { type: roleConstants.POSTROLE_FAILURE, error } }
}

function rolePermission(id, permission){
    return dispatch =>{
        dispatch(request());
        roleService.rolePermission(id, permission)
        .then(
            rolePermission => { 
                dispatch(success(rolePermission));
                // history.goBack();
                dispatch(alertActions.success('New role permission has been created'));
            },
            error => {
                dispatch(failure(error.toString()));
                // history.goBack();
                dispatch(alertActions.error('Adding role permission failed'));
            }
        );
    }

    function request(role) { return { type: roleConstants.ROLEPERMISSION_REQUEST, role } }
    function success(rolePermission) { return { type: roleConstants.ROLEPERMISSION_SUCCESS, rolePermission } }
    function failure(error) { return { type: roleConstants.ROLEPERMISSION_FAILURE, error } }
}

function roleById(id) {
    return dispatch => {
        dispatch(request(id));

        roleService.getOneRole(id)
            .then(
                role => {
                    dispatch(success(role.items));
                    localStorage.setItem('role', JSON.stringify(role.items))
                    history.push('/admin/roles/edit');
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: roleConstants.GET_REQUEST } }
    function success(role) { return { type: roleConstants.GET_SUCCESS, role } }
    function failure(error) { return { type: roleConstants.GET_FAILURE, error } }
}

function getOneRole(id){
    return dispatch => {
        dispatch(request(id));
        roleService.getOneRole(id)
            .then(
                role => {
                    dispatch(success(role.items));
                    localStorage.setItem('userRole', JSON.stringify(role.items))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: roleConstants.GET_REQUEST } }
    function success(role) { return { type: roleConstants.GET_SUCCESS, role } }
    function failure(error) { return { type: roleConstants.GET_FAILURE, error } }
}


function roleUpdate(role, rolePermissions) {
    const roleId = role._id
    return dispatch => {
        dispatch(request(role));
        // custom toast mixin for sweetAlert
        const Toast = Swal.mixin({
            toast: true,
			width: 300,
			height: 500,
			background: '#D4EDDA',
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        const Error = Swal.mixin({
            toast: true,
			width: 300,
			background: '#ff9999',
			position: 'center',
			showConfirmButton: false,
			timer: 5000
        });
        roleService.updateRole(role)
            .then(
                role => {
                    dispatch(success(role));
                    rolePermissions.map((rp)=>{
                       return dispatch(rolePermission(roleId, rp))
                    })
                    dispatch(alertActions.success('Role successfully updated'));
                    history.goBack();
                    Toast.fire({
                        type: 'success',
                        title: '<span style="color:#0A551E">Role Successfully Updated!</span>'
                    })
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Edit role failed'));
                    Error.fire({
                        type: 'error',
                        title: '<span style="color:#990000">Edt Role Failed!</span>'
                    })
                }
            );
    };

    function request(role) { return { type: roleConstants.UPDATEROLE_REQUEST, role } }
    function success(role) { return { type: roleConstants.UPDATEROLE_SUCCESS, role } }
    function failure(error) { return { type: roleConstants.UPDATEROLE_FAILURE, error } }
}

function roleDelete(id){
    
    return dispatch => {
        dispatch(request(id));
        roleService.roleDelete(id)
            .then(
                role => dispatch(success(id)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(id) { return { type: roleConstants.ROLE_DEL_REQUEST, id } }
    function success(id) { return { type: roleConstants.ROLE_DEL_SUCCESS, id } }
    function failure(error) { return { type: roleConstants.ROLE_DEL_FAILURE, error } }
}


// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        roleService.delete(id)
            .then(
                role => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: roleConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: roleConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: roleConstants.DELETE_FAILURE, id, error } }
}