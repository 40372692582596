import React from 'react'
// import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
// import { checkSideNav } from '../../Components/checkSideMenu';
// import { Route, Switch } from 'react-router-dom';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import "./MainPermission.scss";
import { permissionActions } from '../../../_core/actions/permission';
import { history } from '../../../_core/helpers/history';
import Swal from 'sweetalert2';

const mapStateToProps = ({ permissions }) => ({
    permissions, 
});

const EditPermission = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    let HeaderContent = "Edit Permission";

    
    const [isSubmitted, setIsSubmitted] = React.useState(false);

    var setPrm = [];
    if(props.permissions?.permission){
        setPrm = [
            ...(
                props.permissions.permission.map(permission => ({
                    _id: permission._id,
                    permissionName: permission.permissionName,
                    code: permission.code
                }))
            )
        ]
    }
    let data = setPrm[0]
    let info = {...data}

    const edit = typeof setPrm !== 'undefined' ? true : false;
    const  perm = edit ? info : {};

    const [permissions, setPermission] =  React.useState({ 
        _id: edit ? perm._id : '',
        permissionName: edit ? perm.permissionName : '',
        code: edit ? perm.code : '',
    });


  if(props.permissions.permission){}else{ history.goBack() }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPermission({
                ...permissions,
                [name]: value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setIsSubmitted(true);
        if (permissions.permissionName && permissions.code) {
            Swal.fire({
                title: '<p class="swal-subtitle-dark"> Add this permission?</p>',
                html:
                    "<br> <b class='swal-subtitle-dark'>Permission Name:  "
                    + permissions.permissionName + 
                    "<br>Permission Code:  "
                    + permissions.code + "</b>",

                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#24DC98',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Done!',
                background: '#2E3549',
            }).then((result) => {
                if (result.value) {
                    dispatch(permissionActions.permissionUpdate(permissions));
                }
            })
        }
    }
    
    return (
        <BodyBackgroundColor backgroundColor='#1C2233'>
        <div className="create_permission" >


            <form name="form" onSubmit={handleSubmit}>


                <div className="table-card">
                    

                    <div className="table-header">
                        <span className="main-title">{HeaderContent}</span>
                    </div>

                    <div className={(isSubmitted && !permissions.permissionName ? ' has-error' : '')} id="permName">

                        <div className="floating-label attrName"> 

                            <input  className="floating-input" type="text" placeholder=" "  name="permissionName" value={permissions.permissionName} onChange={handleChange} id="permissionName"/>
                            <label>Permission Type</label>
                            {isSubmitted && !permissions.permissionName &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Permission Type is required</div>
                            }
                            
                        </div>

                     </div>

                    <div className={(isSubmitted && !permissions.code ? ' has-error' : '')} id="permCode">

                        <div className="floating-label attrName"> 

                            <input  className="floating-input" type="text" placeholder=" " name="code" value={permissions.code} onChange={handleChange} id="code" />
                            <label>Permission Code</label>
                            {isSubmitted && !permissions.code &&
                                <div className="help-block red-text-dark" data-error="wrong" data-success="right">Permission Code is required</div>
                            }
                            
                        </div>

                    </div>
                <div className="table-footer">
                    <button className="waves-effect waves-light btn cancelDark" onClick={(e)=>{e.preventDefault(); props.history.goBack()}} >CANCEL</button>
                    <button className="waves-effect waves-light btn submitDark">SAVE</button>

                </div>
                </div>
            </form>
        </div>
    </BodyBackgroundColor>
    )
});


// const EditPermissionPage = connect(mapStateToProps)((props) => {
    

//     return (
//         <div
//             className={cc([
//                 'createPermission',
//                 checkSideNav()?.trim()
//             ])}
//         >   
//             <div> 
//                 <div className="row">
//                     <div className="col s6 mainTitle"><p> Access Management</p></div>
//                     <div className="col s6 sub-title-main"><p>Permission/ Edit Permission</p></div>
//                 </div>
//              </div>
//            <EditPermission {...props}/>

//         </div>
//     )
// });
// EditPermissionPage.WrappedComponent.displayName = 'EditPermissionPage';
// const EditPermissionPageRoute = (props) => (
// 	<Switch>
//         <Route
// 			exact
// 			path={`${props.match.path}`}
// 			render={(props) => <EditPermissionPage {...props} mode="list" />}
// 		/>
		
// 	</Switch>
// );
export default EditPermission;
