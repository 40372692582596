import { get } from '../helpers/api';
import { userService } from '../services/user';

function searchAll(item, page = 1, itemsPerPage = 10){
    return get(`/api/v1/search?search=${item.replace(/^\s+|\s+$/g, '%20')}&page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
};

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
};

export const searchServices = {
    searchAll
};