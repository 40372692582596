import { announcementConstants } from '../constants/announcement';


let initialState = {
    items: [],
    loading: true,
    error: null,
    totalPerPage: 0,
    perPage: 0,
    currentPage: 0,
}
export function announcements(state = initialState, action) {
  switch (action.type) {
    case announcementConstants.GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case announcementConstants.GETALL_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          items: action.announcements.items,
          totalPerPage:  action.announcements.total,
          perPage: action.announcements.pages,
          currentPage: action.announcements.page,
        };
        case
        announcementConstants
        .GET_REQUEST:
              return {
                saving: true
              };
            case
        announcementConstants
        .GET_SUCCESS:
              return {
                announcement: action.announcement
              };
            case
        announcementConstants
        .GET_FAILURE:
              return {
                error: action.error
              };
        case announcementConstants.DELETE_PERMISSION_REQUEST:
          return {
            ...state,
            items: state.announcements.items.map(announcement =>
              announcement._id === action.id
                ? { ...announcement, deleting: true }
                : announcement
            )
          };
        case announcementConstants.GETBYID_FAILURE:
        return { 
          ...state,
          loading: false,
          error: action.error
        };
        case announcementConstants.UPDATE_ANNOUNCEMENT_REQUEST:
          return {loading: true}

        default:
        return state
  }
}