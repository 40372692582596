import requestsService from '../services/requests';
import requestsConstants from '../constants/requests';

function getRequests(options = {}) {
    options.selectPage = options.selectPage ?? 1;
    options.itemsPerPage = options.itemsPerPage ?? 10;
    options.getAll = options.getAll ?? false;

    const request = () => ({
        type: requestsConstants.GET_REQUESTS_REQUEST
    });

    const success = (requests) => ({
        type: requestsConstants.GET_REQUESTS_SUCCESS,
        requests
    });

    const failure = (error) => ({
        type: requestsConstants.GET_REQUESTS_FAILURE,
        error
    });

    return dispatch => {
        dispatch(request(options));

        requestsService.getRequests(options)
        .then(
            requests => { dispatch(success(requests)) },
            error => { dispatch(failure(error?.toString()))}
        )
    }
}

export default { getRequests };