import { rewardConstants } from '../constants/reward';

const initialState = {
  items: [],
  error: '',
  loading: true,
  total: 0,
  page: 1,
  pages: 1,
  userRewards: {
    items: [],
    error: '',
    loading: true,
    total: 0,
    page: 1,
    pages: 1,
  }
};

export function reward(state = initialState, action) {
  switch (action.type) {
    case rewardConstants.GETALL_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        success: false
      };
    case rewardConstants.GETALL_SUCCESS:
      return {
        ...state,
        ...action.reward,
        loading: false,
      };
    case rewardConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case rewardConstants.POST_REQUEST:
      return {
        ...state,
        loading: false,
        error: '',
        success: false,
      }

    case rewardConstants.POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        success: true,
      }

    case rewardConstants.POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      }
    case rewardConstants.GETONE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        success: false
      };
    case rewardConstants.GETONE_SUCCESS:
      return {
        ...state,
        ...action.reward,
        loading: false,
      };
    case rewardConstants.GETONE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case rewardConstants.GETBYUSER_REQUEST:
      return {
        ...state,
        userRewards: {
          ...state.userRewards,
          loading: true,
          error: ''
        }
      };
    case rewardConstants.GETBYUSER_SUCCESS:
      return {
        ...state,
        userRewards: {
          ...state.userRewards,
          ...action.userRewards,
          loading: false,
        }
      };
    case rewardConstants.GETBYUSER_FAILURE:
      return {
        ...state,
        userRewards: {
          ...state.userRewards,
          loading: false,
          error: action.error
        }
      };
    case rewardConstants.UPDATE_REWARD_REQUEST:
      return {
        ...state,
        loading: false,
        error: '',
        success: false,
      }

    case rewardConstants.UPDATE_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        success: true,
      }

    case rewardConstants.UPDATE_REWARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      }
    case rewardConstants.DELETE_REQUEST:
        return {
          ...state,
          items: state.userRewards.items.map(reward =>
            reward._id === action.id
              ? { ...reward, deleting: true }
              : reward
          )
        }
    default:
      return state
  }
}
