import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BodyBackgroundColor from 'react-body-backgroundcolor'
//import MainNav from '../Components/MainNav';
import PublicNav from '../Components/PublicNav/PublicNav';
import { Overlay } from '../Components/Overlay-Login/Overlay';
import icon from '../_templates/26731183_1548961821883748_5876949817012874527_n.jpg'
import M from 'materialize-css/dist/js/materialize.min.js';
import MaterialIcon from 'material-icons-react';
import comments_icon from '../_templates/comments.png'
import './feedpage.sass'

class Feedpage extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
        	blurClass: 'none-blurClass',
        }
    }

    componentDidMount() {
    	document.addEventListener('DOMContentLoaded', function() {
    	var elems = document.querySelectorAll('.modal');
		    M.Modal.init(elems);
		});

		window.addEventListener('scroll', (event) => {
         if(window.pageYOffset > 300){
             this.setState({ blurClass: 'blurClass' });
         } else {
         	this.setState({ blurClass: 'none-blurClass' });
         }
      });
    }

    render() {
        return (
        	<BodyBackgroundColor backgroundColor='#effdfe'>
	          <div className="feedpage">
	          	<PublicNav />
	          	<Overlay />
	          	<div className="feed-post">
					<div className="row">
						<div className="center">
							<div className="container">
								<div className="col s12 m8 l8">
									<ul className="collection">
									    <li className="collection-item avatar">
										    <img src={icon} alt="" className="circle responsive-img"/>
										    <span className="create-post">
												<form>
							    					<input type="text" className="validate modal-trigger" href="#modal1"placeholder="What's on your mind?"/>
		            								<div className="right-align">
		            									<button className="waves-effect waves-light btn btn-share">SHARE</button>
		            								</div>
							    				</form>
										    </span>
										    <a href="#!" className="secondary-content"><MaterialIcon icon="close" /></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
	          	</div>

	          	
			  <div id="modal1" className="modal">
				    <div className="modal-content">
				      <ul className="collection">
							<li className="collection-item avatar">
								<img src={icon} alt="" className="circle responsive-img"/>
									<span className="create-post">
										<form>
							    			<Link className="modal-trigger" to="#modal1"><input type="text" className="validate modal-trigger" href="#modal1"placeholder="What's on your mind?"/></Link>
		            							<div className="right-align">
		            								<button className="waves-effect waves-light btn btn-share">SHARE</button>
		            							</div>
							    		</form>
									</span>
								    
							</li>
						</ul>
				    </div>
				    
			  </div>

		        <Overlay />
				<div className={`section ${this.state.blurClass}`}>
                	<div className="container">
						<div className="row">
                            <div className="col s12 m7 feed-container">
								<ul className="collection">
								    <li className="collection-item avatar">
								      <img src={icon} alt="" className="circle responsive-img"/>
								      <span className="title">Heartlene Santiago</span>
								      <Link to="#"className="secondary-content"><i className="material-icons black-text">more_horiz</i></Link>
								    </li>
								    <li className="collection-item">
								    	<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
								    	<i className="material-icons pink-text">thumb_up</i>
								    	<img src={comments_icon} alt="" className="comment-icon responsive-img"/>
										<p className="no_of_likes"><b> 2.3k</b><span className="no_of_comments"><b>299</b> Comments</span></p>
								    </li>								    	
								    <li className="collection-item avatar">
								      <img src={icon} alt="" className="circle responsive-img"/>
								      <span className="title">Heartlene Santiago</span> <span className="comment-detail">May bumabagabag sa aking isipan may tatanong lang sana sayo </span>
								      <p className="Commend-now"> Commend </p>

								      <img src={icon} alt="" className="circle responsive-img"/>
								      <span className="title">Heartlene Santiago</span> <span className="comment-detail">May bumabagabag sa aking isipan may tatanong lang sana sayo </span>
								      <p className="Commend"> Commend </p>

								      <img src={icon} alt="" className="circle responsive-img"/>
								      <span className="title">Heartlene Santiago</span> <span className="comment-detail">May bumabagabag sa aking isipan may tatanong lang sana sayo </span>
								      <p className="Commend"> Commend </p>
								    </li>
								    <li className="collection-item">
								    	<div className="container">
								    		<form>
								    			<input id="addcomment1" type="text" className="validate" placeholder="Add comment"/>
								    		</form>
								    		<div className="center-align">
												<Link to="#" className="view-all-comment">View all comments</Link>
								    		</div>
								    	</div>
								    </li>
								</ul>
								<ul className="collection">
								    <li className="collection-item avatar">
								      <img src={icon} alt="" className="circle responsive-img"/>
								      <span className="title">Heartlene Santiago</span>
								      <Link to="#"className="secondary-content"><i className="material-icons black-text">more_horiz</i></Link>
								    </li>
								    <li className="collection-item">
								    	<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
								    	<i className="material-icons pink-text">thumb_up</i>
								    	<img src={comments_icon} alt="" className="comment-icon responsive-img"/>
										<p className="no_of_likes"><b>1</b><span className="no_of_comments"><b>0</b> Comments</span></p>
								    </li>
								    <li className="collection-item">
								    	<div className="container">
								    		<form>
								    			<input id="addcomment2" type="text" className="validate" placeholder="Add comment"/>
								    		</form>
								    	</div>
								    </li>								    	
								</ul>
                            </div>
                            <div className="col s12 m5 info-container">
                               <div className="center">
                                    <ul className="collection with-header">
								    	<li className="collection-header"><h6><b>Welcome Aboard Freshmen!</b></h6></li>
									    <li className="collection-item avatar">
									      <img src={icon} alt="" className="circle responsive-img"/>
									   	  <span className="name"><b>Heartlene Santiago</b></span>
									   	  <p className="sub-detail">Joined: 1 second ago</p>
									    
									   	  <img src={icon} alt="" className="circle responsive-img"/>
									   	  <span className="name"><b>Heartlene Santiago</b></span>
									   	  <p className="sub-detail">Joined: 1 second ago</p>

									   	  <img src={icon} alt="" className="circle responsive-img"/>
									   	  <span className="name"><b>Heartlene Santiago</b></span>
									   	  <p className="sub-detail">Joined: 1 second ago</p>
									    
									   	  <img src={icon} alt="" className="circle responsive-img"/>
									   	  <span className="name"><b>Heartlene Santiago</b></span>
									   	  <p className="sub-detail">Joined: 1 second ago</p>

									    </li>
									</ul>

									<ul className="collection with-header">
								    	<li className="collection-header"><h6><b>Challenges</b></h6></li>
									    <li className="collection-item avatar">
									      <img src={icon} alt="" className="circle responsive-img"/>
									   	  <span className="name"><b>Heartlene Santiago</b></span>
									   	  <p className="sub-detail">Created a Challenge!</p>
									    
									   	  <img src={icon} alt="" className="circle responsive-img"/>
									   	  <span className="name"><b>Heartlene Santiago</b></span>
									   	  <p className="sub-detail">Created a Challenge!</p>

									   	  <img src={icon} alt="" className="circle responsive-img"/>
									   	  <span className="name"><b>Heartlene Santiago</b></span>
									   	  <p className="sub-detail">Created a Challenge!</p>
									    
									   	  <img src={icon} alt="" className="circle responsive-img"/>
									   	  <span className="name"><b>Heartlene Santiago</b></span>
									   	  <p className="sub-detail">Created a Challenge!</p>
										<button className="waves-effect waves-light btn btn-go-to-challenge">Go to Challenge!</button>
									    </li>
									</ul>
                                </div>
                            </div>
                        </div>
                	</div>
                </div>


	            <Link to="/templates">Go Back</Link>
	          </div>
	        </BodyBackgroundColor>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return {
        loggingIn,
        alert
    };
}

const connectedFeedpage = connect(mapStateToProps)(Feedpage);
export { connectedFeedpage as Feedpage }; 