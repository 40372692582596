export const ESSConstants = {
    GETALL_REQUEST: 'ESS_GETALL_REQUEST',
    GETALL_SUCCESS: 'ESS_GETALL_SUCCESS',
    GETALL_FAILURE: 'ESS_GETALL_FAILURE',

    GETBYID_REQUEST: 'ESS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'ESS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'ESS_GETBYID_FAILURE',

    GETBYUSER_REQUEST: 'ESS_GETBYUSER_REQUEST',
    GETBYUSER_SUCCESS: 'ESS_GETBYUSER_SUCCESS',
    GETBYUSER_FAILURE: 'ESS_GETBYUSER_FAILURE',

    POST_REQUEST: 'ESS_POST_REQUEST',
    POST_SUCCESS: 'ESS_POST_SUCCESS',
    POST_FAILURE: 'ESS_POST_FAILURE',

    UPDATE_REQUEST: 'ESS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ESS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ESS_UPDATE_FAILURE',

    GETBYUSER_ACTIVITIES_REQUEST: 'ESS_ACTIVITIES_GETBYUSER_REQUEST',
    GETBYUSER_ACTIVITIES_SUCCESS: 'ESS_ACTIVITIES_GETBYUSER_SUCCESS',
    GETBYUSER_ACTIVITIES_FAILURE: 'ESS_ACTIVITIES_GETBYUSER_FAILURE'
};