import React from 'react';
import cc from 'classcat';
import { If, Else, Then } from 'react-if';
import moment from 'moment';
import { useToggle } from 'react-use';

import { userService } from '../_core/services/user';
import { decryptUserEmail } from '../_core/helpers/crypto_config';

import { department, maritalStatus, position } from '../_core/constants/selections';

import bioClassnames from './Bio.module.scss';

const Bio = ({ userCred }) => {
	const [open, toggle] = useToggle(false);
	const [user, setUser] = React.useState({});

	const decryptValues = (val) => {
		let isValid = !!val !== null || val !== '' || val !== undefined;
		let decryptVal = '';
		if (isValid) {
			decryptVal = decryptUserEmail(val) === '' ? val : decryptUserEmail(val);
		}
		return decryptVal;
	}

	React.useEffect(() => {
		if (!userCred?._id) {
			return;
		}

		const getUser = async () => {
			try {
				const user = (await userService.getById(userCred?._id)).user[0];
				setUser(user);
			} catch(error) {
				// TODO: This user doesn't exist
			}
		}

		getUser();
	}, [userCred]);

	return (
		<div
			className={cc([
				'card',
				'z-depth-0',
				bioClassnames.bio
			])}
		>
			{ user?.details &&
				<If condition={open}>
					<Then>
						<div className={bioClassnames.field}>
							<h6 data-main>Bio</h6>
							<p>{user.details[0].bio}</p>
						</div>
						<div className={bioClassnames.field}>
							<h6 data-main>Profile</h6>
						</div>
						<div className={bioClassnames.field}>
							<h6>Email</h6>
							<p>{decryptValues(user.email)}</p>
						</div>
						<div className={bioClassnames.field}>
							<h6>Birth date</h6>
							<p>{moment(user.details[0].birthDate).utc().format('MMMM D, YYYY')}</p>
						</div>
						<div className={bioClassnames.field}>
							<h6>Marital status</h6>
							<p>
								{
									maritalStatus.default.filter(status => (
										status.value === user.details[0].maritalStatus
									))[0]?.label ||
									'No data available'
								}
							</p>
						</div>
						<div className={bioClassnames.field}>
							<h6>Mother's maiden name</h6>
							<p>
								{
									user.details[0].mothersMaidenName || 'No data available'
								}
							</p>
						</div>
						<div className={bioClassnames.field}>
							<h6>Address</h6>
							<p>
								{
									[
										user.details[0].city,
										user.details[0].state,
										user.details[0].country,
									].join(', ')
								}
							</p>
						</div>
						<div className={bioClassnames.field}>
							<h6>Nationality</h6>
							<p>{user.details[0].nationality ?? 'No data available'}</p>
						</div>
						<div className={bioClassnames.field}>
							<h6 data-main>Employment details</h6>
						</div>
						<div className={bioClassnames.field}>
							<h6>Department</h6>
							<p>
								{
									department.default.filter(department => (
										department.value === user.details[0].department
									))[0]?.label ??
									'No data available'
								}
							</p>
						</div>
						<div className={bioClassnames.field}>
							<h6>Position</h6>
							<p>
								{
									position.software.filter(position => (
										position.value === user.details[0].position
									))[0]?.label ??
									'No data available'
								}
							</p>
						</div>
						<div className={bioClassnames.field}>
							<h6>Start date</h6>
							<p>{moment(user.details[0].startDate).utc().format('MMMM D, YYYY')}</p>
						</div>
						<div className={bioClassnames.field}>
							<h6>Regularization date</h6>
							<p>{moment(user.details[0].regularizationDate).utc().format('MMMM D, YYYY')}</p>
						</div>
						<button onClick={toggle}>Show less</button>
					</Then>
					<Else>
						<button onClick={toggle}>Show profile</button>
					</Else>
				</If>
			}
		</div>
	);
}

export default Bio;