import React from 'react';
import DocumentTitle from 'react-document-title';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState } from 'draft-js';
import { If, Then, Else, When } from 'react-if';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import pageManagementActions from '../_core/services/pageManagement';

import classnames from './Page.module.scss';

const Page = (props) => {
    const [loading, setLoading] = React.useState(true);
    const [page, setPage] = React.useState({});

    React.useEffect(() => {
        pageManagementActions.getBySlug(props.match.params.slug).then(({ success: page }) => {
            if(page?.status === 'published' && isEmpty(page?.deletedAt)){
                setPage(page);
            }

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }, [props.match.params.slug, setPage, setLoading]);

    return (
        <div className={classnames.page}>
            <If condition={loading === true}>
                <Then>
                    {/* TODO: Add loading skeletons */}
                </Then>
                <Else>
                    <If condition={isEmpty(page) === false}>
                        <Then>
                            <DocumentTitle title={`${page.title} | ${process.env.REACT_APP_DOCUMENT_TITLE}`}>
                                <>
                                    <div className={classnames.header}>
                                        <h3>{page.title}</h3>
                                        <p>Published {moment(page.createdAt).utc().format('MMMM DD, YYYY')}</p>
                                    </div>
                                    <article>
                                        <When condition={isEmpty(page.featuredImage) === false}>
                                            <img src={page.featuredImage} alt="featured"/>
                                        </When>
                                        <Editor
                                            editorState={
                                                isEmpty(page?.content) ?
                                                EditorState.createEmpty() :
                                                EditorState.createWithContent(convertFromRaw(JSON.parse(page.content)))
                                            }
                                            readOnly
                                            toolbarHidden
                                        />
                                    </article>
                                </>
                            </DocumentTitle>
                        </Then>
                        <Else>
                            <div className={classnames['not-found']}>
                                <h1>Nothing to see here</h1>
                                <p>The page you are looking for either doesn't exist or has been unpublished.</p>
                            </div>
                        </Else>
                    </If>
                </Else>
            </If>
        </div>
    );
};

export default Page;