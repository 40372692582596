import { post, get, del, put } from '../helpers/api';
import { userService } from '../services/user';

export const permissionService = {
    getAll,
    getById,
    permissionDelete,
    permissionAdd,
    permissionUpdate,
};

function getAll(page, itemsPerPage) {
    return get(`/api/v1/permission?page=${page || 1}&items-per-page=${itemsPerPage || 20}`).then(handleResponse);
}

function getById(id) {
    return get(`/api/v1/permission/${id}`).then(handleResponse);
}

function permissionAdd(permissions) {
    return post('/api/v1/permission', JSON.stringify(permissions))
        .then(handleResponse)
}

function permissionUpdate(permission) {
    let id = permission._id;
    delete permission._id;
    return put(`/api/v1/permission/${id}`, JSON.stringify(permission))
        .then(handleResponse)
}

function permissionDelete(id){
    return del(`/api/v1/permission/${id}`).then(handleResponse)
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}
