import { currentUserConstants } from '../constants/currentUser';

export function currentUser(state = {}, action) {
  switch (action.type) {

    //get current user
    case currentUserConstants.GET_CURRENT_REQUEST:
      return {
        loading: true
      };
    case currentUserConstants.GET_CURRENT_SUCCESS:
      return {
        currentUser: action.currentUser
      };
    case currentUserConstants.GET_CURRENT_FAILURE:
      return { 
        error: action.error
      };

    default:
      return state
  }
}