import { notifConstants } from '../constants/notification';
import { notifService } from '../services/notification';
import { alertActions } from './alert';

export const notifActions = {
    getNotif,
    getNotifByPage,
    createNotif,
    updateNotif
};

function getNotif(){
    return dispatch => {
        dispatch(request());
        notifService.getNotif()
            .then(
                notif => dispatch(success(notif)),
                error => dispatch(failure(error.toString())),
            );
    };   

    function request() { return { type: notifConstants.GET_NOTIF_REQUEST} }
    function success(notif) { return { type: notifConstants.GET_NOTIF_SUCCESS, notif } }
    function failure(error) { return { type: notifConstants.GET_NOTIF_FAILURE, error} }

}

function getNotifByPage(page){
    return dispatch => {
        dispatch(request());
        notifService.getNotifByPage(page)
            .then(
                notif => dispatch(success(notif)),
                error => dispatch(failure(error.toString())),
            );
    };   

    function request() { return { type: notifConstants.GET_NOTIFPAGE_REQUEST} }
    function success(notif) { return { type: notifConstants.GET_NOTIFPAGE_SUCCESS, notif } }
    function failure(error) { return { type: notifConstants.GET_NOTIFPAGE_FAILURE, error} }

}

function createNotif(notification){
        
        return dispatch => {
            dispatch(request(notification));
    
            notifService.createNotif(notification)
                .then(
                    notification => { 
                        dispatch(success());
                        dispatch(alertActions.success('New post successfully created'));
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.success('Something Went Wrong'));
                        
                    }
                );
        };
    

    function request(notification) { return { type: notifConstants.POST_NOTIF_REQUEST, notification} }
    function success(notification) { return { type: notifConstants.POST_NOTIF_SUCCESS, notification } }
    function failure(error) { return { type: notifConstants.POST_NOTIF_FAILURE, error} }
}


function updateNotif(id, notification){
        
    return dispatch => {
        dispatch(request(id, notification));

        notifService.updateNotif(id, notification)
            .then(
                notification => { 
                    dispatch(success());
                    dispatch(alertActions.success('Notification successfully updated.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.success('Something Went Wrong'));
                    
                }
            );
    };


function request(notification) { return { type: notifConstants.UPDATE_NOTIF_REQUEST, notification} }
function success(notification) { return { type: notifConstants.UPDATE_NOTIF_SUCCESS, notification } }
function failure(error) { return { type: notifConstants.UPDATE_NOTIF_FAILURE, error} }
}




