import questionServices from "../services/question";
import questionConstants from "../constants/question";
import { MessageAfterSubmit } from "../components/Alert";
// import questionnaireActions from "./questionnaire";

function getAll(page, items) {
    return dispatch => {
        dispatch(request(page, items));
        questionServices.getAll(page, items)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request(page, items) { return { type: questionConstants.GET_REQUEST, page, items }}
    function success(questions) { return { type: questionConstants.GET_SUCCESS, questions } }
    function failure(error) { return { type: questionConstants.GET_FAILURE, error} }
}

function create(question, id) {
    return dispatch => {
        dispatch(request());
        questionServices.create(question, id)
            .then(
                question => {
                    dispatch(success(question));
                    MessageAfterSubmit("success", question.success);
                },
                error => {
                    MessageAfterSubmit("error", error.toString());
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request() { return { type: questionConstants.CREATE_REQUEST }}
    function success(question) { return { type: questionConstants.CREATE_SUCCESS, question } }
    function failure(error) { return { type: questionConstants.CREATE_FAILURE, error} }
}

function update(item, id) {
    return dispatch => {
        dispatch(request());
        questionServices.update(item, id)
            .then(
                question => {
                    dispatch(success(question));
                    MessageAfterSubmit("success", question.success.message);
                },
                error => {
                    MessageAfterSubmit("error", error.toString());
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request() { return { type: questionConstants.UPDATE_REQUEST }}
    function success(question) { return { type: questionConstants.UPDATE_SUCCESS, question } }
    function failure(error) { return { type: questionConstants.UPDATE_FAILURE, error} }
}

function remove (id, questionnaireId) {
    return dispatch => {
        dispatch(request());
        questionServices.remove(id, questionnaireId)
            .then(
                question => {
                    dispatch(success(question));
                    // MessageAfterSubmit("success", question.success);
                    // dispatch(questionnaireActions.getAllQuestions(questionnaireId));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // MessageAfterSubmit("error", error.toString());
                }
            );
    };   

    function request() { return { type: questionConstants.DELETE_REQUEST }}
    function success(question) { return { type: questionConstants.DELETE_SUCCESS, question } }
    function failure(error) { return { type: questionConstants.DELETE_FAILURE, error} }
}

function getById(id) {
    return dispatch => {
        dispatch(request());
        questionServices.getById(id)
            .then(
                question => {
                    dispatch(success(question));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };   

    function request() { return { type: questionConstants.GETBYID_REQUEST }}
    function success(question) { return { type: questionConstants.GETBYID_SUCCESS, question } }
    function failure(error) { return { type: questionConstants.GETBYID_FAILURE, error} }
}

export default {
    getAll,
    create,
    update,
    remove,
    getById,
}