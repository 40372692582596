import React from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { challengeActions } from '../_core/actions/challenge';
import { achievementActions } from '../_core/actions/achievement';
import MainNav from '../Components/MainNav';
import MaskedInput from 'react-maskedinput'
import M from 'materialize-css/dist/js/materialize.min.js'
import { wagertype, difficulty } from '../_core/constants/selections'

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';

import BodyBackgroundColor from 'react-body-backgroundcolor'
import { userActions } from '../_core/actions/user';

// import './CreateChallenge.sass'

registerPlugin(FilePondPluginImagePreview);

class UserCreateChallengePage extends React.Component {
	constructor(props){
		super(props);
		let user = JSON.parse(localStorage.getItem('userCredentials'));
		this.state = {
			challenge: {
				userId: user.user._id,
				title: '',
				content: '',
				type: '',
				value: '',
				wager: '',
				requiredAchievements: '',
				status: 'new',
				finishedAt: '',
				duration: '',

				
			},
			selections: {
				wagertype: wagertype,
				difficulty: difficulty
			},
			submitted: false,
			render: true
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.renderAlert = this.renderAlert.bind(this);
		this.replaceDuration = this.replaceDuration.bind(this);
	}
	handleChange(event) {
        const { name, value } = event.target;
        const { challenge }        = this.state;

        this.setState({
            challenge: {
                ...challenge,
                [name]: value
            }
        });
	}
	

    componentDidMount() {
		this.props.dispatch(achievementActions.getAll());
		this.props.dispatch(userActions.getAll(1, 100000));

        var Select = document.querySelectorAll('select');
			M.FormSelect.init(Select);
		
		setTimeout(function() {
			var achievementSelect = document.querySelectorAll('#achievement');
			M.FormSelect.init(achievementSelect);
		}, 1000)
    }


	handleSubmit(event) {
		
		event.preventDefault();
        this.setState({ submitted: true });
		const { challenge } = this.state;
		if(challenge.title && challenge.content && challenge.type && challenge.value && challenge.duration && challenge.duration){
			this.props.dispatch(challengeActions.create(challenge))
			// console.log(challenge);
		}

	}
	
	renderAlert(){
        const { alert } = this.props;
        if(this.state.render) { 
            return (<div onLoad={this.autoClose} id="alert" className={`alert ${alert.type}`} toggle={this.onDismiss}>{alert.message}<span className="closebtn right" onClick={this.handleClose}>&times;</span></div>)
            
        } else {
            return null
        }
	}
	handleClose(event){
        event.preventDefault()
        this.setState({ render: false })
	}
	replaceDuration(str){
		var newNewStr = str;
		
		newNewStr = newNewStr.replace("_", "0");
		
		return newNewStr;
	}
    render() {
		const { creating, alert, achievements }    = this.props;
		const { challenge, selections,submitted } = this.state;
        return (
        	<BodyBackgroundColor backgroundColor='#ffffff'>
       			<div className="create-challenge-page">
        			<MainNav/>
        			<div className="row">
	        			<div className="col s12 m6 offset-m3">
	        				<h6 className="challenges"> Challenge Management </h6>
		        			<div className="bg-create-challenge">
								<h6 className="create-challenges"> Create Challenge </h6>
		        				<hr/>
		        				<div className="container">
		        					<form name="form" onSubmit={this.handleSubmit}>
										{alert.message &&
                                            this.renderAlert()
                                        }
										<div className={'input-field' + (submitted && !challenge.title ? ' has-error' : '')}>
		                                    <label htmlFor="challenge" className="challenge-name">Challenge Name</label>
		                                    <input type="text" className="form-control validate" name="title" id="challenge" value={challenge.title} onChange={this.handleChange}/>
											{submitted && !challenge.title &&
                                            	<div className="helper-text red-text">Challenge title is required</div>
                                        	}
										</div>
		                                <div className="input-field">
		                                	<label htmlFor="textarea1" className="descp">Description</label>
								        	<textarea id="textarea1" className="materialize-textarea" name="content" value={challenge.content} onChange={this.handleChange}></textarea>
											{submitted && !challenge.content &&
                                            	<div className="helper-text red-text">Challenge Description is required</div>
                                        	}
										</div>
								        <div className="input-field col s12 m12 l6">
												
												<select name="type" value={challenge.type} onChange={this.handleChange}>
								           			<option defaultValue="">--- Choose Difficulty ---</option>
								           			{selections.difficulty.default.map((difficulty, index) =>
							                           <option key={difficulty.value} value={difficulty.label}>{difficulty.label}</option>
							                        )}
									    		</select>
											<label htmlFor="difficulty">Difficulty</label>
											{submitted && !challenge.type &&
                                            	<div className="helper-text red-text">Type is required</div>
                                        	}
										</div>
										<div className="input-field col s12 m12 l6">
												<select name="wager" value={challenge.wager} onChange={this.handleChange}>
								           			<option defaultValue="">--- Choose Wager ---</option>
								           			{selections.wagertype.default.map((wagertype, index) =>
							                           <option key={wagertype.value} value={wagertype.label}>{wagertype.label}</option>
							                        )}
									    		</select>
										    <label htmlFor="wager">Wager</label>
											{submitted && !challenge.wager &&
                                            	<div className="helper-text red-text">Wager is required</div>
                                        	}
										</div>
										<div className="input-field col s12 m12 l6">
											<label className="wager-points">Wager Points</label>
											<input type="number" className="form-control validate" id="wager-points"  min="1" max="100" name="value" value={challenge.value} onChange={this.handleChange}/> 
											{submitted && !challenge.value &&
                                            	<div className="helper-text red-text">Wager points is required</div>
                                        	}
										</div>
										<div className="input-field col s12 m12 l6">
												{achievements.items &&
	                                                <select id="achievement" name="requiredAchievements" value={challenge.requiredAchievements} onChange={this.handleChange}>
	                                                    <option defaultValue="">--- Choose achievement ---</option>
	                                                    {achievements.items.map((achievement, index) =>
	                                                       <option key={achievement._id} value={achievement._id}>{achievement.title}</option>
	                                                    )}
	                                                </select>
												}
										    <label>Required Achievements</label>
											{submitted && !challenge.requiredAchievements &&
                                            	<div className="helper-text red-text">Challenge achievement is required</div>
                                        	}
										</div>
												
										<div className="input-field col s12 m12 l12">
								
										<label>Duration - year-month-day hour:min:sec</label>
										</div>
										
										<div className="input-field col s12 m12 l12">
										
												<MaskedInput
												name="duration"
												mask="11-11-11::11:11:11"
												placeholder="YY-MM-DD::hh:mm:ss"
												size="19"
												value={challenge.duration}
												onChange={this.handleChange}
												/>
											{submitted && !challenge.duration &&
                                            	<div className="helper-text red-text">Duration is required</div>
                                        	}
										</div>
										<label className="img-lbl">Image:</label>
										<div className="file-field input-field">
									    	<FilePond allowMultiple={false} maxFiles={1} server='null'/>
									    </div>
									    <div className="left-align">
	                                        <button className="waves-effect waves-light btn btn-create">Create</button>
	                                        {creating &&
												<img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
											}
											<Link to="/challenge" className="waves-effect waves-light btn red btn-cancel">Cancel</Link>
                                   		 </div>
		                            </form>
		        				</div>
		        			</div>
		        		</div>
	        		</div>
				</div>
			</BodyBackgroundColor>
        );
    }
}

function mapStateToProps(state) {
	const { creating } = state.challenges;
    const { alert,achievements } = state;
    return {
        creating,
		alert,
		achievements
    };
}
const connectedUserCreateChallengePage = connect(mapStateToProps)(UserCreateChallengePage);

export { connectedUserCreateChallengePage as UserCreateChallengePage };