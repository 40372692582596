import React from 'react';
import MainNav from '../Components/MainNav';

import StepZilla from "react-stepzilla";

import Step1 from '../_templates/steps/step1.js';
import Step2 from '../_templates/steps/step2.js';
import Step3 from '../_templates/steps/step3.js';
import Step4 from '../_templates/steps/step4.js';

import './NewUserEditor.sass'

class NewUserEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.sampleStore = {
          email: '',
          gender: '',
          savedToCloud: false
        };
  }
    componentDidMount() {

    }

    getStore() {
        return this.sampleStore;
    }

    updateStore(update) {
        this.sampleStore = {
          ...this.sampleStore,
          ...update,
        }
    }

    render() {

        const steps =
            [
                {name: 'User Profile', component: <Step1 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
                {name: 'Personal Information', component: <Step2 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
                {name: 'User Credentials', component: <Step3 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
                {name: 'Employment Details', component: <Step4 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
            ]

        return (
          <div className="user-editor-page">
            <MainNav/>
              <div className="center-align">
                <div className='step-progress container'>
                  <StepZilla
                    steps={steps}
                    showNavigation={false}
                   />
                </div>
              </div>
          </div>
        );
    }
}

export { NewUserEditor };
