import requestsConstants from '../constants/requests';

export default function requests(state = {}, action){
    switch(action.type){
        case requestsConstants.GET_REQUESTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case requestsConstants.GET_REQUESTS_SUCCESS:
            return {
				...state,
				...action.requests,
				loading: false
            };
        case requestsConstants.GET_REQUESTS_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
};