import React from 'react';

import Sub from './Sub';
import Main from './Main';

import './NavigationBar.scss';

const NavigationBar = (props) => {
    return (
        <div className="row navigation-bar" style={{ position: "sticky", top: 0, zIndex: 200 }}>
            <Sub
                isSidebarExpanded={props.isSidebarExpanded}
                toggleIsSidebarExpanded={props.toggleIsSidebarExpanded}
                title={props.leftNavbarTitle}
            />
            <Main title={props.rightNavbarTitle}/>
        </div>
    );
};

export default NavigationBar;