import React from 'react';
import cc from 'classcat';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import isEmpty from 'lodash/isEmpty';
import Modal from 'react-modal';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { If, Else, Then, When } from 'react-if';

import { essService } from '../_core/services/ess';

import Avatar from '../Components/Avatar/Avatar';
//import clock from '../_templates/clock_icon.png';
import closeIcon from '../_templates/x_square.png';
import close from '../_templates/dark/off_outline_close.png';
import dot from '../_templates/dot.png'
import statusPillApproved from '../_templates/status_pill_approved.png';
import statusPillCompleted from '../_templates/status_pill_completed_new.svg';
import statusPillOnHold from '../_templates/status_pill_on_hold.png';
import statusPillInProgress from '../_templates/status_pill_in_progress.svg';
import statusPillPending from '../_templates/status_pill_pending.png';
import statusPillRejected from '../_templates/status_pill_rejected.png';
import download from '../_templates/dark/download.png';
import fileIcon from '../_templates/dark/file_blank_outline.png';
import calendar from '../_templates/calendar_calendar.png';
import send from '../_templates/send.png';

import { history } from '../_core/helpers/history';
import { commentActions } from '../_core/actions/comment';
import { commentService } from '../_core/services/comment';

import Dropzone from "../Components/AttachFile/Dropzone";
import FileViewer from 'react-file-viewer';


import 'react-datepicker/dist/react-datepicker.css';
import './RequestModal.scss';

Modal.defaultStyles.overlay.zIndex = 1000;
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.8)';
Modal.defaultStyles.content.border = 'none';
Modal.defaultStyles.content.borderRadius = 0;
Modal.defaultStyles.content.bottom = 60;
Modal.defaultStyles.content.padding = 0;
Modal.defaultStyles.content.top = 60;

Modal.setAppElement(document.getElementById('root'));

const editViewMode = ['edit', 'view'];
const createEditMode = ['create', 'edit'];

const statusPills = {
    'approved': statusPillApproved,
    'completed': statusPillCompleted,
    'new': statusPillPending,
    'on hold': statusPillOnHold,
		'in progress': statusPillInProgress,
    'rejected': statusPillRejected
};

const customStyles = {
    content: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }
  };

function isFile(input) {
	if ('File' in window && input instanceof File)
	   return true;
	else return false;
}

const RequestHistory = ({ authentication, request }) => (
	<div className="request-history">
		<div className="header">
			<h6>Request History</h6>
			<button>
				<img src={dot} alt="options"/>
			</button>
		</div>
		<div className="request-history-container">
			{
				isEmpty(request.rejecter_details) === false &&
				<div>
					<div className="status">
						<img src={statusPills.rejected} alt="rejected"/>
						<div className="bar"/>
					</div>
					<div className="profile">
						<Link to={`/profile/${request.rejecter_details[0].user_id}`}>
							<Avatar
								size={30}
								src={request.rejecter_details[0].avatar}
							/>
						</Link>
						
						<div className="details">
							<Link to={`/profile/${request.rejecter_details[0].user_id}`}>
								<p className="details-name">
									{
										[
											request.rejecter_details[0].firstName,
											request.rejecter_details[0].lastName,
										].join(' ')
									}
								</p>
							</Link>
							<p>Rejected request</p>
							<p title={
								[
									moment(request.rejectedAt).utc().format('MMMM DD, YYYY'),
									moment(request.rejectedAt).utc().format('h:mm A')
								].join(' at ')
							} >
								{ moment(request.rejectedAt).utc().fromNow() }
							</p>
						</div>
					</div>
				</div>
			}
			{
				isEmpty(request.approver_details) === false &&
				<div>
					<div className="status">
						<img src={request.subtype === 'technical support' ? statusPills.completed : statusPills.approved } alt="aproved"/>
						<div className="bar"/>
					</div>
					<div className="profile">
						<Link to={`/profile/${request.approver_details[0].user_id}`}>
							<Avatar
								size={30}
								src={request.approver_details[0].avatar}
							/>
						</Link>
						<div className="details">
							<Link to={`/profile/${request.approver_details[0].user_id}`}>
								<p className="details-name">
									{
										[
											request.approver_details[0].firstName,
											request.approver_details[0].lastName,
										].join(' ')
									}
								</p>
							</Link>
							<p>Approved request</p>
							<p title={
								[
									moment(request.approvedAt).utc().format('MMMM DD, YYYY'),
									moment(request.approvedAt).utc().format('h:mm A')
								].join(' at ')
							} >
								{ moment(request.approvedAt).utc().fromNow() }
							</p>
						</div>
					</div>
				</div>
			}
			{
				isEmpty(request.noter_details) === false &&
				<div>
						<div className="status">
							<img src={request.subtype === 'technical support' ?  statusPills['in progress'] : statusPills['on hold']} alt="on hold"/>
							<div className="bar"/>
						</div>
						<div className="profile">
							<Link to={`/profile/${request.noter_details[0].user_id}`}>
								<Avatar
									size={30}
									src={request.noter_details[0].avatar}
								/>
							</Link>
							<div className="details">
								<Link to={`/profile/${request.noter_details[0].user_id}`}>
									<p className="details-name">
										{
											[
												request.noter_details[0].firstName,
												request.noter_details[0].lastName,
											].join(' ')
										}
									</p>
								</Link>
								<p>Updated status</p>
								<p title={
									[
										moment(request.notedAt).utc().format('MMMM DD, YYYY'),
										moment(request.notedAt).utc().format('h:mm A')
									].join(' at ')
								} >
									{ moment(request.notedAt).utc().fromNow() }
								</p>
							</div>
						</div>
				</div>
			}
			<div>
				<div className="status">
					<img src={statusPills.new} alt="pending"/>
				</div>
				<div className="profile">
					<Link to={`/profile/${request.users?.[0].user_id}`}>
						<Avatar
							src={request.users?.[0].avatar}
							size={30}
						/>
					</Link>
					<div className="details">
						<p className="details-name">
							<If
								condition={request.user_id === authentication.user.user._id}
							>	
								<Then>You</Then>
								<Else>
									<Link to={`/profile/${request.users?.[0].user_id}`}>
										{
											[
												request?.users?.[0]?.firstName,
												request?.users?.[0]?.lastName
											].join(' ')
											.trim()
										}
									</Link>
								</Else>
							</If>
						</p>
						<p title={
							[
								moment(request.createdAt).utc().format('MMMM DD, YYYY'),
								moment(request.createdAt).utc().format('h:mm A')
							].join(' at ')
						} >
							{ moment(request.createdAt).utc().fromNow() }
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
);

const RequestRemarks = (props) => {
	const dispatch = useDispatch();
	const request = props.request;
	const comment = props.comment;
	const replyComment = props.isReply;
	const type = replyComment ? "reply" : "comment";
	const urlId = props.commentId
	let user = JSON.parse(localStorage.getItem('user'));

	const [ replies, setReplies ] = React.useState([]);
	const [commentContent, setCommentContent] = React.useState({
        originId: "",
        type: type,
        content: "",
		attachFile: ""
    });
	const [ update, setUpdate ] = React.useState(false);
	const [ clearUpdate, setClearUp ] = React.useState(false);
	const [ fileStatus, setFileStatus ] = React.useState(false);
	


	React.useEffect(()=>{
        setCommentContent({
            ...commentContent,
            originId: urlId !== undefined ? urlId : props.request._id,
            type: type,
            content: "",
        });
		setReplies(props.replies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props]);


    const clearInput = () => {
        setCommentContent({
            ...commentContent,
            originId: urlId !== undefined ? urlId : props.request._id,
			type: type,
            content: "",
			attachFile: ""
        });
		setClearUp(true)
    }


	const commentValue = (e) => {
        const code = (e.keyCode ? e.keyCode : e.which);
		
        let inputValue = e.target.value;
        let removeWhitespaces = inputValue.trim()
        if (removeWhitespaces !== '') {
            const text = e.target.value
            setCommentContent({
                ...commentContent,
                originId: urlId !== undefined ? urlId : props.request._id,
                type: type,
                content: text,
				attachFile: ""
            });
            
            if(code === 13 && !e.shiftKey){
                sendComment();
            }
			setUpdate(true)
        } else {
            clearInput();
			setUpdate(false)
        }
    };

	const replySubmit = (submitComment) => {

		setTimeout(function () {
			commentService.getByOrigin(submitComment.originId, 1, 999999999).then(res=>{
				if(res.items){
					setReplies(res.items)
				}
			}).catch(error=>{
				console.log(error)
			});
		}, 500)

    }

	const sendComment = () => {
		let status = request.status === 'on hold' ? 'hold' : request.status;
        const submitComment = ({
            ...commentContent,
            content: status+": " + commentContent.content.replace(/(\r\n|\n|\r)$/gm, "")
        });
        try{

            dispatch(commentActions.postComment(submitComment))
			
			clearInput();
			// setClearUp(false)
			// .then((res)=>{

            //     // if(res.success){

			setTimeout(function () {
				dispatch(commentActions.getByOrigin(submitComment.originId, 1, 999999999));
			}, 500)
           
			setUpdate(false)
			
                    
			if(submitComment.type === "reply") {
				replySubmit(submitComment)
		
			}
			setTimeout(function () {
				setClearUp(false)
			}, 1000)
			// 	// 	console.log(submitComment)
					
            //     // }
			// 	console.log(res)
            // }).catch(error=>{
			// 	console.log(error)
			// });
			
			
        }catch(err){
            console.log(err)
        }
    }

	const setimage = (url) => {
		return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
	}

	const [showFilePreview, setFilePreview] = React.useState(false);
	const [FileUrl, setFileUrl] = React.useState('');
	const [FileType, setFileType] = React.useState('');

	const openFilePreview = () => {
		setFilePreview(true);
	};

	return (

		<div className="request-remarks">
			{
				isEmpty(request.rejecter_details) === false &&
				<div>
					<div className='remarks-header'>
						<div style={{marginRight:'15px'}}>
							<Link to={`/profile/${request.rejecter_details[0].user_id}`}>
								<Avatar
									size={30}
									src={request.rejecter_details[0].avatar}
								/>
							</Link>
						</div>
						<div>
							<h6>Reject remark</h6>
							<p>{ request.rejectedRemark }</p>
						</div>
					</div>
					<div className='commetReply-Div'>
						<div className="commentReply-container">
							{comment.length > 0 && comment.filter(venue =>venue.content.toLowerCase().startsWith('rejected')).map((comments)=>(
								<div className='comment-right'>

									{
										comments.poster !== user.user_id ?
											<div className='comment-container'>
												<Link to={`/profile/${comments.poster}`}>
													<Avatar
														src={comments.details[0].avatar}
														size={30}
													/>
												</Link>
												<Link to={`/profile/${comments.poster}`}>
													<p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(request.rejecter_details) === false ? 'rejected' : ""}>{ isEmpty(request.rejecter_details) === false ? 'Rejected' : "" }</span></p>
												</Link>
												
											</div>
										:
											<div className='comment-container'>
												<Link to={`/profile/${comments.poster}`}>
													<p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(request.rejecter_details) === false ? 'rejected' : ""}>{ isEmpty(request.rejecter_details) === false ? 'Rejected' : "" }</span></p>
												</Link>
												<Link to={`/profile/${comments.poster}`}>
													<Avatar
														src={comments.details[0].avatar}
														size={30}
													/>
												</Link>
												
											</div>
									}

									<div className='text-content'><p>{ comments.content.split(':')[1] }</p></div>
								</div>
								
							)).reverse()}

							{replies.length > 0 && replies.filter(venue =>venue.content.toLowerCase().startsWith('rejected')).map((reply)=>(
								<div key={reply._id} className ={reply.poster !== user.user_id ? 'comment-left' : 'comment-right'} id='postRepliesContainer'>
									
										{
											reply.poster !== user.user_id ?
											<div className='comment-container'>
												<Link to={`/profile/${reply.poster}`}>
													<Avatar
														src={reply.details[0].avatar}
														size={30}
													/>
												</Link>
												
												<Link to={`/profile/${reply.poster}`}>
													<p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(request.rejecter_details) === false ? 'rejected' : "" }>{ isEmpty(request.rejecter_details) === false ? 'Rejected' : "" }</span></p>
												</Link>
											</div>
											:
											<div className='comment-container'>
													<Link to={`/profile/${reply.poster}`}>
														<p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(request.rejecter_details) === false ? 'rejected' : "" }>{ isEmpty(request.rejecter_details) === false ? 'Rejected' : "" }</span></p>
													</Link>
													<Link to={`/profile/${reply.poster}`}>
														<Avatar
															src={reply.details[0].avatar}
															size={30}
														/>
													</Link>
											</div>
										}

									
									<div className='text-content'><p>{ reply.content.split(':')[1] }</p></div>
								</div>
							)).reverse()}
						</div>

						<div className={request.status !== 'rejected' ? 'comment-hide' : 'add-comment-request'}>
							<textarea 
								className="insert-comment" 
								placeholder={replyComment ? "Type your reply here" : "Add a comment" }
								onKeyUp={ (e)=> commentValue(e) }
								onChange={ (e)=> commentValue(e) }
								value = {commentContent.content}
								id={'commentForm' + request._id}
							>
							</textarea>
							{
								update !== false ?
								<img src={send} height={20} alt="" onClick={ ()=> sendComment() }/>
								:
								<img src={send} height={20} alt=""/>
							}
						</div>
					</div>
				</div>
			}

			{
				isEmpty(request.approver_details) === false &&
				<div>
					<div className='remarks-header'>
						<div style={{marginRight:'15px'}}>
							<Link to={`/profile/${request.approver_details[0].user_id}`}>
								<Avatar
									src={request.approver_details[0].avatar}
									size={30}
								/>
							</Link>
							
						</div>
						<div>
							<h6>{request.subtype === 'technical support' ?  'Completed remark' : 'Approval remark'}</h6>
							<p>{ request.approvalRemark }</p>
						</div>
					</div>
					<div className='commetReply-Div'>
						<div className="commentReply-container">
							{comment.length > 0 && comment.filter(venue =>venue.content.toLowerCase().startsWith('approved')).map((comments)=>(
								<div className='comment-right'>
									{
										comments.poster !== user.user_id ?
											<div className='comment-container'>
												<Link to={`/profile/${comments.poster}`}>
													<Avatar
														src={comments.details[0].avatar}
														size={30}
													/>
												</Link>
												<Link to={`/profile/${comments.poster}`}>
													<p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(request.approver_details) === false ? 'approved' : ""}>{ isEmpty(request.approver_details) === false ? request.subtype === 'technical support' ?  'Completed' : 'Approved' : ""}</span></p>
												</Link>
											</div>
										:
											<div className='comment-container'>
												<Link to={`/profile/${comments.poster}`}>
													<p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(request.approver_details) === false ? 'approved' : ""}>{ isEmpty(request.approver_details) === false ? request.subtype === 'technical support' ?  'Completed' : 'Approved' : ""}</span></p>
												</Link>
												<Link to={`/profile/${comment.poster}`}>
													<Avatar
														src={comments.details[0].avatar}
														size={30}
													/>
												</Link>
											</div>
									}

									<div className='text-content'><p>{ comments.content.split(':')[1] }</p></div>
								</div>								
								
							)).reverse()}

							{replies.length > 0 && replies.filter(venue =>venue.content.toLowerCase().startsWith('approved')).map((reply)=>(
								<div key ={reply._id} className ={reply.poster !== user.user_id ? 'comment-left' : 'comment-right'} id='postRepliesContainer'>
									
										{
											reply.poster !== user.user_id ?
											<div className='comment-container'>
												<Link to={`/profile/${reply.poster}`}>
													<Avatar
														src={reply.details[0].avatar}
														size={30}
													/>
												</Link>
												<Link to={`/profile/${reply.poster}`}>
													<p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(request.approver_details) === false ? 'approved' : "" }>{ isEmpty(request.approver_details) === false ? request.subtype === 'technical support' ?  'Completed' : 'Approved' : "" }</span></p>
												</Link>
											</div>
											:
											<div className='comment-container'>
												<Link to={`/profile/${reply.poster}`}>
													<p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(request.approver_details) === false ? 'approved' : "" }>{ isEmpty(request.approver_details) === false ? request.subtype === 'technical support' ?  'Completed' : 'Approved' : ""}</span></p>
												</Link>
												<Link to={`/profile/${reply.poster}`}>
													<Avatar
														src={reply.details[0].avatar}
														size={30}
													/>
												</Link>
											</div>
										}

									
									<div className='text-content'><p>{ reply.content.split(':')[1] }</p></div>
								</div>
							)).reverse()}
						</div>

						<div className={request.status !== 'approved' ? 'comment-hide' : 'add-comment-request'}>
							<textarea 
								className="insert-comment" 
								placeholder={replyComment ? "Type your reply here" : "Add a comment" }
								onKeyUp={ (e)=> commentValue(e) }
								onChange={ (e)=> commentValue(e) }
								value = {commentContent.content}
								id={'commentForm' + request._id}
							>
							</textarea>
							{
								update !== false ?
								<img src={send} height={20} alt="" onClick={ ()=> sendComment() }/>
								:
								<img src={send} height={20} alt=""/>
							}
							
						</div>
					</div>
				</div>
			}

			{
				isEmpty(request.noter_details) === false &&
				<div style={{marginTop:'10px'}}>
					<div className='remarks-header'>
						<div style={{marginRight:'15px'}}>
							<Link to={`/profile/${request.noter_details[0].user_id}`}>
								<Avatar
									src={request.noter_details[0].avatar}
									size={30}
								/>
							</Link>

						</div>
						<div>
							<h6>{request.subtype === 'technical support' ? 'In Progress remark' : 'Hold remark'}</h6>
							<p>{ request.notedRemark }</p>
						</div>
					</div>

					<div className='commetReply-Div'>
						<div className="commentReply-container">
							{comment.length > 0 && comment.filter(venue =>venue.content.toLowerCase().startsWith('hold')).map((comments)=>(
								<div key ={comments._id} className ={comments.poster !== user.user_id ? 'comment-left' : 'comment-right'}>

										{
											comments.poster !== user.user_id ?
											<div className='comment-container'>
												<Link to={`/profile/${comments.poster}`}>
													<Avatar
														src={comments.details[0].avatar}
														size={30}
													/>
												</Link>
												<Link to={`/profile/${comments.poster}`}>
													<p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(request.noter_details) === false ? 'hold' : ""}>{ isEmpty(request.noter_details) === false ? request.subtype === 'technical support' ? 'In Progress' : 'Hold': "" }</span></p>
												</Link>
											</div>
											:
											<div className='comment-container'>
												<Link to={`/profile/${comments.poster}`}>
													<p>{comments.details[0].firstName +" "+ comments.details[0].lastName} : <span className={isEmpty(request.noter_details) === false ? 'hold' : ""}>{ isEmpty(request.noter_details) === false ? request.subtype === 'technical support' ? 'In Progress' : 'Hold': "" }</span></p>
												</Link>
												<Link to={`/profile/${comments.poster}`}>
													<Avatar
														src={comments.details[0].avatar}
														size={30}
													/>
												</Link>
											</div>
										}
										

									{/* <div className='text-content'><p>{ comments.content.split(':')[1] }</p></div> */}
									<div className='text-content'>
									{
											comments.attachFile !== null && update !== true?

											<>
												{
													setimage(comments.attachFile) !== false ?
														<>
															<p>{ comments.content.split(':')[1] }</p>
															<img src={comments.attachFile} height={150} alt=""  style={{cursor:'pointer', paddingTop:"10px",border:'none', borderRadius:0}} onClick={() => {  openFilePreview(); setFileUrl(comments.attachFile); setFileType(comments.attachFile.split('.').pop()) }}/>
														
														</>
													:
														<>
															<p style={{whiteSpace:'initial', wordWrap:'anywhere', marginBottom:'5px'}}>{ comments.content.split(':')[1] }</p>
															<p onClick={() => {  openFilePreview(); setFileUrl(comments.attachFile); setFileType(comments.attachFile.split('.').pop()) }} style={{cursor:'pointer', fontWeight:'700', color:'blue'}}>{ comments.attachFile.split('/').pop()}</p>
														</>


												}
												
												
											</>

											:
											 <p>{ comments.content.split(':')[1] }</p>

										}
										</div>									
								</div>
								
								
							)).reverse()}
							{replies.length > 0 && replies.filter(venue =>venue.content.toLowerCase().startsWith('hold')).map((reply)=>(
								<div key={reply._id} className ={reply.poster !== user.user_id ? 'comment-left' : 'comment-right'} id='postRepliesContainer'>
									
										{
											reply.poster !== user.user_id ?
											<div className='comment-container'>
												<Link to={`/profile/${reply.poster}`}>
													<Avatar
														src={reply.details[0].avatar}
														size={30}
													/>
												</Link>
												<Link to={`/profile/${reply.poster}`}>
													<p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(request.noter_details) === false ? 'hold' : ""}>{ isEmpty(request.noter_details) === false ? request.subtype === 'technical support' ? 'In Progress' : 'Hold': "" }</span></p>
												</Link>
											</div>
											:
											<div className='comment-container'>
												<Link to={`/profile/${reply.poster}`}>
													<p>{reply.details[0].firstName +" "+ reply.details[0].lastName} : <span className={isEmpty(request.noter_details) === false ? 'hold' : ""}>{ isEmpty(request.noter_details) === false ? request.subtype === 'technical support' ? 'In Progress' : 'Hold': "" }</span></p>
												</Link>
												<Link to={`/profile/${reply.poster}`}>
													<Avatar
														src={reply.details[0].avatar}
														size={30}
													/>
												</Link>
											</div>
										}

									
									{/* <div className='text-content'><p>{ reply.content.split(':')[1] }</p></div> */}
									<div className='text-content'>
										
										{
											reply.attachFile !== null && update !== true?

											<>
												{
													setimage(reply.attachFile) !== false ?
														<>
															<p>{ reply.content.split(':')[1] }</p>
															<img src={reply.attachFile} height={150} alt=""  style={{paddingTop:"10px",border:'none', borderRadius:0, cursor:'pointer'}} onClick={() => {  openFilePreview(); setFileUrl(reply.attachFile); setFileType(reply.attachFile.split('.').pop()) }}/>
														
														</>
													:
														<>
															<p style={{whiteSpace:'initial', wordWrap:'anywhere', marginBottom:'5px'}}>{ reply.content.split(':')[1] }</p>
															<p onClick={() => {  openFilePreview(); setFileUrl(reply.attachFile); setFileType(reply.attachFile.split('.').pop()) }} style={{cursor:'pointer', fontWeight:'700', color:'blue'}}>{ reply.attachFile.split('/').pop()}</p>
														</>


												}
												
												{/* {
													imgError !== false ?
													<>
														<p>{ reply.content.split(':')[1] }</p>
														<img src={reply.attachFile} height={150} alt="" onError={()=>setimage()} style={{paddingTop:"10px",border:'none', borderRadius:0}}/>
													</>
													
													:
													<>
														<p>{ reply.content.split(':')[1] }</p>
														<img src={reply.attachFile} height={150} alt="" style={{paddingTop:"10px",border:'none', borderRadius:0}}/>
														<p>{ reply.attachFile.split('/').pop()}</p>
													</>
													
												} */}
												
												
											</>

											:
											 <p>{ reply.content.split(':')[1] }</p>

										}
										
									</div>									
								</div>
							)).reverse()}
						</div>

						<div className={request.status !== 'on hold' ? 'comment-hide' : 'add-comment-request attachDiv'} style={fileStatus !== true ? {height:'70%'} : {height:'auto'}}>
						{/* <div className='add-comment'> */}
							{/* <textarea 
								className="insert-comment" 
								placeholder={replyComment ? "Type your reply here" : "Add a comment" }
								onKeyUp={ (e)=> commentValue(e) }
								onChange={ (e)=> commentValue(e) }
								value = {commentContent.content}
								id={'commentForm' + request._id}
							>
							</textarea> */}
							{/* {
								update !== false ?
								<img src={send} height={20} alt="" onClick={ ()=> sendComment() }/>
								:
								<img src={send} height={20} alt=""/>
							} */}
							{/* {
								update !== false ? */}
								<div >

										<Dropzone 
											item={items => {
												setCommentContent({
													...commentContent,
													attachFile: items.attachFile,
													content: items.content,
												})
												setFileStatus(items.attachFile?.length !== 0 ? true : false)
												setUpdate(items.contentUpdate)
											}}
											clear={clearUpdate}
											
											/>
									<img src={send} height={20} alt="" onClick={ ()=> commentContent.content.length !== 0  || isFile(commentContent.attachFile) !== false? sendComment() : ''} style={fileStatus !== true ? {top:'10px'} : {top:'16px'}}/>
								</div>
								
						</div>
						<div>
                            <Modal
                                isOpen={showFilePreview}
                                ariaHideApp={true}
                                toggle={setFilePreview}
                                onRequestClose={() => setFilePreview(false)}
                                shouldCloseOnOverlayClick={true}
                                className="fileModalPreview"
                             	style={customStyles}
                            >
                                <div
                                    className="row center rejecting-container"
                                    style={{
                                        width: "auto",
                                        height: "auto",
                                     	margin: "auto",
                                        padding: "50px",
                                    }}
                                >
                                <div className="gAction">
                                    <a href={FileUrl} download>
                                        <button className="btn-download" style={{background:'none'}}>
                                        	<img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'brightness(5.5)' }}/>
                                        </button>
                                    </a>
                                        <img height={20} onClick={() => setFilePreview(false)} src={close} alt="" style={{ margin:'15px 7px', filter: 'brightness(5.5)', cursor:'pointer' }}/>
                                </div>
                                <div style={{ height: '100%' }}>
                                    <FileViewer 
                                        fileType={FileType} 
                                        filePath={FileUrl} 
                                        key= {FileUrl}
                                    />
                                </div>

                            </div>
                        </Modal>
                    </div>						
					</div>


					
				</div>
			}
		</div>
	);
};

const RequestModal = (props) => {
	const [ title, setTitle ] = React.useState('');
	const [ type, setType ] = React.useState('');
	const [ description, setDescription ] = React.useState('');
	const shouldModifyModalWidth = useMediaQuery({ maxWidth: 1200 });
	const [ urlId, setUrlId ] = React.useState('');

	const [IsSubmitted, setIsSubmitted] = React.useState(false);

	const close = React.useCallback(() => {
		props.history.push('/employee-self-service');
	}, [props.history]);

	const submit = React.useCallback((e) => {
		e.preventDefault();
	}, []);

	var comments = props.comment;
	var replies = props.replies;
	
	React.useEffect(() => {
		if(comments.length !== 0) {
			let newState = comments.length > 0  && comments.map((e) => e._id);
			setUrlId(newState[0])
		}else{
			setUrlId(props.getId)
		}


	},[comments,props.request, urlId, props.getId]);

	
    const submittedCommentCallback = (callbackSubmit) => {
        setIsSubmitted(callbackSubmit);
    }

	const TotalHours =(start,end) =>{
		const startedAt = moment(start);
		const endedAt = moment(end);
		
		// Calculate the difference in milliseconds
		const duration = moment.duration(endedAt.diff(startedAt));

		// Get the total hours and total minutes
		const totalHours = duration.asHours();
		const totalMinutes = duration.asMinutes();

		let result = '';

		if (totalHours >= 1) {
			const hours = Math.floor(totalHours);
			const remainingMinutes = Math.round(totalMinutes % 60); // Round the minutes
			result = `${hours} hour${hours !== 1 ? 's' : ''}`;
			if (remainingMinutes > 0) {
				result += ` ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
			}
		} else {
			const minutes = Math.round(totalMinutes);
			result = `${minutes} minute${minutes !== 1 ? 's' : ''}`;
		}

		
		return result;
	}


	return (
		<Modal
			isOpen={true}
			onRequestClose={close}
			shouldCloseOnOverlayClick={true}
			className="RequestModalUser"
			centered
			style={{
				content: {
					left: shouldModifyModalWidth ? 50 : 200,
					right: shouldModifyModalWidth ? 50 : 200,
				}
			}}
		>
			<div
				className={cc([
					'request-modal',
					props.mode,
					{
						'invalid-request-id': props.invalidRequestId
					}
				])}
			>
				<button className="close-modal" onClick={close}>
					<img src={closeIcon} alt="close"/>
				</button>
				<div className="header" style={{display:'flex'}}>
					{
						props.invalidRequestId === false &&
						<>
							<div className="title-container" style={{flex:1}}>
								{
									editViewMode.includes(props.mode) &&
									<div className="request-date">
										<img src={calendar} height={20} alt="date"/>
										<p>
											{
												[
													moment(props.request.startedAt).utc().format('MM/DD/YYYY'),
													moment(props.request.endedAt).utc().format('MM/DD/YYYY')
												].join(' - ')
											}
										</p>
									</div>
								}
								{
									props.mode === 'create' &&
									<h6>Create request</h6>
								}
							</div>
							{/* {
								editViewMode.includes(props.mode) &&
								<div className="request-date">
									<img src={clock} height={20} alt="date"/>
									<p>
										{
											[
												moment(props.request.startedAt).utc().format('MM/DD/YYYY'),
												moment(props.request.endedAt).utc().format('MM/DD/YYYY')
											].join(' - ')
										}
									</p>
								</div>
							} */}
							{
								editViewMode.includes(props.mode) &&
								<div className="request-status" style={{flex:1, justifyContent:'center'}}>
									<p>Current status:</p>
									<img src={props.request.subtype === 'technical support' && props.request.status === 'on hold' ? statusPills['in progress']  : props.request.subtype === 'technical support' && props.request.status === 'approved' ? statusPills['completed'] :  statusPills[props.request.status]} alt={props.request.status} height={30}/>
								</div>
							}
							<div className='dateFiled' style={{flex:1, justifyContent:'end', display:'flex'}}>
								<p style={{fontWeight:'700', paddingRight:'2px'}}>Date Filed : </p> 
								<p>{moment(props.request.createdAt).utc().format('MM/DD/YYYY')}</p>
							</div>
						</>
					}

				</div>
				{
					editViewMode.includes(props.mode) &&
					<div className="title-rfcode">
						<h6>{ props.request.subject }</h6>
						<p>{ props.request.refCode }</p>
					</div>
				}
				{
					props.invalidRequestId ?
					<div className="request-not-found">
					<p>Request not found</p>
					</div> :
					<div className="request-details-container">
						<div className="request-details">
							<div className="header">
								<h6>Request details</h6>
								<button>
									<img src={dot} alt="options"/>
								</button>
							</div>
							{
								createEditMode.includes(props.mode) &&
								<form onSubmit={submit}>
									<div className="form-field">
										<label htmlFor="request-title">Request title</label>
										<input
											type="text"
											name="request-title"
											onChange={e => { setTitle(e.target.value) }}
											placeholder="Write request title"
											value={title}
											/>
									</div>
									<div className="form-field">
										<label htmlFor="request-type">Request type</label>
										<select
											name="request-type"
											onChange={e => { setType(e.target.value) }}
											value={type}
											>
											<option value="" disabled>Select request type</option>
											<option value="sick leave">Sick leave</option>
											<option value="medical leave">Medical leave</option>
										</select>
									</div>
									<div className="form-field">
										<label htmlFor="request-description">Description</label>
										<textarea
											name="request-description"
											onChange={e => { setDescription(e.target.value) }}
											placeholder="Write description"
											value={description}
											/>
									</div>
									<div className="row date">
										<div className="col s6">
											<div className="form-field date">
												<label htmlFor="from">From</label>
												<DatePicker
													// timeIntervals={10}
													showTimeSelect
													/>
											</div>
										</div>
										<div className="col s6">
											<div className="form-field date">
												<label htmlFor="to">To</label>
												<DatePicker
													// timeIntervals={10}
													showTimeSelect
													/>
											</div>
										</div>
									</div>
									<div className="policy">
										<p>Here is where content for simple reminders of policy rules</p>
										<br/>
										<p>1. First item</p>
										<p>2. Second item</p>
									</div>
									<div className="actions">
										<button className="close" onClick={close}>
											<p>Close</p>
										</button>
									</div>
								</form>
							}
							{
								editViewMode.includes(props.mode) &&
								<>
									
									<div className="description">
										<p>{ props.request.description }</p>
									</div>

									<div className={props.request.files && props.request.files.length !== 0 ? 'attachement' : ''}>
										{props.request.files?.map((c) => (
												<div key={c} className="user-attach-div">
													<div className="attach-preview">
														<img
															src={c}
															className="attach-file"
															alt="Thumb"
															onError={(e) => (e.target.src = fileIcon) }
														/>
															<p>{c.split('/').pop()}</p>
													</div>
													<a href={c} download>
														<button className="btn-download" style={{background:'none'}} >
															<img height={20} src={download} alt="" style={{ marginRight: 7, filter: 'contrast(0.5)' }}/>
														</button>
													</a>
												</div>
											))
											
										}
									</div>
									<When condition={props.request.subtype === 'overtime' || props.request.subtype === 'undertime'}>
										<div className="overtime-period">
											<div>
												<h6>From</h6>
												<div>{moment(props.request.startedAt).utc().format('hh:mm A')}</div>
											</div>
											<div>
												<h6>To</h6>
												<div>{moment(props.request.endedAt).utc().format('hh:mm A')}</div>
											</div>
										</div>
										<div className="total-hours">
												<h6 style={{fontSize:'14px', marginBottom:'10px'}}>Total Hours</h6>
												<div style={{backgroundColor:'#fafafa', border:'solid 1px #DDDDDD', borderRadius:'5px', padding:'10px', whiteSpace:'pre-wrap'}}>{TotalHours(props.request.startedAt,props.request.endedAt)}</div>
										</div>
									</When>
									<RequestRemarks
											request={props.request}
											comment={props.comment}
											replies={replies}
											isReply={Object.keys(props.comment).length !== 0}
											commentId={urlId}
											submittedComment={ submittedCommentCallback }
											isRepliedSubmitted= { IsSubmitted }
										/>

									<div className="policy">
										<p>Here is where content for simple reminders of policy rules</p>
										<br/>
										<p>1. First item</p>
										<p>2. Second item</p>
									</div>
									<div className="actions">
										<button className="close" onClick={close}>
											<p>Close</p>
										</button>
									</div>
								</>
							}
						</div>
						{
							editViewMode.includes(props.mode) &&
							<RequestHistory authentication={props.authentication} request={props.request}/>
						}
					</div>
				}
			</div>
		</Modal>
	);
};

var prevComments = {};

const commentUpdates = (comments) => {
    const commentsList = comments;
    prevComments = commentsList;
}

const WrappedRequestModal = (props) => {
	const dispatch = useDispatch();
	const getId = history.location.pathname.split('view/')[1];
	const [ request, setRequest ] = React.useState({});
	const [ invalidRequestId, setInvalidRequestId ] = React.useState(false);
	const [ shouldFetch, setShouldFetch ] = React.useState(true);
	const [hasComment, setHasComment] = React.useState({});


	const getComments = props.comments
	var comments = prevComments;

	React.useEffect(
		() => {
			const getRequest = async () => {
				if(shouldFetch === false) {
					return;
				}

				if(
					props.loading === false &&
					isEmpty(props.request)
				){
					setShouldFetch(false);

					try {
						const getRequest = await essService.getById(props.match.params.requestId)
						const request = getRequest?.success?.[0] || {};

						if(isEmpty(request)){
							setInvalidRequestId(true)
						} else {
							setRequest(request)
						}
					} catch(error) {
						setInvalidRequestId(true);
					}
				} else {
					setRequest(props.request)
				}
			};

			getRequest();
		},
		[
			setShouldFetch,
			shouldFetch,
			props.loading,
			props.match,
			props.request,
			
		]
	);

	React.useEffect(()=>{
		if(getId){

			commentService.getByOrigin(getId, 1, 999).then(res=>{
                if(res.items){
					
					if(res.items.length !== 0){
						dispatch(commentActions.getByOrigin(getId, 1, 999));
						commentService.getByOrigin(res.items[0]._id, 1, 999).then(res=>{
							if(res.items){
			
								if(res.items.length !== 0){
									setHasComment(res.items)
								}
			
							}
						}).catch(error=>{});
					}
				}
			})
		}

        return ()=> { 
            prevComments = {};
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, getId]);

	if(request && getComments && getComments.filter((c)=> c.originId === request._id).length > 0){
        comments = getComments;
        commentUpdates(getComments);
    }

	return props.loading === false ? <RequestModal {...props} invalidRequestId={invalidRequestId} request={request} comment={comments} replies={hasComment} getId={getId}/> : null
};


const mapStateToProps = (state,props) => {
    const getOrigin = state.comments?.orig_comment?.items || {};
    const getComments = getOrigin.length > 0 && getOrigin.filter(c => c.type === "comment");
    const getReplies = getOrigin.length > 0 && getOrigin.filter(c => c.type === "reply");
    const loading = state.comments?.loading;
	
    return({
        comments: getComments,
        replies: getReplies,
        loadingComments: loading,
		authentication: state.authentication,
		loading: state.ess.getAllByUser?.loading,
		request: state.ess.getAllByUser?.data?.items?.filter(request => (
			request._id === props.match.params.requestId
		))?.[0] || {}	
    });
};

export default React.memo(connect(mapStateToProps)(WrappedRequestModal));