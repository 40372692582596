import sweetAlert from 'sweetalert2';

export const MessageAfterSubmit = (status, message) => {

    const theme = "#2a3042";
    const fontColor = "#FFFFFF";

    const toast = sweetAlert.mixin({
        background: theme,
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    return toast.fire({
        type: status,
        title: `
          <span style="color: ${fontColor}; font-size: 1rem;">
              ${message}
          </span>
      `
    });
};

export const UserToast = (status, message) => {

    // const theme = "#2a3042";
    const fontColor = "green";

    const toast = sweetAlert.mixin({
        toast: true,
        width: 400,
        // height: 600,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
    });

    return toast.fire({
        type: status,
        title: `
          <span style="color: ${fontColor}; font-size: 1rem;">
              ${message}
          </span>
      `
    });
};