export const technicalConstants = {
    
    GETALL_REQUEST: 'TECHNICAL_GETALL_REQUEST',
    GETALL_SUCCESS: 'TECHNICAL_GETALL_SUCCESS',
    GETALL_FAILURE: 'TECHNICAL_GETALL_FAILURE',

    GETUSER_REQUEST: 'TECHNICAL_GETUSER_REQUEST',
    GETUSER_SUCCESS: 'TECHNICAL_GETUSER_SUCCESS',
    GETUSER_FAILURE: 'TECHNICAL_GETUSER_FAILURE',

    UPDATE_REQUEST: 'TECHNICAL_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'TECHNICAL_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'TECHNICAL_UPDATE_FAILURE',

    APPROVE_REQUEST: 'TECHNICAL_APPROVE_REQUEST',
    APPROVE_SUCCESS: 'TECHNICAL_APPROVE_SUCCESS',
    APPROVE_FAILURE: 'TECHNICAL_APPROVE_FAILURE',

    HOLD_REQUEST: 'TECHNICAL_HOLD_REQUEST',
    HOLD_SUCCESS: 'TECHNICAL_HOLD_SUCCESS',
    HOLD_FAILURE: 'TECHNICAL_HOLD_FAILURE',

    DECLINE_REQUEST: 'TECHNICAL_DECLINE_REQUEST',
    DECLINE_SUCCESS: 'TECHNICAL_DECLINE_SUCCESS',
    DECLINE_FAILURE: 'TECHNICAL_DECLINE_FAILURE',

}