import { feedsConstants } from '../constants/feeds';
import { feedsServices } from '../services/feeds';

export const feedsActions = {
    getFeedsByUser
}

function getFeedsByUser(id, selectPage){
    return dispatch => {
        dispatch(request(id, selectPage))
        
        feedsServices.getFeedsByUser(id, selectPage)
            .then(
                userFeeds => dispatch(success(userFeeds)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: feedsConstants.GET_USERFEEDS_REQUEST } }
    function success(userFeeds) { return { type: feedsConstants.GET_USERFEEDS_SUCCESS, userFeeds } }
    function failure(error) { return { type: feedsConstants.GET_USERFEEDS_FAILURE, error } }   
}

