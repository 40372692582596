import evaluationConstants from "../constants/evaluation";

const initialState = {
    data: [],
    dataById: [],
    dataLastEvaluation: [],

    loading: true,
    isError: false,
    userEvaluations: [],
    peerEvaluations: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
      case evaluationConstants.GET_REQUEST:
        return {
          ...state,
          loading: true,
          isError: false,
          itemsPerPage: action.items,
        };
      case evaluationConstants.GET_SUCCESS:
        return {
          ...state,
          loading: false,
          isError: false,
          data: action.evaluations,
          list: action.evaluations.items,

          totalPerPage: action.evaluations.total,
          perPage: action.evaluations.pages,
          currentPage: action.evaluations.page,
        };
      case evaluationConstants.GET_FAILURE:
        return {
          ...state,
          loading: false,
          isError: action.error,
        };

      case evaluationConstants.GETBYID_REQUEST:
        return {
          ...state,
          loading: true,
          isError: false,
        };
      case evaluationConstants.GETBYID_SUCCESS:
        return {
          ...state,
          loading: false,
          isError: false,
          dataById: action.evaluation,
        };
      case evaluationConstants.GETBYID_FAILURE:
        return {
          ...state,
          loading: false,
          isError: action.error,
        };


        case evaluationConstants.GET_USER_REQUEST:
            return {
                ...state,
                userEvaluationsLoading: true,
                userEvaluationsError: false,
                userEvaluationItemsPerPage: action.items, 
            };
        case evaluationConstants.GET_USER_SUCCESS:
            return {
                ...state,
                userEvaluationsLoading: false,
                userEvaluationsError: false,
                userEvaluations: action.evaluations
            };
        case evaluationConstants.GET_USER_FAILURE:
            return {
                ...state,
                userEvaluationsLoading: false,
                userEvaluationsError: action.error,
            };

        case evaluationConstants.GET_PEER_REQUEST:
            return {
                ...state,
                peerEvaluationsLoading: true,
                peerEvaluationsError: false,
                peerEvaluationsItemsPerPage: action.items,
            };
        case evaluationConstants.GET_PEER_SUCCESS:
            return {
                ...state,
                peerEvaluationsLoading: false,
                peerEvaluationsError: false,
                peerEvaluations: action.evaluations
            };
        case evaluationConstants.GET_PEER_FAILURE:
            return {
                ...state,
                peerEvaluationsLoading: false,
                peerEvaluationsError: action.error,
            };

      case evaluationConstants.GET_LAST_EVALUATION_REQUEST:
        return {
          ...state,
          loading: true,
          isError: false,
          dataLastEvaluation: [],
        };
      case evaluationConstants.GET_LAST_EVALUATION_SUCCESS:
        return {
          ...state,
          loading: false,
          isError: false,
          dataLastEvaluation: action.evaluation.success,
        };
      case evaluationConstants.GET_LAST_EVALUATION_FAILURE:
        return {
          ...state,
          loading: false,
          isError: action.error,
          dataLastEvaluation: [],
        };

      default:
        return state;
    }
}
