import React from 'react';
import { If, Then } from 'react-if';
import Skeleton from 'react-loading-skeleton';

import classnames from './Avatar.module.scss';

import avatar from '../../_templates/man.png';

const Avatar = ({ border = "", onClick: onClickProp = () => { }, onLoad = () => { }, src, size = 35 }) => {
	const [isError, setIsError] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);

	const onClick = React.useCallback((event) => {
		onClickProp(event);
	}, [onClickProp]);

	const onError = React.useCallback(() => {
		onLoad();
		setIsError(true);
	}, [onLoad]);

	const onImageLoad = React.useCallback(() => {
		onLoad();
		setIsLoading(false);
	}, [onLoad]);

	React.useEffect(() => {
		setIsError(false);
		setIsLoading(true);
	}, [src, setIsError, setIsLoading]);

	return (
		<div className={classnames.avatar} style={{ border, height: size, width: size }} onClick={onClick}>
			<div className={classnames['invisible-overlay']} />
			<img
				alt="avatar"
				className={isLoading ? 'hide' : ''}
				onError={onError}
				onLoad={onImageLoad}
				src={isError ? avatar : src ?? avatar}
				height={size}
				width={size}
			/>
			<If condition={isLoading}>
				<Then>
					<div className={classnames.skeleton}>
						<Skeleton
							circle
							duration={0.8}
							height={size}
							width={size}
						/>
					</div>
				</Then>
			</If>
		</div>
	);
}

export default React.memo(Avatar);