import React from 'react';

const openLinkInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export default (text = "") => {
    if (text !== "") {
        let textSplit = text.split(/\s/);

        let formatText = textSplit.map((txt, i) => {
            let hasSpace = i !== (textSplit.length - 1);
            let maybeSpace = hasSpace ? ' ' : '';

            if (txt.match(/^http\:\//)) { //eslint-disable-line
                return (
                    <span
                        key={i} // Adding unique key prop here
                        style={{ color: '#2D93EE', cursor: 'pointer' }}
                        onClick={()=>openLinkInNewTab(txt)}>
                        {txt}{maybeSpace}
                    </span>
                );
            } else {
                return <span key={i}>{txt + maybeSpace}</span>; // Adding unique key prop here
            }
        });

        return <span>{formatText}<br/></span>
    }

    return text
}
