import { get, post } from '../helpers/api';
import { userService } from '../services/user';

export const missionService = {
    getMissions,
    getMissionById,
    startMission
};

function getMissions(quest, mission) {
    return get(`/api/v1/quest/${quest}/mission`).then(handleResponse);
}

function getMissionById(quest, mission) {
    return get(`/api/v1/quest/${quest}/mission/${mission}`).then(handleResponse);
}

function startMission(mission) {
   let userid = mission.userId;
   let questid = mission.questId
   return post(`/api/v1/user/${userid}/quest/${questid}/mission`, JSON.stringify(mission))
       .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }

        return data;
    });
}
