import React from "react";
import styled from "styled-components";
import dot from "../../../_templates/table-icons/action-dot-highlight.png";

import ReactTooltip from "react-tooltip";
import delete_icon from "../../../_templates/dark/delete.png";
import complete_challenge from "../../../_templates/complete.png";
import failed_icon from "../../../_templates/dark/close.png";
// import viewParticipants_icon from "../../../_templates/dark/group.png";
import viewChallenge_icon from "../../../_templates/dark/external_link.png";

// const ActionWrapperTwoIcon = styled.div`
//   display: none;

//   > img {
//     margin: 0 5px;
//   }
//   > img:hover {
//     cursor: pointer;
//   }

//   @media (max-width: 993px){
//     display: block;
//   } 
// `;
// export const DynamicTableAction = (props) => {
//   return (
//     <div>
//       {props.originPage !== undefined && props.iconSet === "2" && (
//         <ActionWrapperTwoIcon>
//           <img
//             src={edit_icon}
//             alt=""
//             data-tip={props.editLabel}
//             place="top"
//             data-class="tooltips"
//             onClick={props.onClickEdit}
//           />
//           <img
//             src={delete_icon}
//             alt=""
//             data-tip={props.deleteLabel}
//             place="top"
//             data-class="tooltips"
//             onClick={props.onClickDelete}
//           />
//           <ReactTooltip />
//         </ActionWrapperTwoIcon>
//       )}
//     </div>
//   );
// };

const ActionWrapperDots = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  
  >img{
    cursor: pointer;
  }

  >div.dropownAction{
    background-color: #2A3143;
    border-top: 2px solid #566282;
    width: 170px;
    height: 5%;
    box-shadow: 0 5px 4px 5px rgba(0, 0, 0, 0.03);
    padding: 10px 0 0;
    position: absolute;
    max-width: 14.75rem;

    &.open {
      display: block;
    }

    &.close {
      display: none;
    }

    >div {
        >ul {
            background-color: #2A3143;
            width: 100%;
            height: 100%;
            padding: 10px 0;            
            >li {
                display: flex;
                align-items: center;
                cursor: pointer;
                width: 100%;
                height: 37px;
                padding: 10px 10px;
                text-align: left;      
                >img{
                    display: flex;
                    align-items: center;
                    filter: invert(45%) sepia(17%) saturate(463%) hue-rotate(184deg) brightness(94%) contrast(89%);
                }
                >span{
                    padding-left: 10px;
                }
                &:hover {
                    background-color: #32394A;
                }
    
            }



        }
    }

  }

//   >div.dropownAction{
//     position: absolute;
//     width: auto;
//     height: auto;
//     min-height: 97px;
//     right: 82px;
//     margin-top: -12px;
//     overflow: visible!important;

//     &.open {
//       display: block;
//     }

//     &.close {
//       display: none;
//     }

//     >div {
//       width: 5px;
//       height: 5px;
//       border-top: 5px solid transparent;
//       border-left: 5px solid transparent;
//       border-right: 5px solid transparent;
//       border-bottom: 5px solid #566282;
//       margin-left: auto;
//       margin-right: 5px;

//       >ul {
//         background-color: #2A3143;
//         border-top: 2px solid #566282;
//         width: 100%;
//         height: 100%;
//         box-shadow: 0 5px 4px 5px rgba(0, 0, 0, 0.03);
//         padding: 10px 0;
  
//         >li {
//           width: 100%;
//           height: 37px;
//           padding: 10px 10px;
//           text-align: left;
//           cursor: pointer;
          
  
//           >img {
//             margin-right: 15px;
            
//             margin-top: -5px;
//             vertical-align: middle;
//             display: inline-block; 
//           }
  
//           >span {
//             margin: auto 0;
//             font-size: 14px;
//             font-weight: 500;
//             color: inherit;
  
//             margin-top: -5px;
//             vertical-align: middle;
//             display: inline-block;
//           }
  
//           &:hover {
//             background-color: #32394A;
//           }
//         }
//       }
//     }


  }



  
  @media (max-width: 993px){
    display: none;
  }
`;

export const DynamicEngagementAction = (props) => {
  const [isOpen, setOpen] = React.useState(false);
  const [defaultWidth, setDefaultWidth] = React.useState(0);
  const wrapperRef = React.useRef(null);

  const handleClickOutside = React.useCallback((event) => {
    let ref = wrapperRef;
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  }, [wrapperRef]);

  React.useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]); // eslint-disable-line

//   const addPermission = JSON.parse(localStorage.getItem('addPermission'));
//   const editPermission = JSON.parse(localStorage.getItem('editPermission'));
//   const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
//   const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  React.useEffect(() => {
    setDefaultWidth(wrapperRef.current ? wrapperRef.current.offsetWidth : 0);
}, [wrapperRef.current, defaultWidth]);// eslint-disable-line


  return (
    <>
        <ActionWrapperDots className="dropdown" ref={wrapperRef}>

            <div className='cursor-pointer actionBtn ' onClick={() => setOpen(() => !isOpen)}>
                <img src={dot} alt="actions"  style={{cursor:'pointer'}}/>
            </div>

            <div
                id="dropdown"
                style={{right:`${defaultWidth/2}px`}}
                className={isOpen ? "dropownAction open" : "dropownAction close"}
            >

                <div >
                    <ul>

                        <>

                            {
                                props.originPage === 'QuestChallenge'  ?
                                
                                    props.challengeStatus === 'failed' || props.challengeStatus === 'completed'?
    
                                        <>
                                            {props.deleteParticipants && <li className="" onClick={props.onDeleteParticipants}><img src={delete_icon} alt="edit"/><span>Delete</span></li>}
                                        </>
    
                                    : props.challengeStatus === 'finished' ?
                                        <>
                                            {props.onCompleteChallenge && <li className="" onClick={props.onCompleteChallenge}><img src={complete_challenge} alt="edit"/><span>Complete</span></li>}
                                            {props.onFailedChallenge && <li className="" onClick={props.onFailedChallenge}><img className="filter-view" src={failed_icon} alt="edit" style={{width:'23px', height:'23px'}}/><span>Failed</span></li>}
                                            {props.deleteParticipants && <li className="" onClick={props.onDeleteParticipants}><img src={delete_icon} alt="edit"/><span>Delete</span></li>}
                                        </>
                                    : props.originPage === 'viewParticipants' ?
                                        <>
                                            {props.viewChallengeParticipants && <li className="" onClick={props.onClickViewParticipants}><img src={viewChallenge_icon} alt="edit" className="filter-view"/><span>{props.viewChallengeParticipants}</span></li>}
                                        </>
                                    :
                                        <>
                                            {/* {props.onFinishedChallenge && <li className="" onClick={props.onFinishedChallenge}><img src={complete_challenge} alt="edit"/><span>Finished</span></li>} */}
                                            {props.deleteParticipants && <li className="" onClick={props.onDeleteParticipants}><img src={delete_icon} alt="edit"/><span>Delete</span></li>}
                                        </>
                                
                                
                                :
                                props.originPage ==='viewParticipants' && props.viewChallengeParticipants &&
                                    <li className=""  onClick={props.onClickViewParticipants}>
                                        <img src={viewChallenge_icon} alt="edit"/>
                                        <span>{props.viewChallengeParticipants}</span>
                                    </li>
                            }


                        </>
                    </ul>
                </div>
            </div>

            </ActionWrapperDots>
      {/* {props.originPage !== undefined 
        && props.iconSet === "2" 
        && props.editStatus === false
        && (
        <ActionWrapperTwoIcon>
          {props.completeLabel &&
          <img
            src={complete_icon}
            alt=""
            data-tip={props.completeLabel}
            place="top"
            data-class="tooltips"
            onClick={props.onClickAddChallenge}
          />
          }
          <img
            src={edit_icon}
            alt=""
            data-tip={props.editLabel}
            place="top"
            data-class="tooltips"
            onClick={props.onClickEdit}
          />
          <img
            src={delete_icon}
            alt=""
            data-tip={props.deleteLabel}
            place="top"
            data-class="tooltips"
            onClick={props.onClickDelete}
          />
          
        </ActionWrapperTwoIcon>
      )} */}

      <ReactTooltip place="bottom"/>
    </>
  );
};
