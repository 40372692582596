import { get } from '../helpers/api';
import { userService } from '../services/user';

export const timekeepingServices = {
    getAll,
    getUser,
    getAllPrags,
    getByUser,
};

function getAll(page, itemsPerPage) {
    return get(`/api/v1/timekeeping/Pragmanila?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}

function getAllPrags(page, itemsPerPage, start, end) {
    return get(`/api/v1/timekeeping/Pragmanila?page=${page || 1}&items-per-page=${itemsPerPage || 20}&startDate=${start}&endDate=${end}`).then(handleResponse);
}

function getUser(user,start,end) {
    return get(`/api/v1/timekeeping/Pragmanila/${user}?&startDate=${start}&endDate=${end}`).then(handleResponse);
}

// function getByUser(username) {
//     return get(`/api/v1/timekeeping/pragger/${username}`).then(handleResponse);
//   }

function getByUser(user) {
    return get(`/api/v1/timekeeping/Pragmanila/${user}`).then(handleResponse);
  }

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}