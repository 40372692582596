import { creditConstants } from '../constants/credit';

let initialState = {
    loading: false,
    error: null,
}

export function credits(state = initialState, action) {
    switch (action.type) {
        case creditConstants.GET_TOTAL_REQUEST:
            return {
                ...state,
                totalCreditError: false,
            };
        case creditConstants.GET_TOTAL_SUCCESS:
            return {
                ...state,
                totalCredit: action?.credits?.success[0] || 0
            };
        case creditConstants.GET_TOTAL_FAILURE:
            return {
                ...state,
                totalCreditError: action.error
            };

        default:
            return state;
    }
}