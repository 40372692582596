import { technicalConstants } from '../constants/technical';

let initialState = {
    items: [],
    loading: true,
    error: null,
    totalPerPage: 0,
    perPage: 0,
    currentPage: 0,
  }

export function technical (state = initialState, action) {
    switch (action.type) {

        //Get All
        case technicalConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case technicalConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                items: action.technical.items,
                totalPerPage: action.technical.total,
                perPage: action.technical.pages,
                currentPage: action.technical.page,

                //technical: action.technical
            };
        case technicalConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        //Get user
        case technicalConstants.GETUSER_REQUEST:
            return {
                ...state,
                error: '',
                loading: true,
                success: false
            };
        case technicalConstants.GETUSER_SUCCESS:
            return {
                ...state,
                ...action.technical,
                loading: false,
            };
        case technicalConstants.GETUSER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
