import React from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import Select, { components } from "react-select";
import moment from 'moment';

import { badgesActions } from '../../_core/actions/badges';
import { userActions } from "../../_core/actions/user";
import { questActions } from "../../_core/actions/quest";
import { achievementActions } from "../../_core/actions/achievement";
import { questService } from "../../_core/services/quest";

import { history } from "../../_core/helpers/history";

import { useParams } from "react-router-dom";

import {
  ContentWrapper,
  LabelCustom,
  SaveAndCancelButton,
} from "../Components/PageWrapper/ContentWrapper";

import avatar from '../../_templates/man.png';
import defaultCover from '../../_templates/alt_cover.png';

import "./Achievements.scss";


export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

export const SaveAndCancelBtnWrapper = styled.div`
  width: 100%;

  > div {
    max-width: 125px;
    margin-left: auto;

    > button {
      width: 120px;
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      border: 1px solid #556ee6;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 15px;

      &:nth-child(1) {
        background-color: transparent;
        color: #556ee6;

        &:focus {
          background-color: transparent;
        }
      }
    }
  }
`;

const mapStateToProps = ({achievements,users }) => ({
    achievements,
    users
  
  });
  
  const CreateEditAhievement = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    const selectInputRef =React.useRef();
    const selectInputRefUsers =React.useRef();
    const selectInputRefQuest =React.useRef();
    const selectInputRefBadge =React.useRef();

    const [usersList, setUsers] = React.useState([]);
    const [badgesList, setBadges] = React.useState([]);
    const [questsList, setQuests] = React.useState([]);
    const [colorError, setcolorError] = React.useState(false);
    const [ClearSelect, setClearSelect] = React.useState(false);
    const [showUsers, setShowUsers] = React.useState(false);
    const [isAdd, setIsAdd] = React.useState(true); // eslint-disable-line
    const [showDiv, setDivShow] = React.useState(false);
    const [ArrayBadge, setArrayBadge] = React.useState([]);
    

    const { pageId } = useParams();



    const [inputValues, setInputValue] = React.useState({
        user_id: "",
        badge_id: null,
        requirement: "",
        requirementIds:""
    });

    React.useEffect(() => {
        if (pageId) {
            setIsAdd(false); // Set update
            dispatch(achievementActions.getOne(pageId)); // call selected challege id
        }
        //eslint-disable-next-line
      }, [dispatch, pageId]);

      React.useEffect(() => {
        dispatch(userActions.getAllActiveUsers(1,100000,'',''));
        dispatch(badgesActions.getAll(1,100000,'',''));
        dispatch(questActions.getAll(1,100000,'',''));
        //eslint-disable-next-line
    }, [dispatch,inputValues]);

    React.useEffect(() => {
        filterUsersDropdownValue(props?.users?.users?.items);
        filterBadgeDropdownValue(props?.badges?.items?.items);
        filterQuestsDropdownValue(props?.quests?.items?.items);
        //eslint-disable-next-line
    }, [props.users,props.badges,props.quests]);

    React.useEffect(() => {
            if(ClearSelect === true){
                selectInputRefUsers.current.select.clearValue();
                selectInputRefQuest.current.select.clearValue();
                selectInputRefBadge.current.select.clearValue();
                setClearSelect(false)
            }
        //eslint-disable-next-line
    }, [ClearSelect]);

    React.useEffect(() => {

        // prepopulate selected achievement to edit
        if (pageId) {

           let selectedData =  props.achievements.items ?? [];
            if(selectedData) {
                selectedData.forEach((item) => {
                    setInputValue({
                        user_id: item.user_id,
                        badge_id: item.badge_id,
                        requirement: item.requirement,
                        requirementIds: item.requirementIds
                    })
                    setDivShow(true)
                    setShowUsers(true)
              })
            }
        }
        //eslint-disable-next-line
      }, [props.achievements, pageId, dispatch]);

    const filterUsersDropdownValue = (data) => {
        let optionGroupData = [];
        
        if(props.mode !=='edit' && inputValues.requirement === 'quest' && props.mode !=='view'){
            questService.getById(inputValues.requirementIds).then(res => {

                let questsList = []

                data && data.filter(user => !res.success?.[0].participants.includes(user._id)).map((items) => {
                   
                        if (items.length !== 0) {
                            questsList.push({
                            label: 
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <img src={items.details[0].avatar ? items.details[0].avatar : avatar} onError={(e) => e.target.src = avatar} alt="avatar" height="25px" width="25px" style={{objectFit:'contain', marginRight:'10px', borderRadius:'50%'}}/>
                                        <p>{items.details[0].firstName+' '+items.details[0].lastName}</p>
                                    </div>,
                            value: items._id,
                            
                            });
                        }
                        return null                                
                })

                if(res.success?.[0].badge?.length !== undefined){
                    setArrayBadge(res.success?.[0].badge)
                }else{
                    setArrayBadge([])
                }

                setUsers(questsList)
    
            }).catch(err => console.error(err));

        }else{

            data && data.map((items) => {
                if (items.length !== 0) {
                  optionGroupData.push({
                    label: 
                          <div style={{display:'flex',alignItems:'center'}}>
                              <img src={items.details[0].avatar ? items.details[0].avatar : avatar} onError={(e) => e.target.src = avatar} alt="avatar" height="25px" width="25px" style={{objectFit:'contain', marginRight:'10px', borderRadius:'50%'}}/>
                              <p>{items.details[0].firstName+' '+items.details[0].lastName}</p>
                          </div>,
                    value: items._id,
                    
                  });
                }
                return null
              });
        }

        setUsers(optionGroupData);
      };
      const filterBadgeDropdownValue = (data) => {
        let optionGroupData = [];
        data && data.map((items) => {
          if (items.length !== 0) {
            
            optionGroupData.push({
              label: 
                    <div style={{display:'flex',alignItems:'center'}}>
                        <img src={items.badgeImage ? items.badgeImage : defaultCover} onError={(e) => e.target.src = defaultCover} alt="badge" height="25px" width="25px" style={{objectFit:'contain', margin:'4px', borderRadius:'50%'}}/>
                        <p>{items.badgeName}</p>
                    </div>,
              value: items._id,
              disabled: true
              
            });
          }
          return null
        });

    
        setBadges(optionGroupData);
      };

      const filterQuestsDropdownValue = (data) => {

        let optionGroupData = [];
        let today = moment().utc();

        data && data
        .filter(item => !moment(today).isSameOrAfter(moment(item.expiresAt)))
        .map((items, index) => {
            if (items.length !== 0) {
                optionGroupData.push({
                  label: 
                        <div style={{display:'flex',alignItems:'center'}}>
                            <p key={index}>{items.questTitle}</p>
                        </div>,
                  value: items._id,
                  
                });
              }
              return null
        })

    
        setQuests(optionGroupData);
      };

      const handleChange = (data) => {
        const names = data.target.name
        const value = data.target.value

        if(value === 'quest' ){
            setDivShow(true)
            setInputValue({ ...inputValues, [names]: value });
        }else if (value === 'anniversary' || value === 'none'){
            setDivShow(true)
            setInputValue({ ...inputValues, [names]: value });
            setShowUsers(true)
            setArrayBadge([])
        }else if(value === ''){
            setDivShow(false)
            setInputValue({
                user_id: "",
                badge_id: "",
                requirement:"",
                requirementIds:""
            })
            setClearSelect(true)
            setShowUsers(false)
        }else{
            setDivShow(false)
            setInputValue({
                user_id: "",
                badge_id: "",
                requirement:"",
                requirementIds:""
            })
            
        }

    };

    const handleChangeQuest = (data) => {
        const names = data.target.name
        const value = data.target.value
        if(inputValues.requirement !== ''){
            setInputValue({ ...inputValues, [names]: value });
            setShowUsers(true)
        }

    };

    // const questBadges = (data) => {
    //     setInputValue({ ...inputValues, badge_id: data });

    // };

    const handleBadges = (data) => {
        const badgeList =[]
        data && data.map((items) => {
            if (items.length !== 0) {
                badgeList.push(items.value);
            }
            return null
        });
        setInputValue({ ...inputValues, badge_id: badgeList });
    };

    React.useEffect(() => {
        if(history.action === 'POP'){
            history.goBack();
        }
        //eslint-disable-next-line
    }, [history.action, ArrayBadge])


    const cancelAnnouncement = () => {

        history.goBack()
        setcolorError(false);
    }

    const submit = () => {

        if(
            !inputValues.requirement
        ) {
            setcolorError(true);
        }else{

            let achievementData = {
                user_id: inputValues.user_id,
                badge_id: ArrayBadge.length !== 0 ? ArrayBadge : inputValues.badge_id,
                requirement: inputValues.requirement,
                requirementIds: inputValues.requirementIds
            }

            if(isAdd){

                dispatch(achievementActions.create(achievementData));
                setcolorError(false);
                setTimeout(() => {
                    history.goBack()    
                }, 1500)
                
            } else {

                dispatch(achievementActions.update(pageId,achievementData))
                setcolorError(false);
                setTimeout(() => {
                    history.goBack()    
                }, 1500)
            
            }
        }

    }
    const customStyles = {
        control: (base, state) => (colorError ? {
          ...base,
          background: "#2e3648",
          height:'47px',
          // match with the menu
          borderRadius: '5px',
          // Overwrittes the different states of border
          borderColor: state.isFocused ? "#323a4f" : "#A13D45",
          // Removes weird border around container
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#556ee6" : "#556ee6"
          },
        }:{
          ...base,
          background: "#2e3648",
          height:'47px',
          borderRadius: '5px',
          borderColor: state.isFocused ? "#323a4f" : "#323a4f",
          boxShadow: state.isFocused ? null : null,
          "&:hover": {

            borderColor: state.isFocused ? "#556ee6" : "#556ee6",
          },
        }),

      container: (provided, state) => ({
        ...provided,
        marginTop: 0,
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible"
      }),
      placeholder: (provided, state) => (colorError ? {
          ...provided,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
          transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
          fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
          background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
          color: state.hasValue ? "#9BA3CA" :"#A13D45",
      }:{
          ...provided,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
          transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
          fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
          background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
          color: "#9BA3CA",
      }),
      
      option: (base, state) => ({
          ...base,
          color: '#9BA3CA',
          backgroundColor: state.isSelected ? '#32394A' : '#2A3143',
          ':hover': {
            backgroundColor: state.isSelected ? '#2A3143' : '#32394A'
          }
        }),
      menuList: base => ({
          ...base,
          // kill the white space on first and last option
          padding: 0
      }),
      indicatorSeparator: state => ({
          display: 'none',
      }),
      input: (provided, state) => ({
          ...provided,
          height: 35,
          color: '#9BA3CA',
          lineHeight: state.isFocused ? 35 : 0,
          transition:  "top 0.1s, font-size 0.1s"
      }),
      singleValue: (provided) => ({
          ...provided,
          color: ' #9BA3CA',
          fontSize:"14px",
          fontWeight:'normal',
          top:'35%'
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          marginTop: '-15px',
        }),
    }

    const multiSelect = {
        control: (base, state) => ({
            ...base,
            background: "#2e3648",
            height:'47px',
            borderRadius: '5px',
            marginBottom:"20px",
            borderColor: state.isFocused ? "#323a4f" : "#323a4f",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
              borderColor: state.isFocused ? "#556ee6" : "#323A4F",
            }, 
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible",
            height: "50px"
        }),
    
        input: (styles, state) => ({
            ...styles,
            height: 45,
            margin: 0,
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            marginTop: '-15px',
          }),
        menu: (provided) => ({
            ...provided,
            height: "auto",
            maxHeight: "208px",
            position: "absolute",
            display: "block",
            background: "#2E3648",
          }),
        option: (base, state) => ({
            ...base,
            color: '#9BA3CA',
            backgroundColor: state.isSelected ? '#32394A' : '#2A3143',
            ':hover': {
              backgroundColor: state.isSelected ? '#2A3143' : '#32394A'
            }
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#3F4A64',
            width:'max-content',
            height:'34px'
        }),
        multiValueLabel: (base) => ({
            ...base,
            color:'#9BA4C7',
            width:'155px',
            padding:'0px',
            paddingLeft:'0px'
        }),
        multiValueRemove:(base, state) => ({
            ...base,
            color: '#3F4A64',
            borderRadius:'50%',
            height:'24px',
            width:'24px',
            margin:'5px',
            backgroundColor: state.isSelected ? '#556EE6' : '#556EE6',
            ':hover': {
              backgroundColor: state.isSelected ? '#556EE6' : '#556EE6'
            }
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -5 : "35%",
            transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
            background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
            color: "#9BA3CA",
        }),
    
    };    

    const { ValueContainer, Placeholder } = components;
    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused} className="menu">
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
            </ValueContainer>
        );
        };

        const RequirmentsOptions = [
            { value: "quest", label: 'Quest'},
            { value: "anniversary", label: 'Anniversary'},
            { value: "none", label: 'None'},
        ];

    return ( 
        <CustomContentWrapper>
            <ContentWrapper>
            {
            props.mode === 'edit' ? 
                <LabelCustom className="main-label">
                    Edit a Achievement
                </LabelCustom>
            : props.mode === 'view' ? 
                <LabelCustom className="main-label">
                    View a Achievement
                </LabelCustom>
            : 
                <LabelCustom className="main-label">
                    Create a Achievement
                </LabelCustom>
            }

          <div className="announcementForm">
              <div style={{marginBottom:'20px'}}>
                  <Select 
                      className="basic-single"
                      classNamePrefix="select"
                      options={RequirmentsOptions} 
                      value={RequirmentsOptions?.find(option => option.value === inputValues.requirement || '' )}
                      //defaultValue={null}
                      components={{
                              ValueContainer: CustomValueContainer
                      }}
                      placeholder="Requirement"
                      name="requirement"
                      onChange={(statusValue)=> {handleChange({target: { name:'requirement', value: statusValue === null ? '' : statusValue.value }})}}
                      maxMenuHeight={220}
                      menuPlacement="auto"
                      menuPortalTarget={document.querySelector('body')} 
                      styles={customStyles}
                      isSearchable={false}
                      isClearable
                      ref={selectInputRef}
                      isDisabled={props.mode !== 'edit' && props.mode !== 'view' ? false : true}
                  />
              </div>
              
              <div className={inputValues.requirement === 'quest' && showDiv !== false ? 'showDiv': 'hideDiv'} style={{marginBottom:'20px'}}>
                  <Select 
                      className="basic-single"
                      classNamePrefix="select"
                      options={questsList} 
                      value={questsList.filter(f => inputValues.requirementIds?.includes(f.value) || "")}
                      components={{
                              ValueContainer: CustomValueContainer
                      }}
                      placeholder="Quests"
                      name="requirementIds"
                      onChange={(statusValue)=> {handleChangeQuest({target: { name:'requirementIds', value: statusValue === null ? '' : statusValue.value }})}}
                      maxMenuHeight={220}
                      menuPlacement="auto"
                      menuPortalTarget={document.querySelector('body')} 
                      styles={customStyles}
                      isSearchable={false}
                      isClearable={true}
                      ref={selectInputRefQuest}
                      isDisabled={props.mode !== 'view' ? false : true}
                  />
              </div>   
  
              <div className={showUsers !== false ? 'showDiv': 'hideDiv'} style={{marginBottom:'20px'}}>
                  <Select 
                      className="basic-single"
                      classNamePrefix="select"
                      options={usersList} 
                      value={usersList?.find(option => option.value === inputValues.user_id || '' )}
                      components={{
                              ValueContainer: CustomValueContainer
                      }}
                      placeholder="Users"
                      name="user_id"
                      onChange={(statusValue)=> {handleChangeQuest({target: { name:'user_id', value: statusValue === null ? '' : statusValue.value }})}}
                      maxMenuHeight={220}
                      menuPlacement="auto"
                      menuPortalTarget={document.querySelector('body')} 
                      styles={customStyles}
                      isSearchable={false}
                      isClearable={true}
                      ref={selectInputRefUsers}
                      isDisabled={props.mode !== 'edit' && props.mode !== 'view' ? false : true}
                  />
              </div>
  
              <div className={showUsers !== false ? 'showDiv': 'hideDiv'} style={{marginBottom:'20px'}}>
                  <Select 
                      className="basic-single"
                      classNamePrefix="select"
                      options={badgesList} 
                      value={
                        ArrayBadge?.length !== 0 ?
                            badgesList.filter(f => ArrayBadge?.includes(f.value) || "")
                        :
                            badgesList.filter(f => inputValues.badge_id?.includes(f.value) || "")
                        }
                    //   {badgesList?.find(option => option.value === inputValues.badge_id || '' )}
                      components={{
                              ValueContainer: CustomValueContainer
                      }}
                      placeholder="Badges"
                      name="badge_id"
                      onChange={handleBadges}
                      maxMenuHeight={220}
                      menuPlacement="auto"
                      menuPortalTarget={document.querySelector('body')} 
                      styles={multiSelect}
                      isSearchable={false}
                      isClearable={true}
                      ref={selectInputRefBadge}
                      isDisabled={ArrayBadge?.length !== 0  || props.mode === 'view'? true : false}
                      isMulti
                  />
              </div>     
              <div style={{marginBottom:"20px"}}></div>
              {
                props.mode !== 'edit' && props.mode !== 'create' ?
                <SaveAndCancelBtnWrapper>
                    <div>
                        <button onClick={() => cancelAnnouncement()}>Cancel</button>
                    </div>
                </SaveAndCancelBtnWrapper>
                :
                <SaveAndCancelButton
                    cancelFn={() => cancelAnnouncement()}
                    saveFn={() => submit()}
                    submitLabel="SAVE"
                />
              }

          </div>
  
        </ContentWrapper>
  
      </CustomContentWrapper>
    )
  });


  export default CreateEditAhievement;