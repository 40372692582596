import { post, get, del } from '../helpers/api';
import { userService } from '../services/user';

export const userAttributeService = {
    getUsers,
    getUserAttributes,
    addUserAttribute,
    delete: _delete
};

function getUsers(){
    return get('/api/v1/user?page=1&items-per-page=10000000').then(handleResponse);
}
function getUserAttributes(id) {
    if(id) {
        return get(`/api/v1/user/${id}?fields=attributes`).then(handleResponse);
    } else {
        return get(`/api/v1/attribute`).then(handleResponse)
    }
}

function addUserAttribute(userAttribute) {
    let userId = userAttribute.user_id;
    delete userAttribute.user_id;
    return post(`/api/v1/user/${userId}/attribute`, JSON.stringify(userAttribute))
        .then(handleResponse)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(userAttribute) {
    let user_id = userAttribute.user_id;
    let attr_id = userAttribute.attribute_id;
    return del(`/api/v1/user/${user_id}/attribute/${attr_id}`).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}