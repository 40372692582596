import React from 'react';

import ProfileDropdown from '../ProfileDropdown/ProfileDropdown';
import UserNotif from '../Components/UserNotif';
import { UserDashboardContext } from '../UserDashboard/UserDashboard';

import './Main.scss';

import chevronLeft from '../_templates/chevron-left.png';

import { history } from '../_core/helpers/history';



const MainNavbar = ({ title }) => {
    const device = React.useContext(UserDashboardContext).device;
    const specificPaths = ['/engage/quest/challenges', '/engage/mychallenges', '/engage/acceptedchallenges', '/engage/leaderboards','/rewards/redemption', '/engage/acceptedquests'];
    const path = specificPaths.includes(history.location.pathname);

    //const path = history.location.pathname === '/engage/quest/challenges' ? true : false;

    return (
        <div className="col s6 l8">
            <div className="main-navbar">
                {path ?
                <div className="main-navbar-return" onClick={ ()=> history.goBack() }>
                    <img alt="<" src={chevronLeft}/>
                    <h6>
                    {
                        device === 'desktop' &&
                        title
                    }
                </h6>
                </div>
                :
                <h6>
                    {
                        device === 'desktop' &&
                        title
                    }
                </h6>
                }
                <UserNotif />
                <ProfileDropdown />
            </div>
        </div>
    );
};

export default MainNavbar;