import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_core/actions/user';
import { timekeepingAction } from '../../../_core/actions/timekeeping';
// import SideMenu from '../Components/SideMenu';
import ErrorPage from '../../../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../../../Components/ERRORPAGE/ButtonHome';
import BodyBackgroundColor from 'react-body-backgroundcolor';
// import error from '../../_templates/error.png';
import './ReportsDark.sass';
// import M from 'materialize-css/dist/js/materialize.min.js';
import Select from 'react-select';
import {checkSideNav} from '../../Components/checkSideMenu'; //adjust component width if admin sidenav is open

class ReportsPageDark extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: '',
            loading: false,
            error: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.searchUser = this.searchUser.bind(this);
        this.getUsers = this.getUsers.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(userActions.getAll(1, 100000));
        this.props.dispatch(timekeepingAction.getAll(1, 100000));
    }

    componentWillUnmount(){
        this.props.dispatch(userActions.getAll(1, 100000));
        this.props.dispatch(timekeepingAction.getAll(1, 100000));
        this.getUsers();
    }
    
    searchUser = async (e) => {
        e.preventDefault();
    
        let username = this.state.user;

    
        if (username === '') {
            // Set the error state if the username is empty
            this.setState({
                error: 'Please select a user',
            });
        } else {
            // Clear the error state if the username is not empty
            this.setState({
                error: null,
                loading: true,
            });
    
            try {
                // Dispatch the action and wait for it to complete
                await this.props.dispatch(timekeepingAction.getUser(username));
                // Set loading to false after dispatch completes
                this.setState({
                    loading: false,
                });
            } catch (error) {
                console.error('Error:', error);
                // Handle any errors here
                // Set loading to false if dispatch fails
                this.setState({
                    loading: false,
                });
            }
        }
    }


    getUsers(){
        const users = this.props.users;
        let usersList = [];
        
        if (users && users.users && users.users.items) {
            usersList = users.users.items.map((user, index) => {
                // Ensure user.details[0] is defined before accessing properties
                const firstName = user.details[0] ? user.details[0].firstName : '';
                const lastName = user.details[0] ? user.details[0].lastName : '';
                
                return {
                    value: user.praggerUsername,
                    label: `${firstName} ${lastName}`,
                    key: index,
                };
            });
        }
    
        return usersList;
    }

    handleChange = (event) => {
        const value = event.value;
        console.log(event)
        //localStorage.setItem('attribute', JSON.stringify(attribute.success))
        this.setState({     
            user: value
        }, () => {
            // console.log(this.state.user)
        })
    }

    render() {
        const { users } = this.props
        const loading = this.state.loading
        const error = this.state.error

        const customStyles = {
            control: (base, state) => ({
                ...base,
                fontFamily: '"Helvetica Neue", sans-serif',
                border: state.isFocused ? '1px solid #556EE6 !important' : '1px solid #2E3648',
                boxShadow: state.isFocused ? '1px solid #556EE6' : '1px solid #2E3648',
                outline: 'none',
                height: '50px',
                padding: '0px 3px',
                borderRadius: '5px',
                alignContent: "center",
                backgroundColor: '#2E3648',
                color: 'white'
              }),
              input: (state) => ({
                fontFamily: '"Helvetica Neue", sans-serif',
                '& input': {
                  font: 'inherit',
                  textDecoration: state.isFocused ? 'none !important' : 0,
                  color: 'white',
                  marginLeft: '50px',
                },
                backgroundColor: '#2E3648',
                color: 'white',
                height: '30px !important',
                marginLeft: '50px'
              }),
            menu:(provided)  => ({
                ...provided,
                height: "auto",
                maxHeight: "180px",
                zIndex: "99",
                position: "absolute",
                display: "block",
                backgroundColor: '#2E3648',
                color: '#9BA2CE'
            }),
            menuList:(provided)  => ({
                ...provided,
                height: "auto",
                maxHeight: "180px",
                padding: "2px 2px",
                backgroundColor: '#2E3648',
                color: '#9BA2CE'
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#323A4F' : state.isFocused ? '#323A4F' : '',
                color: state.isSelected ? 'white' : state.isFocused ? 'white' : '',
                "&:hover": {
                    // Overwrittes the different states of border
                    backgroundColor: state.isFocused ? "#323A4F" : "",
                    color: state.isSelected ? 'white' : '',
                }
            }),
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = 'opacity 300ms';
              const color = '#9BA2CE';
              const marginLeft = '50px';
              return { ...provided, opacity, transition, color, marginLeft };
            }
          }

        return (
            <BodyBackgroundColor backgroundColor='#222736'>
                

                <div className={"tkBodyContainer" + checkSideNav()}>
                    {/* <SideMenu /> */}
                    {users.error ?
                    <div className="container error-div">
                        <ErrorPage />
                        <div className="text-danger">
                            <img className="error" src={error} alt="error" />
                            {users.error}
                        </div>
                        <ButtonHome />
                    </div> :
                    <div className="timekeep-page-container">
                        <div className="row valign-wrapper">
                            <div className="col s12 m12 l12" id="zero">
                                <h5 className="text-left" id="header">Timekeeping</h5>
                                <p className="tkTitle">
                                    <span className="tkTxt1"><b>Reports</b></span>
                                                 
                                </p>
                            </div>
                        </div>
                        {loading && 
                            <div>
                                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </div>
                        }
                        {users.loading ?
                        <div className="center">
                            <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </div>
                        : 
                        <div>
                        {users.users &&
                            <div className="card-panel clearfix">
                                <b>Generate Reports</b>
                                <div className="row" id="zero">
                                    <div className="col s12 m12 l12" id="zero">
                                        <div className="search-border">
                                            <Select 
                                                options={this.getUsers()}
                                                placeholder={<div className="placeholder">Select user</div>}
                                                className="basic-single"
                                                onChange={this.handleChange}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                    {error &&
                                        <div className="col s12 m12 l12" id="zero">
                                            <em className="error">{error}</em>
                                        </div>
                                    }
                                    <div className="col s12 m12 l12 right" id="zero">
                                        <button className="btn btn-large right generate-btn" onClick={this.searchUser}>Generate Reports</button>
                                    </div>
                                </div>
                        </div>
                        }
                        </div>
                        }
                    </div>
                    }
                </div>
            </BodyBackgroundColor>
        );

    }
}

function mapStateToProps(state) {
    const { timekeeping, users } = state;
    return {
        timekeeping,
        users
    };
}


const connectedReportsPage = connect(mapStateToProps)(ReportsPageDark);
export { connectedReportsPage as ReportsPageDark };