import { timekeepingConstants } from '../constants/timekeeping';
import { timekeepingServices } from '../services/timekeeping.js';
// import { alertActions } from './alert';
import { history } from '../helpers/history';

export const timekeepingAction = {
    getAll,
    getAllPrags,
    getUser,
    get,
    savePrag
};


function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());

        timekeepingServices.getAll(page, itemsPerPage)
            .then(
                timekeepings => {
                    dispatch(success(timekeepings))
                    if(window.location.pathname === '/admin/today'){
                        history.push(`/admin/today?page=${Number(page) || 1}`)
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: timekeepingConstants.GET_ALL_REQUEST } }
    function success(timekeepings) { return { type: timekeepingConstants.GET_ALL_SUCCESS, timekeepings } }
    function failure(error) { return { type: timekeepingConstants.GET_ALL_FAILURE, error } }
}

function getAllPrags(selectPage, itemsPerPage, start, end) {
    return dispatch => {
        dispatch(request());

        timekeepingServices.getAllPrags(selectPage, itemsPerPage, start, end)
            .then(
                timekeepings => {
                    dispatch(success(timekeepings));
                    history.push('/admin/today?page='+ selectPage);
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: timekeepingConstants.GET_PRAGGER_REQUEST } }
    function success(timekeepings) { return { type: timekeepingConstants.GET_PRAGGER_SUCCESS, timekeepings } }
    function failure(error) { return { type: timekeepingConstants.GET_PRAGGER_FAILURE, error } }
}

function getUser(user,start,end) {
    return dispatch => {
        dispatch(request(user));

        timekeepingServices.getUser(user,start,end)
            .then(
                timekeeping => {
                    dispatch(success(timekeeping));
                    history.push(`/admin/timekeeping/timesheet/${user}`);
                },
                error => {
                    dispatch(failure(error.toString()));
                    alert('user not found');
                    history.push('/admin/timekeeping/reports');
                }
            );
    };

    function request() { return { type: timekeepingConstants.GET_REQUEST } }
    function success(timekeeping) { return { type: timekeepingConstants.GET_SUCCESS, timekeeping } }
    function failure(error) { return { type: timekeepingConstants.GET_FAILURE, error } }
}

function get(username) {
    return dispatch => {
        dispatch(request(username));

        timekeepingServices.getByUser(username)
          .then(
            timekeeping => dispatch(success(timekeeping)),
            error => dispatch(failure(error.toString()))
          )
        // leaveTypeService.getAll()
        //     .then(
        //         // leaveTypes => dispatch(success(leaveTypes.items)),
        //         leaveTypes => dispatch(success(leaveTypes)),
        //         error => dispatch(failure(error.toString()))
        //     );
    };

    function request(username) { return { type: timekeepingConstants.GET_REQUEST, username } }
    function success(timekeeping) { return { type: timekeepingConstants.GET_SUCCESS, timekeeping } }
    function failure(error) { return { type: timekeepingConstants.GET_FAILURE, error } }
}

function savePrag(prag) {
    return {
        type: timekeepingConstants.SAVE_PRAG,
        prag
    };
}