import { get } from '../helpers/api';
import { userService } from '../services/user';

export const expService = {
    getExp   
};


function getExp(id){
    return get(`/api/v1/user/${id}/exp`).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);

        }
        return data;
    });
}