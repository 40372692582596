import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import moment from "moment";

import { useParams } from "react-router-dom";
import {useDropzone} from 'react-dropzone';
import Select, { components } from "react-select";
import Switch from "react-switch";

import { DragDropImage,} from "../../Components/ImageUploader/updatedUpload";

import calendar from '../../../_templates/dropdown_chevrondown.png'

import { history } from "../../../_core/helpers/history";
import { challengeActions } from "../../../_core/actions/challenge";

import {
    CustomDatePicker,
    CustomInputText,
    CustomTextAreaEngage
  } from "../../Components/FloatingTextBox/TextArea";

import {
    ContentWrapper,
    LabelCustom,
    SaveAndCancelButton,
  } from "../../Components/PageWrapper/ContentWrapper";

import "react-datepicker/dist/react-datepicker.css";
import "./Challenge.scss";


export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;
export const SearchIconFindUser = styled.img`
  margin-bottom: -30px;
  margin-left: 15px;
  display: flex;
  position: relative;
  z-index: 1;
  height: 15px;
  width: 15px;
  object-fit: contain;
`;

export const FindUserWrapper = styled.div`
  > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
    margin-left: 15px;
  }
`;

export const GridContainer = styled.div`
  ${(props) =>
    props.isShowUserDetails === false
      ? css`
          margin-top: 20px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;

          // > div > label {
          //   margin-top: 10px;
          // }

        
        `
      : css`
          display: block;
        `}

  > div > label.label__questionnaires, > div > label.label__tenures {
    display: flex;
    float: left;
    top: 10px;
    position: relative;
    z-index: 1;
    margin-left: 25px;
    color: #9ba2ce;
  }

  > div > label.Error__message {
    margin-left: 10px;
  }

  > div > label.label__questionnaires {
    background: #2a3143;
    width: 72px;
    margin-top: 5px;
   
  }
  > div > label.label__tenures {
    background: #2a3143;
    width: 40px;
    margin-top: 5px;
  }

  > div > label.Error__message {
    font-size: 10px;
    font-weight: 400;
    color: #f14646;
  }

  > div > div.error_date {
    border: 1px solid #f14646;
    border-radius: 3px;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
  margin-top: 20px;

    > img.admin_calendar_icon1 {
        display: flex;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
    }
    > img.admin_calendar_icon2 {
        display: flex;
        float: right;
        margin-top: 10px;
        margin-right: 10px;
    }
  
    .floating-label {
        display: inline-block;
    }

    .floating-label:nth-child(2) {
        float: right; 
    }

   .calendar-dark {
        background-image:url(${calendar}); !important;
    }

`;


const mapStateToProps = ({ users, badges, reward, challenges}) => ({
  users,
  badges,
  reward,
  challenges
});

const CreateEditChallenge = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    const [InputError, setInputError] = useState(false);
    const [files, setFiles] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
    //const [TimeLimitError, setTimeError] = useState(false); 
    const [startDate, setStartDate] = useState(new Date());// eslint-disable-line
    const [ExpirationDate, setExpirationDate] = useState(new Date());
    const [IsEditedExpire, setIsEditedExpire] = useState(false)
    const [remove, setRemove] = useState(false)
    const [checked, setchecked] = useState(false);
    const [checkeds, setChecked] = React.useState(false);

    //let ActivePage = props.match.params.page;


    const { pageId } = useParams();

    const [challenge, setChallenge] = useState({
        questId:"",
        recurring: false,
        timeLimit: "",
        active:1,
        requiredBadges:"",
        title: "",
        description: "",
        bestTime: "00:00:00",
        location: "",
        lat:"",
        lon:"",
        eneryValue:"",
        credits:"",
        exp:"",
        expiresAt: moment(ExpirationDate).utc().format(),
        startsAt: moment(startDate).utc().format(),
        images:"",
        status:""
    });    
    
    React.useEffect(() => {
      if (pageId) {
          setIsAdd(false); // Set update
          dispatch(challengeActions.getOne(pageId)); // call selected challege id
      }
      //eslint-disable-next-line
    }, [dispatch, pageId]);

    

    useEffect(() => {

        // prepopulate selected challenge to edit
        if (pageId) {
          
          let selectedData =  props.challenges.challenge ?? [];
            if(selectedData) {
                selectedData.forEach((item) => {

                    setChallenge({
                        questId: item.questId,
                        recurring: item.recurring,
                        timeLimit: item.timeLimit.split(':')[0],
                        active: item.active,
                        requiredBadges: item.requiredBadges,
                        title: item.title,
                        description: item.description,
                        bestTime: item.bestTime,
                        location: item.location,
                        lat: item.lat,
                        lon: item.lon,
                        eneryValue:item.eneryValue,
                        credits:item.credits !== null ? item.credits: 0,
                        exp:item.exp !== null ? item.exp : 0,
                        expiresAt: item.expiresAt,
                        startsAt: item.startsAt ? item.startsAt : moment(startDate).utc().format(),
                        images:item.images,
                        status:item.status
                    });

                    setCount(item.description.length)
                    setchecked(item.active !== 1 ? false : true)
                    setChecked(item.status === 'completed' ? true : false)
              })
            }
        }
        //eslint-disable-next-line
      }, [props.challenges.challenge, pageId, dispatch]);
    

    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        if(name === "description"){
            setCount(value.length)
        }
        setChallenge({
            ...challenge,
            [name]: value,
        });
    }


    const {getRootProps, getInputProps, open} = useDropzone({
        accept: 'image/x-png, image/png, image/jpg, image/jpeg, image/gif',
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
            })));
            setChallenge({
                ...challenge,
                images:acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                    }))[0],
              });
    
        }
        });


    const removeSelectedImage = () => {
        setFiles([]);
        setChallenge({ ...challenge, images: null});
        setRemove(true)
    } 

    const handleToDate = (date) => {
        setExpirationDate(date);
        setIsEditedExpire(true);
        setChallenge({ ...challenge, expiresAt: moment(date).utc().format() });
    };

    // const handleTime = (e) => {
    //     e.preventDefault(); // crashes
    //     const value = e.target.value;
    //     setChallenge({
    //         ...challenge,
    //         timeLimit: value,
    //     })
    // };


    const handleNumber = (e) => {
        e.preventDefault(); // crashes
        const value = e.target.value.replace(/[^\d.]/ig, "");
        const total = parseInt(value);
        if(value.length === 0) {
            setChallenge({ ...challenge, [e.target.name]: '', });
        } else {
            setChallenge({ ...challenge, [e.target.name]: total, });
        }
    };

    const cancelQuest = () => {
        // props.handleCloseModal(false)
        history.goBack()
        setInputError(false);
    }
    
    const handleSelect = (data) => {
        const names = data.target.name
        const value = data.target.value
        setChallenge({ ...challenge, [names]: value });
    };

    const submitQuestChallenge = () => {
        if(
            !challenge.title ||
            !challenge.description ||
            !challenge.expiresAt ||
            !challenge.eneryValue ||
            !challenge.credits ||
            !challenge.exp ||
            !challenge.timeLimit
        ) {
            setInputError(true);
            //setTimeError(true)
        }else{
            if(isAdd){
                //const limitConvert= challenge.timeLimit.split(" ")[0]
                let challengeItems ={
                    questId: challenge.questId,
                    recurring: challenge.recurring,
                    timeLimit: challenge.timeLimit+":00:00",
                    active: challenge.active,
                    requiredBadges: challenge.requiredBadges,
                    title: challenge.title,
                    description: challenge.description,
                    bestTime: challenge.bestTime,
                    location: challenge.location,
                    lat: challenge.lat,
                    lon: challenge.lon,
                    eneryValue:challenge.eneryValue,
                    credits:challenge.credits,
                    exp:challenge.exp,
                    expiresAt: challenge.expiresAt,
                    startsAt: challenge.startsAt,
                    images:challenge.images,
                    status: challenge.status
                }
                dispatch(challengeActions.createChallenge(challengeItems))
                setInputError(false);
                //setTimeError(false)
                setTimeout(() => {
                    history.goBack()    
                }, 1500)

            }else {
                let challengeId = pageId;
                //const limitConvert= challenge.timeLimit.split(" ")[0]
                let challengeItems ={
                    questId: challenge.questId,
                    recurring: challenge.recurring,
                    timeLimit: challenge.timeLimit+":00:00",
                    active: challenge.active,
                    requiredBadges: challenge.requiredBadges,
                    title: challenge.title,
                    description: challenge.description,
                    bestTime: challenge.bestTime,
                    location: challenge.location,
                    lat: challenge.lat,
                    lon: challenge.lon,
                    eneryValue:challenge.eneryValue,
                    credits:challenge.credits,
                    exp:challenge.exp,
                    expiresAt: challenge.expiresAt,
                    startsAt: challenge.startsAt,
                    images:challenge.images,
                    status: challenge.status
                }

                dispatch(challengeActions.update(challengeItems, challengeId))
                setInputError(false);
                //setTimeError(false)
                setTimeout(() => {
                    history.goBack()    
                }, 1500)
                if(checkeds === true) {
                    dispatch(challengeActions.completeOneChallenge(challengeId));
                }
            
            }
        }
    }

    const { ValueContainer, Placeholder } = components;

    const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused} className="menu">
            {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
            child && child.type !== Placeholder ? child : null
        )}
        </ValueContainer>
    );
    };

    const customStyles2 = {
        control: (base, state) => (InputError ? {
            ...base,
            background: "#2e3648",
            height:"40px",
            // match with the menu
            borderRadius: '5px',
            marginBottom:"15px",
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#323a4f" : "#f14646",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
              // Overwrittes the different states of border
              borderColor: state.isFocused ? "#556ee6" : "#323A4F",
            },
          }:{
            ...base,
            background: "#2e3648",
            height:"40px",
            borderRadius: '5px',
            marginBottom:"20px",
            borderColor: state.isFocused ? "#323a4f" : "#323a4f",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
              borderColor: state.isFocused ? "#556ee6" : "#323A4F",
            },
          }),
        container: (provided, state) => ({
            ...provided,
            marginTop: 0,
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            marginTop: '-15px',
          }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: "#9BA3CA",
            "&:hover": {
                color: state.isFocused ? "#556ee6" : "#556ee6",
              }, 
        }),
        option: (base, state) => ({
            ...base,
            color: "#9ba2ce",
            backgroundColor: state.isSelected ? "#32394A" : "#2e3648",
            ":hover": {
              backgroundColor: state.isSelected ? "#2e3648" : "#32394A",
            },
            zIndex: "999",
          }),
    
        menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        }),
        menu: (provided) => ({
          ...provided,
          height: "auto",
          maxHeight: "208px",
          position: "absolute",
          display: "block",
          background: "#2E3648",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible"
        }),
        input: (provided, state) => ({
            ...provided,
            height: 35,
            color: '#9BA3CA',
            lineHeight: state.isFocused ? 35 : 0,
            transition:  "top 0.1s, font-size 0.1s"
        }),
        placeholder: (provided, state) => (InputError ? {
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
            transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
            background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
            color: state.hasValue ? "#9BA3CA" :"#f14646",
        }:{
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -4 : "35%",
            transition: state.hasValue || state.selectProps.inputValue ? "top 0.1s, font-size 0.1s":"top 0.2s, font-size 0.2s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
            background: state.hasValue || state.selectProps.inputValue ? "#2A3143" : "transparent" ,
            color: "#9BA3CA",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: ' #9BA3CA',
            fontSize:"14px",
            fontWeight:'normal',
            top:'35%'
          }),
      };

    const expOptions = [
        { value: "10", label: '10'},
        { value: "25", label: '25'},
        { value: "50", label: '50'},
        { value: "100", label: '100'},
        { value: "250", label: '250'},
        { value: "500", label: '500'},
        { value: "750", label: '750'},
        { value: "1000", label: '1000'},
    ];

    const creditsOptions = [
        { value: "10", label: '10'},
        { value: "20", label: '20'},
        { value: "50", label: '50'},
        { value: "100", label: '100'},
        { value: "250", label: '250'},
        { value: "300", label: '300'},
        { value: "500", label: '500'},
    ];

    const energyOptions = [
        { value: 1, label: '1'},
        { value: 2, label: '2'},
        { value: 3, label: '3'},
        { value: 4, label: '4'},
        { value: 5, label: '5'},
        { value: 6, label: '6'},
        { value: 7, label: '7'},
        { value: 8, label: '8'},
        { value: 9, label: '9'},
        { value: 10, label: '10'}
    ];

   
    const handleChecked=(checked)=> {
        setchecked(checked);
        setChallenge({ ...challenge, active: checked !== false ? 1: 0 });
    }

    

    const handleChangeCheckBox = (event) => { 
        setChecked(event.target.checked)
    }

    return (
        <CustomContentWrapper>
            <ContentWrapper>
                <LabelCustom className="main-label">
                    {isAdd ? "Create" : "Edit"} a Challenge
                </LabelCustom>
                {
                    isAdd ? <></> : 
                    
                    <>
                        <div className="toggleSwitch">
                            <div className="titleSwitch-edit">
                                <p className="switch-one">Publish / Unpublish</p>
                                <p className="switch-two">Challenge content will be displayed on Engage page</p>
                            </div>
                                <Switch 
                                    onColor="#556EE6"
                                    height={16}
                                    width={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={handleChecked} 
                                    checked={checked} 
                                    className="toggleBtn"
                                />
                        </div> 
                        <div className={challenge.status !=="finished" ? "toggleSwitch disabled" : "toggleSwitch"}>
                            <div className="titleSwitch-edit">
                                <p className="switch-one">Complete</p>
                                <p className="switch-two">Mark challenge as complete</p>
                            </div>
                            <input type="checkbox" 
                                name="rememberCheckbox"
                                checked={checkeds}
                                onChange={handleChangeCheckBox}
                                disabled={challenge.status !=="finished" ? true : false}
                            />
                        </div>
                    </>
                }

                <CustomInputText
                    label={"Challenge name"}
                    name={"title"}
                    value={challenge.title}
                    inputOnChange={handleChangeInput}
                    isError={!challenge.title && InputError}
                />
                <CustomTextAreaEngage
                    label={"Description"}
                    name={"description"}
                    value={challenge.description}
                    inputOnChange={handleChangeInput}
                    maxlength="250"
                    isError={!challenge.description && InputError}
                />
                <p className={isAdd ? "countChallenge countAdd" : "countChallenge countEdit"}>{count}</p>

                <label className="questCover">Challenge Cover Photo</label>
                <div {...getRootProps({className: 'dropzone'})}>
                    <div className={files[0] || challenge.images ? 'active-dz': 'unactive-dz'} >
                        <input {...getInputProps()} />
                        <div>
                            <p className="dropTitle">Drag and Drop</p>
                            <p className="dropTitle">Cover Photo here</p>
                            <p className="dropTitle">or</p>
                        </div>

                        <button onClick={open} className="custom-file-upload-engage">
                            Browse
                        </button>
                    </div>
                    <DragDropImage 
                        removeImage={removeSelectedImage} 
                        selectedImage={files} 
                        existingImage={challenge.images} 
                        remove={remove}
                        isAdd={isAdd}
                        
                    />
                </div>

                <div className="valid-main">
                    <label className="challengeValid">Valid Till</label>
                        <div className="datepickers">
                            <CustomDatePicker
                                type="text"
                                label="From"
                                selected={startDate ? startDate : new Date(challenge.startsAt)}
                                startDate={startDate}
                                endDate={ExpirationDate}
                                value={startDate}
                                dateFormat="dd/MM/yyyy"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                selectsStart
                                maxDate={ExpirationDate}
                                readOnly
                            />    
                            <CustomDatePicker
                                type="text"
                                label="To"
                                selected={ExpirationDate && IsEditedExpire === false ?  new Date(challenge.expiresAt)  :  ExpirationDate}
                                startDate={startDate}
                                minDate={startDate}
                                endDate={ExpirationDate}
                                selectsEnd
                                onChange={(date) => handleToDate(date)}
                                dateFormat="dd/MM/yyyy"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                isError={!challenge.expiresAt && InputError}
                            />                    
                        </div>
                </div>
                <label data-domain="hrs" className="text">
                    <CustomInputText
                        label={"Time limit"}
                        name={"timeLimit"}
                        value={String(challenge.timeLimit)}
                        inputOnChange={(e) => handleNumber(e)}
                        isError={!challenge.timeLimit && InputError}
                    />
                </label>

                <Select
                    value={energyOptions.find(obj => obj.value === challenge.eneryValue || '')}
                    onChange={(statusValue)=> {handleSelect({target: { name:'eneryValue', value: statusValue === null ? '' : statusValue.value }})}}
                    components={{
                        ValueContainer: CustomValueContainer
                    }}
                    captureMenuScroll={false}
                    placeholder="Energy"
                    styles={customStyles2}
                    options={energyOptions}
                    openMenuOnClick={false}
                    isSearchable={false}
                    menuPlacement="bottom"
                    hideSelectedOptions={false}
                    isClearable={true}
                    maxMenuHeight={200}
                    className="basic-single"
                    classNamePrefix="select"
                    name="eneryValue"
                    menuPortalTarget={document.querySelector('body')} 
                />
                {InputError && !challenge.eneryValue && (
                    <div className="Error_div"><label className="Error__message">Please fill-up this field</label></div>
                )}
                <Select
                    value={creditsOptions.find(obj => obj.value === challenge.credits || '')}
                    onChange={(statusValue)=> {handleSelect({target: { name:'credits', value: statusValue === null ? '' : statusValue.value }})}}
                    components={{
                        ValueContainer: CustomValueContainer
                    }}
                    captureMenuScroll={false}
                    placeholder="Credits"
                    styles={customStyles2}
                    options={creditsOptions}
                    openMenuOnClick={false}
                    isSearchable={false}
                    menuPlacement="bottom"
                    hideSelectedOptions={false}
                    isClearable={true}
                    maxMenuHeight={200}
                    className="basic-single"
                    classNamePrefix="select"
                    name="credits"
                    menuPortalTarget={document.querySelector('body')} 
                />
                {InputError && !challenge.credits && (
                    <div className="Error_div"><label className="Error__message">Please fill-up this field</label></div>
                )}
                <Select
                    value={expOptions.find(obj => obj.value === challenge.exp || '')}
                    onChange={(statusValue)=> {handleSelect({target: { name:'exp', value: statusValue === null ? '' : statusValue.value }})}}
                    components={{
                        ValueContainer: CustomValueContainer
                    }}
                    captureMenuScroll={false}
                    placeholder="Experience"
                    styles={customStyles2}
                    options={expOptions}
                    openMenuOnClick={false}
                    isSearchable={false}
                    menuPlacement="bottom"
                    hideSelectedOptions={false}
                    isClearable={true}
                    maxMenuHeight={200}
                    className="basic-single"
                    classNamePrefix="select"
                    name="exp"
                />
                {InputError && !challenge.exp && (
                    <div className="Error_div"><label className="Error__message">Please fill-up this field</label></div>
                )}
                <SaveAndCancelButton
                    cancelFn={() => cancelQuest()}
                    saveFn={() => submitQuestChallenge()}
                />

            </ContentWrapper>
        </CustomContentWrapper>
    );
});

export default CreateEditChallenge;
