import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { useParams } from "react-router-dom";
import DocumentTitle from "react-document-title";
import cc from "classcat";
import BodyBackgroundColor from "react-body-backgroundcolor";
import {
  PageWrapper,
  ContentWrapper,
  LabelCustom,
  SaveAndCancelButton,
} from "../../Components/PageWrapper/ContentWrapper";
import { checkSideNav } from "../../Components/checkSideMenu";
import { DynamicHeaderContent} from "../../Components/PageWrapper/DynamicWrapper";
import './settingsDark.scss';
import ImageUploadModal from './ImageUploadModal';
import { CustomInputText, CustomTextAreaEngage,} from "../../Components/FloatingTextBox/TextArea";
import avatarIcon from '../../../_templates/settings-profile.png';
import coverIcon from '../../../_templates/settings-cover.png';
import { history } from "../../../_core/helpers/history";
import { settingsActions } from '../../../_core/actions/settings';
import { settingsService } from "../../../_core/services/settings";

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;

const PhotoBorder = (props) => {
  return (
      <div className="dashed-border">
          <div><div><div><div>{props.children}</div></div></div></div>
      </div>
  )
}

const mapStateToProps = ({ users, badges, reward, settings}) => ({
    users,
    badges,
    reward,
    settings
});


function saveFileMetadataToLocal(file, key) {
    if (file) {
        const fileMetadata = {
            name: file.name,
            type: file.type,
            size: file.size // You can add other metadata as needed
        };
        localStorage.setItem(key, JSON.stringify(fileMetadata));
    } else {
        localStorage.removeItem(key); // Remove the key if the file is null
    }
}


const SettingsDark = connect(mapStateToProps)((props) => {        

  const [isProfileImage, setIsProfileImage] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [avatarSrc, setAvatarSrc] = React.useState(null);
  const [coverSrc, setCoverSrc] = React.useState(null);
  const [avatarUpload, setAvatarUpload] = React.useState(null);
  const [coverUpload, setCoverUpload] = React.useState(null);
  const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
  // const [count, setCount] = React.useState(0);
  const [InputError, setInputError] = React.useState(false);// eslint-disable-line
  const dispatch = useDispatch();

  const { pageId } = useParams();

  const [settingsData, setSettingData] = React.useState({
    org_name: '',
    org_slug: '',
    org_avatar: '',
    org_cover: '',
    org_description: '',
    org_address: '',
    default: 0
  })
 


React.useEffect(() => {
    if (pageId) {
      setIsAdd(false);
      settingsService.getById(pageId).then((res) => {
        if(res.success !== undefined) {
          let data = res.success[0];
      
          setSettingData({
            ...settingsData,
            org_name: data.org_name,
            org_slug: data.org_slug,
            org_description: data.org_description,
            org_address: data.org_address,
            default: data.default
          })

          setAvatarSrc(data.org_avatar)
          setCoverSrc(data.org_cover)
        }
      });
    }
    //eslint-disable-next-line
  }, [props, pageId]);



  const handleChangeInputName = (e) => {
    const { name, value } = e.target;

    setSettingData({
      ...settingsData,
      [name]: value,
      // org_slug: value.replace(/\s+/g,'-').toLowerCase()
      org_slug: value.replace(/[&\/\\#,+()$~%.'":*?<>{}^!@_`=|;\[\]]/g,'').toLowerCase().replace(/\s/g,'-')//eslint-disable-line
      
    });

  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    // if(name === "org_description"){
    //     setCount(value.length)
    // }

    setSettingData({
        ...settingsData,
        [name]: value,
      });

  }

  const toggleModal = (img) => {
    setIsProfileImage(img === 'profile' ? true : false);
    setOpenModal(!openModal);
  }

  const draftImageUpload = (src, file) => {

    console.log(file)
    if (isProfileImage) {
        setAvatarSrc(src);
        setAvatarUpload(file);

    } else {
        setCoverSrc(src);
        setCoverUpload(file);
    }
  }

  console.log(avatarUpload)

  const cancelAnnouncement = () => {
    history.goBack()
    setInputError(false);
  }

  const submit = () => {
    if( !settingsData.org_name ||
        !settingsData.org_description ||
        !settingsData.org_address ){
      setInputError(true);
    }else {
        let newsettingsData = {
            org_name: settingsData.org_name,
            org_slug: settingsData.org_slug,
            org_description: settingsData.org_description,
            org_address: settingsData.org_address,
            default: 0
          }

          

        if(isAdd !== true){
            console.log(avatarUpload)
            dispatch(settingsActions.update(pageId,newsettingsData,avatarUpload,coverUpload));
            setTimeout(() => {
              history.goBack()    
          }, 1500)
        }else{

            let newSettingsDataLocal = {
                org_name: settingsData.org_name,
                org_slug: settingsData.org_slug,
                org_description: settingsData.org_description,
                org_address: settingsData.org_address,
                // org_avatar: avatarUpload, // Placeholder for avatar data
                // org_cover: coverUpload,  // Placeholder for cover data
                default: 0
            };
            
            // Extract avatar file data if it exists
            // const avatarFile = avatarUpload ? avatarUpload.get('file') : null;
            // if (avatarFile ) {
            //     newSettingsDataLocal.org_avatar = {
            //         name: avatarFile.name,
            //         type: avatarFile.type
            //         // Add any other relevant properties you need
            //     };
            // }

            // // Extract cover file data if it exists
            // const coverFile = coverUpload ? coverUpload.get('file') : null;
            // if (coverFile) {
            //     newSettingsDataLocal.org_cover = {
            //         name: coverFile.name,
            //         type: coverFile.type
            //         // Add any other relevant properties you need
            //     };
            // }

            //dispatch(settingsActions.create(newsettingsData,avatarUpload,coverUpload));
            //   setTimeout(() => {
            //     history.push(`/admin/settings/user/create`)    
            // }, 1500)
            localStorage.setItem('newSettingsData', JSON.stringify(newSettingsDataLocal));
            // const avatarFile = avatarUpload ? avatarUpload.get('file') : null;
            // const coverFile = coverUpload ? coverUpload.get('file') : null;
            
            // saveFileMetadataToLocal(avatarFile, 'orgAvatar');
            // saveFileMetadataToLocal(coverFile, 'orgCover');
            // Check if the avatarUpload FormData object contains any data
            // const file = avatarUpload;
            // const avatarUploads = new FormData();
            // avatarUploads.append('file', file); // Append the file data to the FormData object
            
            // Convert FormData object to an array of key-value pairs
            // const avatarUploadArray = [...avatarUploads.entries()];
            
            // // Store the array in local storage
            // localStorage.setItem('avatarUpload', JSON.stringify(avatarUploadArray));

            function formDataToObject(formData) {
                const object = {};
                if (formData) {
                    formData.forEach((value, key) => {
                        object[key] = value;
                    });
                } else {
                    return object;
                }
                return object;
            }
            
            // Convert FormData object to a plain object
            const avatarObject = formDataToObject(avatarUpload);
            history.push({
                pathname: `/admin/settings/user/create`,
                state: { avatar: avatarObject }
            });
        }


    }
  }

  return (
    
          <ContentWrapper>
            <LabelCustom className="main-label">
              {isAdd !== true ? 'Edit ' : 'Create '}a Organization
            </LabelCustom>

            <CustomInputText
              label={"Organization Name"}
              name="org_name"
              value={settingsData.org_name}
              inputOnChange={handleChangeInputName}
              isError={!settingsData.org_name && InputError}
            />
            {/* <div className="test">
              <div className="titleSwitch-edit">
                <p className="switch-one">{settingsData.org_slug ? settingsData.org_slug : "Organization Slug"} </p>
              </div>
            </div> */}
            <CustomInputText
              label={"Organization Slug"}
              name="org_slug"
              value={settingsData.org_slug}
              readOnly={true}
              className={"test"}
              isError={!settingsData.org_description && InputError}
            />
            <div className="settings-images-wrapper">
              <div>
                <p>Organization logo</p>
                <div className="settings-logo-wrapper">
                  <PhotoBorder>
                    <div className="image-wrapper">
                      {/* <img src={avatarSrc} alt="avatar" /> */}
                      <img src={avatarSrc || avatarIcon } alt="avatar" onError={(e) => e.target.src = avatarIcon}/>
                      
                    </div>
                  </PhotoBorder>
                </div>
                <button className="settings-add-profile-btn" onClick={() => toggleModal('profile')} >UPLOAD LOGO</button>
              </div>
              <div>
                <p>Organization cover</p>
                <div className="settings-cover-wrapper">
                  <PhotoBorder>
                    <div className="image-wrapper">
                      {/* <img src={avatarSrc} alt="avatar" /> */}
                      <img src={coverSrc || coverIcon} alt="avatar" onError={(e) => e.target.src = coverIcon} />
                    </div>
                  </PhotoBorder>
                </div>
                <button className="settings-add-cover-btn" onClick={() => toggleModal('cover')}>UPLOAD ORGANIZATION COVER</button>
              </div>
            </div>
            <ImageUploadModal
                isOpen={openModal}
                closeModal={() => setOpenModal(false)}
                modalTitle={isProfileImage ? "Change profile photo" : "Change Cover Photo"}
                savedImage={(imgSrc, imgFile) => draftImageUpload(imgSrc, imgFile)}
                isProfilePhoto={isProfileImage}
            />
            <CustomTextAreaEngage
              label={"Organization Description"}
              name={"org_description"}
              value={settingsData.org_description}
              inputOnChange={handleChangeInput}
              maxlength="250"
              // isError={!reward.content && InputError}
              isError={!settingsData.org_description && InputError}
            />
            <CustomInputText
              label={"Organization Address"}
              name="org_address"
              value={settingsData.org_address}
              inputOnChange={handleChangeInput}
              isError={!settingsData.org_address && InputError}
            />
            <SaveAndCancelButton
                cancelFn={() => cancelAnnouncement()}
                saveFn={() => submit()}
                submitLabel="SAVE"
            />
          </ContentWrapper>
    
  )
})

export default SettingsDark;