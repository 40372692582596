import { officeConstants } from '../constants/office';
import { officeService } from '../services/office';
import { history } from '../helpers/history';

export const officeActions = {
    getAll,
    getOne
};


function getAll() {
    return dispatch => {
        dispatch(request());
        officeService.getAll()
            .then(
                offices => dispatch(success(offices.success)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: officeConstants.GETALL_REQUEST } }
    function success(offices) { return { type: officeConstants.GETALL_SUCCESS, offices } }
    function failure(error) { return { type: officeConstants.GETALL_FAILURE, error } }
}

function getOne(id) {
    return dispatch => {
        dispatch(request(id));
        officeService.getOne(id)
            .then(
                office => {
                    dispatch(success(office.success))
                    history.push('/employees-per-office')
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: officeConstants.GET_REQUEST } }
    function success(office) { return { type: officeConstants.GET_SUCCESS, office } }
    function failure(error) { return { type: officeConstants.GET_FAILURE, error } }
}