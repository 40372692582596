import React, { useEffect }from 'react'
import cc from 'classcat';
import { connect, useDispatch } from 'react-redux';
import { checkSideNav } from '../../Components/checkSideMenu';
import { Route, Switch } from 'react-router-dom';
import { If, Else, Then,  } from 'react-if';
import { Link } from 'react-router-dom';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import Pagination from 'react-js-pagination';
import "./RolesPageDark.scss";
import { roleActions } from '../../../_core/actions/role'
import { history } from '../../../_core/helpers/history';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import RolesAction from './RolesActionDark';
import DocumentTitle from "react-document-title";
import { PageWrapper } from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent } from "../../Components/PageWrapper/DynamicWrapper";


const mapStateToProps = ({ roles }) => ({
    roles,
});

const ListRoles = (props) => {
    const dispatch = useDispatch();
    let buttonHeaderContent = "ADD NEW ROLE";
    

    const [totalData, setTotalData] = React.useState(0);
    const [ActivePage, setActivePage] = React.useState(0);
    const [GetTotalPerpage, setGetTotalPerpage] = React.useState(10);
    const [currentValue, setCurrentValue] = React.useState(10);

    const [update, setUpdate] =  React.useState(false);
    const cookies = new Cookies();
    const userCred = JSON.parse(localStorage.getItem('userCredentials'))
 
    let showEntries = "Show Entries ";

    let fromPage = ((ActivePage -1) * GetTotalPerpage) + 1
    let toPage =  Math.min((ActivePage - 1)* GetTotalPerpage + GetTotalPerpage, totalData)

    const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

    useEffect(() => {
        let defaultPage = 1;
        let itemPerPage = cookies.get("Roles:"+userCred.user._id) ? cookies.get("Roles:"+userCred.user._id) : currentValue;
        dispatch(roleActions.getAll(defaultPage,itemPerPage));
    }, [update,dispatch])//eslint-disable-line

    useEffect(() => {
        let newItems = cookies.get("Roles:"+userCred.user._id)
       
        if(update !== false){
           cookies.set("Roles:"+userCred.user._id, currentValue, { path: "/", sameSite:'lax' })
            setUpdate(false)
        }else{
            setGetTotalPerpage(newItems ? newItems : currentValue);
            setUpdate(false)
        }
        setTotalData(props.roles.totalPerPage)
        setActivePage(props.roles.currentPage)
    }, [props,cookies,currentValue,update,userCred])
    

    const handlePageChange = (page) => {
        dispatch(roleActions.getAll(page,GetTotalPerpage));
    }

    const handleEditAttribute = (event) => {
        const {id} = event.target;
        props.dispatch(roleActions.roleById(id));
	}

    /* ----------------Custom input type number ----------------*/
        const topArrowClicked = (e) => {
            if (currentValue < 50){
                if(cookies.get("Roles:"+userCred.user._id) === "20" && currentValue === 20){
                    setCurrentValue(prevState => prevState + 30);
                    setUpdate(true)
                }else{
                    setCurrentValue(prevState => prevState + 10);
                    setUpdate(true)
                }
            }
        }
        const bottomArrowClicked = (e) => {
            if (currentValue > 10){
                if(cookies.get("Roles:"+userCred.user._id) === "50" && currentValue === 50){
                    setCurrentValue(prevState => prevState - 30);
                    setUpdate(true)
                }else{
                    setCurrentValue(prevState => prevState - 10);
                    setUpdate(true)
                }
            }

        }

        const InputArrows = ({topArrowClick, bottomArrowClick}) => {

            return (
              <div className="arrows-component">
                <div className="arrows">
                  <button onClick={topArrowClick}>
                  <i className="fa fa-angle-up" aria-hidden="true" style={{cursor:'pointer'}}></i>
                  </button>
                  <button onClick={bottomArrowClick}>
                    <i className="fa fa-angle-down" aria-hidden="true" style={{cursor:'pointer'}}></i>
                  </button>
                </div>
              </div>
            );
          };
    /* --------------------------------------------------------*/

    const deleteAttribute = (roleid) => {
		const Toast = Swal.mixin({
			toast: true,
			width: 300,
			background: '#D4EDDA',
			position: 'center',
			showConfirmButton: false,
			timer: 1500
		});
		
		Swal.fire({
			title:  '<p class="swal-subtitle-dark"> Are you sure?</p>',
            html: "<p class='swal-subtitle-dark'>You won't be able to revert this!</p>",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#24DC98',
			cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            background: '#2E3549',
		}).then((result) => {
			if (result.value) {
                    dispatch(roleActions.roleDelete(roleid));    
					setTimeout(() => {
						Toast.fire({
							type:'success',
							title:'<span style="color:#0A551E">Role has been deleted.</span>',
						});
                        dispatch(roleActions.getAll(ActivePage, GetTotalPerpage))
					}, 100);
					
				
			}
        })
    }
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const addPermission = JSON.parse(localStorage.getItem('addPermission'));
    const editPermission = JSON.parse(localStorage.getItem('editPermission'));
    const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
    const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

    return (
        <BodyBackgroundColor backgroundColor='#1C2233'>
        <div className="list_roles" >

            <div className="table-card">
                <div className="table-header">
                    
                    {
                        (addPermission.includes("Access - Roles") === true) || (roleAllTrue === 1) ?
                        <button className={secretDetails !== null ? "darkmode-btn-default disabled-link" : 'darkmode-btn-default' } onClick={ (e) => history.push('/admin/roles/add')}>{ buttonHeaderContent }</button> : false
                    }
                    <div className="block">
                        <span className="darkmode-btn-default entries test">{ showEntries } </span>
                        <div className="custom-input">
                            <input type="text" className="entries-btn" name="clicks" value={GetTotalPerpage ? GetTotalPerpage : currentValue} pattern="[0-9]*" style={{pointerEvents:'none'}} readOnly/>
                            <span className="arrows">
                                <InputArrows 
                                    topArrowClick={topArrowClicked} 
                                    bottomArrowClick={bottomArrowClicked} 
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className = "responsive-table responsive-attributes">
                        <thead>
                            <tr>
                                <th> Name </th>
                                <th> Slug </th>
                                <th> Role All </th>
                                <th> Date </th>
                                {(editPermission.includes("Access - Roles") === true) || (deletePermission.includes("Access - Roles") === true) || (roleAllTrue === 1) ? 
                                    <th className="action-tbl-title"> Actions </th> : false
                                }
                            </tr>   
                        </thead>
                      
                        <tbody>
                        <If condition={props.roles.loading === false }> 
                            <Then>

                            {props.roles.loading === false && props.roles.roles.items.map((role)=>  (
                                    <tr className="table-td-data" key ={role._id}>
                                    <td style={{textTransform:'capitalize'}}>{role.roleName}</td>
                                    <td>{role.roleSlug}</td>
                                    <td>{role.roleAll ? 'Yes' : 'No'}</td>
                                    <td className="attr-slug-text">{(new Date(role.createdAt)).toLocaleDateString('en-US', DATE_OPTIONS)}</td>
                                    <td className={secretDetails !== null ? "action-tbl disabled-link" : 'action-tbl' }>
                                        {(editPermission.includes("Access - Roles") === true) || (roleAllTrue === 1) ? 
                                            <Link to="#" id={role._id} key={role._id} onClick={handleEditAttribute} className="btn-action btn-edit"  data-tip={"Edit "+ role.roleName} place="top" data-class="tooltips"></Link> : false
                                        }
                                        {(deletePermission.includes("Access - Roles") === true) || (roleAllTrue === 1) ? 
                                            <Link to="#" onClick={() => { deleteAttribute(role._id) }} className="btn-action btn-del" data-tip={"Delete "+ role.roleName} place="top" data-class="tooltips"></Link> : false
                                        }
                                        <ReactTooltip />
                                    </td>
                                    </tr>
                            ))}
                            </Then>
                            <Else>
                                <tr className="table-td-data">
                                    <td> 
                                        <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                                            <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </td>
                                </tr>
                            </Else>
                           
                        </If>
                        </tbody>
                    </table>
                </div>
                
                <div className="table-footer">
                    
                {totalData !== 0 && <div className="dataCount"> Showing { fromPage } to { toPage  } of {  totalData } entries  </div> }
              
                    <Pagination
                        activeClass="pagination_navigtion_active"
                        itemClass="pagination_navigtion"
                        linkClass="pagination_navigtion_link"
                        
                        hideDisabled
                        hideFirstLastPages
                        prevPageText='Previous'
                        nextPageText='Next'
                        pageRangeDisplayed={10}
                        activePage={ActivePage}
                        itemsCountPerPage={GetTotalPerpage}
                        totalItemsCount={totalData}
                        onChange={(e) => handlePageChange(e)}
                    />
                </div>
            </div>
        </div>
    </BodyBackgroundColor>
    )
}


const ListRolesPage = connect(mapStateToProps)((props) => {
    
  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  
  const filterBreadCrumbs = (mode) => {
    console.log(mode)
    switch (mode) {
        case "list":
          setBreadCrumbs([
            {  label: "Roles", link: "/admin/roles?page=1" },
          ]);
          break;
        case "create":
          setBreadCrumbs([
            {  label: "Roles", link: "/admin/roles?page=1" },
            { label: "Create New Role", link: "/admin/roles/add"},
          ]);
          break;
        case "edit":
          setBreadCrumbs([
            {  label: "Roles", link: "/admin/roles?page=1" },
            {  label: "Edit Role", link: "/admin/roles/edit" },
          ]);
          break;
          default:
          // Error page
          break;
        }
};

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);

    return (
        // <div
        //     className={cc([
        //         'mainRoles',
        //         checkSideNav()?.trim()
        //     ])}
        // >   
        //     <div> 
        //         <div className="row">
        //             <div className="col s6 mainTitle"> Access Management</div>
        //             <div className="col s6 sub-title-main">Roles</div>
        //         </div>
        //     </div>
        //     <ListRoles {...props}/>
        // </div>
        <BodyBackgroundColor backgroundColor='#1C2233'>
            <PageWrapper className={cc(["RolesPage", checkSideNav()?.trim()])}>
                <DocumentTitle
                    title={
                        props.mode === "create"
                            ? `Create Role | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                            : props.mode === "edit"
                                ? `Edit Role | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                                : `Access | Roles | ${process.env.REACT_APP_DOCUMENT_TITLE}`
                    }
                />
                <DynamicHeaderContent
                    TitlePage="Access"
                    breadCrumbs={true}
                    breadCrumbsPages={breadCrumbs}
                />
                <If condition={['create', 'edit'].includes(props.mode)}>
                    <Then>
                        <RolesAction {...props} />
                    </Then>
                    <Else>
                        <ListRoles {...props}/>
                    </Else>
                </If>
            </PageWrapper>
        </BodyBackgroundColor>
    )
});
ListRolesPage.WrappedComponent.displayName = 'ListRolesPage';
const ListRolesPageRoute = (props) => (
	<Switch>
        <Route
			exact
			path={`${props.match.path}/add`}
			render={(props) => <ListRolesPage {...props} mode="create" />}
		/>
        <Route
			exact
			path={`${props.match.path}/edit`}
			render={(props) => <ListRolesPage {...props} mode="edit" />}
		/>
        <Route
			exact
			path={`${props.match.path}`}
			render={(props) => <ListRolesPage {...props} mode="list" />}
		/>
		
	</Switch>
);
export default React.memo(ListRolesPageRoute);