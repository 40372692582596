export const energyConstants = {
    CREATE_REQUEST: 'ENERGY_CREATE_REQUEST',
    CREATE_SUCCESS: 'ENERGY_CREATE_SUCCESS',
    CREATE_FAILURE: 'ENERGY_CREATE_FAILURE',

    UPDATE_REQUEST: 'ENERGY_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ENERGY_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ENERGY_UPDATE_FAILURE',

    GETALL_REQUEST: 'ENERGY_GETALL_REQUEST',
    GETALL_SUCCESS: 'ENERGY_GETALL_SUCCESS',
    GETALL_FAILURE: 'ENERGY_GETALL_FAILURE',
};