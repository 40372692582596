export const questConstants = {
	POSTQUEST_REQUEST: 'QUESTS_POSTQUEST_REQUEST',
    POSTQUEST_SUCCESS: 'QUESTS_POSTQUEST_SUCCESS',
    POSTQUEST_FAILURE: 'QUESTS_POSTQUEST_FAILURE',
    
    GETALL_REQUEST: 'QUESTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'QUESTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'QUESTS_GETALL_FAILURE',

    DELETE_REQUEST: 'QUESTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'QUESTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'QUESTS_DELETE_FAILURE',

    GET_REQUEST: 'QUESTS_GET_REQUEST',
    GET_SUCCESS: 'QUESTS_GET_SUCCESS',
    GET_FAILURE: 'QUESTS_GET_FAILURE',

    USERQUESTS_REQUEST: 'GETALLQUESTS_USER_REQUEST',
    USERQUESTS_SUCCESS: 'GETALLQUESTS_USER_SUCCESS',
    USERQUESTS_FAILURE: 'GETALLQUESTS_USER_FAILURE',

    UPDATE_REQUEST: 'QUEST_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'QUEST_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'QUEST_UPDATE_FAILURE',

};

