import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import {useDropzone} from 'react-dropzone';
import { postActions } from '../../../_core/actions/post';
import { announcementActions } from '../../../_core/actions/announcement';
import { smsActions } from '../../../_core/actions/sms';
import { Editor } from 'react-draft-wysiwyg';
import {  draftjsToMd, mdToDraftjs } from 'draftjs-md-converter';
import {  convertToRaw, EditorState, Modifier,convertFromRaw  } from 'draft-js';
import { history } from "../../../_core/helpers/history";
import Swal from 'sweetalert2';

import { useParams } from "react-router-dom";
import  SMSModal from "./SMSModal";

import {
  ContentWrapper,
  LabelCustom,
  SaveAndCancelButton,
} from "../../Components/PageWrapper/ContentWrapper";
import { DragDropImage,} from "../../Components/ImageUploader/updatedUpload";

import "react-datepicker/dist/react-datepicker.css";
import "./AnnouncementDark.scss";

export const CustomContentWrapper = styled.div`
  ${(props) =>
    props.displayUserWrapper === true &&
    css`
      display: grid;
      grid-template-columns: 57% 40%;
      grid-column-gap: 3%;
    `}
`;


const mapStateToProps = ({posts }) => ({
    posts,
  
  });
  
  const CreateEditAnnoucement = connect(mapStateToProps)((props) => {
    const dispatch = useDispatch();
    const [files, setFiles] = React.useState([]);
    const [remove, setRemove] = useState(false)
    const [isAdd, setIsAdd] = useState(true); // eslint-disable-line
    const [InputError, setInputError] = useState(false);// eslint-disable-line
    const [MaxCharacters, setMaxCharacter] = React.useState(0);
    const [checkeds, setChecked] = React.useState(false);
    const [showAppModal, setAppModal] = React.useState(false);// eslint-disable-line
    const [editorState, setEditorState] = useState(() => {
        return EditorState.createEmpty();
    });

    const { pageId } = useParams();
    const userCred = JSON.parse(localStorage.getItem('user'))


    const [announcement, setAnnouncements] =  React.useState({ 
        postType: '',
        postTitle: '',
        postSlug: '',
        userId: '',
        postContent: '',
        postImage: '',
        withImage: '',
        status: '',
        mentions: '',
        anonymous:'',
    });

    React.useEffect(() => {
        if (pageId) {
            setIsAdd(false); // Set update
            dispatch(announcementActions.getContent(pageId)); // call selected challege id
        }
        //eslint-disable-next-line
      }, [dispatch, pageId]);

    React.useEffect(() => {

        // prepopulate selected challenge to edit
        if (pageId) {
          
          let selectedData =  props.announcements.announcement ?? [];
            if(selectedData) {
                selectedData.forEach((item) => {

                    const rawData2 = mdToDraftjs(item.postContent);
                    const contentState2 = convertFromRaw(rawData2);
                    let MaxCharacter = contentState2.getPlainText(' ').length;

                    setAnnouncements({
                        postType: item.postType,
                        postTitle: item.postTitle,
                        postSlug: item.postSlug,
                        userId: item.userId,
                        postContent: item.postContent,
                        postImage: item.postImage,
                        withImage: item.withImage,
                        status: item.status,
                        mentions:item.mentions,
                        anonymous:item.anonymous,
                    });

                    setEditorState(EditorState.createWithContent(contentState2));
                    setMaxCharacter(MaxCharacter)
              })
            }
        }
        //eslint-disable-next-line
      }, [props.announcements.announcement, pageId, dispatch]);

    

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        let rawContent = convertToRaw(editorState.getCurrentContent());
        let MaxCharacter = rawContent['blocks'][0].text.length;
        let customMarkdownDictionary = {
            BOLD: '**',
            ITALIC: '_',
        };
        let convertToMrkDwn = draftjsToMd(convertToRaw(editorState.getCurrentContent()),  customMarkdownDictionary)
        //submission
        setAnnouncements({ ...announcement, postContent: convertToMrkDwn });
        setMaxCharacter(MaxCharacter)
    };

    const {getRootProps, getInputProps, open} = useDropzone({
        accept: 'image/x-png, image/png, image/jpg, image/jpeg, image/gif',
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
            })));
            setAnnouncements({
                ...announcement,
                postImage:acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                    }))[0],
              });
    
        }
    });

    const handleBeforeInput = input => {
        const inputLength = editorState
            .getCurrentContent()
            .getPlainText().length;
        if (input && inputLength >= 800) {
            return "handled";
        }
    };

    const handlePastedText = input => {
        const inputLength = editorState
            .getCurrentContent()
            .getPlainText().length;
        let remainingLength = 800 - inputLength;
        if (input.length + inputLength >= 800) {
            const newContent = Modifier.insertText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                input.slice(0,remainingLength)
            );
            onEditorStateChange(
                EditorState.push(
                    editorState,
                    newContent,
                    "insert-characters"
                )
            );
            return true;
        } else {
            return false;
        }
    };

    const removeSelectedImage = () => {
        setFiles([]);
        setAnnouncements({ ...announcement, postImage: null});
        setRemove(true)
    }   

    const cancelAnnouncement = () => {

        if(
            checkeds === true
        ) {
            dispatch(smsActions.deleteAll(userCred.user_id)) //delete all sms otp messages of user login.
            history.goBack()
            setInputError(false);
        }else{
            history.goBack()
            setInputError(false);
        }

    }

    const confirmationModal = () => {
  
        return Swal.fire({
          title: "<p class='swal-title-engagement'>Confirmation</p>",
          html:
            " <div style='margin-bottom:20px'><p class='alert-first'>Do you want to cancel sending announcements to all users to their contact numbers?</p></div>",
    
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#556ee6",
          cancelButtonColor: "#2A3143",
          customClass: {
            header: 'header-class',
            container: 'container-class',
            popup: 'popup-class-engagament',
            actions: 'actions-class-engagament',
            cancelButton: 'cancel-button-class',
            confirmButton: 'confirm-btn-engagement',
            content: 'E-alert-conent',
          },
          reverseButtons:true,
          cancelButtonText: "CANCEL",
          confirmButtonText:"SAVE",
          background: "#2E3549",
        });
    
        
      };
    

    const handleChangeCheckBox = (event) => { 

        const ToastFailed = Swal.mixin({
            background: "#2a3042",
            toast: true,
            width: 400,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });

        if (
            event.target.checked !== false
        ){
            if(userCred.contactNumber === null || userCred.contactNumber === undefined || userCred.contactNumber === "" ){
                ToastFailed.fire({
                    type: 'error',
                    title: `<span style="color:#FFFFFF">You don't have registered number.</span> `
                })
            }else{
                dispatch(smsActions.createOTP())
                setTimeout(() => {
                    setAppModal(true);
                }, 1600)
            }
        }else{
            confirmationModal().then((result) => {
                if(result.value){
                    setChecked(false)
                    //dispatch(smsActions.deleteAll(userCred.user_id))
                }
            })
        }
     
    }

    const handleCloseModal =()=> {
        setAppModal(false)
    }

    const submit = () => {
        const userCred = JSON.parse(localStorage.getItem('userCredentials'))
        if(
            !announcement.postContent
        ) {
            setInputError(true);
        }else{
            if(isAdd){
                
                let AnnouncementPost = {
                    postType: 'announcement',
                    postTitle: "Announcement",
                    postSlug: 'Post',
                    userId: userCred.user._id,
                    postContent: announcement.postContent,
                    postImage: announcement.postImage !== null ? announcement.postImage: null,
                    withImage: announcement.postImage ? true : false,
                    status: 1,
                    mentions: null,
                    anonymous:0,
                }

                let messageBody = {
                    message:"Pragmahr Announcement:\n\n"+announcement.postContent
                }
         
                dispatch(postActions.create(AnnouncementPost));
                setInputError(false);
                setTimeout(() => {
                    history.goBack()    
                }, 1500)
                
                if(checkeds === true){
                    dispatch(smsActions.createMessage(messageBody)) //sending an announcement to all users with their contact numbers.
                    dispatch(smsActions.deleteAll(userCred.user._id))  
                }
            } else {
                let announcementId = pageId;

                let AnnouncementPost = {
                    postType: 'announcement',
                    postTitle: "Announcement",
                    postSlug: 'Post',
                    userId: userCred.user._id,
                    postContent: announcement.postContent,
                    postImage: announcement.postImage !== null ? announcement.postImage : null,
                    withImage: announcement.postImage !== null ? true : false,
                    status: 1,
                    mentions: null,
                    anonymous:0,
                }

                dispatch(announcementActions.update(announcementId, AnnouncementPost))
               
                setInputError(false);
                setTimeout(() => {
                    history.goBack()    
                }, 1500)
            
            }
        }
    }


    return ( 
    <CustomContentWrapper>
        <ContentWrapper>
            <LabelCustom className="main-label">
                Announcement Content
            </LabelCustom>
            <Editor
                {...{editorState }}
                name="editor"
                onEditorStateChange={onEditorStateChange}
                handleBeforeInput={handleBeforeInput} 
                handlePastedText={handlePastedText} 
                toolbar={{
                    options: [
                        'inline',
                        'list',
                        'link',
                        'history'
                    ],
                    link: {
                        defaultTargetOption: '_self',
                    },

                }}
                wrapperClassName="editor-wrapper"
            />
            <LabelCustom className="main-label">
                <p className="max-character-description">{`${MaxCharacters}`} characters entered out of 800</p>
            </LabelCustom>
            <div className="smsCheckBox">
                <label >
                <input type="checkbox" 
                    name="smsCheckbox"
                    checked={checkeds}
                    onChange={handleChangeCheckBox}
                />Send to all contact number
                </label>
            </div>

            <SMSModal
                isOpen={showAppModal} 
                onRequestClose={() => setAppModal(false)} 
                toggle={setAppModal}
                active={() => setChecked(true)}
                handleCloseModal={handleCloseModal}
            />

            <div>
                <span htmlFor="feature_image" className="featured">
                    Featured Image
                </span>
                <div {...getRootProps({className: 'dropzone'})}>
                    <div className={files[0] || announcement.postImage? 'active-dz': 'unactive-dz'} >
                        <input {...getInputProps()} />
                        <p>Drag and drop files here</p>
                        <button type="button" onClick={open} className="custom-file-upload">
                            UPLOAD
                        </button>
                    </div>
                    <DragDropImage 
                            removeImage={removeSelectedImage} 
                            selectedImage={files} 
                            existingImage={announcement.postImage} 
                            remove={remove}
                            isAdd={isAdd}
                    />
                </div>
            </div>

            <SaveAndCancelButton
                cancelFn={() => cancelAnnouncement()}
                saveFn={() => submit()}
                submitLabel="PUBLISH"
            />
        </ContentWrapper>
    </CustomContentWrapper>

    )
  });


  export default CreateEditAnnoucement;