import { post, get, del, put } from '../helpers/api';
import { userService } from '../services/user';

export const achievementService = {
    create,
    getAll,
    getOne,
    update,
    delete: _delete
};

function getAll(page,itemsPerPage) {
    return get(`/api/v1/achievement?page=${page}&items-per-page=${itemsPerPage}`).then(handleResponse);
}
function create(achievement){
    return post('/api/v1/achievement', JSON.stringify(achievement))
    .then(handleResponse)
}

function getOne(id) {
    return get(`/api/v1/achievement/${id}`).then(handleResponse);
}

function update(id,achievement) {
    return put(`/api/v1/achievement/${id}`, JSON.stringify(achievement)).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return del(`/api/v1/achievement/${id}`).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}