import React from "react";
import cc from "classcat";
import moment from "moment";
import styled from "styled-components";
import DocumentTitle from "react-document-title";
import BodyBackgroundColor from "react-body-backgroundcolor";

import { Link } from 'react-router-dom';
import { Route, Switch } from "react-router-dom";
import { Switch as SwitchCase, Case, Then, Else,If } from 'react-if';
import { connect, useDispatch } from "react-redux";
import { leaveTypeService } from '../../../_core/services/leaveType';
import ReactImageFallback from "react-image-fallback";

import { checkSideNav } from "../../Components/checkSideMenu";
import { leaveTypeActions } from '../../../_core/actions/leaveType';
import { leaveCreditActions } from '../../../_core/actions/leaveCredit';
import { leaveActions } from '../../../_core/actions/leave';
import avatar from '../../../_templates/man.png';

import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent, DynamicTableAndPaginationWrapper,DynamicSearchWrapper  } from "../../Components/PageWrapper/DynamicWrapper";
import "../../Components/StatusStyle/status.scss";
import "./LeaveCreditsDark.scss";
import CreateCreditsPage from "./CreateCreditsPage";
import ManageCredits from "./ManageCredits";

import { leaveHours } from '../../../_core/helpers/config'

const AdminLeaveCreadits = styled.div`
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4), &:last-child {
        text-align: inherit;

		@media (max-width: 993px){
			text-align: right;
		} 
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4) {
        text-align: inherit;
        
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;

const LeavePageList = (props) => {
  const dispatch = useDispatch();
  const [users, setUsers] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [names, setNames] = React.useState([]);
  const [credits, setCredits] = React.useState([]);
  const [search, setNewSearch] = React.useState("");
  const [keys, setKeys] = React.useState("");

  const [entriesValues, setentriesValues] = React.useState(); //showEntries

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  let filterkeys = ["name"];

  React.useEffect(()=>{
	dispatch(leaveTypeActions.getAll());
    dispatch(leaveCreditActions.getAll());
    dispatch(leaveActions.getAll());
  },[dispatch])
  

  React.useEffect(() => {
	try {
		leaveTypeService.getAll(1, 999999).then(res => {
			if (res.items) {
                let typeName = []
                
                res.items.length !== 0 &&
                res.items.forEach((data) => {
                    const s = data.name;
                    const withoutLastChunk = s.slice(0, s.lastIndexOf(" "));
                    typeName.push(withoutLastChunk);
                });
                
                typeName.unshift('Name');
                (editPermission.includes("Leaves - User Leave Overview") === true) || (roleAllTrue === 1) ? typeName.push("Last Accrue", "Last File", "Actions") : typeName.push("Last Accrue", "Last File", "");

				setTypes(res.items)//remove

                setNames(typeName)
			}
		}).catch((err) => { console.log('get leave error occured') })
	} catch (err) {
		console.log(err);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


React.useEffect(()=>{
    setUsers(props.users.users);
    setCredits(props.leaveCredits.leaveCredits)
  },[props])

  const getCredits=(uid, tid)=>{
    const userId = uid;
    const typeId = tid;
    var userCredits = [];
    var count = 0;

    if(props.leaveCredits.loading !== true){

        credits && credits.users.filter((credits)=> credits._id === userId && count === 0)
        .map((userCredit)=>{
            userCredit.leave_types.map((credit)=>{
                if(credit._id === typeId && count === 0){
                    userCredits.push((credit.available / leaveHours.time) + " ("+ (credit.available)+" hrs)")
                    count++ 
                    // userCredits.push(credit.available)
                    // count++ 
                }
                // console.log(credit)
                return credit
            })
            return userCredit
        })
    
        if(count === 0){
            userCredits.push(0)
            count++
        }

        return userCredits

       
    }


}

//showEntries
const entriesValue = (entries) => {
    setentriesValues(entries);
}


// const getTotal=(id)=>{
//     const userId = id;
//     var totalCredits = [];

//     if(props.leaveCredits.loading !== true){

//         credits && credits.length && credits.users.filter((credits)=>credits._id === userId)
//         .map((credit)=>{
//             credit.leave_types.map((userCredit)=>{
//                 if(userCredit.name){
//                     totalCredits.push(userCredit.available / leaveHours.time)
//                     return userCredit
//                 }else{
//                     return userCredit
//                 }
//             })
//         return credit
//         })

//         var sum = totalCredits.reduce((total, value)=> total + value, 0)

//         return sum

//     }
// }

const createdAt=(id)=>{
    const userId = id;
    var createdDates = [];
    var lastDates = [];
    var count = 0;

    if(props.leaveCredits.loading !== true){

        credits && credits.users.filter((credits)=>credits._id === userId && count === 0)
        .map((credit)=>{
            credit.leave_types.map((date)=>{
                date.details.map((date1)=>{
                    createdDates.push(date1.createdAt)
                    return date1
                })
                
                return date
            })
            count++

            let getCreatedDate = createdDates.map(d => moment(d))
            var latestCd = moment.max(getCreatedDate)
            var createdDate = moment(latestCd).format("MMM D, YYYY")

            lastDates.push(createdDate)

            return credit
        })

        if(count === 0){
            lastDates.push(<p>No Accrues Yet</p>)
            count++
        }
        
        return lastDates

    }
}

const updatedAt=(id)=>{
    const userId = id;
    //var updatedDates = [];
    var lastDates = [];
    var count = 0;

    props.leaves && props.leaves.items.filter((leaves)=>leaves.user_id === userId && count === 0)
    .map((date)=>{
        var updatedDate = moment(date.createdAt).format("MMM D, YYYY")
        lastDates.push(updatedDate)
        count++
        return null
    }).sort().reverse()

    // credits && credits.users.filter((credits)=>credits._id === userId && count === 0)
    // .map((credit)=>{
    //     credit.leave_types.map((date)=>{
    //         date.details.map((date1)=>{
    //             updatedDates.push(date1.updatedAt)
    //             return date1
    //         })
    //         return date
    //     })
    //     count++

    //     let getUpdatedDate = updatedDates.map(d => moment(d))
    //     var latestUd = moment.max(getUpdatedDate)
    //     var updatedDate = moment(latestUd).format("MMM D, YYYY")

    //     lastDates.push(updatedDate)
        
    //     return credit
    // }) 

    if(count === 0){
        lastDates.push(<p>No Filings Yet</p>)
        count++
    }

    return lastDates
}

    //search
    const handleSearchChange = (item) => {
		setNewSearch(item);
		};
	  
	  //filters
	  const filterKeys = (item) => {
		  setKeys(item);
	  };



  	const LeaveData = () => {
    	let data = users ?? [];
    return (
      <>
        <If condition={props.leaveCredits.loading !== true}>
            <Then>
                {data.length !== 0 ? (
                    data.items.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <div className="valign-wrapper">
                                    <ReactImageFallback
                                        src={item.details[0].avatar || item.details.avatar}
                                        fallbackImage={avatar}
                                        initialImage={avatar}
                                        alt="user avatar"
                                        className="user-photo avatar circle responsive-img"
                                    />
                                    <span className="admin-user-name"> {item.details[0].firstName || item.details.firstName}{' '}{item.details[0].lastName || item.details.lastName}</span>
                                 </div>  
                            </td>
                            {types && types.map((type, index)=>
                                <td key={index} className="leave-types">
                                    {getCredits(item._id, type._id)}
                                    {/* {getCredits(item._id, type._id)} */}
                                </td>
                            )}
                            {/* <td>{getTotal(item._id)}</td> */}
                            <td>{createdAt(item._id)}</td>
                            <td>{updatedAt(item._id)[0]}</td>
                            {
                            (editPermission.includes("Leaves - User Leave Overview") === true) || (roleAllTrue === 1) ? 
                              <td className={secretDetails !== null ? "disabled-link" : '' }>					
                                  <Link to={`/admin/leave-credits-overview/manage/${item._id}`}>
                                      <button className="btn waves-effect waves-light" id="review">MANAGE</button>
                                  </Link>  
                              </td> : false
                            }
                            
                        </tr>
                    ))
                ) : (
                    <>
                    <tr>
                        <td colSpan="4"> 
                          <div className="center">
                            <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          </div>
                        </td>
                    </tr>
                    </>
                )}
            </Then>

            <Else>
            <>
                <tr>
                 <td colSpan="2"> Loading Data... </td>
                 <td colSpan="1"> 
                  <div className="center">
                    <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                 </td>
                </tr>
            </>
            </Else>
        </If>
      </>
    );
  };

  return (
    <AdminLeaveCreadits>

        <DynamicSearchWrapper
            buttonLabel="ADD LEAVE CREDITS"
            btnPath="/admin/leave-credits-overview/create"
            entriesValue={entriesValue}
            data={props.users}
            location={props.location}
            searchs={handleSearchChange}
            filters={filterKeys}
            keys={filterkeys}
            paginationPath={"/admin/leave-credits-overview"}
            originPage="LeavesCredits"
            addPage="Leaves - User Leave Overview"
        />


        <DynamicTableAndPaginationWrapper
            tableHeader={names}
            tableBodyData={LeaveData()}
            originPage="UsersSearchFilter"
            data={props.users}
            location={props.location}
            paginationPath={"/admin/leave-credits-overview"}
            entriesItem={entriesValues}
            search={search}
            loading = {props.users.loading}
            filter={keys}
        />
    </AdminLeaveCreadits>
  );
};

const mapStateToProps = ({ leaves, leaveTypes,leaveCredits,users }) => ({
  leaves,
  leaveTypes,
  leaveCredits,
  users
});


const LeaveCreditsPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])
  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "User Leave Overview", link: "/admin/leave-credits-overview?page=1" },
        ]);
        break;
      case "create":
        setBreadCrumbs([
          { label: "User Leave Overview", link: "/admin/leave-credits-overview?page=1" },
          { label: "Manual Add Leave Credit", link: "#" },
        ]);
        break;
        case "manage":
            setBreadCrumbs([
              { label: "User Leave Overview", link: "/admin/leave-credits-overview?page=1" },
              { label: "Modify Leave Credit", link: "#" },
            ]);
            break;
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Leaves | Active Leaves | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            ? props.mode === "manage" 
            :`Leave Manage Credits | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : ''
        }
      >
        <PageWrapper className={cc(["LeaveCredits", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Leaves"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <LeavePageList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={['create', 'add'].includes(props.mode)}>
              <CreateCreditsPage {...props } />
            </Case>
            <Case condition={['manage'].includes(props.mode)}>
              <ManageCredits {...props } />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});


LeaveCreditsPage.WrappedComponent.displayName = "LeaveCreditsPage";

const LeaveCreditsDarkRoute = (props) => (
    
  <Switch>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <LeaveCreditsPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <LeaveCreditsPage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/manage/:pageId`}
      render={(props) => <LeaveCreditsPage {...props} mode="manage" />}
    />
    <Route
      exact
      path={`${props.match.path}/:id/create`}
      render={(props) => <LeaveCreditsPage {...props} mode="add" />}
    />
  </Switch>
);
export default React.memo(LeaveCreditsDarkRoute);
