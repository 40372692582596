import React, { useEffect } from "react";
import closeIcon from '../../_templates/x_square.png';
import fileIcon from '../../_templates/file_blank_fill.png';
const acceptedImgFile = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';


export const DragDropImage = (props) => {

  // Just some styles
    const styles = {
        preview: {
            display: "inline-block",
            flexDirection: "column",
            padding: "10px"
        },
        image: { 
            maxWidth: "37px", 
            maxHeight: "fit-content", 
            float:'left', 
            width: '37px',
            objectFit: 'scale-down'
        },
        delete: {
            cursor: "pointer",
            background: "none",
            border: "none",
            display: "inline-block",
            flexDirection: "column",
            padding: "15px",
            float:'right'
        },
        li:{
            padding: "5px",
            marginLeft: "40px"
        },
        grid:{
            border: "1px solid #DDDDDD",
            borderRadius: "5px",
            width: "auto",
            height: "60px",
            flex: "0 1",
            display: "flex",
            alignItems: "center"
        }
    };

    const thumbs = props.selectedImage.map(file => (
        <div key={file.name} style={styles.grid}>
          <div style={styles.preview}>
            
            <img
              accept={acceptedImgFile}
              src={file.preview}
              style={styles.image}
              onError={(e) => (e.target.src = fileIcon) }
              alt="Thumb"
            />
            <li key={file.path} style={styles.li}>{file.path}</li>

          </div>
          <button onClick={props.removeImage} style={styles.delete} className="btn-remove-img">
                <img height={20} src={closeIcon} alt="" style={{ marginRight: 7 }}/>
            </button>
        </div>
      ));

      useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        props.selectedImage.forEach(file => URL.revokeObjectURL(file.preview));
      }, [props.selectedImage]);

  return (
    <>
        <aside>
            <ul>{thumbs}</ul>
      </aside>
    </>
  );
};
