import React from "react";
import Modal from 'react-modal';
import moment from 'moment';

import closeIcon from '../_templates/x_square.png';

import Skeleton from 'react-loading-skeleton';
import { challengeService } from '../_core/services/challenge';

import './ChallengeWidget.sass'


const ChallengeFailed = (props) => {
    const [challenge, setChallenge] = React.useState([]);
    const [executed, setExecuted] = React.useState(false);

    React.useEffect(() => {
        if (!executed) {
          const timeoutId = setTimeout(() => {
            // Check if props.challengeId exists before making the API call
            if (props.challengeId) {
              challengeService.getOne(props.challengeId)
                .then(res => {
                  setChallenge(res.success[0]);
                  setExecuted(true);
                })
                .catch(error => {
                  console.log(error);
                  setExecuted(true);
                });
            }
          }, 1000);
      
          // Reset executed state to false immediately after setting the timeout
          setExecuted(false);
      
          return () => clearTimeout(timeoutId);
        }
      }, [executed, props.challengeId]);


    const cancel = () => {
        props.handleCloseModal(false)
    }
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        toggle={props.toggle}
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
        centered
        className="ChallengeUpdate challenge-failed"
      >
        <div className="row center create-challenge-container"
            style={{
                width: "100%",
                height: "auto",
                margin: "auto",
                padding: "20px 0px 20px",
                borderBottom: "1px solid #979797"
            }}>
            
            <p className="usermodal_title_update center"> Details Completion</p>
            <button className="close-modal" onClick={cancel}>
                <img height={20} src={closeIcon} alt="" style={{ marginRight: 7}}/>
			</button>
        </div>

        <div className="request-details-container">
            {/* <div className="div-image" style={{marginBottom:'50px'}}>
                {
                    challenge.finishImages !== null ? 
                        <img height={274} width={370.94} src={challenge.finishImages} alt="" style={{ objectFit:'contain' }}/>
                    :
                    <div style={{display:'inline-grid', alignItems:'center', backgroundColor:'#FFFF'}}>
                        <img height={274} width={370.94} src={ChallengeEmpty} alt="" style={{ objectFit:'none' }} />
                        <span className ="noImgStyle" style={{marginTop:'-80px'}}>No Image File</span>
                    </div>
                    
                }
                
            </div> */}
            <div className="div1" style={{width:'auto',margin:'auto', marginBottom:'50px', marginTop:'35px'}}>

                {executed ?
                    challenge.userDetails?.map((details, index) => (
                        <div className="div1-title" style={{marginLeft:'20px',marginRight:'20px'}} key={index}>
                            <span style={{fontWeight:'700', textTransform:'capitalize'}}>{details.firstName} {details.lastName}, </span> Failed the Challenge:Time Limit Exceeded!<span style={{fontWeight:'700', textTransform:'capitalize'}}> {challenge.title}</span>
                        </div>
                    ))
                :
                <div className="div1-title">
                    <span ><Skeleton height={20} width={200} /></span>
                </div>
                }

                
               
            </div>
            <div className="footer-container">
                <div style={{textAlign:'center', margin:'25px'}}>
                    <span>Period from</span> <span style={{fontWeight:'700', textTransform:'capitalize'}}>{ moment(challenge.createdAt).format('L')} - { moment(challenge.expiresAt).format('L')}</span>
                </div>
            </div>

        </div>


      </Modal>
    </>
  );
};

export default ChallengeFailed;