import React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../_core/actions/user';
import { userService } from '../_core/services/user';
import { alertActions } from '../_core/actions/alert';
import { randomTips } from '../_core/helpers/config';
// import BodyBackgroundColor from 'react-body-backgroundcolor'
import logo from '../_templates/Logo.png';

import './Login.sass'
import './Login_Circle.sass'
import './Login_DayTime.sass'
import './Login_Mountain.sass'
import './Login_Load.sass'
import './Login_Building.sass'
import './Login_Bike.sass'

import '../_core/styles/alert.sass'
import M from 'materialize-css/dist/js/materialize.min.js'

import { useDispatch } from "react-redux";

const NewLogin = (props) => {

    const dispatch = useDispatch();
    const prevUrl = props.location.state !== undefined ? props.location.state.from : '';

    const [login, setLogin] = React.useState({
        email: '',
        password: '',
        submitted: false,
        render: false,
        refreshToken: false,
        organization:'',
    });

    const [bgType, setBgType] = React.useState("");
    const [bgDefault , setDefault] = React.useState({
        stars: 'stars',
        big_planet: 'big-planet',
        small_planet: 'small-planet',
        rocket: 'rocket',
        rocks: 'rocks',
        ground: 'ground',
    });
    const [bgDaytime] = React.useState({
        sun:'sun',
        shade:'shade',
        floating_rock_left:'floating-rock-left',
        // small_rock_1:'small_rock_1',
        // small_rock_2:'small_rock_2',
        ground: 'ground',
        cloud: 'cloud',
        rocks: 'rocks',
    });
    const [bgCircle] = React.useState({
        circle_left:'circle-left',
        circle_right:'circle-right',
        circle_left_small:'circle-left-small',
        circle_right_small:'circle-right-small',
    });

    const [bgLoad] = React.useState({
        loading_right:'loading-right',
        loading_left:'loading-left',
        circle_big_right_load:'circle-big-right-load',
        circle_big_left_load:'circle-big-left-load',
        circle_small_right_load:'circle-small-right-load',
        circle_small_left_load:'circle-small-left-load',
    });

    const [bgBuilding] = React.useState({
        building:'building',
        building_behind:'building-behind',
        path:'path',
        plants_1:'plant-1',
        plant_2:'plant-2',
        plant_3:'plant-3',
        road_floor:'road-floor',
    });

    const [bgBike] = React.useState({
        bike:'bike',
        building_bike:'building-bike',
        cloud_bike:'cloud-bike',
        leaves:'leaves',
        plants_1_bike:'plant-1-bike',
        plant_2_bike:'plant-2-bike',
        plant_3_bike:'plant-3-bike',
        road_1:'road_1',
        road_2:'road_2',
        tree_1:'tree_1',
        tree_2:'tree_2',
        tree_3:'tree_3',
        tree_4:'tree_4',
        tree_5:'tree_5',
    });

    const [bgMountain] = React.useState({
        circle_left_small_mountain: 'circle-left-small-mountain',
        circle_right_small_mountain: 'circle-right-small-mountain',
        mountain_big_left: 'mountain-big-left',
        mountain_big_right: 'mountain-big-right',
        mountain_small_right: 'mountain-small-right',
        mountain_small_left: 'mountain-small-left',
    });

    const [submitted, setSubmitted] = React.useState(false);
    const [others, setOthers] = React.useState({
        error: '',
        isValidate: false,
        prevUrl: prevUrl,
        steps:1,
        maxStep:2,
        emailError:false,
        passwordType:"password",
        orgError:false,
    })

    const myArray = [
        'default',
        'daytime',
        'bike',
        'building',
        'circle',
        'load',
        'mountain'
    ]

    const alertTimeOut = () => {
        setTimeout(function () {
            dispatch(alertActions.clear());
        }, 4000);
    }

    //Tips Randomizer
    const randomItemGenerator = (array) => {
        return  array[Math.floor(Math.random()*array.length)]
    }

    
    const dailyTips = async () => {
		let shuffles = randomItemGenerator(randomTips.tip)
		try {
            var toastHTML = '<div class="toasted"><div class="toast-head"><p class="tip-head">Tip of the day</p></div><p class="toast-tips">'+shuffles+'</p></div>';
            return M.toast({html: toastHTML, classes: 'toasted'});
		 
		} catch (err) {
		  console.error(err);
		}
	};

	React.useEffect(() => {
        
        refresh()
        alertTimeOut()
		
	}, []);	// eslint-disable-line 

    const shuffleArray = (array) =>{
        let i = array.length - 1;
        for (; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array;
    }

    //Randomize Background
    const refresh=()=> {
        var midNight = new Date();
        midNight.setHours(0,0,0,0);

        var arrayLength = myArray.length

    
        // // Retrieve last saved settings
         var backgroundType = JSON.parse(localStorage.getItem("backgroundType"));

        // // If no settings or they are from before midnight, 
        // //    or they have a different count of background: regenerate
        if (!backgroundType || backgroundType.date < midNight.toJSON() 
                || backgroundType.types?.length !== arrayLength || localStorage.getItem("backgroundType") === null) {
                
            // Re-initialise for a new day
            backgroundType = {
                types: shuffleArray(myArray), // randomly shuffle the backhground types
                date: midNight.toJSON() // Take note of the date of generation
            };
            // Save this in local storage so that it will be read when page reloads
            localStorage.setItem('backgroundType', JSON.stringify(backgroundType));
        }
    
        var msSinceMidnight = new Date() - midNight,
            msPerQuote = 1000*60*60*24,
            index = Math.floor(msSinceMidnight / msPerQuote),
            nextAt = new Date((index + 1) * msPerQuote + midNight.getTime());

        // // Get background from shuffled array at an index that depends on time of the day
        var randomQuote = backgroundType.types[index];
 
        // // Display
        setBgType(randomQuote)
        dailyTips()
        // // Repeat this function automatically when this background expires
        setTimeout(refresh, nextAt - new Date());
    }
    

    const handleChange  = (e) => {
        const { name, value } = e.target;
        setLogin({ ...login, [name]: value });
    }

    const proceedToNextStep = () =>{
        setSubmitted(false);

        if (others.steps === others.maxStep) {
            return handleSubmit();
        }
        
        return others.steps < others.maxStep && setOthers(prevState => { return {...others, steps: prevState.steps + 1}});
    }

    const emailWarning = () => {
        setOthers({
            ...others, emailError:true,
        });
    }

    const onKeyPress = (e) => {
        if(e.which === 13) {
          submitInputs();
        }
    }

    const submitInputs=()=> {
        switch (others.steps) {
            case 1:
                if (!login.email) {
                    setLogin({
                        ...login,
                        email:''
                    });
                    setSubmitted(true)
                }else{
                    try {
                        userService.verifyEmail(login.email).then(res => {
                            if(res.success) {

                                console.log(res.success)

                                if(res.success.organization === null){
                                    setOthers({
                                        ...others,
                                        orgError:true,
                                        emailError:false

                                    });
                                    setSubmitted(false)

                                }else{
                                    setLogin({ 
                                        ...login,
                                        organization: res.success.organization !== null ? res.success.organization.org_slug : null,
                                    })
                                    setOthers({
                                        ...others,
                                        emailError:false,
                                        submitted:false,
                                        orgError:false
                                    });
                                    return proceedToNextStep();                                                           
                                }
                                
                            }
                        }).catch((err) => {
                            return emailWarning();
                        })
                    } catch (error) {
                        return emailWarning();
                    }
                }
                break;
            case 2:
                if (!login.password) {
                    setLogin({
                        ...login,
                        submitted:true,
                        password:''
                    });
                }else{
                    return proceedToNextStep();
                }
                break;
            default:
                return;
        }
    }
    const changeBtn =()=> {
        setLogin({
            ...login,
            submitted: false
        });

        return setOthers(prevState => { return {...others, steps: prevState.steps - 1}});
    }
    
    const togglePassword = () => {
        if(others.passwordType==="password"){
            setOthers({
                ...others,
                passwordType: "text"
            });
            return;
        }
        setOthers({
            ...others,
            passwordType: "password"
        });
    }

    const handleSubmit=()=> {
        // e.preventDefault();

        setLogin({
            ...login,
            submitted: true,
            render: true
        });

        if (login.email && login.password) {
            if(bgType === 'default'){
                var rocketLog = bgDefault.rocket + ' rocket-launch'
                setDefault({...bgDefault, rocket: rocketLog });
            }

            dispatch(userActions.login(login.email, login.password, login.refreshToken, prevUrl));
            setTimeout(function () {
                //this.setState({ render: false })
                dispatch(alertActions.clear());
                // this.zoomImages()
                if(bgType === 'default'){
                    setDefault({...bgDefault, rocket: 'rocket'});
                }
            }, 3000);
        }


    }
    
    // const handleClose=(event)=> {
    //     event.preventDefault()

    //     //this.setState({ render: false });
    //     //this.props.dispatch(alertActions.clear());
    // }

    const rememberMe = () => {
        // Get the checkbox
        var checkedBox = document.getElementById("remember");

        // If the checkbox is checked
        if (checkedBox.checked === true) {
            setLogin({ ...login, refreshToken: true })
        }else{
            setLogin({ ...login, refreshToken: false }) 
        }
    }

    const checkPosition = (e) => {
        var mouseX = e.pageX;
        var scrWidth = window.innerWidth;

        var bigPLanetX = ((mouseX - scrWidth) / scrWidth * 100) / Math.pow(10, 0.5) + 0.39 * scrWidth;
        var smallPLanetX = ((mouseX - scrWidth) / scrWidth * 100) / Math.pow(10, 0.5) + 0.47 * scrWidth;
        var rocksX = ((scrWidth - mouseX) / scrWidth * 100) - 100
        // var rocketX = (mouseX / scrWidth * 100) / Math.pow(10, 1.5) +  0.81 * scrWidth;
        var groundX = ((mouseX - scrWidth) / scrWidth * 100) / Math.pow(10, 1);
        var starsX = ((mouseX - scrWidth) / scrWidth * 100);

        document.querySelector('.big-planet').style.right = (bigPLanetX) + 'px';
        document.querySelector('.small-planet').style.right = (smallPLanetX) + 'px';
        document.querySelector('.rocks').style.right = (rocksX) + 'px';
        document.querySelector('.ground').style.left = (groundX) + 'px';
        document.querySelector('.stars').style.right = (starsX) + 'px';
    }

	return (
            <DocumentTitle title={`Login | ${process.env.REACT_APP_DOCUMENT_TITLE}`}>
                <div className="login-cover">
                    {/* <div className="login" onMouseMove={(e) => this.checkPosition(e)}> */}
                    <div className={
                    bgType === 'default' ? 
                    "login" 
                    : bgType === 'daytime' ? 
                    "login-daytime" 
                    : bgType === 'circle'? 
                    "login-circle"
                    : bgType === 'bike'? 
                    "login-bike"
                    : bgType === 'building'? 
                    "login-building"
                    : bgType === 'load' ? 
                    "login-load"
                    : bgType === 'mountain' ? 
                    "login-mountain"
                    :
                    "login"
                    } 
                    onMouseMove={bgType !== 'default' ? null : (e) => checkPosition(e)}
                    >
                 
                    

                        <div className="login-bg"></div>

                        {bgType === 'default' ? 
                            <>
                                {/* DEFAULT DESIGN */}
                                <div className={bgDefault.stars}></div>
                                <div className={bgDefault.big_planet}></div>
                                <div className={bgDefault.small_planet}></div>
                                <div className={bgDefault.rocket}>
                                    <ul>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                    {/* <div className="flame-wrapper">
                                        <div className="flame red"></div>
                                        <div className="flame orange"></div>
                                        <div className="flame gold"></div>
                                        <div className="flame white"></div>
                                    </div> */}
                                </div>
                                <div className={bgDefault.rocks}></div>
                                <div className={bgDefault.ground}></div>
                            </> 
                        : bgType === 'daytime' ? 
                            <>
                            {/* DAYTIME DESIGN */}
                                <div className={bgDaytime.rocks}></div>
                                <div className={bgDaytime.ground}></div>
                                <div className={bgDaytime.cloud}></div>
                                <div className={bgDaytime.sun}></div>
                                <div className={bgDaytime.shade}></div>
                                <div className={bgDaytime.floating_rock_left}></div>
                                {/* <div className={bgDaytime.small_rock_1}></div>
                                <div className={bgDaytime.small_rock_2}></div> */}
                            </>
                        : bgType === 'bike' ? 
                            <>
                                {/* BIKE DESIGN */}
                                <div className={bgBike.bike}></div>
                                <div className={bgBike.building_bike}></div>
                                <div className={bgBike.cloud_bike}></div>
                                <div className={bgBike.leaves}></div>
                                <div className={bgBike.plants_1_bike}></div>
                                <div className={bgBike.plant_2_bike}></div>
                                <div className={bgBike.plant_3_bike}></div>
                                <div className={bgBike.road_1}></div>
                                <div className={bgBike.road_2}></div>
                                <div className={bgBike.tree_1}></div>
                                <div className={bgBike.tree_2}></div>
                                <div className={bgBike.tree_3}></div>
                                <div className={bgBike.tree_4}></div>
                                <div className={bgBike.tree_5}></div>
                            </>
                        : bgType === 'building' ? 
                            <>
                                {/* BUILDING DESIGN */}
                                <div className={bgBuilding.building}></div>
                                <div className={bgBuilding.building_behind}></div>
                                <div className={bgBuilding.path}></div>
                                <div className={bgBuilding.plants_1}></div>
                                <div className={bgBuilding.plant_2}></div>
                                <div className={bgBuilding.plant_3}></div>
                                <div className={bgBuilding.road_floor}></div>
                            </>
                        : bgType === 'circle' ? 
                            <>
                                {/* CIRCLE DESIGN */}
                                <div className={bgCircle.circle_left}></div>
                                <div className={bgCircle.circle_right}></div>
                                <div className={bgCircle.circle_left_small}></div>
                                <div className={bgCircle.circle_right_small}></div>
                            </>
                        : bgType === 'load' ? 
                            <>
                                {/* LOAD DESIGN */}
                                <div className={bgLoad.loading_right}></div>
                                <div className={bgLoad.loading_left}></div>
                                <div className={bgLoad.circle_big_right_load}></div>
                                <div className={bgLoad.circle_big_left_load}></div>
                                <div className={bgLoad.circle_small_right_load}></div>
                                <div className={bgLoad.circle_small_left_load}></div>
                            </>
                        : bgType === 'mountain' ? 
                            <>
                                {/* MOUNTAIN DESIGN */}
                                <div className={bgMountain.circle_left_small_mountain}></div>
                                <div className={bgMountain.circle_right_small_mountain}></div>
                                <div className={bgMountain.mountain_big_left}></div>
                                <div className={bgMountain.mountain_big_right}></div>
                                <div className={bgMountain.mountain_small_right}></div>
                                <div className={bgMountain.mountain_small_left}></div>        
                            </>
                        : 
                        <>
                        </> 
                        }



                        <div className="login-container">
                            <div>
                                <div className='card-login'>
                                    <div className="logo-container">
                                        <Link to="/"><img src={logo} className="logo" alt="logo" /></Link>
                                    </div>
                                    
                                    <h1 className="welcome-phr">
                                        {others.steps !== others.maxStep ? "Log in to PragmaHR" : ""}
                                    </h1>
                                    {others.steps !== others.maxStep ? 
                                        ""
                                     : 
                                     <div style={{paddingLeft:30, paddingRight:30, fontFamily:"Arial, Helvetica, sans-serif"}}>
                                        <span style={{marginRight:10, fontSize:14}}>{login.email}</span>
                                        <button className="btn-change" onClick={() => changeBtn()}>Change</button>
                                     </div>
                                    }
                            
                                        <div className={'input-field' + (submitted && !login.email ? ' has-error' : '')}>
                                            <p className="login-title">{others.steps !== others.maxStep ? "Username or Email address" : "Enter your password"}</p>

                                            {others.steps !== others.maxStep ? 
                                                
                                                <>
                                                <div className="input-container">
                                                    <input type="text" onKeyPress={onKeyPress} className="log-email" name="email" value={login.email} onChange={handleChange} placeholder="Enter your email"/>
                                                    
                                                </div> 
                                                    {submitted && !login.email &&
                                                        <div className="helper-text log-err-text"><span>Username or Email Address is required.</span></div>
                                                    }
                                                    {others.emailError === true &&
                                                        <div className="helper-text log-err-text">Username or Email Address doesn't exists.</div>
                                                    }
                                                    {others.orgError === true &&
                                                        <div className="helper-text log-err-text">Username or Email Address doesn't have registered organization.</div>
                                                    }
                                                </>
                                                
                                                : 
                                                <>
                                                <div className="input-container">
                                                    <input type={others.passwordType} onKeyPress={onKeyPress} style={{width:"90%",float:"left"}} className="log-pw" autoComplete="new-password" name="password" value={login.password} onChange={handleChange} placeholder="Password" />
                                                    <div className="input-group-btn" style={{width:"10%",float:"right"}}>
                                                        <button className="btn btn-togglePassword" onClick={togglePassword}>
                                                            { others.passwordType==="password"? <i className="fa fa-eye-slash" aria-hidden="true"></i> : <i class="fa fa-eye" aria-hidden="true"></i> }
                                                        </button>
                                                    </div>
                                                </div>
                                                {submitted && !login.password &&
                                                    <div className="helper-text log-err-text">Password is required</div>
                                                }
                                                {props.alert.message &&
                                                    <div className="helper-text log-err-text">Invalid Password.</div>
                                                }
                                                <div className="rememberMe">
                                                    <p>
                                                        <label>
                                                            <input type="checkbox" className="filled-in" id="remember" onClick={rememberMe} />
                                                            <span className="remember-chkbx">Remember me</span><span className="right forgot-pw"><Link to="/resetpassword" id="link2" className="fpw">Forgot password?</Link></span>
                                                        </label>
                                                    </p>
                                                </div>
                                                </>
                                               
                                                                                        
                                            }
                                            <div className="cotinue-btn">
                                                <button className="btn-large btn-continue" style={others.steps !== others.maxStep ? {marginTop:20} : {}} onClick={() => submitInputs()}>{others.steps !== others.maxStep ? "Continue" : "Login"}</button>
                                            </div>
                                        </div>
                                    

                                </div>
                                <footer className="phr-footer">
                                    <p>&copy; {new Date().getFullYear()} PragmaHR. All rights reserved</p>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
	)
};

const mapStateToProps = (state) => ({
	loggingIn: state.authentication,
	alert: state.alert,
});

export default React.memo(connect(mapStateToProps)(NewLogin));