import React from "react";
import cc from "classcat";
import DocumentTitle from "react-document-title";
import { connect, useDispatch } from "react-redux";
import { checkSideNav } from "../../Components/checkSideMenu";
import { Route, Switch as SwitchRoute } from "react-router-dom";
import BodyBackgroundColor from "react-body-backgroundcolor";
import { Switch as SwitchCase, Case } from 'react-if';
import Switch from "react-switch";
import { If, Else, Then,  } from 'react-if';
import styled from "styled-components";
import emptyIcon from '../../../_templates/empty.png';
// import moment from "moment";
import {
  PageWrapper,
  ContentWrapper,
} from "../../Components/PageWrapper/ContentWrapper";
import { DynamicHeaderContent,DynamicTableAndPaginationWrapper, DynamicSearchWrapper } from "../../Components/PageWrapper/DynamicWrapper";
import { DynamicTableAction } from "../../Components/tableActions/tableActions";
import CreateEditRewardPage from './CreateEditRewardPage';
import Swal from 'sweetalert2';
import { rewardActions } from '../../../_core/actions/reward';
import { history } from "../../../_core/helpers/history";
import { rewardService } from "../../../_core/services/reward";


const AdminRewardstsPage = styled.div`
  table {
    >thead>tr>th {
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        text-align: center;
      }
    }

    >tbody>tr>td {
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        text-align: center;
        
        img {
          border-radius: 50%;
          object-fit: cover;
        }

        @media (max-width: 993px){
          text-align: left;
        } 
      }
    }
  }

  .subjectTooltip {
    background-color: #121D38!important;
    width: auto;
    padding: 10px 10px;
    margin-top: 0;
    margin-left: 30px;

    color: #9BA3CA;
    font-size: 14px;
    font-weight: 400;
  }
`;


const RewardsPageList = (props) => {
  const dispatch = useDispatch(); //eslint-disable-line
  const [entriesValues, setentriesValues] = React.useState();
  const [reward, setRewards] = React.useState([]);
  const [search, setNewSearch] = React.useState("");
  const [keys, setKeys] = React.useState("");
  // const [checked, setchecked] = React.useState(false);

  const editPermission = JSON.parse(localStorage.getItem('editPermission'));
  const deletePermission = JSON.parse(localStorage.getItem('deletePermission'));
  const roleAllTrue = JSON.parse(localStorage.getItem('userRole'))?.[0].roleAll;

  let tableHeader = (editPermission.includes("Engagement - Rewards") === true) || (deletePermission.includes("Engagement - Rewards") === true) || (roleAllTrue === 1) ?  ["Title", "Description", "Value", "Availability", "Stock", "Actions"] : ["Title", "Description", "Value", "Availability", "Stock", ""];
  let filterkeys = ["title", "content"];

  const secretDetails = JSON.parse(localStorage.getItem('secretDetails'));

    //show entries
    const entriesValue = (entries) => {
        setentriesValues(entries);
    }

    //search
    const handleSearchChange = (item) => {
      setNewSearch(item);
  	};
    
    //filters
    const filterKeys = (item) => {
        setKeys(item);
    };
    
    const handleChecked=(id, status)=> {
      let newStatusValue = status ? 0 : 1 ;

      const getRewardId = props.reward?.items;
      const resultId = getRewardId.filter(checkId);

      function checkId(statId) {
        return statId._id === id ;
      }

      const updateRewardStatus = {
        "active": newStatusValue,
        "title": resultId[0].title,
        "content": resultId[0].content,
        "expiresAt": resultId[0].expiresAt,
        "value": resultId[0].value,
        "image": resultId[0].image,
        "status": resultId[0].status,
        "stock": resultId[0].stock,
      }

      Swal.fire({
        title: '<p class="swal-subtitle-dark">Are you sure?</p>',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#24DC98',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, ${status ? 'Hide' : 'Display'} it!`,
        background: '#2E3549',
      }).then((result) => {
          if (result.value) {
            rewardService.updateReward(id, updateRewardStatus).then((res) => { 
              const ToastSuccess = Swal.mixin({
                background: "#2a3042",
                toast: true,
                width: 300,
                position: 'center',
                showConfirmButton: false,
                timer: 1500
              });
          
              setTimeout(() => {
                ToastSuccess.fire({
                    type: 'success',
                    title: '<span style="color:#FFFFFF"> Reward has been updated! </span>'
                });dispatch(rewardActions.getAll(10,window.location.href.slice(-1),'',''))
              }, 100)
          });
          }
      })

      
    }

  const deleteReward = (rewardID) => {

    const id = rewardID;

    const Toast = Swal.mixin({
      toast: true,
      width: 300,
      height: 500,
      background: '#D4EDDA',
      position: 'center',
      showConfirmButton: false,
      timer: 5000
    });
    Swal.fire({
      title: "<p class='swal-title-engagement'>Confirmation</p>",
      html:
        " <div style='margin:30px'><p class='alert-first'>Do you want to delete this Reward?</p></div>",
  
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#2A3143",
        customClass: {
          header: 'header-class',
          container: 'container-class',
          popup: 'popup-class-engagament',
          actions: 'actions-class-engagament',
          cancelButton: 'cancel-button-class',
          confirmButton: 'confirm-btn-engagement',
          content: 'E-alert-conent',
        },
        reverseButtons:true,
        cancelButtonText: "LEAVE",
        confirmButtonText:"DELETE",
        background: "#2E3549",
    }).then((result) => {
        if (result.value) {
      dispatch(rewardActions.deleteRewards(id)); 
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: '<span style="color:#0A551E"> Reward  has been deleted.</span>',
        });
          dispatch(rewardActions.getAll())
      }, 100)
                
        }
    })
  
  }

  const updateReward = (item) => {
    history.push(`/admin/rewards/${item}/edit`)
  }

  React.useEffect(()=>{
    const timer = setTimeout(() => {
      setRewards(props.reward?.items);
      }, 100);
      return () => clearTimeout(timer);
  },[props.reward])
  

  const RewardsData = () => {
    let data = reward ?? [];
    // let today = moment().utc();
    
    return (
      <>
        <If condition={props.reward.loading === false }> 
            <Then>
                    {data.length !== 0 ?  (
                data.map((item, index) => (
                    <tr key={index}>
                    <td >{item.title}</td>
                    <td style={{ width: "30%" }}>{item.content}</td>
                    {/* <td>{moment(today).isSameOrAfter(moment.utc(item.expiresAt)) ? 'Expired': item.expiresAt===null? '---' : moment.utc(item.expiresAt).fromNow(true)}</td> */}
                    <td>{item.value}</td>
                    <td className={secretDetails !== null ? "disabled-link" : '' }>
                    <Switch 
                      onColor="#556EE6"
                      height={16}
                      width={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onChange={ (e) => {handleChecked(item._id, item.active)}} 
                      checked={item.active ? true : false} 
                      className="toggleBtn"
                    />
                    </td>
                    <td>{item.stock}</td>
                  {(editPermission.includes("Engagement - Rewards") === true) || (deletePermission.includes("Engagement - Rewards") === true) || (roleAllTrue === 1) ? 
                      <td className={secretDetails !== null ? "disabled-link" : '' }>
                          <div>
                          <DynamicTableAction
                              originPage="Reward"
                              iconSet="2"
                              editLabel="Edit Reward"
                              deleteLabel="Delete Reward"
                              onClickDelete={ (e) => {deleteReward(item._id);}}
                              // completeLabel={item.status === "finished" ? "Complete Evaluation" : ""}
                              onClickEdit={() => updateReward(item._id)}
                              // onClickDelete={() => deleteEvaluation(item?.data[0]?._id, index)}
                              // onClickCompleted={() => updateEvaluationStatus("complete", item?.data[0]?._id, index)}
                              deletePermission="Engagement - Rewards"
                              editPermission="Engagement - Rewards"
                          />
                          </div>
                      </td> : false
                    }
                    </tr>
                ))
                ) :
                <tr>
                    <td colSpan="12" style={{textAlign:'center'}}>
                        <img src={emptyIcon} className="empty" alt="empty"/>
                        <p style={{fontSize:'16px'}}>No Data Available</p>
                        <p style={{fontSize:'14px'}}>There are currently no data available</p>
                    </td>
                </tr>
                }
            </Then>
            <Else>
              <tr className="table-td-data">
                <td> 
                  <div><span style={{marginRight: '50px'}}>Loading Data...</span>
                      <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                </td>
              </tr>
            </Else>
        </If>
      </>
    );
  };

  return (
    <AdminRewardstsPage>

      {/* <DynamicContentHeaderWrapper
        buttonLabel="ADD REWARD"
        btnPath="/admin/rewards/create"
        entriesValue={entriesValue}
        originPage="Rewards"
      /> */}

    <DynamicSearchWrapper
        buttonLabel="ADD REWARD"
        btnPath="/admin/rewards/create"
        entriesValue={entriesValue}
        data={props.reward.items}
        location={props.location}
        searchs={handleSearchChange}
        filters={filterKeys}
        paginationPath={"/admin/rewards"}
        keys={filterkeys}
        originPage="Rewards"
        addPage="Engagement - Rewards"

      />

      <DynamicTableAndPaginationWrapper
        tableHeader={tableHeader}
        tableBodyData={RewardsData()}
        originPage="Rewards"
        data={props.reward}
        location={props.location}
        paginationPath={"/admin/rewards"}
        entriesItem={entriesValues}
        loading = {props.reward.loading}
        search={search}
        filter={keys}
      />
    </AdminRewardstsPage>
  );
};


const mapStateToProps = ({badges, reward }) => ({
  badges,
  reward
});


const RewardsPage = connect(mapStateToProps)((props) => {
  const ContentPageBackgroundColor = "#1C2233";

  const [breadCrumbs, setBreadCrumbs] = React.useState([])

  const filterBreadCrumbs = (mode) => {
    switch (mode) {
      case "list":
        setBreadCrumbs([
          { label: "Rewards", link: "/admin/rewards?page=1" },
        ]);
        break;
      case "create":
        setBreadCrumbs([
          { label: "Rewards", link: "/admin/rewards?page=1" },
          { label: "Create Reward", link: "#" },
        ]);
        break;
        case "edit":
          setBreadCrumbs([
            { label: "Rewards", link: "/admin/rewards?page=1" },
            { label: "Edit Reward", link: "#" },
          ]);
          break;
        default:
        // Error page
        break;
      }
  }

  React.useEffect(() => {
    filterBreadCrumbs(props.mode);
  }, [props.mode]);


  return (
    <BodyBackgroundColor backgroundColor={ContentPageBackgroundColor}>
      <DocumentTitle
        title={
          props.mode === "create"
            ? `Create Badge | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : props.mode === "list"
            ? `Rewards | Rewards | ${process.env.REACT_APP_DOCUMENT_TITLE}`
            : `Edit Rewards | ${process.env.REACT_APP_DOCUMENT_TITLE}`
        }
      >
        <PageWrapper className={cc(["RewardsPage", checkSideNav()?.trim()])}>
          <DynamicHeaderContent
            TitlePage="Engagement"
            breadCrumbs={true}
            breadCrumbsPages={breadCrumbs}
          />
          <SwitchCase>
            <Case condition={['list'].includes(props.mode)}>
              <ContentWrapper>
                <RewardsPageList {...props} />
              </ContentWrapper>
            </Case>
            <Case condition={['create', 'edit'].includes(props.mode)}>
              <CreateEditRewardPage {...props } />
            </Case>
          </SwitchCase>
        </PageWrapper>
      </DocumentTitle>
    </BodyBackgroundColor>
  );
});

RewardsPage.WrappedComponent.displayName = "RewardsPage";
const RewardsPageRoute = (props) => (
  <SwitchRoute>
    <Route
      exact
      path={`${props.match.path}`}
      render={(props) => <RewardsPage {...props} mode="list" />}
    />
    <Route
      exact
      path={`${props.match.path}/create`}
      render={(props) => <RewardsPage {...props} mode="create" />}
    />
    <Route
      exact
      path={`${props.match.path}/:pageId/edit`}
      render={(props) => <RewardsPage {...props} mode="edit" />}
    />
  </SwitchRoute>
);
export default React.memo(RewardsPageRoute);
