const getUrl = window.location.origin;
const leaveUrlPathname = "/page/leave-policy";

export default [
    //Leave Policy
    {
        type: "leave",
        policies: [
            {
                title: "Policy on Leave",
                subtitle: "",
                items: [
                    `visit ${getUrl}${leaveUrlPathname}`
                ],
                note: ""
            }
        ]
    },
    //Overtime Policy
    {
        type: "overtime",
        policies: [
            {
                title: "Policy on Overtime Work",
                subtitle: "",
                items: [
                    "The employee should email the personnel who ask them to render Overtime and ask for the approval.",
                    "The employee must forward the approved overtime to hr@pragmanila.com",
                    "Any employee who renders an overtime work with a disapproved response, shall not be entitled to an overtime compensation.",
                    "Overtime is calculated after an additional thirty (30) minutes has been worked beyond the regular working schedule. Overtime of less than thirty (30) minutes shall not be considered as overtime.",
                    "Except in highly meritorious cases to be determined by PO/TL, an overtime shall not exceed four (4) hours a day",
                ],
                note: ""
            }
        ]
    },
    //Undertime Policy
    {
        type: "undertime",
        policies: [
            {
                title: "Policy on Undertime Work",
                subtitle: "",
                items: [
                    "Pragmanila Solutions Inc. expect their employees to complete the prescribed 9 hours of work per day (1 hour lunchbreak included). However, leaving the workplace earlier than the appointed dismissal time is not a common scenario. Some employees have to leave earlier than expected because of a medical emergency, or private and personal matters. It should be properly documented by sending an email approval to hr@pragmanila.com. The Human Resource Manager will approve the request and that’s the queue that you will notify your PO/TL as well."
                ],
                note: ""
            }
        ]

    },
    //COE Policy
    {
        type: "coe",
        policies: [
            {
                title: "COE Request",
                subtitle: "",
                items: [
                    "Complete the details needed to request COE in pragmahr.com",
                    'Do not forget to fill up the Purpose of this request. Example: “Credit Card Application"',
                    "Please mention if your COE requires the details of your salary"
                ],
                note: ""
            }
        ]
    },
    //Tech Support Policy
    {
        type: 'support',
        policies: [
            {
                title: "JIRA, GITLAB & SKETCH",
                subtitle: "How to Request for Access:",
                items: [
                    "Send an email with the following details",
                    [
                        "Subject: Jira, Gitlab & Sketch Request –Last Name, First Name",
                        "To: support@pragmanila.com",
                        "Message Body:",
                        [
                            "1. Full Name: First Name Middle Initial Last Name",
                            "2. Email Address: yourcompanyemail@pragmanila.com",
                            "3. Name of Project: e.g. Unionbank"
                        ]
                    ]
                ],
                note: ""
            },
            {
                title: "PRAGGER",
                subtitle: "What is Pragger?",
                items: [
                    "Pragger is the official timekeeping system used by Pragmanila Employees.",
                    "It can be accessed by installing the Mobile App available in both IOS App Store and Google Play Store.",
                    "How to Register:",
                    [
                        "1. Open IOS App Store or Google Play Store.",
                        '2. Search for and Download/Install the "Pragger Companion App".',
                        '3. Open the App and take note of the "Device ID" that shows.',
                        "4. Send an email with the following details:",
                        [
                            "Subject: Pragger Registration Request –Last Name, First Name",
                            "To: charm@pragmanila.com cc:support@pragmanila.com",
                            "Message Body:",
                            [
                                "Personal Email: yourpersonalemail.com",
                                "Device ID: deviceidfromstep3",
                            ]
                        ],
                        "5. Admin will then Register/Create your user. Wait for their email response. Should you not receive an update within an hour feel free to reach out or send a follow up.",
                        "6. Once email is received. Click on/Accept the Invitation Link.",
                        "7. Use the username and temporary password provided.",
                        "8. Change/Update the temporary password.",
                        "9. Nominate a 6 Digit PIN or Access Code. This is required for the camera feature to work."
                    ]
                ],
                note: ""
            }
        ]
    },
    //Concern Policy
    {
        type: "concern",
        policies: [
            {
                title: "Reach out directly to Human Resource Manager for the following cases below:",
                subtitle: "",
                items: [
                    "Harassments and Bullying",
                    "Discrimination against your race, sex, religion, disability or other protected class.",
                    "When you have questions about or issues with benefits or rights guaranteed by the law",
                    "Health Concerns that may affect your productivity at work",
                    "When you encounter certain issues with Supervisor – in very limited circumstances"
                ],
                note: "Email directly to hr@pragmanila.com"
            }
        ]
    },
]