import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { attributeActions } from '../../_core/actions/attribute';
import { userAttributeActions } from '../../_core/actions/userAttribute';
import { userActions } from '../../_core/actions/user';
import { alertActions } from '../../_core/actions/alert';
import M from 'materialize-css/dist/js/materialize.min.js';
import { confirmAlert } from 'react-confirm-alert';
import MaterialIcon from 'material-icons-react';
// import SideNav from '../SideNav';
// import SideMenu from '../Components/SideMenu';
import ErrorPage from '../../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../../Components/ERRORPAGE/ButtonHome';
import error from '../../_templates/error.png';
// import './UserManagement.sass';
import '../Admin.sass';
import {checkSideNav} from '../Components/checkSideMenu'; //adjust component width if admin sidenav is open

class AddUserAttribute extends React.Component {
    constructor(props) {
        super(props);

        //get logged in user
        let _user_id = JSON.parse(localStorage.getItem('userAttribute'));



        //setup state for new or edited profile
        this.state = {
            userAttribute: {
                user_id: _user_id,
                attribute_id: '',
                type: '',
                value: '',
                name: ''
            },
            submitted: false,
            render: false
        };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChanges = this.handleChanges.bind(this);
        this.alertTimeOut = this.alertTimeOut.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    alertTimeOut = () =>
        setTimeout(function () {
            this.setState({ render: false })
            this.props.dispatch(alertActions.clear())
        }.bind(this), 5000
        )

    /*componentWillUnmount() {
        clearTimeout(this.alertTimeOut)
        clearTimeout(this.roles)
    }*/

    handleClose(event) {
        event.preventDefault()
        this.setState({ render: false });
        this.props.dispatch(alertActions.clear());
        clearTimeout(this.alertTimeOut)
    }

    componentDidMount() {
        let id = JSON.parse(localStorage.getItem('userAttribute'));
        this.props.dispatch(attributeActions.getAll(1, 100000));
        this.props.dispatch(userActions.getUser(id));
        this.props.dispatch(userAttributeActions.getUserAttributes(id));
        //attributes
        setTimeout(function () {
            var attributeSelect = document.querySelectorAll('.attribute-select');
            M.FormSelect.init(attributeSelect);
        }, 1000)
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { userAttribute } = this.state;

        this.setState({
            userAttribute: {
                ...userAttribute,
                [name]: value
            }
        });

    }

    handleChanges(event) {

        var tempType = event.nativeEvent.target.selectedIndex;
        var type = event.nativeEvent.target[tempType].label;
        var name = event.nativeEvent.target[tempType].text;

        const { value } = event.target;
        const { userAttribute } = this.state;


        this.setState({
            userAttribute: {
                ...userAttribute,
                attribute_id: value,
                type: type,
                name: name
            }
        });
    }

    handleDeleteUserAttribute(userAttribute) {

        return (e) => confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-body'>
                        <h1 className="confirm-title">Are you sure?</h1>
                        <p>You want to delete this attribute?</p>
                        <div className="react-confirm-alert-button-group">
                            <button onClick={() => {
                                this.setState({ render: true })
                                this.props.dispatch(userAttributeActions.delete(userAttribute));
                                onClose()
                            }}>Yes</button>
                            <button onClick={onClose}>No</button>
                        </div>
                    </div>
                )
            }
        })

    }
    handleSubmit(event) {
        event.preventDefault();
        const { userAttribute } = this.state;
        let _user_id = JSON.parse(localStorage.getItem('userAttribute'));

        this.setState(
            {
                submitted: true,
                render: true,
                userid: _user_id
            }
        );
        this.props.dispatch(userAttributeActions.addUserAttribute(userAttribute));
    }
    render() {
        const { attributes, userAttributes, users, alert } = this.props;
        const { userAttribute, render } = this.state;

        //attributes
        const attributesExist = function () {
            let attributeExist = [];

            for (let userAttribute in userAttributes.items) {
                attributeExist.push(
                    userAttributes.items[userAttribute].attribute_id,
                );
            }

            return attributeExist;
        }
        const attributeSelection = function () {
            let attributesSelect = [];
            let existing = attributesExist()
            // let ExattributesSelect = attributesExist();
            for (let attribute in attributes.items) {
                // console.log(existing.includes(attributes.items[attribute]._id))
                if (!existing.includes(attributes.items[attribute]._id)) {
                    attributesSelect.push({
                        value: attributes.items[attribute]._id,
                        label: attributes.items[attribute].attrName,
                        type: attributes.items[attribute].attrType
                    });
                }

            }
            return attributesSelect;
        }

        // console.log(users.user)
        return (
            <div className={"bodyContainer" + checkSideNav()}>
                {/* <SideMenu /> */}
                {userAttributes.error ?
                    <div className="container error-div">
                        <ErrorPage />
                        <div className="text-danger">
                            <img className="error" src={error} alt="error" />
                            {userAttributes.error}
                        </div>
                        <ButtonHome />
                    </div> :
                    <div className="container user-attribute">
                        {alert.message && render &&
                            <div id="alert" className={`alert ${alert.type}`} toggle={this.onDismiss}>{alert.message}<span className="closebtn right" onClick={this.handleClose}>&times;</span></div>
                        }
                        <div className="col s12 m8 offset-m2 l6 offset-l3">
                            <div className="card">
                                <div className="card-content black-text">
                                    {/* <span className="card-title center-align">ATTRIBUTES</span> */}
                                    <form ref="addUserAttribute" name="form" onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            {users.user &&
                                                <div className="col s12">Add an attribute to {users.user.user[0].details[0].firstName + " " + users.user.user[0].details[0].lastName}</div>
                                            }
                                            <div className="input-field col s4">
                                                {attributeSelection() &&
                                                    <select name="attribute_id" className="attribute-select" value={userAttribute.attribute_id} label={userAttribute.type} onChange={this.handleChanges}>
                                                        <option defaultValue="">--- Choose Attribute ---</option>
                                                        {attributeSelection().map((attribute, index) =>
                                                            <option key={attribute.value} value={attribute.value} label={attribute.type}>{attribute.label}</option>
                                                        )}
                                                    </select>
                                                }
                                            </div>
                                            <div className="col s4">
                                                <div className="input-field col s12">
                                                    <input id="attrValue" type="text" name="value" className="validate" value={userAttribute.value} onChange={this.handleChange} required />
                                                    <label htmlFor="attrValue">Value</label>
                                                </div>
                                            </div>
                                            <div className="col s4">
                                                <div className="input-field col s12">
                                                    <button onClick={this.handleSubmit} className="btn-small blue">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div>
                            {userAttributes.loading && <em>Loading attributes...</em>}
                            {userAttributes.items &&
                                <table className="col s12 responsive-table">
                                    <thead className="thead">
                                        <tr>
                                            <th>Attribute ID</th>
                                            <th>Attribute Name</th>
                                            <th>Attribute Type</th>
                                            <th>Attribute Value</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userAttributes.items.map((userAttribute, index) =>
                                            <tr key={index}>
                                                <td>{userAttribute.attribute_id}</td>
                                                <td>{userAttribute.name}</td>
                                                <td>{userAttribute.type}</td>
                                                <td>{userAttribute.value}</td>
                                                <td>
                                                    <button onClick={this.handleDeleteUserAttribute(userAttribute)} className="waves-effect waves-light btn red"><MaterialIcon icon="clear" size="small" color="white" /></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            }

                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { attributes, attribAssignPost, userAttributes, users, alert } = state;
    return {
        attributes,
        attribAssignPost,
        userAttributes,
        users,
        alert
    };
}


const connectedAddUserAttribute = connect(mapStateToProps)(AddUserAttribute);
export { connectedAddUserAttribute as AddUserAttribute };
