import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MainNav from '../Components/MainNav';
import ErrorPage from '../Components/ERRORPAGE/Errorhandler';
import ButtonHome from '../Components/ERRORPAGE/ButtonHome';
import error from '../_templates/error.png';
import address from '../_templates/address_icon.png';
import location from '../_templates/location-office.png';
import phone from '../_templates/phone_icon.png'
import ReactImageFallback from "react-image-fallback";
import avatar from '../_templates/man.png';
import { officeActions } from '../_core/actions/office';
import { userActions } from '../_core/actions/user';
import './OfficesPage.sass'

class OfficesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offices: [],
            users: []
        };
    }

    componentDidMount(){
        this.props.dispatch(officeActions.getAll());
        this.props.dispatch(userActions.getAllActive());
    }

    static getDerivedStateFromProps(props){
        return{
            offices: props.office,
            users: props.users
        }
    }

    getOffice(id){
        this.props.dispatch(officeActions.getOne(id));
    }

    goBack(){
        this.props.dispatch(userActions.getAllActiveUsers(1));
    }

    render() {
        const { offices, users } = this.state;
        return (
            <div className="offices-page">
                <MainNav />
                <div className="backbtn">
                    <Link to="/people" className="link-goback" onClick={this.goBack}>
                        <i className="material-icons backicon">keyboard_backspace</i> 
                        <span className="backtext">Go back</span>
                    </Link>
                </div>
                <div className="offc-container">
                    <div className="row offc-header" id="zero">
                        <div className="col s12 m6 l6" id="zero">
                            <h1 className="offc-title" id="zero">Offices</h1>
                        </div>
                        <div className="col s12 m6 l6 offc-btns" id="zero">
                            <span className="btns">
                            <Link to="/leaderboards" className="btn btn-large btn-leaderboard">View Leaderboards</Link>
                            <Link to="/people" className="btn btn-large btn-people" onClick={this.goBack}>View Everyone</Link>
                            </span>
                        </div>
                        <div className="col s12 m12 l12" id="zero">
                            <hr className="offc-border"/>
                        </div>
                    </div>
                    <div className="offc-container-list">
                        {offices.error ? 
                        <div className="container error-div">
                            <ErrorPage />
                            <div className="text-danger">
                                <img className="error" src={error} alt="error" />
                                {offices.error}
                            </div>
                            <ButtonHome />
                        </div>
                        :
                        <div className="offc-container-list">
                            {offices.loading ?
                            <div className="offc-load">
                                <img alt="" className="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </div>
                            : offices.offices && offices.offices.length <= 0 ?
                            <div className="offc-no-result">
                                <center><h5><em>no records found</em></h5></center>
                            </div>
                            :
                            <div className="offc-success">
                                <div className="offc-align">
                                {offices.offices && offices.offices.map((office, index)=>
                                    <div key={index} className="offc-card" onClick={()=>this.getOffice(office._id)}>
                                        <div className="location-image">
                                            <ReactImageFallback
                                                src={office.sigil}
                                                fallbackImage={location}
                                                initialImage={location}
                                                alt="location_img"
                                                className="location-img"/>
                                            <div className="dark-cover">
                                                <p className="offc-name">{office.name}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="offc-tag">{office.tagline ? office.tagline : office.name}</p>
                                        </div>
                                        <div className="offc-info">
                                            <table>
                                                <tbody>
                                                    <tr className="offc-address">
                                                        <td className="address">
                                                            <ReactImageFallback
                                                                src={address}
                                                                fallbackImage={address}
                                                                alt="address_img"
                                                                className="address-img" />
                                                        </td>
                                                        <td className="address-text">{office.address ? <p>{office.address}</p> : <em>No address</em>}</td>
                                                    </tr>
                                                    <tr className="offc-contact">
                                                        <td className="contact">
                                                            <ReactImageFallback
                                                                src={phone}
                                                                fallbackImage={phone}
                                                                alt="contact_img"
                                                                className="contact-img" />
                                                        </td>
                                                        <td className="contact-text">{office.contact ? <p>{office.contact}</p> : <em>No contact number</em>}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="office-employees valign-wrapper">
                                            <div className="offc-emp">
                                            {users.users && users.users.items.filter((user) => user.details[0].officeId === office._id).slice(0, 3).map((user, index)=>
                                            <ReactImageFallback
                                                key={index}
                                                src={user.details[0].avatar}
                                                fallbackImage={avatar}
                                                initialImage={avatar}
                                                alt="employee_img"
                                                className="offc-emp-img"/>
                                            )}
                                            </div>
                                            <div className="offc-emp-count">
                                                <p className="emp-count">{users.users && users.users.items.filter((user) => user.details[0].officeId === office._id).length >= 4 ? "+ " : " " }{users.users && users.users.items.filter((user) => user.details[0].officeId === office._id).length <= 3 ? "" : users.users && users.users.items.filter((user) => user.details[0].officeId === office._id).length-3}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { office, users } = state;
    return { office, users };
}
const connectedOfficesPage = connect(mapStateToProps)(OfficesPage);

export { connectedOfficesPage as OfficesPage };