export default {
    GET_REQUEST: "GET_QUESTION_REQUEST",
    GET_SUCCESS: "GET_QUESTION_SUCCESS",
    GET_FAILURE: "GET_QUESTION_FAILURE",

    CREATE_REQUEST: "CREATE_QUESTION_REQUEST",
    CREATE_SUCCESS: "CREATE_QUESTION_SUCCESS",
    CREATE_FAILURE: "CREATE_QUESTION_FAILURE",

    UPDATE_REQUEST: "UPDATE_QUESTION_REQUEST",
    UPDATE_SUCCESS: "UPDATE_QUESTION_SUCCESS",
    UPDATE_FAILURE: "UPDATE_QUESTION_FAILURE",

    DELETE_REQUEST: "DELETE_QUESTION_REQUEST",
    DELETE_SUCCESS: "DELETE_QUESTION_SUCCESS",
    DELETE_FAILURE: "DELETE_QUESTION_FAILURE",

    GETBYID_REQUEST: "GETBYID_QUESTION_REQUEST",
    GETBYID_SUCCESS: "GETBYID_QUESTION_SUCCESS",
    GETBYID_FAILURE: "GETBYID_QUESTION_FAILURE",
}