import { leaveConstants } from '../constants/leave';

let initialState = {
  items: [],
  loading: true,
  error: null,
  totalPerPage: 0,
  perPage: 0,
  currentPage: 0,
}

export function leaves(state =initialState, action) {
  switch (action.type) {
    //get all user
    case leaveConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case leaveConstants.GETALL_SUCCESS:
      return {
        // items: action.leaves
        // leaves: action.leaves
        ...state,
        loading: false,
        error: null,
        items: action.leaves.items,
        totalPerPage:  action.leaves.total,
        perPage: action.leaves.pages,
        currentPage: action.leaves.page,

      };
    case leaveConstants.GETALL_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };

    case leaveConstants.GETUSER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case leaveConstants.GETUSER_SUCCESS:
      return {
        // leaves: action.leaves
        ...state,
        loading: false,
        error: null,
        items: action.leaves.items,
        totalPerPage:  action.leaves.total,
        perPage: action.leaves.pages,
        currentPage: action.leaves.page,
      };
    case leaveConstants.GETUSER_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };

    //get user by _id
    case leaveConstants.GET_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        success: false
      };
    case leaveConstants.GET_SUCCESS:
      return {
        ...state,
        ...action.leave,
        loading: false,
      };
    case leaveConstants.GET_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };

    case leaveConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(leave =>
          leave.id === action.id
            ? { ...leave, deleting: true }
            : leave
        )
      };
    case leaveConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(leave => leave.id !== action.id)
      };
    case leaveConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(leave => {
          if (leave.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...leaveCopy } = leave;
            // return copy of user with 'deleteError:[error]' property
            return { ...leaveCopy, deleteError: action.error };
          }

          return leave;
        })
      };
    default:
      return state
  }
}