import React from 'react';
import DocumentTitle from 'react-document-title';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Dashboard from '../Dashboard/Dashboard';
import EmployeeSelfService from '../EmployeeSelfService/EmployeeSelfService';
import Engage from '../Engage/Engage';
import Sidebar from '../NavigationBar/Sidebar';
import NavigationBar from '../NavigationBar/NavigationBar';
import Profile from '../Profile/Profile';
import Timekeeping from '../Timekeeping/Timekeeping';
import People from '../People/People';
import Page from '../Page/Page';
import UserEditProfilePage from '../UserEditProfile/UserEditProfilePage'
import Search from "../Search/Search";
import Performance from "../Performance/Performance";
import Reward from "../Reward/RewardSide";
import NewChangePass from "../ChangePasswordPage/NewChangePass"

import './UserDashboard.scss';

export const UserDashboardContext = React.createContext({ isTablet: false });

const UserDashboard = (props) => {
    const [isSidebarExpanded, toggleIsSidebarExpanded] = (
        React.useState(JSON.parse(localStorage.getItem('isSidebarExpanded')) ?? true)
    );

    const pageTitle = React.useMemo(
        () => (
            props.location.pathname.split('/')[1].replace(/\W/g, " ").trim()
                .toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1))
                .join(' ')
        ),
        [props.location.pathname]
    );

    React.useEffect(
        () => {
            localStorage.setItem('isSidebarExpanded', isSidebarExpanded);
        },
        [isSidebarExpanded]
    );

    const navbarTitle = React.useMemo(() => {
        const title = {
            sub: pageTitle,
            main: 'Summary'
        };

        switch (true) {
            case props.location.pathname.includes('/profile'):
                title.main = '';
                break;
            case props.location.pathname.includes('/people'):
                title.main = 'People';
                title.sub = "Profile";
                break;
            case props.location.pathname === '/timekeeping':
                title.main = 'Timesheet';
                title.sub = "Profile";
                break;
            case props.location.pathname === '/engage/challenges':
                title.sub = 'Challenges';
                break;
            case props.location.pathname === '/engage/quests':
                title.sub = 'Quests';
                break;
            case props.location.pathname === '/edit-profile':
                title.sub = 'Edit Profile';
                title.main = ""
                break;
            case props.location.pathname === '/engage/quest/challenges':
                const qcTitle = JSON.parse(localStorage.getItem('questChallenges'))?.questTitle
                title.main = 'Activity';
                title.sub = qcTitle || 'Quest Title';
                break;
            case props.location.pathname.includes('/search'):
                title.main = 'Search';
                title.sub = "Profile";
                break;
            case props.location.pathname.includes('/performance'):
                title.main = 'Summary';
                title.sub = "Evaluation Appraisal";
                break;
            case props.location.pathname === '/people/teams':
                title.main = 'People';
                title.sub = "Profile";
                break;
            case props.location.pathname === '/rewards/redemption':
                title.main = 'Rewards';
                break;
            case props.location.pathname.includes('/rewards'):
                title.main = 'Rewards Redemption';
                title.sub = "Rewards";
                break;  
            case props.location.pathname.includes('/changepassword'):
                title.main = '';
                title.sub = "Change Password";
                break;  
            default:
                break;
        }

        return title;
    }, [pageTitle, props.location.pathname]);

    const isTablet = useMediaQuery({ maxWidth: 992 });

    return (
        <DocumentTitle title={[pageTitle, process.env.REACT_APP_DOCUMENT_TITLE].join(" | ")}>
            <UserDashboardContext.Provider value={{ device: isTablet ? 'tablet' : 'desktop' }}>
                <Sidebar
                    isNavigationBarExpanded={isSidebarExpanded}
                    toggleIsNavigationBarExpanded={toggleIsSidebarExpanded}
                />
                <div className="user-dashboard">
                    <NavigationBar
                        isSidebarExpanded={isSidebarExpanded}
                        leftNavbarTitle={navbarTitle.sub}
                        rightNavbarTitle={navbarTitle.main}
                        toggleIsSidebarExpanded={toggleIsSidebarExpanded}
                    />
                    <Switch>
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/employee-self-service" component={EmployeeSelfService} />
                        <Route path="/engage" component={Engage} />
                        <Route path="/profile" component={Profile} />
                        <Route path="/timekeeping" component={Timekeeping} />
                        <Route path="/people" component={People} />
                        <Route path="/page/:slug" component={Page} />
                        <Route path="/edit-profile" component={UserEditProfilePage} />
                        <Route path="/search" component={Search} />
                        <Route path="/performance" component={Performance} />
                        <Route path="/rewards" component={Reward}/>
                        <Route path="/changepassword" component={NewChangePass}/>
                    </Switch>
                </div>
            </UserDashboardContext.Provider>
        </DocumentTitle>
    );
};

export default withRouter(UserDashboard);
