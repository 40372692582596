import { challengeConstants } from '../constants/challenge';
import { challengeService } from '../services/challenge';
import { alertActions } from './alert';
import { history } from '../helpers/history';
import Swal from 'sweetalert2'

export const challengeActions = {
    create,
    getAll,
    getAllFilter,
    acceptChallenge,
    getById,
    getOne,
    update,
    quitChallenge,
    completeChallenge,
    finishChallenge,
    resetChallenge,
    createChallenge,
    deleteChallenge,
    getAllAdmin,
    revertChallenge,
    completeOneChallenge,
    getAlllAcceptedChallenge
    // delete: _delete
};

function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());

        challengeService.getAll(page, itemsPerPage)
            .then(
                challenges => dispatch(success(challenges)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: challengeConstants.GETALL_REQUEST } }
    function success(challenges) { return { type: challengeConstants.GETALL_SUCCESS, challenges } }
    function failure(error) { return { type: challengeConstants.GETALL_FAILURE, error } }
}

function getAllAdmin(page, itemsPerPage, search, filter) {
    return dispatch => {
        dispatch(request());

        challengeService.getAllAdmin(page, itemsPerPage, search, filter)
            .then(
                challenges => dispatch(success(challenges)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: challengeConstants.GETALL_REQUEST } }
    function success(challenges) { return { type: challengeConstants.GETALL_SUCCESS, challenges } }
    function failure(error) { return { type: challengeConstants.GETALL_FAILURE, error } }
}

function getAllFilter(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());

        challengeService.getAllFilter(page, itemsPerPage)
            .then(
                challengest => dispatch(success(challengest)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: challengeConstants.GETALL_REQUEST } }
    function success(challenges) { return { type: challengeConstants.GETALL_SUCCESS, challenges } }
    function failure(error) { return { type: challengeConstants.GETALL_FAILURE, error } }
}

function create(challenge) {
    return dispatch => {
        dispatch(request(challenge));

        challengeService.create(challenge)
            .then(
                challenge => { 
                    dispatch(success());
                    history.push('/challenge')
                    dispatch(alertActions.success('New challenge has been created'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };

    function request(challenge) { return { type: challengeConstants.CREATE_REQUEST, challenge } }
    function success(challenge) { return { type: challengeConstants.CREATE_REQUEST, challenge } }
    function failure(error) { return { type: challengeConstants.CREATE_REQUEST, error } }
}

function createChallenge(challenge,page) {
    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });
    const ToastSuccessUser = Swal.mixin({
        background: "white",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const ToastFailed = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });
    return dispatch => {
        dispatch(request(challenge));

        challengeService.createChallenge(challenge)
            .then(
                challenge => { 
                    dispatch(success(challenge));
                    if(page !== 'user') {
                        //setTimeout(function(){window.location.reload(1);}, 2000);
                        ToastSuccess.fire({
                            type: 'success',
                            title: '<span style="color:#FFFFFF">New challenge has been created</span>'
                        })

                    }else{
                        setTimeout(function(){window.location.reload(1);}, 1000);
                        ToastSuccessUser.fire({
                            type: 'success',
                            title: '<span style="color:green">New challenge has been created</span>'
                        })
                    }

                },
                error => {
                    dispatch(failure(error.toString()));
                    ToastFailed.fire({
                        type: 'error',
                        title: `<span style="color:#FFFFFF"> Something went wrong</span> `
                    });
                }
            );
    };

    function request(challenge) { return { type: challengeConstants.CREATE_REQUEST, challenge } }
    function success(challenge) { return { type: challengeConstants.CREATE_REQUEST, challenge } }
    function failure(error) { return { type: challengeConstants.CREATE_REQUEST, error } }
}

function getOne(id) {
    return dispatch => {
        dispatch(request(id));

        challengeService.getOne(id)
            .then(
                challenge => {
                    dispatch(success(challenge.success));
                },
                error => dispatch(failure(error.toString()))

            );
    };

    function request() { return { type: challengeConstants.GET_REQUEST} }
    function success(challenge) { return { type: challengeConstants.GET_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.GET_FAILURE, error } }
}

function update(challenge, id) {

    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const ToastFailed = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    return dispatch => {
        dispatch(request());

        challengeService.updateChallenge(id, challenge)
        .then(
            challenge => {
                dispatch(success(challenge));

                setTimeout(() => {
                    ToastSuccess.fire({
                        type: 'success',
                        title: '<span style="color:#FFFFFF">Challenge has been updated! </span>'
                    })
                }, 100)
                
            },
            error => {
                dispatch(failure(error.toString()));
                ToastFailed.fire({
                    type: 'error',
                    title: `<span style="color:#FFFFFF"> Challenge Update Failed</span> `
                });
            }
        );
    };
    function request(challenge) { return { type: challengeConstants.UPDATE_REQUEST, challenge } }
    function success(challenge) { return { type: challengeConstants.UPDATE_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.UPDATE_FAILURE, error } }
}


function getById(id) {
    return dispatch => {
        dispatch(request(id));

        challengeService.getById(id)
            .then(
                challenge => {
                    dispatch(success(challenge.success));
                },
                error => dispatch(failure(error.toString()))

            );
    };

    function request() { return { type: challengeConstants.GET_REQUEST} }
    function success(challenge) { return { type: challengeConstants.GET_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.GET_FAILURE, error } }
}

function acceptChallenge(challenge) {
    return dispatch => {
        dispatch(request(challenge));

        challengeService.acceptChallenge(challenge)
            .then(
                challenge => { 
                    dispatch(success(challenge.success));
                    dispatch(alertActions.success('Challenge Accepted'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('something went wrong'));
                }
            );
    };

    function request(challenge) { return { type: challengeConstants.ACCEPT_REQUEST, challenge } }
    function success(challenge) { return { type: challengeConstants.ACCEPT_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.ACCEPT_FAILURE, error } }
}


function quitChallenge(challenge) {
    return dispatch => {
        dispatch(request(challenge));

        challengeService.quitChallenge(challenge)
            .then(
                challenge => { 
                    dispatch(success(challenge.success));
                    dispatch(alertActions.success('User successfully quit the challenge'));
                    setTimeout(function(){window.location.reload(1);}, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('something went wrong'));
                }
            );
    };

    function request(challenge) { return { type: challengeConstants.QUIT_REQUEST, challenge } }
    function success(challenge) { return { type: challengeConstants.QUIT_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.QUIT_FAILURE, error } }
}

function completeChallenge(challenge) {
    return dispatch => {
        dispatch(request(challenge));

        challengeService.completeChallenge(challenge)
            .then(
                challenge => { 
                    dispatch(success(challenge.success));
                    // window.location.reload();
                    dispatch(alertActions.success('User successfully complete the challenge'));
                    setTimeout(function(){window.location.reload(1);}, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('something went wrong'));
                }
            );
    };

    function request(challenge) { return { type: challengeConstants.COMPLETE_REQUEST, challenge } }
    function success(challenge) { return { type: challengeConstants.COMPLETE_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.COMPLETE_FAILURE, error } }
}

function completeOneChallenge(challenge) {
    return dispatch => {
        dispatch(request(challenge));

        challengeService.completeOneChallenge(challenge)
            .then(
                challenge => { 
                    dispatch(success(challenge.success));
                    // window.location.reload();
                    dispatch(alertActions.success('Challenge successfully completed.'));
                    //setTimeout(function(){window.location.reload(1);}, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('something went wrong'));
                }
            );
    };

    function request(challenge) { return { type: challengeConstants.COMPLETE_REQUEST, challenge } }
    function success(challenge) { return { type: challengeConstants.COMPLETE_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.COMPLETE_FAILURE, error } }
}

function finishChallenge(challenge,files) {
    return dispatch => {
        dispatch(request(challenge));

        challengeService.finishChallenge(challenge,files)
            .then(
                challenge => { 
                    dispatch(success(challenge));
                    // setTimeout(function(){window.location.reload(1);}, 2000);
                    dispatch(alertActions.success('User successfully finish the challenge'));
                    //setTimeout(function(){window.location.reload(1);}, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };

    function request(challenge) { return { type: challengeConstants.FINISH_REQUEST, challenge } }
    function success(challenge) { return { type: challengeConstants.FINISH_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.FINISH_FAILURE, error } }
}

function resetChallenge(challenge) {
    return dispatch => {
        dispatch(request(challenge));

        challengeService.resetChallenge(challenge)
            .then(
                challenge => { 
                    dispatch(success(challenge.success));
                    // setTimeout(function(){window.location.reload(1);}, 2000);
                    dispatch(alertActions.success('User successfully reset the challenge'));
                    setTimeout(function(){window.location.reload(1);}, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Something went wrong'));
                }
            );
    };

    function request(challenge) { return { type: challengeConstants.RESET_REQUEST, challenge } }
    function success(challenge) { return { type: challengeConstants.RESET_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.RESET_FAILURE, error } }
}



function deleteChallenge(id){
    return dispatch => {
        dispatch(request(id))
        
        challengeService.deletChallenge(id)
            .then(
                challenge => {
                    dispatch(success(challenge))
                },
                error => dispatch(failure(error.toString()))
            )

    }
    function request(id) { return { type: challengeConstants.DELETE_REQUEST, id } }
    function success(challenge) { return { type: challengeConstants.DELETE_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.DELETE_FAILURE, error } }
}

function revertChallenge(challenge) {
    return dispatch => {
        dispatch(request(challenge));

        challengeService.revertChallenge(challenge)
            .then(
                challenge => { 
                    dispatch(success(challenge.success));
                    dispatch(alertActions.success('User successfully quit the challenge'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('something went wrong'));
                }
            );
    };

    function request(challenge) { return { type: challengeConstants.QUIT_REQUEST, challenge } }
    function success(challenge) { return { type: challengeConstants.QUIT_SUCCESS, challenge } }
    function failure(error) { return { type: challengeConstants.QUIT_FAILURE, error } }
}

function getAlllAcceptedChallenge(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());

        challengeService.getAlllAcceptedChallenge(page, itemsPerPage)
            .then(
                challengest => dispatch(success(challengest)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: challengeConstants.GETALL_REQUEST } }
    function success(challenges) { return { type: challengeConstants.GETALL_SUCCESS, challenges } }
    function failure(error) { return { type: challengeConstants.GETALL_FAILURE, error } }
}