import { post,put,get } from '../helpers/api';
import { userService } from '../services/user';

export const energyService = {
    create,
    update,
    getAll
};


function create(energy){
    let id = energy.userId;
    delete energy._id;
    return post(`/api/v1/user/${id}/energy`, JSON.stringify(energy))
    .then(handleResponse)
}

function update(energy){
    let id = energy.userId;
    delete energy._id;
    return put(`/api/v1/user/${id}/energy`, JSON.stringify(energy))
    .then(handleResponse)
}

function getAll(id){
    return get(`/api/v1/user/${id}/energy`).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}