export const leaveCreditConstants = {
    GETALL_REQUEST: 'LEAVECREDIT_GETALL_REQUEST',
    GETALL_SUCCESS: 'LEAVECREDIT_GETALL_SUCCESS',
    GETALL_FAILURE: 'LEAVECREDIT_GETALL_FAILURE',

    POST_REQUEST: 'LEAVECREDITS_POST_REQUEST',
    POST_SUCCESS: 'LEAVECREDITS_POST_SUCCESS',
    POST_FAILURE: 'LEAVECREDITS_POST_FAILURE',

    UPDATE_REQUEST: 'LEAVECREDITS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'LEAVECREDITS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'LEAVECREDITS_UPDATE_FAILURE',

    GETUSER_CREDIT_REQUEST: 'LEAVECREDIT_GETUSER_REQUEST',
    GETUSER_CREDIT_SUCCESS: 'LEAVECREDIT_GETUSER_SUCCESS',
    GETUSER_CREDIT_FAILURE: 'LEAVECREDIT_GETUSER_FAILURE',

};


