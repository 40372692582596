export const smsConstants = {
  
    GETALL_REQUEST: 'SMS_GETALL_REQUEST',
    GETALL_SUCCESS: 'SMS_GETALL_SUCCESS',
    GETALL_FAILURE: 'SMS_GETALL_FAILURE',
  
    GET_REQUEST: 'SMS_GET_REQUEST',
    GET_SUCCESS: 'SMS_GET_SUCCESS',
    GET_FAILURE: 'SMS_GET_FAILURE',
  
    POST_REQUEST: 'SMS_CREATE_REQUEST',
    POST_SUCCESS: 'SMS_CREATE_SUCCESS',
    POST_FAILURE: 'SMS_CREATE_FAILURE',

    DELETE_REQUEST: 'SMS_DELETE_REQUEST',
    DELETE_SUCCESS: 'SMS_DELETE_SUCCESS',
    DELETE_FAILURE: 'SMS_DELETE_FAILURE',
  
  };
  
  