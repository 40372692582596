import React from 'react';
import ReactDOM from 'react-dom';
import DocumentTitle from 'react-document-title';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { store } from './_core/helpers/store';
import App from './App/App';
import { BrowserRouter } from 'react-router-dom';

import 'materialize-css/dist/css/materialize.min.css'
import 'rc-time-picker/assets/index.css';

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<DocumentTitle title={process.env.REACT_APP_DOCUMENT_TITLE}>
				<App />
			</DocumentTitle>
		</BrowserRouter>
	</Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();