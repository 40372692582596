import { questConstants } from '../constants/quest';
import { questService } from '../services/quest';
import { challengeService } from '../services/challenge';
// import { alertActions } from './alert';
import { history } from '../helpers/history';
import Swal from 'sweetalert2'

export const questActions = {
    getAll,
    getById,
    getAllUserQuest,
    getAllFilter,
    create,
    deleteQuest,
    update,
    getAllQuestChallenge,
    getAllAcceptedQuest

};


function getAll(page, itemsPerPage) {
    return dispatch => {
        dispatch(request());
        
        questService.getAll(page, itemsPerPage)
            .then(
                quests => dispatch(success(quests)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: questConstants.GETALL_REQUEST } }
    function success(quests) { return { type: questConstants.GETALL_SUCCESS, quests } }
    function failure(error) { return { type: questConstants.GETALL_FAILURE, error } }
}

function getAllFilter(page, itemsPerPage, search, filter) {
    return dispatch => {
        dispatch(request());
        
        questService.getAllFilter(page, itemsPerPage, search, filter)
            .then(
                quests => dispatch(success(quests)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: questConstants.GETALL_REQUEST } }
    function success(quests) { return { type: questConstants.GETALL_SUCCESS, quests } }
    function failure(error) { return { type: questConstants.GETALL_FAILURE, error } }
}

function getAllUserQuest() {
    return dispatch => {
        dispatch(request());

        questService.getAllUserQuest()
        .then(
            quests => dispatch(success(quests)),
            error => dispatch(failure(error.toString()))
        );
    };

    function request() { return { type: questConstants.USERQUESTS_REQUEST } }
    function success(quests) { return { type: questConstants.USERQUESTS_SUCCESS, quests } }
    function failure(error) { return { type: questConstants.USERQUESTS_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request(id));

        questService.getById(id)
            .then(
                quest => {
                    dispatch(success(quest.success));
                },
                error => dispatch(failure(error.toString()))

            );
    };

    function request() { return { type: questConstants.GET_REQUEST } }
    function success(quest) { return { type: questConstants.GET_SUCCESS, quest } }
    function failure(error) { return { type: questConstants.GET_FAILURE, error } }
}


function getAllQuestChallenge(id, page, itemsPerPage) {
    return dispatch => {
        dispatch(request());
        
        questService.getAllQuestChallenge(id, page, itemsPerPage)
            .then(
                quests => dispatch(success(quests)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: questConstants.GETALL_REQUEST } }
    function success(quests) { return { type: questConstants.GETALL_SUCCESS, quests } }
    function failure(error) { return { type: questConstants.GETALL_FAILURE, error } }
}


//Checking if item has a file
function isFile(input) {
    if ('File' in window && input instanceof File)
       return true;
    else return false;
 }

function create(quest,challenge) {
    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const ToastFailed = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });
    const imageFile = quest.questImage;
    const fd = new FormData();

    fd.append('id', quest.userId)
    fd.append('file',imageFile)
    quest.questImage = null;

    function failure(error) { return { type: questConstants.POSTQUEST_REQUEST, error } }
    function success(quest) { return { type: questConstants.POSTQUEST_SUCCESS, quest } }

    return async dispatch => {
        let createdPost;
        let imageData;
        let isError = null;

        try {
            createdPost = await questService.createQuest(quest);
        } catch(error) {
            isError = error.toString();
            dispatch(failure(error.toString()));
            ToastFailed.fire({
                type: 'error',
                title: `
                    <span style="color:#FFFFFF">
                        Something went wrong
                    </span>
                `
            });

            return;
        }

        try {
            if( imageFile ){
                imageData = await questService.imageUpload(createdPost._id, fd)
            }

        } catch(error) {
            isError = error;
            ToastFailed.fire({
                type: 'error',
                title: `
                    <span style="color:#FFFFFF">
                        Something went wrong
                    </span>
                `
            });

            return;
        }

        try {
            challenge.map(questChallenge => {
                //const limitConvert= questChallenge.timeLimit.split(" ")[0]
                    const item = {
                        "questId":createdPost._id,
                        "recurring": false,
                        "timeLimit": questChallenge.timeLimit,
                        "active":1,
                        "requiredBadges": questChallenge.requiredBadges,
                        "title": questChallenge.title,
                        "description": questChallenge.description,
                        "bestTime": questChallenge.bestTime,
                        "location": questChallenge.location,
                        "lat":questChallenge.lat,
                        "lon":questChallenge.lon,
                        "eneryValue":questChallenge.eneryValue,
                        "credits":questChallenge.credits,
                        "exp":questChallenge.exp,
                        "expiresAt":questChallenge.expiresAt,
                        "images":questChallenge.images,
                        "order":questChallenge.order,
                    }
                    
                    return challengeService.createChallenge(item);

                });
        } catch(error) {
            isError = error.toString();
            dispatch(failure(error.toString()));
            ToastFailed.fire({
                type: 'error',
                title: `
                    <span style="color:#FFFFFF">
                        Something went wrong
                    </span>
                `
            });

            return;
        }        

        dispatch(success({
            ...quest,
            questImage: imageData?.questImage ?? null,
            _id: createdPost._id
        }));
        
        if(isError === null){
            await ToastSuccess.fire({
                type: 'success',
                title: '<span style="color:#FFFFFF">Quest has been created! </span>'
            })
            history.goBack();
        }
        
    };
};




function update(id,quest,challenge = null) {

    const ToastSuccess = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const ToastFailed = Swal.mixin({
        background: "#2a3042",
        toast: true,
        width: 300,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    return dispatch => {
        dispatch(request());

        questService.updateQuest(id,quest)
        .then(
            quest => {
                dispatch(success(quest));
                setTimeout(() => {
                    ToastSuccess.fire({
                        type: 'success',
                        title: '<span style="color:#FFFFFF">Quest has been updated! </span>'
                    })
                }, 100)

                if (challenge?.length > 0) {
                    let order = 1;
                    challenge.map((item, count) => {
                        if (!item._id) { // add quest challenge
                            item.order = order; //update quest challenge order
                            order++ ;
                                const fd = new FormData();
                                const imageFile = item.images;
                                fd.append('file',imageFile)
                                //checking if post images has a content file
                                if(isFile(item.images) === true){
                                    item.images = null;
                                }
                            questService
                                .createQuestChallenge(item)
                                .then((res) => {
                                    if ( res !== undefined && count === challenge.length - 1) {
                                        // Get the last quest challenge processed then move to quest challenge list
                                        history.goBack();
                                    }
                                    if(isFile(imageFile) === true){
                                        questService.imageUploadChallenge(res._id,fd);
                                    }
                                });
                        } 
                        else { //update quest Challenge
                            item.order = order; //update questChallenge order
                            order++ ;

                            const fd = new FormData();
                            const imageFile = item.images;
                            fd.append('file',imageFile)
                            //checking if post images has a content file
                            if(isFile(item.images) === true){
                                item.images = null;
                            }

                            questService
                                .updateQuestChallenge(item)
                                .then((res) => {
                                    if (res !== undefined && count === challenge.length - 1) {
                                        // Get the last challenge processed then move to questActions
                                        history.goBack();
                                    }
                                    if(isFile(imageFile) === true){
                                        questService.imageUploadChallenge(item._id,fd);
                                    }
                                });
                        }
                        return null;
                    });
                    
                } else {
                    history.goBack(); // move to quest list
                }              
                
            },
            error => {
                dispatch(failure(error.toString()));
                ToastFailed.fire({
                    type: 'error',
                    title: `<span style="color:#FFFFFF"> Quest Update Failed</span> `
                });
            }
        );
    };
    function request(quest) { return { type: questConstants.UPDATE_REQUEST, quest } }
    function success(quest) { return { type: questConstants.UPDATE_SUCCESS, quest } }
    function failure(error) { return { type: questConstants.UPDATE_FAILURE, error } }
}

function deleteQuest(id){
    return dispatch => {
        dispatch(request(id))
        
        questService.deleteQuest(id)
            .then(
                quest => {
                    dispatch(success(quest))
                },
                error => dispatch(failure(error.toString()))
            )

    }
    function request(id) { return { type: questConstants.DELETE_REQUEST, id } }
    function success(quest) { return { type: questConstants.DELETE_SUCCESS, quest } }
    function failure(error) { return { type: questConstants.DELETE_FAILURE, error } }
}

function getAllAcceptedQuest(page, itemsPerPage, id) {
    return dispatch => {
        dispatch(request());
        
        questService.getAllAcceptedQuest(page, itemsPerPage, id)
            .then(
                quests => dispatch(success(quests)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: questConstants.GETALL_REQUEST } }
    function success(quests) { return { type: questConstants.GETALL_SUCCESS, quests } }
    function failure(error) { return { type: questConstants.GETALL_FAILURE, error } }
}